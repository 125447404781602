import { t } from "lib/i18n";

import CForm from "components/Form/CForm/CForm";
import SelectFormGroup from "components/Form/SelectFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import { Constants } from "config/constants";
import { useCForm } from "hooks/useCForm";
import { ruleset } from "lib/validation-rules";
import _ from "lodash";
import { dummyFinanceCategory } from "models/FinanceCategoryModel";
import { FC, useState } from "react";
import { useParams } from "react-router-dom";
import FinanceCategorySelectFormGroup from "../../../components/Form/CategorySelectFormGroup/FinanceCategorySelectFormGroup";

const EditCategoryPage: FC = () => {
    const params = useParams<{ id: string }>();
    const [type, setType] = useState("BOTH");
    const form = useCForm({
        url: "finance/category",
        mode: "all",
        // model: FinanceCategoryModel(),
        // redirectUrl: "/finance/categories",
        dummyData: () => dummyFinanceCategory(),
    });

    const types: Record<FinanceCategoryType, string> = Constants.financeTypes;

    const categoryFilter = (category: IFinanceCategory) => {
        return (!params.id || Number(params.id) !== category.id) && (type === "BOTH" || category.type === type);
    };

    return (
        <div className="p-3">
            {useFormTitle(form.id, t("create_by_name", { name: _.capitalize(t("category")) }))}
            <HeaderRight formSubmit={form} />
            <CForm form={form.form} onSave={form.onSave}>
                <TextFormGroup
                    name="name"
                    errorPlacement="rightEnd"
                    focus={true}
                    rules={{
                        validate: ruleset.required,
                    }}
                />
                <SelectFormGroup
                    name="type"
                    data={_.map(types, (name, id) => ({ name, id }))}
                    compProps={{ onSelect: value => setType(value) }}
                />
                <FinanceCategorySelectFormGroup
                    style={{ width: "100%" }}
                    name="parent_id"
                    label={t("parent_category")}
                    revalidate={type}
                    customFilter={(category: IFinanceCategory) => categoryFilter(category)}
                    compProps={{
                        cleanable: true,
                    }}
                />
            </CForm>
        </div>
    );
};

export default EditCategoryPage;
