import CIcon from "components/CIcon";
import { columnCheckBox, columnCheckBoxSelected, iDragAndDrop } from "assets/icons/Icons";
import { Dropdown } from "rsuite";
import React, { FC } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { cn } from "lib/utils";
import { ColumnsManagerType } from "components/DataTable/ColumnsManager/useColumns";

interface IColumManagerItemProps {
    columnKey: string;
    title?: string;
    cm: ColumnsManagerType;
    column: ITableColumnProps;
}

export const ColumnManagerItem: FC<IColumManagerItemProps> = ({ columnKey, title, cm, column }) => {
    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: columnKey });

    return (
        <Dropdown.Item
            ref={setNodeRef}
            onSelect={(eventKey, event) => {
                event.stopPropagation();
                cm.toggleColumn(columnKey);
                // setIsOpen(true);
            }}
            test-id={`${columnKey}-manager`}
            className={cn("d-flex align-items-center")}
            style={{
                transform: CSS.Transform.toString(transform),
                transition,
            }}
        >
            <div
                className={cn("tw-mr-2", {
                    "tw-cursor-grabbing": isDragging,
                    "tw-cursor-grab": !isDragging,
                })}
                {...attributes}
                {...listeners}
            >
                <CIcon path={iDragAndDrop} />
            </div>
            {column.hide ? (
                <CIcon
                    path={columnCheckBox}
                    size={0.65}
                    test-id={`${columnKey}-unchecked`}
                    style={{ color: "#FFF", border: "1px solid #D0D5DD", borderRadius: "5px" }}
                />
            ) : (
                <CIcon
                    path={columnCheckBoxSelected}
                    size={0.65}
                    test-id={`${columnKey}-checked`}
                    style={{ fillRule: "evenodd", color: "#2E90FA", background: "white" }}
                />
            )}
            <span className="pl-2">{title}</span>
        </Dropdown.Item>
    );
};
