import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import SaleSection from "pages/dashboard/SaleSection";

import FinanceSection from "./FinanceSection";
import TopSaleSection from "./TopSaleSection";
import { useAuth } from "providers/AuthProvider/AuthProvider";

const DashboardPage: FCC = () => {
    const auth = useAuth();

    return (
        <div className="h-100 p-2">
            {useTitle("Dashboard")}
            <HeaderRight />
            {auth.hasAbility(["report_sell"]) ? <SaleSection /> : null}
            <FinanceSection />
            <TopSaleSection />
        </div>
    );
};

export default DashboardPage;
