import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";

import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import { Constants, OperationStatus, OperationType, ProductType, Trash } from "config/constants";
import React, { ReactNode, useState } from "react";
import CreateButton from "../../../components/DataTable/CreateButton";
import OperationProductsCell from "../misc/OperationProductsCell";
import StockOperationsDrawer from "../misc/StockOperationsDrawer";
import _ from "lodash";
import { toFixed } from "lib/utils";
import CheckListFilter from "../../../components/DataTable/Filters/CheckListFilter";
import { getLabel } from "components/Form/form-utils";
import { Badge } from "rsuite";
import { productNameAppend } from "lib/data-utils";
import { exportFile } from "lib/export-table";
import OperationProcessIndicator from "components/OperationProcessIndicator";
import classNames from "classnames";
import { QuickLinks } from "components/Header/QuickLinks";
import { ItemDataType } from "rsuite/cjs/@types/common";
import { RowDataType } from "rsuite-table/src/@types/common";
import SuppliesPageFilterDrawer from "./drawer/SuppliesPageFilterDrawer";
import ToggleFilterDrawer from "../../../components/DataTable/Filters/ToggleFilterDrawer";
import { Colors } from "config/colors";

const SuppliesDeletedPage: FCC = props => {
    const [op, setOp] = useState<IOperation>();
    // const brandType: string = selectSetting("brand_type")?.value;
    // const isRestaurant = brandType === BrandType.RESTAURANT;
    const dp = useDataProvider({
        url: "operation",
        useUrl: true,
        useDates: true,
        extraFilterKeys: ["storage_id", "product_type", "category_id", "balance_id"],
        with: [
            "operation_items.product",
            "operation_items.unit",
            "operation_items.stock",
            "operation_items.product",
            "transaction_finance.balance",
            "transaction_finance.operation.supplier",
            "supplier",
            "storage",
            "supply_return_operations",
            "supply_returns",
        ],
        params: {
            trash: Trash.OnlyTrash,
        },
        sort: ["operated_at", "-1"],
        filters: {
            type: ["type", OperationType.IN],
        },
        refineList: list => {
            return list?.map(ls => {
                return {
                    ...ls,
                    stock_operations:
                        ls.status === 2
                            ? ls.meta?.item || []
                            : _.map(
                                  _.groupBy(ls.stock_operations, sp => sp.product_id),
                                  (a, yt) => ({
                                      ...a[0],
                                      quantity: parseFloat(
                                          toFixed(
                                              a.reduce((fd, rt) => fd + rt.quantity, 0),
                                              4,
                                          ),
                                      ),
                                      total_cost: parseFloat(
                                          toFixed(
                                              a.reduce((fd, rt) => fd + rt.total_cost, 0),
                                              4,
                                          ),
                                      ),
                                  }),
                              ),
                };
            });
        },
    });
    const drawer = useDrawer();
    const filterDrawer = useDrawer();

    // const summary = useData<any>("operation/total", {
    //     params: { date: dp.params.all?.date },
    //     filters: _.values(dp.filters.all),
    // });

    const cm = useColumns<any>("operation/deleted-supplies", [
        {
            key: "id",
            align: "right",
            width: 70,
            flexGrow: undefined,
            title: t("data_id"),
            onClick: (d: IOperation) => {
                setOp(d);
                drawer.setDataId(d.id);
            },
        },
        { key: "products", Comp: OperationProductsCell, flexGrow: 2 },
        {
            key: "operated_at",
            title: t("operation"),
            type: "datetime",
            flexGrow: 2,
            multiline: true,
        },
        {
            key: "created_at",
            type: "datetime",
            flexGrow: 2,
            multiline: true,
        },
        { key: "supplier.name", title: t("supplier") },
        { key: "storage.name", title: t("storage") },
        // {
        //     key: "transaction_finance.amount",
        //     type: "money",
        //     title: t("amount"),
        //     sortable: true,
        //     summary: () => <Money value={summary.data && summary.data[0]?.total_cost} />,
        //     render: e => {
        //         if (e.status === 2) {
        //             return <Money value={_.sumBy(e.meta?.items, (x: any) => parseFloat(_.get(x, "total_cost")))} />;
        //         } else {
        //             return <Money value={e.transaction_finance?.amount} />;
        //         }
        //     },
        // },
        {
            key: "transaction_finance.balance.name",
            title: t("account"),
            render: d =>
                d?.transaction_finance?.balance?.type === "SUPPLIER" ? (
                    <label style={{ color: "red" }}>
                        <TText tkey="unpaid" />
                    </label>
                ) : (
                    d?.transaction_finance?.balance?.name
                ),
        },
        { key: "invoice_number", title: t("invoice_number") },
        { key: "description" },
        { key: "inventory_status", render: e => <OperationProcessIndicator status={e.inventory_status} /> },
        {
            key: "status",
            render: e => {
                return (
                    <Badge
                        style={{ background: e.status === 1 ? Colors.JungleGreen : Colors.VividOrange }}
                        content={<span>{getLabel(Constants.operationStatus[e.status])}</span>}
                    />
                );
            },
        },
        optionsColumn({
            dp,
            edit: "/inventory/supplies/edit/:id",
            duplicate: "/inventory/supplies/duplicate/:id",
            return: "/inventory/supplies/return/:id",
            delete: "operation/:id",
            preventDelete: (d: IOperation) => {
                const isRestricted = d.stock_operations?.filter(
                    (item: IStockOperation) => item.quantity !== item.stock?.quantity,
                );
                return !!d.is_system || !!isRestricted?.length || d?.supply_returns?.length !== 0;
            },
            editable: d => !!d.is_system,
            duplicable: d => !!d.is_system,
            returnable: d => !!d.is_system || d.status === 2,
            canDelete: "stock_manage_supply_delete",
            canEdit: "stock_manage_supply_edit",
            canDuplicate: "stock_manage_supply_create",
            canReturnProduct: "returns_manage_create",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("supplies"), dp.total)}

            <QuickLinks
                links={[
                    {
                        link: "/inventory/supplies",
                        label: t("general"),
                    },
                    {
                        link: "/inventory/supplies/detailed",
                        label: t("details"),
                    },
                    {
                        link: "/inventory/supplies/deleted_supplies",
                        label: t("deleted_supplies"),
                        active: true,
                    },
                    {
                        link: "/inventory/supplies/supply_draft",
                        label: t("draft"),
                    },
                ]}
            />

            <HeaderRight dp={dp} cm={cm} date reload print export={() => exportFile(dp, cm.columns, t("supplies"))} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    {/*<SearchFilter fields="barcode" placeholder={t("barcode")} />*/}
                    <SearchFilter fields="id" placeholder={t("operation_code")} />

                    <RemoteCheckListFilter
                        fields="product_id"
                        selectedDetails={{
                            url: "product",
                        }}
                        searchField="name"
                        remote={{
                            url: "product",
                            sort: ["updated_at", "-1"],
                            limit: 20,
                            refineList: (products?: IProduct[]) =>
                                products
                                    ?.map(i => productNameAppend(i))
                                    ?.filter(f => !(f.type === ProductType.GOODS && f.has_modifications)) ?? [],
                        }}
                        pickerProps={{
                            placeholder: t("product"),
                            groupBy: "type",
                            renderMenuGroup: (groupTitle: ReactNode, item: ItemDataType) => {
                                return getLabel((groupTitle || "") as any);
                            },
                            menuStyle: {
                                width: 500,
                            },
                        }}
                        width={120}
                    />
                    <CheckListFilter
                        pickerProps={{ searchable: false }}
                        options={[
                            { label: t("draft"), value: OperationStatus.DRAFT },
                            { label: t("approved"), value: OperationStatus.APPROVED },
                        ]}
                        fields="status"
                    />
                    {/*{isRestaurant && (*/}
                    {/*    <CheckListFilter*/}
                    {/*        pickerProps={{ searchable: false, placeholder: t("type") }}*/}
                    {/*        options={[*/}
                    {/*            { label: getLabel(ProductType.GOODS), value: ProductType.GOODS },*/}
                    {/*            { label: getLabel(ProductType.PREPARATION), value: ProductType.PREPARATION },*/}
                    {/*            { label: getLabel(ProductType.DISH), value: ProductType.DISH },*/}
                    {/*            { label: getLabel(ProductType.INGREDIENT), value: ProductType.INGREDIENT },*/}
                    {/*        ]}*/}
                    {/*        fields="product_type"*/}
                    {/*    />*/}
                    {/*)}*/}
                    <RemoteCheckListFilter
                        remote="supplier"
                        fields="supplier_id"
                        searchField="name"
                        pickerProps={{ placeholder: t("supplier") }}
                        width={120}
                    />
                    <ToggleFilterDrawer isOpen={filterDrawer.isOpen} onChange={val => filterDrawer.setIsOpen(val)} />
                </div>

                {/* <CreateButton to={"/inventory/supplies/create-beta"} canCreate={"stock_manage_supply_create"} label={`${t("create")} (beta)`} color={"orange"} /> */}
                <CreateButton to={"/inventory/supplies/create"} canCreate={"stock_manage_supply_create"} />
            </FiltersContainer>
            <StockOperationsDrawer
                // disclosed
                trash={[1, ["operation_items.product", 1], ["operation_items", 1]]}
                {...drawer}
                op={op}
                title={t("total_supply") + " #" + drawer.dataId}
                cols={["product.name", "quantity", "cost", "total_cost"]}
            />
            <SuppliesPageFilterDrawer dp={dp} {...filterDrawer} />
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                    affixHeader: true,
                    rowClassName: (d?: RowDataType) =>
                        classNames({
                            suspicious: d?.suspicion,
                            "deleted-row": d && !!d?.deleted_at,
                        }),
                }}
            />
        </div>
    );
};

export default SuppliesDeletedPage;
