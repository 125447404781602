import React, { createContext, useContext, useState } from "react";
import _ from "lodash";

/**
 this drawer provider is used to generate unique id for each drawer to avoid id conflict when there are multiple drawers on the same page
 */

const CDrawerContext = createContext<{ id: string; setId: any }>({
    id: ``,
    setId: _.noop,
});
export const useCDrawerId = () => useContext(CDrawerContext);

const CDrawerProvider = (props: any) => {
    const [id, setId] = useState(1); // this is the unique id for each drawer (incremented by 1)
    return <CDrawerContext.Provider value={{ id: `drawer-${id}`, setId }}>{props.children}</CDrawerContext.Provider>;
};

export default CDrawerProvider;
