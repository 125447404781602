import DataTable from "components/DataTable/DataTable";
import CDrawer from "components/Drawers/CDrawer";
import { t } from "lib/i18n";

import { useTableHeight } from "hooks/useTableHeight";
import { useColumns } from "../DataTable/ColumnsManager/useColumns";

import { ICDrawerProps } from "components/Drawers/CDrawer";
type IProps = ICDrawerProps;

const TaxesDrawer: FCC<IProps> = ({ ...props }) => {
    const height = useTableHeight(250);
    const cm = useColumns<any>(`taxes-drawer`, [
        {
            title: t("name"),
            key: "tax.name",
            align: "left",
            flexGrow: 3,
            render: d => d?.tax?.name || "-",
        },
        {
            title: t("percentage"),
            key: "tax.rate",
            align: "left",
            flexGrow: 3,
            render: d => d?.tax?.rate || "-",
        },
        {
            title: t("tax_amount"),
            key: "tax_amount",
            type: "money",
            align: "left",
            flexGrow: 3,
        },
    ]);

    return (
        <CDrawer size="sm" {...props} title={t("taxes")}>
            <div className="">
                <div className="d-flex">
                    <div className="flex-fill border-left">
                        <DataTable
                            noShadow
                            columns={cm.columns}
                            dp={{ data: props.data } as any}
                            hidePages
                            tableProps={{
                                autoHeight: false,
                                height,
                            }}
                        />
                    </div>
                </div>
            </div>
        </CDrawer>
    );
};

export default TaxesDrawer;
