import classNames from "classnames";
import { translateUnitName } from "lib/utils";
import { money } from "lib/money";
import React, { CSSProperties } from "react";
import { useCurrency } from "hooks/useCurrency";

interface IProps {
    value?: number | string;
    status?: number;
    testId?: string;
    currency?: string;
    colored?: boolean;
    symbol?: boolean;
    withoutRounding?: boolean;
    style?: CSSProperties;
    unit?: IUnit;
    className?: string;
}

const Money: FCC<IProps> = props => {
    const currency = useCurrency();
    const value = props.value ?? "";
    const status = props.status ?? 1;
    const className = classNames({
        "new-blue": props.colored && props.style?.color && value !== 0,
        "text-success": props.colored && Number(value) > 0 && !props.style?.color,
        "text-danger": props.colored && Number(value) < 0 && !props.style?.color,
        "text-empty": value === 0 || status === 2,
    });

    const symbol = props.symbol === undefined || props.symbol ? (props.currency ?? currency) : "";

    return (
        <span
            className={className + " " + "text-13 pr-1 " + props.className}
            test-id={props.testId}
            style={props.style}
        >
            {!props.unit &&
                (props.symbol ? (Number(value) > 0 ? "+" : props.colored && Number(value) < 0 ? "-" : "") : "")}
            <span test-id="amount-of-money">{!props.unit && money(value, symbol)}</span>

            {props.unit && value + " " + translateUnitName(props.unit)}
        </span>
    );
};

export default Money;
