import { t } from "lib/i18n";
import CForm from "components/Form/CForm/CForm";
import TagPickerFormGroup from "components/Form/TagPickerFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import JsonView from "components/JsonView";
import { useCForm } from "hooks/useCForm";
import { getVenuesList, isUrl } from "lib/utils";
import _ from "lodash";
import { dummyUser, UserModel } from "models/UserModel";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MaskInputFormGroup from "components/Form/MaskInputFormGroup";
import RemoteSelectFormGroup from "components/Form/RemoteSelectFormGroup";
import TextPostfixBtnFormGroup from "components/Form/TextPostfixBtnFormGroup";
import CIcon from "components/CIcon";
import { iEye, iEyeClosed, iInfoOutline } from "assets/icons/Icons";
import { useAppSelector } from "hooks/useRedux";
import CInputPhoneFormGroup from "components/Form/CInputPhoneFormGroup";
import { UserSchema } from "form-schemas/UserSchema";
import UploaderFormGroup from "components/Form/FileUploadFormGroup";
import TextAreaFormGroup from "components/Form/TextAreaFormGroup";
import { Tooltip, Whisper } from "rsuite";
import { TText } from "components/i18n/TText";

const UserEditPage: FCC = () => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [roleAbilities, setRoleAbility] = useState<IAbility[]>([]);
    const cloposPayModule = useAppSelector(state => state.packages.modules.find((d: any) => d.name === "clopos_pay"));
    const params = useParams<any>();
    const form = useCForm({
        url: "user",
        // model: UserModel(),
        model2: UserSchema,
        with: ["roles.abilities", "venues", "media"],
        dummyData: dummyUser,
        hasAllVenues: true,
        refine: d => {
            setRoleAbility(d.roles?.[0]?.abilities ?? []);
            return {
                ..._.pick(d, _.keys(UserModel().fields).concat("id")),
                role: d.roles?.[0]?.name,
                venues: d.venues,
                web: hasAbility("web_access"),
                terminal: hasAbility("terminal_access"),
                can_receive_tips: !!d.can_receive_tips,
                tip_message: d.tip_message,
                media: d.media,
                images: [
                    {
                        // this is some hacky way to delete images before sending to backend
                        uuid: d.media?.[0]?.urls?.original,
                    },
                ],
            };
        },
    });
    const role: string = form.form.watch("role");
    const canReceiveTips = !!form.form.watch("can_receive_tips");
    const allAccessVenues = getVenuesList();

    const hasAbility = (a: string): boolean => {
        return !!roleAbilities.find(ab => ab.name === a);
    };

    useEffect(() => {
        form.form.setValue("web", hasAbility("web_access"));
        form.form.setValue("isCreate", !params?.id);
        form.form.setValue("terminal", hasAbility("terminal_access"));

        form.set("prepareForSubmit", (d?: any) => {
            let postObj: any = {
                ..._.pick(d, _.keys(dummyUser())),
                salary: parseFloat(d.salary) || 0,
                can_receive_tips: d.can_receive_tips,
                tip_message: d.tip_message,
                images: d.images,
            };

            if (!hasAbility("web_access")) {
                delete postObj.email;
                delete postObj.password;
            }

            // todo
            // delete images if not changed
            // I have copied this code from terminal edit page and this should be changed
            if (postObj.images && !_.isEmpty(postObj.images)) {
                postObj = _.omit(postObj, isUrl(postObj.images[0].uuid) ? ["images"] : [""]);
            }

            if (postObj.image) {
                delete postObj.image;
            }

            return postObj;
        });

        if (allAccessVenues.length === 1) form.form.setValue("venues", [allAccessVenues[0].id]);
        // eslint-disable-next-line
    }, [roleAbilities]);

    return (
        <div className="tw-h-full tw-p-4">
            {useFormTitle(form.id, t("staff"))}
            <HeaderRight
                formSubmit={{
                    isLoading: form.isLoading,
                    onSave: form.onSave,
                }}
            />

            <CForm form={form.form} onSave={form.onSave}>
                <JsonView />
                <div className="mt-3 mb-5">
                    <UploaderFormGroup
                        name="images[0].uuid"
                        mediaDataName={"media[0]"}
                        label={t("image")}
                        compProps={{
                            action: "media",
                            multiple: false,
                            listType: "picture",
                        }}
                        aspectRatio={16 / 16}
                        removeMainKey={true}
                    />
                    <TextFormGroup name="username" errorPlacement="rightEnd" focus={true} />
                    <RemoteSelectFormGroup
                        name="role"
                        valueKey="name"
                        labelKey="title"
                        searchLocal={true}
                        isItemDisabled={d => d.name === "owner"}
                        compProps={{
                            disabled: role === "owner",
                            onSelect: (value: string, item: any) => {
                                form.form.setValue("role_id", item.id);
                                setRoleAbility(item.abilities);
                            },
                        }}
                        remote={{
                            url: "role",
                            with: ["abilities"],
                        }}
                        errorPlacement="rightEnd"
                    />
                    <TextFormGroup
                        name="salary"
                        errorPlacement="rightEnd"
                        focus={true}
                        type="number"
                        compProps={{ width: 300 }}
                    />

                    {hasAbility("web_access") ? (
                        <>
                            <TextFormGroup
                                name="email"
                                compProps={{ type: "email", disabled: role === "owner" }}
                                className={`${!hasAbility("web_access") && "d-none"}`}
                                errorPlacement="rightEnd"
                            />
                            <TextPostfixBtnFormGroup
                                name="password"
                                className={`${!hasAbility("web_access") && "d-none"}`}
                                errorPlacement="rightEnd"
                                postFix={<CIcon path={isPasswordVisible ? iEyeClosed : iEye} />}
                                compProps={{
                                    type: isPasswordVisible ? "text" : "password",
                                }}
                                controlWrapperProps={{ style: { width: 300 } }}
                                inputBtn={{
                                    onClick: () => setIsPasswordVisible(!isPasswordVisible),
                                    //TODO: check this
                                    // inside: true,
                                }}
                            />
                        </>
                    ) : null}
                    <MaskInputFormGroup
                        mask="9999"
                        name="pin"
                        errorPlacement="rightEnd"
                        className={`${!hasAbility("terminal_access") && "d-none"}`}
                        rules={{
                            deps: ["pin", "terminal"],
                        }}
                    />
                    <TextFormGroup
                        name="card"
                        errorPlacement="rightEnd"
                        // type=""
                        className={`${!hasAbility("terminal_access") && "d-none"}`}
                        rules={{
                            deps: ["pin", "terminal"],
                        }}
                    />
                    <TextFormGroup
                        name="barcode"
                        errorPlacement="rightEnd"
                        type="text"
                        className={`${!hasAbility("field_seller") && "d-none"}`}
                    />
                    <CInputPhoneFormGroup
                        name="mobile_number"
                        label={t("phone")}
                        className="d-inline-flex"
                        labelProps={{ className: "rs-form-group" }}
                        controlWrapperProps={{ style: { width: 300 } }}
                        compProps={
                            cloposPayModule ? ({ description: t("mobile_number_hasubscribed_clopos_pay") } as any) : {}
                        }
                    />
                    {params?.id && role !== "owner" ? (
                        <div className="rs-form-group d-flex align-items-center">
                            <ToggleFormGroup name="status" />
                        </div>
                    ) : null}
                    {allAccessVenues.length > 1 && role !== "owner" && (
                        <TagPickerFormGroup
                            label={t("venue")}
                            name="venues"
                            data={allAccessVenues}
                            errorPlacement="rightEnd"
                            compProps={{
                                placeholder: t("select_venue"),
                            }}
                        />
                    )}
                    <div className="rs-form-group tw-flex tw-items-start">
                        <ToggleFormGroup name="can_receive_tips" />

                        <Whisper
                            placement="right"
                            trigger="hover"
                            speaker={
                                <Tooltip>
                                    <TText tkey="tips_info" />
                                </Tooltip>
                            }
                        >
                            <div className={"tw-p-2"}>
                                <CIcon path={iInfoOutline} size={0.9} />
                            </div>
                        </Whisper>
                    </div>

                    {canReceiveTips && (
                        <TextAreaFormGroup
                            label={t("tip_message")}
                            style={{ wordWrap: "break-word" }}
                            compProps={{
                                style: { width: 350, height: 100 },
                                placeholder: t("tip_message_placeholder"),
                            }}
                            className="tw-mb-3 tw-mt-4"
                            name="tip_message"
                        />
                    )}
                </div>
            </CForm>
        </div>
    );
};

export default UserEditPage;
