import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { useEffect, useState } from "react";
import TabsView from "../../../components/TabsView";
import StockDetails from "./StockDetails";
import StockGeneral from "./StockGeneral";
import StockHistory from "./StockHistory";

interface IProps extends ICDrawerProps {
    op?: IProduct;
    title: string;
    activeStorages: number[];
}

const StockDrawer: FCC<IProps> = props => {
    const [tabs, setTabs] = useState([] as any);

    useEffect(() => {
        setTabs([
            {
                label: t("general"),
                Component: StockGeneral,
                props: {
                    activeStorages: props.activeStorages,
                    op: props.op,
                },
            },
            {
                label: t("history"),
                Component: StockHistory,
                props: { dataId: props.dataId, unit: props.op?.unit },
            },
            {
                label: `${t("details")}`,
                Component: StockDetails,
                props: {
                    ...props,
                },
            },
        ]);
        // eslint-disable-next-line
    }, [props.op]);

    return (
        <CDrawer {...props} size="md">
            <div className="">
                <TabsView tabs={tabs} />
            </div>
        </CDrawer>
    );
};

export default StockDrawer;
