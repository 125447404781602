import { t } from "lib/i18n";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import { useEffect } from "react";
import TextFormGroup from "components/Form/TextFormGroup";
import TagPickerFormGroup from "components/Form/TagPickerFormGroup";
import { useData } from "hooks/useData";
import { UseSettingsFormReturnType } from "../useSettingsForm";

interface IProps {
    form: UseSettingsFormReturnType;
}

const ProductReturnSettingsTab: FCC<IProps> = props => {
    const { setValue, getValues } = props.form.form;
    const storages = useData<IStorage[]>("storage");

    useEffect(() => {
        if (storages.data) {
            const availableStorage = getValues("returns_storage.value")?.filter((id: number) =>
                storages.data?.find(storage => id === storage.id),
            );
            setValue("returns_storage.value", availableStorage);
        }
        // eslint-disable-next-line
    }, [storages.data?.length]);

    return (
        <div className="p-3">
            <ToggleFormGroup name="returns_enable.value" label={t("product_return_setting")} />
            <ToggleFormGroup name="returns_discount_enable.value" label={t("discounted_receipt_return")} />
            <TextFormGroup type="number" name="returns_max_age.value" label={t("product_return_date_range")} />
            <TagPickerFormGroup
                name="returns_storage.value"
                data={storages.data ?? []}
                compProps={{
                    placeholder: t("select", { name: t("storage") }),
                }}
            />
        </div>
    );
};

export default ProductReturnSettingsTab;
