import Widget from "components/Widget";

import { t } from "lib/i18n";
import { calcDiffPercentage } from "lib/chart-utils";
import _ from "lodash";
import TabDataDisplay from "pages/statistics/sales/TabDataDisplay";
import { FC } from "react";
import { ArrowDownHollow, ArrowUpHollow } from "assets/icons/Icons";
import CIcon from "../../components/CIcon";
import Percentage from "../../components/mini/Percentage";
import { tabs, tabs2 } from "./SaleSection";

interface IProps {
    tab: (typeof tabs)[number] | (typeof tabs2)[number];
    data: any[];
}

const SaleSectionWidget: FC<IProps> = ({ tab, data }) => {
    const name = tab[0];
    const transKey = tab[1];
    const today = _.last(data);
    const yesterday = _.head(data);
    const diff = calcDiffPercentage(today[name], yesterday[name]);
    const getIconProps = (val: number): any => {
        return val < 0
            ? { path: ArrowDownHollow, size: 0.6, style: { color: "red" } }
            : { path: ArrowUpHollow, size: 0.6, style: { color: "green" } };
    };
    return (
        <div key={name} className="tw-whitespace-nowrap tw-mr-2">
            <Widget>
                <div className="text-center">
                    {t(transKey)} <br />
                    <span style={{ fontSize: 20, fontWeight: 500 }}>
                        <TabDataDisplay name={name} value={today[name]} />
                    </span>
                </div>
                <hr />
                <div className="d-flex justify-content-between">
                    <div>
                        <TabDataDisplay name={name} value={yesterday[name]} />
                    </div>
                    <div>
                        {diff !== 0 ? <CIcon {...getIconProps(diff)} className="pr-1" /> : null}
                        <Percentage val={diff} />
                    </div>
                </div>
            </Widget>
        </div>
    );
};

export default SaleSectionWidget;
