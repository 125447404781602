import { useTitle } from "components/Header/Title";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import VenueSaleSection from "./VenueSaleSection";
import React, { useEffect } from "react";
import { FORMAT_ISO_DATE, formatWithCustomFormat } from "config/dates";
import "./VenueDashboard.scss";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { getPreviousRange } from "lib/chart-utils";
import _ from "lodash";
import { getFormatedDate } from "lib/utils";
import { passCashRestartCheck } from "lib/passCashRestartCheck";
import VenueProductivity from "./VenueProductivity";
import { VenueDashboardDate } from "./VenueDashboardDate";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import {
    setDashboardCategories,
    setDashboardDate,
    setDashboardDateRanges,
    setDashboardDates,
} from "redux/features/venue-dashboard";
import { format } from "date-fns";
import { useSetting } from "hooks/useSetting";

const VenueDashboardPage: FCC = () => {
    const auth = useAuth();
    const dispatch = useAppDispatch();
    const { dateRange, date } = useAppSelector(state => state.venueDashboard);

    const cashRestart = useSetting("cash_restart")?.value;

    useEffect(() => {
        const today = passCashRestartCheck(cashRestart);

        if (today) {
            dispatch(setDashboardDate(today.getTime()));
            dispatch(setDashboardDateRanges([format(today, FORMAT_ISO_DATE), format(today, FORMAT_ISO_DATE)]));
            dispatch(setDashboardDates([format(today, FORMAT_ISO_DATE)]));
            dispatch(setDashboardCategories([getFormatedDate(today)]));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cashRestart]);

    const _refineList = (arr: Array<IVenueDashboard>) => {
        if (!arr) return [];

        return arr.map((data: IVenueDashboard) => {
            const _report: any = {
                average_time: 0,
                shift_date: "_report",
                total_cash: 0,
                total_profit: 0,
                total_cost: 0,
                total_discount: 0,
                total_guests: 0,
                total_receipt: 0,
                total_tax: 0,
                venue_id: 0,
            };

            if (data.report.length) {
                data.report.forEach((report: IVenueDashboardReport) => {
                    _report.total_profit += report.profit ?? 0;
                    _report.average_time += report.average_time ?? 0;
                    _report.total_cash += report.total_cash ?? 0;
                    _report.total_cost += report.total_cost ?? 0;
                    _report.total_discount += report.total_discount ?? 0;
                    _report.total_guests += report.total_guests ?? 0;
                    _report.total_receipt += report.total_receipt ?? 0;
                    _report.total_tax += report.total_tax ?? 0;
                    _report.venue_id = report.venue_id;
                });
            } else {
                Object.assign(_report, data.report, { shift_date: formatWithCustomFormat(new Date()) });
            }

            data.report = _report;
            return Object.assign(_report, { id: data.id, name: data.name });
        });
    };

    const dp = useDataProvider({
        url: "reports/venues",
        useUrl: true,
        params: {
            date: [format(date, FORMAT_ISO_DATE), format(date, FORMAT_ISO_DATE)],
        },
        refineList: (arr: Array<IVenueDashboard>) => _refineList(arr),
    });

    const dpDate = dp.params.getValue("date");
    const _dateRange = dpDate ? getPreviousRange(dpDate) : undefined;

    const dpOld = useDataProvider({
        url: "reports/venues",
        params: { date: _dateRange },
        stop: _.isNil(dpDate),
        refineList: (arr: Array<IVenueDashboard>) => _refineList(arr),
    });

    useEffect(() => {
        if (dp) {
            dp.params.add("date", dateRange);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateRange]);

    return (
        <div className="h-100">
            {useTitle("Dashboard")}

            {auth.hasAbility(["report_sell"]) ? (
                <>
                    <div className="general-dashboard">
                        <VenueDashboardDate />
                    </div>
                    <VenueProductivity data={dp.data} originalData={dp.originalData} />
                    <VenueSaleSection dp={dp} dpOld={dpOld} />
                </>
            ) : null}
        </div>
    );
};

export default VenueDashboardPage;
