import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import CheckListFilter from "components/DataTable/Filters/CheckListFilter";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { getLabel } from "components/Form/form-utils";
import { ProductType } from "config/constants";
import React from "react";
import { Colors } from "config/colors";
import { IDataProvider } from "components/DataTable/useDataProvider";

interface Props extends ICDrawerProps {
    dp: IDataProvider;
}

const ReturnReportFilters = (props: Props) => {
    return (
        <CDrawer size="xs" title={t("filters")} {...props}>
            <div className="d-flex flex-column justify-content-between" style={{ height: "89vh" }}>
                <div className="px-3 pt-3 clopos-scroll" style={{ overflowY: "auto" }}>
                    <FiltersContainer hasPadding={true} dp={props.dp}>
                        {/*<label style={labelColStyle}><TText key="terminals" /></label>*/}
                        {/*<LocalCheckListFilter*/}
                        {/*    fields="terminal_id"*/}
                        {/*    url="terminal"*/}
                        {/*    pickerProps={{ placeholder: t("terminals") }}*/}
                        {/*    width={450}*/}
                        {/*    maxWidth={450}*/}
                        {/*/>*/}
                        {/*<label style={labelColStyle}><TText key="staff" /></label>*/}
                        {/*<RemoteCheckListFilter*/}
                        {/*    fields="owner_id"*/}
                        {/*    remote={{*/}
                        {/*        url: "user",*/}
                        {/*        refineList: (d: any[]) => d?.filter(user => user.owner || !user.hide),*/}
                        {/*    }}*/}
                        {/*    labelField="username"*/}
                        {/*    searchField="username"*/}
                        {/*    pickerProps={{ placeholder: t("staff") }}*/}
                        {/*    width={450}*/}
                        {/*    maxWidth={450}*/}
                        {/*/>*/}
                        <label style={labelColStyle}>
                            <TText tkey="product_type" />
                        </label>
                        <CheckListFilter
                            pickerProps={{ searchable: false }}
                            options={[
                                { label: getLabel(ProductType.GOODS), value: ProductType.GOODS },
                                { label: getLabel(ProductType.DISH), value: ProductType.DISH },
                                { label: getLabel(ProductType.MODIFICATION), value: ProductType.MODIFICATION },
                                { label: getLabel(ProductType.TIMER), value: ProductType.TIMER },
                            ]}
                            fields="type"
                            noBindModGood
                            width={450}
                            maxWidth={450}
                        />
                    </FiltersContainer>
                </div>
            </div>
        </CDrawer>
    );
};

export default ReturnReportFilters;

const labelColStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
    marginTop: "24px",
};
