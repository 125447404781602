import { UseFormReturnType } from "hooks/useCForm";
import Ajax from "../../lib/Ajax";
import { ProductType } from "config/constants";
import { BaseSyntheticEvent } from "react";

interface IProps {
    form: UseFormReturnType;
    existGoodModal: any;
    type?: ProductType;
    isSaveCreate?: boolean;
    event: BaseSyntheticEvent;
}

export const onSubmitCheckName = async ({ form, existGoodModal, type, isSaveCreate, event }: IProps) => {
    try {
        const name = form.form.getValues("name");
        if (!name.trim()) {
            form.form.trigger("name", { shouldFocus: true });
            return;
        }
        // if (!form.id) {
        const { data } = await Ajax.post({
            url: "product/check-name",
            data: {
                name: name,
                category_id: form.form.getValues("category_id"),
                type,
                ignore: form?.id,
            },
        });
        if (data.exists) {
            // save the isSaveCreate value to be used in the onConfirmCreateExists function
            existGoodModal.setData(isSaveCreate);
            existGoodModal.setIsOpen(true);
        } else {
            await form.onSave(event);
        }
    } catch (e) {
        /* empty */
    }
};

export const onConfirmCreateExists = async ({ form, existGoodModal, event }: IProps) => {
    await form.onSave(event, existGoodModal.data);
    existGoodModal.setIsOpen(false);
};
