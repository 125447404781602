import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { memo } from "react";
import _ from "lodash";
import { useFieldArray, useFormContext } from "react-hook-form";
import { Button, ButtonToolbar } from "rsuite";
import ItemsHeaderRow from "./ItemsHeaderRow";
import { toFixed } from "lib/utils";
import CIcon from "../../../components/CIcon";
import { iPlus } from "assets/icons/Icons";
import CMultiSelectBeta from "../../../components/CMultiSelectBeta";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Colors } from "config/colors";

export interface ItemsTabProps {
    ItemComponent: FCC<any>;
    itemProps?: any;
    dummyData: () => any;
    columns: [string, number][];
    itemsFieldName?: string;
    buttonToolbarClassName?: string;
    buttonClassName?: string;
    buttonTitle?: string;
    className?: string;
    isDraggable?: boolean;
    isModificator?: boolean;
    massInsertProps?: {
        itemRefine?: (d: any) => any; // Since mass insert returns IProduct, this refined IProduct with correct keys and values.
        idField?: string;
    };
    total?: boolean;
}

/**
 * @deprecated use FormItems instead
 * @param param0
 * @returns
 */
export const ItemsTabPure: FCC<ItemsTabProps> = ({
    isModificator,
    isDraggable,
    dummyData,
    ItemComponent,
    itemsFieldName = "items",
    columns,
    total,
    ...props
}) => {
    const { control, setValue, watch, getValues } = useFormContext();
    const { append, remove, fields } = useFieldArray({ control, name: itemsFieldName, keyName: "xxid" });
    const key = props.massInsertProps?.idField ?? "product_id";

    const items = watch(itemsFieldName, fields) as any[];
    const values = getValues(itemsFieldName) ?? [];

    const buttonToolbarClassName = props.buttonToolbarClassName ?? "";
    const buttonClassName = props.buttonClassName ?? "";

    const handleDragAndDrop = (result: DropResult) => {
        if (isDraggable) {
            const { source, destination } = result;

            if (!destination) {
                return;
            }
            if (source.index === destination.index) {
                return;
            }

            const _currentValues = _.cloneDeep(values);
            const startIndex = source.index,
                endIndex = destination.index;
            const [removed] = _currentValues.splice(startIndex, 1);
            _currentValues.splice(endIndex, 0, removed);
            // _currentValues.forEach((item: any, index: number) => {
            //     if (index >= Math.min(startIndex, endIndex) && index <= Math.max(startIndex, endIndex)) {
            //         item.position = index;
            //     }
            // });
            setValue(itemsFieldName, _currentValues);
        }
    };

    const appendModalData = (data: any[]) => {
        const addedItems = data.map(item => props.massInsertProps?.itemRefine?.(item) ?? item);
        append(addedItems);

        const hasField = items.map((a, i) => (!a[key] ? i : null)).filter(_.isNumber);
        remove(hasField);
    };

    const totalWeightCalculate = () => {
        return toFixed(
            values.reduce((acc: number, item: any) => acc + (item.gross || 0) * (item.unit_weight || 0), 0),
            4,
        );
    };

    return (
        <div className={props.className ?? "py-3 px-1"}>
            <ItemsHeaderRow columns={columns} />

            <DragDropContext onDragEnd={handleDragAndDrop}>
                <Droppable droppableId="receipt">
                    {provided => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                            {values.map((field: any, i: number) => {
                                const key = fields[i]?.xxid ?? field.id;

                                let onRemove = remove;
                                if (isModificator && getValues(`modifications[${i}].id`)) {
                                    onRemove = () => setValue(`modifications[${i}].deleted_at`, Date.now());
                                }
                                return (
                                    <Draggable
                                        key={key + "_" + i}
                                        isDragDisabled={!isDraggable}
                                        draggableId={key + "_" + i.toString()}
                                        index={i}
                                    >
                                        {provided => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <ItemComponent
                                                    itemsFieldName={itemsFieldName}
                                                    isDraggable={isDraggable}
                                                    field={field}
                                                    index={i}
                                                    key={key}
                                                    size={values.filter((a: any) => !a.deleted_at).length}
                                                    onRemove={onRemove}
                                                    {...props.itemProps}
                                                />
                                            </div>
                                        )}
                                    </Draggable>
                                );
                            })}
                            {provided.placeholder}
                        </div>
                    )}
                </Droppable>
            </DragDropContext>

            <div className={`pt-3 d-flex`}>
                <ButtonToolbar className={buttonToolbarClassName}>
                    {props.massInsertProps && <CMultiSelectBeta onSave={appendModalData} />}
                    <Button
                        className={"mr-2 " + buttonClassName}
                        color="cyan"
                        appearance="primary"
                        test-id="add-product-button"
                        onClick={() => {
                            append(dummyData());
                        }}
                    >
                        <CIcon path={iPlus} className="mr-2" />
                        {props.buttonTitle ?? t("add")}
                    </Button>
                </ButtonToolbar>
                <div className="d-flex text-right">
                    {total && (
                        <div style={{ width: "305px" }}>
                            <div>
                                <p className="tw-text-[#98a2b3]">
                                    <TText tkey="total" />:
                                </p>
                            </div>
                            <div style={{ color: Colors.SteelGray, fontSize: "16px" }} className="total-weight">
                                {totalWeightCalculate() + " " + t("kg_short")}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default memo(ItemsTabPure);
