import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import React, { useMemo } from "react";
import _ from "lodash";
import { useDrawer } from "components/Drawers/useDrawer";
import ReceiptDrawer from "components/Drawers/ReceiptDrawer";
import { ReviewType } from "config/constants";
import CheckListFilter from "components/DataTable/Filters/CheckListFilter";
import { Button, Rate, Tooltip, Whisper } from "rsuite";
import { useData } from "hooks/useData";
import { exportFile } from "lib/export-table";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import CheckBoxFilter from "../../components/DataTable/Filters/CheckBoxFilter";
import { EmojiRate } from "./misc/EmojiRate";
import classNames from "classnames";
import { RowDataType } from "rsuite-table/src/@types/common";

type ratingDataType = {
    value: number;
    label: string;
}[];

const reviewTypeData = [
    { value: 1, label: t("receipt") },
    { value: 2, label: t("brand") },
];

const ReviewsPage: FCC = () => {
    const ratingData: ratingDataType = [
        { value: 1, label: t("rate_label_1") },
        { value: 2, label: t("rate_label_2") },
        { value: 3, label: t("rate_label_3") },
        { value: 4, label: t("rate_label_4") },
        { value: 5, label: t("rate_label_5") },
    ];

    const rd = useDrawer({ backdrop: false, overflowAuto: true });

    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        with: ["items", "receipt"],
        sort: ["created_at", "-1"],
        url: "reviews",
    });

    const totalStats = useData<any>("reviews/total", {
        params: {
            date: dp.params.all.date,
            items_type_delivery: dp.params.all.items_type_delivery,
            items_type_food: dp.params.all.items_type_food,
            items_type_service: dp.params.all.items_type_service,
        },
        filters: _.values(dp.filters.all),
    });

    const refineColumns = useMemo((): ITableColumnProps[] => {
        const fetchRate = (t: number, data: any) => {
            const tD = data.items.find((rates: any) => rates.type === t);
            return tD?.value || "--";
        };
        return [
            {
                key: "items.service",
                align: "left",
                title: t("service"),
                summary: () => totalStats?.data?.items[3] || 0,
                render: (d: any) => <EmojiRate rate={fetchRate(3, d)} ratingData={ratingData} />,
            },
            {
                key: "items.food",
                align: "left",
                title: t("food"),
                summary: () => totalStats?.data?.items[4] || 0,
                render: (d: any) => <EmojiRate rate={fetchRate(4, d)} ratingData={ratingData} />,
            },
            {
                key: "items.delivery",
                align: "left",
                title: t("delivery"),
                summary: () => totalStats?.data?.items[2] || 0,
                render: (d: any) => <EmojiRate rate={fetchRate(2, d)} ratingData={ratingData} />,
            },
        ];
        // eslint-disable-next-line
    }, [dp.data, totalStats]);

    const cm = useColumns<any>("reviews", [
        { key: "id", hide: true, align: "left", flexGrow: undefined, width: 40 },
        {
            title: t("type"),
            key: "type",
            align: "left",
            render: (d: any) =>
                d?.r_id || d?.receipt?.id ? (
                    <Button appearance="link" className="p-0" onClick={() => rd.setDataId(d?.r_id || d?.receipt?.id)}>
                        <TText tkey="receipt" /> #{d?.r_id || d?.receipt?.id}
                    </Button>
                ) : (
                    t("brand")
                ),
            flexGrow: undefined,
            width: 150,
        },
        { key: "full_name", flexGrow: undefined, width: 150, align: "left" },
        { key: "phone_number", flexGrow: undefined, width: 150, hide: true, align: "left", title: t("phone") },
        {
            key: "text",
            flexGrow: 3,
            hide: true,
            align: "left",
            title: t("description"),
            render: (d: any) =>
                d?.text ? (
                    <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>{d.text}</Tooltip>}>
                        <div className={"review-description-container"}>
                            <p className={"review-description-label"}>{d.text}</p>
                        </div>
                    </Whisper>
                ) : (
                    "--"
                ),
        },
        ...refineColumns,
        {
            key: "average",
            hide: true,
            align: "left",
            flexGrow: undefined,
            width: 175,
            title: t("average"),
            render: e => {
                return (
                    <Whisper placement="top" trigger="hover" speaker={<Tooltip>{e.average}</Tooltip>}>
                        <Rate readOnly={true} defaultValue={e.average} size="xs" allowHalf />
                    </Whisper>
                );
            },
            summary: () => totalStats?.data?.average || 0,
        },
        // { key: "ip", hide: true, align: "left", title: t("fiscal_ip_address") },
        { key: "created_at", hide: true, align: "left", flexGrow: 1.2, type: "datetime" },
        optionsColumn({
            dp,
            delete: "reviews/:id",
            canDelete: "review_delete",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("reviews"), dp.total)}
            <HeaderRight cm={cm} print reload dp={dp} date export={() => exportFile(dp, cm.columns, t("reviews"))} />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="receipt_id" placeholder={t("receipt_number")} />
                    <CheckListFilter
                        pickerProps={{ searchable: false, placeholder: t("type") }}
                        options={reviewTypeData}
                        fields={"type"}
                    />
                    <CheckListFilter
                        pickerProps={{ searchable: false, placeholder: t("service") }}
                        options={ratingData}
                        useParams
                        fields={`items_type_${ReviewType[3]}`}
                    />
                    <CheckListFilter
                        pickerProps={{ searchable: false, placeholder: t("food") }}
                        options={ratingData}
                        useParams
                        fields={`items_type_${ReviewType[4]}`}
                    />
                    <CheckListFilter
                        pickerProps={{ searchable: false, placeholder: t("delivery") }}
                        options={ratingData}
                        useParams
                        fields={`items_type_${ReviewType[2]}`}
                    />
                    <CheckBoxFilter useParams fields="trash" value="2" title={t("deleted_reviews_filter")} />
                </div>
            </FiltersContainer>

            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                    affixHeader: true,
                    rowClassName: (d?: RowDataType) =>
                        classNames({
                            "deleted-row": d && !!d?.deleted_at,
                        }),
                }}
            />

            <ReceiptDrawer {...rd} />
        </div>
    );
};

export default ReviewsPage;
