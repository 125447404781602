import { getLabel } from "components/Form/form-utils";
import React, { ReactNode } from "react";
import CIcon from "../../../../components/CIcon";

interface IProps {
    value: string | number | ReactNode;
    icon: any;
    name: string;
}

const Wallet: FCC<IProps> = ({ value, icon, name }) => {
    return (
        <div className="d-flex align-items-center">
            <div className={`wallet ${name}`}>
                <CIcon path={icon} size={2} />
            </div>
            <div className="pl-3">
                <h5 className="font-weight-normal tw-text-[#475f7b]">{value}</h5>
                <p>{getLabel(name)}</p>
            </div>
        </div>
    );
};

export default Wallet;
