import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useTitle } from "components/Header/Title";

import DataTable from "components/DataTable/DataTable";
import HeaderRight from "components/Header/HeaderRight";
import _ from "lodash";
import { getProductName, translateUnitName } from "lib/utils";
import { exportFile } from "lib/export-table";
import { useDrawer } from "components/Drawers/useDrawer";
import Money from "components/mini/Money";
import CellBtn from "components/DataTable/CellButton";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import MenuCategoryTreeFilter from "components/DataTable/Filters/CategoryTreeFilter/MenuCategoryTreeFilter";
import { CategoryTypeEnum, ProductType } from "config/constants";
import { productNameAppend } from "lib/data-utils";
import { useData } from "hooks/useData";
import { useLocation } from "react-router-dom";
import nrInRoutings from "./nr-in-routings";
import GoodsStatsDrawer from "../goods/drawer/GoodsStatsDrawer";
import { QuickLinks } from "components/Header/QuickLinks";
import React from "react";
import ReportFilters from "./components/ReportFilters";
import ToggleFilterDrawer from "components/DataTable/Filters/ToggleFilterDrawer";

const NewReportsProductPage: FCC = () => {
    const loc = useLocation();
    const url = "reports/product";
    // const brandType: string = selectSetting("brand_type")?.value;
    // const isRestaurant = brandType === BrandType.RESTAURANT;
    const rd = useDrawer({ backdrop: false, overflowAuto: true });
    const filter = useDrawer();

    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        url,
        with: ["category", "product.unit"],
        extraFilterKeys: [
            "receipt_id",
            "receipt_terminal_id",
            "receipt_table_id",
            "receipt_owner_id",
            "product_type",
            "sale_type_id",
            "accounting_category_id",
            "receipt_customer_id",
            "gift",
            "source",
        ],
    });

    const summary = useData<any>(url, {
        filters: _.values(dp.filters.all),
        params: {
            total: 1,
            date: dp.params.all.date,
            time: dp.params.all?.time,
        },
    });

    const cm = useColumns<any>("reports/product", [
        {
            title: "ID",
            key: "product_id",
            align: "left",
            flexGrow: 0.3,
        },
        {
            title: t("name"),
            key: "product.name",
            render: d => (
                // d?.product_type === "DISH" ? (
                <CellBtn label={getProductName(d?.product)} action={() => rd.setData(d?.product)} />
            ),
            // ) : (
            //     getProductName(d?.product)
            // ),
            align: "left",
            flexGrow: 2,
        },
        {
            title: t("type"),
            key: "product_type",
            render: d => _.capitalize(t(d.product_type?.toLowerCase())),
        },
        { title: t("category"), key: "category.name", width: 150 },
        {
            title: t("total_count"),
            key: "count",
            summary: () => summary.data?.[0]?.count,
            render: e => `${e.count} ${translateUnitName(e.product?.unit)}`,
        },
        {
            key: "gift_total_amount",
            hide: true,
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.gift_total_amount} />,
            minWidth: 120,
        },
        {
            key: "gift_total_count",
            hide: true,
            summary: () => summary.data?.[0]?.gift_total_count,
        },
        {
            title: t("price"),
            key: "average_price",
            type: "money",
            numberRoundingLength: 2,
        },
        {
            title: t("average_sale_price"),
            key: "average_sale_price",
            type: "money",
            numberRoundingLength: 2,
            minWidth: 140,
        },
        {
            title: t("total_discount"),
            key: "discount",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.discount} />,
            minWidth: 105,
        },
        {
            title: t("tax"),
            key: "tax",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.tax} />,
        },
        {
            title: t("total_cost"),
            key: "total_cost",
            type: "money",
            numberRoundingLength: 2,
            render: d => <Money value={d?.total_cost} />,
            summary: () => <Money value={summary.data?.[0]?.total_cost} />,
        },
        {
            title: t("total_cash"),
            key: "total",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.total} />,
        },
        {
            title: t("profit"),
            key: "profit",
            type: "money",
            render: (d: any) => <Money value={d.profit} />,
            summary: () => <Money value={summary.data?.[0]?.profit} />,
        },
        {
            title: t("cashback"),
            key: "loyalty_value",
            type: "money",
            render: (d: any) => <Money value={d.loyalty_value} />,
        },
    ]);
    return (
        <div className="h-100">
            {useTitle(t("products"), dp.total)}
            <QuickLinks inHeader={false} links={nrInRoutings(loc)} />

            <HeaderRight cm={cm} print export={() => exportFile(dp, cm.columns, t("products"))} reload date dp={dp} />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <RemoteCheckListFilter
                        fields="product_id"
                        searchField="name"
                        remote={{
                            url: "product",
                            sort: ["updated_at", "-1"],
                            params: {
                                trash: [2],
                            },
                            limit: 20,
                            refineList: (products?: IProduct[]) =>
                                products
                                    ?.map(i => productNameAppend(i))
                                    ?.filter(f => !(f.type === ProductType.GOODS && f.has_modifications)) ?? [],
                        }}
                        pickerProps={{ placeholder: t("product") }}
                    />
                    <MenuCategoryTreeFilter categoryTypes={[CategoryTypeEnum.PRODUCT]} />
                    <RemoteCheckListFilter
                        remote={{
                            url: "station",
                            refineList: (list: any[]) => {
                                list?.push({ id: null, name: t("without_station") });
                                return list;
                            },
                        }}
                        searchField="name"
                        fields="receipt_station_id"
                        pickerProps={{ placeholder: t("station") }}
                    />

                    <ToggleFilterDrawer
                        isOpen={filter.isOpen}
                        onChange={val => {
                            filter.setIsOpen(val);
                            rd.setIsOpen(false);
                        }}
                    />
                </div>
            </FiltersContainer>

            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
            <ReportFilters {...filter} dp={dp} />
            <GoodsStatsDrawer {...rd} />
        </div>
    );
};

export default NewReportsProductPage;
