import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";

import { Constants } from "config/constants";
import CreateButton from "../../../components/DataTable/CreateButton";
import React from "react";
import { RowDataType } from "rsuite-table/src/@types/common";

const FinanceCategoriesPage: FCC = props => {
    const types: Record<FinanceCategoryType, string> = Constants.financeTypes;
    const dp = useDataProvider({
        url: "finance/category",
        useUrl: true,
        sort: ["created_at", "1"],
    });
    //position:"absolute",top:"12px",left:"60px"
    const cm = useColumns<any>("finance/category", [
        {
            key: "id",
            align: "left",
            flexGrow: 0.4,
        },
        {
            key: "name",
            align: "left",
            flexGrow: 2,
        },
        {
            key: "type",
            render: (d: IFinanceCategory) => <div style={{ marginLeft: "0.9rem" }}>{types[d.type]}</div>,
        },
        optionsColumn({
            dp,
            isLocked: d => !d.editable,
            editable: d => !d.editable,
            preventDelete: d => !d.editable,
            edit: "/finance/categories/edit/:id",
            delete: "/finance/category/:id",
            canEdit: "finance_category_edit",
            canDelete: "finance_category_delete",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("categories"), dp.total)}
            <HeaderRight cm={cm} print reload dp={dp} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="name" />
                </div>
                <CreateButton to="/finance/categories/create" canCreate={"finance_category_create"} />
            </FiltersContainer>
            <div className="categories_db-container">
                <DataTable
                    dp={dp}
                    columns={cm.columns}
                    tableProps={{
                        isTree: true,
                        renderTreeToggle: (icon: React.ReactNode, rowData?: RowDataType) => {
                            return rowData?.children?.length && rowData?.children?.length > 0 ? (
                                <div test-id="toggler-button">{icon}</div>
                            ) : null;
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default FinanceCategoriesPage;
