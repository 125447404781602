import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import Ajax from "lib/Ajax";
import { Trash } from "config/constants";
import { convertToSlug } from "lib/convertToSlug";

// Generic state interface
export interface GenericDataState<T> {
    byId: { [key: string]: T };
    ids: string[];
    status: "idle" | "loading" | "succeeded" | "failed";
    error: string | null | undefined;
    lastUpdatedAt: string | null;
    lastId: number | null;
}

interface GenericAsyncThunkOptions {
    params?: any;
    headers?: any;
    slugifyKey?: string;
}

const gteColumnsMap: Record<string, { [key: string]: string }> = {
    units: {
        column: "id",
        lastUpdateColumn: "lastId",
    },
};

// Generic async thunk creator
function createGenericAsyncThunk<T>(type: string, endpoint: string, options?: GenericAsyncThunkOptions) {
    return createAsyncThunk(`${type}/fetchData`, async (arg: any, { getState }): Promise<IApiResponse<T[]>> => {
        const state = getState() as any;

        const lastUpdatedAt = state.model[type]?.[gteColumnsMap[type]?.["lastUpdateColumn"] ?? "lastUpdatedAt"];
        // const response = await fetch(`${endpoint}?since=${lastUpdatedAt}`);
        const url = Ajax.buildUrl({
            url: endpoint,
            params: {
                limit: 999,
                page: 1,
                filters: lastUpdatedAt
                    ? [[gteColumnsMap?.[type]?.["column"] ?? "updated_at", ">=", lastUpdatedAt]]
                    : undefined,
                ...options?.params,
                ...(lastUpdatedAt ? { trash: Trash.TrashAndActive } : {}),
            },
        });
        return await Ajax.get<any>({ url, headers: options?.headers });
    });
}

// Helper function to normalize data
function normalizeData<T extends { id: any }>(data: T[], state: GenericDataState<T>, slugifyKey: string) {
    const byId: { [key: string]: T } = state.byId ?? {};
    const ids: string[] = state.ids ?? [];

    data.forEach((item: any) => {
        item.slug = convertToSlug(item?.[slugifyKey]);

        byId[item.id] = item;
        const itemIndex = ids.findIndex(id => id === item.id.toString());
        if (itemIndex > -1) {
            if (item.deleted_at) {
                ids.splice(itemIndex, 1);
            }
        } else {
            ids.push(item.id.toString());
        }
    });

    return { byId, ids };
}

// Generic slice creator
export function createGenericSlice<T>(name: string, endpoint: string, options?: GenericAsyncThunkOptions) {
    const fetchData = createGenericAsyncThunk<T>(name, endpoint, options);

    const slice = createSlice({
        name,
        initialState: {
            byId: {},
            ids: [],
            status: "idle",
            error: null,
            lastUpdatedAt: null,
            lastId: null,
        } as GenericDataState<T>,
        reducers: {},
        extraReducers: builder => {
            builder
                .addCase(fetchData.pending, state => {
                    state.status = "loading";
                })
                .addCase(fetchData.fulfilled, (state, action: PayloadAction<IApiResponse<T[]>>) => {
                    const { byId, ids } = normalizeData(
                        action.payload.data as any[],
                        state,
                        options?.slugifyKey ?? "name",
                    );
                    state.byId = byId as any;
                    state.ids = ids;
                    state.lastUpdatedAt = action.payload.timestamp;
                    state.lastId = Number(ids[ids.length - 1]);
                    state.status = "succeeded";
                })
                .addCase(fetchData.rejected, (state, action) => {
                    state.status = "failed";
                    state.error = action.error.message;
                });
        },
    });

    return { reducer: slice.reducer, fetchData };
}
