import { t } from "lib/i18n";
import Ajax from "./Ajax";
import { IDataProvider } from "components/DataTable/useDataProvider";
import * as Sentry from "@sentry/browser";
import { getLabel } from "components/Form/form-utils";

export async function downloadGoodsMenuExcel() {
    try {
        await Ajax.download({ url: "product/export/for-use" }, `${t("products_with_modification")}`);
    } catch (e) {
        Sentry.captureException(e);
    }
}

export async function exportFile(db: IDataProvider & any, columns: ITableColumnProps[] | any, title: string) {
    const url = db.urlWithId || db.url;
    const params = db.urlParams;
    // const exportParam = ["report/deleted/product",
    //     "report/cash/product", "report/cash/category", "report/cash/table",
    //     "report/cash/station", "report/cash/user",
    //     "report/cash/customer"].includes(url);

    //col.title ?? getLabel(col.key as string)
    params.exports = columns
        .filter((c: any) => c.key !== "options")
        .map((a: any) => ({ [a.title || getLabel(a.key as string)]: a.key }));
    const result: any = {};

    params.exports.forEach((obj: any) => {
        const key = Object.keys(obj)[0];
        result[key] = obj[key];
    });

    params.exports = btoa(unescape(encodeURIComponent(JSON.stringify(result))));
    params.limit = 999;

    // let exportUrl = `${url}/${db.urlWithId ? "inventoryCheckExport" : "export"}`;
    let filename = title.toLowerCase();

    if (params.date) {
        filename += `-${params.date[0]}-${params.date[1]}`;
    }

    // if (url === "stock/product") {
    //     exportUrl = "stock/export";
    // } else if (exportParam) {
    //     exportUrl = `${url}`;
    //     params.export = `${filename}.xls`;
    // }

    try {
        await Ajax.download({ url: url, params }, `${filename}`);
    } catch (e) {
        //log.runtimeError(e);
        Sentry.captureException(e);
    }
}
