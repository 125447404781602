import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import ReceiptDrawer from "components/Drawers/ReceiptDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import Money from "components/mini/Money";
import { EMPTY_DATA } from "config/main";
import { useTableHeight } from "hooks/useTableHeight";
import TransactionReference from "pages/finance/transactions/TransactionReference";

import StockOperationsDrawer from "../misc/StockOperationsDrawer";
import classNames from "classnames";
import _ from "lodash";
import WhisperWithoutIcon from "../../../components/mini/WhisperWithoutIcon";
import ReturnedSuppliesProductsDrawer from "../misc/ReturnedSuppliesProductsDrawer";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { RowDataType } from "rsuite-table/src/@types/common";

interface IProps {
    supplier: any;
    isSupplier?: boolean;
    summary?: any;
    showUnpaidAmount?: boolean;
    summaryFake?: any;
    returned?: any;
}

const renderAmount = (d: ITransaction) => {
    return <Money value={d.amount} />;
};

const TransactionDetails: FCC<IProps> = ({ returned, supplier, isSupplier }) => {
    const auth = useAuth();
    const suppliesReturnsDrawer = useDrawer();
    const rd = useDrawer();
    const sd = useDrawer();
    const cm = useColumns<any>(`transaction-details`, [
        { title: t("data_id"), key: "id", width: 60, align: "right", flexGrow: undefined },
        {
            key: "operated_at",
            type: "datetime",
            align: "center",
            reverse: true,
            minWidth: 135,
            title: t("operation"),
        },
        {
            key: "reference",
            render: d => (
                <TransactionReference
                    isInDrawer={true}
                    transaction={d}
                    showReceipt={rd.setDataId}
                    noReturnSupplierName
                    showSupply={(op: IOperation) => {
                        sd.setData(op);
                        sd.setDataId(op.id);
                    }}
                    showReturns={suppliesReturnsDrawer.setDataId}
                />
            ),
        },
        {
            key: "category_id",
            title: t("operation_type"),
            flexGrow: undefined,
            width: 120,
            render: d => (d.returns_id ? t("product_return_setting") : d?.supplier ? t("total_supply") : "---"),
        },
        {
            key: "amount",
            type: "money",
            title: t("transaction_value"),
            flexGrow: undefined,
            render: (d: ITransaction) => {
                if (isSupplier) {
                    if (!d.operation_id) {
                        return <Money value={0} />;
                    }
                } else if (!d.receipt_id) {
                    return <Money value={0} />;
                }
                return renderAmount(d);
            },
            summary: () => (
                <Money
                    value={_.sumBy(
                        supplier.data?.filter((l: any) => (isSupplier && l.operation_id) || l.receipt_id),
                        (d?: any) => d.amount,
                    )}
                />
            ),
        },
        {
            key: "amount",
            type: "money",
            title: t("payment"),
            flexGrow: undefined,
            width: 130,
            render: (d: ITransaction) => {
                if (!d.group) {
                    return <Money value={0} />;
                }
                return renderAmount(d);
            },
            summary: () => (
                <Money
                    value={_.sumBy(
                        supplier.data?.filter((l: any) => l.group),
                        (d?: any) => d.amount,
                    )}
                />
            ),
        },
        // {
        //     key: "before_amount",
        //     type: "money",
        //     flexGrow: undefined,
        //     render: (d: ITransaction) => <Money colored value={d.before_amount}/>
        // },
        {
            key: "after_amount",
            type: "money",
            flexGrow: undefined,
            render: (d: ITransaction) => <Money colored value={d.after_amount} />,
        },

        { key: "description", render: d => <WhisperWithoutIcon description={d.description} /> },
        {
            key: isSupplier ? "from_balance.name" : "balance.name",
            title: isSupplier ? t("from_account") : t("to_account"),
            render: (d: ITransaction) =>
                isSupplier
                    ? d.type === "OUT"
                        ? d.balance?.name
                        : d.from_balance?.name
                    : d.type === "OUT"
                      ? EMPTY_DATA
                      : d.balance?.name,
        },
        { key: "user.username", title: t("transaction_by") },
    ]);

    const height = useTableHeight(185); //  simple table height calculator. Sorry bashqa yol tapmadim :(

    return (
        <div className="d-flex justify-content-center align-items-center">
            {auth.hasAbility(["finance_transaction_view"]) ? (
                <>
                    {" "}
                    <div className="flex-fill border-left">
                        <ReceiptDrawer {...rd} />
                        <StockOperationsDrawer
                            {...sd}
                            title={t("total_supply") + " #" + sd.dataId}
                            cols={["product.name", "quantity", "cost", "total_cost"]}
                        />
                        <DataTable
                            noShadow
                            columns={cm.columns}
                            dp={{
                                ...supplier,
                                data: _.orderBy(
                                    supplier.data?.concat(returned?.data || []) || [],
                                    // ["operated_at"], ["desc"]
                                    [supplier.urlParams.sort![0] ?? "operated_at"],
                                    [supplier.urlParams.sort![1] === 1 ? "asc" : "desc"],
                                ),
                            }}
                            tableProps={{
                                headerHeight: 50,
                                autoHeight: false,
                                height: height,
                                rowClassName: (d?: RowDataType) =>
                                    classNames({
                                        "deleted-row": d && !!d?.deleted_at,
                                    }),
                            }}
                        />
                    </div>
                    <ReturnedSuppliesProductsDrawer {...suppliesReturnsDrawer} />
                </>
            ) : (
                <div style={{ marginTop: "25%" }}>
                    <TText tkey="access_denied" />
                </div>
            )}
        </div>
    );
};

export default TransactionDetails;
