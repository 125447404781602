import { I18N, t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import classNames from "classnames";
import CheckBoxFilter from "components/DataTable/Filters/CheckBoxFilter";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SelectFilter from "components/DataTable/Filters/SelectFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import Money from "components/mini/Money";
import { FinanceAccountDefaultFilters } from "config/constants";
import { useData } from "hooks/useData";
import _ from "lodash";
import ReactDataSheet from "react-datasheet";
import CashflowDrawer from "./CashflowDrawer";
import { getReportDates, getReportsByType } from "./utils";
import RemoteSelectFilter from "components/DataTable/Filters/RemoteSelectFilter";
import { toFixed } from "lib/utils";
import { Tooltip, Whisper } from "rsuite";
import CIcon from "components/CIcon";
import { ArrowDownHollow } from "assets/icons/Icons";
import { useRef } from "react";
import { format } from "date-fns";
import { getDateFNSLocale } from "../../../lib/getDateFNSLocale";

const transferCategories: number[] = [
    3, // Transfer
    29, // Cash Collection
];

const excludedIds = [
    16, // PAY_DEBT
    17, // ADD_FUND_CUSTOMER
    20, // OUT_FUND_CUSTOMER
];

const Formats = {
    MONTH: "LLLL yyyy",
    YEAR: "yyyy",
    DAY: "LLLL dd",
} as const;

type PeriodType = keyof typeof Formats;

const periodLabel = (date: string, period: PeriodType) => {
    const formatter = Formats[period];

    return _.capitalize(
        format(new Date(date), formatter, {
            locale: getDateFNSLocale(I18N.localLang),
        }),
    );
};

const refineData = (dp: any, totals: ICashflowTotal[]) => {
    if (!dp.data) {
        return [];
    }

    const diffTotals: ICashflowTotal[] = [];

    /*
     * Remove excluded categories from the data
     * Jira: https://clopos.atlassian.net/browse/CL-1331
     */
    dp.data = dp.data.filter((item: ICashflowItem) => !excludedIds.includes(item.id));

    if (dp.filters.getValue("report")) {
        dp.data = dp.data.filter(
            (d: ICashflowItem) =>
                !_.isEmpty(
                    d.reports &&
                        (d.reports as ICashflowReport[]).filter(
                            (r: ICashflowReport) =>
                                r.total_diff || (r.total_diff === 0 && transferCategories.includes(r.category_id!)),
                        ),
                ),
        );
    }

    totals?.forEach(total => {
        const newTotal = {
            ...total,
            total_final: parseFloat(toFixed(total.total_in - total.total_out, 2)),
        };
        diffTotals.push(newTotal);
    });

    const dates: string[] = getReportDates(dp.data) ?? [];
    const period = dp.params.getValue("period") as PeriodType;

    return [
        [
            { value: t("category"), className: "sticky-td tw-bg-[#ffffff]" },
            ...dates.map(date => ({
                value: periodLabel(date, period),
                className: "sticky-top tw-bg-[#ffffff] " + periodLabel(date, period),
            })),
        ],
        ...getReportsByType(dp.data, dates, "out", totals),
        ...getReportsByType(dp.data, dates, "in", totals),
        ...getReportsByType(dp.data, dates, "final", diffTotals),
        ...getReportsByType(dp.data, dates, "fake"),
    ];
};

const CashflowPage: FCC = () => {
    const url = "report/finance/date";
    const dp = useDataProvider({
        url,
        useUrl: true,
        useDates: true,
        localSort: [["_lft"], ["asc"]],
        params: {
            period: "MONTH",
        },
    });

    const idFilter = dp.filters.getValue("id");
    const totalRemoveTransferFilter = idFilter
        ? {
              category_id: ["category_id", "!=", idFilter],
              ..._.omit(dp.filters.all, ["id"]),
          }
        : dp.filters.all;

    const totals = useData<any>("report/finance/total", {
        params: dp.params.all,
        filters: totalRemoveTransferFilter,
    });

    const data = refineData(dp, totals.data);
    const sd = useDrawer();

    const headerRef: any = useRef(null);

    const reportDetails = (cell: any, col: number) => {
        if (cell.category_id) {
            const fakeCats = [17, 14, 13, 12];
            const convertOutToIn = [21]; // this is because type does not define the requested data
            const convertInToOut = [22]; // this is because type does not define the requested data

            const columLentgh = data[0].length - 1;

            Object.assign(cell, {
                type: convertOutToIn.includes(cell.category_id)
                    ? "in"
                    : convertInToOut.includes(cell.category_id)
                      ? "out"
                      : cell.type,
                indicator: !_.isArray(cell.category_id) && fakeCats.includes(cell.category_id) ? 1 : 0,
                period: dp.params.getValue("period"),
                start: dp.params.getValue("date")[0],
                end: dp.params.getValue("date")[1],
                balance: dp.filters.getFilter("balance_id"),
                firstColumn: col === 1,
                lastColumn: col === columLentgh,
                oneColumn: columLentgh === 1,
            });
            sd.setData(cell);
        }
    };

    const headerHeight = headerRef.current?.nextSibling?.clientHeight;

    return (
        <div className="flex-column " style={{ height: "calc(100vh - 76px)" }}>
            {useTitle(t("cashflow"))}
            <div ref={headerRef}>
                <HeaderRight dp={dp} reload date />
            </div>
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <RemoteSelectFilter
                        fields="balance_id"
                        remote={{
                            url: "finance/balance",
                            filters: FinanceAccountDefaultFilters,
                        }}
                    />
                    <SelectFilter
                        pickerProps={{ searchable: false }}
                        fields="period"
                        fieldType="params"
                        cleanable={false}
                        options={[
                            { label: t("day"), value: "DAY" },
                            { label: t("month"), value: "MONTH" },
                            { label: t("year"), value: "YEAR" },
                        ]}
                    />
                    <CheckBoxFilter fields="report" title={t("hide_empty_reports")} value="empty" />
                    <CheckBoxFilter
                        fields="id"
                        filterSymbol={"!="}
                        value={transferCategories}
                        title={t("hide_transfers")}
                    />
                </div>
            </FiltersContainer>
            <CashflowDrawer {...sd} />
            <div
                className="shadow bg-white mb-3 rounded p-3 view position-relative tw-flex-1"
                style={{ zIndex: 0, height: `calc(100% - ${headerHeight}px)` }}
            >
                <div className="wrapper clopos-scroll tw-relative">
                    <ReactDataSheet
                        overflow="clip"
                        data={data as any[][]}
                        valueRenderer={(cell: any) => cell.value}
                        cellRenderer={(props: any) => {
                            const _props = {
                                ...props,
                                onDoubleClick: () => reportDetails(props.cell, props.col),
                            };
                            return <CellRenderer {..._props} />;
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default CashflowPage;

const CellRenderer: FCC<ReactDataSheet.CellRendererProps<any>> = ({ cell, col, row, className, ...props }) => {
    return (
        <td
            onMouseDown={props.onMouseDown}
            onMouseOver={props.onMouseOver}
            onDoubleClick={props.onDoubleClick}
            className={classNames([className, "p-1", cell.className], {})}
        >
            {col === 0 ? (
                <div className="text-left tw-min-w-[100px]">{cell.value}</div>
            ) : row === 0 || cell.value === "" ? (
                <div className="text-right tw-min-w-[100px]">{cell.value}</div>
            ) : (
                <div className="d-flex justify-content-end align-items-center">
                    {cell.type === "fake" ? <Money value={cell.value} /> : <Money colored value={cell.value} />}
                    {cell.category_id ? (
                        <Whisper
                            placement="right"
                            trigger="hover"
                            speaker={
                                <Tooltip>
                                    <TText tkey="click_to_see_details" />
                                </Tooltip>
                            }
                        >
                            <span>
                                <CIcon className="pl-2" path={ArrowDownHollow} />
                            </span>
                        </Whisper>
                    ) : (
                        ""
                    )}
                </div>
            )}
        </td>
    );
};
