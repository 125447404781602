import { Toggle } from "rsuite";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import { ToggleProps } from "rsuite/cjs/Toggle/Toggle";
import _ from "lodash";

interface IProps extends CFormGroupProps<ToggleProps> {
    CFormStyle?: any;
    testId?: string;
}

const ToggleFormGroup: FCC<IProps> = props => {
    const cProps: any = {
        render: ({ field: { name, value, onChange } }: any) => {
            return (
                <div className="pt-1">
                    <Toggle
                        test-id={props?.testId ?? name}
                        className="my-1 pt-1"
                        checked={!!value}
                        onChange={onChange}
                        {...props.compProps}
                    >
                        {props?.children}
                    </Toggle>
                </div>
            );
        },
        ...props.compProps,
    };

    return (
        <CFormGroup
            {..._.omit(props, "children")}
            compProps={cProps}
            // controlWrapperProps={{ style: { paddingLeft: 170 } }}
        />
    );
};

export default ToggleFormGroup;
