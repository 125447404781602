import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import React, { FC } from "react";
import { TText } from "components/i18n/TText";
import { PerformanceType, Trash } from "config/constants";
import { QuickLinks } from "components/Header/QuickLinks";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SelectFilter from "components/DataTable/Filters/SelectFilter";

export const IncentiveBonusReportsPage: FC = () => {
    const dp = useDataProvider({
        url: "incentive-programs-transactions",
        useUrl: true,
        useDates: true,
        with: ["staff:id,username,first_name", "product:id,name", "incentiveProgram"],
        sort: ["created_at", "-1"],
    });

    const cm = useColumns<any>("incentive-bonus-reports", [
        {
            title: t("staff"),
            key: "staff.username",
            flexGrow: 1.2,
        },
        {
            title: t("product"),
            key: "product.name",
            minWidth: 200,
            flexGrow: 1.5,
        },
        {
            key: "amount",
        },
        {
            title: t("incentive_program"),
            key: "incentive_program.name",
            flexGrow: 1.2,
        },
        {
            key: "transaction_id",
            flexGrow: 0.9,
        },
        {
            title: t("operated_at"),
            key: "updated_at",
            type: "datetime",
            multiline: true,
            reverse: true,
            align: "center",
            hide: true,
        },
        {
            key: "created_at",
            type: "datetime",
            multiline: true,
            reverse: true,
            align: "center",
            hide: true,
        },
        {
            key: "performance_type",
            render: ({ incentive_program }: { incentive_program: IIncentiveProgram }) => {
                return <TText tkey={`${incentive_program?.performance_type}_sales`} />;
            },
        },
    ]);

    return (
        <div className="h-100">
            {useTitle(t("incentive_programs"))}
            <QuickLinks
                inHeader={false}
                links={[
                    {
                        link: "/staff/incentive-program",
                        label: t("incentive_program"),
                    },
                    {
                        link: "/staff/incentive-program/bonus-reports",
                        label: t("bonus_report"),
                        active: true,
                    },
                    {
                        link: `/staff/incentive-program/finance-transactions`,
                        label: t("finance_transaction"),
                    },
                ]}
            />
            <HeaderRight cm={cm} dp={dp} reload date />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <RemoteCheckListFilter
                        fields="staff_id"
                        remote={{
                            url: "user",
                            refineList: list =>
                                list?.map(item => ({
                                    id: item.id,
                                    name: item.username,
                                })),
                        }}
                        searchLocal={true}
                        pickerProps={{ placeholder: t("staff") }}
                    />

                    <RemoteCheckListFilter
                        fields="incentive_program_id"
                        remote={{
                            url: "incentive-programs",
                        }}
                        searchLocal={true}
                        pickerProps={{ placeholder: t("incentive_program") }}
                    />

                    <RemoteCheckListFilter
                        fields="product_id"
                        searchField="name"
                        remote={{
                            url: "product",
                            sort: ["updated_at", "-1"],
                            limit: 20,
                            params: {
                                trash: Trash.TrashAndActive,
                            },
                        }}
                        pickerProps={{ placeholder: t("product") }}
                    />

                    <SelectFilter
                        fields="performance_type"
                        options={[
                            { value: PerformanceType.OVERALL, label: t(`${PerformanceType.OVERALL}_sales`) },
                            { value: PerformanceType.SELF, label: t(`${PerformanceType.SELF}_sales`) },
                        ]}
                    />
                </div>
            </FiltersContainer>

            <DataTable dp={dp} columns={cm.columns} />
        </div>
    );
};
