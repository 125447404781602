import classNames from "classnames";

import { IconButton, IconButtonProps } from "rsuite";
import CIcon from "./CIcon";
import { iRefresh, iSpinner } from "assets/icons/Icons";

type IProps = IconButtonProps;

const ReloadButton: FCC<IProps> = ({ loading, className, ...props }) => {
    return (
        <div
            className="d-flex justify-content-center align-items-center rounded-circle"
            style={{ width: "36px", height: "36px", background: "#f2f4f7", overflow: "hidden", marginLeft: "8px" }}
        >
            <IconButton
                disabled={loading}
                style={{ borderRadius: "30px", background: "#f2f4f7" }}
                className={classNames(["d-flex justify-content-center align-items-center", className], {
                    "": loading,
                })}
                {...props}
                test-id={"reload-button"}
                icon={
                    <CIcon
                        style={{ background: "#F2F4F7", borderRadius: "30px" }}
                        path={loading ? iSpinner : iRefresh}
                        spin={loading}
                        size={1}
                    />
                }
            />
        </div>
    );
};

export default ReloadButton;
