import { t } from "lib/i18n";
import { useTitle } from "components/Header/Title";

import { useLocation } from "react-router-dom";
import { getLabel } from "components/Form/form-utils";
import { useRoutes } from "hooks/useRoutes";
import { IRouteWithComp } from "types/routes";

interface IProps {
    type: "no_access" | "no_permission";
}

const NoAccessPage: FCC<IProps> = ({ type }) => {
    const loc = useLocation();
    const routes = useRoutes();
    const subRoutes: IRouteWithComp[] = routes.reduce(
        (subroutes, route) => subroutes.concat(route.subroutes as any),
        [],
    );
    const prevRoute = subRoutes.find((a: any) => a.path === loc.pathname);

    return (
        <div className="h-100">
            {useTitle(prevRoute?.title || "")}

            {/*<DataTable dp={{data: fakeData || []} as any} columns={cm.columns}*/}
            {/*           tableProps={{*/}
            {/*               headerHeight: 50,*/}
            {/*               affixHeader: true*/}
            {/*           }}/>*/}

            <div
                className="h-100 d-flex justify-content-center align-items-center"
                style={{
                    position: "absolute",
                    zIndex: 5,
                    top: 0,
                    //background: "rgb(255 255 255 / 81%)",
                    width: "100%",
                }}
            >
                <h3 style={{ color: "rgb(0 0 0 / 41%)", width: "40%" }}>
                    {t(type, {
                        name: prevRoute?.title || "",
                        permission: Array.isArray(prevRoute?.permissions)
                            ? prevRoute?.permissions?.map(p => getLabel(p)).join(", ") || ""
                            : "",
                    })}
                </h3>
            </div>
        </div>
    );
};

export default NoAccessPage;
