import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import Money from "components/mini/Money";
import _ from "lodash";
import TransactionsDrawer from "./transactions/TransactionsDrawer";
import { exportFile } from "lib/export-table";
import SelectFilter from "../../components/DataTable/Filters/SelectFilter";
import React, { FC } from "react";
import { useData } from "hooks/useData";
import CellBtn from "../../components/DataTable/CellButton";
import { money } from "lib/money";
import { useCurrency } from "hooks/useCurrency";
import StaffBonusDrawer from "../../components/Drawers/StaffBonusDrawer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import { useAuth } from "providers/AuthProvider/AuthProvider";

const PayrollPage: FC = () => {
    const dp = useDataProvider<IPayroll>({
        url: "report/finance/payroll",
        useUrl: true,
        with: ["bonus_balance"],
        useDates: true,
        filters: {
            status: ["status", 1],
        },
        params: {
            v2: 1,
        },
    });
    const total = useData<IPayrollTotal>("report/finance/payroll", {
        params: {
            total: 1,
            ...dp.params.all,
        },
        filters: _.values(dp.filters.all),
    });

    const auth = useAuth();
    const currency = useCurrency();
    const drawer = useDrawer({ backdrop: false, overflowAuto: true });
    const bonusDrawer = useDrawer({ backdrop: false, overflowAuto: false });

    const cm = useColumns<IPayroll>("report/finance/payroll", [
        {
            key: "username",
            align: "left",
            flexGrow: 1.2,
            render: e => (
                <div
                    className="text-primary tw-cursor-pointer"
                    onClick={() => {
                        drawer.setData(e);
                        drawer.setDataId(e.id);
                    }}
                >
                    {e.username}
                </div>
            ),
        },
        {
            title: t("role"),
            key: "role.name",
            align: "left",
            flexGrow: 0.2,
            hideData: () => !auth.hasAbility(["role_manage_view"]),
        },
        {
            key: "total", // total
            title: t("total_paid"),
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={total.data?.total} />,
            render: e => <Money value={e.total} />,
        },
        {
            key: "salary",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={total.data?.salary} />,
        },
        {
            key: "payroll",
            title: t("paid_salary"),
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={total.data?.payroll} />,
            render: e => <Money value={e.payroll} />,
        },
        {
            key: "left",
            title: t("left_salary"),
            minWidth: 200,
            align: "center",
            type: "money",
            numberRoundingLength: 2,
            summary: () => (total.data ? <Money value={total.data.salary - total.data.payroll} /> : "--"),
            render: e => (e.salary ? <Money value={e.salary - e.payroll} /> : "--"),
        },
        {
            key: "earned_incentive_bonus",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={total.data?.earned_incentive_bonus} />,
            render: (d: any) => (
                <CellBtn
                    action={() => bonusDrawer.setData({ user: d })}
                    label={money(d.earned_incentive_bonus, currency)}
                />
            ),
        },
        {
            key: "paid_incentive_bonus",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={total.data?.paid_incentive_bonus} />,
            render: d => <Money value={d.paid_incentive_bonus || 0} />,
        },
        {
            key: "paid_bonus",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={total.data?.paid_bonus} />,
            render: e => <Money value={e.paid_bonus} />,
        },
        {
            key: "total_paid_bonus",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={total.data?.total_paid_bonus} />,
            render: d => <Money value={d.total_paid_bonus || 0} />,
        },
    ]);
    return (
        <div className="h-100">
            {useTitle(t("payroll"), dp.total)}
            <HeaderRight dp={dp} reload export={() => exportFile(dp, cm.columns, t("payroll"))} print date />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="username" />
                    <SelectFilter
                        pickerProps={{ placeholder: t("status"), searchable: false }}
                        fields="status"
                        hasAllOption={true}
                        options={[
                            { value: 1, label: t("active") },
                            { value: "0", label: t("inactive") },
                        ]}
                    />
                    <RemoteCheckListFilter
                        remote={{
                            url: "role",
                        }}
                        searchField="name"
                        fields="roles"
                        searchLocal={true}
                        pickerProps={{ placeholder: t("roles"), valueKey: "name", className: "tw-ml-1" }}
                        hasPermission={auth.hasAbility(["role_manage_view"])}
                    />

                    {/*<CheckBoxFilter negate fields="status"*/}
                    {/*        title={t("users_with_inactive")} value="1" />*/}
                </div>
            </FiltersContainer>
            <DataTable dp={dp} columns={cm.columns} tableProps={{ headerHeight: 50 }} />
            {drawer.isOpen && <TransactionsDrawer {...drawer} date={dp.params.getValue("date")} />}
            {bonusDrawer.isOpen && <StaffBonusDrawer {...bonusDrawer} date={dp.params.getValue("date")} />}
        </div>
    );
};

export default PayrollPage;
