import { ICDrawerProps } from "components/Drawers/CDrawer";
import RemoteSelectFormGroup from "components/Form/RemoteSelectFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import TextPostfixFormGroup from "components/Form/TextPostfixFormGroup";
import { t } from "lib/i18n";
import JsonView from "components/JsonView";
import { BalanceWithAmount } from "components/mini/BalanceWithAmount";
import { useCForm } from "hooks/useCForm";
import _ from "lodash";
import { DateRangePicker } from "rsuite";
import { iExchange, iMinus, iPlus } from "assets/icons/Icons";
import CIcon from "../../../components/CIcon";
import CFormModal from "../../../components/Drawers/CFormModal";
import FinanceCategorySelectFormGroup from "../../../components/Form/CategorySelectFormGroup/FinanceCategorySelectFormGroup";
import DatePickerFormGroup from "../../../components/Form/DatePickerFormGroup";
import SelectFormGroup from "../../../components/Form/SelectFormGroup";
import { FinanceAccountDefaultFilters } from "config/constants";
import { useData } from "hooks/useData";
import { dummySupplierTransaction, SupplierTransactionModel } from "models/TransactionModel";
import { format } from "date-fns";

interface IProps extends ICDrawerProps {
    revalidate: () => void;
}
const typeIcon: Record<string, string> = {
    IN: iPlus,
    OUT: iMinus,
    TRANSFER: iExchange,
    SUPPLIER_PAY: iMinus,
};
const CreateSupplierTransactionModal: FCC<IProps> = props => {
    const accounts = useData<IAccount[]>("finance/balance", {
        params: {
            limit: 999,
            filters: _.values(FinanceAccountDefaultFilters),
        },
    });

    const form = useCForm({
        url: "finance/transaction",
        model: SupplierTransactionModel(),
        id: props.dataId || undefined,
        redirectUrl: false,
        clearForm: false,
        dummyData: () => dummySupplierTransaction(),
        prepareForSubmit: (d: any) => ({
            ...d,
            operated_at: format(new Date(d.operated_at), "yyyy-MM-dd HH:mm:ss"),
        }),
    });

    const type = form.form.watch("type");

    const accountProps = {
        compProps: {
            renderMenuItem: (val: any, item: any) => <BalanceWithAmount testId="supplier_id_item" item={item} />,
            renderValue: (val: any, item: any) => <BalanceWithAmount item={item} />,
        },
    };

    return (
        <CFormModal
            {...props}
            size="sm"
            form={form}
            formProps={{ layout: "horizontal" }}
            title={t("create_by_name", { name: _.capitalize(t("transaction")) })}
        >
            <TextPostfixFormGroup
                name="amount"
                type="number"
                preFix={<CIcon path={typeIcon[type]} />}
                controlWrapperProps={{ style: { width: 300 } }}
                compProps={{
                    addonClass: `tw-bg-[#ffedec] tw-text-[#f04438] tw-my-[7px] tw-mx-[16px] tw-rounded-[7px]`,
                }}
            />

            <SelectFormGroup name="from_balance_id" {...accountProps} data={accounts.data} />

            <RemoteSelectFormGroup
                name="supplier_id"
                label={t("suppliers")}
                priorityError={
                    _.isNil(form.form.getValues("balance_id")) && !_.isNil(form.form.getValues("from_balance_id"))
                        ? t("required")
                        : undefined
                }
                searchLocal={true}
                compProps={{
                    renderMenuItem: (val: any, item: any) => (
                        <BalanceWithAmount testId="supplier_id_item" item={item} />
                    ),
                    renderValue: (val: any, item: any) => <BalanceWithAmount item={item} />,
                    onSelect: (val, d: any) => form.form.setValue(`balance_id`, d.balance.id),
                }}
                remote={{
                    url: "supplier",
                    with: ["balance"],
                    limit: 999,
                    refineList: (d?: any[]) => {
                        d?.forEach(s => {
                            s.amount = s.balance?.amount || 0;
                        });
                        return d ?? [];
                    },
                }}
            />

            <FinanceCategorySelectFormGroup
                name="category_id"
                style={{ width: "100%" }}
                customFilter={(c: IFinanceCategory) => c.parent_id === 9 || c.id === 9}
                compProps={{
                    cleanable: false,
                }}
            />
            <DatePickerFormGroup
                time
                name="operated_at"
                compProps={{
                    cleanable: false,
                    shouldDisableDate: DateRangePicker.after?.(new Date()),
                    "test-id": "supplier-transaction-operated-at",
                }}
            />

            <TextFormGroup name="description" compProps={{ "test-id": "transaction-description" }} />
            <JsonView />
        </CFormModal>
    );
};

export default CreateSupplierTransactionModal;
