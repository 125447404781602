import { makeUseReduxModelData } from "hooks/data-hooks/useReduxModelData";
import _ from "lodash";
import { store } from "redux/store";

interface IStoragesConfig {
    showOnlyActive?: boolean;
    showAllVenues?: boolean;
    refineList?: (list: IStorageWithVenue[]) => IStorageWithVenue[];
}

export const useStorages = makeUseReduxModelData("storage", (storages, conf: IStoragesConfig) => {
    if (_.isEmpty(conf)) {
        return {};
    }

    let list = storages.ids.map(id => storages.byId[id]);

    if (conf?.showOnlyActive) {
        list = list.filter((storage: IStorage) => storage.status);
    }

    const currentVenueId = store.getState().venue.currentVenueId;

    if (!conf.showAllVenues && currentVenueId) {
        list = list.filter((storage: IStorage) => storage.venue_id === currentVenueId);
    }

    if (conf?.refineList) {
        list = conf.refineList(list);
    }

    return { list };
});
