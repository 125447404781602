import classNames from "classnames";
import { config } from "config/Config";
import { useAppSelector } from "hooks/useRedux";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { IntercomProps } from "react-use-intercom/dist/types";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { usePackages } from "providers/PackageProvider";
import HeaderUserBox from "./HeaderUserBox";
import CIcon from "../CIcon";
import { iChat, iNews } from "assets/icons/Icons";
import { depot } from "@cloposcom/libs";
import { Colors } from "config/colors";
import { useSetting } from "hooks/useSetting";

interface IProps {
    noChat?: boolean;
}

function initBeamer(user?: IUser, brand?: string, country?: string) {
    if (!user) return;
    //eslint-disable-next-line
    //@ts-ignore
    window.beamer_config = {
        product_id: "EwEhzrnJ35375", //DO NOT CHANGE
        selector: ".beamerTrigger",
        display: "right",
        top: 20,
        right: 20,
        button_position: "bottom-right",
        language: country === "AZ" ? "AZ" : "EN",
        filter: "admin",
        lazy: false,
        alert: true,
        counter: true,
        user_firstname: user.first_name,
        user_lastname: user.last_name,
        user_email: user.email,
        user_id: `${brand}:${user.id}`,
    };

    // init script https://app.getbeamer.com/js/beamer-embed.js
    const script = document.createElement("script");
    script.src = "https://app.getbeamer.com/js/beamer-embed.js";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);
}

const Header: FCC<IProps> = ({ noChat }) => {
    const { boot, show, update, shutdown } = useIntercom();
    const auth = useAuth();
    const location = useLocation();
    const brandType: string = useSetting("brand_type")?.value;
    const packages = usePackages();
    const [isIntercomInit, setIsIntercomInit] = useState(false);
    const { isFullScreen } = useAppSelector(state => state.ui);

    const isSA = packages?.country === "SA";
    const showChat = !noChat && !isSA;

    const bootInfo: IntercomProps = {
        hideDefaultLauncher: true,
        userHash: auth.user?.intercom_hash,
        userId: `${config.brand}:${auth.user?.id}`,
        name: `${auth.user?.username}`,
        // email: auth.user?.email,
        customAttributes: { role: auth.user?.role },
        utmSource: document.referrer,
        company: {
            companyId: config.brand,
            name: config.brand,
            industry: brandType,
            plan: packages?.subscription?.package.title,
            createdAt: packages?.subscription?.created_at,
            website: config.oldBrandUrl,
            monthlySpend: packages?.subscription?.period_price,
            customAttributes: {
                country: packages?.country,
                modules: packages?.subscription?.modules.map(m => m?.name),
            },
        },
    };

    useEffect(() => {
        shutdown();
    }, []);
    const turnOffIntercom = depot.getItem("turn_off_intercom");
    useEffect(() => {
        if (turnOffIntercom === "1") {
            // //eslint-disable-next-line
            // console.log("turn off intercom");
            return;
        }
        if (!isSA) {
            if (config.brand !== "demo") {
                if (!isIntercomInit) {
                    boot(bootInfo);
                    initBeamer(auth.user, config.brand, packages.country);
                    setIsIntercomInit(true);
                } else {
                    update(bootInfo);
                }
            } else {
                boot();
            }
        }
    }, [location.pathname, isSA]);

    return (
        <>
            <div
                id="Header"
                style={{ paddingLeft: "13px", display: isFullScreen ? "none" : "flex" }}
                className={classNames("d-print-none py-3 pr-3 d-flex flex-column ")}
            >
                <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex">
                        <div id="page-title" className="mr-3" />
                        <div id="quick-links" />
                    </div>
                    <div className="d-flex justify-content-end align-items-center flex-wrap">
                        <div id="header-portal" />
                        <div id="debug-portal" />
                        <div className="ml-2 d-flex">
                            <HeaderUserBox />
                            <button
                                className="d-flex justify-content-center align-items-center rounded-circle mr-2 beamerTrigger"
                                style={{
                                    width: "36px",
                                    height: "36px",
                                    overflow: "hidden",
                                    background: Colors.LightGray,
                                }}
                            >
                                <CIcon size={0.7} path={iNews} />
                            </button>
                            {showChat && (
                                <button
                                    className="position-relative d-flex justify-content-center align-items-center rounded-circle"
                                    style={{
                                        width: "36px",
                                        height: "36px",
                                        background: Colors.PaleSkyBlue,
                                        overflow: "hidden",
                                    }}
                                    onClick={() => show()}
                                >
                                    <div className="position-absolute"></div>
                                    <CIcon path={iChat} size={0.65} style={{ color: Colors.BrilliantBlue }} />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div id="quick-links-under" />
        </>
    );
};

export default Header;
