import RDefaultComp from "./RDefaultComp";
import RReceiptProductsComp from "./RReceiptProductsComp";
import RTextAreaComp from "./RTextAreaComp";
import RSubtotalComp from "./RSubtotalComp";
import RQrCodeComp from "./RQrCodeComp";
import RCustomerComp from "./RCustomerComp";
import { IReceiptItem, IReceiptItemType, ReceiptItemType } from "../../types";
import RReceiptInfoComp from "./RReceiptInfo";
import { FC } from "react";

const receiptItemViewForType: Record<IReceiptItemType, FC<IReceiptItem>> = {
    brandLogo: RDefaultComp,
    brandName: RDefaultComp,
    receiptNumber: RDefaultComp,
    dailyId: RDefaultComp,
    monthlyId: RDefaultComp,
    Cashier: RDefaultComp,
    receipt_owner: RDefaultComp,
    Waiter: RDefaultComp,
    openDate: RDefaultComp,
    closedDate: RDefaultComp,
    saleType: RDefaultComp,
    tax: RDefaultComp,
    serviceCharge: RDefaultComp,
    discount: RDefaultComp,
    subtotal: RSubtotalComp,
    receiptTotal: RDefaultComp,
    address: RDefaultComp,
    line: RDefaultComp,
    qrCode: RQrCodeComp,
    whiteSpace: RDefaultComp,
    customer: RCustomerComp,
    receiptProduct: RReceiptProductsComp,
    textArea: RTextAreaComp,
    delivery: RDefaultComp,
    gift: RDefaultComp,
    paymentMethod: RDefaultComp,
    brandAddress: RDefaultComp,
    brandCity: RDefaultComp,
    brandPhone: RDefaultComp,
    description: RDefaultComp,
    ByClopos: RDefaultComp,
    courier: RDefaultComp,
    taxDetail: RDefaultComp,
    guestCount: RDefaultComp,
    receiptInfo: RReceiptInfoComp,
    date: RDefaultComp,
    pos: RDefaultComp,
    station: RDefaultComp,
    products: RDefaultComp,
    stationComment: RDefaultComp,
    orderNumber: RDefaultComp,
};
const DesignForType = ({ item, receiptType }: { item: IReceiptItem; receiptType: ReceiptItemType }) => {
    const Component = receiptItemViewForType[item.type];

    if (Component) {
        return (
            <div className="ml-2 w-100">
                <Component {...item} receiptType={receiptType} />
            </div>
        );
    } else {
        return <></>;
    }
};
export default DesignForType;
