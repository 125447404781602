import { useMenuCategories } from "hooks/data-hooks/useMenuCategories";
import CategorySelectFormGroup from "./CategorySelectFormGroup";
import { CategorySelectFormGroupProps } from "./CategorySelectFormGroupProps";

const MenuCategorySelectFormGroup: FCC<CategorySelectFormGroupProps> = props => {
    const categories = useMenuCategories();

    // useEffect(() => {
    //     categories.revalidate();
    //     console.log("Revalidate cats");
    // }, [props?.revalidate]);

    return <CategorySelectFormGroup {...props} categories={categories} />;
};

export default MenuCategorySelectFormGroup;
