import { t } from "lib/i18n";
import { FORMATS } from "config/constants";
import { yesterday } from "config/dates";
import { passCashRestartCheck } from "lib/passCashRestartCheck";
import { DatePicker, DatePickerProps } from "rsuite";
import React from "react";
import { useSetting } from "hooks/useSetting";
import { format } from "date-fns";

interface IProps extends DatePickerProps {
    time?: boolean;
    resetData?: boolean;
    value?: any;
    mainstyle?: any;
    label?: string;
    width?: string | number;
}

const DatePickerState: FCC<IProps> = ({ time, label, onChangeCalendarDate, value, ...props }) => {
    const cashRestart = useSetting("cash_restart")?.value;
    const accurateToday = passCashRestartCheck(cashRestart);
    const inputWidth = props.width ? props.width : 300;

    return (
        <div style={props?.mainstyle}>
            {label && (
                <p test-id={"date-picker-label"} style={{ marginBottom: 1 }}>
                    {label}
                </p>
            )}
            <DatePicker
                test-id={"date-picker"}
                value={props?.resetData ? Date.now() : value}
                onChangeCalendarDate={onChangeCalendarDate}
                style={{ width: inputWidth, maxHeight: "20px !important" }}
                format={time ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
                renderValue={v => format(v, time ? FORMATS.datetime : FORMATS.date)}
                ranges={
                    [
                        { label: t("today"), value: accurateToday, "test-id": "today" },
                        { label: t("yesterday"), value: yesterday(), "test-id": "yesterday" },
                    ] as any[]
                }
                {...props}
            />
        </div>
    );
};

export default DatePickerState;
