import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import Money from "components/mini/Money";
import { EMPTY_DATA } from "config/main";

import { Tooltip, Whisper } from "rsuite";

interface IProps {
    r: IReceipt;
}

const GiftCell: FCC<IProps> = props => {
    const giftCount = props.r.receipt_products
        .filter((rp: IReceiptProduct) => !!rp.is_gift && !rp.deleted_at)
        .reduce((total, rpp) => total + rpp.count, 0);

    return (
        <div className="d-flex justify-content-center flex-column align-items-center" style={{ marginTop: "-15px" }}>
            {props.r.gift_total ? <Money style={{ fontSize: "14px" }} value={props.r.gift_total} /> : EMPTY_DATA}
            <Whisper
                speaker={
                    <Tooltip>
                        <TText tkey="gifted_products" />
                    </Tooltip>
                }
            >
                <span className="text-empty ml-1" style={{ fontSize: "11px" }}>
                    {giftCount ? `${giftCount} ` : ``} {giftCount ? t("pcs_short") : EMPTY_DATA}
                </span>
            </Whisper>
        </div>
    );
};

export default GiftCell;
