import { translateUnitName } from "lib/utils";
import { FC } from "react";

interface IProps {
    d: IOperation;
}

const OperationProductsCell: FC<IProps> = ({ d }): any => {
    if (d.products_label) {
        return d.products_label;
    }
    return (
        d.product_info_list
            ?.map(op => (
                <span>
                    {op.parent_name} {op.name} ({op.info?.quantity}
                    <i>{translateUnitName(op?.unit)}</i>)
                </span>
            ))
            .concat(<span />)
            .reduce((p, c) => (
                <>
                    {p}, {c}
                </>
            )) || []
    );
};

export default OperationProductsCell;
