import { t } from "lib/i18n";
import DataTable from "components/DataTable/DataTable";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";

import { useTableHeight } from "hooks/useTableHeight";
import { useColumns } from "../DataTable/ColumnsManager/useColumns";
import { useData } from "hooks/useData";
import Money from "../mini/Money";
import { getFormatedDate } from "lib/utils";
import { useSetting } from "hooks/useSetting";
import { Trash } from "config/constants";

const AccountDrawer: FCC<ICDrawerProps> = ({ ...props }) => {
    const cash_restart = useSetting("cash_restart")?.value;

    const dp = useData(!!props.dataId && `finance/balance/date-states`, {
        params: { trash: Trash.TrashAndActive, limit: 999 },
        filters: [["balance_id", props.dataId]] as any,
    });
    // don't delete this useData. It's there to compare data with the above one. (used by dev team)
    useData(!!props.dataId && `data-state`, {
        params: { trash: Trash.TrashAndActive, limit: 999 },
        filters: [
            ["model_type", "BALANCE"],
            ["model_id", props.dataId],
        ] as any,
    });
    const cm = useColumns<IDataState>(`account-details`, [
        {
            title: t("date"),
            key: "date",
            type: "date",
            align: "left",
            flexGrow: 3,
            render: e => (
                <div className="d-flex align-items-center" style={{ fontSize: "12px" }}>
                    <div className="mr-1">{getFormatedDate(e.date)}</div>

                    <div style={{ fontSize: "12px" }} className="text-empty text-12">
                        {cash_restart}
                    </div>
                </div>
            ),
        },
        { title: t("amount"), key: "value", render: e => <Money value={e.amount} /> },
    ]);
    const height = useTableHeight(60);

    return (
        <CDrawer size="md" {...props} title={t("details")}>
            <div className="h-100">
                <div className="d-flex">
                    <div className="flex-fill border-left">
                        <DataTable
                            noShadow
                            columns={cm.columns}
                            dp={{ data: dp.data } as any}
                            hidePages
                            tableProps={{
                                autoHeight: false,
                                height,
                            }}
                        />
                    </div>
                </div>
            </div>
        </CDrawer>
    );
};

export default AccountDrawer;
