import classNames from "classnames";
import ReloadButton from "components/ReloadButton";

import { Panel } from "rsuite";
import { ReactNode } from "react";

export interface IWidgetProps {
    title?: ReactNode;
    headerButtons?: ReactNode;
    onClick?: () => void;
    onRefresh?: () => void;
    className?: string;
    hightlight?: boolean;
    loading?: boolean;
}
const Widget: FCC<IWidgetProps> = props => {
    return (
        <Panel
            className={classNames(["shadow rounded", props.className], {
                "bg-info text-white": props.hightlight,
                "bg-white": !props.hightlight,
            })}
            style={{ minHeight: "160px" }}
            onClick={props.onClick}
        >
            {props.title && (
                <div className="d-flex justify-content-between">
                    <div className="d-flex">
                        <h5 className="font-weight-light mb-3 mr-3">{props.title}</h5>
                        {props.headerButtons}
                    </div>
                    {props.onRefresh && <ReloadButton size="xs" loading={props.loading} onClick={props.onRefresh} />}
                </div>
            )}
            {props.children}
        </Panel>
    );
};

export default Widget;
