import { t } from "lib/i18n";
import { Rnd } from "react-rnd";
import { IS_PR_PREVIEW, isAlpha, isDev } from "config/main";
import { IconButton } from "rsuite";
import { BugIcon, DragIcon } from "assets/icons";
import { useDepot } from "hooks/useDepot";
import TabsView from "../TabsView";
import PortalTo from "../PortalTo";
import { SettingsTab } from "./SettingsTab";
import { StateViewerTab } from "./StateViewerTab";

export const DebugModal = () => {
    const [isOpen, setIsOpen] = useDepot("CLOPOS_DEBUG_MODE_OPEN", false);
    const isDebugMode = Boolean(localStorage.getItem("CLOPOS_DEBUG_MODE"));
    const showDebugModal = isDev || isAlpha || IS_PR_PREVIEW || isDebugMode;

    if (!showDebugModal) return null;

    return (
        <>
            <PortalTo id="debug-portal">
                <IconButton
                    appearance="subtle"
                    className="tw-p-2"
                    icon={<img src={BugIcon} height={22} alt="bug-icon" />}
                    onClick={() => setIsOpen(!isOpen)}
                />
            </PortalTo>

            {isOpen && (
                <Rnd
                    default={{
                        x: window.innerWidth - 600,
                        y: 0,
                        width: "initial",
                        height: "initial",
                    }}
                    minWidth={400}
                    minHeight={100}
                    bounds="parent"
                    enableResizing={false}
                    dragHandleClassName="drag-move"
                    className="tw-fixed p-3 tw-bg-white tw-z-50 tw-rounded-lg tw-shadow-2xl"
                >
                    <div className="drag-move tw-cursor-move tw-absolute tw-right-4 tw-z-50">
                        <img src={DragIcon} height={22} alt="move-icon" className="tw-select-none" draggable={false} />
                    </div>
                    <TabsView
                        tabs={[
                            {
                                label: "State Viewer",
                                Component: StateViewerTab,
                            },
                            {
                                label: t("settings"),
                                Component: SettingsTab,
                            },
                        ]}
                    />
                </Rnd>
            )}
        </>
    );
};
