import { t } from "lib/i18n";
import React, { useEffect, useState } from "react";
import { useCForm } from "hooks/useCForm";
import TextFormGroup from "components/Form/TextFormGroup";

import { useFormTitle } from "components/Header/Title";
import HeaderRight from "components/Header/HeaderRight";
import CForm from "components/Form/CForm/CForm";
import RemoteSelectFormGroup from "../../../components/Form/RemoteSelectFormGroup";
import { useParams } from "react-router-dom";
import JsonView from "components/JsonView";
// import { Icon, Toggle } from "rsuite";
import SelectFormGroup from "components/Form/SelectFormGroup";
import _ from "lodash";
import { taxCalculationType } from "config/constants";
import { TaxModel } from "models/TaxModel";

const EditTaxPage: FCC = () => {
    const params: any = useParams();
    const form = useCForm({
        url: "finance/taxes",
        model: TaxModel(),
    });
    const [shouldTaxCalcType, setShouldTaxCalcType] = useState(false);

    // const changeCalcType = (state: boolean) => {
    //     setTaxCalcType(state)
    //     form.form.setValue("calculation_type", state === true ? taxCalculationType.percentage : taxCalculationType.amount);
    // }

    useEffect(() => {
        setShouldTaxCalcType(form.form.getValues("calculation_type") === taxCalculationType.percentage);
        if (_.isNil(form.form.getValues("calculation_type"))) {
            form.form.setValue("calculation_type", taxCalculationType.percentage);
        }
    }, [form]);

    return (
        <div className="p-3">
            {useFormTitle(form.id, "", form.form.getValues("name"))}
            <HeaderRight formSubmit={form} />
            <CForm form={form.form} onSave={form.onSave}>
                <TextFormGroup name="name" errorPlacement="rightEnd" focus={true} />

                {/* <div className="rs-form-group" role="group">
                <label className="rs-control-label"><TText key="calculation_type" /></label>
                <div className="rs-form-control-wrapper" style={{ marginTop: 3 }}>
                    <Toggle
                        name="calculation_type"
                        checkedChildren={<Icon icon="percent" />}
                        unCheckedChildren={<Icon icon="money" />}
                        onChange={changeCalcType}
                        checked={taxCalcType}
                        disabled={!_.isNil(form.form.getValues("id"))}
                        size="lg"
                    />
                </div>
            </div> */}

                <TextFormGroup
                    name="rate"
                    label={shouldTaxCalcType ? t("percentage") : t("amount")}
                    type="number"
                    errorPlacement="rightEnd"
                    compProps={{
                        style: { width: "300px" },
                    }}
                    focus={true}
                />

                <RemoteSelectFormGroup
                    name="type_id"
                    searchLocal={true}
                    remote={{
                        url: "finance/tax/types",
                    }}
                    disabled={params?.id}
                    errorPlacement="rightEnd"
                />

                <SelectFormGroup
                    name="priority"
                    data={[...Array(10)].map((_o, i) => ({ id: i + 1, name: i + 1 }))}
                    errorPlacement="rightEnd"
                />
                <JsonView />
            </CForm>
        </div>
    );
};

export default EditTaxPage;
