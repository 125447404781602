import { t } from "lib/i18n";
import classNames from "classnames";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import SelectFilter from "components/DataTable/Filters/SelectFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import { ReasonType } from "config/constants";
import _ from "lodash";
import ActionString from "./receipts/misc/ActionString";
import { ProductsProvider } from "hooks/useProducts";
import PriceCell from "./receipts/PriceCell";
import SummaryCell from "./receipts/SummaryCell";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import { useData } from "hooks/useData";
import LocalCheckListFilter from "components/DataTable/Filters/LocalCheckListFilter";
import ReceiptNewStationDrawer from "./receipts/ReceiptNewStation";
import { useDrawer } from "components/Drawers/useDrawer";
import CIcon from "components/CIcon";
import { iDot } from "assets/icons/Icons";
import { Colors } from "config/colors";
import CheckBoxFilter from "components/DataTable/Filters/CheckBoxFilter";
import React from "react";
import ReceiptSplittedDrawer from "./receipts/ReceiptSplittedDrawer";
import { useSetting } from "hooks/useSetting";
import { convertToSlug } from "lib/convertToSlug";

const ReceiptHistoryPage: FCC = () => {
    const url = "receipt/log";
    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        url,
        with: ["terminal:id,name"],
        sort: ["created_at", "-1"],
    });
    const suspicion_actions = useSetting("suspicion_action")?.value;
    const newrd = useDrawer({ backdrop: false, overflowAuto: true });
    const splitted = useDrawer({ backdrop: false, overflowAuto: true });

    const cm = useColumns<any>("receipt/log", [
        {
            title: "#" + t("receipt"),
            key: "rid",
            align: "left",
            flexGrow: 1,
        },
        { title: t("timestamp"), key: "timestamp", type: "datetime", reverse: true },
        {
            title: t("terminal"),
            key: "terminalId",
            render: t => (
                <span className="d-flex h-100">
                    <div className="mr-2">
                        {t.payload.isOnline ? (
                            <CIcon path={iDot} size={0.8} style={{ color: Colors.EmeraldGreen }} />
                        ) : (
                            <CIcon path={iDot} size={0.8} style={{ color: Colors.BrightRed }} />
                        )}
                    </div>
                    {/* TODO: Is it possible to not to have terminal id even after we requested it directly? */}
                    {t.terminal?.id ? t.terminal?.name + ` (${t.terminal?.id})` : (t.terminalId ?? "-")}
                </span>
            ),
        },
        {
            title: t("changes"),
            key: "payload",
            render: items => (
                <div className="d-flex">
                    <ActionString
                        d={items}
                        onClick={() => {
                            switch (items.type) {
                                case "Split":
                                    splitted.setData(items.payload);
                                    break;
                                case "SplitIn":
                                case "SplitOut":
                                    splitted.setData(items.payload.rps);
                                    break;
                                default:
                                    newrd.setData(items);
                            }
                        }}
                    />
                </div>
            ),
            flexGrow: 4,
            align: "left",
        },
        {
            title: t("price"),
            key: "price",
            render: e => <PriceCell p={e} />,
            align: "left",
            summary: () => {
                return <SummaryCell p={dp.data} />;
            },
        },
    ]);

    const suspicionActions = useSetting("suspicion_action");
    const suspicionActionsValue: string[] = suspicionActions?.value ?? [];
    const suspicionOptions = suspicionActions?.options ?? [];

    const reasons = useData(`reasons`, {
        filters: [["type", ReasonType.delete]],
    });

    const suspiciousTypes = _.map(suspicionOptions, (value: any) => {
        return {
            value: value,
            label: t(`${value.toLowerCase()}_rlog_action_title` as LangKey) ?? value,
            slug: convertToSlug(t(`${value}_rlog_action_title` as LangKey) ?? value),
        };
    });

    const closedAction = _.findIndex(dp.data ?? [], ["type", "Close"]);
    return (
        <div className="h-100">
            {useTitle(t("history"), dp.total)}
            {/*export={() => exportFile(dp, cm.columns, t("history"))}*/}
            <HeaderRight cm={cm} date reload dp={dp} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="rid" type={"number"} />
                    <SelectFilter
                        pickerProps={{
                            placeholder: t("actions"),
                            searchBy: (val: string, _, dt: any) => dt?.slug?.includes(convertToSlug(val)),
                            renderMenuItem: (label: any, item: any) => {
                                const isExists = suspicionActionsValue.find(sA => sA === item.value);
                                return (
                                    <div key={item.value}>
                                        <p style={{ color: isExists ? "#F79009" : "#575757" }}>{label}</p>
                                    </div>
                                );
                            },
                        }}
                        fields="type"
                        options={suspiciousTypes}
                        width={150}
                        maxWidth={200}
                    />

                    <SelectFilter
                        pickerProps={{ placeholder: t("product_delete_tags") }}
                        fields="reason_id"
                        options={(reasons.data as any)?.map((reason: any) => ({
                            value: reason.id,
                            label: reason.name,
                        }))}
                        width={230}
                        maxWidth={450}
                    />

                    <RemoteCheckListFilter
                        fields="userId"
                        remote={{
                            url: "user",
                            refineList: (d: any[]) => d?.filter(user => user.owner || !user.hide),
                        }}
                        labelField="username"
                        searchField="username"
                        pickerProps={{ placeholder: t("staff") }}
                        width={200}
                        maxWidth={200}
                    />
                    <LocalCheckListFilter
                        fields="terminalId"
                        url="terminal"
                        pickerProps={{ placeholder: t("terminals") }}
                    />
                    <CheckBoxFilter fields="cause" title={t("suspicion_action")} value="1" />
                </div>
            </FiltersContainer>

            <ProductsProvider>
                <DataTable
                    columns={cm.columns}
                    dp={dp}
                    noShadow
                    tableProps={{
                        headerHeight: 50,
                        sortColumn: "timestamp",
                        sortType: "asc",
                        wordWrap: true,
                        rowClassName: (d: any) =>
                            classNames({
                                suspicious: isSuspicious(dp.data ?? [], d, closedAction, suspicion_actions),
                            }),
                    }}
                />
            </ProductsProvider>
            <ReceiptNewStationDrawer {...newrd} />
            <ReceiptSplittedDrawer {...splitted} />
        </div>
    );
};

export default ReceiptHistoryPage;

function isSuspicious(
    orderHistory: IHistory[],
    action: IHistory,
    closedActionIndex: number,
    suspicion_actions: any,
): boolean {
    const index = orderHistory.findIndex(h => h.id === action?.id);
    const closedAction = closedActionIndex > -1 ? orderHistory[closedActionIndex] : null;

    return (
        (closedAction && closedActionIndex < index && orderHistory[closedActionIndex + 1].type !== "Print") ||
        suspicion_actions?.indexOf(action?.type) > -1
    );
}
