import { combineReducers } from "@reduxjs/toolkit";
import { createGenericSlice } from "./utils";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

export const financeCategoriesSlice = createGenericSlice<IFinanceCategory>(
    ReduxSlicesNames.FINANCE_CATEGORIES,
    "/finance/category",
    {
        params: { tree: 0 },
    },
);

export const menuCategoriesSlice = createGenericSlice<ICategory>(ReduxSlicesNames.MENU_CATEGORIES, "/category", {
    params: {
        tree: 0,
    },
});

export const paymentMethodsSlice = createGenericSlice<IPaymentMethod>(
    ReduxSlicesNames.PAYMENT_METHODS,
    "/finance/paymentMethod",
    {
        params: {
            // todo: make `trash` as param so it can passed from users of hook
            trash: [2],
            limit: 999,
        },
    },
);

export const rolesSlice = createGenericSlice<IRole>(ReduxSlicesNames.ROLES, "/role", {
    params: {
        limit: 999,
    },
});

export const usersSlice = createGenericSlice<IUser>(ReduxSlicesNames.USERS, "/user", {
    params: {
        limit: 999,
    },
    slugifyKey: "username",
});

export const stationsSlice = createGenericSlice<IStation>(ReduxSlicesNames.STATIONS, "/station", {
    params: {
        limit: 999,
    },
});

export const saleTypesSlice = createGenericSlice<ISaleType>(ReduxSlicesNames.SALE_TYPES, "/finance/saleType", {
    params: {
        limit: 999,
    },
});

export const modifierGroupsSlice = createGenericSlice<IModifierGroup>(
    ReduxSlicesNames.MODIFIER_GROUPS,
    "/product/modificator-group",
);

export const modifiersSlice = createGenericSlice<IModifier>(ReduxSlicesNames.MODIFICATORS, "/product/modificator");

export const unitsSlice = createGenericSlice<IUnit>(ReduxSlicesNames.UNITS, "/unit", {});

export const storageSlice = createGenericSlice<IStorageWithVenue>(ReduxSlicesNames.STORAGE, "/storage", {
    params: {
        with: ["venue"],
        limit: 999,
    },
    headers: {
        "x-all-venues": "1",
    },
});

const modelSlice = combineReducers({
    financeCategories: financeCategoriesSlice.reducer,
    menuCategories: menuCategoriesSlice.reducer,
    paymentMethods: paymentMethodsSlice.reducer,
    roles: rolesSlice.reducer,
    users: usersSlice.reducer,
    stations: stationsSlice.reducer,
    storage: storageSlice.reducer,
    saleTypes: saleTypesSlice.reducer,
    modifierGroups: modifierGroupsSlice.reducer,
    modifiers: modifiersSlice.reducer,
    units: unitsSlice.reducer,
});

export default modelSlice;
