import { parse } from "date-fns";
import { Nullable } from "types/Nullable";
import { CUSTOMER_DATE_FORMAT_REQUEST } from "lib/customerDate/customerDateFormats";

export const parseCustomerDate = (date: ICustomer["date_of_birth"]): Nullable<Date> => {
    const parsedDate = parse(String(date), CUSTOMER_DATE_FORMAT_REQUEST, new Date());

    if (Number.isNaN(parsedDate.getTime())) return null;

    return parsedDate;
};
