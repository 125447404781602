import { t } from "lib/i18n";
import { Tooltip, Whisper } from "rsuite";
import { CircleSmall } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import CellBtn from "components/DataTable/CellButton";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import LocalCheckListFilter from "components/DataTable/Filters/LocalCheckListFilter";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import SelectFilter from "components/DataTable/Filters/SelectFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import ReceiptDrawer from "components/Drawers/ReceiptDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import { getLabel } from "components/Form/form-utils";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import DoubleDataColumn from "components/mini/DoubleDataColumn";
import { Colors } from "config/colors";
import { EMPTY_DATA } from "config/main";
import { exportFile } from "lib/export-table";

const status = [
    { label: t("pending"), value: "PENDING" },
    { label: t("ignored"), value: "IGNORE" },
    { label: t("received"), value: "RECEIVED" },
];

const type = [
    { label: t("call_center"), value: "call_center" },
    { label: t("wolt"), value: "wolt" },
    { label: t("emenu"), value: "emenu" },
];

const integrationStatus = {
    PENDING: { color: Colors.BrightOrange, desc: "open" },
    RECEIVED: { color: Colors.TropicalGreen, desc: "paid" },
    IGNORE: { color: Colors.BrightRed, desc: "mistake" },
} as const;

const OrderNotification: FCC = () => {
    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        url: "service-notification",
        with: ["receipt:id,service_notification_id", "terminal:id,name", "user:id,username", "integration_status"],
        sort: ["created_at", "-1"],
        params: {
            trash: ["receipt", 2],
        },
    });
    const rd = useDrawer({ backdrop: false, overflowAuto: true });

    const cm = useColumns<IOrderNotification>("service-notification", [
        { key: "id", title: t("notification_id") },
        { key: "type", render: e => getLabel(e.type) },
        { key: "created_at", type: "datetime", multiline: true },
        {
            key: "integration",
            render: e =>
                (e.integration ? getLabel(e.integration) : e.type === "CALL_CENTER" ? t("call_center") : "") +
                `${e.payload?.service?.order_number ? ` #${e.payload?.service?.order_number}` : ""}`,
        },
        {
            key: "receive_user_id",
            title: t("received_user_id"),
            render: e => {
                return e?.user?.username ? (
                    <DoubleDataColumn type="double-data" value1={e?.user?.username} />
                ) : (
                    EMPTY_DATA
                );
            },
            // e?.user?.username || e.receive_user_id,
        },

        {
            key: "receive_terminal_id",
            title: t("received_terminal_id"),
            render: e =>
                e?.terminal?.name ? (
                    <DoubleDataColumn
                        type="double-data"
                        value1={e?.terminal?.name}
                        value2={e.receive_terminal_id.toString()}
                        key2={"ID:"}
                    />
                ) : (
                    EMPTY_DATA
                ),

            // e?.terminal?.name || e.receive_terminal_id,
        },
        {
            key: "receipt.id",
            title: t("receipt") + " ID",
            render: d => (
                <CellBtn
                    deleted={!!d.receipt?.deleted_at}
                    action={() => {
                        rd.hide();
                        rd.setDataId(d?.receipt?.id as any);
                    }}
                    label={d?.receipt ? `#${d?.receipt?.id}` : ""}
                />
            ),
        },

        {
            key: "integration_status",
            render: e => {
                return e?.integration_status ? getLabel(e?.integration_status) : EMPTY_DATA;
            },
        },
        {
            key: "status",
            render: d => (
                <Whisper
                    preventOverflow
                    trigger="hover"
                    speaker={
                        <Tooltip style={{ width: 120, marginTop: "13px" }}>
                            {t(integrationStatus[d?.status]?.desc)}
                        </Tooltip>
                    }
                    placement="leftStart"
                >
                    <div style={{ marginTop: "-13px" }}>
                        <CIcon size={2} path={CircleSmall} style={{ color: integrationStatus[d?.status]?.color }} />
                    </div>
                </Whisper>
            ),
        },
    ]);

    return (
        <div className="h-100">
            {useTitle(t("order_notifications"), dp.total)}
            <HeaderRight
                cm={cm}
                date
                reload
                dp={dp}
                export={() => exportFile(dp, cm.columns, t("order_notifications"))}
            />
            <ReceiptDrawer {...rd} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SelectFilter fields="integration" options={type} pickerProps={{ placeholder: t("type") }} />
                    <SelectFilter fields="status" options={status} pickerProps={{ placeholder: t("status") }} />
                    <RemoteCheckListFilter
                        fields="receive_user_id"
                        remote={{
                            url: "user",
                            refineList: (d: any[]) => d?.filter(user => user.owner || !user.hide),
                        }}
                        searchField="username"
                        labelField="username"
                        pickerProps={{ placeholder: t("staff") }}
                    />

                    <LocalCheckListFilter
                        fields="receive_terminal_id"
                        url="terminal"
                        pickerProps={{ placeholder: t("terminals") }}
                    />
                </div>
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                    affixHeader: true,
                }}
            />
        </div>
    );
};

export default OrderNotification;
