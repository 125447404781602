import { t } from "lib/i18n";

export const h: { [index: string]: any } = {
    formatTime(number: number): string {
        if (number === null) {
            return "-";
        }

        const secNum = number;
        const hours = Math.floor(secNum / 3600);
        const minutes = Math.floor((secNum - hours * 3600) / 60);
        const seconds = secNum - hours * 3600 - minutes * 60;

        const hoursString = hours === 1 ? `${hours} ${t("hour")}` : `${hours} ${t("hours")}`;
        const minutesString = minutes === 1 ? `${minutes} ${t("minute")}` : `${minutes} ${t("minutes")}`;

        if (hours === 0 && minutes === 0 && seconds > 0) {
            return `${seconds} ${t("seconds")}`;
        } else if (hours === 0 && minutes > 0) {
            return `${minutesString}`;
        } else if (hours > 0 && minutes === 0) {
            return `${hoursString}`;
        } else if (hours > 0 && minutes > 0) {
            return `${hoursString} ${minutesString}`;
        }

        return "-";
    },
};
