import { useMemo } from "react";
import { useAppSelector } from "./useRedux";
import { translateUnitName } from "lib/utils";

export function useProductUnitOptions(productId: number) {
    const product = useAppSelector(state => state.data.products.byId[productId]);
    const units = useAppSelector(state => state.data.units);

    const unitOptions = useMemo(() => {
        return [units.byId[product?.unit_id], ...(product?.packages || [])]
            .filter(u => !!u)
            .map(unit => ({
                id: unit.id,
                value: unit.id,
                name: translateUnitName(unit),
                label: translateUnitName(unit),
            }));
    }, [product, units]);

    return unitOptions;
}
