import React from "react";
import { getLabel } from "./form-utils";

interface IProps {
    name: string;
    form?: any;
    value?: any;
    className?: string;
    label?: string;
}

export const PlainColor: FCC<IProps> = ({ label, name, form, value, className }) => {
    return (
        <div className={`rs-form-group mb-2 ${className}`} role="group">
            <label className="rs-control-label">{label || getLabel(name)}</label>
            <div className="rs-form-control-wrapper">
                <span>
                    <div
                        style={{
                            width: 28,
                            height: 28,
                            marginRight: 5,
                            marginBottom: 5,
                            transform: "scale(1)",
                            transition: " transform 100ms ease 0s",
                        }}
                    >
                        <div
                            title={`#${value}`}
                            style={{
                                background: "transparent",
                                height: "100%",
                                width: "100%",
                                position: "relative",
                                outline: "none",
                                borderRadius: "50%",
                                boxShadow: `#${value} 0px 0px 0px 3px inset`,
                                transition: "box-shadow 100ms ease 0s",
                            }}
                        />
                    </div>
                </span>
            </div>
        </div>
    );
};
