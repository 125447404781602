import { IReceiptPaymentMethod } from "@cloposcom/libs/src/receipt-calculation/interfaces";
import { ReceiptStatus } from "@cloposcom/receipt-utils/dist/receipt-calculation/interfaces";
import { useDataProvider } from "components/DataTable/useDataProvider";
import CProductsDrawer from "components/Drawers/CProductSelector/CProductsDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { Title } from "components/Header/Title";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { CategoryTypeEnum, ProductType, SalesChannelTypes } from "config/constants";
import { useData } from "hooks/useData";
import { useLoading } from "hooks/useLoading";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import Ajax from "lib/Ajax";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useIntegrationByName } from "providers/PackageProvider";
import React, { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
    changeSalesChannel,
    recalculateReceipt,
    resetReceipt,
    selectReceiptData,
    selectSaleTypeChangeStatus,
    setInitialValues,
    setSalesTypeServiceCharge,
    updateReceipt,
} from "redux/features/webCashier";
import { changeModalState, setPriceList } from "redux/features/webCashierAdditionals";
import { Message, SelectPicker, toaster } from "rsuite";
import "./ReceiptCreate.scss";
import ReceiptCustomer from "./ReceiptCustomer";
import ReceiptModals from "./ReceiptModals";
import ReceiptOtherOptions from "./ReceiptOtherOptions";
import ReceiptPageTable from "./ReceiptPageTable";
import ReceiptPaymentPlans from "./ReceiptPaymentPlans";
import { useCalculationSetting } from "hooks/useCalculationSetting";
import { setShowedCategoryTypes, setShowedTypes } from "redux/features/productsSelector/productsSelector";

const ReceiptCreatePage: FC = () => {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { receipt, close_without_payment } = useSelector(selectReceiptData);

    const { currentVenueId } = useAppSelector(state => state.venue);
    const { isLoading, withLoading } = useLoading();
    const dispatch = useAppDispatch();
    const auth = useAuth();
    const history = useHistory();

    const calculationSetting = useCalculationSetting();

    const webCashierIntegration = useIntegrationByName("web_cashier");

    const [paymentMethodId, setPaymentMethodId] = React.useState<number>(0);

    const sellerList = useData("user", {
        with: ["roles.abilities"],
        filters: [
            ["abilities", "field_seller"],
            ["status", 1],
        ],
    });

    const priceList = useData("products/price-lists");

    dispatch(setPriceList(priceList?.data));

    // eslint-disable-next-line @typescript-eslint/naming-convention
    const saleTypeChangeStatus = useSelector(selectSaleTypeChangeStatus);

    useEffect(() => {
        dispatch(setShowedTypes([ProductType.DISH, ProductType.GOODS, ProductType.MODIFICATION]));
        dispatch(setShowedCategoryTypes([CategoryTypeEnum.PRODUCT]));

        return () => {
            dispatch(setInitialValues(null));
        };
    }, []);

    const salesTypes = useDataProvider({
        url: "finance/saleType",
        filters: { id: ["id", "!=", 1] } as any,
        refineList: (list: any) => {
            return list?.filter((item: ISaleType) => item.status[currentVenueId]);
        },
    });

    const changeSaleType = (type: ISaleType) => {
        /**
         * service_charge_rate 0, 1+ ve ya null ola bilir. null o demekdir ki, sale type-a yox, terminal settingsdeki rate-e baxmalidir.
         * Orda artiq null ola bilmir, minimum 0 olur.
         *
         * Evvel burda null check yox idi, birbasha Number() funksiyasi cagirilirdi. Amma Number(null) = 0 olur ve problem yaradir.
         * Problemle bagli Jira ticket: https://clopos.atlassian.net/browse/CL-827
         */
        dispatch(
            setSalesTypeServiceCharge(type.service_charge_rate !== null ? Number(type.service_charge_rate) : null),
        );
        dispatch(updateReceipt({ key: "payment_methods", value: [] }));
        dispatch(updateReceipt({ key: "sale_type_id", value: type?.id }));
        dispatch(changeSalesChannel(type?.channel));

        if (type?.channel !== SalesChannelTypes.DELIVERY) {
            dispatch(updateReceipt({ key: "courier_id", value: null }));
            const meta = { ...receipt.meta };
            delete meta.courier_name;
            dispatch(updateReceipt({ key: "meta", value: meta }));
            dispatch(updateReceipt({ key: "delivery_fee", value: 0 }));
            dispatch(recalculateReceipt({ setting: calculationSetting }));
        }

        setPaymentMethodId(type.payment_method_id ?? 0);

        const meta = { ...receipt.meta, ...{ sale_type: { name: type?.name } } };
        dispatch(updateReceipt({ key: "meta", value: meta }));
        dispatch(updateReceipt({ key: "saleType", value: type }));
    };

    const onSave = () => {
        let isAviableToSave = true;
        let payment_methods: IReceiptPaymentMethod[] = [];
        if (close_without_payment) {
            if (receipt.status > ReceiptStatus.PAYED && receipt.status < ReceiptStatus.TRANSFERRED) {
                dispatch(resetReceipt(null));
                dispatch(recalculateReceipt({ setting: calculationSetting }));
            }
        } else {
            payment_methods = receipt.payment_methods!.filter(pm => pm.amount != 0);
            const paymentTotal = payment_methods.reduce((pm, pm2) => pm + pm2.amount * 1, 0);
            isAviableToSave = receipt.total === paymentTotal;
        }

        // Əgər Satış tipi seçilməyibsə sale_type_id = 0 olur. Bu zaman xəta verir və bunun üçün aşağıdakı əməliyyatı yerinə yetiririk.
        if (
            receipt.sale_type_id === undefined ||
            receipt.receipt_products.length === 0 ||
            receipt.payment_methods!.length === 0 ||
            !isAviableToSave
        ) {
            return dispatch(
                changeModalState({
                    product: undefined,
                    open: true,
                    type: "warning",
                    maxPrice: 0,
                    title: t("warning"),
                    optional: {
                        salesTypeNotSelect: receipt.sale_type_id === 0,
                        productNotSelect: receipt.receipt_products.length === 0,
                        paymentNotSelect: receipt.payment_methods!.length === 0,
                        totalNotMatch: !isAviableToSave,
                    },
                }),
            );
        }

        const _receipt = {
            ...receipt,
            payment_methods,
            user_id: auth.user?.id,
            meta: {
                ...receipt.meta,
                ...{
                    user: {
                        name: auth.user?.first_name,
                        role: auth?.user?.role,
                    },
                },
            },
            closed_at: new Date().getTime().toString(),
            deleted_at: null,
            updated_at: null,
            customer_id: receipt.customer_id === "" ? null : receipt.customer_id,
        };

        delete _receipt.saleType;

        withLoading(() =>
            Ajax.post({
                url: "receipts",
                data: _receipt,
                headers: {
                    "x-all-venues": "0",
                },
            }),
        )
            .then(resp => {
                dispatch(setInitialValues(null));
                history.push("/sales/receipts");
            })
            .catch((error: CustomError) => {
                return toaster.push(
                    <Message type="error" showIcon closable>
                        {error.message}
                    </Message>,
                );
            });
    };

    if (!webCashierIntegration?.status)
        return (
            <div
                style={{
                    width: "100%",
                    height: "calc(100vh - 70px)",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                }}
            >
                <h3>
                    <TText tkey="web_cashier" /> <TText tkey="deactivated" />
                </h3>
            </div>
        );

    return (
        <div className="h-100 web-cashier-form">
            <CProductsDrawer />
            <Title title={t("create_receipt")} forceLink={true} />
            <HeaderRight formSubmit={{ isLoading, onSave }} />
            <div className="container">
                <div className="left-side">
                    <ReceiptPageTable sellerList={sellerList?.data} />
                </div>
                <div className="right-side">
                    <div className="title">
                        <TText tkey="order_details" />
                    </div>
                    <div className="item">
                        <div className="label">
                            <TText tkey="sales_type" />
                        </div>
                        <SelectPicker
                            test-id="sales_type"
                            data={salesTypes?.data}
                            value={receipt.sale_type_id}
                            disabled={!!receipt.sale_type_id && !saleTypeChangeStatus}
                            labelKey={"name"}
                            valueKey={"id"}
                            cleanable={false}
                            searchable={false}
                            renderMenuItem={(label, item) => <span test-id="sales_type_item">{label}</span>}
                            placeholder={t("select", { name: t("sale_type") })}
                            onSelect={(value, item) => changeSaleType(item as any)}
                        />
                    </div>
                    <ReceiptCustomer />
                    <ReceiptOtherOptions sellerList={sellerList?.data} />
                    <ReceiptPaymentPlans payment_method_id={paymentMethodId} />
                    <ReceiptModals />
                </div>
            </div>
        </div>
    );
};

export default ReceiptCreatePage;
