import { FIListItemProps } from "components/FormItems/FormItemsList";
import { GridButtonRemove } from "components/NewForm/Grid/GridButtonRemove";
import { GridInput } from "components/NewForm/Grid/GridInput";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { GridSelect } from "components/NewForm/Grid/GridSelect";
import { useProductUnitOptions } from "hooks/useProductUnits";
import { store } from "redux/store";
import { GridProductSelectItem } from "components/NewForm/Grid/GridProductSelectItem";
import React from "react";
import { isEditingPage } from "lib/isEditingPage";

interface IProps extends FIListItemProps<WasteItemField> {
    refineItemOnInsert: (d: IProduct) => WasteItemField;
}

export interface WasteItemField {
    product_id: number;
    quantity: number;
    unit_id: string | number;
    product_full_name?: string;
    id?: string | number;
    disabled: boolean;
}

const WasteItem: FCC<IProps> = ({ field, fields, index: i, remove, form, error, refineItemOnInsert }) => {
    const isEdit = isEditingPage();

    const onSelectProduct = (id: number) => {
        const d = store.getState().data.products.byId[id];
        form.setValue(`items[${i}]`, refineItemOnInsert(d));
    };

    const items = form.watch("items") ?? [];

    const removeItem = async (index: number) => {
        remove(index);
    };
    const unitForSelect = useProductUnitOptions(field.product_id);

    return (
        <>
            <GridProductSelectItem
                onSelect={onSelectProduct}
                disabled={field.disabled}
                name={`items[${i}].product_id`}
            />

            {field.id && isEdit ? (
                <GridItemPlain value={unitForSelect.find(u => u.value === field.unit_id)?.label} />
            ) : (
                <GridSelect
                    options={unitForSelect}
                    name={`items[${i}].unit_id`}
                    selectProps={{
                        searchable: false,
                        cleanable: false,
                    }}
                />
            )}

            <GridInput name={`items[${i}].quantity`} index={i} trigger={form.trigger} type="number" decimals={[0, 4]} />

            <GridButtonRemove onClick={() => removeItem(i)} disabled={items.length < 2} />
        </>
    );
};

export default WasteItem;
