import { EMPTY_DATA } from "config/main";
import { getFormatedDate } from "lib/utils";
import { format, isValid } from "date-fns";
import { CSSProperties } from "react";

interface DoubleDataColumnProps {
    type?: "date-time" | "double-data" | "date-time-reverse";
    date?: string;
    testId?: string;
    value1?: string;
    key1?: string;
    key2?: string;
    value2?: string;
    className?: string;
    firstColStyle?: CSSProperties;
    secColStyle?: CSSProperties;
    style?: CSSProperties;
    showSeconds?: boolean;
}

const DoubleDataColumn: FCC<DoubleDataColumnProps> = ({
    type = "double-data",
    value1,
    value2,
    date,
    key1,
    key2,
    testId,
    firstColStyle,
    secColStyle,
    style,
    className,
    showSeconds,
}) => {
    const sDate = String(date);
    const objDate = new Date(sDate);

    switch (type) {
        case "date-time":
            return (
                <div
                    style={{ marginTop: "-0.6rem", fontSize: "12px", ...style }}
                    test-id={testId}
                    className={className}
                >
                    <div>{isValid(objDate) ? format(objDate, showSeconds ? "HH:mm:ss" : "HH:mm") : EMPTY_DATA}</div>
                    <div style={{ fontSize: "11px" }} className="text-empty text-12">
                        {getFormatedDate(sDate, false)}
                    </div>
                </div>
            );
        case "date-time-reverse":
            return (
                <div
                    style={{ marginTop: "-0.6rem", fontSize: "12px", ...style }}
                    test-id={testId}
                    className={className}
                >
                    <div>{getFormatedDate(sDate, false)}</div>
                    <div style={{ fontSize: "11px" }} className="text-empty text-12">
                        {isValid(objDate) ? format(objDate, "HH:mm") : EMPTY_DATA}
                    </div>
                </div>
            );
        case "double-data":
            return (
                <div
                    style={{ marginTop: value1 && value2 ? "-0.6rem" : 0, fontSize: "1", ...style }}
                    test-id={testId}
                    className={className}
                >
                    <div style={{ fontSize: "12px", ...firstColStyle }}>{`${key1 || ""}${value1 || ""}`} </div>
                    {value2 && (
                        <div className="text-empty" style={{ fontSize: "11px", ...secColStyle }}>
                            {" "}
                            {`${key2 || ""} ${value2 || ""}`}
                        </div>
                    )}
                </div>
            );
        default:
            return null;
    }
};

export default DoubleDataColumn;
