import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";
import { t } from "lib/i18n";

interface IPhone {
    value: string;
}

export const CustomerSchema: ValidationSchema = {
    name: ruleset.required,
    email: ruleset.email,
    date_of_birth: ruleset.birthday,
    phones: [
        {
            value: (current: string, formValues: { phones: IPhone[] }) => {
                if (formValues.phones.map(p => p.value).filter(p => p === current).length > 1) {
                    return t("phone_must_be_unique");
                }
            },
        },
    ],
};
