import { t } from "lib/i18n";
import Widget from "../../components/Widget";
import { useDataProvider } from "components/DataTable/useDataProvider";
import CChart from "../../components/Chart";
import { formatByType, getPointerLabel } from "lib/chart-utils";
import _ from "lodash";
import NoData from "components/NoData";
import { useDepot } from "hooks/useDepot";
import { IconButton } from "rsuite";
import CIcon from "../../components/CIcon";
import { iBarChart, iPieChart } from "assets/icons/Icons";
import { useCurrency } from "hooks/useCurrency";
import { useSetting } from "hooks/useSetting";
import { passCashRestartCheckStart } from "lib/passCashRestartCheckStart";
import { passCashRestartCheckEnd } from "lib/passCashRestartCheckEnd";

const TopCategoryWidget: FCC = () => {
    const [chartType, setChartType] = useDepot("TopCategoryWidget", "column");
    const cashRestart = useSetting("cash_restart")?.value;

    const dp = useDataProvider({
        url: "reports/category",
        sort: ["total_cash", "-1"],
        params: {
            date: [passCashRestartCheckStart(cashRestart), passCashRestartCheckEnd(cashRestart)],
        },
        with: ["category", "product.unit"],
        refineList: list => {
            return list?.map(l => {
                return {
                    deleted_at: null,
                    id: l.category_id,
                    image: null,
                    name: l.category?.name,
                    total_cash: l.total_cash,
                    total_cost: l.total_cost,
                    total_count: l.total_count,
                };
            });
        },
    });

    const btnProps = (name: string): any => ({
        size: "xs",
        appearance: name === chartType ? "primary" : undefined,
        onClick: () => setChartType(name),
    });

    const data = dp.data?.slice(0, 5).map((category: any) => ({ y: category.total_cash, name: category.name }));
    const currency = useCurrency();

    return (
        <Widget
            title={t("most_selling_category")}
            headerButtons={
                <>
                    <IconButton className="mr-2" icon={<CIcon path={iBarChart} />} {...btnProps("column")} />
                    <IconButton className="mr-2" icon={<CIcon path={iPieChart} />} {...btnProps("pie")} />
                </>
            }
        >
            {_.isNil(_.find(data, (val: any) => val.y > 0)) ? (
                <NoData />
            ) : (
                <CChart
                    options={{
                        chart: { height: 200 },
                        xAxis: {
                            categories: data.map((m: any) => m.name),
                        },
                        tooltip: {
                            useHTML: true,
                            headerFormat: "<small>{point.key}</small><table>",
                            pointFormatter() {
                                return getPointerLabel(this, "money", currency);
                            },
                            footerFormat: "</table>",
                        },
                        plotOptions: {
                            column: {
                                dataLabels: {
                                    enabled: true,
                                    formatter() {
                                        return formatByType("money", this.y, currency);
                                    },
                                },
                            },
                        },
                    }}
                    series={[{ type: chartType, data, name: t("total_cash") } as any]}
                />
            )}
        </Widget>
    );
};

export default TopCategoryWidget;
