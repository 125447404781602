import { useFormTitle } from "components/Header/Title";
import React, { FC, useEffect } from "react";
import _ from "lodash";
import HeaderRight from "../../../../components/Header/HeaderRight";
import TabsView from "../../../../components/TabsView";
import { useParams } from "react-router-dom";
import ProductMappingProductPage from "./ProductMappingProductTab";
import { useCForm } from "hooks/useCForm";
import CForm from "components/Form/CForm/CForm";
import JsonView from "../../../../components/JsonView";
import ProductMappingModifierPage from "./ProductMappingModifierTab";
import { modifierGroupsSlice, modifiersSlice } from "redux/features/model/slice";
import { useAppDispatch } from "hooks/useRedux";
import { t } from "lib/i18n";
import Ajax from "../../../../lib/Ajax";
import { ProductMappingFormParams } from "pages/menu/product-mapping/menu-product-mapping.types";

const ProductMappingFormPage: FC = () => {
    const params = useParams<ProductMappingFormParams>();
    const dispatch = useAppDispatch();
    const serviceName = params?.service_name;

    useEffect(() => {
        dispatch(modifierGroupsSlice.fetchData(""));
        dispatch(modifiersSlice.fetchData(""));
    }, []);

    const form = useCForm({
        url: `integrations/${serviceName}/call-action?action=updateMapper`,
        mode: "all",
        getData: () => Ajax.get({ url: `integrations/${serviceName}/call-action?action=getMenu` }),
        refine: (d: IProductMapping[]) => {
            const products: IProductMapping[] = [];
            const modifiers: IProductMappingModifierGroup[][] = [];
            d.forEach(data => {
                products.push(_.omit(data, "modifiersGroups"));
                data.modifiersGroups?.forEach(mg => {
                    const _modifiers: IProductMappingModifierGroup[] | IProductMappingModifier[] = [];
                    _modifiers.push(Object.assign(_.omit(mg, "modifiers"), { group: true }));
                    mg.modifiers?.forEach(mgModifier => {
                        _modifiers.push(mgModifier);
                    });
                    modifiers.push(_modifiers);
                });
            });

            return {
                products,
                modifiers,
            };
        },
        prepareForSubmit: (d: { products: IProductMapping[]; modifiers: IProductMappingModifierGroup[][] }) => {
            const products: IProductMapper[] = d?.products?.filter(dt => !!dt.mapper)?.map(dt => dt.mapper) ?? [];
            const modifiers: IProductMapper[] = [];
            d?.modifiers?.forEach((ms: IProductMappingModifierGroup[]) => {
                ms.forEach(m => {
                    if (m.mapper) {
                        modifiers.push(m.mapper);
                    }
                });
            });
            return {
                payload: {
                    mapper: [...products, ...modifiers],
                },
            };
        },
    });

    return (
        <div>
            {useFormTitle(t("product_mapping"), t("edit"))}
            <HeaderRight formSubmit={form} />

            <CForm {...form} onSave={form.onSave}>
                <TabsView
                    tabs={[
                        {
                            label: t("product"),
                            Component: ProductMappingProductPage,
                        },
                        {
                            label: t("modifier"),
                            Component: ProductMappingModifierPage,
                        },
                    ]}
                />

                <JsonView />
            </CForm>
        </div>
    );
};

export default ProductMappingFormPage;
