import { useAppSelector } from "hooks/useRedux";

// todo: this is not provider file just helper selectors.

export const usePackages = () => useAppSelector(state => state.packages);

export const useIntegrationByName = (service_name: IntegrationName) => {
    const integrations = useAppSelector(state => state.packages?.integrations);
    if (!integrations) return null;
    return integrations?.find((i: IIntegration) => i.service_name === service_name) ?? null;
};

export const useIntegrations = () => useAppSelector(state => state.packages?.integrations);
