import {
    DndContext,
    DragOverlay,
    DropAnimation,
    KeyboardSensor,
    PointerSensor,
    closestCenter,
    defaultDropAnimationSideEffects,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import {
    SortableContext,
    arrayMove,
    sortableKeyboardCoordinates,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { FC, ReactNode, useMemo, useState } from "react";
import { VirtualizedList } from "core/components/VirtualizedList";

interface SortableListProps {
    fields: any[];
    children: (field: any, i: number) => ReactNode;
    onUpdate: (newList: any[]) => void;
    virtualize?: boolean;
}
const dropAnimationConfig: DropAnimation = {
    sideEffects: defaultDropAnimationSideEffects({
        styles: {
            active: {
                opacity: "0.4",
            },
        },
    }),
};

export const FISortableList: FC<SortableListProps> = ({ fields, children, onUpdate, virtualize }) => {
    const [active, setActive] = useState<any | null>(null);
    const activeFieldIndex = useMemo(() => fields.findIndex(f => f.xxid === active?.id), [active, fields]);
    // console.log("SortableList", activeId, activeField);

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    return (
        <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={({ active, over }) => {
                if (active.id !== over?.id) {
                    const oldIndex = fields.findIndex(f => f.xxid === active.id);
                    const newIndex = fields.findIndex(f => f.xxid === over?.id);

                    const newList = arrayMove(fields, oldIndex, newIndex);
                    onUpdate(newList);
                }

                setActive(null);
            }}
            onDragStart={({ active }) => setActive(active)}
            onDragCancel={() => {
                setActive(null);
            }}
        >
            <SortableContext items={fields.map(f => f.xxid)} strategy={verticalListSortingStrategy}>
                {virtualize ? <VirtualizedList items={fields} children={children} /> : fields.map(children)}
            </SortableContext>

            <DragOverlay
                className="tw-grid tw-grid-cols-subgrid tw-gap-2 tw-shadow "
                dropAnimation={dropAnimationConfig}
            >
                {active ? children(fields[activeFieldIndex], activeFieldIndex) : null}
            </DragOverlay>
        </DndContext>
    );
};
