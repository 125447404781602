import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import _ from "lodash";
import { useParams } from "react-router-dom";
import CForm from "components/Form/CForm/CForm";
import CheckboxFormGroup from "../../../components/Form/CheckboxFormGroup";
import RemoteSelectFormGroup from "../../../components/Form/RemoteSelectFormGroup";
import TextFormGroup from "../../../components/Form/TextFormGroup";
import HeaderRight from "../../../components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import JsonView from "../../../components/JsonView";
import { CategoryTypeEnum, InventoryBehavior } from "config/constants";
import { useCForm } from "hooks/useCForm";
import { translateUnit } from "lib/utils";
import { dummyIngredientData, IngredientModel } from "models/GoodsModel";
import IngredientUnit, { dummyUnit } from "./IngredientUnit";
import React, { useEffect } from "react";
import { FormItems } from "components/FormItems/FormItems";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { FormItemsAddButton } from "components/FormItems/FormItemsAddButton";
import { useAppDispatch } from "hooks/useRedux";
import { unitsSlice } from "redux/features/model/slice";
import MenuCategorySelectFormGroup from "../../../components/Form/CategorySelectFormGroup/MenuCategorySelectFormGroup";
import { StorageSelectFormGroup } from "components/Form/adhoc/StorageSelectFormGroup";

const IngredientCreatePage: FCC = () => {
    const params = useParams<any>();
    const dispatch = useAppDispatch();
    const form = useCForm({
        url: "product/ingredient",
        with: ["packages"],

        dummyData: dummyIngredientData,
        model: IngredientModel(),
        refine: (d: any) => {
            d.inventory_behavior =
                d.inventory_behavior === InventoryBehavior.MINUS_INGREDIENTS
                    ? InventoryBehavior.MINUS_INGREDIENTS
                    : InventoryBehavior.MINUS_SELF;

            if (Array.isArray(d.properties) && d.properties.length === 0) {
                d.properties = {};
            }

            return d;
        },
        prepareForSubmit: d => {
            if (d.inventory_behavior) {
                d.inventory_behavior = InventoryBehavior.PASSIVE;
            } else {
                d.inventory_behavior = InventoryBehavior.MINUS_INGREDIENTS;
            }
            d.low_stock = d.low_stock || 0;
            d.unit_weight = d.unit_weight || 0;
            return d;
        },
    });
    const unit = form.form.watch("unit");
    const hasModifications = form.form.watch("has_modifications");

    const isInventoryBehaviorChangeable = form.form.watch("is_inventory_behavior_changeable") ?? true;

    useEffect(() => {
        dispatch(unitsSlice.fetchData(""));
    }, []);

    return (
        <div className="tw-p-4">
            {useFormTitle(form.id, t("ingredient"))}
            <HeaderRight formSubmit={form} showSaveCreateButton={!form.id} />
            <CForm form={form.form} onSave={form.onSave}>
                <TextFormGroup name="name" errorPlacement="rightEnd" focus={true} />
                <TextFormGroup
                    name="barcode"
                    compProps={{
                        disabled: hasModifications,
                        onPressEnter: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                            e.key === "Enter" && e.preventDefault();
                        },
                    }}
                    errorPlacement="rightEnd"
                />

                <MenuCategorySelectFormGroup
                    name="accounting_category_id"
                    label={t("accounting_category")}
                    style={{ width: "100%" }}
                    types={[CategoryTypeEnum.ACCOUNTING]}
                    type={CategoryTypeEnum.ACCOUNTING}
                    compProps={{
                        cleanable: true,
                    }}
                />

                <MenuCategorySelectFormGroup
                    name="category_id"
                    label={t("category")}
                    style={{ width: "100%" }}
                    types={[CategoryTypeEnum.INGREDIENT]}
                    compProps={{
                        cleanable: true,
                    }}
                />

                <RemoteSelectFormGroup
                    name="unit_id"
                    searchLocal={true}
                    remote={{
                        url: "unit",
                        filters: { categories: [["type", "!=", 4]] },
                        refineList: d => d?.map(translateUnit),
                    }}
                    compProps={{
                        placeholder: "Select unit",
                    }}
                />
                <TextFormGroup
                    name="unit_weight"
                    label={t("unit_weight")}
                    postFix={unit?.symbol}
                    compProps={{ style: { width: "300px" } }}
                    errorPlacement="topEnd"
                    type="number"
                />

                <TextFormGroup
                    className="tw-text-secondary-200 tw-mb-2"
                    name="low_stock"
                    type={"number"}
                    compProps={{
                        description: (
                            <CheckboxFormGroup
                                className={"tw-text-secondary-200"}
                                controlWrapperProps={{}}
                                name={"properties.should_notify_low_stock"}
                                label={t("notification")}
                            />
                        ),
                    }}
                />

                <CheckboxFormGroup
                    name={"inventory_behavior"}
                    label={t("ignore_stock")}
                    compProps={{ disabled: !isInventoryBehaviorChangeable }}
                />

                {_.isNil(params.id) && (
                    <>
                        <div className="rs-form-group" role="group">
                            <div className="rs-form-group" role="group">
                                <label className="rs-control-label" style={{ fontSize: "16px", fontWeight: 600 }}>
                                    <TText tkey="inventory_management" />
                                </label>
                            </div>
                        </div>
                        <div className="mb-5">
                            <TextFormGroup
                                name="initial_stock.quantity"
                                label={t("initial_stock_quantity")}
                                postFix={unit?.symbol}
                                compProps={{ style: { width: "300px" } }}
                                errorPlacement="topEnd"
                                type="number"
                            />
                            <TextFormGroup
                                name="initial_stock.cost_price"
                                label={t("initial_stock_cost_price")}
                                errorPlacement="topEnd"
                                type="money"
                                compProps={{
                                    style: { width: "260px" },
                                }}
                            />
                            <StorageSelectFormGroup
                                name="initial_stock.storage_id"
                                label={t("initial_stock_storage_id")}
                            />
                        </div>
                    </>
                )}
                <>
                    <div className="rs-form-group" role="group">
                        <div className="rs-form-group" role="group">
                            <label className="rs-control-label" style={{ fontSize: "16px", fontWeight: 600 }}>
                                <TText tkey="units" />
                            </label>
                        </div>
                    </div>
                    <div className="mb-5">
                        <FormItems itemsFieldName="packages">
                            <FormItemsList
                                columns={[["name"], ["count", "right"], [""]]}
                                children={_props => <IngredientUnit {..._props} />}
                                footer={
                                    <div className="tw-grid tw-grid-cols-subgrid tw-col-span-4">
                                        <div className="tw-flex tw-col-span-3 tw-gap-2">
                                            <FormItemsAddButton dummyData={dummyUnit} />
                                        </div>
                                    </div>
                                }
                            />
                        </FormItems>
                    </div>
                </>
                <JsonView />
            </CForm>
        </div>
    );
};

export default IngredientCreatePage;
