import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { Button, Modal } from "rsuite";
import CModal, { ICModalProps } from "components/Drawers/CModal";
import CIcon from "components/CIcon";
import { iExclamationCircle2 } from "assets/icons/Icons";
import { useAppSelector } from "hooks/useRedux";
import { useSetting } from "hooks/useSetting";

interface IntegrationWarningModalProps extends ICModalProps {
    onConnect: (id: number) => void;
}

export const IntegrationConnectModal: FCC<IntegrationWarningModalProps> = ({ onConnect, ...props }) => {
    const currencies = useSetting("currency")?.extra?.symbols;
    const subscription = useAppSelector(state => state.packages.subscription);
    return (
        <CModal {...props} backdrop="static" size="xs">
            <Modal.Header style={{ paddingRight: 0 }}>
                <div className="d-flex align-items-center justify-content-center">
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ borderRadius: "50%", background: "#FEF3F2", height: "56px", width: "56px" }}
                    >
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ borderRadius: "50%", background: "#FEE4E2", height: "40px", width: "40px" }}
                        >
                            <CIcon
                                size={1}
                                path={iExclamationCircle2}
                                style={{ color: "#F39C12", fillRule: "evenodd" }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <h6 className="d-flex justify-content-center mb-3">
                    <TText tkey="warning" />
                </h6>

                <div className="d-flex align-items-center mt-2">
                    {/*<CIcon style={{ color: "#F39C12", marginRight: "10px" }} path={iAlert} />*/}
                    {t("addition_cost_will_be_added", {
                        price: `${props.data?.price} ${currencies[subscription?.currency?.toLowerCase() ?? "azn"]}`,
                    })}
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={props.onClose}
                    className="rs-btn05"
                    style={{
                        width: "48%",
                        height: "40px",
                        background: "#fff",
                        fontWeight: "bold",
                        borderRadius: "8px",
                        border: "solid 1px #D0D5DD",
                    }}
                >
                    <TText tkey="reject" />
                </Button>
                <Button
                    className="rs-btn05"
                    onClick={() => {
                        onConnect(props.data?.id);
                    }}
                    test-id={"connect-button"}
                    appearance="primary"
                    color="green"
                    style={{ fontWeight: "bold", borderRadius: "8px", width: "48%", height: "40px" }}
                >
                    <TText tkey="accept" />
                </Button>
            </Modal.Footer>
        </CModal>
    );
};
