import { ICDrawerProps } from "components/Drawers/CDrawer";
import { TText } from "components/i18n/TText";
import React, { useState } from "react";
import { Button, Modal } from "rsuite";
import { iAlert, iExclamationCircle2 } from "assets/icons/Icons";
import CIcon from "../../../../components/CIcon";
import CModal from "../../../../components/Drawers/CModal";
import { Colors } from "config/colors";
import Ajax from "../../../../lib/Ajax";
interface IDeleteModalProps extends ICDrawerProps {
    revalidate: () => void;
}
const DeleteBalanceModal: FCC<IDeleteModalProps> = props => {
    const [isLoading, setIsLoading] = useState(false);
    const handleDelete = async (e: React.SyntheticEvent) => {
        try {
            setIsLoading(true);

            await Ajax.post({
                url: `operation/${props.data?.id || props.dataId}/pay`,

                data: { supplier_remain: 1 },
            });
            setIsLoading(false);
            props.onClose?.(e);
            props.revalidate();
        } catch (e) {
            console.log(e, "error");
        }
    };
    return (
        <CModal {...props} size="xs">
            <Modal.Header style={{ paddingRight: 0 }}>
                <div className="d-flex align-items-center justify-content-center">
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ borderRadius: "50%", background: Colors.SoftRose, height: "56px", width: "56px" }}
                    >
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{
                                borderRadius: "50%",
                                background: Colors.LightSalmonPink,
                                height: "40px",
                                width: "40px",
                            }}
                        >
                            <CIcon
                                size={1}
                                path={iExclamationCircle2}
                                style={{ color: Colors.BrightRed, fillRule: "evenodd" }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column align-items-center">
                <h6 className="d-flex justify-content-center mb-3 " style={{ width: "80%" }}>
                    <span style={{ color: Colors.CobaltBlue, paddingRight: "6px" }}>#{props.data?.id}</span>
                    <span>
                        <TText tkey="cancel_supplier_payment" />
                    </span>
                </h6>
                <div
                    className="d-flex align-items-center"
                    style={{
                        background: Colors.OffWhite,
                        marginTop: "24px",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        width: "fit-content",
                    }}
                >
                    <CIcon path={iAlert} style={{ color: Colors.VividOrange, marginRight: "7px" }} />
                    <span style={{ color: Colors.BrightOrange }}>
                        <TText tkey="payment_sts_unp" />
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    onClick={props.onClose}
                    className="rs-btn05"
                    style={{
                        width: "48%",
                        height: "40px",
                        background: Colors.White,
                        fontWeight: "bold",
                        borderRadius: "8px",
                        border: `solid 1px ${Colors.SilverGray2}`,
                    }}
                >
                    <TText tkey="abort" />
                </Button>
                <Button
                    className="rs-btn05"
                    loading={isLoading}
                    onClick={e => handleDelete(e)}
                    appearance="primary"
                    color="green"
                    style={{ fontWeight: "bold", borderRadius: "8px", width: "48%", height: "40px" }}
                >
                    <TText tkey="tip_confirm" />
                </Button>
            </Modal.Footer>
        </CModal>
    );
};
export default DeleteBalanceModal;
