import { useDrawer } from "components/Drawers/useDrawer";
import { UseFormReturnType } from "hooks/useCForm";
import { multiplication } from "lib/utils";
import _ from "lodash";
import { ISupplyOperationItem, OnCreateOI } from "pages/inventory/types";
import { useState } from "react";
import { Notification, toaster } from "rsuite";
import { t } from "lib/i18n";

export function useSupplyImport(form: UseFormReturnType) {
    const importedItemResultModal = useDrawer();
    const [importedItemQuantity, setImportedItemQuantity] = useState(0);
    const [notImportedItemQuantity, setNotImportedItemQuantity] = useState(0);

    const addItemsFromXLS = (
        items: {
            cost: number;
            product_id: number;
            quantity: number;
            product: IProduct;
            // tax_rate: number;
            // discount_rate: number;
        }[],
    ) => {
        try {
            const newItems: OnCreateOI<ISupplyOperationItem>[] = [];
            for (const item of items) {
                const calcTotalCost = multiplication(item?.cost, item?.quantity);
                const product = item.product;
                const opitem: OnCreateOI<ISupplyOperationItem> = {
                    product_id: product.id,
                    unit_id: product.unit_id,
                    quantity: item.quantity || 0,
                    cost: item.cost || 0,
                    total_cost: calcTotalCost,
                    tax_rate: 0, // item.tax_rate || 0,
                    discount_rate: 0, // item.discount_rate || 0,
                    discount_value: 0,
                    tax_value: 0,
                    product: product,
                    subtotal: calcTotalCost,
                    operated_at: "",
                };
                newItems.push(opitem);
            }
            const oldItems = form.form.getValues("items");
            const firstOldItem = oldItems[0];

            if (firstOldItem && firstOldItem.product_id === null) {
                form.form.setValue("items", newItems);
            } else {
                form.form.setValue("items", [...oldItems, ...newItems]);
            }
        } catch (e) {
            toaster.push(
                <Notification type="error" header={t("error") + " (AIFXLS)"} closable>
                    {t("went_wrong")}
                </Notification>,
            );
        }
    };

    const importSheetCompleted = (response: any) => {
        if (_.isArray(response)) {
            const products = _.filter(response, r => !_.isNil(r.product)) ?? [];
            setImportedItemQuantity(products.length);
            setNotImportedItemQuantity(response.length - products.length);
            importedItemResultModal.setIsOpen(true);
            addItemsFromXLS(products);
        } else {
            toaster.push(
                <Notification type="error" header={t("error") + " (ISC)"} closable>
                    {t("went_wrong")}
                </Notification>,
            );
        }
    };

    return {
        importProps: {
            action: "operation/excel-actions-for-supply",
            onSuccess: importSheetCompleted,
        },
        importModal: {
            ...importedItemResultModal,
            import_count: importedItemQuantity,
            not_found_count: notImportedItemQuantity,
        },
    };
}
