import _ from "lodash";
import { ProductType } from "config/constants";

export const dummyModificatorGroup = (): Partial<IModifierGroup> => ({
    id: 0,
    max_select: 1,
    min_select: 1,
    name: "",
    modificators: [],
    type: 0,
});

export const dummyModificatorMultiItem = (groupId: number): Partial<IModifier> => ({
    id: 0,
    group_id: groupId,
    brutto: 0,
    name: "",
    ingredient_id: 0,
    max_count: 0,
    min_count: 0,
    default_count: 0,
    price: 0,
    cost_price: 0,
    status: true,
});

export const dummyModificatorSingleItem = (groupId: number, defaultCount = 0): Partial<IModifier> => ({
    id: 0,
    group_id: groupId,
    brutto: 0,
    name: "",
    ingredient_id: 0,
    max_count: null,
    min_count: null,
    default_count: defaultCount,
    price: 0,
    cost_price: 0,
    status: true,
});

export const refineProductList = (ls: any[]) => {
    const refinedList = _.map(ls, (item: any) => {
        if (item.type === ProductType.GOODS && item.has_modifications) {
            item.modifications = _.map(item.modifications, (modification: any) => {
                const modif = _.find(ls, { id: modification.id });
                modif.slug = `${item.slug} ${modif?.slug} ${modif?.barcode}`;
                modif.type = ProductType.GOODS;
                modif.o_type = ProductType.MODIFICATION;
                return modif;
            });
            item.slug = item.modifications.map((m: any) => m.slug).join("-");
        } else {
            item.slug = `${item.slug} ${item.barcode}`;
        }
        return item;
    });

    return _.uniqBy(
        _.filter(refinedList, (productItem: any) => productItem.type !== ProductType.MODIFICATION)?.reduce(
            (newList, item) => {
                item.o_type = item.type;
                return newList.concat(item).concat(
                    item.modifications?.map((m: any) => ({
                        ...m,
                        type: ProductType.GOODS,
                        o_type: ProductType.MODIFICATION,
                    })) ?? [],
                );
            },
            [] as any[],
        ),
        "id",
    ) as any;
};
