import { getLabel } from "components/Form/form-utils";
import _ from "lodash";
import React, { useContext, useEffect } from "react";
import { CheckPicker, SelectPicker, SelectPickerProps } from "rsuite";
import { IDataProvider } from "../useDataProvider";
import { FiltersContext } from "./FiltersContainer";
import { usePopupContainer } from "hooks/usePopupContainer";
import { t } from "lib/i18n";

export interface ISelectFilterProps<T> {
    dp?: IDataProvider;
    fields: string;
    labelField?: string;
    searchField?: string;
    pickerProps?: Omit<SelectPickerProps<T>, "data">;
    options: {
        value: string | number;
        label: string;
    }[];
    comparison_value?: number;
    fieldType?: "params" | "filters";
    cleanable?: boolean;
    width?: string | number;
    maxWidth?: string | number;
    multi?: boolean;
    hasAllOption?: boolean;
}

/**
 * This component is used to create a select filter for the data table.
 * It uses url to store filters.
 * Example urls:
 *  - sales/order-notifications?{"filters":{"integration":["integration","call_center"]}}
 *  - finance/accounts/customer?{"filters":{"amount":["amount","<",0],"group_id":["group_id",[3]]}}
 *
 *  Well, as you can see there are two types of filters:
 *  1. { [FILTER_NAME]: ["FILTER_NAME", "VALUE"] }
 *  2. { [FILTER_NAME]: ["FILTER_NAME", "OPERATOR", "VALUE"] }
 *
 *  Operators are customizable. They could be any string.
 */

const SelectFilter: FCC<ISelectFilterProps<any>> = ({
    width = 200,
    maxWidth = 200,
    dp,
    fieldType = "filters",
    ...props
}) => {
    const filtersContext = useContext(FiltersContext);
    dp = dp || filtersContext.dp;
    const urlDataManager = dp[fieldType];

    const value = getValue(urlDataManager, !!props.multi, !!props.hasAllOption, props.fields) || "";

    const { container } = usePopupContainer();

    const comparisonOperator = (value: string) => {
        if (!_.isNil(props.comparison_value)) {
            return value ? [props.fields, value, props.comparison_value] : "";
        }
        return [props.fields, value];
    };

    useEffect(() => {
        if (props.fields === "suspicious" && dp.filters.getValue("suspicion") && dp.filters.getValue("suspicious")) {
            urlDataManager.remove("suspicion");
        }
        // eslint-disable-next-line
    }, [value]);

    // used to add filter toglobal dp object
    const onSelect = (val: any) => {
        if (val !== "__all__") {
            if (fieldType === "params") {
                dp.params.add(props.fields, val);
            } else {
                val = comparisonOperator(val);
                dp.filters.add(val, props.fields);
            }
        } else {
            urlDataManager.remove(props.fields);
        }
    };

    const onSelectMulti = (val: any[]) => {
        if (val.length) {
            if (fieldType === "params") {
                dp.params.add(props.fields, val);
            } else {
                dp.filters.add([props.fields, val], props.fields);
            }
        } else {
            urlDataManager.remove(props.fields);
        }
    };

    const pickerProps: Partial<SelectPickerProps<any>> & { "test-id": string } = {
        placeholder: getLabel(props.fields),
        "test-id": `${props.fields}-filter`,
        ...props.pickerProps,
    };

    const options = [
        ...(props.hasAllOption
            ? [
                  {
                      value: "__all__",
                      label: t("all"),
                  },
              ]
            : []),
        ...(props.options ?? []),
    ];

    if (props.multi) {
        return (
            <CheckPicker
                data={options}
                onSelect={onSelectMulti}
                container={() => container.current!}
                value={value || []}
                cleanable={!!_.isNil(props.cleanable) && !!value}
                onClean={() => urlDataManager.remove(props.fields)}
                style={{ maxWidth, width }}
                {...pickerProps}
            />
        );
    }

    return (
        <SelectPicker
            data={options}
            onSelect={onSelect}
            container={() => container.current!}
            value={value}
            cleanable={!!_.isNil(props.cleanable) && value !== "__all__"}
            onClean={() => urlDataManager.remove(props.fields)}
            style={{ maxWidth, width }}
            {...pickerProps}
        />
    );
};

SelectFilter.defaultProps = {
    pickerProps: {
        searchable: false,
    },
};

export default SelectFilter;

const getValue = (
    urlDataManager: IDataProvider[keyof IDataProvider],
    isMulti: boolean,
    hasAllOption: boolean,
    fields: string,
) => {
    if (isMulti) {
        return urlDataManager.getValue(fields, []);
    }

    const value = urlDataManager.getFilter(fields) ?? [];

    if (value.length === 0 && hasAllOption) {
        return "__all__";
    }

    return _.isArray(value[0]) ? _.last(value[0]) : _.isArray(value) ? value[1] : value;
};
