import { EventEmitter } from "eventemitter3";

export class PromiseChannels extends EventEmitter {
    public map = new Map<symbol, Promise<any>>();
    public lastResolveFn: null | DummyFn = null;
    public hasFailed = false;
    public error: string | undefined;

    constructor(
        public size: number = 4,
        public delay: number = 0,
    ) {
        super();
    }

    public get finish(): Promise<void> {
        return new Promise(resolve => {
            this.on("finished", resolve);
        });
    }

    private callResolveOne = () => {
        if (typeof this.lastResolveFn === "function") {
            this.lastResolveFn();
            this.lastResolveFn = null;
        }
    };

    private resolvedOne(): void {
        this.emit("resolved-one");
        if (this.delay > 0) {
            setTimeout(this.callResolveOne, this.delay);
        } else {
            this.callResolveOne();
        }
        if (this.map.size === 0) {
            this.emit("finished");
        }
    }

    public add(p: Promise<void>): Promise<void> {
        const s = Symbol();
        this.hasFailed = false;
        this.map.set(s, p);
        p.then(() => {
            this.map.delete(s);
            this.resolvedOne();
        }).catch(e => {
            this.map.delete(s);
            this.resolvedOne();
            this.hasFailed = true;
            this.error = e;
        });

        return new Promise((resolve): void => {
            if (this.map.size < this.size) {
                resolve();
            } else {
                this.lastResolveFn = resolve;
            }
        });
    }
}
