import { t } from "lib/i18n";
import { useCForm } from "hooks/useCForm";
import { dummyMenuStation, StationModel } from "models/StationModel";
import { useFormTitle } from "components/Header/Title";

import HeaderRight from "../../../components/Header/HeaderRight";
import CForm from "components/Form/CForm/CForm";
import TextFormGroup from "../../../components/Form/TextFormGroup";
import ToggleFormGroup from "../../../components/Form/ToggleFormGroup";
import { iCheckBoxChecked, iClose } from "assets/icons/Icons";
import CIcon from "../../../components/CIcon";

const MenuStationCreatePage: FCC = props => {
    const form = useCForm({
        url: "station",
        // redirectUrl: "/menu/stations",
        dummyData: dummyMenuStation,
        model: StationModel(),
    });

    return (
        <div className="p-3">
            {useFormTitle(form.id, t("station"))}
            <HeaderRight formSubmit={form} showSaveCreateButton={!form.id} />
            <CForm form={form.form} onSave={form.onSave}>
                <TextFormGroup name="name" errorPlacement="rightEnd" focus={true} />
                <ToggleFormGroup
                    name="meta.station_reminder"
                    label={t("station_reminder")}
                    compProps={{
                        checkedChildren: <CIcon path={iCheckBoxChecked} style={{ marginBottom: "-3px" }} />,
                        unCheckedChildren: <CIcon path={iClose} style={{ marginBottom: "-3px" }} />,
                    }}
                />
                <ToggleFormGroup
                    name="printable"
                    label={t("printable")}
                    compProps={{
                        checkedChildren: <CIcon path={iCheckBoxChecked} style={{ marginBottom: "-3px" }} />,
                        unCheckedChildren: <CIcon path={iClose} style={{ marginBottom: "-3px" }} />,
                    }}
                />

                {/*<CheckboxFormGroup name='printable'/>*/}
            </CForm>
        </div>
    );
};

export default MenuStationCreatePage;
