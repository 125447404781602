import { format } from "date-fns";
import { useAppSelector } from "hooks/useRedux";
import Ajax from "lib/Ajax";
import { getDatePairFromNumbers } from "lib/pure";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import useSWR from "swr";
import { mergeDeepRight } from "lib/mergeDeepRight";

export type FiltersMap = Record<string, SearchRequestFilters>;

export interface IDataProviderConfig {
    url: string;
    with?: string[];
    withCount?: string[];
    sort?: string[];
    extraFilterKeys?: Array<string>;
    filters?: FiltersMap;
    params?: any;
    headers?: any;
    limit?: number;
    dataPath?: string;
    useUrl?: boolean;
    hasAllVenues?: boolean;
    localSort?: [string[], Array<"asc" | "desc">];
    refineList?: (list: any[], state: IUrlState) => any[];
    stop?: boolean;
    useDates?: boolean; // includes global dates values in url state
}

interface IUrlState {
    page: number;
    sort: string[];
    limit: number;
    filters: any;
    extraFilterKeys?: { badgeCount: number };
    params: any;
    stop?: boolean;
    url: string;
}

// const decode = (d: string) => JSON.parse(atob(d) || "{}");
const decode = (d: string) => JSON.parse(d || "{}");
// const encode = (d: any) => btoa(JSON.stringify(d));
const encode = (d: any) => JSON.stringify(d);
//TODO: add default filters (SupplierTransactionPage example)
export const useDataProvider = <T extends object = any>(conf: IDataProviderConfig) => {
    const history = useHistory();
    const loc = useLocation();
    const dates = getDatePairFromNumbers(useAppSelector(s => s.app.dates));

    const [query, queryStr] = useMemo<any>(() => getParsedQuery(loc.search), [loc.search]);
    const [state, setState] = useState(() => getUrlParams(conf, query, dates));
    const [selectedFilterCount, setSelectedFilterCount] = useState(0);
    const stateStr = encode(state);
    const configStr = encode(getUrlParams(conf, {}, dates));

    useEffect(() => {
        updateUrlState(getUrlParams(conf, query, dates));
    }, [configStr]);

    const updateUrlState: typeof setState = v => {
        if (!conf.useUrl) {
            setState(v);
            return;
        }

        const value = _.isFunction(v) ? v(state) : v;
        const location = {
            pathname: loc.pathname,
            search: "?" + encode(value),
        };

        if (history.location.search) {
            history.push(location);
        } else {
            history.replace(location);
        }
    };

    // effect to replace url with correct one only first time
    useEffect(() => {
        if (conf.useUrl && stateStr !== queryStr) {
            history?.replace({
                pathname: loc.pathname,
                search: "?" + stateStr,
            });
        }
    }, [conf.useUrl, history, loc.pathname]);

    // effect to update dp state to match the query in url
    useEffect(() => {
        if (conf.useUrl) {
            return history.listen((loc, action) => {
                if (action === "POP" || action === "PUSH") {
                    const [q] = getParsedQuery(loc.search);
                    setState(q);
                }
            });
        }
    }, [history, conf.useUrl]);

    const urlParams = {
        with: conf.with,
        ..._.omit(state, "filters", "params", "stop", "url"),
        filters: _.values(state.filters),
        ...state.params,
        withCount: conf.withCount,
    };

    // if(conf.useDates){
    //     urlParams.date = [format(dates[0], "yyyy-MM-dd"), format(dates[1], "yyyy-MM-dd")]
    // }
    const url = Ajax.buildUrl({
        url: conf.useUrl ? conf.url : state.url,
        params: urlParams,
    });

    const changeSelectedFilterCount = (count: number) => {
        setSelectedFilterCount(count);
    };

    const addFilter = (filter: SearchRequestFilters, filterName: string) => {
        if (state.page !== 1) {
            updateUrlState(v => {
                v.page = 1;
                return v;
            });
        }
        updateUrlState(prev => _.set({ ...prev }, ["filters", filterName], filter));
    };

    const addParam = (name: string, value: any) => {
        if (state.page !== 1) {
            updateUrlState(v => {
                v.page = 1;
                return v;
            });
        }
        updateUrlState(prev => _.set({ ...prev }, ["params", name], value));
    };

    const clearFilters = () => {
        updateUrlState(s => ({
            ...s,
            filters: conf.filters ?? {},
            params: clearParams(s),
        }));
    };

    const clearParams = (s: any) => {
        let params = conf.params ?? {};
        params = conf.useDates ? { ...params, ...{ date: s.params.date } } : params;

        return params;
    };

    const removeFilter = (name: string) =>
        updateUrlState(prev => _.set({ ...prev }, ["filters"], _.omit(prev.filters, name)));
    const removeParam = (name: string) =>
        updateUrlState(prev => _.set({ ...prev }, ["params"], _.omit(prev.params, name)));
    const setPage = (p: number) => updateUrlState(prev => _.set({ ...prev }, "page", p));
    const setPageSize = (size: number) => {
        updateUrlState({ ...state, limit: size, page: 1 });
    };
    const addSort = (name: string, value: any) =>
        updateUrlState((query: any) => Object.assign(query, { sort: [name, value] }));
    const {
        error,
        mutate: revalidate,
        isValidating,
        ...swr
    } = useSWR(
        conf.useUrl ? (conf.stop ? null : url) : state.stop ? null : url,

        (url: string) =>
            Ajax.get({ url, headers: { "x-all-venues": conf?.hasAllVenues === true ? "1" : "0", ...conf?.headers } }),
        {
            revalidateOnFocus: false,
        },
    );

    const retrievedData = _.get(swr.data, conf.dataPath ?? "data");
    const retrievedSorts = _.get(swr.data, "sorts");
    const fetchedAt = _.get(swr.data, "timestamp");
    const localSort = conf.localSort?.flat().join(",") ?? "";

    const { data, originalData } = useMemo(() => {
        let data: T[] = _.isArray(retrievedData) ? retrievedData?.slice(0) || [] : retrievedData;

        const originalData = data;

        if (conf.refineList) {
            data = conf.refineList(_.cloneDeep(data), state);
        }
        if (conf.localSort) {
            data = _.orderBy(data, ...conf.localSort);
        }

        return { data, originalData };
    }, [retrievedData, localSort]);

    // noinspection TypeScriptValidateTypes
    return {
        data,
        originalData,
        sorts: retrievedSorts,
        error,
        resp: data,
        urlParams,
        url: conf.url,
        isLoading: !data && !error,
        isValidating,
        page: state.page,
        pageSize: state.limit,
        conf,
        setPage,
        refinedTotal: data?.length || 0,
        total: swr.data?.total || swr.data?.data.length || 0, // sometimes total is not set, so taking the array length is better
        pageCount: Math.ceil((swr.data?.total ?? 0) / state.limit),
        setPageSize,
        revalidate: async () => {
            await revalidate();
            return true;
        },
        throttledRevalidate: _.throttle(revalidate, 3000),

        sort: {
            getSort: (name: string) => {
                return state.sort?.includes(name) ? _.last(state.sort) : "0";
            },
            add: addSort,
        },
        extraFilterKeys: {
            fields: conf.extraFilterKeys ?? [],
            selectedFilterCount: selectedFilterCount,
            changeSelectedFilterCount: changeSelectedFilterCount,
        },
        // filters
        filters: {
            all: state.filters,
            add: addFilter,
            remove: removeFilter,
            clear: clearFilters,
            //@eslint-disable-next-line
            getValue: <T = any,>(name: string, defaults?: T): T => {
                const f = _.get(state.filters, name);
                return f === undefined ? defaults : (_.last(f as any) as any);
            },
            getFilter: (name: string) => {
                return _.get(state.filters, name);
            },
            requiredFilters: conf.filters,
        },
        params: {
            all: state.params,
            getValue: <T = any,>(name: string, defaults?: T): T => {
                return _.get(state.params, name) ?? defaults;
            },
            getFilter: (name: string) => {
                return _.get(state.params, name);
            },
            add: addParam,
            remove: removeParam,
        },
        fetchedAt,
    };
};

export type IDataProvider<T extends object = any> = ReturnType<typeof useDataProvider<T>>;

const getUrlParams = (conf: IDataProviderConfig, query: any = {}, dates: [Date, Date]): IUrlState => {
    if (conf.useDates) {
        query.params = query.params ?? {};
        query.params.date = [format(dates[0], "yyyy-MM-dd"), format(dates[1], "yyyy-MM-dd")];
        //reset page when change date
        if (query.page && query.page !== 1) {
            query.page = 1;
        }
    }

    return mergeDeepRight(
        {
            page: 1,
            sort: conf.sort,
            limit: conf.limit ?? 50,
            filters: conf.filters ?? {},
            params: conf.params ?? {},
            stop: conf.stop ?? undefined,
            url: conf.url ?? "",
        },
        conf.useUrl ? query : {},
    );
};

export const getParsedQuery = (str: string) => {
    // for get token in url
    if (str.includes("?token=")) {
        return [{}, null];
    }
    let queryStr = null;
    try {
        queryStr = decodeURIComponent(str.substr(1));
    } catch (ex) {
        queryStr = str.substr(1);
    }
    const query = decode(queryStr);
    return [query, queryStr];
};
