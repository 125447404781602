import SelectFormGroup from "components/Form/SelectFormGroup";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import { FinanceAccountDefaultFilters } from "config/constants";
import { useData } from "hooks/useData";
import _ from "lodash";
import { useSetting } from "hooks/useSetting";
import { t } from "lib/i18n";

type TCurrencyPosition = "start" | "end" | "none";

const getLabel = (key: TCurrencyPosition): string => {
    const labels: Record<TCurrencyPosition, string> = {
        start: t("currency_start"),
        end: t("currency_end"),
        none: t("none"),
    };
    return labels[key];
};

const FinanceSettingsTab: FCC = () => {
    const accounts = useData<IAccount[]>("finance/balance", { filters: _.values(FinanceAccountDefaultFilters) });
    const moneyFormat = useSetting("money_format");
    const moneyFraction = useSetting("money_fraction");
    const currencyPosition = useSetting("currency_position");

    return (
        <div className="p-3">
            <ToggleFormGroup name="finance_customer_minus.value" />

            <SelectFormGroup
                name="finance_terminal_balance.value"
                data={accounts.data?.map(d => ({ id: d.id.toString(), name: d.name })) ?? []}
            />

            <SelectFormGroup
                name="money_format.value"
                data={moneyFormat.options.map(option => ({
                    id: option,
                    name: moneyFormat.extra[option].display,
                }))}
            />

            <SelectFormGroup
                name="money_fraction.value"
                data={moneyFraction.options.map(option => ({
                    id: option,
                    name: t(moneyFraction.extra[option]),
                }))}
                compProps={{
                    defaultValue: moneyFraction.default,
                }}
            />

            <SelectFormGroup
                name="currency_position.value"
                data={currencyPosition.options.map(option => ({
                    id: option,
                    name: getLabel(option),
                }))}
                compProps={{
                    defaultValue: currencyPosition.default,
                }}
            />
        </div>
    );
};

export default FinanceSettingsTab;
