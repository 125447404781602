import CModal from "components/Drawers/CModal";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { ASSETS_BASE_URL } from "config/main";
import { useData } from "hooks/useData";
import { useLoading } from "hooks/useLoading";
import Ajax from "lib/Ajax";
import { FC, useEffect, useState } from "react";
import { Button, Message, Modal, toaster } from "rsuite";
import { PaymentBox } from "./payment-box";
import { PeriodBox } from "./period-box";
import { ICDrawerProps } from "components/Drawers/CDrawer";

interface IPaymentMethod {
    method: string;
    register: boolean;
    type: string;
}

const paymentMethodLogo = (pm: IPaymentMethod): string => {
    if (pm.method === "MULTICARD") {
        return `${ASSETS_BASE_URL}/img/payment-methods/multicard.svg`;
    } else if (pm.type === "cash") {
        return `${ASSETS_BASE_URL}/img/payment-methods/cash.svg`;
    }

    return `${ASSETS_BASE_URL}/img/payment-methods/mc-visa.svg`;
};

const PaymentModal: FC<ICDrawerProps> = props => {
    const [selected, setSelected] = useState("");
    const payments = useData<any[]>("payment/available");
    const prices = useData<any[]>("payment/prices");
    useEffect(() => {
        const secondElement = prices.data?.[1];
        prices.data?.splice(1, 1);
        prices.data?.push(secondElement);
    }, [prices.data]);
    const { isLoading, withLoading } = useLoading();
    const paymentsList: IPaymentMethod[] = payments.data || [];
    const [selectedOffer, setSelectedOffer] = useState(1);

    useEffect(() => {
        setSelected(paymentsList?.[0]?.method ?? "");
        setSelectedOffer(1);
    }, [props.isOpen]);

    const onClickPay = () => {
        const method = selected === "APP" ? "YIGIM" : selected;
        const url = `payment/register/${method}?monthly_period=${selectedOffer}`;
        withLoading(async () => {
            const register = await Ajax.get({ url: url });
            if (register.data.redirect) {
                window.open(register.data.redirect, "_blank");
            } else {
                toaster.push(
                    <Message type="error" showIcon closable>
                        {register.data.transaction.status}
                    </Message>,
                );
            }
        });
    };

    return (
        <CModal {...props} backdrop title={t("available_payments")}>
            <Modal.Body>
                <div id="PaymentPage">
                    <div className={"offers"}>
                        {prices.data?.length
                            ? prices.data?.map((price, index) => (
                                  <PeriodBox
                                      key={index}
                                      index={index}
                                      {...price}
                                      onSelect={setSelectedOffer}
                                      isActive={selectedOffer === price.monthly_period}
                                  />
                              ))
                            : null}
                    </div>
                    <div className="payment-type">
                        <h4 className={"title"}>
                            <TText tkey="payment_type" />
                        </h4>
                        <div className={"payment-methods"}>
                            {paymentsList.map((p, index) => (
                                <PaymentBox
                                    logo={paymentMethodLogo(p)}
                                    monthly_period={selectedOffer}
                                    key={index}
                                    {...p}
                                    onSelect={setSelected}
                                    isActive={selected === p.method}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer>
                <Button
                    loading={isLoading}
                    color="green"
                    className="px-3 rounded"
                    onClick={onClickPay}
                    disabled={isLoading}
                >
                    <TText tkey="next" />
                </Button>
            </Modal.Footer>
        </CModal>
    );
};

export default PaymentModal;
