import React, { memo } from "react";
import { FIListItemProps } from "components/FormItems/FormItemsList";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { Button } from "rsuite";
import { LinkIcon, LinkOffIcon } from "../../../../assets/icons";
import { cn } from "lib/utils";
import { GridSelect } from "components/NewForm/Grid/GridSelect";
import { MappingTypes } from "config/constants";
import { t } from "lib/i18n";
import { IModifierMappingModificatorGroups } from "pages/menu/product-mapping/menu-product-mapping.types";

export interface IModifierGroupItem extends FIListItemProps {
    modificator_groups: IModifierMappingModificatorGroups[];
}

const MappingModifierGroupItem = memo<IModifierGroupItem>(
    ({ modificator_groups, field, fields, itemsFieldName, index, remove, form }) => {
        const getName = (name: string, i?: number) => {
            return `${itemsFieldName}[${i ?? index}].${name}`;
        };

        const onSelectModifier = (id: number) => {
            form.setValue(getName("mapper"), {
                ...field.mapper,
                external_product_key: field.id,
                type: MappingTypes.MODIFIER_GROUP,
                clopos_product_key: id,
            });

            for (let i = 1; i < fields.length; i++) {
                form.setValue(getName("mapper", i), {
                    ...field.mapper,
                    external_product_key: field.id,
                    clopos_product_key: null,
                });
            }
        };

        const disconnectMapper = () => {
            fields?.forEach((field, index) => {
                form.setValue(getName("mapper", index), {
                    ...field?.mapper,
                    clopos_product_key: null,
                });
            });
        };

        return (
            <>
                <GridItemPlain value={t("group")} />

                <GridItemPlain value={field.name} />

                <div></div>

                <Button
                    disabled={!field?.mapper?.clopos_product_key}
                    onClick={() => disconnectMapper()}
                    className={cn("", {
                        "tw-bg-[#FFEDEC]": !!field?.mapper?.clopos_product_key,
                        "tw-bg-[#FAFBFC]": !field?.mapper?.clopos_product_key,
                    })}
                >
                    <img src={field?.mapper?.clopos_product_key ? LinkOffIcon : LinkIcon} alt={getName("name.tr")} />
                </Button>

                <GridItemPlain value={field?.mapper?.clopos_product_key} />

                <GridSelect
                    options={modificator_groups}
                    name={getName("mapper.clopos_product_key")}
                    onChange={val => onSelectModifier(Number(val))}
                    selectProps={{
                        searchable: true,
                        cleanable: false,
                    }}
                />
            </>
        );
    },
);

export default MappingModifierGroupItem;
