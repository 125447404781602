import { t } from "lib/i18n";

import { useFormTitle } from "components/Header/Title";
import { useCForm } from "hooks/useCForm";
import { CategoryModel, dummyCategory } from "models/CategoryModel";

import CForm from "components/Form/CForm/CForm";
import ColorPicker from "../../../components/Form/ColorPicker";
import TextFormGroup from "../../../components/Form/TextFormGroup";
import HeaderRight from "../../../components/Header/HeaderRight";
import JsonView from "../../../components/JsonView";
import _ from "lodash";
import UploaderFormGroup from "../../../components/Form/FileUploadFormGroup";
import { isUrl } from "lib/utils";
import { useParams } from "react-router-dom";
import { CategoryTypeEnum } from "config/constants";
import MenuCategorySelectFormGroup from "../../../components/Form/CategorySelectFormGroup/MenuCategorySelectFormGroup";
import React from "react";

const IngredientCategoryEditPage: FCC = () => {
    const params = useParams<any>();
    const form = useCForm({
        url: "category",
        model: CategoryModel(),
        // redirectUrl: "/menu/ingredientCategories",
        dummyData: dummyCategory,
        prepareForSubmit: (d: any) => {
            d.type = "INGREDIENT";
            const images = d.images?.length ? d.images[0].uuid : "";
            return _.omit(d, isUrl(images) ? ["image", "images"] : ["image"]);
        },
        refine: (d: any) => {
            d = _.pickBy(d, _.identity);
            return !_.isEmpty(d.media)
                ? {
                      images: [{ uuid: d.media[0]?.urls?.thumb }],
                      ...d,
                  }
                : d;
        },
    });

    return (
        <div className="tw-p-4 tw-border-0 tw-border-t tw-border-solid tw-border-slate-100">
            {useFormTitle(form.id, t("categories"))}
            <HeaderRight formSubmit={form} showSaveCreateButton={!form.id} />
            <CForm form={form.form} onSave={form.onSave}>
                <TextFormGroup
                    name="name"
                    errorPlacement="rightEnd"
                    focus={true}
                    compProps={{ "test-id": "category-name" }}
                />

                <MenuCategorySelectFormGroup
                    name="parent_id"
                    label={t("parent_category")}
                    style={{ width: "100%" }}
                    customFilter={params.id ? (category: ICategory) => category.id != params.id : null}
                    types={[CategoryTypeEnum.INGREDIENT]}
                    testId="category-parent"
                    compProps={{
                        cleanable: true,
                    }}
                />
                <ColorPicker
                    name="color"
                    label={t("display")}
                    className={!_.isEmpty(form.form.getValues("images")) ? "d-none" : "d-block"}
                />
                <UploaderFormGroup
                    name="images[0].uuid"
                    removeMainKey={true}
                    label={t("image")}
                    // uploadUrl={"media"}
                    compProps={{
                        action: "media",
                        multiple: false,
                        listType: "picture",
                        name: "media_file",
                    }}
                />
                <JsonView />
            </CForm>
        </div>
    );
};

export default IngredientCategoryEditPage;
