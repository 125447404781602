import { t } from "lib/i18n";
// import {optionsColumn} from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import Money from "components/mini/Money";
import { useData } from "hooks/useData";
import { iBox, iTrash } from "assets/icons/Icons";
import CIcon from "../../../../components/CIcon";
import RemoteCheckListFilter from "../../../../components/DataTable/Filters/RemoteCheckListFilter";
import ProductReturnDrawer from "../../../../components/Drawers/ProductReturnDrawer/ProductReturnDrawer";
import ReceiptDrawer from "../../../../components/Drawers/ReceiptDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import { ProductType, Trash } from "config/constants";
import { productNameAppend } from "lib/data-utils";
import PaymentMethodsCell from "../../receipts/PaymentMethodsCell";
import React from "react";
import DoubleDataColumn from "../../../../components/mini/DoubleDataColumn";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import classNames from "classnames";
import { Colors } from "config/colors";
import ToggleFilterDrawer from "../../../../components/DataTable/Filters/ToggleFilterDrawer";
import ProductReturnFilters from "./ProductReturnFilters";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";

const ProductReturnPage: FCC = props => {
    const url = "returns";
    const dp = useDataProvider({
        url,
        useUrl: true,
        useDates: true,
        with: ["user", "return_products.product", "transaction", "terminal", "customer"],
        sort: ["operated_at", "-1"],
    });
    const returnSpecific = useDrawer({ backdrop: false, overflowAuto: true });
    const receiptSpecific = useDrawer({ backdrop: false, overflowAuto: true });
    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;

    const filter = useDrawer();

    const summary = useData<any>("returns/report/total", {
        params: { date: dp.params.all?.date },
    });

    const cm = useColumns<any>("returns", [
        {
            key: "id",
            title: "ID",
            flexGrow: 0.1,
            render: d => (
                <div
                    style={{ color: Colors.CobaltBlue, cursor: "pointer" }}
                    onClick={() => {
                        receiptSpecific.hide();
                        returnSpecific.setDataId(d?.id);
                    }}
                >
                    {d.id}
                </div>
            ),
        },
        {
            key: "operated_at",
            type: "datetime",
            align: "center",
            flexGrow: 0.5,
            minWidth: 130,
            multiline: true,
        },
        {
            key: "products",
            flexGrow: 1.5,
            render: d => (
                <div style={{ fontSize: 12, wordBreak: "break-word" }}>
                    {d?.return_products?.map((i: any) => `${i?.product?.name} x${i?.quantity}`).join(" ,")}
                </div>
            ),
        },
        {
            key: "storage_id",
            flexGrow: 0.5,
            align: "left",
            render: d => {
                const size = 0.6;
                let isWasteValue,
                    isStorageValue = false;
                for (let i = 0; i < d.return_products?.length; i++) {
                    if (isWasteValue && isStorageValue) break;
                    if (d.return_products[i].waste) isWasteValue = true;
                    else isStorageValue = true;
                }
                if (isWasteValue && isStorageValue) {
                    return (
                        <span style={{ marginLeft: "10px" }}>
                            <CIcon path={iBox} size={size} style={{ color: Colors.TropicalGreen }} />
                            <CIcon path={iTrash} size={size} style={{ color: Colors.BrightRed }} />
                        </span>
                    );
                } else if (isStorageValue) {
                    return (
                        <CIcon path={iBox} size={size} style={{ marginLeft: "10px", color: Colors.TropicalGreen }} />
                    );
                } else {
                    return <CIcon path={iTrash} size={size} style={{ marginLeft: "10px", color: Colors.BrightRed }} />;
                }
            },
        },
        {
            key: "receipt_id",
            render: d => (
                <div
                    onClick={() => {
                        returnSpecific.hide();
                        receiptSpecific.setDataId(d?.receipt_id);
                    }}
                    style={{ color: Colors.CobaltBlue, cursor: "pointer" }}
                >
                    {d?.receipt_id}
                </div>
            ),
            flexGrow: 0.5,
        },
        {
            key: "user_id",
            title: t("user"),
            flexGrow: 0.5,
            minWidth: 100,
            render: e => {
                return (
                    <DoubleDataColumn
                        type="double-data"
                        value1={e?.user?.username}
                        value2={`${e?.user?.first_name} ${e?.user?.last_name}`}
                    />
                );
            },
            // e?.user?.username || e.receive_user_id,
        },
        {
            key: "receive_terminal_id",
            title: t("received_terminal_id"),
            flexGrow: 0.5,
            render: e => (
                <DoubleDataColumn type="double-data" value1={e?.terminal?.name} value2={e?.terminal?.id} key2={"ID:"} />
            ),
            // e?.terminal?.name || e.receive_terminal_id,
        },
        {
            key: "customer",
            title: t("customer"),
            flexGrow: 0.5,
            render: e => (
                <DoubleDataColumn type="double-data" value1={e?.customer?.name} value2={e?.customer?.id} key2={"ID:"} />
            ),
        },
        {
            key: "amount",
            align: "center",
            type: "money",
            flexGrow: 0.5,
            render: d => <Money style={{ fontSize: "12px" }} value={d?.amount} />,
            summary: () => (
                <Money
                    colored
                    style={{ color: Colors.CobaltBlue, fontSize: "14px" }}
                    value={summary.data && summary?.data?.total_amount}
                />
            ),
        },
        {
            key: "payment_methods",
            title: t("payment_method"),
            render: d => <PaymentMethodsCell r={d} />,
            align: "center",
            flexGrow: 0.85,
        },
        optionsColumn({
            dp,
            delete: url + "/:id",
            canDelete: "returns_manage_delete",
        }),

        // {key: "created_at", align: "right", sortable: true},
        // optionsColumn({
        //     dp,
        //     edit: '/sales/payment-methods/edit/:id',
        //     delete: "finance/paymentMethod/:id",
        //     deletable: (d: any) => d.id < 99,
        //     canEdit: "finance_payment_method_edit",
        //     canDelete: "finance_payment_method_delete"
        // })
    ]);

    return (
        <div className="h-100">
            {useTitle(t("product_return_setting"), dp.total)}
            <HeaderRight dp={dp} cm={cm} date reload print />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter className="search-input" fields="id" placeholder={t("data_id")} />

                    <RemoteCheckListFilter
                        fields="user_id"
                        remote={{
                            url: "user",
                            refineList: (d: any[]) => d?.filter(user => user.owner || !user.hide),
                        }}
                        searchField="username"
                        labelField="username"
                        pickerProps={{ placeholder: t("staff") }}
                        width={120}
                        maxWidth={150}
                    />
                    <RemoteCheckListFilter
                        fields="product_id"
                        searchField="name"
                        remote={{
                            url: "product",
                            sort: ["updated_at", "-1"],
                            limit: 20,
                            params: {
                                trash: Trash.TrashAndActive,
                            },
                            filters: {
                                type: isRestaurant
                                    ? [
                                          ["type", ProductType.GOODS],
                                          ["type", ProductType.DISH],
                                          ["type", ProductType.MODIFICATION],
                                      ]
                                    : [
                                          ["type", ProductType.GOODS],
                                          ["type", ProductType.MODIFICATION],
                                      ],
                            },
                            refineList: (products?: IProduct[]) =>
                                products
                                    ?.map(i => productNameAppend(i))
                                    ?.filter(f => !(f.type === ProductType.GOODS && f.has_modifications)) ?? [],
                        }}
                        pickerProps={{ placeholder: t("product") }}
                        width={120}
                        maxWidth={150}
                    />

                    <ToggleFilterDrawer isOpen={filter.isOpen} onChange={val => filter.setIsOpen(val)} />
                </div>
            </FiltersContainer>

            <ProductReturnFilters {...filter} dp={dp} />
            <DataTable
                dp={dp}
                columns={cm.columns}
                rowPadding={"25px 13px 0"}
                tableProps={{
                    headerHeight: 50,
                    rowHeight: 66,
                    affixHeader: true,
                    rowClassName: (d?: any) =>
                        classNames({
                            "deleted-row": d && !!d?.deleted_at,
                        }),
                }}
            />
            <ProductReturnDrawer {...returnSpecific} />
            <ReceiptDrawer {...receiptSpecific} />
        </div>
    );
};

export default ProductReturnPage;
