import { ICDrawerProps } from "components/Drawers/CDrawer";
import TextFormGroup from "components/Form/TextFormGroup";
import { t } from "lib/i18n";
import { useCForm } from "hooks/useCForm";
import { useEffect } from "react";
import CFormModal from "../../../components/Drawers/CFormModal";
import { ReasonType } from "config/constants";

const CreateReasonModal: FCC<ICDrawerProps> = props => {
    const form = useCForm({
        url: "reasons",
        redirectUrl: false,
        isModal: true,
        dummyData: () => ({
            name: "",
            type: ReasonType.wastes,
        }),
        prepareForSubmit: (d: any) => {
            d.type = ReasonType.wastes;
            return d;
        },
    });

    useEffect(() => {
        form.form.reset(
            {
                name: "",
                type: ReasonType.wastes,
            },
            { keepDirty: false },
        );
    }, [props.isOpen]);

    return (
        <CFormModal
            {...props}
            testId="create-reason-modal"
            receivedValue={(d: any, name: any) => {
                props.mainForm?.setValue("reason_id", d.id);
                form.form.setValue("name", "");
            }}
            form={form}
            formProps={{ layout: "horizontal" }}
            title={t("create_by_name", { name: t("reason") })}
            size="sm"
        >
            <TextFormGroup name="name" errorPlacement="rightEnd" focus={true} />
        </CFormModal>
    );
};

export default CreateReasonModal;
