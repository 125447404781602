import { t } from "lib/i18n";
import React, { CSSProperties, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import {
    setIsSingleSelect,
    setOpenProductSelector,
    setSelectedProducts,
} from "redux/features/productsSelector/productsSelector";
import { useProductSelectorContext } from "providers/ProductSelectorProvider";
import { Colors } from "config/colors";
import { cn } from "lib/utils";
import CIcon from "components/CIcon";
import { iChevronRight } from "assets/icons/Icons";
import { GridInputError } from "components/NewForm/Grid/GridInputError";
import { queueTrashProduct } from "redux/features/productsSelector/trashProductSelector";

export interface ICProductSelectorProps {
    onSelect: (value: number) => void;
    style?: CSSProperties;
    textStyle?: CSSProperties;
    disabled?: boolean;
    withBadge?: boolean;
    customBadge?: React.ReactNode;
    showStockCost?: boolean;
    className?: string;
    value: number;
    errorMessage?: string;
    textValue?: string;
}

const CProductSelector: FCC<ICProductSelectorProps> = ({
    onSelect,
    style,
    textStyle,
    disabled,
    className,
    errorMessage,
    value,
    textValue,
}) => {
    // todo: isEdit hooku ile evez olunsun. Bu hooku yaratmisham, amma mastere merge olunmayib hele
    const isEdit = window.location.pathname.indexOf("/edit") !== -1;
    const dispatch = useAppDispatch();
    const { byId, isLoading } = useAppSelector(state => state.data.products);
    const product = byId[value];
    const productName = (product?.name || product?.full_name) ?? textValue;

    useEffect(() => {
        /**
         * Product selector istifade olunan sehifeler var (meselen, supplies). Bu sehifelerde deyek ki, product secildi, elave olundu, bir muddet sonra
         * hemin mehsullari sildik. Bele olanda edit sehifesine girende, productin adini filan gostere bilmirdik, cunki backend ancaq silinmemish olan mehsullari qaytarirdi,
         * amma gosterilmeli idi. Bu sebebden sehifeye kecende bize lazim olan shertler odenerse, silinmish productlari cekirik ve gosteririk.
         *
         * Shertlerden birincisi sehifenin edit olmasidir, create zamani silinmish productlari cekmek lazim deyil cunki.
         * Ikinci shert ise redux-in load olub bitmesidir. Eks halda biz bilmirik ki, product silindiyi ucun undefined qaytarir, yoxsa
         * hele data fetch olunub bitmediyi ucun mu. Ona gore !isLoading sherti ile yoxlayiriq.
         * Ucuncu shert ise productin olub olmadigidir. Yoxdursa, demeli silinmish productdir.
         *
         * Bu shertler odenirse, hemin productlarin idlerini queue-e elave edirik. Ve queue-e productlar elave olunduqca debounce edib gozleyirik,
         * bitdikden sonra ise butun silinmish productlarin idlerini backende gonderib cekirik.
         *
         * Fetch etdiyimiz hisse: src/components/Drawers/CProductSelector/CProductsDrawer.tsx:102
         * Redux action: src/redux/features/productsSelector/trashProductSelector.ts:14
         * Jira ticket: https://clopos.atlassian.net/browse/CL-756?atlOrigin=eyJpIjoiY2VhYTk3NDFiMzk3NDAxMzhmMzBiZTVmMTY0YjA0NzciLCJwIjoiaiJ9
         */
        if (isEdit && !isLoading && !product) {
            dispatch(queueTrashProduct(value));
        }
    }, [isLoading, product]);

    const { updateConfig } = useProductSelectorContext();
    const handleClick = () => {
        if (disabled) return;
        updateConfig({ onProductSelectorSave: (ids: number[]) => onSelect(ids?.[0]) });

        dispatch(setIsSingleSelect(true));
        dispatch(setSelectedProducts([value]));
        dispatch(setOpenProductSelector(true));
    };

    return (
        <div
            className={cn(
                "tw-min-w-52 tw-rounded tw-bg-transparent tw-border tw-border-solid tw-border-slate-200 tw-px-2 tw-h-12",
                "active:tw-bg-slate-100",
                "hover:tw-transition-colors tw-duration-[1s] hover:tw-ring-1 tw-ring-blue-300",
                "hover:tw-border-blue-300 hover:focus-within:tw-border-blue-400",
                // "tw-max-w-[250px]",
                className,
                {
                    "tw-cursor-not-allowed tw-bg-slate-300/10": disabled,
                    "tw-cursor-pointer": !disabled,
                },
            )}
            style={style}
            onClick={handleClick}
        >
            <span
                test-id="product-selector-open-single"
                className="tw-flex tw-items-center tw-justify-between tw-gap-2 tw-h-full"
                style={{
                    color: productName ? Colors.SteelGray : Colors.LightSilverGray,
                    ...textStyle,
                }}
            >
                <div className={"tw-max-w-4xl tw-overflow-hidden tw-flex tw-flex-col"}>
                    <div className="tw-overflow-ellipsis tw-whitespace-nowrap tw-overflow-hidden" title={productName}>
                        {productName || t("select", { name: t("product") })}
                    </div>
                    {product?.parent_name && (
                        <div className={"tw-text-xs tw-text-gray-400"}>{product?.parent_name}</div>
                    )}
                </div>
                <div className="tw-flex tw-items-center">
                    {errorMessage ? <GridInputError message={errorMessage} /> : null}
                    <CIcon path={iChevronRight} size={0.5} className="tw-text-gray-400" />
                </div>
            </span>
        </div>
    );
};

export default CProductSelector;
