import { ChangeEvent } from "react";
import { TText } from "components/i18n/TText";
import _ from "lodash";
import { ISplitAbilities } from "pages/staff/roles/RoleEditPage";
import { Checkbox, CheckboxGroup, CheckboxProps, Col, Row } from "rsuite";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import { USER_ABILITIES } from "config/constants";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import NewCheckboxTableFrom from "./NewCheckboxTableFrom";
import NewCheckboxTableTerminal from "./NewCheckboxTableTerminal";
import { t } from "lib/i18n";

interface IProps extends CFormGroupProps<CheckboxProps> {
    options: ISplitAbilities;
    abilities: Array<IAbility>;
}

interface IFieldProps {
    name: string;
    value: string[];
    onChange: (value: string[], event: ChangeEvent<HTMLInputElement>) => void;
}

const enum AccessType {
    WEB = "web_access",
    TERMINAL = "terminal_access",
}

const shouldDisable = (gname: string, value: string[] | undefined) => {
    return (
        (gname === "terminal" && !value?.includes(AccessType.TERMINAL)) ||
        (gname === "web" && !value?.includes(AccessType.WEB))
    );
};

const handleChildrenChange = (
    abilityName: string,
    isChecked: boolean,
    event: ChangeEvent<HTMLInputElement>,
    onChange: (updatedAbilities: string[], event: ChangeEvent<HTMLInputElement>) => void,
    currentAbilities: string[],
) => {
    let refined = currentAbilities;

    const parentOfCheckedItem = _.omit(
        USER_ABILITIES.find(s => _.values(s).includes(abilityName)),
        ["name", "group"],
    );
    const isParentAbility = parentOfCheckedItem.all === abilityName;

    if (isChecked) {
        if (isParentAbility) {
            const filterNotFound = _.values(parentOfCheckedItem).filter(p => !refined.includes(p));
            refined.push(...filterNotFound);
        } else {
            refined.push(abilityName);

            /*
             * If all children of a group are checked, mark the group as checked.
             * This condition checks if `parentOfCheckedItem.all` exists,
             * ensuring it is only applied in the web access context.
             */
            if (parentOfCheckedItem.all) {
                const isAllChildrenChecked = _.values(_.omit(parentOfCheckedItem, "all")).every(child =>
                    refined.includes(child),
                );

                if (isAllChildrenChecked) {
                    refined.push(parentOfCheckedItem.all);
                }
            }
        }
    } else {
        if (isParentAbility) {
            refined = refined.filter(v => !_.values(parentOfCheckedItem).includes(v));
        } else {
            if (parentOfCheckedItem.all && refined.includes(parentOfCheckedItem.all)) {
                refined = refined.filter(ve => ![parentOfCheckedItem.all, abilityName].includes(ve));
            } else {
                refined = refined.filter(ve => ve !== abilityName);
            }
        }
    }

    onChange(refined, event);
};

const handleParentChange = (
    abilityName: string,
    isChecked: boolean,
    event: ChangeEvent<HTMLInputElement>,
    onChange: (updatedAbilities: string[], event: ChangeEvent<HTMLInputElement>) => void,
    ability: string[],
    options: ISplitAbilities,
) => {
    let refined = ability || [];

    if (!isChecked) {
        if (abilityName === AccessType.WEB) {
            refined = refined.filter(v => !options.web.map(o => o.name).includes(v));
        } else if (abilityName === AccessType.TERMINAL) {
            refined = refined.filter(v => !options.terminal.map(o => o.name).includes(v));
        }
    } else {
        refined.push(abilityName);
    }

    onChange(refined, event);
};

const NewAbilityCheckFormGroup: FCC<IProps> = ({ options, abilities, ...props }) => {
    const { watch } = useCFormContext();
    const ability = watch("abilities") || [];

    const cprops = {
        render: ({ field: { name, value, onChange } }: { field: IFieldProps }) => {
            const webAbilityNames = options?.web?.map(g => g.name);
            const terminalAbilityNames = options?.terminal?.map(g => g.name);
            const isTerminalDisabled = shouldDisable("terminal", value);
            const isWebDisabled = shouldDisable("web", value);

            return (
                <div className="tw-grid tw-grid-cols-[repeat(2,minmax(600px,1fr))] tw-gap-3">
                    <div>
                        <div className="tw-bg-basic-white-300 tw-border tw-border-solid tw-border-basic-metal-grey-500 tw-rounded-t-xl tw-py-3 tw-px-4">
                            <div className="tw-flex tw-items-center tw-justify-between tw-mb-2 tw-h-[35px]">
                                <span className="tw-text-text-600 text-h6-medium">{t("web_access")}</span>
                                <Checkbox
                                    test-id="web-access"
                                    checked={ability.includes(AccessType.WEB)}
                                    className="convert-toggle pr-4"
                                    onChange={(v, checked: boolean, event) =>
                                        handleParentChange(v as string, checked, event, onChange, ability, options)
                                    }
                                    value={AccessType.WEB}
                                />
                            </div>
                            <Checkbox
                                test-id="web-access-select-all"
                                className={`tw-h-[27px] tw-transition ${isWebDisabled ? "tw-opacity-50" : "tw-opacity-100"} tw-text-text-600 text-body1-regular`}
                                checked={webAbilityNames?.every(name => ability.includes(name))}
                                readOnly={isWebDisabled}
                                onChange={(v, checked, event) => {
                                    onChange(
                                        checked
                                            ? _.uniq([...ability, ...webAbilityNames])
                                            : [..._.differenceWith(ability, webAbilityNames), AccessType.WEB],
                                        event,
                                    );
                                }}
                            >
                                <TText tkey="select_all" />
                            </Checkbox>
                        </div>

                        <CheckboxGroup
                            inline
                            name={name}
                            value={value}
                            className={`mx-0 tw-transition ${isWebDisabled ? "tw-opacity-50" : "tw-opacity-100"} `}
                        >
                            <Row className="mx-0 tw-w-full">
                                {_.map(options, (group: IAbility[], groupName: string) => {
                                    return (
                                        group[0]?.name &&
                                        group[0]?.name === AccessType.WEB && (
                                            <Col sm={24} key={groupName} className="mb-5 p-0 tw-w-full">
                                                <NewCheckboxTableFrom
                                                    ability={ability}
                                                    abilities={abilities}
                                                    onChange={(v, checked: boolean, event) =>
                                                        handleChildrenChange(
                                                            v as string,
                                                            checked,
                                                            event,
                                                            onChange,
                                                            ability,
                                                        )
                                                    }
                                                    readonly={isWebDisabled}
                                                />
                                            </Col>
                                        )
                                    );
                                })}
                            </Row>
                        </CheckboxGroup>
                    </div>
                    <div>
                        <div className="tw-bg-basic-white-300 tw-border tw-border-solid tw-border-basic-metal-grey-500 tw-rounded-t-xl tw-py-3 tw-px-4">
                            <div className="tw-flex tw-items-center tw-justify-between tw-mb-2 tw-h-[35px]">
                                <span className="tw-text-text-600 text-h6-medium">{t("terminal_access")}</span>

                                <Checkbox
                                    checked={ability.includes(AccessType.TERMINAL)}
                                    className="convert-toggle pr-4"
                                    test-id="terminal-access"
                                    onChange={(v, checked: boolean, event) =>
                                        handleParentChange(v as string, checked, event, onChange, ability, options)
                                    }
                                    value={AccessType.TERMINAL}
                                />
                            </div>
                            <Checkbox
                                test-id="terminal-access-select-all"
                                className={`tw-h-[27px] tw-transition ${isTerminalDisabled ? "tw-opacity-50" : "tw-opacity-100"} tw-text-text-600 text-body1-regular`}
                                readOnly={isTerminalDisabled}
                                checked={terminalAbilityNames?.every(name => ability.includes(name))}
                                onChange={(v, checked, event) => {
                                    onChange(
                                        checked
                                            ? _.uniq([...ability, ...terminalAbilityNames])
                                            : [..._.differenceWith(ability, terminalAbilityNames), AccessType.TERMINAL],
                                        event,
                                    );
                                }}
                            >
                                <TText tkey="select_all" />
                            </Checkbox>
                        </div>
                        <CheckboxGroup
                            inline
                            name={name}
                            value={value}
                            className={`mx-0 tw-transition ${isTerminalDisabled ? "tw-opacity-50" : "tw-opacity-100"} `}
                        >
                            <Row className="mx-0 tw-w-full">
                                {_.map(options, (group: IAbility[], groupName: string) => {
                                    return (
                                        group[0]?.name &&
                                        group[0]?.name === AccessType.TERMINAL && (
                                            <Col sm={24} key={groupName} className="p-0 tw-w-full">
                                                <NewCheckboxTableTerminal
                                                    ability={ability}
                                                    abilities={abilities}
                                                    onChange={(v, checked, event) =>
                                                        handleChildrenChange(
                                                            v as string,
                                                            checked,
                                                            event,
                                                            onChange,
                                                            ability,
                                                        )
                                                    }
                                                    readonly={isTerminalDisabled}
                                                />
                                            </Col>
                                        )
                                    );
                                })}
                            </Row>
                        </CheckboxGroup>
                    </div>
                </div>
            );
        },
        ...props.compProps,
    };

    return (
        <CFormGroup
            {...props}
            label={false}
            compProps={cprops}
            controlWrapperProps={{
                style: { width: "100%" },
            }}
        />
    );
};

export default NewAbilityCheckFormGroup;
