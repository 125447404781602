import { t } from "lib/i18n";

import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { QuickLinks } from "components/Header/QuickLinks";
import { useTitle } from "components/Header/Title";
import Qty from "components/mini/Qty";
import { Constants, OperationStatus, OperationType, ProductType } from "config/constants";
import React, { ReactNode, useState } from "react";
import { Badge } from "rsuite";
import CreateButton from "../../../components/DataTable/CreateButton";
import CheckListFilter from "../../../components/DataTable/Filters/CheckListFilter";
import { getLabel } from "components/Form/form-utils";
import { productNameAppend } from "lib/data-utils";
import { exportFile } from "lib/export-table";
import { ValueType } from "rsuite/esm/CheckPicker/CheckPicker";
import SuppliesPageFilterDrawer from "./drawer/SuppliesPageFilterDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import ToggleFilterDrawer from "../../../components/DataTable/Filters/ToggleFilterDrawer";
import { Colors } from "config/colors";

const BetaSuppliesPageDetailed: FCC = props => {
    const url = "operation-items";
    // const brandType: string = selectSetting("brand_type")?.value;
    // const isRestaurant = brandType === BrandType.RESTAURANT;
    const [filteredProducts, setFilteredProducts] = useState<any[]>([]);
    const filterDrawer = useDrawer();

    const dp = useDataProvider({
        url,
        useUrl: true,
        useDates: true,
        extraFilterKeys: ["storage_id", "product_type", "category_id", "balance_id"],
        with: [
            "product",
            "unit",
            "stock",
            "product",
            // "transaction_finance.balance",
            // "transaction_finance.operation.supplier",
            "supplier",
            "status",
            "storage",
        ],
        params: {
            trash: [["product", 2]],
        },
        sort: ["operated_at", "-1"],
        filters: {
            type: ["type", OperationType.IN],
            // status: ["status", 1],
        },
    });

    const cm = useColumns<any>("operation/supplies-detailed", [
        {
            key: "operation_id",
            align: "right",
            width: 70,
            flexGrow: undefined,
            title: t("data_id"),
        },
        { key: "product.full_name", title: t("product"), flexGrow: 2 },
        {
            key: "operated_at",
            title: t("operation"),
            type: "datetime",
            flexGrow: 2,
            multiline: true,
        },
        {
            key: "created_at",
            type: "datetime",
            flexGrow: 2,
            multiline: true,
        },
        { key: "supplier.name", title: t("supplier") },
        { key: "storage.name", title: t("storage") },
        { key: "quantity", title: t("qty"), render: d => <Qty value={d.quantity} unit={d?.unit} /> },
        { key: "cost", type: "money", title: t("cost") },
        { key: "total_cost", type: "money", title: t("total") },
        {
            key: "status",
            hide: true,
            minWidth: 120,
            render: e => {
                return (
                    <Badge
                        style={{ background: e.status === 1 ? Colors.JungleGreen : Colors.VividOrange }}
                        content={<span>{getLabel(Constants.operationStatus[e.status])}</span>}
                    />
                );
            },
        },
    ]);

    return (
        <div className="h-100">
            {useTitle(t("supplies"), dp.refinedTotal)}
            <QuickLinks
                links={[
                    {
                        link: "/inventory/supplies",
                        label: t("general"),
                    },
                    {
                        link: "/inventory/supplies/detailed",
                        label: t("details"),
                        active: true,
                    },
                    {
                        link: "/inventory/supplies/deleted_supplies",
                        label: t("deleted_supplies"),
                    },
                    {
                        link: "/inventory/supplies/supply_draft",
                        label: t("draft"),
                    },
                ]}
            />
            <HeaderRight dp={dp} cm={cm} date reload print export={() => exportFile(dp, cm.columns, t("supplies"))} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <RemoteCheckListFilter
                        fields="product_id"
                        selectedDetails={{
                            url: "product",
                        }}
                        searchField="name"
                        remote={{
                            url: "product",
                            sort: ["updated_at", "-1"],
                            limit: 20,
                            refineList: (products?: IProduct[]) =>
                                products
                                    ?.map(i => productNameAppend(i))
                                    ?.filter(f => !(f?.type === ProductType.GOODS && f.has_modifications)) ?? [],
                        }}
                        pickerProps={{
                            placeholder: t("product"),
                            onChange: (d: ValueType) => setFilteredProducts(d),
                            groupBy: "type",
                            renderMenuGroup: (groupTitle: ReactNode) => {
                                return getLabel((groupTitle || "") as any);
                            },
                            style: {
                                width: 150,
                            },
                        }}
                        width={120}
                    />
                    <CheckListFilter
                        pickerProps={{ searchable: false }}
                        options={[
                            { label: t("draft"), value: OperationStatus.DRAFT },
                            { label: t("approved"), value: OperationStatus.APPROVED },
                        ]}
                        fields="status"
                    />

                    {/*{isRestaurant && (*/}
                    {/*    <CheckListFilter*/}
                    {/*        pickerProps={{ searchable: false, placeholder: t("type") }}*/}
                    {/*        options={[*/}
                    {/*            { label: getLabel(ProductType.GOODS), value: ProductType.GOODS },*/}
                    {/*            { label: getLabel(ProductType.PREPARATION), value: ProductType.PREPARATION },*/}
                    {/*            { label: getLabel(ProductType.DISH), value: ProductType.DISH },*/}
                    {/*            { label: getLabel(ProductType.INGREDIENT), value: ProductType.INGREDIENT },*/}
                    {/*        ]}*/}
                    {/*        fields="product_type"*/}
                    {/*    />*/}
                    {/*)}*/}
                    <RemoteCheckListFilter
                        remote="supplier"
                        fields="supplier_id"
                        searchField="name"
                        pickerProps={{ placeholder: t("supplier") }}
                        width={120}
                    />
                    <ToggleFilterDrawer isOpen={filterDrawer.isOpen} onChange={val => filterDrawer.setIsOpen(val)} />
                </div>
                <CreateButton to={"/inventory/supplies/create"} canCreate={"stock_manage_supply_create"} />
                <SuppliesPageFilterDrawer dp={dp} {...filterDrawer} />
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
        </div>
    );
};

export default BetaSuppliesPageDetailed;
