import { createSlice } from "@reduxjs/toolkit";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

export interface SupplyItems {
    // id: number;
    // operation_item_id: number,
    quantity: any;
    price: any;
    discount_rate: any;
    discount_value: any;
    tax_rate: any;
    tax_value: any;
    tax_all: any;
    discount_all: any;
    total_cost: any;
}
const initialState: SupplyItems = {
    // id: 0,
    // operation_item_id: 0,
    quantity: 0,
    price: 0,
    discount_rate: 0,
    discount_value: 0,
    tax_rate: 0,
    tax_value: 0,
    tax_all: 0,
    discount_all: 0,
    total_cost: 0,
};
export const ItemsSlice = createSlice({
    name: ReduxSlicesNames.SUPPLY_ITEMS,
    initialState,
    reducers: {
        setQuantity: (state: any, action: any) => {
            state.quantity = action.payload;
        },
        setPrice: (state: any, action: any) => {
            state.price = action.payload;
        },
        setDiscount_rate: (state: any, action: any) => {
            state.discount_rate = action.payload;
        },
        setDiscount_value: (state: any, action: any) => {
            state.discount_value = action.payload;
        },
        setTax_rate: (state: any, action: any) => {
            state.tax_rate = action.payload;
        },
        setTax_value: (state: any, action: any) => {
            state.tax_value = action.payload;
        },
        setTax_all: (state: any, action: any) => {
            state.tax_all = action.payload;
        },
        setDiscount_all: (state: any, action: any) => {
            state.discount_all = action.payload;
        },
        setTotal_cost: (state: any, action: any) => {
            state.total_cost = action.payload;
        },
        resetIMData: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    resetIMData,
    setQuantity,
    setPrice,
    setDiscount_rate,
    setDiscount_value,
    setTax_rate,
    setTax_value,
    setTax_all,
    setDiscount_all,
    setTotal_cost,
} = ItemsSlice.actions;
export default ItemsSlice.reducer;
