import { t } from "lib/i18n";

import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import { OperationType, ProductType } from "config/constants";
import React, { ReactNode, useState } from "react";
import CreateButton from "../../../components/DataTable/CreateButton";
import { exportFile } from "lib/export-table";
import OperationProcessIndicator from "components/OperationProcessIndicator";
import { productName, productNameAppend } from "lib/data-utils";
import _ from "lodash";
import { getLabel } from "components/Form/form-utils";
import { translateUnit } from "lib/utils";
import CheckBoxFilter from "components/DataTable/Filters/CheckBoxFilter";
import classNames from "classnames";
import { QuickLinks } from "components/Header/QuickLinks";
import { RowDataType } from "rsuite-table/src/@types/common";
import { ValueType } from "rsuite/esm/CheckPicker/CheckPicker";
import { SelectStorageFilter } from "components/adhoc-filters/SelectStorageFilter";

const WastesPagesDetailed: FCC = props => {
    const [filteredProducts, setFilteredProducts] = useState<any[]>([]);

    const dp = useDataProvider({
        url: "operation",
        useUrl: true,
        useDates: true,
        with: ["operation_items.product", "operation_items.unit", "supplier", "storage", "reason_data"],
        params: {
            // sort: [
            //     ["operated_at", "-1"],
            //     ["product_id", "-1"],
            // ],
            detailedWastes: true,
        },
        filters: {
            type: ["type", OperationType.WASTE],
        },
        refineList: list => {
            const dummyArr = [];
            for (const x in list) {
                const listArr = list[x];
                if (listArr.operation_items) {
                    for (const a in listArr.operation_items) {
                        const selfItem = listArr.operation_items[a];
                        const product_name = productName(selfItem.product);

                        if (filteredProducts.length !== 0 && !_.includes(filteredProducts, selfItem.product_id))
                            continue;

                        dummyArr.push({
                            rootID: listArr.id,
                            ...selfItem,
                            inventory_status: listArr.inventory_status,
                            status: listArr.status,
                            product_name,
                            operated_at: listArr.updated_at,
                            reason: listArr?.reason_data?.info?.name,
                            storage: listArr.storage,
                            description: listArr.description,
                        });
                    }
                }
            }
            return dummyArr;
        },
    });

    const cm: any = useColumns<any>("operation-detailed", [
        { key: "id", align: "left", width: 70, flexGrow: undefined, title: t("data_id") },
        { key: "product_name", title: t("product"), flexGrow: 3 },
        {
            key: "operated_at",
            title: t("operation"),
            type: "datetime",
            flexGrow: 1,
            multiline: true,
            showSeconds: true,
        },
        { key: "storage.name", title: t("storage") },
        {
            key: "quantity",
            render: (d: any) => (d?.quantity ?? 0)?.toFixed(2) + " " + translateUnit(d?.unit)?.symbol,
        },
        { key: "cost", type: "money" },
        { key: "total_cost", type: "money" },
        { key: "reason" },
        { key: "inventory_status", render: e => <OperationProcessIndicator status={e.inventory_status} /> },
    ]);

    return (
        <div className="h-100">
            {useTitle(t("details"), dp.refinedTotal)}

            <QuickLinks
                links={[
                    {
                        link: "/inventory/write-off",
                        label: t("write_off"),
                    },
                    {
                        link: "/inventory/write-off/detailed",
                        label: t("details"),
                        active: true,
                    },
                    {
                        link: "/inventory/write-off/reason-reports",
                        label: t("write_off_reason_reports"),
                    },
                    {
                        link: "/inventory/write-off/reasons",
                        label: t("reasons"),
                    },
                ]}
            />

            <HeaderRight dp={dp} cm={cm} date reload print export={() => exportFile(dp, cm.columns, t("stocks"))} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <RemoteCheckListFilter
                        fields="product_id"
                        selectedDetails={{
                            url: "product",
                        }}
                        searchField="name"
                        remote={{
                            url: "product",
                            sort: ["updated_at", "-1"],
                            limit: 20,
                            refineList: (products?: IProduct[]) =>
                                products
                                    ?.map(i => productNameAppend(i))
                                    ?.filter(f => !(f.type === ProductType.GOODS && f.has_modifications)) ?? [],
                        }}
                        pickerProps={{
                            placeholder: t("product"),
                            onChange: (d: ValueType) => setFilteredProducts(d),
                            groupBy: "type",
                            renderMenuGroup: (groupTitle: ReactNode) => {
                                return getLabel((groupTitle || "") as any);
                            },
                            style: {
                                width: 150,
                            },
                        }}
                    />

                    <SelectStorageFilter multi />
                    <RemoteCheckListFilter
                        remote={{ url: "reasons", filters: { type: ["type", 1] } }}
                        fields="reason_id"
                        pickerProps={{ placeholder: t("reason") }}
                    />
                    <CheckBoxFilter
                        useParams
                        fields="trash"
                        value={[2, ["operation_items.product", 2]]}
                        title={t("show_deleted_values")}
                    />
                </div>
                <CreateButton to={"/inventory/write-off/create"} canCreate={"stock_manage_waste_manage"} />
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                    affixHeader: true,
                    rowClassName: (d?: RowDataType) =>
                        classNames({
                            "deleted-row": d && !!d?.deleted_at,
                        }),
                }}
            />
        </div>
    );
};

export default WastesPagesDetailed;
