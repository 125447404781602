import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useTitle } from "components/Header/Title";
import DataTable from "../../../components/DataTable/DataTable";
import HeaderRight from "../../../components/Header/HeaderRight";
import { exportFile } from "lib/export-table";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import nrInRoutings from "./nr-in-routings";
import { useLocation } from "react-router-dom";
import StaffServiceChargeDrawer from "components/Drawers/StaffServiceChargeDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import CellBtn from "components/DataTable/CellButton";
import { money } from "lib/money";
import { useData } from "hooks/useData";
import _ from "lodash";
import Money from "components/mini/Money";
import { QuickLinks } from "components/Header/QuickLinks";
import RemoteCheckListFilter from "../../../components/DataTable/Filters/RemoteCheckListFilter";
import React from "react";
import SourceFilter from "../../../components/DataTable/Filters/SourceFilter";
import StaffBonusDrawer from "components/Drawers/StaffBonusDrawer";
import { BrandType } from "types/routes";
import { useCurrency } from "hooks/useCurrency";
import { useSetting } from "hooks/useSetting";

const NewReportsStaffPage: FCC = () => {
    const loc = useLocation();
    const url = "reports/staff";
    // const courier = 'reports/courier';
    // const seller = 'reports/workers/seller';
    const sd = useDrawer({ backdrop: false, overflowAuto: false });
    const bonusDrawer = useDrawer({ backdrop: false, overflowAuto: false });
    const currency = useCurrency();

    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        url,
        with: ["user"],
    });
    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;

    const summary = useData<any>(url, {
        filters: _.values(dp.filters.all),
        params: {
            total: 1,
            date: dp.params.all.date,
        },
    });

    const columns: any = [
        {
            title: "ID",
            key: "user.id",
            align: "left",
            flexGrow: 0.3,
        },
        { key: "user.username", title: t("username") },
        {
            title: t("total_guests"),
            key: "total_guests",
            align: "right",
            summary: () => summary.data?.[0]?.total_guests,
        },
        {
            title: t("total_receipt"),
            key: "total_receipt",
            align: "right",
            summary: () => summary.data?.[0]?.total_receipt,
        },
        {
            title: t("total_products_revenue"),
            key: "total_products_revenue",
            align: "right",
            summary: () => summary.data?.[0]?.total_products_revenue,
        },
        {
            title: t("total_service_charge"),
            render: (d: any) => (
                <CellBtn action={() => sd.setData({ user: d.user })} label={money(d.total_service_charge, currency)} />
            ),
            key: "total_service_charge",
            align: "right",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.total_service_charge} />,
        },
        {
            title: t("total_cash"),
            key: "total_cash",
            align: "right",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.total_cash} />,
        },
        {
            title: t("seller_product_count"),
            key: "seller_product_count",
            align: "right",
            numberRoundingLength: 2,
        },
        {
            title: t("seller_total"),
            key: "seller_total",
            align: "right",
            type: "money",
            numberRoundingLength: 2,
        },
        {
            title: t("courier_delivery_fee"),
            key: "courier_delivery_fee",
            align: "right",
            type: "money",
            numberRoundingLength: 2,
        },
        {
            title: t("courier_receipt_count"),
            key: "courier_receipt_count",
            align: "right",
            numberRoundingLength: 2,
        },
        {
            title: t("courier_total"),
            key: "courier_total",
            align: "right",
            numberRoundingLength: 2,
        },
    ].filter(c => (isRestaurant ? true : c.key !== "total_guests"));

    const cm = useColumns<any>("reports/staff", columns);

    return (
        <div className="h-100">
            {useTitle(t("staff"), dp.total)}

            <QuickLinks inHeader={false} links={nrInRoutings(loc)} />

            <HeaderRight cm={cm} print export={() => exportFile(dp, cm.columns, t("staff"))} reload date dp={dp} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    {/*<SearchFilter fields={"staff.name"} />*/}
                    <RemoteCheckListFilter
                        fields="receipt_owner_id"
                        remote={{
                            url: "user",
                            refineList: (d: any[]) => d?.filter(user => user.owner || !user.hide),
                        }}
                        labelField="username"
                        searchField="username"
                        pickerProps={{ placeholder: t("staff") }}
                    />
                    <SourceFilter />
                </div>
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
            <StaffServiceChargeDrawer {...sd} dateFilter={dp.params.all.date} />
            {bonusDrawer.data && <StaffBonusDrawer {...bonusDrawer} date={dp.params.getValue("date")} />}
        </div>
    );
};

export default NewReportsStaffPage;
