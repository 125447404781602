export const dummyPreparationItem = () => ({
    gross: 0,
    id: null,
    type: null,
    name: null,
    price: 0,
    cost_price: 0,
    unit: null,
    unit_id: null,
    unit_weight: 0,
    gross_weight_kg: 0,
});
