import { iCheckBoxChecked, iClose } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import CForm from "components/Form/CForm/CForm";
import TextFormGroup from "components/Form/TextFormGroup";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import JsonView from "components/JsonView";
import { useCForm } from "hooks/useCForm";
import { useAppDispatch } from "hooks/useRedux";
import Ajax from "lib/Ajax";
import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";
import _ from "lodash";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useEffect } from "react";
import { setSettingByField } from "redux/features/settings";
import { useSetting } from "hooks/useSetting";

const dummyStorage = () => ({ name: "", status: 1 });
const StorageSchema: ValidationSchema = {
    name: ruleset.combine([ruleset.required, ruleset.minLength(3)]),
};

const CreateStoragePage: FCC = () => {
    const form = useCForm({
        url: "storage",
        model2: StorageSchema,
        dummyData: dummyStorage,
        prepareForSubmit: d => {
            return { name: d.name, status: d.status ? 1 : 0 };
        },
    });
    const dispatch = useAppDispatch();
    const documentId = form.form?.getValues()?.id;
    const status = form.form?.watch("status");
    const auth = useAuth();

    const brandStorage = useSetting("brand_storage");

    useEffect(() => {
        if (!status && brandStorage.value.includes(documentId)) {
            toggleStorage();
        }
    }, [status]);

    const toggleStorage = async () => {
        if (!documentId) {
            return;
        }
        const newValue = brandStorage.value.includes(documentId)
            ? brandStorage.value.filter((p: number) => p !== documentId)
            : brandStorage.value.concat(documentId);

        dispatch(
            setSettingByField({
                brand_storage: {
                    ...brandStorage,
                    value: newValue,
                },
            }),
        );
    };

    const onSave = async () => {
        if (documentId) {
            await Ajax.post({
                url: "setting/brand_storage?_method=PUT",
                data: {
                    ...brandStorage,
                    value: JSON.stringify(brandStorage.value),
                },
            });
        }
        return form.onSave();
    };

    return (
        <div className="p-3 mb-5">
            {useFormTitle(form.id, t("create_by_name", { name: _.capitalize(t("storage")) }))}
            <HeaderRight formSubmit={{ ...form, onSave }} />
            <CForm form={form.form} onSave={form.onSave}>
                <TextFormGroup name="name" errorPlacement="rightEnd" className="pb-3" focus={true} />
                <ToggleFormGroup
                    name="status"
                    label={t("status")}
                    compProps={{
                        checkedChildren: <CIcon path={iCheckBoxChecked} style={{ marginBottom: "-3px" }} />,
                        unCheckedChildren: <CIcon path={iClose} style={{ marginBottom: "-3px" }} />,
                    }}
                />
                {documentId && (
                    <ToggleFormGroup
                        name="sale_storage_status"
                        label={t("sale_storage_status")}
                        compProps={{
                            checked: brandStorage.value?.indexOf(documentId) !== -1,
                            onChange: () => toggleStorage(),
                            checkedChildren: <CIcon path={iCheckBoxChecked} style={{ marginBottom: "-3px" }} />,
                            unCheckedChildren: <CIcon path={iClose} style={{ marginBottom: "-3px" }} />,
                            disabled: !auth.hasAbility(["setting_general"]) || !status,
                        }}
                    />
                )}
                <JsonView />
            </CForm>
        </div>
    );
};

export default CreateStoragePage;
