import { StorageSelectFormGroup } from "components/Form/adhoc/StorageSelectFormGroup";
import CForm from "components/Form/CForm/CForm";
import DatePickerFormGroup from "components/Form/DatePickerFormGroup";
import RemoteSelectFormGroup from "components/Form/RemoteSelectFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import { FormItems } from "components/FormItems/FormItems";
import { FormItemsAddButton } from "components/FormItems/FormItemsAddButton";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { MassInsertButton } from "components/FormItems/MassInsertButton";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import JsonView from "components/JsonView";
import { format } from "date-fns";
import { useCForm } from "hooks/useCForm";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import _ from "lodash";
import { WasteOperationModel } from "models/OperationModel";
import { useEffect } from "react";
import { unitsSlice } from "redux/features/model/slice";
import { Col, DateRangePicker, Grid, Row } from "rsuite";
import CProductsDrawer from "../../../components/Drawers/CProductSelector/CProductsDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import { ProductType, ReasonType } from "config/constants";
import { WriteOffSchema } from "form-schemas/WriteOffSchema";
import { dummyInventoryItem, dummyTransferItem, dummyWasteOperation } from "../misc/utils";
import CreateReasonModal from "./CreateReasonModal";
import WasteItem, { WasteItemField } from "./WasteItem";
import { isEditingPage } from "lib/isEditingPage";
import { setDisabledProductIds, setShowedTypes } from "redux/features/productsSelector/productsSelector";
import { isContainValue } from "lib/pure";

const columns = [["product"], ["unit"], ["qty"], [""]] as const;

const refineItemOnInsert = (d: IProduct) => {
    return {
        product_id: d.id,
        quantity: 0,
        unit_id: d.unit_id,
        disabled: false,
        product_full_name: undefined,
        id: undefined,
    };
};

const CreateWastePage: FCC = () => {
    const dispatch = useAppDispatch();
    const createReasonModal = useDrawer();
    const isEdit = isEditingPage();
    const form = useCForm<IOperation>({
        url: "operation",
        with: ["operation_items.product.packages", "reason_data", "operation_items.unit", "operation_items.product"],
        // params: {
        //     trash: 2,
        // },
        prepareForSubmit: d => {
            d.items = d.items.map((i: any) => _.omit(i, "units", "product_full_name"));
            d.operated_at = format(isEdit ? new Date(d.operated_at) : d.operated_at, "yyyy-MM-dd HH:mm:ss");

            return _.pick(d, _.keys(WasteOperationModel.fields));
        },

        dummyData: dummyWasteOperation,
        model2: WriteOffSchema,
        mode: "all",
        refine: (d?: any) => {
            const refinedData = { ...d };
            if (isEdit) {
                refinedData.items = d?.operation_items?.map((oP: IStockOperation) => {
                    return {
                        id: oP.id,
                        unit_id: oP.unit_id,
                        product_id: oP.product_id,
                        quantity: oP.quantity,
                        product_full_name: oP.product?.deleted_at
                            ? `${oP.product?.full_name} (${t("deleted_product")})`
                            : oP.product?.full_name,
                        disabled: !!oP.product?.deleted_at,
                    };
                });
                refinedData.reason_id = d?.reason_data?.info?.id || null;
            } else {
                refinedData.items = [dummyTransferItem()];
                refinedData.reason = "";
            }
            return refinedData;
        },
    });

    const items = form.form.watch("items");
    const itemIds = items?.map((p: any) => p.product_id);
    const itemsHash = itemIds?.join(",");

    const storageId = form.form.watch("storage_id");
    const isEditable = !isEdit || form.form.watch("canEdit");

    const productsById = useAppSelector(state => state.data.products.byId);

    const searchableColumns: SearchableColumns<Mutable<typeof columns>> = {
        product: (searchVal: string, item: any) => isContainValue(searchVal, productsById[item?.product_id]?.slug),
    };

    useEffect(() => {
        dispatch(setDisabledProductIds(itemIds));
    }, [itemsHash]);

    useEffect(() => {
        dispatch(unitsSlice.fetchData(""));
        dispatch(
            setShowedTypes([ProductType.DISH, ProductType.PREPARATION, ProductType.GOODS, ProductType.INGREDIENT]),
        );
    }, []);

    return (
        <div className="tw-h-full tw-p-4">
            {useFormTitle(form.id, t("write_off"))}
            <HeaderRight
                formSubmit={{
                    ...form,
                    disabled: !isEditable,
                }}
            />

            <CForm {...form} disabled={!isEditable} formProps={{ layout: "vertical" }}>
                <JsonView collapsed />
                <Grid fluid>
                    <Row className="mb-3" gutter={100}>
                        <Col xs={24} sm={24} md={5}>
                            <StorageSelectFormGroup
                                controlWrapperProps={{ className: "d-flex" }}
                                tooltip={t("waste_storage_info")}
                                compProps={{
                                    style: { width: "100%" },
                                }}
                                refineList={list => list.filter(l => l.status || (isEdit && l.id === storageId))}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={5} className="pr-0">
                            <DatePickerFormGroup
                                time
                                name="operated_at"
                                label={t("operation")}
                                compProps={{
                                    cleanable: false,
                                    shouldDisableDate: DateRangePicker.after?.(new Date()),
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3" gutter={100}>
                        <Col xs={24} sm={24} md={5} className="pr-0">
                            <RemoteSelectFormGroup
                                name="reason_id"
                                label={t("reason")}
                                searchLocal={true}
                                revalidate={createReasonModal.dataId}
                                remote={{
                                    url: "reasons",
                                    filters: {
                                        type: ["type", ReasonType.wastes],
                                    },
                                }}
                                compProps={{
                                    style: { width: "300px" },
                                    renderExtraFooter: () => {
                                        return (
                                            <div
                                                test-id="create-new-reason"
                                                className="rs-picker-select-menu-item text-center"
                                                onClick={() => {
                                                    createReasonModal.setDataId(0);
                                                }}
                                            >
                                                {t("create_new_by_name", {
                                                    name: t("reason"),
                                                })}
                                            </div>
                                        );
                                    },
                                }}
                                errorPlacement="rightEnd"
                            />
                        </Col>
                        <Col xs={24} sm={24} md={5} className="pr-0">
                            <TextFormGroup
                                name="description"
                                label={t("description")}
                                errorPlacement="rightEnd"
                                compProps={{
                                    "test-id": "description",
                                    style: { width: "300px" },
                                }}
                            />
                        </Col>
                    </Row>
                </Grid>
                <FormItems itemsFieldName="items">
                    <FormItemsList<WasteItemField, Mutable<typeof columns>>
                        virtualize
                        columns={columns as Mutable<typeof columns>}
                        searchableColumns={searchableColumns}
                        children={_props => {
                            return <WasteItem {..._props} refineItemOnInsert={refineItemOnInsert} />;
                        }}
                        footer={
                            <div className="tw-grid tw-grid-cols-subgrid tw-col-span-4">
                                <div className="tw-flex tw-col-span-3 tw-gap-2">
                                    <MassInsertButton idField="product_id" refineItem={refineItemOnInsert} />
                                    <FormItemsAddButton dummyData={dummyInventoryItem} />
                                </div>
                            </div>
                        }
                    />
                </FormItems>
            </CForm>
            <CreateReasonModal {...createReasonModal} mainForm={form.form} />
            <CProductsDrawer />
        </div>
    );
};

export default CreateWastePage;
