import _ from "lodash";

import { TagPicker, TagPickerProps } from "rsuite";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import { TypeAttributes } from "rsuite/cjs/@types/common";
import { usePopupContainer } from "hooks/usePopupContainer";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

interface IProps extends CFormGroupProps<Partial<TagPickerProps>> {
    data?: any[];
    valueKey?: string;
    labelKey?: string;
    creatable?: boolean;
    testId?: string;
    errorPlacement?: TypeAttributes.Placement8;
}

const TagPickerFormGroup: FCC<IProps> = ({ data, valueKey, labelKey, compProps, ...props }) => {
    const form = useCFormContext();
    const value = form.watch(props.name);
    const { container } = usePopupContainer();
    if (_.isString(value)) {
        // eslint-disable-next-line no-console
        console.error("Tag picker value must be array", props.name);
        return null;
    }
    const newCompProps = _.assign(
        {
            data,
            labelKey,
            valueKey,
            cleanable: false,
            style: { width: 300 },
            creatable: props.creatable ?? false,
            "test-id": props.testId ?? props.name,
            container: () => container.current!,
        },
        compProps,
    );

    return <CFormGroup {...(props as any)} compProps={newCompProps} />;
};

TagPickerFormGroup.defaultProps = {
    labelKey: "name",
    valueKey: "id",
    data: [],
    comp: TagPicker as any,
};

export default TagPickerFormGroup;
