import { TText } from "components/i18n/TText";
import { Modal } from "rsuite";
import CModal from "../CModal";
import { iCheckBoxChecked, iClose } from "assets/icons/Icons";
import CIcon from "../../CIcon";
import React from "react";

import "./BulkDeleteResultModal.scss";

interface IProps {
    modal: any;
    bulkDeleteResult: {
        deleted_count: number;
        could_not_deleted_count: number;
    };
}

const BulkDeleteResultModal: FCC<IProps> = props => {
    return (
        <CModal {...props.modal} backdrop size="xs" style={{ padding: "24px !important" }}>
            <Modal.Body className="pb-0 bulk-delete-result">
                <div className="modal-title">
                    <span>
                        <TText tkey="operation_completed" />
                    </span>
                </div>
                <div className="deleted">
                    <div className="btn success">
                        <CIcon path={iCheckBoxChecked} className="icon" />
                    </div>
                    <span>
                        <span className="count">{props.bulkDeleteResult?.deleted_count}</span>{" "}
                        <TText tkey="products_deleted" />
                    </span>
                </div>
                <div className="could-not-deleted">
                    <div className="btn danger">
                        <CIcon path={iClose} />
                    </div>
                    <span>
                        <span className="count">{props.bulkDeleteResult?.could_not_deleted_count}</span>{" "}
                        <TText tkey="products_not_deleted" />
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <button
                    onClick={() => props.modal.onClose()}
                    className="footer-btn rs-btn-default rs-btn-lg rs-btn-block"
                >
                    <TText tkey="okay_button" />
                </button>
            </Modal.Footer>
        </CModal>
    );
};

export default BulkDeleteResultModal;
