import { t } from "lib/i18n";

import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import { ReasonType } from "config/constants";
import CheckListFilter from "components/DataTable/Filters/CheckListFilter";
import ReasonsEditorDrawer from "components/Drawers/ReasonsEditorDrawer";
import CheckBoxFilter from "components/DataTable/Filters/CheckBoxFilter";
import classNames from "classnames";
import CreateButton from "../../../components/DataTable/CreateButton";
import { QuickLinks } from "components/Header/QuickLinks";
import { RowDataType } from "rsuite-table/src/@types/common";
import React from "react";
import { exportFile } from "lib/export-table";

const ReasonsPage: FCC = (props: any) => {
    const rType = props?.match?.params?.type || "wastes";

    const dp = useDataProvider({
        url: "reasons",
        filters: {
            type: ["type", ReasonType[rType]],
        },
    });
    const useReasonDrawer = useDrawer();

    const canManage = rType === "wastes" ? "stock_manage_waste_manage" : "";

    const cm = useColumns<any>("reasons", [
        {
            key: "name",
            align: "left",
            flexGrow: 9,
        },
        {
            key: "created_at",
            title: t("created_at"),
            type: "datetime",
            flexGrow: 1,
            multiline: true,
            showSeconds: true,
        },
        optionsColumn({
            dp,
            edit: payload => {
                useReasonDrawer.setData({
                    action: "edit",
                    type: ReasonType[rType],
                    payload,
                });
            },
            editable: d => d?.deleted_at,
            delete: "reasons/:id",
            canDelete: canManage,
            canEdit: canManage,
        }),
    ]);

    const completeDrawerCRUD = () => {
        useReasonDrawer.hide();
        dp.revalidate();
    };

    return (
        <div className="h-100">
            {useTitle(t("reasons"), undefined, t("reasons"))}

            <QuickLinks
                links={[
                    {
                        link: "/inventory/write-off",
                        label: t("write_off"),
                    },
                    {
                        link: "/inventory/write-off/detailed",
                        label: t("details"),
                    },
                    {
                        link: "/inventory/write-off/reason-reports",
                        label: t("write_off_reason_reports"),
                    },
                    {
                        link: "/inventory/write-off/reasons",
                        label: t("reasons"),
                        active: true,
                    },
                ]}
            />

            <HeaderRight dp={dp} cm={cm} reload print export={() => exportFile(dp, cm.columns, t("reasons"))} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="name" placeholder={t("reasons")} />
                    {!rType && (
                        <CheckListFilter
                            pickerProps={{ searchable: false }}
                            options={[
                                { label: t("write_off"), value: ReasonType.wastes },
                                { label: t("delete"), value: ReasonType.delete },
                            ]}
                            fields="type"
                        />
                    )}
                    <CheckBoxFilter useParams fields="trash" value="2" title={t("deleted_transactions")} />
                </div>
                <CreateButton
                    to={() =>
                        useReasonDrawer.setData({
                            action: "create",
                            type: ReasonType[rType],
                        })
                    }
                    canCreate={canManage}
                />
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                    affixHeader: true,
                    rowClassName: (d?: RowDataType) =>
                        classNames({
                            "deleted-row": d && !!d?.deleted_at,
                        }),
                }}
            />
            <ReasonsEditorDrawer completeDrawerCRUD={completeDrawerCRUD} {...useReasonDrawer} />
        </div>
    );
};

export default ReasonsPage;
