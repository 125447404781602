import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReceiptItem } from "pages/settings/receipt/types";
import { v4 } from "uuid";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

interface ReceiptState {
    items: IReceiptItem[];
    changeCount: number;
}

const initialState: ReceiptState = {
    items: [
        {
            type: "receiptInfo",
            value: "",
            status: true,
            isCustom: false,
            textSize: 1,
            isBold: false,
            showLabel: false,
            align: "center",
            description: "",
            default: false,
            id: true,
            owner: true,
            uniqueId: "40a452b8-8bb4-429c-85b0-05dfd8fc4948",
        },
        {
            type: "saleType",
            value: "",
            status: true,
            isCustom: false,
            textSize: 1,
            isBold: false,
            showLabel: false,
            align: "left",
            description: "",
            default: false,
            uniqueId: "71699f06-2c13-48a3-969e-62c067a1577c",
        },
        {
            type: "date",
            value: "",
            status: true,
            isCustom: false,
            textSize: 1,
            isBold: false,
            showLabel: false,
            align: "left",
            description: "",
            default: false,
            uniqueId: "73465e90-8de7-421a-88cd-ce9081ec0edd",
        },
        {
            type: "pos",
            value: "",
            status: true,
            isCustom: false,
            textSize: 1,
            isBold: false,
            showLabel: false,
            align: "left",
            description: "",
            default: false,
            uniqueId: "6b2a7471-4e6b-4edd-83e5-e53a44240353",
        },
        {
            type: "guestCount",
            value: "",
            status: true,
            isCustom: false,
            textSize: 1,
            isBold: false,
            showLabel: false,
            align: "left",
            description: "",
            default: false,
            uniqueId: "7787c9d8-97d1-456a-bd0f-4467a6a6acec",
        },
        {
            type: "station",
            value: "",
            status: true,
            isCustom: false,
            textSize: 1,
            isBold: false,
            showLabel: false,
            align: "left",
            description: "",
            default: false,
            uniqueId: "f9cdbcdd-0873-40b6-8f90-1f078089deeb",
        },
        {
            type: "products",
            value: "",
            status: true,
            isCustom: false,
            textSize: 1,
            isBold: false,
            showLabel: false,
            align: "center",
            comment: true,
            description: "",
            default: false,
            uniqueId: "4b1e74cc-f06e-48d3-997e-2d2ce3203705",
        },
        {
            type: "stationComment",
            value: "",
            status: true,
            isCustom: false,
            textSize: 1,
            isBold: false,
            showLabel: false,
            align: "left",
            description: "",
            default: false,
            uniqueId: "f6005bf3-a5a1-4f59-b9ca-1cbd300381de",
        },
        {
            type: "orderNumber",
            value: "",
            status: true,
            isCustom: false,
            textSize: 1,
            isBold: false,
            showLabel: false,
            align: "left",
            description: "",
            default: false,
            uniqueId: "aacfe159-cc03-4c28-834f-d8650d8015de",
        },
    ],
    changeCount: 0, // this need for check if receipt is changed
};

const stationReceipt = createSlice({
    name: ReduxSlicesNames.STATION_RECEIPT,
    initialState,
    reducers: {
        setStationReceiptItems: (state, action: PayloadAction<{ items: IReceiptItem[] }>) => {
            state.items = action.payload.items.map(item => ({
                ...item,
                uniqueId: item.uniqueId || v4(),
            }));
            state.changeCount++;
        },
        addStationReceiptItem: (state, action: PayloadAction<IReceiptItem>) => {
            state.items.push(action.payload);
            state.changeCount++;
        },
        deleteStationReceiptItem: (state, action: PayloadAction<number>) => {
            state.items.splice(action.payload, 1);
            state.changeCount++;
        },
        updateStationReceiptItemPosition: (state, action: PayloadAction<{ startIndex: number; endIndex: number }>) => {
            const { startIndex, endIndex } = action.payload;

            // Validate indices
            if (startIndex < 0 || endIndex < 0 || startIndex >= state.items.length || endIndex >= state.items.length) {
                return;
            }

            // Remove the item from the startIndex and insert it at the endIndex
            const [removed] = state.items.splice(startIndex, 1);
            state.items.splice(endIndex, 0, removed);

            state.changeCount++;
        },
        toggleStationReceiptItemActivity: (state, action: PayloadAction<number>) => {
            const item = state.items[action.payload];
            item.status = !item.status;
            state.changeCount++;
        },
        toggleStationReceiptItemActivityByType: (
            state,
            action: PayloadAction<{ key: keyof IReceiptItem; type: string }>,
        ) => {
            const index = state.items?.findIndex(i => i.type === action.payload.type);
            if (index !== -1) {
                (state.items[index] as any)[action.payload.key] = !state.items[index][action.payload.key]; //as any typescript 4.8.3 don't access
            }
            state.changeCount++;
        },
        changeStationKeyReceiptItemByType: (
            state,
            action: PayloadAction<{ item: IReceiptItem; value: string; key?: keyof IReceiptItem }>,
        ) => {
            const item: IReceiptItem | undefined = state.items?.find(i => i.uniqueId === action.payload.item.uniqueId);
            const key: keyof IReceiptItem | undefined = action.payload.key;
            if (key && item) {
                (item as any)[key] = action.payload.value; //as any typescript 4.8.3 don't access
            } else if (item) {
                item.value = action.payload.value;
            }
            state.changeCount++;
        },
    },
});

export const selectStationReceiptItems = createSelector(
    (state: RootState) => state.stationPrint.items,
    items => items,
);
export const {
    updateStationReceiptItemPosition,
    deleteStationReceiptItem,
    addStationReceiptItem,
    toggleStationReceiptItemActivity,
    toggleStationReceiptItemActivityByType,
    changeStationKeyReceiptItemByType,
    setStationReceiptItems,
} = stationReceipt.actions;

export default stationReceipt.reducer;
