import { t } from "lib/i18n";
import React, { useEffect, useState } from "react";

import CDrawer from "./CDrawer";

import { ICDrawerProps } from "components/Drawers/CDrawer";
import Ajax from "lib/Ajax";
import { useLoading } from "hooks/useLoading";
import Money from "components/mini/Money";

type IProps = ICDrawerProps;

interface IVenueRemain {
    amount: string;
    id: number;
    name: string;
    pivot: { balance_id: number; venue_id: number };
}

const DebtDrawer: FCC<IProps> = props => {
    const [venueRemains, setVenueRemains] = useState<IVenueRemain[]>([]);
    const { isLoading, withLoading } = useLoading();
    const customerId = props?.data?.id;

    useEffect(() => {
        if (!customerId) return;

        void withLoading(async () => {
            const res = await Ajax.get({ url: `/customers/${customerId}/venue-remains` });
            setVenueRemains(res.data);
        });
    }, [customerId]);

    return (
        <CDrawer size="sm" title={t("debt")} {...props}>
            <div className="tw-flex tw-border-0 tw-border-b tw-border-solid tw-border-b-gray-100 tw-mb-2 tw-py-2">
                <div className={"tw-basis-1/2 tw-px-4 tw-font-medium"}>{t("venue")}</div>
                <div className={"tw-basis-1/2 tw-px-4 tw-font-medium text-right"}>{t("amount")}</div>
            </div>
            {!isLoading &&
                venueRemains.map((remains: IVenueRemain) => (
                    <div key={remains.id} className="d-flex pb-2">
                        <div className={"tw-basis-1/2 tw-px-4"}>{remains.name}</div>
                        <div className={"tw-basis-1/2 tw-px-4 text-right"}>
                            <Money value={remains.amount} colored={true} />
                        </div>
                    </div>
                ))}
        </CDrawer>
    );
};

export default DebtDrawer;
