import { t } from "lib/i18n";
import classNames from "classnames";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import CheckBoxFilter from "components/DataTable/Filters/CheckBoxFilter";
import CheckListFilter from "components/DataTable/Filters/CheckListFilter";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";

import _ from "lodash";
import { exportFile } from "lib/export-table";
import CreateButton from "components/DataTable/CreateButton";
import Money from "components/mini/Money";
import { useDrawer } from "components/Drawers/useDrawer";
import AccountDrawer from "components/Drawers/AccountDrawer";
import React from "react";
import { AccountPageType, accountQuickLinks } from "./utils";
import { BalanceType } from "types/balance";

export interface RowDataType {
    dataKey?: string;
    children?: RowDataType[];

    [key: string]: any;
}

const AccountsPage: FCC = () => {
    const url = "finance/balance";

    const dp = useDataProvider({
        url,
        useUrl: true,
        filters: { balance_type: ["type", [BalanceType.CARD, BalanceType.CASH, BalanceType.BANK]] },
    });

    const ad = useDrawer({ backdrop: false, overflowAuto: false });

    const cm = useColumns<any>("finance/balance", [
        {
            key: "id",
            title: "ID",
            align: "left",
            flexGrow: 0.4,
        },
        {
            key: "name",
            align: "left",
            onClick: d => ad.setDataId(d.id),
            flexGrow: 2,
        },
        {
            key: "description",
            align: "left",
            flexGrow: 2,
        },
        {
            key: "type",
            render: (e: IAccount) => _.capitalize(t(e.type?.toLowerCase() as Lowercase<BalanceType>)),
        },
        {
            key: "amount",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={_.sumBy(dp.data, "amount")} />,
        },
        optionsColumn({
            dp,
            isLocked: d => d.id < 1000,
            preventDelete: (a: IAccount) => a.amount !== 0,
            editable: (a: IAccount) => !!a.system_type,
            edit: "/finance/accounts/edit/:id",
            delete: "/finance/balance/:id",
            canEdit: "finance_balance_edit",
            canDelete: "finance_balance_delete",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("accounts"), dp.total)}

            {accountQuickLinks(AccountPageType.COMPANY)}

            <HeaderRight dp={dp} cm={cm} reload print export={() => exportFile(dp, cm.columns, t("accounts"))} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="name" />
                    <CheckListFilter
                        pickerProps={{ searchable: false }}
                        options={[
                            { label: _.capitalize(t("cash")), value: BalanceType.CASH },
                            { label: _.capitalize(t("card")), value: BalanceType.CARD },
                            { label: _.capitalize(t("bank")), value: BalanceType.BANK },
                        ]}
                        fields="type"
                    />
                    <CheckBoxFilter title={t("deleted_accounts")} fields="trash" value={2} useParams />
                </div>
                <CreateButton to={"/finance/accounts/create"} canCreate={"finance_balance_create"} />
            </FiltersContainer>

            <DataTable
                key={url}
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    rowClassName: (d?: RowDataType) =>
                        classNames({
                            "deleted-row": d && !!d?.deleted_at,
                        }),
                    headerHeight: 50,
                }}
            />

            <AccountDrawer {...ad} />

            {/*<AccountSubInfo />*/}
        </div>
    );
};

export default AccountsPage;
