import _ from "lodash";
import React, { ComponentProps, ReactNode, useCallback, useState } from "react";
import { FieldErrors, FieldValues, UseFormReturn } from "react-hook-form";
import { useFormItemsContext } from "./FormItems";
import { FIHeaderProps, FormItemsHeader } from "./FormItemsHeader";
import { FISortableItem } from "./FISortableItem";
import { FISortableList } from "./FISortableList";
import { cn } from "lib/utils";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import { GridItemPlain } from "../NewForm/Grid/GridItemPlain";
import { getLabel } from "../Form/form-utils";
import { Input } from "rsuite";
import { VirtualizedList } from "core/components/VirtualizedList";

export type FIListItemProps<Field extends FieldValues = any> = {
    key: string;
    field: Field;
    index: number;
    append: (value: any) => void;
    remove: (index: number) => void;
    fields: Field[];
    form: UseFormReturn;
    error: FieldErrors<Field> | undefined;
    itemsFieldName: string;
};

type HeaderColumns = FIHeaderProps["columns"];

interface FIListProps<T extends FieldValues = FieldValues, K extends HeaderColumns = HeaderColumns> {
    children: (conf: FIListItemProps<T>) => ReactNode;
    columns: K;
    searchableColumns?: SearchableColumns<K>;
    footer?: ReactNode;
    virtualize?: boolean;
    showRowNumbers?: boolean;
    showHeader?: boolean;
    sortable?: boolean;
    getRowProps?: (conf: FIListItemProps<T>) => ComponentProps<"div">;
}

export const FormItemsList = <T extends FieldValues = FieldValues, K extends HeaderColumns = HeaderColumns>({
    children,
    columns,
    searchableColumns,
    virtualize = false,
    footer,
    showHeader = true,
    showRowNumbers = false,
    sortable,
    getRowProps,
}: FIListProps<T, K>) => {
    const form = useCFormContext();
    const { append, remove, itemsFieldName, fields } = useFormItemsContext();
    const items = form.watch(itemsFieldName).map((x: any, i: any) => {
        x.index = i;

        // In fields we have unique xxid but don't have updated data
        // In items we have updated data but don't have xxid
        return {
            ...fields[i],
            ...x,
        };
    }) as any[];

    const [searchFilters, setSearchFilters] = useState<Partial<Record<K[number][0], string>>>({});
    const handleInput = useCallback((columnId: K[number][0], searchValue: string) => {
        setSearchFilters(prev => ({
            ...prev,
            [columnId]: searchValue,
        }));
    }, []);

    let filteredItems = items;

    if (searchableColumns) {
        columns = columns.map(x => {
            const name = x[0];

            return name in searchableColumns
                ? [
                      name,
                      x[1],
                      () => (
                          <Input
                              test-id={`column-header-${name}`}
                              key={name}
                              name={name}
                              placeholder={getLabel(name)}
                              className={"tw-max-w-full"}
                              onInput={e => handleInput(name, (e.target as HTMLInputElement).value)}
                          />
                      ),
                  ]
                : x;
        }) as K;

        filteredItems = filteredItems.filter(item =>
            (Object.keys(searchFilters) as K[number][0][]).every(columnId =>
                searchableColumns[columnId]?.(searchFilters[columnId]!, item),
            ),
        );
    }

    if (showRowNumbers) {
        // @ts-ignore
        // TODO: type should be something like this `typeof showRowNumbers extends true ? ("№" | K)[] : K[]`
        columns = [["№"], ...columns];
    }

    const mapperFn = (field: any) => {
        const index = field.index;

        const rowData: FIListItemProps<T> = {
            field,
            index,
            append,
            remove,
            fields: items,
            itemsFieldName,
            key: field.xxid,
            form,
            error: _.get(form.formState.errors, `${itemsFieldName}[${index}]`) as FieldErrors<T>,
        };

        const child = children(rowData);

        if (!child) return null;

        const divProps = getRowProps?.(rowData);
        const Comp = sortable ? FISortableItem : "div";

        return (
            <Comp
                key={field.xxid}
                id={field.xxid}
                {...divProps}
                className={cn(
                    "tw-grid tw-grid-cols-subgrid hover:tw-bg-slate-50/50 focus-within:tw-bg-blue-50",
                    // "tw-rounded tw-outline-none tw-outline-offset-0 focus-within:tw-outline-blue-50", // extra bg color when focused
                    divProps?.className,
                )}
                style={{ gridColumn: `span ${columns.length} / span ${columns.length}`, ...divProps?.style }}
            >
                {showRowNumbers ? (
                    <GridItemPlain className="tw-bg-transparent tw-border-none" value={index + 1 + "."} />
                ) : null}
                {child}
            </Comp>
        );
    };

    return (
        <div className="FormItemsList tw-grid tw-gap-2 tw-py-3 tw-mb-2 tw-w-fit">
            {showHeader ? <FormItemsHeader columns={columns} /> : null}
            {sortable ? (
                <FISortableList
                    fields={filteredItems}
                    children={mapperFn}
                    virtualize={virtualize}
                    onUpdate={(newList: any[]) => {
                        form.setValue(itemsFieldName, newList);
                    }}
                />
            ) : virtualize ? (
                <VirtualizedList items={filteredItems} children={mapperFn} />
            ) : (
                filteredItems.map(mapperFn)
            )}
            {/* {virtualize ? <VirtualizedList fields={filteredFields} children={mapperFn} /> : filteredFields.map(mapperFn)} */}
            {footer}
        </div>
    );
};
