import { FC } from "react";
import LoadingSvg from "assets/img/cube.svg";

export const LoadingScreen: FC = () => {
    return (
        <div className="tw-flex tw-justify-center tw-items-center h-100">
            <img src={LoadingSvg} alt="loading animation" style={{ width: 40 }} />
        </div>
    );
};
