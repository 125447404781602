import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppDispatch } from "hooks/useRedux";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

interface TrashProductSelectorState {
    queue: number[];
}

const initialState: TrashProductSelectorState = {
    queue: [],
};

const trashProductSelector = createSlice({
    name: ReduxSlicesNames.TRASH_PRODUCT_SELECTOR,
    initialState,
    reducers: {
        addToQueue: (state, action: PayloadAction<number>) => {
            state.queue.push(action.payload);
        },
        resetQueue: state => {
            state.queue = [];
        },
    },
});

const { resetQueue, addToQueue } = trashProductSelector.actions;

const queueTrashProduct = (productId: number) => (dispatch: ReturnType<typeof useAppDispatch>) => {
    dispatch(addToQueue(productId));
};

export { resetQueue as resetTrashProductQueue, queueTrashProduct };
export default trashProductSelector.reducer;
