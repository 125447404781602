import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useCForm } from "hooks/useCForm";
import _ from "lodash";
import TextFormGroup from "components/Form/TextFormGroup";
import { useFormTitle } from "components/Header/Title";
import HeaderRight from "components/Header/HeaderRight";
import CForm from "components/Form/CForm/CForm";
import RemoteSelectFormGroup from "../../components/Form/RemoteSelectFormGroup";
import { useParams } from "react-router-dom";
import { dummySaleType, fillDummyVenuesList } from "models/PaymentMethodModel";
import SelectFormGroup from "../../components/Form/SelectFormGroup";
import { CheckPicker, Tooltip, Whisper } from "rsuite";
import { useData } from "hooks/useData";
import CFormGroup from "components/Form/CFormGroup";
import JsonView from "components/JsonView";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import ToggleFormGroup from "../../components/Form/ToggleFormGroup";
import React from "react";
import CIcon from "components/CIcon";
import { iInfoCircle } from "assets/icons/Icons";
import { useAppSelector } from "hooks/useRedux";
import { Colors } from "config/colors";
import { SalesChannelTypes } from "config/constants";
import { SaleTypeScheme } from "form-schemas/SaleTypeScheme";
import { selectSettingOptionsOf } from "redux/features/settings";

const EditSaleTypePage: FCC = () => {
    const params: any = useParams();
    const { currentVenueId, venues } = useAppSelector(state => state.venue);
    const auth = useAuth();
    const taxes = useData<IStorage[]>("finance/taxes", {}, auth.hasAbility(["tax_view"]));
    const form = useCForm({
        url: "finance/saleType",
        with: ["taxes"],
        model2: SaleTypeScheme,
        dummyData: () => dummySaleType(),
        refine: d => {
            d.service_charge_rate = _.isNumber(d.service_charge_rate)
                ? d.service_charge_rate.toString()
                : d.service_charge_rate;
            d.tmp_status = d.status;
            d.status = d.status[`${currentVenueId}`] === 1;
            return d;
        },
        prepareForSubmit: d => {
            d = {
                ...d,
                service_charge_rate: _.isString(d.service_charge_rate)
                    ? parseInt(d.service_charge_rate)
                    : d.service_charge_rate,
                status: {
                    ...d.tmp_status,
                    [`${currentVenueId}`]: d.status ? 1 : 0,
                },
            };
            return _.omit(d, ["id", "tmp_status"]);
        },
    });

    const channelOptions = [
        { id: "DELIVERY", name: t("channel_delivery") },
        { id: "TAKEAWAY", name: t("channel_takeaway") },
    ];

    const isSystemGenerated = params?.id ? params.id < 999 : false;

    if (isSystemGenerated) {
        channelOptions.push({
            id: "IN",
            name: t("channel_in"),
        });
    }

    const tipRateOptions = useAppSelector(s => selectSettingOptionsOf(s, "tip_rate"));
    const venuesArr = { ...fillDummyVenuesList(), ...form.form.getValues("status") };
    const valueForToggle = venuesArr[currentVenueId];

    return (
        <div className="p-3">
            {useFormTitle(form.id, t("create_by_name", { name: _.capitalize(t("payment_method")) }))}
            <HeaderRight formSubmit={form} />
            <CForm noShadow form={form.form} onSave={form.onSave}>
                <TextFormGroup
                    name="name"
                    errorPlacement="rightEnd"
                    focus={true}
                    compProps={{
                        disabled: isSystemGenerated,
                    }}
                    labelProps={{
                        style: {
                            textAlign: "left",
                            paddingLeft: "12px",
                        },
                    }}
                />
                <SelectFormGroup
                    name="channel"
                    label={t("sales_type_channel")}
                    compProps={{
                        defaultValue: isSystemGenerated ? null : SalesChannelTypes.DELIVERY,
                        disabled: isSystemGenerated,
                    }}
                    labelProps={{
                        style: {
                            textAlign: "left",
                            paddingLeft: "12px",
                        },
                    }}
                    data={channelOptions}
                />
                <RemoteSelectFormGroup
                    name="payment_method_id"
                    searchLocal={true}
                    compProps={{
                        disabled: isSystemGenerated,
                        cleanable: true,
                        onClean: () => form.form.setValue("payment_method_id", ""),
                    }}
                    labelProps={{
                        style: {
                            textAlign: "left",
                            paddingLeft: "12px",
                        },
                    }}
                    remote={{
                        url: "finance/paymentMethod",
                    }}
                    errorPlacement="rightEnd"
                />
                <SelectFormGroup
                    name="service_charge_rate"
                    label={t("service_charge_rate")}
                    labelProps={{
                        style: {
                            textAlign: "left",
                            paddingLeft: "12px",
                        },
                    }}
                    data={[{ id: null, name: t("default_service_charge") }, ...tipRateOptions]}
                />
                <CFormGroup
                    name="taxes"
                    labelProps={{
                        style: {
                            textAlign: "left",
                            paddingLeft: "12px",
                        },
                    }}
                >
                    <CheckPicker
                        data={_.map(taxes?.data, t => ({ value: t?.id, label: t?.name }))}
                        value={_.map(form.form.getValues("taxes"), t => t.id)}
                        onChange={data =>
                            form.form.setValue(
                                "taxes",
                                _.map(data, dataT => taxes?.data?.find((t: any) => t?.id === dataT)),
                            )
                        }
                        test-id="taxes"
                        placeholder={t("select", { name: t("tax") })}
                        onClean={() => form.form.setValue("taxes", [])}
                        style={{
                            width: 300,
                        }}
                    />
                </CFormGroup>
                <CFormGroup
                    name="status"
                    labelProps={{
                        style: {
                            textAlign: "left",
                            paddingLeft: "12px",
                        },
                    }}
                >
                    {_.values(venuesArr)?.map((value, index) => {
                        const key = Object.keys(venuesArr)[index];
                        const venue: IVenue | undefined = venues.find((g: any) => g.id === Number(key));
                        if (currentVenueId !== venue?.id) return null;
                        return (
                            <div style={{ display: "flex", alignItems: "center", gap: 25 }}>
                                {/*{venueList.length !== 1 && (*/}
                                {/*    <b style={{ minWidth: 60, textAlign: "left" }}>*/}
                                {/*        {venueList.find((g: any) => g.id === Number(key))?.name}*/}
                                {/*    </b>*/}
                                {/*)}*/}
                                {/*<ToggleFormGroup*/}
                                {/*    disabled={_.isNil(value)}*/}
                                {/*    CFormStyle={{ paddingLeft: 0 }}*/}
                                {/*    onChange={(__: any, checked: boolean) => {*/}
                                {/*        const dummyS = { ...venuesArr };*/}
                                {/*        dummyS[key] = checked === true ? 1 : 0;*/}
                                {/*        form.form.setValue("status", dummyS);*/}
                                {/*    }}*/}
                                {/*    key={key}*/}
                                {/*    checked={value}*/}
                                {/*    label={t("customer_required")}*/}
                                {/*    compProps={{*/}
                                {/*        checkedChildren: "ON",*/}
                                {/*        unCheckedChildren: "OFF",*/}

                                {/*        disabled: isSystemGenerated,*/}
                                {/*        style: {},*/}
                                {/*    }}*/}
                                {/*/>*/}
                                <div style={{ display: "flex", alignItems: "center", gap: 25 }}>
                                    {/*{venueList.length !== 1 && <b style={{ minWidth: 60 }}>{venue?.name}</b>}*/}
                                    <div
                                        style={{ marginLeft: "-120px", marginRight: "75px", marginTop: "6px" }}
                                        className="d-flex align-items-center"
                                    >
                                        <Whisper
                                            placement="right"
                                            trigger="click"
                                            speaker={
                                                <Tooltip>
                                                    <TText tkey="sales_type_info" />
                                                </Tooltip>
                                            }
                                        >
                                            <span>
                                                <CIcon
                                                    path={iInfoCircle}
                                                    size={1}
                                                    style={{ color: Colors.VibrantSkyBlue }}
                                                />
                                            </span>
                                            {/*<Icon style={iconStyle} size="lg" className="pl-2" icon={"info-circle"} />*/}
                                        </Whisper>
                                    </div>
                                    <ToggleFormGroup
                                        disabled={_.isNil(value)}
                                        name={`status`}
                                        label={false}
                                        CFormStyle={{ paddingLeft: 0 }}
                                        // onChange={(__: any, checked: boolean) => {
                                        //     const dummyS = { ...venuesArr };
                                        //     dummyS[currentVenueId] = !dummyS[currentVenueId] ? 1 : 0;
                                        //     form.form.setValue(`status`, dummyS);
                                        // }}
                                        compProps={
                                            {
                                                checkedChildren: "ON",
                                                unCheckedChildren: "OFF",
                                                "test-id": "sales-type-status",
                                                // disabled: isSystemGenerated,
                                            } as any
                                        }
                                        key={key}
                                    >
                                        {!valueForToggle ? t("deactivated") : t("activated")}
                                    </ToggleFormGroup>
                                </div>
                            </div>
                        );
                    })}
                </CFormGroup>
                <JsonView collapsed />
            </CForm>
        </div>
    );
};

export default EditSaleTypePage;
