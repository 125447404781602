import { getLabel } from "components/Form/form-utils";
import { parsePhoneNumber } from "libphonenumber-js";
import _ from "lodash";

import { Col, FlexboxGrid } from "rsuite";

interface IProps {
    infos: any;
}

const CustomerInfo: FCC<IProps> = ({ infos }) => {
    return (
        <div className="pt-5 px-5">
            <FlexboxGrid>
                {_.map(infos, (info: any, index: any) => (
                    <FlexboxGrid.Item as={Col} className="pb-4" colspan={24} md={6}>
                        <p className="info-title">{getLabel(index)}</p>
                        {_.isArray(info) ? (
                            info.map(i => (
                                <p className="info-content">
                                    {index === "phones" ? parsePhoneNumber(i)?.formatInternational() : i}
                                </p>
                            ))
                        ) : (
                            <p className="info-content">{info}</p>
                        )}
                    </FlexboxGrid.Item>
                ))}
            </FlexboxGrid>
        </div>
    );
};

export default CustomerInfo;
