import { useAppDispatch } from "hooks/useRedux";
import { useProductSelectorContext } from "providers/ProductSelectorProvider";
import { useFormItemsContext } from "./FormItems";
import { store } from "redux/store";
import { FC, ReactNode } from "react";
import _ from "lodash";
import { Button } from "rsuite";
import { setIsSingleSelect, setOpenProductSelector } from "redux/features/productsSelector/productsSelector";
import CIcon from "components/CIcon";
import { iMultiSelectTable } from "assets/icons/Icons";
import { t } from "lib/i18n";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

interface IMassInsertButtonProps {
    refineItem?: (item: IProduct) => any;
    idField: string;
    children?: ReactNode;
}

export const MassInsertButton: FC<IMassInsertButtonProps> = ({ refineItem, idField = "product_id", children }) => {
    const dispatch = useAppDispatch();
    const { updateConfig } = useProductSelectorContext();
    const { append, remove, itemsFieldName } = useFormItemsContext();
    const { getValues, disabled } = useCFormContext();

    const onSave = (selectedIds: number[]) => {
        const productsById = store.getState().data.products.byId;
        const addedItems = selectedIds.map(id => refineItem?.(productsById[id]) ?? productsById[id]);
        append(addedItems);

        const itemsField: any[] = getValues(itemsFieldName);

        const hasField = itemsField.map((a, i) => (!a[idField] ? i : null)).filter(_.isNumber);
        remove(hasField);
    };

    return (
        <Button
            test-id="multi-select-btn"
            onClick={() => {
                updateConfig({ onProductSelectorSave: onSave });
                dispatch(setIsSingleSelect(false));
                dispatch(setOpenProductSelector(true));
            }}
            disabled={disabled}
        >
            {children ?? (
                <>
                    <CIcon size={0.8} path={iMultiSelectTable} />
                    {t("multiple_select")}
                </>
            )}
        </Button>
    );
};
