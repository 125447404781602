import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";

import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import Money from "components/mini/Money";
import React, { useState } from "react";
import CreateButton from "components/DataTable/CreateButton";
import { Badge, Button, Message, Modal, toaster, Tooltip, Whisper } from "rsuite";
import { getLabel } from "components/Form/form-utils";
import { Constants, OperationStatus, OperationType } from "config/constants";
import { useHistory } from "react-router-dom";
import CheckBoxFilter from "components/DataTable/Filters/CheckBoxFilter";
import classNames from "classnames";
import Ajax from "lib/Ajax";
import { format } from "date-fns";
import DatePickerState from "components/State/DatePickerState";
import ConfirmModal from "components/Drawers/ConfirmModal";
import { useDrawer } from "components/Drawers/useDrawer";
import OperationProcessIndicator from "components/OperationProcessIndicator";
import { iBan, iContentCopy, iDownload } from "assets/icons/Icons";
import CModal from "components/Drawers/CModal";
import { Colors } from "config/colors";
import { exportFile } from "lib/export-table";
import ExportModal from "components/Header/Export/ExportModal";
import { inventoryCheckSecondStepColumns } from "./utils";
import LearnButton from "components/DataTable/LearnButton";
import { SelectStorageFilter } from "components/adhoc-filters/SelectStorageFilter";
import { useCurrency } from "hooks/useCurrency";

const InventoryCheckPages: FCC<any> = props => {
    const history = useHistory();
    const dp = useDataProvider({
        url: "operation",
        useUrl: true,
        useDates: true,
        with: ["total_cost", "total_difference_cost", "supplier", "storage"],
        filters: {
            type: ["type", OperationType.INVENTORY_CHECK],
        },
        sort: ["created_at", "-1"],
    });
    const exportDrw = useDrawer();
    const cancelModal = useDrawer();
    const [date, setDate] = useState<number | Date>(new Date());
    // const [isDuplicateModal, setIsDuplicateModal] = useState(false);
    const [hasPeriodType, setHasPeriodType] = useState(false);
    // const [duplicateData, setDuplicateData] = useState<IOperation | null>(null);
    const [isDuplicateLoading, setIsDuplicateLoading] = useState(false);
    const [isCancelLoading, setIsCancelLoading] = useState(false);

    const duplicateModal = useDrawer();
    const [itemDetails, setItemDetails] = useState<any>(null);

    const cancelInventorization = async (d: any) => {
        setIsCancelLoading(true);
        await Ajax.post({
            url: `operation/${d.id}/cancel`,
        }).catch(() => setIsCancelLoading(false));
        await dp.revalidate();
        cancelModal.hide();
        setIsCancelLoading(false);
    };

    const duplicateInventorization = async () => {
        if (duplicateModal.data === null) return;
        setIsDuplicateLoading(true);
        const fullDuplicateData: IApiResponse<IOperation> = await Ajax.get({
            url: `operation/${duplicateModal.data.id}`,
            params: {
                with: ["operation_items"],
            },
        });
        const d = fullDuplicateData?.data;
        const items = d.status === 1 ? d.operation_items || d?.meta?.items : d?.meta?.items || d.operation_items;
        if (items?.length === 0)
            return toaster.push(
                <Message type="warning" showIcon closable>
                    <TText tkey="empty_items" />
                </Message>,
            );
        await Ajax.post({
            url: "operation",
            data: {
                items: items.map((i: any) => ({ product_id: i.product_id, declarator: i.declarator })),
                operated_at: format(date, "yyyy-MM-dd HH:mm:ss"),
                force_apply: hasPeriodType,
                status: 2,
                storage_id: d.storage_id,
                type: OperationType.INVENTORY_CHECK,
            },
        })
            .then(res => {
                props?.history?.push(`/inventory/check/editor/edit/${res?.data?.id}`);
            })
            .catch(() => {
                toaster.push(
                    <Message type="error" showIcon closable>
                        <TText tkey="went_wrong" />
                    </Message>,
                );
            });
        setIsDuplicateLoading(false);
    };

    const cm = useColumns<any>("inventory/check", [
        { key: "id", isFixed: true, align: "right", hide: false, width: 70, flexGrow: undefined, title: t("data_id") },
        { key: "storage.name", title: t("storage") },
        {
            key: "status",
            render: e => {
                return (
                    <span>
                        <Whisper
                            preventOverflow
                            trigger="hover"
                            speaker={<Tooltip>{getLabel(Constants.operationStatus[e.status])}</Tooltip>}
                        >
                            <Badge
                                style={{
                                    background:
                                        e.status === OperationStatus.APPROVED ? Colors.JungleGreen : Colors.VividOrange,
                                }}
                            />
                        </Whisper>
                    </span>
                );
            },
            hide: false,
        },
        { key: "description", hide: true },
        {
            key: "created_at",
            type: "datetime",
            multiline: true,
            showSeconds: true,
        },
        {
            key: "operated_at",
            title: t("operation"),
            type: "datetime",
            multiline: true,
            showSeconds: true,
        },
        {
            key: "published_at",
            title: t("inventory_run_time"),
            type: "datetime",
            hide: false,
            multiline: true,
            showSeconds: true,
            align: "center",
        },
        {
            key: "total_difference_cost",
            title: t("diff"),
            render: (d: IOperation) => <Money value={d.total_difference_cost} colored />,
        },
        {
            key: "total_cost",
            title: t("total_inventory_cost"),
            render: (d: IOperation) => <Money value={d.total_cost} />,
        },
        {
            key: "inventory_status",
            isFixed: true,
            render: e => <OperationProcessIndicator status={e.inventory_status} />,
        },

        optionsColumn({
            dp,
            buttons: (d: any) =>
                !d?.deleted_at && (
                    <>
                        <Button
                            test-id={`edit-button-${d.id}`}
                            style={{
                                marginRight: "15px",
                                width: "60px",
                            }}
                            appearance="link"
                            onClick={() => history.push(`/inventory/check/editor/edit/${d.id}`)}
                        >
                            {d.status === 2 ? t("open") : t("view")}
                        </Button>
                    </>
                ),
            delete: "operation/:id",
            width: 130,
            others: d => {
                return !d.deleted_at
                    ? [
                          {
                              label: t("export_btn"),
                              icon: iDownload,
                              onClick: d => {
                                  exportDrw.setIsOpen(true);
                                  setItemDetails(d);
                              },
                          },
                          {
                              icon: iContentCopy,
                              label: t("duplicate"),
                              onClick: (d: any) => {
                                  //   setIsDuplicateModal(true);
                                  //   setDuplicateData(d);
                                  duplicateModal.setData(d);
                              },
                          },
                          ...(d?.status === 1
                              ? [
                                    {
                                        icon: iBan,
                                        label: t("cancel"),
                                        onClick: () => cancelModal.setData(d),
                                        visible: d?.status === 1,
                                    },
                                ]
                              : []),
                      ]
                    : [];
            },
            canDelete: "stock_manage_fixation",
        }),
    ]);

    const currency = useCurrency();

    const secondStepColumns = useColumns("inventory/check/second-step", inventoryCheckSecondStepColumns(currency));

    return (
        <div className="h-100">
            {useTitle(t("inventory_check"), dp.total)}
            <HeaderRight
                dp={dp}
                cm={cm}
                date
                reload
                print
                export={() => exportFile(dp, cm.columns, t("inventory_check"))}
            />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="productSearch" placeholder={t("product")} />
                    <SelectStorageFilter multi />
                    <CheckBoxFilter fields="trash" useParams title={t("show_deleted_values")} value="2" />
                </div>

                <LearnButton page="inventory_check" />

                <CreateButton
                    to={"/inventory/check/editor/create"}
                    label={t("create")}
                    canCreate={"stock_manage_fixation"}
                />
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    affixHeader: true,
                    rowClassName: (d?: any) =>
                        classNames({
                            "deleted-row": d && !!d?.deleted_at,
                        }),
                }}
            />
            <CModal
                size={"sm"}
                {...duplicateModal}
                // open={isDuplicateModal}
                // onClose={() => {
                //     setIsDuplicateModal(false);
                //     setDuplicateData(null);
                // }}
                // onExited={() => {
                //     setIsDuplicateModal(false);
                //     setDuplicateData(null);
                // }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <TText tkey="inventory_check" /> <TText tkey="duplicate" />
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DatePickerState
                        mainstyle={{ marginTop: 10 }}
                        time
                        label={t("operation")}
                        cleanable={false}
                        shouldDisableDate={d => !!d && d.getTime() > Date.now()}
                        onChange={(date: any) => setDate(new Date(date))}
                        value={date}
                        className={"w-100"}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        loading={isDuplicateLoading}
                        appearance="primary"
                        color="green"
                        onClick={duplicateInventorization}
                    >
                        <TText tkey="create" />
                    </Button>
                </Modal.Footer>
            </CModal>

            {itemDetails?.id && exportDrw.isOpen && (
                <ExportModal
                    exportOptions={[
                        {
                            name: "Excel",
                            onClick: () =>
                                exportFile(
                                    { url: "operation/" + itemDetails?.id, urlParams: { inventoryCheck: 1 } },
                                    secondStepColumns.columns,
                                    `${t("inventory_check")} ${` (${t("inventory_check_document_no")}: ${
                                        itemDetails?.id
                                    })`}`,
                                ),
                        },
                    ]}
                    {...exportDrw}
                />
            )}

            <ConfirmModal {...cancelModal} isLoading={isCancelLoading} onConfirm={cancelInventorization} />
        </div>
    );
};

export default InventoryCheckPages;
