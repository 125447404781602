import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";

import DataTable from "components/DataTable/DataTable";
import _ from "lodash";
import { ReactNode } from "react";

interface IProps {
    data: any;
}

const DishModificationsGroupTab: FCC<IProps> = ({ data }) => {
    const refineModifier = (value: any) => ({
        id: value.id,
        name: `${value.name} ${value.brutto !== 0 ? " - " + value.brutto : ""}`,
    });

    const dp = {
        data: _.orderBy(
            (data?.modificator_groups || []).map((modifier: any) => ({
                children: modifier.modificators?.map(refineModifier),
                position: _.isNil(data.meta?.modifier_pos?.[modifier.id])
                    ? null
                    : data.meta?.modifier_pos?.[modifier.id],
                ...modifier,
            })),
            "position",
        ),
    } as any;

    const cm = useColumns<any>("drawer-ingredient-dish-modifiers", [
        { key: "id", width: 150, flexGrow: undefined },
        {
            title: t("name"),
            key: "name",
        },
    ]);

    return (
        <div className="p-3">
            <DataTable
                noShadow
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    isTree: true,
                    defaultExpandAllRows: true,
                    rowKey: "id",
                    renderTreeToggle: (icon: ReactNode, rowData: any) => {
                        return icon;
                    },
                }}
                hidePages={true}
            />
        </div>
    );
};

export default DishModificationsGroupTab;
