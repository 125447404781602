import React from "react";
import { getLabel } from "./form-utils";
import _ from "lodash";

interface IProps {
    name: string;
    form?: any;
    value?: any;
    label?: string;
}

export const PlainText: FCC<IProps> = ({ label, name, form, value }) => {
    return (
        <div className="rs-form-group mb-2" role="group">
            <label className="rs-control-label">{label || getLabel(name)}</label>
            <div className="rs-form-control-wrapper">
                <div className="y-1 tw-font-semibold tw-tracking-[0.6px]">
                    {value || (!_.isNil(form?.getValues(name)) ? form?.getValues(name) : 0) || "---"}
                </div>
            </div>
        </div>
    );
};
