import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { SortType } from "components/Drawers/CProductSelector/utils";
import { CategoryTypeEnum, ProductType } from "config/constants";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

export interface IProductsFilters {
    category: number[];
    station: number[];
    type: string[];
}

export type ProductsFilterKey = keyof IProductsFilters;

// TODO: burda eslinde selectedProductIds, disabledProductIds bunlari birleshdirmek mumkundu. Adini nonSelectableIds kimi qoymaq olar, ve click olunan item eger bu arrayde varsa, drawer-i close edirik vse
interface ProductsAndCategoriesState {
    lastUpdatedOfProducts?: string;
    selectedProductIds: number[];
    searchText: string;
    selectedFilters: IProductsFilters;
    shouldShowSelected: boolean;
    sortColumn: string; // for local sorting
    sortType: SortType; // for local sorting
    result: number[];
    isOpenProductSelector: boolean;
    counter: number; //for show loading of productsSelector

    // transfer from context
    disabledProductIds: number[];
    isSingleSelect: boolean;
    showedTypes: ProductType[];
    showedCategoryTypes: CategoryTypeEnum[];

    visibleProductIds: number[];
}

export const findProductCategory: Record<Exclude<ProductType, ProductType.ALL>, "PRODUCT" | "INGREDIENT"> = {
    INGREDIENT: "INGREDIENT",
    GOODS: "PRODUCT",
    PREPARATION: "INGREDIENT",
    DISH: "PRODUCT",
    TIMER: "PRODUCT",
    MODIFICATION: "PRODUCT",
};

const initialState: ProductsAndCategoriesState = {
    sortColumn: "",
    sortType: undefined,
    result: [],
    isOpenProductSelector: false,
    searchText: "",
    lastUpdatedOfProducts: undefined,
    selectedProductIds: [],
    selectedFilters: {
        category: [],
        station: [],
        type: [],
    },
    counter: 0,
    shouldShowSelected: false,

    isSingleSelect: false,
    disabledProductIds: [],
    showedTypes: [ProductType.GOODS, ProductType.DISH, ProductType.INGREDIENT, ProductType.PREPARATION],
    showedCategoryTypes: [CategoryTypeEnum.INGREDIENT, CategoryTypeEnum.PRODUCT],

    visibleProductIds: [],
};

const productSelector = createSlice({
    name: ReduxSlicesNames.PRODUCT_SELECTOR,
    initialState,
    reducers: {
        setLastUpdated: (state, action: PayloadAction<string | undefined>) => {
            state.lastUpdatedOfProducts = action.payload;
        },
        setSelectedProducts: (state, action: PayloadAction<number[]>) => {
            state.selectedProductIds = _.uniq(action.payload);
        },
        setAllSelectedProducts: (state, action: PayloadAction<number[]>) => {
            state.selectedProductIds = action.payload;
        },
        setShouldShowSelected: (state, action: PayloadAction<boolean>) => {
            state.shouldShowSelected = action.payload;
        },
        setFilters: (state, action: PayloadAction<IProductsFilters>) => {
            state.selectedFilters = action.payload;
        },
        setSearchText: (state, action: PayloadAction<string>) => {
            state.searchText = action.payload;
        },
        setSortColumn: (state, action: PayloadAction<string>) => {
            state.sortColumn = action.payload;
        },
        setSortType: (state, action: PayloadAction<SortType>) => {
            state.sortType = action.payload;
        },
        setResult: (state, action: PayloadAction<number[]>) => {
            let result = action.payload;

            if (state.visibleProductIds.length > 0) {
                result = result.filter(id => state.visibleProductIds.includes(id));
            }

            state.result = result;
        },
        setInitialResult: (state, action: PayloadAction<number[]>) => {
            state.result = Array.from(new Set([...state.result, ...action.payload]));
        },

        setOpenProductSelector: (state, action: PayloadAction<boolean>) => {
            state.isOpenProductSelector = action.payload;
        },
        setCounter: (state, action: PayloadAction<number>) => {
            state.counter = action.payload;
        },

        clearState: state => {
            state.selectedProductIds = [];
            state.searchText = "";
            state.shouldShowSelected = false;
            state.sortColumn = "";
            state.sortType = undefined;
            // state.result = state.productsSelector.allIds;
            // state.isLoading = false;
        },
        clearSelectedIds: state => {
            state.selectedProductIds = [];
        },

        setDisabledProductIds: (state, action: PayloadAction<number[]>) => {
            state.disabledProductIds = action.payload;
        },
        setIsSingleSelect: (state, action: PayloadAction<boolean>) => {
            state.isSingleSelect = action.payload;
        },
        setShowedTypes: (state, action: PayloadAction<ProductType[]>) => {
            state.showedTypes = action.payload;
        },
        setShowedCategoryTypes: (state, action: PayloadAction<CategoryTypeEnum[]>) => {
            state.showedCategoryTypes = action.payload;
        },

        setVisibleProductIds: (state, action: PayloadAction<number[]>) => {
            state.visibleProductIds = action.payload;
        },
    },
});

export const {
    setLastUpdated,
    setSelectedProducts,
    setAllSelectedProducts,
    setShouldShowSelected,
    setFilters,
    setSearchText,
    setSortColumn,
    setSortType,
    setResult,
    clearSelectedIds,
    clearState,
    setOpenProductSelector,
    setCounter,
    setInitialResult,

    setDisabledProductIds,
    setIsSingleSelect,
    setShowedTypes,
    setShowedCategoryTypes,
    setVisibleProductIds,
} = productSelector.actions;
export default productSelector.reducer;
