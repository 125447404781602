import { ICDrawerProps } from "components/Drawers/CDrawer";
import { TText } from "components/i18n/TText";
import { FC } from "react";
import { Modal } from "rsuite";
import { iAlert, iExclamationCircle2 } from "assets/icons/Icons";
import CIcon from "../../../../../components/CIcon";
import { changeModalState } from "redux/features/webCashierAdditionals";
import { useAppDispatch } from "hooks/useRedux";

interface IProps extends ICDrawerProps {
    detail: any;
}

const ProductWarningModal: FC<IProps> = ({ detail, ...props }) => {
    const dispatch = useAppDispatch();

    const closeModal = () => {
        dispatch(changeModalState({ ...detail, open: false, title: "" }));
    };

    return (
        <>
            <Modal.Header style={{ paddingRight: 0 }}>
                <div className="d-flex align-items-center justify-content-center">
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ borderRadius: "50%", background: "#FEF3F2", height: "56px", width: "56px" }}
                    >
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ borderRadius: "50%", background: "#FEE4E2", height: "40px", width: "40px" }}
                        >
                            <CIcon
                                size={1}
                                path={iExclamationCircle2}
                                style={{ color: "#F04438", fillRule: "evenodd" }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <h6 className="d-flex justify-content-center mb-3">
                    <TText tkey="warning" />
                </h6>

                {detail?.optional?.salesTypeNotSelect ? (
                    <div className="d-flex align-items-center">
                        <CIcon style={{ color: "#F39C12", marginRight: "10px" }} path={iAlert} />
                        <TText tkey="be_sure_sale_type_selected" />
                    </div>
                ) : null}
                {detail?.optional?.productNotSelect ? (
                    <div className="d-flex align-items-center mt-2">
                        <CIcon style={{ color: "#F39C12", marginRight: "10px" }} path={iAlert} />
                        <TText tkey="be_sure_product_added" />
                    </div>
                ) : null}
                {detail?.optional?.paymentNotSelect ? (
                    <div className="d-flex align-items-center mt-2">
                        <CIcon style={{ color: "#F39C12", marginRight: "10px" }} path={iAlert} />
                        <TText tkey="be_sure_payment_method_added" />
                    </div>
                ) : null}
                {!detail?.optional?.paymentNotSelect && detail?.optional?.totalNotMatch ? (
                    <div className="d-flex align-items-center mt-2">
                        <CIcon style={{ color: "#F39C12", marginRight: "10px" }} path={iAlert} />
                        <TText tkey="total_not_match" />
                    </div>
                ) : null}
            </Modal.Body>
        </>
    );
};

export default ProductWarningModal;
