import { FIListItemProps } from "components/FormItems/FormItemsList";
import { GridButtonRemove } from "components/NewForm/Grid/GridButtonRemove";
import { GridInput } from "components/NewForm/Grid/GridInput";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { GridSelect } from "components/NewForm/Grid/GridSelect";
import { cn } from "lib/utils";
import React, { FC, useState } from "react";
import { useProductUnitOptions } from "hooks/useProductUnits";
import { store } from "redux/store";
import { GridProductSelectItem } from "components/NewForm/Grid/GridProductSelectItem";
import { isEditingPage } from "lib/isEditingPage";

interface IProps extends FIListItemProps<TransferItemField> {
    quantity: number;
    field: TransferItemField;
    fields: TransferItemField[];
    refineItemOnInsert: (d: IProduct) => TransferItemField;
}

export interface TransferItemField {
    product_id: number;
    units: Array<{ id: number; name: string }>;
    quantity: number;
    unit_id: string | number;
    product_full_name?: string;
    id?: string | number;
}

const TransferItem: FC<IProps> = ({ field, fields, index, remove, form, error, quantity, refineItemOnInsert }) => {
    const { setValue } = form;
    const isEdit = isEditingPage();

    const [removedItemIndex, setRemovedItemIndex] = useState<number | null>(null);

    const onSelectProduct = (id: number) => {
        const d = store.getState().data.products.byId[id];
        setValue(`items[${index}]`, refineItemOnInsert(d));
        form.trigger(`items[${index}].product_id`);
    };
    const units = useProductUnitOptions(field.product_id);

    const items = form.watch("items") ?? [];

    const removeItem = async (index: number) => {
        remove(index);
    };

    return (
        <>
            <GridProductSelectItem onSelect={onSelectProduct} name={`items[${index}].product_id`} />

            {field.id && isEdit ? (
                <GridItemPlain value={units.find(u => u.value === field.unit_id)?.label} />
            ) : (
                <GridSelect
                    options={units}
                    name={`items[${index}].unit_id`}
                    selectProps={{
                        searchable: false,
                        cleanable: false,
                    }}
                />
            )}

            <GridItemPlain
                value={quantity > 0 ? `+${quantity}` : (quantity ?? 0)}
                align="right"
                className={cn({
                    "tw-text-green-500": quantity > 0,
                    "tw-text-red-500": quantity <= 0,
                })}
            />

            <GridInput name={`items[${index}].quantity`} type="number" decimals={[0, 4]} />

            <GridButtonRemove onClick={() => removeItem(index)} disabled={items.length < 2} />
        </>
    );
};

export default TransferItem;
