import { t } from "lib/i18n";

import { Modal } from "rsuite";
import CModal from "./Drawers/CModal";

interface IProps {
    modal: any;
    permission: string[];
}

const NoAccessModal: FCC<IProps> = props => {
    return (
        <CModal {...props.modal} backdrop title={t("access")}>
            <Modal.Body className="pb-0">
                <p>
                    {t("permission_info", {
                        permission: props.permission.join(", "),
                    })}
                </p>
            </Modal.Body>
        </CModal>
    );
};

export default NoAccessModal;
