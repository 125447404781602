import { t } from "lib/i18n";
import { store } from "redux/store";

export const productName = (p?: IProduct, modPrefix = "") => {
    const productsById = store.getState().data.products.byId;

    if (p && !p.name) {
        p = productsById[p.id];
    }
    return `${p?.parent_name ? modPrefix : ""} ${p?.parent_name ?? ""} ${p?.name ?? ""} ${
        p?.deleted_at ? `(${t("deleted_product")})` : ""
    }`;
};

export const productNameAppend = (p?: IProduct, modPrefix = "") => ({ ...p, name: productName(p, modPrefix) });

const terminalAbilities = new Set([
    "terminal_returns",
    "terminal_access",
    "terminal_discount_select",
    "terminal_printer_manage",
    "terminal_pre_print",
    "terminal_remove_after_preprint",
    "terminal_remove_after_station_print",
    "terminal_receipt_archive",
    "terminal_finance_accounts_manage",
    "guests_select",
    "close_receipt",
    "terminal_report",
    "terminal_table_access",
    "terminal_finance_access",
    "terminal_gift",
    "terminal_customer",
    "terminal_product_transfer",
    "terminal_receipt_close_mistake",
    "terminal_manage_service_charge",
    "terminal_menu_manage",
    "terminal_product_create",
    "terminal_product_edit",
    "terminal_change_table",
    "terminal_merge_receipts",
    "terminal_receipt_reopen",
    "terminal_receipt_lock_manage",
    "terminal_change_receipt_owner",
    "terminal_product_reprice",
    "terminal_receipt_add_client",
    "terminal_loyalty_balance_usage",
    "terminal_cash_shift_can_open",
    "terminal_cash_shift_can_close",
    "terminal_discount_after_precheck",
    "terminal_close_receipt_without_send_station",
    "terminal_enable_actions_after_precheck",
    "terminal_full_screen_control",
    "terminal_fiscal_manage",
]);

const otherAbilities = ["field_seller", "field_courier"];

const addAbility = (newItem: any, key: string, ability: IAbility) => {
    if (newItem[key]) {
        newItem[key].push(ability);
    } else {
        newItem[key] = [ability];
    }
};

export const splitAbilities = (newItem: any, ability: IAbility) => {
    let key: string;

    if (terminalAbilities.has(ability.name)) {
        key = "terminal";
    } else if (otherAbilities.includes(ability.name)) {
        key = "other";
    } else {
        key = "web";
    }

    addAbility(newItem, key, ability);
    return newItem;
};
