const IconIngredientComponent = ({ color, size, style }: { color?: string; size: number; style?: any }) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            height={size}
            width={size}
            x="0px"
            y="0px"
            viewBox="0 0 45 45"
            style={style}
        >
            <g>
                <path
                    fill={color}
                    d="M26.6,33.1c-0.1-1.5-0.1-3,0-4.5c0-0.2,0-0.5,0-0.7c0.1-2.8,0.3-6.6,1.4-9.6c0.1-0.3,0-0.6-0.3-0.7
		c-0.3-0.1-0.6,0-0.7,0.3c-1.1,3.1-1.3,7-1.5,9.9c0,0.3,0,0.5,0,0.7c-0.1,1.6,0,3.1,0,4.6c0.1,2.9,0.2,5.5-0.9,6.5
		c-0.4,0.4-1.1,0.6-2,0.6c-2.7-0.1-4.8-1.2-6.1-2.9c-1-1.4-1.4-3.3-0.9-5c1-3.6,2.5-4.8,4.9-6.6c1.2-0.9,2.6-2,4.2-3.5
		c0.2-0.2,0.2-0.5,0-0.8c-0.2-0.2-0.5-0.2-0.8,0c-1.5,1.5-2.9,2.5-4.1,3.5c-2.5,1.9-4.2,3.2-5.3,7.1c-0.6,2.1-0.2,4.2,1.1,5.9
		c1.5,2,3.9,3.2,6.9,3.4c0.1,0,0.2,0,0.3,0c1.1,0,1.9-0.3,2.5-0.9C26.8,39,26.7,36.3,26.6,33.1z"
                />
                <path
                    fill={color}
                    d="M36,34.1c-0.1-2.5-1.3-4.3-2.7-6.3c-1.4-1.9-2.9-4.1-3.8-7.6c-0.1-0.3-0.4-0.5-0.7-0.4
		c-0.3,0.1-0.5,0.4-0.4,0.7c0.9,3.6,2.5,5.9,3.9,7.9c1.4,2,2.5,3.5,2.5,5.7c0.1,2.1-0.6,3.8-1.8,4.9c-1.4,1.2-3.4,1.7-6.1,1.3
		c-0.3,0-0.6,0.2-0.6,0.5c0,0.3,0.2,0.6,0.5,0.6c0.7,0.1,1.3,0.1,1.9,0.1c2.1,0,3.8-0.6,5.1-1.7C35.3,38.6,36.1,36.5,36,34.1z"
                />
                <path
                    fill={color}
                    d="M40,30.5c-0.7-2.3-2.2-3.6-3.9-5.2c-1.8-1.7-3.9-3.6-5.4-7.2c-0.1-0.4-1-3,2.2-6.2c0.1-0.1,0.2-0.3,0.2-0.4
		c0-0.2-0.1-0.3-0.2-0.4l-3-2.5c-0.2-0.2-0.5-0.2-0.7,0c-1.5,1.6-2,3.3-2.5,4.8c-0.3,1-0.7,2-1.2,2.9c-2.3,3.3-5,4.3-7.7,5.3
		c-2,0.8-4.1,1.6-5.9,3.3c-1.6,1.5-2.4,3.8-2.2,6.1c0.3,2.2,1.5,4.1,3.5,5.2c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.3
		c0.1-0.3,0.1-0.6-0.2-0.7c-1.7-1-2.8-2.6-3-4.4c-0.2-1.9,0.5-3.9,1.8-5.2c1.6-1.5,3.5-2.3,5.5-3c2.8-1.1,5.8-2.2,8.2-5.7
		c0,0,0,0,0,0c0.6-1,1-2.1,1.4-3.2c0.4-1.4,0.9-2.8,2-4l2.1,1.8c-1.8,1.9-2.3,3.6-2.4,4.7c-0.1,1.1,0.1,1.9,0.3,2.2c0,0,0,0,0,0.1
		c0,0,0,0,0,0c0,0,0,0.1,0,0.1c0,0,0,0,0,0c1.6,3.7,3.8,5.7,5.7,7.5c1.6,1.5,3,2.7,3.6,4.7c0.6,1.9,0.6,5.7-3,7.6
		c-0.3,0.1-0.4,0.5-0.2,0.7c0.1,0.2,0.3,0.3,0.5,0.3c0.1,0,0.2,0,0.3-0.1C40.7,37.1,40.7,32.7,40,30.5z"
                />
            </g>
        </svg>
    );
};

export default IconIngredientComponent;
