import { t } from "lib/i18n";
import { iPlus } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { OperationStatus, OperationType } from "config/constants";
import { format } from "date-fns";
import { useCForm } from "hooks/useCForm";
import Ajax from "lib/Ajax";
import { cleanEmptyStrings } from "lib/api-utils";
import _ from "lodash";
import { useSupplyImport } from "pages/inventory/supplies/NewSupplyCreate/useSupplyImport";
import ImportResultModal from "pages/inventory/supplies/modals/ImportResultModal";
import CForm from "components/Form/CForm/CForm";
import { FormItems } from "components/FormItems/FormItems";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { MassInsertButton } from "components/FormItems/MassInsertButton";
import JsonView from "components/JsonView";
import { GridItemTotal } from "components/NewForm/Grid/GridItemTotal";
import { dummySupplyItem, dummySupplyOperation } from "../../misc/utils";
import GeneralTab from "./GeneralTab";
import { SupplyItem, SupplyItemField } from "./SupplyItem";
import { AddByBarcodeButton } from "pages/inventory/supplies/NewSupplyCreate/AddByBarcodeButton";
import { useEffect, useState } from "react";
import { SupplySchema } from "form-schemas/SupplySchema";
import { useCurrency } from "hooks/useCurrency";
import { isEditingPage } from "lib/isEditingPage";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { isContainValue } from "lib/pure";
import { debouncedFetchStockCounts } from "redux/features/stock-counts-thunk";
import { resetStockCounts } from "redux/features/stockCountsSelector";
import CProductsDrawer from "components/Drawers/CProductSelector/CProductsDrawer";

const columns = [
    ["product_id"],
    ["total_count", "right"],
    ["unit_id"],
    ["quantity", "right"],
    ["cost", "right"],
    ["item_discount", "right"],
    ["item_tax", "right"],
    ["total", "right"],
    [""],
] as const;

export const CreateSupplyPage = () => {
    const currency = useCurrency();
    const isEdit = isEditingPage();
    const dispatch = useAppDispatch();
    const isDuplicate = window.location.pathname.indexOf("/duplicate") !== -1;
    const [isApproved, setIsApproved] = useState(false);

    const form = useCForm({
        url: "operation",
        isDuplicate,
        with: [
            "operation_items.product",
            "operation_items.product.packages",
            "operation_items.unit",
            "transaction_finance",
            "transaction_finances",
            "supply_returns.products",
        ],
        mode: "all",
        dummyData: dummySupplyOperation,
        model2: SupplySchema,
        refine: (data: IOperation) => {
            if (!isEdit && !isDuplicate) return data;

            setIsApproved(data.status === OperationStatus.APPROVED);

            const returnedItems = data.supply_returns
                ?.map((sr: any) => sr.products.map((p: any) => p.id))
                .flat() as number[];

            return {
                ...data,
                operated_at: isDuplicate ? new Date().toISOString() : data?.operated_at,
                operated_at_default: isDuplicate ? new Date().toISOString() : data?.operated_at,
                __finance__: data.__finance__ ?? {
                    balance_id: data.supplier_remain === 1 ? "supplier_remain" : data.transaction_finance?.balance_id,
                    category_id: data.transaction_finance?.category_id,
                },
                items: data.operation_items?.map(d => {
                    const isProductDeleted = d.product?.deleted_at;
                    const isProductReturned = returnedItems.includes(d.product_id);
                    const disabled = isProductDeleted || (isEdit && isProductReturned);
                    const disabled_reason = disabled
                        ? [
                              isProductDeleted ? t("deleted_product") : "",
                              isProductReturned ? t("because_returned") : "",
                          ].join(" / ")
                        : undefined;

                    return {
                        operation_item_id: d.id,
                        product_id: d.product_id,
                        unit_id: d.unit_id,
                        quantity: d.quantity,
                        cost: d.cost,
                        subtotal: d.subtotal,
                        total_cost: d.total_cost,
                        tax_rate: d.tax_rate,
                        tax_value: d.tax_value,
                        discount_rate: d.discount_rate,
                        discount_value: d.discount_value,
                        disabled,
                        disabled_reason,
                        product_full_name: d.product?.deleted_at
                            ? `${d.product?.full_name} (${t("deleted_product")})`
                            : d.product?.full_name,
                    };
                }),
            };
        },
        prepareForSubmit: data => {
            const operated_at = format(new Date(data?.operated_at), "yyyy-MM-dd HH:mm:ss");
            const supplier_remain = data?.__finance__?.balance_id === "supplier_remain" ? 1 : 0;
            const total_amount = _.sumBy(data.items, (x: any) => parseFloat(_.get(x, "total_cost")));

            if (isDuplicate) {
                delete data.id;
            }

            return {
                ...cleanEmptyStrings(data),
                operated_at,
                supplier_remain,
                type: OperationType.IN,
                __finance__: {
                    ...data.__finance__,
                    total_amount,
                },
                meta: {
                    ...data.meta,
                    total_amount,
                },
                items: data.items.map((i: any) => {
                    const item = {
                        id: i.operation_item_id,
                        product_id: i.product_id,
                        unit_id: i.unit_id,
                        quantity: i.quantity,
                        cost: i.cost,
                        subtotal: i.subtotal,
                        total_cost: i.total_cost,
                        // status: i.status,
                        tax_rate: i.tax_rate,
                        tax_value: i.tax_value,
                        discount_rate: i.discount_rate,
                        discount_value: i.discount_value,
                    };

                    if (isDuplicate) {
                        delete item.id;
                    }
                    return item;
                }),
            };
        },
    });
    const { importModal, importProps } = useSupplyImport(form);
    const storageId = form.form.watch("storage_id");

    const { queue } = useAppSelector(state => state.stockCounts);

    const isEditable = !isEdit || form.form.watch("canEdit");

    const productsById = useAppSelector(state => state.data.products.byId);

    const searchableColumns: SearchableColumns<Mutable<typeof columns>> = {
        product_id: (searchVal: string, item: any) => isContainValue(searchVal, productsById[item?.product_id]?.slug),
    };

    useEffect(() => {
        if (queue.length) {
            dispatch(debouncedFetchStockCounts(storageId, queue));
        }
    }, [queue.length]);

    useEffect(() => {
        return () => {
            dispatch(resetStockCounts());
        };
    }, []);

    return (
        <div className="tw-p-4 tw-h-full">
            {useFormTitle(isEdit ? form.id : null, t("supply"))}
            <HeaderRight
                formSubmit={{
                    ...form,
                    disabled: !isEditable,
                }}
                export={
                    form.id
                        ? () =>
                              Ajax.download(
                                  { url: `operation/excel-actions-for-supply/${form.id}` },
                                  `${t("supplies")} - ${form.id}`,
                              )
                        : undefined
                }
                importOptions={importProps}
            />
            <CForm {...form} disabled={!isEditable} formProps={{ layout: "vertical", fluid: true }}>
                <JsonView />

                <GeneralTab isDuplicate={isDuplicate} isApproved={isApproved} />

                <FormItems itemsFieldName="items">
                    <FormItemsList<SupplyItemField, Mutable<typeof columns>>
                        columns={columns as Mutable<typeof columns>}
                        searchableColumns={searchableColumns}
                        virtualize
                        showRowNumbers={true}
                        getRowProps={rowData => ({
                            className: rowData.field.disabled ? "tw-bg-red-50/50 hover:tw-bg-red-50" : "",
                        })}
                        footer={
                            <div className="tw-grid tw-grid-cols-subgrid tw-col-span-9 tw-sticky tw-bottom-0 tw-bg-white tw-border-gray-50 tw-border-solid tw-border-0 tw-border-t-2  tw-py-2 tw-z-10 tw-h-min">
                                <div className="tw-flex tw-col-span-4 tw-gap-2 ">
                                    <MassInsertButton
                                        idField="product_id"
                                        refineItem={product =>
                                            dummySupplyItem({
                                                product_id: product.id,
                                                unit_id: product.unit_id,
                                                cost: product.cost_price,
                                                total_cost: 0,
                                            })
                                        }
                                    >
                                        <CIcon path={iPlus} className="mr-2" />
                                        {t("add")}
                                    </MassInsertButton>
                                    {/* <FormItemsAddButton dummyData={dummySupplyItem} /> */}
                                    <AddByBarcodeButton />
                                </div>
                                <GridItemPlain
                                    align="right"
                                    className="tw-bg-transparent tw-border-none"
                                    value={t("total") + ":"}
                                />
                                <GridItemTotal name="discount_value" decimals={2} postfix={currency} />
                                <GridItemTotal name="tax_value" decimals={2} postfix={currency} />
                                <GridItemTotal name="total_cost" decimals={2} postfix={currency} />
                            </div>
                        }
                    >
                        {_props => <SupplyItem {..._props} />}
                    </FormItemsList>
                </FormItems>
            </CForm>
            <ImportResultModal {...importModal} />
            <CProductsDrawer />
        </div>
    );
};
