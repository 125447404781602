import { ErrorBoundary } from "components/ErrorBoundary";
import Header from "components/Header/Header";
import { useRouteLoader } from "config/routes";
import useInterval from "hooks/useInterval";
import { useModuleLoader } from "hooks/useModules";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useRoutes } from "hooks/useRoutes";
import { sendGrafanaOnlineTracker } from "lib/utils";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useIntegrations, usePackages } from "providers/PackageProvider";
import { ProductSelectorProvider } from "providers/ProductSelectorProvider";
import { FC, Suspense, useEffect, useRef } from "react";
import { Route, useLocation } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";
import { Container, Content, Loader } from "rsuite";
import GlobalError from "../components/GlobalError";
import MobileDetectModal from "../components/MobileDetectModal";
import NoAccessPage from "../pages/NoAccessPage";
import LayoutSidebar from "./Sidebar/LayoutSidebar";
import { PopupContainerProvider } from "hooks/usePopupContainer";
import { IRouteWithComp } from "types/routes";
import { useDrdsh } from "hooks/useDrdsh";
import Ajax from "lib/Ajax";
import { PackagesState, setPackages } from "redux/features/packages";
import { useLoading } from "hooks/useLoading";
import { LoadingScreen } from "core/components/LoadingScreen";

const INTERCOM_APP_ID = "xj4jn0kl";
const UserLayout: FC = () => {
    const routes = useRoutes();
    const packages = usePackages();
    const dispatch = useAppDispatch();
    const auth = useAuth();
    const isFullScreen = useAppSelector(state => state.ui.isFullScreen);
    const integrations = useIntegrations();
    const mainContentRef = useRef<HTMLDivElement>(null);
    const location = useLocation();
    const packageLoader = useLoading();
    const isSA = packages.country === "SA";

    useInterval(sendGrafanaOnlineTracker, 60000);
    useEffect(() => {
        sendGrafanaOnlineTracker();
    }, []);

    useModuleLoader();
    useRouteLoader();
    useDrdsh();

    useEffect(() => {
        void packageLoader.withLoading(async () => {
            const packages = await Ajax.get<PackagesState>({
                url: "/status",
                params: {
                    with: ["subscription.packages"],
                },
            });

            dispatch(setPackages(packages.data));
        });
    }, []);

    const checkModuleAccess = (route: IRouteWithComp) => !!packages.limitation?.[route.access!];

    // const checkModuleAvailability = (route: IRouteWithComp) => {
    //     const modules = packages.modules?.map(module => module.name);
    //     return route.modules?.every(module => modules?.includes(module));
    // };

    const checkIntegrationAvailability = (route: IRouteWithComp) => {
        return route.integrations?.every(
            integration =>
                integrations.findIndex((_integration: IIntegration) => _integration.service_name === integration) !==
                -1,
        );
    };

    const checkPermissions = (route: IRouteWithComp) => auth.hasAbility(route.permissions as string[]);

    const pageAccess = (route: IRouteWithComp): any => {
        // if (route.modules && !checkModuleAvailability(route)) {
        //     return NoAccessPage.bind(null, { title: t("no_access") });
        // }

        if (route.integrations && !checkIntegrationAvailability(route)) {
            return NoAccessPage.bind(null, { type: "no_access" });
        }

        if (route.access && !checkModuleAccess(route)) {
            return NoAccessPage.bind(null, { type: "no_access" });
        }

        if (route.permissions && Array.isArray(route.permissions) && !checkPermissions(route)) {
            return NoAccessPage.bind(null, { type: "no_permission" });
        }

        return route.Comp;
    };

    if (packageLoader.isLoading) {
        return <LoadingScreen />;
    }

    const mainContainer = (
        <PopupContainerProvider name="Main" container={mainContentRef}>
            <Container className="w-100 h-100 d-flex flex-row" style={{ background: "white" }}>
                <MobileDetectModal />
                <LayoutSidebar />
                <Container id="main-section" className="h-100 overflow-auto">
                    <Header />
                    <Content className="position-relative">
                        <div
                            id="MainContent"
                            ref={mainContentRef}
                            style={{
                                height: location.pathname.includes("/inventory/check/editor")
                                    ? "100% !important"
                                    : "auto",
                                background: isFullScreen ? "white" : "",
                            }}
                        >
                            <Suspense fallback={<Loader backdrop center />}>
                                {routes.map(route =>
                                    route.subroutes.map(sr => (
                                        <ErrorBoundary key={sr.path}>
                                            <Route exact key={sr.path} path={sr.path} component={pageAccess(sr)} />
                                        </ErrorBoundary>
                                    )),
                                )}
                                {/* <Route path="*" key="not-found" component={NotFoundPage} /> */}
                            </Suspense>
                        </div>
                    </Content>
                </Container>
            </Container>
        </PopupContainerProvider>
    );

    if (isSA) {
        return mainContainer;
    }

    return <IntercomProvider appId={INTERCOM_APP_ID}>{mainContainer}</IntercomProvider>;
};

const UserLayoutContainer: FCC = () => (
    <ProductSelectorProvider>
        <GlobalError>
            <UserLayout />
        </GlobalError>
    </ProductSelectorProvider>
);

export default UserLayoutContainer;
