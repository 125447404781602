import { TText } from "components/i18n/TText";
import { ProductType } from "config/constants";
import { useAppSelector } from "hooks/useRedux";
import _ from "lodash";
import { memo, useMemo, useState } from "react";
import { Item, Menu, useContextMenu } from "react-contexify";
import CurrencyInput from "react-currency-input-field";
import { Input } from "rsuite";
import { ArrowDownHollow, ArrowUpHollow, iTrash } from "assets/icons/Icons";
import CIcon from "../../CIcon";
import { Colors } from "config/colors";

const sortedColumnKey = (columnKey: any) => {
    switch (columnKey) {
        case "name":
            return "product.name";
        case "category":
            return "category.name";
        default:
            return columnKey;
    }
};
const handleFocus = (event: any) => {
    event.target.select();
};
const handleKeyDown = (event: any) => {
    try {
        if (event.key === "Enter") {
            const current = event.target.tabIndex;
            const nextI: any = document.querySelector(`[tabindex = "${current + 1}"]`);
            if (nextI) nextI.focus();
        }
    } catch (ex) {
        /* empty */
    }
};
const CSheetInputWithConfirm = memo(({ iValue, onCellChange, dI, c, d, inputType }: any) => {
    return (
        <div>
            <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                {_.isNil(inputType) || inputType === "money" ? (
                    <CurrencyInput
                        test-id={"inv-input-currency"}
                        className="inv-input-currency"
                        defaultValue={iValue}
                        decimalsLimit={4}
                        onValueChange={val => onCellChange(d, val, c.key)}
                        decimalSeparator="."
                        groupSeparator=","
                        allowNegativeValue={false}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                        tabIndex={dI}
                    />
                ) : (
                    <Input
                        className="inv-input-generic"
                        type={inputType}
                        defaultValue={iValue}
                        onChange={val => onCellChange(d, val, c.key)}
                        onFocus={handleFocus}
                        onKeyDown={handleKeyDown}
                        tabIndex={dI}
                    />
                )}
                {c?.suffix && <label style={{ padding: "0 5px 0 5px" }}>{c?.suffix(d)}</label>}
            </div>
        </div>
    );
});

type IProps = {
    onChange: (item: IProduct, value: string, key: string) => void;
    data: any[] | null;
    columns: any[];
    readOnly?: boolean | any;
    displayInputError?: boolean;
    fullScreen?: boolean;
    deleteContextMenuAction?: any;
};
const MENU_ID = new Date().toISOString();

const SheetTableState = ({
    data,
    columns,
    onChange,
    readOnly,
    displayInputError,
    fullScreen,
    deleteContextMenuAction,
}: IProps) => {
    const [tableSort, setTableSort] = useState<any>({});
    const iMan: any = useAppSelector(state => state.itemManagement);
    const { items, searchValue } = iMan;

    const { show } = useContextMenu({
        id: MENU_ID,
    });

    const onCellChange = (item: IProduct, value: string, key: string) => {
        onChange(item, value, key);
    };

    const onSorting = (column: any) => {
        const key = sortedColumnKey(column.key);

        setTableSort((prev: any) => {
            let d = { ...prev };

            if (_.isNil(d[key])) {
                d[key] = true;
            } else if (_.isEqual(d[key], true)) {
                d[key] = false;
            } else if (_.isEqual(d[key], false)) {
                const x: any = {};

                for (const a in d) {
                    if (a !== key) x[a] = d[a];
                }

                d = x;
            }

            return d;
        });
    };

    const dataToggle = useMemo(() => {
        if (Object.keys(tableSort).length === 0) return data;

        const sortVals: ("desc" | "asc")[] = [];

        for (const x in tableSort) {
            if (tableSort[x] !== 0) sortVals.push(tableSort[x] === true ? "desc" : "asc");
        }

        const keys = Object.keys(tableSort).map(key => (obj: any) => {
            const value: any = _.get(obj, key);

            return typeof value === "string" ? value.toLowerCase() : value;
        });

        return _.orderBy(data, keys, sortVals);
    }, [data, tableSort]);

    return (
        <>
            <table className="inv-table" test-id="inv-table">
                <thead
                    style={{
                        position: "sticky",
                        top: fullScreen ? "-17px" : "",
                        zIndex: 1,
                    }}
                >
                    <tr>
                        {columns?.map((c: any) => {
                            const key = sortedColumnKey(c.key);
                            let sortIcon = null;
                            if (c?.sortable === true) {
                                if (tableSort[key] === true) {
                                    sortIcon = <CIcon path={ArrowUpHollow} className="inv-table-sort-icon" />;
                                } else if (tableSort[key] === false) {
                                    sortIcon = <CIcon path={ArrowDownHollow} className="inv-table-sort-icon" />;
                                }
                            }
                            return (
                                <th
                                    key={c.key}
                                    style={{
                                        width: c.width,
                                        position: c.key === "name" ? "sticky" : "unset",
                                        left: c.key === "name" ? -17 : "unset",
                                        cursor: c?.sortable ? "pointer" : "unset",
                                    }}
                                    onClick={c?.sortable ? () => onSorting(c) : undefined}
                                >
                                    <div>
                                        <label style={{ cursor: c?.sortable ? "pointer" : "unset" }}>
                                            {c?.title || c?.label}
                                        </label>
                                        {sortIcon}
                                    </div>
                                </th>
                            );
                        })}
                    </tr>
                </thead>
                <tbody>
                    {dataToggle?.map((d: any, dI: number) => {
                        if (searchValue?.length > 0) {
                            const regex = new RegExp(_.escapeRegExp(searchValue), "gmui");
                            if ((d?.name || d?.slug || d?.parent_name || "")?.match(regex) === null) return null;
                        }

                        return (
                            <tr key={d.id}>
                                {columns?.map((c: any, i: number) => {
                                    const value =
                                        items?.[d.id]?.[c.key] === 0
                                            ? items?.[d.id]?.[c.key]
                                            : items?.[d.id]?.[c.key] || d?.[c.key];
                                    const parsedValue = parseFloat(
                                        !_.isNumber(items?.[d.id]?.[c.key]) ? d[c.key] : items?.[d.id]?.[c.key],
                                    );
                                    const iValue = _.isNaN(parsedValue) ? "" : parsedValue;
                                    const hasError =
                                        c?.readOnly === true
                                            ? "1px solid #DDD"
                                            : (_.isNil(iValue) || !_.isNumber(iValue)) &&
                                                d.type !== ProductType.PREPARATION &&
                                                d.type !== ProductType.DISH &&
                                                c.type === "qty" &&
                                                displayInputError
                                              ? "1.5px solid red"
                                              : "1px solid #DDD";

                                    return (
                                        <td
                                            test-id={c.key}
                                            key={c.key + i}
                                            style={{
                                                border: hasError,
                                                backgroundColor: c?.onClick
                                                    ? Colors.WhisperGray
                                                    : c?.inputType
                                                      ? Colors.ArcticWhite
                                                      : Colors.White,
                                                cursor: c?.onClick ? "pointer" : "default",
                                                minWidth: c.key === "name" ? "auto" : 130,
                                                maxWidth: c.key === "name" ? "auto" : 130,
                                                position: c.key === "name" ? "sticky" : "unset",
                                                left: c.key === "name" ? -17 : "unset",
                                                color: d.product?.deleted_at ? "red" : "",
                                            }}
                                            onClick={c?.onClick ? () => c?.onClick(d) : undefined}
                                            onContextMenu={
                                                readOnly ? undefined : e => show({ event: e, id: MENU_ID, props: d })
                                            }
                                        >
                                            {c?.readOnly || readOnly ? (
                                                (() => {
                                                    if (c?.render) return c?.render(d);
                                                    if (c?.suffix && value) return value + " " + c?.suffix?.(d);
                                                    if (c?.type === "qty" || c?.type === "money") {
                                                        return `0${c?.suffix ? " " + c?.suffix?.(d) : ""}`;
                                                    } else {
                                                        return "-";
                                                    }
                                                })()
                                            ) : (
                                                <CSheetInputWithConfirm
                                                    iValue={c?.inputType === "text" ? value : iValue}
                                                    onCellChange={onCellChange}
                                                    inputType={c?.inputType}
                                                    dI={dI}
                                                    c={c}
                                                    d={d}
                                                />
                                            )}
                                        </td>
                                    );
                                })}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            {!readOnly && (
                <Menu id={MENU_ID}>
                    <Item onClick={deleteContextMenuAction}>
                        <CIcon path={iTrash} style={{ marginRight: 10 }} />
                        <TText tkey="remove" />
                    </Item>
                </Menu>
            )}
        </>
    );
};

export default SheetTableState;
