import DataTable from "components/DataTable/DataTable";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";

import { useTableHeight } from "hooks/useTableHeight";
import { useData } from "hooks/useData";
import { useColumns } from "../DataTable/ColumnsManager/useColumns";

interface IProps extends ICDrawerProps {
    dateFilter: any;
}

const StaffServiceChargeDrawer: FCC<IProps> = ({ ...props }) => {
    const dp = useData("reports/service-charge-daily", {
        params: { date: props.dateFilter },
        filters: [["receipt_owner_id", props?.data?.user?.id]] as any,
    });

    const cm = useColumns<any>(`service-charge-drawer`, [
        {
            title: t("date"),
            key: "date",
            type: "date",
            flexGrow: 3,
        },
        {
            title: t("total_service_charge"),
            key: "service_charge_amount",
            type: "money",
            numberRoundingLength: 2,
            align: "left",
            flexGrow: 3,
        },
    ]);
    const height = useTableHeight(60);

    return (
        <CDrawer size="md" {...props} title={props?.data?.user?.username}>
            <div className="">
                <div className="d-flex">
                    <div className="flex-fill border-left">
                        <DataTable
                            noShadow
                            columns={cm.columns}
                            dp={{ data: dp.data } as any}
                            hidePages
                            tableProps={{
                                autoHeight: false,
                                height,
                            }}
                        />
                    </div>
                </div>
            </div>
        </CDrawer>
    );
};

export default StaffServiceChargeDrawer;
