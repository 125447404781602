import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useTitle } from "components/Header/Title";

import DataTable from "../../../components/DataTable/DataTable";
import HeaderRight from "../../../components/Header/HeaderRight";
import _ from "lodash";
import { translateUnitName } from "lib/utils";
import { exportFile } from "lib/export-table";
import { useDrawer } from "components/Drawers/useDrawer";
import Money from "components/mini/Money";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import { CategoryTypeEnum, ProductType } from "config/constants";
import { productNameAppend } from "lib/data-utils";
import { useData } from "hooks/useData";
import { useLocation } from "react-router-dom";
import nrInRoutings from "./nr-in-routings";
import { QuickLinks } from "components/Header/QuickLinks";
import React from "react";
import ToggleFilterDrawer from "../../../components/DataTable/Filters/ToggleFilterDrawer";
import ReturnReportFilters from "./components/ReturnReportFilters";
import MenuCategoryTreeFilter from "../../../components/DataTable/Filters/CategoryTreeFilter/MenuCategoryTreeFilter";

const NewReportsReturnsPage: FCC = () => {
    const loc = useLocation();
    const url = "reports/returns";
    const filter = useDrawer();

    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        url,
        with: ["category", "product.unit"],
        extraFilterKeys: [
            "receipt_id",
            "receipt_terminal_id",
            "receipt_table_id",
            "receipt_owner_id",
            "product_type",
            "sale_type_id",
            "accounting_category_id",
            "receipt_customer_id",
            "gift",
            "source",
        ],
    });

    const summary = useData<any>(url, {
        filters: _.values(dp.filters.all),
        params: {
            total: 1,
            date: dp.params.all.date,
            time: dp.params.all?.time,
        },
    });

    const cm = useColumns<any>("reports/returns", [
        {
            title: "ID",
            key: "product_id",
            align: "left",
            flexGrow: 0.3,
        },
        {
            title: t("name"),
            key: "product.name",
            align: "left",
            flexGrow: 2,
        },
        {
            title: t("type"),
            key: "product.type",
            render: d => _.capitalize(t(d.product?.type?.toLowerCase())),
        },
        {
            title: t("total_count"),
            key: "total_count",
            summary: () => summary.data?.[0]?.count,
            render: e => (
                <>
                    <span>{e.total_count}</span>{" "}
                    <span style={{ marginLeft: "5px" }}>{translateUnitName(e.product?.unit)}</span>
                </>
            ),
        },
        {
            title: t("price"),
            key: "average_price",
            type: "money",
            flexGrow: 0.8,
            numberRoundingLength: 2,
        },
        {
            title: t("total_cost"),
            key: "total_cost",
            type: "money",
            numberRoundingLength: 2,
            flexGrow: 0.8,
            render: d => <Money value={d?.total_cost} />,
            summary: () => <Money value={summary.data?.[0]?.total_cost} />,
        },
        {
            title: t("profit"),
            key: "profit",
            type: "money",
            numberRoundingLength: 2,
            flexGrow: 0.8,
            render: d => <Money value={d?.profit} />,
            summary: () => <Money value={summary.data?.[0]?.profit} />,
        },
        {
            title: t("amount"),
            key: "total_amount",
            type: "money",
            flexGrow: 0.8,
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.total_amount} />,
        },
        {
            title: t("waste_count"),
            key: "waste_count",
            align: "right",
            flexGrow: 0.8,
            render: (d: any) => <span style={{ marginRight: "5px" }}>{d.waste_count}</span>,
            summary: () => <span style={{ marginRight: "5px" }}>{summary.data?.[0]?.waste_count}</span>,
        },
    ]);
    return (
        <div className="h-100">
            {useTitle(t("return"), dp.total)}
            <QuickLinks inHeader={false} links={nrInRoutings(loc)} />

            <HeaderRight cm={cm} print export={() => exportFile(dp, cm.columns, t("products"))} reload date dp={dp} />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <RemoteCheckListFilter
                        fields="product_id"
                        searchField="name"
                        remote={{
                            url: "product",
                            sort: ["updated_at", "-1"],
                            params: {
                                trash: [2],
                            },
                            limit: 20,
                            refineList: (products?: IProduct[]) =>
                                products
                                    ?.map(i => productNameAppend(i))
                                    ?.filter(f => !(f.type === ProductType.GOODS && f.has_modifications)) ?? [],
                        }}
                        pickerProps={{ placeholder: t("product") }}
                    />
                    <MenuCategoryTreeFilter categoryTypes={[CategoryTypeEnum.PRODUCT]} />
                    <RemoteCheckListFilter
                        remote={{
                            url: "station",
                            refineList: (list: any[]) => {
                                list?.push({ id: null, name: t("without_station") });
                                return list;
                            },
                        }}
                        searchField="name"
                        fields="station_id"
                        pickerProps={{ placeholder: t("station") }}
                    />

                    <ToggleFilterDrawer
                        isOpen={filter.isOpen}
                        onChange={val => {
                            filter.setIsOpen(val);
                        }}
                    />
                </div>
            </FiltersContainer>

            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
            <ReturnReportFilters {...filter} dp={dp} />
        </div>
    );
};

export default NewReportsReturnsPage;
