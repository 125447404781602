import { t } from "lib/i18n";

import { mixed, number, object, string } from "yup";

export const dummyTransaction = (type?: TransactionType, date?: Date): Partial<ITransaction> => {
    switch (type) {
        case "IN":
            return {
                type,
                amount: null,
                operated_at: date || new Date(),
                description: "",
                category_id: null,
                balance_id: null,
            };
        case "OUT":
            return {
                type,
                amount: null,
                operated_at: date || new Date(),
                description: "",
                category_id: null,
                balance_id: null,
            };
        case "TRANSFER":
            return {
                type,
                amount: null,
                operated_at: date || new Date(),
                description: "",
                from_balance_id: null,
                balance_id: null,
                category_id: 3,
            };
        default:
            return {
                type: "OUT",
                amount: null,
                operated_at: date || new Date(),
                description: "",
                category_id: null,
                balance_id: null,
            };
    }
};

export const dummySupplierTransaction = () => {
    return {
        type: "SUPPLIER_PAY",
        amount: 0,
        operated_at: new Date(),
        description: "",
        from_balance_id: null,
        supplier_id: null,
        balance_id: null,
        category_id: null,
    };
};

export const SupplierTransactionModel = () =>
    object().shape({
        amount: number().typeError(t("required")).moreThan(0).required(t("required")),
        from_balance_id: number().typeError(t("required")).required(t("required")),
        balance_id: number().typeError(t("required")).required(t("required")),
        category_id: number().typeError(t("required")),
        supplier_id: number().typeError(t("required")).required(t("required")),
    });

export const TransactionModel = () =>
    object().shape({
        type: string().required(t("required")),
        amount: number().typeError(t("required")).moreThan(0, t("must_be_greater_0")).required(t("required")),
        balance_id: number().typeError(t("required")).required(t("required")),
        from_balance_id: number()
            .nullable()
            .when("type", {
                is: "TRANSFER",
                then: number().required(t("required")),
                otherwise: number().notRequired(),
            }),
        category_id: number()
            .nullable()
            .when("type", {
                is: "TRANSFER",
                then: number().notRequired(),
                otherwise: number().required(t("required")),
            }),
        staff_id: mixed().nullable(),
        operated_at: string().required(t("required")),
        description: string(),
    });

export const SupplierPaymentTransactionModel = () =>
    object().shape({
        finance: object().shape({
            balance_id: number().required(t("required")),
            category_id: number().typeError(t("required")),
        }),
        // operated_at: date().required(t("required")),
    });
export const SupplierPaymentTransactionDummy = () => {
    return {
        finance: {
            category_id: null,
            balance_id: null,
        },
        description: "",
        operated_at: new Date(),
    };
};
