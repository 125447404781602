import { t } from "lib/i18n";
import { IDataProvider } from "components/DataTable/useDataProvider";
import { getLabel } from "components/Form/form-utils";
import { Constants } from "config/constants";
import CChart from "components/Chart";
import Widget from "components/Widget";

import { IconButton } from "rsuite";
import { getPointerLabel, groupByWeekDay, IDatesReportData } from "lib/chart-utils";
import { useDepot } from "hooks/useDepot";
import { iBarChart, iPieChart } from "assets/icons/Icons";
import CIcon from "../../../components/CIcon";
import { useCurrency } from "hooks/useCurrency";

interface IProps {
    activeTab: string;
    dp: IDataProvider<IDatesReportData>;
}

const WeekDayChartWidget: FCC<IProps> = ({ activeTab, dp }) => {
    const [chartType, setChartType] = useDepot("weekDayChartType", "pie");
    const data = groupByWeekDay(dp.data).map((d: any, i) => {
        return {
            y: d[activeTab],
            name: Constants.weekDays[d.weekDay],
        };
    });
    const currency = useCurrency();
    /**
     * since each chart has different data types such as total earning or number of guest or even average time,
     * data needs to be formatter accordingly
     */
    const type =
        activeTab === "average_time" ? "time" : ["total_guests", "total_receipt"].includes(activeTab) ? "" : "money";

    const btnProps = (name: string): any => ({
        size: "xs",
        appearance: name === chartType ? "primary" : undefined,
        onClick: () => setChartType(name),
    });

    return (
        <Widget
            title={t("right_chart_title")}
            headerButtons={
                <>
                    <IconButton className="mr-2" icon={<CIcon path={iPieChart} />} {...btnProps("pie")} />
                    <IconButton icon={<CIcon path={iBarChart} />} {...btnProps("column")} />
                </>
            }
        >
            <CChart
                options={{
                    chart: {
                        height: 200,
                    },
                    xAxis: {
                        type: "category",
                        categories: Constants.weekDays,
                    },
                    tooltip: {
                        useHTML: true,
                        headerFormat: "<small>{point.key}</small><table>",
                        pointFormatter() {
                            return getPointerLabel(this, type, currency);
                        },
                        footerFormat: "</table>",
                    },
                }}
                series={[{ type: chartType, data, name: getLabel(activeTab) } as any]}
            />
        </Widget>
    );
};

export default WeekDayChartWidget;
