import { t } from "lib/i18n";
import Widget from "../../components/Widget";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { FinanceAccountDefaultFilters } from "config/constants";
import { getPointerLabel } from "lib/chart-utils";
import CChart from "../../components/Chart";
import NoData from "components/NoData";
import _ from "lodash";
import { shiftYesterdayF } from "lib/shiftYesterdayF";
import { shiftTomorrowF } from "lib/shiftTomorrowF";
import { moneyFormatter } from "lib/moneyFormatter";
import { useDepot } from "hooks/useDepot";
import { IconButton } from "rsuite";
import CIcon from "../../components/CIcon";
import { iBarChart, iPieChart } from "assets/icons/Icons";
import { useCurrency } from "hooks/useCurrency";
import { useSetting } from "hooks/useSetting";

const AccountWidget: FCC = () => {
    const [chartType, setChartType] = useDepot("PaymentTypeWidget", "column");
    const url = "finance/balance";
    const cashRestart = useSetting("cash_restart")?.value;
    const currency = useCurrency();

    const dp = useDataProvider({
        url,
        filters: FinanceAccountDefaultFilters,
        params: {
            date: [shiftYesterdayF(cashRestart), shiftTomorrowF(cashRestart)],
        },
    });

    const data = dp.data
        ?.filter((account: IAccount) => account.amount > 0)
        .map((account: IAccount) => ({
            y: account.amount,
            name: `${account.name} <br/> ${moneyFormatter(account.amount)} ${currency}`,
        }));

    const btnProps = (name: string): any => ({
        size: "xs",
        appearance: name === chartType ? "primary" : undefined,
        onClick: () => setChartType(name),
    });

    return (
        <Widget
            title={t("accounts")}
            headerButtons={
                <>
                    <IconButton className="mr-2" icon={<CIcon path={iBarChart} />} {...btnProps("column")} />
                    <IconButton className="mr-2" icon={<CIcon path={iPieChart} />} {...btnProps("pie")} />
                </>
            }
        >
            {_.isEmpty(data) ? (
                <NoData />
            ) : (
                <CChart
                    options={{
                        chart: { height: 200 },
                        xAxis: {
                            categories: data.map((m: any) => m.name),
                        },
                        tooltip: {
                            useHTML: true,
                            headerFormat: "<small>{point.key}</small><table>",
                            pointFormatter() {
                                return getPointerLabel(this, "money", currency);
                            },
                            footerFormat: "</table>",
                        },
                    }}
                    series={[{ type: chartType, data, name: t("amount") } as any]}
                />
            )}
        </Widget>
    );
};

export default AccountWidget;
