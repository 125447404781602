import { t } from "lib/i18n";
import { ModifierGroupType } from "config/constants";
import { ValidationSchema, getParent } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";

export const ModifierGroupSchema: ValidationSchema = {
    name: ruleset.required,
    min_select: ruleset.combineIf(
        (val, vals, par) => par?.type === ModifierGroupType.MULTI,
        [ruleset.gte(0), ruleset.lte("max_select", true)],
    ),
    max_select: [ruleset.gte(1), ruleset.gte("min_select", true)],
    modificators: [
        {
            brutto: ruleset.gte(0),
            price: ruleset.gte(0),
            default_count: ruleset.combineIf(
                (val, vals, par) => getParent(par).type === ModifierGroupType.MULTI,
                [ruleset.gte("min_count", true), ruleset.lte("max_count", true)],
            ),
            min_count: ruleset.combineIf(
                (val, vals, par) => getParent(par).type === ModifierGroupType.MULTI,
                [
                    ruleset.gte(0),
                    ruleset.lte("max_count", true),
                    (value, values, parent) => {
                        const mg = getParent(parent) as IModifierGroup;
                        if (value > mg.max_select) {
                            return t("validation_compare_value_less_or_equal", {
                                compareValue: mg.max_select,
                            });
                        }

                        const arr = mg.modificators ?? [];
                        const sum = arr.reduce((acc, item) => acc + (item.min_count ?? 0), 0);

                        return sum > mg.max_select ? t("sum_of_min_values_greater_than_max_select") : undefined;
                    },
                ],
            ),
            max_count: [ruleset.gte(1), ruleset.gte("min_count", true)],
        },
    ],
};
