import React, { useEffect } from "react";
import CDrawer from "../../../../components/Drawers/CDrawer";
import ProductPricesPage from "./ProductPricesPage";
import CForm from "components/Form/CForm/CForm";
import { useCForm } from "hooks/useCForm";
import Ajax from "lib/Ajax";
import _ from "lodash";

const ProductPricesDrawer = (props: any) => {
    const data = props?.data;
    const has_modifications = data?.has_modifications;
    const ids = has_modifications ? data?.modifications?.map((d: any) => d.id) : [data?.id];

    const form = useCForm({
        url: "products/prices",
        methodOnSubmit: "post",
        getData: () => {
            const url = Ajax.buildUrl({
                url: "products/prices",
                params: {
                    filters: [["product_id", ids]],
                },
            });
            return Ajax.get({ url });
        },
        refine: (d: IMultiPrice[]) => {
            const grouped = _.groupBy(d, "product_id");
            return { product_prices: Object.values(grouped) };
        },
        redirectUrl: false,
    });

    useEffect(() => {
        if (props.data?.id) {
            form.set("url", "products/prices" + `/${props.data?.id}`);
        }
    }, [props.data?.id]);

    useEffect(() => {
        form.set("prepareForSubmit", (d: any) => {
            return {
                ...d.product_prices
                    ?.map((data: Array<IMultiPrice>) => data.filter((dt: IMultiPrice) => dt.list_id !== null))
                    .flat(1),
            };
        });
        form.set("afterSubmit", (d: any) => {
            form.form.reset([]);
            props.onClose();
        });
    }, [has_modifications]);

    return (
        <CDrawer
            className="product-price-drawer"
            title={"Prices / " + data?.name + " - (ID: " + data?.id + ")"}
            {...props}
            onExited={() => form.form.reset([])}
        >
            <CForm form={form.form} onSave={form.onSave} noPanel>
                <ProductPricesPage data={data} form={form} has_modifications={has_modifications} />
            </CForm>
        </CDrawer>
    );
};

export default ProductPricesDrawer;
