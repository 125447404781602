import { t } from "lib/i18n";

import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { useDrawer } from "components/Drawers/useDrawer";

import DataTable from "../DataTable/DataTable";
import { useColumns } from "../DataTable/ColumnsManager/useColumns";
import { useTableHeight } from "hooks/useTableHeight";
import { ReasonType } from "config/constants";
import { useDataProvider } from "../DataTable/useDataProvider";
import { optionsColumn } from "../DataTable/ColumnsManager/OptionsColumn";
import ReasonsEditorDrawer from "./ReasonsEditorDrawer";
import { iPlus } from "assets/icons/Icons";

type IProps = ICDrawerProps;

const ReasonsDrawer: FCC<IProps> = props => {
    const dp = useDataProvider({
        url: "reasons",
        filters: {
            type: ["type", ReasonType.delete],
        },
    });
    const useReasonDrawer = useDrawer();
    const cm = useColumns<any>("reasons-drawer", [
        {
            key: "name",
            align: "left",
            flexGrow: 9,
        },
        {
            key: "created_at",
            title: t("created_at"),
            type: "datetime",
            hide: true,
            flexGrow: 1,
        },
        optionsColumn({
            dp,
            edit: payload => {
                props.setIsClosable(false);
                useReasonDrawer.setData({
                    action: "edit",
                    type: ReasonType.delete,
                    payload,
                });
            },
            editable: d => d?.deleted_at,
            delete: "reasons/:id",
        }),
    ]);

    const completeDrawerCRUD = () => {
        useReasonDrawer.hide();
        dp.revalidate();
    };

    const height = useTableHeight(250);

    return (
        <CDrawer
            {...props}
            size="sm"
            title={t("receipt_product_delete_reasons")}
            saveIBtn={() => {
                props.setIsClosable(false);
                useReasonDrawer.setData({
                    action: "create",
                    type: ReasonType.delete,
                });
            }}
            saveIBtnIcon={iPlus}
        >
            <DataTable
                noShadow
                columns={cm.columns}
                dp={dp}
                tableProps={{
                    autoHeight: false,
                    height,
                }}
            />
            <ReasonsEditorDrawer
                completeDrawerCRUD={completeDrawerCRUD}
                {...useReasonDrawer}
                onClose={e => {
                    useReasonDrawer.onClose(e);
                    props.setIsClosable(true);
                }}
            />
        </CDrawer>
    );
};

export default ReasonsDrawer;
