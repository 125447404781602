import { createAsyncThunk } from "@reduxjs/toolkit";

import { fetchVenues } from "lib/utils/fetchVenues";

export const fetchVenuesAsync = createAsyncThunk(
    "venue/fetchVenues",
    async (token: string | undefined, { getState, dispatch }) => {
        const venues = await fetchVenues(token);
        // if (venues.length > 0) {
        //     console.log("fetchVenuesAsync", venues[0].id);
        //     dispatch(setCurrentVenue(venues[0].id));
        // }
        return venues;
    },
);
