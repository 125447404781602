import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import ToggleFilter from "components/DataTable/Filters/ToggleFilter";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import LocalCheckListFilter from "../../../../components/DataTable/Filters/LocalCheckListFilter";
import { Colors } from "config/colors";

interface Props extends ICDrawerProps {
    dp: any;
}

const col = "d-flex justify-content-between align-items-center w-100";

const ProductReturnFilters = (props: Props) => {
    return (
        <CDrawer size="xs" title={t("filters")} {...props}>
            <div className="d-flex flex-column justify-content-between" style={{ height: "89vh" }}>
                <div className="px-3 pt-3 clopos-scroll" style={{ overflowY: "auto" }}>
                    <FiltersContainer hasPadding={true} dp={props.dp}>
                        <SearchFilter
                            className="search-input tw-max-w-full"
                            fields="receipt_id"
                            placeholder={t("receipt_number")}
                        />

                        <div style={{ margin: "24px 0px 0px" }} className={col}>
                            <label style={labelRowStyle}>
                                <TText tkey="show_deleted_values" />
                            </label>
                            <ToggleFilter useParams fields="trash" value="2" title={t("show_deleted_values")} />
                        </div>

                        <label style={labelColStyle}>
                            <TText tkey="customers" />
                        </label>
                        <RemoteCheckListFilter
                            fields="customer_id"
                            searchField="name"
                            remote="customer"
                            pickerProps={{ placeholder: t("customers") }}
                            width={450}
                            maxWidth={450}
                        />

                        <label style={labelColStyle}>
                            <TText tkey="payment_method" />
                        </label>
                        <RemoteCheckListFilter
                            fields="paymentMethods"
                            remote={{
                                url: "finance/paymentMethod",
                            }}
                            searchField="name"
                            pickerProps={{ placeholder: t("payment_method") }}
                            width={450}
                            maxWidth={450}
                        />

                        <label style={labelColStyle}>
                            <TText tkey="terminals" />
                        </label>
                        <LocalCheckListFilter
                            fields="terminal_id"
                            url="terminal"
                            pickerProps={{ placeholder: t("terminals") }}
                            width={450}
                            maxWidth={450}
                        />
                    </FiltersContainer>
                </div>
            </div>
        </CDrawer>
    );
};

export default ProductReturnFilters;

const labelRowStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
};
const labelColStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
    marginTop: "24px",
};
