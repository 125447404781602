const IconPreparationComponent = ({ color, size, style }: { color?: string; size: number; style?: any }) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            height={size}
            width={size}
            style={style}
            fill={color}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 45 45"
        >
            <g>
                <path
                    d="M43.2,23.8H41v-1.7c0,0,0,0,0,0c0,0,0,0,0,0c0-1.3-0.6-2.6-1.6-3.4c-2.3-1.8-6-3.1-10.3-3.6c0-0.2,0.1-0.5,0.1-0.7
           c0-2.3-1.8-4.1-4.1-4.1c-2.3,0-4.1,1.8-4.1,4.1c0,0.2,0,0.5,0.1,0.7c-4.3,0.5-8,1.8-10.3,3.6c-1,0.8-1.6,2.1-1.6,3.4c0,0,0,0,0,0
           v1.7H6.8c-0.8,0-1.4,0.6-1.4,1.4v0.4C5.4,26.4,6,27,6.8,27H9v5.7c0,3.9,3.2,7,7,7h17.9c3.9,0,7-3.2,7-7V27h2.2
           c0.8,0,1.4-0.6,1.4-1.4v-0.4C44.6,24.4,44,23.8,43.2,23.8z M25,11.3c1.7,0,3.1,1.4,3.1,3.1c0,0.2,0,0.3,0,0.5h-0.6
           c0-0.1,0-0.3,0-0.5c0-1.4-1.1-2.5-2.5-2.5c-1.4,0-2.5,1.1-2.5,2.5c0,0.2,0,0.3,0,0.5h-0.6c0-0.1,0-0.3,0-0.5
           C21.9,12.7,23.3,11.3,25,11.3z M23.5,14.4c0-0.8,0.7-1.5,1.5-1.5c0.8,0,1.5,0.7,1.5,1.5c0,0.2,0,0.3-0.1,0.5c-0.5,0-1,0-1.5,0
           c-0.5,0-1,0-1.5,0C23.5,14.8,23.5,14.6,23.5,14.4z M11.3,19.5c2.8-2.2,8.1-3.6,13.7-3.6c5.7,0,10.9,1.4,13.7,3.6
           c0.7,0.5,1.1,1.3,1.2,2.1H10C10.1,20.8,10.6,20,11.3,19.5z M6.8,26c-0.2,0-0.4-0.2-0.4-0.4v-0.4c0-0.2,0.2-0.4,0.4-0.4H9V26H6.8z
            M40,32.7c0,3.3-2.7,6.1-6.1,6.1H16.1c-3.3,0-6.1-2.7-6.1-6.1v-6.1v-2.2v-1.7h30v1.7v2.2V32.7z M43.6,25.6c0,0.2-0.2,0.4-0.4,0.4
           H41v-1.3h2.2c0.2,0,0.4,0.2,0.4,0.4V25.6z"
                />
                <path d="M11.4,23c-0.3,0-0.5,0.2-0.5,0.5v9.3c0,0.3,0.2,0.5,0.5,0.5c0.3,0,0.5-0.2,0.5-0.5v-9.3C11.9,23.3,11.7,23,11.4,23z" />
            </g>
        </svg>
    );
};

export default IconPreparationComponent;
