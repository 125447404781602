import { IDataProvider } from "components/DataTable/useDataProvider";
import { ICDrawerProps } from "components/Drawers/CDrawer";
import CFormModal from "components/Drawers/CFormModal";
import CheckboxFormGroup from "components/Form/CheckboxFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import { t } from "lib/i18n";
import { useCForm } from "hooks/useCForm";
import { ruleset } from "lib/validation-rules";

interface IProps extends ICDrawerProps {
    dataId?: number;
    data?: any;
    dp?: IDataProvider;
    hide: () => void;
}

const dummyStation = () => ({
    name: "",
    printable: false,
    meta: { station_reminder: false },
});

const MenuStationCreateModal: FCC<IProps> = props => {
    const editId = props.data?.id || props.dataId;

    const form = useCForm({
        id: editId,
        url: "station",
        isModal: true,
        dummyData: dummyStation,
    });

    return (
        <CFormModal
            {...props}
            form={form}
            testId="menu-station-modal"
            formProps={{ layout: "horizontal" }}
            title={`${t("stations")} / ${editId || t("create")}`}
            size="xs"
            revalidate={props.dp?.revalidate}
        >
            <TextFormGroup
                name="name"
                // focus={true}
                label={false}
                compProps={{
                    placeholder: t("name"),
                }}
                rules={{
                    validate: {
                        required: ruleset.required,
                        minLength: ruleset.minLength(2),
                    },
                }}
            />
            <CheckboxFormGroup
                testId="station-printable"
                controlWrapperProps={{ style: { paddingLeft: 0 } }}
                label={false}
                name="printable"
            />
            <CheckboxFormGroup
                testId="station-station-reminder"
                controlWrapperProps={{ style: { paddingLeft: 0 } }}
                label={t("station_reminder")}
                name="meta.station_reminder"
            />
        </CFormModal>
    );
};

export default MenuStationCreateModal;
