import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";
import { t } from "lib/i18n";
import { IReceiptPaymentMethod } from "@cloposcom/libs/src/receipt-calculation/interfaces";

export const ReceiptRepriceScheme: ValidationSchema = {
    payment_methods: [
        {
            id: ruleset.required,
            name: ruleset.required,
            amount: ruleset.combine([
                ruleset.gt(0),
                (_, values) => {
                    const total =
                        values.payment_methods.reduce(
                            (prev: number, cur: IReceiptPaymentMethod) => prev + Number(cur.amount),
                            0,
                        ) || 0;

                    if (Number(values.receipt?.total || 0) !== total) {
                        return t("total_not_match");
                    }
                },
            ]),
        },
    ],
};
