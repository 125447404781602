import { convertToSlug } from "lib/convertToSlug";

export function updateBaseUrl(brand: string, venueId: number) {
    window.location.href = refineVenueUrl(brand, venueId);

    // window.history.pushState({}, "", url.toString());
}

export function refineVenueUrl(brand: string, venueId: number): string {
    const url = new URL(window.location.href);
    const path = url.pathname.split("/");

    path[1] = brand;
    path[2] = venueId.toString();

    url.pathname = path.slice(0, 3).join("/");
    url.search = "";
    return url.toString();
}

export function refineUrl(brand: string, venueId: number): string {
    const url = new URL(window.location.href);
    const path = url.pathname.split("/");
    path[1] = brand;
    path[2] = venueId?.toString() ?? "";
    url.pathname = path.join("/");

    return url.toString();
}

export const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

export const getDatePairFromNumbers = (dates: [number, number]) => dates.map(date => new Date(date)) as [Date, Date];
export const getNumberPairFromDates = (dates: [Date, Date]) => dates.map(date => date.getTime()) as [number, number];

export const isContainValue = (searchVal: string, value: string = "") =>
    new RegExp(convertToSlug(searchVal), "gi").test(value);
