import { useEffect, useState } from "react";

export function useDebouncedSearch(callbakcHandler: () => void, delay: number = 500) {
    const [searchInputValue, setSearchInputValue] = useState("");

    useEffect(() => {
        const timeoutId = setTimeout(callbakcHandler, delay);

        return () => clearTimeout(timeoutId);
    }, [searchInputValue]);

    return {
        searchInputValue,
        setSearchInputValue,
    };
}
