import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React, { useEffect, useState } from "react";
import CIcon from "../../../components/CIcon";
import { iBarChart, iChevronDown, iChevronUp, iPercentage, iPieChart } from "assets/icons/Icons";
import CChart from "../../../components/Chart";
import _ from "lodash";
import { ReportTypeEnums } from "config/constants";
import { getPointerLabel } from "lib/chart-utils";
import { useAppSelector } from "hooks/useRedux";
import { getFormatedDate } from "lib/utils";
import { Colors } from "config/colors";
import { useCurrency } from "hooks/useCurrency";
import { moneyFormatter } from "lib/moneyFormatter";

interface IProps {
    data: Array<any>;
    originalData: Array<any>;
}

const ReportTypes = [ReportTypeEnums.AREA, ReportTypeEnums.PIE, ReportTypeEnums.COLUMN];

const renderReportButtonIcon = (type: string) => {
    switch (type) {
        case ReportTypeEnums.AREA:
            return <CIcon path={iPercentage} />;
        case ReportTypeEnums.PIE:
            return <CIcon path={iPieChart} />;
        case ReportTypeEnums.COLUMN:
            return <CIcon path={iBarChart} />;
    }
};

const VenueProductivity: FCC<IProps> = ({ originalData, data }) => {
    const { pointStart, dates: allDates, categories } = useAppSelector(state => state.venueDashboard);

    const currency = useCurrency();
    const [_pointStart, setPointStart] = useState(0);
    const [isShowGraphic, setIsShowGraphic] = useState(true);
    const [selectedReportType, setSelectedReportType] = useState(ReportTypeEnums.COLUMN);

    useEffect(() => {
        if (selectedReportType === ReportTypeEnums.AREA && pointStart !== _pointStart) {
            setTimeout(() => {
                changeDate(pointStart);
                setPointStart(pointStart);
            }, 10);
        }
    }, [pointStart]);

    const changeDate = (date: number) => {
        const Highchart = (window as any).HighchartRef;
        const current = Highchart.current;
        current.chart?.series?.forEach((serie: any) => {
            serie.update({
                pointStart: date,
            });
        });
    };

    const getSeries = () => {
        if (selectedReportType !== ReportTypeEnums.PIE) {
            const reportData: Array<any> = _.cloneDeep(originalData);
            const series: any = [];

            if (reportData) {
                reportData.forEach(data => {
                    const seriesData: any = [];
                    allDates.forEach(date => {
                        if (data.report.length) {
                            const index = data.report.findIndex((resport: any) => resport.shift_date === date);
                            seriesData.push(index !== -1 ? data.report[index].total_cash : 0);
                        } else {
                            seriesData.push(data?.report?.total_cash ?? 0);
                        }
                    });
                    series.push({
                        name: data.name,
                        data: seriesData,
                    });
                });
            }
            return series;
        } else {
            return [
                {
                    name: t("general") + " " + t("total_cash"),
                    data: data
                        ? data.map((d: any) => {
                              return {
                                  y: d.total_cash,
                                  name: d.name,
                              };
                          })
                        : [],
                },
            ] as any;
        }
    };

    return (
        <div className="productivity-container">
            <div className="head">
                <div className="title">
                    <TText tkey="graphics" />
                </div>
                <div className="hr"></div>
                <div className="right-side">
                    {isShowGraphic ? (
                        <div className="report-types">
                            {ReportTypes.map(type => {
                                return (
                                    <button
                                        key={type}
                                        onClick={() => setSelectedReportType(type)}
                                        className={type === selectedReportType ? "active" : ""}
                                    >
                                        {renderReportButtonIcon(type)}
                                    </button>
                                );
                            })}

                            <div className="bracket"></div>
                        </div>
                    ) : null}
                    <button className="graphic" onClick={() => setIsShowGraphic(!isShowGraphic)}>
                        {isShowGraphic ? t("hide_graph") : t("show_graph")}
                        <CIcon path={isShowGraphic ? iChevronUp : iChevronDown} />
                    </button>
                </div>
            </div>
            {isShowGraphic ? (
                <div className="chart-container">
                    {selectedReportType === ReportTypeEnums.AREA ? (
                        <CChart
                            series={getSeries()}
                            chart={{
                                type: selectedReportType,
                            }}
                            options={{
                                global: {},
                                colors: [
                                    Colors.VividBlue,
                                    Colors.SunflowerYellow,
                                    Colors.LavenderPurple,
                                    Colors.Tangerine,
                                    Colors.SoftRed,
                                    Colors.PaleYellow,
                                    Colors.JungleGreen,
                                ],
                                accessibility: {
                                    point: {
                                        valueDescriptionFormat:
                                            "{index}. {point.category}, {point.y:.,1f} billions, {point.percentage:.1f}%.",
                                    },
                                },
                                yAxis: {
                                    labels: {
                                        format: "{value}%",
                                    },
                                    title: {
                                        enabled: false,
                                    },
                                } as any,
                                tooltip: {
                                    pointFormatter() {
                                        return getPointerLabel(this, "money-percentage", currency);
                                    },
                                    split: true,
                                },
                                xAxis: [
                                    {
                                        type: "datetime",
                                        dateTimeLabelFormats: {
                                            day: "%d %b",
                                        },
                                        labels: {
                                            formatter: (data: any) => getFormatedDate(data.value),
                                        },
                                    },
                                    {
                                        type: "datetime",
                                        visible: false,
                                    },
                                ],
                                plotOptions: {
                                    series: {
                                        pointStart: _pointStart,
                                        pointInterval: 24 * 3600 * 1000,
                                    } as any,
                                    area: {
                                        stacking: "percent",
                                        marker: {
                                            enabled: false,
                                        },
                                    },
                                },
                                legend: {
                                    enabled: true,
                                },
                            }}
                        />
                    ) : null}
                    {selectedReportType !== ReportTypeEnums.AREA ? (
                        <CChart
                            series={getSeries()}
                            chart={{
                                type: selectedReportType,
                                zoomType: "x",
                                marginTop: 30,
                                animation: {
                                    duration: 600,
                                },
                            }}
                            options={{
                                colors: [
                                    Colors.VividBlue,
                                    Colors.SunflowerYellow,
                                    Colors.LavenderPurple,
                                    Colors.Tangerine,
                                    Colors.SoftRed,
                                    Colors.PaleYellow,
                                    Colors.JungleGreen,
                                ],
                                tooltip: {
                                    useHTML: true,
                                    headerFormat: "<small>{point.key}</small><table>",
                                    pointFormatter() {
                                        return getPointerLabel(this, "money", currency);
                                    },
                                    footerFormat: "</table>",
                                },
                                plotOptions: {
                                    column: {
                                        stacking: "normal",
                                        dataLabels: {
                                            enabled: true,
                                            style: {
                                                textWeight: 300,
                                            },
                                            formatter: function () {
                                                return moneyFormatter(this?.y) + " " + currency;
                                            },
                                        },
                                    },
                                },
                                xAxis: {
                                    categories:
                                        selectedReportType === ReportTypeEnums.COLUMN
                                            ? categories
                                            : data
                                              ? data.map((dt: any) => {
                                                    return dt.name;
                                                })
                                              : [],
                                },
                                yAxis:
                                    selectedReportType === ReportTypeEnums.COLUMN
                                        ? ({
                                              min: 0,
                                              stackLabels: {
                                                  enabled: true,
                                                  formatter: function (this: any) {
                                                      return moneyFormatter(this?.total) + " " + currency;
                                                  },
                                              },
                                          } as any)
                                        : null,
                                legend: {
                                    shadow: false,
                                    enabled: true,
                                },
                            }}
                        />
                    ) : null}
                </div>
            ) : null}
        </div>
    );
};

export default VenueProductivity;
