import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React, { FC } from "react";
import "./Settings.scss";
import CFormGroup from "components/Form/CFormGroup";
import { CRadioTile } from "components/RadioTile/CRadioTile";
import { IMenuCustomizationTabProps } from "pages/qr-menu/settings/MenuCustomizationTab";
import { useSetting } from "hooks/useSetting";
import { BrandType } from "types/routes";

const enum CategoriesOnProductPageLayoutType {
    OFF = "off",
    SCROLL = "scroll",
    GRID = "grid",
}

export const CategoriesOnProductPageLayout: FC<IMenuCustomizationTabProps> = ({ form }) => {
    const currentTemplate = form.form.watch("payload.meta.menu_layout.categories_on_product_page");

    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;

    const categoriesOnProductPageLayouts = [
        {
            value: CategoriesOnProductPageLayoutType.SCROLL,
            label: "categories_on_product_page_scroll",
            imageSrc: isRestaurant
                ? "/img/qr-menu-layouts/categories-in-products-layout-scroll.svg"
                : "/img/qr-menu-layouts/shop-categories-in-products-layout-scroll.svg",
        },
        {
            value: CategoriesOnProductPageLayoutType.GRID,
            label: "categories_on_product_page_grid",
            imageSrc: isRestaurant
                ? "/img/qr-menu-layouts/categories-in-products-layout-grid.svg"
                : "/img/qr-menu-layouts/shop-categories-in-products-layout-grid.svg",
        },
        {
            value: CategoriesOnProductPageLayoutType.OFF,
            label: "categories_on_product_page_off",
            imageSrc: isRestaurant
                ? "/img/qr-menu-layouts/categories-in-products-layout-off.svg"
                : "/img/qr-menu-layouts/shop-categories-in-products-layout-off.svg",
        },
    ] as const;

    if (!currentTemplate) {
        form.form.setValue(
            "payload.meta.menu_layout.categories_on_product_page",
            CategoriesOnProductPageLayoutType.SCROLL,
        );
    }

    return (
        <>
            <div className="page-title">
                <TText tkey="categories_on_product_page" />
            </div>
            <CFormGroup
                hideLabel={true}
                name="payload.meta.menu_layout.categories_on_product_page"
                comp={CRadioTile}
                compProps={{
                    options: categoriesOnProductPageLayouts.map(o => ({
                        ...o,
                        label: t(o.label),
                    })),
                }}
            />
        </>
    );
};
