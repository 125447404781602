import { t } from "lib/i18n";
import { useData } from "hooks/useData";
import Widget from "../../components/Widget";
import { getPointerLabel } from "lib/chart-utils";
import CChart from "../../components/Chart";
import _ from "lodash";
import NoData from "components/NoData";
import { IconButton } from "rsuite";
import { useDepot } from "hooks/useDepot";
import CIcon from "../../components/CIcon";
import { iBarChart, iPieChart } from "assets/icons/Icons";
import { useCurrency } from "hooks/useCurrency";
import { useSetting } from "hooks/useSetting";
import { passCashRestartCheckStart } from "lib/passCashRestartCheckStart";
import { passCashRestartCheckEnd } from "../../lib/passCashRestartCheckEnd";

const PaymentTypeWidget: FCC = () => {
    const [chartType, setChartType] = useDepot("PaymentTypeWidget", "column");
    const cashRestart = useSetting("cash_restart")?.value;

    const dp = useData<IStatsPayments[]>("reports/date", {
        params: {
            date: [passCashRestartCheckStart(cashRestart), passCashRestartCheckEnd(cashRestart)],
            period: "DAILY",
            filters: {
                indicator: ["indicator", 0],
            },
        },
    });

    const data = Object.values(_.head(dp.data)?.payment_methods ?? {})?.map((method: IStatsPaymentsPaymentMethods) => ({
        y: method.total_amount,
        name: method.payment_method?.name,
    }));
    const currency = useCurrency();

    const btnProps = (name: string): any => ({
        size: "xs",
        appearance: name === chartType ? "primary" : undefined,
        onClick: () => setChartType(name),
    });

    return (
        <div>
            <Widget
                title={t("profit_by_payment_method")}
                headerButtons={
                    <>
                        <IconButton
                            className="mr-2"
                            icon={<CIcon size={0.6} path={iBarChart} />}
                            {...btnProps("column")}
                        />
                        <IconButton icon={<CIcon path={iPieChart} size={0.6} />} {...btnProps("pie")} />
                    </>
                }
            >
                {_.isEmpty(data) ? (
                    <NoData />
                ) : (
                    <CChart
                        options={{
                            chart: { height: 200 },
                            xAxis: {
                                categories: data.map((m: any) => m.name),
                            },
                            tooltip: {
                                useHTML: true,
                                headerFormat: "<small>{point.key}</small><table>",
                                pointFormatter() {
                                    return getPointerLabel(this, "money", currency);
                                },
                                footerFormat: "</table>",
                            },
                        }}
                        series={[{ type: chartType, data, name: t("income") } as any]}
                    />
                )}
            </Widget>
        </div>
    );
};

export default PaymentTypeWidget;
