import FinanceCategoryTreeFilter from "components/DataTable/Filters/CategoryTreeFilter/FinanceCategoryTreeFilter";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import SourceFilter from "components/DataTable/Filters/SourceFilter";
import ToggleFilter from "components/DataTable/Filters/ToggleFilter";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { Colors } from "config/colors";
import { Source } from "config/constants";

interface IProps extends ICDrawerProps {
    dp: any;
    setDeletedBy: (value: boolean) => void;
    showOnPage?: "customerPage" | "supplierPage" | "transactions";
}
const defaultTrashFilter: any[] = [
    ["operation.supplier", 2],
    ["operation.stock_operations.product", 2],
    ["balance", 2],
    2,
];
const TransactionPageFilterDrawer = (props: IProps) => {
    const auth = useAuth();

    return (
        <CDrawer size="xs" title={t("filters")} {...props}>
            <div className="d-flex flex-column justify-content-between" style={{ height: "89vh" }}>
                <div className="px-3 pt-3 clopos-scroll" style={{ overflowY: "auto" }}>
                    <FiltersContainer hasPadding={true} dp={props.dp}>
                        <SearchFilter fields="description" placeholder={t("description")} className="tw-max-w-full" />

                        <div style={{ marginTop: "24px" }} className={col}>
                            <label style={labelRowStyle}>
                                <TText tkey="deleted_transactions" />
                            </label>
                            <ToggleFilter
                                useParams
                                fields="trash"
                                title={t("deleted_transactions")}
                                value={defaultTrashFilter}
                                afterChange={checked => props.setDeletedBy(!checked)}
                            />
                        </div>

                        {props.showOnPage === "customerPage" || props.showOnPage === "supplierPage" ? null : (
                            <div style={{ marginTop: "24px" }} className={col}>
                                <label style={labelRowStyle}>
                                    <TText tkey="transaction_with_receipt" />
                                </label>
                                <ToggleFilter
                                    negate
                                    fields="receipt_id"
                                    extraFields={["returns_id", "!=", ""]}
                                    title={t("transaction_with_receipt")}
                                    value=""
                                />
                            </div>
                        )}

                        <SourceFilter
                            className="tw-max-w-full tw-w-full"
                            showLabel={true}
                            options={[Source.pos, Source.web, Source.manager]}
                        />

                        {props.showOnPage === "supplierPage" ? null : (
                            <>
                                <label style={labelColStyle}>
                                    <TText tkey="payment_method" />
                                </label>
                                <RemoteCheckListFilter
                                    fields="payment_method_id"
                                    remote={{
                                        url: "/finance/paymentMethod",
                                    }}
                                    searchField="name"
                                    pickerProps={{ placeholder: t("payment_method") }}
                                    width={450}
                                    maxWidth={450}
                                />
                            </>
                        )}
                        {props.showOnPage === "customerPage" || props.showOnPage === "supplierPage" ? null : (
                            <>
                                <label style={labelColStyle}>
                                    <TText tkey="transaction_by" />
                                </label>
                                <RemoteCheckListFilter
                                    fields="user_id"
                                    remote={{
                                        url: "user",
                                        refineList: (d: any[]) => d?.filter(user => user.owner || !user.hide),
                                    }}
                                    labelField="username"
                                    searchField="username"
                                    width={450}
                                    maxWidth={450}
                                    pickerProps={{ placeholder: t("transaction_by") }}
                                />
                            </>
                        )}
                        {props.showOnPage === "supplierPage" || props.showOnPage === "customerPage" ? null : (
                            <>
                                <label style={labelColStyle}>
                                    <TText tkey="supplier" />
                                </label>
                                <RemoteCheckListFilter
                                    fields="supplier_id"
                                    searchField="name"
                                    remote="supplier"
                                    hasPermission={auth.hasAbility(["stock_manage_supplier"])}
                                    pickerProps={{ placeholder: t("supplier"), placement: "topStart" }}
                                    width={450}
                                    maxWidth={450}
                                />
                            </>
                        )}
                        {props.showOnPage === "customerPage" || props.showOnPage === "supplierPage" ? null : (
                            <>
                                <label style={labelColStyle}>
                                    <TText tkey="customers" />
                                </label>
                                <RemoteCheckListFilter
                                    fields="customer_id"
                                    searchField="name"
                                    remote="customer"
                                    pickerProps={{ placeholder: t("customers"), placement: "bottom" }}
                                    hasPermission={auth.hasAbility(["customer_manage_view"])}
                                    width={450}
                                    maxWidth={450}
                                />
                            </>
                        )}
                        {props.showOnPage === "transactions" ? null : (
                            <>
                                <label style={labelColStyle}>
                                    <TText tkey="category" />
                                </label>
                                <FinanceCategoryTreeFilter
                                    refine={list => {
                                        return list?.filter(t =>
                                            (props.showOnPage === "customerPage" ? [1, 15, 18, 19] : [9, 22]).includes(
                                                t.id,
                                            ),
                                        );
                                    }}
                                    pickerProps={{ placement: "bottom" }}
                                    showUncategorisedOption={false}
                                    width={450}
                                    maxWidth={450}
                                />
                            </>
                        )}
                        {props.showOnPage === "transactions" ? null : (
                            <>
                                <label style={labelColStyle}>
                                    <TText tkey="transaction_by" />
                                </label>
                                <RemoteCheckListFilter
                                    fields="user_id"
                                    remote={{
                                        url: "user",
                                        refineList: (d: any[]) => d?.filter(user => user.owner || !user.hide),
                                    }}
                                    labelField="username"
                                    searchField="username"
                                    pickerProps={{ placeholder: t("transaction_by") }}
                                    width={450}
                                    maxWidth={450}
                                />
                            </>
                        )}
                    </FiltersContainer>
                </div>
            </div>
        </CDrawer>
    );
};

export default TransactionPageFilterDrawer;

const col = "d-flex justify-content-between align-items-center w-100";

const labelRowStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
};
const labelColStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
    marginTop: "16px",
};
