import { Tooltip, Whisper } from "rsuite";

interface IProps {
    description?: string | null;
}

const WhisperWithoutIcon: FCC<IProps> = ({ description }) => {
    if (!description) {
        return null;
    }
    return (
        <>
            <Whisper placement="left" trigger="hover" speaker={<Tooltip>{description}</Tooltip>}>
                <span>{description}</span>
            </Whisper>
        </>
    );
};

export default WhisperWithoutIcon;
