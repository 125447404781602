import { changeKeyReceiptItemByType, toggleReceiptItemActivityByType } from "../receiptConstructor";
import { Dispatch } from "react";
import { IReceiptItem, ReceiptItemType } from "pages/settings/receipt/types";
import { changeStationKeyReceiptItemByType } from "../stationPrintConstructor";

export const handleChangeTextSizeAC = (value: string | undefined, item: IReceiptItem) => {
    return (dispatch: Dispatch<any>) => {
        if (!value) return;
        const { receiptType } = item;
        if (receiptType === ReceiptItemType.Receipt) {
            dispatch(changeKeyReceiptItemByType({ item, key: "textSize", value }));
        } else {
            dispatch(changeStationKeyReceiptItemByType({ item, key: "textSize", value }));
        }
    };
};
export const handleToggleActivityByTypeAC = (key: keyof IReceiptItem | undefined) => {
    return (dispatch: Dispatch<any>) => {
        if (!key) return;
        dispatch(toggleReceiptItemActivityByType({ key: key as keyof IReceiptItem, type: "receiptProduct" }));
    };
};
