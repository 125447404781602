import Axios, { Method, ResponseType } from "axios";
import { EventEmitter } from "fbemitter";
import _ from "lodash";
import qs from "qs";
import { toaster } from "rsuite";
import { ErrorType } from "config/errors";
import { logger } from "./utils";
import * as Sentry from "@sentry/browser";
import { store } from "redux/store";
import { config } from "config/Config";
import { MessageError } from "components/Message";
import { depot } from "@cloposcom/libs";
import { isDev } from "config/main";

const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

export function createError(data: any): CustomError {
    const err = new Error() as CustomError;
    _.assign(
        err,
        {
            isCustom: true,
            type: ErrorType.EMPTY,
        },
        data,
    );
    return err;
}

export function downloadDataAsFile(data: any, fileName: string) {
    const url = URL.createObjectURL(data);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", fileName.replace(/\./g, "_"));
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}

class Ajax extends EventEmitter {
    private axios = Axios.create({
        baseURL: config.apiUrl,
    });

    constructor() {
        super();
        this.axios.defaults.baseURL = config.apiUrl;
        config.on("brandChange", () => {
            this.axios.defaults.baseURL = config.apiUrl;
        });
    }

    public setToken(token?: string): void {
        if (token) {
            this.axios.defaults.headers.common.Authorization = "Bearer " + token;
            this.axios.defaults.headers.common["X-Auth-Token"] = token;
            this.axios.defaults.headers.common["X-Lang"] = store.getState().lang.current;

            if (store.getState().venue.currentVenueId)
                this.axios.defaults.headers.common["x-venue"] = store.getState().venue.currentVenueId;
        } else {
            delete this.axios.defaults.headers.common.Authorization;
        }
    }

    buildUrl = (conf: IAjaxConf) => {
        let url = conf.url;
        const params = conf.method === "put" ? { ...conf.params, _method: "put" } : conf.params;
        const token = { api_token: this.axios.defaults.headers.common["X-Auth-Token"] };

        if (!_.isEmpty(params)) {
            let generalP = { ...params };
            if (isSafari) generalP = { ...generalP, ...token };
            const queryString = qs.stringify(generalP, { encode: false });
            const hasQuestionMark = url.indexOf("?") !== -1;
            url += (hasQuestionMark ? "&" : "?") + queryString;
        }

        return url;
    };

    public async load<T = any>(conf: IAjaxConf): Promise<IApiResponse<T>> {
        try {
            if (conf.method === "put") {
                conf.url = this.buildUrl(conf);
                conf.method = "post";
            }

            const selectedVenue = store.getState().venue.currentVenueId;
            if (selectedVenue) this.axios.defaults.headers.common["x-venue"] = selectedVenue;
            this.axios.defaults.headers.common["X-Lang"] = store.getState().lang.current;

            const resp = await this.axios.request(conf);

            if (
                conf.method !== "OPTIONS" &&
                conf.method !== "GET" &&
                conf.method !== "options" &&
                conf.method !== "get" &&
                !isDev
            ) {
                logger.clientRequest("Panel", {
                    url: conf.url,
                    method: conf.method as any,
                    req: conf,
                    res: resp,
                    user: {
                        email: depot.getItem("user")?.email,
                    },
                } as any);
            }

            if (!resp.data.success) {
                throw createError({
                    response: resp,
                    ...resp.data.error?.[0],
                });
            }
            return resp.data;
        } catch (e: any) {
            if (!e.response) {
                throw createError({ type: ErrorType.IS_OFFLINE, message: "System is offline" });
            }
            const err: CustomError = e.isCustom ? e : createError(e.response.data.error?.[0]);

            if (conf.customError) {
                conf.customError(err);
            } else if (e.response.data.error?.[0]?.models?.length) {
                const customMsg = `\n${e.response.data.error?.[0]?.models?.map((m: any) => m.context).join("\n")}`;
                toaster.push(MessageError(`${e.response?.data?.message || err.message || err.type} ${customMsg}`), {
                    duration: 5000,
                });
            } else {
                toaster.push(MessageError(e.response?.data?.message || err.message || err.type), {
                    duration: 4000,
                });
            }

            // eslint-disable-next-line no-console
            console.info({
                // message: err.message,
                method: conf.method,
                url: conf.url,
                status: e.response.status,
                statusText: e.response.statusText,
                request: conf,
                response: e.response.data,
            });

            //log.runtimeError(e, conf);
            Sentry.captureException(e);
            switch (err.type) {
                case ErrorType.SERVER_SIDE:
                    // if (!err.models?.length) {
                    //     toaster.push(MessageError(err.message || err.type), { duration: 4000 });
                    //
                    //     // toaster.push(err.message || err.type);
                    // }
                    break;
                case ErrorType.AUTH:
                    // toaster.push(MessageError(err.message || err.type), { duration: 4000 });

                    // toaster.push(t("session_expired"));
                    this.emit("AUTH_ERROR");
                    break;
                case ErrorType.PERMISSION:
                    // TODO: Refresh auth role
                    // await auth.refreshAuthRole();
                    // toaster.push(MessageError(err.message || err.type), { duration: 4000 });
                    // toaster.push(err.message || err.type);
                    break;
                case ErrorType.VALIDATION:
                    break;
                default:
                    // toaster.push(MessageError(err.message || err.type), { duration: 4000 });
                    // toaster.push(err.message || err.type);
                    break;
            }

            throw err;
        }
    }

    public async download(conf: IAjaxConf, fileName: string): Promise<any> {
        conf.method = "GET";
        conf.responseType = "blob";
        if (!_.isEmpty(conf.params)) {
            // in some parts, two params are added nested and creates issue on export
            // here checking if such case exist and flatting it to original params
            if (conf.params.params) {
                const extraParams = conf.params.params;
                delete conf.params.params;
                conf.params = {
                    ...conf.params,
                    ...extraParams,
                };
            }
            conf.url += "?" + qs.stringify(conf.params, { encode: true });
            conf.params = null;
        }
        try {
            const resp = await this.axios.request(conf);
            downloadDataAsFile(resp.data, fileName);
        } catch (e) {
            // alert("Error while downloading file");
            toaster.push(MessageError("Error while downloading file"), { duration: 4000 });
        }
    }

    public get<T = any>(conf: IAjaxConf): Promise<IApiResponse<T>> {
        conf.method = "get";
        return this.load(conf);
    }

    public post<T = any>(conf: IAjaxConf): Promise<IApiResponse<T>> {
        conf.method = "post";
        return this.load(conf);
    }

    public put<T = any>(conf: IAjaxConf): Promise<IApiResponse<T>> {
        conf.method = "put";
        return this.load(conf);
    }

    public patch<T = any>(conf: IAjaxConf): Promise<IApiResponse<T>> {
        conf.method = "patch";
        return this.load(conf);
    }

    public delete<T = any>(conf: IAjaxConf): Promise<IApiResponse<T>> {
        conf.method = "delete";
        return this.load(conf);
    }
}

export interface IAjaxConf {
    url: string;
    method?: Method;
    data?: any;
    params?: any;
    headers?: any;
    responseType?: ResponseType;
    customError?: (error: any) => void;
}

export default new Ajax();
