import { t } from "lib/i18n";

import { Checkbox } from "components/checkbox";

interface IProps {
    logo: string;
    method: string;
    register: boolean;
    type?: string;
    monthly_period: number;
    onSelect: (method: any) => void;
    isActive: boolean;
}

export const PaymentBox: FCC<IProps> = ({ logo, register, type, method, monthly_period, onSelect, isActive }) => {
    const label = `${type === "card" ? t("by_card") : t("in_cash")}`;
    return (
        <div className={`payment-box-wrapper ${isActive && "active"}`} onClick={() => onSelect(method)}>
            <div className={"new-payment-box"}>
                <div className="d-flex align-items-center position-relative">
                    <div className={`checkbox-select-wrapper duration-bg ${isActive && "active"}`}>
                        <div className={`checkbox-select ${isActive && "active"}`}>
                            <Checkbox color={"white"} />
                        </div>
                    </div>
                    <div className={"new-title"}>{label}</div>
                </div>
                <div>
                    <img src={logo} alt={method} />
                </div>
            </div>
        </div>
    );
};
