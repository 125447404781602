import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useTableHeight } from "hooks/useTableHeight";
import DataTable from "../../../components/DataTable/DataTable";
import { useDataProvider } from "components/DataTable/useDataProvider";
import React from "react";

const ReceiptIntegration = ({ r }: any) => {
    const dp = useDataProvider({
        url: `finance/transaction`,
        filters: {
            receipt_id: ["receipt_id", r.id],
            indicator: ["indicator", 1],
            category_id: ["category_id", 30],
        },
        with: ["staff:id,username"],
    });

    const cm = useColumns<any>(`incentive_program`, [
        {
            key: "id",
            align: "left",
        },

        {
            key: "staff_id",
            minWidth: 120,
            align: "left",
            render: (d: any) => <div>{d.staff?.username}</div>,
        },

        { key: "amount", align: "left", type: "money" },

        {
            key: "operated_at",
            flexGrow: 0.5,
            type: "datetime",
            multiline: true,
        },
    ]);

    const height = useTableHeight(r.fiscal_id ? 284 : 250);

    return (
        <div className="d-flex">
            <div className="flex-fill border-left">
                <DataTable
                    noShadow
                    columns={cm.columns}
                    dp={dp}
                    hidePages
                    rowPadding={"20px 10px 0px"}
                    tableProps={{
                        rowHeight: 60,
                        autoHeight: false,
                        height,
                    }}
                />
            </div>
        </div>
    );
};

export default ReceiptIntegration;
