import { TText } from "components/i18n/TText";
import React from "react";
import CModal from "../../../components/Drawers/CModal";
import { Modal } from "rsuite";
import CIcon from "../../../components/CIcon";
import { iAlert } from "assets/icons/Icons";
import { ICDrawerProps } from "components/Drawers/CDrawer";

export const IntegrationErrorModal: FCC<ICDrawerProps> = props => {
    return (
        <CModal {...props} size="xs">
            <Modal.Header style={{ paddingRight: 0 }}>
                <div className="d-flex align-items-center justify-content-center">
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ borderRadius: "50%", background: "#FEF3F2", height: "56px", width: "56px" }}
                    >
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ borderRadius: "50%", background: "#FEE4E2", height: "40px", width: "40px" }}
                        >
                            <CIcon size={1} path={iAlert} style={{ color: "#F04438", fillRule: "evenodd" }} />
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <h6 className="d-flex justify-content-center mb-3">
                    <TText tkey="error" />
                </h6>

                <div className="d-flex align-items-center mt-2">
                    <CIcon style={{ color: "#F04438", marginRight: "10px" }} path={iAlert} />
                    <ul style={{ listStyle: "none" }}>
                        {props.data?.messages?.map((message: string) => <li>{message}</li>)}
                    </ul>
                </div>
            </Modal.Body>
        </CModal>
    );
};
