import React, { createContext, useCallback, useContext, useMemo, useState } from "react";

interface ProductSelectorConfig {
    onProductSelectorSave: ((selectedProductsIds: number[]) => void) | null;
}
interface ProductSelectorContextType extends ProductSelectorConfig {
    updateConfig: (config: Partial<ProductSelectorConfig>) => void;
}

const ProductSelectorContext = createContext<ProductSelectorContextType | undefined>(undefined);

export const useProductSelectorContext = () => {
    const context = useContext(ProductSelectorContext);
    if (!context) {
        throw new Error("useProductSelectorContext must be used within a ProductSelectorProvider");
    }
    return context;
};

export const ProductSelectorProvider: FCC = ({ children }) => {
    const [config, setConfig] = useState<ProductSelectorConfig>({
        onProductSelectorSave: null,
    });

    const updateConfig = useCallback((newConfig: Partial<ProductSelectorConfig>) => {
        setConfig(prevConfig => ({ ...prevConfig, ...newConfig }));
    }, []);

    const value = useMemo(() => {
        return {
            ...config,
            updateConfig,
        };
    }, [config, updateConfig]);

    return <ProductSelectorContext.Provider value={value}>{children}</ProductSelectorContext.Provider>;
};
