import { t } from "lib/i18n";
import { roundAndClean } from "@cloposcom/libs";
import { FIListItemProps } from "components/FormItems/FormItemsList";
import { GridButtonRemove } from "components/NewForm/Grid/GridButtonRemove";
import { GridInput } from "components/NewForm/Grid/GridInput";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { getUnitNames } from "config/constants";
import { useAppSelector } from "hooks/useRedux";
import { store } from "redux/store";
import RecipeIcon from "./RecipeIcon";
import { toFixedMinMax } from "lib/utils";
import { GridProductSelectItem } from "components/NewForm/Grid/GridProductSelectItem";
import React from "react";
import { useCurrency } from "hooks/useCurrency";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

interface IProps extends FIListItemProps<RecipeItemField> {
    itemsIdString?: string;
}

export interface RecipeItemField {
    id: number;
    type: string;
    gross: number;
    unit_weight: number;
    cost_price: number;
    // total_cost_price: number;
    name?: string;
    full_name?: string;
}

const RecipeItem: FCC<IProps> = ({ field, index, remove, fields, form, error }) => {
    const { setValue } = useCFormContext();
    const productById = useAppSelector(state => state.data.products.byId);
    const unitsById = useAppSelector(state => state.data.units.byId);
    const product = productById[field?.id];

    const unit = unitsById[product?.unit_id];
    const currency = useCurrency();

    const removeItem = async (index: number) => {
        remove(index);
    };

    const onSelectProduct = (id: number) => {
        const item = store.getState().data.products.byId[id];

        setValue(`contents[${index}]`, {
            id: item?.id,
            ingredient_id: item?.id,
            full_name: item?.full_name,
            price: 0,
            cost_price: Number(item?.cost_price) ?? 0,
            total_cost_price: 0,
            unit: item?.unit ?? null,
            unit_id: Number(item?.unit_id) ?? null,
            gross: 0,
            type: item?.type,
            unit_weight: Number(item?.unit_weight) ?? null,
        });
    };

    return (
        <>
            <GridProductSelectItem onSelect={onSelectProduct} name={`contents[${index}].id`} />

            <div className="tw-flex tw-justify-center tw-items-center tw-h-full">
                {field?.type ? <RecipeIcon testId={`contents[${index}].icon`} type={field?.type} /> : "-"}
            </div>

            <GridInput
                name={`contents[${index}].gross`}
                type="number"
                decimals={[0, 4]}
                postfix={getUnitNames(unit?.symbol)}
                index={index}
            />

            <GridItemPlain
                align="right"
                testId={`contents[${index}].gross_weight_kg`}
                value={roundAndClean(field.gross * field.unit_weight, 4)}
                postfix={t("kg_short")}
            />

            <GridItemPlain
                testId={`contents[${index}].total_cost_price`}
                align="right"
                value={toFixedMinMax(field.cost_price * field.gross, 2, 4)}
                postfix={currency}
            />

            <GridButtonRemove test-id={`contents[${index}].remove`} onClick={() => removeItem(index)} />
        </>
    );
};

export default RecipeItem;
