import { t } from "lib/i18n";
import { useCForm } from "hooks/useCForm";
import { dummySupplier } from "pages/inventory/suppliers/SupplierModel";
import { useFormTitle } from "components/Header/Title";

import HeaderRight from "components/Header/HeaderRight";
import CForm from "components/Form/CForm/CForm";
import _ from "lodash";
import TextFormGroup from "components/Form/TextFormGroup";
import TextAreaFormGroup from "components/Form/TextAreaFormGroup";
import { ruleset } from "lib/validation-rules";

const CreateSupplierPage: FCC = props => {
    const form = useCForm({
        url: "supplier",
        dummyData: dummySupplier,
    });

    return (
        <div className="p-3">
            {useFormTitle(form.id, t("create_by_name", { name: _.capitalize(t("supplier")) }))}
            <HeaderRight formSubmit={form} />
            <CForm form={form.form} onSave={form.onSave}>
                <TextFormGroup
                    name="name"
                    errorPlacement="rightEnd"
                    focus={true}
                    rules={{
                        validate: {
                            required: ruleset.required,
                        },
                    }}
                />
                <TextFormGroup name="phone" errorPlacement="rightEnd" />
                <TextFormGroup name="tin" errorPlacement="rightEnd" />
                <TextAreaFormGroup name="description" className="pb-3" />
            </CForm>
        </div>
    );
};

export default CreateSupplierPage;
