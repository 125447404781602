import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import React, { CSSProperties } from "react";
import CDrawer from "../../../components/Drawers/CDrawer";

import { ICDrawerProps } from "components/Drawers/CDrawer";

type IProps = ICDrawerProps;

const ReceiptStationDrawer: FCC<IProps> = props => {
    const cm = useColumns<any>(`receipt-station`, [
        { key: "position", width: 75, flexGrow: undefined },
        {
            key: "name",
            render: e => {
                const rpms: any[] = e.mainRPMNames.length
                    ? e.mainRPMNames
                          .map((f: string) => {
                              //f[1]?.length && e.mainRPMNames.includes(f[1][0])
                              const main = e.rpmGroups.find((rg: any[]) => rg[1].includes(f));
                              if (main) {
                                  return `${main[0]}: ${f}`;
                              }
                              return "";
                          })
                          .filter((ss: string) => ss)
                    : [];

                const style: CSSProperties = rpms.length
                    ? {
                          display: "flex",
                          flexDirection: "column",
                          marginTop: -10,
                      }
                    : {};
                return (
                    <div style={style}>
                        <span>{e.name}</span>
                        {rpms.length ? <span>({rpms})</span> : ""}
                    </div>
                );
            },
        },
        { key: "count" },
    ]);

    return (
        <CDrawer size="md" {...props}>
            <div className="">
                <div className="d-flex">
                    <div className="flex-fill border-left">
                        {props.data?.map((d: any) => (
                            <div className={"py-3"}>
                                <span className="p-2">
                                    {d.stationName} {d.comment ? `/ ${t("comment")}: ${d.comment}` : ""}
                                </span>
                                <DataTable noShadow columns={cm.columns} dp={{ data: d.list || [] } as any} hidePages />
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </CDrawer>
    );
};

export default ReceiptStationDrawer;
