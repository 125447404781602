import { TText } from "components/i18n/TText";

interface IProps {
    r: IReceipt;
    susCount: number | undefined;
}

const ReceiptHistoryDetailSubInfo: FCC<IProps> = ({ r, susCount }) => {
    return (
        <div className="d-flex justify-content-between p-2 border-top mb-1" style={{ minHeight: "60px" }}>
            <div className="w-50 d-flex align-items-center justify-content-around">
                <p className="font-weight-bold">
                    <TText tkey="preprint_count" />
                </p>
                <p className="text-right m-0">{r.meta.preprint_count || 0}</p>
            </div>

            <div className="w-50 d-flex align-items-center justify-content-around">
                <p className="font-weight-bold">
                    <TText tkey="suspicion_action" />
                </p>
                <p className="text-right m-0">{susCount || 0}</p>
            </div>
        </div>
    );
};

export default ReceiptHistoryDetailSubInfo;
