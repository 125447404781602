import { TText } from "components/i18n/TText";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Badge, Button } from "rsuite";
import { IDataProvider } from "../useDataProvider";
import { FiltersContext } from "./FiltersContainer";
import { iCLose2, iFilterVariant } from "assets/icons/Icons";
import CIcon from "../../CIcon";

interface IProps {
    dp?: IDataProvider;
    excludeFilterKeys?: Array<string>;
    isOpen?: boolean;
    onChange?: (checked: boolean) => void;
    afterChange?: (checked: boolean) => void;
    useParams?: boolean;
    extraFields?: string[];
    negate?: boolean;
    filterSymbol?: OP;
}

const ToggleFilterDrawer: FCC<IProps> = _props => {
    const props = _.omit(_props, "dp");
    const filtersContext = useContext(FiltersContext);
    const dp = _props.dp || filtersContext.dp;
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [defaultFilterKeys, setDefaultFilterKeys] = useState<Array<any>>([]);
    const [defaultParamsKeys, setDefaultParamsKeys] = useState<Array<any>>([]);
    const [isShowClearButton, setIsShowClearButton] = useState<boolean>(false);
    const [selectedFilterCount, setSelectedFilterCount] = useState(0);

    useEffect(() => {
        setIsOpen(!!props.isOpen);
    }, [props.isOpen]);

    useEffect(() => {
        const filterKeys: Array<any> = Object.keys(dp.filters.all);
        const paramsKeys: Array<any> = Object.keys(dp.params.all);
        const filterKeysSort = filterKeys.sort((a, b) => a.localeCompare(b));
        const paramsKeysSort = paramsKeys.sort((a, b) => a.localeCompare(b));

        setDefaultFilterKeys(filterKeysSort ?? []);
        setDefaultParamsKeys(paramsKeysSort ?? []);
    }, []);

    useEffect(() => {
        const selectedFilterCount = dp?.extraFilterKeys.selectedFilterCount;

        setSelectedFilterCount(selectedFilterCount ? selectedFilterCount : 0);
    }, [dp?.extraFilterKeys?.selectedFilterCount]);

    useEffect(() => {
        const filterKeys: Array<string> = Object.keys(dp.filters.all);
        const paramsKeys: Array<string> = Object.keys(dp.params.all);
        let matchKeysLength = 0;

        dp?.extraFilterKeys?.fields.forEach(key => {
            if (filterKeys.includes(key)) matchKeysLength += 1;
            if (paramsKeys.includes(key)) matchKeysLength += 1;
        });

        props?.excludeFilterKeys?.forEach(key => {
            if (!filterKeys.includes(key) && !paramsKeys.includes(key)) {
                matchKeysLength += 1;
            }
        });

        const filterKeysSort = filterKeys.sort((a, b) => a.localeCompare(b));
        const paramsKeysSort = paramsKeys.sort((a, b) => a.localeCompare(b));

        const isFilterKeysSame = filterKeysSort.toString() == defaultFilterKeys.toString();
        const isParamsKeysSame = paramsKeysSort.toString() == defaultParamsKeys.toString();

        setIsShowClearButton(!isFilterKeysSame || !isParamsKeysSame);

        dp?.extraFilterKeys?.changeSelectedFilterCount(matchKeysLength < 0 ? 0 : matchKeysLength);
    }, [dp.filters.all, dp.params.all, defaultFilterKeys, defaultParamsKeys]);

    const onChange = () => {
        props.onChange?.(!isOpen);
        setIsOpen(!isOpen);
    };

    return (
        <div className="toggle-filter-drawer">
            <Button
                className="d-flex justify-content-center align-items-center filter-button"
                style={{ color: "#000", backgroundColor: "#F2F4F8" }}
                onClick={() => onChange()}
            >
                <CIcon
                    path={isOpen ? iCLose2 : iFilterVariant}
                    className="mr-2"
                    style={{ width: "1rem", height: "1rem", color: "black" }}
                />
                <TText tkey="filters" />
            </Button>
            {selectedFilterCount > 0 && (
                <Badge className="float-right filter-badge" content={<span>{selectedFilterCount}</span>} />
            )}
            {(selectedFilterCount > 0 || isShowClearButton) && (
                <div className="clear-all-filters" onClick={dp.filters.clear}>
                    <span>
                        <TText tkey="clear_all_filters" />
                    </span>
                </div>
            )}
        </div>
    );
};

export default ToggleFilterDrawer;
