import { t } from "lib/i18n";
import { useAppSelector } from "hooks/useRedux";
import React, { FC, useMemo } from "react";
import PhoneInput, { PhoneInputProps } from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { InputGroup, InputNumberProps } from "rsuite";
import "../../../src/assets/scss/phone-input.scss";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";

interface CInputPhoneFormGroupProps extends CFormGroupProps<InputNumberProps> {
    testId?: string;
    inputStyle?: React.CSSProperties;
    description?: string;
}

const CInputPhoneFormGroup: FCC<CInputPhoneFormGroupProps> = props => {
    const { country } = useAppSelector((state: RootState) => state.packages);
    const cprops: any = {
        ...props.compProps,
        country,
    };
    return <CFormGroup {...(props as any)} compProps={cprops} comp={CPhoneInput} />;
};

const CPhoneInput: FC<
    PhoneInputProps & {
        name: string;
        onChange: (...event: any[]) => void;
    }
> = ({ name, country, onChange, ...props }) => {
    /**
     * There is bug in react-phone-input-2 if country changes it re-renders: https://codesandbox.io/p/sandbox/epic-zeh-47dfnr
     * That's why we should preserve undefined if props.value is not empty
     * Usage of useMemo is necessary to prevent this case: if user wants to delete phone number fully then write another, we should prevent country code reappear.
     */
    const defaultCountry = useMemo(
        () => (!props.value && country ? country.toString().toLowerCase() : undefined),
        [country],
    );

    return (
        <InputGroup>
            <PhoneInput
                inputProps={{
                    name,
                    "test-id": name,
                }}
                test-id="phone-input"
                disableCountryCode={false}
                inputStyle={{ height: "36px", width: "100%", borderWidth: 0, borderRadius: 0, ...props.inputStyle }}
                country={defaultCountry}
                onChange={phone => {
                    let newValue = phone;
                    if (newValue && !newValue.startsWith("+")) {
                        newValue = "+" + newValue;
                    }
                    onChange(newValue);
                }}
                placeholder={t("phone")}
                {...props}
            />
        </InputGroup>
    );
};

export default CInputPhoneFormGroup;
