import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import RemoteSelectFormGroup from "components/Form/RemoteSelectFormGroup";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import JsonView from "components/JsonView";
import { useEffect } from "react";
import { Message, toaster } from "rsuite";
import CForm from "components/Form/CForm/CForm";
import SelectFormGroup from "../../../components/Form/SelectFormGroup";
import SubmitButtonGroup from "../../../components/Form/SubmitButtonGroup";
import TextFormGroup from "../../../components/Form/TextFormGroup";
import { withDrawer } from "hocs/withDrawer";
import { useCForm } from "hooks/useCForm";
import CategorySelect from "../../products/CategorySelect";

interface IProps extends ICDrawerProps {
    onSubmit?: any;
}

const CategoryBulkUpdateDrawer: FCC<IProps> = props => {
    const useForm = useCForm({
        url: "category/bulk",
        model: false,
        isModal: false,
        redirectUrl: false,
        afterSubmit: () => {
            props.onSubmit();
            toaster.push(
                <Message type="success" showIcon closable>
                    <TText tkey="success" />
                </Message>,
            );
        },
    });

    useEffect(() => {
        const prepareForSubmit = (data: object) => ({
            ids: props.data,
            data,
        });
        useForm.set("prepareForSubmit", prepareForSubmit);
        // eslint-disable-next-line
    }, [props.data]);

    return (
        <CDrawer title={t("bulk_update_drawer_title")} {...props}>
            <CForm form={useForm.form} onSave={useForm.onSave} noShadow>
                <div className="p-3">
                    <CategorySelect
                        name={"parent_id"}
                        disabledIds={props.data}
                        no_cat_option
                        style={{ width: 300 }}
                        label={t("parent_category")}
                    />
                    <SelectFormGroup
                        name={"hidden"}
                        label={t("visibility")}
                        data={[
                            { id: 1, name: t("hide") },
                            { id: 0, name: t("show") },
                        ]}
                    />
                    <RemoteSelectFormGroup
                        name="tax_id"
                        searchLocal={true}
                        label={t("tax")}
                        remote={{
                            url: "finance/taxes",
                        }}
                        errorPlacement="rightEnd"
                    />
                    <TextFormGroup name="gov_code" />
                </div>
                <div style={{ padding: 20, float: "right" }}>
                    <SubmitButtonGroup isLoading={useForm.isLoading} onSubmit={useForm.onSave} />
                </div>
                <JsonView />
            </CForm>
        </CDrawer>
    );
};

export default withDrawer(CategoryBulkUpdateDrawer);
