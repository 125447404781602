import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import SubsHeader from "pages/settings/subscription/SubsHeader";

const SubscriptionPage: FCC = () => {
    const dp = useDataProvider({ url: "payment/transaction", useUrl: true, sort: ["created_at", "-1"] });
    const cm = useColumns<ISubscriptionTransaction>("payment/transaction", [
        { key: "created_at", type: "datetime", reverse: true },
        { key: "amount", type: "money" },
        { key: "method", title: t("payment_method") },
    ]);

    return (
        <div>
            {useTitle(t("subscriptions"))}
            <HeaderRight dp={dp} reload print />
            <SubsHeader />
            <DataTable dp={dp} columns={cm.columns} />
        </div>
    );
};

export default SubscriptionPage;
