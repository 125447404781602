import Rate from "assets/img/rate_1.svg";
import Rate2 from "assets/img/rate_2.svg";
import Rate3 from "assets/img/rate_3.svg";
import Rate4 from "assets/img/rate_4.svg";
import Rate5 from "assets/img/rate_5.svg";
import { Tooltip, Whisper } from "rsuite";

interface IProps {
    rate: number;
    ratingData: any[];
}

export const EmojiRate: FCC<IProps> = ({ rate, ratingData }) => {
    const rateEmoji = (rate: number) => {
        switch (rate) {
            case 1:
                return <img src={Rate} alt={`rate ${rate}`} style={{ width: 20 }} className="mr-1" />;
            case 2:
                return <img src={Rate2} alt={`rate ${rate}`} style={{ width: 20 }} className="mr-1" />;
            case 3:
                return <img src={Rate3} alt={`rate ${rate}`} style={{ width: 20 }} className="mr-1" />;
            case 4:
                return <img src={Rate4} alt={`rate ${rate}`} style={{ width: 20 }} className="mr-1" />;
            case 5:
                return <img src={Rate5} alt={`rate ${rate}`} style={{ width: 20 }} className="mr-1" />;
        }
    };

    const emojiMeaning = ratingData.find(rd => rd.value === rate);

    return (
        <div className="d-flex">
            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{emojiMeaning?.label || ""}</Tooltip>}>
                <div>{rateEmoji(rate)}</div>
            </Whisper>
        </div>
    );
};
