import React, { FC } from "react";
import { EMPTY_DATA } from "config/main";
import { getFormatedDate } from "lib/utils";
import { DoubleDataCell } from "components/DataTable/cell-types/DoubleDataCell";
import { format, isValid } from "date-fns";

interface IDateTimeCellProps extends React.HTMLAttributes<"div"> {
    date: string;
    showSeconds?: boolean;
    multiline?: boolean;
    reverse?: boolean;
}

export const DateTimeCell: FC<IDateTimeCellProps> = ({ date, showSeconds, multiline, reverse, ...props }) => {
    const objDate = new Date(date);

    if (!isValid(objDate)) {
        return EMPTY_DATA;
    }

    const timeString = format(objDate, showSeconds ? "HH:mm:ss" : "HH:mm");
    const dateString = getFormatedDate(date, false);

    const [value1, value2] = reverse ? [dateString, timeString] : [timeString, dateString];

    if (!multiline) {
        return (
            <div className={"tw-flex tw-gap-2"}>
                <div className={"tw-text-base"}>{value1}</div>
                <div className={"tw-text-base tw-text-gray-400"}>{value2}</div>
            </div>
        );
    }

    return <DoubleDataCell value1={value1} value2={value2} {...props} />;
};
