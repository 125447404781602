import { t } from "lib/i18n";
import { IDataProvider } from "components/DataTable/useDataProvider";
import { endOfLastMonth, last30, last7, lastDay, startOfLastMonth, startOfMonth } from "config/dates";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { getDatePairFromNumbers, getNumberPairFromDates } from "lib/pure";
import { AppSlice } from "redux/features/app";
import { DateRangePicker } from "rsuite";
import { passCashRestartCheck } from "lib/passCashRestartCheck";
import { iCalendar } from "assets/icons/Icons";
import CIcon from "../../CIcon";
import { useSetting } from "hooks/useSetting";
import { format } from "date-fns";

export interface IDateRangeProps {
    dp: IDataProvider;
}

const DateRangeFilter: FCC<IDateRangeProps> = () => {
    const dispatch = useAppDispatch();
    const dates = useAppSelector(s => s.app.dates);

    const updateDates = (v: [Date, Date]) => {
        dispatch(AppSlice.actions.setDates(getNumberPairFromDates(v)));
    };

    const cashRestart = useSetting("cash_restart").value;
    const accurateToday = passCashRestartCheck(cashRestart);

    return (
        <div
            className="d-flex align-items-center tw-cursor-pointer"
            style={{
                padding: "0px",
                background: "#F2F4F7",
                borderRadius: "30px",
                marginRight: "8px",
                position: "relative",
            }}
        >
            <DateRangePicker
                format="yyyy-MM-dd"
                cleanable={false}
                style={{ paddingLeft: "10px" }}
                appearance="subtle"
                test-id="filter-date-range-picker"
                editable={false}
                shouldDisableDate={DateRangePicker.afterToday?.()}
                placement="bottomEnd"
                placeholder={false}
                value={getDatePairFromNumbers(dates)}
                onChange={updateDates as any}
                caretAs={() => (
                    <CIcon style={{ position: "absolute", left: 0, top: 10, zIndex: 10 }} size={0.7} path={iCalendar} />
                )}
                renderValue={vals => {
                    let start, end;
                    if (new Date(vals[0]).getFullYear() !== new Date(vals[1]).getFullYear()) {
                        start = format(new Date(vals[0]), "d MMM yyyy");
                        end = format(new Date(vals[1]), "d MMM yyyy");
                    } else {
                        start = format(new Date(vals[0]), "d MMM");
                        end = format(new Date(vals[1]), "d MMM");
                    }
                    return (
                        <>
                            <span>{start}</span>
                            {start !== end && <span> - {end}</span>}
                        </>
                    );
                }}
                ranges={[
                    { label: t("today"), value: [accurateToday, accurateToday], placement: "left" },
                    {
                        label: t("yesterday"),
                        value: [lastDay(accurateToday), lastDay(accurateToday)],
                        placement: "left",
                    },
                    {
                        label: t("last_7_days"),
                        value: [last7(accurateToday), accurateToday],
                        placement: "left",
                    },
                    {
                        label: t("last_30_days"),
                        value: [last30(accurateToday), accurateToday],
                        placement: "left",
                    },
                    {
                        label: t("this_month"),
                        value: [startOfMonth(), accurateToday],
                        placement: "left",
                        "test-id": "range-picker-this-month",
                    } as any,
                    {
                        label: t("last_month"),
                        value: [startOfLastMonth(), endOfLastMonth()],
                        placement: "left",
                    },
                ]}
            />
        </div>
    );
};

export default DateRangeFilter;
