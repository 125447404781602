import { t } from "lib/i18n";
import classNames from "classnames";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import { exportFile } from "lib/export-table";
import { productNameAppend } from "lib/data-utils";
import Money from "components/mini/Money";
import GoodsStatsDrawer from "pages/statistics/goods/drawer/GoodsStatsDrawer";
import { ProductType, Trash } from "config/constants";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useData } from "hooks/useData";
import LocalCheckListFilter from "../../components/DataTable/Filters/LocalCheckListFilter";
import _ from "lodash";

const TaxStatsPage: FCC = () => {
    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        url: "finance/tax/report",
    });
    const rd = useDrawer();
    const summary: any = useData<any>("finance/tax/total", {
        params: { date: dp.params.all.date },
        filters: _.values(dp.filters.all),
    });
    const cm = useColumns<any>("finance/tax/report", [
        {
            key: "name",
            align: "left",
            flexGrow: 2,
        },
        {
            key: "type",
            align: "left",
            flexGrow: 2,
            render: d => d?.type?.name || "-",
        },
        {
            key: "rate",
            align: "left",
            title: t("percentage"),
            flexGrow: 2,
            render: d => d.rate + "%",
        },
        {
            key: "used_amount",
            title: t("tax_amount"),
            align: "left",
            flexGrow: 2,
            render: d => <Money value={d.used_amount} />,
            summary: () => <Money colored value={(summary?.data && summary?.data?.used_amount) || 0} />,
        },
        {
            key: "return_tax_amount",
            align: "left",
            flexGrow: 2,
            render: d => <Money value={d.return_tax_amount} />,
            summary: () => <Money colored value={(summary?.data && summary?.data?.return_tax_amount) || 0} />,
        },
        {
            key: "used_count",
            align: "left",
            flexGrow: 2,
            summary: () => (summary?.data && summary?.data?.used_count) || 0,
        },
    ]);

    return (
        <div className="h-100">
            {useTitle(t("taxes"), `${(dp.data && dp.data.length) || 0}`)}
            <HeaderRight cm={cm} dp={dp} reload export={() => exportFile(dp, cm.columns, t("goods"))} print date />
            <GoodsStatsDrawer {...rd} />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="name" />
                    <LocalCheckListFilter
                        fields="type_id"
                        url="finance/tax/types"
                        // pickerProps={{ placeholder: t("terminals") }}
                    />
                    <LocalCheckListFilter
                        fields="terminal_id"
                        url="terminal"
                        pickerProps={{ placeholder: t("terminals") }}
                    />
                    <RemoteCheckListFilter
                        fields="product_id"
                        searchField="name"
                        remote={{
                            url: "product",
                            sort: ["updated_at", "-1"],
                            limit: 20,
                            params: {
                                trash: Trash.TrashAndActive,
                            },
                            filters: {
                                type: [
                                    ["type", ProductType.GOODS],
                                    ["type", ProductType.DISH],
                                    ["type", ProductType.MODIFICATION],
                                ],
                            },
                            refineList: (products?: IProduct[]) =>
                                products
                                    ?.map(i => productNameAppend(i))
                                    ?.filter(f => !(f.type === ProductType.GOODS && f.has_modifications)) ?? [],
                        }}
                        pickerProps={{ placeholder: t("product") }}
                    />
                </div>
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                    rowClassName: (d?: any) =>
                        classNames({
                            "deleted-row": d && !!d?.deleted_at,
                        }),
                }}
            />
        </div>
    );
};

export default TaxStatsPage;
