import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";

import { getLabel } from "components/Form/form-utils";
import CreateButton from "../../components/DataTable/CreateButton";
import _ from "lodash";
import CIcon from "../../components/CIcon";
import { iCheckBoxChecked, iClose } from "assets/icons/Icons";
import React from "react";
import { config } from "config/Config";
import { Colors } from "config/colors";
import { SalesChannelTypes } from "config/constants";

const SalesTypePage: FCC = () => {
    const dp = useDataProvider({
        url: "finance/saleType",
        useUrl: true,
        with: ["payment_method"],
    });

    const cm = useColumns<ISaleType>("finance/saleType", [
        { key: "id", align: "left", title: "ID" },
        {
            key: "name",
            align: "left",
            render: (d: any) => getLabel(d.name, !_.isNil(d.system_type)),
        },
        {
            key: "channel",
            title: t("sales_type_channel"),
            render: d =>
                t(`channel_${d?.channel}`.toLowerCase() as `channel_${Lowercase<SalesChannelTypes>}`) ?? d?.channel,
        },
        { key: "payment_method.name", title: t("payment_method") },
        {
            key: "status",
            type: "bool",
            render: d =>
                d?.status?.[config.venueId] === 1 ? (
                    <div
                        className="d-flex align-items-center rounded-circle justify-content-center"
                        style={{ background: Colors.MintGreen, width: "16px", height: "16px" }}
                    >
                        <CIcon path={iCheckBoxChecked} style={{ color: Colors.TropicalGreen }} size={0.45} />
                    </div>
                ) : (
                    <div
                        className="d-flex align-items-center rounded-circle justify-content-center"
                        style={{ background: Colors.SoftPeach, width: "16px", height: "16px" }}
                    >
                        <CIcon path={iClose} style={{ color: Colors.BrightRed }} size={0.45} />
                    </div>
                ),
        },
        {
            key: "service_charge_rate",
            render: d => (d.service_charge_rate === null ? t("default_service_charge") : d?.service_charge_rate),
        },
        optionsColumn({
            dp,
            edit: "/sales/type/edit/:id",
            delete: "finance/saleType/:id",
            preventDelete: d => d.id < 999,
            canDelete: "sale_type_manage",
            canEdit: "sale_type_manage",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("sales_type"), dp.total)}
            <HeaderRight dp={dp} cm={cm} reload print />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter className="search-input" fields="name" />
                </div>
                <CreateButton to="/sales/type/create" canCreate={"sale_type_manage"} />
            </FiltersContainer>

            <DataTable
                dp={dp}
                columns={cm.columns}
                rowPadding={"22px 10px 0"}
                tableProps={{
                    rowHeight: 60,
                }}
            />
        </div>
    );
};

export default SalesTypePage;
