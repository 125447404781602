import { config } from "config/Config";
import { ButtonToolbar, Dropdown } from "rsuite";
import CIcon from "../../components/CIcon";
import { iHome } from "assets/icons/Icons";
import { refineUrl } from "lib/pure";
import React from "react";
import { useAppSelector } from "hooks/useRedux";
import { Colors } from "config/colors";

interface IProps {
    expand: boolean;
}
const redirectToSelectedBrandVenue = (brand: string, venue: number) => {
    window.location.href = refineUrl(brand, venue);
};

export const SidebarBrandSelector: FCC<IProps> = ({ expand }) => {
    const selectedBrand = config.getBrand();
    // const selectedVenue = config.getVenueId();
    const { venues, currentVenueId } = useAppSelector(state => state.venue);
    const brandNames = config.getBrands().map(item => ({ label: item, value: item }));
    if (brandNames.length < 2) {
        return null;
    }
    return (
        <>
            <ButtonToolbar className="brand-selector" style={{ cursor: "pointer" }}>
                <Dropdown
                    noCaret={true}
                    style={{ height: 36 }}
                    title={expand ? selectedBrand : null}
                    placement={expand ? "topEnd" : "rightEnd"}
                    onSelect={(value: string) => redirectToSelectedBrandVenue(value, 1)}
                    icon={
                        <CIcon
                            path={iHome}
                            style={{ color: Colors.White, float: expand ? "left" : "none", marginTop: "2px" }}
                        />
                    }
                >
                    {brandNames.map((brandName: { label: string; value: string }) => (
                        <Dropdown
                            placement="rightEnd"
                            key={brandName.label}
                            title={brandName.label}
                            style={{ minWidth: 120, background: Colors.LightPeriwinkle }}
                            trigger={["hover", "click"]}
                            onClick={() => redirectToSelectedBrandVenue(brandName.value as string, 1)}
                            toggleClassName="brand-venue__selector"
                        >
                            {brandVenuesParser(brandName)?.map((venue: IVenue) => (
                                <Dropdown.Item
                                    active={venue.id === currentVenueId && brandName.value === selectedBrand}
                                    onSelect={(eventKey, event) => {
                                        event.stopPropagation();
                                        return redirectToSelectedBrandVenue(brandName.value as string, venue.id);
                                    }}
                                    key={venue.id}
                                >
                                    {venue.name}
                                </Dropdown.Item>
                            ))}
                        </Dropdown>
                    ))}
                </Dropdown>
            </ButtonToolbar>
        </>
    );
};
const brandVenuesParser = (brandName: { label: string; value: string }): any[] => {
    try {
        const brandVenueListKey = `_client_${brandName.label}_venueList`;

        const storedData = localStorage.getItem(brandVenueListKey);

        if (!storedData) return [];

        const json_encode = (value: string, retried = 0): any[] => {
            if (retried > 3) return [];

            const result = JSON.parse(value);

            if (typeof result === "string") {
                return json_encode(result, retried + 1);
            }

            return result;
        };

        return json_encode(storedData as string);
    } catch (e) {
        console.error(e);

        return [];
    }
};
