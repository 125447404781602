import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useCForm } from "hooks/useCForm";
import { dummyPaymentMethod, fillDummyVenuesList } from "models/PaymentMethodModel";
import { useData } from "hooks/useData";
import _ from "lodash";
import { FinanceAccountDefaultFilters } from "config/constants";
import { useParams } from "react-router-dom";
import TextFormGroup from "components/Form/TextFormGroup";
import SelectFormGroup from "components/Form/SelectFormGroup";
import { useFormTitle } from "components/Header/Title";
import HeaderRight from "components/Header/HeaderRight";
import CForm from "components/Form/CForm/CForm";
import { BalanceWithAmount } from "components/mini/BalanceWithAmount";
import CFormGroup from "components/Form/CFormGroup";
import JsonView from "components/JsonView";

import ToggleFormGroup from "../../../components/Form/ToggleFormGroup";
import React, { useEffect } from "react";
import { useAppSelector } from "hooks/useRedux";
import { Tooltip, Whisper } from "rsuite";
import CIcon from "../../../components/CIcon";
import { iInfoCircle } from "assets/icons/Icons";
import { Colors } from "config/colors";
import { PaymentMethodScheme } from "form-schemas/PaymentMethodScheme";

const EditPaymentMethodPage: FCC = props => {
    const { venues, currentVenueId } = useAppSelector(state => state.venue);

    const accounts = useData<IFinanceCategory[]>("finance/balance", {
        params: {
            limit: 999,
            filters: _.values(FinanceAccountDefaultFilters),
        },
    });
    const form = useCForm({
        url: "finance/paymentMethod",
        model2: PaymentMethodScheme,
        // redirectUrl: "/finance/payment-methods",
        dummyData: dummyPaymentMethod,
        refine: (d: any) => {
            d.tmp_status = d.status;
            //for show active status for venue
            d.status = d.status[`${currentVenueId}`] === 1;
            return d;
        },
        prepareForSubmit: (d: any) => {
            d.split = d.split ? 1 : 0;
            d.customer_required = d.customer_required ? 1 : 0;
            d.status = {
                ...d.tmp_status,
                [`${currentVenueId}`]: d.status ? 1 : 0,
            };
            return _.omit(d, ["id", "tmp_status", !d.balance_id ? "balance_id" : ""]);
        },
    });
    const name = form.form.getValues("name");

    useEffect(() => {
        form.form.trigger("balance_id");
    }, [name]);

    const params: any = useParams();
    // const isSystemGenerated = params?.id ? +params.id === 1 : false; // 1 is system generated payment method (cash)
    const hasBeenDisabledBySystem = params?.id ? +params.id <= 4 : false;
    const hasBeenDisabledBalanceBySystem = params?.id ? +params.id > 2 && +params.id <= 4 : false;

    const venuesArr = { ...fillDummyVenuesList(), ...form.form.getValues("status") };
    const valueForToggle = venuesArr[currentVenueId];

    return (
        <div className="p-3">
            {useFormTitle(form.id, t("create_by_name", { name: _.capitalize(t("payment_method")) }))}
            <HeaderRight formSubmit={form} />
            <CForm noShadow form={form.form} onSave={form.onSave}>
                <TextFormGroup
                    compProps={{
                        disabled: hasBeenDisabledBySystem,
                    }}
                    name="name"
                    errorPlacement="rightEnd"
                    focus={true}
                    labelProps={{
                        style: { textAlign: "left" },
                    }}
                />
                <SelectFormGroup
                    name="balance_id"
                    compProps={{
                        disabled: hasBeenDisabledBalanceBySystem,
                        renderMenuItem: (val: any, item: any) => (
                            <BalanceWithAmount testId="balance_id_item" item={item} />
                        ),
                        renderValue: (val: any, item: any) => <BalanceWithAmount item={item} />,
                    }}
                    data={accounts.data}
                    labelProps={{
                        style: { textAlign: "left" },
                    }}
                    errorPlacement="rightEnd"
                />
                <ToggleFormGroup
                    name="split"
                    label={t("split")}
                    labelProps={{
                        style: {
                            textAlign: "left",
                            // paddingLeft: "12px",
                        },
                    }}
                    // style={{ maxWidth: "420px", display: "flex", flexDirection: "row-reverse" }}
                    compProps={{
                        checkedChildren: "ON",
                        unCheckedChildren: "OFF",
                    }}
                />
                <ToggleFormGroup
                    name="customer_required"
                    label={t("customer_required")}
                    labelProps={{
                        style: {
                            textAlign: "left",
                            // paddingLeft: "12px",
                        },
                    }}
                    // style={{ maxWidth: "420px", display: "flex", flexDirection: "row-reverse" }}
                    compProps={{
                        checkedChildren: "ON",
                        unCheckedChildren: "OFF",
                        disabled: hasBeenDisabledBySystem,
                    }}
                />
                {/*<CheckboxFormGroup name="" compProps={{ disabled: isSystemGenerated }} />*/}
                <CFormGroup
                    name="status"
                    // style={{ maxWidth: "420px", display: "flex", flexDirection: "row-reverse" }}
                    labelProps={{
                        style: {
                            textAlign: "left",
                            // paddingLeft: "12px",
                        },
                    }}
                >
                    {_.values(venuesArr)?.map((value, index) => {
                        const key = Object.keys(venuesArr)[index];
                        const venue: IVenue | undefined = venues.find((g: any) => g.id === Number(key));
                        if (currentVenueId !== venue?.id) return null;
                        return (
                            <div style={{ display: "flex", alignItems: "center", gap: 25 }}>
                                {/*{venueList.length !== 1 && (*/}
                                {/*    <b style={{ minWidth: 60, textAlign: "left" }}>*/}
                                {/*        {venueList.find((g: any) => g.id === Number(key))?.name}*/}
                                {/*    </b>*/}
                                {/*)}*/}
                                {/*<ToggleFormGroup*/}
                                {/*    disabled={_.isNil(value)}*/}
                                {/*    CFormStyle={{ paddingLeft: 0 }}*/}
                                {/*    onChange={(__: any, checked: boolean) => {*/}
                                {/*        const dummyS = { ...venuesArr };*/}
                                {/*        dummyS[key] = checked === true ? 1 : 0;*/}
                                {/*        form.form.setValue("status", dummyS);*/}
                                {/*    }}*/}
                                {/*    key={key}*/}
                                {/*    checked={value}*/}
                                {/*    label={t("customer_required")}*/}
                                {/*    compProps={{*/}
                                {/*        checkedChildren: "ON",*/}
                                {/*        unCheckedChildren: "OFF",*/}

                                {/*        disabled: isSystemGenerated,*/}
                                {/*        style: {},*/}
                                {/*    }}*/}
                                {/*/>*/}
                                <div style={{ display: "flex", alignItems: "center", gap: 25 }}>
                                    {/*{venueList.length !== 1 && <b style={{ minWidth: 60 }}>{venue?.name}</b>}*/}
                                    <div
                                        style={{ marginLeft: "-120px", marginRight: "75px", marginTop: "6px" }}
                                        className="d-flex align-items-center"
                                    >
                                        <Whisper
                                            placement="right"
                                            trigger="click"
                                            test-id="sales_type_info"
                                            speaker={
                                                <Tooltip test-id="sales_type_info_tooltip">
                                                    <TText tkey="sales_type_info" />
                                                </Tooltip>
                                            }
                                        >
                                            <span test-id="sales_type_info">
                                                <CIcon
                                                    path={iInfoCircle}
                                                    size={1}
                                                    style={{ color: Colors.VibrantSkyBlue }}
                                                />
                                            </span>
                                            {/*<Icon style={iconStyle} size="lg" className="pl-2" icon={"info-circle"} />*/}
                                        </Whisper>
                                    </div>
                                    <ToggleFormGroup
                                        disabled={_.isNil(value)}
                                        name={`status`}
                                        label={false}
                                        CFormStyle={{ paddingLeft: 0 }}
                                        // onChange={(__: any, checked: boolean) => {
                                        //     const dummyS = { ...venuesArr };
                                        //     dummyS[currentVenueId] = !dummyS[currentVenueId] ? 1 : 0;
                                        //     form.form.setValue(`status`, dummyS);
                                        // }}
                                        compProps={{
                                            checkedChildren: "ON",
                                            unCheckedChildren: "OFF",
                                        }}
                                        key={key}
                                    >
                                        {!valueForToggle ? t("deactivated") : t("activated")}
                                    </ToggleFormGroup>
                                </div>
                            </div>
                        );
                    })}
                </CFormGroup>
                <JsonView />
            </CForm>
        </div>
    );
};

export default EditPaymentMethodPage;
