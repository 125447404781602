import { t } from "lib/i18n";
import { number, object, string } from "yup";

export const TaxModel = () =>
    object().shape({
        name: string().required(t("required")),
        rate: string().required(t("required")),
        type_id: number().required(t("required")),
        priority: number().required(t("required")),
        calculation_type: number(),
    });
