import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import CreateButton from "components/DataTable/CreateButton";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import { ProductType, Trash } from "config/constants";
import _ from "lodash";
import { useState } from "react";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import OperationProcessIndicator from "components/OperationProcessIndicator";
import Ajax from "lib/Ajax";
import { productNameAppend } from "lib/data-utils";
import { exportFile } from "lib/export-table";
import { toFixed } from "lib/utils";
import ReturnedSuppliesProductsDrawer from "../misc/ReturnedSuppliesProductsDrawer";
import StockOperationsDrawer from "../misc/StockOperationsDrawer";
import { SelectStorageFilter } from "components/adhoc-filters/SelectStorageFilter";

const ProductReturnSuppliesPage: FCC = () => {
    const url = "product/supply-return";
    const [opSupply, setOpSupply] = useState<IOperation>();
    const drawer = useDrawer({ backdrop: false, overflowAuto: true });
    const drawerSupply = useDrawer({ backdrop: false, overflowAuto: false });
    const dp = useDataProvider({
        url,
        useUrl: true,
        useDates: true,
        with: [
            "products.",
            "balance",
            "user",
            "operation.supplier",
            "supply_operation",
            "storage",
            "supply_operation.supplier",
            "products.unit",
            "transaction",
        ],
        sort: ["created_at", "-1"],
        refineList: list => {
            return list?.map(ls => {
                return {
                    ...ls,
                    stock_operations:
                        ls.status === 2
                            ? ls.meta?.item || []
                            : _.map(
                                  _.groupBy(ls.stock_operations, sp => sp.product_id),
                                  a => ({
                                      ...a[0],
                                      quantity: parseFloat(
                                          toFixed(
                                              a.reduce((fd, rt) => fd + rt.quantity, 0),
                                              4,
                                          ),
                                      ),
                                      total_cost: parseFloat(
                                          toFixed(
                                              a.reduce((fd, rt) => fd + rt.total_cost, 0),
                                              4,
                                          ),
                                      ),
                                  }),
                              ),
                    items: ls.products.filter((so: any) => !so.parent_id),
                    supplier: ls?.supply_operation?.supplier,
                    transaction_finance: {
                        balance: ls?.balance,
                    },
                };
            });
        },
    });

    const cm = useColumns<any>("operation/supplies/return", [
        {
            key: "id",
            align: "left",
            flexGrow: 0.2,
            title: t("data_id"),
            isFixed: true,
            onClick: (d: IOperation) => {
                drawerSupply.hide();
                drawer.setDataId(d.id);
            },
        },
        {
            key: "supply_operation_id",
            title: t("operation_code"),
            flexGrow: 0.3,
            align: "left",

            onClick: async (d: IOperation) => {
                drawer.hide();
                const transactionData: any = await Ajax.get({
                    url: "/operation/" + d.supply_operation_id,
                    method: "get",
                    params: {
                        with: [
                            "stock_operations.stock",
                            "stock_operations.unit",
                            "stock_operations.product",
                            "transaction_finance.balance",
                            "transaction_finance.operation.supplier",
                            "supplier",
                            "storage",
                            "supply_returns",
                            "supply_returns.transaction",
                        ],
                    },
                });
                if (transactionData?.success) {
                    setOpSupply(transactionData?.data);
                    if (d.supply_operation_id) drawerSupply.setDataId(d.supply_operation_id);
                }
            },
        },
        {
            key: "supply_operation.supplier.name",
            title: t("supplier"),

            flexGrow: 0.3,
            render: d => d?.supplier?.name || d?.operation?.supplier?.name,
        },
        { key: "storage.name", title: t("storage"), flexGrow: 0.3 },
        {
            key: "transaction.amount",
            title: t("amount"),

            type: "money",
            align: "left",
            flexGrow: 0.3,
        },
        {
            key: "balance.name",
            title: t("account"),
            flexGrow: 0.3,
            render: d => d.transaction_finance.balance.name,
        },
        { key: "description", flexGrow: 0.3 },
        {
            key: "operation.inventory_status",
            isFixed: true,
            title: t("inventory_status"),
            flexGrow: 0.2,
            render: e => <OperationProcessIndicator status={e?.operation?.inventory_status} />,
        },
        {
            key: "operated_at",
            type: "datetime",
            flexGrow: 0.3,
            multiline: true,
            showSeconds: true,
        },
        {
            key: "created_at",
            type: "datetime",
            flexGrow: 0.3,
            multiline: true,
            showSeconds: true,
        },
        optionsColumn({
            dp,
            delete: "/product/supply-return/:id",
            canDelete: "supply_returns_manage_delete",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("returned_supplies"), dp.total)}
            <HeaderRight
                dp={dp}
                cm={cm}
                date
                reload
                print
                export={() => exportFile(dp, cm.columns, t("returned_supplies"))}
            />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    {/*<SearchFilter fields="barcode" placeholder={t("barcode")} />*/}
                    <SearchFilter fields="id" placeholder={t("operation_code")} />
                    <RemoteCheckListFilter
                        fields="product_id"
                        searchField="name"
                        remote={{
                            url: "product",
                            sort: ["updated_at", "-1"],
                            limit: 20,
                            params: {
                                trash: Trash.TrashAndActive,
                            },
                            filters: {
                                type: [
                                    ["type", ProductType.GOODS],
                                    ["type", ProductType.INGREDIENT],
                                    ["type", ProductType.MODIFICATION],
                                ],
                            },
                            refineList: (products?: IProduct[]) =>
                                products
                                    ?.map(i => productNameAppend(i))
                                    ?.filter(f => !(f.type === ProductType.GOODS && f.has_modifications)) ?? [],
                        }}
                        pickerProps={{ placeholder: t("product") }}
                        width={120}
                    />
                    <RemoteCheckListFilter
                        remote="supplier"
                        fields="supplier_id"
                        searchField="name"
                        pickerProps={{ placeholder: t("supplier") }}
                        width={120}
                    />
                    <SelectStorageFilter />
                </div>
                <CreateButton to={"/inventory/returned-supplies/create"} canCreate={"supply_returns_manage_create"} />
            </FiltersContainer>
            <ReturnedSuppliesProductsDrawer {...drawer} />

            <StockOperationsDrawer
                {...drawerSupply}
                op={opSupply}
                title={t("total_supply") + " #" + drawerSupply.dataId}
                cols={["product.name", "quantity", "cost", "total_cost"]}
            />
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
        </div>
    );
};

export default ProductReturnSuppliesPage;
