import { t } from "lib/i18n";
import { useCForm } from "hooks/useCForm";
import { dummyAccount } from "models/AccountModel";
import CForm from "components/Form/CForm/CForm";
import TextFormGroup from "components/Form/TextFormGroup";
import SelectFormGroup from "components/Form/SelectFormGroup";
import _ from "lodash";
import { Constants } from "config/constants";
import { useFormTitle } from "components/Header/Title";

import HeaderRight from "components/Header/HeaderRight";
import TextAreaFormGroup from "../../../components/Form/TextAreaFormGroup";
import React from "react";
import JsonView from "components/JsonView";
import { ruleset } from "lib/validation-rules";

const AccountsEditPage: FCC = props => {
    const form = useCForm({
        url: "finance/balance",
        dummyData: () => dummyAccount(),
    });

    return (
        <div className="p-3">
            {useFormTitle(form.id, t("create_by_name", { name: _.capitalize(t("account")) }))}
            <HeaderRight formSubmit={form} />
            <CForm noShadow form={form.form} onSave={form.onSave}>
                <JsonView />
                <TextFormGroup
                    name="name"
                    errorPlacement="rightEnd"
                    focus={true}
                    rules={{
                        validate: {
                            required: ruleset.required,
                        },
                    }}
                />
                <SelectFormGroup
                    name="type"
                    data={_.map(Constants.financeAccountTypes, (name, id) => ({ name, id }))}
                    errorPlacement="rightEnd"
                />
                <TextFormGroup
                    name="amount"
                    type="number"
                    errorPlacement="rightEnd"
                    rules={{
                        validate: {
                            required: ruleset.number,
                        },
                    }}
                    compProps={{ disabled: true, style: { width: 300 } }}
                />
                <TextAreaFormGroup
                    compProps={{ style: { width: 300, height: 65 } }}
                    className="mb-3"
                    name="description"
                />
            </CForm>
        </div>
    );
};

export default AccountsEditPage;
