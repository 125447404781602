import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React, { useEffect, useState } from "react";
import TagPickerFormGroup from "components/Form/TagPickerFormGroup";
import _ from "lodash";
import TextFormGroup from "components/Form/TextFormGroup";
import UploaderFormGroup from "components/Form/FileUploadFormGroup";
import BlockColorPicker from "components/Form/BlockColorPicker";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import { Button, Form, Tooltip, Whisper } from "rsuite";
import { QR_MENU_SHORT_URL } from "config/main";
import { useData } from "hooks/useData";
import JSZip from "jszip";
import QRCodeStyling from "qr-code-styling";
import { saveAs } from "file-saver";
import { usePackages } from "providers/PackageProvider";
import CIcon from "components/CIcon";
import { iContentCopy, iInfoOutline } from "assets/icons/Icons";
import { useAppSelector } from "hooks/useRedux";
import { Colors } from "config/colors";
import { useSetting } from "hooks/useSetting";
import { useLoading } from "hooks/useLoading";
import { useIntegrationForm } from "pages/settings/integrations/utils";

interface IGeneralSettingsTabProps {
    form: ReturnType<typeof useIntegrationForm>;
}

export const GeneralSettingsTab: FCC<IGeneralSettingsTabProps> = ({ form }) => {
    const { currentVenueId } = useAppSelector(state => state.venue);

    const baseUrlShort = `${QR_MENU_SHORT_URL}/m/`;
    const packages = usePackages();
    const baseUrl = `${QR_MENU_SHORT_URL}/m/${btoa(
        JSON.stringify({ vn: currentVenueId, b: packages?.subscription?.brand_id }),
    )}`;
    const [isCopied, setIsCopied] = useState(false);
    const qrDefaultOptions: any = {
        type: "canvas",
        image: "/img/icon-128.png",
        margin: 15,
        cornersSquareOptions: {
            type: "extra-rounded",
        },
        cornersDotOptions: {
            type: "dot",
        },
        dotsOptions: {
            color: Colors.CharcoalGray,
            type: "extra-rounded",
        },
        imageOptions: {
            margin: 7,
        },
    };
    const qrDefaultSvgOptions: any = {
        type: "canvas",
        image: "https://cdn.clopos.com/_clopos/logo.svg",
        margin: 15,
        cornersSquareOptions: {
            type: "extra-rounded",
        },
        cornersDotOptions: {
            type: "dot",
        },
        dotsOptions: {
            color: Colors.CharcoalGray,
            type: "extra-rounded",
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 10,
        },
    };
    const { isLoading, withLoading } = useLoading();

    const tablesWithHall = useData<ITable[]>("table", {
        with: ["hall"],
    });

    const copyToClipboard = () => {
        setIsCopied(true);
        navigator.clipboard?.writeText(baseUrl);
        setTimeout(() => {
            setIsCopied(false);
        }, 3000);
    };

    const prepareTableUrl = (table: ITable) => {
        const json = { t: table.id, b: packages?.subscription?.brand_id };
        const hallTableName = btoa(JSON.stringify(json));
        return `${baseUrlShort}${hallTableName}`;
    };

    const prepareQRZip = async (tables?: ITable[]) => {
        const zip = new JSZip();
        const singleQRPNG = await new QRCodeStyling({
            width: 1024,
            height: 1024,
            data: baseUrl,
            ...qrDefaultOptions,
        }).getRawData();
        const singleQRSVG = await new QRCodeStyling({
            width: 1024,
            height: 1024,
            type: "svg",
            data: baseUrl,
            ...qrDefaultSvgOptions,
        }).getRawData("svg");

        if (tables) {
            const test = await Promise.all(
                tables.map(async table => {
                    return {
                        hall: table.hall?.name,
                        table: table.name,
                        canvas: await new QRCodeStyling({
                            width: 1024,
                            height: 1024,
                            data: prepareTableUrl(table),
                            ...qrDefaultOptions,
                        }).getRawData(),
                    };
                }),
            );
            const testSVG = await Promise.all(
                tables.map(async table => {
                    return {
                        hall: table.hall?.name,
                        table: table.name,
                        canvas: await new QRCodeStyling({
                            width: 1024,
                            height: 1024,
                            type: "svg",
                            data: prepareTableUrl(table),
                            ...qrDefaultSvgOptions,
                        }).getRawData("svg"),
                    };
                }),
            );

            test.forEach(t => zip.file(`${t.hall}_${t.table}.png`, t.canvas!));
            testSVG.forEach(t => zip.file(`${t.hall}_${t.table}.svg`, t.canvas!));
        }

        zip.file(`qr_menu.png`, singleQRPNG!);
        zip.file(`qr_menu.svg`, singleQRSVG!);

        return await zip.generateAsync({ type: "blob" });
    };

    useEffect(() => {
        const image = document.getElementById("image");

        if (image) {
            const code = new QRCodeStyling({
                width: 150,
                height: 150,
                data: baseUrl,
                ...qrDefaultOptions,
            });
            code.append(image);
        }

        return () => {
            if (image) {
                image.innerHTML = "";
            }
        };
        // eslint-disable-next-line
    }, [tablesWithHall.data]);

    const optionsForLanguage = useSetting("emenu_lang")?.extra;

    const downloadQR = () => {
        withLoading(async () => {
            return tablesWithHall.data?.length ? await prepareQRZip(tablesWithHall.data) : await prepareQRZip();
        }).then(content => saveAs(content, `qr_menu_${currentVenueId}.zip`));
    };

    return (
        <div className="pb-3 pt-3">
            <ToggleFormGroup name="status" label={t("emenu_enabled")} />
            <div className="tw-flex">
                <ToggleFormGroup name="payload.meta.require_login" label={t("require_login")} />
                <Whisper
                    placement="right"
                    trigger="hover"
                    speaker={
                        <Tooltip>
                            <TText tkey="emenu_require_login_info" />
                        </Tooltip>
                    }
                >
                    <div className={"tw-pt-2 tw-pl-1"}>
                        <CIcon path={iInfoOutline} size={0.9} style={{ color: Colors.CobaltBlue }} />
                    </div>
                </Whisper>
            </div>
            <ToggleFormGroup name="payload.meta.hide_service_charge" label={t("hide_service_charge")} />
            <ToggleFormGroup name="payload.meta.call_waiter_status" label={t("call_waiter_status")} />
            <ToggleFormGroup name="payload.meta.ask_for_check_status" label={t("ask_for_check_status")} />
            <ToggleFormGroup name="payload.meta.approve_order_status" label={t("approve_order_status")} />

            <TagPickerFormGroup
                name="payload.langs"
                label={t("languages")}
                data={_.map(optionsForLanguage, (name, id) => ({ id, name }))}
                compProps={{
                    placeholder: t("select_language"),
                }}
            />

            <TextFormGroup name="payload.subtitle" label={t("emenu_subtitle")} />
            <TextFormGroup
                name="payload.meta.about"
                label={t("about")}
                compProps={{
                    rows: 5,
                    as: "textarea",
                }}
            />
            <UploaderFormGroup
                name="payload.image"
                mediaDataName={"payload.image_data"}
                label={t("image")}
                compProps={{
                    action: "media",
                    multiple: false,
                    listType: "picture",
                }}
                aspectRatio={16 / 16}
                onRemove={form.onSave}
                afterUpload={() => form.onSave()}
            />
            <UploaderFormGroup
                name="payload.background_image"
                mediaDataName={"payload.background_image_data"}
                label={t("emenu_background_image")}
                compProps={{
                    action: "media",
                    multiple: false,
                    listType: "picture",
                }}
                onRemove={form.onSave}
                afterUpload={() => form.onSave()}
            />
            <BlockColorPicker name="payload.color" label={t("color")} />
            <Form.Group>
                <Form.ControlLabel />
                <div className={"d-flex align-items-center"}>
                    <div style={{ marginLeft: -10 }} id="image" />
                    <div className={"tw-flex tw-flex-col tw-items-start tw-gap-4"}>
                        <Button onClick={downloadQR} loading={isLoading} disabled={tablesWithHall.isLoading}>
                            {tablesWithHall.data?.length ? t("download_qr_for_tables") : t("download_qr")}
                        </Button>

                        <div className={"tw-flex tw-items-center tw-gap-2"}>
                            <a href={baseUrl} target={"_blank"}>
                                {baseUrl}
                            </a>
                            <CIcon
                                path={iContentCopy}
                                className="pl-2 pr-2 tw-cursor-pointer copy-button"
                                onClick={copyToClipboard}
                            />
                            {isCopied && <TText tkey="data_copied" className={"tw-text-green-700"} />}
                        </div>
                    </div>
                </div>
            </Form.Group>
        </div>
    );
};
