import { t } from "lib/i18n";
import { useCallback } from "react";
import { CustomerModel, dummyCustomer } from "models/CustomerModel";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import CForm from "components/Form/CForm/CForm";
import TextAreaFormGroup from "components/Form/TextAreaFormGroup";
import { useData } from "hooks/useData";
import SelectFormGroup from "components/Form/SelectFormGroup";
import { Button, Col } from "rsuite";
import JsonView from "components/JsonView";
import { useCForm } from "hooks/useCForm";
import _ from "lodash";
import { Constants } from "config/constants";
import MaskInputFormGroup from "components/Form/MaskInputFormGroup";
import CustomerArrayField from "./form/CustomerArrayField";
import { v4 } from "uuid";
import { CustomerSchema } from "form-schemas/CustomerSchema";
import { useFieldArray } from "react-hook-form";
import CIcon from "components/CIcon";
import { iClose } from "assets/icons/Icons";
import CInputPhoneFormGroup from "components/Form/CInputPhoneFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import { parseCustomerDate } from "lib/customerDate/parseCustomerDate";
import { isValidCustomerDate } from "lib/customerDate/isValidCustomerDate";
import { formatCustomerDate } from "lib/customerDate/formatCustomerDate";
import { formatCustomerDateForSave } from "lib/customerDate/formatCustomerDateForSave";

interface PhoneField {
    id: string;
    value: string;
}

const CustomerEditPage = () => {
    const customerGroups = useData<ICustomerGroup[]>("customers-group", { params: { limit: 9999 } });
    const genderName = Constants.genderName;
    const form = useCForm({
        url: "customer",
        model2: CustomerSchema,
        dummyData: dummyCustomer,
        refine: (d: ICustomer) => {
            const date = parseCustomerDate(d.date_of_birth);

            return {
                ...d,
                phones: d.phones.map((phone: string) => ({ value: phone })),
                date_of_birth: isValidCustomerDate(date) ? formatCustomerDate(date) : null,
                addresses: d.addresses.map((address: string) => ({ address })),
            };
        },
        prepareForSubmit: d => ({
            ..._.pick(d, _.keys(CustomerModel().fields).concat("id")),
            cid: d.cid ? d.cid : v4(),
            phones: d.phones
                .filter((phone: PhoneField) => phone.value !== "") // if last phone is empty, remove it from the list, it is necessary for the backend. Otherwise, it deletes all phones.
                .map((phone: PhoneField) => phone.value),
            date_of_birth: _.isEmpty(d.date_of_birth) ? null : formatCustomerDateForSave(d.date_of_birth),
            addresses: d.addresses.map((d: any) => d.address),
        }),
    });

    const { control, getValues } = form.form;
    const { fields, append, remove } = useFieldArray({ control, name: "phones" });

    const handleAddPhoneNumberInput = useCallback(() => {
        const phones = getValues("phones");
        const hasPhone = phones.some((phone: PhoneField) => phone && phone.value === "");

        if (hasPhone) {
            return;
        }

        append({ value: "" });
    }, [getValues, append]);

    return (
        <div className="p-3">
            {useFormTitle(form.id, t("customer"))}
            <HeaderRight formSubmit={form} />
            <CForm noShadow={true} form={form.form} onSave={form.onSave}>
                <Col xs={24} sm={24} md={24} lg={12} className="pb-2">
                    <div className=" w-100 d-flex flex-column">
                        <TextFormGroup
                            name="name"
                            errorPlacement="rightEnd"
                            labelProps={{ className: "resp-hor-label" }}
                            className="d-inline-flex"
                            focus={true}
                        />
                        <TextFormGroup
                            name="email"
                            errorPlacement="rightEnd"
                            labelProps={{ className: "resp-hor-label" }}
                            className="d-inline-flex"
                        />
                        <CustomerArrayField
                            form={form}
                            name="addresses"
                            label={t("address_address")}
                            labelProps={{ className: "resp-hor-label" }}
                            className="d-inline-flex"
                        />
                        <div className="d-inline-flex rs-form-group">
                            <CInputPhoneFormGroup
                                name={"phone"}
                                className="d-inline-flex"
                                labelProps={{ className: "resp-hor-label" }}
                                compProps={{ defaultValue: getValues("phone") }}
                                // todo: styleingleri daha seliqeli etmek lazimdir, meselen, wrap etmek lazimdir divleri.
                                controlWrapperProps={{ style: { width: 300 } }}
                                label={t("phone")}
                                disabled={getValues("is_verified")}
                            />
                        </div>
                        <>
                            {fields?.map((field, i: number) => {
                                return (
                                    <div className="d-inline-flex rs-form-group" key={field.id}>
                                        <CInputPhoneFormGroup
                                            name={`phones.${i}.value`} // phones.${i} is not working because does not support flat field array. So, we need to use phones.${i}.value. DOCS: https://react-hook-form.com/docs/usefieldarray
                                            className="d-inline-flex"
                                            labelProps={{ className: "resp-hor-label" }}
                                            compProps={{ defaultValue: getValues("phones") }}
                                            // todo: styleingleri daha seliqeli etmek lazimdir, meselen, wrap etmek lazimdir divleri.
                                            controlWrapperProps={{ style: { width: 300 } }}
                                            label={i == 0 ? t("extra_phone_numbers") : " "}
                                        />
                                        <Button className="tw-ml-2" onClick={() => remove(i)}>
                                            <CIcon path={iClose} />
                                        </Button>
                                    </div>
                                );
                            })}
                            <div className="d-inline-flex rs-form-group">
                                <label className="resp-hor-label rs-form-control-label">
                                    {!fields.length ? t("extra_phone_numbers") : ""}
                                </label>
                                <Button appearance="link" className="tw-w-[300px]" onClick={handleAddPhoneNumberInput}>
                                    {t("add_phone_number")}
                                </Button>
                            </div>
                        </>
                        <TextAreaFormGroup
                            compProps={{ style: { width: "300px" } }}
                            name="description"
                            labelProps={{ className: "resp-hor-label" }}
                            className="d-inline-flex"
                        />
                        <SelectFormGroup
                            width="300px"
                            name={"group_id"}
                            data={customerGroups.data}
                            compProps={{ cleanable: true }}
                            labelProps={{ className: "resp-hor-label" }}
                            className="d-inline-flex"
                        />
                        <TextFormGroup
                            name={"code"}
                            type="text"
                            compProps={{ style: { width: "300px" } }}
                            labelProps={{ className: "resp-hor-label" }}
                            className="d-inline-flex"
                        />
                        <SelectFormGroup
                            width="300px"
                            name={"gender"}
                            data={_.map(genderName, (name, id) => ({ name, id: parseInt(id) }))}
                            compProps={{ cleanable: true }}
                            labelProps={{ className: "resp-hor-label" }}
                            className="d-inline-flex"
                        />
                        <MaskInputFormGroup
                            name={"date_of_birth"}
                            mask={"99.99.9999"}
                            labelProps={{ className: "resp-hor-label" }}
                            className="d-inline-flex"
                        />
                    </div>
                </Col>

                <JsonView />
            </CForm>
        </div>
    );
};

export default CustomerEditPage;
