import { iDragAndDrop } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import UploaderFormGroup from "components/Form/FileUploadFormGroup";
import { FIListItemProps } from "components/FormItems/FormItemsList";
import { GridButtonRemove } from "components/NewForm/Grid/GridButtonRemove";
import { GridInput } from "components/NewForm/Grid/GridInput";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { GridRadio } from "components/NewForm/Grid/GridRadio";
import { GridToggle } from "components/NewForm/Grid/GridToggle";
import { getUnitNames } from "config/constants";
import { productName } from "lib/data-utils";
import { toFixedMinMax } from "lib/utils";
import _ from "lodash";
import React, { memo, useState } from "react";
import { store } from "redux/store";
import { GridProductSelectItem } from "components/NewForm/Grid/GridProductSelectItem";
import { useCurrency } from "hooks/useCurrency";

interface IProps extends FIListItemProps<ModificationSingleItemField> {
    isDraggable?: boolean;
}

export interface ModificationSingleItemField {
    ingredient_id: number;
    name: string;
    full_name: string;
    images: any[];
    cost_price: number;
    unit: any;
    brutto: number;
    price: number;
    default_count: number;
    status: string;
    ingredient: IIngredient;
    unregister?: any;
}

const ModificationSingleItem = memo<IProps>(({ field, fields, itemsFieldName, index, remove, form, isDraggable }) => {
    const currency = useCurrency();
    const [selectedItem, setSelectedItem] = useState(field?.ingredient);
    const getName = (name: string, i?: number) => `${itemsFieldName}[${i ?? index}].${name}`;

    const onSelectModification = (id: number) => {
        const productsById = store.getState().data.products.byId;
        const item = productsById[id];

        setSelectedItem(item);

        form.setValue(getName("ingredient_id"), item.id);
        form.setValue(getName("images"), !_.isEmpty(item.media) ? [{ uuid: item.media?.[0]?.urls?.thumb }] : []);
        form.setValue(getName("cost_price"), item.ingredient?.cost_price || 0);
        form.setValue(getName("unit"), item.unit);
        form.setValue(getName("name"), productName(item));
        form.setValue(getName("brutto"), 0);
        form.setValue(getName("price"), 0);
    };

    const onChangeBrutto = (value: number) => {
        // form.setValue(getName("brutto"), value);
        form.setValue(getName("cost_price"), Number(value * (selectedItem?.ingredient?.cost_price ?? 0)));
    };

    const onCheckRadio = () => {
        fields.forEach((f, i) => {
            form.setValue(getName("default_count", i), 0);
        });
        form.setValue(getName("default_count"), 1);
    };

    const removeItem = (index: number) => {
        const isDefault = form.getValues(getName("default_count"));

        remove(index);

        const items = form.getValues(itemsFieldName);

        if (isDefault && items.length > 0) {
            form.setValue(getName("default_count", 0), 1);
        }
    };

    return (
        <>
            {isDraggable ? <CIcon path={iDragAndDrop} className="dragg-icon" /> : null}

            <GridProductSelectItem onSelect={onSelectModification} name={getName("ingredient_id")} />
            <GridInput name={getName("name")} index={index} />

            <UploaderFormGroup
                name={getName("images[0].uuid")}
                onRemove={() => form.setValue(getName("images"), [])}
                label={false}
                // uploadUrl="media"
                // fileInfoButtonsStyle={{ top: -10, right: 0 }}
                className="tw-justify-center tw-items-center tw-flex "
                style={{ marginBottom: 0 }}
                compProps={{
                    action: "media",
                    multiple: false,
                    listType: "picture",
                    name: "media_file",
                    // className: "tw-flex tw-flex-row",
                }}
                fileInfoButtonsStyle={{ position: "static", flexDirection: "row", alignItems: "center" }}
                buttonStyle={{
                    width: 30,
                    height: 30,
                    margin: 0,
                }}
                controlWrapperProps={{ className: "tw-flex tw-gap-2" }}
            />
            <GridInput
                name={getName("brutto")}
                index={index}
                type="number"
                decimals={[0, 4]}
                onChangeValue={onChangeBrutto}
                postfix={getUnitNames(field?.unit?.symbol)}
            />

            <GridRadio name={getName("default_count")} control={form.control} onChange={onCheckRadio} />

            <GridItemPlain
                testId={getName(`cost_price`)}
                value={toFixedMinMax(field.ingredient?.cost_price ?? 0, 2, 4)}
                align="right"
                postfix={currency}
            />

            <GridInput name={getName("price")} index={index} type="number" decimals={2} postfix={currency} />

            <GridToggle name={getName("status")} />

            <GridButtonRemove onClick={() => removeItem(index)} />
        </>
    );
});

export default ModificationSingleItem;
