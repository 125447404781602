import { CustomerDiscountType } from "@cloposcom/receipt-utils/dist/receipt-calculation/interfaces";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { FC } from "react";
import { useSelector } from "react-redux";
import { SelectPicker, Toggle } from "rsuite";
import { iAddUser2, iCLose2, iDollar, iMapMarker2, iStarOutline } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import { useData } from "hooks/useData";
import { recalculateReceipt, selectReceiptData, updateReceipt } from "redux/features/webCashier";
import { useCurrency } from "hooks/useCurrency";
import { useCalculationSetting } from "hooks/useCalculationSetting";
import { useAppDispatch } from "hooks/useRedux";

const renderCustomerPlaceholder = () => {
    return (
        <span>
            <CIcon path={iAddUser2} />
            {t("select", { name: t("customer") })}
        </span>
    );
};

const ReceiptCustomer: FC<any> = () => {
    const dispatch = useAppDispatch();
    const currency = useCurrency();
    const { receipt } = useSelector(selectReceiptData);
    const customers = useData("customer", {
        with: ["group", "balance", "bonuce", "venue_remains", "cashback_balance"],
        params: { limit: 999 },
    });

    const customer: any = receipt?.meta?.customer;
    const calculationSetting = useCalculationSetting();

    const changeDiscountValue = (value: boolean, discount_value: number) => {
        dispatch(
            updateReceipt({
                key: "customer_discount_type",
                value: value ? 1 : 0,
            }),
        );
        dispatch(
            updateReceipt({
                key: "discount_rate",
                value: value ? discount_value : 0,
            }),
        );
        dispatch(
            updateReceipt({
                key: "discount_type",
                value: 1,
            }),
        );
        dispatch(recalculateReceipt({ setting: calculationSetting }));
    };

    return (
        <div className="item">
            <div className="label">
                <TText tkey="customer" />
            </div>
            {customer ? (
                <div className="customer-card">
                    <div className="header">
                        <div className="name" test-id="customer_name">
                            {customer.name}
                        </div>
                        <div
                            className="close"
                            onClick={() => {
                                dispatch(updateReceipt({ key: "customer_id", value: null }));
                                dispatch(recalculateReceipt({ setting: calculationSetting }));
                                const meta = { ...receipt.meta };
                                delete meta.customer;
                                dispatch(updateReceipt({ key: "meta", value: meta }));
                                changeDiscountValue(false, 0);
                            }}
                        >
                            <CIcon path={iCLose2} />
                        </div>
                    </div>
                    <div className="balances">
                        <div className="cashback">
                            <div className="icon">
                                <CIcon path={iStarOutline} />
                            </div>
                            <div className="desc">
                                <div className="amount">
                                    {customer?.cashback} {currency}
                                </div>
                                <div className="type">
                                    <TText tkey="cashback" />
                                </div>
                            </div>
                        </div>
                        <div className="balance">
                            <div className="icon">
                                <CIcon path={iDollar} />
                            </div>
                            <div className="desc">
                                <div className="amount">
                                    {customer?.balance} {currency}
                                </div>
                                <div className="type">
                                    <TText tkey="balance" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="discount">
                        <div className="group">
                            <span>
                                {customer?.group?.name} {customer?.group?.discount_value + "%"}
                            </span>
                        </div>
                        <div className="checkbox">
                            <Toggle
                                checked={receipt?.customer_discount_type !== CustomerDiscountType.NONE}
                                onChange={value => changeDiscountValue(value, customer?.group?.discount_value)}
                            />
                        </div>
                    </div>
                    <div className="detail">
                        <div className="phone">{customer?.phones ? customer?.phones[0] : ""}</div>
                        <div className="email">{customer?.email}</div>
                        {customer?.addresses && (
                            <div className="address">
                                <CIcon path={iMapMarker2} />
                                <span>{customer?.addresses?.toString()}</span>
                            </div>
                        )}
                    </div>
                </div>
            ) : (
                <SelectPicker
                    data={customers?.data}
                    test-id="customer"
                    labelKey={"name"}
                    valueKey={"id"}
                    value={customer}
                    name="customer"
                    renderMenuItem={label => <span test-id="customer_item">{label}</span>}
                    onSelect={(value, item, event) => {
                        dispatch(dispatch(updateReceipt({ key: "customer_id", value: value })));
                        const { name, cashback_balance, balance, phones, description, code, group, addresses } = item;
                        const meta = {
                            ...receipt.meta,
                            ...{
                                customer: {
                                    name,
                                    bonus: 0,
                                    cashback: Math.floor(cashback_balance?.amount * 1000) / 1000,
                                    balance: Math.floor(balance?.amount * 1000) / 1000,
                                    phones,
                                    desc: description,
                                    code,
                                    group,
                                    addresses,
                                },
                            },
                        };

                        dispatch(updateReceipt({ key: "meta", value: meta }));
                        changeDiscountValue(true, group?.discount_value);
                    }}
                    placeholder={renderCustomerPlaceholder()}
                />
            )}
        </div>
    );
};

export default ReceiptCustomer;
