import { ASSETS_BASE_URL } from "config/main";
import React from "react";
import { Button, Tooltip, Whisper } from "rsuite";
import { iStarOutline } from "assets/icons/Icons";
import CIcon from "../CIcon";

interface IProps {
    id: number;
    description?: string;
    review?: { average: number };
    onClick?: (d?: any) => void;
    suspicion?: any;
}

const IdWithMisc: FCC<IProps> = ({ onClick, id, description, review, suspicion }) => {
    return (
        <>
            {/*<span className='pr-2'>{id}</span>*/}
            <Button
                style={{ color: `${suspicion ? "#e6bb1f" : ""}` }}
                appearance={"link"}
                className="p-0 pr-2"
                onClick={onClick}
            >
                {id}
            </Button>
            {description && (
                <Whisper placement="right" trigger="hover" speaker={<Tooltip>{description}</Tooltip>}>
                    <img alt="" src={`${ASSETS_BASE_URL}/img/comment-o.svg`} />
                </Whisper>
            )}
            {review && (
                <Whisper placement="right" trigger="hover" speaker={<Tooltip>{review.average}</Tooltip>}>
                    <CIcon path={iStarOutline} />
                </Whisper>
            )}
        </>
    );
};

export default IdWithMisc;
