import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "../../../components/DataTable/DataTable";
import { useData } from "hooks/useData";
import { toFixed } from "lib/utils";
import { useCurrency } from "hooks/useCurrency";
import { Trash } from "config/constants";

interface IProps {
    dataId?: any;
}

const StorageHistory: FCC<IProps> = ({ dataId }) => {
    const dp = useData(!!dataId && `data-state`, {
        params: { trash: Trash.TrashAndActive, limit: 999 },
        filters: [
            ["model_type", "STORAGE"],
            ["model_id", dataId],
        ] as any,
    });

    const currency = useCurrency();

    const cm = useColumns<any>(`storage-history`, [
        {
            title: t("date"),
            key: "updated_at",
            type: "datetime",
            reverse: true,
            align: "left",
            flexGrow: 3,
        },
        { title: t("amount"), key: "value", type: "money", render: e => `${toFixed(e.value, 2)} ${currency}` },
    ]);

    return (
        <div className="d-flex">
            <div className="flex-fill border-left">
                <DataTable hidePages noShadow columns={cm.columns} dp={{ data: dp.data } as any} />
            </div>
        </div>
    );
};

export default StorageHistory;
