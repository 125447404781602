import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { getLabel } from "components/Form/form-utils";
import RemoteSelectFormGroup from "components/Form/RemoteSelectFormGroup";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useDrawer } from "components/Drawers/useDrawer";
import { Message, toaster } from "rsuite";
import CForm from "components/Form/CForm/CForm";
import SelectFormGroup from "components/Form/SelectFormGroup";
import SubmitButtonGroup from "components/Form/SubmitButtonGroup";
import { useCForm } from "hooks/useCForm";
import CreateCategoryModal from "../categories/CreateCategoryModal";
import { withDrawer } from "hocs/withDrawer";

interface IProps extends ICDrawerProps {
    onSubmit?: any;
}

const EMenuBulkUpdateDrawer: FCC<IProps> = props => {
    const [isDisabled, setIsDisabled] = useState(false);
    const useForm = useCForm({
        url: "product/bulk",
        model: false,
        isModal: false,
        redirectUrl: false,
        afterSubmit: () => {
            props.onSubmit();
            toaster.push(
                <Message type="success" showIcon closable>
                    <TText tkey="success" />
                </Message>,
            );
        },
    });
    const createCategoryModalHook = useDrawer();
    const content = useForm.form.watch();
    useEffect(() => {
        useForm.set("prepareForSubmit", (data: any) => {
            data.category_id = data.category_id === 0 ? null : data.category_id === null ? undefined : data.category_id;
            data.station_id = data.station_id === 0 ? null : data.station_id === null ? undefined : data.station_id;

            return {
                ids: props.data,
                data: _.omitBy(data, d => d === undefined),
            };
        });
        // eslint-disable-next-line
    }, [props.data]);

    useEffect(() => {
        setIsDisabled(!_.map(_.omitBy(useForm.form.getValues(), _.isNil)).length);
        // eslint-disable-next-line
    }, [content]);

    return (
        <CDrawer title={t("bulk_update_drawer_title")} {...props}>
            <div className="tw-p-4">
                <CForm form={useForm.form} onSave={useForm.onSave} noShadow>
                    <SelectFormGroup
                        name={"emenu_hidden"}
                        label={t("show_on_emenu")}
                        data={[
                            { id: 0, name: t("show") },
                            { id: 1, name: t("hide") },
                        ]}
                    />
                    <RemoteSelectFormGroup
                        remote={{
                            params: { tree: 0 },
                            url: "category",
                            filters: {
                                type: [
                                    ["type", "EMENU"],
                                    ["type", "PRODUCT"],
                                ],
                            },
                        }}
                        name={"emenu_category_id"}
                        label={t("category")}
                        compProps={{
                            cleanable: true,
                            groupBy: "type",
                            renderMenuGroup: (_title: React.ReactNode, item: any) =>
                                `(${t("category")}) ${getLabel(item.groupTitle)}`,
                        }}
                    />
                    <div style={{ padding: 20, float: "right" }}>
                        <SubmitButtonGroup
                            isLoading={useForm.isLoading}
                            onSubmit={useForm.onSave}
                            disabled={isDisabled}
                        />
                    </div>
                </CForm>
            </div>
            <CreateCategoryModal {...createCategoryModalHook} mainForm={useForm.form} />
        </CDrawer>
    );
};

export default withDrawer(EMenuBulkUpdateDrawer);
