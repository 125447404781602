import { iPlus } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import CProductsDrawer from "components/Drawers/CProductSelector/CProductsDrawer";
import CForm from "components/Form/CForm/CForm";
import { FormItems } from "components/FormItems/FormItems";
import { FIHeaderProps } from "components/FormItems/FormItemsHeader";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { MassInsertButton } from "components/FormItems/MassInsertButton";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import JsonView from "components/JsonView";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { GridItemTotal } from "components/NewForm/Grid/GridItemTotal";
import { t } from "lib/i18n";
import { format } from "date-fns";
import { ReturnFromSupplySchema } from "form-schemas/ReturnFromSupplySchema";
import { useCForm } from "hooks/useCForm";
import { useAppDispatch } from "hooks/useRedux";
import Ajax from "lib/Ajax";
import _ from "lodash";
import { AddByBarcodeButton } from "pages/inventory/supplies/NewSupplyCreate/AddByBarcodeButton";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { dummyReturnSupplyItem, dummyReturnSupplyOperation } from "../../misc/utils";
import { ISupplyOperationItem } from "../../types";
import { SupplyItemField } from "../NewSupplyCreate/SupplyItem";
import ReturnSupplyGeneralTab from "../ReturnSupplyCreate/ReturnSupplyGeneralTab";
import { ReturnFromSupplyItem } from "./ReturnFromSupplyItem";
import { useCurrency } from "hooks/useCurrency";
import { setDisabledProductIds, setVisibleProductIds } from "redux/features/productsSelector/productsSelector";

const columns: FIHeaderProps["columns"] = [
    ["product_id"],
    ["unit_id"],
    ["quantity", "right"],
    ["cost", "right"],
    ["total", "right"],
    [""],
];

export type OperationItemQuantitiesType = Record<number, number>;

export const ReturnFromSupplyCreatePage = () => {
    const currency = useCurrency();
    const dispatch = useAppDispatch();
    const params = useParams<any>();
    const [operationItemQuantities, setOperationItemQuantities] = useState<OperationItemQuantitiesType>({});

    const form = useCForm({
        url: ({ dataFlowStage, id }) => (dataFlowStage === "GET_DATA" ? "operation/" + id : "product/supply-return"),
        with: [
            "operation_items.product",
            "operation_items.product.packages",
            "operation_items.unit",
            "transaction_finance",
            "transaction_finances",
            "supply_returns.products",
        ],
        mode: "all",
        methodOnSubmit: "post",
        model2: ReturnFromSupplySchema,
        dummyData: dummyReturnSupplyOperation,
        refine: (data: IOperation) => {
            const itemQuantities: OperationItemQuantitiesType = {};
            const ids =
                data.operation_items
                    ?.filter(item => !item.product?.deleted_at)
                    .map(item => {
                        itemQuantities[item.product_id] = item.quantity;
                        return item.product_id;
                    }) ?? [];

            // dispatch(
            //     setProductsCallback(p => {
            //         p.allIds = p.allIds.filter(id => ids.includes(id));
            //         p.byId = _.pick(p.byId, ids);
            //     }),
            // );

            dispatch(setVisibleProductIds(ids));

            setOperationItemQuantities(itemQuantities);

            return _.omit(
                {
                    ...data,
                    operated_at: new Date().toISOString(),
                    operated_at_default: new Date().toISOString(),
                    from_storage_id: data.storage_id,
                    items: [dummyReturnSupplyItem()],
                    description: data.description ?? "",
                },
                ["id", "operation_items"],
            );
        },
        prepareForSubmit: data => {
            const operated_at = format(new Date(data?.operated_at), "yyyy-MM-dd HH:mm:ss");
            const supplier_remain = data?.balance_id === "supplier_remain" ? 1 : 0;
            const postData = {
                operated_at,
                supplier_remain,
                supply_operation_id: Number(params.id),
                balance_id: data.balance_id,
                category_id: data.category_id,
                from_storage_id: data.from_storage_id,
                description: data.description,
                items: data.items,
            };

            if (data?.balance_id === "supplier_remain") {
                delete postData?.balance_id;
            }

            return postData;
        },
        afterSubmit: () => dispatch(setDisabledProductIds([])),
    });

    // disable selected prooducts from drawer
    const items = form.form.watch("items") ?? [];
    const itemIds = items.map((item: ISupplyOperationItem) => item.product_id || item.product?.id).join(",");

    useEffect(() => {
        if (itemIds) {
            const ids = itemIds.split(",").map(Number);

            dispatch(setDisabledProductIds(ids));
        }
    }, [itemIds]);

    useEffect(() => {
        return () => {
            dispatch(setVisibleProductIds([]));
        };
    }, []);

    return (
        <div className="tw-p-4 tw-h-full">
            {useTitle(t("return"))}
            <HeaderRight
                formSubmit={form}
                export={
                    form.id
                        ? () =>
                              Ajax.download(
                                  { url: `operation/excel-actions-for-supply/${form.id}` },
                                  `${t("supplies")} - ${form.id}`,
                              )
                        : undefined
                }
                // import={importProps}
            />
            <CForm {...form} formProps={{ layout: "vertical", fluid: true }}>
                <JsonView />

                <ReturnSupplyGeneralTab fromSupply={true} />

                <FormItems itemsFieldName="items">
                    <FormItemsList<SupplyItemField>
                        columns={columns}
                        virtualize
                        children={_props => {
                            return (
                                <ReturnFromSupplyItem {..._props} operationItemQuantities={operationItemQuantities} />
                            );
                        }}
                        getRowProps={rowData => {
                            return {
                                className: rowData.field.disabled ? "tw-bg-red-50/50 hover:tw-bg-red-50" : "",
                            };
                        }}
                        footer={
                            <div className="tw-grid tw-grid-cols-subgrid tw-col-span-9 tw-sticky tw-bottom-0 tw-bg-white tw-border-gray-50 tw-border-solid tw-border-0 tw-border-t-2  tw-py-2 tw-z-10">
                                <div className="tw-flex tw-col-span-3 tw-gap-2 ">
                                    <MassInsertButton
                                        idField="product_id"
                                        refineItem={product => {
                                            return dummyReturnSupplyItem({
                                                product_id: product.id,
                                                unit_id: product.unit_id,
                                                cost: product.cost_price,
                                                total_cost: 0,
                                                operation_item_quantity: operationItemQuantities[product.id],
                                            });
                                        }}
                                    >
                                        <CIcon path={iPlus} className="mr-2" />
                                        {t("add")}
                                    </MassInsertButton>
                                    {/* <FormItemsAddButton dummyData={dummySupplyItem} /> */}
                                    <AddByBarcodeButton />
                                </div>
                                <GridItemPlain
                                    align="right"
                                    className="tw-bg-transparent tw-border-none"
                                    value={t("total") + ":"}
                                />
                                <GridItemTotal name="total_cost" decimals={2} postfix={currency} />
                            </div>
                        }
                    />
                </FormItems>
            </CForm>
            <CProductsDrawer />
        </div>
    );
};
