import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";

export const WriteOffSchema: ValidationSchema = {
    storage_id: ruleset.required,
    reason_id: ruleset.required,
    items: [
        {
            product_id: ruleset.required,
            unit_id: ruleset.required,
            quantity: ruleset.combine([ruleset.required, ruleset.gt(0), ruleset.lte(Number.MAX_SAFE_INTEGER)]),
        },
    ],
};
