import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import _ from "lodash";
import Money from "components/mini/Money";
import { Colors } from "config/colors";
import { useData } from "hooks/useData";
import { money } from "lib/money";
import { useCurrency } from "hooks/useCurrency";

interface IProps {
    r: IReceipt;
}

const style = {
    color: Colors.PaleGray,
    border: `1.5px dashed ${Colors.PaleGray}`,
};

interface IReceiptPaymentMethod {
    id: number;
    amount: number | string;
    name?: string;
}

const ReceiptDetailSubInfo: FCC<IProps> = ({ r }) => {
    const pmDp = useData<IPaymentMethod[]>("finance/paymentMethod");
    const currency = useCurrency();

    const mapPaymentMethods = (pMethods: IReceiptPaymentMethod[]): IReceiptPaymentMethod[] => {
        return pMethods?.map((method: IReceiptPaymentMethod) => {
            const findMethod: IPaymentMethod | undefined = _.find(pmDp.data ?? [], { id: method.id });
            return {
                ...method,
                name: findMethod?.name,
            };
        });
    };

    return (
        <div>
            <div className="d-flex justify-content-between p-3 border-top">
                <div style={{ width: "50%" }}>
                    {/*<div className="d-flex align-items-center w-100 mt-3">*/}
                    {/*    <div className="d-flex align-items-center justify-content-between" style={{ width: "50%" }}>*/}
                    {/*        <p className="max-content"><TText key="subtotal" /></p>*/}
                    {/*        <span*/}
                    {/*            className="fill-available"*/}
                    {/*            style={{*/}
                    {/*                height: "1px",*/}
                    {/*                margin: "0 10px",*/}
                    {/*                color: "#EAECF0",*/}
                    {/*                border: "1.5px dashed #EAECF0",*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <p className="text-right font-weight-bold">*/}
                    {/*        <Money value={r.subtotal} />*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    {/* bura */}
                    <div className="d-flex   align-items-center w-100 mt-3">
                        <div className="d-flex align-items-center justify-content-between" style={{ width: "50%" }}>
                            <p className="fill-available">
                                <TText tkey="service_charge" />
                            </p>
                            <span
                                className="fill-available"
                                style={{
                                    height: "1px",
                                    margin: "0 10px",
                                    ...style,
                                }}
                            />
                        </div>
                        <p className="text-right font-weight-bold">
                            <Money value={r.service_charge_value} />
                        </p>
                    </div>

                    {r.delivery_fee > 0 && (
                        <div className="d-flex align-items-center w-100 mt-3">
                            <div className="d-flex align-items-center justify-content-between" style={{ width: "50%" }}>
                                <p className="fill-available">{t("delivery_fee")}</p>
                                <span
                                    className="fill-available"
                                    style={{
                                        height: "1px",
                                        margin: "0 10px",
                                        ...style,
                                    }}
                                />
                            </div>

                            <p className="text-right font-weight-bold">
                                <Money value={r.delivery_fee} />
                            </p>
                        </div>
                    )}

                    {r.loyalty_value && r.loyalty_type && (
                        <div className="d-flex   align-items-center w-100 mt-3">
                            <div className="d-flex align-items-center justify-content-between" style={{ width: "50%" }}>
                                <p className="fill-available">{r.loyalty_type}</p>
                                <span
                                    className="fill-available"
                                    style={{
                                        height: "1px",
                                        margin: "0 10px",
                                        ...style,
                                    }}
                                />
                            </div>

                            <p className="text-right font-weight-bold">
                                <Money value={r.loyalty_value} />
                            </p>
                        </div>
                    )}

                    {r.customer_id && (
                        <div className="d-flex   align-items-center w-100 mt-3">
                            <div className="d-flex align-items-center justify-content-between" style={{ width: "50%" }}>
                                <p className="fill-available">
                                    <TText tkey="customer" />
                                </p>
                                <span
                                    className="fill-available"
                                    style={{
                                        height: "1px",
                                        margin: "0 10px",
                                        ...style,
                                    }}
                                />
                            </div>

                            <p className="text-right font-weight-bold">{r.customer?.name}</p>
                        </div>
                    )}

                    {/*<div className="d-flex   align-items-center w-100 mt-3">*/}
                    {/*    <div className="d-flex align-items-center justify-content-between" style={{ width: "50%" }}>*/}
                    {/*        <p className="max-content"><TText key="discount" /></p>*/}
                    {/*        <span*/}
                    {/*            className="fill-available"*/}
                    {/*            style={{*/}
                    {/*                height: "1px",*/}
                    {/*                margin: "0 10px",*/}
                    {/*                color: "#EAECF0",*/}
                    {/*                border: "1.5px dashed #EAECF0",*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <p className="text-right font-weight-bold">*/}
                    {/*        {<Money value={r.discount_value + (r.rps_discount || 0)} />}*/}
                    {/*    </p>*/}
                    {/*</div>*/}
                    {/*<div className="d-flex  align-items-center w-100 mt-3">*/}
                    {/*    <div className="d-flex align-items-center justify-content-between" style={{ width: "50%" }}>*/}
                    {/*        <p className="fill-available"><TText key="tax_amount" /></p>*/}
                    {/*        <span*/}
                    {/*            className="fill-available"*/}
                    {/*            style={{*/}
                    {/*                height: "1px",*/}
                    {/*                margin: "0 10px",*/}
                    {/*                color: "#EAECF0",*/}
                    {/*                border: "1.5px dashed #EAECF0",*/}
                    {/*            }}*/}
                    {/*        />*/}
                    {/*    </div>*/}
                    {/*    <p className="text-right font-weight-bold">{<Money value={r.total_tax} />}</p>*/}
                    {/*</div>*/}
                    {/* <p className='font-weight-bold'><TText key="product_discount" /></p> */}
                    {/* <p className='font-weight-bold'><TText key="receipt_discount" /></p> */}
                </div>

                <div style={{ width: "50%" }}>
                    {/* <p className='text-right'>{<Money value={r.rps_discount}/>}</p> */}
                    {/* <p className='text-right'>{<Money value={r.discount_value}/>}</p> */}

                    {mapPaymentMethods(r.payment_methods).length ? (
                        <div className="d-flex align-items-start w-100 mt-3">
                            <div className="d-flex align-items-center justify-content-between" style={{ width: "50%" }}>
                                {mapPaymentMethods(r.payment_methods).length ? (
                                    <p className="fill-available">
                                        <TText tkey="payment_methods" />
                                    </p>
                                ) : null}
                                {mapPaymentMethods(r.payment_methods).length ? (
                                    <span
                                        className="fill-available"
                                        style={{
                                            height: "1px",
                                            ...style,
                                        }}
                                    />
                                ) : null}
                            </div>
                            <p style={{ minWidth: "121px" }} className="text-right font-weight-bold">
                                {_.concat(
                                    [],
                                    mapPaymentMethods(r.payment_methods)?.map(
                                        (method: IReceiptPaymentMethod) =>
                                            `${method.name}: ${money(method.amount, currency)}`,
                                    ),
                                ).join(" | ")}
                            </p>
                        </div>
                    ) : null}

                    <div className="d-flex justify-content-between align-items-center w-100 mt-3">
                        {r.fiscal_id && (
                            <p className="font-weight-bold">
                                <TText tkey="fiscal_id" />
                            </p>
                        )}

                        {r.fiscal_id && (
                            <span
                                style={{
                                    height: "1px",
                                    ...style,
                                }}
                            />
                        )}
                        {r.fiscal_id && <p className="text-right">{r.fiscal_id}</p>}
                    </div>
                    <div className="d-flex justify-content-start align-items-start w-100 ">
                        <div className="d-flex align-items-center justify-content-between" style={{ width: "100%" }}>
                            <p className="fill-available">
                                <TText tkey="info" />
                            </p>

                            <span
                                className="mx-3 fill-available"
                                style={{
                                    height: "1px",
                                    ...style,
                                }}
                            />
                        </div>
                        <p className="text-left font-weight-bold fill-available">
                            {`${t("receipt_opened_at")}: ${
                                _.reject([r.hall?.name, r.table?.name], _.isNil).join(",") || r.sale_type?.name
                            }`}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReceiptDetailSubInfo;
