import { t } from "lib/i18n";
import {
    addDays,
    differenceInDays,
    format,
    getDay,
    getMonth,
    getWeek,
    lastDayOfMonth,
    max,
    min,
    subDays,
} from "date-fns";
import { Point } from "highcharts";
import _ from "lodash";
import { h } from "./h";
import { money } from "lib/money";

export const PERIODS = ["daily", "weekly", "monthly"] as const;
export type PeriodsType = (typeof PERIODS)[number];
type DataType = "money" | "time" | "money-percentage" | "";

export interface IDatesReportData {
    // [key: string]: number | string;
    dateObj?: Date;
    dates?: [Date, Date];
    week?: number;
    weekDay?: number;
    hour?: number;
    month?: number;
    date: string;
    total_cash: number;
    unical_count: number;
    count: number;
    total_receipt: number;
    total_guests: number;
    average_cash: number;
    average_time: number;
    total_profit: number;
    payment_methods?: Array<{
        payment_method_id: number;
        operated_date: string;
        total_amount: number;
        payment_method: IPaymentMethod;
    }>;
}

export function prepareArrayWithRange(conf: {
    arr: IDatesReportData[];
    range: number;
    props: string[];
}): IDatesReportData[] {
    return _.range(0, conf.range).map(d => {
        const item = conf.arr?.find(i => i.hour === (d as any)) ?? createObjFromProps(conf.props);
        item.hour = d;
        return calculateAverage(item);
    });
}

export function prepareArrayWithDates(conf: {
    arr: IDatesReportData[];
    range: [Date, Date];
    props: string[];
}): IDatesReportData[] {
    const [start, end] = conf.range;
    const numberOfDays = differenceInDays(end, start);

    return _.range(0, numberOfDays + 1).map(d => {
        const cd = addDays(start, d);
        const currentDay = format(cd, "yyyy-MM-dd");
        const item = conf.arr?.find(i => i.date === currentDay) ?? createObjFromProps(conf.props);
        item.date = currentDay;
        item.dateObj = cd;
        item.week = getWeek(cd, { weekStartsOn: 1 });
        item.month = getMonth(cd);
        item.weekDay = getDay(cd);

        // item.weekDay = (getDay(cd) + 1) % 7;

        return calculateAverage(item);
    });
}

function calculateAverage(obj: IDatesReportData): any {
    return {
        ...obj,
        average_cash: obj.total_receipt && obj.total_cash ? Number((obj.total_cash / obj.total_receipt).toFixed(2)) : 0,
    };
}

const createObjFromProps = (props: string[]): IDatesReportData => {
    return _.zipObject(props, _.times(props.length, _.constant(0))) as any;
};

export const getTotals = (data: any[] = []): IDatesReportData => ({
    total_cash: _.sumBy(data, "total_cash"),
    unical_count: _.sumBy(data, "unical_count"),
    count: _.sumBy(data, "count"),
    total_profit: _.sumBy(data, "total_profit"),
    average_cash: _.meanBy(data, "average_cash"),
    total_receipt: _.sumBy(data, "total_receipt"),
    total_guests: _.sumBy(data, "total_guests"),
    average_time: _.meanBy(data, "average_time"),
    date: data[0]?.date,
    dates: [min(data.map(d => d.dateObj!)), max(data.map(d => d.dateObj!))],
});

export const groupByPeriod = (period: PeriodsType, data: IDatesReportData[] = []): IDatesReportData[] => {
    if (period === "daily") {
        return data;
    }
    const p = period === "monthly" ? "month" : "week";
    return _.map(_.groupBy(data, p), (subData, i) => getTotals(subData));
};

export const groupByWeekDay = (data: IDatesReportData[] = []): IDatesReportData[] => {
    return _.map(_.groupBy(data, "weekDay"), subData => {
        const totals = getTotals(subData);

        return {
            ...totals,
            weekDay: subData[0].weekDay,
        };
    });
};

export function getChartPointLabel(item: IDatesReportData, period: PeriodsType): string {
    const date = new Date(item.date);

    switch (period) {
        case "daily":
            return `${format(date, "MMM dd, yyyy")}`;
        case "weekly":
            return `${format(date, "MMM dd, E")} - ${t("sunday")}`;
        case "monthly":
            return `${format(date, "MMM dd")} - ${format(lastDayOfMonth(date), "dd")}`;
    }
}

export const formatByType = (
    type: DataType,
    value: number | undefined,
    currency: string,
    percentage?: number | undefined,
) => {
    switch (type) {
        case "money":
            return money(value, currency);
        case "money-percentage":
            return `${percentage?.toFixed(2)}% (${money(value, currency)})`;
        case "time":
            return h.formatTime(value?.toFixed(0));
        default:
            return value;
    }
};

export function getPointerLabel(pointer: Point, type: DataType, currency: string): string {
    return `<tr>
        <td style="padding-right: 5px"><span style="color: ${pointer.color};">${pointer.series.name}</span>: </td>
        <td style="text-align: right"><b>${formatByType(type, pointer.y, currency, pointer.percentage)}</b></td>
    </tr>`;
}

export function getPreviousRange(range: [string, string] = [null, null] as any): [string, string] {
    const [start, end] = range.map(d => new Date(d));
    const diff = Math.abs(differenceInDays(end, start)) + 1;

    return [format(subDays(start, diff), "yyyy-MM-dd"), format(subDays(start, 1), "yyyy-MM-dd")];
}

export function calcDiffPercentage(num1: number, num2: number) {
    if (num2 === 0) return 100;

    if (num1 > num2) {
        const increase: number = num1 - num2;
        return (increase / num2) * 100;
    }

    const decrease: number = num2 - num1;
    return -(decrease / num2) * 100;
}
