import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { arrayToNameId } from "lib/utils";

import { BrandType } from "types/routes";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

export interface SettingState {
    allSettings: ISettings;
    settingsLoading: boolean;
}

const initialState: SettingState = {
    allSettings: {} as ISettings,
    settingsLoading: true,
};

export const SettingSlice = createSlice({
    name: ReduxSlicesNames.SETTINGS,
    initialState,
    reducers: {
        setSettings: (state, action: PayloadAction<ISettings>) => {
            state.allSettings = action.payload;
            state.settingsLoading = false;
        },
        setSettingByField: (state, action: PayloadAction<Partial<ISettings>>) => {
            state.allSettings = { ...state.allSettings, ...action.payload };
        },
        setSettingsLoading: (state, action: PayloadAction<boolean>) => {
            state.settingsLoading = action.payload;
        },
    },
});
// create selector for all settings
export const selectSettings = (state: RootState) => state.settings.allSettings;
export const selectSetting =
    <K extends keyof ISettings>(name: K) =>
    (state: RootState): ISettings[K] =>
        selectSettings(state)[name];
export const selectSetting2 = <K extends keyof ISettings>(state: RootState, name: K): ISettings[K] =>
    selectSettings(state)[name];
export const selectIsShop = (state: RootState) => selectSetting2(state, "brand_type")?.value === BrandType.SHOP;

type KeysWithOptions<T> = {
    [K in keyof T]: T[K] extends { options: any } ? K : never;
}[keyof T];

export const selectSettingOptionsOf = createSelector(selectSetting2<KeysWithOptions<ISettings>>, s => {
    return arrayToNameId(s?.options || []);
});

export const { setSettings, setSettingByField, setSettingsLoading } = SettingSlice.actions;
export default SettingSlice.reducer;
