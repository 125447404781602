import { isAlpha, isBeta, PROTOCOL, STAGE, SUBDOMAIN, SUBDOMAIN_BASE, TLD } from "./main";
import { EventEmitter } from "eventemitter3";
import { depot } from "@cloposcom/libs";
import { refineUrl, sleep } from "lib/pure";

interface EventsMap {
    brandChange: (brand: string) => void;
    venueChange: (venueId: number) => void;
    baseUrlChange: (baseUrl: string) => void;
}

const APIStage: Record<string, string> = {
    alpha: "api-alpha",
    beta: "api-beta",
    rc: "api-rc",
    prod: "api",
    dev: "api-alpha",
};

export class Config extends EventEmitter {
    private _brand!: string;
    public venueId!: number;
    public brandStage!: string;
    //@ts-ignore
    on<T extends keyof EventsMap>(event: T, listener: EventsMap[T]): this;
    //@ts-ignore
    emit<T extends keyof EventsMap>(event: T, ...arg: Parameters<EventsMap[T]>): this;

    constructor() {
        super();
        this.init();
    }

    private async init() {
        this.setBrand(this.getBrand());
        await sleep(0);
        this.setVenueId(this.getVenueId());
    }

    public get brand() {
        return this._brand;
    }

    public getBrand(): string {
        const _brandName = window.location.pathname.split("/")[1];
        return _brandName !== "login" ? _brandName : "";
    }

    public setBrand(brand: string) {
        this._brand = brand;
        this.emit("brandChange", brand);
        this.emit("baseUrlChange", this.baseUrl);
    }
    public setBrandStage(stage: string) {
        this.brandStage = stage;
    }
    public setVenueId(venueId: number) {
        this.venueId = venueId;
        this.emit("venueChange", venueId);
        this.emit("baseUrlChange", this.baseUrl);
        if (this.brand) {
            window.history.replaceState({}, "", refineUrl(this.brand, this.venueId));
        }
    }

    public getVenueId(): number {
        let venueId = parseInt(window.location.pathname.split("/")[2]);

        if (isNaN(venueId)) {
            venueId = parseInt(depot.getItem("venueId") ?? "");
        }

        if (isNaN(venueId)) {
            venueId = 1;
        }

        return venueId;
    }

    public get baseUrl() {
        return `${this.brand}/${this.venueId}`;
    }

    public get brandUrl() {
        return `${PROTOCOL}//${SUBDOMAIN}.clopos.${TLD}/${this.baseUrl}`;
    }

    public get oldBrandUrl() {
        return `${PROTOCOL}//${this.brand}.clopos.${TLD}`;
    }

    public get qrMenuUrl() {
        return `${PROTOCOL}//${this.brand}.clopos.menu`;
    }

    public get terminalUrl() {
        return `https://${this.brand}.clopos.com/pos/${isAlpha || isBeta ? `index.${STAGE}.html` : ""}`;
    }

    public get apiUrl() {
        // if (!IS_NEW_URL && isDev && process.env.REACT_APP_API_URL) {
        //     return process.env.REACT_APP_API_URL; // ? process.env.REACT_APP_API_URL : `${PROTOCOL}//${this.brand}.clopos.test/api/`;
        // }
        return `https://${this.brand}.clopos.com/${APIStage[this.brandStage || STAGE]}/`;
    }

    public getStages() {
        if (isAlpha) {
            return [
                {
                    link: `${PROTOCOL}://${SUBDOMAIN_BASE}-beta.clopos.${TLD}/${this.brand}`,
                    name: "beta",
                },
                {
                    link: `${PROTOCOL}://${SUBDOMAIN_BASE}.clopos.${TLD}/${this.brand}`,
                    name: "prod",
                },
            ];
        } else if (isBeta) {
            return [
                {
                    link: `${PROTOCOL}://${SUBDOMAIN_BASE}-alpha.clopos.${TLD}/${this.brand}`,
                    name: "prod",
                },
            ];
        }

        return [];
    }

    getBrands() {
        const brands = new Set<string>();
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key?.startsWith("_client")) {
                const brand = key.split("_")[2];
                if (brand) {
                    brands.add(brand);
                }
            }
        }
        return Array.from(brands);
    }
}

export const config = new Config();

(window as any).config = config;
