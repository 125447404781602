import { t } from "lib/i18n";
import * as _ from "lodash";
import { toaster } from "rsuite";
import React, { useEffect, useState } from "react";
import { useTitle } from "components/Header/Title";
import HeaderRight from "components/Header/HeaderRight";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import IntegrationApp from "./IntegrationApp";
import "./IntegrationPage.scss";
import IntegrationModal from "./IntegrationModal";
import { useDrawer } from "components/Drawers/useDrawer";
import Ajax from "lib/Ajax";
import { useLoading } from "hooks/useLoading";
import { IntegrationConnectModal } from "./IntegrationConnectModal";
import { IntegrationErrorModal } from "./IntegrationErrorModal";
import IntegrationCategories from "./IntegrationCategories";
import { setIntegrations } from "redux/features/packages";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { useData } from "hooks/useData";
import { MessageError } from "components/Message";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";

const IntegrationSettingsPage: FCC = () => {
    const { hasAbility } = useAuth();

    const hasModulesPermission = hasAbility(["module_manage"]);
    const { isLoading, withLoading } = useLoading();
    const integrationModal = useDrawer();
    const modal = useDrawer();
    const errorModal = useDrawer();
    const [currentModuleId, setCurrentModuleId] = useState<number | null>(null);
    const [categoryList, setCategoryList] = useState<LangKey[]>([]);
    const [activeCategory, setActiveCategory] = useState<LangKey>("all");
    const [modules, setModules] = useState<IModule[]>([]);
    const dispatch = useAppDispatch();

    const partner: IPartner = useAppSelector(state => state.app.partner);

    const onConnect = (modul: IModule) => {
        withLoading(() =>
            Ajax.put({
                url: `brand/subscription/subscribeModule/${modul.id}`,
                customError: (e: CustomError) => {
                    if (e.code === 2031) {
                        const message = t("contact_our_support", {
                            modul: t(modul.name) || modul.title,
                        });
                        errorModal.setData({
                            messages: [`${message}.`, `${t("phone_number")}: ${partner?.number}`],
                        });
                    } else {
                        toaster.push(MessageError(e.response?.data?.message || e.message || e.type), {
                            duration: 4000,
                        });
                    }
                },
            }),
        ).then(res => {
            dispatch(setIntegrations(res?.data?.integration));
            modal.hide();
            dp.revalidate();
            if (integrationModal.isOpen) {
                integrationModal.setData(res.data);
            }
        });
    };

    const connectToModule = (d: IModule) => {
        setCurrentModuleId(d.id);
        if (d.price) {
            modal.setData(d);
        } else {
            onConnect(d);
        }
    };

    const dp = useData("brand/subscription/module", {
        with: ["integrations"],
    });

    useEffect(() => {
        if (dp?.data) {
            const categories: Record<
                string,
                {
                    slug: LangKey;
                    count: number;
                }
            > = {};

            const data: IModule[] = _.cloneDeep(dp?.data);

            setModules(data);

            data.forEach((module: IModule) => {
                module.categories?.forEach((category: IModuleCategory) => {
                    if (!categories[category.slug]) {
                        categories[category.slug] = {
                            slug: category.slug,
                            count: 0,
                        };
                    }
                    categories[category.slug].count++;
                });
            });

            setCategoryList([
                ...(["all", "installed_modules"] as const),
                ..._.orderBy(Object.values(categories), ["count"], ["desc"])
                    .slice(0, 5)
                    .map(cat => cat.slug),
            ]);
        }
    }, [dp?.data]);

    const changeCategory = (category: LangKey) => {
        setActiveCategory(category);
    };

    const getFilteredData = (data: IModule[]) => {
        if (activeCategory === "all") {
            return data;
        }

        if (activeCategory === "installed_modules") {
            return data.filter((m: IModule) => m?.integration?.id);
        }

        return data.filter((m: IModule) =>
            m?.categories
                ? m?.categories.findIndex((category: IModuleCategory) => category.slug === activeCategory) !== -1
                : false,
        );
    };

    return (
        <div className="h-100">
            {useTitle(t("modules"))}
            <HeaderRight />
            <IntegrationCategories
                activeCategory={activeCategory}
                categoryList={categoryList}
                setActiveCategory={category => changeCategory(category)}
            />
            <div className="p-3 integration-container" id={"Integration"}>
                <TransitionGroup component={null}>
                    {getFilteredData(modules || []).map((module: IModule) => (
                        <CSSTransition key={module.id} timeout={250} classNames="fade">
                            <IntegrationApp
                                activeCategory={activeCategory}
                                key={module.id}
                                isLoading={isLoading && currentModuleId === module.id}
                                modulesPermission={hasModulesPermission}
                                integrationModal={integrationModal}
                                connectToModule={d => connectToModule(d)}
                                module={module}
                            />
                        </CSSTransition>
                    ))}
                </TransitionGroup>
            </div>

            {integrationModal.isOpen && (
                <IntegrationModal
                    {...integrationModal}
                    modulesPermission={hasModulesPermission}
                    isLoading={isLoading}
                    connectToModule={d => connectToModule(d)}
                />
            )}

            {modal.isOpen && (
                <IntegrationConnectModal
                    {...modal}
                    onConnect={() => {
                        onConnect(modal.data);
                    }}
                />
            )}
            <IntegrationErrorModal {...errorModal} />
        </div>
    );
};

export default IntegrationSettingsPage;
