import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";

import { taxStatus } from "config/constants";
import CreateButton from "../../../components/DataTable/CreateButton";
import React from "react";

const TaxesPage: FCC = props => {
    const dp = useDataProvider({
        url: "finance/taxes",
        useUrl: true,
    });

    const cm = useColumns<any>("finance/taxes", [
        { key: "name", align: "left" },
        { key: "type", align: "left", render: t => t.type?.name },
        {
            key: "calculation_type",
            align: "left",

            render: d => (d.calculation_type === 1 ? t("percentage") : t("amount")),
        },
        { key: "rate", title: t("percentage"), align: "left", render: t => t?.rate + "%" },
        { key: "status", align: "left", render: t => taxStatus[t.status] },
        { key: "priority", align: "left" },
        {
            key: "created_at",
            align: "left",
            multiline: true,
            showSeconds: true,
            type: "datetime",
        },
        optionsColumn({
            dp,
            edit: "/settings/taxes/edit/:id",
            delete: "finance/taxes/:id",
            preventDelete: d => !!d.is_system,
            canDelete: "tax_delete",
            canEdit: "tax_edit",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("taxes"), dp.total)}
            <HeaderRight dp={dp} cm={cm} reload print />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="name" />
                </div>
                <CreateButton to="/settings/taxes/create" canCreate={"tax_create"} />
            </FiltersContainer>

            <DataTable dp={dp} columns={cm.columns} />
        </div>
    );
};

export default TaxesPage;
