import { allowedTypesForChangeFontSize, fontSizeOptions, textForDADTitle } from "../utils";
import { SelectPicker } from "rsuite";
import { IReceiptItem } from "../../types";
import { useAppDispatch } from "hooks/useRedux";
import { handleChangeTextSizeAC } from "redux/features/constructor/constructor-thunks";

const RDefaultComp = (item: IReceiptItem) => {
    const dispatch = useAppDispatch();

    return (
        <div className="d-flex align-items-center justify-content-between mr-2">
            {textForDADTitle[item.type]}
            {allowedTypesForChangeFontSize.includes(item.type) && (
                <SelectPicker
                    cleanable={false}
                    onSelect={v => dispatch(handleChangeTextSizeAC(v, item))}
                    value={item.textSize}
                    data={fontSizeOptions}
                    size="sm"
                    placement="autoVertical"
                    searchable={false}
                />
            )}
        </div>
    );
};
export default RDefaultComp;
