import React, { useEffect, useRef } from "react";

interface IProps {
    setHeight: (o: any) => void;
    height: number;
    id: number;
}

const EXTRA_HEIGHT = 46 + 10;

const ExpandedRowView: FCC<IProps> = ({ id, height, setHeight, children }) => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current) {
            const h = ref.current.getBoundingClientRect().height + EXTRA_HEIGHT;
            if (h !== height) {
                setHeight((hs: any) => ({ ...hs, [id]: h }));
            }
        }
    });

    useEffect(() => {
        if (!ref.current) {
            return;
        }
        const config = { attributes: true, childList: true, subtree: true };

        // Callback function to execute when mutations are observed
        const callback = function (mutationsList: any, observer: any) {
            // Use traditional 'for loops' for IE 11
            for (const mutation of mutationsList) {
                if (mutation.type === "childList" && ref.current) {
                    const h = ref.current.getBoundingClientRect().height + EXTRA_HEIGHT;
                    if (h !== height) {
                        setHeight((hs: any) => ({ ...hs, [id]: h }));
                    }
                }
            }
        };

        const observer = new MutationObserver(callback);
        observer.observe(ref.current, config);

        return () => observer.disconnect();
    }, [ref, height, id, setHeight]);

    return (
        <div style={{ height: height - EXTRA_HEIGHT }}>
            <div ref={ref} className="p-3">
                {children}
            </div>
        </div>
    );
};

export default ExpandedRowView;
