import { getLabel } from "components/Form/form-utils";
import Money from "components/mini/Money";
import _ from "lodash";

interface IProps {
    finances: any;
}

const CustomerFinances: FCC<IProps> = ({ finances }) => {
    return (
        <div className="p-4 d-flex justify-content-around">
            {_.map(finances, (finance: any, index: any) => (
                <div>
                    <p>{getLabel(index)}</p>
                    <h5 className="font-weight-normal tw-text-[#475f7b]">
                        {["spent", "total_discount"].includes(index) ? <Money value={finance} /> : finance}
                    </h5>
                </div>
            ))}
        </div>
    );
};

export default CustomerFinances;
