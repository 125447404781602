import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import { useDataProvider } from "components/DataTable/useDataProvider";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { FC, useEffect } from "react";

interface IProps extends ICDrawerProps {
    date: string[];
}

const TransactionsDrawer: FC<IProps> = props => {
    const dp = useDataProvider({
        stop: !props.dataId,
        url: "finance/transaction",
        params: { date: props.date },
        with: ["balance", "user"],
    });

    useEffect(() => {
        dp.filters.add(["staff_id", props.dataId!], "staff");
    }, [props.dataId]);

    const cm = useColumns<ITransaction>("finance/transaction-drawer", [
        {
            key: "operated_at",
            type: "datetime",
            align: "left",
            flexGrow: 0.8,
            title: t("operation"),
            multiline: true,
            reverse: true,
        },
        {
            key: "amount",
            type: "money",
            flexGrow: 0.8,
        },
        {
            key: "account",
            flexGrow: 0.8,
            render: e => <span>{e.balance?.name}</span>,
        },
        {
            key: "transaction_by",
            flexGrow: 0.8,
            render: e => <span>{e.user?.username}</span>,
        },
        {
            key: "description",
            flexGrow: 2,
        },
    ]);

    return (
        <CDrawer
            {...props}
            testId="payroll-transactions-drawer"
            size="lg"
            title={props.data?.username ? `${t("transactions")} : ${props.data.username}` : t("transactions")}
        >
            <div className="">
                <DataTable noShadow dp={dp} columns={cm.columns} />
            </div>
        </CDrawer>
    );
};

export default TransactionsDrawer;
