import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";

export const SupplySchema: ValidationSchema = {
    supplier_id: ruleset.required,
    storage_id: ruleset.required,
    __finance__: {
        category_id: ruleset.required,
        balance_id: ruleset.required,
    },
    items: [
        {
            product_id: ruleset.required,
            unit_id: ruleset.required,
            quantity: ruleset.combine([ruleset.required, ruleset.gt(0), ruleset.lte(Number.MAX_SAFE_INTEGER)]),
            cost: ruleset.combine([ruleset.required, ruleset.gte(0), ruleset.lte(Number.MAX_SAFE_INTEGER)]),
            discount_rate: ruleset.combine([ruleset.gte(0), ruleset.lte(100)]),
            tax_rate: ruleset.combine([ruleset.gte(0), ruleset.lte(100)]),
            total_cost: ruleset.combine([ruleset.gte(0), ruleset.lte(Number.MAX_SAFE_INTEGER)]),
        },
    ],
};
