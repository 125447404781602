import { TText } from "components/i18n/TText";
import { Tooltip, Whisper } from "rsuite";

import { Colors } from "config/colors";

interface IProps {
    storage: IStorage;
    activeStorages: number[];
}

const StorageWithStatus: FCC<IProps> = ({ storage, activeStorages }) => {
    return (
        <div className="d-flex align-items-center">
            {/* <Badge
                style={{ background:  "#28a745" : "#dc3545", marginRight: 5 }}
                content={
                    activeStorages.includes(storage?.id) ? (

                    ) : (
                        t("inactive")
                    )
                }
            /> */}
            {activeStorages.includes(storage?.id) ? (
                <Whisper
                    placement="topEnd"
                    trigger="hover"
                    speaker={
                        <Tooltip>
                            <TText tkey="active" />
                        </Tooltip>
                    }
                >
                    <div
                        className="d-flex justify-content-center rounded-circle align-items-center"
                        style={{ padding: "2px", background: Colors.MintGreen }}
                    >
                        <div
                            style={{
                                width: "8px",
                                height: "8px",
                                borderRadius: "100%",
                                background: Colors.EmeraldGreen,
                            }}
                        />
                    </div>
                </Whisper>
            ) : (
                <Whisper
                    placement="topEnd"
                    trigger="hover"
                    speaker={
                        <Tooltip>
                            <TText tkey="inactive" />
                        </Tooltip>
                    }
                >
                    <div
                        className="d-flex justify-content-center rounded-circle align-items-center"
                        style={{ padding: "2px", background: Colors.SoftPeach }}
                    >
                        <div
                            style={{ width: "8px", height: "8px", borderRadius: "100%", background: Colors.ChiliRed }}
                        />
                    </div>
                </Whisper>
            )}
            <div style={{ marginLeft: "13px" }}>{storage?.name}</div>
        </div>
    );
};

export default StorageWithStatus;
