import { t } from "lib/i18n";
import { translateUnit } from "lib/utils";
import React, { memo, ReactNode } from "react";
import { productName } from "lib/data-utils";
import { Tooltip, Whisper } from "rsuite";
import { getLabel } from "components/Form/form-utils";
import IconDishComponent from "../../../assets/img/productTypeIcons/IconDishComponent";
import IconPreparationComponent from "../../../assets/img/productTypeIcons/IconPreparationComponent";
import IconIngredientComponent from "../../../assets/img/productTypeIcons/IconIngredientComponent";
import IconGoodsComponent from "../../../assets/img/productTypeIcons/IconGoodsComponent";
import { Colors } from "config/colors";

const size = 25;
const color = Colors.VibrantSkyBlue;

const iconByType: { [key: string]: ReactNode } = {
    DISH: <IconDishComponent color={color} size={size} />,
    PREPARATION: <IconPreparationComponent color={color} size={size} />,
    INGREDIENT: <IconIngredientComponent color={color} size={size} />,
    MODIFICATION: <IconGoodsComponent color={color} size={size} />,
    GOODS: <IconGoodsComponent color={color} size={size} />,
};

const ProductNameArea = memo(({ product }: { product: IProduct }) => {
    const name = productName(product);

    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            {product?.type && (
                <div style={{ marginRight: 10 }}>
                    <Whisper placement="top" trigger="hover" speaker={<Tooltip>{getLabel(product.type)}</Tooltip>}>
                        <div>{iconByType[product.type]}</div>
                    </Whisper>
                </div>
            )}
            <Whisper placement="top" trigger="hover" speaker={<Tooltip>{name}</Tooltip>}>
                <p>{name}</p>
            </Whisper>
        </div>
    );
});

export const inventoryCheckFirstStepColumns: Array<ITableColumnProps> = [
    {
        title: t("data_id"),
        width: 25,
        className: "text-left sticky-td",
        readOnly: true,
        testId: "inv-data_id",
        key: "product_id",
        sortable: true,
        render: (d: any) => <b>{d?.product_id}</b>,
    },
    {
        title: t("product"),
        width: 60,
        className: "text-left sticky-td",
        readOnly: true,
        testId: "inv-product",
        key: "name",
        sortable: true,
        render: (d: any) => <ProductNameArea product={d?.product} />,
    },
    {
        title: t("category"),
        width: 30,
        className: "text-left sticky-td",
        readOnly: true,
        testId: "inv-category",
        key: "category",
        sortable: true,
        hide: true,
        render: (d: any) => d?.category?.name ?? "-",
    },
    {
        title: t("actual_balance"),
        width: 30,
        className: "text-right sticky-top",
        type: "qty",
        testId: "inv-actual_balance",
        disabled: (status: number) => status !== 2,
        key: `quantity`,
        inputType: "money",
        suffix: (d: any) => translateUnit(d?.product?.unit)?.symbol,
        render: (d: any) => d?.quantity?.toFixed(2) + " " + translateUnit(d?.product?.unit)?.symbol,
    },
    {
        title: t("comment"),
        width: 100,
        testId: "inv-comment",
        className: "text-right sticky-top",
        disabled: (status: number) => status !== 2,
        key: `comment`,
        inputType: "text",
        render: (d: any) => d?.comment ?? "-",
    },
];

export const inventoryCheckSecondStepColumns = (
    currency: any,
    ingrdContainsDrawerState?: any,
    transfersDrawerState?: any,
): Array<ITableColumnProps> => [
    {
        title: t("data_id"),
        width: 50,
        className: "text-left sticky-td",
        readOnly: true,
        key: "product_id",
        sortable: true,
        render: (d: any) => <b>{d?.product_id}</b>,
    },
    {
        title: t("category"),
        width: 90,
        className: "text-left sticky-td",
        readOnly: true,
        key: "category",
        sortable: true,
        hide: true,
        render: (d: any) => d?.category?.name ?? "-",
    },
    {
        title: t("product"),
        width: 180,
        className: "text-left sticky-td",
        readOnly: true,
        key: "name",
        sortable: true,
        render: (d: any) => <ProductNameArea product={d?.product} />,
    },

    {
        title: t("last_inventory_check"),
        width: 90,
        className: "text-right sticky-top",
        readOnly: true,
        type: "datetime",
        key: "last_inventory_check",
        render: (d: any) => d?.product?.last_inventory_check ?? "-",
    },
    {
        title: t("initial_balance"),
        width: 90,
        className: "text-right sticky-top",
        key: "initial_quantity",
        type: "qty",
        readOnly: true,
        render: (d: any) =>
            (d?.product?.initial_quantity ?? 0)?.toFixed(2) + " " + translateUnit(d?.product?.unit)?.symbol,
    },
    {
        title: t("check_supplies"),
        width: 90,
        readOnly: true,
        className: "text-right sticky-top",
        type: "qty",
        key: "supply_quantity",
        render: (d: any) =>
            (d?.product?.supply_quantity ?? 0)?.toFixed(2) + " " + translateUnit(d?.product?.unit)?.symbol,
    },
    {
        title: t("check_sales"),
        width: 90,
        readOnly: true,
        className: "text-right sticky-top",
        type: "qty",
        key: "sell_quantity",
        render: (d: any) =>
            (d?.product?.sell_quantity ?? 0)?.toFixed(2) + " " + translateUnit(d?.product?.unit)?.symbol,
    },
    {
        title: t("waste_qty"),
        width: 90,
        className: "text-right sticky-top",
        readOnly: true,
        type: "qty",
        key: "waste_quantity",
        render: (d: any) =>
            (d?.product?.waste_quantity ?? 0)?.toFixed(2) + " " + translateUnit(d?.product?.unit)?.symbol,
    },
    {
        title: t("waste_cost"),
        width: 90,
        className: "text-right sticky-top",
        readOnly: true,
        type: "money",
        key: "waste_cost",
        render: (d: any) => (d?.product?.waste_cost ?? 0) + " " + currency,
    },
    {
        title: t("transfer"),
        width: 90,
        className: "text-right sticky-top",
        key: "transfer",
        type: "qty",
        readOnly: true,
        render: (d: any) =>
            ((d?.product?.transfer_in ?? 0) - (d?.product?.transfer_out ?? 0))?.toFixed(2) +
            " " +
            translateUnit(d?.product?.unit)?.symbol,
        onClick: (p: any) => transfersDrawerState?.setData(p),
    },
    // {
    //     title: t("stock_adjustment"),
    //     width: 90,
    //     className: "text-right sticky-top",
    //     readOnly: true,
    //     type: "qty",
    //     key: "fixation",
    //     render: (d: any) => (d?.product?.fixation ?? 0)?.toFixed(2) + " " + translateUnit(d?.product?.unit)?.symbol,
    // },
    {
        title: t("sale_return_quantity"),
        width: 90,
        className: "text-right sticky-top",
        readOnly: true,
        hide: true,
        type: "qty",
        key: "return_quantity",
        render: (d: any) =>
            (d?.product?.return_quantity ?? 0)?.toFixed(2) + " " + translateUnit(d?.product?.unit)?.symbol,
    },
    {
        title: t("supply_total_return"),
        width: 90,
        className: "text-right sticky-top",
        readOnly: true,
        hide: true,
        type: "qty",
        key: "supply_return_quantity",
        render: (d: any) =>
            (d?.product?.supply_return_quantity ?? 0)?.toFixed(2) + " " + translateUnit(d?.product?.unit)?.symbol,
    },
    {
        title: t("make_usage_quantity"),
        width: 70,
        className: "text-right sticky-top",
        readOnly: true,
        hide: true,
        type: "qty",
        key: "make_usage_quantity",
        render: (d: any) =>
            (d?.product?.make_usage_quantity ?? 0) + " " + (translateUnit(d?.product?.unit)?.symbol ?? "-"),
    },
    {
        title: t("expected_balance"),
        width: 90,
        readOnly: true,
        className: "text-right sticky-top",
        type: "qty",
        key: "expected_quantity",
        render: (d: any) =>
            (d?.product?.expected_quantity ?? 0)?.toFixed(2) + " " + translateUnit(d?.product?.unit)?.symbol,
    },
    {
        title: t("actual_balance"),
        width: 90,
        className: "text-right sticky-top editable",
        type: "qty",
        key: `quantity`,
        inputType: "money",
        disabled: (status: number) => status !== 2,
        suffix: (p: any) => translateUnit(p?.product?.unit)?.symbol,
    },
    {
        title: t("actual_balance_prepack"),
        width: 90,
        className: "text-right sticky-top",
        type: "qty",
        key: "prepacked_quantity",
        readOnly: true,
        render: (d: any) => (d?.prepacked_quantity ?? 0)?.toFixed(4) + " " + translateUnit(d?.product?.unit)?.symbol,
        onClick: (p: any) => ingrdContainsDrawerState?.setData(p),
    },
    {
        title: t("actual_balance_sum"),
        width: 90,
        className: "text-right sticky-top",
        type: "qty",
        key: "total_quantity",
        readOnly: true,
        suffix: (p: any) => translateUnit(p?.product?.unit)?.symbol,
    },
    {
        title: `${t("average_cost")}, ${currency}`,
        width: 90,
        className: "text-right sticky-top",
        readOnly: true,
        hide: true,
        type: "money",
        key: "cost_price",
        render: (d: any) => (d?.cost_price ?? 0) + " " + currency,
    },
    {
        title: `${t("last_cost")}, ${currency}`,
        width: 90,
        className: "text-right sticky-top",
        readOnly: true,
        hide: true,
        type: "money",
        key: "last_cost",
        render: (d: any) => (d?.product?.cost_price || d?.cost_price) ?? 0 + " " + currency,
    },
    {
        title: t("total_cost") + ", " + currency,
        width: 90,
        className: "text-right sticky-top",
        type: "money",
        key: "total_cost",
        readOnly: true,
        render: (d: any) => (d?.total_cost ?? 0) + " " + currency,
    },
    {
        title: t("diff_qty"),
        width: 90,
        className: "text-right sticky-top",
        readOnly: true,
        type: "qty",
        key: "difference_quantity",
        suffix: (p: any) => translateUnit(p?.product?.unit)?.symbol,
    },
    {
        title: `${t("diff")}, ${currency}`,
        width: 90,
        className: "text-right sticky-top",
        readOnly: true,
        type: "money",
        key: "difference_cost",
        suffix: () => currency,
    },

    {
        title: t("comment"),
        width: 90,
        className: "text-right sticky-top",
        disabled: (status: number) => status !== 2,
        key: `comment`,
        inputType: "text",
        render: (d: any) => d?.comment ?? "-",
    },
];
