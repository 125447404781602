import { depot } from "@cloposcom/libs";
import * as Sentry from "@sentry/browser";
import { config } from "config/Config";
import { useLoading } from "hooks/useLoading";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { redirectToBrandStage } from "layout/LoginLayout/BrandInput";
import LoginLayout from "layout/LoginLayout/LoginLayout";
import UserLayout from "layout/UserLayout";
import Ajax from "lib/Ajax";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { setBrandData, setPartner } from "redux/features/app";
import "rsuite/dist/rsuite.min.css";
import { useInitializeSettings } from "hooks/useInitializeSettings";
import { useSentryInitialize } from "hooks/useSentryInitialize";
import { Nullable } from "types/Nullable";
import { setCurrentLang, setLangsList } from "redux/features/lang";
import { I18N } from "lib/i18n";
import { getDefaultLang } from "lib/getDefaultLang";
import { captureMessage } from "@sentry/react";
import { LoadingScreen } from "core/components/LoadingScreen";

export const App: FCC = () => {
    const auth = useAuth();
    const dispatch = useAppDispatch();
    const { current: lang, list: availableLangsList, isLoading: isLangLoading } = useAppSelector(state => state.lang);
    const currentUrl = window.location.href;
    const { currentVenueId } = useAppSelector(state => state.venue);
    const checkLoader = useLoading(true);
    const isSettingsLoading = useAppSelector(state => state.settings.settingsLoading);

    useEffect(() => {
        (async () => {
            try {
                dispatch(setLangsList(await I18N.getAvailableLangs()));
            } catch (e: any) {
                captureMessage(e.message, { extra: { message: e.cause } });
            }
        })();
    }, []);

    useEffect(() => {
        const onFocus = () => {
            if (currentVenueId) {
                depot.setItem("lastActiveVenue", currentVenueId);
            }
        };
        // for save selectedVenue in new tab
        window.addEventListener("focus", onFocus);

        return () => window.removeEventListener("focus", onFocus);
    }, [currentUrl]);

    useInitializeSettings();
    useSentryInitialize();

    useEffect(() => {
        checkLoader.withLoading(async () => {
            const token = new URL(currentUrl).searchParams.get("token");

            if (token) {
                await checkRemoteLogin(token);
            }

            const brandInfo: Nullable<any> = config.brand ? await getBrandInfo() : null;

            if (brandInfo === null) {
                if (lang === null) {
                    dispatch(setCurrentLang(getDefaultLang(availableLangsList))).then(I18N.removeSavedLocalLang);

                    return;
                }

                dispatch(setCurrentLang(lang));

                return;
            }

            dispatch(setCurrentLang(lang ?? brandInfo.language));
            dispatch(setPartner(brandInfo.partner));
            dispatch(setBrandData(brandInfo));

            redirectToBrandStage(brandInfo.stage);
        });
        // eslint-disable-next-line
    }, [currentUrl]);

    useEffect(() => {
        if (!config.brand) {
            window.history.replaceState({}, "", "/login");
        }
    }, [config.brand]);

    const getBrandInfo = async (): Promise<any> => await Ajax.get({ url: "/check" }).then(r => r.data[0]);

    const checkRemoteLogin = async (token: string): Promise<void> => {
        try {
            depot.setItem("turn_off_intercom", "1");
            Ajax.setToken(token);
            const response = await Ajax.get({ url: "auth/user" });
            depot.setItem("token", token);
            response.data.token = token;
            auth.setUser(response.data as IUser);
        } catch (e) {
            Sentry.captureException(e);
        }
    };

    if (isSettingsLoading || checkLoader.isLoading || isLangLoading) {
        return <LoadingScreen />;
    }

    return (
        <BrowserRouter basename={"/" + (!auth.isLoggedIn ? "" : config.baseUrl)}>
            {auth.isLoggedIn ? <UserLayout /> : <LoginLayout />}
        </BrowserRouter>
    );
};
