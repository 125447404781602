import classNames from "classnames";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import CheckBoxFilter from "components/DataTable/Filters/CheckBoxFilter";
import CheckListFilter from "components/DataTable/Filters/CheckListFilter";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import ReasonsEditorDrawer from "components/Drawers/ReasonsEditorDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { QuickLinks } from "components/Header/QuickLinks";
import { useTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import { ReasonType } from "config/constants";
import { RowDataType } from "rsuite-table/src/@types/common";
import Money from "../../../components/mini/Money";
import { exportFile } from "lib/export-table";

const ReasonsReportsPage: FCC = (props: any) => {
    const rType = props?.match?.params?.type || "wastes";

    const dp = useDataProvider({
        url: "reasons",
        useUrl: true,
        useDates: true,
        filters: {
            type: ["type", ReasonType[rType]],
        },
    });
    const useReasonDrawer = useDrawer();

    let amountTitle = t("amount");

    if (rType === "wastes") {
        amountTitle = t("cost_price");
    }

    // const canManage = rType === "wastes" ? "stock_manage_waste_manage" : "";

    const cm = useColumns<any>("reasons-reports", [
        {
            key: "name",
            align: "left",
            flexGrow: 9,
        },
        {
            key: "used_amount",
            title: amountTitle,
            render: d => <Money value={d.used_amount} symbol={false} />,
        },
        {
            key: "used_count",
            title: t("total_count"),
        },
    ]);

    const completeDrawerCRUD = () => {
        useReasonDrawer.hide();
        dp.revalidate();
    };

    return (
        <div className="h-100">
            {useTitle(t("reasons"), undefined, t("reasons"))}

            <QuickLinks
                links={[
                    {
                        link: "/inventory/write-off",
                        label: t("write_off"),
                    },
                    {
                        link: "/inventory/write-off/detailed",
                        label: t("details"),
                    },
                    {
                        link: "/inventory/write-off/reason-reports",
                        label: t("write_off_reason_reports"),
                        active: true,
                    },
                    {
                        link: "/inventory/write-off/reasons",
                        label: t("reasons"),
                    },
                ]}
            />

            <HeaderRight
                dp={dp}
                cm={cm}
                date
                reload
                print
                export={() => exportFile(dp, cm.columns, t("write_off_reason_reports"))}
            />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="name" placeholder={t("reasons")} />
                    {!rType && (
                        <CheckListFilter
                            pickerProps={{ searchable: false }}
                            options={[
                                { label: t("write_off"), value: ReasonType.wastes },
                                { label: t("delete"), value: ReasonType.delete },
                            ]}
                            fields="type"
                        />
                    )}
                    <CheckBoxFilter useParams fields="trash" value="2" title={t("deleted_transactions")} />
                </div>
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                    affixHeader: true,
                    rowClassName: (d?: RowDataType) =>
                        classNames({
                            "deleted-row": d && !!d?.deleted_at,
                        }),
                }}
            />
            <ReasonsEditorDrawer completeDrawerCRUD={completeDrawerCRUD} {...useReasonDrawer} />
        </div>
    );
};

export default ReasonsReportsPage;
