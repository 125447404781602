import { IconButton } from "rsuite";
import CIcon from "components/CIcon";
import { iClose } from "assets/icons/Icons";
import { FIListItemProps } from "components/FormItems/FormItemsList";
import { GridSelect } from "components/NewForm/Grid/GridSelect";
import { useAppSelector } from "hooks/useRedux";
import { translateUnitName, translateUnitNameLong } from "lib/utils";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { t } from "lib/i18n";

interface IIngredientUnitProps extends FIListItemProps {}

const IngredientUnit: FCC<IIngredientUnitProps> = ({ index, field, remove, form }) => {
    const units = useAppSelector(state => state.model.units);
    const unitOptions = units.ids
        .filter(id => units.byId[id].type === "PACKAGE")
        .map(id => ({ value: units.byId[id].id, label: translateUnitNameLong(units.byId[id]) }));

    const currentUnit = units.byId[field.id];
    const baseUnit = units.byId?.[currentUnit?.parent_id ?? 0];
    return (
        <>
            <GridSelect
                name={`packages[${index}].id`}
                options={unitOptions}
                onChange={val => {
                    const unit = units.byId[val];
                    form.setValue(`packages[${index}].equal`, unit?.equal);
                }}
                selectProps={{
                    cleanable: false,
                    placeholder: t("select", { name: t("unit") }),
                }}
            />
            <GridItemPlain value={field.equal} align="right" postfix={translateUnitName(baseUnit)} />

            <IconButton
                onClick={() => remove(index)}
                className="items-tab__close"
                icon={<CIcon path={iClose} className="text-danger" />}
            />
        </>
    );
};

export default IngredientUnit;

export const dummyUnit = (): Partial<any> => ({
    id: null,
    equal: 0,
});
