import { iExclamationCircle } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import { t } from "lib/i18n";
import { FC } from "react";
import { Tooltip, Whisper } from "rsuite";

export const GridInputError: FC<{ message?: string }> = ({ message }) => {
    if (message === undefined) return null;

    return (
        <Whisper
            placement="bottom"
            speaker={<Tooltip className="tw-text-red-200 tw-text-sm tw-p-2">{message || t("error")}</Tooltip>}
        >
            <div className="tw-h-full tw-px-2 tw-flex tw-items-center">
                <CIcon path={iExclamationCircle} className="tw-text-red-500" />
            </div>
        </Whisper>
    );
};
