import { FC } from "react";
import { Controller, UseFormReturn } from "react-hook-form";

interface GridRadioProps {
    name: string;
    value?: string | number;
    onChange?: (e: any) => void;
    control: UseFormReturn["control"];
}

export const GridRadio: FC<GridRadioProps> = ({ value = 1, name, onChange }) => {
    return (
        <Controller
            name={name}
            render={({ field }) => {
                return (
                    <label className="tw-flex tw-justify-center">
                        <input
                            {...field}
                            checked={field.value === value}
                            type="radio"
                            value={value}
                            onChange={onChange}
                        />
                    </label>
                );
            }}
        />
    );
};
