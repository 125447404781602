import { t } from "lib/i18n";
import { ruleset } from "lib/validation-rules";
import { FIListItemProps } from "components/FormItems/FormItemsList";
import { GridButtonRemove } from "components/NewForm/Grid/GridButtonRemove";
import { GridInput } from "components/NewForm/Grid/GridInput";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { useCurrency } from "hooks/useCurrency";

interface IProps extends FIListItemProps<GoodsVariantItemField> {}

export interface GoodsVariantItemField {
    id?: number;
    name: string;
    barcode: string;
    cost_price: number;
    price: number;
    deleted_at?: string;
}

const GoodsVariantItem: FCC<IProps> = ({ index, field, remove, form }) => {
    const currency = useCurrency();
    const modifications = form.watch("modifications")?.filter((m: GoodsVariantItemField) => !m.deleted_at) ?? [];

    return (
        <>
            <GridInput
                name={`modifications[${index}].name`}
                // value={field.name}
                index={index}
                control={form.control}
                inputProps={{
                    placeholder: t("name"),
                }}
                rules={{
                    validate: {
                        required: ruleset.required,
                    },
                }}
            />
            <GridInput
                name={`modifications[${index}].barcode`}
                // value={field.barcode}
                index={index}
                inputProps={{
                    placeholder: t("barcode"),
                    onKeyDown: e => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                        }
                    },
                }}
            />

            <GridItemPlain align="right" value={field.cost_price.toString()} postfix={currency} />

            <GridInput
                name={`modifications[${index}].price`}
                type="number"
                index={index}
                inputProps={{
                    placeholder: t("price"),
                }}
                decimals={2}
                // onChangeValue={val => form.setValue(`modifications[${index}].price`, val)}
                rules={{
                    validate: {
                        min: ruleset.gte(0),
                    },
                }}
                postfix={currency}
            />

            <GridButtonRemove
                onClick={() => {
                    if (field.id) {
                        form.setValue(`modifications[${index}].deleted_at`, Date.now());
                    } else {
                        remove(index);
                    }
                }}
                disabled={modifications.length < 2}
            />
        </>
    );
};

export default GoodsVariantItem;
