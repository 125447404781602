import { ModifierGroupType } from "config/constants";
import ModificationMultiItem, {
    ModificationMultiItemField,
} from "pages/products/dishes/edit/tabs/modifications/group/ModificationMultiItem";
import ModificationSingleItem, {
    ModificationSingleItemField,
} from "pages/products/dishes/edit/tabs/modifications/group/ModificationSingleItem";
import { dummyModificatorMultiItem, dummyModificatorSingleItem } from "pages/products/dishes/utils";
import _ from "lodash";
import { FormItems, useFormItemsContext } from "components/FormItems/FormItems";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { FIHeaderProps } from "components/FormItems/FormItemsHeader";
import { FormItemsAddButton } from "components/FormItems/FormItemsAddButton";
import { FC } from "react";

interface IProps {
    group: IModifierGroup;
    index?: number;
}

const getColumns = (type: ModifierGroupType): FIHeaderProps["columns"] => {
    return [
        [""],
        ["modifier"],
        ["name"],
        ["image", "center"],
        ["gross", "right"],
        ["default_count", "center"],
        ...(type === ModifierGroupType.SINGLE
            ? []
            : ([
                  ["min_count", "right"],
                  ["max_count", "right"],
              ] as any)),
        ["cost_price", "right"],
        ["price", "right"],
        ["status"],
        [""],
    ];
};

const ModificationGroup: FCC<IProps> = ({ group, index }) => {
    const isSingle = group.type === ModifierGroupType.SINGLE;

    const itemsFieldName = _.isNil(index) ? "modificators" : `modificator_groups[${index}].modificators`;
    const Comp = isSingle ? ModificationSingleItem : ModificationMultiItem;

    return (
        <FormItems itemsFieldName={itemsFieldName}>
            <FormItemsList<ModificationMultiItemField & ModificationSingleItemField>
                showHeader
                virtualize
                sortable
                columns={getColumns(group.type)}
                children={_props => <Comp group={group} {..._props} />}
                footer={<Footer isSingle={isSingle} group={group} />}
            />
        </FormItems>
    );
};

const Footer: FC<{ isSingle: boolean; group: IModifierGroup }> = ({ isSingle, group }) => {
    const { fields } = useFormItemsContext();
    return (
        <div className="tw-grid tw-grid-cols-subgrid tw-col-span-12 tw-gap-11">
            <div className="tw-col-span-5">
                {isSingle ? (
                    <FormItemsAddButton
                        dummyData={() => dummyModificatorSingleItem(group.id!, fields.length > 0 ? 0 : 1)}
                    />
                ) : (
                    <FormItemsAddButton dummyData={() => dummyModificatorMultiItem(group.id!)} />
                )}
            </div>
        </div>
    );
};

export default ModificationGroup;
