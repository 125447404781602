import slugify from "slugify";

export const convertToSlug = (value: string): string =>
    slugify(value.replace(/[><$%&|]/g, ""), {
        lower: true,
        strict: true,
    });

// For testing purposes
// console.log(
//     convertToSlug(
//         '            -------./>\'\\[d.as$d]\\[. "asd" ....... .. : ::  0132456 -------  !@#$%^&*() |=_ }{|?><?>< -----   😂😊 TEST BACK-end     TəƏəbriklər xeyirli olsun  789 Баклажановый соус ------     ',
//     ),
// );
