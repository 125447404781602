import DatePickerFormGroup from "components/Form/DatePickerFormGroup";
import RemoteSelectFormGroup from "components/Form/RemoteSelectFormGroup";
import { t } from "lib/i18n";
import { BalanceWithAmount } from "components/mini/BalanceWithAmount";
import { FinanceAccountDefaultFilters } from "config/constants";
import { useDrawer } from "components/Drawers/useDrawer";
import { StorageSelectFormGroup } from "components/Form/adhoc/StorageSelectFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import _ from "lodash";
import CreateSupplierModal from "pages/inventory/suppliers/CreateSupplierModal";
import { FC } from "react";
import { Col, Grid, Row } from "rsuite";
import { useFormContext } from "react-hook-form";

interface IReturnSupplyGeneralProps {
    fromSupply?: boolean;
}

const ReturnSupplyGeneralTab: FC<IReturnSupplyGeneralProps> = ({ fromSupply }) => {
    const { setValue, trigger } = useFormContext();
    const modal = useDrawer();

    return (
        <div className="tw-max-w-[600px]">
            <Grid fluid>
                <Row>
                    <Col sm={12}>
                        <RemoteSelectFormGroup
                            name="supplier_id"
                            remote={{
                                url: "supplier",
                                limit: 999,
                            }}
                            errorPlacement="rightEnd"
                            selectIfSingle
                            searchLocal={true}
                            width="100%"
                            revalidate={modal.dataId}
                            compProps={{
                                disabled: fromSupply,
                                renderExtraFooter: () => (
                                    <div
                                        test-id="create-new-supplier"
                                        className="rs-picker-select-menu-item text-center"
                                        onClick={() => {
                                            modal.setDataId(0);
                                        }}
                                    >
                                        {t("create_new_by_name", {
                                            name: t("supplier"),
                                        })}
                                    </div>
                                ),
                            }}
                        />

                        <StorageSelectFormGroup
                            showOnlyActive={true}
                            name="from_storage_id"
                            label={t("from_storage_id")}
                            searchField="name"
                            width="100%"
                            compProps={{
                                disabled: fromSupply,
                            }}
                        />

                        <DatePickerFormGroup
                            time
                            name="operated_at"
                            label={t("operation")}
                            compProps={{
                                cleanable: false,
                                shouldDisableDate: d => !!d && d.getTime() > Date.now(),
                            }}
                        />
                    </Col>
                    <Col sm={12}>
                        <RemoteSelectFormGroup
                            name="category_id"
                            label={t("category")}
                            searchLocal={true}
                            selectIfSingle
                            remote={{
                                url: "finance/category",
                                filters: { pid: ["parent_id", 9] },
                                params: { tree: 0 },
                            }}
                            compProps={{
                                sort: () => (a, b) => a._lft - b._lft,
                                renderMenuItem: (label: any, item: any) => {
                                    return (
                                        <span test-id="category_id_item">
                                            {_.times(item.depth).map((x, i) => (
                                                <span key={i} className="px-2">
                                                    --
                                                </span>
                                            ))}
                                            {label}
                                        </span>
                                    );
                                },
                            }}
                        />
                        <RemoteSelectFormGroup
                            name="balance_id"
                            label={t("balance")}
                            searchLocal={true}
                            remote={{
                                url: "finance/balance",
                                filters: FinanceAccountDefaultFilters,
                                refineList: (list: any[]) => {
                                    list?.push({ id: "supplier_remain", name: t("supplier_remain") });
                                    return list;
                                },
                            }}
                            compProps={{
                                renderMenuItem: (val: any, item: any) => (
                                    <BalanceWithAmount testId="balance_id_item" item={item} />
                                ),
                                renderValue: (val: any, item: any) => <BalanceWithAmount item={item} />,
                            }}
                        />
                        <TextFormGroup name="description" />
                    </Col>
                </Row>
            </Grid>
            <CreateSupplierModal
                {...modal}
                onSelectSupplier={val => {
                    if (val) {
                        setValue("supplier_id", val);
                        trigger("supplier_id");
                    }
                }}
            />
        </div>
    );
};

export default ReturnSupplyGeneralTab;
