import { iDragAndDrop } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import UploaderFormGroup from "components/Form/FileUploadFormGroup";
import { FIListItemProps } from "components/FormItems/FormItemsList";
import { GridButtonRemove } from "components/NewForm/Grid/GridButtonRemove";
import { GridInput } from "components/NewForm/Grid/GridInput";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { GridToggle } from "components/NewForm/Grid/GridToggle";
import { getUnitNames } from "config/constants";
import { productName } from "lib/data-utils";
import { toFixed } from "lib/utils";
import _ from "lodash";
import React, { useState } from "react";
import { store } from "redux/store";
import { GridProductSelectItem } from "components/NewForm/Grid/GridProductSelectItem";
import { useCurrency } from "hooks/useCurrency";

interface IProps extends FIListItemProps<ModificationMultiItemField> {
    isDraggable?: boolean;
    group: IModifierGroup;
}

export interface ModificationMultiItemField {
    ingredient_id: number;
    name: string;
    full_name: string;
    images: any[];
    cost_price: number;
    unit: any;
    brutto: number;
    price: number;
    default_count: number;
    max_count: number;
    min_count: number;
    status: string;
    ingredient: IIngredient;
}

const ModificationMultiItem: FCC<IProps> = ({ field, index, remove, form, isDraggable, itemsFieldName }) => {
    const currency = useCurrency();
    const getName = (name: string, i?: number) => `${itemsFieldName}[${i ?? index}].${name}`;
    const [selectedItem, setSelectedItem] = useState<IIngredient>(field?.ingredient);

    const onSelectModification = (id: number) => {
        const productsById = store.getState().data.products.byId;
        const item = productsById[id];
        setSelectedItem(item);
        form.setValue(getName("ingredient_id"), item.id);
        if (!_.isEmpty(item.media)) {
            form.setValue(getName("images"), [{ uuid: item.media?.[0]?.urls?.thumb }]);
        }
        form.setValue(getName("cost_price"), item.ingredient?.cost_price ?? 0);
        form.setValue(getName("unit"), item.unit);
        form.setValue(getName("name"), productName(item));
        form.setValue(getName("brutto"), 0);
        form.setValue(getName("default_count"), 0);
        form.setValue(getName("max_count"), 0);
        form.setValue(getName("min_count"), 0);
        form.setValue(getName("price"), 0);
    };

    const onChangeBrutto = (val: number) => {
        form.setValue(getName("cost_price"), toFixed(Number(val) * (selectedItem?.ingredient?.cost_price ?? 0), 4));
    };

    return (
        <>
            {isDraggable ? <CIcon path={iDragAndDrop} className="dragg-icon" /> : null}

            <GridProductSelectItem onSelect={onSelectModification} name={getName("ingredient_id")} />

            <GridInput name={getName("name")} index={index} />

            <UploaderFormGroup
                name={getName("images[0].uuid")}
                onRemove={() => form.setValue(getName("images"), [])}
                label={false}
                // uploadUrl="media"
                // fileInfoButtonsStyle={{ top: -10, right: 0 }}
                className="tw-justify-center tw-items-center tw-flex "
                style={{ marginBottom: 0 }}
                compProps={{
                    action: "media",
                    multiple: false,
                    listType: "picture",
                    name: "media_file",
                    // className: "tw-flex tw-flex-row",
                }}
                fileInfoButtonsStyle={{ position: "static", flexDirection: "row", alignItems: "center" }}
                buttonStyle={{
                    width: 30,
                    height: 30,
                    margin: 0,
                }}
                controlWrapperProps={{ className: "tw-flex tw-gap-2" }}
                // controlWrapperProps={{ style: { minWidth: 50 }, className: "ml-2" }}
            />

            <GridInput
                name={getName("brutto")}
                index={index}
                type="number"
                decimals={[0, 4]}
                onChangeValue={onChangeBrutto}
                postfix={getUnitNames(field?.unit?.symbol)}
            />

            <GridInput name={getName("default_count")} index={index} type="number" decimals={0} />

            <GridInput
                name={getName("min_count")}
                index={index}
                type="number"
                decimals={0}
                rules={{
                    deps: [getName("max_count")],
                }}
            />

            <GridInput
                name={getName("max_count")}
                index={index}
                type="number"
                decimals={0}
                rules={{
                    deps: [getName("min_count")],
                    // validate: {
                    //     min: ruleset.gte(getName("min_count")),
                    //     minGround: ruleset.gte(0),
                    // },
                }}
            />

            <GridItemPlain
                testId="cost_price"
                className="tw-min-w-min"
                value={field.ingredient?.cost_price ?? 0}
                align="right"
                postfix={currency}
            />

            <GridInput name={getName("price")} index={index} type="number" decimals={2} postfix={currency} />

            <GridToggle name={getName("status")} />

            <GridButtonRemove onClick={() => remove(index)} />
        </>
    );
};

export default ModificationMultiItem;
