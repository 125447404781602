import { iExchange, iMinus, iPlus } from "assets/icons/Icons";
import { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { BalanceWithAmount } from "components/mini/BalanceWithAmount";
import Money from "components/mini/Money";
import JsonViewState from "components/State/JsonViewState";
import RemoteSelectFormState from "components/State/RemoteSelectPickerState";
import { FinanceAccountDefaultFilters } from "config/constants";
import Ajax from "lib/Ajax";
import _ from "lodash";
import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { Button, DateRangePicker, Input, InputGroup, Message, Panel, Radio, RadioGroup, toaster } from "rsuite";
import CIcon from "../CIcon";
import DatePickerState from "../State/DatePickerState";
import CModal from "./CModal";
import { useCurrency } from "hooks/useCurrency";
import { format } from "date-fns";

type IProps = ICDrawerProps;

const styles = {
    form: {
        gap: 15,
        display: "flex",
        alignItems: "center",
    },
    label: {
        marginBottom: 4,
        width: 100,
    },
};
const type: Record<string, string> = {
    IN: iPlus,
    OUT: iMinus,
    TRANSFER: iExchange,
};

const CustomerBalanceModal: FCC<IProps> = props => {
    const currency = useCurrency();
    const [balanceValue, setBalanceValue]: any = useState(null);
    const [amountValue, setAmountValue]: any = useState(0);
    const [description, setDescription]: any = useState("");
    const [date, setDate]: any = useState(new Date());
    const [transactionType, setTransactionType] = useState<string | number | undefined>("IN");
    const [isLoading, setIsLoading] = useState(false);
    const customer: ICustomer = props.data;

    useEffect(() => {
        setDate(new Date());
    }, [props.isOpen]);

    const resetForm = () => {
        setBalanceValue(null);
        setAmountValue(0);
        setDescription("");
        setDate(null);
        setTransactionType("IN");
    };

    const executeTransaction = async () => {
        if (_.isNil(balanceValue) || _.isNaN(amountValue))
            return toaster.push(
                <Message type="warning" showIcon closable>
                    <TText tkey="error" />
                </Message>,
            );
        setIsLoading(true);
        const res = await Ajax.post({
            url: "finance/transaction",
            data: {
                amount: amountValue,
                balance_id: customer.balance_id,
                // cid: customer.cid,
                from_balance_id: balanceValue,
                is_terminal: false,
                type: "BALANCE_" + transactionType,
                customer_id: customer.id,
                operated_at: format(new Date(date), "yyyy-MM-dd HH:mm:ss"),
                description,
            },
        }).catch(() => {
            setIsLoading(false);
        });
        setIsLoading(false);
        if (res?.success) {
            toaster.push(
                <Message type="success" showIcon closable>
                    <TText tkey="success" />
                </Message>,
            );
            props.onClose?.({} as any);
            setTimeout(resetForm, 500);
        }
    };

    return (
        <CModal {...props} size="sm" title={t("add_transaction")}>
            <div
                style={{
                    gap: 12,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    marginTop: 20,
                    marginBottom: 20,
                }}
            >
                <Panel
                    header={
                        <label>
                            <TText tkey="customer" />
                        </label>
                    }
                    bordered
                    style={{ width: 500 }}
                >
                    {customer?.id && (
                        <div style={styles.form}>
                            <label style={styles.label}>
                                <TText tkey="data_id" />
                            </label>
                            <label>{customer?.id}</label>
                        </div>
                    )}
                    {customer?.name && (
                        <div style={styles.form}>
                            <label style={styles.label}>
                                <TText tkey="name" />
                            </label>
                            <label>{customer?.name}</label>
                        </div>
                    )}
                    {_.isNumber(customer?.balance?.amount) && (
                        <div style={styles.form}>
                            <label style={styles.label}>
                                <TText tkey="balance" />
                            </label>
                            <label>
                                <Money colored value={customer?.balance?.amount} />
                            </label>
                        </div>
                    )}
                </Panel>

                <div style={styles.form}>
                    <p style={styles.label}>
                        <TText tkey="allow_transactions" />
                    </p>
                    <RadioGroup
                        inline
                        appearance="picker"
                        value={transactionType}
                        onChange={setTransactionType}
                        style={{
                            width: 300,
                            display: "flex",
                            justifyContent: "space-around",
                        }}
                    >
                        <Radio value="OUT">
                            <TText tkey="expense" />
                        </Radio>
                        <Radio value="IN">
                            <TText tkey="income" />
                        </Radio>
                    </RadioGroup>
                </div>

                <div style={styles.form}>
                    <p style={styles.label}>
                        <TText tkey="amount" /> ({currency})
                    </p>
                    <InputGroup style={{ width: 300 }}>
                        <InputGroup.Addon className={`btn-${transactionType}`}>
                            <CIcon path={type[transactionType!]} />
                        </InputGroup.Addon>
                        <CurrencyInput
                            placeholder={t("amount")}
                            onValueChange={setAmountValue}
                            className={"rs-input"}
                            value={amountValue}
                            disableAbbreviations
                            suffix={" " + currency}
                            decimalsLimit={3}
                            decimalSeparator="."
                            groupSeparator=","
                        />
                    </InputGroup>
                </div>

                <div style={styles.form}>
                    <p style={styles.label}>
                        <TText tkey="balance" />
                    </p>
                    <RemoteSelectFormState
                        placeholder={t("balance")}
                        remote="finance/balance"
                        remoteQueries={{
                            filters: Object.values(FinanceAccountDefaultFilters),
                        }}
                        itemLabel={"name"}
                        itemValue={"id"}
                        searchField={"name"}
                        onSelect={setBalanceValue as never}
                        value={balanceValue}
                        errorStyle={{
                            width: 300,
                        }}
                        pickerProps={{
                            renderMenuItem: (val: any, item: any) => <BalanceWithAmount item={item?.item} />,
                            renderValue: (val: any, item: any) => <BalanceWithAmount item={item?.item} />,
                            cleanable: false,
                        }}
                    />
                </div>

                <div style={styles.form}>
                    <p style={styles.label}>
                        <TText tkey="operated_at" />
                    </p>
                    <DatePickerState
                        time
                        label={""}
                        cleanable={false}
                        shouldDisableDate={DateRangePicker.after?.(new Date())}
                        onChangeCalendarDate={(date: any) => setDate(new Date(date))}
                        value={new Date(date)}
                        style={{
                            width: 300,
                        }}
                    />
                </div>

                <div style={styles.form}>
                    <p style={styles.label}>
                        <TText tkey="description" />
                    </p>
                    <Input
                        value={description}
                        onChange={setDescription}
                        placeholder={t("description")}
                        style={{
                            width: 300,
                        }}
                    />
                </div>
            </div>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                    onClick={executeTransaction}
                    disabled={
                        transactionType === "OUT" &&
                        (customer?.balance?.amount < 0 || customer?.balance?.amount < amountValue)
                    }
                    loading={isLoading}
                    appearance={"primary"}
                    color={"green"}
                >
                    <TText tkey="pay" />
                </Button>
            </div>
            <JsonViewState
                value={{ form: { balanceValue, transactionType, amountValue, description, date }, customer }}
            />
        </CModal>
    );
};

export default CustomerBalanceModal;
