import { TText } from "components/i18n/TText";

import { useEffect } from "react";
import { Modal } from "rsuite";
import { iClose } from "assets/icons/Icons";
import { CloposManagerStoreLinks, MobileDeviceTypeEnums } from "config/constants";
import { getCookie, getMobileOperatingSystem, setCookie } from "lib/utils";
import CIcon from "./CIcon";
import CModal from "./Drawers/CModal";
import { useDrawer } from "./Drawers/useDrawer";

const MobileDetectModal: FCC = () => {
    const operationSystem = getMobileOperatingSystem();
    const modal = useDrawer({ backdrop: true, overflowAuto: true });

    useEffect(() => {
        const cookie = getCookie("mobile-banner-show");
        const isMobile =
            operationSystem === MobileDeviceTypeEnums.iOS || operationSystem === MobileDeviceTypeEnums.Android;
        modal.setIsOpen(isMobile && cookie === null);
    }, [operationSystem]);

    const openStore = () => {
        closeModal();
        window.open(CloposManagerStoreLinks[MobileDeviceTypeEnums.Unknown], "_blank");
    };

    const closeModal = () => {
        setCookie("mobile-banner-show", "yes");
        modal.setIsOpen(false);
    };

    return (
        <CModal className="mobile-modal" {...modal}>
            <Modal.Body>
                <div className="header-button">
                    <button onClick={() => closeModal()}>
                        <CIcon path={iClose} />
                    </button>
                </div>

                <div className="content">
                    <span className="title">
                        <TText tkey="manager_app_heading" />
                    </span>
                    <span className="description">
                        <TText tkey="manager_app_subheading" />
                    </span>
                </div>
                <div className="image-section">
                    <img src="/img/mobile-banner.png" />
                </div>
                <div className="button-group">
                    <button className="download-button" onClick={() => openStore()}>
                        <span>
                            <TText tkey="download_app" />
                        </span>
                    </button>
                    <button className="browser-button" onClick={() => closeModal()}>
                        <span>
                            <TText tkey="stay_on_web" />
                        </span>
                    </button>
                </div>
            </Modal.Body>
        </CModal>
    );
};

export default MobileDetectModal;
