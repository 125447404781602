export const dummyPaymentMethod = () => ({
    id: null,
    name: "",
    amount: 0,
});

export const dummyPaymentMethods = () => ({
    payment_methods: [],
});

// export const PaymentMethod = () =>
//     object().shape({
//         id: number(),
//         amount: string()
//             .required(t("required"))
//             .matches(/^[0-9]+([.][0-9]{0,2})?$/, t("must_be_2_decimal")),
//     });

// export const PaymentMethods = object().shape({
//     payment_methods: array(
//         object({
//             id: number().nullable().required(t("required")),
//             amount: number().required(t("required")).min(0.01, t("must_be_greater_0")),
//             name: string(),
//         }),
//     ).test("total_not_match", t("total_not_match"), function (value) {
//         return (
//             Number(this.parent?.receipt?.total || 0) ===
//             Number(value?.reduce((a, b) => a + Number(b?.amount), 0).toFixed(2))
//         );
//     }),
// });
