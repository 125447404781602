import CFormGroup, { CFormGroupProps } from "components/Form/CFormGroup";
import { ToggleProps, Toggle } from "rsuite";
interface IProps extends CFormGroupProps<ToggleProps> {
    CFormStyle?: any;
    checked?: boolean;
    onChange?: (value: any, checked: boolean) => void;
}
const LoyaltyToggleButton: FCC<IProps> = props => {
    const { labelDescription, label, ...restProps } = props;

    const cprops: any = {
        render: ({ field: { value, onChange } }: any) => {
            return (
                <>
                    <div>
                        <span className="tw-text-xl tw-mr-4 tw-font-medium">{label}</span>
                        <Toggle {...restProps.compProps} className="my-1 " checked={!!value} onChange={onChange} />
                    </div>
                    <span className="tw-block tw-text-sm tw-text-disabled tw-font-normal tw-mt-3 tw-mb-5 tw-break-words">
                        {labelDescription}
                    </span>
                </>
            );
        },
        ...restProps,
    };

    return <CFormGroup {...(restProps as any)} compProps={cprops} />;
};

export default LoyaltyToggleButton;
