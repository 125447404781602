import CForm from "components/Form/CForm/CForm";
import { TText } from "components/i18n/TText";
import _ from "lodash";
import { FC, useEffect } from "react";
import { Button, FormProps, Modal } from "rsuite";
import { UseFormReturnType } from "hooks/useCForm";
import CModal, { ICModalProps } from "./CModal";

interface ICFormModalProps extends ICModalProps {
    form: UseFormReturnType;
    revalidate?: () => void;
    formProps?: FormProps;
    showFooter?: boolean;
    receivedValue?: (d: any, name: any) => void;
}

const CFormModal: FC<ICFormModalProps> = ({ title, dataId, showFooter, formProps, form, receivedValue, ...props }) => {
    useEffect(() => {
        form.set("afterSubmit", (d: any) => {
            receivedValue?.(d.receivedValue, props.data?.name);
            onClose();
            props.revalidate?.();
        });
    }, [props.data]);

    function onClose() {
        props.onClose?.({} as any);
        form.form.reset(form.conf.dummyData?.());
    }

    return (
        <CModal {...props} backdrop title={title} onClose={onClose}>
            <Modal.Body className="pb-0">
                <CForm {...form} noPanel formProps={formProps} isLoading={form.form.formState.isLoading}>
                    {props.children}
                </CForm>
            </Modal.Body>

            {(showFooter || _.isNil(showFooter)) && (
                <Modal.Footer>
                    <Button
                        loading={form.isLoading}
                        appearance="primary"
                        color="green"
                        test-id="modal-save-button"
                        onClick={form.onSave}
                    >
                        <TText tkey="save" />
                    </Button>
                </Modal.Footer>
            )}
        </CModal>
    );
};

export default CFormModal;
