import { TText } from "components/i18n/TText";

import { ColumnsManagerType } from "components/DataTable/ColumnsManager/useColumns";
import { getLabel } from "components/Form/form-utils";
import React, { useState } from "react";
import { Dropdown, Tooltip, Whisper } from "rsuite";
import CIcon from "../../CIcon";
import { iTable } from "assets/icons/Icons";
import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    UniqueIdentifier,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { ColumnManagerItem } from "components/DataTable/ColumnsManager/ColumnManagerItem";

interface IProps {
    cm: ColumnsManagerType;
    title?: string;
}

const ColumnsManagerButton: FCC<IProps> = props => {
    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const onDragEnd = (e: DragEndEvent) => {
        if (!e.over) return;

        const { active, over } = e;

        if (active.id !== over.id) {
            props.cm.moveColumn(active.id as string, over.id as string);
        }
    };

    const [isOpen, setIsOpen] = useState(false);

    return (
        <Dropdown
            open={isOpen}
            noCaret
            placement="bottomEnd"
            toggleClassName="p-0"
            test-id="columns-manager"
            menuStyle={{ maxHeight: "540px", overflowY: "auto" }}
            title={
                <Whisper
                    placement="bottom"
                    trigger="hover"
                    speaker={
                        // This state needs for test purposes
                        // In some pages (menu/ingredientCategories) this tooltip shows the above column name
                        // so cypress can't click on it
                        isOpen ? (
                            <div></div>
                        ) : (
                            <Tooltip>
                                <TText tkey="show_in_table" />
                            </Tooltip>
                        )
                    }
                >
                    <div className="d-flex align-items-center">
                        <CIcon path={iTable} size={0.65} />
                        {props.title && <span className="pl-2">{props.title}</span>}
                    </div>
                </Whisper>
            }
            onToggle={v => setIsOpen(v!)}
        >
            <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={onDragEnd}
                modifiers={[restrictToVerticalAxis, restrictToParentElement]}
            >
                <SortableContext
                    items={props.cm.allColumns.map(({ key }) => key as UniqueIdentifier)}
                    strategy={verticalListSortingStrategy}
                >
                    {props.cm.allColumns.map(column => {
                        const { key, title }: any = column;

                        return key === "options" ? null : (
                            <ColumnManagerItem
                                key={key}
                                columnKey={key}
                                title={title ?? getLabel(key)}
                                cm={props.cm}
                                column={column}
                            />
                        );
                    })}
                </SortableContext>
            </DndContext>
        </Dropdown>
    );
};

export default ColumnsManagerButton;
