import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { FC, useState } from "react";
import { Checkbox, Col, Grid, Row } from "rsuite";
import { iMinus } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import { cn } from "lib/utils";
import { actionIcon } from "../../statistics/receipts/misc/ActionString";
import { useAppSelector } from "hooks/useRedux";
import { selectSettingOptionsOf } from "redux/features/settings";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

const ReceiptSettingsSuspicionTab: FC = () => {
    const ctx = useCFormContext();
    const suspiciousActions = useAppSelector(s => selectSettingOptionsOf(s, "suspicion_action"));
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const [showSelectedActions, setShowSelectedActions] = useState(false);

    const actions: string[] = ctx.watch("suspicion_action.value") ?? [];

    const markAsSuspect = (action: string) => {
        const actionIndex = actions.findIndex(ac => ac === action);
        if (actionIndex > -1) {
            actions.splice(actionIndex, 1);
        } else {
            actions.push(action);
        }

        ctx.setValue("suspicion_action.value", actions, { shouldDirty: true });
    };

    const acitonList: string[] = showSelectedActions ? actions : suspiciousActions.map(sa => sa.id);

    return (
        <div className="p-3">
            <Checkbox
                className="ckeckbox__icon"
                checked={showSelectedActions}
                value={showSelectedActions ? 1 : 0} // in case any value added manually
                onChange={(v, c) => setShowSelectedActions(c)}
            >
                <div className="d-flex align-items-center ">
                    <TText tkey="suspicion_action" />
                </div>
            </Checkbox>
            <Grid fluid className={cn("tw-grid tw-gap-y-4")}>
                <Row>
                    <Col xs={2} sm={2} md={1} lg={1} className="tw-w-[40px] tw-h-[40px]"></Col>
                    <Col xs={12} sm={12} md={4} lg={4} className="pl-0 pr-1">
                        <div className="tw-opacity-70 tw-text-slate-700 tw-text-md tw-font-medium">
                            <TText tkey="actions" />
                        </div>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={6} className="pl-0 pr-1">
                        <div className="tw-opacity-70 tw-text-slate-700 tw-text-md tw-font-medium tw-text-center">
                            <TText tkey="mark_as_suspect" />
                        </div>
                    </Col>
                </Row>
                {acitonList?.map(action => {
                    const isActionSelected = actions.includes(action);
                    const ActionIcon = actionIcon[action]?.[0]?.icon;
                    return (
                        <Row key={action}>
                            <Col xs={2} sm={2} md={1} lg={1} className="tw-w-[40px] tw-h-[40px]">
                                <CIcon
                                    path={ActionIcon ?? iMinus}
                                    className={cn("tw-w-[20px] tw-h-[20px]", {
                                        "tw-text-orange": isActionSelected,
                                    })}
                                />
                            </Col>
                            <Col
                                xs={12}
                                sm={12}
                                md={4}
                                lg={4}
                                className={cn(
                                    "tw-flex pl-0 pr-1 tw-items-center tw-gap-x-2 tw-text-md tw-cursor-pointer",
                                )}
                            >
                                <div
                                    className={cn("tw-opacity-70 tw-text-slate-700 tw-font-medium", {
                                        "tw-text-orange": isActionSelected,
                                    })}
                                    onClick={() => markAsSuspect(action)}
                                >
                                    {t(`${action.toLowerCase()}_rlog_action_title` as LangKey) ?? action}
                                </div>
                            </Col>
                            <Col xs={6} sm={6} md={6} lg={6} className="pl-0 pr-1 tw-cursor-pointer">
                                <div className="tw-opacity-70 tw-text-slate-700 tw-font-medium tw-justify-center tw-flex">
                                    <Checkbox
                                        className="ckeckbox__icon"
                                        checked={isActionSelected}
                                        onChange={() => markAsSuspect(action)}
                                    />
                                </div>
                            </Col>
                        </Row>
                    );
                })}
            </Grid>
        </div>
    );
};

export default ReceiptSettingsSuspicionTab;
