import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";

import _ from "lodash";
import CreateButton from "../../../components/DataTable/CreateButton";
import { getLabel } from "components/Form/form-utils";
import CIcon from "../../../components/CIcon";
import { iCheckBoxChecked, iClose } from "assets/icons/Icons";
import React from "react";
import { useAppSelector } from "hooks/useRedux";
import { Colors } from "config/colors";

const PaymentMethodsPage: FCC = props => {
    const { currentVenueId } = useAppSelector(state => state.venue);

    const dp = useDataProvider({
        url: "finance/paymentMethod",
        useUrl: true,
        with: ["balance"],
    });

    const formatBalanceName = (rowData: any) =>
        _.capitalize(rowData.balance ? t(rowData.balance.name.toLowerCase()) || rowData.balance.name : "--");

    const cm = useColumns<any>("finance/paymentMethod", [
        { key: "id", align: "left", title: "ID" },
        {
            key: "name",
            align: "left",
            render: (d: any) => getLabel(d.name),
            flexGrow: 2,
        },
        { key: "balance.name", title: t("account"), render: formatBalanceName },
        {
            key: "status",
            type: "bool",
            render: d =>
                d?.status?.[currentVenueId] === 1 ? (
                    <div
                        className="d-flex align-items-center rounded-circle justify-content-center"
                        style={{ background: Colors.MintGreen, width: "16px", height: "16px" }}
                    >
                        <CIcon path={iCheckBoxChecked} style={{ color: Colors.TropicalGreen }} size={0.45} />
                    </div>
                ) : (
                    <div
                        className="d-flex align-items-center rounded-circle justify-content-center"
                        style={{ background: Colors.SoftPeach, width: "16px", height: "16px" }}
                    >
                        <CIcon path={iClose} style={{ color: Colors.BrightRed }} size={0.45} />
                    </div>
                    // <Icon icon="check" className="text-success" />
                ),
        },
        { key: "split", type: "bool", title: t("split") },
        { key: "customer_required", type: "bool", title: t("customer_required") },
        optionsColumn({
            dp,
            edit: "/sales/payment-methods/edit/:id",
            delete: "finance/paymentMethod/:id",
            preventDelete: (d: any) => d.id < 99,
            canEdit: "finance_payment_method_edit",
            canDelete: "finance_payment_method_delete",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("payment_methods"), dp.total)}
            <HeaderRight dp={dp} cm={cm} reload print />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter className="search-input" fields="name" />
                </div>
                <CreateButton to="/sales/payment-methods/create" canCreate={"finance_payment_method_create"} />
            </FiltersContainer>

            <DataTable
                dp={dp}
                columns={cm.columns}
                rowPadding={"22px 10px 0"}
                tableProps={{
                    rowHeight: 60,
                }}
            />
        </div>
    );
};

export default PaymentMethodsPage;
