import DataTable from "components/DataTable/DataTable";
import { useDataProvider } from "components/DataTable/useDataProvider";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import Money from "components/mini/Money";
import { FC } from "react";
import { useColumns } from "../DataTable/ColumnsManager/useColumns";
import IdWithMisc from "components/mini/IdWithMisc";
import { useDrawer } from "components/Drawers/useDrawer";
import ReceiptDrawer from "components/Drawers/ReceiptDrawer";

interface IProps extends ICDrawerProps {
    date: string[];
}

const StaffServiceChargeDrawer: FC<IProps> = props => {
    const rd = useDrawer();

    const dp = useDataProvider({
        url: "incentive-programs/transactions/" + props.data.user.id,
        with: ["incentiveProgram:id,name", "product:id,name,parent_name"],
        params: {
            date: props.date,
        },
    });

    const cm = useColumns(`drawer/incentive-programs`, [
        {
            title: t("date"),
            key: "created_at",
            type: "date",
        },
        {
            key: "receipt_id",
            title: "#" + t("receipt"),
            render: e => (
                <IdWithMisc
                    id={e.receipt_id}
                    onClick={() => {
                        props.setIsClosable(false);
                        rd.setDataId(e.receipt_id);
                    }}
                />
            ),
        },
        {
            title: t("product_name"),
            key: "product.full_name",
            type: "text",
        },
        {
            title: t("incentive_program"),
            key: "incentive_program.name",
            type: "text",
        },
        {
            title: t("bonus"),
            key: "amount",
            type: "money",
            numberRoundingLength: 2,
            render: d => <Money value={d.amount} />,
            align: "left",
        },
    ]);

    return (
        <>
            <CDrawer size="md" {...props} title={props?.data?.user?.username}>
                <div className="">
                    <div className="d-flex">
                        <div className="flex-fill border-left">
                            <DataTable
                                noShadow
                                columns={cm.columns}
                                dp={dp}
                                tableProps={{
                                    autoHeight: false,
                                }}
                                virtualized={true}
                            />
                        </div>
                    </div>
                </div>
            </CDrawer>
            {rd.isOpen && (
                <ReceiptDrawer
                    {...rd}
                    onClose={() => {
                        rd.onClose();
                        props.setIsClosable(true);
                    }}
                />
            )}
        </>
    );
};

export default StaffServiceChargeDrawer;
