import { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { MouseEvent, useState } from "react";
import { Message } from "rsuite";
import { iDish, iModifikator, iPreparation } from "assets/icons/Icons";
import CIcon from "../../../../../components/CIcon";
import UsageByTypeDrawer from "../../../../../components/Drawers/UsageByTypeDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import { Colors } from "config/colors";
import { ProductType } from "config/constants";

interface IProps extends ICDrawerProps {
    dataId: any;
    productId: number;
    countData: {
        PREPARATION: number;
        DISH: number;
        MODIFICATORS: number;
    };
}

interface IUsingTypes {
    label: string;
    icon: string;
    type: string;
    count: number;
}

const UsageTabBeta: FCC<IProps> = ({ dataId, productId, countData, ...props }) => {
    const usageByTypeDrawer = useDrawer();
    //for show in drawer selected
    const [selectedType, setSelectedType] = useState<null | string>(null);
    const usingTypes: IUsingTypes[] = [
        { label: t("in_dishes"), icon: iDish, type: ProductType.DISH, count: countData?.DISH },

        {
            label: t("in_preperations"),
            icon: iPreparation,
            type: ProductType.PREPARATION,
            count: countData?.PREPARATION,
        },

        {
            label: t("in_modificators"),
            icon: iModifikator,
            type: "modificator",
            count: countData?.MODIFICATORS,
        },
    ].filter(type => type.count);
    const handleSelect = (e: MouseEvent<HTMLDivElement>, item: IUsingTypes) => {
        e.preventDefault();
        setSelectedType(item.type);
        usageByTypeDrawer.setDataId(productId);
        usageByTypeDrawer.setData({ type: item.type, title: item.label });
        props.setIsClosable(false);
    };
    return (
        <div>
            {usingTypes.length > 0 ? (
                usingTypes.map(item => (
                    <div
                        key={item.label}
                        className="mx-3 my-2 p-3 tw-cursor-pointer d-flex align-items-center"
                        style={{
                            border: `1px solid ${Colors.PaleGray}`,
                            color:
                                selectedType === item.type && usageByTypeDrawer.isOpen
                                    ? Colors.CobaltBlue
                                    : Colors.SteelGray,
                            borderRadius: "6px",
                        }}
                        onClick={e => handleSelect(e, item)}
                    >
                        <CIcon path={item.icon} />
                        <span className="ml-3" style={{ fontSize: 20 }}>
                            {item.label}
                        </span>
                        <span
                            className="ml-2"
                            style={{
                                fontSize: 12,
                                background: Colors.LightGray,
                                padding: "2px 13px",
                                borderRadius: "16px",
                                fontWeight: 600,
                            }}
                        >
                            {item.count}
                        </span>
                    </div>
                ))
            ) : (
                <div className="empty-data">
                    <div className="warning">
                        <Message type="warning" showIcon>
                            <TText tkey="not_in_use" />
                        </Message>
                    </div>
                </div>
            )}

            <UsageByTypeDrawer
                {...usageByTypeDrawer}
                onClose={() => {
                    props.setIsClosable(true);
                    usageByTypeDrawer.onClose();
                }}
            />
        </div>
    );
};

export default UsageTabBeta;
