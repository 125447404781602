import { isDev, VERSION_FOR_SENTRY } from "config/main";
import "./start";
import ReactDOM from "react-dom/client";
import { App } from "App";
import "assets/scss/index.scss";
import "assets/scss/rsuite-default.css";
import "assets/scss/rsuite-wrapped-default.scss";
import "react-datasheet/lib/react-datasheet.css";
import "assets/scss/tw.css";
import { AuthProvider } from "providers/AuthProvider/AuthProvider";
import * as Sentry from "@sentry/react";
import { store } from "redux/store";
import { Provider } from "react-redux";
import { config } from "config/Config";

window.__ = {
    enablecloposdebugmode: () => {
        localStorage.setItem("CLOPOS_DEBUG_MODE", "true");
        window.location.reload();
    },
};

if (!isDev) {
    Sentry.init({
        dsn: "https://ce09f0b6a653d95f022dd72a4f319cdd@sentry.clopos.com/3",
        release: "client@" + VERSION_FOR_SENTRY,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: true,
            }),
        ],
        tracesSampleRate: 1.0,
        replaysSessionSampleRate: 0, // 0.1,
        replaysOnErrorSampleRate: 0, // 1.0,
        ignoreErrors: [
            "Error Delete",
            "The given data was invalid.",
            "not deletable. Before you need delete",
            "Not Deletable or use in",
            "No query results for model",
            "Customer Already Exist",
            "Error Update",
            "Error Create",
            "Access Denied.You haven't",
            "Not Deletable User",
            "Brand Not Found",
            "errors.limitations.product_limit",
            "System is offline",
            "Can't Delete.",
            "Request failed with status code",
            "Inventory Check!",
            "Only Owner or Self can",
            "due to access control checks",
        ],
        denyUrls: [
            // Bitrex24 error ignored
            /clopos\.bitrix24\.ru/i,
            // Analytics URLs ignored
            /log-api\.clopos\.com/i,
            /mc\.yandex\.ru/i,
            /www\.google-analytics\.com/i,
        ],
        debug: isDev,
        environment: window.STAGE,
    });
    Sentry.getCurrentScope()?.setTag("brand", config.brand);

    config.on("brandChange", brand => {
        Sentry.getCurrentScope()?.setTag("brand", brand);
    });

    window.addEventListener("vite:preloadError", e => {
        Sentry.captureException(e);

        window.location.reload();
    });
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
    <Provider store={store}>
        <AuthProvider>
            <App />
        </AuthProvider>
    </Provider>,
);
