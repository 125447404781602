import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import TextAreaFormGroup from "components/Form/TextAreaFormGroup";
import LoyaltyRadioLimitGroupField from "./form/LoyaltyRadioLimitGroupField";
import RadioFormGroup from "components/Form/RadioFormGroup";
import { Button, Divider, Radio } from "rsuite";
import TextFormGroup from "components/Form/TextFormGroup";
import HeaderRight from "components/Header/HeaderRight";
import LoyaltyRadioGroupField from "./form/LoyaltyRadioGroupField";
import { useTitle } from "components/Header/Title";
import SaleTypeField from "./form/LoyaltySaleTypeField";
import JsonView from "components/JsonView";
import CForm from "components/Form/CForm/CForm";
import { useData } from "hooks/useData";
import React, { useEffect, useState } from "react";
import { cBoolean, PaymentMethodType, ProductType } from "config/constants";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useCForm } from "hooks/useCForm";
import LoyaltyToggleButton from "./form/LoyaltyToggleButton";
import { model } from "./model";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import {
    setIsSingleSelect,
    setOpenProductSelector,
    setSelectedProducts,
    setShouldShowSelected,
    setShowedTypes,
} from "redux/features/productsSelector/productsSelector";
import CProductsDrawer from "components/Drawers/CProductSelector/CProductsDrawer";
import { useProductSelectorContext } from "providers/ProductSelectorProvider";
import CIcon from "components/CIcon";
import { iChevronRight, iClose } from "assets/icons/Icons";
import { dummyLoyaltyData } from "./LoyaltyModel";
import { useIntegrationByName } from "providers/PackageProvider";
import { CCheckTreePickerFormGroup } from "components/Form/CCheckTreePickerFormGroup";
import { cn } from "lib/utils";
import { useMenuCategories } from "hooks/data-hooks/useMenuCategories";
import { useCurrency } from "hooks/useCurrency";

const labelProps = {
    style: {
        width: "250px",
    },
};

const LoyaltyPage = () => {
    const productsById = useAppSelector(state => state.data.products.byId);
    const { updateConfig } = useProductSelectorContext();
    const user = useAuth();
    const currency = useCurrency();
    const dispatch = useAppDispatch();
    const loyaltyIntegration = useIntegrationByName("loyalty");
    const hasAbility = user.hasAbility(["loyalty_edit", "loyalty_manage"]);

    const [shouldShowPercent, setShouldShowPercent] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const form = useCForm({
        url: "brand/integration",
        id: loyaltyIntegration ? loyaltyIntegration.id : undefined,
        model,
        redirectUrl: false,
        refine: d => {
            d.payload = d.payload ?? dummyLoyaltyData().payload;
            d.payload.sale_types = d.payload.sale_types?.map((sale_types: ILoyaltySaleType) => {
                sale_types.payment_methods = sale_types.payment_methods?.filter(
                    method => method.id !== PaymentMethodType.CASHBACK_BALANCE,
                );
                return sale_types;
            });
            d.status = d.status ? 1 : 0;

            /*
            DON'T DELETE BELLOW 3 LINES OF CODE
            The reason is that we have some clients which saves true/false instead of 1/0
            so we have to take them on count
             */
            d.payload.all_sale_types = d.payload.all_sale_types ? 1 : 0;
            d.payload.daily_earn_limit.status = d.payload.daily_earn_limit.status ? 1 : 0;
            d.payload.daily_spend_limit.status = d.payload.daily_spend_limit.status ? 1 : 0;
            return d;
        },
        afterSubmit: d => {
            const formValue = d.formValue;
            const payload = formValue.payload ?? dummyLoyaltyData().payload;

            form.form.setValue("payload", payload);
            if (payload.all_sale_types) {
                setIsFormSubmitted(true);
            }
        },
        prepareForSubmit: d => {
            d.payload.sale_types =
                d.payload.sale_types
                    ?.filter((saleType: ILoyaltySaleType) => saleType.status && !!saleType.id)
                    .map((saleType: ILoyaltySaleType) => ({
                        ...saleType,
                        payment_methods: saleType.status
                            ? saleType.all_payment_methods
                                ? []
                                : (saleType?.payment_methods?.filter(({ status, id }) => status && !!id) ?? [])
                            : [],
                    })) ?? [];
            d.status = d.status ? 1 : 0;
            return d;
        },
    });
    const service_name = form.form.watch("service_name");
    const daily_earn_limit = form.form.watch("payload.daily_earn_limit");
    const daily_spend_limit = form.form.watch("payload.daily_spend_limit");
    const selectedProducts = form.form.watch("payload.excluded_products") ?? [];

    const categories = useMenuCategories(true);

    const paymentMethod = useData("finance/paymentMethod");
    const saleType = useData("finance/saleType", {
        with: ["payment_method"],
    });

    const enableAllSaleTypes = form.form.watch(`payload.all_sale_types`);
    const ratesValue = form.form.watch(`payload.rates.default`);

    const currencyString = t("loyalty_currency", {
        currency: `5${currency}`,
        currencystatic: `${currency}`,
    });

    useEffect(() => {
        setShouldShowPercent(ratesValue > 5);
    }, [ratesValue]);

    useEffect(() => {
        if (isFormSubmitted) {
            prepareSaleTypesAndPaymentMethods();
        }
    }, [isFormSubmitted]);

    useEffect(() => {
        if (!saleType.data || !paymentMethod.data || !service_name) return;
        prepareSaleTypesAndPaymentMethods();
    }, [saleType.data, paymentMethod.data, service_name]);

    useEffect(() => {
        updateConfig({
            onProductSelectorSave: onProductsSelect,
        });
        dispatch(setIsSingleSelect(false));
        dispatch(setShowedTypes([ProductType.DISH, ProductType.GOODS]));
    }, []);

    const prepareSaleTypesAndPaymentMethods = () => {
        const _paymentMethod: IPaymentMethod[] = paymentMethod?.data?.filter(
            (payment_method: IPaymentMethod) => payment_method.id !== PaymentMethodType.CASHBACK_BALANCE,
        );

        const loyaltySaleTypes: ILoyaltySaleType[] = form.form.getValues("payload.sale_types") ?? [];

        const payment_methods = createPaymentMethodList(_paymentMethod);

        const saleTypes: ISaleType[] = saleType.data;

        const data: ILoyaltySaleType[] = saleTypes.map((type, index) => {
            const typeIndex = loyaltySaleTypes.findIndex(
                (loyaltySaleType: ILoyaltySaleType) => type.id === loyaltySaleType.id,
            );

            if (typeIndex > -1) {
                const loyaltyPaymentMethods = loyaltySaleTypes[typeIndex].payment_methods ?? [];

                const paymentMethods = payment_methods.map(paymentMethod => {
                    const methodIndex = loyaltyPaymentMethods.findIndex(
                        lPaymentMethod => lPaymentMethod.id === paymentMethod.id,
                    );

                    if (methodIndex > -1) {
                        return loyaltyPaymentMethods[methodIndex];
                    }

                    return paymentMethod;
                });

                return {
                    ...loyaltySaleTypes[typeIndex],
                    payment_methods: paymentMethods,
                };
            }

            return {
                id: type.id!,
                status: false,
                all_payment_methods: 1,
                payment_methods,
            };
        });

        form.form.setValue("payload.sale_types", data);
        setIsFormSubmitted(false);
    };

    const createPaymentMethodList = (payment_methods: IPaymentMethod[]): ILoyaltyPaymentMethod[] => {
        return payment_methods.reduce((acc: any, item: IPaymentMethod) => {
            acc.push({
                id: item.id,
                status: false,
            });
            return acc;
        }, [] as any);
    };

    const onProductsSelect = (ids: number[]) => {
        form.form.setValue("payload.excluded_products", ids);
    };

    return (
        <div className="tw-h-full tw-p-4">
            {useTitle(t("loyalty_program"))}
            <HeaderRight formSubmit={hasAbility ? form : undefined} />

            <CForm
                form={form.form}
                onSave={form.onSave}
                formProps={{
                    paddingY: 0,
                    className: "loyalty-form",
                }}
            >
                <JsonView />
                <LoyaltyToggleButton
                    name="status"
                    label={t("loyalty_status")}
                    hideLabel
                    labelProps={{
                        className: "py-0",
                    }}
                    labelDescription={t("loyalty_description")}
                />
                <div className={`tw-text-base tw-font-medium tw-text-metal`}>
                    <TText tkey="loyalty_program_type" />
                </div>
                <LoyaltyRadioGroupField />
                <Divider />
                <div className={`tw-text-base tw-font-medium tw-text-metal tw-mb-3`}>
                    <TText tkey="loyalty_program_settings" />
                </div>
                <TextFormGroup
                    errorPlacement="topStart"
                    focus={true}
                    labelProps={labelProps}
                    name="payload.rates.default"
                    postFix="%"
                    type="number"
                    popoverText={shouldShowPercent ? t("loyalty_max_percent") : undefined}
                    label={t("cashback_rate")}
                    labelDescription={t("cashback_rate_description")}
                    compProps={{
                        className: "w-inherit",
                        description: currencyString,
                    }}
                />

                <LoyaltyRadioLimitGroupField
                    labelProps={labelProps}
                    defaultValue={daily_spend_limit?.status ?? cBoolean.False}
                    currency={`${currency}`}
                    receiptName={`payload.daily_spend_limit.receipt_count`}
                    currencyName={`payload.daily_spend_limit.receipt_amount`}
                    radioName={"payload.daily_spend_limit"}
                    descr={t("loyalty_daily_spend_limit_description")}
                    label={t("loyalty_daily_spend_limit")}
                    form={form}
                    descrDontBe={t("dont_have_daily_spend_limit_desc")}
                    descrLetItBe={t("have_daily_spend_limit_desc")}
                    dailyEarnLimit={daily_spend_limit}
                />
                <LoyaltyRadioLimitGroupField
                    labelProps={labelProps}
                    defaultValue={daily_earn_limit?.status ?? cBoolean.False}
                    currency={`${currency}`}
                    descrDontBe={t("dont_have_daily_earn_limit_desc")}
                    descrLetItBe={t("have_daily_earn_limit_desc")}
                    receiptName={`payload.daily_earn_limit.receipt_count`}
                    currencyName={`payload.daily_earn_limit.receipt_amount`}
                    radioName={"payload.daily_earn_limit"}
                    descr={t("loyalty_daily_earn_limit_description")}
                    label={t("loyalty_daily_earn_limit")}
                    form={form}
                    dailyEarnLimit={daily_earn_limit}
                />

                <RadioFormGroup
                    name="payload.all_sale_types"
                    className="mt-4"
                    labelProps={labelProps}
                    style={{ wordWrap: "break-word" }}
                    label={t("sale_type_and_payment")}
                    labelDescription={t("sale_types_and_payment_methods_desc")}
                >
                    <div className="tw-flex-col">
                        <div>
                            <Radio
                                name="payload.all_sale_types"
                                value={1}
                                onChange={() => form.form.setValue("payload.all_sale_types", 1)}
                            >
                                <TText tkey="all_sale_type_and_payment" />
                            </Radio>
                        </div>
                        <div>
                            <Radio
                                name="payload.all_sale_types"
                                value={0}
                                onChange={() => form.form.setValue("payload.all_sale_types", 0)}
                            >
                                <TText tkey="select_sale_types_and_payment_methods" />
                            </Radio>
                        </div>
                    </div>
                    {enableAllSaleTypes === 0 ? (
                        <>
                            <Divider />
                            {saleType.isLoading ? (
                                <>Loading...</>
                            ) : (
                                <>
                                    {saleType?.data?.length &&
                                        saleType?.data?.map((saleType: any, index: number) => (
                                            <SaleTypeField
                                                key={saleType.id}
                                                saleType={saleType}
                                                index={index}
                                                form={form}
                                                paymentMethod={paymentMethod.data}
                                            />
                                        ))}
                                </>
                            )}
                        </>
                    ) : null}
                </RadioFormGroup>
                <CCheckTreePickerFormGroup
                    name="payload.excluded_products_categories"
                    data={categories?.tree}
                    label={t("exclude_categories")}
                    labelDescription={t("exclude_category_description")}
                    placeholder={t("select_category")}
                    labelProps={labelProps}
                    style={{ wordWrap: "break-word" }}
                    className="tw-my-3"
                    compProps={{
                        cascade: "child",
                        placement: "bottom",
                        style: {
                            maxWidth: 350,
                            width: 350,
                        },
                    }}
                />
                <div className="tw-flex tw-p-0">
                    <div style={labelProps.style}>
                        <div className="tw-mr-3">
                            {t("exclude_products")}

                            <div className="tw-text-secondary tw-text-sm tw-break-words">
                                {t("exclude_products_description")}
                            </div>
                        </div>
                    </div>
                    <div className="tw-ml-3">
                        <div
                            className={cn(
                                "tw-flex tw-relative tw-items-center tw-w-input-xl tw-h-[40px] tw-rounded-lg tw-py-2 tw-pr-6 tw-pl-2 tw-cursor-pointer tw-border tw-border-solid tw-border-border",
                                {
                                    "tw-text-disabled": !selectedProducts.length,
                                },
                            )}
                            onClick={() => {
                                dispatch(setSelectedProducts(selectedProducts));
                                dispatch(setOpenProductSelector(true));
                            }}
                        >
                            <div
                                style={{
                                    // width: "300px",
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {selectedProducts.length
                                    ? selectedProducts
                                          ?.map((id: number) => {
                                              const product = productsById?.[id];
                                              return product?.full_name;
                                          })
                                          ?.join(",")
                                    : t("select", { name: t("product") })}
                            </div>
                            {selectedProducts.length ? (
                                <div
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        form.form.setValue("excluded_products", []);
                                    }}
                                    style={{ position: "absolute", right: "10px", top: "10px" }}
                                >
                                    <CIcon path={iClose} />
                                </div>
                            ) : null}
                        </div>
                        {!!selectedProducts.length && (
                            <Button
                                onClick={() => {
                                    dispatch(setShouldShowSelected(true));
                                    dispatch(setSelectedProducts(selectedProducts));
                                    dispatch(setOpenProductSelector(true));
                                }}
                                loading={false}
                                className="tw-mt-4 tw-bg-accent tw-text-primary"
                                ripple={false}
                                style={{
                                    width: 350,
                                    maxWidth: 350,
                                }}
                            >
                                <TText tkey="selected_products" />
                                <CIcon path={iChevronRight} size={0.4} className="ml-2" />
                            </Button>
                        )}
                    </div>
                </div>
                <TextAreaFormGroup
                    label={t("loyalty_desc_label")}
                    labelProps={labelProps}
                    style={{ wordWrap: "break-word" }}
                    compProps={{
                        style: { width: 350, height: 100 },
                        placeholder: t("loyalty_text_description"),
                    }}
                    className="tw-mb-3 tw-mt-4"
                    name="payload.description"
                />
            </CForm>
            <CProductsDrawer />
        </div>
    );
};

export default LoyaltyPage;
