import CDrawer from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import TabsView from "components/TabsView";
import { useData } from "hooks/useData";
import _ from "lodash";
import { iSpinner } from "assets/icons/Icons";
import CustomerDetails from "../../pages/marketing/customer/CustomerDetails";
import CIcon from "../CIcon";

import { ICDrawerProps } from "components/Drawers/CDrawer";
type IProps = ICDrawerProps;

// const date = {};
const CustomerDrawer: FCC<IProps> = props => {
    // const auth = useAuth();
    const r = useData<ICustomer>(!!props.dataId && `customer/${props.dataId}`, {
        with: ["group", "balance", "bonus", "cashback_balance"],
        params: {
            sort: ["created_at", "1"],
        },
    });

    // const dp = useDataProvider({
    //     url: "finance/transaction",
    //     filters: {
    //         // "indicator": ["indicator", 0],
    //         //"indicator": ["indicator", 1],
    //         customer_id: ["customer_id", props.dataId ?? 0],
    //     },
    //     stop: !auth.hasAbility(["finance_transaction_view"]),
    //     params: date,
    //     with: [
    //         "category",
    //         "balance",
    //         "staff",
    //         "user",
    //         "operation.supplier",
    //         // "operation.stock_operations.product",
    //         "from_balance",
    //         "supplier",
    //         "customer",
    //     ],
    //     sort: ["id", "-1"],
    // });
    // date = { date: dp.params?.all?.date };
    const refineData = _.omit(r.data, ["phone", "address", "deleted_at", "created_at", "updated_at"]) as ICustomer;
    return (
        <CDrawer
            size="md"
            title={`${t("customer")} #${props.dataId} : ${refineData.name}`}
            {...props}
            // dp={dp}
            // calendar
        >
            <div className="">
                {!r.isLoading && r.data ? (
                    <TabsView
                        tabs={[
                            {
                                label: t("customer_details"),
                                Component: CustomerDetails,
                                props: { customer: refineData },
                            },
                            // {
                            //     label: t("transactions"),
                            //     Component: TransactionDetails,
                            //     props: { supplier: dp, summary, summaryFake, showUnpaidAmount: true },
                            // },
                        ]}
                    />
                ) : (
                    <CIcon path={iSpinner} spin className="m-3" />
                )}
            </div>
        </CDrawer>
    );
};

export default CustomerDrawer;
