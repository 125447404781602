import ToggleFormGroup from "components/Form/ToggleFormGroup";
import _ from "lodash";

import TextFormGroup from "components/Form/TextFormGroup";
import { useAppSelector } from "hooks/useRedux";
import { selectSettings } from "redux/features/settings";

const TerminalSettingsTab2: FCC = props => {
    const settings = useAppSelector(selectSettings);
    //TODO: add filter for app_discount after change terminal delete
    // const names = _.filter(
    //     settings,
    //     s => s.group === "admin_password_require" && s.name !== "ap_add_discount" && s.name !== "ap_clear_cache",
    // ).map(s => s.name);
    const names = _.filter(settings, s => s.group === "admin_password_require" && s.name === "ap_remove_product").map(
        s => s.name,
    ); //"ap_add_client"
    return (
        <div className="p-3">
            <TextFormGroup name="admin_password.value" />
            {names.map(name => (
                <ToggleFormGroup key={name} name={name + ".value"} />
            ))}
        </div>
    );
};

export default TerminalSettingsTab2;
