import { t } from "lib/i18n";
import { array, number, object, string } from "yup";

import { v4 } from "uuid";

export const dummyCustomer = (): Partial<ICustomer> => {
    return {
        name: "",
        email: "",
        phone: "",
        phones: [],
        addresses: [""],
        description: "",
        group_id: null,
        gender: 0,
        date_of_birth: "",
        code: "",
        cid: v4(),
    };
};

export const CustomerModel = () =>
    object().shape({
        name: string().required(t("required")),
        email: string().email(t("not_valid_email")),
        code: string(),
        cid: string(),
        phones: array().of(
            object({
                phone: string()
                    .nullable()
                    .matches(/^((?!_).)*$/, t("complete_phone")),
            }),
        ),
        phone: string(),
        addresses: array().of(string().nullable()),
        description: string(),
        group_id: string().nullable(),
        gender: string().nullable(),
        date_of_birth: string().matches(/^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/, {
            message: t("date_format"),
            excludeEmptyString: true,
        }),
    });

export const CustomerGroupModel = () =>
    object().shape({
        name: string().required(t("required")),
        discount_value: number().min(0, t("must_be_greater_equal_0")).typeError(t("required")),
    });

export const dummyCustomerGroup = (): Partial<ICustomerGroup> => {
    return {
        name: "",
        discount_value: 0,
    };
};
