import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { useAppSelector } from "hooks/useRedux";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { Colors } from "config/colors";
import CIcon from "components/CIcon";
import { iCheckBoxChecked, iClose, iSearch } from "assets/icons/Icons";
import Highlighter from "react-highlight-words";
import { Input, InputGroup } from "rsuite";
import DataTable from "components/DataTable/DataTable";
import { useRemoteConfig } from "hooks/useRemoteConfig";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { convertToSlug } from "lib/convertToSlug";

interface ISelectGovPackageCodeDrawerProps extends ICDrawerProps {
    govCode: string;
    govPackageCode: string;
}

export const SelectGovPackageCodeDrawer: FC<ISelectGovPackageCodeDrawerProps> = props => {
    const govCodes = useRemoteConfig("govCodeUz").config as IGovCodeUzScheme;
    const { language } = useAppSelector(state => state.app.brandData);
    const [searchText, setSearchText] = useState("");
    const searchTextTrim = convertToSlug(searchText);

    const form = useFormContext();
    const selectedGovCode = form.getValues(props.govCode);
    const selectedGovPackageCode = form.getValues(props.govPackageCode);

    const codes = language === "ru" ? govCodes["ru"] : govCodes["default"];
    const packageCodes = codes?.find(g => g.gov_code === selectedGovCode)?.gov_package_code;

    const cm = useColumns<IGovPackageCode>("gov_package_code", [
        {
            title: t("name"),
            key: "gov_code_name",
            flexGrow: 3,
            fullText: true,
            render: row => (
                <div className="d-flex align-items-center tw-gap-2">
                    {row.code === selectedGovPackageCode && (
                        <div
                            className="d-flex align-items-center rounded-circle justify-content-center flex-shrink-0"
                            style={{ background: Colors.CobaltBlue, width: "16px", height: "16px" }}
                        >
                            <CIcon
                                className="ps-checked-icon"
                                path={iCheckBoxChecked}
                                style={{ color: "white" }}
                                size={0.45}
                            />
                        </div>
                    )}
                    <Highlighter
                        highlightClassName="search-highlighter"
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={row.name || "─"}
                    />
                </div>
            ),
        },
        {
            title: t("gov_code"),
            key: "gov_code",
            flexGrow: 1,
            render: row => (
                <Highlighter
                    highlightClassName="search-highlighter"
                    searchWords={[searchText]}
                    autoEscape={true}
                    textToHighlight={row.code || "─"}
                />
            ),
        },
    ]);

    return (
        <CDrawer size={"md"} title={t("gov_code")} {...props}>
            <div className={"tw-px-4 tw-py-1 tw-mb-4"}>
                <InputGroup inside>
                    <Input
                        test-id="product-selector-search-input"
                        autoFocus={true}
                        onChange={setSearchText}
                        size="lg"
                        value={searchText}
                        placeholder={t("search")}
                    />
                    <InputGroup.Addon>
                        {searchText ? (
                            <CIcon className="tw-cursor-pointer" onClick={() => setSearchText("")} path={iClose} />
                        ) : (
                            <CIcon path={iSearch} />
                        )}
                    </InputGroup.Addon>
                </InputGroup>
            </div>
            {packageCodes ? (
                <DataTable<IGovPackageCode>
                    dp={
                        {
                            data: packageCodes.filter(
                                code =>
                                    convertToSlug(code.name).includes(searchTextTrim) ||
                                    code.code.includes(searchTextTrim),
                            ),
                        } as any
                    }
                    columns={cm.columns}
                    tableProps={{
                        rowClassName: "tw-cursor-pointer",
                        autoHeight: true,
                        onRowClick: row => {
                            form.setValue(props.govPackageCode, row.code);
                            props.hide();
                        },
                    }}
                />
            ) : (
                <div className={"tw-px-4"}>{t("gov_package_code_not_found_for_gov_code")}</div>
            )}
        </CDrawer>
    );
};
