import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";

import DataTable from "components/DataTable/DataTable";
import { useDataProvider } from "components/DataTable/useDataProvider";
import _ from "lodash";
import CellBtn from "../../../../components/DataTable/CellButton";
import { useHistory } from "react-router-dom";

interface IProps {
    dataId: any;
}

const InUseModifier: FCC<IProps> = ({ dataId }) => {
    const history = useHistory();

    const dp = useDataProvider({
        // stop: !dataId,
        url: `product/modificator`,
        filters: {
            ingredient_id: ["ingredient_id", dataId],
        },
        with: ["modificator_group"],
        refineList: list => {
            return _.uniqBy(list || [], "group_id");
        },
    });

    const modifierCm = useColumns<any>(`product/modificator`, [
        { key: "group_id", title: `#${t("group")}`, flexGrow: undefined, width: 100 },
        {
            key: "modificator_group.name",
            title: `${t("group")} ${t("name")}`,
            render: e => (
                <CellBtn
                    action={() => history.push(`/menu/manageModifiers/edit/${e.modificator_group.id}`)}
                    label={e.modificator_group.name}
                />
            ),
        },
        { key: "brutto", title: t("use_volume"), flexGrow: undefined, width: 100 },
        { key: "cost_price", title: `${t("cost_price")}`, flexGrow: undefined, width: 100 },
    ]);

    return (
        <div>
            <DataTable dp={dp} columns={modifierCm.columns} noShadow />
        </div>
    );
};

export default InUseModifier;
