import { t } from "lib/i18n";
import { FORMATS } from "config/constants";
import { yesterday } from "config/dates";
import { passCashRestartCheck } from "lib/passCashRestartCheck";
import { DatePicker, DatePickerProps, Form } from "rsuite";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import React from "react";
import { usePopupContainer } from "hooks/usePopupContainer";
import { useSetting } from "hooks/useSetting";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import { format } from "date-fns";

interface DataPickerForm {
    "test-id"?: string;
}

interface IProps extends CFormGroupProps<DatePickerProps | DataPickerForm> {
    time?: boolean;
    resetData?: boolean;
    width?: number;
}

const DatePickerFormGroup: FCC<IProps> = ({ time, width, ...props }) => {
    const ctx = useCFormContext();
    const error = ctx ? props.priorityError || ctx.getFieldState(props.name).error?.message : props.error;

    const cashRestart = useSetting("cash_restart")?.value;
    const accurateToday = passCashRestartCheck(cashRestart);
    const inputWidth = width ? width : 300;
    const { container } = usePopupContainer();

    return (
        <CFormGroup
            {...(props as any)}
            compProps={{
                render: ({ field: { onChange, value } }: any) => {
                    return (
                        <>
                            <DatePicker
                                container={() => container.current!}
                                test-id={props.name}
                                defaultValue={accurateToday}
                                disabled={props.disabled || ctx.disabled}
                                placement={"autoVerticalStart"}
                                value={value ? new Date(value) : new Date()}
                                onChange={onChange}
                                style={{ width: inputWidth }}
                                format={time ? "yyyy-MM-dd HH:mm" : "yyyy-MM-dd"}
                                renderValue={v => format(v, time ? FORMATS.datetime : FORMATS.date)}
                                ranges={[
                                    {
                                        label: t("today"),
                                        value: accurateToday,
                                        "test-id": "calendar-today",
                                    } as any,
                                    { label: t("yesterday"), value: yesterday() },
                                ]}
                                {...props.compProps}
                            />
                            <Form.ErrorMessage show={!!error} placement={"bottomStart"} style={{ zIndex: 1050 }}>
                                <span>{error}</span>
                            </Form.ErrorMessage>
                        </>
                    );
                },
            }}
        />
    );
};

export default DatePickerFormGroup;
