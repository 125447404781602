import { ProductType } from "config/constants";

export type SortType = "asc" | "desc" | undefined;

export const getResultDataByType = (
    result: number[],
    productsById: Record<number, IProduct>,
    filterProductType: string[],
) => {
    const resultData: IProduct[] = [];
    for (let i = 0; i < result.length; i++) {
        const product = productsById[result[i]];
        if (!product) continue;
        if (product?.has_modifications) continue;
        if (filterProductType.includes(product.type)) {
            resultData.push(product);
        } else if (product.type === ProductType.MODIFICATION && filterProductType.includes(ProductType.GOODS)) {
            resultData.push(product);
        }
    }
    return resultData;
};
// this function for calculate count of matching elements in two arrays
export const countMatchingElements = (arr1: number[], arr2: number[]) => {
    let count = 0;
    const set2 = new Set(arr2);

    for (const elem1 of arr1) {
        if (set2.delete(elem1)) {
            count++;
        }
    }

    return count;
};
