import { DiscountType } from "@cloposcom/receipt-utils/dist/receipt-calculation/interfaces";
import { IDbReceiptProduct } from "@cloposcom/receipt-utils/src/receipt-calculation/interfaces";
import { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { FC, useEffect, useState } from "react";
import { addDiscountToLine, recalculateAll, recalculateReceipt } from "redux/features/webCashier";
import { changeModalState } from "redux/features/webCashierAdditionals";
import { store } from "redux/store";
import { Button, Modal, SelectPicker } from "rsuite";
import ReceiptNumberInput from "../ReceiptItemNumberInput";
import { useCalculationSetting } from "hooks/useCalculationSetting";
import { useAppDispatch } from "hooks/useRedux";

interface IProps extends ICDrawerProps {
    detail: {
        product: IDbReceiptProduct | undefined;
        maxPrice: number;
        index: number;
        open: boolean;
        type: string;
    };
}

const discountTypes: Array<{
    value: number;
    label: string;
}> = [
    {
        value: DiscountType.PERCENT,
        label: t("percentage"),
    },
    {
        value: DiscountType.DIRECT,
        label: t("amount"),
    },
];

const ProductDiscountModal: FC<IProps> = ({ detail }) => {
    const { product } = detail;
    const [discountType, setDiscountType] = useState(DiscountType.PERCENT);
    const [count, setCount] = useState(1);
    const [value, setDiscountValue] = useState<any>(null);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setDiscountType(
            product?.discount_type === 1 || product?.discount_type === 0 ? DiscountType.PERCENT : DiscountType.DIRECT,
        );
        setDiscountValue(product?.discount_type === 1 ? product.discount_rate : product?.discount_value);
    }, [product]);

    const productsById = store.getState().data.products.byId;
    const _product = productsById[product?.product_id ?? 0];
    const parentProduct = _product.parent_id ? productsById[_product.parent_id] : undefined;

    const calculationSetting = useCalculationSetting();

    const addDiscount = () => {
        if (discountTypes && count && value !== null) {
            dispatch(
                addDiscountToLine({
                    product: product!,
                    discountValue: value,
                    discountType: discountType,
                    count: count,
                    _product,
                    parentProduct,
                    setting: calculationSetting,
                }),
            );
            closeModal();
            dispatch(recalculateAll({ setting: calculationSetting, product: product! }));
            dispatch(recalculateReceipt({ setting: calculationSetting }));
        }
    };

    const closeModal = () => {
        dispatch(changeModalState({ ...detail, open: false, title: "" }));
    };

    return (
        <>
            <Modal.Body>
                <div className="discount-form">
                    <div className="count">
                        <ReceiptNumberInput
                            min={1}
                            step={0}
                            max={product?.count}
                            value={count}
                            name="discount_count"
                            onChange={(count: number) => setCount(count)}
                        />
                    </div>

                    <div className="value-label">
                        <TText tkey="discount" />
                    </div>
                    <div className="value">
                        <div className="value-input">
                            <ReceiptNumberInput
                                min={0}
                                step={2}
                                inputID={"poduct_discount_modal_" + product?.id}
                                max={discountType === DiscountType.PERCENT ? 100 : product?.price}
                                value={value}
                                hideButtons={true}
                                name="discount_value"
                                placeholder={t("discount")}
                                onChange={(count: number) => setDiscountValue(count as any)}
                            />
                        </div>
                        <div className="type-selector">
                            <SelectPicker
                                data={discountTypes}
                                labelKey={"label"}
                                valueKey={"value"}
                                value={discountType}
                                name="discount_type"
                                searchable={false}
                                cleanable={false}
                                onSelect={value => {
                                    setDiscountType(value);
                                    setDiscountValue(0);
                                }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" color="green" test-id="add-discount" onClick={() => addDiscount()}>
                    <TText tkey="add" />
                </Button>
            </Modal.Footer>
        </>
    );
};

export default ProductDiscountModal;
