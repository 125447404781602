import { Col, FlexboxGrid } from "rsuite";
import { iMapMarker } from "assets/icons/Icons";
import CIcon from "../../../../components/CIcon";
import { t } from "lib/i18n";

interface IProps {
    addresses: any;
}

const CustomerAddresses: FCC<IProps> = ({ addresses }) => {
    return (
        <div className="px-5 pt-2">
            <FlexboxGrid>
                {addresses.map((address: string, index: number) => (
                    <FlexboxGrid.Item as={Col} colspan={24} md={12}>
                        <div className="d-flex px-4 py-3 align-items-center address-box">
                            <CIcon path={iMapMarker} size={2} />
                            <div className="pl-4">
                                <h5 className="font-weight-normal tw-text-[#475f7b]">{`${t("address")} #${++index}`}</h5>
                                <p className="font-weight-normal tw-text-[#475f7b]">{address}</p>
                            </div>
                        </div>
                    </FlexboxGrid.Item>
                ))}
            </FlexboxGrid>
        </div>
    );
};

export default CustomerAddresses;
