import { t } from "lib/i18n";
import { array, boolean, number, object } from "yup";

const isFloat = (value: number): boolean => (typeof value === "number" ? !Number.isInteger(value) : false);
const transformFn = (value: number, originalValue: number | string): number | undefined => {
    if (typeof originalValue === "string") {
        if (originalValue.length === 0) return;
    } else if (isFloat(value)) {
        const v = `${value}`;
        return +v;
    } else {
        return value;
    }
};

export const model = object().shape({
    payload: object().shape({
        rates: object().shape({
            default: number()
                .min(0, t("min_allowed_num"))
                .transform(transformFn)
                .max(100, t("max_percent"))
                .required(t("required"))
                .test(
                    "is-decimal",
                    () => {
                        return t("max_decimal_allowed", {
                            max: "2",
                        });
                    },
                    value => /^\d+\.?\d{0,2}$/.test(`${value}`),
                ),
        }),
        daily_earn_limit: object()
            .shape({
                status: boolean(),
                receipt_count: number()
                    .integer(t("write_integer"))
                    .min(0, t("min_allowed_num"))
                    .transform(transformFn)
                    .required(t("required")),
                receipt_amount: number()
                    .integer(t("write_integer"))
                    .min(0, t("min_allowed_num"))
                    .transform(transformFn)
                    .required(t("required")),
            })

            .nullable(),
        daily_spend_limit: object()
            .shape({
                status: boolean(),
                receipt_count: number()
                    .integer(t("write_integer"))
                    .min(0, t("min_allowed_num"))
                    .transform(transformFn)
                    .required(t("required")),
                receipt_amount: number()
                    .integer(t("write_integer"))
                    .min(0, t("min_allowed_num"))
                    .transform(transformFn)
                    .required(t("required")),
            })
            .nullable(),
        sales_types: array(),
    }),
});
