import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React from "react";
import { Button } from "rsuite";
import "./PriceListDrawer.scss";
import CIcon from "../../../../components/CIcon";
import { iClose, iPlus } from "assets/icons/Icons";
import TextFormGroup from "../../../../components/Form/TextFormGroup";
import SelectFormGroup from "../../../../components/Form/SelectFormGroup";
import { useFieldArray } from "react-hook-form";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

const ProductPricesItems: FCC<any> = ({ itemsIndex, modificationId, priceListsData }) => {
    const { control, watch } = useCFormContext();
    const { remove, append } = useFieldArray({
        control,
        name: `product_prices.${itemsIndex}`,
    });

    const productPriceValues = watch(`product_prices[${itemsIndex}]`) ?? [];

    const addNewPrice = () => {
        const value = {
            product_id: modificationId,
            list_id: null,
            price: 0,
            tempId: Math.random(),
        };
        append(value);
    };

    return (
        <>
            {productPriceValues?.map((modification: any, index: number) => {
                return (
                    <div className="input-section" key={modification?.id || modification.tempId}>
                        <SelectFormGroup
                            name={`product_prices[${itemsIndex}][${index}].list_id`}
                            className="select-list"
                            isItemDisabled={d => productPriceValues?.find((t: IMultiPrice) => t.list_id === d.id)}
                            placeholder={t("select_price_list")}
                            label={false}
                            data={priceListsData}
                        />
                        <TextFormGroup
                            name={`product_prices[${itemsIndex}][${index}].price`}
                            type="money"
                            key={"money_" + itemsIndex + "_" + index}
                            controlId={"money_" + itemsIndex + "_" + index}
                            label={false}
                            compProps={{ style: { width: "100%" } }}
                        />
                        <button type="button" onClick={() => remove(index)}>
                            <CIcon path={iClose} />
                        </button>
                    </div>
                );
            })}
            <div className="footer-section">
                <Button type="button" onClick={() => addNewPrice()}>
                    <CIcon path={iPlus} />
                    <span>
                        <TText tkey="add" />
                    </span>
                </Button>
            </div>
        </>
    );
};

export default ProductPricesItems;
