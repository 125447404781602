import { t } from "lib/i18n";
import { FORMAT_ISO_DATE } from "config/dates";
import { prepareArrayWithDates } from "lib/chart-utils";
import SaleSectionWidget from "pages/dashboard/SaleSectionWidget";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { Col } from "rsuite";
import CSection from "../../components/CSection";
import { passCashRestartCheck } from "lib/passCashRestartCheck";
import { useSetting } from "hooks/useSetting";
import { format, subDays } from "date-fns";

/**
 * Pair of [name, translation_key]
 */
export const tabs = [
    ["total_cash", "total_cash"],
    ["total_profit", "total_profit"],
    ["average_cash", "average_receipt"],
    ["total_receipt", "receipts"],
    ["total_guests", "total_guests"],
    ["average_time", "average_time"],
] as const;

export const tabs2 = [
    ["total_service_charge", "total_service_charge"],
    ["total_discount", "total_discount"],
    ["total_tax", "tax"],
    ["total_refunded", "returned_total"],
    ["mistakes_receipts_count", "unpaid_closed_receipts"],
    ["total_delivery_fee", "delivery_fee"],
] as const;

const SaleSection: FCC = () => {
    const cashRestart = useSetting("cash_restart")?.value;
    const checkStart = passCashRestartCheck(cashRestart);
    const checkEnd = subDays(passCashRestartCheck(cashRestart), 1);

    const dp = useDataProvider({
        url: "reports/date",
        params: {
            date: [format(checkEnd, FORMAT_ISO_DATE), format(checkStart, FORMAT_ISO_DATE)],
            period: "DAILY",
        },
        refineList: arr =>
            prepareArrayWithDates({
                arr,
                props: tabs.map(t => t[0]),
                range: [checkEnd, checkStart],
            }),
    });

    const dp2 = useDataProvider({
        url: "reports/common-report-for-statistics-of-sales",
        params: {},
        refineList: arr =>
            prepareArrayWithDates({
                arr,
                props: tabs2.map(t => t[0]),
                range: [checkEnd, checkStart],
            }),
    });

    return (
        <CSection title={t("sales")}>
            {tabs.map((tab, i) => (
                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={4} className="pb-3" key={i}>
                    <SaleSectionWidget key={tab[0]} tab={tab} data={dp.data} />
                </Col>
            ))}
            {tabs2.map((tab, i) => (
                <Col xs={24} sm={12} md={8} lg={6} xl={6} xxl={4} className="pb-3" key={i}>
                    <SaleSectionWidget key={tab[0]} tab={tab} data={dp2?.data} />
                </Col>
            ))}
        </CSection>
    );
};

export default SaleSection;
