import { mdiAlertCircleOutline, mdiCheckCircleOutline, mdiLoading } from "@mdi/js";
import { config } from "config/Config";
import { useLoading } from "hooks/useLoading";
import { sleep } from "lib/pure";
import React, { useEffect, useState } from "react";
import { Form, InputGroup } from "rsuite";
import CIcon from "../../components/CIcon";
import Ajax from "../../lib/Ajax";
import { IS_PR_PREVIEW, isDev } from "config/main";
import { Colors } from "config/colors";
import { t } from "lib/i18n";

interface IProps {
    setIsBrandValid: (valid: boolean) => void;
}

export const BrandInput: FCC<IProps> = props => {
    const [brand, setBrand] = useState(config.brand);
    const [state, setState] = useState<"idle" | "checked" | "error">("idle");
    const loading = useLoading();

    useEffect(() => {
        if (brand) {
            checkBrand(brand);
        }
    }, []);

    const onChange = async (value: string, e: any) => {
        if (state !== "idle") setState("idle");
        setBrand(value);
        props.setIsBrandValid(false);
        await sleep(500);
        if (value !== e.target.value) {
            return;
        }
        checkBrand(value);
    };

    function checkBrand(value: string) {
        return loading.withLoading(async () => {
            try {
                const resp = await Ajax.get({ url: `https://${value}.clopos.com/api/check` });
                // const resp = await fetch(`https://${value}.clopos.com/api/check`).then(r => r.json());
                const isExists = !!resp?.success;
                if (!isExists) {
                    setState("error");
                } else {
                    setState("checked");
                    props.setIsBrandValid(true);
                    config.setBrand(value);
                    redirectToBrandStage(resp.data[0].stage);
                    // window.location.replace(`https://${value}.clopos.com/`);
                    // window.history.replaceState({}, "", `/${value}/`);
                }
            } catch (e) {
                setState("error");
            }
        });
    }

    const icon = loading.isLoading
        ? mdiLoading
        : state === "error"
          ? mdiAlertCircleOutline
          : state === "checked"
            ? mdiCheckCircleOutline
            : "";
    const color = loading.isLoading
        ? Colors.LightSlateGray
        : state === "error"
          ? Colors.VividRed
          : state === "checked"
            ? Colors.ForestGreen
            : Colors.LightSlateGray;
    return (
        <Form layout="vertical" className="px-5 mb-5">
            <Form.Group>
                <Form.ControlLabel style={{ color: Colors.CharcoalBlue }}>{t("brand")}</Form.ControlLabel>
                <InputGroup inside className="w-100" size="lg">
                    <Form.Control
                        name="brand"
                        onChange={onChange}
                        value={brand}
                        placeholder={t("brand")}
                        errorMessage={state === "error" ? "Brand not found" : ""}
                        className="rounded-sm py-2"
                        style={{ borderColor: color }}
                    />
                    <InputGroup.Addon style={{ padding: 10, height: "100%" }}>
                        <CIcon style={{ color }} size={1.4} path={icon} spin={loading.isLoading} />
                    </InputGroup.Addon>
                </InputGroup>
            </Form.Group>
        </Form>
    );
};
export const redirectToBrandStage = (stage: string) => {
    const currentUrl = window.location.href;
    let correctPath = "";
    config.setBrandStage(stage);
    if (isDev || IS_PR_PREVIEW) {
        correctPath = `/${config.brand}/`;
    } else {
        if (stage === "prod") {
            correctPath = `https://client.clopos.com/${config.brand}/`;
        } else {
            // console.log(config.brand);
            correctPath = `https://client-${stage}.clopos.com/${config.brand}/`;
        }
    }

    // const hasRedirected = depot.getItem("stage_redirect", sessionStorage);
    if (currentUrl.indexOf("token=") > -1 || currentUrl.indexOf(correctPath) === -1) {
        // if (allowedStages?.[stage]?.includes(currentStage) && !isDev) return;
        // console.log("Попытка перенаправления на:", correctPath);
        window.location.href = correctPath;
    }
};
