import React, { CSSProperties } from "react";
import _ from "lodash";

interface IProps {
    media: any[];
    color: string | null;
    name: string;
    style?: CSSProperties;
}

const MenuMedia: FCC<IProps> = ({ media, color, name, style }) => {
    return !_.isEmpty(media) ? (
        <div
            style={{
                marginTop: "-0.4rem",
                background: `url(${media[0].urls.thumb})`,
                backgroundSize: "cover",
                height: 30,
                width: 50,
                borderRadius: "0.25rem",
                ...style,
            }}
        />
    ) : (
        <div
            style={{
                marginTop: "-0.4rem",
                backgroundColor: `#${color}`,
                height: 30,
                width: 50,
                borderRadius: "0.25rem",

                ...style,
            }}
        />
    );
};

export default MenuMedia;
