import { createSlice } from "@reduxjs/toolkit";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

export interface AppState {
    isFullScreen: boolean;
    isSidebarExpanded: boolean;
}

const initialState: AppState = {
    isFullScreen: false,
    isSidebarExpanded: true,
};

export const uiSlice = createSlice({
    name: ReduxSlicesNames.UI,
    initialState,
    reducers: {
        toggleIsFullScreen: (state, action) => {
            state.isFullScreen = action.payload;
        },
        toggleIsSidebarExpanded: state => {
            state.isSidebarExpanded = !state.isSidebarExpanded;
        },
    },
});

export const { toggleIsFullScreen, toggleIsSidebarExpanded } = uiSlice.actions;
export default uiSlice.reducer;
