import { TText } from "components/i18n/TText";
import React from "react";

import { Input } from "rsuite";

const RQrCodeComp = () => {
    return (
        <div className="d-flex align-items-center justify-content-between w-100">
            <div>
                <TText tkey="qr_code" />
            </div>
            <Input size="sm" style={{ width: "144px" }} placeholder="QR text"></Input>{" "}
            <Input size="sm" style={{ width: "144px" }} className="mr-3" placeholder="Description"></Input>
        </div>
    );
};

export default RQrCodeComp;
