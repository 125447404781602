import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { FormItems } from "components/FormItems/FormItems";
import { FormItemsAddButton } from "components/FormItems/FormItemsAddButton";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { GridItemTotal } from "components/NewForm/Grid/GridItemTotal";
import _ from "lodash";
import { useEffect } from "react";
import { Message, toaster } from "rsuite";
import CDrawerHeaderRight from "components/Drawers/CDrawerHeaderRight";
import CForm from "components/Form/CForm/CForm";
import JsonView from "components/JsonView";
import { useCForm } from "hooks/useCForm";
import { useData } from "hooks/useData";
import { dummyPaymentMethod, dummyPaymentMethods } from "models/ReceiptPaymentMethodModel";
import { useCDrawerId } from "providers/CDrawerProvider";
import PaymentItem, { PaymentItemField } from "./misc/PaymentItem";
import { ReceiptRepriceScheme } from "form-schemas/ReceiptRepriceScheme";
import { withDrawer } from "hocs/withDrawer";
import { useCurrency } from "hooks/useCurrency";

interface IProps extends ICDrawerProps {}

const PaymentChange: FCC<IProps> = props => {
    const receipt = useData(!!props?.dataId && `receipt/${props.dataId}`);
    const { id, setId } = useCDrawerId();
    const currency = useCurrency();

    useEffect(() => {
        setId(2);
    }, []);
    const form = useCForm<IReceipt>({
        url: `receipt/${props?.dataId}/reprice`,
        methodOnSubmit: "put",
        mode: "all",
        redirectUrl: false,
        // model: PaymentMethods,
        dummyData: dummyPaymentMethods,
        model2: ReceiptRepriceScheme,
        prepareForSubmit: (d: any) => {
            return { payment_methods: d.payment_methods };
        },
        afterSubmit: d => {
            props.dp?.revalidate();
            props.hide?.();
            return toaster.push(
                <Message type="success" showIcon closable>
                    <TText tkey="success" />
                </Message>,
                { duration: 4000 },
            );
        },
    });

    useEffect(() => {
        form.set("url", `receipt/${props.dataId}/reprice`);
        // eslint-disable-next-line
    }, [props.dataId]);

    useEffect(() => {
        const data = receipt.data?.payment_methods || [];
        form.form.setValue("receipt", receipt.data);
        form.form.setValue(
            "payment_methods",
            data?.filter((pm: { amount: number }) => pm.amount),
        );
    }, [receipt.data]);

    return (
        <CDrawer
            size="sm"
            title={`#${props.dataId} (${t("total")}: ${receipt.data?.total})`}
            {..._.omit(props, ["dp"])}
        >
            <div className="ml-3">
                <CDrawerHeaderRight drawerId={id} show saveIconBtn formSubmit={form} />
                <CForm {...form} formProps={{ layout: "vertical" }} noShadow noPanel>
                    <JsonView />
                    <FormItems itemsFieldName="payment_methods">
                        <FormItemsList<PaymentItemField>
                            columns={[["payment_method"], ["amount", "right"], [""]]}
                            children={_props => {
                                return <PaymentItem {..._props} />;
                            }}
                            footer={
                                <div className="tw-grid tw-grid-cols-subgrid tw-col-span-2">
                                    <div className="tw-flex tw-gap-2 tw-justify-between">
                                        <FormItemsAddButton dummyData={dummyPaymentMethod} />
                                        <GridItemPlain
                                            align="right"
                                            className="tw-bg-transparent tw-border-none"
                                            value={t("total") + ":"}
                                        />
                                    </div>
                                    <GridItemTotal name="amount" postfix={currency} decimals={2} />
                                </div>
                            }
                        />
                    </FormItems>
                    <span style={{ color: "red" }}>
                        {form?.form?.formState.errors?.payment_methods?.message as string}
                    </span>
                </CForm>
            </div>
        </CDrawer>
    );
};

export default withDrawer(PaymentChange);
