import { useData } from "hooks/useData";
import _ from "lodash";
import { useEffect, useState } from "react";
import CBulkTagPickerFormGroup from "../../../components/Form/CBulkTagPickerFormGroup";
import { UseSettingsFormReturnType } from "../useSettingsForm";

interface IProps {
    form: UseSettingsFormReturnType;
    storages: IStorage[];
}

const StorageConfig: FCC<IProps> = ({ form, storages }) => {
    const stations = useData<IStorage[]>("station", {
        params: {
            limit: 100, // todo: makeUseReduxModelData ile data hooku yaradilsin
        },
    });
    const terminals = useData<IStorage[]>("terminal");
    const storage_rules = form.form.watch("brand_storage_select.value");
    const storageIds = form.form.watch("brand_storage.value");
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        const refinedData: any[] = [];
        if (stations.data?.length && terminals.data?.length) {
            terminals.data?.forEach(terminal => {
                stations.data?.forEach(station => {
                    refinedData.push({
                        ...terminal,
                        station,
                    });
                });
            });

            setData(refinedData);
        }
        // eslint-disable-next-line
    }, [stations.data, terminals.data]);

    // TODO: Should be rewritten
    const filterActiveStorages = (value: number[], key: string, terminalStorage: any) => {
        const _terminalStorage = _.cloneDeep(terminalStorage);
        return (_terminalStorage[key] = value ? value.filter(v => storageIds.includes(v)) : []);
    };

    useEffect(() => {
        switch (storage_rules) {
            case "0":
                form.form.setValue("brand_storage_terminal.value", {});
                form.form.setValue("brand_storage_station.value", {});
                form.form.setValue("brand_storage_terminal_station.value", {});
                break;
            case "1":
                form.form.setValue("brand_storage_terminal.value", {});
                form.form.setValue("brand_storage_terminal_station.value", {});
                break;
            case "2":
                form.form.setValue("brand_storage_station.value", {});
                form.form.setValue("brand_storage_terminal_station.value", {});
                break;
            case "3":
                form.form.setValue("brand_storage_terminal.value", {});
                form.form.setValue("brand_storage_station.value", {});
                break;
            default:
                break;
        }
        // eslint-disable-next-line
    }, [storage_rules]);

    useEffect(() => {
        const terminalStorage = form.form.getValues("brand_storage_terminal.value");
        const stationStorage = form.form.getValues("brand_storage_station.value");
        const bothStorage = form.form.getValues("brand_storage_terminal_station.value");

        _.forEach(terminalStorage, (value: number[], key) => filterActiveStorages(value, key, terminalStorage));
        _.forEach(stationStorage, (value: number[], key) => filterActiveStorages(value, key, stationStorage));
        _.forEach(bothStorage, (value: number[], key) => filterActiveStorages(value, key, bothStorage));

        form.form.setValue("brand_storage_terminal.value", terminalStorage);
        form.form.setValue("brand_storage_station.value", stationStorage);
        form.form.setValue("brand_storage_terminal_station.value", bothStorage);
        // eslint-disable-next-line
    }, [storageIds]);

    const rules = (sr: string) => {
        switch (sr) {
            case "1":
                return (
                    <CBulkTagPickerFormGroup
                        options={storages || []}
                        data={stations.data || []}
                        form={form}
                        reset={storage_rules}
                        columns={["station", "storage"]}
                        name={"brand_storage_station.value"}
                    />
                );
            case "2":
                return (
                    <CBulkTagPickerFormGroup
                        options={storages || []}
                        data={terminals.data || []}
                        form={form}
                        reset={storage_rules}
                        columns={["terminal", "storage"]}
                        name={"brand_storage_terminal.value"}
                    />
                );
            case "3":
                return (
                    <CBulkTagPickerFormGroup
                        options={storages || []}
                        data={data || []}
                        form={form}
                        columns={["terminal", "station", "storage"]}
                        reset={storage_rules}
                        name={"brand_storage_terminal_station.value"}
                    />
                );
            default:
                return null;
        }
    };

    return rules(storage_rules);
};

export default StorageConfig;
