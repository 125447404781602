import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import TabsView from "components/TabsView";
import PreparationIngredientTab from "pages/menu/preparations/drawer/tabs/PreparationIngredientTab";
import { useEffect, useState } from "react";
import { useData } from "hooks/useData";
import Ajax from "../../../../lib/Ajax";
import UsageTabBeta from "./tabs/UsageTabBeta";

type IProps = ICDrawerProps;

const PreparationDrawer: FCC<IProps> = props => {
    const url = `product/preparation/${props?.dataId}`;
    const [totalCount, setTotalCount] = useState<number>(0);
    const [countData, setCountData] = useState<any>();

    const { data }: any = useData(!!props.dataId && url, {
        params: {
            sort: ["created_at", "1"],
            with: ["unit", "recipe.unit"],
        },
    });
    // const dp = useDataProvider({
    //     // stop: !dataId,
    //     url: url,
    //     filters: {
    //         ingredient_id: ["ingredient_id", dataId],
    //     },
    //     with: ["modificator_group"],
    //     refineList: list => {
    //         return _.uniqBy(list || [], "group_id");
    //     },
    // });
    // const refinedData = data.
    // const { data: countData }: any = useData(!!props.dataId && `product/relatable/${props.dataId}/info`, {});
    useEffect(() => {
        (async () => {
            if (props.dataId) {
                const data = await Ajax.get({
                    url: `product/relatable/${props.dataId}/info`,
                });

                const countData: { [key: string]: number } = data.data;
                const totalCount = Object.values(countData).reduce((a: number, b: number) => a + b, 0);
                setTotalCount(totalCount);
                setCountData(countData);
            }
        })();
    }, [props.dataId]);
    return (
        <CDrawer size="md" title={`${t("preparation")} : #${data?.id} : ${data?.name}`} {...props}>
            <TabsView
                tabs={[
                    {
                        label: `${t("recipe")} ${data?.recipe?.length || 0}`,
                        Component: PreparationIngredientTab,
                        props: { ingredients: data?.recipe ?? [] },
                    },
                    {
                        label: `${t("in_use")} ${totalCount}`,
                        Component: UsageTabBeta,
                        props: {
                            productId: data?.id,
                            countData,
                            dataId: props.data?.id || props.dataId,
                            ...props,
                        },
                    },
                    // {
                    //     label: `${t("in_use")} (${data?.relatable?.length || 0})`,
                    //     Component: PreparationDishTab,
                    //     props: { dishes: data?.relatable ?? [], preparationId: data?.id },
                    // },
                    // {
                    //     label: t("used_by_modifier"),
                    //     Component: InUseModifier,
                    //     props: {
                    //         dataId: props.data?.id || props.dataId,
                    //     },
                    // },
                ]}
            />
        </CDrawer>
    );
};

export default PreparationDrawer;
