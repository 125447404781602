import { t } from "lib/i18n";

import { array, number, object, ref, string } from "yup";
import { ProductType } from "config/constants";

export const OperationModel = object().shape({
    storage_id: number().required(t("required")).typeError(t("required")),
    operated_at: string(),
    type: number(),
});

export const SupplyOperationItemObject = object({
    product_id: number().typeError(t("required")).required(t("required")),
    unit_id: number().typeError(t("required")).required(t("required")),
    quantity: number()
        .typeError(t("required"))
        .when("unit_id", {
            is: 1,
            then: number().when("status", {
                is: 1,
                then: number()
                    .min(1, t("must_be_greater_0"))
                    .typeError(t("required"))
                    .integer(t("no_decimal"))
                    .required(t("required")),
                otherwise: number()
                    .min(0, t("must_be_greater_equal_0"))
                    .typeError(t("required"))
                    .integer(t("no_decimal"))
                    .required(t("required")),
            }),
            otherwise: number().when("status", {
                is: 1,
                then: number().min(0.0001, t("must_be_greater_0")).typeError(t("required")).required(t("required")),
                otherwise: number()
                    .min(0, t("must_be_greater_equal_0"))
                    .typeError(t("required"))
                    .required(t("required")),
            }),
        }),
    cost: number().min(0, t("must_be_greater_equal_0")).typeError(t("required")).required(t("required")),
    total_cost: number().min(0, t("must_be_greater_equal_0")).typeError(t("required")).required(t("required")),
});

export const SupplyOperationModel = () =>
    object()
        .concat(OperationModel)
        .concat(
            object({
                supplier_id: number().typeError(t("required")).required(t("required")),
                finance: object({
                    balance_id: string().typeError(t("required")).required(t("required")),
                    category_id: number().required(t("required")),
                }),
                items: array().of(SupplyOperationItemObject),
                supplier_remain: number().notRequired(),
                description: string(),
                status: number().typeError(t("required")).required(),
                meta: object(),
            }),
        );

export const SupplyReturnOperationModel = () =>
    object()
        .concat(SupplyOperationModel())
        .concat(
            object({
                returnItems: array().of(
                    object({
                        product_id: number().typeError(t("required")).required(t("required")),
                        unit_id: number().typeError(t("required")).required(t("required")),
                        quantity: number()
                            .typeError(t("required"))
                            .when("unit_id", {
                                is: 1,
                                then: number()
                                    .min(1, t("must_be_greater_0"))
                                    .typeError(t("required"))
                                    .integer(t("no_decimal"))
                                    .required(t("required")),
                                otherwise: number()
                                    .min(0.0001, t("must_be_greater_0"))
                                    .typeError(t("required"))
                                    .required(t("required")),
                            })
                            .when("quantityOriginal", (quantityOriginal, schema) => {
                                return schema.test({
                                    test: (quantity: number) => quantity <= quantityOriginal,
                                    message: t("can_not_pass_the_limit_qty", {
                                        max: quantityOriginal,
                                        unit: "",
                                    }),
                                });
                            }),
                        cost: number()
                            .min(0, t("must_be_greater_equal_0"))
                            .typeError(t("required"))
                            .required(t("required")),
                        total_cost: number()
                            .min(0, t("must_be_greater_equal_0"))
                            .typeError(t("required"))
                            .required(t("required")),
                    }),
                ),
            }),
        );

export const TransferOperationModel = object()
    .concat(OperationModel)
    .concat(
        object({
            from_storage_id: number()
                .typeError(t("required"))
                .required(t("required"))
                .notOneOf([ref("storage_id")], t("not_same_destination")),
            reason: string(),
            items: array().of(
                object({
                    product_id: number().typeError(t("required")).required(t("required")),
                    unit_id: number().typeError(t("required")).required(t("required")),
                    quantity: number()
                        .typeError(t("required"))
                        .min(0.0001, t("must_be_greater_0"))
                        .typeError(t("required"))
                        .required(t("required")),
                }),
            ),
        }),
    );

export const WasteOperationModel = object().shape({
    storage_id: number().typeError(t("required")).required(t("required")),
    operated_at: string(),
    type: number(),
    reason_id: string().required(t("required")),
    reason: string().optional(),
    description: string().optional(),
    items: array().of(
        object({
            product_id: number().typeError(t("required")).required(t("required")),
            unit_id: number().typeError(t("required")).required(t("required")),
            quantity: number()
                .typeError(t("required"))
                .min(0, t("must_be_greater_equal_0"))
                .typeError(t("required"))
                .required(t("required")),
        }),
    ),
});
export const ManufactureOperationModel = object()
    .concat(OperationModel)
    .concat(
        object({
            items: array().of(
                object({
                    product_id: number().typeError(t("required")).required(t("required")),
                    unit_id: number().typeError(t("required")).required(t("required")),
                    quantity: number()
                        .min(0, t("must_be_greater_equal_0"))
                        .required(t("required"))
                        .typeError(t("required")),
                    cost: number()
                        .min(0, t("must_be_greater_equal_0"))
                        .typeError(t("required"))
                        .required(t("required")),
                    total_cost: number()
                        .min(0, t("must_be_greater_equal_0"))
                        .typeError(t("required"))
                        .required(t("required")),
                }),
            ),
        }),
    );

export const CheckOperationModel = () =>
    object().shape({
        storage_id: number().typeError(t("required")).required(t("required")),
        operated_at: string(),
        status: number(),
        type: number(),
        op_items: array(),
    });

export const InventoryOperationModel = () =>
    object().shape({
        storage_id: number().typeError(t("required")).required(t("required")),
        operated_at: string(),
        type: number(),
        status: number(),
        items: array().of(
            object({
                product_id: number().typeError(t("required")).required(t("required")),
                unit_id: number().typeError(t("required")).required(t("required")),
                quantity: number().when("type", {
                    is: (p_type: string) => p_type === ProductType.DISH || p_type === ProductType.PREPARATION,
                    then: number().nullable().notRequired(),
                    otherwise: number()
                        .typeError(t("required"))
                        .min(0, t("must_be_greater_equal_0"))
                        .typeError(t("required"))
                        .required(t("required")),
                }),
            }),
        ),
    });
