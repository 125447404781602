import { t } from "lib/i18n";
import { useCForm } from "hooks/useCForm";
import { PackageModel } from "pages/inventory/packages/PackageModel";
import { useFormTitle } from "components/Header/Title";

import _ from "lodash";
import HeaderRight from "components/Header/HeaderRight";
import CForm from "components/Form/CForm/CForm";
import TextFormGroup from "components/Form/TextFormGroup";

const CreateInventoryPackagePage: FCC = props => {
    const form = useCForm({
        url: "unit",
        redirectUrl: "/inventory/packages",
        model: PackageModel(),
        refine: d => ({
            ...d,
            type: 4,
        }),
        dummyData: () => ({
            name: "",
            equal: 1,
            type: 4,
            // parent_id: null,
        }),
    });

    return (
        <div className="p-3">
            {useFormTitle(form.id, t("create_by_name", { name: _.capitalize(t("package")) }))}
            <HeaderRight formSubmit={form} />

            <CForm form={form.form} onSave={form.onSave}>
                <div className="pb-3">
                    <TextFormGroup name="name" errorPlacement="rightEnd" focus={true} />
                    <TextFormGroup name="equal" type="number" errorPlacement="rightEnd" />

                    {/*<RemoteSelectFormGroup*/}
                    {/*    label={t("unit")}*/}
                    {/*    name="parent_id"*/}
                    {/*    remote={{*/}
                    {/*        url: "unit",*/}
                    {/*        filters: { parent: ["parent_id", ""] },*/}
                    {/*        refineList: (units?: IUnit[]) => units?.map(translateUnit) ?? [],*/}
                    {/*    }}*/}
                    {/*    errorPlacement="rightEnd"*/}
                    {/*/>*/}
                </div>
            </CForm>
        </div>
    );
};

export default CreateInventoryPackagePage;
