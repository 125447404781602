import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import CIcon from "components/CIcon";
import { iCheckBoxChecked, iClose } from "assets/icons/Icons";
import { Modal } from "rsuite";
import CModal from "components/Drawers/CModal";
import { ICDrawerProps } from "components/Drawers/CDrawer";

interface IProps extends ICDrawerProps {
    import_count: number;
    not_found_count: number;
}

const ImportResultModal: FCC<IProps> = ({ import_count, not_found_count, ...props }) => {
    return (
        <CModal {...props} backdrop size="xs" style={{ padding: "24px !important" }}>
            <Modal.Body className="pb-0 bulk-delete-result">
                <div className="modal-title">
                    <span>
                        <TText tkey="operation_completed" />
                    </span>
                </div>
                {import_count > 0 ? (
                    <div className="deleted">
                        <div className="btn success">
                            <CIcon path={iCheckBoxChecked} className="icon" />
                        </div>
                        <span>{t("qty_products_imported", { quantity: import_count })}</span>
                    </div>
                ) : null}
                {not_found_count > 0 ? (
                    <div className="could-not-deleted">
                        <div className="btn danger">
                            <CIcon path={iClose} />
                        </div>
                        <span>{t("qty_product_not_found", { quantity: not_found_count })}</span>
                    </div>
                ) : null}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.onClose} className="footer-btn rs-btn-default rs-btn-lg rs-btn-block">
                    <TText tkey="okay_button" />
                </button>
            </Modal.Footer>
        </CModal>
    );
};
export default ImportResultModal;
