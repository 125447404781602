import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";

export const UserSchema: ValidationSchema = {
    username: [ruleset.required, ruleset.minLength(2)],
    venues: [ruleset.required, ruleset.minLength(1)],
    role: ruleset.required,
    role_id: ruleset.number,
    salary: ruleset.number,
    email: ruleset.email,
    card: ruleset.requiredIf(values => values.terminal && !values.pin, "pin_or_card"),
    pin: ruleset.combineIf(
        (value, values) => values.terminal && !values.card,
        [ruleset.requiredMsg("pin_or_card"), ruleset.length(4), ruleset.number],
    ),
    password: ruleset.requiredIf(values => values.isCreate && values.web),
};
