import { t } from "lib/i18n";
import * as _ from "lodash";
import CDrawer from "components/Drawers/CDrawer";
import { ProductType } from "config/constants";
import { productName } from "lib/data-utils";
import { getLabel } from "components/Form/form-utils";
import DataTable from "components/DataTable/DataTable";
import { useTableHeight } from "hooks/useTableHeight";
import { translateUnit } from "lib/utils";

const TransfersDrawer = (props: any) => {
    const d = props?.data;
    const height = useTableHeight(230);

    if (_.isNil(d)) return <></>;
    return (
        <CDrawer
            title={`${t("transfers")} - ${productName(d?.product)} ${
                (d?.product?.type ?? d?.type) === ProductType.INGREDIENT
                    ? "(" + getLabel(d?.product?.type ?? d?.type) + ")"
                    : ""
            }`}
            {...props}
            size="md"
        >
            <div>
                <DataTable
                    noShadow
                    columns={[
                        {
                            title: t("transfer_in"),
                            width: 230,
                            key: "transfer_in",
                            suffix: () => translateUnit(d?.product?.unit)?.symbol,
                            render: (d: any) => (d?.transfer_in ?? 0) + " " + translateUnit(d?.product?.unit)?.symbol,
                        },
                        {
                            title: t("transfer_out"),
                            width: 230,
                            key: "transfer_out",
                            suffix: () => translateUnit(d?.product?.unit)?.symbol,
                            render: (d: any) => (d?.transfer_out ?? 0) + " " + translateUnit(d?.product?.unit)?.symbol,
                        },
                    ]}
                    dp={
                        {
                            data: [
                                {
                                    transfer_in: d?.product?.transfer_in,
                                    transfer_out: d?.product?.transfer_out,
                                },
                            ],
                        } as any
                    }
                    hidePages
                    tableProps={{
                        rowHeight: 60,
                        autoHeight: false,
                        height,
                    }}
                />
            </div>
        </CDrawer>
    );
};

export default TransfersDrawer;
