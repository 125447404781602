import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import "react-contexify/ReactContexify.css";

import { Item, Menu } from "react-contexify";
import { Message, toaster } from "rsuite";
import { IOptionColumnItem } from "./OptionsColumn";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { useDrawer } from "components/Drawers/useDrawer";
import { useLoading } from "hooks/useLoading";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import Ajax from "lib/Ajax";
import ConfirmModal from "components/Drawers/ConfirmModal";
import CIcon from "../../CIcon";
import { iArrowDownRight, iContentCopy, iEditPencil, iTrash } from "assets/icons/Icons";
import { getLabel } from "../../Form/form-utils";
import React, { SyntheticEvent, useState } from "react";
import NoAccessModal from "../../NoAccessModal";
import { MessageSuccess } from "../../Message";

type IProps = {
    id: string;
    columns: ITableColumnProps[];
};

const ContextMenu: FCC<IProps> = ({ columns, id }) => {
    const menuConfig: ITableColumnProps | undefined = columns.find((c: any) => c.key === "options");
    const conf = menuConfig?.optionsConf;
    const history = useHistory();
    const modal = useDrawer();
    const d = {
        deleted_at: null,
    };
    const { isLoading, withLoading } = useLoading();
    const noAccessModal = useDrawer();
    const errorModal = useDrawer();
    const auth = useAuth();
    const [permissionLabel, setPermissionLabel] = useState<string>("");

    // const isModifable = (d: any) => conf.editable?.(d) && conf.deletable?.(d);

    const onDelete = ({ props }: any) => {
        const d = props.d;
        if (conf.deletable?.(d))
            return toaster.push(
                <Message type="warning" showIcon closable>
                    <TText tkey="error" />
                </Message>,
                { duration: 4000 },
            );

        if (conf.canDelete && !auth.hasAbility([conf.canDelete])) {
            setPermissionLabel(getLabel(conf.canDelete));
            noAccessModal.setDataId(1);
            return;
        }

        modal.setData(d);
    };

    const onConfirmDelete = (d: any, e: SyntheticEvent) => {
        withLoading(async () => {
            const deleteUrl = _.isString(conf.delete) ? conf.delete : conf.delete?.(d);
            if (!deleteUrl) return;
            const id = (deleteUrl?.indexOf("stock/:stock_id") as any) > -1 ? (d as any).stocks[0].id : d.id;
            return Ajax.delete({ url: deleteUrl?.replace(`:${deleteUrl?.split(":")[1]}`, id.toString()) });
        })
            .then(() => {
                toaster.push(MessageSuccess(t("deleted_successfully")), { duration: 4000 });
            })
            .finally(() => {
                modal.onClose(e);
                conf.dp.revalidate();
            })
            .catch(e => {
                if (e.models?.length) {
                    errorModal.setData(e);
                }
            });
    };

    const onEdit = ({ props }: any) => {
        const d = props.d;
        if (conf.editable?.(d))
            return toaster.push(
                <Message type="warning" showIcon closable>
                    <TText tkey="error" />
                </Message>,
                { duration: 4000 },
            );

        if (conf.canEdit && !auth.hasAbility([conf.canEdit])) {
            setPermissionLabel(getLabel(conf.canEdit));
            noAccessModal.setDataId(1);
            return;
        }

        if (_.isString(conf.edit)) {
            history.push(conf.edit?.replace(":id", d.id.toString()));
        } else {
            conf.edit?.(d);
        }
    };

    const onDuplicate = ({ props }: any) => {
        const d = props.d;
        if (!conf.duplicable?.(d))
            return toaster.push(
                <Message type="warning" showIcon closable>
                    <TText tkey="error" />
                </Message>,
                { duration: 4000 },
            );

        if (conf.canDuplicate && !auth.hasAbility([conf.canDuplicate])) {
            setPermissionLabel(getLabel(conf.canDuplicate));
            noAccessModal.setDataId(1);
            return;
        }
        if (_.isString(conf.duplicate)) {
            history.push(conf.duplicate?.replace(":id", d.id.toString()));
        } else {
            conf.duplicate?.(d);
        }
    };

    const onReturnProduct = ({ props }: any) => {
        const d = props.d;
        if (conf.returnable?.(d))
            return toaster.push(
                <Message type="warning" showIcon closable>
                    <TText tkey="error" />
                </Message>,
                { duration: 4000 },
            );

        if (_.isString(conf.return)) {
            history.push(conf.return?.replace(":id", d.id.toString()));
        } else {
            conf.returnable?.(d);
        }
    };

    function isItemHidden({ props, data, triggerEvent }: any, index: number) {
        const d = props.d;

        return !conf?.others?.()?.[index].visible?.(d);
    }
    return (
        <>
            {conf?.delete && (
                <ConfirmModal type={conf?.modalType} {...modal} isLoading={isLoading} onConfirm={onConfirmDelete} />
            )}
            <Menu id={id}>
                {conf?.edit && !d.deleted_at && (
                    <Item onClick={onEdit} test-id="edit-button">
                        <CIcon path={iEditPencil} style={{ marginRight: 10 }} />
                        <TText tkey="edit" />
                    </Item>
                )}
                {conf?.return && (
                    <Item onClick={onReturnProduct} test-id="return-button">
                        <CIcon path={iArrowDownRight} style={{ marginRight: 10 }} />
                        <TText tkey="return_product" />
                    </Item>
                )}

                {conf?.duplicable && (
                    <Item onClick={onDuplicate} test-id="duplicate-button">
                        <CIcon path={iContentCopy} style={{ marginRight: 10 }} />
                        <TText tkey="duplicate" />
                    </Item>
                )}
                {conf?.delete && !d.deleted_at && (
                    <Item onClick={onDelete} test-id="delete-button">
                        <CIcon path={iTrash} style={{ marginRight: 10 }} />
                        <TText tkey="remove" />
                    </Item>
                )}
                {/*{!_.isUndefined(conf?.others) && <Separator />}*/}

                {!_.isUndefined(conf?.others) &&
                    conf.others?.(d).map((opt: IOptionColumnItem, index: number) => {
                        return (
                            (_.isUndefined(opt.visible) ||
                                opt.visible === true ||
                                typeof opt.visible === "function") && (
                                <Item
                                    test-id={opt.testId}
                                    hidden={args => {
                                        if (typeof opt.visible === "function") {
                                            return isItemHidden(args, index);
                                        } else return false;
                                    }}
                                    key={index}
                                    onClick={({ props }) => opt.onClick(props.d)}
                                >
                                    <div style={opt.style}>
                                        {opt?.icon && <CIcon path={opt?.icon as never} style={{ marginRight: 10 }} />}
                                        {opt.label}
                                    </div>
                                </Item>
                            )
                        );
                    })}
            </Menu>
            <NoAccessModal modal={noAccessModal} permission={[permissionLabel]} />
        </>
    );
};

export default ContextMenu;
