import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import CheckListFilter from "components/DataTable/Filters/CheckListFilter";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import ReceiptDrawer from "components/Drawers/ReceiptDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import { Constants, ExportType, FinanceAccountDefaultFilters } from "config/constants";
import { EMPTY_DATA } from "config/main";
import React, { useEffect, useState } from "react";
import TransactionReference from "./TransactionReference";
import CreateTransactionModal from "./CreateTransactionModal";
import StockOperationsDrawer from "../../inventory/misc/StockOperationsDrawer";
import classNames from "classnames";
import CreateButton from "components/DataTable/CreateButton";
import DeletedBy from "components/mini/DeletedBy";
import { useData } from "hooks/useData";
import _ from "lodash";
import Money from "components/mini/Money";
import WhisperWithoutIcon from "components/mini/WhisperWithoutIcon";
import ProductReturnDrawer from "components/Drawers/ProductReturnDrawer/ProductReturnDrawer";
import ReturnedSuppliesProductsDrawer from "../../inventory/misc/ReturnedSuppliesProductsDrawer";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { QuickLinks } from "components/Header/QuickLinks";
import CIcon from "components/CIcon";
import { iArrowDown, iArrowUp, iExchange } from "assets/icons/Icons";
import TransactionPageFilterDrawer from "./TransactionPageFilterDrawer";
import { RowDataType } from "rsuite-table/src/@types/common";
import ToggleFilterDrawer from "components/DataTable/Filters/ToggleFilterDrawer";
import { Colors } from "config/colors";
import FinanceCategoryTreeFilter from "components/DataTable/Filters/CategoryTreeFilter/FinanceCategoryTreeFilter";

const transactionColors: Record<TransactionType, string> = {
    IN: Colors.EmeraldGreen,
    OUT: Colors.CarmineRed,
    TRANSFER: Colors.JetBlack,
    SUPPLIER_PAY: Colors.JetBlack,
};

const transactionIcons = {
    IN: <CIcon path={iArrowDown} size={0.5} style={{ color: transactionColors.IN, marginTop: "2px" }} />,
    OUT: <CIcon path={iArrowUp} size={0.5} style={{ color: transactionColors.OUT }} />,
    TRANSFER: <CIcon path={iExchange} size={0.5} style={{ color: transactionColors.TRANSFER }} rotate={90} />,
    SUPPLIER_PAY: null,
};

const TransactionsPage: FCC = () => {
    const [hasDeletedBy, setHasDeletedBy] = useState<boolean>(true);

    const [op, setOp] = useState<IOperation>();
    const dp = useDataProvider({
        url: "finance/transaction",
        useUrl: true,
        useDates: true,
        extraFilterKeys: [
            "description",
            "source",
            "trash",
            "payment_method_id",
            "user_id",
            "supplier_id",
            "customer_id",
        ],
        filters: {
            indicator: ["indicator", 0],
            receipt_id: [
                ["receipt_id", "=", ""],
                ["returns_id", "!=", ""],
            ],
        },
        with: [
            "category",
            "role",
            "balance",
            "staff",
            "user",
            // "operation.operation_items",
            // "operation.operation_items.unit",
            // "operation.operation_items.product",
            "operation.supplier",
            // "operation.stock_operations.unit",
            // "operation.stock_operations.product",
            "from_balance",
            "supplier",
            "customer",
            "user_delete",
            "group_references.balance",
            "group_references.from_balance",
            "payment_method",
        ],
        sort: ["operated_at", "-1"],
    });
    const rd = useDrawer({ backdrop: false, overflowAuto: true });
    const sd = useDrawer({ backdrop: false, overflowAuto: true });
    const filter = useDrawer();

    const returnsDrawer = useDrawer({ backdrop: false, overflowAuto: true });
    const suppliesReturnsDrawer = useDrawer({ backdrop: false, overflowAuto: true });
    const auth = useAuth();
    const summary = useData<any>("report/finance/total", {
        params: { date: dp.params.all?.date },
        filters: _.values(dp.filters.all),
    });
    //rd.setDataId(d.receipt_id)
    const cm = useColumns<ITransaction>("finance/transaction", [
        {
            title: t("data_id"),
            key: "id",
            width: 100,
            align: "right",
            flexGrow: undefined,
            suffix: (d: ITransaction) => <div className="">{transactionIcons[d.type]}</div>,
        },
        {
            key: "amount",
            type: "money",
            numberRoundingLength: 2,
            flexGrow: undefined,
            getStyle: (d: ITransaction) => ({ color: transactionColors[d.type] }),
            summary: () => <Money colored value={summary.data && summary.data[0]?.total_diff} />,
        },
        {
            key: "operated_at",
            type: "datetime",
            align: "center",
            title: t("operation"),
            multiline: true,
            reverse: true,
        },
        // info: bu hisseni muzakire edenden sonra comment eledim, hele ki bir ishe yaramir deye
        // {
        //     key: "accounting_period",
        //     type: "datetime",
        //     align: "center",
        //     title: t("finance_accounting_period"),
        //     minWidth: 135,
        //     hide: true,
        //     // render: (d: any) => <DateTimeCell type="date-time-reverse" date={d.accounting_period} />,
        // },
        {
            key: "created_at",
            type: "datetime",
            align: "center",
            reverse: true,
            multiline: true,
        },
        {
            key: "user.username",
            minWidth: 180,
            title: t("transaction_by"),
        },
        {
            key: "deleted_at",
            type: "datetime",
            minWidth: 105,
            hide: hasDeletedBy,
            render: d => <DeletedBy {...d} />,
        },
        { key: "user_delete.username", title: t("deleted_by"), hide: hasDeletedBy },
        { key: "category.name", title: t("category"), minWidth: 180 },
        {
            key: "reference",
            render: d => {
                return (
                    <TransactionReference
                        transaction={d}
                        showReturns={id => {
                            const isReturn = d?.category?.parent_id || d?.category?.id;
                            if (isReturn === 22) {
                                sd.hide();
                                rd.hide();
                                returnsDrawer.hide();
                                suppliesReturnsDrawer.setDataId(id);
                            } else {
                                sd.hide();
                                rd.hide();
                                suppliesReturnsDrawer.hide();
                                returnsDrawer.setDataId(id);
                            }
                        }}
                        showReceipt={id => {
                            sd.hide();
                            returnsDrawer.hide();
                            suppliesReturnsDrawer.hide();
                            rd.setDataId(id);
                        }}
                        showSupply={(op: IOperation) => {
                            rd.hide();
                            returnsDrawer.hide();
                            suppliesReturnsDrawer.hide();
                            setOp(op);
                            sd.setDataId(op.id);
                        }}
                    />
                );
            },
            minWidth: 200,
        },
        {
            key: "description",
            render: e => <WhisperWithoutIcon description={e.description} />,
        },
        {
            title: t("payment_method"),
            key: "payment_method.name",
            // render: (d: any) => <PaymentMethodsCell r={d} />,
            align: "center",
            flexGrow: 0.85,
            minWidth: 120,
        },
        {
            key: "before_amount",
            type: "money",
            numberRoundingLength: 2,
            flexGrow: undefined,
            getStyle: d => ({ color: Colors.MidGray }),
            hide: true,
        },
        {
            key: "after_amount",
            type: "money",
            numberRoundingLength: 2,
            flexGrow: undefined,
            getStyle: d => ({ color: Colors.MidGray }),
            hide: true,
        },
        // {
        //     key: "from_balance.name",
        //     title: t("from_account"),
        //     render: (d: ITransaction) => d.type === "OUT" ? d.balance?.name : d.from_balance?.name ?? (d.balance?.name ?? EMPTY_DATA),
        //
        // },
        {
            key: "balance.name",
            title: t("account"),
            hideData: (d: ITransaction) => d.type === "OUT",
            render: (d: ITransaction) => d.balance?.name ?? d.from_balance?.name ?? EMPTY_DATA,
        },
        {
            key: "type",
            minWidth: 100,
            render: (d: ITransaction) => (Constants.transactionTypes as any)[d.type],
        },
        optionsColumn({
            dp,
            preventDelete: (d: ITransaction) => !!(d.receipt_id || d.operation),
            editable: (d: ITransaction) => !!(d.receipt_id || d.operation),
            delete: "finance/transaction/:id",
            canDelete: "finance_transaction_delete",
        }),
    ]);

    const modal = useDrawer();

    useEffect(() => {
        if (!modal.dataId) {
            dp?.revalidate();
        }
        // eslint-disable-next-line
    }, [modal.dataId]);

    return (
        <div className="h-100">
            {useTitle(t("transactions"), dp.total)}

            <QuickLinks
                inHeader={false}
                links={[
                    {
                        link: "/finance/transactions",
                        label: t("transactions"),
                        active: true,
                    },
                    {
                        link: "/finance/customer-transactions",
                        label: t("customer_transactions"),
                        hide: !auth.hasAbility(["customer_manage_view"]),
                    },
                    {
                        link: "/finance/supplier-transactions",
                        label: t("supplier_transactions"),
                        hide: !auth.hasAbility(["stock_manage_supplier"]),
                    },
                ]}
            />

            <HeaderRight
                cm={cm}
                date
                dp={dp}
                reload
                print
                exportType={ExportType.FINANCE_TRANSACTION}
                haveExportList={true}
                exportWithOptions={[{ name: "Excel", columns: cm.columns, dp }]}
            />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <CheckListFilter
                        pickerProps={{ searchable: false }}
                        fields="type"
                        options={[
                            { label: t("income"), value: "IN" },
                            { label: t("expense"), value: "OUT" },
                        ]}
                    />

                    {/*<label style={labelColStyle}><TText key="balance" /></label>*/}
                    <RemoteCheckListFilter
                        fields="balance_id"
                        remote={{
                            url: "finance/balance",
                            filters: FinanceAccountDefaultFilters,
                        }}
                        searchField="name"
                    />
                    <FinanceCategoryTreeFilter params={{ withHidden: 1 }} showUncategorisedOption={false} />

                    <ToggleFilterDrawer
                        excludeFilterKeys={["receipt_id"]}
                        isOpen={filter.isOpen}
                        onChange={val => filter.setIsOpen(val)}
                    />
                </div>

                <CreateButton to={() => modal.setDataId(0)} canCreate={"finance_transaction_create"} />
            </FiltersContainer>

            <ReceiptDrawer {...rd} />
            <StockOperationsDrawer
                {...sd}
                op={op}
                title={t("total_supply") + " #" + sd.dataId}
                cols={["product.name", "quantity", "cost", "total_cost"]}
            />
            {modal?.isOpen ? (
                <CreateTransactionModal
                    {...modal}
                    revalidate={() => {
                        dp.revalidate();
                        summary.revalidate();
                    }}
                />
            ) : null}
            <DataTable
                dp={dp}
                columns={cm.columns}
                // rowPadding={"0"}
                tableProps={{
                    headerHeight: 50,
                    affixHeader: true,
                    rowClassName: (d?: RowDataType) =>
                        classNames({
                            "deleted-row": d && !!d?.deleted_at,
                            "gray-row": d && !d?.deleted_at && d?.category?.id === 3,
                        }),
                }}
            />
            <ProductReturnDrawer {...returnsDrawer} />
            <ReturnedSuppliesProductsDrawer {...suppliesReturnsDrawer} />
            <TransactionPageFilterDrawer {...filter} dp={dp} setDeletedBy={setHasDeletedBy} showOnPage="transactions" />
        </div>
    );
};

export default TransactionsPage;
