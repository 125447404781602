import { TText } from "components/i18n/TText";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useHistory } from "react-router-dom";
import { Divider, Dropdown, IconButton } from "rsuite";
import { BUILDVERSION } from "config/main";
import { mdiHelpCircleOutline, mdiLogout } from "@mdi/js";
import { iAccount } from "assets/icons/Icons";
import CIcon from "../CIcon";
import { Colors } from "config/colors";
import { WithAsProps } from "rsuite/cjs/@types/common";
import { LanguagePicker } from "components/i18n/LanguagePicker";
import { Ref } from "react";

interface IProps {}

const HeaderUserBox: FCC<IProps> = () => {
    const auth = useAuth();
    const history = useHistory();

    return (
        <div className="user-dropdown" style={{ marginRight: "8px" }}>
            <Dropdown
                noCaret
                toggleClassName="p-0"
                renderToggle={(props: WithAsProps, ref: Ref<any>) => {
                    return (
                        <div
                            {...props}
                            ref={ref}
                            className="d-flex justify-content-center align-items-center rounded-circle"
                            style={{
                                width: "36px",
                                height: "36px",
                                marginRight: "8px",
                                background: Colors.LightGray,
                                overflow: "hidden",
                            }}
                        >
                            <IconButton
                                className="d-flex justify-content-center align-items-center"
                                style={{ borderRadius: "30px", background: Colors.LightGray }}
                                icon={<CIcon path={iAccount} style={{ background: Colors.LightGray }} size={0.8} />}
                            />
                        </div>
                    );
                }}
                placement="bottomEnd"
                menuStyle={{ minWidth: 170, borderRadius: "8px" }}
            >
                <Dropdown.Item onSelect={() => history.push(`/staff/users/edit/${auth.user?.id}`)}>
                    <CIcon path={iAccount} size={0.6} style={{ marginBottom: "-4px" }} />
                    <span style={{ marginLeft: "10px" }}>{auth.user?.username}</span>
                </Dropdown.Item>
                <LanguagePicker />
                <Dropdown.Item onSelect={auth.logout} onClick={() => window.open("https://help.clopos.com")}>
                    <div className="d-flex align-items-center">
                        <CIcon path={mdiHelpCircleOutline} size={0.7} />
                        <div style={{ marginLeft: "10px" }}>
                            <TText tkey="help_center" />
                        </div>
                    </div>
                </Dropdown.Item>

                <Divider style={{ margin: "5px 0" }} />
                <Dropdown.Item onSelect={auth.logout}>
                    <div style={{ color: Colors.BrightRed }} className="d-flex align-items-center">
                        <CIcon path={mdiLogout} size={0.7} />
                        <div style={{ marginLeft: "10px" }}>
                            <TText tkey="logout" />
                        </div>
                    </div>
                </Dropdown.Item>

                <Dropdown.Item disabled>
                    <div style={{ fontFamily: "monospace" }}>{`${BUILDVERSION}`}</div>
                </Dropdown.Item>
            </Dropdown>
        </div>
    );
};

export default HeaderUserBox;
