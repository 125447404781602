import CIcon from "components/CIcon";
import { CiWeb } from "assets/icons/Icons";
import { t } from "lib/i18n";
import { Dropdown } from "rsuite";
import React from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { setCurrentLang } from "redux/features/lang";
import _ from "lodash";

export const LanguagePicker = () => {
    const dispatch = useAppDispatch();
    const { current: currLanguage, list: languages } = useAppSelector(state => state.lang);
    const activeLang = languages.find(lang => lang.name === currLanguage);

    return (
        <Dropdown
            icon={<CIcon path={CiWeb} size={0.8} />}
            toggleClassName={"tw-flex tw-items-center tw-gap"}
            placement="bottomEnd"
            title={_.isNil(activeLang) ? t("select_language") : activeLang.title}
        >
            {languages
                .filter(language => language.active)
                .map(language => (
                    <Dropdown.Item
                        key={language.name}
                        active={language.name === currLanguage}
                        onSelect={() => {
                            dispatch(setCurrentLang(language.name));
                        }}
                    >
                        {language.title}
                    </Dropdown.Item>
                ))}
        </Dropdown>
    );
};
