import {
    iBox,
    iChangeQuest,
    iCogOutline,
    iDollar,
    iKitchenAppliances,
    iModifikator,
    iPeople,
    iPercentage,
    iQrCode,
    iSentToKitchenKitsFail,
    iStatistics,
} from "assets/icons/Icons";
import { t } from "lib/i18n";
import { useModules } from "hooks/useModules";
import { useAppSelector } from "hooks/useRedux";
import { setRoutes } from "hooks/useRoutes";
import _ from "lodash";
import TerminalEditPage from "pages/access/terminals/TerminalEditPage";
import { TerminalsPage } from "pages/access/terminals/TerminalsPage";
import AccountsEditPage from "pages/finance/accounts/AccountsEditPage";
import EditCategoryPage from "pages/finance/categories/EditCategoryPage";
import EditPaymentMethodPage from "pages/finance/payment-methods/EditPaymentMethodPage";
import PayrollPage from "pages/finance/PayrollPage";
import CashflowPage from "pages/finance/reports/CashflowPage";
import BetaIngredientMovementsPage from "pages/inventory/BetaIngredientMovementsPage";
import InventoryCheckEditorPage from "pages/inventory/check/InventoryCheckEditorPage";
import CreateManufacturePage from "pages/inventory/manufactures/CreateManufacturePage";
import ManufacturesPage from "pages/inventory/manufactures/ManufacturesPage";
import CreateInventoryPackagePage from "pages/inventory/packages/CreateInventoryPackagePage";
import InventoryPackagesPage from "pages/inventory/packages/InventoryPackagesPage";
import ProductMovementsPage from "pages/inventory/ProductMovementsPage";
import StockPage from "pages/inventory/StockPage";
import CreateStoragePage from "pages/inventory/storages/CreateStoragePage";
import StoragesPage from "pages/inventory/storages/StoragesPage";
import CreateSupplierPage from "pages/inventory/suppliers/CreateSupplierPage";
import SuppliersPage from "pages/inventory/suppliers/SuppliersPage";
import { CreateSupplyPage } from "pages/inventory/supplies/NewSupplyCreate/CreateSupplyPage";
import SuppliesPageDeletedSupplies from "pages/inventory/supplies/SuppliesDeletedPage";
import SuppliesPageSupplyDraft from "pages/inventory/supplies/SuppliesDraftPage";
import SuppliesPage from "pages/inventory/supplies/SuppliesPage";
import CreateTransferPage from "pages/inventory/transfers/CreateTransferPage";
import TransfersPage from "pages/inventory/transfers/TransfersPage";
import CreateWastePage from "pages/inventory/write-off/CreateWastePage";
import ReasonsPage from "pages/inventory/write-off/ReasonsPage";
import WastesPages from "pages/inventory/write-off/WastesPages";
import WastesPagesDetailed from "pages/inventory/write-off/WastesPagesDetailed";
import LoyaltyPage from "pages/marketing/loyalty/LoyaltyPage";
import ReviewsPage from "pages/marketing/ReviewsPage";
import ManageModifiersCreate from "pages/menu/manage-modifiers/create-edit/ManageModifiersCreate";
import PreparationEditPage from "pages/menu/preparations/PreparationEditPage";
import DishEditPage from "pages/products/dishes/edit/DishEditPage";
import GoodsEditPage from "pages/products/goods/GoodsEditPage";
import GeneralSettingsPage from "pages/settings/general/GeneralSettingsPage";
import ReceiptSettingsPage from "pages/settings/receipt/ReceiptSettingsPage";
import SubscriptionPage from "pages/settings/subscription/SubscriptionPage";
import EditHallPage from "pages/settings/tables/EditHallPage";
import HallsPage from "pages/settings/tables/HallsPage";
import ManageTablesPage from "pages/settings/tables/ManageTablesPage";
import EditTaxPage from "pages/settings/tax/EditTaxPage";
import TaxesPage from "pages/settings/tax/TaxesPage";
import TerminalSettingsPage from "pages/settings/terminal/TerminalSettingsPage";
import RolesPage from "pages/staff/roles/RolesPage";
import UserEditPage from "pages/staff/users/UserEditPage";
import { UsersPage } from "pages/staff/users/UsersPage";
import NewReportsCategoriesPage from "pages/statistics/new-reports/NewReportsCategoriesPage";
import NewReportsCustomersPage from "pages/statistics/new-reports/NewReportsCustomersPage";
import NewReportsPaymentPage from "pages/statistics/new-reports/NewReportsPaymentsPage";
import NewReportsProductPage from "pages/statistics/new-reports/NewReportsProductPage";
import NewReportsSalesTypePage from "pages/statistics/new-reports/NewReportsSalesTypePage";
import NewReportsStaffPage from "pages/statistics/new-reports/NewReportsStaffPage";
import NewReportsStationPage from "pages/statistics/new-reports/NewReportsStationPage";
import NewReportsTablesPage from "pages/statistics/new-reports/NewReportsTablesPage";
import DeletedReceiptsStatPage from "pages/statistics/receipts/DeletedReceiptsStatPage";
import { ReceiptBetaPage } from "pages/statistics/receipts/ReceiptBetaPage";
import TaxStatsPage from "pages/statistics/TaxStatsPage";
import { usePackages } from "providers/PackageProvider";
import { useEffect, useState } from "react";
import { selectIsShop } from "redux/features/settings";
import IconsPage from "../components/IconsPage";
import DashboardPage from "../pages/dashboard/Dashboard";
import AccountsPage from "../pages/finance/accounts/AccountsPage";
import CashShiftPage from "../pages/finance/cashShift/CashShiftPage";
import FinanceCategoriesPage from "../pages/finance/categories/CategoriesPage";
import PaymentMethodsPage from "../pages/finance/payment-methods/PaymentMethodsPage";
import CustomerTransactionPage from "../pages/finance/transactions/CustomerTransactionPage";
import SupplierTransactionPage from "../pages/finance/transactions/SupplierTransactionsPage";
import TransactionsPage from "../pages/finance/transactions/TransactionsPage";
import InventoryCheckPages from "../pages/inventory/check/InventoryCheckPages";
import BetaSuppliesPageDetailed from "../pages/inventory/supplies/BetaSuppliesPageDetailed";
import ProductReturnSuppliesPage from "../pages/inventory/supplies/ProductReturnSuppliesPage";
import { ReturnFromSupplyCreatePage } from "pages/inventory/supplies/ReturnFromSupplyCreate/ReturnFromSupplyCreatePage";
import ReturnSupplyCreatePage from "../pages/inventory/supplies/ReturnSupplyCreate/ReturnSupplyCreatePage";
import ReasonsReportsPage from "../pages/inventory/write-off/ReasonsReportsPage";
import CustomerGroupPage from "../pages/marketing/customer-group/CustomerGroupPage";
import CustomerEditPage from "../pages/marketing/customer/CustomerEditPage";
import CustomerPage from "../pages/marketing/customer/CustomerPage";
import AccountingCategoriesPage from "../pages/menu/categories/AccountingCategoriesPage";
import AccountingCategoryEditPage from "../pages/menu/categories/AccountingCategoryEditPage";
import MenuCategoriesPage from "../pages/menu/categories/MenuCategoriesPage";
import MenuCategoryEditPage from "../pages/menu/categories/MenuCategoryEditPage";
import IngredientCategoriesPage from "../pages/menu/ingredient-category/IngredientCategoriesPage";
import IngredientCategoryEditPage from "../pages/menu/ingredient-category/IngredientCategoryEditPage";
import IngredientBulkCreatePage from "../pages/menu/ingredients/IngredientBulkCreatePage";
import IngredientCreatePage from "../pages/menu/ingredients/IngredientCreatePage";
import IngredientPage from "../pages/menu/ingredients/IngredientPage";
import ManageModifiersPage from "../pages/menu/manage-modifiers/list/ManageModifiersPage";
import { MenuOrderPage } from "pages/menu/MenuOrderPage";
import PreparationPage from "../pages/menu/preparations/PreparationPage";
import ProductMappingFormPage from "../pages/menu/product-mapping/form/ProductMappingFormPage";
import ProductMappingPage from "../pages/menu/product-mapping/list/ProductMappingPage";
import ProductsPage from "../pages/menu/ProductPage";
import MenuStationCreatePage from "../pages/menu/stations/MenuStationCreatePage";
import MenuStationsPage from "../pages/menu/stations/MenuStationsPage";
import CreateTimerPage from "../pages/products/timer/CreateTimerPage";
import EMenuProductsPage from "../pages/qr-menu/products/eMenuProductsPage";
import SettingsPage from "../pages/qr-menu/settings/SettingsPage";
import EditSaleTypePage from "../pages/sales/EditSaleTypePage";
import OrderNotification from "../pages/sales/OrderNotification";
import SalesTypePage from "../pages/sales/SalesTypePage";
import IntegrationSettingsPage from "../pages/settings/integrations/IntegrationSettingsPage";
import ServiceChargeCreateEditPage from "../pages/settings/service-charge/ServiceChargeCreateEditPage";
import ServiceChargePage from "../pages/settings/service-charge/ServiceChargePage";
import IncentiveProgramCreateEditPage from "../pages/staff/incentive-program/IncentiveProgramCreateEditPage";
import EMenuStatsPage from "../pages/statistics/emenu/EMenuStatsPage";
import NewReportsRoute from "../pages/statistics/new-reports/NewReports";
import NewReportsModifierPage from "../pages/statistics/new-reports/NewReportsModifierPage";
import NewReportsReturnsPage from "../pages/statistics/new-reports/NewReportsReturnsPage";
import ReceiptHistoryPage from "../pages/statistics/ReceiptHistoryPage";
import MergedReceiptsStatPage from "../pages/statistics/receipts/MergedReceiptsStatPage";
import OpenReceiptStatsPage from "../pages/statistics/receipts/OpenReceiptsStatPage";
import ReceiptCreatePage from "../pages/statistics/receipts/ReceiptCreate/ReceiptCreatePage";
import ProductReturnPage from "../pages/statistics/sales/product-return/ProductReturnPage";
import SaleStatsPage from "../pages/statistics/sales/SaleStatsPage";
import VenueDashboardPage from "../pages/statistics/venue-dashboard/VenueDashboard";
import { ModuleEnums } from "./constants";
import { allButQRMenu, allPackages, onlyQRMenu } from "./main";
import { BrandType, IRouteWithComp, Limitation } from "types/routes";
import { reportAccessRoutes } from "config/reportAccessRoutes";
import StaffAccountsPage from "../pages/finance/accounts/StaffAccountsPage";
import CustomerAccountsPage from "../pages/finance/accounts/CustomerAccountsPage";
import SupplierAccountsPage from "../pages/finance/accounts/SupplierAccountsPage";
import ManufacturesPageDetailed from "pages/inventory/manufactures/ManufacturesPageDetailed";
import { IncentiveProgramPage } from "pages/staff/incentive-program/IncentiveProgramPage";
import { IncentiveBonusReportsPage } from "pages/staff/incentive-program/IncentiveBonusReportsPage";
import { IncentiveFinanceTransactionPage } from "pages/staff/incentive-program/IncentiveTransactionsPage";
import { CreateTerminalPage } from "pages/access/terminals/CreateTerminalPage";
import NewRoleEditPage from "pages/staff/roles/NewRoleEditPage";

//this needs for disable page by module

export const useRouteLoader = () => {
    const { subscription } = usePackages();
    const venues = useAppSelector(state => state.venue.venues);
    const isShop = useAppSelector(selectIsShop);
    const { modules } = useModules();
    const [moduleRoutes, setModuleRoutes] = useState<IRouteWithComp[]>([]);

    useEffect(() => {
        setRoutes(generateRoutes(venues, isShop, subscription, moduleRoutes));
    }, [venues, isShop, subscription, moduleRoutes]);

    useEffect(() => {
        setModuleRoutes(
            _.sortBy(Object.entries(modules), [0]).reduce((prev, [, v]) => {
                const routes: IRouteWithComp[] = v.registerRoutes?.() ?? [];
                return [...prev, ...routes];
            }, [] as IRouteWithComp[]),
        );
    }, [modules]);
};

/**
 * @param venues
 * @param isShop
 * @param subscription
 * @param moduleRoutes - bu parameter eslinde muveqqetidi. Cunki, geleceyine daha yxashi olar ki, route sistemine register getirek, meselen istenilen module
 * ozunu istenilen positiona add ede bilsin. Bunu elemek hem cox vaxt teleb edecek, hem de guclu testing lazim olacaq. Ona gore indilik sirf modulelari
 * gonderirem parameter olaraq.
 */
const generateRoutes = (
    venues: IVenue[],
    isShop: boolean,
    subscription: ISubscription | null,
    moduleRoutes: IRouteWithComp[],
) => {
    return [
        {
            title: t("stats"),
            id: "dashboard",
            icon: iStatistics,
            subroutes: [
                { title: t("dashboard"), path: "/", Comp: DashboardPage, packages: allButQRMenu },
                {
                    title: t("branch_report"),
                    path: "/venue-dashboard",
                    Comp: VenueDashboardPage,
                    packages: allButQRMenu,
                    hide: venues.length < 2,
                },
                { title: "Icons page", path: "/clopos-icons-page", Comp: IconsPage, hide: true },
                {
                    title: t("sales"),
                    path: "/statistic/sales",
                    Comp: SaleStatsPage,
                    permissions: ["report_sell"],
                    packages: allButQRMenu,
                },
                {
                    title: t("reports"),
                    path: "/statistic/reports",
                    Comp: NewReportsRoute,
                    permissions: (hasAbility: (i: string[]) => boolean) =>
                        Object.keys(reportAccessRoutes).some(route => hasAbility([route])),
                    packages: allButQRMenu,
                    // hide: true,
                },
                {
                    title: t("reports"),
                    path: "/statistic/reports/products",
                    Comp: NewReportsProductPage,
                    permissions: ["report_product"],
                    packages: allButQRMenu,
                    hide: true,
                },
                {
                    title: t("reports"),
                    path: "/statistic/reports/modifiers",
                    Comp: NewReportsModifierPage,
                    permissions: ["report_product"],
                    packages: allButQRMenu,
                    hide: true,
                },
                {
                    title: t("reports"),
                    path: "/statistic/reports/returns",
                    Comp: NewReportsReturnsPage,
                    permissions: ["report_payment"],
                    packages: allButQRMenu,
                    hide: true,
                },
                {
                    title: t("tables"),
                    path: "/statistic/reports/tables",
                    permissions: ["report_table"],
                    type: BrandType.RESTAURANT,
                    packages: allButQRMenu,
                    Comp: NewReportsTablesPage,
                    hide: true,
                },
                {
                    title: t("sales_type"),
                    path: "/statistic/reports/sales-type",
                    Comp: NewReportsSalesTypePage,
                    permissions: ["report_sell"],
                    packages: allButQRMenu,
                    hide: true,
                },
                {
                    title: t("categories"),
                    path: "/statistic/reports/categories",
                    Comp: NewReportsCategoriesPage,
                    permissions: ["report_category"],
                    packages: allButQRMenu,
                    hide: true,
                },
                {
                    title: t("staff"),
                    path: "/statistic/reports/staff",
                    Comp: NewReportsStaffPage,
                    permissions: ["report_user"],
                    packages: allButQRMenu,
                    hide: true,
                },
                {
                    title: t("stations"),
                    path: "/statistic/reports/stations",
                    permissions: ["report_station"],
                    packages: allButQRMenu,
                    Comp: NewReportsStationPage,
                    hide: true,
                },
                {
                    title: t("customers"),
                    path: "/statistic/reports/customers",
                    Comp: NewReportsCustomersPage,
                    permissions: ["report_customer"],
                    packages: allButQRMenu,
                    hide: true,
                },
                {
                    title: t("payments"),
                    path: "/statistic/reports/payments",
                    permissions: ["report_payment"],
                    packages: allButQRMenu,
                    Comp: NewReportsPaymentPage,
                    hide: true,
                },
                // {
                //     title: t("sales") + ' beta',
                //     path: "/statistic/salesbeta",
                //     Comp: SaleStatsPageCopy,
                //     permissions: ["report_sell"],
                //     packages: allButQRMenu
                // },
                // {
                //     title: t("customers"),
                //     // path: "/statistic/customers",
                //     path: "/statistic/new-reports/customers",
                //     Comp: CustomerStatsPage,
                //     permissions: ["report_customer"],
                //     packages: allButQRMenu,
                //     hide:true
                // },
                // {
                //     title: t("staff"), /*path: "/statistic/waiters",*/
                //     path: "/statistic/new-reports/staff",
                //     Comp: UserStatPage,
                //     permissions: ["report_user"],
                //     packages: allButQRMenu,
                //     hide:true
                // },
                // {
                //     title: t("stations"),
                //     // path: "/statistic/stations",
                //     path: "/statistic/new-reports/stations",
                //     Comp: StationStatsPage,
                //     permissions: ["report_station"],
                //     packages: allButQRMenu,
                //     hide:true
                // },
                // {
                //     title: t("tables"),
                //     // path: "/statistic/tables",
                //     path: "/statistic/new-reports/tables",
                //     Comp: TableStatsPage,
                //     permissions: ["report_table"],
                //     type: BrandType.RESTAURANT,
                //     packages: allButQRMenu,
                //      hide:true
                // },
                // {
                //     title: t("categories"),
                //     // path: "/statistic/categories",
                //     path: "/statistic/new-reports/categories",
                //     Comp: CategoryStatsPage,
                //     permissions: ["report_category"],
                //     packages: allButQRMenu,
                //     hide: true
                // },
                // {
                //     title: t("products"),
                //     // path: "/statistic/goods",
                //     path: "/statistic/new-reports",
                //     Comp: GoodsStatsPage,
                //     permissions: ["report_product"],
                //     packages: allButQRMenu,
                //     hide: true
                // },
                // {title: t("deleted_goods"), path: "/statistic/deletedGoods", Comp: DeletedGoodsStatsPage},
                // {title: t("receipts"), path: "/statistic/receipts", Comp: ReceiptStatsPage},
                {
                    title: t("history"),
                    path: "/statistic/receipt-history",
                    Comp: ReceiptHistoryPage,
                    permissions: ["receipt_view"],
                    packages: allButQRMenu,
                },
                {
                    title: t("taxes"),
                    path: "/statistic/taxes",
                    Comp: TaxStatsPage,
                    permissions: ["tax_report_show"],
                    packages: allButQRMenu,
                },
                // {
                //     title: t("payments"),
                //     // path: "/statistic/payments",
                //     path: "/statistic/new-reports/payments",
                //     Comp: PaymentStatPage,
                //     permissions: ["report_payment"],
                //     packages: allButQRMenu,
                //     hide:true
                // },
                // {
                //     title: t("sales_type"),
                //     // path: "/statistic/saleTypes",
                //     path: "/statistic/new-reports/sales-type",
                //     Comp: SaleTypeDailyStat,
                //     permissions: ["report_sell"],
                //     packages: allButQRMenu,
                //     hide:true
                // },

                {
                    title: t("emenu"),
                    path: "/statistic/emenu",
                    Comp: EMenuStatsPage,
                    permissions: ["emenu_manage"],
                    packages: onlyQRMenu,
                    module: Limitation.EMENU,
                    integrations: [ModuleEnums.EMENU.toString()],
                },
            ],
        },
        {
            title: t("sale"),
            id: "sales",
            icon: iPercentage,
            isNew: false,
            packages: allButQRMenu,
            subroutes: [
                {
                    title: t("receipts"),
                    path: "/sales/receipts",
                    Comp: ReceiptBetaPage,
                    permissions: ["receipt_view"],
                },
                {
                    path: "/sales/receipts/create",
                    Comp: ReceiptCreatePage,
                    permissions: ["receipt_view"],
                    integrations: [ModuleEnums.WEB_CASHIER.toString()],
                },
                {
                    title: t("order_notifications"),
                    path: "/sales/order-notifications",
                    Comp: OrderNotification,
                    permissions: ["receipt_view"],
                },
                {
                    title: t("product_return_setting"),
                    path: "/sales/product-return",
                    Comp: ProductReturnPage,
                    permissions: ["returns_manage_view"],
                },
                {
                    title: t("payment_methods"),
                    path: "/sales/payment-methods",
                    Comp: PaymentMethodsPage,
                    permissions: ["finance_payment_method_view"],
                },
                {
                    path: "/sales/payment-methods/edit/:id",
                    Comp: EditPaymentMethodPage,
                    permissions: ["finance_payment_method_edit"],
                },
                {
                    path: "/sales/payment-methods/create",
                    Comp: EditPaymentMethodPage,
                    permissions: ["finance_payment_method_create"],
                },
                {
                    title: t("sales_type"),
                    path: "/sales/type",
                    Comp: SalesTypePage,
                    permissions: ["sale_type_manage"],
                },
                { path: "/sales/type/edit/:id", Comp: EditSaleTypePage, permissions: ["sale_type_manage"] },
                { path: "/sales/type/create", Comp: EditSaleTypePage, permissions: ["sale_type_manage"] },
                {
                    title: t("open_receipts"),
                    path: "/sales/open-receipts",
                    Comp: OpenReceiptStatsPage,
                    permissions: ["receipt_view"],
                    hide: true,
                },
                {
                    title: t("deleted_receipts"),
                    path: "/sales/deleted-receipts",
                    Comp: DeletedReceiptsStatPage,
                    permissions: ["receipt_view"],
                    hide: true,
                },
                {
                    title: t("merged_receipts"),
                    path: "/sales/merged-receipts",
                    Comp: MergedReceiptsStatPage,
                    permissions: ["receipt_view"],
                    hide: true,
                },
            ],
        },
        {
            title: t("finances"),
            id: "finances",
            icon: iDollar,
            packages: allButQRMenu,
            subroutes: [
                {
                    title: t("accounts"),
                    path: "/finance/accounts",
                    Comp: AccountsPage,
                    access: Limitation.FINANCE,
                    permissions: ["finance_balance_view"],
                },
                {
                    title: t("accounts"),
                    path: "/finance/accounts/customer",
                    Comp: CustomerAccountsPage,
                    access: Limitation.FINANCE,
                    permissions: ["finance_balance_view"],
                    hide: true,
                },
                {
                    title: t("accounts"),
                    path: "/finance/accounts/supplier",
                    Comp: SupplierAccountsPage,
                    access: Limitation.FINANCE,
                    permissions: ["finance_balance_view"],
                    hide: true,
                },
                {
                    title: t("accounts"),
                    path: "/finance/accounts/staff",
                    Comp: StaffAccountsPage,
                    access: Limitation.FINANCE,
                    permissions: ["finance_balance_view"],
                    hide: true,
                },

                {
                    path: "/finance/accounts/edit/:id",
                    Comp: AccountsEditPage,
                    access: Limitation.FINANCE,
                    permissions: ["finance_balance_edit"],
                },

                {
                    path: "/finance/accounts/create",
                    Comp: AccountsEditPage,
                    access: Limitation.FINANCE,
                    permissions: ["finance_balance_create"],
                },

                {
                    title: t("categories"),
                    path: "/finance/categories",
                    Comp: FinanceCategoriesPage,
                    access: Limitation.FINANCE,
                    permissions: ["finance_category_view"],
                },

                {
                    path: "/finance/categories/edit/:id",
                    Comp: EditCategoryPage,
                    access: Limitation.FINANCE,
                    permissions: ["finance_category_edit"],
                },

                {
                    path: "/finance/categories/create",
                    Comp: EditCategoryPage,
                    access: Limitation.FINANCE,
                    permissions: ["finance_category_create"],
                },

                {
                    title: t("transactions"),
                    path: "/finance/transactions",
                    Comp: TransactionsPage,
                    access: Limitation.FINANCE,
                    permissions: ["finance_transaction_view"],
                },

                {
                    title: t("transactions"),
                    path: "/finance/customer-transactions",
                    Comp: CustomerTransactionPage,
                    permissions: ["finance_transaction_view"],
                    hide: true,
                },
                {
                    title: t("transactions"),
                    path: "/finance/supplier-transactions",
                    Comp: SupplierTransactionPage,
                    permissions: ["finance_transaction_view"],
                    hide: true,
                },

                {
                    title: t("payroll"),
                    path: "/finance/payroll",
                    Comp: PayrollPage,
                    access: Limitation.FINANCE,
                    permissions: ["finance_transaction_view"],
                },
                {
                    title: t("cash_flow"),
                    path: "/finance/cashFlow",
                    Comp: CashflowPage,
                    access: Limitation.FINANCE,
                    permissions: ["finance_transaction_view"],
                },
                {
                    title: t("cash_shift"),
                    path: "/finance/cashShift",
                    Comp: CashShiftPage,
                    access: Limitation.FINANCE,
                    permissions: ["cash_shift_view"],
                    integrations: [ModuleEnums.CASH_SHIFT.toString()],
                },
            ],
        },
        {
            title: t("menu"),
            key: "menu",
            id: "menu",
            icon: iKitchenAppliances,
            subroutes: [
                {
                    title: t("all_product_all"),
                    path: "/menu/products",
                    Comp: ProductsPage,
                    permissions: ["product_view"],
                    packages: allPackages,
                },

                {
                    path: "/menu/goods/edit/:id",
                    Comp: GoodsEditPage,
                    permissions: ["product_edit"],
                },

                {
                    path: "/menu/goods/duplicate/:id",
                    Comp: GoodsEditPage,
                    permissions: ["product_create"],
                },

                {
                    path: "/menu/goods/create",
                    Comp: GoodsEditPage,
                    permissions: ["product_create"],
                },
                {
                    path: "/menu/dish/edit/:id",
                    Comp: DishEditPage,
                    permissions: ["product_edit"],
                },

                {
                    path: "/menu/dish/duplicate/:id",
                    Comp: DishEditPage,
                    permissions: ["product_create"],
                },

                {
                    path: "/menu/dish/create",
                    Comp: DishEditPage,
                    permissions: ["product_create"],
                },

                {
                    title: t("preparation"),
                    path: "/menu/preparation",
                    Comp: PreparationPage,
                    permissions: ["product_view"],
                    packages: allPackages,
                },
                { path: "/menu/preparation/duplicate/:id", Comp: PreparationEditPage, permissions: ["product_create"] },
                { path: "/menu/preparation/edit/:id", Comp: PreparationEditPage, permissions: ["product_edit"] },
                { path: "/menu/preparation/create", Comp: PreparationEditPage, permissions: ["product_create"] },
                {
                    title: t("timer"),
                    path: "/menu/timer/create",
                    Comp: CreateTimerPage,
                    permissions: ["product_create"],
                    packages: allPackages,
                    hide: true,
                },
                {
                    title: t("timer"),
                    path: "/menu/timer/edit/:id",
                    Comp: CreateTimerPage,
                    permissions: ["product_edit"],
                    packages: allPackages,
                    hide: true,
                },
                {
                    title: t("ingredient"),
                    path: "/menu/ingredient",
                    Comp: IngredientPage,
                    permissions: ["product_view"],
                    // type: BrandType.RESTAURANT,
                    packages: allButQRMenu,
                },
                { path: "/menu/ingredient/create", Comp: IngredientCreatePage, permissions: ["product_create"] },
                {
                    path: "/menu/ingredient/bulk-create",
                    Comp: IngredientBulkCreatePage,
                    permissions: ["product_create"],
                },
                { path: "/menu/ingredient/edit/:id", Comp: IngredientCreatePage, permissions: ["product_edit"] },

                {
                    title: t("categories"),
                    path: "/menu/categories",
                    Comp: MenuCategoriesPage,
                    permissions: ["category_view"],
                    packages: allPackages,
                },
                {
                    title: t("accounting_categories"),
                    path: "/menu/accounting-categories",
                    Comp: AccountingCategoriesPage,
                    permissions: ["category_view"],
                    packages: allPackages,
                    hide: true,
                },
                { path: "/menu/categories/edit/:id", Comp: MenuCategoryEditPage, permissions: ["category_edit"] },
                { path: "/menu/categories/create", Comp: MenuCategoryEditPage, permissions: ["category_create"] },

                {
                    path: "/menu/accounting-categories/edit/:id",
                    Comp: AccountingCategoryEditPage,
                    permissions: ["category_edit"],
                },
                {
                    path: "/menu/accounting-categories/create",
                    Comp: AccountingCategoryEditPage,
                    permissions: ["category_create"],
                },

                {
                    title: t("ingredient_categories"),
                    path: "/menu/ingredientCategories",
                    Comp: IngredientCategoriesPage,
                    permissions: ["category_view"],
                    type: BrandType.RESTAURANT,
                    packages: allButQRMenu,
                    hide: true,
                },
                {
                    path: "/menu/ingredientCategories/edit/:id",
                    Comp: IngredientCategoryEditPage,
                    permissions: ["category_edit"],
                },
                {
                    path: "/menu/ingredientCategories/create",
                    Comp: IngredientCategoryEditPage,
                    permissions: ["category_create"],
                },

                {
                    title: t("stations"),
                    path: "/menu/stations",
                    Comp: MenuStationsPage,
                    permissions: ["station_view"],
                    packages: allButQRMenu,
                },
                { path: "/menu/stations/create", Comp: MenuStationCreatePage, permissions: ["station_create"] },
                { path: "/menu/stations/edit/:id", Comp: MenuStationCreatePage, permissions: ["station_edit"] },

                {
                    title: t("menu_rearrangement"),
                    path: "/menu/orders",
                    Comp: MenuOrderPage,
                    permissions: ["menu_order"],
                    packages: allPackages,
                },
                {
                    title: t("manage_modifiers"),
                    path: "/menu/manageModifiers",
                    Comp: ManageModifiersPage,
                    permissions: ["modificator_view"],
                    type: BrandType.RESTAURANT,
                    packages: allPackages,
                },
                {
                    title: t("manage_modifiers") + " " + t("edit"),
                    path: "/menu/manageModifiers/edit/:id",
                    Comp: ManageModifiersCreate,
                    permissions: ["modificator_edit"],
                    type: BrandType.RESTAURANT,
                    hide: true,
                },
                {
                    title: t("manage_modifiers") + " " + t("edit"),
                    path: "/menu/manageModifiers/duplicate/:id",
                    Comp: ManageModifiersCreate,
                    permissions: ["modificator_edit"],
                    type: BrandType.RESTAURANT,
                    hide: true,
                },

                {
                    title: t("product_mapping"),
                    path: "/menu/mapping",
                    Comp: ProductMappingPage,
                    permissions: ["product_view"],
                    packages: allPackages,
                    integrations: [ModuleEnums.GETIR.toString()],
                },
                {
                    title: t("product_mapping") + " " + t("edit"),
                    path: "/menu/mapping/edit/:service_name",
                    Comp: ProductMappingFormPage,
                    permissions: ["product_view"],
                    hide: true,
                },
            ],
        },
        {
            title: t("emenu"),
            icon: iQrCode,
            id: "emenu",
            packages: onlyQRMenu,
            module: Limitation.EMENU,
            integrations: [ModuleEnums.EMENU.toString()],
            subroutes: [
                {
                    title: t("products"),
                    path: "/e-menu/products",
                    Comp: EMenuProductsPage,
                    permissions: ["emenu_manage"],
                    integrations: [ModuleEnums.EMENU.toString()],
                },
                {
                    path: "/e-menu/products/goods/edit/:id",
                    Comp: GoodsEditPage,
                    permissions: ["emenu_manage"],
                    integrations: [ModuleEnums.EMENU.toString()],
                },
                {
                    path: "/e-menu/products/dish/edit/:id",
                    Comp: DishEditPage,
                    permissions: ["emenu_manage"],
                    integrations: [ModuleEnums.EMENU.toString()],
                },

                // {
                //     title: t("categories"),
                //     path: "/e-menu/categories",
                //     Comp: EMenuCategoriesPage,
                //     permissions: ["emenu_manage"],
                // },
                // { path: "/e-menu/categories/edit/:id", Comp: EMenuCategoryEditPage, permissions: ["emenu_manage"] },
                // { path: "/e-menu/categories/create", Comp: EMenuCategoryEditPage, permissions: ["emenu_manage"] },

                {
                    title: isShop ? t("showcase_rearrangement") : t("menu_rearrangement"),
                    path: "/e-menu/orders",
                    Comp: MenuOrderPage,
                    permissions: ["emenu_manage"],
                    integrations: [ModuleEnums.EMENU.toString()],
                },

                {
                    title: t("settings"),
                    path: "/e-menu/settings",
                    Comp: SettingsPage,
                    permissions: ["emenu_manage"],
                    integrations: [ModuleEnums.EMENU.toString()],
                },
            ],
        },
        {
            title: t("marketing"),
            icon: iPeople,
            // icon: "peoples",
            id: "marketing",
            subroutes: [
                {
                    title: t("customers"),
                    path: "/marketing/customers",
                    Comp: CustomerPage,
                    access: Limitation.MARKET,
                    permissions: ["customer_manage_view"],
                    packages: allButQRMenu,
                },

                {
                    path: "/marketing/customers/create",
                    Comp: CustomerEditPage,
                    access: Limitation.MARKET,
                    permissions: ["customer_manage_create"],
                },

                {
                    path: "/marketing/customers/edit/:id",
                    Comp: CustomerEditPage,
                    access: Limitation.MARKET,
                    permissions: ["customer_manage_edit"],
                },

                {
                    title: t("customer_groups"),
                    path: "/marketing/customerGroups",
                    Comp: CustomerGroupPage,
                    access: Limitation.MARKET,
                    permissions: ["customer_group_manage_view"],
                    packages: allButQRMenu,
                },
                {
                    title: t("reviews"),
                    path: "/marketing/reviews",
                    Comp: ReviewsPage,
                    // module: Limitation.REVIEW,
                    permissions: ["review_view"],
                    packages: allPackages,
                    integrations: [ModuleEnums.REVIEW.toString()],
                },
                {
                    title: t("loyalty_program"),
                    path: "/marketing/loyalty",
                    Comp: LoyaltyPage,
                    integrations: [ModuleEnums.LOYALTY.toString()],
                    permissions: ["loyalty_view"],
                },
                // {
                //     title: t("loyalty_program"),
                //     path: "/marketing/loyaity/",
                //     Comp: LoyaltyPage,
                //     module: Module.MARKET,
                //     permissions: ["loyalty_manage"],
                //     packages: allPackages,
                // },
            ],
        },
        {
            title: t("inventory"),
            // icon: "retention",
            icon: iBox,
            id: "inventory",
            packages: allButQRMenu,
            subroutes: [
                {
                    title: t("stock"),
                    path: "/inventory/stocks",
                    Comp: StockPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_view"],
                },

                {
                    title: t("supplies"),
                    path: "/inventory/supplies",
                    Comp: SuppliesPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_view_supply"],
                },
                {
                    title: t("supplies") + ` (${t("details")})`,
                    path: "/inventory/supplies/detailed",
                    Comp: BetaSuppliesPageDetailed,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_view_supply"],
                    hide: true,
                },
                // {
                //     title: t("supplies") + ` (${t("details")})`,
                //     path: "/inventory/supplies/betaDetailed",
                //     Comp: BetaSuppliesPageDetailed,
                //     access: Module.STOCK,
                //     permissions: ["stock_manage_view_supply"],
                //     // hide: true,
                // },
                {
                    title: t("supplies") + ` (${t("deleted_supplies")})`,
                    path: "/inventory/supplies/deleted_supplies",
                    Comp: SuppliesPageDeletedSupplies,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_view_supply"],
                    hide: true,
                },
                {
                    title: t("supplies") + ` (${t("draft")})`,
                    path: "/inventory/supplies/supply_draft",
                    Comp: SuppliesPageSupplyDraft,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_view_supply"],
                    hide: true,
                },

                {
                    path: "/inventory/supplies/create",
                    Comp: CreateSupplyPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_supply_create"],
                },

                {
                    path: "/inventory/supplies/edit/:id",
                    Comp: CreateSupplyPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_supply_edit"],
                },

                {
                    path: "/inventory/supplies/duplicate/:id",
                    Comp: CreateSupplyPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_supply_create"],
                },

                {
                    path: "/inventory/supplies/return/:id",
                    Comp: ReturnFromSupplyCreatePage,
                    access: Limitation.STOCK,
                    permissions: ["supply_returns_manage_create"],
                },

                {
                    title: t("returned_supplies"),
                    path: "/inventory/returned-supplies",
                    Comp: ProductReturnSuppliesPage,
                    access: Limitation.STOCK,
                    permissions: ["supply_returns_manage_view"],
                },

                {
                    path: "/inventory/returned-supplies/create",
                    Comp: ReturnSupplyCreatePage,
                    access: Limitation.STOCK,
                    permissions: ["supply_returns_manage_create"],
                },

                {
                    title: t("transfers"),
                    path: "/inventory/transfers",
                    Comp: TransfersPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_transfer"],
                },

                {
                    path: "/inventory/transfers/create",
                    Comp: CreateTransferPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_transfer"],
                },

                {
                    path: "/inventory/transfers/edit/:id",
                    Comp: CreateTransferPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_transfer"],
                },

                {
                    title: t("write_off"),
                    path: "/inventory/write-off",
                    Comp: WastesPages,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_waste_view"],
                },

                {
                    title: t("write_off") + ` (${t("reasons")})`,
                    path: "/inventory/write-off/reasons",
                    Comp: ReasonsPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_waste_view"],
                    hide: true,
                },

                {
                    title: t("write_off") + ` (${t("write_off_reason_reports")})`,
                    path: "/inventory/write-off/reason-reports",
                    Comp: ReasonsReportsPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_waste_view"],
                    hide: true,
                },

                {
                    title: t("write_off") + ` (${t("details")})`,
                    path: "/inventory/write-off/detailed",
                    Comp: WastesPagesDetailed,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_waste_view"],
                    hide: true,
                },

                {
                    path: "/inventory/write-off/create",
                    Comp: CreateWastePage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_waste_manage"],
                },

                {
                    path: "/inventory/write-off/edit/:id",
                    Comp: CreateWastePage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_waste_manage"],
                },

                {
                    title: t("ingredients_movements"),
                    path: "/inventory/ingredientsMovements",
                    Comp: BetaIngredientMovementsPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_movement"],
                },
                {
                    title: t("product_movements"),
                    path: "/inventory/productMovements",
                    Comp: ProductMovementsPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_movement"],
                },
                {
                    title: t("inventory_check"),
                    path: "/inventory/check/editor/create",
                    Comp: InventoryCheckEditorPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_fixation"],
                    hide: true,
                },
                {
                    title: t("inventory_check"),
                    path: "/inventory/check/editor/edit/:id",
                    Comp: InventoryCheckEditorPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_fixation"],
                    hide: true,
                },

                // OLD ROUTES FOR INV
                {
                    title: t("inventory_check"),
                    path: "/inventory/editor/create",
                    Comp: InventoryCheckEditorPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_fixation"],
                    hide: true,
                },
                {
                    title: t("inventory_check"),
                    path: "/inventory/editor/edit/:id",
                    Comp: InventoryCheckEditorPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_fixation"],
                    hide: true,
                },
                // OLD ROUTES FOR INV

                {
                    title: t("inventory_check"),
                    path: "/inventory/check",
                    Comp: InventoryCheckPages,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_fixation"],
                },

                {
                    title: t("storage"),
                    path: "/inventory/storages",
                    Comp: StoragesPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_storage_view"],
                },

                {
                    path: "/inventory/storages/create",
                    Comp: CreateStoragePage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_storage"],
                },

                {
                    path: "/inventory/storages/edit/:id",
                    Comp: CreateStoragePage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_storage"],
                },

                {
                    title: t("packages"),
                    path: "/inventory/packages",
                    Comp: InventoryPackagesPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_packages_view"],
                },

                {
                    path: "/inventory/packages/create",
                    Comp: CreateInventoryPackagePage,
                    access: Limitation.STOCK,
                    permissions: ["stock_packages_manage"],
                },

                {
                    path: "/inventory/packages/edit/:id",
                    Comp: CreateInventoryPackagePage,
                    access: Limitation.STOCK,
                    permissions: ["stock_packages_manage"],
                },

                {
                    title: t("suppliers"),
                    path: "/inventory/suppliers",
                    Comp: SuppliersPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_supplier"],
                },

                {
                    path: "/inventory/suppliers/create",
                    Comp: CreateSupplierPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_supplier"],
                },

                {
                    path: "/inventory/suppliers/edit/:id",
                    Comp: CreateSupplierPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_supplier"],
                },

                {
                    path: "/inventory/supplier-debt",
                    Comp: SuppliersPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_supplier"],
                },

                {
                    title: t("stock_preparations"),
                    path: "/inventory/stock-preparations",
                    Comp: ManufacturesPage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_preparation"],
                    // type: BrandType.RESTAURANT,
                },

                {
                    title: t("stock_preparations") + ` (${t("details")})`,
                    path: "/inventory/stock-preparations/detailed",
                    Comp: ManufacturesPageDetailed,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_preparation"],
                    hide: true,
                    // type: BrandType.RESTAURANT,
                },

                {
                    path: "/inventory/stock-preparations/create",
                    Comp: CreateManufacturePage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_preparation"],
                },

                {
                    path: "/inventory/stock-preparations/edit/:id",
                    Comp: CreateManufacturePage,
                    access: Limitation.STOCK,
                    permissions: ["stock_manage_preparation"],
                },
            ],
        },
        {
            title: t("staff"),
            icon: iChangeQuest,
            id: "staff",
            packages: allButQRMenu,
            subroutes: [
                {
                    title: t("users"),
                    path: "/staff/users",
                    Comp: UsersPage,
                    permissions: ["user_manage_view"],
                },

                { path: "/staff/users/edit/:id", Comp: UserEditPage, permissions: ["user_manage_edit"] },

                { path: "/staff/users/create", Comp: UserEditPage, permissions: ["user_manage_create"] },

                {
                    title: t("roles"),
                    path: "/staff/roles",
                    Comp: RolesPage,
                    permissions: ["role_manage_view"],
                },

                { path: "/staff/roles/edit/:id", Comp: NewRoleEditPage, permissions: ["role_manage_edit"] },

                { path: "/staff/roles/create", Comp: NewRoleEditPage, permissions: ["role_manage_create"] },

                {
                    title: t("incentive_program"),
                    path: "/staff/incentive-program",
                    Comp: IncentiveProgramPage,
                    permissions: ["incentive_program_view"],
                    integrations: [ModuleEnums.INCENTIVE_PROGRAM.toString()],
                },
                {
                    title: t("incentive_program"),
                    path: "/staff/incentive-program/bonus-reports",
                    Comp: IncentiveBonusReportsPage,
                    permissions: ["incentive_program_manage_view"],
                    integrations: [ModuleEnums.INCENTIVE_PROGRAM.toString()],
                    hide: true,
                },
                {
                    title: t("incentive_program"),
                    path: "/staff/incentive-program/finance-transactions",
                    Comp: IncentiveFinanceTransactionPage,
                    permissions: ["incentive_program_manage_view"],
                    integrations: [ModuleEnums.INCENTIVE_PROGRAM.toString()],
                    hide: true,
                },
                {
                    path: "/staff/incentive-program/create",
                    Comp: IncentiveProgramCreateEditPage,
                    permissions: ["incentive_program_create"],
                    integrations: [ModuleEnums.INCENTIVE_PROGRAM.toString()],
                },
                {
                    path: "/staff/incentive-program/edit/:id",
                    Comp: IncentiveProgramCreateEditPage,
                    permissions: ["incentive_program_edit"],
                    integrations: [ModuleEnums.INCENTIVE_PROGRAM.toString()],
                },
            ],
        },
        {
            title: t("add_ons"),
            icon: iModifikator,
            id: "add_ons",
            packages: allButQRMenu,
            subroutes: [
                {
                    title: t("all"),
                    path: "/modules/all",
                    Comp: IntegrationSettingsPage,
                    permissions: ["add_ons_manage"],
                },

                ...moduleRoutes,
            ],
        },
        {
            title: t("devices"),
            icon: iSentToKitchenKitsFail,
            id: "access",
            packages: allButQRMenu,
            subroutes: [
                {
                    title: t("terminals"),
                    path: "/access/terminals",
                    Comp: TerminalsPage,
                    permissions: ["terminal_manage_view"],
                },
                { path: "/access/terminals/edit/:id", Comp: TerminalEditPage, permissions: ["terminal_manage_edit"] },
                { path: "/access/terminals/create", Comp: CreateTerminalPage, permissions: ["owner"] },
            ],
        },
        {
            title: t("settings"),
            icon: iCogOutline,
            // icon: "cog",
            id: "settings",
            subroutes: [
                { path: "/settings/reasons/:type", Comp: ReasonsPage },
                {
                    title: t("general"),
                    path: "/settings/general",
                    Comp: GeneralSettingsPage,
                    permissions: ["setting_general"],
                    packages: allPackages,
                },
                {
                    title: t("terminal"),
                    path: "/settings/terminal",
                    Comp: TerminalSettingsPage,
                    permissions: ["setting_terminal"],
                    packages: allButQRMenu,
                },
                {
                    title: t("taxes"),
                    path: "/settings/taxes",
                    permissions: ["tax_view"],
                    Comp: TaxesPage,
                    packages: allButQRMenu,
                },
                {
                    title: t("taxes"),
                    path: "/settings/taxes/create",
                    permissions: ["tax_create"],
                    Comp: EditTaxPage,
                    hide: true,
                },
                {
                    title: t("taxes"),
                    path: "/settings/taxes/edit/:id",
                    permissions: ["tax_edit"],
                    Comp: EditTaxPage,
                    hide: true,
                },

                {
                    title: t("receipts"),
                    path: "/settings/receipts",
                    Comp: ReceiptSettingsPage,
                    permissions: ["setting_receipt"],
                    packages: allButQRMenu,
                },
                {
                    title: t("subs"),
                    path: "/settings/subs",
                    Comp: SubscriptionPage,
                    hide: subscription?.period_price === 0,
                    packages: allPackages,
                },
                {
                    title: t("table_management"),
                    path: "/settings/halls",
                    Comp: HallsPage,
                    permissions: ["setting_table"],
                    type: BrandType.RESTAURANT,
                    packages: allButQRMenu,
                },
                {
                    path: "/settings/halls/create",
                    Comp: EditHallPage,
                    permissions: ["setting_table"],
                    hide: true,
                },
                {
                    path: "/settings/halls/edit/:id",
                    Comp: EditHallPage,
                    permissions: ["setting_table"],
                    hide: true,
                },
                {
                    path: "/settings/halls/manageTables/:id",
                    Comp: ManageTablesPage,
                    permissions: ["setting_table"],
                    hide: true,
                },

                {
                    title: t("service_charges"),
                    path: "/settings/service-charges",
                    Comp: ServiceChargePage,
                    hide: true,
                    packages: allPackages,
                },
                {
                    title: t("service_charges"),
                    path: "/settings/service-charges/create",
                    Comp: ServiceChargeCreateEditPage,
                    hide: true,
                    packages: allPackages,
                },
                {
                    title: t("service_charges"),
                    path: "/settings/service-charges/edit/:id",
                    Comp: ServiceChargeCreateEditPage,
                    hide: true,
                    packages: allPackages,
                },
            ],
        },
    ];
};
