import { useDrawer } from "components/Drawers/useDrawer";
import { StorageSelectFormGroup } from "components/Form/adhoc/StorageSelectFormGroup";
import DatePickerFormGroup from "components/Form/DatePickerFormGroup";
import RemoteSelectFormGroup from "components/Form/RemoteSelectFormGroup";
import SelectFormGroup from "components/Form/SelectFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import { t } from "lib/i18n";
import { BalanceWithAmount } from "components/mini/BalanceWithAmount";
import { WithInfoIcon } from "components/mini/WithInfoIcon";
import { FinanceAccountDefaultFilters, OperationStatus } from "config/constants";
import _ from "lodash";
import CreateSupplierModal from "pages/inventory/suppliers/CreateSupplierModal";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { Col, Grid, Row } from "rsuite";
import { isEditingPage } from "lib/isEditingPage";

interface IProps {
    isDuplicate: boolean;
    isApproved: boolean;
}

const GeneralTab: FC<IProps> = ({ isDuplicate, isApproved }) => {
    const { getValues, watch, setValue, trigger } = useFormContext();
    const modal = useDrawer();
    const isEdit = isEditingPage();
    const hasSupplyReturn = getValues()?.supply_returns?.length > 0;
    const storageId = watch("storage_id");

    return (
        <div className="tw-max-w-[600px]">
            <Grid fluid>
                <Row>
                    <Col sm={12}>
                        <WithInfoIcon text={t("have_return")} show={hasSupplyReturn} className="tw-mb-3">
                            <RemoteSelectFormGroup
                                name="supplier_id"
                                remote={{
                                    url: "supplier",
                                    limit: 999,
                                }}
                                errorPlacement="rightEnd"
                                selectIfSingle
                                fields="name"
                                width="100%"
                                revalidate={modal.dataId}
                                compProps={{
                                    readOnly: !isDuplicate && hasSupplyReturn,
                                    renderExtraFooter: () => {
                                        return (
                                            <div
                                                test-id="create-new-supplier"
                                                className="rs-picker-select-menu-item text-center"
                                                onClick={() => {
                                                    modal.setDataId(0);
                                                }}
                                            >
                                                {t("create_new_by_name", {
                                                    name: t("supplier"),
                                                })}
                                            </div>
                                        );
                                    },
                                }}
                            />
                        </WithInfoIcon>

                        <WithInfoIcon text={t("have_return")} show={hasSupplyReturn} className="tw-mb-3">
                            <StorageSelectFormGroup
                                width="100%"
                                compProps={{
                                    disabled: hasSupplyReturn,
                                }}
                                refineList={list => list.filter(l => l.status || (isEdit && l.id === storageId))}
                            />
                        </WithInfoIcon>

                        <DatePickerFormGroup
                            time
                            name="operated_at"
                            label={t("operation")}
                            compProps={{
                                cleanable: false,
                                shouldDisableDate: d => !!d && d.getTime() > Date.now(),
                            }}
                        />
                        {/*<CheckboxFormGroup name="supplier_remain" controlWrapperProps={{style: {}}}/>*/}
                        <TextFormGroup name="invoice_number" />
                    </Col>
                    <Col sm={12}>
                        <SelectFormGroup
                            name="status"
                            label={t("status")}
                            data={[
                                { name: t("draft"), id: OperationStatus.DRAFT },
                                { name: t("approved"), id: OperationStatus.APPROVED },
                            ]}
                            compProps={{ disabled: isApproved && !isDuplicate }}
                        />

                        <RemoteSelectFormGroup
                            name="__finance__.category_id"
                            label={t("category")}
                            searchLocal={true}
                            selectIfSingle
                            remote={{
                                url: "finance/category",
                                filters: { pid: ["parent_id", 9] },
                                params: { tree: 0 },
                            }}
                            compProps={{
                                sort: () => (a, b) => a._lft - b._lft,
                                renderMenuItem: (label: any, item: any) => {
                                    return (
                                        <span test-id="__finance__.category_id_item">
                                            {_.times(item.depth).map((x, i) => (
                                                <span key={i} className="px-2">
                                                    --
                                                </span>
                                            ))}
                                            {label}
                                        </span>
                                    );
                                },
                            }}
                        />
                        <RemoteSelectFormGroup
                            name="__finance__.balance_id"
                            label={t("balance")}
                            searchLocal={true}
                            remote={{
                                url: "finance/balance",
                                filters: FinanceAccountDefaultFilters,
                                refineList: (list: any[]) => {
                                    list?.push({ id: "supplier_remain", name: t("supplier_remain") });
                                    return list;
                                },
                            }}
                            compProps={{
                                renderMenuItem: (val: any, item: any) => (
                                    <BalanceWithAmount testId="__finance__.balance_id_item" item={item} />
                                ),
                                renderValue: (val: any, item: any) => <BalanceWithAmount item={item} />,
                            }}
                        />
                        <TextFormGroup name="description" />
                    </Col>
                </Row>
            </Grid>
            <CreateSupplierModal
                {...modal}
                onSelectSupplier={val => {
                    if (val) {
                        setValue("supplier_id", val);
                        trigger("supplier_id");
                    }
                }}
            />
        </div>
    );
};

export default GeneralTab;
