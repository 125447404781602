import { t } from "lib/i18n";
import React, { FC } from "react";
import { FIListItemProps } from "components/FormItems/FormItemsList";
import { GridButtonRemove } from "components/NewForm/Grid/GridButtonRemove";
import { GridSelect } from "components/NewForm/Grid/GridSelect";
import { useProductUnitOptions } from "hooks/useProductUnits";
import { useAppSelector } from "hooks/useRedux";
import { GridInput } from "components/NewForm/Grid/GridInput";
import { roundAndClean } from "@cloposcom/receipt-utils";
import { OperationItemQuantitiesType } from "./ReturnFromSupplyCreatePage";
import { GridProductSelectItem } from "components/NewForm/Grid/GridProductSelectItem";
import { dummyReturnSupplyItem } from "pages/inventory/misc/utils";
import { useCurrency } from "hooks/useCurrency";

export interface ReturnSupplyItemField {
    id?: number;
    product_id: number;
    unit_id: number;
    quantity: number;
    cost: number;
    total_cost: number;
    product_full_name?: string;
    operation_item_quantity?: number;
}

export const ReturnFromSupplyItem: FC<
    FIListItemProps<ReturnSupplyItemField> & { operationItemQuantities: OperationItemQuantitiesType }
> = ({ form, field, index, remove, operationItemQuantities }) => {
    const productsById = useAppSelector(state => state.data.products.byId);
    const currency = useCurrency();

    const items = form.watch("items") ?? [];

    const removeItem = async (index: number) => {
        remove(index);
    };

    const unitOptions = useProductUnitOptions(field.product_id);

    const onChangeProduct = (id: number) => {
        const product = productsById[id];
        const item = dummyReturnSupplyItem({
            product_id: product.id,
            product_full_name: product.full_name ?? product.name,
            unit_id: product.unit_id,
            cost: product.cost_price,
            operation_item_quantity: operationItemQuantities[product.id],
        });

        form.setValue(`items[${index}]`, item);
    };

    function recalculateTotal(item: ReturnSupplyItemField) {
        const subtotal = item.cost * item.quantity;
        const total_cost = roundAndClean(subtotal, 2);
        return { ...item, subtotal, total_cost };
    }

    const onChangeNonTotal = (item: Partial<ReturnSupplyItemField>) => {
        for (const key in item) {
            if ((item as any)[key] !== undefined) {
                (item as any)[key] = Number((item as any)[key] as string);
            }
        }
        const newItem = recalculateTotal({ ...field, ...item });
        form.setValue(`items[${index}]`, newItem);
    };

    const onChangeTotal = (total: number) => {
        if (total === 0 && field.quantity === 0) {
            return;
        }
        const subtotal = roundAndClean(total, 2);
        const cost = roundAndClean(subtotal / field.quantity, 4);

        form.setValue(`items[${index}]`, { ...field, cost, total_cost: total });
    };

    return (
        <>
            <GridProductSelectItem
                name={`items[${index}].product_id`}
                onSelect={onChangeProduct}
                textValue={field.product_full_name}
            />

            <GridSelect
                name={`items[${index}].unit_id`}
                options={unitOptions}
                selectProps={{
                    placeholder: t("unit"),
                    cleanable: false,
                    searchable: false,
                }}
            />
            <GridInput
                name={`items[${index}].quantity`}
                type="number"
                onChangeValue={quantity => {
                    onChangeNonTotal({ quantity });
                }}
                decimals={[0, 3]}
                index={index}
                postfix={unitOptions.find(u => u.id === field.unit_id)?.name}
            />
            <GridInput
                name={`items[${index}].cost`}
                index={index}
                postfix={currency}
                type="number"
                decimals={[2, 4]}
                onChangeValue={val => {
                    onChangeNonTotal({ cost: val });
                }}
            />

            <GridInput
                name={`items[${index}].total_cost`}
                type="number"
                decimals={2}
                onChangeValue={onChangeTotal}
                index={index}
                postfix={currency}
            />

            <GridButtonRemove onClick={() => removeItem(index)} disabled={items.length < 2} />
        </>
    );
};
