import CChart from "components/Chart";
import Widget from "components/Widget";
import { tabs } from "pages/statistics/sales/SaleStatsPage";
import React from "react";
import TabDataDisplay from "./TabDataDisplay";
import { Colors } from "config/colors";
import { t } from "lib/i18n";

interface IProps {
    onClick: () => void;
    tab: (typeof tabs)[number];
    data: any[];
    isActive: boolean;
    total: number;
}

const DataTabWidget: FCC<IProps> = ({ onClick, tab, isActive, data, total }) => {
    const name = tab[0];

    return (
        <div
            key={name}
            style={{
                cursor: "pointer",
                whiteSpace: "pre-wrap",
                width: `calc(100%/${tabs?.length} - 10px)`,
                minHeight: "160px",
            }}
        >
            <Widget hightlight={isActive} onClick={onClick}>
                <div>
                    <span>{t(tab[1] ?? name)}</span> <br />
                    <span style={{ fontSize: 20, fontWeight: 500 }}>
                        <TabDataDisplay name={name} value={total} />
                    </span>
                </div>
                <CChart
                    options={{
                        chart: {
                            backgroundColor: "transparent",
                            height: 50,
                        },
                        tooltip: {
                            enabled: false,
                        },
                        legend: {
                            enabled: false,
                        },
                        xAxis: {
                            visible: false,
                        },
                        yAxis: { visible: false },
                        colors: [isActive ? Colors.White : Colors.SkyBlue],
                    }}
                    series={[
                        {
                            type: "spline",
                            enableMouseTracking: false,
                            // line: { color: "red" },
                            data: data?.map((d: any) => ({
                                y: d[name],
                                x: d?.dateObj?.getTime(),
                                // name: getChartPointLabel(d, period)
                            })),
                        } as any,
                    ]}
                />
            </Widget>
        </div>
    );
};

export default DataTabWidget;
