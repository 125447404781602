import { useData } from "hooks/useData";
import { useEffect, useState } from "react";
import { useLoading } from "hooks/useLoading";
import Ajax from "lib/Ajax";
import _ from "lodash";
import { t } from "lib/i18n";
import { v4 } from "uuid";
import { arrayToMap } from "lib/utils";
import { useHistory } from "react-router-dom";

interface IUseHallManager {
    (id: number): {
        hallIsLoading: boolean;
        isLoading: boolean;
        hall: IHall | undefined;
        save: () => Promise<void> | undefined;
        cleanHall: () => void;
        deleteTable: (table: ITable) => void;
        duplicateTable: (table: ITable) => void;
        updateTable: (table: ITable) => void;
        reset: () => void;
        addTable: () => void;
    };
}

export interface IHallManager extends ReturnType<IUseHallManager> {}

export const useHallManager: IUseHallManager = id => {
    const {
        data: _hall,
        isLoading: hallIsLoading,
        revalidate,
    } = useData<IHall>(`hall/${id}`, {
        with: ["tables"],
    });

    const { isLoading, withLoading } = useLoading();
    const history = useHistory();
    const [hall, setHall] = useState<IHall>();

    useEffect(() => {
        _hall && setHall(_hall);
    }, [_hall]);

    const addTable = () => {
        if (!hall) return;

        const biggestTableNumber =
            Number(_.max(hall.tables?.map(t => parseFloat(t.name.match(/\d+/)?.[0] || "0"))) || 0) ?? 0;

        setHall({
            ...hall,
            tables: (hall.tables || []).concat(generateTable(id, {}, biggestTableNumber + 1)),
        });
    };

    const save = () => {
        if (!hall || !_hall) return;

        const mapBefore = arrayToMap(_hall.tables || [], "id");
        const mapAfter = arrayToMap(hall.tables || [], "id");

        // filter tables which has been changed or didn't exist before
        const changedList = _.filter(mapAfter, t => (mapBefore[t.id] ? !_.isEqual(mapBefore[t.id], t) : true))
            // filter out id if it is string
            .map(t => (isNaN(t.id as any) ? _.omit(t, ["id"]) : t));

        const deletedList = _.filter(_.keys(mapBefore), id => !mapAfter[id]);

        return withLoading(async () => {
            let hasError = false;

            if (deletedList.length) {
                await Ajax.post({
                    url: "table/delete",
                    data: {
                        tables: JSON.stringify(deletedList),
                    },
                }).catch(() => (hasError = true));
            }

            if (changedList.length && !hasError) {
                await Ajax.post({
                    url: "table/update",
                    data: {
                        tables: JSON.stringify(changedList),
                    },
                }).catch(() => (hasError = true));
            }

            if (!hasError) {
                await Ajax.put({
                    url: `hall/${id}`,
                    data: hall,
                }).catch(() => (hasError = true));
            }

            reset();

            if (!hasError) {
                history.push(`/settings/halls`);
            }
        });
    };

    const cleanHall = () => {
        hall &&
            setHall({
                ...hall,
                tables: [],
            });
    };

    const deleteTable = (table: ITable) => {
        hall?.tables &&
            setHall({
                ...hall,
                tables: hall.tables.filter(t => t.id !== table.id),
            });
    };

    const duplicateTable = (table: ITable) => {
        if (!hall?.tables) return;

        /**
         * If the table is near the edge of the hall, we need to duplicate it to the opposite side
         */
        const x = table.x + (table.x + 20 + table.w > 801 ? -20 : 20);
        const y = table.y + (table.y + 20 + table.h > 501 ? -20 : 20);

        setHall({
            ...hall,
            tables: hall.tables.concat(
                generateTable(id, {
                    ...table,
                    name: getNameforCopy(table.name),
                    x,
                    y,
                }),
            ),
        });
    };

    const updateTable = (table: ITable) => {
        hall?.tables &&
            setHall({
                ...hall,
                tables: hall.tables.map(t => (t.id === table.id ? { ...t, ...table } : t)),
            });
    };

    const reset = () => {
        void revalidate();
    };

    return {
        hallIsLoading,
        isLoading,
        hall,
        save,
        cleanHall,
        deleteTable,
        duplicateTable,
        updateTable,
        reset,
        addTable,
    };
};

const generateTable = (hallId: number, data?: Partial<ITable>, counter?: number) =>
    ({
        hall_id: hallId,
        h: 60,
        w: 60,
        x: 1,
        y: 1,
        shape: "RECT",
        name: `${t("table")} ${counter}`,
        ...data,
        id: v4(),
    }) as ITable;

function getNameforCopy(name: string): string {
    if (!isNaN(parseInt(name, 10))) {
        name = parseInt(name, 10) + 1 + "";
    } else {
        const last = _.last(name.split(" ")) as string;
        if (!isNaN(parseInt(last, 10))) {
            name = name?.replace(last, (parseInt(last, 10) + 1).toString());
        } else if (last === "(" + t("copy") + ")") {
            name += " 2";
        } else {
            name += " (" + t("copy") + ")";
        }
    }
    return name;
}
