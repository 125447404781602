import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";

import { useDrawer } from "components/Drawers/useDrawer";
import { ModifierGroupType, ProductType } from "config/constants";
import ModificationGroup from "pages/products/dishes/edit/tabs/modifications/group/ModificationGroup";
import ModificationModal from "pages/products/dishes/edit/tabs/modifications/modal/ModificationModal";
import React, { SyntheticEvent, useEffect, useState } from "react";
import { Button, IconButton, Message, Panel, PanelGroup } from "rsuite";
import { dummyModificatorGroup } from "../../../utils";
import { useFieldArray } from "react-hook-form";
import { v4 } from "uuid";
import { mdiChevronDown, mdiChevronUp, mdiPlus } from "@mdi/js";
import CIcon from "components/CIcon";
import { iEditPencil, iMultiModifier, iSingleModifier } from "assets/icons/Icons";
import CProductsDrawer from "components/Drawers/CProductSelector/CProductsDrawer";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import { useAppDispatch } from "hooks/useRedux";
import { setDisabledProductIds, setShowedTypes } from "redux/features/productsSelector/productsSelector";

const iconStyle = { size: 0.8, color: "rgba(0, 0, 0, 0.5)" };

const ModificationTab: FCC = () => {
    const dispatch = useAppDispatch();
    const { watch, setValue, control, getValues } = useCFormContext();
    const items = watch("modificator_groups") as IModifierGroup[];
    const modal = useDrawer();

    // const lastUpdatedOfProducts = useAppSelector(state => state.productSelector.lastUpdatedOfProducts);

    const { move } = useFieldArray({ control, name: "modificator_groups", keyName: "xxxid" });
    const [hasMoved, setHasMoved] = useState(false);

    useEffect(() => {
        dispatch(setDisabledProductIds([]));
        dispatch(
            setShowedTypes([ProductType.DISH, ProductType.PREPARATION, ProductType.GOODS, ProductType.INGREDIENT]),
        );
    }, []);

    const onClickAdd = () => {
        modal.setDataId(0);
        modal.setData({
            ...dummyModificatorGroup(),
            key: v4(),
        });
    };

    const onClickEdit = (group: IModifierGroup, index: number) => (e: SyntheticEvent) => {
        e.stopPropagation();
        modal.setDataId(1);
        modal.setData({
            id: group.id ?? v4(),
            groupFormIndex: index,
            ...group,
        });
    };

    const moveItem = (i: number, dir: number) => {
        move(i, dir);
        setHasMoved(!hasMoved);
    };

    const up = (i: number) => {
        const goUP = i - 1;
        if (goUP >= 0) {
            moveItem(i, goUP);
        }
    };

    useEffect(() => {
        const positions = items?.reduce(
            (modifier_pos: Record<number, number>, modifer, i) => ({ ...modifier_pos, [modifer.id!]: i }),
            {},
        );
        setValue("meta.modifier_pos", positions);
        // eslint-disable-next-line
    }, [hasMoved]);

    const down = (i: number) => {
        const doDown = i + 1;
        if (doDown <= items.length - 1) {
            moveItem(i, i + 1);
        }
    };

    const getGroupHeader = (item: IModifierGroup, i: number) => {
        return (
            <div
                className="tw-flex tw-flex-1 tw-items-center tw-border-0 tw-border-solid tw-border-slate-100"
                test-id="panel-header"
            >
                <div className="pr-2 ">
                    <div onClick={e => up(i)}>
                        <CIcon {...iconStyle} path={mdiChevronUp} />
                    </div>
                    <div onClick={e => down(i)}>
                        <CIcon {...iconStyle} path={mdiChevronDown} />
                    </div>
                </div>
                {item.type === ModifierGroupType.SINGLE ? (
                    <CIcon
                        {...iconStyle}
                        path={iSingleModifier}
                        className="mr-2 text-black-50"
                        style={{ fillRule: "evenodd" }}
                    />
                ) : (
                    <CIcon
                        {...iconStyle}
                        path={iMultiModifier}
                        className="mr-2 text-black-50"
                        style={{ fillRule: "evenodd" }}
                    />
                )}
                <span>
                    {item.name} {item.type ? `(${t("min")}: ${item.min_select} / ${t("max")}: ${item.max_select})` : ""}
                </span>
                <IconButton
                    icon={<CIcon size={0.8} path={iEditPencil} />}
                    onClick={onClickEdit(item, i)}
                    size="xs"
                    appearance="subtle"
                    className="ml-2"
                />
            </div>
        );
    };

    return (
        <>
            <div className="tw-p-4">
                {getValues("sold_by_weight") ? (
                    <Message showIcon type="warning" children={t("sold_unit_mod")} style={{ maxWidth: "50%" }} />
                ) : null}
                {getValues("meta.hasPortion") ? (
                    <Message showIcon type="warning" children={t("portion_dish_mod_msg")} style={{ maxWidth: "50%" }} />
                ) : null}
                <PanelGroup accordion>
                    {items?.map((item: any, i) => {
                        return (
                            <Panel
                                className="no-padding-header"
                                key={`panel-group-${item.key}`}
                                eventKey={item.key}
                                header={getGroupHeader(item, i)}
                            >
                                <ModificationGroup key={`group-modification-${item.key}`} group={item} index={i} />
                            </Panel>
                        );
                    })}
                </PanelGroup>
                <Button
                    style={{ display: "flex", alignItems: "center" }}
                    test-id={"add-modification-group"}
                    disabled={!!(getValues("sold_by_weight") || getValues("meta.hasPortion"))}
                    className="mt-3"
                    color="cyan"
                    appearance="primary"
                    onClick={onClickAdd}
                >
                    <CIcon size={0.8} path={mdiPlus} className="mr-2" />
                    <TText tkey="add_modif_set" />
                </Button>
            </div>
            <ModificationModal {...modal} />
            <CProductsDrawer />
        </>
    );
};

export default ModificationTab;
