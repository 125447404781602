import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useTitle } from "components/Header/Title";

import DataTable from "../../../components/DataTable/DataTable";
import HeaderRight from "../../../components/Header/HeaderRight";
import { exportFile } from "lib/export-table";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import nrInRoutings from "./nr-in-routings";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useLocation } from "react-router-dom";
import { useData } from "hooks/useData";
import _ from "lodash";
import Money from "components/mini/Money";
import { QuickLinks } from "components/Header/QuickLinks";
import React from "react";
import SourceFilter from "../../../components/DataTable/Filters/SourceFilter";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";

const NewReportsCustomersPage: FCC = () => {
    const loc = useLocation();
    const url = "reports/customer";

    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        url,
        with: ["customer"],
    });

    const summary = useData<any>(url, {
        filters: _.values(dp.filters.all),
        params: {
            total: 1,
            date: dp.params.all.date,
        },
    });

    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;

    const columns: any = [
        {
            title: "ID",
            key: "customer.id",
            align: "left",
            flexGrow: 0.3,
        },
        { key: "customer.name", title: t("name"), align: "left" },
        {
            title: t("total_guests"),
            key: "total_guest",
            align: "right",
            summary: () => summary.data?.[0]?.total_guest,
        },
        {
            title: t("total_receipt"),
            key: "total_receipt",
            align: "right",
            summary: () => summary.data?.[0]?.total_receipt,
        },
        {
            title: t("total_cash"),
            key: "total_cash",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.total_cash} />,
        },
        {
            title: t("cashback"),
            key: "loyalty_value",
            type: "money",
            render: (d: any) => <Money value={d.loyalty_value} />,
        },
    ].filter(c => (isRestaurant ? true : c.key !== "total_guests"));

    const cm = useColumns<any>("reports/customer", columns);

    return (
        <div className="h-100">
            {useTitle(t("customers"), dp.total)}
            <QuickLinks inHeader={false} links={nrInRoutings(loc)} />
            <HeaderRight cm={cm} print export={() => exportFile(dp, cm.columns, t("customers"))} reload date dp={dp} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields={"staff.name"} />
                    <SourceFilter />
                </div>
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
        </div>
    );
};

export default NewReportsCustomersPage;
