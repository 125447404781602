import { makeUseReduxModelData } from "hooks/data-hooks/useReduxModelData";

interface IUseRolesConfig {
    noOwner?: boolean;
}

export const useRoles = makeUseReduxModelData("roles", (roles, conf: IUseRolesConfig) => {
    if (conf.noOwner) {
        return {
            list: roles.ids.map(id => roles.byId[id]).filter(role => role.name !== "Owner"),
        };
    }
    return {};
});
