import { t } from "lib/i18n";
import { cn } from "lib/utils";
import { Tooltip, Whisper } from "rsuite";
import { ReactNode } from "react";
import { useSetting } from "hooks/useSetting";

interface IProps {
    storage: IStorage;
    label: ReactNode;
    name?: string;
    testId?: string;
    hideStatus?: boolean;
}

const StorageCheckListItem: FCC<IProps> = ({ storage, label, name, hideStatus, testId }) => {
    const activeStorages = useSetting("brand_storage");

    const renderStatus = (status?: boolean) => {
        const msg = status ? t("storage_active_for_sale") : t("storage_inactive_for_sale");
        const background = status ? "tw-bg-green-500" : "tw-bg-red-500";

        return (
            <Whisper placement="topEnd" trigger="hover" speaker={<Tooltip>{msg}</Tooltip>}>
                <div className={cn("tw-rounded-full tw-p-1", background)} />
            </Whisper>
        );
    };

    return (
        <div className="tw-flex tw-items-center tw-gap-2" test-id={testId}>
            {!hideStatus && renderStatus(activeStorages?.value?.includes(storage.id))}
            <div
                test-id={name ? `${name}_item` : null}
                className={`${storage.deleted_at ? "tw-text-[#c0392b] font-weight-bold" : ""}`}
            >
                {label}
            </div>
        </div>
    );
};

export default StorageCheckListItem;
