import { t } from "lib/i18n";
import _ from "lodash";

import { Divider } from "rsuite";

interface IProps {
    group: string;
    discount: number | string;
}

const CustomerDiscount: FCC<IProps> = ({ group, discount }) => {
    return (
        <>
            <div className="p-4 d-flex justify-content-around align-items-center">
                <h5 className="font-weight-normal tw-text-[#475f7b]">{group}</h5>
                <span className="group-discount">
                    {_.upperCase(t("group_discount"))}
                    <span className="tw-text-[#5a8dee] pl-2">{discount}%</span>
                </span>
            </div>
            <Divider className="my-0" />
        </>
    );
};

export default CustomerDiscount;
