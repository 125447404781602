import React, { CSSProperties } from "react";
import { Icon } from "@mdi/react";
import _ from "lodash";

interface IProps {
    path: string | any;
    size?: string | number | null;
    style?: CSSProperties;
    className?: string;
    spin?: boolean;
    rotate?: number;
    onClick?: (e?: any) => void;
}

const CIcon = (props: IProps) => {
    return <Icon {..._.cloneDeep({ ...props, size: props.size || 0.6 })} />;
};

export default CIcon;
