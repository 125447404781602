import { t } from "lib/i18n";
import _ from "lodash";

type ReportType = "in" | "out" | "fake" | "final";
export const getReportDates = (arr: ICashflowItem[] = []): string[] => {
    const dates = _.uniq(arr.map(i => i.reports?.map(r => r.identifier)).flat());
    return dates.sort((a, b) => new Date(a).getTime() - new Date(b).getTime());
};
const fakeTransactions = (i: ICashflowItem) => [12, 13, 14, 18, 21, 22, 24].includes(i.id);
const transactions = (i: ICashflowItem, type: ReportType) =>
    (i.type === "BOTH" || i.type === type.toUpperCase()) && !fakeTransactions(i);

export const getReportsByType = (
    arr: ICashflowItem[],
    dates: string[],
    type: ReportType,
    totals?: ICashflowTotal[],
) => {
    arr =
        type === "fake"
            ? arr?.filter(fakeTransactions)
            : type === "final"
              ? []
              : arr.filter(i => transactions(i, type));
    const noLeftPaddingIds = [12, 18, 22, 23, 25];
    const inFake = [21];
    const outFake = [24];
    const data = arr.map(d => [
        {
            value: d.name,
            className: d.parent_id
                ? !noLeftPaddingIds.includes(d.id)
                    ? "pl-3 sticky-td tw-bg-[#ffffff]"
                    : "tw-bg-[#f6fafb] sticky-td"
                : "tw-bg-[#f6fafb] sticky-td",
        },
        ...dates.map(r => {
            const rep = d.reports.find(d => d.identifier === r);
            const children = arr.filter(arr => d.id === arr.id || arr.parent_id === d.id).map(r => r.id);
            const value = inFake.includes(d.id)
                ? getReportItemValue("in", rep)
                : outFake.includes(d.id)
                  ? getReportItemValue("out", rep)
                  : getReportItemValue(type, rep);
            return {
                value,
                className: d.parent_id ? "" : "tw-bg-[#f6fafb]",
                category_id: children.length > 1 ? children : d.id,
                type: type === "fake" ? "out" : type,
                date: r,
            };
        }),
    ]);

    if (totals) {
        const totalByType = [
            {
                value:
                    type.toUpperCase() === "OUT"
                        ? t("total_out")
                        : type.toUpperCase() === "IN"
                          ? t("total_in")
                          : t("total_diff"),
                className: "tw-bg-[#f6fafb] font-weight-bold sticky-td",
            },
            ...dates.map(r => {
                const rep = (totals as any).find((x: any) => x.identifier === r);
                return {
                    value: getReportItemValue(type, rep),
                    className: "tw-bg-[#f6fafb]",
                };
            }),
        ];
        data.push(totalByType as any);
    }
    const spaceRow = [
        {
            value: "",
            className: "sticky-td tw-bg-[#ffffff]",
        },
        ...dates.map(r => {
            return {
                value: "",
                className: "sticky-td tw-bg-[#ffffff]",
            };
        }),
    ];
    data.push(spaceRow as any);

    return data;
};

const getReportItemValue = (type: ReportType | any, item?: ICashflowReport) => {
    const sign = type === "out" ? -1 : 1;
    type = type === "fake" ? "out" : type;
    return sign * _.get(item, `total_${type}`, 0);
};
