import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React, { FC, useEffect, useState } from "react";
import { Button, ButtonGroup, Dropdown, Message, Panel, Popover, Radio, RadioGroup, toaster, Whisper } from "rsuite";
import { useSelector } from "react-redux";
import {
    deletePaymentMethod,
    selectReceiptData,
    selectReceiptPaymentMethods,
    updateReceipt,
    updateReceiptPaymentItem,
} from "redux/features/webCashier";
import { useData } from "hooks/useData";
import CIcon from "../../../../components/CIcon";
import { iClose, iPlus } from "assets/icons/Icons";
import ReceiptNumberInput from "./ReceiptItemNumberInput";
import { IReceiptPaymentMethod } from "@cloposcom/receipt-utils/src/receipt-calculation/interfaces";
import { ReceiptStatus } from "@cloposcom/receipt-utils/dist/receipt-calculation/interfaces";
import { PaymentMethodType } from "config/constants";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useSetting } from "hooks/useSetting";

const StatusList = [
    {
        value: ReceiptStatus.CLOSE_GUEST_LEFT,
        label: t("guest_left"),
    },
    {
        value: ReceiptStatus.CLOSE_ON_HOUSE,
        label: t("on_the_house"),
    },
    {
        value: ReceiptStatus.CLOSE_MISTAKE,
        label: t("mistake_by_name", { name: t("waiter") }),
    },
];

const renderPaymentMethodList = (props: any, ref: any) => {
    const { data, onClose, onSelect, left, top, className } = props;

    const handleSelect = (eventKey: any) => {
        onClose();
        onSelect(data[eventKey]);
    };

    return (
        <Popover ref={ref} className={className} style={{ left, top, width: "230px" }} full>
            <Dropdown.Menu onSelect={handleSelect}>
                {data?.map((paymentMethod: IPaymentMethod, index: number) => {
                    return (
                        <Dropdown.Item eventKey={index} key={paymentMethod.name + index} test-id="payment_method_item">
                            {paymentMethod.name}
                        </Dropdown.Item>
                    );
                })}
            </Dropdown.Menu>
        </Popover>
    );
};

const ReceiptPaymentPlans: FC<{ payment_method_id?: number }> = props => {
    const dispatch = useAppDispatch();
    const { receipt, close_without_payment } = useSelector(selectReceiptData);
    const paymentMethods: Array<IReceiptPaymentMethod> | undefined = useSelector(selectReceiptPaymentMethods);
    const { currentVenueId } = useAppSelector(state => state.venue);

    const [isHideButton, setIsHideButton] = useState(false);
    const [salesPaymentMethod, setPaymentMethod] = useState<any>(null);
    const [isDeactive, setPaymentMethodStatus] = useState<boolean>(false);

    const isFinanceCustomerMinus = useSetting("finance_customer_minus")?.value;

    const paymentMethod = useData("finance/paymentMethod");

    const customer: any = receipt?.meta?.customer;

    useEffect(() => {
        if (paymentMethods!.length > 0) {
            const lastIndex = paymentMethods!.length - 1;
            const maxValue = getMaxValue(lastIndex);
            const payment_method: IReceiptPaymentMethod = paymentMethods![lastIndex];

            if (payment_method.amount > maxValue) {
                recalculatePaymentAmount(payment_method, lastIndex, maxValue);
            }
        }
    }, [receipt.total]);

    useEffect(() => {
        setIsHideButton(false);
        setPaymentMethodStatus(false);
        if (props.payment_method_id) {
            setIsHideButton(true);
            const payment_method: IPaymentMethod = paymentMethod?.data?.find((payment_method: IPaymentMethod) => {
                return payment_method.id === props.payment_method_id;
            });
            if (payment_method) {
                if (payment_method.status[currentVenueId]) {
                    setPaymentMethod(payment_method);
                    onSelectPaymentMethod(payment_method);
                } else {
                    setPaymentMethodStatus(true);
                }
            }
        }
    }, [props.payment_method_id, paymentMethod]);

    const recalculatePaymentAmount = (
        payment_method: IReceiptPaymentMethod,
        index: number,
        maxValue: number,
        lastAmount = 0,
    ) => {
        let amount = maxValue + lastAmount;

        amount = amount >= 0 ? amount : 0;

        dispatch(
            updateReceiptPaymentItem({
                data: { ...payment_method, amount: amount },
                index: index,
            }),
        );

        if (amount === 0) {
            const newMaxValue = getMaxValue(index - 1);

            recalculatePaymentAmount(
                paymentMethods![index - 1],
                index - 1,
                newMaxValue,
                lastAmount + payment_method.amount,
            );
        }
    };

    const onSelectPaymentMethod = (payment_method: IPaymentMethod) => {
        // if (receipt.receipt_products!.length === 0) {
        //     return dispatch(
        //         changeModalState({
        //             product: undefined,
        //             open: true,
        //             type: "warning",
        //             maxPrice: 0,
        //             optional: {
        //                 salesTypeNotSelect: receipt.sale_type_id === 0,
        //                 productNotSelect: receipt.receipt_products.length === 0,
        //                 paymentNotSelect: paymentMethods!.length === 0,
        //             },
        //         }),
        //     );
        // }
        setPaymentMethodStatus(false);
        if (payment_method.split) {
            if (payment_method.customer_required && receipt.customer_id == 0) {
                return toaster.push(
                    <Message type="warning" className="terminal-warning" showIcon closable>
                        <TText tkey="no_customer_chosen" />
                    </Message>,
                    {
                        placement: "bottomEnd",
                        duration: 5000,
                    },
                );
            } else {
                const payment_methods: Array<IReceiptPaymentMethod> = [...receipt.payment_methods!];
                payment_methods.push({
                    id: payment_method.id!,
                    name: payment_method.name,
                    amount: 0,
                });
                dispatch(
                    updateReceipt({
                        key: "payment_methods",
                        value: payment_methods,
                    }),
                );
            }
        } else {
            setIsHideButton(true);
            dispatch(
                updateReceipt({
                    key: "payment_methods",
                    value: [
                        {
                            id: payment_method.id!,
                            name: payment_method.name,
                            amount: 0,
                        },
                    ],
                }),
            );
        }
    };

    const getPaymentMethodList = () => {
        return paymentMethod?.data?.filter((payment_method: IPaymentMethod) => {
            return (
                paymentMethods!.findIndex(
                    (_payment_method: IReceiptPaymentMethod) => _payment_method.id === payment_method.id,
                ) === -1
            );
        });
    };

    const getMaxValue = (index: number, cashback = false, customer_balance = false): number => {
        const receiptTotal: number = receipt.total * 100;
        const totalMethodPrices =
            paymentMethods?.length === 1
                ? 0
                : paymentMethods!
                      .map(payment_method => payment_method.amount)
                      .reduce((previousValue, currentValue: any, currentIndex, array) => {
                          if (currentIndex === index) {
                              return previousValue;
                          }
                          return previousValue + currentValue * 100;
                      }, 0);
        const maxValue = (receiptTotal - totalMethodPrices) / 100;

        if (cashback) {
            return maxValue > customer?.cashback ? customer?.cashback : maxValue;
        }

        if (customer_balance) {
            return maxValue > customer?.balance && !isFinanceCustomerMinus ? customer?.balance : maxValue;
        }

        return maxValue;
    };

    const renderPanelHeader = () => {
        return (
            <span className="header">
                <TText tkey="payment_details" />
            </span>
        );
    };

    return (
        <Panel header={renderPanelHeader()} className="accordion">
            {/*<div className="item d-flex justify-content-between mt-4">*/}
            {/*    <div className="label"><TText key="close_without_payment" /></div>*/}
            {/*    <Toggle*/}
            {/*        checked={close_without_payment}*/}
            {/*        onChange={value => {*/}
            {/*            dispatch(*/}
            {/*                updateCashier({*/}
            {/*                    key: "close_without_payment",*/}
            {/*                    value: value,*/}
            {/*                }),*/}
            {/*            );*/}
            {/*            dispatch(*/}
            {/*                updateReceipt({*/}
            {/*                    key: "status",*/}
            {/*                    value: value ? 5 : 1,*/}
            {/*                }),*/}
            {/*            );*/}
            {/*            dispatch(*/}
            {/*                updateReceipt({*/}
            {/*                    key: "local_status",*/}
            {/*                    value: value ? 5 : 1,*/}
            {/*                }),*/}
            {/*            );*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</div>*/}

            {close_without_payment ? (
                <div>
                    <RadioGroup
                        name="payment-status-group"
                        defaultValue={receipt.status}
                        onChange={(v, c) => {
                            dispatch(
                                updateReceipt({
                                    key: "status",
                                    value: v,
                                }),
                            );
                        }}
                    >
                        {StatusList.map(status => {
                            return (
                                <Radio value={status.value} name="payment-status">
                                    {status.label}
                                </Radio>
                            );
                        })}
                    </RadioGroup>
                </div>
            ) : (
                <>
                    {isDeactive && (
                        <div className="item">
                            <Message type="warning" showIcon>
                                <TText tkey="sale_type_connected_payment_method_inactive" />
                            </Message>
                        </div>
                    )}
                    <div className="item">
                        {paymentMethods?.map((payment_method: IReceiptPaymentMethod, index: number) => {
                            return (
                                <div className="payment-plan" key={`${payment_method.name}_${payment_method.id}`}>
                                    <div className="name">{payment_method.name}</div>
                                    <div className="input">
                                        <ReceiptNumberInput
                                            hideButtons
                                            inputID={"payment_method_amount_" + payment_method.id}
                                            name={"payment_method_amount_" + payment_method.id}
                                            min={0}
                                            max={getMaxValue(
                                                index,
                                                payment_method.id === PaymentMethodType.CASHBACK_BALANCE,
                                                payment_method.id === PaymentMethodType.CUSTOMER_BALANCE,
                                            )}
                                            onChange={(value: any) => {
                                                dispatch(
                                                    updateReceiptPaymentItem({
                                                        data: { ...payment_method, amount: value },
                                                        index,
                                                    }),
                                                );
                                            }}
                                            step={3}
                                            value={payment_method.amount}
                                        />
                                        {props?.payment_method_id && !salesPaymentMethod?.split ? null : (
                                            <div
                                                className="delete"
                                                onClick={() => {
                                                    dispatch(deletePaymentMethod(index));
                                                    setIsHideButton(false);
                                                }}
                                            >
                                                <CIcon path={iClose} />
                                            </div>
                                        )}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    {!isHideButton ? (
                        <div className="item">
                            <ButtonGroup style={{ width: "100%" }}>
                                <Whisper
                                    className="add-payment-plan"
                                    trigger="click"
                                    placement="auto"
                                    speaker={(props, ref) =>
                                        renderPaymentMethodList(
                                            {
                                                data: getPaymentMethodList(),
                                                onSelect: (payment_method: IPaymentMethod) =>
                                                    onSelectPaymentMethod(payment_method),
                                                ...props,
                                            },
                                            ref,
                                        )
                                    }
                                >
                                    <Button className="add-payment-plan" test-id="add-payment-plan">
                                        <CIcon path={iPlus} />
                                        <div className="text">
                                            <TText tkey="payment_method" /> <TText tkey="add" />
                                        </div>
                                    </Button>
                                </Whisper>
                            </ButtonGroup>
                        </div>
                    ) : null}
                </>
            )}
        </Panel>
    );
};

export default ReceiptPaymentPlans;
