import _ from "lodash";
import React, { useEffect, useRef } from "react";
import { Input, InputGroup, InputNumber } from "rsuite";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import { globalNumberInputChecker, isNumeric } from "lib/utils";
import { PlainText } from "./PlainText";
import CIcon from "../CIcon";
import { iMinus, iPlus } from "assets/icons/Icons";
import { useNumberInput } from "hooks/useNumberInput";
import { useCurrency } from "hooks/useCurrency";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

interface IProps extends CFormGroupProps {
    type?: "money" | "number" | "text" | "time";
    focus?: boolean;
    plaintext?: boolean;
    postFix?: any;
    test?: string;
}

const TextFormGroup: FCC<IProps> = ({ comp = Input, compProps = {}, type = "text", postFix, ...props }) => {
    const currency = useCurrency();
    const { getValues } = useCFormContext();
    const newProps: IProps = { comp, ...props };

    const ref = useRef<HTMLInputElement>();

    useEffect(() => {
        if (!ref.current) return;

        if (props.focus) {
            ref.current.focus();
        }

        ref.current.onfocus = () => {
            if (isNumeric(ref.current?.value || "") && Number(ref.current?.value) === 0) {
                ref.current?.select();
            }
        };
        // eslint-disable-next-line
    }, [props.focus, ref]);

    if (props.plaintext) {
        return <PlainText label={props.label || ""} name={props.name} value={getValues(props.name) || ""} />;
    }

    newProps.compProps = {
        ...compProps,
        autoComplete: "off",
        // className: cn(compProps.className, "tw-w-input-lg"),
        // inputRef: ref, //TODO: onsuz da ishlemirdi. baxmaq lazim olacaq ne ucundu bu.
    };

    if (!_.isNil(postFix)) {
        newProps.comp = NumberInput as any;
        newProps.compProps.postFix = postFix;
    }

    let _style = {
        width: "300px",
    };

    if (postFix || type === "money" || type === "number") {
        _style = Object.assign(
            {
                borderBottomRightRadius: "0 !important",
                borderTopRightRadius: "0 !important",
            },
            _style,
        );

        newProps.comp = NumberInput as any;

        if (type === "money") {
            newProps.compProps.postFix = currency;
        }

        if (newProps.compProps.postFix) {
            _style.width = newProps.compProps.width ?? "260px";
        }
    }

    newProps.compProps.style = { ..._style, ...newProps.compProps.style };

    return <CFormGroup {...(_.omit(newProps, ["focus"]) as any)} />;
};

interface INumberInputProps {
    name: string;
    value: any;
    onChange: any;
    increaseValue: (val: any) => void;
    decreaseValue: (val: any) => void;
    postFix?: string;
    spinner?: boolean;
    disabled?: boolean;
    min?: number;
    max?: number;
    interval?: number;
}

const NumberInput = React.forwardRef<unknown, INumberInputProps>(
    ({ name, value, interval, onChange, increaseValue, decreaseValue, spinner, postFix, ...props }, ref) => {
        const inputID = "input-" + name;
        useNumberInput("number", inputID);
        const _increaseValue = () => {
            let _value = 0;
            if (props.max !== undefined && value + 1 > props.max) {
                _value = props.max;
            } else {
                _value = value + (interval ? interval : 1);
            }
            increaseValue ? increaseValue(_value) : onChange(_value);
        };

        const _decreaseValue = () => {
            let _value = 0;
            if (props.min !== undefined && value - 1 < props.min) {
                _value = props.min;
            } else {
                _value = value - (interval ? interval : 1);
            }
            decreaseValue ? decreaseValue(_value) : onChange(_value);
        };

        return (
            <InputGroup>
                {spinner && (
                    <InputGroup.Button
                        disabled={props.disabled}
                        test-id={`${name}-minuse-button`}
                        className="minus-button"
                        onClick={() => _decreaseValue()}
                    >
                        <CIcon path={iMinus} />
                    </InputGroup.Button>
                )}
                <InputNumber
                    ref={ref}
                    name={name}
                    id={inputID}
                    test-id={inputID}
                    buttonAppearance="primary"
                    value={value}
                    type="number"
                    scrollable={false}
                    onWheel={(e: any) => e.target?.blur()} // disable mouse wheel
                    {...props}
                    onChange={(_value: any, event: any) => {
                        if (event.type === "change") {
                            onChange(globalNumberInputChecker(_value), event);
                        }
                    }}
                />
                {spinner && (
                    <InputGroup.Button
                        test-id={`${name}-plus-button`}
                        disabled={props.disabled}
                        className={"plus-button " + (postFix ? "" : "border")}
                        onClick={() => _increaseValue()}
                    >
                        <CIcon path={iPlus} />
                    </InputGroup.Button>
                )}
                {postFix ? (
                    <InputGroup.Addon style={{ width: "auto", minWidth: 40 }}>{postFix}</InputGroup.Addon>
                ) : (
                    <InputGroup.Addon
                        className="empty-addons d-none"
                        style={{ backgroundColor: props.disabled ? "#f7f7fa" : "transparent" }}
                    ></InputGroup.Addon>
                )}
            </InputGroup>
        );
    },
);

export default TextFormGroup;
