import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { fetchVenuesAsync } from "./thunks";
import { depot } from "@cloposcom/libs";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

interface VenueState {
    venues: IVenue[];
    currentVenueId: number;
}

const initialState: VenueState = {
    venues: [],
    currentVenueId: 0,
};

const venueSlice = createSlice({
    name: ReduxSlicesNames.VENUES,
    initialState,
    reducers: {
        setVenueList(state, action: PayloadAction<IVenue[]>) {
            state.venues = action.payload;
        },
        setCurrentVenue(state, action: PayloadAction<number>) {
            state.currentVenueId = action.payload;
            if (isNaN(state.currentVenueId)) {
                state.currentVenueId = parseInt(window.location.pathname.split("/")[2]);
            }
            if (isNaN(state.currentVenueId) || !state.currentVenueId) {
                state.currentVenueId = parseInt(depot.getItem("lastActiveVenue") ?? "");
            }
            if (isNaN(state.currentVenueId) || !state.currentVenueId) {
                state.currentVenueId = 1;
            }
            depot.setItem("lastActiveVenue", action.payload);
        },
    },
    extraReducers: builder => {
        builder.addCase(fetchVenuesAsync.fulfilled, (state, action) => {
            state.venues = action.payload ?? [];
        });
    },
});

export const { setCurrentVenue, setVenueList } = venueSlice.actions;
export default venueSlice.reducer;
