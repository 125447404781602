import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import { ChromePicker, CirclePickerProps } from "react-color";

type IProps = CFormGroupProps<CirclePickerProps>;

const BlockColorPicker: FCC<IProps> = props => {
    return (
        <CFormGroup
            compProps={
                {
                    render: ({ field: { onChange, value } }: any) => {
                        return (
                            <ChromePicker
                                color={"#" + value}
                                onChange={color => {
                                    onChange(color.hex.substr(1));
                                }}
                            />
                        );
                    },
                } as any
            }
            {...(props as any)}
        />
    );
};

export default BlockColorPicker;
