import { t } from "lib/i18n";
import { FlexboxGrid } from "rsuite";
import DataPair from "../../mini/DataPair";
import { FORMATS } from "config/constants";
import DataTable from "../../DataTable/DataTable";
import React from "react";
import { productName } from "lib/data-utils";
import { countDecimal, translateUnitName } from "lib/utils";
import { useTableHeight } from "hooks/useTableHeight";
import Money from "components/mini/Money";
import _ from "lodash";
import { format } from "date-fns";

const getColumns = (cols?: string[], data?: IStockOperation[]) => {
    const columns: ITableColumnProps<IStockOperation>[] = [
        {
            key: "product.name",
            title: t("product"),
            align: "left",
            render: d => productName(d.product),
            flexGrow: 2,
        },
        { key: "from_storage.name", title: t("from_storage") },
        { key: "storage.name", title: t("to_storage") },
        {
            key: "after_quantity",
            title: t("after"),
            align: "right",
            render: d => d.after_quantity,
            suffix: d => <i>{translateUnitName(d.unit, "")}</i>,
            flexGrow: 2,
        },
        {
            key: "before_quantity",
            title: t("before"),
            align: "right",
            render: d => d.before_quantity,
            suffix: d => <i>{translateUnitName(d.unit, "")}</i>,
        },
        {
            key: "quantity",
            title: t("quantity"),
            // align: "right",

            suffix: d => <i>{translateUnitName(d.unit || d.product?.unit, "")}</i>,
            render: (d: IStockOperation) => {
                return countDecimal(d.quantity) > 4 ? parseFloat(d.quantity.toString()).toFixed(4) : d.quantity;
            },
        },
        {
            key: "diff",
            align: "right",
            getData: d => parseFloat((d.after_quantity - d.before_quantity).toFixed(3)),
            getStyle: d => {
                const diff = d.after_quantity - d.before_quantity;
                return { color: diff < 0 ? "red" : "green" };
            },
            suffix: d => <i>{translateUnitName(d.unit, "")}</i>,
        },
        {
            key: "cost",
            type: "money",
            render: (d: any) => <Money value={d?.cost} />,
            align: "right",
            numberRoundingLength: 4,
        },
        {
            key: "total_cost",
            title: t("total"),
            type: "money",
            align: "right",
            summary: () => <Money value={_.sumBy(data, "total_cost")} />,
            render: d => <Money value={d?.total_cost} />,
        },
    ];
    return cols ? columns.filter(c => cols.includes(c.key)) : columns;
};

export default function SuppliedProductsTab({ op, cols, stockOperations, ...props }: any) {
    const height = useTableHeight(170);
    return (
        <>
            {op && (
                <>
                    <div className="d-none d-print-block">
                        <h5>{props.title}</h5>
                        <hr />
                        <FlexboxGrid>
                            <FlexboxGrid.Item colspan={8}>
                                <DataPair name="amount" type="money" value={op.transaction_finance?.amount} />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={8}>
                                <DataPair name="supplier" value={op.supplier?.name} />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={8}>
                                <DataPair name="date" value={format(new Date(op.operated_at), FORMATS.date_full)} />
                            </FlexboxGrid.Item>

                            <FlexboxGrid.Item colspan={8}>
                                <DataPair name="account" value={op.transaction_finance?.balance?.name} />
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={8}>
                                <DataPair name="storage" value={op?.storage?.name} />
                            </FlexboxGrid.Item>
                        </FlexboxGrid>

                        <hr />
                    </div>
                    <DataTable
                        hidePages
                        noShadow
                        columns={getColumns(cols, stockOperations)}
                        dp={{ data: stockOperations } as any}
                        tableProps={{
                            id: "print-table",
                            headerHeight: 49,
                            autoHeight: false,
                            height,
                        }}
                    />
                </>
            )}
        </>
    );
}
