import React, { FC } from "react";
import { Button } from "rsuite";
import { TText } from "../../i18n/TText";

interface IExportModalFileList {
    onClick: () => void;
    disabled: boolean;
    isLoading: boolean;
}

export const ExportModalButton: FC<IExportModalFileList> = ({ onClick, disabled, isLoading }) => {
    return (
        <Button
            test-id="generate-export-file"
            className="rs-btn05"
            disabled={disabled}
            loading={isLoading}
            onClick={() => onClick?.()}
            appearance="primary"
            style={{
                fontWeight: "bold",
                borderRadius: "8px",
                width: "100%",
                height: "40px",
            }}
        >
            <TText tkey="download" />
        </Button>
    );
};
