import CInputFormGroup from "components/Form/CInputFormGroup";
import _ from "lodash";
import React, { ComponentType, ReactNode, useEffect, useRef } from "react";
import { Input } from "rsuite";
import { CFormGroupProps } from "./CFormGroup";
import { globalNumberInputChecker, isNumeric } from "lib/utils";
import { useNumberInput } from "hooks/useNumberInput";

interface IProps extends CFormGroupProps {
    type?: "money" | "number" | "text";
    postFix?: string | ReactNode;
    preFix?: string | ReactNode;
    focus?: boolean;
}

const TextPostfixFormGroup: FCC<IProps> = ({
    comp = Input,
    type = "text",
    compProps = {},
    postFix,
    preFix,
    placeholder,
    ...props
}) => {
    const newProps: IProps = { ...props };
    const ref = useRef<HTMLInputElement>();

    newProps.compProps = {
        ...compProps,
        autoComplete: "off",
        inputRef: ref,
        postfix: postFix,
        prefix: preFix,
    };

    const inputID: string = "input-" + props.name;
    useNumberInput(type || "number", inputID);

    useEffect(() => {
        if (ref.current) {
            if (props.focus) {
                ref.current.focus();
            }
            ref.current.onfocus = () => {
                if (isNumeric(ref.current?.value || "") && Number(ref.current?.value) === 0) {
                    ref.current?.select();
                }
            };
        }
    }, [props.focus, ref, preFix]);

    if (type === "number" || type === "money") {
        const cprops: any = {
            render: ({ field: { name, value, onChange } }: any) => {
                return (
                    <Input
                        name={name}
                        id={inputID}
                        test-id={name}
                        placeholder={placeholder}
                        step={0.0001}
                        onWheel={(e: any) => e.target?.blur()}
                        value={value ? value : ""}
                        style={{ textAlign: "right" }}
                        type="number"
                        {...newProps.compProps}
                        onChange={(value: any, event: any) => {
                            onChange(globalNumberInputChecker(value, newProps.compProps.decimalLimit || 5), event);
                        }}
                    />
                );
            },
            ...newProps.compProps,
        };

        const compo = _.omit(newProps, "comp") as any;
        return <CInputFormGroup {...compo} compProps={cprops} />;
    }

    return <CInputFormGroup hideLabel={props.hideLabel} {...newProps} comp={comp as ComponentType} />;
};

export default TextPostfixFormGroup;
