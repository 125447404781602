import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import TabsView from "components/TabsView";
import { useData, UseDataReturnType } from "hooks/useData";
import ProductReturnsDetails from "pages/statistics/receipts/ProductReturnsDetails";
import ReceiptDetails from "pages/statistics/receipts/ReceiptDetails";
import ReceiptHistory from "pages/statistics/receipts/ReceiptHistory";
import { useIntegrationByName, usePackages } from "providers/PackageProvider";
import { iSpinner } from "assets/icons/Icons";
import { Colors } from "config/colors";
import { ReceiptHistoryProvider, useReceiptHistory } from "hooks/useReceiptHistory";
import ReceiptDeductedStock from "pages/statistics/receipts/ReceiptDeductedStock";
import CIcon from "components/CIcon";
import { Trash } from "config/constants";
import ReceiptIncentiveBonus from "pages/statistics/receipts/ReceiptIncentiveBonus";

type IProps = ICDrawerProps;

const ReceiptContainer = ({
    r,
    isPlanStandard,
    props,
}: {
    r: UseDataReturnType<IReceipt>;
    isPlanStandard: boolean;
    props: any;
}) => {
    const history = useReceiptHistory();
    const incentive = useIntegrationByName("incentive_program");

    return (
        <div className="h-100">
            {!r.isLoading && r.data ? (
                <TabsView
                    tabs={[
                        { label: t("receipt"), Component: ReceiptDetails, props: { r: r.data, ...props }, id: 0 },
                        {
                            label: (
                                <div>
                                    <TText tkey="history" />{" "}
                                    <span
                                        style={{
                                            background: Colors.LightGray,
                                            padding: "0 8px",
                                            borderRadius: "1rem",
                                            color: Colors.GunmetalBlue,
                                            fontWeight: "500",
                                            fontSize: "12px",
                                        }}
                                    >
                                        {history?.data?.length}
                                    </span>{" "}
                                </div>
                            ),
                            Component: ReceiptHistory,
                            props: { r: r.data, history, ...props },
                            id: 1,
                        },
                        {
                            label: t("deducted_stock"),
                            hide: !isPlanStandard,
                            Component: ReceiptDeductedStock,
                            props: { r: r.data },
                            id: 2,
                        },
                        {
                            label: (
                                <div>
                                    <TText tkey="product_return_setting" />{" "}
                                    {r?.data?.returns?.length ? (
                                        <span
                                            style={{
                                                background: Colors.PaleSkyBlue,
                                                padding: "0 8px",
                                                borderRadius: "1rem",
                                                color: Colors.CobaltBlue,
                                                fontWeight: "500",
                                                fontSize: "12px",
                                            }}
                                        >
                                            {r?.data?.returns?.length}
                                        </span>
                                    ) : null}
                                </div>
                            ),
                            Component: ProductReturnsDetails,
                            props: { r: r.data, ...props },
                            id: 3,
                        },
                        {
                            label: t("incentive_program"),
                            Component: ReceiptIncentiveBonus,
                            props: { r: r.data },
                            hide: !incentive,
                            id: 4,
                        },
                    ]}
                />
            ) : (
                <CIcon path={iSpinner} spin className="m-3" /> //h
            )}
        </div>
    );
};

const ReceiptDrawer: FCC<IProps> = props => {
    const r = useData<IReceipt>(!!props.dataId && `receipt/${props.dataId}`, {
        params: { trash: Trash.TrashAndActive },
        with: [
            "hall",
            "table",
            "user_delete",
            "sale_type",
            "customer",
            "returns",
            "returns.user",
            "returns.return_products.product",
            "returns.transaction",
        ],
    });
    const packages = usePackages();
    const packageID = packages?.subscription?.package?.id;
    const isPlanStandard = packageID === 3 || packageID === 7 || packageID === 18 || packageID === 19;
    const integrationOrderNumber = (r.data as IReceipt | undefined)?.meta?.notification?.order_number;
    const integrationOrder = integrationOrderNumber
        ? `(${(r.data as IReceipt | undefined)?.sale_type?.name}: ${integrationOrderNumber})`
        : "";

    return (
        <CDrawer
            style={{ width: "70vw" }}
            title={
                <>
                    <TText tkey="receipt" />:
                    <div className="text-primary ml-1"> {`${props.dataId} ${integrationOrder}`}</div>
                </>
            }
            {...props}
        >
            <ReceiptHistoryProvider
                url={`receipt/log`}
                params={{ limit: 999 }}
                stop={!r.data?.cid}
                with={["terminal"]}
                sort={["timestamp", "1"]}
                filters={{
                    cid: ["cid", r.data ? (r.data as any).cid : props.dataId],
                }}
            >
                <ReceiptContainer isPlanStandard={isPlanStandard} r={r} props={props} />
            </ReceiptHistoryProvider>
        </CDrawer>
    );
};

export default ReceiptDrawer;
