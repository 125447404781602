import _ from "lodash";
import React, { ComponentType, HTMLAttributes } from "react";
import { Controller } from "react-hook-form";
import { Form, FormControlLabelProps, FormGroupProps, InputGroup, InputNumberProps } from "rsuite";
import { getLabel } from "./form-utils";
import { TypeAttributes } from "rsuite/cjs/@types/common";
import { ControllerRenderProps } from "react-hook-form/dist/types/controller";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

export interface CFormGroupProps<T = InputNumberProps & { addonClass?: string }> extends FormGroupProps {
    name: string;
    label?: string | false;
    comp?: ComponentType<T>;
    compProps?: T & { "test-id"?: string; render: (field: ControllerRenderProps) => React.ReactNode };
    controlWrapperProps?: HTMLAttributes<HTMLDivElement>;
    tooltip?: string;
    error?: string;
    labelProps?: FormControlLabelProps;
    errorPlacement?: TypeAttributes.Placement8;
    hideLabel?: boolean;
}

const CInputFormGroup: FCC<CFormGroupProps> = ({
    tooltip,
    name,
    label,
    comp,
    compProps,
    controlWrapperProps,
    labelProps,
    hideLabel,
    ...props
}) => {
    const ctx = useCFormContext();
    const error = ctx ? (_.get(ctx.formState.errors, name)?.message as string) : props.error;
    const controlProps = _.omit(controlWrapperProps, "className");
    const className = "rs-form-control-wrapper " + (controlWrapperProps?.className ?? "");
    const controllerProps = _.omit(compProps, "addonClass");
    const testId = controllerProps?.["test-id"] ?? name;

    return (
        <Form.Group {...props}>
            {hideLabel
                ? null
                : label !== false && <Form.ControlLabel {...labelProps}>{label ?? getLabel(name)}</Form.ControlLabel>}

            <div {...controlProps} className={className}>
                <InputGroup {...props}>
                    {compProps?.prefix && (
                        <InputGroup.Addon className={compProps?.addonClass}>{compProps?.prefix}</InputGroup.Addon>
                    )}
                    <Controller
                        control={ctx.control}
                        name={name}
                        render={({ field }) => {
                            const Comp = comp as any;
                            return compProps?.render ? (
                                compProps.render(field)
                            ) : Comp ? (
                                <Comp {...field} {...controllerProps} />
                            ) : null;
                        }}
                        {...(controllerProps as any)}
                        test-id={testId}
                    />
                    {compProps?.postfix && (
                        <InputGroup.Addon className={compProps?.addonClass}>{compProps?.postfix}</InputGroup.Addon>
                    )}
                    {tooltip && <Form.HelpText tooltip>{tooltip}</Form.HelpText>}
                    <Form.ErrorMessage show={!!error} placement={props.errorPlacement ?? "bottomStart"}>
                        <span test-id={`${testId}-error`}>{error}</span>
                    </Form.ErrorMessage>
                </InputGroup>
            </div>
        </Form.Group>
    );
};

CInputFormGroup.defaultProps = {
    controlWrapperProps: {},
};

export default CInputFormGroup;
