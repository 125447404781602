import React, { FC, useEffect, useRef } from "react";
import { InputGroup, InputNumber } from "rsuite";
import CIcon from "../../../../components/CIcon";
import { iMinus, iPlus } from "assets/icons/Icons";
import { globalNumberInputChecker } from "lib/utils";

const ReceiptNumberInput: FC<any> = ({
    name,
    onChange,
    value,
    min,
    max,
    disabled,
    hideButtons,
    placeholder,
    style,
    inputStyle,
    inputID,
    prefix,
    step,
}) => {
    const ref = useRef();

    const _increaseValue = () => {
        onChange(value + 1);
        let _value = 0;
        if (max !== undefined && value + 1 > max) {
            _value = max;
        } else {
            _value = value + 1;
        }

        onChange(_value);
    };

    const _decreaseValue = () => {
        let _value = 0;
        if (min !== undefined && value - 1 < min) {
            _value = min;
        } else {
            _value = value - 1;
        }
        onChange(_value);
    };

    useEffect(() => {
        const inputBox = document.getElementById(inputID);

        const invalidChars = ["-", "+", "e"];

        const inputListener: any = inputBox?.addEventListener("keydown", function (e) {
            if (invalidChars.includes(e.key)) {
                e.preventDefault();
            }
        });
        return () => {
            inputBox?.removeEventListener("keydown", inputListener);
        };
    }, []);

    return (
        <div className="count-input columns" style={style}>
            <InputGroup>
                {!hideButtons ? (
                    <InputGroup.Button className="minus-button" onClick={() => _decreaseValue()}>
                        <CIcon path={iMinus} />
                    </InputGroup.Button>
                ) : null}
                <InputNumber
                    id={inputID}
                    ref={ref}
                    name={name}
                    test-id={name}
                    disabled={disabled}
                    value={value}
                    type="number"
                    scrollable={false}
                    style={inputStyle}
                    placeholder={placeholder}
                    onWheel={(e: any) => e.target?.blur()}
                    onClick={event => {
                        if (inputID) {
                            const input: any = document.getElementById(inputID);
                            input.focus();
                            input.select();
                        }
                    }}
                    onBlur={() => {
                        if (value < min) {
                            onChange(min);
                        }
                    }}
                    onChange={(_value: any, event: any) => {
                        const vl = globalNumberInputChecker(_value, step);
                        vl <= max ? onChange(vl) : null;
                    }}
                />
                {prefix ? <InputGroup.Addon style={{ width: 40 }}>{prefix}</InputGroup.Addon> : null}
                {!hideButtons ? (
                    <InputGroup.Button
                        className={"plus-button "}
                        test-id="increase-btn"
                        onClick={() => _increaseValue()}
                    >
                        <CIcon path={iPlus} />
                    </InputGroup.Button>
                ) : null}
            </InputGroup>
        </div>
    );
};

export default ReceiptNumberInput;
