import { getLabel } from "components/Form/form-utils";
import _ from "lodash";
import React, { useContext, useEffect } from "react";
import { SelectPicker, SelectPickerProps } from "rsuite";
import { IDataProvider, IDataProviderConfig, useDataProvider } from "../useDataProvider";
import { FiltersContext } from "./FiltersContainer";
import { useData } from "hooks/useData";
import CIcon from "../../CIcon";
import { iSpinner } from "assets/icons/Icons";
import { productName } from "lib/data-utils";
import { Colors } from "config/colors";
import { useDebouncedSearch } from "hooks/useDebouncedSearch";
import { Trash } from "config/constants";

interface IProps {
    dp?: IDataProvider;
    fields: string;
    remote: string | IDataProviderConfig;
    labelField?: string;
    searchField?: string;
    pickerProps?: Partial<SelectPickerProps<any>>;
    comparison_value?: number;
    fieldType?: "params";
    cleanable?: boolean;
    addToFilter?: boolean;
    searchRemotely?: boolean;
    selectedDetails?: {
        url: string;
    };
}

const RemoteSelectFilter: FCC<IProps> = _props => {
    const props = _.omit(_props, "dp");
    const filtersContext = useContext(FiltersContext);
    const dp = _props.dp || filtersContext.dp;

    const localDp = useDataProvider(_.isString(props.remote) ? { url: props.remote } : props.remote);
    const options = localDp.isLoading ? [] : localDp.data;

    const fieldType = (dp as any)[props.fieldType || "filters"];
    let value = fieldType.getFilter(props.fields) ?? "";
    value = _.isArray(value) && !_.isEmpty(value) ? value[1] : _.isArray(value) && _.isEmpty(value) ? "" : value;

    const selectedProducts = useData(value && _props.selectedDetails?.url, {
        filters: {
            id: ["id", value],
        } as any,
        params: {
            trash: Trash.TrashAndActive,
        },
    });

    const comparisonOperator = (value: string) => {
        if (!_.isNil(props.comparison_value)) {
            return value ? [props.fields, value, props.comparison_value] : "";
        }
        return [props.fields, value];
    };

    useEffect(() => {
        if (value) {
            if (props.addToFilter && props.fields === "storage_id") {
                const filter: any[] = [`stocks.${props.fields}`, value];
                dp.filters.add(filter, props.fields);
            }
        } else {
            dp.filters.remove(props.fields);
        }
        // eslint-disable-next-line
    }, [value]);

    // used to add filter to global dp object
    const onSelect = (val: any) => {
        if (val) {
            if (props.fieldType) {
                fieldType.add(props.fields, val);
            } else {
                val = comparisonOperator(val);
                fieldType.add(val, props.fields);
            }
        } else {
            fieldType.remove(props.fields);
        }
    };

    const { searchInputValue, setSearchInputValue } = useDebouncedSearch(() => onSearch(searchInputValue));

    const onSearch = (val: string) => {
        if (val) {
            localDp.filters.add([props.searchField || props.fields, val], props.fields);
        } else {
            localDp.filters.remove(props.fields);
        }
    };

    const pickerProps: Partial<SelectPickerProps<any>> = {
        placeholder: getLabel(props.fields),
        ...props.pickerProps,
    };

    return (
        <SelectPicker
            {...pickerProps}
            data={options}
            labelKey={"name"}
            valueKey="id"
            onSearch={_props?.searchRemotely ? (val: string) => setSearchInputValue(val) : undefined}
            onSelect={props?.pickerProps?.onSelect || onSelect}
            value={value}
            cleanable={!!_.isNil(props.cleanable) && !!value}
            onClean={() => fieldType.remove(props.fields)}
            renderValue={(value, item, selectedElement: React.ReactNode) => {
                return _props.selectedDetails?.url && selectedProducts.data?.length ? (
                    <SelectFilterValue selectedItems={selectedProducts.data || ([] as any)} keyIndex={"name"} />
                ) : (
                    selectedElement
                );
            }}
        />
    );
};

RemoteSelectFilter.defaultProps = {
    pickerProps: {
        searchable: false,
    },
};

interface IIProps {
    selectedItems: any[];
    keyIndex: string;
}

const SelectFilterValue: FCC<IIProps> = ({ selectedItems, keyIndex }) => {
    return selectedItems.length ? (
        <span className="rs-picker-toggle-value">
            <span className="rs-picker-value-list" title={`${selectedItems.map(t => t[keyIndex]).join(" , ")}`}>
                {selectedItems.map((t, i) => {
                    return (
                        <>
                            <span className="rs-picker-value-item"> {productName(t)}</span>
                            {selectedItems.length - 1 !== i && <span className="rs-picker-value-separator">,</span>}
                        </>
                    );
                })}
            </span>
        </span>
    ) : (
        <p style={{ padding: 1, color: Colors.MediumGray, textAlign: "center" }}>
            <CIcon path={iSpinner} spin />
        </p>
    );
};

export default RemoteSelectFilter;
