import { t } from "lib/i18n";
import { useEffect } from "react";
import CForm from "components/Form/CForm/CForm";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import JsonView from "components/JsonView";
import TabsView from "components/TabsView";
import { useCForm } from "hooks/useCForm";
import { getDataPropertiesOf, isUrl } from "lib/utils";
import _ from "lodash";
import { dummyGoods } from "models/GoodsModel";
import EditGeneralTab from "pages/products/goods/EditGeneralTab";
import EditVariantsTab from "pages/products/goods/EditModificationsTab";
import { useParams } from "react-router-dom";
import { usePackages } from "providers/PackageProvider";
import EditEGoodsTab from "pages/products/goods/EditEGoodsTab";
import CDrawerHeaderRight from "components/Drawers/CDrawerHeaderRight";
import ConfirmModal from "../Drawers/ConfirmModal";
import { useLoading } from "hooks/useLoading";
import { useDrawer } from "../Drawers/useDrawer";
import { onConfirmCreateExists, onSubmitCheckName } from "./CheckNameUtil";
import { InventoryBehavior, ProductType } from "config/constants";
import { GoodSchema } from "form-schemas/GoodSchema";
import { Limitation } from "types/routes";
import { useSetting } from "hooks/useSetting";
import { useEmenuLanguages } from "hooks/useEmenuLanguages";
import { isInDuplicatePage } from "lib/isInDuplicatePage";

interface IProps {
    showPageHeader?: boolean;
    showDrawerHeader?: boolean;
    afterSubmit?: (d: any) => void;
    afterSubmitCallback?: boolean;
    categoryID?: string;
}

const CreateGoodComponent: FCC<IProps> = props => {
    const isDuplicate = isInDuplicatePage();
    const params = useParams<any>();
    const packages = usePackages();
    const returnsMaxAge = useSetting("returns_max_age")?.value;
    const existGoodModal = useDrawer();
    const { isLoading } = useLoading();
    const emenuLangs = useEmenuLanguages();

    const form = useCForm({
        url: "product/goods",
        isDuplicate,
        mode: "all",
        with: ["modifications", "media", "taxes"],
        model2: GoodSchema,
        dummyData: dummyGoods,
        prepareForSubmit: (d: any) => {
            const images = d.images?.length ? d.images[0].uuid : "";
            const fields = isUrl(images) ? ["image", "images"] : ["image"];

            d = _.omit(d, fields.concat(["created_at", "updated_at"]));
            d.max_age = d.max_age || null;
            d.emenu_hidden = d.emenu_hidden ? 1 : 0;
            d.inventory_behavior = d.inventory_behavior
                ? InventoryBehavior.PASSIVE
                : InventoryBehavior.MINUS_INGREDIENTS;

            if (d.has_modifications) {
                d.modifications.forEach((m: IVariant) => {
                    m.meta = m.meta ?? {};
                    m.name = m.deleted_at && !m.name ? m.slug || "deleted modification" : m.name;
                    m.ignore_service_charge = d?.ignore_service_charge;
                    m.properties = getDataPropertiesOf(d);
                    m.meta.hasPortion = d?.meta?.hasPortion;
                    m.discountable = d?.discountable;
                    m.inventory_behavior = d?.inventory_behavior;

                    emenuLangs.map(lang => {
                        if (m?.meta?.langs && Array.isArray(d?.meta?.langs[lang])) {
                            m.meta.langs[lang] = {} as any;
                        }
                    });
                    // m.meta.gov_package_code = d?.meta?.gov_package_code;
                });
            }

            return params?.id || d.has_modifications
                ? _.omit(d, ["meta.fromMenuConstruct"])
                : _.omit(d, ["modifications", "meta.fromMenuConstruct"]);
        },
        refine: (d: any) => {
            d.inventory_behavior =
                d.inventory_behavior === InventoryBehavior.MINUS_INGREDIENTS
                    ? InventoryBehavior.MINUS_INGREDIENTS
                    : InventoryBehavior.MINUS_SELF;
            d.max_age = !_.isNil(d.max_age) ? `${d.max_age}` : d.max_age;
            /**
             * menu/goods/edit sehifesinde price backendden 0 gele bilir. Ve ashagida yazilan kodun kohne versiyasinda
             * price 0 olarsa onu silirdi ve validationda partlayirdi. Onun qarshisini almaq ucun bele bir shert elave etdim. Amma, ne qeder dogru oldugunu
             * bilmirem. Bununla elaqeli neyise deyishmezden once ashagidaki tickete baxin:
             *
             * https://clopos.atlassian.net/jira/software/c/projects/CL/boards/22?assignee=634e84c3d66d8108a12163f8&selectedIssue=CL-714
             *
             * kohne kod:
             * d = _.pickBy(d, _.identity);
             *
             * UPDATE: silindi
             */
            // d = _.pickBy(d, value => value === 0 || _.identity(value));

            if (isInDuplicatePage()) {
                d.name =
                    d.name +
                    ` (${t("duplicate")}-${new Date().getTime().toString(36) + new Date().getUTCMilliseconds()})`;
            }

            d.setting_max = returnsMaxAge;

            d.properties = getDataPropertiesOf(d);

            // todo: delete this (second part) if today is after 30 September
            d.properties.gov_package_code = d.properties.gov_package_code || d?.meta?.gov_package_code;

            emenuLangs.forEach(lang => {
                if (d?.meta?.langs && Array.isArray(d?.meta?.langs[lang])) {
                    d.meta.langs[lang] = {};
                }

                if (!d.has_modifications) return;

                d.modifications.forEach((m: any) => {
                    if (m?.meta?.langs && Array.isArray(m?.meta?.langs[lang])) {
                        m.meta.langs[lang] = {};
                    }
                });
            });

            return !_.isEmpty(d.media)
                ? {
                      images: [{ uuid: d.media[0]?.urls?.thumb }],
                      ...d,
                  }
                : d;
        },
        hasAllVenues: true,
        afterSubmit: d => props?.afterSubmit?.(d),
        afterSubmitCallback: props?.afterSubmitCallback,
    });

    const name = form.form.watch("name");
    const formTitle = useFormTitle(form.id, "", `${form.form.getValues("name")} - (ID: ${form.id})`);

    useEffect(() => {
        if (_.isNil(props?.categoryID)) return;

        form.form.setValue("category_id", props.categoryID);
        form.form.setValue("meta", {
            ...form.form.getValues("meta"),
            fromMenuConstruct: true,
        });

        // eslint-disable-next-line
    }, [props?.categoryID]);

    useEffect(() => {
        form.form.setValue("setting_max", returnsMaxAge);

        // eslint-disable-next-line
    }, []);

    return (
        <div className="position-relative">
            {props?.showPageHeader && (
                <>
                    {formTitle}
                    <HeaderRight
                        formSubmit={{
                            isLoading: form.isLoading,
                            onSave: async (event, isSaveCreate) => {
                                await onSubmitCheckName({
                                    form,
                                    existGoodModal,
                                    type: ProductType.GOODS,
                                    isSaveCreate,
                                    event,
                                });
                            },
                        }}
                    />
                </>
            )}

            {props?.showDrawerHeader && <CDrawerHeaderRight show saveIconBtn formSubmit={form} />}

            <CForm form={form.form} onSave={form.onSave}>
                <JsonView />
                <TabsView
                    tabs={[
                        {
                            label: t("general"),
                            Component: EditGeneralTab,
                            hasError: !!form.form.formState.errors.name,
                        },
                        {
                            label: t("modification"),
                            Component: EditVariantsTab,
                        },
                        {
                            label: t("emenu"),
                            Component: EditEGoodsTab,
                            hide: !packages?.limitation?.[Limitation.EMENU],
                            hasError: !!form.form.formState.errors.meta,
                        },
                    ]}
                />
            </CForm>

            <ConfirmModal
                isLoading={isLoading}
                {...existGoodModal}
                onConfirm={async (_, event) => {
                    await onConfirmCreateExists({
                        event: event!,
                        form,
                        existGoodModal,
                        // saveCreate: !isDuplicate,
                    });
                }}
                onClose={() => existGoodModal.setIsOpen(false)}
                content={t("create_goods_by_exists_name", { name })}
            />
        </div>
    );
};

export default CreateGoodComponent;
