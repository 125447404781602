import _ from "lodash";
import { format } from "date-fns";
import { useCForm } from "hooks/useCForm";
import { dummyReturnSupplyOperation, dummySupplyItem } from "../../misc/utils";
import { cleanEmptyStrings } from "lib/api-utils";
import { useFormTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import HeaderRight from "components/Header/HeaderRight";
import Ajax from "lib/Ajax";
import CForm from "components/Form/CForm/CForm";
import JsonView from "components/JsonView";
import { FormItems } from "components/FormItems/FormItems";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { SupplyItemField } from "../NewSupplyCreate/SupplyItem";
import { MassInsertButton } from "components/FormItems/MassInsertButton";
import CIcon from "components/CIcon";
import { iPlus } from "assets/icons/Icons";
import { AddByBarcodeButton } from "../NewSupplyCreate/AddByBarcodeButton";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { GridItemTotal } from "components/NewForm/Grid/GridItemTotal";
import ImportResultModal from "../modals/ImportResultModal";
import { useSupplyImport } from "../NewSupplyCreate/useSupplyImport";
import { ReturnSupplyItem } from "./ReturnSupplyItem";
import { ReturnSupplySchema } from "form-schemas/ReturnSupplySchema";
import { OperationStatus } from "config/constants";
import ReturnSupplyGeneralTab from "./ReturnSupplyGeneralTab";
import CProductsDrawer from "components/Drawers/CProductSelector/CProductsDrawer";
import { FC } from "react";
import { useCurrency } from "hooks/useCurrency";
import { useAppSelector } from "hooks/useRedux";
import { isContainValue } from "lib/pure";

const columns = [["product"], ["unit_id"], ["qty"], ["cost"], ["total"], [""]] as const;

const ReturnSupplyCreatePage: FC = () => {
    const productsById = useAppSelector(state => state.data.products.byId);

    const searchableColumns: SearchableColumns<Mutable<typeof columns>> = {
        product: (searchVal: string, item: any) => isContainValue(searchVal, productsById[item?.product_id]?.slug),
    };

    const form = useCForm({
        url: "product/supply-return",
        mode: "all",
        dummyData: dummyReturnSupplyOperation,
        model2: ReturnSupplySchema,
        prepareForSubmit: data => {
            const total_amount = _.sumBy(data.items, (x: any) => parseFloat(_.get(x, "total_cost")));

            const postData: any = {
                ...cleanEmptyStrings(data),
                category_id: data?.category_id,
                meta: {
                    ...data.meta,
                    total_amount,
                },
                status: OperationStatus.APPROVED,
                supplier_remain: data?.balance_id === "supplier_remain" ? 1 : 0,
                from_storage_id: data?.from_storage_id,
                supplier_id: data?.supplier_id,
                description: data?.description,
                operated_at: data?.operated_at ? format(new Date(data?.operated_at), "yyyy-MM-dd HH:mm:ss") : undefined,
                items: data?.items?.map((i: any) => ({
                    product_id: i?.product_id,
                    quantity: i?.quantity,
                    cost: i?.cost,
                    total_cost: i?.total_cost,
                })),
            };

            if (postData?.balance_id === "supplier_remain") {
                delete postData?.balance_id;
            }

            return postData;
        },
    });

    const { importModal, importProps } = useSupplyImport(form);
    const currency = useCurrency();

    return (
        <div className="tw-p-4 tw-h-full">
            {useFormTitle(form.id, t("supply"))}
            <HeaderRight
                formSubmit={form}
                export={
                    form.id
                        ? () =>
                              Ajax.download(
                                  { url: `operation/excel-actions-for-supply/${form.id}` },
                                  `${t("supplies")} - ${form.id}`,
                              )
                        : undefined
                }
                importOptions={importProps}
            />
            <CForm {...form} formProps={{ layout: "vertical", fluid: true }}>
                <JsonView />

                <ReturnSupplyGeneralTab />

                <FormItems itemsFieldName="items">
                    <FormItemsList<SupplyItemField, Mutable<typeof columns>>
                        columns={columns as Mutable<typeof columns>}
                        searchableColumns={searchableColumns}
                        virtualize
                        children={_props => {
                            return <ReturnSupplyItem {..._props} />;
                        }}
                        getRowProps={rowData => {
                            return {
                                className: rowData.field.disabled ? "tw-bg-red-50/50 hover:tw-bg-red-50" : "",
                            };
                        }}
                        footer={
                            <div className="tw-grid tw-grid-cols-subgrid tw-col-span-9 tw-sticky tw-bottom-0 tw-bg-white tw-border-gray-50 tw-border-solid tw-border-0 tw-border-t-2  tw-py-2 tw-z-10">
                                <div className="tw-flex tw-col-span-3 tw-gap-2 ">
                                    <MassInsertButton
                                        idField="product_id"
                                        refineItem={product => {
                                            return dummySupplyItem({
                                                product_id: product.id,
                                                unit_id: product.unit_id,
                                                cost: product.cost_price,
                                                total_cost: 0,
                                            });
                                        }}
                                    >
                                        <CIcon path={iPlus} className="mr-2" />
                                        {t("add")}
                                    </MassInsertButton>
                                    {/* <FormItemsAddButton dummyData={dummySupplyItem} /> */}
                                    <AddByBarcodeButton />
                                </div>
                                <GridItemPlain
                                    align="right"
                                    className="tw-bg-transparent tw-border-none"
                                    value={t("total") + ":"}
                                />
                                <GridItemTotal name="total_cost" decimals={2} postfix={currency} />
                            </div>
                        }
                    />
                </FormItems>
            </CForm>
            <ImportResultModal {...importModal} />
            <CProductsDrawer />
        </div>
    );
};

export default ReturnSupplyCreatePage;
