import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { useAppDispatch } from "hooks/useRedux";

interface StockCountsSelectorState {
    stockCounts: Record<number, number>;
    queue: number[];
}

const initialState: StockCountsSelectorState = {
    stockCounts: {},
    queue: [],
};

const stockCountsSelector = createSlice({
    name: "stockCountSelector",
    initialState,
    reducers: {
        setStockCounts: (state, action: PayloadAction<Record<number, number>>) => {
            state.stockCounts = {
                ...state.stockCounts,
                ...action.payload,
            };
        },
        addToQueue: (state, action: PayloadAction<number>) => {
            if (state.queue.includes(action.payload) || state.stockCounts[action.payload] !== undefined) return;

            state.queue.push(action.payload);
        },
        resetQueue: state => {
            state.queue = [];
        },
        resetStockCounts: state => {
            state.stockCounts = {};
        },
    },
});

const { resetStockCounts, resetQueue, addToQueue, setStockCounts } = stockCountsSelector.actions;

const queueStockCount = (productId: number) => (dispatch: ReturnType<typeof useAppDispatch>) => {
    dispatch(addToQueue(productId));
};

export { resetStockCounts, resetQueue as resetStockCountQueue, queueStockCount, setStockCounts };
export default stockCountsSelector.reducer;

export const selectStockCountOf = (productId: number) => (state: RootState) =>
    state.stockCounts.stockCounts[productId] || 0;
