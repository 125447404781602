import { createAsyncThunk } from "@reduxjs/toolkit";
import { captureMessage } from "@sentry/react";
import Ajax from "lib/Ajax";
import { resetStockCountQueue, setStockCounts } from "redux/features/stockCountsSelector";
import _ from "lodash";
import { Trash } from "config/constants";

const fetchStockCounts = createAsyncThunk(
    "data/fetchStockCounts",
    async (
        {
            storageId,
            productIds,
        }: {
            storageId: number;
            productIds: number[];
        },
        { dispatch },
    ) => {
        try {
            const url = Ajax.buildUrl({
                url: "stock/product",
                params: {
                    trash: Trash.TrashAndActive,
                    limit: productIds.length,
                    filters: {
                        storage: ["storage_id", storageId],
                        id: ["id", productIds],
                    },
                    with: ["total_quantity"],
                },
            });

            const response = await Ajax.get({
                url,
            });

            if (response.data) {
                const idMap: Record<number, number> = productIds.reduce(
                    (acc, id) => ({
                        ...acc,
                        [id]: 0,
                    }),
                    {},
                );

                for (const product of response.data) {
                    idMap[product.id] = product.total_quantity;
                }

                dispatch(setStockCounts(idMap));
                dispatch(resetStockCountQueue());
            }
        } catch (e) {
            captureMessage("Could not fetch stock counts", {
                extra: {
                    error: e,
                },
            });
        }
    },
);

const _debouncedFetchStockCounts = _.debounce((arg, dispatch) => {
    dispatch(fetchStockCounts(arg));
}, 50);

export const debouncedFetchStockCounts = (storageId: number, productIds: number[]) => (dispatch: any) =>
    _debouncedFetchStockCounts({ storageId, productIds }, dispatch);
