import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import JsonViewState from "components/State/JsonViewState";
import Ajax from "lib/Ajax";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { Checkbox, IconButton, Message, SelectPicker, toaster } from "rsuite";
import { iClose } from "assets/icons/Icons";
import CIcon from "../CIcon";
import { useSetting } from "hooks/useSetting";
import { useAppDispatch } from "hooks/useRedux";
import { setSettingByField } from "redux/features/settings";

type IProps = ICDrawerProps;

export type DayHourValue = {
    name: string;
    open: string | null;
    close: string | null;
    fullTime?: boolean;
};

type DayHourPicker = {
    mon: DayHourValue;
    tue: DayHourValue;
    wed: DayHourValue;
    thu: DayHourValue;
    fri: DayHourValue;
    sat: DayHourValue;
    sun: DayHourValue;
};

const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"] as const;

const WorkTimeDrawer: FCC<IProps> = props => {
    const dispatch = useAppDispatch();
    const workHours: any = useSetting("brand_work_hours")?.value;
    const [hours, setHours] = useState<{ label: string; value: string }[]>([]);
    const [dayValues, setDayValues] = useState<DayHourPicker>({
        mon: {
            name: "mon",
            open: null,
            close: null,
            fullTime: false,
        },
        tue: {
            name: "tue",
            open: null,
            close: null,
            fullTime: false,
        },
        wed: {
            name: "wed",
            open: null,
            close: null,
            fullTime: false,
        },
        thu: {
            name: "thu",
            open: null,
            close: null,
            fullTime: false,
        },
        fri: {
            name: "fri",
            open: null,
            close: null,
            fullTime: false,
        },
        sat: {
            name: "sat",
            open: null,
            close: null,
            fullTime: false,
        },
        sun: {
            name: "sun",
            open: null,
            close: null,
            fullTime: false,
        },
    });

    useEffect(() => {
        const arr: { label: string; value: string }[] = [];
        let i, j;
        for (i = 0; i < 24; i++) {
            for (j = 0; j < 4; j++) {
                const hour = i + ":" + (j === 0 ? "00" : 15 * j);
                arr.push({ label: hour, value: hour });
            }
        }
        setHours(arr);
    }, []);

    useEffect(() => {
        if (workHours.length !== 0) {
            for (const x in workHours) {
                setDayValues(prev => ({
                    ...prev,
                    [workHours[x]?.name]: workHours[x],
                }));
            }
        }
    }, [workHours]);

    const submitAction = async () => {
        const filteredWorkHours = Object.values(dayValues).filter(dv => dv.fullTime || (dv.open && dv.close));

        await Ajax.post({
            url: "setting/update/",
            data: {
                settings: {
                    brand_work_hours: {
                        access: true,
                        group: "brand",
                        name: "brand_work_hours",
                        type: "string",
                        value: JSON.stringify(filteredWorkHours),
                    },
                },
            },
        })
            .then(res => {
                dispatch(
                    setSettingByField({
                        brand_work_hours: {
                            ...res.data.brand_work_hours,
                            value: filteredWorkHours,
                        },
                    }),
                );
            })
            .catch(() =>
                toaster.push(
                    <Message type="error" showIcon closable>
                        <TText tkey="error" />
                    </Message>,
                ),
            );
        props.onClose?.({} as any);
    };

    return (
        <CDrawer {...props} title={t("work_hours")} saveIBtn={submitAction} style={{ width: 500 }}>
            <div className={"tw-flex tw-flex-col tw-gap-2"}>
                {daysOfWeek.map(d => (
                    <div key={d} className={"tw-flex tw-flex-row tw-justify-around tw-items-center tw-mt-2"}>
                        <div className={"tw-flex tw-flex-col tw-gap-1"}>
                            <label style={{ width: 100 }}>{t(d)}</label>
                            <Checkbox
                                checked={dayValues[d].fullTime}
                                onChange={(value, checked) =>
                                    setDayValues(prev => ({ ...prev, [d]: { ...prev[d], fullTime: checked } }))
                                }
                            >
                                {t("t_24_hourly")}
                            </Checkbox>
                        </div>
                        <SelectPicker
                            size="md"
                            placeholder={t("opening")}
                            data={hours}
                            value={(dayValues[d as never] as DayHourValue)?.open}
                            onChange={v =>
                                setDayValues((prev: any) => ({
                                    ...prev,
                                    [d]: {
                                        ...prev[d],
                                        open: v,
                                    },
                                }))
                            }
                            placement={"leftStart"}
                            cleanable={false}
                            style={{ width: 100 }}
                            disabled={dayValues[d].fullTime}
                        />
                        <SelectPicker
                            size="md"
                            placeholder={t("closing")}
                            data={hours}
                            value={(dayValues[d as never] as DayHourValue)?.close}
                            onChange={v =>
                                setDayValues((prev: any) => ({
                                    ...prev,
                                    [d]: {
                                        ...prev[d],
                                        close: v,
                                    },
                                }))
                            }
                            placement={"leftStart"}
                            cleanable={false}
                            style={{ width: 100 }}
                            disabled={dayValues[d].fullTime}
                        />
                        {!_.isNil((dayValues[d as never] as DayHourValue)?.close) ||
                        !_.isNil((dayValues[d as never] as DayHourValue)?.open) ? (
                            <IconButton
                                icon={<CIcon path={iClose} />}
                                onClick={() =>
                                    setDayValues(prev => ({
                                        ...prev,
                                        [d]: {
                                            name: d,
                                            open: null,
                                            close: null,
                                            fullTime: false,
                                        },
                                    }))
                                }
                            />
                        ) : (
                            <div style={{ height: 36, width: 36 }} />
                        )}
                    </div>
                ))}
            </div>
            <JsonViewState value={{ dayValues }} style={{ position: "relative", top: 0 }} />
        </CDrawer>
    );
};

export default WorkTimeDrawer;
