import { t } from "lib/i18n";
import React from "react";
import CDrawer from "../../../components/Drawers/CDrawer";
import DataTable from "../../../components/DataTable/DataTable";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";

import { ICDrawerProps } from "components/Drawers/CDrawer";

type IProps = ICDrawerProps;

const ReceiptSplittedDrawer: FCC<IProps> = props => {
    const cm = useColumns<any>(`splitted-receipt-station`, [
        { key: "product_id", title: t("data_id"), getStyle: (v: any) => ({ marginLeft: 12 }) },
        { key: "product_name", title: t("name"), getStyle: (v: any) => ({ marginLeft: 12 }) },
        { key: "count", getStyle: (v: any) => ({ marginLeft: 13 }) },
    ]);
    return (
        <CDrawer title={t("splitted_products")} size="md" {...props}>
            <div className="">
                <div className="d-flex">
                    <div className="flex-fill border-left">
                        <div className={"py-2"}>
                            <div className="d-flex flex-column pl-2 pb-2">
                                <span className="pb-1">{props?.data?.user}</span>
                            </div>
                        </div>
                        <DataTable noShadow columns={cm.columns} dp={{ data: props.data || [] } as any} hidePages />
                    </div>
                </div>
            </div>
        </CDrawer>
    );
};
// receipt_log_admin_pass_prompt   {user} administator şifrəsindən istifadə etdi receipt_log_splitted
export default ReceiptSplittedDrawer;
