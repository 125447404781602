import { calcDiffPercentage } from "lib/chart-utils";
import TabDataDisplay from "pages/statistics/sales/TabDataDisplay";
import { iArrowDown, iArrowUp } from "assets/icons/Icons";
import CIcon from "../../../components/CIcon";
import React from "react";
import { t } from "lib/i18n";

interface IProps {
    tab: any;
    data: any;
    oldData: any;
}

const VenueSaleSectionWidget: FCC<IProps> = ({ tab, data, oldData }) => {
    const name = tab[0];
    const diff = calcDiffPercentage(data[name], oldData[name]);

    return (
        <div key={name} className=" detail-widget">
            <div className="widget-container">
                <span className="widget-price">
                    <div className="icon">{tab[2]}</div>
                    <TabDataDisplay name={name} value={data[name]} />
                </span>
                <span className="widget-title">{t(tab[1] ?? name)}</span>
            </div>
            <hr />
            <div className="d-flex justify-content-between">
                <div className="diff-data">
                    <TabDataDisplay name={name} value={oldData[name]} />
                </div>
                {data[name] !== oldData[name] ? (
                    <div className="dobule-column mt-0">
                        <div className={"percent mt-0 " + (diff < 0 ? "red" : "green")}>
                            <CIcon path={diff < 0 ? iArrowDown : iArrowUp} />
                            <span className="data">{diff.toFixed(0)}%</span>
                        </div>
                    </div>
                ) : null}
            </div>
        </div>
    );
};

export default VenueSaleSectionWidget;
