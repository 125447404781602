import { QuickLinks } from "components/Header/QuickLinks";
import { t } from "lib/i18n";

export const enum AccountPageType {
    COMPANY = "COMPANY",
    CUSTOMER = "CUSTOMER",
    SUPPLIER = "SUPPLIER",
    STAFF = "STAFF",
}

export const accountQuickLinks = (type: AccountPageType) => {
    return (
        <QuickLinks
            links={[
                {
                    link: '/finance/accounts?{"page":1,"limit":50,"filters":{"balance_type":["type",["CARD","CASH","BANK"]]},"params":{},"url":"finance/balance"}',
                    label: t("company_accounts"),
                    testId: "company-accounts",
                    active: type === AccountPageType.COMPANY,
                },
                {
                    link: '/finance/accounts/customer?{"page":1,"sort":["created_at","1"],"limit":50,"filters":{},"params":{},"url":"customer"}',
                    label: t("customer_accounts"),
                    testId: "goods",
                    active: type === AccountPageType.CUSTOMER,
                },
                {
                    link: '/finance/accounts/supplier?{"page":1,"limit":50,"filters":{"type":[["type","DISH"]]},"params":{}}',
                    label: t("supplier_accounts"),
                    testId: "supplier-accounts",
                    active: type === AccountPageType.SUPPLIER,
                },
                {
                    link: '/finance/accounts/staff?{"page":1,"limit":50,"filters":{"type":[["type","TIMER"]]},"params":{}}',
                    label: t("staff_accounts"),
                    testId: "staff-accounts",
                    active: type === AccountPageType.STAFF,
                },
            ]}
        />
    );
};
