import React, { FC } from "react";
import RadioFormGroup from "../../../components/Form/RadioFormGroup";
import { t } from "lib/i18n";
import { Radio } from "rsuite";
import { PerformanceType } from "config/constants";
import { TText } from "components/i18n/TText";
import { useFormContext } from "react-hook-form";

const labelProps = {
    style: {
        width: "250px",
    },
};

export const IncentiveProgramType: FC = () => {
    const { setValue } = useFormContext<IIncentiveProgram>();

    return (
        <RadioFormGroup
            name="performance_type"
            className="tw-mt-4 tw-break-words"
            label={t("performance_type")}
            labelProps={labelProps}
            labelDescription={t("performance_type_desc")}
        >
            <div>
                <Radio
                    name="performance_type"
                    value={PerformanceType.SELF}
                    onChange={() => setValue("performance_type", PerformanceType.SELF)}
                >
                    <TText tkey="self_sales" />
                </Radio>

                <div className="tw-pl-3 tw-text-sm tw-text-secondary">
                    <TText tkey="self_sales_desc" />
                </div>
            </div>

            <div className="tw-mt-1">
                <Radio
                    name="performance_type"
                    value={PerformanceType.OVERALL}
                    onChange={() => setValue("performance_type", PerformanceType.OVERALL)}
                >
                    <TText tkey="overall_sales" />
                </Radio>
                <div className="tw-pl-3 tw-text-sm tw-text-secondary">
                    <TText tkey="overall_sales_desc" />
                </div>
            </div>
        </RadioFormGroup>
    );
};
