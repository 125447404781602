import { CanvasPrinter } from "@cloposcom/escpos-byte";
import { generateReceipt, loadFont } from "./utils";
import { useEffect, useRef, useState } from "react";
import { IReceiptItem } from "../types";
import { useCurrency } from "hooks/useCurrency";
import { useSetting } from "hooks/useSetting";

const useGenerateReceipt = ({ items }: { items: IReceiptItem[] }) => {
    const [isLoading, setIsLoading] = useState(false);
    const canvasRef = useRef<HTMLDivElement>(null);
    const [hasFont, setHasFont] = useState(false);

    const logo = useSetting("brand_logo")?.extra?.print_image;
    const brand = useSetting("brand_name")?.value;
    const phone = useSetting("address_phone")?.value;
    const address = useSetting("address_address")?.value;

    const currency = useCurrency();

    const printer = new CanvasPrinter(
        {
            font: "Receipt-Font",
            fontSize: 25,
            canvasHeight: 500,
            canvasWidth: 552,
            addedHeight: 150,
            bottom_margin: 150,
        },
        document.getElementById("canvas") as HTMLCanvasElement,
    );
    useEffect(() => {
        (async () => {
            setIsLoading(true);

            if (!hasFont) {
                await loadFont();
                setHasFont(true);
            }

            generateReceipt({ brand, address, printer, logo, items, phone, currency }).then(canvas => {
                if (canvasRef.current) {
                    canvasRef.current.innerHTML = "";
                    canvasRef.current.append(canvas);
                }
            });

            setIsLoading(false);
        })();
    }, [items]);

    return { isLoading, canvasRef };
};

export default useGenerateReceipt;
