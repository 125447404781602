import { Badge } from "rsuite";
import Money from "./Money";
import { Colors } from "config/colors";

interface IProps {
    item?: IAccount;
    testId?: string;
}

export const BalanceWithAmount: FCC<IProps> = ({ item, testId }) => (
    <div test-id={testId}>
        {item?.name}
        <Badge
            className="float-right"
            style={{ background: "transparent", border: `1px solid ${Colors.WarmGray}`, color: Colors.DarkGray }}
            content={
                <span>
                    <Money colored value={item?.amount} />
                </span>
            }
        />
    </div>
);
