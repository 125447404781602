import CModal from "components/Drawers/CModal";
import { IConfirmModal } from "./MConfirm/MDefaultComp";
import { modalByType } from "./MConfirm/CompByType";

const ConfirmModal: FCC<IConfirmModal> = ({ content, onConfirm, isLoading, type = "default", ...props }) => {
    const Component = modalByType[type || "default"];
    return (
        <CModal {...props} backdrop="static" size="xs">
            <Component content={content} onConfirm={onConfirm} isLoading={isLoading} {...props} />
        </CModal>
    );
};

export default ConfirmModal;
