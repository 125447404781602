import { Lang, LangInfo } from "lib/i18n";

const getLangsFromBrowser = (): Lang[] => navigator.languages.map(lang => lang.split("-")[0]) as Lang[];

export const getDefaultLang = (availableLangs: LangInfo[]) => {
    const browserLangs = getLangsFromBrowser();
    const availableLangsSet = new Set(availableLangs.map(availableLang => availableLang.name));

    for (const lang of browserLangs) {
        if (availableLangsSet.has(lang)) return lang;
    }

    return Lang.EN;
};
