import Ajax from "lib/Ajax";
import { arrayToMap } from "lib/utils";
import { useEffect, useMemo } from "react";
import useSWR, { useSWRConfig } from "swr";
import _ from "lodash";

export interface IDataConfig {
    with?: string[];
    params?: Record<string, any>;
    headers?: any;
    filters?: SearchRequestFilters;
    createIdMap?: boolean;
    hasAllVenues?: boolean;
    ignoreCache?: boolean;
    refine?: (data: any) => any;
}

export interface UseDataReturnType<T> {
    data?: T;
    error?: any;
    isLoading: boolean;
    isValidating: boolean;
    revalidate: () => Promise<boolean>;
    idMap: Record<number, T extends Array<any> ? T[number] : T>;
}

// export interface UseDataReturnType<T = IData> {
//     data?: T;
// }

export function useData<T = IData | any>(
    u?: string | false,
    config: IDataConfig = {},
    hasPermission = true,
): UseDataReturnType<T> {
    const shouldCreateIdMap = config.createIdMap;
    const swrConfig = useSWRConfig();
    const url =
        hasPermission &&
        u &&
        Ajax.buildUrl({
            url: u,
            params: {
                with: config?.with,
                filters: config?.filters,
                ...config?.params,
            },
        });

    useEffect(() => {
        if (config.ignoreCache && _.isString(url)) {
            swrConfig.cache.delete(url);
        }
    }, []);
    const resp = useSWR(
        url as any,
        (url: string): Promise<IApiResponse<T>> =>
            Ajax.get({
                url,
                headers: { ...config?.headers, "x-all-venues": config?.hasAllVenues === true ? "1" : "0" },
            }),
        { revalidateOnFocus: false, revalidateOnReconnect: false },
    );
    // const data = useMemo(() => resp.data?.data)
    return useMemo(() => {
        const idMap =
            shouldCreateIdMap && Array.isArray(resp.data?.data) ? arrayToMap(resp.data?.data ?? [], "id") : {};
        const refiner = config.refine ?? ((data: any) => data);
        const reinedData = refiner(resp.data?.data);
        return {
            data: reinedData as T | undefined,
            error: resp.error,
            revalidate: async () => {
                await resp.mutate();
                return true;
            },
            isLoading: !!u && !resp.data && !resp.error,
            isValidating: resp.isValidating,
            idMap,
        };
    }, [shouldCreateIdMap, resp.data?.timestamp, u]);
}
