import { I18N, t } from "lib/i18n";
import { LoggerRemote } from "@cloposcom/libs";
import axios from "axios";
import _ from "lodash";
import { cBoolean, Constants, MobileDeviceTypeEnums } from "config/constants";
import { getLabel } from "components/Form/form-utils";
import { addDays, differenceInMinutes, format, getYear, isValid } from "date-fns";
import { config } from "config/Config";
import { store } from "redux/store";
import { Message, toaster } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { isDev } from "config/main";
import classNames from "classnames";
import { extendTailwindMerge } from "tailwind-merge";
import { Colors } from "config/colors";
import { ReactNode } from "react";
import { FIHeaderLabelProps } from "components/FormItems/FormItemsHeader";
import { getDateFNSLocale } from "./getDateFNSLocale";

const twMerge = extendTailwindMerge({ prefix: "tw-" });

export const logger = new LoggerRemote(config.brand, "g2ecbuw92n");

config.on("brandChange", brand => logger.setBrand(brand));

export const setCookie = (cookieName: string, cookieValue: string) => {
    const expires = `expires=${addDays(new Date(), 2).toUTCString()}`;
    const hostname = window.location.hostname;

    document.cookie = `${cookieName}=${cookieValue}; ${expires}; path=/; domain=.${hostname};`;
};

export const getCookie = (cookieName: string) => {
    const cookie = document.cookie
        .split(";")
        .map(c => c.trim().split("="))
        .find(cook => cook[0] === cookieName);

    return cookie ? cookie[1] : null;
};

export const sumPath: (p: string, ls: any[]) => any = (p, ls) => _.sum(_.map(ls, _.property(p)).map(Number));

export const renderProductName = (name: string, parentName: string | null | undefined) => {
    if (_.isNil(parentName)) {
        return name;
    }
    return `${parentName} (${name})`;
};

export const arrayToNameId = (ls: Primitive[]) => ls.map(str => ({ name: str.toString(), id: str.toString() }));

export function arrayToMap<T, K = T>(arr: T[], key: keyof T, fn: (i: T) => K = _.identity): Record<string, K> {
    // return arr.reduce((m, v) => ({ ...m, [_.get(v, key)]: fn(v) }), {});
    const m: Record<string, K> = {};
    for (const v of arr) {
        m[_.get(v, key) as any] = fn(v);
    }
    return m;
}

export function isNumeric(n: string) {
    return !isNaN(parseFloat(n)) && isFinite(n as any);
}

export const translateUnitName = (unit?: Partial<IUnit>, defaultVal = "--"): string => {
    if (!unit) return defaultVal;
    return unit ? (Constants.unitNames as any)[unit.symbol || unit.name || ""] || unit.name || defaultVal : defaultVal;
};
export const translateUnitNameLong = (unit?: Partial<IUnit>, defaultVal = "--"): string => {
    if (!unit) return defaultVal;
    return unit ? (Constants.unitNames as any)[unit.name || ""] || unit.name || defaultVal : defaultVal;
};

export const translateUnit = (unit: IUnit): IUnit => {
    if (!unit)
        return {
            equal: null,
            name: "",
            symbol: "",
            type: "",
            _lft: 0,
            _rgt: 0,
            id: null,
            created_at: null,
            updated_at: null,
        } as never;

    unit.name = (Constants.unitNames as any)[unit.name] || unit.name;
    unit.symbol = (Constants.unitNames as any)[unit.symbol] || unit.symbol;
    return unit;
};

export const getProductName = (product?: IProduct) => {
    const deletedLabel = product?.deleted_at ? ` - ${t("deleted_product")}` : "";
    return product
        ? `${
              product.parent_name
                  ? `${product.parent_name} (${product.name}) ${deletedLabel}`
                  : `${product.name} ${deletedLabel}`
          }`
        : "--";
};

/**
 * This function is used to prevent special cases when the number is too close to zero
 * And it uses scientific notation to convert the number to given precision
 * @param num
 * @param precision
 * @returns
 */
export const toFixed = (num: number, precision: number): string => {
    if (num < 1e-5 && num > -1e-5) {
        return (0).toFixed(precision);
    }
    return (+(Math.round(+(num + "e" + precision)) + "e" + -precision)).toFixed(precision);
};

export function roundAndClean(value: number, decimalDigit: number): number {
    return _.isNaN(value) ? 0 : parseFloat(toFixed(value, decimalDigit));
}

export function previewFile(
    file: any,
    callback: (result: string | ArrayBuffer | null) => void,
    secondaryCallback?: (result: string | ArrayBuffer | null) => void,
) {
    const reader = new FileReader();
    reader.onloadend = () => {
        callback(reader.result);
        if (secondaryCallback) secondaryCallback(reader.result);
    };
    reader.readAsDataURL(file);
}

export function isUrl(value: string) {
    // eslint-disable-next-line
    const regexp = /(ftp|http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/;
    return regexp.test(value);
}

export function multiplication(a: number | string | undefined, b: number | string | undefined, defaultValue = 0) {
    const v = Number(a ?? 0) * Number(b ?? 0);
    return _.isNaN(v) ? defaultValue : Number(v.toFixed(4));
}

export const countDecimal = (v: number) => {
    if (Math.floor(v.valueOf()) === v.valueOf()) return 0;
    return v.toString().split(".")[1].length || 0;
};

export function globalNumberInputChecker(value: any, decimalLimit = 5) {
    if (value === "0") {
        return "0";
    }

    if (typeof value === "string" && value.indexOf(".") !== -1) {
        const parts = value.split(".").slice(0, 2);

        if (parts[1] && parts[1].length > decimalLimit) {
            parts[1] = parts[1].substring(0, decimalLimit);
        }

        if (!parts[0] || parseInt(parts[0]) === 0) {
            parts[0] = "0";
        }

        if (parts[1] && parseInt(parts[1]) !== 0) {
            return parts.join(".");
        }
        return parts.join(".");
    }

    return isNumeric(value) ? Number(value) : value;
}

export function countdown(s: any) {
    const d = Math.floor(s / (3600 * 24));

    s -= d * 3600 * 24;

    const h = Math.floor(s / 3600);

    s -= h * 3600;

    const m = Math.floor(s / 60);

    return {
        d,
        h,
        m,
    };
}

export function calculateIngrPerRecipe(weight = 0, recipeOutput: number) {
    return roundAndClean(weight * recipeOutput, 4).toString();
}

export function calculateRecipePerIngr(weight = 0, recipeOutput: number) {
    return roundAndClean(weight / recipeOutput, 4).toString();
}

export const getVenuesList = (): IVenue[] => {
    try {
        return store.getState().venue.venues;
    } catch (e) {
        return [];
    }
};

export const refineVenueList = <T extends { venue: IVenue; venue_id: number }>(sts: T[]) => {
    const currentVenue = store.getState().venue.currentVenueId; //config.venueId;
    return [
        ..._.filter(sts, st => st.venue_id === currentVenue),
        ..._.filter(sts, st => st.venue_id !== currentVenue).sort((a, b) => a.venue_id - b.venue_id),
    ]?.map(d => ({ ...d, venue_name: d?.venue?.name }));
};

export async function sendGrafanaOnlineTracker() {
    if (isDev) return;
    try {
        const refreshDate = () => localStorage.setItem("@clps_last_online_tracker_sent", new Date().toISOString());
        const sendTracker = () =>
            axios.get(`https://d.clopos.com/?host=online&source=panel&brand=${config.brand}&value=1`);
        const last_sent = localStorage.getItem("@clps_last_online_tracker_sent");

        if (!_.isNil(last_sent)) {
            const diff = differenceInMinutes(new Date(), new Date(last_sent));

            if (diff >= 1) {
                refreshDate();
                return await sendTracker();
            }
        } else {
            refreshDate();
            await sendTracker();
        }
    } catch (e) {
        /* empty */
    }
}

export const getMobileOperatingSystem = () => {
    const _window: any = window;
    const userAgent = navigator.userAgent || navigator.vendor || _window.opera;

    if (/android/i.test(userAgent)) {
        return MobileDeviceTypeEnums.Android;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !_window.MSStream) {
        return MobileDeviceTypeEnums.iOS;
    }

    return MobileDeviceTypeEnums.Unknown;
};

export const boolToNumber = (val: boolean | cBoolean, val0: cBoolean, val1: cBoolean): cBoolean => {
    return typeof val === "boolean" ? (val ? val1 : val0) : val === undefined ? val0 : val;
};

// Calculate fontSize from text length
export const calculateFontSize = (text: string, baseFontSize: number, scalingFactor: number): any => {
    // Calculate the decrease in font size based on text length
    const fontSizeDecrease = Math.min(text.length * scalingFactor, baseFontSize - 10);

    // Calculate the new font size
    const fontSize = baseFontSize - fontSizeDecrease;

    // Ensure the font size is not too small
    return { fontSize: Math.max(fontSize, 10) + "px" }; // the minimum font size is 10px
};

export const showToaster = (message: string, type: TypeAttributes.Status) => {
    toaster.push(
        <Message type={type} showIcon closable>
            {message}
        </Message>,
        {
            placement: "topCenter",
        },
    );
};

export const getFormatedDate = (date: any, time = false): string => {
    const newDate = new Date(date);

    if (!isValid(newDate)) return "";

    const dateYear = getYear(newDate);
    const currentDateYear = getYear(new Date());

    const formatter = `dd ${dateYear === currentDateYear ? "MMMM" : "MMM yyyy"} ${time ? "HH:mm:ss" : ""}`;

    const locale = getDateFNSLocale(I18N.localLang);

    return format(newDate, formatter, {
        locale,
    });
};

export function cn(...inputs: Parameters<typeof classNames>) {
    return twMerge(classNames(inputs));
}

export const fixIntegrationPayloadAndStatus = (data: any) => {
    if (!data?.payload || Array.isArray(data?.payload)) {
        data.payload = {};
    }
    data.status = !!data?.status;

    return data;
};

/**
 * This function does not return properties of given object. It literally returns the "properties" field of the object if it exists.
 * If it does not exists or is not an object, it returns an empty object.
 * "properties" is field we use to store additional data in our entities in the backend.
 *
 * Reason I have created this function: https://sentry.clopos.com/share/issue/305d11db46cb4f86b1f433ad3b86bbe4/
 */
export const getDataPropertiesOf = <T extends { properties?: Record<string, any> }>(
    data: T,
): T["properties"] extends Record<string, any> ? T["properties"] : NonNullable<unknown> => {
    if (!data || !data.properties || Object.prototype.toString.call(data.properties) !== "[object Object]") {
        return {};
    }

    return data.properties;
};

export const generateRandomToken = (length: number) => {
    let result = "";
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
};

export function toFixedMinMax(number: number, minDecimalPlaces: number, maxDecimalPlaces: number): string {
    if (minDecimalPlaces > maxDecimalPlaces) {
        console.error("Minimum decimal places cannot be greater than maximum.");
        return number.toFixed(maxDecimalPlaces);
    }
    if (isNaN(number)) number = 0;

    // Convert number to string with maximum decimal places
    const numStr = number.toFixed(maxDecimalPlaces);
    let integerPart = numStr.split(".")[0]; //.replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    if (isNaN(parseInt(integerPart))) integerPart = "0";
    const decimalPart = numStr.split(".")[1] || "";
    let minDecimals = decimalPart.slice(0, minDecimalPlaces);
    const maxDecimals = decimalPart.slice(minDecimalPlaces).replace(/0+$/, "");

    if (minDecimals.length < minDecimalPlaces) {
        const neededZeros = minDecimalPlaces - decimalPart.length;
        minDecimals = `${minDecimals}${"0".repeat(neededZeros)}`;
    }
    const decimals = `${minDecimals}${maxDecimals}`;

    if (decimals.length === 0) return integerPart;
    return `${integerPart}.${decimals}`;
}

export const renderDualLabel =
    (line1: string) =>
    (line2: string, props?: FIHeaderLabelProps): ReactNode => {
        return (
            <div {...props}>
                <b style={{ color: Colors.GunmetalBlue }} className="tw-capitalize">
                    {getLabel(line1)}
                </b>
                <div className={"d-flex justify-content-between"}>
                    <div>{getLabel(line2)}</div>
                </div>
            </div>
        );
    };
