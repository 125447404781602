import Ajax from "lib/Ajax";
import { depot } from "@cloposcom/libs";

export const fetchVenues = async (token?: string): Promise<IVenue[] | undefined> => {
    try {
        const venueRes = await Ajax.get({
            url: "venues",
            headers: {
                common: {
                    "X-Auth-Token": token,
                },
            },
        });
        const data = venueRes?.data;
        const refinedVenueList = (data || [])?.map((b: any) => ({ ...b, label: b.name, value: b.id }));
        if (data) {
            depot.setItem(`venueList`, refinedVenueList);
        }
        return refinedVenueList;
    } catch (e) {
        console.log(e);
        /* empty */
    }
};
