import { TText } from "components/i18n/TText";
import parsePhoneNumber from "libphonenumber-js";
import { NavLink, useLocation } from "react-router-dom";
import { Colors } from "config/colors";

interface IProps {
    name: string;
    phones: string[];
    customer: ICustomer;
}

const CustomerHeader: FCC<IProps> = ({ name, phones, customer }) => {
    const location: any = useLocation();
    return (
        <div className="p-4 tw-bg-[#e2ecff] d-flex justify-content-between align-items-center">
            <div>
                <h5 className="mb-1 tw-text-[#475f7b]">{name ?? "--"}</h5>
                <p className="tw-text-[#475f7b]">
                    {phones.length ? parsePhoneNumber(phones[0])?.formatInternational() : "--"}
                </p>
            </div>
            {location?.pathname === "/marketing/customers" && (
                <NavLink
                    style={{
                        color: Colors.White,
                        background: Colors.VibrantSkyBlue,
                        borderRadius: "5px",
                        textDecoration: "none",
                    }}
                    className="p-2 br-5 "
                    to={{ pathname: `/finance/customer-transactions`, state: { customer } }}
                >
                    <TText tkey="transactions" />
                </NavLink>
            )}
        </div>
    );
};

export default CustomerHeader;
