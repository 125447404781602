import React from "react";

interface IProps {
    html: string;
}

const IntegrationHtmlViewer: FCC<IProps> = ({ html }) => {
    return <div dangerouslySetInnerHTML={{ __html: html }} className="info-pages"></div>;
};

export default IntegrationHtmlViewer;
