import React, { CSSProperties, ReactNode } from "react";
import { Tooltip, Whisper } from "rsuite";

import CIcon from "../CIcon";
import { iBorderedInfo } from "assets/icons/Icons";
import { Colors } from "config/colors";
import { t } from "lib/i18n";

interface InfoWhisperProps {
    infoKey: LangKey;
    children: any;
    classes?: string;
    iconStyle?: CSSProperties;
    infoItem?: ReactNode;
}

/**
 * @deprecated use WithInfoIcon component instead
 * @param param0
 * @returns
 */
const InfoWhisper = ({ infoKey, children, classes, iconStyle, infoItem }: InfoWhisperProps) => {
    return (
        <div className={`d-flex mb-2 align-items-center ${classes}`}>
            {children}
            <Whisper
                // style={{ display: "flex", alignItems: "center" }}
                placement="right"
                trigger="hover"
                speaker={<Tooltip>{infoItem ? infoItem : t(infoKey)}</Tooltip>}
            >
                <span className="pl-1">
                    <CIcon path={iBorderedInfo} size={0.7} style={{ ...iconStyle, color: Colors.CobaltBlue }} />
                </span>
                {/*<Icon style={iconStyle} size="lg" className="pl-2" icon={"info-circle"} />*/}
            </Whisper>
        </div>
    );
};

export default InfoWhisper;
