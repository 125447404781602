import { IDataProviderConfig, useDataProvider } from "components/DataTable/useDataProvider";
import _ from "lodash";
import { FC, ReactNode, useEffect, useMemo, useState } from "react";
import { useDebouncedSearch } from "hooks/useDebouncedSearch";
import SelectFormGroup, { SelectFormGroupProps } from "./SelectFormGroup";
import { usePopupContainer } from "hooks/usePopupContainer";
import { Loader } from "rsuite";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

interface IProps extends SelectFormGroupProps {
    remote: string | IDataProviderConfig;
    searchField?: string;
    isItemDisabled?: (d: any) => boolean;
    renderMenu?: (menu: ReactNode) => ReactNode;
    revalidate?: any;
    plaintext?: boolean;
    hasAllVenues?: boolean;
    refineData?: (d: any) => any;
    defaultValue?: (d: any) => void;
    searchLocal?: boolean;
    selectIfSingle?: boolean;
    comp?: FC;
}

const FixedLoader = () => (
    <Loader
        content="Loading..."
        className="tw-flex tw-justify-center tw-absolute tw-bottom-0 tw-w-full tw-p-1 tw-bg-white"
    />
);

const RemoteSelectFormGroup: FCC<IProps> = ({
    plaintext,
    compProps,
    searchField = "name",
    isItemDisabled,
    defaultValue,
    searchLocal,
    remote,
    selectIfSingle,
    ...props
}) => {
    const { setValue, getValues } = useCFormContext();
    const [localSearchVal, setLocalSearchVal] = useState("");
    const [originalData, setOriginalData] = useState<any[]>([]);
    const [pageCount, setPageCount] = useState(1);
    const [hasMoreData, setHasMoreData] = useState(false);
    const localDp = useDataProvider(_.isString(remote) ? { url: remote, hasAllVenues: props?.hasAllVenues } : remote);
    const options = useMemo(() => (localDp.isLoading ? [] : localDp.data || []), [localDp.data, localDp.isLoading]);

    const { searchInputValue, setSearchInputValue } = useDebouncedSearch(
        () => {
            if (searchLocal) {
                setLocalSearchVal(searchInputValue);
                return;
            }
            if (searchInputValue) {
                localDp.filters.add([props.fields, searchInputValue], props.fields);
            } else {
                localDp.filters.remove(props.fields);
            }
        },
        searchLocal ? 0 : 500,
    );

    const onSearch = (val: string) => setSearchInputValue(val);

    const currentValue = getValues(props.name);

    const getData = () => {
        const _data = props?.refineData?.(options) ?? options;
        if (!searchLocal) return _data;
        return _data.filter((d: any) => {
            return d[searchField].toLowerCase().includes(localSearchVal.toLowerCase());
        });
    };

    const data: any[] = getData();

    const loadMoreData = () => {
        localDp.params.add("page", pageCount + 1);
    };

    const onItemsRendered = (props: any) => {
        if (props.visibleStopIndex >= originalData.length - 1 && originalData.length < localDp.total) {
            loadMoreData();
        }
    };

    useEffect(() => {
        const page = localDp.params.getValue("page");
        setHasMoreData(localDp.total > 50);
        if (page > 1) {
            setPageCount(page);
            return setOriginalData((prev: any[]) => [...prev, ...data]);
        }
        setOriginalData(data);
    }, [data?.length, localDp.page]);

    useEffect(() => {
        if (selectIfSingle && _.isNil(currentValue) && originalData.length === 1) {
            setValue(props.name, originalData[0].id);
        }
    }, [originalData.length, selectIfSingle, currentValue]);

    useEffect(() => {
        if (_.isNil(currentValue) && !_.isNil(defaultValue)) {
            const defaultItem = defaultValue(data);
            if (defaultItem !== null) {
                setValue(props.name, defaultItem);
            }
        }
    }, [originalData.length, currentValue, defaultValue]);

    useEffect(() => {
        localDp.revalidate();
        // eslint-disable-next-line
    }, [props.revalidate]);

    const { container } = usePopupContainer();
    const renderMenuItem = (label: any) => {
        // əgər select-box içində idsini bilmədiyimiz ancaq adını bildiyimiz datanı seçmək istəsək
        // və həmin komponentin öz renderMenuItem funksiyası yoxdursa bunu istifadə edirik
        const testId = props.testId ?? props.name;
        return <span test-id={`${testId}_item`}>{label}</span>;
    };

    compProps = {
        container: () => container.current!,
        ...compProps,
        virtualized: hasMoreData,
        renderMenuItem: compProps?.renderMenuItem ?? renderMenuItem,
        listProps: {
            onItemsRendered: hasMoreData ? onItemsRendered : undefined,
        },
    };

    const disabledItemValues = useMemo(() => {
        return (options?.filter(isItemDisabled ?? _.constant(false)) || []).map(x => x[props.valueKey ?? "id"]);
    }, [isItemDisabled, options, props.valueKey]);

    const renderMenu = (menu: any) => {
        return (
            <div className="menu-items">
                {localDp.isLoading && <FixedLoader />}
                {props.renderMenu?.(menu) || menu}
            </div>
        );
    };

    const Comp = props.comp ?? SelectFormGroup;

    const onClose = () => {
        searchLocal ? setLocalSearchVal("") : localDp.filters.remove(props.fields);
    };

    return (
        <Comp
            {...props}
            data={originalData}
            plaintext={plaintext}
            testId={props.testId}
            compProps={{ ...compProps, onSearch, renderMenu, onClose, disabledItemValues }}
        />
    );
};
export default RemoteSelectFormGroup;
