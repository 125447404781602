import React, { createContext, forwardRef } from "react";

import { IDataProvider } from "../useDataProvider";

interface IProps {
    dp: IDataProvider;
    hasPadding?: boolean;
    children: React.ReactNode;
    className?: string;
}

export const FiltersContext = createContext<{
    dp: IDataProvider;
}>({} as any);

const FiltersContainer = forwardRef<HTMLDivElement, IProps>(function FiltersContainer(props, forwardRef) {
    const padding = props.hasPadding ? "" : "pl-3 pr-3";
    return (
        <div ref={forwardRef} className={`d-print-none pb-3 ${padding} d-flex flex-wrap tw-gap-1 ${props.className}`}>
            <FiltersContext.Provider value={{ dp: props.dp }}>{props.children}</FiltersContext.Provider>
        </div>
    );
});

export default FiltersContainer;
