import CCheckTreePicker, { ICheckTreePickerProps } from "../CheckTreePicker/CCheckTreePicker";
import React, { FC } from "react";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import { getLabel } from "./form-utils";
import { Colors } from "config/colors";
import CIcon from "../CIcon";
import { iSpinner } from "assets/icons/Icons";

interface ICheckTreePickerFormGroupProps extends CFormGroupProps<Omit<ICheckTreePickerProps, "data">> {
    loading?: boolean;
}

export const CCheckTreePickerFormGroup: FC<ICheckTreePickerFormGroupProps> = ({ compProps, ...props }) => {
    return (
        <CFormGroup
            {...props}
            label={props.label}
            comp={CCheckTreePicker}
            compProps={{
                data: props.data || [],
                placeholder: props.placeholder ?? getLabel(props.name),
                valueKey: "id",
                labelKey: "name",
                renderMenu: (menu: any) => {
                    if (props.loading) {
                        return (
                            <p style={{ padding: 4, color: Colors.MediumGray, textAlign: "center" }}>
                                <CIcon path={iSpinner} spin /> loading...
                            </p>
                        );
                    }

                    return menu;
                },
                defaultExpandAll: true,
                ...compProps,
            }}
        />
    );
};
