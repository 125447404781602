import React, { FC } from "react";
import { RegisterOptions, useController } from "react-hook-form";
import { cn } from "lib/utils";
import CProductSelector, { ICProductSelectorProps } from "../../Drawers/CProductSelector/CProductSelector";

interface IGridProductSelectItemProps extends Omit<ICProductSelectorProps, "value" | "errorMessage"> {
    name: string;
    rules?: RegisterOptions;
}

export const GridProductSelectItem: FC<IGridProductSelectItemProps> = ({ name, rules, ...props }) => {
    const ctrl = useController({ name, rules });

    const newProps = {
        ...props,
        errorMessage: ctrl.fieldState?.error?.message,
        value: ctrl.field.value,
        onSelect: (value: any) => {
            props.onSelect(value);
            ctrl.field.onChange(value);
        },
    };

    return (
        <div className={cn("tw-max-w-full")}>
            <CProductSelector {...newProps} />
        </div>
    );
};
