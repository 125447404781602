import { SyntheticEvent } from "react";
import { Button, List } from "rsuite";
import CIcon from "./CIcon";

export interface ISortListData {
    name: string;
    id: number;
    parent_id?: number | null;
}

interface IProps {
    title: string;
    list: ISortListData[];
    icon: string;
    onSortEnd: (payload: any) => void;
    onClick?: (data: ISortListData) => (event: SyntheticEvent) => void;
    activeId?: number;
}

const CSortableList: FCC<IProps> = props => {
    const { list, icon, onSortEnd, onClick } = props;

    // const table = useTableHeight(100);

    return (
        <div>
            <div className="tw-p-4">
                <h4 className="tw-text-lg tw-mb-0 tw-text-center tw-text-secondary/50">{props.title}</h4>
                <hr className="tw-my-0 " />
            </div>
            <List
                sortable
                size="md"
                bordered
                hover
                className="shadow"
                onSortEnd={onSortEnd}
                autoScroll
                // style={{ maxHeight: table }}
            >
                {list.map((data, index) => (
                    <List.Item key={index} index={index} className="p-0 d-flex">
                        {/*<Icon icon={icon} className="p-3" style={{ paddingRight: 10 }} />*/}
                        <CIcon path={icon} size={0.9} className="tw-m-3" />
                        <Button
                            appearance={props.activeId === data.id ? "primary" : "subtle"}
                            ripple={true}
                            className="tw-p-3 tw-w-full tw-justify-start"
                            onClick={onClick ? onClick(data) : undefined}
                        >
                            {data.name}
                        </Button>
                    </List.Item>
                ))}
            </List>
        </div>
    );
};

export default CSortableList;
