import { Col } from "rsuite";
import CSection from "../../../components/CSection";
import VenueSaleSectionWidget from "./VenueSaleSectionWidget";

interface IProps {
    tabs: any;
    data: any;
    oldData: any;
}

const VenueSaleSectionExpand: FCC<IProps> = ({ tabs, data, oldData }) => {
    return (
        <CSection>
            {tabs.map((tab: any, i: number) => {
                return (
                    <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} className="pb-3" key={i}>
                        <VenueSaleSectionWidget key={tab[0] as any} tab={tab} data={data} oldData={oldData} />
                    </Col>
                );
            })}
        </CSection>
    );
};

export default VenueSaleSectionExpand;
