import { INVENTORY_STATUS, INVENTORY_STATUS_BY_ID } from "config/constants";
import _ from "lodash";

import { t } from "lib/i18n";
import { Tag, Tooltip, Whisper } from "rsuite";
import CIcon from "./CIcon";
import { CSSProperties } from "react";

type IProps = {
    status: keyof typeof INVENTORY_STATUS_BY_ID;
    tagMode?: boolean;
    children?: any;
    style?: any;
    iconStyle?: CSSProperties;
    receipt?: boolean;
};

const OperationProcessIndicator: FCC<IProps> = props => {
    const status = props.status;
    const state: {
        name: string;
        icon: string;
        color: string;
        testId?: string;
    } = INVENTORY_STATUS_BY_ID[status];

    if (_.isNil(props.tagMode)) {
        return (
            <Whisper
                style={props.style}
                placement="top"
                trigger="hover"
                speaker={<Tooltip>{t(`process_status_${status}`)}</Tooltip>}
            >
                {props.receipt ? (
                    <span>
                        <CIcon
                            size={0.6}
                            spin={status === INVENTORY_STATUS.IN_PROCESSES}
                            style={{ color: state.color, ...props.iconStyle }}
                            path={state.icon}
                        />
                    </span>
                ) : (
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{
                            borderRadius: "50%",
                            width: 24,
                            height: 24,
                            background: INVENTORY_STATUS_BY_ID[status].background,
                        }}
                    >
                        <CIcon
                            test-id={`process-status-${state.testId}`}
                            size={0.6}
                            spin={status === INVENTORY_STATUS.IN_PROCESSES}
                            style={{ color: state.color, ...props.iconStyle }}
                            path={state.icon}
                        />
                    </div>
                )}
            </Whisper>
        );
    } else {
        return (
            <Tag style={{ ...props.style }}>
                {t(`process_status_${status}`)} {props.children}
            </Tag>
        );
    }
};

export default OperationProcessIndicator;
