import { t } from "lib/i18n";
import "./IntegrationPage.scss";

interface IProps {
    setActiveCategory: (category: LangKey) => void;
    activeCategory: LangKey;
    categoryList: LangKey[];
}

const IntegrationCategories: FCC<IProps> = ({ setActiveCategory, activeCategory, categoryList }) => {
    return (
        <div className="h-100">
            <ul className="category-list">
                {categoryList.map((category, index) => (
                    <li
                        key={index}
                        onClick={() => setActiveCategory(category)}
                        className={activeCategory === category ? "active" : ""}
                    >
                        <span>{t(category)}</span>
                    </li>
                ))}
            </ul>
        </div>
    );
};

export default IntegrationCategories;
