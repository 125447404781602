import { FC } from "react";
import { useController } from "react-hook-form";
import { Toggle } from "rsuite";

interface GridToggleProps {
    name: string;
    onChange?: (val: boolean, e: any) => void;
}

export const GridToggle: FC<GridToggleProps> = ({ name, onChange }) => {
    const ctrl = useController({ name });
    return (
        <div className="tw-my-2">
            <Toggle
                test-id={name}
                checked={!!ctrl.field.value}
                onChange={(checked, e) => {
                    ctrl.field.onChange(checked);
                    onChange?.(checked, e);
                }}
            />
        </div>
    );
};
