import { useState } from "react";
import Ajax from "../lib/Ajax";

export function useBulk<T = any>() {
    const [isBulkModeOn, setIsBulkModeOn] = useState(false);
    const [bulkMode, setBulkMode] = useState(null);
    const [bulkData, setBulkData] = useState<Array<T>>([]);

    const resetBulkState = () => {
        setIsBulkModeOn(false);
        setBulkData([]);
        setBulkMode(null);
    };

    const deleteRequest = (url: string, targets: string[]) => {
        return Ajax.delete({
            url,
            data: {
                ids: targets,
            },
        });
    };

    return {
        isBulkModeOn,
        setIsBulkModeOn,
        bulkMode,
        setBulkMode,
        bulkData,
        setBulkData,
        resetBulkState,
        deleteRequest,
    };
}
