import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { SyntheticEvent } from "react";
import { Button, Modal } from "rsuite";
import { iAlert } from "assets/icons/Icons";
import { Colors } from "config/colors";
import CIcon from "../../CIcon";
import { ICDrawerProps } from "../CDrawer";

export interface IConfirmModal extends ICDrawerProps {
    onConfirm: (id: number, event: SyntheticEvent) => void;
    content?: string;
    isLoading: boolean;
    type?: "receiptDelete" | string;
}

const MDefaultComp: FCC<IConfirmModal> = ({ content, onConfirm, isLoading, ...props }) => {
    return (
        <>
            <Modal.Body className="text-center">
                <CIcon path={iAlert} size={1.4} style={{ color: Colors.AmberYellow }} />
                <div className="mt-3">{content ?? t("are_you_sure")}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button disabled={isLoading} onClick={props.onClose} test-id="delete-cancel-btn">
                    <TText tkey="non" />
                </Button>
                <Button
                    loading={isLoading}
                    appearance="primary"
                    test-id="delete-confirm-btn"
                    onClick={e => onConfirm(props?.data || props?.dataId, e)}
                    color="red"
                >
                    <TText tkey="yes" />
                </Button>
            </Modal.Footer>
        </>
    );
};
export default MDefaultComp;
