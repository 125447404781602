import { t } from "lib/i18n";
import { getLabel } from "./Form/form-utils";
import { FC } from "react";

interface IProps {
    height?: number;
    label?: LangKey;
    title?: string;
    permissions?: string[];
}
const NoData: FC<IProps> = ({ height, title, permissions, label }) => {
    return (
        <div style={{ height: height ?? 200, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <span style={{ color: "#8e8e93" }}>
                {label
                    ? t(label, {
                          name: title || "",
                          permission: permissions?.map(p => getLabel(p)).join(", ") || "",
                      })
                    : t("no_data")}
            </span>
        </div>
    );
};

export default NoData;
