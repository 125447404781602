import { t } from "lib/i18n";
import _ from "lodash";

type ValidatorFn = (value: any, values: any, parentData?: any, meta?: any) => string | undefined;

export const ruleset = {
    combine: (fns: ValidatorFn[]) => {
        return (value: any, values: any, parentData?: any, meta?: any) => {
            for (const fn of fns) {
                const error = fn(value, values, parentData, meta);
                if (error) return error;
            }
            return undefined;
        };
    },
    combineIf: (predicate: (value: any, values: any, parentData?: any, meta?: any) => boolean, fns: ValidatorFn[]) => {
        return (value: any, values: any, parentData?: any, meta?: any) => {
            if (predicate(value, values, parentData)) {
                for (const fn of fns) {
                    const error = fn(value, values, parentData, meta);
                    if (error) return error;
                }
            }
            return undefined;
        };
    },
    gt:
        (gt: number | string, useSiblings = false, message?: string) =>
        (value: any, values: any, parentData?: any) => {
            if (_.isNil(value)) return undefined;
            const compareValue = typeof gt === "string" ? _.get(useSiblings ? parentData : values, gt) : gt;
            return !_.isNil(compareValue) && value > compareValue
                ? undefined
                : (message ?? t("validation_compare_value_must_great", { compareValue }));
        },
    gte:
        (gte: number | string, useSiblings = false, message?: string) =>
        (value: any, values: any, parentData?: any) => {
            if (_.isNil(value)) return undefined;
            const compareValue = typeof gte === "string" ? _.get(useSiblings ? parentData : values, gte) : gte;
            return !_.isNil(compareValue) && value >= compareValue
                ? undefined
                : (message ?? t("validation_compare_value_great_or_equal", { compareValue }));
        },
    lt:
        (lt: number | string, useSiblings = false, message?: string) =>
        (value: any, values: any, parentData?: any) => {
            if (_.isNil(value)) return undefined;
            const compareValue = typeof lt === "string" ? _.get(useSiblings ? parentData : values, lt) : lt;
            return !_.isNil(compareValue) && value < compareValue
                ? undefined
                : (message ?? t("validation_compare_value_must_less", { compareValue }));
        },
    lte:
        (lte: number | string, useSiblings = false, message?: string) =>
        (value: any, values: any, parentData?: any) => {
            if (_.isNil(value)) return undefined;

            const compareValue = typeof lte === "string" ? _.get(useSiblings ? parentData : values, lte) : lte;
            // console.log("LTE", lte, value, compareValue, values);
            return !_.isNil(compareValue) && value <= compareValue
                ? undefined
                : (message ?? t("validation_compare_value_less_or_equal", { compareValue }));
        },

    gtDate: (gt: number | string | Date, message?: string) => (value: Date | string, values: any) => {
        if (_.isNil(value)) return undefined;
        const compareValue = new Date(_.isNaN(new Date(gt)) ? gt : _.get(values, gt as string));
        compareValue.setHours(0);
        compareValue.setMinutes(0);
        compareValue.setSeconds(0);
        compareValue.setMilliseconds(0);
        const valueDate = new Date(value);
        valueDate.setHours(0);
        valueDate.setMinutes(0);
        valueDate.setSeconds(0);
        valueDate.setMilliseconds(0);
        return !_.isNaN(compareValue.getTime()) && new Date(valueDate).getTime() > compareValue.getTime()
            ? undefined
            : (message ?? t("validation_compare_value_must_great", { compareValue: compareValue?.toString() }));
    },

    gtTime:
        (gt: number | string | Date, message?: string, useSiblings = false) =>
        (value: Date | string, values: any, parentData?: any) => {
            if (_.isNil(value)) return undefined;

            const compareValue = new Date(
                !_.isNaN(new Date(gt).getTime()) ? gt : _.get(useSiblings ? parentData : values, gt as string),
            );
            const result = compareTime(new Date(value), compareValue);

            return !_.isNil(compareValue) && result > 0
                ? undefined
                : (message ??
                      t("validation_compare_value_must_great", {
                          compareValue: compareValue.toTimeString(),
                      }));
        },
    ltTime:
        (lt: number | string | Date, message?: string, useSiblings = false) =>
        (value: Date | string, values: any, parentData?: any) => {
            if (_.isNil(value)) return undefined;

            const compareValue = new Date(
                !_.isNaN(new Date(lt).getTime()) ? lt : _.get(useSiblings ? parentData : values, lt as string),
            );
            const result = compareTime(new Date(value), compareValue);

            return !_.isNil(compareValue) && result < 0
                ? undefined
                : (message ??
                      t("validation_compare_value_must_great", {
                          compareValue: compareValue.toTimeString(),
                      }));
        },

    gteDate:
        (gte: number | string | Date, message?: string, useSiblings = false) =>
        (value: any, values: any, parentData?: any) => {
            if (_.isNil(value)) return undefined;
            const compareValue = new Date(
                _.isNaN(new Date(gte)) ? gte : _.get(useSiblings ? parentData : value, gte as string),
            );
            compareValue.setHours(0);
            compareValue.setMinutes(0);
            compareValue.setSeconds(0);
            const valueDate = new Date(value);
            valueDate.setHours(0);
            valueDate.setMinutes(0);
            valueDate.setSeconds(0);
            return !_.isNaN(compareValue.getTime()) && new Date(valueDate).getTime() >= compareValue.getTime()
                ? undefined
                : (message ?? t("validation_compare_value_great_or_equal", { compareValue: compareValue?.toString() }));
        },
    ltDate: (lt: number | string | Date, message?: string) => (value: any, values: any) => {
        if (_.isNil(value)) return undefined;
        const compareValue = new Date(_.isNaN(new Date(lt)) ? lt : _.get(values, lt as string)).getTime();
        return !_.isNil(compareValue) && new Date(value).getTime() < compareValue
            ? undefined
            : (message ?? t("validation_compare_value_must_less", { compareValue }));
    },
    lteDate: (lte: number | string | Date, message?: string) => (value: any, values: any) => {
        if (_.isNil(value)) return undefined;
        const compareValue = new Date(_.isNaN(new Date(lte)) ? lte : _.get(values, lte as string)).getTime();
        return !_.isNil(compareValue) && new Date(value).getTime() <= compareValue
            ? undefined
            : (message ?? t("validation_compare_value_less_or_equal", { compareValue }));
    },
    lteTime: (lte: number | string | Date, message?: string) => (value: any, values: any) => {
        if (_.isNil(value)) return undefined;
        const compareValue = new Date(_.isNaN(new Date(lte)) ? lte : _.get(values, lte as string));
        const newCompareDate = new Date();
        const newValueDate = new Date();
        const _value = new Date(value);

        newCompareDate.setHours(compareValue.getHours());
        newCompareDate.setMinutes(compareValue.getMinutes());
        newCompareDate.setSeconds(compareValue.getSeconds());

        newValueDate.setHours(_value.getHours());
        newValueDate.setMinutes(_value.getMinutes());
        newValueDate.setSeconds(_value.getSeconds());

        return !_.isNil(compareValue) && new Date(newValueDate).getTime() <= new Date(newCompareDate).getTime();
    },

    required: (value: any) => {
        const val = typeof value === "string" ? value.trim() : value;
        return ![undefined, null, ""].includes(val) ? undefined : t("required");
    },
    requiredMsg:
        (messageKey: LangKey = "required") =>
        (value: any) => {
            const val = typeof value === "string" ? value.trim() : value;
            return ![undefined, null, ""].includes(val) ? undefined : t(messageKey);
        },
    requiredIf:
        (predicate: (values: any) => boolean, messageKey: LangKey = "required") =>
        (value: any, values: any) => {
            return predicate(values) ? ruleset.requiredMsg(messageKey)(value) : undefined;
        },
    number: (value: any) => {
        if (_.isNil(value)) return undefined;
        return !isNaN(value) ? undefined : t("must_be_number");
    },
    numeric: (value: any) => {
        if (_.isNil(value)) return undefined;
        return /^\d+$/.test(value) ? undefined : "Value must be numeric";
    },
    decimal2: (value?: string | number) => {
        if (_.isNil(value) || value === "") return undefined;
        return value?.toString().match(/^[0-9]+([.][0-9]{0,2})?$/) ? undefined : t("must_be_2_decimal");
    },
    decimal: (value: any) => {
        if (_.isNil(value)) return undefined;
        return !isNaN(value) && value % 1 !== 0 ? undefined : "Value must be a decimal";
    },
    integer: (value: any) => {
        if (_.isNil(value)) return undefined;
        return !isNaN(value) && value % 1 === 0 ? undefined : "Value must be an integer";
    },
    birthday: (value: any) => {
        if (_.isNil(value) || value === "") return undefined;
        return /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/.test(value)
            ? undefined
            : t("date_format");
    },
    email: (value: any) => {
        if (_.isNil(value) || value === "") return undefined;
        return /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value) ? undefined : t("validation_invalid_email_address");
    },
    url: (value: any) => {
        if (_.isNil(value) || value === "") return undefined;
        return /^(https?:\/\/)?[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+/.test(value) ? undefined : t("validation_invalid_url");
    },
    phone: (value: any) => {
        if (_.isNil(value) || value === "") return undefined;
        return /^\+?[0-9]{8,}$/.test(value) ? undefined : t("invalid_phone");
    },
    date: (value: any) => {
        if (_.isNil(value) || value === "") return undefined;
        return !isNaN(Date.parse(value)) ? undefined : "Invalid date";
    },
    minDate: (minDate: string) => (value: any) => {
        if (_.isNil(value) || value === "") return undefined;
        return Date.parse(value) >= Date.parse(minDate) ? undefined : `Date must be after ${minDate}`;
    },
    maxDate: (maxDate: string) => (value: any) => {
        if (_.isNil(value) || value === "") return undefined;
        return Date.parse(value) <= Date.parse(maxDate) ? undefined : `Date must be before ${maxDate}`;
    },
    length: (length: number) => (value: any) => {
        if (length < 0) console.warn("Length must be greater than or equal to 0");
        if (_.isNil(value)) return undefined;
        return value?.length === length ? undefined : t("validation_length_value", { length });
    },
    minLength: (minLength: number, message?: string) => (value: any) => {
        if (minLength < 0) console.warn("minLength must be greater than or equal to 0");
        return value.length >= minLength ? undefined : message || t("validation_min_length_value", { minLength });
    },
    maxLength: (maxLength: number) => (value: any) => {
        if (_.isNil(value)) return undefined;
        if (maxLength < 0) console.warn("maxLength must be greater than or equal to 0");
        return value.length <= maxLength ? undefined : t("validation_max_length_value", { maxLength });
    },
    pattern: (pattern: RegExp) => (value: any) => {
        if (_.isNil(value)) return undefined;
        return pattern.test(value) ? undefined : t("validation_invalid_format");
    },
    match: (field: string) => (value: any, values: any) => {
        if (_.isNil(value)) return undefined;
        return value === values[field] ? undefined : t("validation_value_not_match");
    },
    oneOf: (options: any[]) => (value: any) => {
        if (_.isNil(value)) return undefined;
        return options.includes(value)
            ? undefined
            : t("validation_value_must_be_options_values", { options: options.join(", ") });
    },
    notOneOf: (options: any[]) => (value: any) => {
        if (_.isNil(value)) return undefined;
        return !options.includes(value)
            ? undefined
            : t("validation_value_must_not_be_options_values", { options: options.join(", ") });
    },
    unique: (values: any[]) => (value: any) => {
        if (_.isNil(value)) return undefined;
        return values.includes(value) ? t("validation_value_must_be_unique") : undefined;
    },
    notEqual: (checkValueKey: string, message?: string) => (value: any, values: any) => {
        if (_.isNil(value)) return undefined;
        if (_.isNil(values[checkValueKey])) return undefined;
        return value.toString() === values[checkValueKey].toString() ? message : undefined;
    },

    barcodeWeight: (value: string, values: any) => {
        if (_.isNil(value)) return undefined;
        if (values.sold_by_weight && value && value.length !== 5) {
            return t("form_sold_by_weight_length_error");
        }
    },
    compareValue: (valueToCompare: any, message?: string) => (value: string, values: any) => {
        if (value !== valueToCompare) return message || t("wrong_value");
    },
};

function compareTime(date1: Date, date2: Date) {
    const hours1 = date1.getHours();
    const minutes1 = date1.getMinutes();

    const hours2 = date2.getHours();
    const minutes2 = date2.getMinutes();

    if (hours1 !== hours2) return hours1 - hours2;
    return minutes1 - minutes2;
}
