import { t } from "lib/i18n";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { getPointerLabel, prepareArrayWithRange } from "lib/chart-utils";
import CChart from "components/Chart";
import Widget from "components/Widget";

import { useCurrency } from "hooks/useCurrency";

interface IProps {
    activeTab: string;
    date: string[];
    tabs: string[];
    betaVersion: boolean;
}

const HoursChartWidget: FCC<IProps> = ({ activeTab, date, tabs, betaVersion }) => {
    const dp = useDataProvider({
        // url: "report/cash/hour",
        url: betaVersion ? "reports/hour" : "report/cash/hour",
        params: { date },
        refineList: (arr, state) =>
            prepareArrayWithRange({
                arr,
                props: tabs,
                range: 24,
            }),
    });

    const currency = useCurrency();

    return (
        <Widget title={t("left_chart_title")}>
            <CChart
                options={{
                    chart: {
                        height: 200,
                    },
                    xAxis: {
                        type: "category",
                    },
                    tooltip: {
                        useHTML: true,
                        headerFormat: "<small>{point.key}</small><table>",
                        pointFormatter() {
                            return getPointerLabel(this, "money", currency);
                        },
                        footerFormat: "</table>",
                    },
                }}
                series={[
                    {
                        type: "column",
                        data: dp.data?.map((d: any) => ({
                            y: d[activeTab],
                            x: d.hour,
                        })) as any[],
                    },
                ]}
            />
        </Widget>
    );
};

export default HoursChartWidget;
