import { t } from "lib/i18n";
import { FC } from "react";
import { Button } from "rsuite";
import { useFormItemsContext } from "./FormItems";

import CIcon from "components/CIcon";
import { iPlus } from "assets/icons/Icons";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

interface IProps {
    title?: string;
    className?: string;
    dummyData: () => any;
}

export const FormItemsAddButton: FC<IProps> = ({ title, className, dummyData }) => {
    const { append } = useFormItemsContext();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { disabled } = useCFormContext();

    return (
        <Button
            className={className}
            color="cyan"
            appearance="primary"
            test-id="add-product-button"
            onClick={() => {
                append(dummyData());
            }}
            disabled={disabled}
        >
            <CIcon path={iPlus} className="mr-2" />
            {title ?? t("add")}
        </Button>
    );
};
