import { useFormItemsContext } from "components/FormItems/FormItems";
import { useBarcodeScanner } from "hooks/useBarcodeScanner";
import { useAppSelector } from "hooks/useRedux";
import _ from "lodash";
import { dummySupplyItem } from "pages/inventory/misc/utils";
import { FC } from "react";
import { Notification, toaster, Toggle } from "rsuite";
import { t } from "lib/i18n";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

export const AddByBarcodeButton: FC = () => {
    const products = useAppSelector(state => state.data.products);
    const { append } = useFormItemsContext();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { disabled } = useCFormContext();

    const { setBarcodeMode } = useBarcodeScanner({
        onBarcodeAccept: code => {
            const pid = products.allIds.find(id => products.byId[id].barcode === code);
            if (_.isNil(pid)) {
                return toaster.push(
                    <Notification type="warning" header={t("not_found")} closable>
                        {t("not_found_message")}
                    </Notification>,
                    { duration: 4000 },
                );
            }
            const product = products.byId[pid];
            const item = dummySupplyItem({
                product_id: product.id,
                unit_id: product.unit_id,
                cost: product.cost_price,
                total_cost: product.cost_price,
            });
            append([item]);
        },
    });

    return (
        <div className="tw-flex tw-items-center">
            <Toggle
                className="ml-3"
                size="lg"
                onChange={setBarcodeMode}
                checkedChildren={t("barcode")}
                unCheckedChildren={t("barcode")}
                disabled={disabled}
            />
        </div>
    );
};
