import React from "react";
import { Colors } from "./colors";

// const NEW_URL_SUBDOMAINS = [
//     "client",
//     "client-alpha",
//     "client-beta",
//     "client-rc",
//     "panel",
//     "panel-alpha",
//     "panel-beta",
//     "panel-rc",
// ];

const pattern = /^(https:\/\/)?pr-\d+\.client(-\w+)?\.clopos\.com(\/.*)?$/;
export const IS_PR_PREVIEW = pattern.test(window.location.hostname);

export const SUBDOMAIN = window.location.hostname.split(".")[IS_PR_PREVIEW ? 1 : 0];
export const SUBDOMAIN_BASE = SUBDOMAIN.split("-")[0];
export const SUBDOMAIN_STAGE = SUBDOMAIN.split("-")[1];
// eslint-disable-next-line @typescript-eslint/naming-convention

export const EMPTY_DATA = React.createElement("div", { style: { color: Colors.LightSilverGray } }, "─");

export const STAGE = import.meta.env.VITE_STAGE ?? "dev";
export const BUILD = import.meta.env.VITE_BUILD;

//there stage from url (project stage) , stage for brand in config
export const isAlpha = SUBDOMAIN_STAGE === "alpha";
export const isDev = window.location.hostname.endsWith("clopos.test");
export const isBeta = SUBDOMAIN_STAGE === "beta";
export const isRC = SUBDOMAIN_STAGE === "rc";
export const isProd = SUBDOMAIN === "client" && !SUBDOMAIN_STAGE;
export const isAlphaOrDev = isAlpha || isDev;
export const isAlphaOrDevOrBeta = isAlpha || isDev || isBeta;

export const TLD = window.location.host.split(".").pop();
export const PROTOCOL = window.location.protocol;
export const QR_MENU_SHORT_URL = `https://clps.cc`;
export const ASSETS_BASE_URL = "";
export const REMOTE_ASSETS_BASE_URL = "https://cdn.clopos.com/_clopos/client/";
export const getAssetUrl = (img: string) => `${ASSETS_BASE_URL}${img}`;
export const getRemoteAssetUrl = (img: string) => `${REMOTE_ASSETS_BASE_URL}${img}`;

export const MIN_WIDTH = 900;
export const MIN_WIDTH_MODIFIER = 1050;

export const VERSION = `${import.meta.env.VITE_VERSION}-${STAGE.slice(0, 1)}${STAGE !== "dev" ? BUILD : "--"}`;
export const BUILDVERSION = `${STAGE.slice(0, 1)}.${STAGE !== "dev" ? BUILD : "--"}`;
export const VERSION_FOR_SENTRY = `${import.meta.env.VITE_VERSION}.${STAGE.slice(0, 1)}.${
    STAGE !== "dev" ? BUILD : ""
}`;

export const allPackages = [
    "restaurant_start",
    "restaurant_standard",
    "shop_start",
    "shop_standard",
    "restaurant_emenu_package",
    "restaurant_pro",
    "shop_pro",
];

export const onlyQRMenu = ["restaurant_emenu_package"];

export const allButQRMenu = [
    "restaurant_start",
    "restaurant_standard",
    "shop_start",
    "shop_standard",
    "restaurant_pro",
    "shop_pro",
];
