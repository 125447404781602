import ExpandedRowView from "components/DataTable/ExpandedRowView";
import { IDataProvider } from "components/DataTable/useDataProvider";
import { getLabel } from "components/Form/form-utils";
import _ from "lodash";
import { ReactNode, useEffect, useRef, useState } from "react";
import { Form, IconButton, Pagination } from "rsuite";
import { Cell, Column, ColumnProps, HeaderCell, Table, TableProps } from "rsuite-table";
import { renderCell } from "./cell-renderers";
import SortBtn from "./SortBtn";
import BulkCheckBox from "../mini/BulkCheckBox";
import { useContextMenu } from "react-contexify";
import ContextMenu from "./ColumnsManager/ContextMenu";
import "../../assets/scss/table-reset.scss";
import CIcon from "../CIcon";
import { iMinus, iPlus } from "assets/icons/Icons";
import { cn } from "lib/utils";
import { useCurrency } from "hooks/useCurrency";

export interface IDataTableProps<T = any> {
    dp: IDataProvider;
    columns: ITableColumnProps<T>[];
    tableProps?: Omit<TableProps<any, any>, "virtualized"> & { "test-id"?: string };
    hidePages?: boolean;
    noShadow?: boolean;
    renderExpanded?: (d: any) => ReactNode;
    isBulkMode?: boolean;
    bulkData?: any[];
    bulkException?: any;
    isBulkSelected?: boolean;
    disabledProductIds?: number[];
    setBulkData?: (item: any) => void;
    setAllBulkData?: (select: boolean) => void;
    dataPath?: string;
    filter?: (d: T) => boolean;
    innerRef?: any;
    resizeRowHeight?: (d: any) => boolean;
    rowPadding?: string;
    virtualized?: boolean;
}

const extraColProps = [
    "contentWrapClass",
    // "sortable", //add if you want to local sort in column, another gets from dp.sorts
    "hide",
    "noPrint",
    "sortField",
    "render",
    "summary",
    "color",
    "hideData",
    "staticContent",
    "numberRoundingLength",
    "onClick",
    "Comp",
    "suffix",
    "prefix",
    "getData",
    "help",
    "getStyle",
    "optionsConf",
    "testId",
    "multiline",
    "reverse",
    "showSeconds",
    "isFixed",
    "customTitle",
];

const defaultColumnProps: ColumnProps = {
    flexGrow: 1,
    align: "left",
};

// const ROW_HEIGHT = 46;

function DataTable<T = IData>({ dp, dataPath, columns, ...props }: IDataTableProps<T>): any {
    const [expandedIds, setExpandedIds] = useState<number[]>([242]);
    const [isBulkSelected, setBulkSelected] = useState(false);
    const [tableHeight, setTableHeight] = useState<number>(0);
    const [heights, setHeights] = useState<Record<number, number>>({});
    const bulkException = props?.bulkException;
    const currency = useCurrency();
    const MENU_ID = useRef(new Date().toISOString());
    const { show } = useContextMenu({
        id: MENU_ID.current,
    });
    const ref: any = useRef(null);

    /**
     * Old version: const isPaginationHidden = !dp.page || props.hidePages || dp.pageSize >= dp.total;
     *
     * There was a bug in the old version where the pagination was hidden when the page size was greater than or equal to the total number of items.
     * Which makes changing the page size to a lower value impossible.
     * This was fixed by removing the dp.pageSize >= dp.total condition.
     */
    const isPaginationHidden = !dp.page || props.hidePages;

    const onOpenRow = (d: IProduct) => () => {
        setHeights(hs => (expandedIds.includes(d.id) ? _.omit(heights, d.id) : _.assign({}, hs, { [d.id]: 0 })));
        setExpandedIds(ids => (ids.includes(d.id) ? _.without(ids, d.id) : _.concat(ids, d.id)));
    };

    let data = !_.isNil(dataPath) ? _.get(dp.data, dataPath) : dp.data;

    if (props.filter) {
        data = data?.filter(props.filter) ?? [];
    }

    const next = (current: number): SortingDirection => {
        return (((current + 2) % 3) - 1) as SortingDirection;
    };

    const onSortColumn = (dataKey: string, direction: string) => {
        if (dp.sorts?.includes(dataKey)) {
            dp.sort.add(dataKey, next(parseInt(direction)));
            // dp.revalidate();
        }
    };

    const tableProps: TableProps<any, any> = {
        data,
        autoHeight: !props.virtualized,
        loading: dp.isLoading,
        rowKey: "id",
        className: cn("tw-relative", { "no-shadow rounded-lg": !props.noShadow }),
        affixHeader: true,
        virtualized: props.virtualized,

        ...props.tableProps,
    };

    if (props.resizeRowHeight && tableProps.rowHeight) {
        tableProps.rowHeight = props.resizeRowHeight(data) ? tableProps.rowHeight : 46;
    }

    if (props.renderExpanded) {
        tableProps.renderRowExpanded = (d: any) => (
            <ExpandedRowView id={d.id} height={heights[d.id] ?? 0} setHeight={setHeights}>
                {props.renderExpanded?.(d)}
            </ExpandedRowView>
        );
        tableProps.expandedRowKeys = expandedIds;
    }

    const recalculateTableHeight = () => {
        if (!props.virtualized || tableProps.height) return;

        const tableBody = document.querySelector(".rs-table-header-row-wrapper");
        const pagination = document.querySelector(".rs-pagination-group");

        if (tableBody) {
            const calculatedHeight =
                window.innerHeight -
                tableBody.getBoundingClientRect().top -
                (pagination ? pagination.getBoundingClientRect().height + 14 : 0);

            setTableHeight(calculatedHeight);
        }
    };

    useEffect(() => {
        if (props.bulkData?.length === 0) {
            setBulkSelected(false);
        }
    }, [props.bulkData]);

    useEffect(() => {
        window.addEventListener("resize", recalculateTableHeight);

        return () => window.removeEventListener("resize", recalculateTableHeight);
    }, []);

    useEffect(() => {
        recalculateTableHeight();
    }, [dp.data]);

    useEffect(() => {
        const containerElement = document.querySelector("section.overflow-auto.rs-container");
        const tableHeaderElement = document?.querySelector("div.rs-table-affix-header");
        const pinHeader = () => {
            const topHeight = ref?.current?.getBoundingClientRect().top;
            const method = topHeight <= 0 ? "add" : "remove";

            tableHeaderElement?.classList?.[method]("fixed");
        };
        containerElement?.addEventListener("scroll", pinHeader);

        return () => containerElement?.removeEventListener("scroll", pinHeader);
    }, []);

    const selectAll = () => {
        if (props.setAllBulkData) {
            props.setAllBulkData(!isBulkSelected);
            setBulkSelected(!isBulkSelected);
        }
    };

    return (
        <div
            ref={ref}
            className="tw-overflow-hidden print:tw-overflow-visible"
            test-id="data-table"
            data-is-loading={tableProps.loading}
        >
            <Table height={props.virtualized ? tableHeight : undefined} {...tableProps}>
                {props.renderExpanded && (
                    <Column width={70} align="center">
                        <HeaderCell children={null} />
                        <Cell>
                            {(d: any) => (
                                <IconButton
                                    onClick={onOpenRow(d)}
                                    size="xs"
                                    appearance="subtle"
                                    icon={<CIcon path={expandedIds.includes(d.id) ? iMinus : iPlus} />}
                                />
                            )}
                        </Cell>
                    </Column>
                )}

                {props.isBulkMode && (
                    <Column width={70} align="center">
                        <HeaderCell>
                            <div>
                                <BulkCheckBox
                                    testIdSuffix="-all"
                                    checked={!_.isNil(props.isBulkSelected) ? props.isBulkSelected : isBulkSelected}
                                    onChange={() => selectAll()}
                                />
                            </div>
                        </HeaderCell>
                        <Cell>
                            {(d: any, rowIndex) => {
                                if (
                                    bulkException &&
                                    bulkException?.length === 2 &&
                                    d[bulkException[0]] === bulkException[1]
                                ) {
                                    return;
                                }

                                return (
                                    <BulkCheckBox
                                        disabled={props.disabledProductIds?.includes(d.id)}
                                        checked={props.bulkData?.includes(d.id)}
                                        testIdSuffix={`-${rowIndex}`}
                                        onChange={() => props?.setBulkData?.(d)}
                                    />
                                );
                            }}
                        </Cell>
                    </Column>
                )}

                {columns.map((col, index) => {
                    const colProps = {
                        ...defaultColumnProps,
                        className: col.contentWrapClass || "",
                        ..._.omit(col, extraColProps),
                    };
                    const className = col.noPrint ? "d-print-none" : "expandable-row-class";
                    const dataKey = col.sortField || (col.key as string);
                    let sort = (dp.sort?.getSort(dataKey) as string) ?? "0";
                    const isSortableByServer = dp.sorts?.includes(dataKey);
                    const isLocalSortable = (col.sortField || col.key) && col.sortable;
                    const isSortable = isSortableByServer || isLocalSortable;
                    if (isLocalSortable && tableProps.sortColumn === col.sortField) {
                        sort = tableProps.sortType === "asc" ? "1" : "-1";
                    }

                    return (
                        <Column {...(colProps as any)} key={index}>
                            <HeaderCell className={className} style={{ height: col.summary ? 100 : 40 }}>
                                <div
                                    className={isSortable ? "rs-header-cell-items sort-hover" : "rs-header-cell-items"}
                                    onClick={() => onSortColumn(dataKey, sort || "0")}
                                    style={{ width: "100%", height: "100%", padding: 10 }}
                                >
                                    <div>
                                        {col.customTitle ? col.customTitle : (col.title ?? getLabel(col.key as string))}
                                        {isSortable && <SortBtn sort={sort} />}

                                        {col.help && (
                                            <Form.HelpText tooltip style={{ margin: "1px 0 0 5px" }}>
                                                {col.help}
                                            </Form.HelpText>
                                        )}
                                        {col.currency && <> {currency}</>}
                                    </div>
                                    {col.summary && (
                                        <div
                                            style={{ color: "#1794ff", fontSize: 16 }}
                                            test-id={`${col.key as string}-summary`}
                                        >
                                            {col.summary()}
                                        </div>
                                    )}
                                </div>
                            </HeaderCell>

                            <Cell dataKey={(col.sortField || col.key) as string} className={className}>
                                {(d: any) => {
                                    const hasActions: ITableColumnProps | undefined = columns.find(
                                        (c: any) => c.key === "options",
                                    );
                                    if (hasActions) {
                                        return (
                                            <div
                                                test-id={col.key}
                                                style={
                                                    col.key === "options"
                                                        ? {
                                                              padding: "0 10px 0px",
                                                              height: "100%",
                                                          }
                                                        : tableProps?.isTree
                                                          ? {
                                                                display: "flex",
                                                                alignItems: "center",
                                                                width: "100%",
                                                            }
                                                          : {
                                                                height: "100%",
                                                                position: "relative",
                                                                padding: props.rowPadding
                                                                    ? props.rowPadding
                                                                    : "13px 10px 0px",
                                                            }
                                                }
                                                onContextMenu={(e: any) => {
                                                    if (!d.deleted_at) {
                                                        const isDrawer = e.target?.closest(".rs-drawer-dialog");
                                                        //check left SideBar opened or not
                                                        const isNavBarOpen =
                                                            !!document.getElementsByClassName("rs-sidenav-collapse-in")
                                                                .length;

                                                        const addToX = isDrawer ? 0 : isNavBarOpen ? 184 : 56;
                                                        show({
                                                            event: e,
                                                            id: MENU_ID.current,
                                                            props: { d },
                                                            position: {
                                                                x:
                                                                    e.clientX -
                                                                    ref.current?.getBoundingClientRect().left +
                                                                    addToX,
                                                                y: e.clientY,
                                                            },
                                                        });
                                                    } else {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                    }
                                                }}
                                            >
                                                {renderCell(col)(d)}
                                            </div>
                                        );
                                    } else {
                                        return (
                                            <div
                                                test-id={col.key}
                                                style={
                                                    tableProps?.isTree
                                                        ? {
                                                              display: "contents",
                                                          }
                                                        : {
                                                              height: "100%",
                                                              position: "relative",
                                                              padding: props.rowPadding
                                                                  ? props.rowPadding
                                                                  : "13px 10px 0px",
                                                          }
                                                }
                                            >
                                                {renderCell(col)(d)}
                                            </div>
                                        );
                                    }
                                }}
                            </Cell>
                        </Column>
                    );
                })}
            </Table>

            <ContextMenu id={MENU_ID.current} columns={columns} />

            {!isPaginationHidden && (
                <Pagination
                    prev
                    next
                    first
                    last
                    maxButtons={5}
                    ellipsis
                    boundaryLinks
                    className="d-print-none mb-3 px-2"
                    layout={["total", "-", "limit", "|", "pager", "skip"]}
                    limitOptions={[10, 20, 50, 100, 500]}
                    activePage={dp.page}
                    limit={dp.pageSize}
                    total={dp.total}
                    onChangePage={dp.setPage}
                    onChangeLimit={dp.setPageSize}
                />
            )}
        </div>
    );
}

export default DataTable;
