import { useFormItemsContext } from "components/FormItems/FormItems";
import _ from "lodash";
import { FC } from "react";
import { GridItemPlain } from "./GridItemPlain";
import { GridItemAlignment } from "./grid-item-utils";
import { cn, toFixedMinMax } from "lib/utils";
import { useFormContext } from "react-hook-form";

type GridItemTotalProps = {
    postfix?: string;
    align?: GridItemAlignment;
    className?: string;
    decimals?: number | [number, number];
    testId?: string;
} & (
    | {
          name: string;
      }
    | { iteratee: (item: any) => number }
);

export const GridItemTotal: FC<GridItemTotalProps> = ({
    postfix,
    align = "right",
    className,
    decimals = [2, 4],
    testId,
    ...props
}) => {
    const { itemsFieldName } = useFormItemsContext();
    const { watch } = useFormContext();
    const _decimals: [number, number] = Array.isArray(decimals) ? decimals : [decimals, decimals];
    const fields = (watch(itemsFieldName) as any[]) ?? [];
    const getter = "iteratee" in props ? props.iteratee : (item: any) => _.get(item, props.name);

    const total = toFixedMinMax(
        fields.reduce((acc, curr) => acc + (Number(getter(curr)) || 0), 0),
        ..._decimals,
    );
    return (
        <GridItemPlain
            testId={testId}
            value={total}
            postfix={postfix}
            align={align}
            className={cn("tw-bg-blue-50", className)}
        />
    );
};
