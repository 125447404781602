import _ from "lodash";
import { Colors } from "config/colors";
import React from "react";
import { GenericDataState } from "redux/features/model/utils";

export const renderCategoryMenuItem = (
    label: any,
    item: any,
    searchValue: boolean,
    _testId: string,
    categories: GenericDataState<ICategory | IFinanceCategory>,
) => {
    if (!searchValue) {
        return (
            <span test-id={`${_testId}_item`}>
                {_.times(item.depth).map((x, i) => (
                    <span key={i} className="px-2">
                        --
                    </span>
                ))}
                {label}
            </span>
        );
    } else {
        return (
            <span style={{ display: "grid" }} test-id={`${_testId}_item`}>
                <span style={{ fontSize: 14, fontWeight: "500" }}>{item.name}</span>
                {item.parent_id && (
                    <span style={{ fontSize: 12, color: Colors.LightSilverGray }}>
                        {renderCategoryParentNames(categories.byId[item.parent_id], categories)}{" "}
                    </span>
                )}
            </span>
        );
    }
};

export const renderCategoryParentNames = (
    item: ICategory | IFinanceCategory,
    categories: GenericDataState<ICategory | IFinanceCategory>,
): string => {
    if (item.parent_id) {
        return renderCategoryParentNames(categories.byId[item.parent_id], categories) + " / " + item.name;
    } else {
        return item.name;
    }
};
