import { FormProvider, useFormContext } from "react-hook-form";
import React, { createContext, FC, useContext } from "react";
import { ICFormProps } from "components/Form/CForm/CForm";

interface ICFormContext extends Omit<ICFormProps, "form" | "onSave" | "children"> {}

const CFormContext = createContext<ICFormContext>({} as ICFormContext);

export const useCFormContext = () => {
    const cContext = useContext(CFormContext);
    const ctx = useFormContext();

    return {
        ...ctx,
        ...cContext,
    };
};

interface ICFormProviderProps extends ICFormProps {}

export const CFormProvider: FC<ICFormProviderProps> = props => {
    return (
        <CFormContext.Provider value={props}>
            <FormProvider {...props.form}>{props.children}</FormProvider>
        </CFormContext.Provider>
    );
};
