import React from "react";
import { getLabel } from "./form-utils";
import CIcon from "../CIcon";
import { iCheckBoxChecked, iClose } from "assets/icons/Icons";

interface IProps {
    name: string;
    form?: any;
    value?: any;
    label?: string;
}

export const PlainCheckbox: FCC<IProps> = ({ label, name, form, value }) => {
    const newValue = value || form?.getValues(name);
    return (
        <div className="rs-form-group mb-2" role="group">
            <div className="rs-form-control-wrapper">
                <div className="y-1">
                    {newValue ? (
                        <CIcon path={iCheckBoxChecked} className="text-success" />
                    ) : (
                        // <Icon icon="check" className="text-success" />
                        <CIcon path={iClose} className="text-success" />

                        // <Icon icon="close" className="text-danger" />
                    )}
                    <span className="pl-2">{label || getLabel(name)}</span>
                </div>
            </div>
        </div>
    );
};
