import { ChangeEvent } from "react";
import { Checkbox, ColumnProps, HeaderProps, Table } from "rsuite";
import { getLabel } from "./form-utils";
import { menuTitleByType, USER_ABILITIES } from "config/constants";
import _ from "lodash";
import { t } from "lib/i18n";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";
import { ValueType } from "rsuite/esm/Radio/Radio";
import { IGrouppedAbility } from "./NewCheckboxTableTerminal";

const { Cell } = Table;
const Column = Table.Column as FCC<ColumnProps>;
const HeaderCell = Table.HeaderCell as FCC<HeaderProps>;

interface IProps {
    onChange: (value: ValueType | undefined, checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
    ability: string[];
    abilities: Array<IAbility>;
    readonly: boolean;
}

const NewCheckboxTableFrom: FCC<IProps> = ({ onChange, abilities, ability, readonly }) => {
    const brandType: string = useSetting("brand_type")?.value;

    const _abilities = USER_ABILITIES.filter(ua => abilities.some(ab => ab.name === ua.name));

    const isRestaurant = brandType === BrandType.RESTAURANT;
    const roles = isRestaurant
        ? []
        : [
              "stock_manage_preparation",
              "modificator_manage",
              "station_manage",
              "menu_order",
              "report_station",
              "report_table",
              "setting_table",
          ];

    const renderTable = (groupedAbilities: IGrouppedAbility[]) => {
        const group = groupedAbilities[0]?.group;

        return (
            <div
                key={group}
                className="tw-pb-3 tw-border-0 tw-border-x tw-border-solid tw-border-basic-metal-grey-500 last:tw-rounded-b-xl last:tw-border-b"
            >
                <Table
                    virtualized
                    autoHeight
                    width={602}
                    data={groupedAbilities.filter(r => !roles.includes(r.all))}
                    headerHeight={44}
                    rowHeight={40}
                    className="ability-check-form tw-w-full"
                >
                    <Column flexGrow={3}>
                        <HeaderCell>
                            <h5 className="text-subtitle2-medium tw-py-[8.5px] !tw-m-0">
                                {menuTitleByType.menuTitleVariation[group as string]?.[brandType] ??
                                    getLabel(group as string)}
                            </h5>
                        </HeaderCell>
                        <Cell>
                            {rowData => (
                                <span className="d-flex h-100 align-items-center text-body2-regular tw-text-text-600">
                                    {getLabel(rowData?.name)}
                                </span>
                            )}
                        </Cell>
                    </Column>

                    <Column flexGrow={1} align={"right"}>
                        <HeaderCell className="text-caption2-regular">{t("all")}</HeaderCell>
                        <Cell>
                            {rowData => (
                                <div className="d-flex h-100 align-items-center justify-content-end">
                                    <Checkbox
                                        value={rowData?.all}
                                        test-id={rowData?.all}
                                        onChange={onChange}
                                        readOnly={readonly}
                                    />
                                </div>
                            )}
                        </Cell>
                    </Column>

                    <Column flexGrow={1} align={"right"}>
                        <HeaderCell className="text-caption2-regular">{t("view")}</HeaderCell>
                        <Cell dataKey="view">
                            {rowData => (
                                <div className="d-flex h-100 align-items-center justify-content-end">
                                    <Checkbox
                                        test-id={rowData?.view}
                                        value={rowData?.view}
                                        disabled={!rowData?.view}
                                        onChange={onChange}
                                        readOnly={readonly}
                                    />
                                </div>
                            )}
                        </Cell>
                    </Column>

                    <Column flexGrow={1} align={"right"}>
                        <HeaderCell className="text-caption2-regular">{t("create")}</HeaderCell>
                        <Cell dataKey="create">
                            {rowData => (
                                <div className="d-flex h-100 align-items-center justify-content-end">
                                    <Checkbox
                                        test-id={rowData?.create}
                                        value={rowData?.create}
                                        disabled={!rowData?.create}
                                        onChange={onChange}
                                        readOnly={readonly}
                                    />
                                </div>
                            )}
                        </Cell>
                    </Column>

                    <Column flexGrow={1} align={"right"}>
                        <HeaderCell className="text-caption2-regular">{t("edit")}</HeaderCell>
                        <Cell dataKey="edit">
                            {rowData => (
                                <div className="d-flex h-100 align-items-center justify-content-end">
                                    <Checkbox
                                        test-id={rowData?.edit}
                                        value={rowData?.edit}
                                        disabled={!rowData?.edit}
                                        onChange={onChange}
                                        readOnly={readonly}
                                    />
                                </div>
                            )}
                        </Cell>
                    </Column>

                    <Column flexGrow={1} align={"right"}>
                        <HeaderCell className="text-caption2-regular">{t("delete")}</HeaderCell>
                        <Cell dataKey="delete">
                            {rowData => (
                                <div className="d-flex h-100 align-items-center justify-content-end tw-pr-4">
                                    <Checkbox
                                        test-id={rowData?.delete}
                                        value={rowData?.delete}
                                        disabled={!rowData?.delete}
                                        onChange={onChange}
                                        readOnly={readonly}
                                    />
                                </div>
                            )}
                        </Cell>
                    </Column>
                </Table>
            </div>
        );
    };

    return _.map(
        _.groupBy(
            _abilities?.filter(a => (isRestaurant ? true : a.group !== "emenu")),
            "group",
        ),
    ).map(renderTable);
};

export default NewCheckboxTableFrom;
