import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import _ from "lodash";
import React, { ReactNode, useState } from "react";
import { getProductName, translateUnitName } from "lib/utils";
import { moneyFormatter } from "lib/moneyFormatter";
import { useData } from "hooks/useData";
import Money from "../../components/mini/Money";
import { useDrawer } from "components/Drawers/useDrawer";
import StockDrawer from "./misc/StockDrawer";
import { exportFile } from "lib/export-table";
import CellBtn from "../../components/DataTable/CellButton";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import CheckListFilter from "components/DataTable/Filters/CheckListFilter";
import { CategoryTypeEnum, ProductType } from "config/constants";
import { getLabel } from "components/Form/form-utils";
import StorageWithStatus from "components/mini/StorageWithStatus";
import CreateButton from "../../components/DataTable/CreateButton";
import { productNameAppend } from "lib/data-utils";
import MenuCategoryTreeFilter from "../../components/DataTable/Filters/CategoryTreeFilter/MenuCategoryTreeFilter";
import CIcon from "components/CIcon";
import { iClose, iDish, iDoubleCheckBox, iGoods, iIngredient, iPreparation, iTrash } from "assets/icons/Icons";
import SelectFilter from "../../components/DataTable/Filters/SelectFilter";
import { SelectStorageFilter } from "components/adhoc-filters/SelectStorageFilter";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";
import { useBulk } from "hooks/useBulk";
import { Button, Message, toaster } from "rsuite";
import { TText } from "components/i18n/TText";
import ConfirmModal from "../../components/Drawers/ConfirmModal";
import { useLoading } from "hooks/useLoading";
import ErrorModal from "../../components/ErrorModal";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import NoAccessModal from "../../components/NoAccessModal";

export const productTypeIcon: Record<string, string> = {
    DISH: iDish,
    GOODS: iGoods,
    INGREDIENT: iIngredient,
    PREPARATION: iPreparation,
    MODIFICATION: iGoods,
};

const StockPage: FCC = () => {
    const activeStorages = useSetting("brand_storage")?.value;

    const dp = useDataProvider({
        url: "stock/product",
        useUrl: true,
        with: ["unit", "category", "total_quantity", "stocks.storage"],
        sort: ["updated_at", "-1"],
    });

    const summaryParams = _.clone(dp.params.all);
    const summaryFilters = _.clone(dp.filters.all);
    if (summaryParams?.storage_id) {
        if (dp.params.getFilter("storage_id")) {
            summaryParams.storage_id = ["storage_id", dp.params.getFilter("storage_id")];
        }
    }

    // Data provider and summary requests uses same filters but with different fields
    if (summaryFilters?.id) {
        const productId = _.clone(summaryFilters?.id?.[1]);
        delete summaryFilters?.id;
        summaryFilters.product_id = ["product_id", productId];
    } else {
        delete summaryFilters?.id;
        delete summaryFilters?.product_id;
    }

    const filters = _.values(summaryParams).concat(_.values(summaryFilters));

    const summary = useData<any>("stock/total", {
        filters: filters,
    });
    const stockDrawer = useDrawer({ backdrop: false, overflowAuto: true });
    const [op, setOp] = useState<IProduct>();
    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;

    const cm = useColumns<any>("stock/product", [
        {
            key: "id",
            align: "left",
            width: 70,
            flexGrow: undefined,
            title: t("data_id"),
        },
        {
            key: "name",
            align: "left",
            flexGrow: 4,
            render: d => (
                <CellBtn
                    action={() => {
                        setOp(d);
                        stockDrawer.setDataId(d.id);
                    }}
                    label={getProductName(d)}
                />
            ),
        },
        {
            key: "total_quantity",
            title: t("qty"),

            minWidth: 120,
        },
        {
            key: "unit",
            width: 70,
            title: t("unit"),
            render: d => translateUnitName(d.unit),
        },
        {
            key: "total_cost",
            title: t("total_cost"),

            minWidth: 140,
            render: d => moneyFormatter(d.total_cost),
            summary: () => {
                const totalCost = summary.data && summary.data[0] ? summary.data[0].total_cost : 0;
                return <Money value={totalCost} />;
            },
        },
        {
            key: "average_cost",
            title: t("average"),

            minWidth: 140,
            render: d => moneyFormatter(d.average_cost),
        },
        {
            key: "type",
            render: d => {
                return (
                    <div className="d-flex align-items-center">
                        <CIcon path={productTypeIcon?.[d.type]} />
                        <div className="ml-2">{_.capitalize(t(d.type?.toLowerCase()))}</div>
                    </div>
                );
            },

            minWidth: 140,
        },
        { key: "category.name", title: t("category"), minWidth: 140 },
        {
            key: "stocks.0.storage.name",
            title: t("storage"),
            flexGrow: 2,
            render: d => {
                const storages: any[] = _.uniqBy(
                    d.stocks?.map((stock: IStockItem) => stock.storage),
                    "id",
                );
                return storages.length > 1 ? (
                    storages.map((storage: IStorage) => storage?.name).join(" - ")
                ) : (
                    <StorageWithStatus storage={_.head(storages)} activeStorages={activeStorages} />
                );
            },
        },
        optionsColumn({
            dp,
            editable: d => true,
            preventDelete: d => {
                const qty = d.stocks?.reduce((total: number, v: any) => total + v.quantity, 0);
                const roundedQty = Math.round(qty * 1e5) / 1e5;
                return roundedQty !== 0;
            },
            delete: "stock/productGroup/:stock_id",
            canDelete: "stock_manage_delete",
            canEdit: "stock_manage_edit",
            canDuplicate: "stock_manage_create",
        }),
    ]);

    const auth = useAuth();
    const [permissionLabel, setPermissionLabel] = useState("");
    const bulkDeleteModal = useDrawer();
    const errorModal = useDrawer();
    const noAccessModal = useDrawer();
    const bulkDeleteResultModal = useDrawer();
    const {
        bulkData,
        setBulkData,
        deleteRequest,
        resetBulkState,
        bulkMode,
        setBulkMode,
        isBulkModeOn,
        setIsBulkModeOn,
    } = useBulk<number>();
    const { isLoading, withLoading } = useLoading();

    const blkPicker = (m: any) => {
        setBulkMode(m);
        setIsBulkModeOn(true);
    };

    const blkHandleCheck = ({ id }: { id: number }) => {
        setBulkData(prev => (bulkData.includes(id) ? prev.filter(f => f !== id) : [...prev, id]));
    };

    const blkHandleSelectAll = (selected: boolean) => {
        setBulkData(prev => (selected ? dp.data.map(item => item.id) : []));
    };

    const blkApplyHandler = () => {
        const permission = "stock_manage";
        if (!auth.hasAbility([permission])) {
            setPermissionLabel(permission);
            noAccessModal.setDataId(1);
            return;
        }
        bulkDeleteModal.setDataId(1);
    };

    /* Bulk delete confirmation action. */
    const onConfirmDelete = () => {
        withLoading(() => {
            return deleteRequest("/stock/bulk/delete", bulkData as unknown as string[]);
        })
            .then(() => {
                toaster.push(
                    <Message type="success" showIcon>
                        <TText tkey="success" />
                    </Message>,
                );
                resetBulkState();
                bulkDeleteModal.onClose();
                dp.revalidate();
            })
            .catch(e => {
                bulkDeleteModal.onClose();
                if (e.models?.length) {
                    errorModal.setData(e);
                }
            });
    };

    return (
        <div className="h-100">
            {useTitle(t("stocks"), dp.total)}
            <HeaderRight cm={cm} dp={dp} export={() => exportFile(dp, cm.columns, t("stocks"))} reload />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    {/*<SearchFilter fields="barcode" placeholder={t("barcode")} />*/}
                    {!bulkMode && (
                        <Button
                            className="multiple-select-button"
                            test-id="multiple-select-button"
                            ripple={false}
                            onClick={() => blkPicker("true")}
                        >
                            <CIcon path={iDoubleCheckBox} />
                            <span>
                                <TText tkey="multiple_select" />
                            </span>
                        </Button>
                    )}
                    {bulkMode && (
                        <div className="bulk-mode">
                            <span className="badge">{bulkData.length}</span>
                            <div
                                onClick={() => resetBulkState()}
                                test-id="multiple-select-cancel-button"
                                className="d-flex"
                            >
                                <CIcon path={iClose} />
                                <span className="cancel-button">
                                    <TText tkey="cancel" />
                                </span>
                            </div>
                        </div>
                    )}
                    <RemoteCheckListFilter
                        fields="id"
                        selectedDetails={{
                            url: "product",
                        }}
                        searchField="name"
                        remote={{
                            url: "product",
                            sort: ["updated_at", "-1"],
                            limit: 100,
                            refineList: (products?: IProduct[]) =>
                                _.sortBy(
                                    products
                                        ?.map(i => productNameAppend(i))
                                        ?.filter(f => !(f.type === ProductType.GOODS && f.has_modifications)),
                                    item => item.type !== ProductType.INGREDIENT,
                                ) ?? [],
                        }}
                        width={120}
                        maxWidth={450}
                        pickerProps={{
                            menuStyle: {
                                width: 500,
                            },
                            placeholder: t("product"),
                            groupBy: "type",
                            renderMenuGroup: (groupTitle: ReactNode) => {
                                return getLabel((groupTitle || "") as any);
                            },
                        }}
                    />
                    {isRestaurant && (
                        <CheckListFilter
                            pickerProps={{ searchable: false }}
                            options={[
                                { label: getLabel(ProductType.GOODS), value: ProductType.GOODS },
                                { label: getLabel(ProductType.PREPARATION), value: ProductType.PREPARATION },
                                { label: getLabel(ProductType.DISH), value: ProductType.DISH },
                                { label: getLabel(ProductType.INGREDIENT), value: ProductType.INGREDIENT },
                            ]}
                            fields="type"
                        />
                    )}
                    <MenuCategoryTreeFilter
                        categoryTypes={[CategoryTypeEnum.INGREDIENT, CategoryTypeEnum.PRODUCT]}
                        width={120}
                        maxWidth={450}
                    />
                    <MenuCategoryTreeFilter
                        fieldName={"accounting_category_id"}
                        placeholder={t("accounting_category")}
                        categoryTypes={[CategoryTypeEnum.ACCOUNTING]}
                    />
                    <SelectFilter
                        fields="qty_stock_filters"
                        pickerProps={{ searchable: false, placeholder: t("quantity_filter") }}
                        options={[
                            { label: t("low_stock"), value: 2 },
                            { label: t("negative_stock"), value: 1 },
                            { label: t("zero_stock"), value: 3 },
                            { label: t("positive_stock"), value: 4 },
                        ]}
                        width={150}
                    />

                    <SelectStorageFilter multi />

                    {bulkData.length > 0 && (
                        <div className="bulk-acton-buttons">
                            <Button ripple={false} className="delete" onClick={blkApplyHandler}>
                                <CIcon path={iTrash} />
                                <TText tkey="delete" />
                            </Button>
                        </div>
                    )}
                </div>

                <CreateButton
                    to={"/inventory/supplies/create"}
                    label={t("create_supply")}
                    canCreate={"stock_manage_supply_create"}
                />
            </FiltersContainer>
            <StockDrawer
                {...stockDrawer}
                op={op}
                title={`${t("stock")} #${stockDrawer.dataId} : ${
                    op?.parent_name ? `${op.parent_name} (${op?.name})` : op?.name
                }`}
                activeStorages={activeStorages}
            />
            <DataTable
                dp={dp}
                isBulkMode={isBulkModeOn}
                bulkData={bulkData}
                setBulkData={blkHandleCheck}
                setAllBulkData={blkHandleSelectAll}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                    affixHeader: true,
                }}
                virtualized={true}
            />
            <ErrorModal modal={errorModal} />
            <ConfirmModal {...bulkDeleteModal} isLoading={isLoading} onConfirm={onConfirmDelete} />
            <NoAccessModal modal={noAccessModal} permission={["stock_manage", ...permissionLabel]} />
        </div>
    );
};

export default StockPage;
