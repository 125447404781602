import { t } from "lib/i18n";
import { dummyPreparationItem } from "pages/menu/preparations/utils";
import RecipeItem, { RecipeItemField } from "pages/products/dishes/edit/RecipeItem";
import React, { ComponentProps, useEffect } from "react";
import { Message } from "rsuite";
import SelectFormGroup from "components/Form/SelectFormGroup";
import _ from "lodash";
import { ProductType } from "config/constants";
import CProductsDrawer from "components/Drawers/CProductSelector/CProductsDrawer";
import { FormItems } from "components/FormItems/FormItems";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { FIHeaderProps } from "components/FormItems/FormItemsHeader";
import { MassInsertButton } from "components/FormItems/MassInsertButton";
import { FormItemsAddButton } from "components/FormItems/FormItemsAddButton";
import { GridItemTotal } from "components/NewForm/Grid/GridItemTotal";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { useCurrency } from "hooks/useCurrency";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import { useAppDispatch } from "hooks/useRedux";
import { setDisabledProductIds, setShowedTypes } from "redux/features/productsSelector/productsSelector";

const columns: FIHeaderProps["columns"] = [
    ["ingredient"],
    ["type"],
    ["gross", "right"],
    ["gross_weight_kg", "right"],
    ["cost", "right"],
    [""],
];

const massInsertProps: ComponentProps<typeof MassInsertButton> = {
    idField: "id",
    refineItem: (item: any) => ({
        id: item.id,
        price: 0,
        cost_price: item.cost_price,
        unit: item?.unit,
        unit_id: item?.unit_id,
        name: item.name,
        gross: 0,
        type: item.type,
        unit_weight: item.unit_weight,
    }),
};

interface IRecipeTab {
    id?: number;
}

const RecipeTab: FCC<IRecipeTab> = ({ id }) => {
    const dispatch = useAppDispatch();
    const form = useCFormContext();
    const currency = useCurrency();
    const inventory_behavior = form.watch("inventory_behavior");
    const items = form.watch("contents");
    const itemsIdString = items?.map((i: any) => i?.id)?.join(",");

    useEffect(() => {
        dispatch(
            setShowedTypes([ProductType.GOODS, ProductType.DISH, ProductType.INGREDIENT, ProductType.PREPARATION]),
        );
    }, []);

    useEffect(() => {
        if (id) {
            dispatch(setDisabledProductIds([Number(id)]));
        }
    }, [id]);

    useEffect(() => {
        if (_.isNil(inventory_behavior)) {
            form.setValue("inventory_behavior", 0);
        }
        // eslint-disable-next-line
    }, [inventory_behavior]);

    useEffect(() => {
        const ids = items?.map((item: any) => item.id) ?? [];

        if (id) {
            ids.push(Number(id));
        }

        dispatch(setDisabledProductIds(ids));
    }, [itemsIdString]);

    return (
        <div className="tw-p-4">
            {form.getValues("sold_by_weight") ? (
                <div className="p-2">
                    <Message
                        showIcon
                        type="info"
                        children={t("sold_unit", { unit_name: t("kg_short") })}
                        style={{ maxWidth: "50%" }}
                    />
                </div>
            ) : null}
            <FormItems itemsFieldName="contents">
                <FormItemsList<RecipeItemField>
                    columns={columns}
                    children={_props => {
                        return <RecipeItem {..._props} />;
                    }}
                    footer={
                        <div className="tw-grid tw-grid-cols-subgrid tw-col-span-6">
                            <div className="tw-flex tw-col-span-2 tw-gap-2">
                                <MassInsertButton {...massInsertProps} />
                                <FormItemsAddButton dummyData={dummyPreparationItem} />
                            </div>
                            <GridItemPlain
                                align="right"
                                className="tw-bg-transparent tw-border-none"
                                value={t("total") + ":"}
                            />
                            <GridItemTotal
                                iteratee={(item: RecipeItemField) => (item.gross || 0) * (item.unit_weight || 0)}
                                postfix={t("kg_short")}
                                decimals={[0, 3]}
                            />
                            <GridItemTotal
                                testId="total_cost_price"
                                iteratee={(item: RecipeItemField) => item.cost_price * item.gross}
                                postfix={currency}
                            />
                        </div>
                    }
                />
            </FormItems>
            <SelectFormGroup
                className="tw-mt-10"
                name={"inventory_behavior"}
                label={t("write_off_method")}
                data={[
                    { id: 0, name: t("write_off_ingredients") },
                    { id: 1, name: t("write_off_ready_item") },
                ]}
            />
            <CProductsDrawer />
        </div>
    );
};

export default RecipeTab;
