import { IDbReceiptProduct } from "@cloposcom/receipt-utils/src/receipt-calculation/interfaces";
import { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { FC, useState } from "react";
import { Button, Modal, SelectPicker } from "rsuite";
import { useData } from "hooks/useData";
import { updateReceiptItem } from "redux/features/webCashier";
import { changeModalState } from "redux/features/webCashierAdditionals";
import { useAppDispatch } from "hooks/useRedux";

interface IProps extends ICDrawerProps {
    detail: {
        product: IDbReceiptProduct | undefined;
        maxPrice: number;
        index: number;
        open: boolean;
        type: string;
    };
}

const ProductSellerModal: FC<IProps> = ({ detail, ...props }) => {
    const { index, product } = detail;
    const sellerList = useData("user", {
        with: ["roles.abilities"],
        filters: [
            ["abilities", "field_seller"],
            ["status", 1],
        ],
    });

    const [sellerId, setSerllerId] = useState<any>(product?.seller_id ?? null);

    const dispatch = useAppDispatch();

    const closeModal = () => {
        dispatch(changeModalState({ ...detail, open: false, title: "" }));
    };

    return (
        <>
            <Modal.Body>
                <SelectPicker
                    data={sellerList.data}
                    labelKey={"username"}
                    valueKey={"id"}
                    name="seller"
                    test-id="product_seller"
                    value={sellerId}
                    style={{ width: "100%" }}
                    renderMenuItem={label => <span test-id="product_seller_item">{label}</span>}
                    onClean={() => setSerllerId(null)}
                    onSelect={(value, item, event) => setSerllerId(value)}
                    placeholder={t("select", { name: t("seller") })}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button
                    appearance="primary"
                    color="green"
                    test-id="save-seller"
                    onClick={() => {
                        const _product = { ...product! };
                        _product.seller_id = sellerId;
                        dispatch(updateReceiptItem(_product!));
                        closeModal();
                    }}
                >
                    <TText tkey="save" />
                </Button>
            </Modal.Footer>
        </>
    );
};

export default ProductSellerModal;
