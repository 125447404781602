import { t } from "lib/i18n";
import { bool, number, object, string } from "yup";

import { getVenuesList } from "lib/utils";
import { store } from "redux/store";
import { SalesChannelTypes } from "config/constants";

export const fillDummyVenuesList = () => {
    const venuesList = getVenuesList();
    const venueArr: any = {};
    for (const x in venuesList) {
        venueArr[venuesList[x].id] = 0;
    }
    return venueArr;
};

const dummyVenueList = () => {
    const venue: number = store.getState().venue.currentVenueId;
    return { [venue]: 1 };
};

export const dummyPaymentMethod = (): Partial<IPaymentMethod> => {
    return {
        id: null,
        name: "",
        balance_id: null,
        split: 0,
        customer_required: 0,
        status: dummyVenueList(),
    };
};

export const PaymentMethodModel = () =>
    object().shape({
        id: number().nullable().notRequired(),
        name: string().required(t("required")),
        balance_id: string()
            .required(t("required"))
            .typeError(t("required"))
            .when("id", (id: any, schema: any) => {
                return id === 3 || id === 4 ? schema.notRequired() : schema.required(t("required"));
            }),
        split: bool(),
        customer_required: bool(),
    });

export const dummySaleType = (): Partial<ISaleType> => {
    return {
        name: "",
        status: dummyVenueList(),
        payment_method_id: null,
        service_charge_rate: null,
        channel: SalesChannelTypes.DELIVERY,
    };
};

export const SaleTypeModel = () =>
    object().shape({
        name: string().required(t("required")),
        channel: string().required(t("required")),
        payment_method_id: string().nullable().notRequired(),
        service_charge_rate: string().nullable(),
    });
