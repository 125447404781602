import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import Ajax from "lib/Ajax";
import { useEffect, useState } from "react";
import { Button, Input } from "rsuite";
import { iCheckBoxChecked } from "assets/icons/Icons";
import CIcon from "../CIcon";

interface IProps extends ICDrawerProps {
    completeDrawerCRUD: () => void;
}

const ReasonsEditorDrawer: FCC<IProps> = props => {
    const url = "reasons";

    const data = props?.data;
    const [isUpdating, setUpdating] = useState(false);

    const [rType, setRType] = useState(null);
    const [rName, setRName] = useState("");

    useEffect(() => {
        if (data?.type && data?.action === "create") {
            setRType(data?.type);
            setRName("");
        }
        if (data?.action === "edit") {
            setRType(data?.payload?.type);
            setRName(data?.payload?.name);
        }
        // eslint-disable-next-line
    }, [data]);

    const onClearState = () => {
        setRType(null);
        setRName("");
    };

    const onCreate = async () => {
        setUpdating(true);
        const res = await Ajax.post({
            url: url,
            data: {
                type: rType,
                name: rName,
            },
        }).catch(() => setUpdating(false));
        if (res?.success) {
            props?.completeDrawerCRUD();
            onClearState();
        }
        setUpdating(false);
    };

    const onEdit = async () => {
        setUpdating(true);
        const res = await Ajax.put({
            url: url + "/" + data?.payload?.id,
            data: {
                type: rType,
                name: rName,
            },
        }).catch(() => setUpdating(false));
        if (res?.success) {
            props?.completeDrawerCRUD();
            onClearState();
        }
        setUpdating(false);
    };

    return (
        <CDrawer size="xs" {...props} title={t("reason")}>
            <div style={{ padding: 10 }}>
                {/* {!data?.type && (
                <SelectFormGroup
                    name="type"
                    data={
                        [
                            { name: t("waste"), id: reasonTypes.write-off },
                            { name: t("delete"), id: reasonTypes.delete },
                        ]
                    }
                    errorPlacement="rightEnd"
                />
            )} */}

                <Input value={rName} onChange={setRName} style={{ marginBottom: 10 }} />

                <Button loading={isUpdating} onClick={data?.action === "create" ? onCreate : onEdit} color="green">
                    <CIcon path={iCheckBoxChecked} className="mr-2" />
                    {data?.action === "create" ? t("create") : t("edit")}
                </Button>
            </div>
        </CDrawer>
    );
};

export default ReasonsEditorDrawer;
