import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { useEffect, useState } from "react";
import TabsView from "../../../components/TabsView";
import StorageHistory from "./StorageHistory";

interface IProps extends ICDrawerProps {
    op?: IStorage;
    title: string;
}

const StorageDrawer: FCC<IProps> = props => {
    const [tabs, setTabs] = useState([] as any);

    useEffect(() => {
        setTabs([{ label: t("history"), Component: StorageHistory, props: { dataId: props.dataId } }]);
        // eslint-disable-next-line
    }, [props.op]);

    return (
        <CDrawer {...props} size="md">
            <div className="">
                <TabsView tabs={tabs} />
            </div>
        </CDrawer>
    );
};

export default StorageDrawer;
