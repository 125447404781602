import { t } from "lib/i18n";
import { bool, object, string } from "yup";

export const StationModel = () =>
    object().shape({
        name: string().required(t("required")),
        printable: bool(),
        meta: object().shape({
            station_reminder: bool(),
        }),
    });

export const dummyMenuStation = (): Partial<IStation> => ({
    name: "",
    printable: 0,
    station_reminder: 0,
});
