import { t } from "lib/i18n";

import classNames from "classnames";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import CheckBoxFilter from "components/DataTable/Filters/CheckBoxFilter";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import _ from "lodash";

import CreateButton from "../../../components/DataTable/CreateButton";
import Money from "../../../components/mini/Money";
import CreateSupplierTransactionModal from "./CreateSupplierTransactionModal";
import SupplierDrawer from "./SupplierDrawer";
import { exportFile } from "lib/export-table";
import { NavLink, useLocation } from "react-router-dom";
import { QuickLinks } from "components/Header/QuickLinks";
import { RowDataType } from "rsuite-table/src/@types/common";
import { useData } from "hooks/useData";

const SuppliersPage: FCC = () => {
    const url = "supplier";
    const dp = useDataProvider({
        url,
        useUrl: true,
        with: ["balance"],
    });

    const total = useData("suppliers/total", {
        filters: _.values(dp.filters.all),
    });

    const modalPayment = useDrawer();
    const sd = useDrawer();
    const loc: any = useLocation();

    const cm = useColumns<any>("supplier", [
        {
            key: "id",
            title: "ID",
            align: "left",
            testId: "supplier-id",
            flexGrow: 0.25,
        },
        {
            key: "name",
            align: "left",
            // onClick: (d: ISupplier) => sd.setData(d),
            render: (e: ISupplier) => (
                // http://client.clopos.test:8084/alpha/1/finance/supplier-transactions?{%22page%22:1,%22limit%22:50,%22filters%22:{%22supplier_id%22:[%22supplier_id%22,%22=%22,[27]]}}
                <NavLink to={{ pathname: `/finance/supplier-transactions`, state: { supplier: e } }}>{e.name}</NavLink>
            ),
            flexGrow: 2,
        },
        { key: "balance.id", title: t("balance") + " №", hide: true, align: "center" },
        // {
        //     key: "debtCredit",
        //     title: t("balance"),
        //     type: "money",
        //     hideData: (d: ISupplier) => d.balance?.amount! < 0,
        //     getData: (d: ISupplier) => Math.abs(d.balance?.amount!),
        //     help: t("you_owe_to_supplier"),
        //     // render: (d: ISupplier) =>
        //     summary: () => (
        //         <Money
        //             colored
        //             value={_.sumBy(
        //                 _.filter(dp.data, (d: any) => d.balance?.amount > 0),
        //                 "balance.amount",
        //             )}
        //         />
        //     ),
        //
        //     sortField: "balance.amount",
        // },

        // {
        //     key: "debt",
        //     title: t("debt"),
        //     type: "money",
        //     hideData: (d: ISupplier) => d.balance?.amount! > 0,
        //     getData: (d: ISupplier) => Math.abs(d.balance?.amount!),
        //     help: t("supplier_owes_to_you"),
        //     summary: () => (
        //         <Money
        //             colored
        //             value={_.sumBy(
        //                 _.filter(dp.data, (d: any) => d.balance?.amount < 0),
        //                 "balance.amount",
        //             )}
        //         />
        //     ),
        //
        //     sortField: "balance.amount",
        // },
        {
            key: "balance.amount",
            title: t("balance"),
            type: "money",
            hideData: (d: ISupplier) => (d.balance?.amount ?? 0) < 0,
            getData: (d: ISupplier) => Math.abs(d.balance?.amount ?? 0),
            help: t("you_owe_to_supplier"),
            render: d => <Money colored value={d.balance?.amount} />,
            summary: () => <Money colored value={total?.data?.total ?? 0} />,

            sortField: "balance.amount",
        },
        { key: "description" },
        { key: "phone" },
        { key: "tin" },
        optionsColumn({
            dp,
            edit: "/inventory/suppliers/edit/:id",
            delete: "supplier/:id",
            preventDelete: (d: any) => d.balance?.amount !== 0,
            canEdit: "stock_manage_supplier",
            canDelete: "stock_manage_supplier",
        }),
    ]);
    return (
        <div className="h-100">
            {useTitle(t("suppliers"), dp.total)}
            <HeaderRight dp={dp} cm={cm} export={() => exportFile(dp, cm.columns, t("suppliers"))} reload print />
            <QuickLinks
                links={[
                    {
                        link: "/inventory/suppliers",
                        label: t("suppliers"),
                        active: loc.pathname === "/inventory/suppliers",
                    },
                ]}
            />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="name" />
                    {/* <SelectFilter fields="amount" options={supplierTypeOptions()} comparison_value={0} pickerProps={{placeholder: t("type")}}/> */}
                    <CheckBoxFilter fields="trash" useParams title={t("show_deleted_values")} value="2" />
                </div>
                <CreateButton to="/inventory/suppliers/create" canCreate={"stock_manage_supplier"} />
                <CreateButton
                    testId="make-payment"
                    to={() => {
                        modalPayment.setDataId(0);
                        modalPayment.setData({ length: 999 });
                    }}
                    label={t("make_payment")}
                    canCreate={"finance_transaction_create"}
                />
            </FiltersContainer>

            <CreateSupplierTransactionModal {...modalPayment} revalidate={dp.revalidate} />

            <SupplierDrawer {...sd} />

            <DataTable
                key={url}
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    rowClassName: (d: RowDataType) =>
                        classNames({
                            "deleted-row": d && d.deleted_at,
                        }),
                    headerHeight: 50,
                }}
            />
        </div>
    );
};
export default SuppliersPage;

// const supplierTypeOptions = () => _.map(supplierTypes, (label, value: number) => ({ value, label: getLabel(label) }));
