import { createContext, FC, ReactNode, useContext } from "react";
import { useFieldArray } from "react-hook-form";

interface FormItemsProps {
    itemsFieldName: string;
    children: ReactNode;
}

interface IFormItemsContext<T = any> {
    itemsFieldName: string;
    append: (value: any) => void;
    remove: (index?: number | number[]) => void;
    fields: T[];
}

const FormItemsContext = createContext({} as IFormItemsContext);

export const useFormItemsContext = <T = any,>() => {
    const context = useContext<IFormItemsContext<T>>(FormItemsContext);

    if (context === undefined) {
        throw new Error("useFormItemsContext must be used within a FormItemsProvider");
    }

    return context;
};

export const FormItems: FC<FormItemsProps> = ({ itemsFieldName, children }) => {
    // const { setValue, watch, getValues } = useFormContext();
    const { append, remove, fields } = useFieldArray<any>({ name: itemsFieldName, keyName: "xxid" as any });

    // TODO: Move itemsFieldName to a separate context

    // const items = watch(itemsFieldName, fields) as any[];
    // const values = getValues(itemsFieldName) ?? [];
    return (
        <FormItemsContext.Provider value={{ append, remove, fields, itemsFieldName }}>
            {children}
        </FormItemsContext.Provider>
    );
};
