import { ICModalProps } from "components/Drawers/CModal";
import TextFormGroup from "components/Form/TextFormGroup";
import { t } from "lib/i18n";
import { useCForm } from "hooks/useCForm";
import { useEffect } from "react";
import CFormModal from "../../../components/Drawers/CFormModal";
import TextAreaFormGroup from "../../../components/Form/TextAreaFormGroup";
import JsonView from "../../../components/JsonView";
import { dummySupplier, SupplierModel } from "./SupplierModel";

interface IProps extends ICModalProps {
    onSelectSupplier: (d: number) => void;
}

const CreateSupplierModal: FCC<IProps> = ({ onSelectSupplier, ...props }) => {
    const form = useCForm({
        url: "supplier",
        model: SupplierModel(),
        redirectUrl: false,
        isModal: true,
        dummyData: dummySupplier,
    });

    useEffect(() => {
        form.form.reset(dummySupplier(), { keepDirty: false });
    }, [props.isOpen]);

    return (
        <CFormModal
            {...props}
            receivedValue={(d: any, name: any) => {
                onSelectSupplier(d.id);
            }}
            form={form}
            formProps={{ layout: "horizontal" }}
            title={t("create_by_name", { name: t("supplier") })}
            size="sm"
            testId="create-supplier-modal"
        >
            <TextFormGroup name="name" errorPlacement="rightEnd" focus={true} />
            <TextFormGroup name="phone" errorPlacement="rightEnd" />
            <TextFormGroup name="tin" errorPlacement="rightEnd" />
            <TextAreaFormGroup
                name="description"
                className="pb-3"
                compProps={{
                    "test-id": "supplier-description",
                }}
            />
            <JsonView />
        </CFormModal>
    );
};

export default CreateSupplierModal;
