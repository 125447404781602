import { useAuth } from "providers/AuthProvider/AuthProvider";
import _ from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";
import Ajax from "../lib/Ajax";

const ReceiptHistoryContext = createContext<{ data: IHistory[]; loading: boolean }>({ data: [], loading: false });

export const useReceiptHistory = () => useContext(ReceiptHistoryContext);

interface ReceiptHistoryProviderProps {
    url: string;
    with?: string[];
    sort?: string[];
    filters?: SearchRequestFiltersMap;
    stop?: boolean;
    refineList?: (list: IProduct[]) => IProduct[];
    params?: any;
    noReq?: boolean;
    localSort?: any;
}

export const ReceiptHistoryProvider: FCC<ReceiptHistoryProviderProps> = props => {
    const [history, setHistory] = useState<IHistory[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const auth = useAuth();

    useEffect(() => {
        if (!auth.isLoggedIn && !_.isNil(props.noReq) && !props.noReq) {
            setIsLoading(false);
            return;
        }

        if (props.stop) {
            setHistory([]);
            setIsLoading(false);
            return;
        }

        const url = Ajax.buildUrl({
            url: props.url,
            params: {
                with: props.with,
                sort: props.sort,
                filters: _.values(props.filters),
                limit: 999,
                page: 1,
                ...props.params,
            },
        });

        Ajax.get({ url }).then(res => {
            let list = res.data;
            list = props.refineList?.(list) ?? list;
            if (props.localSort) {
                list = _.orderBy(list, ...props.localSort);
            }
            setHistory(list);
            setIsLoading(false);
        });
    }, [auth.isLoggedIn, props.filters, props.stop]);

    return (
        <ReceiptHistoryContext.Provider value={{ data: history, loading: isLoading }}>
            {props.children}
        </ReceiptHistoryContext.Provider>
    );
};
