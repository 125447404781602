import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import { getParsedQuery, useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import { ReactNode, useMemo, useState } from "react";
import _ from "lodash";
import { getProductName } from "lib/utils";
import { OperationType, OperationTypes, ProductType, Trash } from "config/constants";
import { exportFile } from "lib/export-table";
import { productNameAppend } from "lib/data-utils";
import CheckListFilter from "components/DataTable/Filters/CheckListFilter";
import { useLocation } from "react-router-dom";
import { getLabel } from "components/Form/form-utils";
import RemoteSelectFilter from "components/DataTable/Filters/RemoteSelectFilter";
import Money from "components/mini/Money";
import CellBtn from "components/DataTable/CellButton";
import ReceiptDrawer from "components/Drawers/ReceiptDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import StockOperationsDrawer from "./misc/StockOperationsDrawer";
import { getDataById } from "lib/api-utils";
import ReturnedSuppliesProductsDrawer from "./misc/ReturnedSuppliesProductsDrawer";
import { Message } from "rsuite";
import { SelectStorageFilter } from "components/adhoc-filters/SelectStorageFilter";

const defaultDrawerCols = ["product.name", "quantity", "cost", "total_cost"];

const OperationReference = ({
    data,
    receiptDrawer,
    suppliesDrawer,
    suppliesReturnDrawer,
    setDrawerTitle,
    setDrawerTabsTitle,
    setShowDeductedTab,
    setDrawerCols,
}: {
    data: IStockOperation;
    receiptDrawer: any;
    suppliesDrawer: any;
    suppliesReturnDrawer: any;
    setDrawerTitle: any;
    setDrawerTabsTitle: any;
    setShowDeductedTab: any;
    setDrawerCols: any;
}) => {
    const type = data?.operation?.type;
    const operationName = _.isNil(type) ? t("sale") : t(OperationTypes[type]);
    const operationID = data?.operation?.id;
    const receiptID = data?.receipt_id;

    const referenceAction = async () => {
        if (receiptID) {
            receiptDrawer.setDataId(receiptID);
        } else if (operationID) {
            setDrawerCols(defaultDrawerCols);
            const operationDataRes: any = await getDataById("/operation", operationID, [
                "operation_items.unit",
                "storage",
                "from_storage",
                "operation_items.product",
                "stock_operations.product.unit",
                "stock_operations.unit",
                "supplier",
            ]);
            if (type === OperationType.IN) {
                setDrawerTitle(t("total_supply"));
                setShowDeductedTab(false);
                suppliesDrawer.setData(operationDataRes);
            } else if (type === OperationType.TRANSFER) {
                setDrawerCols(["product.name", "quantity"]);
                setDrawerTitle(
                    `${t("transfer")} #${operationDataRes.id} (${operationDataRes?.from_storage?.name || "---"} -> ${
                        operationDataRes?.storage?.name || "--"
                    })`,
                );
                setDrawerTabsTitle(t("supplies"));
                setShowDeductedTab(false);
                suppliesDrawer.setData(operationDataRes);
            } else if (type === OperationType.WASTE) {
                setDrawerTitle(t("write_off"));
                setShowDeductedTab(false);
                suppliesDrawer.setData(operationDataRes);
            } else if (type === OperationType.MANUFACTURE) {
                setDrawerTitle(t("stock_preparations"));
                setShowDeductedTab(true);
                suppliesDrawer.setData(operationDataRes);
            } else if (type === OperationType.INVENTORY_CHECK) {
                //TODO: Check there drawer
                setDrawerTitle(t("inventory_check"));
                suppliesDrawer.setData(operationDataRes);
            } else if (type === OperationType.SUPPLY_RETURN) {
                suppliesReturnDrawer.setDataId(data?.operation?.returns_id);
            }
        }
    };

    return (
        <span>
            {<CellBtn action={referenceAction} label={`#${data?.operation?.returns_id || receiptID || operationID}`} />}{" "}
            {operationName}
        </span>
    );
};

const ProductMovementsPage: FCC = () => {
    const [drawerTitle, setDrawerTitle] = useState(t("total_supply"));
    const [drawerTabsTitle, setDrawerTabsTitle] = useState(t("products"));
    const [drawerCols, setDrawerCols] = useState(defaultDrawerCols);
    const [shouldShowDeductedTab, setShouldShowDeductedTab] = useState(false);
    const receiptDrawerData = useDrawer({ backdrop: false, overflowAuto: true });
    const suppliesDrawer = useDrawer({ backdrop: false, overflowAuto: true });
    const suppliesReturnDrawer = useDrawer({ backdrop: false, overflowAuto: true });
    const loc = useLocation();
    const [query] = useMemo<any>(() => getParsedQuery(loc.search), [loc.search]);
    const hasProductFilter = !_.isNil(query?.filters?.product_id) && !_.isNil(query?.params?.storage_id);

    const dp = useDataProvider({
        url: "stock-operations/follow/products",
        with: ["product.unit", "operation", "storage", "product"],
        limit: 50,
        sort: ["operated_at", "-1"],
        useUrl: true,
        useDates: true,
        stop: !hasProductFilter,
    });

    const cm = useColumns<IStockOperation>("stock-operations/follow/products", [
        {
            key: "id",
            title: t("data_id"),
            flexGrow: 2,
            render: d => d?.receipt_id || d?.operation?.id,
        },
        {
            key: "product.name",
            title: t("name"),
            flexGrow: 4,
            fullText: true,
            render: d => getProductName(d.product),
        },
        {
            key: "operation.type",
            title: t("operation_type"),
            flexGrow: 4,
            render: d => (
                <OperationReference
                    data={d}
                    receiptDrawer={receiptDrawerData}
                    suppliesDrawer={suppliesDrawer}
                    suppliesReturnDrawer={suppliesReturnDrawer}
                    setDrawerTitle={setDrawerTitle}
                    setDrawerTabsTitle={setDrawerTabsTitle}
                    setShowDeductedTab={setShouldShowDeductedTab}
                    setDrawerCols={setDrawerCols}
                />
            ),
        },
        {
            key: "quantity",
            flexGrow: 4,
            render: d => <Money unit={d.product?.unit} value={d?.difference_quantity} colored />,
        },
        {
            key: "before",
            flexGrow: 4,
            render: d => <Money unit={d.product?.unit} value={d?.before} />,
            title: t("before_quantity"),
            hide: true,
        },
        {
            key: "after",
            flexGrow: 4,
            render: d => <Money unit={d.product?.unit} value={d?.after} />,
            title: t("after_quantity"),
            hide: true,
        },
        { key: "unit_cost", flexGrow: 4, render: d => <Money value={d?.unit_cost || d?.cost} /> },
        {
            key: "total_cost",
            flexGrow: 4,
            type: "money",
            render: d => <Money value={d?.total_cost} />,
        },
        {
            key: "storage.name",
            title: t("storage"),
            flexGrow: 4,
            render: d => d?.storage?.name || "--",
        },
        { key: "operated_at", flexGrow: 4, type: "datetime", reverse: true },
        { key: "created_at", flexGrow: 4, type: "datetime", reverse: true },
    ]);

    return (
        <div className="h-100 p-2">
            {useTitle(t("product_movements"), dp.total)}
            <HeaderRight
                dp={dp}
                cm={cm}
                print
                reload
                date
                export={() => exportFile(dp, cm.columns, t("product_movements"))}
            />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SelectStorageFilter fieldType="params" cleanable={false} />
                    <RemoteSelectFilter
                        fields="product_id"
                        searchField="name"
                        selectedDetails={{
                            url: "product",
                        }}
                        cleanable={false}
                        remote={{
                            url: "product",
                            sort: ["created_at", "-1"],
                            limit: 50,
                            filters: {
                                type: [
                                    ["type", ProductType.GOODS],
                                    ["type", ProductType.DISH],
                                    ["type", ProductType.INGREDIENT],
                                    ["type", ProductType.PREPARATION],
                                    ["type", ProductType.MODIFICATION],
                                ],
                            },
                            params: {
                                trash: Trash.TrashAndActive,
                            },
                            refineList: (products?: IProduct[]) =>
                                products
                                    ?.map(i => productNameAppend(i))
                                    ?.filter(f => !(f.type === ProductType.GOODS && f.has_modifications))
                                    ?.map(f => {
                                        if (f.type === ProductType.MODIFICATION) {
                                            f.type = ProductType.GOODS;
                                        }
                                        return f;
                                    }) ?? [],
                        }}
                        pickerProps={{
                            placeholder: t("product"),
                            groupBy: "type",
                            renderMenuGroup: (groupTitle: ReactNode) => {
                                return getLabel((groupTitle || "") as any);
                            },
                        }}
                        searchRemotely
                    />
                    <CheckListFilter
                        noBindModGood
                        pickerProps={{ searchable: false, placeholder: t("operation_type") }}
                        options={[
                            { label: t("supply"), value: 1 },
                            { label: t("transfer"), value: 3 },
                            { label: t("write_off"), value: 4 },
                            { label: t("return"), value: 5 },
                            { label: t("make"), value: 7 },
                            { label: t("inventory_check"), value: 9 },
                            { label: t("supply_return"), value: 10 },
                            { label: t("initial_stock"), value: 11 },
                            { label: t("sale"), value: 100 }, // A made up OperationType for this API specifically. 100 ID doesn't existst.
                        ]}
                        fields="type"
                        width={150}
                        maxWidth={150}
                    />
                </div>
            </FiltersContainer>
            {hasProductFilter ? (
                <DataTable dp={dp} columns={cm.columns} />
            ) : (
                <div className="empty-data">
                    <div className="warning">
                        <Message type="warning" showIcon>
                            <span style={{ fontSize: 14 }}>
                                <TText tkey="select_product_and_storage_is_required" />
                            </span>
                        </Message>
                    </div>
                </div>
            )}
            <ReceiptDrawer {...receiptDrawerData} />
            <StockOperationsDrawer
                {...suppliesDrawer}
                showDeductedTab={shouldShowDeductedTab}
                title={drawerTitle}
                tabTitle={drawerTabsTitle}
                cols={drawerCols}
            />
            <ReturnedSuppliesProductsDrawer {...suppliesReturnDrawer} />
        </div>
    );
};
export default ProductMovementsPage;
