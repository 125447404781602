import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { PickerHandle, TreePicker, TreePickerProps } from "rsuite";
import { ItemDataType } from "rsuite/esm/@types/common";
import _ from "lodash";
import { usePopupContainer } from "hooks/usePopupContainer";
import { flattenData, renderCategoryParentNames } from "lib/tree-picker-utils";

export interface ITreePickerProps extends Omit<TreePickerProps, "cascade"> {
    customFooter?: () => React.ReactNode;
    groupBy?: string | undefined;
    groupNodeTitle?: (nodeData: ItemDataType) => React.ReactNode;
}

export const CTreePicker = forwardRef<PickerHandle, ITreePickerProps>((props, ref) => {
    const valueKey = props.valueKey || "value";
    const labelKey = props.labelKey || "label";
    const childrenKey = props.childrenKey || "children";

    const [searchQuery, setSearchQuery] = useState<string>("");
    const [expandItemValues, setExpandItemValues] = useState<ItemDataType[]>([]);
    const { container } = usePopupContainer();

    /**
     * We only need flatten version of the tree when search results or selected items are shown.
     */
    const showFlatten = searchQuery.trim() !== "";
    const groupedData = props.groupBy ? _.groupBy(props.data, props.groupBy) : {};

    /**
     * We should clean children prop if it's empty.
     * Otherwise rsuite's TreePicker component will add arrow icon for last child even if it doesn't have any children.
     */
    const cleanedData = useMemo(() => {
        let _data = props.data;

        if (props.groupBy) {
            _data = Object.keys(groupedData).map(k => {
                return {
                    [valueKey]: k,
                    [labelKey]: k,
                    [childrenKey]: groupedData[k],
                };
            });
        }

        const omitChildrenPropIfIsEmpty = (d: ItemDataType) => {
            return d.reduce((prev: ItemDataType[], curr: ItemDataType) => {
                return [
                    ...prev,
                    {
                        ..._.omit(curr, childrenKey),
                        ...(curr[childrenKey]?.length && {
                            [childrenKey]: omitChildrenPropIfIsEmpty(curr[childrenKey]),
                        }),
                    },
                ];
            }, []);
        };

        return omitChildrenPropIfIsEmpty(_.cloneDeep(_data));
    }, [props.data]);

    const data = useMemo(() => {
        let d = cleanedData;

        if (showFlatten) {
            d = flattenData(d, childrenKey);
        }

        return d;
    }, [cleanedData, showFlatten]);

    const renderTreeNode = (nodeData: ItemDataType) => {
        const parent = _.find(data, { id: nodeData.parent_id });

        return parent ? (
            <div className={"w-full tw-flex tw-flex-col"}>
                <div className={"tw-text-base tw-whitespace-break-spaces"}>
                    {props.groupBy && props.groupNodeTitle && Object.keys(groupedData).includes(nodeData[valueKey])
                        ? props.groupNodeTitle(nodeData)
                        : nodeData[labelKey]}
                </div>
                {showFlatten && nodeData.parent_id && (
                    <div className={"tw-text-sm tw-text-gray-400"}>
                        {renderCategoryParentNames(data, parent, labelKey)}
                    </div>
                )}
            </div>
        ) : (
            <div className={"tw-text-base tw-whitespace-break-spaces"}>
                {props.groupBy && props.groupNodeTitle && Object.keys(groupedData).includes(nodeData[valueKey])
                    ? props.groupNodeTitle(nodeData)
                    : nodeData[labelKey]}
            </div>
        );
    };

    useEffect(() => {
        if (props.defaultExpandAll) {
            setExpandItemValues(flattenData(cleanedData, childrenKey).map(d => d[valueKey]));
        }
    }, [props.defaultExpandAll, cleanedData, valueKey]);

    return (
        <TreePicker
            container={() => container.current!}
            placement="autoVerticalStart"
            {..._.omit(props, ["customFooter", "groupNodeTitle", "groupBy"])}
            data={data}
            onSearch={q => {
                setSearchQuery(q);
            }}
            onClose={() => {
                setSearchQuery("");
            }}
            renderExtraFooter={props.customFooter}
            uncheckableItemValues={Object.keys(groupedData)}
            renderTreeNode={renderTreeNode}
            menuClassName="[&_.rs-tree-node-label]:tw-w-full"
            expandItemValues={expandItemValues}
            onExpand={(v: ItemDataType[]) => setExpandItemValues(v)}
            ref={ref}
        />
    );
});
