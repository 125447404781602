import { t } from "lib/i18n";
import Widget from "../../components/Widget";
import { useDataProvider } from "components/DataTable/useDataProvider";
import _ from "lodash";
import { sumPath } from "lib/utils";
import { getPointerLabel } from "lib/chart-utils";
import CChart from "../../components/Chart";
import NoData from "components/NoData";
import { useDepot } from "hooks/useDepot";
import { IconButton } from "rsuite";
import CIcon from "../../components/CIcon";
import { iBarChart, iPieChart } from "assets/icons/Icons";
import { useCurrency } from "hooks/useCurrency";
import { useSetting } from "hooks/useSetting";
import { passCashRestartCheckStart } from "lib/passCashRestartCheckStart";
import { passCashRestartCheckEnd } from "lib/passCashRestartCheckEnd";

const ExpenseWidget: FCC = () => {
    const [chartType, setChartType] = useDepot("ExpenseWidget", "column");
    const cashRestart = useSetting("cash_restart")?.value;

    const dp = useDataProvider({
        url: "finance/transaction",
        with: ["category"],
        filters: {
            type: ["type", "OUT"],
            indicator: ["indicator", 0],
        },
        params: {
            date: [passCashRestartCheckStart(cashRestart), passCashRestartCheckEnd(cashRestart)],
        },
    });

    const btnProps = (name: string): any => ({
        size: "xs",
        appearance: name === chartType ? "primary" : undefined,
        onClick: () => setChartType(name),
    });

    const groupData: Record<any, any> = _.groupBy(_.filter(dp.data, { type: "OUT" }), "category_id");
    const data = Object.values(groupData).map((transaction: ITransaction[]) => {
        return {
            y: sumPath("amount", transaction),
            name: transaction[0].category?.name,
        };
    });
    const currency = useCurrency();

    return (
        <Widget
            title={t("expense_by_category_type")}
            headerButtons={
                <>
                    <IconButton className="mr-2" icon={<CIcon size={0.6} path={iBarChart} />} {...btnProps("column")} />
                    <IconButton className="mr-2" icon={<CIcon size={0.6} path={iPieChart} />} {...btnProps("pie")} />
                </>
            }
        >
            {_.isEmpty(data) ? (
                <NoData />
            ) : (
                <CChart
                    options={{
                        chart: { height: 200 },
                        xAxis: {
                            categories: data.map((m: any) => m.name),
                        },
                        tooltip: {
                            useHTML: true,
                            headerFormat: "<small>{point.key}</small><table>",
                            pointFormatter() {
                                return getPointerLabel(this, "money", currency);
                            },
                            footerFormat: "</table>",
                        },
                    }}
                    series={[{ type: chartType, data, name: t("amount") } as any]}
                />
            )}
        </Widget>
    );
};

export default ExpenseWidget;
