import { t } from "lib/i18n";

import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { useEffect, useState } from "react";
import Ajax from "../../../lib/Ajax";
import UsageTabBeta from "../preparations/drawer/tabs/UsageTabBeta";

type IProps = ICDrawerProps;

const IngredientDrawer: FCC<IProps> = ({ dataId, ...props }) => {
    // const { data } = useData(!!dataId && `product/ingredient/${dataId}`, {
    //     with: ["unit", "relatable.unit"],
    // });
    const [countData, setCountData] = useState<any>();

    useEffect(() => {
        (async () => {
            if (dataId) {
                const data = await Ajax.get({
                    url: `product/relatable/${dataId}/info`,
                });

                const countData: { [key: string]: number } = data.data;
                setCountData(countData);
            }
        })();
    }, [dataId]);

    return (
        <CDrawer size="md" title={`${t("ingredient")} #${dataId} : ${props.data?.name}`} {...props}>
            <UsageTabBeta dataId={dataId} productId={dataId as number} countData={countData} {...props} />
            {/*<TabsView*/}
            {/*    tabs={[*/}
            {/*        {*/}
            {/*            label: `${t("dishes")} (${dishes.length})`,*/}
            {/*            Component: IngredientDrawerTab,*/}
            {/*            props: { data: dishes, ingredientId: dataId, mainData: data },*/}
            {/*        },*/}
            {/*        {*/}
            {/*            label: `${t("preparations")} (${preparations.length})`,*/}
            {/*            Component: IngredientDrawerTab,*/}
            {/*            props: { data: preparations, ingredientId: dataId },*/}
            {/*        },*/}
            {/*        {*/}
            {/*            label: t("used_by_modifier"),*/}
            {/*            Component: InUseModifier,*/}
            {/*            props: { dataId },*/}
            {/*        },*/}
            {/*    ]}*/}
            {/*/>*/}
        </CDrawer>
    );
};

export default IngredientDrawer;
