import { getLabel } from "components/Form/form-utils";
import Money from "components/mini/Money";
import { Colors, getTextColorFor } from "config/colors";
import _ from "lodash";
import React, { CSSProperties } from "react";
import { Badge, Tooltip, Whisper } from "rsuite";
import { usePaymentMethods } from "hooks/data-hooks/usePaymentMethods";

interface IProps {
    r: IReceipt;
}

const badgeStyle: CSSProperties = {
    cursor: "default",
    padding: 2,
    fontFamily: "monospace",
    width: 20,
    borderRadius: 2,
    backgroundColor: Colors.DarkGray,
};

const colorPairs = _.map(Colors, (value, name) => ({ name, value, text: getTextColorFor(name as any) }));
const getColor = (id: number) => colorPairs[id % 10];

const PaymentMethodsCell: FCC<IProps> = props => {
    const methods = usePaymentMethods();

    if (methods.status !== "succeeded") {
        return null;
    }

    return (
        <div>
            {props.r.payment_methods?.map((pm: any, index: number) => {
                if (pm.amount === 0) return null;
                const color = getColor(pm.id);
                const pmo = methods.byId[pm.id];

                return (
                    <Whisper
                        key={index}
                        speaker={
                            <Tooltip>
                                {getLabel(pmo?.name) || pm?.name} {<Money value={pm.amount} />}
                            </Tooltip>
                        }
                    >
                        <Badge
                            style={{
                                ...badgeStyle,
                                marginLeft: index !== 0 ? 5 : 0,
                                backgroundColor: color?.value,
                                color: color?.text,
                                textAlign: "center",
                            }}
                            content={(getLabel(pmo?.name) || pm?.name)?.substr(0, 1).toUpperCase()}
                        />
                    </Whisper>
                );
            })}
        </div>
    );
};

export default PaymentMethodsCell;
