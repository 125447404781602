import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import CreateButton from "components/DataTable/CreateButton";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import SelectFilter from "components/DataTable/Filters/SelectFilter";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";

export const UsersPage: FCC = () => {
    const auth = useAuth();
    const dp = useDataProvider({
        url: "user",
        useUrl: true,
        with: ["roles.abilities"],
        filters: {
            status: ["status", 1],
        },
        refineList: (users: IUser[]) => users?.filter(u => (!u.hide && !u.owner) || auth.user?.id === u.id),
    });

    const cm = useColumns<any>("user", [
        { title: t("username"), key: "username", flexGrow: 2 },

        { title: t("role"), key: "roles.0.title", hideData: () => !auth.hasAbility(["role_manage_view"]) },

        { key: "salary", type: "money" },
        { title: t("email"), key: "email", flexGrow: 2, minWidth: 250 },
        { title: t("brand_phone"), key: "mobile_number" },
        { title: t("pin").toUpperCase(), key: "pin", minWidth: 80 },
        { title: t("card_id"), key: "card", minWidth: 130 },
        { title: t("last_login"), key: "login_at", minWidth: 130 },
        {
            title: t("status"),
            key: "status",
            hide: true,
            type: "bool",
        },
        optionsColumn({
            dp,
            edit: "/staff/users/edit/:id",
            delete: "user/:id",
            canEdit: "user_manage_edit",
            canDelete: "user_manage_delete",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("users"), dp.total)}
            <HeaderRight cm={cm} dp={dp} reload />
            <div className="mr-3" style={{ display: "flex", justifyContent: "space-between " }}>
                <div style={{ display: "flex" }}>
                    <FiltersContainer dp={dp}>
                        <div className="d-flex flex-fill table-filter">
                            <SearchFilter fields="username" />
                            <SelectFilter
                                pickerProps={{ placeholder: t("status"), className: "tw-ml-1", searchable: false }}
                                fields="status"
                                hasAllOption={true}
                                options={[
                                    { value: 1, label: t("active") },
                                    { value: "0", label: t("inactive") },
                                ]}
                            />

                            <RemoteCheckListFilter
                                remote={{
                                    url: "role",
                                }}
                                searchField="name"
                                fields="roles"
                                searchLocal={true}
                                pickerProps={{ placeholder: t("roles"), valueKey: "name", className: "tw-ml-1" }}
                                hasPermission={auth.hasAbility(["role_manage_view"])}
                            />
                        </div>
                    </FiltersContainer>
                </div>
                <CreateButton to={"/staff/users/create"} canCreate={"user_manage_create"} />
            </div>
            <DataTable dp={dp} columns={cm.columns} />
        </div>
    );
};
