import { EMPTY_DATA } from "config/main";
import Money from "./Money";

interface IProps {
    amount: number;
    rate: number;
    symbol?: boolean;
}

const ServiceCharge: FCC<IProps> = props => {
    return props.amount && props.rate ? (
        <span>
            <span className="pr-1">
                {props.amount ? <Money symbol={props.symbol} value={props.amount} /> : EMPTY_DATA}
            </span>
            ({props.rate ? `${props.rate} %` : EMPTY_DATA})
        </span>
    ) : (
        <span>{EMPTY_DATA}</span>
    );
};

export default ServiceCharge;
