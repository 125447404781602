import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useTitle } from "components/Header/Title";

import DataTable from "../../../components/DataTable/DataTable";
import HeaderRight from "../../../components/Header/HeaderRight";
import { exportFile } from "lib/export-table";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import nrInRoutings from "./nr-in-routings";
import { useLocation } from "react-router-dom";
import Money from "components/mini/Money";
import { useData } from "hooks/useData";
import _ from "lodash";
import { QuickLinks } from "components/Header/QuickLinks";
import RemoteCheckListFilter from "../../../components/DataTable/Filters/RemoteCheckListFilter";
import React from "react";
import SourceFilter from "../../../components/DataTable/Filters/SourceFilter";
import { Colors } from "config/colors";

const NewReportsStationPage: FCC = () => {
    const loc = useLocation();
    const url = "reports/station";

    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        url,
        with: ["station"],
        sort: ["created_at", "1"],
    });

    const summary = useData<any>(url, {
        filters: _.values(dp.filters.all),
        params: {
            total: 1,
            date: dp.params.all.date,
        },
    });

    const cm = useColumns<any>("reports/station", [
        {
            title: "ID",
            key: "station.id",
            align: "left",
            flexGrow: 0.3,
        },
        {
            title: t("name"),
            key: "station.name",
            render: (d: any) => {
                return (
                    d.station?.name || (
                        <span style={{ color: Colors.BrightRed }}>
                            <TText tkey="without_station" />
                        </span>
                    )
                );
            },
        },
        {
            title: t("product_count"),
            key: "total_count",
            summary: () => summary.data?.[0]?.total_count,
        },
        {
            title: t("total_cost"),
            key: "total_cost",
            type: "money",
            summary: () => <Money value={summary.data?.[0]?.total_cost} />,
        },
        {
            title: t("total_cash"),
            key: "total_cash",
            type: "money",
            summary: () => <Money value={summary.data?.[0]?.total_cash} />,
        },
    ]);

    return (
        <div className="h-100">
            {useTitle(t("stations"), dp.total)}

            <QuickLinks inHeader={false} links={nrInRoutings(loc)} />

            <HeaderRight cm={cm} print export={() => exportFile(dp, cm.columns, t("stations"))} reload date dp={dp} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <RemoteCheckListFilter
                        remote={{
                            url: "station",
                            refineList: (list: any[]) => {
                                list?.push({ id: null, name: t("without_station") });
                                return list;
                            },
                        }}
                        fields="receipt_station_id"
                        pickerProps={{ placeholder: t("station"), searchable: false }}
                    />
                    <SourceFilter />
                </div>
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
        </div>
    );
};

export default NewReportsStationPage;
