import { t } from "lib/i18n";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import TabsView from "components/TabsView";
import { getProductName } from "lib/utils";
import GoodsStatsIngredients from "pages/statistics/goods/drawer/tabs/GoodsStatsIngredients";
import GoodsStatsModifierTab from "pages/statistics/goods/drawer/tabs/GoodsStatsModifierTab";
import { ProductType } from "config/constants";
import GoodsStatsReceipt from "./tabs/GoodsStatsReceipt";
import { FC } from "react";

type IProps = ICDrawerProps;

const GoodsStatsDrawer: FC<IProps> = props => {
    const id = props.data?.id;

    return (
        <CDrawer title={`#${props.data?.id} ${getProductName(props.data)}`} {...props}>
            <TabsView
                tabs={[
                    {
                        label: t("modifier_groups"),
                        hide: props.data?.type !== ProductType.DISH,
                        Component: GoodsStatsModifierTab,
                        props: { id },
                    },
                    {
                        label: t("deducted_stock"),
                        hide: props.data?.type !== ProductType.DISH,
                        Component: GoodsStatsIngredients,
                        props: { id },
                    },
                    {
                        label: t("receipt"),
                        Component: GoodsStatsReceipt,
                        props: {
                            id,
                            type: props.data?.type,
                            url: `reports/receipts/by-product/${id}`,
                            ...props,
                        },
                    },
                ]}
            />
        </CDrawer>
    );
};

export default GoodsStatsDrawer;
