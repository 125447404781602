import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import RadioFormGroup from "components/Form/RadioFormGroup";
import { Radio } from "rsuite";

const options = () => {
    return [
        { value: "CashBack", label: t("cashback"), disabled: false },
        { value: "Point", label: t("point"), disabled: true },
        { value: "Discount", label: t("discount"), disabled: true },
    ];
};

const LoyaltyRadioGroupField = () => {
    return (
        <RadioFormGroup name="payload.type" className="tw-mt-1" hideLabel>
            {options().map(opt => (
                <div>
                    <Radio value={opt.value} disabled={opt.disabled}>
                        {opt.label}
                    </Radio>
                    {opt.disabled ? (
                        <span className={`tw-m-2 tw-py-0.5 tw-px-1.5 tw-rounded-10 tw-text-sm tw-rounded tw-bg-muted`}>
                            <TText tkey="soon" />
                        </span>
                    ) : null}
                </div>
            ))}
        </RadioFormGroup>
    );
};

export default LoyaltyRadioGroupField;
