import { getLabel } from "components/Form/form-utils";
import { alignments, GridItemAlignment } from "components/NewForm/Grid/grid-item-utils";
import React, { FC, ReactNode } from "react";
import { cn } from "lib/utils";

export interface FIHeaderProps {
    columns: [string, GridItemAlignment?, ((name: string, props?: FIHeaderLabelProps) => ReactNode)?][];
    className?: string;
}

export interface FIHeaderLabelProps extends React.Attributes, React.HTMLAttributes<HTMLDivElement> {
    "test-id": string;
}

export const FormItemsHeader: FC<FIHeaderProps> = ({ columns, className }) => {
    const size = columns.length;

    return (
        <div
            className={`tw-grid tw-grid-cols-subgrid tw-bg-blue-50 tw-sticky tw-h-fit tw-top-[-2px] tw-z-10 tw-shadow`}
            style={{
                gridColumn: `span ${size} / span ${size}`,
            }}
        >
            {columns.map(([name, align = "left", render], i) => {
                const props: FIHeaderLabelProps = {
                    "test-id": `column-header-${name}`,
                    key: i,
                    className: cn(`tw-p-2 tw-text-sm tw-items-end tw-grid ${alignments[align]}`, className),
                };

                if (render) {
                    return render(name, props);
                }

                return (
                    <div key={name} {...props}>
                        <span>{getLabel(name)}</span>
                    </div>
                );
            })}
        </div>
    );
};
