import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import CForm from "components/Form/CForm/CForm";
import DatePickerFormGroup from "components/Form/DatePickerFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import JsonView from "components/JsonView";
import { format } from "date-fns";
import { useCForm } from "hooks/useCForm";
import { TransferOperationModel } from "models/OperationModel";
import React, { FC, useEffect, useState } from "react";
import { Col, Grid, Notification, Row, toaster } from "rsuite";
import { dummyTransferItem, dummyTransferOperation } from "../misc/utils";
import TransferItem, { TransferItemField } from "./TransferItem";
import { refineVenueList, translateUnitName } from "lib/utils";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { ProductType } from "config/constants";
import Ajax from "lib/Ajax";
import { FormItems } from "components/FormItems/FormItems";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { unitsSlice } from "redux/features/model/slice";
import { FormItemsAddButton } from "components/FormItems/FormItemsAddButton";
import { MassInsertButton } from "components/FormItems/MassInsertButton";
import { store } from "redux/store";
import { IOperationItem } from "pages/inventory/types";
import { TransferSchema } from "form-schemas/TransferSchema";
import CProductsDrawer from "components/Drawers/CProductSelector/CProductsDrawer";
import { FormItemsImportButton, IImportedItemSuccesProps } from "components/FormItems/FormItemsImportButton";
import { useStorages } from "hooks/data-hooks/useStorages";
import { StorageSelectFormGroup } from "components/Form/adhoc/StorageSelectFormGroup";
import { isEditingPage } from "lib/isEditingPage";
import { setDisabledProductIds, setShowedTypes } from "redux/features/productsSelector/productsSelector";
import { isContainValue } from "lib/pure";

const columns = [
    ["product"],
    ["unit"],
    ["count", "right"],
    ["qty", "right"],
    [""], // buttons
] as const;

const refineItemOnInsert = (d: any) => {
    const units = store.getState().data.units;
    return {
        product_id: d.id,
        quantity: 0,
        unit_id: d.unit_id,
        units: [units.byId[d.unit_id], ...(d.packages || [])].map(unit => ({
            id: unit.id,
            name: translateUnitName(unit),
        })),
        product_full_name: undefined,
        id: undefined,
    };
};

const CreateTransferPage: FC = () => {
    const isEdit = isEditingPage();
    const [productsStockCount, setProductsStockCount] = useState<Record<string, number>>({});
    const dispatch = useAppDispatch();

    const productsById = useAppSelector(state => state.data.products.byId);

    const searchableColumns: SearchableColumns<Mutable<typeof columns>> = {
        product: (searchVal: string, item: any) => isContainValue(searchVal, productsById[item?.product_id]?.slug),
    };

    const form = useCForm<IOperation>({
        url: "operation",
        mode: "all",
        with: ["operation_items.product.packages", "operation_items.unit", "operation_items.product"],
        dummyData: dummyTransferOperation,
        hasAllVenues: true,
        model2: TransferSchema,
        // model: TransferOperationModel,
        prepareForSubmit: (d: any) => {
            d.items = d.items.map((i: any) => _.omit(i, "units", "product_full_name"));
            d.operated_at = format(isEdit ? new Date(d.operated_at) : d.operated_at, "yyyy-MM-dd HH:mm:ss");

            return _.pick(d, _.keys(TransferOperationModel.fields));
        },
        refine: (d: any) => {
            const refinedData = { ...d };

            if (isEdit) {
                refinedData.items = d?.operation_items?.map((oP: IStockOperation) => ({
                    id: oP.id,
                    unit_id: oP.unit_id,
                    product_id: oP.product?.id,
                    product_full_name: oP.product?.deleted_at
                        ? `${oP.product?.full_name} (${t("deleted_product")})`
                        : oP.product?.full_name,
                    quantity: oP.quantity,
                    units: [oP.unit, ...(oP.product.packages || [])],
                }));
                refinedData.operation_products = d.operation_items.map((item: any) => item.product);
            }

            refinedData.operated_at = new Date(d.operated_at ?? new Date());
            return refinedData;
        },
    });
    const addedItems: IOperationItem[] = form.form.watch("items");
    const itemIds = addedItems?.map((p: any) => p.product_id) ?? [];
    const itemsHash = itemIds?.join(",");

    const fromStorageId = form.form.watch("from_storage_id");
    const storageId = form.form.watch("storage_id");

    const storageList = useStorages({ showOnlyActive: true, refineList: refineVenueList })?.list;

    useEffect(() => {
        dispatch(unitsSlice.fetchData(""));
        dispatch(
            setShowedTypes([ProductType.DISH, ProductType.PREPARATION, ProductType.INGREDIENT, ProductType.GOODS]),
        );
    }, []);

    useEffect(() => {
        dispatch(setDisabledProductIds(itemIds));

        const url = Ajax.buildUrl({
            url: "stock/product",
            params: {
                limit: 999, //(itemIds?.length || 0) + 5,

                filters: {
                    storage: ["storage_id", fromStorageId],
                    id: ["id", itemIds.slice(0, 500)],
                },
                with: ["total_quantity"],
            },
            data: {
                itemIds: itemIds.slice(0, 500),
            },
        });
        (async () => {
            if ((addedItems?.length && fromStorageId) || isEdit) {
                const res = await Ajax.get({ url });
                const counts: Record<string, number> = {};
                for (const d of res.data) {
                    counts[d.id] = d.total_quantity ?? 0;
                }
                if (res.success) {
                    setProductsStockCount(counts);
                }
            }
        })();
    }, [itemsHash, isEdit]);

    useEffect(
        () => {
            if (!isEdit) {
                form.form.setValue("items", [dummyTransferItem()]);
            }
        },
        // eslint-disable-next-line
        [fromStorageId],
    );

    const addItemsFromXLS = (props: IImportedItemSuccesProps) => {
        try {
            const { products, append, remove } = props;
            for (const x in products) {
                const addedItemIndex = _.findIndex(addedItems, p => p.product_id === products[x].product_id);
                const item: any = products[x];
                const product = item.product;

                if (addedItemIndex !== -1) {
                    form.form.setValue(`items[${addedItemIndex}].quantity`, item?.quantity);
                } else {
                    append({
                        id: uuidv4(),
                        product_id: product?.id,
                        unit_id: product?.unit_id,
                        quantity: item?.quantity,
                        deleted_at: Date.now(),
                    });
                }

                const items: IOperationItem[] = form.form.getValues("items");
                const hasField = items.map((a, i) => (!a["product_id"] ? i : null)).filter(_.isNumber);
                remove(hasField);
            }
        } catch (e) {
            toaster.push(
                <Notification type="error" header={t("error") + " (AIFXLS)"} closable>
                    <TText tkey="went_wrong" />
                </Notification>,
            );
        }
    };

    const isEditable = !isEdit || form.form.watch("canEdit");

    return (
        <div className="tw-h-full tw-p-4">
            {useFormTitle(form.id, t("transfers"))}
            <HeaderRight
                formSubmit={{
                    ...form,
                    disabled: !isEditable,
                }}
            />
            {/*<UnitsProvider refineList={(units?: IUnit[]) => units?.map(translateUnit) as any}>*/}
            <CForm {...form} disabled={!isEditable} formProps={{ layout: "vertical" }}>
                <Grid fluid>
                    <Row className="mb-3">
                        <Col xs={24} sm={24} md={7}>
                            <StorageSelectFormGroup
                                refineList={list =>
                                    refineVenueList(list.filter(l => l.status || (isEdit && l.id === fromStorageId)))
                                }
                                name="from_storage_id"
                                label={t("from_storage_id")}
                                compProps={{
                                    groupBy: "venue_name",
                                    disabledItemValues: storageList
                                        ?.filter(storage => storage.id === storageId)
                                        .map(s => s.id),
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                            <StorageSelectFormGroup
                                hideStatus={true}
                                showAllVenues={true}
                                refineList={list =>
                                    refineVenueList(list.filter(l => l.status || (isEdit && l.id === storageId)))
                                }
                                compProps={{
                                    groupBy: "venue_name",
                                    disabledItemValues: storageList
                                        ?.filter(storage => storage.id === fromStorageId)
                                        .map(s => s.id),
                                }}
                            />
                        </Col>
                    </Row>

                    <Row className="mb-3">
                        <Col xs={24} sm={24} md={7}>
                            <DatePickerFormGroup
                                time
                                name="operated_at"
                                label={t("operation")}
                                compProps={{
                                    cleanable: false,
                                    shouldDisableDate: d => !!d && typeof d === "object" && d.getTime() > Date.now(),
                                }}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={6}>
                            <TextFormGroup name="reason" />
                        </Col>
                    </Row>
                </Grid>
                {fromStorageId && (
                    <FormItems itemsFieldName="items">
                        <FormItemsList<TransferItemField, Mutable<typeof columns>>
                            virtualize
                            columns={columns as Mutable<typeof columns>}
                            searchableColumns={searchableColumns}
                            footer={
                                <div className="tw-flex tw-gap-2 tw-col-span-5">
                                    <MassInsertButton idField="product_id" refineItem={refineItemOnInsert} />
                                    <FormItemsAddButton dummyData={dummyTransferItem} />
                                    <FormItemsImportButton
                                        onImportSuccess={addItemsFromXLS}
                                        importUrl="operation/excel-actions-for-supply"
                                    />
                                </div>
                            }
                        >
                            {_props => {
                                return (
                                    <TransferItem
                                        {..._props}
                                        quantity={productsStockCount[_props.field.product_id]}
                                        refineItemOnInsert={refineItemOnInsert}
                                    />
                                );
                            }}
                        </FormItemsList>
                    </FormItems>
                )}
                <JsonView />
            </CForm>
            <CProductsDrawer />
        </div>
    );
};

export default CreateTransferPage;
