import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React, { FC } from "react";
import "./Settings.scss";
import CFormGroup from "../../../components/Form/CFormGroup";
import { CRadioTile } from "components/RadioTile/CRadioTile";
import { IMenuCustomizationTabProps } from "pages/qr-menu/settings/MenuCustomizationTab";
import { useSetting } from "hooks/useSetting";
import { BrandType } from "types/routes";

export const enum ProductsPageLayoutType {
    RIGHT = "right_side",
    LEFT = "left_side",
    TOP = "top",
    BIG = "big",
    LIST = "list",
}

export const ProductsPageLayout: FC<IMenuCustomizationTabProps> = ({ form }) => {
    const currentTemplate = form.form.watch("payload.meta.menu_layout.product_template");

    if (!currentTemplate) {
        form.form.setValue("payload.meta.menu_layout.product_template", ProductsPageLayoutType.RIGHT);
    }

    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;

    const productsPageLayouts = [
        {
            value: ProductsPageLayoutType.RIGHT,
            label: "right_side_image",
            imageSrc: isRestaurant
                ? "/img/qr-menu-layouts/product-layout-right.svg"
                : "/img/qr-menu-layouts/shop-product-layout-right.svg",
        },
        {
            value: ProductsPageLayoutType.LEFT,
            label: "left_side_image",
            imageSrc: isRestaurant
                ? "/img/qr-menu-layouts/product-layout-left.svg"
                : "/img/qr-menu-layouts/shop-product-layout-left.svg",
        },
        {
            value: ProductsPageLayoutType.TOP,
            label: "top_image",
            imageSrc: isRestaurant
                ? "/img/qr-menu-layouts/product-layout-top.svg"
                : "/img/qr-menu-layouts/shop-product-layout-top.svg",
        },
        {
            value: ProductsPageLayoutType.BIG,
            label: "big_image",
            imageSrc: isRestaurant
                ? "/img/qr-menu-layouts/product-layout-big.svg"
                : "/img/qr-menu-layouts/shop-product-layout-big.svg",
        },
        {
            value: ProductsPageLayoutType.LIST,
            label: "list",
            imageSrc: "/img/qr-menu-layouts/product-layout-list.svg",
        },
    ] as const;

    return (
        <>
            <div className="page-title">
                <TText tkey="product_template" />
            </div>
            <CFormGroup
                hideLabel={true}
                name="payload.meta.menu_layout.product_template"
                comp={CRadioTile}
                compProps={{
                    options: productsPageLayouts.map(o => ({
                        ...o,
                        label: t(o.label),
                    })),
                }}
            />
        </>
    );
};
