import React, { useEffect, useState } from "react";
import { Divider, TagPicker, TagPickerProps } from "rsuite";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import { getLabel } from "./form-utils";
import { UseSettingsFormReturnType } from "pages/settings/useSettingsForm";
import { t } from "lib/i18n";

interface IProps extends CFormGroupProps<TagPickerProps> {
    columns: string[];
    data: any[];
    options: any[];
    reset: any;
    form: UseSettingsFormReturnType;
}

const CBulkTagPickerFormGroup: FCC<IProps> = ({ form, reset, columns, data, options, ...props }) => {
    const [tags, setTags] = useState<Record<number, number[]>>({});

    useEffect(() => {
        setTags(form.form.getValues(props.name) || {});
        // eslint-disable-next-line
    }, [reset]);

    const handleChange = (value: number[], event: any, id: number, onChange: any) => {
        setTags(prev => {
            if (!value) return prev;

            const next = {
                ...prev,
                [id]: value,
            };

            onChange(next, event);

            return next;
        });
    };

    const cprops: any = {
        render: ({ field: { onChange } }: any) => {
            return (
                <>
                    <div className="rs-form-group">
                        {columns.map(column => (
                            <label key={column} className="rs-control-label text-left">
                                {getLabel(column)}
                            </label>
                        ))}
                    </div>
                    <Divider style={{ marginTop: -15 }} className="mb-3" />
                    {data?.map(d => {
                        const id = d.station ? `${d.id}:${d.station.id}` : d.id;

                        return (
                            <div className="rs-form-group" key={id}>
                                <label className="rs-control-label text-left">{d.name}</label>
                                {d.station ? (
                                    <label className="rs-control-label text-left">{d.station.name}</label>
                                ) : null}
                                <TagPicker
                                    data={options}
                                    style={{ width: 300 }}
                                    labelKey={"name"}
                                    valueKey={"id"}
                                    value={tags[id] ?? []}
                                    onChange={(value: number[], event: any) => handleChange(value, event, id, onChange)}
                                />
                            </div>
                        );
                    })}
                </>
            );
        },
        ...props.compProps,
    };

    return <CFormGroup {...(props as any)} label={false} compProps={cprops} />;
};

export default CBulkTagPickerFormGroup;
