import { cn } from "lib/utils";
import { Radio, RadioGroup } from "rsuite";
import React, { ReactNode } from "react";
import { ValueType } from "rsuite/esm/Radio/Radio";

interface ICRadioTileProps<T> {
    options: {
        value: T;
        label: ReactNode;
        imageSrc: string;
    }[];
    value: T;
    onChange: (value: T) => void;
}

export function CRadioTile<T extends ValueType>(props: ICRadioTileProps<T>) {
    return (
        <RadioGroup
            value={props.value}
            className={"tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-4 xl:tw-grid-cols-5 tw-gap-4"}
        >
            {props.options.map(o => (
                <div
                    key={o.value}
                    onClick={() => props.onChange(o.value)}
                    className={cn("tw-w-full tw-max-w-64 tw-cursor-pointer")}
                >
                    <img
                        src={o.imageSrc}
                        alt={""}
                        className={cn("tw-w-full tw-border tw-border-solid tw-border-transparent tw-rounded-xl", {
                            "tw-border-blue-400": o.value === props.value, // todo: primary reng deyishende bunu da deyishmek
                        })}
                    />
                    <Radio
                        value={o.value}
                        className={cn("tw-ml-0", {
                            "tw-text-blue-500": o.value === props.value, // todo: primary reng deyishende bunu da deyishmek
                        })}
                    >
                        {o.label}
                    </Radio>
                </div>
            ))}
        </RadioGroup>
    );
}
