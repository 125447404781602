import { TText } from "components/i18n/TText";
import React, { useEffect } from "react";
import { useData } from "hooks/useData";
import { Button, Panel, PanelGroup } from "rsuite";
import "./PriceListDrawer.scss";
import { useFieldArray } from "react-hook-form";
import JsonView from "../../../../components/JsonView";
import ProductPricesItems from "./ProductPricesItems";
import { useCurrency } from "hooks/useCurrency";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

const ProductPricesPage: FCC<any> = ({ data, form, has_modifications }) => {
    const productPrices = useData("products/prices", {
        filters: {
            product_id: [
                "product_id",
                has_modifications
                    ? data.modifications.map((data: any) => {
                          return data.id;
                      })
                    : data.id,
            ],
        } as any,
    });

    const priceLists = useData("products/price-lists");

    const { setValue, control } = useCFormContext();
    const { fields } = useFieldArray({ control, name: "product_prices" });
    const currency = useCurrency();

    useEffect(() => {
        const values: any = [];
        if (has_modifications && productPrices.data) {
            data?.modifications?.map((modification: IProduct, index: number) => {
                values.push(productPrices.data.filter((data: any) => data.product_id === modification.id));
            });
        } else {
            values.push(productPrices.data);
        }
        setValue("product_prices", values);
        form.form.reset({ product_prices: values });
    }, [productPrices.data]);

    const renderPanelHeader = (index: number) => {
        const modification: IProduct = data?.modifications[index];
        return (
            <div className="header">
                <span className="title">{modification.name}</span>
                {prices(modification.cost_price, modification.price)}
            </div>
        );
    };

    const prices = (cost_price: number, price: number) => {
        return (
            <>
                <div className="price-section">
                    <span className="name">
                        <TText tkey="cost_price" />:
                    </span>
                    <span className="price">
                        {cost_price} {currency}
                    </span>
                </div>
                <div className="price-section">
                    <span className="name">
                        <TText tkey="base_price" />:
                    </span>
                    <span className="price">
                        {price} {currency}
                    </span>
                </div>
            </>
        );
    };

    return (
        <div className="d-flex flex-column justify-content-between" style={{ height: "100%" }}>
            <div className="px-3 pt-3 clopos-scroll" style={{ overflowY: "auto", height: "100%" }}>
                <JsonView />
                {fields?.map((fieldData: any, index: number) => {
                    return has_modifications ? (
                        <PanelGroup accordion className="price-list-accordion">
                            <Panel header={renderPanelHeader(index)} defaultExpanded>
                                <div className="panel-body">
                                    <ProductPricesItems
                                        itemsIndex={index}
                                        key={"price_list_accordion_" + fieldData.id}
                                        modificationId={data?.modifications[index]?.id}
                                        priceListsData={priceLists?.data}
                                    />
                                </div>
                            </Panel>
                        </PanelGroup>
                    ) : (
                        <>
                            <div className="rs-panel-title no-border">{prices(data?.cost_price, data?.price)}</div>
                            <div className="panel-body">
                                <ProductPricesItems
                                    itemsIndex={index}
                                    key={fieldData.id}
                                    modificationId={data?.id}
                                    priceListsData={priceLists?.data}
                                />
                            </div>
                        </>
                    );
                })}
            </div>
            <div className="footer">
                <Button loading={form.isLoading} onClick={() => form.onSave()}>
                    <span>
                        <TText tkey="save" />
                    </span>
                </Button>
            </div>
        </div>
    );
};

export default ProductPricesPage;
