import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React from "react";
import CDrawer, { ICDrawerProps } from "../../../components/Drawers/CDrawer";
import MenuCategoryTreeFilter from "../../../components/DataTable/Filters/CategoryTreeFilter/MenuCategoryTreeFilter";
import { CategoryTypeEnum } from "config/constants";
import FiltersContainer from "../../../components/DataTable/Filters/FiltersContainer";
import SelectFilter from "../../../components/DataTable/Filters/SelectFilter";
import { Colors } from "config/colors";

interface IProps extends ICDrawerProps {
    dp: any;
}

const IngredientFIlterDrawer = (props: IProps) => {
    return (
        <CDrawer size="xs" title={t("filters")} {...props}>
            <div className="d-flex flex-column justify-content-between" style={{ height: "89vh" }}>
                <div className="px-3 pt-3 clopos-scroll" style={{ overflowY: "auto" }}>
                    <FiltersContainer hasPadding={true} dp={props.dp}>
                        <label style={labelColStyle}>
                            <TText tkey="accounting_category" />
                        </label>
                        <MenuCategoryTreeFilter
                            fieldName={"accounting_category_id"}
                            placeholder={t("accounting_category")}
                            categoryTypes={[CategoryTypeEnum.ACCOUNTING]}
                            width={450}
                            maxWidth={450}
                        />
                        <label style={labelColStyle}>
                            <TText tkey="in_dish" />
                        </label>

                        <SelectFilter
                            fields="inDish"
                            fieldType={"params"}
                            pickerProps={{ searchable: false, placeholder: t("in_dish") }}
                            options={[
                                { label: t("in_use"), value: "1" },
                                { label: t("not_in_use"), value: "-1" },
                            ]}
                            width={450}
                            maxWidth={450}
                        />
                    </FiltersContainer>
                </div>
            </div>
        </CDrawer>
    );
};

export default IngredientFIlterDrawer;

const labelRowStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
};
const labelColStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
    marginTop: "16px",
};
