export interface IRoute {
    id?: string;
    key?: string;
    title?: string;
    icon?: string;
    isNew?: boolean;
    module?: Limitation;
    integrations?: string[];
    packages?: string[];
    modules?: string[];
}

export const enum Limitation {
    EMENU = "emenu_access",
    // REVIEW = "review_access",
    STOCK = "stock_access",
    FINANCE = "finance_access",
    MARKET = "marketing_access",
    // MODIFICATIONS = "modifications_access",
    // MODIFICATOR = "modificator_access",
    // CASH_SHIFT = "cash_shifts_access",
    // KITCHEN = "kitchen_access",
    LOYALTY = "loyalty_access",
}

export const enum BrandType {
    RESTAURANT = "restaurant",
    SHOP = "shop",
}

export interface IRouteWithComp extends IRoute {
    path: string;
    Comp: FCC;
    hide?: boolean;
    access?: Limitation;
    packages?: string[];
    integrations?: string[];
    permissions?: string[] | ((hasAbility: (args: string[]) => boolean) => boolean);
    type?: BrandType;
}

export interface IRouteWithSubRoutes extends IRoute {
    subroutes: IRouteWithComp[];
}
