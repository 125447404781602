import { t } from "lib/i18n";
import CChart from "components/Chart";
import { IDataProvider } from "components/DataTable/useDataProvider";
import Widget from "components/Widget";
import { getChartPointLabel, getPointerLabel, groupByPeriod, PERIODS, PeriodsType } from "lib/chart-utils";
import React, { useState } from "react";
import { Button } from "rsuite";
import { getFormatedDate } from "lib/utils";
import { Colors } from "config/colors";
import { useCurrency } from "hooks/useCurrency";
import { Series } from "highcharts";

interface IProps {
    dp: IDataProvider;
}

const PageViewChartWidget: FCC<IProps> = props => {
    const [period, setPeriod] = useState<PeriodsType>(PERIODS[0]);
    const title = t("view_count");
    const currency = useCurrency();

    const getSeries = (_dp: IDataProvider, i: number) => {
        const data = groupByPeriod(period, _dp.data);
        return {
            type: "areaspline",
            name: t("view"),
            // yAxis: i as any,
            xAxis: i as any,

            data: data.map(d => ({
                y: d["count"],
                x: new Date(d.date)?.getTime(),
                name: getChartPointLabel(d, period),
            })),
        } as Series;
    };

    const buttons = (
        <div style={{ alignSelf: "left" }}>
            {PERIODS.map(p => (
                <Button
                    key={p}
                    color={p === period ? "blue" : undefined}
                    size="xs"
                    className="mr-3"
                    onClick={() => setPeriod(p)}
                >
                    {t(p)}
                </Button>
            ))}
        </div>
    );

    return (
        <Widget title={title} headerButtons={buttons} className="mb-3">
            <CChart
                series={[getSeries(props.dp, 0)]}
                options={{
                    colors: [Colors.BrightBlue],
                    tooltip: {
                        useHTML: true,
                        headerFormat: "<small>{point.key}</small><table>",
                        pointFormatter() {
                            return getPointerLabel(this, "", currency);
                        },
                        footerFormat: "</table>",
                    },
                    xAxis: [
                        {
                            type: "datetime",
                            labels: {
                                formatter: data => getFormatedDate(data.value),
                            },
                        },
                        {
                            type: "datetime",
                            visible: false,
                        },
                    ],
                }}
            />
        </Widget>
    );
};

export default PageViewChartWidget;
