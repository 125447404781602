import { CategoryTypeEnum } from "config/constants";
import { makeUseReduxModelData, UseReduxModelDataReturn } from "hooks/data-hooks/useReduxModelData";
import _ from "lodash";
import { GenericDataState } from "redux/features/model/utils";

function extraDataFn(
    _categories: GenericDataState<ICategory>,
    tree: boolean,
    types: CategoryTypeEnum | CategoryTypeEnum[] = CategoryTypeEnum.PRODUCT,
) {
    if (!Array.isArray(types)) {
        types = [types];
    }
    const categoriesArray = _.toArray(_categories.byId)
        .filter(c => types.includes(c.type as CategoryTypeEnum))
        .sort((a, b) => a._lft - b._lft);

    if (tree) {
        const buildTree = (nodes: ICategory[]): ICategory[] => {
            return nodes.map(node => {
                const children = categoriesArray.filter(c => c.parent_id === node.id);

                return {
                    ...node,
                    ...(children.length > 0 && { children: buildTree(children) }),
                };
            });
        };

        return {
            tree: buildTree(categoriesArray.filter(c => c.depth === 0)),
            list: categoriesArray,
        };
    }
    return {
        list: categoriesArray,
    };
}

extraDataFn.getDeps = (conf: any[]) => {
    return [conf[0], conf[1]?.toString()];
};

interface WithTree {
    tree: ICategory[];
}

type WithConditionalTree<IsTree> = IsTree extends false ? EmptyObject : WithTree;
type useMenuCategoriesType = <IsTree extends boolean = false>(
    tree?: IsTree,
    type?: CategoryTypeEnum | CategoryTypeEnum[],
) => UseReduxModelDataReturn<"menuCategories", WithConditionalTree<IsTree>>;

export const useMenuCategories = makeUseReduxModelData("menuCategories", extraDataFn) as useMenuCategoriesType;
