import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { QuickLinks } from "components/Header/QuickLinks";
import { useTitle } from "components/Header/Title";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Button, Message, toaster } from "rsuite";
import CreateButton from "../../../components/DataTable/CreateButton";
import ConfirmModal from "../../../components/Drawers/ConfirmModal";
import { useDrawer } from "components/Drawers/useDrawer";
import NoAccessModal from "../../../components/NoAccessModal";
import { BulkModeTypes, CategoryTypeEnum } from "config/constants";
import { useBulk } from "hooks/useBulk";
import { useLoading } from "hooks/useLoading";
import CategoryBulkUpdateDrawer from "../drawer/CategoryBulkUpdateDrawer";
import { RowDataType } from "rsuite-table/src/@types/common";
import CIcon from "../../../components/CIcon";
import { iClose, iDoubleCheckBox, iEditPencil, iTrash } from "assets/icons/Icons";
import { useAuth } from "providers/AuthProvider/AuthProvider";

const AccountingCategoriesPage: FCC = props => {
    const {
        isBulkModeOn,
        setIsBulkModeOn,
        bulkMode,
        setBulkMode,
        bulkData,
        setBulkData,
        resetBulkState,
        deleteRequest,
    } = useBulk();
    const bulkDeleteModal = useDrawer();
    const bulkUpdateModal = useDrawer();
    const [pageType, setPageType] = useState(CategoryTypeEnum.ACCOUNTING);
    const { isLoading, withLoading } = useLoading();
    const [permissionLabel, setPermissionLabel] = useState("");

    const dp = useDataProvider({
        url: "category",
        useUrl: true,
        filters: { type: ["type", CategoryTypeEnum.ACCOUNTING] },
        params: { tree: -1, productCount: "1" },
        sort: ["created_at", "1"],
        limit: 500,
        localSort: [["position"], ["asc"]],
        refineList: list =>
            list?.map(l => ({
                ...l,
            })),
    });
    const noAccessModal = useDrawer();
    // const auth = useAuth();
    const cm = useColumns<any>("accounting-category", [
        // {
        //     flexGrow: undefined,
        //     width: 40,
        //     key: "arrow",
        //     title: "",
        //     render: (d: any) => ""
        // },
        // {
        //     key: 'image',
        //     flexGrow: 0.5,
        //     verticalAlign: "top",
        //     contentWrapClass: "treeView",
        //     render: (d: any) => <MenuMedia {...d}
        //                                    style={{ marginLeft: (_.isEmpty(d.children) && !_.isNull(d.parent_id)) && 10 }} />
        // },
        {
            key: "id",
            title: "ID",
            hide: true,
            flexGrow: 0.5,
        },
        {
            key: "name",
            align: "left",
            flexGrow: 3,
            render: e => {
                return _.isEmpty(e.children) && !_.isNull(e.parent_id) ? (
                    <span className="pl-3">{e.name}</span>
                ) : (
                    e.name
                );
            },
        },
        // {
        //     key: "hidden", title: t("visibility"), type: "bool", align: "center", render: e =>
        //         !e.hidden ? <Icon icon="check" className="text-success" /> : <Icon icon="close" className="text-danger" />
        // },
        { key: "code", title: t("accounting_code"), getStyle: (d: ITransaction) => ({ marginLeft: "1rem" }) },
        optionsColumn({
            dp,
            edit: "/menu/accounting-categories/edit/:id",
            delete: "category/:id",
            canEdit: "category_edit",
            canDelete: "category_delete",
        }),
    ]);
    const loc = useLocation();

    useEffect(() => {
        if (isBulkModeOn) bulkUpdateModal.setData(bulkData, true);
        // eslint-disable-next-line
    }, [bulkData]);

    useEffect(() => {
        blkExit();
        // eslint-disable-next-line
    }, [loc]);

    const auth = useAuth();

    const [bulkDeleteResult, setBulkDeleteResult] = useState({
        deleted_count: 0,
        could_not_deleted_count: 0,
    });

    const getAllIds = (item: ICategory[], ids: number[]) => {
        item.forEach(c => {
            ids.push(c.id);
            if (c.children?.length) {
                getAllIds(c.children, ids);
            }
        });
    };

    const blkHandleCheck = (item: any) => {
        const id = item.id as never;
        if (_.includes(bulkData, id)) {
            setBulkData(prev => prev.filter(f => f !== id));
        } else {
            setBulkData(prev => [...prev, id]);
        }
    };

    const blkHandleSelectAll = (selected: boolean) => {
        const data: Array<any> = dp.data ?? [];
        let _bulkData = [];
        if (selected) {
            _bulkData = data.map(d => {
                return d.id;
            });
        }

        setBulkData(_bulkData);
    };

    const blkExit = () => resetBulkState();

    /* Bulk delete confirmation action. */
    const onConfirmDelete = () => {
        withLoading(() => {
            return deleteRequest("/category/bulk", bulkData);
        })
            .then(response => {
                setBulkDeleteResult(response.data);
                resetBulkState();
                bulkDeleteModal.onClose();
                dp.revalidate();
                toaster.push(
                    <Message type="success" showIcon closable>
                        {" "}
                        <TText tkey="success" />
                    </Message>,
                );
            })
            .catch(() => {
                bulkDeleteModal.onClose();
            });
    };

    const blkPicker = (m: any) => {
        setBulkMode(m);
        setIsBulkModeOn(true);
    };

    const blkApplyHandler = (mode: string) => {
        const permission = mode === BulkModeTypes.DELETE ? "category_delete" : "category_edit";
        if (!auth.hasAbility([permission])) {
            setPermissionLabel(permission);
            noAccessModal.setDataId(1);
            return;
        }
        if (mode === BulkModeTypes.UPDATE) {
            bulkUpdateModal.setDataId(1);
        } else if (mode === BulkModeTypes.DELETE) {
            bulkDeleteModal.setDataId(1);
        }
    };

    return (
        <div className="h-100">
            {useTitle(t("category"), dp.total)}

            <QuickLinks
                links={[
                    {
                        link: '/menu/categories?{"page":1,"sort":["created_at","1"],"limit":500,"filters":{"type":["type","PRODUCT"]},"params":{"tree":-1,"productCount":"1"}}',
                        label: t("menu_categories"),
                        active: pageType === CategoryTypeEnum.PRODUCT,
                    },
                    {
                        link: '/menu/ingredientCategories?{"page":1,"sort":["created_at","1"],"limit":500,"filters":{"type":["type","INGREDIENT"]},"params":{"tree":-1,"productCount":"1"}}',
                        label: t("ingredient_categories"),
                        active: pageType === CategoryTypeEnum.INGREDIENT,
                    },
                    {
                        link: '/menu/accounting-categories?{"page":1,"sort":["created_at","1"],"limit":500,"filters":{"type":["type","ACCOUNTING"]},"params":{"tree":-1,"productCount":"1"}}',
                        label: t("accounting_categories"),
                        active: pageType === CategoryTypeEnum.ACCOUNTING,
                        testId: "accounting-categories",
                    },
                ]}
            />

            <HeaderRight cm={cm} print reload dp={dp} />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    {!bulkMode && (
                        <Button className="multiple-select-button" ripple={false} onClick={() => blkPicker("true")}>
                            <CIcon path={iDoubleCheckBox} />
                            <span>
                                <TText tkey="multiple_select" />
                            </span>
                        </Button>
                    )}
                    {bulkMode && (
                        <div className="bulk-mode">
                            <span className="badge">{bulkData.length}</span>
                            <div onClick={() => blkExit()} className="d-flex">
                                <CIcon path={iClose} />
                                <span className="cancel-button">
                                    <TText tkey="cancel" />
                                </span>
                            </div>
                        </div>
                    )}
                    <SearchFilter fields="name" />
                    {bulkData.length > 0 && (
                        <div className="bulk-acton-buttons">
                            <Button
                                ripple={false}
                                className="edit"
                                onClick={() => blkApplyHandler(BulkModeTypes.UPDATE)}
                            >
                                <CIcon path={iEditPencil} />
                                <TText tkey="change_set" />
                            </Button>
                            <Button
                                ripple={false}
                                className="delete"
                                onClick={() => blkApplyHandler(BulkModeTypes.DELETE)}
                            >
                                <CIcon path={iTrash} />
                                <TText tkey="delete" />
                            </Button>
                        </div>
                    )}
                </div>
                <CreateButton to={"/menu/accounting-categories/create/"} canCreate={"category_create"} />
            </FiltersContainer>
            <div className="categories_db-container">
                <DataTable
                    dp={dp}
                    columns={cm.columns}
                    tableProps={{
                        isTree: true,
                        renderTreeToggle: (icon: React.ReactNode, rowData?: RowDataType) => {
                            return rowData?.children?.length && rowData?.children?.length > 0 ? icon : null;
                        },
                    }}
                    isBulkMode={isBulkModeOn}
                    bulkData={bulkData}
                    setBulkData={blkHandleCheck}
                    setAllBulkData={blkHandleSelectAll}
                />
            </div>
            <CategoryBulkUpdateDrawer
                {...bulkUpdateModal}
                onSubmit={() => {
                    dp.revalidate();
                    blkExit();
                    bulkUpdateModal.hide();
                }}
            />
            <ConfirmModal {...bulkDeleteModal} isLoading={isLoading} onConfirm={onConfirmDelete} />
            <NoAccessModal modal={noAccessModal} permission={[permissionLabel]} />
        </div>
    );
};

export default AccountingCategoriesPage;
