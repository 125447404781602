import { selectSetting } from "../redux/features/settings";
import { store } from "../redux/store";

const roundingAlgorithms = {
    ROUND_HALF_UP: "halfCeil",
    ROUND_HALF_EVEN: "halfEven",
    ALWAYS_ROUND_UP: "ceil",
    ALWAYS_ROUND_DOWN: "floor",
} as const;

const shouldShowTrailingZeros = {
    SHOW_ZERO: true,
    HIDE_ZERO: false,
} as const;

const locales = {
    COMMA_DOT: "en-US",
    DOT_COMMA: "az-AZ",
    SPACE_COMMA: "ru-RU",
    SPACE_DOT: "khq-ML",
    APOSTROPHE_DOT: "de-CH",
} as const;

export const moneyFormatter = (price: number | string | undefined) => {
    const state = store.getState();
    const moneyFormatSettings = selectSetting("money_format")(state).value;
    const precisionSetting = selectSetting("precision")(state).value;
    const roundingSettings = selectSetting("rounding")(state).value;
    const trailingZeroSettings = selectSetting("money_fraction")(state).value;

    return Intl.NumberFormat(locales[moneyFormatSettings], {
        // @ts-ignore
        roundingMode: roundingAlgorithms[roundingSettings],
        maximumFractionDigits: precisionSetting,
        minimumFractionDigits: shouldShowTrailingZeros[trailingZeroSettings] ? precisionSetting : 0,
    }).format(Number(price));
};
