import { EmptyObject } from "@reduxjs/toolkit";
import { useEffect, useMemo } from "react";
import * as slices from "redux/features/model/slice";
import { AppDispatch, store } from "redux/store";
import { useAppDispatch, useAppSelector } from "../useRedux";

// Utility type to extract parameters except for the first one
type Tail<T extends any[]> = T extends [any, ...infer Rest] ? Rest : never;

type ExtractState<T> = Omit<T, keyof EmptyObject>;
type CleanModelType = ExtractState<RootState["model"]>;
type ModelKey = keyof CleanModelType;

export type UseReduxModelDataReturn<K extends ModelKey, T> = CleanModelType[K] & {
    list: Array<CleanModelType[K]["byId"]["string"]>;
    revalidate: () => void;
} & T;

type UseReduxReturnType<K extends ModelKey> = {
    list: Array<RootState["model"][K]["byId"]["string"]>;
    revalidate: () => void;
};

export type ExtendedFn = {
    getDeps?: (config: any[]) => any[];
};

export const makeUseReduxModelData =
    <Fn extends ExtendedFn & ((d: CleanModelType[K], ...config: any[]) => R), K extends ModelKey, R>(
        slice: K,
        extraDataFn: Fn,
    ) =>
    (...config: Tail<Parameters<Fn>>) => {
        const dispatch = useAppDispatch();
        const data = useAppSelector(state => state.model[slice]);

        useEffect(() => {
            void fetchReduxData(dispatch, slice);
        }, [dispatch]);

        const deps = extraDataFn.getDeps?.(config) ?? config;

        return useMemo(() => {
            return {
                ...(data as RootState["model"][K]),
                list: data?.ids.map(id => data.byId[id]) ?? [],
                ...extraDataFn?.(data, ...config),
                revalidate: () => fetchReduxData(dispatch, slice),
            } as RootState["model"][K] & UseReduxReturnType<K> & ReturnType<Fn>;
        }, [data, data.status, ...deps]);
    };

async function fetchReduxData(dispatch: AppDispatch, sliceName: ModelKey) {
    const state = store.getState().model[sliceName];
    if (state?.status !== "loading") {
        const slice = getSlice(sliceName);
        await dispatch((slice.fetchData as any)(""));
    }
}

type Slices = Omit<typeof slices, "default">;

function getSlice<T extends ModelKey>(sliceName: T): Slices[`${T}Slice`] {
    const slice = slices[`${sliceName}Slice`];

    if (!slice) {
        throw new Error(`Slice ${sliceName} not found`);
    }
    return slice;
}
