interface IProps {
    color?: string;
}

export const Checkbox: FCC<IProps> = ({ color }) => (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M1.45832 2.99999L3.81665 5.35833L8.54166 0.641663"
            stroke={color || "#5258F1"}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);
