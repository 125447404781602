import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import UploaderFormGroup from "components/Form/FileUploadFormGroup";
import SelectFormGroup from "components/Form/SelectFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import _ from "lodash";
import TagPickerFormGroup from "components/Form/TagPickerFormGroup";
import { Form, IconButton, Tag, TagGroup } from "rsuite";
import { ReasonType } from "config/constants";
import { useData } from "hooks/useData";
import { iMore } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import { useAppSelector } from "hooks/useRedux";
import { selectSettingOptionsOf, selectSettings } from "redux/features/settings";
import CInputPhoneFormGroup from "components/Form/CInputPhoneFormGroup";
import { Colors } from "config/colors";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import { UseSettingsFormReturnType } from "../useSettingsForm";
import { DayHourValue } from "components/Drawers/WorkTimeDrawer";
import { SocialNetworkList } from "components/Drawers/SocialNetworksDrawer";

interface IProps {
    form: UseSettingsFormReturnType;
    reasonsDrawer: any;
    workTimeDrawer: any;
    socialNetworksDrawer: any;
}

const BrandSettingsTab: FCC<IProps> = props => {
    const { getValues } = useCFormContext();
    const settings = useAppSelector(selectSettings);
    const names = _.filter(settings, s => s?.group === "print_address")?.map(s => s.name);
    const brandType: string = useSetting("brand_type")?.value;
    const workHours: DayHourValue[] = useSetting("brand_work_hours")?.value;
    const timezoneExtra = useSetting("timezone")?.extra;
    const socialNetworks: SocialNetworkList[] = useSetting("brand_social_networks")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;
    const translateForLangs = useSetting("language")?.extra;
    const reasons = useData<any[]>("reasons", {
        filters: [["type", ReasonType.delete]],
    });

    const autoUpdate = async () => {
        props.form.form.setValue("brand_logo.value", props.form.form.getValues("brand_logo.value"), {
            shouldDirty: true,
        });
        await props.form.onSave();
    };

    const tags = (reasons.data ?? []).map(reason => ({ label: reason.name, value: reason.name }));

    const workTimes = Array.isArray(workHours)
        ? workHours
              .filter(dv => dv.fullTime || (dv.open && dv.close))
              .map(wt => ({
                  label: t(wt?.name as LangKey),
                  value: t(wt?.name as LangKey),
              }))
        : [];

    const socialNetworksList = Array.isArray(socialNetworks)
        ? socialNetworks
              .filter(sn => sn?.value !== "")
              .map(sn => ({
                  label: sn?.name,
                  value: sn?.value,
              }))
        : [];

    const currencyOptions = useAppSelector(s => selectSettingOptionsOf(s, "currency"));
    const languageOptions = useAppSelector(s => selectSettingOptionsOf(s, "language"));
    const cashRestartOptions = useAppSelector(s => selectSettingOptionsOf(s, "cash_restart"));
    const timezoneOptions = useAppSelector(s => selectSettingOptionsOf(s, "timezone")).map(option => ({
        name: timezoneExtra[option.name || -1] || option.name,
        id: option.id,
    }));

    return (
        <div className="p-3">
            <TextFormGroup name="brand_name.value" />
            <TextFormGroup name="tin.value" label={t("tin")} compProps={{ placeholder: t("tin") }} />
            <UploaderFormGroup
                name="brand_logo.value"
                compProps={{
                    action: "media",
                    multiple: false,
                    listType: "picture",
                }}
                aspectRatio={16 / 16}
                onRemove={autoUpdate}
                afterUpload={autoUpdate}
            />
            <TextFormGroup
                name="brand_wifi_info.value.name"
                label={t("brand_wifi_info") + ` (${t("name")})`}
                compProps={{ placeholder: t("name") }}
            />
            <TextFormGroup
                name="brand_wifi_info.value.password"
                label={t("brand_wifi_info") + ` (${t("password")})`}
                compProps={{ placeholder: t("password") }}
            />
            <SelectFormGroup name="cash_restart.value" data={cashRestartOptions} />
            <SelectFormGroup name="timezone.value" data={timezoneOptions} />
            <SelectFormGroup
                name="language.value"
                data={languageOptions}
                compProps={{
                    renderMenuItem: (item: any) => <span>{translateForLangs[item]}</span>,
                    renderValue: (value: any) => <span>{translateForLangs[value]}</span>,
                }}
            />
            <SelectFormGroup name="currency.value" data={currencyOptions} />
            <TextFormGroup label={t("location_map_link")} name="location.value.map_link" />
            <div className="d-flex pb-2">
                <span className="d-flex" style={{ minWidth: 292, paddingLeft: 205 }}>
                    <TText tkey="latitude" />
                </span>
                <span style={{ paddingLeft: 90 }}>
                    <TText tkey="longitude" />
                </span>
            </div>
            <div className={"tw-flex tw-gap-3"}>
                <TextFormGroup
                    type={"number"}
                    label={t("coordinates")}
                    name="location.value.coordinates.lat"
                    compProps={{ style: { maxWidth: 144 } }}
                />
                <TextFormGroup
                    hideLabel={true}
                    type={"number"}
                    name="location.value.coordinates.lon"
                    compProps={{ style: { maxWidth: 144 } }}
                />
            </div>
            <Form.Group>
                <div>
                    {names.map((name, index) => {
                        return name === "address_phone" ? (
                            <CInputPhoneFormGroup
                                name={`address_phone.value`}
                                key={index}
                                inputStyle={{
                                    border: "1px solid var(--rs-border-primary)",
                                    fontSize: 14,
                                    color: Colors.DarkGray,
                                }}
                                controlWrapperProps={{
                                    style: { width: 300 },
                                }}
                                label={t("address_phone")}
                            />
                        ) : (
                            <TextFormGroup key={name} name={name + ".value"} />
                        );
                    })}
                </div>
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel>
                    <TText tkey="brand_social_networks" />
                </Form.ControlLabel>
                <div className="d-flex">
                    <TagGroup className="tag-readonly">
                        {socialNetworksList.slice(0, 3).map((sn: any, i) => (
                            <Tag key={i}>{sn.label || t("other")}</Tag>
                        ))}
                    </TagGroup>
                    <IconButton onClick={() => props.socialNetworksDrawer.setDataId(1)} icon={<CIcon path={iMore} />} />
                </div>
            </Form.Group>
            <Form.Group>
                <Form.ControlLabel>
                    <TText tkey="work_hours" />
                </Form.ControlLabel>
                <div className="d-flex">
                    <TagGroup className="tag-readonly">
                        {workTimes.slice(0, 3).map((t: any, i) => (
                            <Tag key={i}>{t.value}</Tag>
                        ))}
                    </TagGroup>
                    <IconButton onClick={() => props.workTimeDrawer.setDataId(1)} icon={<CIcon path={iMore} />} />
                </div>
            </Form.Group>
            {isRestaurant && (
                <TagPickerFormGroup
                    name="cooking_tag.temp_value"
                    label={t("cooking_tag")}
                    compProps={{
                        placement: "autoVertical",
                    }}
                    data={getValues("cooking_tag.temp_value")?.map((v: string) => ({
                        id: v,
                        name: v,
                    }))}
                    creatable={true}
                />
            )}
            <Form.Group>
                <Form.ControlLabel>
                    <TText tkey="receipt_product_delete_reasons" />
                </Form.ControlLabel>
                <div className="d-flex">
                    <TagGroup className="tag-readonly">
                        {tags
                            .map((tag: any) => tag.value)
                            .slice(0, 3)
                            .map((t: string, i: number) => (
                                <Tag key={i}>{t}</Tag>
                            ))}
                    </TagGroup>
                    <IconButton onClick={() => props.reasonsDrawer.setDataId(1)} icon={<CIcon path={iMore} />} />
                </div>
            </Form.Group>
        </div>
    );
};

export default BrandSettingsTab;
