import { useEffect } from "react";
import { t } from "lib/i18n";
import CForm from "components/Form/CForm/CForm";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import TabsView from "components/TabsView";
import JsonView from "components/JsonView";

import ReceiptSettingsTab from "pages/settings/receipt/ReceiptSettingsTab";
import { IReceiptItem } from "pages/settings/receipt/types";
import { useSettingsForm } from "pages/settings/useSettingsForm";

import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useSetting } from "hooks/useSetting";

import { receiptConstructorItems, setReceiptItems } from "redux/features/receiptConstructor";
import { setStationReceiptItems } from "redux/features/stationPrintConstructor";

import StationReceiptEditor from "./StationReceiptEditor/StationReceiptEditor";
import ReceiptSettingsSuspicionTab from "./ReceiptSettingsSuspicionTab";
import ReceiptEditor from "./ReceiptEditor/ReceiptEditor";

const receiptPageFields = [
    "receipt_language",
    "suspicion_action",
    "print_double",
    "station_print_config",
    "receipt_print_config",
    "generate_receipt_number",
];

const ReceiptSettingsPage: FCC = () => {
    const form = useSettingsForm({
        selects: receiptPageFields,
    });
    const dispatch = useAppDispatch();
    const receiptChangeCount = useAppSelector(state => state.receipt.changeCount);
    const receiptItems = useAppSelector(state => state.receipt.items);

    const stationPrintChangeCount = useAppSelector(state => state.stationPrint.changeCount);
    const stationPrintItems = useAppSelector(state => state.stationPrint.items);

    const receiptSetting = useSetting("receipt_print_config");
    const stationReceiptSetting = useSetting("station_print_config");

    const tabsForReceiptSetting = [
        { label: t("receipt"), Component: ReceiptSettingsTab },
        { label: t("actions"), Component: ReceiptSettingsSuspicionTab },
        { label: t("receipt_constructor"), Component: ReceiptEditor },
        { label: t("station_constructor"), Component: StationReceiptEditor },
    ];
    useEffect(() => {
        if (receiptChangeCount) {
            form.form.setValue("receipt_print_config.value", receiptItems, { shouldDirty: true });
        }
    }, [receiptChangeCount]);

    useEffect(() => {
        if (stationPrintChangeCount) {
            form.form.setValue("station_print_config.value", stationPrintItems, { shouldDirty: true });
        }
    }, [stationPrintChangeCount]);

    useEffect(() => {
        if (receiptSetting?.value.length) {
            dispatch(
                setReceiptItems({
                    /**
                     * TODO: read this and complete mission
                     *
                     * Demeli, burda bele bir mesele var:
                     * Kohne mushterilerimizin datalari itmemelidir
                     * Yeni field elave olunubsa gelib merge olunmalidir
                     *
                     * Ashagidaki kod bu caseleri handle edir. Ilk once userin datalarini getirir arraya spread edir,
                     * daha sonra hemin arrayde olmayan yeni fieldleri type-a gore tapib getirib atir arraya.
                     *
                     * Problem bu halda cixir ki, bizde bezi fieldler bir nece defe istifade oluna bilir: line, textarea, space.
                     * Eger biz initialstate-e bu fieldlerden her hansisa birini yeniden add elesek omit olunacaq cunki artiq mushterinin data-
                     * sinda var. Buna hell yolu dushunmek lazimdir, amma tecili task oldugu ucun pass kecirem.
                     */
                    items: [
                        ...receiptSetting.value,
                        ...receiptConstructorItems.filter(
                            item => !receiptSetting.value.some((j: IReceiptItem) => j.type === item.type),
                        ),
                    ],
                }),
            );
        }

        // TODO: bura da union tetbiq edilmelidi, amma heleki yeni field elave olunmadigi ucun etmedim.
        if (stationReceiptSetting?.value.length) {
            dispatch(
                setStationReceiptItems({
                    items: stationReceiptSetting.value,
                }),
            );
        }
    }, []);

    return (
        <div className="h-100 p-2">
            {useTitle(t("receipts"))}
            <HeaderRight formSubmit={form} />
            <CForm {...form}>
                <TabsView tabs={tabsForReceiptSetting} />
                <JsonView />
            </CForm>
        </div>
    );
};

export default ReceiptSettingsPage;
