import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import Money from "components/mini/Money";
import OperationProcessIndicator from "components/OperationProcessIndicator";
import { OperationType } from "config/constants";
import React, { useState } from "react";
import OperationProductsCell from "../misc/OperationProductsCell";
import StockOperationsDrawer from "../misc/StockOperationsDrawer";
import CreateButton from "components/DataTable/CreateButton";
import { exportFile } from "lib/export-table";
import Ajax from "lib/Ajax";
import { iDownload } from "assets/icons/Icons";
import ExportModal from "components/Header/Export/ExportModal";
import LearnButton from "components/DataTable/LearnButton";
import { SelectStorageFilter } from "components/adhoc-filters/SelectStorageFilter";
import { QuickLinks } from "components/Header/QuickLinks";

const ManufacturesPage: FCC = () => {
    const url = "operation";
    const [op, setOp] = useState<IOperation | any>();
    const [itemDetails, setItemDetails] = useState<any>(null);

    const dp = useDataProvider({
        url,
        useUrl: true,
        useDates: true,
        with: ["products_label", "storage", "total_cost"],
        params: {
            // trash: [["stock_operations.product", 2]],
            sort: [
                ["operated_at", "-1"],
                // ["product_id", "-1"],
            ],
        },

        filters: {
            type: ["type", OperationType.MANUFACTURE],
        },
    });
    const drawer = useDrawer({ backdrop: false, overflowAuto: true });
    const exportDrw = useDrawer();
    const cm = useColumns<any>("operation/manufactures", [
        {
            key: "id",
            isFixed: true,
            align: "left",
            width: 70,
            testId: "manufacture-id",
            flexGrow: undefined,
            title: t("data_id"),
            onClick: (d: IOperation) => {
                const stock_operations = d?.stock_operations?.filter((sP: any) => !sP?.parent_id);
                setOp({ ...d, stock_operations, all_operations: d?.stock_operations });
                drawer.setDataId(d.id);
            },
        },
        {
            key: "products_label",
            title: t("products"),
            Comp: OperationProductsCell,
            flexGrow: 3,
        },
        {
            key: "operated_at",
            type: "datetime",
            showSeconds: true,
            multiline: true,
        },
        {
            key: "total_cost",
            type: "money",
            render: e => <Money testId={"manufacture-item-total_cost"} value={e.total_cost} symbol={false} />,
        },
        {
            key: "inventory_status",
            isFixed: true,
            render: e => <OperationProcessIndicator status={e.inventory_status} />,
        },
        { key: "storage.name", title: t("storage") },
        optionsColumn({
            dp,
            edit: "/inventory/stock-preparations/edit/:id",
            delete: "operation/:id",
            preventDelete: d => !!d.is_system,
            editable: d => !!d.is_system,
            canEdit: "stock_manage_preparation",
            canDelete: "stock_manage_preparation",
            others: d => [
                {
                    label: t("export_btn"),
                    icon: iDownload,
                    onClick: d => {
                        exportDrw.setIsOpen?.(true);
                        setItemDetails(d);
                    },
                },
            ],
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("stock_preparations"), dp.total)}
            <QuickLinks
                links={[
                    {
                        link: "/inventory/stock-preparations",
                        label: t("general"),
                        active: true,
                    },
                    {
                        link: "/inventory/stock-preparations/detailed",
                        label: t("details"),
                    },
                ]}
            />
            <HeaderRight
                export={() => exportFile(dp, cm.columns, t("stock_preparations"))}
                dp={dp}
                cm={cm}
                date
                reload
                print
            />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="productSearch" placeholder={t("product")} />
                    <SelectStorageFilter multi />
                    {/* <RemoteCheckListFilter
                        remote="storage"
                        fields="storage_id"
                        searchField="name"
                        pickerProps={{
                            placeholder: t("storage"),
                            renderMenuItem: (label: any, item: any) => (
                                <StorageCheckListItem label={label} storage={item} />
                            ),
                        }}
                    /> */}
                </div>

                <LearnButton page="stock_preparations" />

                <CreateButton to={"/inventory/stock-preparations/create"} canCreate="stock_manage_preparation" />
            </FiltersContainer>
            <StockOperationsDrawer
                {...drawer}
                op={op}
                with={["stock_operations.product.unit", "stock_operations.unit", "stock_operations.storage"]}
                showDeductedTab
                title={t("stock_preparations") + " #" + drawer.dataId}
                tabTitle={t("products")}
                cols={["product.name", "quantity", "cost", "total_cost"]}
            />
            <DataTable dp={dp} columns={cm.columns} />
            {itemDetails?.id && exportDrw.isOpen && (
                <ExportModal
                    exportOptions={[
                        {
                            name: `${t("stock_preparations")} - ${itemDetails.id}`,
                            onClick: () =>
                                Ajax.download(
                                    { url: `operation/${itemDetails.id}/invoice` },
                                    `${t("stock_preparations")} - ${itemDetails.id}`,
                                ),
                        },
                    ]}
                    {...exportDrw}
                />
            )}
        </div>
    );
};

export default ManufacturesPage;
