import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React, { FC } from "react";
import "./Settings.scss";
import CFormGroup from "../../../components/Form/CFormGroup";
import { CRadioTile } from "components/RadioTile/CRadioTile";
import { IMenuCustomizationTabProps } from "pages/qr-menu/settings/MenuCustomizationTab";

const enum CategoryPageLayoutType {
    OFF = "off",
    LIST = "list",
    GRID = "grid",
}

const categoryPageLayouts = [
    {
        value: CategoryPageLayoutType.GRID,
        label: "category_page_grid",
        imageSrc: "/img/qr-menu-layouts/category-layout-grid.svg",
    },
    {
        value: CategoryPageLayoutType.LIST,
        label: "category_page_list",
        imageSrc: "/img/qr-menu-layouts/category-layout-list.svg",
    },
    {
        value: CategoryPageLayoutType.OFF,
        label: "category_page_off",
        imageSrc: "/img/qr-menu-layouts/category-layout-off.svg",
    },
] as const;

export const CategoryPageLayout: FC<IMenuCustomizationTabProps> = ({ form }) => {
    const currentTemplate = form.form.watch("payload.meta.menu_layout.category_page");

    if (!currentTemplate) {
        form.form.setValue("payload.meta.menu_layout.category_page", CategoryPageLayoutType.OFF);
    }

    return (
        <>
            <div className="page-title">
                <TText tkey="category_page" />
            </div>
            <CFormGroup
                hideLabel={true}
                name="payload.meta.menu_layout.category_page"
                comp={CRadioTile}
                compProps={{
                    options: categoryPageLayouts.map(o => ({
                        ...o,
                        label: t(o.label),
                    })),
                }}
            />
        </>
    );
};
