import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React from "react";

import { SelectPicker } from "rsuite";
import { useAppDispatch } from "hooks/useRedux";
import { changeKeyReceiptItemByType } from "redux/features/receiptConstructor";
import { IReceiptItem } from "../../types";
import { fontSizeOptions } from "../utils";
import { handleChangeTextSizeAC } from "redux/features/constructor/constructor-thunks";

const subtotalTypes = [
    {
        value: "subtotal",
        label: t("price_without_tax"),
    },
    {
        value: "total",
        label: t("price_with_tax"),
    },
    {
        value: "subtotal+tax+gift",
        label: t("price_with_tax_and_gift"),
    },
];
const RSubtotalComp = (item: IReceiptItem) => {
    const dispatch = useAppDispatch();

    const handleChangeSubtotal = (value: string | undefined) => {
        if (value) {
            dispatch(changeKeyReceiptItemByType({ item, value }));
        }
    };

    return (
        <div className="d-flex justify-content-between align-items-center w-100">
            <div>
                <TText tkey="subtotal" />
            </div>

            <div className="d-flex mr-2">
                <SelectPicker
                    className="mr-3"
                    style={{ width: "147px" }}
                    cleanable={false}
                    searchable={false}
                    data={subtotalTypes}
                    defaultValue={item.value}
                    onSelect={handleChangeSubtotal}
                    size="sm"
                />
                <SelectPicker
                    cleanable={false}
                    onSelect={v => dispatch(handleChangeTextSizeAC(v, item))}
                    value={item.textSize}
                    data={fontSizeOptions}
                    size="sm"
                    searchable={false}
                />
            </div>
        </div>
    );
};

export default RSubtotalComp;
