import { t } from "lib/i18n";
import DataTable from "../../../components/DataTable/DataTable";
import { useData } from "hooks/useData";
import Qty from "../../../components/mini/Qty";
import _ from "lodash";
import { iSpinner } from "assets/icons/Icons";
import CIcon from "../../../components/CIcon";

interface IProps {
    op?: IProduct;
    activeStorages: number[];
}

type ProductStorageInfo = {
    id: number;
    storageID: number;
    storageName: string;
    children: {
        product_id: number;
        value: number;
        updated_at: string;
    }[];
};

const generateStorageArray = (data: any[]) => {
    let storages: ProductStorageInfo[] = [];
    for (const x in data) {
        const i = data[x];
        const isExists = storages.find(s => s.storageID === data[x]?.storage?.id);
        if (!isExists)
            storages.push({
                id: i?.id,
                storageID: i?.storage?.id,
                storageName: i?.storage?.name,
                children: [],
            });
    }
    for (const x in data) {
        storages = storages.map(s =>
            s.storageID === data[x]?.storage?.id
                ? {
                      ...s,
                      children: [
                          ...s.children,
                          {
                              product_id: data[x]?.product_id,
                              value: data[x]?.quantity,
                              updated_at: data[x]?.updated_at,
                          },
                      ],
                  }
                : s,
        );
    }
    return storages;
};

const StockGeneral: FCC<IProps> = ({ op }) => {
    const dp = useData(op?.id ? `product/${op.id}` : false, {
        with: ["stocks.storage"],
    });

    const cm: ITableColumnProps<ProductStorageInfo>[] = [
        {
            title: t("storage"),
            key: "storageName",
        },
        {
            title: t("quantity"),
            key: "value",
            render: e =>
                e.children ? <Qty value={parseFloat(_.sumBy(e.children, "value").toFixed(4))} unit={op?.unit} /> : "--",
        },
    ];

    const stocks = dp.data?.stocks;
    const tableData = generateStorageArray(stocks);

    return (
        <div className="d-flex">
            <div className="flex-fill border-left">
                {dp.data ? (
                    <DataTable hidePages noShadow columns={cm} dp={{ data: tableData } as any} />
                ) : (
                    <CIcon path={iSpinner} spin className="m-3" />
                )}
            </div>
        </div>
    );
};

export default StockGeneral;
