import Money from "components/mini/Money";

import Wallet from "./wallet";
import { iDollar, iStarOutline } from "assets/icons/Icons";

interface IProps {
    bonus: number | string;
    cashback_balance: number | string;
    balance: number | string;
}

const CustomerWallet: FCC<IProps> = ({ bonus, balance, cashback_balance }) => {
    return (
        <div className="p-4 d-flex justify-content-around">
            {/*<Wallet value={bonus} name="bonus" icon={iStarOutline} />*/}
            <Wallet value={<Money value={cashback_balance} />} name="cashback" icon={iStarOutline} />
            <Wallet value={<Money value={balance} />} name="balance" icon={iDollar} />
        </div>
    );
};

export default CustomerWallet;
