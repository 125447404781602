import SelectFilter, { ISelectFilterProps } from "components/DataTable/Filters/SelectFilter";
import StorageCheckListItem from "components/DataTable/Filters/StorageCheckListItem";
import { t } from "lib/i18n";
import { useStorages } from "hooks/data-hooks/useStorages";
import { FC } from "react";

interface ISelectStorageFilterProps extends Omit<ISelectFilterProps<any>, "options" | "fields"> {
    showOnlyActive?: boolean;
    showAllVenues?: boolean;
    hideStatus?: boolean;
    refineList?: (list: IStorageWithVenue[]) => IStorageWithVenue[];
    fields?: string;
    multi?: boolean;
}

export const SelectStorageFilter: FC<ISelectStorageFilterProps> = ({
    showOnlyActive,
    showAllVenues,
    hideStatus,
    refineList,
    ...props
}) => {
    const storages = useStorages({ showOnlyActive, showAllVenues, refineList });

    return (
        <SelectFilter
            options={storages.list.map(s => ({
                ...s,
                label: s.name,
                value: s.id,
            }))}
            fields="storage_id"
            searchField="name"
            {...props}
            pickerProps={{
                placeholder: t("storage"),
                cleanable: true,
                // "test-id": "storage_id",
                renderMenuItem: (label: any, item: any) => (
                    <StorageCheckListItem label={label} storage={item} hideStatus={hideStatus} />
                ),
                ...props.pickerProps,
            }}
        />
    );
};
