import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import React, { FC } from "react";
import { getFormatedDate } from "lib/utils";
import { EMPTY_DATA } from "config/main";
import { Constants } from "config/constants";
import { QuickLinks } from "components/Header/QuickLinks";
import CheckBoxFilter from "components/DataTable/Filters/CheckBoxFilter";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";

export const IncentiveFinanceTransactionPage: FC = () => {
    const types: Record<FinanceCategoryType, string> = Constants.financeTypes;

    const dp = useDataProvider({
        url: "finance/transaction",
        useUrl: true,
        useDates: true,
        with: ["user:id,username,first_name", "staff:id,username,first_name"],
        filters: {
            category_id: ["category_id", 30],
        },
    });

    const cm = useColumns<any>("incentive-finance-transactions", [
        {
            key: "id",
        },
        {
            title: t("staff"),
            key: "staff.username",
            minWidth: 170,
        },
        {
            key: "amount",
        },
        {
            key: "before_amount",
        },
        {
            key: "after_amount",
        },
        {
            title: t("username"),
            key: "user.username",
            minWidth: 170,
        },
        {
            key: "type",
            render: (d: IFinanceCategory) => types[d.type],
        },
        {
            key: "description",
        },
        {
            key: "operated_at",
            type: "datetime",
            multiline: true,
            reverse: true,
            align: "center",
            hide: true,
        },
        {
            key: "created_at",
            type: "datetime",
            multiline: true,
            reverse: true,
            align: "center",
            hide: true,
        },
    ]);

    return (
        <div className="h-100">
            {useTitle(t("incentive_programs"))}
            <QuickLinks
                inHeader={false}
                links={[
                    {
                        link: "/staff/incentive-program",
                        label: t("incentive_program"),
                    },
                    {
                        link: "/staff/incentive-program/bonus-reports",
                        label: t("bonus_report"),
                    },
                    {
                        link: `/staff/incentive-program/finance-transactions`,
                        label: t("finance_transaction"),
                        active: true,
                    },
                ]}
            />
            <HeaderRight cm={cm} dp={dp} reload date />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <RemoteCheckListFilter
                        fields="staff_id"
                        remote={{
                            url: "user",
                            refineList: list =>
                                list?.map(item => ({
                                    id: item.id,
                                    name: item.username,
                                })),
                        }}
                        searchLocal={true}
                        pickerProps={{ placeholder: t("staff") }}
                    />

                    <CheckBoxFilter fields="trash" useParams title={t("show_deleted_values")} value="2" />
                </div>
            </FiltersContainer>
            <DataTable dp={dp} columns={cm.columns} />
        </div>
    );
};
