import { RadioGroup, RadioGroupProps } from "rsuite";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import * as _ from "lodash";

type IProps = CFormGroupProps<RadioGroupProps>;

const RadioFormGroup: FCC<IProps> = ({ label, ...props }) => {
    const cprops: any = {
        render: ({ field: { name, value, onChange } }: any) => {
            return (
                <RadioGroup name={name} value={value} {...props.compProps} onChange={(v, c) => onChange(v, c)}>
                    {props.children}
                    {/*{label || getLabel(props.name)}*/}
                </RadioGroup>
            );
        },
        ...props.compProps,
    };

    return <CFormGroup {..._.omit(props, "children")} label={label || false} compProps={cprops} />;
};

export default RadioFormGroup;
