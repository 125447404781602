import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { memo, useEffect } from "react";
import { Button, Notification, toaster } from "rsuite";
import * as _ from "lodash";

import { setOpenProductSelector } from "redux/features/productsSelector/productsSelector";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useProductSelectorContext } from "providers/ProductSelectorProvider";

interface IProps {
    applyItems: (items: IProduct[]) => void;
    storageID?: string | number;
    disabledItems?: number[] | null;
    disabled?: boolean;
}

const InventoryProductSelector: FCC<IProps> = props => {
    const dispatch = useAppDispatch();
    const selectedItemIds = useAppSelector(state => state.productSelector.selectedProductIds);
    const productsById = useAppSelector(state => state.data.products.byId);
    const isOpenProductSelector = useAppSelector(state => state.productSelector.isOpenProductSelector);
    const { updateConfig } = useProductSelectorContext();

    useEffect(() => {
        updateConfig({
            onProductSelectorSave: () => applyChanges(selectedItemIds, props.storageID),
        });
    }, [props.storageID, selectedItemIds]);

    const applyChanges = (selectedItems: number[], storageId?: number | string) => {
        if (_.isNil(storageId) || storageId === "")
            return toaster.push(
                <Notification type="error" header={t("storage_id")} closable>
                    <TText tkey="choose_inventory_check_storage" />
                </Notification>,
            );
        props.applyItems(selectedItems.map(id => productsById[id]));
    };

    const openProductsDrawer = () => {
        if (_.isNil(props?.storageID) || props?.storageID === "")
            return toaster.push(
                <Notification type="info" header={t("storage_id")} closable>
                    <TText tkey="choose_inventory_check_storage" />
                </Notification>,
            );
        dispatch(setOpenProductSelector(!isOpenProductSelector));
    };

    return (
        <Button
            test-id={"add-product-button"}
            disabled={props?.disabled}
            onClick={openProductsDrawer}
            appearance="ghost"
            size="lg"
        >
            <TText tkey="add" />
        </Button>
    );
};

export default memo(InventoryProductSelector);
