import { EMPTY_DATA } from "config/main";
import { Tooltip, Whisper } from "rsuite";
import { format, isValid } from "date-fns";

interface IProps {
    deleted_at: string | null;
    user_delete?: { username: string };
}

const DeletedBy: FCC<IProps> = props => {
    const date = new Date(String(props.deleted_at));

    return (
        <div style={{ marginTop: props.deleted_at ? "-0.6rem" : 0 }}>
            {props.deleted_at ? (
                <Whisper speaker={<Tooltip>{props.user_delete?.username ?? EMPTY_DATA}</Tooltip>}>
                    {isValid(date) ? (
                        <span>
                            <div>{format(date, "HH:mm")}</div>
                            <div className="text-empty text-12">{format(date, "MMMM dd")}</div>
                        </span>
                    ) : (
                        <span>
                            <div>
                                <div className="text-empty">{EMPTY_DATA}</div>
                            </div>
                            <div className="text-empty text-12">
                                <div>{EMPTY_DATA}</div>
                            </div>
                        </span>
                    )}
                </Whisper>
            ) : (
                EMPTY_DATA
            )}
        </div>
    );
};

export default DeletedBy;
