import { t } from "lib/i18n";
import { FC, memo, useState } from "react";
import { addReceiptItem } from "redux/features/receiptConstructor";
import { Dropdown } from "rsuite";
import CIcon from "../../../../components/CIcon";
import { iPlus } from "assets/icons/Icons";
import { IReceiptItem, ReceiptItemType } from "../types";
import { v4 } from "uuid";
import { addStationReceiptItem } from "redux/features/stationPrintConstructor";
import { useAppDispatch } from "hooks/useRedux";

const labelForAddedItem: Record<string, string> = {
    line: t("line"),
    qrCode: t("qr_code"),
    textArea: t("text_area"),
    whiteSpace: t("white_space"),
};
interface IProps {
    receiptType: ReceiptItemType;
}
const AddReceiptItemButton: FC<IProps> = memo(({ receiptType }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dispatch = useAppDispatch();

    const menuItems: Omit<IReceiptItem, "uniqueId">[] = [
        {
            type: "line",
            value: "",
            textSize: 1,
            status: true,
            isCustom: true,
        },
        // {
        //     type: "qrCode",
        //     value: "",
        //     position: items.length + 1,
        //     status: true,
        //     isCustom: true,
        // },
        {
            type: "textArea",
            value: "",
            align: "left",
            textSize: 1,
            status: true,
            isCustom: true,
        },
        {
            type: "whiteSpace",
            value: "",
            status: true,
            isCustom: true,
        },
    ];
    const addReceiptElement = (item: IReceiptItem) => {
        if (receiptType === ReceiptItemType.Receipt) {
            dispatch(addReceiptItem(item));
        }
        if (receiptType === ReceiptItemType.StationReceipt) {
            dispatch(addStationReceiptItem(item));
        }
    };

    return (
        <Dropdown
            title={t("add")}
            icon={<CIcon size={0.5} path={iPlus} />}
            placement="bottomEnd"
            appearance="primary"
            noCaret
            open={isOpen}
            onClose={() => setIsOpen(false)}
            onToggle={() => setIsOpen(!isOpen)}
        >
            {menuItems.map(item => (
                <Dropdown.Item
                    icon={<CIcon size={0.5} path={iPlus} />}
                    key={labelForAddedItem[item.type]}
                    className="d-flex align-items-center"
                    eventKey={item.value}
                    onSelect={() => {
                        addReceiptElement({ ...item, uniqueId: v4() });
                        setIsOpen(false);
                    }}
                >
                    {/*{item.icon} */}
                    <span className="pl-2">{labelForAddedItem[item.type]}</span>
                </Dropdown.Item>
            ))}
        </Dropdown>
    );
});
export default AddReceiptItemButton;
