import { ICDrawerProps } from "components/Drawers/CDrawer";
import { FC } from "react";

export function withDrawer<T extends ICDrawerProps>(Comp: FC<T>) {
    const WithDrawerComp: FC<T> = props => {
        if (!props.isOpen) {
            return null;
        }
        return <Comp {...props} />;
    };

    return WithDrawerComp;
}
