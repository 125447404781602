import { t } from "lib/i18n";
import CForm from "components/Form/CForm/CForm";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import { useCForm } from "hooks/useCForm";
import JsonView from "components/JsonView";
import LoyalityToggleButton from "../../marketing/loyalty/form/LoyaltyToggleButton";
import { useEffect } from "react";
import { dummyIncentiveProgram } from "models/IncentiveProgramModel";
import TextFormGroup from "../../../components/Form/TextFormGroup";
import { IncentiveProgramType } from "./IncentiveProgramType";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { Button } from "rsuite";
import CIcon from "../../../components/CIcon";
import { iChevronRight, iClose } from "assets/icons/Icons";
import { TText } from "components/i18n/TText";
import { cn } from "lib/utils";
import { useProductSelectorContext } from "providers/ProductSelectorProvider";
import {
    setDisabledProductIds,
    setIsSingleSelect,
    setOpenProductSelector,
    setSelectedProducts,
    setShouldShowSelected,
    setShowedTypes,
} from "redux/features/productsSelector/productsSelector";
import { CategoryTypeEnum, ProductType, WeekDays } from "config/constants";
import { IncentiveProgramRadioSelect } from "./IncentiveProgramRadioSelect";
import TextAreaFormGroup from "../../../components/Form/TextAreaFormGroup";
import _ from "lodash";
import { IncentiveProgramSchema } from "form-schemas/IncentiveProgramSchema";
import { useMenuCategories } from "hooks/data-hooks/useMenuCategories";
import DatePickerFormGroup from "../../../components/Form/DatePickerFormGroup";
import CCheckPickerFormGroup from "../../../components/Form/CCheckPickerFormGroup";
import { useUsers } from "hooks/data-hooks/useUsers";
import { useStations } from "hooks/data-hooks/useStations";
import { useSaleTypes } from "hooks/data-hooks/useSaleTypes";
import { useRoles } from "hooks/data-hooks/useRoles";
import CProductsDrawer from "components/Drawers/CProductSelector/CProductsDrawer";
import { useCurrency } from "hooks/useCurrency";
import { CCheckTreePickerFormGroup } from "components/Form/CCheckTreePickerFormGroup";
import { format } from "date-fns";

const labelProps = {
    style: {
        width: "250px",
    },
};

const weekDays: WeekDays[] = [
    WeekDays.MONDAY,
    WeekDays.TUESDAY,
    WeekDays.WEDNESDAY,
    WeekDays.THURSDAY,
    WeekDays.FRIDAY,
    WeekDays.SATURDAY,
    WeekDays.SUNDAY,
];

const now = new Date();
const year = now.getFullYear();
const month = now.getMonth();
const day = now.getDate();

const IncentiveProgramCreateEditPage: FCC = () => {
    const dispatch = useAppDispatch();

    const form = useCForm<IIncentiveProgram>({
        url: "incentive-programs",
        dummyData: dummyIncentiveProgram,
        model2: IncentiveProgramSchema,
        refine: (data: IIncentiveProgram) => {
            if (data.active_hours?.start_time && data.active_hours?.end_time) {
                const startTimes = (data.active_hours.start_time as string).split(":");
                const endTimes = (data.active_hours.end_time as string).split(":");
                data.active_hours.start_time = new Date(0, 0, 0, +startTimes[0], +startTimes[1]);
                data.active_hours.end_time = new Date(0, 0, 0, +endTimes[0], +endTimes[1]);
            }
            if (data?.start_date && data?.end_date) {
                data.active_dates = {
                    start_date: new Date(data.start_date),
                    end_date: new Date(data.end_date),
                };
            }

            return data;
        },
        prepareForSubmit: (d: IIncentiveProgram) => {
            const data = _.cloneDeep(d);

            if (data.active_hours?.start_time) {
                data.active_hours.start_time = format(new Date(data.active_hours.start_time), "HH:mm");
                data.active_hours.end_time = format(new Date(data.active_hours.end_time ?? new Date()), "HH:mm");
            } else {
                data.active_hours = {
                    start_time: null,
                    end_time: null,
                };
            }

            data.active_days = data.active_days?.length ? data.active_days : [WeekDays.ALL];
            data.selected_sale_types = data.selected_sale_types?.length ? data.selected_sale_types : ["*"];

            if (data.active_dates?.start_date && data.active_dates?.end_date) {
                data.start_date = format(new Date(data.active_dates.start_date), "yyyy-MM-dd");
                data.end_date = format(new Date(data.active_dates.end_date), "yyyy-MM-dd");
            } else {
                data.start_date = null;
                data.end_date = null;
            }

            return _.omit(data, ["active_dates"]);
        },
    });

    const currency = useCurrency();
    const productsById = useAppSelector(state => state.data.products.byId);
    const { updateConfig } = useProductSelectorContext();
    const setFormValue = form.form.setValue;
    useEffect(() => {
        updateConfig({
            onProductSelectorSave: (ids: number[]) => {
                setFormValue("excluded_items", ids);
            },
        });
        dispatch(setShowedTypes([ProductType.DISH, ProductType.GOODS, ProductType.TIMER]));
        dispatch(setIsSingleSelect(false));
    }, [updateConfig, setFormValue]);

    const roles = useRoles({ noOwner: true });
    const users = useUsers();
    const stations = useStations();
    const saleTypes = useSaleTypes();
    const categories = useMenuCategories(true, CategoryTypeEnum.PRODUCT);

    const currencyString = t("incentive_program_bonus_rate_info", {
        currency: `5${currency}`,
        currencystatic: `${currency}`,
    });

    const selectedProducts = form.form.watch(`excluded_items`) ?? [];
    const activeDays: WeekDays[] = form.form.watch(`active_days`) ?? [WeekDays.ALL];
    const selectedSaleTypes = (form.form.watch(`selected_sale_types`) ?? []) as (number | string)[];
    const activeDates = form.form.watch(`active_dates`) ?? {
        start_date: null,
        end_date: null,
    };
    const activeHours = form.form.watch(`active_hours`) ?? {
        start_time: null,
        end_time: null,
    };

    useEffect(() => {
        if (saleTypes?.list?.length && !form.isLoading && !!form.id) {
            const selectedSaleTypes = form.form.watch("selected_sale_types") ?? [];
            const types = selectedSaleTypes.filter((id: number) => _.findIndex(saleTypes.list, { id }) > -1);
            form.form.setValue("selected_sale_types", types.length ? types : ["*"]);
        }
    }, [saleTypes?.list, form.isLoading, form.id]);

    return (
        <div className="tw-h-full tw-p-4">
            {useFormTitle(form.id)}
            <HeaderRight formSubmit={form} />

            <CForm form={form.form} onSave={form.onSave}>
                <JsonView />

                <LoyalityToggleButton
                    name="status"
                    label={t("status")}
                    hideLabel
                    labelProps={{
                        className: "py-0",
                    }}
                    labelDescription={t("incentive_program_status_desc")}
                />

                <TextFormGroup
                    errorPlacement="topStart"
                    labelProps={labelProps}
                    name="name"
                    label={t("name")}
                    className="tw-mb-8"
                />

                <IncentiveProgramType />

                <TextFormGroup
                    errorPlacement="topStart"
                    focus={true}
                    labelProps={labelProps}
                    name="criteria.sales_bonus_rate"
                    postFix="%"
                    type="number"
                    label={t("sales_bonus_rate")}
                    className="tw-mb-8"
                    labelDescription={t("sales_bonus_rate_desc")}
                    compProps={{
                        description: currencyString,
                    }}
                />

                <CCheckPickerFormGroup
                    name={"assigned_roles"}
                    placeholder={t("select_roles")}
                    label={t("assigned_roles")}
                    labelKey="title"
                    labelProps={labelProps}
                    labelDescription={t("assigned_roles_desc")}
                    data={roles.list}
                    compProps={{
                        cleanable: true,
                    }}
                />

                <CCheckPickerFormGroup
                    name={"assigned_staff"}
                    placeholder={t("select_staff")}
                    label={t("assigned_staff")}
                    labelKey="username"
                    labelProps={labelProps}
                    labelDescription={t("assigned_staff_desc")}
                    data={users.list}
                    compProps={{
                        cleanable: true,
                    }}
                />

                <IncentiveProgramRadioSelect
                    defaultValue={selectedSaleTypes.includes("*") ? "*" : "specified_sale_types"}
                    inputs={[
                        {
                            value: "*",
                            label: "all_sale_types",
                            fieldValue: ["*"],
                        },
                        {
                            value: "specified_sale_types",
                            label: "specified_sale_types",
                            fieldValue: [],
                            children: (
                                <CCheckPickerFormGroup
                                    name="selected_sale_types"
                                    data={saleTypes.list}
                                    hideLabel={true}
                                    style={{ wordWrap: "break-word" }}
                                    placeholder={t("select", { name: t("sale_types") })}
                                    className="tw-my-3"
                                    compProps={{
                                        cascade: "child",
                                        placement: "bottom",
                                        style: {
                                            maxWidth: 300,
                                            width: 300,
                                        },
                                    }}
                                />
                            ),
                        },
                    ]}
                    label={t("sale_types")}
                    labelDescription={t("incentive_sale_types_desc")}
                    name="selected_sale_types"
                />

                <IncentiveProgramRadioSelect
                    defaultValue={!activeDates.start_date && !activeDates.end_date ? "*" : "specified_date_interval"}
                    inputs={[
                        {
                            value: "*",
                            label: "all_time_active",
                            fieldValue: {
                                start_date: null,
                                end_date: null,
                            },
                        },
                        {
                            value: "specified_date_interval",
                            label: "only_selected_date_interval_active",
                            fieldValue: {
                                start_date: new Date(),
                                end_date: new Date(),
                            },
                            children: (
                                <>
                                    <div>
                                        <div className="tw-mb-1">
                                            <TText tkey={"start_date"} />
                                        </div>
                                        <DatePickerFormGroup
                                            compProps={{
                                                format: "yyyy-MM-dd",
                                                renderValue: v => format(new Date(v), "yyyy-MM-dd"),
                                                className: "tw-w-full",
                                                cleanable: false,
                                            }}
                                            label={false}
                                            className="tw-mb-0"
                                            name="active_dates.start_date"
                                        />
                                    </div>

                                    <div className="tw-grid">
                                        <span className="tw-mx-2 tw-mb-1 tw-h-5"></span>
                                        <span className="tw-mx-2"> - </span>
                                    </div>

                                    <div>
                                        <div className="tw-mb-1">
                                            <TText tkey={"end_date"} />
                                        </div>

                                        <DatePickerFormGroup
                                            compProps={{
                                                format: "yyyy-MM-dd",
                                                renderValue: v => format(new Date(v), "yyyy-MM-dd"),
                                                className: "tw-w-full",
                                                cleanable: false,
                                            }}
                                            label={false}
                                            name="active_dates.end_date"
                                        />
                                    </div>
                                </>
                            ),
                        },
                    ]}
                    label={t("active_date")}
                    labelDescription={t("active_date_desc")}
                    name="active_dates"
                />

                <IncentiveProgramRadioSelect
                    defaultValue={activeDays.includes(WeekDays.ALL) ? WeekDays.ALL : "only_selected_week_days"}
                    inputs={[
                        {
                            value: WeekDays.ALL,
                            label: "all_week_days",
                            fieldValue: [WeekDays.ALL],
                        },
                        {
                            value: "only_selected_week_days",
                            label: "only_selected_week_days",
                            fieldValue: [],
                            children: weekDays.map((weekDay: WeekDays) => (
                                <button
                                    onClick={e => {
                                        e.preventDefault();
                                        const dayIndex = activeDays.findIndex(day => day === weekDay);
                                        if (dayIndex > -1) {
                                            activeDays.splice(dayIndex, 1);
                                            form.form.setValue("active_days", activeDays);
                                        } else {
                                            form.form.setValue("active_days", [...activeDays, weekDay]);
                                        }
                                    }}
                                    className={cn(
                                        `tw-bg-transparent tw-flex tw-py-1 tw-px-2 tw-items-center tw-gap-2.5 tw-rounded tw-border-border tw-border-solid tw-border tw-text-disabled tw-text-sm tw-font-medium`,
                                        {
                                            [`tw-bg-primary tw-text-white`]: activeDays?.includes(weekDay),
                                        },
                                    )}
                                >
                                    <TText tkey={`${weekDay}_full` as LangKey} />
                                </button>
                            )),
                        },
                    ]}
                    name="active_days"
                    label={t("active_days")}
                    labelDescription={t("active_days_desc")}
                />

                <IncentiveProgramRadioSelect
                    defaultValue={!activeHours.start_time && !activeHours.end_time ? "*" : "only_selected_hour"}
                    inputs={[
                        {
                            value: "*",
                            label: "all_hour",
                            fieldValue: {
                                start_time: null,
                                end_time: null,
                            },
                        },
                        {
                            value: "only_selected_hour",
                            label: "only_selected_hour",
                            fieldValue: {
                                start_time: new Date(year, month, day, 0, 0),
                                end_time: new Date(year, month, day, 23, 59),
                            },
                            children: (
                                <>
                                    <div>
                                        <div className="tw-mb-1">
                                            <TText tkey={"start_hour"} />
                                        </div>
                                        <DatePickerFormGroup
                                            compProps={{
                                                format: "HH:mm",
                                                renderValue: v => format(new Date(v), "HH:mm"),
                                                style: { width: 100 },
                                                cleanable: false,
                                            }}
                                            label={false}
                                            className="tw-mb-0"
                                            name="active_hours.start_time"
                                        />
                                    </div>

                                    <div className="tw-grid">
                                        <span className="tw-mx-2 tw-mb-1 tw-h-5"></span>
                                        <span className="tw-mx-2"> - </span>
                                    </div>

                                    <div>
                                        <div className="tw-mb-1">
                                            <TText tkey={"end_hour"} />
                                        </div>

                                        <DatePickerFormGroup
                                            compProps={{
                                                format: "HH:mm",
                                                renderValue: v => format(new Date(v), "HH:mm"),
                                                style: { width: 100 },
                                                cleanable: false,
                                            }}
                                            label={false}
                                            name="active_hours.end_time"
                                        />
                                    </div>
                                </>
                            ),
                        },
                    ]}
                    label={t("active_hours")}
                    labelDescription={t("active_hours_desc")}
                    name="active_hours"
                />

                <CCheckTreePickerFormGroup
                    name="excluded_categories"
                    data={categories.tree}
                    placeholder={t("select_category")}
                    label={t("exclude_categories")}
                    // labelKey="name"
                    labelDescription={t("exclude_categories_desc")}
                    labelProps={labelProps}
                    compProps={{
                        cleanable: true,
                        cascade: "child",
                        style: { width: 300 },
                    }}
                />

                <CCheckPickerFormGroup
                    name="excluded_stations"
                    data={stations.list}
                    className="mb-10"
                    placeholder={t("select_station")}
                    label={t("exclude_station")}
                    labelKey="name"
                    labelDescription={t("exclude_station_desc")}
                    labelProps={labelProps}
                    compProps={{
                        cleanable: true,
                    }}
                />

                <div className="tw-flex tw-p-0 tw-gap-3 tw-flex-col sm:tw-flex-row">
                    <div className="tw-w-[250px]">
                        <div className="tw-mr-3">
                            {t("exclude_products")}

                            <div className="tw-text-secondary tw-text-sm tw-break-words">
                                {t("exclude_products_description")}
                            </div>
                        </div>
                    </div>
                    <div>
                        <div
                            className={cn(
                                "tw-flex tw-relative tw-items-center tw-w-input-lg tw-h-[36px] tw-rounded tw-py-2 tw-pr-6 tw-pl-[11px] tw-cursor-pointer tw-border tw-border-solid tw-border-border tw-transition hover:tw-border-primary",
                                {
                                    "tw-text-disabled": !selectedProducts.length,
                                },
                            )}
                            onClick={() => {
                                dispatch(setShouldShowSelected(false));
                                dispatch(setSelectedProducts(selectedProducts));
                                dispatch(setOpenProductSelector(true));
                            }}
                        >
                            <div className="tw-overflow-hidden tw-overflow-ellipsis tw-whitespace-nowrap">
                                {selectedProducts.length
                                    ? selectedProducts
                                          ?.map((id: number) => {
                                              const product = productsById?.[id];
                                              return product?.full_name;
                                          })
                                          ?.join(",")
                                    : t("select_product")}
                            </div>
                            {selectedProducts.length ? (
                                <div
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        form.form.setValue("excluded_items", []);
                                    }}
                                    className="tw-absolute tw-top-2 tw-right-2.5"
                                >
                                    <CIcon path={iClose} />
                                </div>
                            ) : null}
                        </div>
                        {!!selectedProducts.length && (
                            <Button
                                onClick={() => {
                                    dispatch(setShouldShowSelected(true));
                                    dispatch(setSelectedProducts(selectedProducts));
                                    dispatch(setDisabledProductIds([]));
                                    dispatch(setOpenProductSelector(true));
                                }}
                                loading={false}
                                className="tw-mt-4 tw-bg-accent tw-text-primary"
                                ripple={false}
                                style={{
                                    width: 300,
                                    maxWidth: 300,
                                }}
                            >
                                <TText tkey="selected_products" />
                                <CIcon path={iChevronRight} size={0.4} className="ml-2" />
                            </Button>
                        )}
                    </div>
                </div>

                <TextAreaFormGroup
                    label={t("loyalty_desc_label")}
                    labelProps={labelProps}
                    compProps={{
                        style: { width: 300, height: 100 },
                        placeholder: t("incentive_program_text_description"),
                    }}
                    className="tw-mt-4"
                    name="description"
                />
            </CForm>

            <CProductsDrawer />
        </div>
    );
};

export default IncentiveProgramCreateEditPage;
