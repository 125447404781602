import { ICDrawerProps } from "components/Drawers/CDrawer";
import CModal from "components/Drawers/CModal";
import CProductsDrawer from "components/Drawers/CProductSelector/CProductsDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { CategoryTypeEnum, ProductType } from "config/constants";
import { IHallManager } from "pages/settings/tables/useHallManager";
import { useProductSelectorContext } from "providers/ProductSelectorProvider";
import { useEffect, useState } from "react";
import { Button, Input, Modal } from "rsuite";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import {
    setOpenProductSelector,
    setSelectedProducts,
    setShowedCategoryTypes,
    setShowedTypes,
} from "redux/features/productsSelector/productsSelector";
import { cn } from "lib/utils";
import CIcon from "components/CIcon";
import { iChevronRight, iClose } from "assets/icons/Icons";

interface IProps extends ICDrawerProps {
    table: ITable;
    manager: IHallManager;
}

const TableEditModal: FCC<IProps> = ({ table, manager, ...props }) => {
    const dispatch = useAppDispatch();
    const { updateTable } = manager;
    const { updateConfig } = useProductSelectorContext();

    const [name, setName] = useState<string>(() => table.name || "");
    const [selectedProductIds, setSelectedProductIds] = useState<number[]>(() => {
        return table.meta && table.meta.products ? table.meta.products.map(p => p.id) : [];
    });
    const { byId: productsById, isLoading } = useAppSelector(state => state.data.products);

    const onProductsSelect = (productId: number[]) => {
        setSelectedProductIds(productId);
    };

    const onCancel = () => {
        props.hide();
    };

    const onSave = () => {
        updateTable({
            ...table,
            name,
            meta: {
                ...(table.meta || {}),
                products: selectedProductIds.map(id => ({ id })),
            },
        });

        props.hide();
    };

    useEffect(() => {
        updateConfig({
            onProductSelectorSave: onProductsSelect,
        });

        dispatch(setShowedTypes([ProductType.DISH, ProductType.GOODS, ProductType.TIMER]));
        dispatch(setShowedCategoryTypes([CategoryTypeEnum.PRODUCT]));
    }, [updateConfig]);

    const renderProductNames = () => {
        if (isLoading) {
            return t("loading");
        }

        if (selectedProductIds.length) {
            return selectedProductIds
                .map(id => {
                    const product = productsById?.[id];
                    return product?.full_name;
                })
                ?.join(",");
        }

        return t("select", { name: t("product") });
    };

    return (
        <>
            <CModal {...props} backdrop title={t("add_table")} size="xs">
                <Modal.Body className="table-edit-modal">
                    <Input value={name} onChange={setName} style={{ marginBottom: 10 }} />
                    <div
                        className={cn(
                            "tw-flex tw-relative tw-items-center tw-h-[40px] tw-rounded-md tw-py-2 tw-pr-6 tw-pl-2 tw-cursor-pointer tw-border tw-border-solid tw-border-border tw-transition hover:tw-border-blue-400",
                            { "tw-text-disabled": !selectedProductIds.length },
                        )}
                        onClick={() => {
                            dispatch(setSelectedProducts(selectedProductIds));
                            dispatch(setOpenProductSelector(true));
                        }}
                    >
                        <div className="tw-overflow-hidden tw-whitespace-nowrap tw-text-ellipsis">
                            {renderProductNames()}
                            {!selectedProductIds.length ? (
                                <div className="tw-absolute tw-right-[10px] tw-top-[10px]">
                                    <CIcon path={iChevronRight} />
                                </div>
                            ) : (
                                <div
                                    className="tw-absolute tw-right-[10px] tw-top-[10px]"
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setSelectedProductIds([]);
                                    }}
                                >
                                    <CIcon path={iClose} />
                                </div>
                            )}
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={onCancel} appearance="subtle">
                        <TText tkey="cancel" />
                    </Button>
                    <Button appearance="primary" color="green" onClick={onSave}>
                        <TText tkey="save" />
                    </Button>
                </Modal.Footer>
            </CModal>
            <CProductsDrawer style={{ zIndex: 1055 }} />
        </>
    );
};

export default TableEditModal;
