import { t } from "lib/i18n";
import { useMemo } from "react";
import { InputPicker, InputPickerProps } from "rsuite";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import { getLabel } from "./form-utils";
import { PlainText } from "./PlainText";
import { usePopupContainer } from "hooks/usePopupContainer";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import _ from "lodash";

export interface SelectFormGroupProps extends CFormGroupProps<Partial<InputPickerProps>> {
    data?: any[];
    valueKey?: string;
    labelKey?: string;
    isItemDisabled?: (d: any) => boolean;
    plaintext?: boolean;
    disabled?: boolean;
    width?: number | string;
    hideLabel?: boolean;
    testId?: string;
    style?: object;
}

const SelectFormGroup: FCC<SelectFormGroupProps> = ({
    disabled,
    isItemDisabled,
    data,
    valueKey,
    labelKey,
    compProps,
    width,
    testId,
    style,
    ...props
}) => {
    const { getValues } = useCFormContext();
    const disabledItemValues = useMemo(() => {
        return data?.filter(isItemDisabled ?? _.constant(false)).map(x => x[valueKey ?? "id"]);
    }, [isItemDisabled, data, valueKey]);
    const { container } = usePopupContainer();

    const InputWidth = width ? width : 300;

    const renderMenuItem = (label: any, item: any) => {
        // əgər select-box içində idsini bilmədiyimiz ancaq adını bildiyimiz datanı seçmək istəsək
        // və həmin komponentin öz renderMenuItem funksiyası yoxdursa bunu istifadə edirik
        const _testId = testId ?? props.name;
        return <span test-id={`${_testId}_item`}>{label}</span>;
    };

    const newCompProps = {
        data,
        labelKey,
        valueKey,
        disabled,
        cleanable: false,
        disabledItemValues: disabledItemValues,
        style: { width: InputWidth },
        container: () => container.current!,
        ...compProps,
        "test-id": testId ?? props.name,
        placeholder: t("select", {
            name: props.label !== false ? props.label || getLabel(props.name) : "",
        }),
        renderMenuItem: compProps?.renderMenuItem ?? renderMenuItem,
    };

    const plaintTextValue = data?.find((d: any) => getValues(props.name) === d[newCompProps.valueKey || ""]);

    return props.plaintext ? (
        <PlainText name={props.name} value={plaintTextValue?.[newCompProps.labelKey || ""]} />
    ) : (
        <CFormGroup
            hideLabel={props.hideLabel}
            style={style ?? {}}
            disabled={disabled}
            {...(props as any)}
            compProps={newCompProps}
        />
    );
};

SelectFormGroup.defaultProps = {
    labelKey: "name",
    valueKey: "id",
    data: [],
    comp: InputPicker as any,
};

export default SelectFormGroup;
