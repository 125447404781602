import { IDbReceiptProduct } from "@cloposcom/receipt-utils/src/receipt-calculation/interfaces";
import { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { FC, useEffect, useState } from "react";
import { recalculateAll, recalculateReceipt, updateReceiptItem } from "redux/features/webCashier";
import { changeModalState } from "redux/features/webCashierAdditionals";
import { Button, Modal } from "rsuite";
import ReceiptNumberInput from "../ReceiptItemNumberInput";
import { useCurrency } from "hooks/useCurrency";
import { useCalculationSetting } from "hooks/useCalculationSetting";
import { useAppDispatch } from "hooks/useRedux";

interface IProps extends ICDrawerProps {
    detail: {
        product: IDbReceiptProduct | undefined;
        maxPrice: number;
        index: number;
        open: boolean;
        type: string;
    };
}

const ProductPriceModal: FC<IProps> = ({ detail }) => {
    const { product } = detail;
    const [productPrice, setProductPrice] = useState<any>(product?.price ?? 0);

    const dispatch = useAppDispatch();
    const currency = useCurrency();
    const calculationSetting = useCalculationSetting();

    useEffect(() => {
        setProductPrice(product?.price ?? 0);
    }, [product]);

    const changePrice = () => {
        const _product = { ...product! };
        const _price = typeof productPrice === "string" ? Number(parseFloat(productPrice).toFixed(2)) : productPrice;
        _product.price = _price;
        _product.total = _price * _product.count;
        dispatch(updateReceiptItem(_product!));
        closeModal();
        dispatch(recalculateAll({ setting: calculationSetting, product: product! }));
        dispatch(recalculateReceipt({ setting: calculationSetting }));
    };

    const closeModal = () => {
        dispatch(changeModalState({ ...detail, open: false, title: "" }));
    };

    return (
        <>
            <Modal.Body>
                <ReceiptNumberInput
                    min={0}
                    step={3}
                    inputID={"product_price_"}
                    max={10000}
                    value={productPrice}
                    hideButtons={true}
                    name="product_price"
                    placeholder={t("price")}
                    prefix={currency}
                    onChange={(count: number) => setProductPrice(count as any)}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" color="green" test-id="save-price" onClick={() => changePrice()}>
                    <TText tkey="save" />
                </Button>
            </Modal.Footer>
        </>
    );
};

export default ProductPriceModal;
