import { t } from "lib/i18n";
import { Col } from "rsuite";
import CSection from "../../components/CSection";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import NoAccessWidget from "./NoAccessWidget";
import TopCategoryWidget from "./TopCategoryWidget";
import TopGoodsWidget from "./TopGoodsWidget";
import TopWaitersWidget from "./TopWaitersWidget";

const TopSaleSection: FCC = () => {
    const auth = useAuth();

    return (
        <CSection title={t("most_sales")} rowProps={{ className: "mt-4" }}>
            <Col md={8}>
                {auth.hasAbility(["report_category"]) ? (
                    <TopCategoryWidget />
                ) : (
                    <NoAccessWidget
                        title={t("most_selling_category")}
                        label={"no_permission"}
                        permissions={["report_category"]}
                    />
                )}
            </Col>
            <Col md={8}>
                {auth.hasAbility(["report_product"]) ? (
                    <TopGoodsWidget />
                ) : (
                    <NoAccessWidget
                        title={t("most_selling_products")}
                        label={"no_permission"}
                        permissions={["report_product"]}
                    />
                )}
            </Col>
            <Col md={8}>
                {auth.hasAbility(["report_user"]) ? (
                    <TopWaitersWidget />
                ) : (
                    <NoAccessWidget
                        title={t("most_selling_staff")}
                        label={"no_permission"}
                        permissions={["report_user"]}
                    />
                )}
            </Col>
        </CSection>
    );
};

export default TopSaleSection;
