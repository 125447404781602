import { Col, Row } from "rsuite";
import { StandardProps } from "rsuite/cjs/@types/common";

interface IProps {
    title?: string;
    rowProps?: StandardProps;
}
const CSection: FCC<IProps> = ({ title, rowProps, ...props }) => {
    return (
        <Row {...rowProps}>
            {title ? (
                <Col md={24}>
                    <h4 className="font-weight-lighter">{title}</h4>
                </Col>
            ) : null}
            {props.children}
        </Row>
    );
};

export default CSection;
