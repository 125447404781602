import { useEffect, useRef } from "react";
import { createPortal } from "react-dom";

interface IProps {
    id: string;
    className?: string;
}

const PortalTo: FCC<IProps> = props => {
    const ref = useRef<{ el?: HTMLElement; el2?: HTMLElement }>({});

    useEffect(() => {
        ref.current.el2 = document.createElement("div");
        if (props.className) {
            ref.current.el2.classList.add(props.className);
        }
        ref.current.el = document.getElementById(props.id) ?? undefined;
        ref.current.el?.appendChild(ref.current.el2);
    }, [props.id]);

    if (!ref.current.el2) {
        return null;
    }

    return createPortal(props.children, ref.current.el2);
};

export default PortalTo;
