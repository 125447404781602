import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";

import { useAuth } from "providers/AuthProvider/AuthProvider";
import { isUrl, previewFile } from "lib/utils";
import _ from "lodash";
import { CSSProperties, HTMLAttributes, SyntheticEvent, useEffect, useRef, useState } from "react";
import {
    Button,
    Form,
    FormControlLabelProps,
    FormGroupProps,
    IconButton,
    Loader,
    Message,
    toaster,
    Uploader,
    UploaderProps,
} from "rsuite";
import { getLabel } from "./form-utils";
import { PlainImage } from "./PlainImage";
import CModal from "components/Drawers/CModal";
import { useDrawer } from "components/Drawers/useDrawer";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import Ajax from "lib/Ajax";
import imageCompression from "browser-image-compression";
import { config } from "config/Config";
import CIcon from "../CIcon";
import { iClose, iImage } from "assets/icons/Icons";
import { mdiContentCut } from "@mdi/js";
import { FileType } from "rsuite/Uploader";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

// todo: bu componenti seliqeye salmaq lazimdir

export interface UploaderFormGroupProps<T = UploaderProps> extends FormGroupProps {
    name: string;
    mediaDataName?: string;
    label?: string | false;
    compProps?: T & { "test-id"?: string };
    controlWrapperProps?: HTMLAttributes<HTMLDivElement>;
    tooltip?: string;
    error?: string;
    labelProps?: FormControlLabelProps;
    removeMainKey?: boolean;
    onRemove?: () => void;
    afterUpload?: (data: IMedia) => void;
    buttonStyle?: CSSProperties;
    fileInfoStyle?: CSSProperties; //for change style of file input (TIMER/DISH/GOODS)
    fileInfoButtonsStyle?: CSSProperties; //for change place of fileInfo buttons (close/cut)
    plaintext?: boolean;
    aspectRatio?: number;
    hideActions?: boolean;
    disablePreview?: boolean;
    // control?: Control;
}

const UploaderFormGroup: FCC<UploaderFormGroupProps> = ({
    tooltip,
    name,
    /**
     * mediaDataName is field to specify which field in the form data holds data object of media (IMedia)
     * there was bug in this component as it was assuming there will be only one media file in page
     * otherwise "media" field will be overwritten by the last media file
     */
    mediaDataName = "media",
    label,
    compProps,
    aspectRatio,
    controlWrapperProps,
    labelProps,
    fileInfoButtonsStyle,
    fileInfoStyle,
    buttonStyle,
    removeMainKey,
    hideActions,
    disablePreview,
    ...props
}) => {
    const ctx = useCFormContext();
    const user = useAuth();
    const error = ctx ? (_.get(ctx.formState.errors, "media_file")?.message as string) : props.error;
    const controlProps = _.omit(controlWrapperProps, "className");
    const className = "rs-form-control-wrapper " + (controlWrapperProps?.className ?? "");
    const url = `${config.apiUrl}${compProps?.action}`;
    const image = ctx.getValues(name);
    const [fileInfo, setFileInfo] = useState<string | ArrayBuffer | null>(image || null);
    const [cropFileInfo, setCropFileInfo] = useState<string | ArrayBuffer | null>(image || null);
    const [isUploading, setIsUploading] = useState(false);

    const cropModal = useDrawer();
    const cropperRef = useRef<HTMLImageElement>(null);
    const [isCroppingLoading, setIsCroppingLoading] = useState(false);

    const openCropModal = async () => {
        cropModal.setDataId?.(1);
    };

    const saveCroppedImage = async (e: SyntheticEvent) => {
        setIsCroppingLoading(true);

        const imageElement: any = cropperRef?.current;
        const cropper: any = imageElement?.cropper;
        const dataIMG = await cropper.getCroppedCanvas();

        dataIMG.toBlob(async (blob: any) => {
            const compressed = await imageCompression(blob, { maxSizeMB: 5 });
            const form = new FormData();

            form.append("media_file", compressed);

            const res: any = await Ajax.post({
                url,
                data: form,
            }).catch(() => {
                setIsCroppingLoading(false);
            });

            if (res?.success) {
                ctx.setValue(name, res.data.uuid);
                ctx.setValue(mediaDataName, res.data);

                cropModal.onClose?.(e);
            }

            setIsCroppingLoading(false);
        });
    };

    useEffect(() => {
        if (image) {
            if (isUrl(image)) {
                setFileInfo(image);
            } else if (ctx.getValues(mediaDataName)?.urls) {
                setFileInfo(ctx.getValues(mediaDataName)?.urls.original);
            }
        } else {
            setFileInfo(null);
        }

        // Setting image for crop
        if (isUrl(image)) {
            setCropFileInfo(image);
        } else if (_.isArray(ctx.getValues(mediaDataName))) {
            setCropFileInfo(ctx.getValues(mediaDataName)?.[0]?.urls?.original);
        } else if (_.isObject(ctx.getValues(mediaDataName))) {
            setCropFileInfo(ctx.getValues(mediaDataName)?.urls?.original);
        } else {
            setCropFileInfo(null);
        }

        // eslint-disable-next-line
    }, [image]);

    const onRemove = () => {
        ctx.setValue(name, "");
        ctx.setValue(mediaDataName, null);
        setFileInfo(null);
        setCropFileInfo(null);
        removeMainKey && ctx.setValue("images", []);
        if (image && isUrl(image)) {
            props.onRemove?.();
        }
    };

    return props.plaintext ? (
        <PlainImage name={name} label={label || ""} value={fileInfo} className={className} />
    ) : (
        <Form.Group {..._.omit(props, "onRemove", "afterUpload")}>
            {label !== false && <Form.ControlLabel {...labelProps}>{label ?? getLabel(name)}</Form.ControlLabel>}

            <div {...controlProps} className={className}>
                <Uploader
                    {...(compProps as any)}
                    draggable
                    accept=".jpg, .jpeg, .png"
                    name="media_file"
                    headers={{ Authorization: `Bearer ${user.token}` }}
                    action={url}
                    fileListVisible={false}
                    disabled={isUploading || compProps?.disabled}
                    onUpload={(file: FileType) => {
                        setIsUploading(true);
                        previewFile(file.blobFile, setFileInfo, setCropFileInfo);
                    }}
                    onSuccess={(response: any) => {
                        setIsUploading(false);
                        ctx.setValue(name, response.data.uuid);
                        ctx.setValue(mediaDataName, response.data);
                        props.afterUpload?.(response.data);
                    }}
                    onError={(status: any) => {
                        setFileInfo(null);
                        setCropFileInfo(null);
                        setIsUploading(false);
                        if (status.type === "server_error") {
                            const err: any = _.head(status?.response?.error);
                            const msg: string | undefined = _.head(err?.field?.media_file) as string | undefined;
                            toaster.push(
                                <Message type="error" showIcon closable>
                                    {msg ?? t("error")}
                                </Message>,
                            );
                        }
                    }}
                >
                    <button type="button" style={buttonStyle ?? { width: 150, height: 150 }}>
                        {isUploading && <Loader backdrop center />}
                        {!disablePreview && fileInfo ? (
                            <img
                                style={fileInfoStyle}
                                src={fileInfo as string}
                                alt={label !== false ? getLabel(name) : "image"}
                                className="img-fluid"
                            />
                        ) : (
                            <CIcon path={iImage} />
                            // <Icon icon="image" size="lg" />
                        )}
                    </button>
                </Uploader>
                {!hideActions && fileInfo && (
                    <div style={fileInfoButtonsStyle} className="file-upload-action-section">
                        <IconButton
                            size="xs"
                            color="red"
                            circle
                            icon={<CIcon path={iClose} />}
                            test-id="file-remove-button"
                            onClick={onRemove}
                        />
                        <IconButton
                            size="xs"
                            color="blue"
                            circle
                            icon={<CIcon path={mdiContentCut} />}
                            onClick={openCropModal}
                        />
                    </div>
                )}
                {tooltip && <Form.HelpText tooltip>{tooltip}</Form.HelpText>}
                <Form.ErrorMessage show={!!error} placement="bottomStart">
                    {error}
                </Form.ErrorMessage>
            </div>

            <CModal title={t("crop_image_modal_title")} {...cropModal} backdrop>
                <Cropper
                    src={cropFileInfo as string}
                    style={{ minHeight: 300, maxHeight: "70vh", width: "100%", marginTop: 15 }}
                    initialAspectRatio={aspectRatio ?? 16 / 9}
                    aspectRatio={aspectRatio ?? 16 / 9}
                    guides={false}
                    ref={cropperRef}
                />
                <div className="image-crop-bottom-action-button-section">
                    <Button
                        loading={isCroppingLoading}
                        color={"blue"}
                        // placement="right"
                        style={{ right: 0 }}
                        onClick={saveCroppedImage}
                    >
                        <TText tkey="save" />
                    </Button>
                </div>
            </CModal>
        </Form.Group>
    );
};

UploaderFormGroup.defaultProps = {
    controlWrapperProps: {},
};

export default UploaderFormGroup;
