import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import { exportFile } from "lib/export-table";
import { AccountPageType, accountQuickLinks } from "./utils";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import SelectFilter from "components/DataTable/Filters/SelectFilter";
import { customerTypeOptions } from "../../marketing/customer/CustomerPage";

const CustomerAccountsPage: FCC = () => {
    const url = "customer";
    const dp = useDataProvider({
        url,
        useUrl: true,
        with: ["group", "balance", "cashback_balance"],
        sort: ["created_at", "1"],
    });

    const cm = useColumns<ICustomer>("customer-account", [
        {
            key: "id",
            align: "left",
            flexGrow: 1,
        },
        {
            key: "group.name",
            title: t("customer_group"),
        },
        {
            key: "name",
            align: "left",
            flexGrow: 3,
        },
        {
            key: "balance.amount",
            title: t("balance"),
            type: "money",
            flexGrow: 2,
        },
        {
            key: "cashback_balance.amount",
            title: t("cashback"),
            type: "money",
            flexGrow: 2,
        },
    ]);

    return (
        <div className="h-100">
            {useTitle(t("customer"), dp.total)}

            {accountQuickLinks(AccountPageType.CUSTOMER)}

            <HeaderRight
                dp={dp}
                cm={cm}
                reload
                print
                export={() => exportFile(dp, cm.columns, t("customer_accounts"))}
            />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter placeholder={t("name")} fields="name" />
                    <SearchFilter placeholder={t("phone")} fields="phones" />
                    <RemoteCheckListFilter remote={{ url: "customers-group" }} fields="group_id" searchField="name" />
                    <SelectFilter
                        fields="amount"
                        options={customerTypeOptions()}
                        comparison_value={0}
                        pickerProps={{ placeholder: t("type"), searchable: false }}
                        hasAllOption={true}
                    />
                </div>
            </FiltersContainer>

            <DataTable
                key={url}
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
        </div>
    );
};

export default CustomerAccountsPage;
