import { OperationStatus, OperationType } from "config/constants";
import { IOperationItem } from "../types";
import { ReturnSupplyItemField } from "pages/inventory/supplies/ReturnFromSupplyCreate/ReturnFromSupplyItem";

export const dummySupplyItem = (item?: Partial<IOperationItem>) => ({
    product_id: null,
    unit_id: null,
    quantity: 0,
    cost: 0,
    total_cost: 0,
    status: 1,
    operation_item_id: null,
    tax_rate: 0,
    discount_rate: 0,
    ...item,
});

export const dummyReturnSupplyItem = (item?: Partial<ReturnSupplyItemField>) => ({
    product_id: null,
    unit_id: null,
    quantity: 0,
    cost: 0,
    total_cost: 0,
    status: 1,
    ...item,
});
export const dummyTransferItem = () => ({
    product_id: null,
    unit_id: null,
    quantity: 0,
});

export const dummySupplyOperation = () => ({
    description: "",
    storage_id: null as any,
    supplier_id: null as any,
    operated_at: new Date() as any,
    type: OperationType.IN,
    status: OperationStatus.DRAFT,
    supplier_remain: 0,
    invoice_number: "",
    __finance__: {
        balance_id: null,
        category_id: null,
    },
    items: [dummySupplyItem()],
});

export const dummyReturnSupplyOperation = () => ({
    description: "",
    from_storage_id: null as any,
    supplier_id: null as any,
    operated_at: new Date() as any,
    status: OperationStatus.APPROVED,
    supplier_remain: 0,
    invoice_number: "",
    items: [dummyReturnSupplyItem()],
});

export const dummyTransferOperation = () => ({
    storage_id: null as any,
    from_storage_id: null as any,
    operated_at: new Date() as any,
    type: OperationType.TRANSFER,
    finance: {
        balance_id: 4,
        category_id: 9,
    },
    reason: "",
    items: [dummyTransferItem()],
});

export const dummyWasteOperation = () => ({
    storage_id: null as any,
    operated_at: new Date() as any,
    type: OperationType.WASTE,
    finance: {
        balance_id: 4,
        category_id: 9,
    },
    items: [dummyTransferItem()],
});

export const dummyInventoryItem = () => ({
    product_id: null,
    unit_id: null,
    quantity: 0,
});

export const dummyCheckOperation = () => ({
    storage_id: null as any,
    type: OperationType.INVENTORY_CHECK,
    operated_at: new Date() as any,
    op_items: [],
});

export const dummyInventoryOperation = () => ({
    storage_id: null as any,
    type: OperationType.INVENTORY_CHECK,
    operated_at: new Date() as any,
    items: [dummyInventoryItem()],
});

export const dummyManufactureOperation = () => ({
    storage_id: null as any,
    operated_at: new Date() as any,
    type: OperationType.MANUFACTURE,
    operation_items: [dummySupplyItem()],
});
