import { Checkbox, CheckboxProps } from "rsuite";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import { getLabel } from "./form-utils";
import _ from "lodash";
import { PlainCheckbox } from "./PlainCheckbox";
import CIcon from "components/CIcon";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

interface IProps extends CFormGroupProps<CheckboxProps> {
    changeableLabel?: Record<number, string>;
    reverseValue?: boolean;
    useBoolean?: boolean;
    plaintext?: boolean;
    icon?: any;
    testId?: any;
}

const CheckboxFormGroup: FCC<IProps> = ({ label, reverseValue, ...props }) => {
    const { getValues } = useCFormContext();
    const cprops: any = {
        render: ({ field: { name, value, onChange } }: any) => {
            const cl = props.changeableLabel && props.changeableLabel[value ? 1 : 0];
            const v = getValues(name);

            return (
                <Checkbox
                    className="ckeckbox__icon"
                    test-id={props.testId ?? props.name}
                    checked={!_.isNil(reverseValue) && reverseValue ? !v : !!v}
                    value={props.compProps?.value} // in case any value added manually
                    onChange={(value, isChecked, e) => {
                        /**
                         * Checking to see if value is set and is checked
                         * then value is set, else only checked true/false set
                         */
                        let checked: any = isChecked && value ? value : isChecked ? 1 : 0;

                        if (props.useBoolean) checked = !!checked;

                        onChange(!_.isNil(reverseValue) && reverseValue ? !checked : checked, e);
                    }}
                    disabled={!!props.compProps?.disabled}
                >
                    <div className="d-flex align-items-center ">
                        {props.icon && <CIcon path={props.icon} size={1} className="mr-2" />}
                        {props.hideLabel ? null : cl || label || getLabel(props.name)}
                    </div>
                </Checkbox>
            );
        },
        ...props.compProps,
    };

    const test = !reverseValue ? getValues(props.name) : !getValues(props.name);

    return props.plaintext ? (
        <PlainCheckbox {...props} value={test} label={label || ""} />
    ) : (
        <CFormGroup
            {...(props as any)}
            label={false}
            compProps={cprops}
            controlWrapperProps={props.controlWrapperProps || { style: { paddingLeft: 170 } }}
        />
    );
};

export default CheckboxFormGroup;
