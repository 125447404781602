import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import CForm from "components/Form/CForm/CForm";
import RemoteSelectFormGroup from "components/Form/RemoteSelectFormGroup";
import TextAreaFormGroup from "components/Form/TextAreaFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import JsonView from "components/JsonView";
import UnitWithEqual from "components/mini/UnitWithEqual";
import { useCForm } from "hooks/useCForm";
import { calculateIngrPerRecipe, calculateRecipePerIngr, translateUnit } from "lib/utils";
import _ from "lodash";
import { dummyPreparation, PreparationModel } from "models/PreparationModel";
import PreparationItem, { PreparationItemField } from "pages/menu/preparations/PreparationItem";
import { dummyPreparationItem } from "pages/menu/preparations/utils";
import React, { useEffect } from "react";
import { CategoryTypeEnum, ProductType } from "config/constants";
import TextPostfixFormGroup from "components/Form/TextPostfixFormGroup";
import { Form, InputGroup } from "rsuite";
import SelectFormGroup from "components/Form/SelectFormGroup";
import CProductsDrawer from "components/Drawers/CProductSelector/CProductsDrawer";
import { FormItems } from "components/FormItems/FormItems";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { FormItemsAddButton } from "components/FormItems/FormItemsAddButton";
import { MassInsertButton } from "components/FormItems/MassInsertButton";
import { FIHeaderProps } from "components/FormItems/FormItemsHeader";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { GridItemTotal } from "components/NewForm/Grid/GridItemTotal";
import MenuCategorySelectFormGroup from "components/Form/CategorySelectFormGroup/MenuCategorySelectFormGroup";
import { useParams } from "react-router-dom";
import { useCurrency } from "hooks/useCurrency";
import { setDisabledProductIds, setShowedTypes } from "redux/features/productsSelector/productsSelector";
import { useDrawer } from "components/Drawers/useDrawer";
import { useAppDispatch } from "hooks/useRedux";
import CreateCategoryModal from "pages/menu/categories/CreateCategoryModal";
import CheckboxFormGroup from "components/Form/CheckboxFormGroup";

const columns: FIHeaderProps["columns"] = [
    ["recipe"],
    ["type"],
    ["gross", "right"],
    ["gross_weight_kg", "right"],
    ["cost", "right"],
    [""], // buttons
];

const refineMassInsertItem = (d: any) => ({
    id: d.id,
    name: d.name,
    cost_price: d.cost_price,
    unit_id: d.unit_id,
    unit: d.unit,
    price: d.price,
    gross: 0,
    type: d.type,
    unit_weight: d.unit_weight,
});

const PreparationEditPage: FCC = () => {
    const dispatch = useAppDispatch();
    const isDuplicate = window.location.pathname.indexOf("/duplicate") !== -1;
    const params = useParams<{ id: string }>();
    const currency = useCurrency();
    const createCategoryModal = useDrawer();
    const form = useCForm({
        url: "product/preparation",
        with: ["unit", "recipe.unit"],
        isDuplicate,
        model: PreparationModel(),
        refine: (d: IPreparation): IPreparation => {
            if (isDuplicate)
                d.name =
                    d.name +
                    ` (${t("duplicate")}-${new Date().getTime().toString(36) + new Date().getUTCMilliseconds()})`;
            d.contents = _.map(d.recipe, (product: IRecipe) => {
                const gross = product.pivot?.net_put
                    ? Number(product.pivot?.net_put)
                    : calculateIngrPerRecipe(product.pivot?.gross, d.net_output);
                const newPreparation = {
                    ...product,
                    dish_id: product.pivot?.dish_id,
                    gross,
                    ingredient_id: product.pivot?.ingredient_id,
                    net: calculateIngrPerRecipe(product.pivot?.net, d.net_output),
                    price: product.cost_price * Number(gross),
                };
                return _.omit(newPreparation, ["pivot"]);
            });

            if (Array.isArray(d.properties) && d.properties.length === 0) {
                d.properties = {};
            }

            return d;
        },
        dummyData: dummyPreparation,
        prepareForSubmit: (d: any) => {
            d.contents = d.contents.map((sd: any) => ({
                ...sd,
                gross: calculateRecipePerIngr(sd.gross, d.net_output),
                net_put: sd.gross,
            }));

            if (_.isNil(d.unit_id)) {
                d = _.omit(d, ["unit_id"]);
            }
            d.unit_weight =
                d.contents?.reduce((acc: number, item: IIngredient) => {
                    return acc + (item?.gross || 0) * (item.unit_weight || 0);
                }, 0) || 0;

            d.recipe = d.contents;
            return _.omit(d, ["image", "unit", "created_at", "updated_at", "contents"]);
        },
    });
    const items: IRecipe[] = form.form.getValues("contents") || [];

    useEffect(() => {
        dispatch(setDisabledProductIds([+params.id, ...items.map(item => item.id)]));
    }, [items.length]);

    useEffect(() => {
        dispatch(setShowedTypes([ProductType.INGREDIENT, ProductType.PREPARATION, ProductType.GOODS]));
    }, []);

    return (
        <div className="tw-p-4">
            {useFormTitle(form.id, "", form.form.getValues("name"))}
            <HeaderRight formSubmit={form} showSaveCreateButton={!isDuplicate && !form?.id} />

            <CForm form={form.form} onSave={form.onSave}>
                <div className="">
                    <TextFormGroup
                        className="tw-text-secondary-200"
                        name="name"
                        errorPlacement="rightEnd"
                        focus={true}
                    />

                    <MenuCategorySelectFormGroup
                        name="accounting_category_id"
                        label={t("accounting_category")}
                        style={{ width: "100%" }}
                        types={[CategoryTypeEnum.ACCOUNTING]}
                        type={CategoryTypeEnum.ACCOUNTING}
                        compProps={{
                            cleanable: true,
                        }}
                    />

                    <MenuCategorySelectFormGroup
                        name="category_id"
                        label={t("category")}
                        style={{ width: "100%" }}
                        types={[CategoryTypeEnum.INGREDIENT]}
                        type={CategoryTypeEnum.INGREDIENT}
                        modal={createCategoryModal}
                        compProps={{
                            cleanable: true,
                        }}
                    />

                    <span className="d-flex" style={{ marginBottom: 12 }}>
                        <Form.ControlLabel style={{ width: "170px", marginRight: 12, padding: "6px 0" }}>
                            <TText tkey="recipe_output" />
                        </Form.ControlLabel>
                        <InputGroup className="double-select" inside>
                            <TextPostfixFormGroup
                                name="net_output"
                                type="number"
                                label={false}
                                compProps={{
                                    placeholder: t("recipe_output"),
                                    style: { textAlign: "left" },
                                    // textAlign: "left",
                                }}
                            />
                            <RemoteSelectFormGroup
                                name="unit_id"
                                hideLabel
                                width={120}
                                searchLocal={true}
                                remote={{
                                    url: "unit",
                                    with: ["ancestors"],
                                    refineList: (d: IUnit[]) =>
                                        d?.filter(unit => unit.type !== "PACKAGE").map(translateUnit),
                                }}
                                compProps={{
                                    renderMenuItem: (label, item: any) =>
                                        item ? <UnitWithEqual testId="unit_id_item" label={label} item={item} /> : "",
                                    renderValue: (label, item: any) =>
                                        item ? <UnitWithEqual label={label} item={item} /> : "",
                                    // onSelect: (value, item: any) => setUnit(translateUnit(item)),
                                }}
                            />
                        </InputGroup>
                    </span>

                    <TextAreaFormGroup className="tw-text-secondary-200" name="description" />
                    <SelectFormGroup
                        className="tw-text-secondary-200"
                        name="inventory_behavior"
                        label={t("write_off_method")}
                        compProps={{
                            defaultValue: 0,
                        }}
                        data={[
                            { id: 0, name: t("write_off_ingredients") },
                            { id: 1, name: t("write_off_ready_item") },
                        ]}
                    />

                    <TextFormGroup
                        className="tw-text-secondary-200 tw-mb-2"
                        name="low_stock"
                        type={"number"}
                        compProps={{
                            description: (
                                <CheckboxFormGroup
                                    className={"tw-text-secondary-200"}
                                    controlWrapperProps={{}}
                                    name={"properties.should_notify_low_stock"}
                                    label={t("notification")}
                                />
                            ),
                        }}
                    />
                    <JsonView />
                </div>

                <FormItems itemsFieldName="contents">
                    <FormItemsList<PreparationItemField>
                        showHeader
                        // virtualize
                        columns={columns}
                        footer={
                            <div className="tw-grid tw-grid-cols-subgrid tw-col-span-6 tw-pb-6">
                                <div className="tw-col-span-2 tw-gap-2 tw-flex">
                                    <MassInsertButton idField="id" refineItem={refineMassInsertItem} />
                                    <FormItemsAddButton dummyData={dummyPreparationItem} />
                                </div>

                                <GridItemPlain
                                    align="right"
                                    className="tw-bg-transparent tw-border-none"
                                    value={t("total") + ":"}
                                />

                                <GridItemTotal
                                    iteratee={item => (item.gross || 0) * (item.unit_weight || 0)}
                                    postfix={t("kg_short")}
                                />

                                <GridItemTotal
                                    postfix={currency}
                                    iteratee={item => (item.gross || 0) * (item.cost_price || 0)}
                                    name="price"
                                    decimals={2}
                                />
                            </div>
                        }
                    >
                        {_props => {
                            return <PreparationItem {..._props} />;
                        }}
                    </FormItemsList>
                </FormItems>
            </CForm>
            <CProductsDrawer />
            <CreateCategoryModal {...createCategoryModal} />
        </div>
    );
};

export default PreparationEditPage;
