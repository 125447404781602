import { yupResolver } from "@hookform/resolvers/yup";
import Ajax from "lib/Ajax";
import _, { isNil } from "lodash";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { Mode, useForm, UseFormProps } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import { toaster } from "rsuite";
import { useLoading } from "./useLoading";
import { MessageError } from "components/Message";
import { customResolver, ValidationSchema } from "lib/custom-form-schema-resolver";
import { Method } from "axios";
import * as Sentry from "@sentry/react";

export type DataFlowStage = "GET_DATA" | "SUBMIT_DATA";

export interface IUseFormConf {
    url?: string | ((options: { dataFlowStage: DataFlowStage; id: number | string | null }) => string);
    id?: number;
    afterSubmitCallback?: boolean;
    redirectUrl?: string | false; // if false nothing happens after save, if omitted history.back is called
    with?: string[];
    refine?: (d: any) => any;
    dummyData?: () => any;
    getData?: () => any;
    prepareForSubmit?: (d: any) => any;
    afterSubmit?: (d: { formValue: any; sentValue: any; receivedValue: any }) => void;
    model?: any; //ObjectSchema<object & T, object>;
    methodOnSubmit?: "put" | "post";
    isModal?: boolean;
    params?: any;
    headers?: any;
    hasAllVenues?: boolean;
    clearForm?: boolean;
    mode?: Mode;
    rhfOptions?: UseFormProps;
    model2?: ValidationSchema;
    isDuplicate?: boolean;
}

export type UseFormReturnType = ReturnType<typeof useCForm>;

const getId = (conf: IUseFormConf, params: any) => (conf.isModal ? conf.id : (conf.id ?? params.id));

const isFormHasUrlAndId = (conf: IUseFormConf, params: any): boolean => {
    if (typeof conf.url === "function") {
        return true;
    }

    const id = getId(conf, params);

    return !!conf.url && !isNil(id);
};

const getUrl = (conf: IUseFormConf, dataFlowStage: DataFlowStage, _id: number | null = null): string => {
    if (typeof conf.url === "function") {
        return conf.url({ dataFlowStage, id: _id });
    }

    if (conf.isDuplicate && dataFlowStage === "SUBMIT_DATA") {
        return conf.url!;
    }

    return `${conf.url}${_id ? "/" + _id : ""}`;
};

export function useCForm<T extends object = object>(conf: IUseFormConf) {
    const params = useParams<any>();
    const history = useHistory();
    const { isLoading, setIsLoading, withLoading } = useLoading();
    const [localConf, setLocalConf] = useState(conf);
    const id = getId(localConf, params);

    useEffect(() => {
        setLocalConf(s => ({ ...s, id: conf.id, clearForm: conf.clearForm ?? true }));

        // eslint-disable-next-line
    }, [conf.id]);

    const urlParams = {
        with: localConf.with,
        params: localConf.params,
    };

    const refineData = async (data: any) => {
        const refined = (await localConf.refine?.(data)) ?? data;

        return _.mapValues(refined, v => (v === undefined ? "" : v));
    };

    const form = useForm({
        mode: conf.mode ?? "all",
        defaultValues: async () => await getDefaultValues(),
        resolver: localConf.model
            ? yupResolver(localConf.model)
            : localConf.model2
              ? customResolver(localConf.model2)
              : undefined,
        shouldUnregister: false,
        ...conf.rhfOptions,
    });

    const getDefaultValues = async () => {
        setIsLoading(true);
        let data: any = localConf.dummyData?.() ?? {};

        try {
            if (conf.getData) {
                const resp = await conf.getData();
                data = resp?.data;
            } else if (isFormHasUrlAndId(localConf, params)) {
                const url = Ajax.buildUrl({
                    url: getUrl(localConf, "GET_DATA", id),
                    params: urlParams,
                });
                const resp = await Ajax.get<T>({ url });
                data = resp?.data;
            }
            data = await refineData(data);
        } catch (error) {
            // ignore
            console.error(error);
            Sentry.captureException(error);
        }

        setIsLoading(false);

        return data;
    };

    const onSave = async (e?: BaseSyntheticEvent, isSaveCreate?: boolean): Promise<void> => {
        const isValid = () => {
            if (isLoading || (id && form.getValues("id") && parseInt(id) !== form.getValues("id"))) {
                if (id && form.getValues("id") && parseInt(id) !== form.getValues("id")) {
                    toaster.push(MessageError("Updated id does not match form data id"), { duration: 4000 });

                    return;
                }

                return;
            }

            const formValue = form.getValues();
            const data = localConf.prepareForSubmit
                ? _.omit(localConf.prepareForSubmit(formValue), ["created_at", "updated_at", "deleted_at"])
                : localConf.model
                  ? _.pick(formValue, _.keys(localConf.model.fields))
                  : formValue;

            if (!localConf.url) {
                localConf.afterSubmit?.({ formValue, sentValue: data, receivedValue: null });

                return;
            }

            return withLoading(() => {
                const url = getUrl(localConf, "SUBMIT_DATA", id);
                const method: Method = localConf.methodOnSubmit ?? (id && !localConf.isDuplicate ? "put" : "post");

                return Ajax[method]({
                    url: url ?? "",
                    data,
                    headers: {
                        "x-all-venues": localConf?.hasAllVenues === true ? "1" : "0",
                        ...localConf?.headers,
                    },
                });
            })
                .then(resp => {
                    localConf.afterSubmit?.({ formValue, sentValue: data, receivedValue: resp.data });

                    if (localConf.clearForm) {
                        const _data = localConf.redirectUrl ? (localConf.dummyData?.() ?? {}) : formValue;
                        form.reset(_data, { keepDirty: false });
                    }

                    // TODO: SaveCreate zamanı form reset olmasını gözləmirdi
                    setTimeout(() => {
                        if (isSaveCreate) {
                            window.location.reload();
                            return;
                        }

                        if (localConf.afterSubmitCallback) return;

                        if (localConf.redirectUrl) {
                            history.push(localConf.redirectUrl.replace(":id", resp.data.id.toString()));
                        } else if (localConf.redirectUrl !== false && !localConf.isModal) {
                            history.goBack();
                        }
                    }, 20);
                })
                .catch((e: CustomError) => {
                    _.each(e.field, (message, field: string) => {
                        form.setError(field, { type: "validate", message: message.join(", ") });
                    });
                });
        };

        // Manual trigger validation for show error, because there is a bug in react-hook-form which didn't show error message when form is submitted
        const isInvalid = () => form.trigger();

        form.handleSubmit(isValid, isInvalid)(e);
    };

    return {
        id,
        onSave: _.once(onSave),
        conf: localConf,
        urlParams,
        urlWithId: `${conf.url}/${id}`,
        url: conf.url,
        set: <K extends keyof IUseFormConf>(name: K, value: IUseFormConf[K]) =>
            setLocalConf(o => ({
                ...o,
                [name]: value,
            })),
        isLoading: isLoading,
        isSubmitting: form.formState.isSubmitting,
        form,
    };
}
