import TextFormGroup from "components/Form/TextFormGroup";
import { t } from "lib/i18n";
import { Language } from "config/constants";
import { productName } from "lib/data-utils";
import { Col, Divider, Grid, Message, Row } from "rsuite";
import { useEmenuLanguages } from "hooks/useEmenuLanguages";

export const ModifierEditTab2 = ({ form }: any) => {
    const emenuLangs = useEmenuLanguages();
    const fData = form.form.getValues();

    return (
        <div className="tw-p-4">
            <div>
                {!emenuLangs.length && (
                    <div className="pb-3">
                        <Message
                            showIcon
                            type="info"
                            children={t("emenu_lang_not_choosen")}
                            style={{ maxWidth: "50%" }}
                        />
                    </div>
                )}
                <h5>{fData.name}</h5>

                {emenuLangs.map((lang: string, i: number) => (
                    <div className="rs-form-group" key={i}>
                        <label className="rs-control-label">{(Language as any)[lang]}</label>
                        <Grid fluid>
                            <Row>
                                <Col xs={12} sm={12} md={8} lg={6} className="pl-0 pr-1">
                                    <TextFormGroup
                                        name={`meta.langs.${lang}.name`}
                                        controlWrapperProps={{ className: "w-100" }}
                                        label={false}
                                        compProps={{ placeholder: t("name"), className: "w-100" }}
                                        errorPlacement="rightEnd"
                                    />
                                </Col>
                                <Col xs={12} sm={12} md={8} lg={6} className="pl-0 pr-1">
                                    <TextFormGroup
                                        name={`meta.langs.${lang}.description`}
                                        controlWrapperProps={{ className: "w-100" }}
                                        label={false}
                                        compProps={{ placeholder: t("description"), className: "w-100" }}
                                        errorPlacement="rightEnd"
                                    />
                                </Col>
                            </Row>
                        </Grid>
                    </div>
                ))}
            </div>
            <Divider />
            {fData?.modificators &&
                fData.modificators?.map((modification: IVariant, index: number) => (
                    <>
                        <div className="rs-form-group pb-3">
                            <label
                                className="rs-control-label"
                                style={{
                                    display: "block",
                                    float: "none",
                                    fontWeight: "bold",
                                }}
                            >
                                {productName(modification)}
                            </label>
                            <div>
                                {emenuLangs.map((lang: string, i: number) => {
                                    return (
                                        <div className="rs-form-group" key={i}>
                                            <label className="rs-control-label">{(Language as any)[lang]}</label>
                                            <Grid fluid>
                                                <Row>
                                                    <Col xs={12} sm={12} md={8} lg={6} className="pl-0 pr-1">
                                                        <TextFormGroup
                                                            name={`modificators[${index}].meta.langs.${lang}.name`}
                                                            controlWrapperProps={{ className: "w-100" }}
                                                            label={false}
                                                            compProps={{
                                                                placeholder: t("name"),
                                                                className: "w-100",
                                                            }}
                                                            errorPlacement="rightEnd"
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={12} md={8} lg={6} className="pl-0 pr-1">
                                                        <TextFormGroup
                                                            name={`modificators[${index}].meta.langs.${lang}.description`}
                                                            controlWrapperProps={{ className: "w-100" }}
                                                            label={false}
                                                            compProps={{
                                                                placeholder: t("description"),
                                                                className: "w-100",
                                                            }}
                                                            errorPlacement="rightEnd"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Grid>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <Divider />
                    </>
                ))}
        </div>
    );
};
