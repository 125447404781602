import _ from "lodash";
import React, { ComponentType, HTMLAttributes } from "react";
import { Controller } from "react-hook-form";
import { Form, FormControlLabelProps, FormGroupProps, InputGroup, InputNumberProps } from "rsuite";
import { getLabel } from "./form-utils";
import { ButtonProps } from "rsuite/cjs/Button";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

export interface CFormGroupProps<T = InputNumberProps> extends FormGroupProps {
    name: string;
    label?: string | false;
    comp?: ComponentType<T>;
    compProps?: T;
    controlWrapperProps?: HTMLAttributes<HTMLDivElement>;
    tooltip?: string;
    error?: string;
    labelProps?: FormControlLabelProps;
    inputBtn?: ButtonProps;
}

const CInputBtnFormGroup: FCC<CFormGroupProps> = ({
    inputBtn,
    tooltip,
    name,
    label,
    comp,
    compProps,
    controlWrapperProps,
    labelProps,
    ...props
}) => {
    const ctx = useCFormContext();
    const error = ctx ? (_.get(ctx.formState.errors, name)?.message as string) : props.error;
    const controlProps = _.omit(controlWrapperProps, "className");
    const className = "rs-form-control-wrapper " + (controlWrapperProps?.className ?? "");

    return (
        <Form.Group {...props}>
            {label !== false && <Form.ControlLabel {...labelProps}>{label ?? getLabel(name)}</Form.ControlLabel>}
            <div {...controlProps} className={className}>
                <InputGroup {...props}>
                    <Controller
                        control={ctx.control}
                        name={name}
                        render={({ field }) => {
                            const Comp = comp as any;
                            return <Comp {...field} {...compProps} test-id={name} />;
                        }}
                        {...(compProps as any)}
                    />
                    <InputGroup.Button {...inputBtn}>{compProps?.postfix}</InputGroup.Button>
                    {tooltip && <Form.HelpText tooltip>{tooltip}</Form.HelpText>}
                    <Form.ErrorMessage show={!!error} placement="bottomStart">
                        <span test-id={`${name}-error`}>{error}</span>
                    </Form.ErrorMessage>
                </InputGroup>
            </div>
        </Form.Group>
    );
};

CInputBtnFormGroup.defaultProps = {
    controlWrapperProps: {},
};

export default CInputBtnFormGroup;
