import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { FC } from "react";
import { useSelector } from "react-redux";
import { Panel, SelectPicker } from "rsuite";
import { SalesChannelTypes } from "config/constants";
import { useData } from "hooks/useData";
import {
    recalculateReceipt,
    selectReceiptData,
    selectReceiptSalesChannel,
    updateReceipt,
} from "redux/features/webCashier";
import ReceiptNumberInput from "./ReceiptItemNumberInput";
import { useCalculationSetting } from "hooks/useCalculationSetting";
import { useAppDispatch } from "hooks/useRedux";

const ReceiptOtherOptions: FC<any> = ({ sellerList }) => {
    const dispatch = useAppDispatch();
    const courierList = useData("user", {
        with: ["roles.abilities"],
        filters: [
            ["abilities", "field_courier"],
            ["status", 1],
        ],
    });
    const { receipt } = useSelector(selectReceiptData);
    const calculationSetting = useCalculationSetting();
    const salesChannel = useSelector(selectReceiptSalesChannel);

    const renderPanelHeader = () => {
        return (
            <span className="header">
                <TText tkey="other_details" />
            </span>
        );
    };

    return (
        <Panel header={renderPanelHeader()} className="accordion" collapsible test-id="other-options">
            {salesChannel === SalesChannelTypes.DELIVERY ? (
                <div className="item">
                    <div className="label">
                        <TText tkey="courier" />
                    </div>
                    <SelectPicker
                        data={courierList?.data}
                        labelKey={"username"}
                        valueKey={"id"}
                        name="courier"
                        test-id="courier"
                        value={receipt.courier_id}
                        renderMenuItem={label => <span test-id="courier_item">{label}</span>}
                        onClean={() => {
                            dispatch(updateReceipt({ key: "courier_id", value: null }));
                            const meta = { ...receipt.meta };
                            delete meta.courier_name;
                            dispatch(updateReceipt({ key: "meta", value: meta }));
                        }}
                        onSelect={(value, item, event) => {
                            dispatch(updateReceipt({ key: "courier_id", value: value }));
                            const meta = { ...receipt.meta, ...{ courier_name: item.username } };
                            dispatch(updateReceipt({ key: "meta", value: meta }));
                        }}
                        placeholder={t("select", { name: t("courier") })}
                    />
                    <ReceiptNumberInput
                        min={0}
                        max={1000}
                        step={3}
                        value={receipt.delivery_fee}
                        hideButtons={true}
                        name="delivery_fee"
                        style={{ marginTop: "8px" }}
                        inputID={"courier_delivery_fee"}
                        placeholder={t("courier_delivery_fee")}
                        onChange={(value: number | string) => {
                            const _value = typeof value === "string" && value ? parseFloat(value) : value;
                            dispatch(updateReceipt({ key: "delivery_fee", value: _value }));
                            dispatch(recalculateReceipt({ setting: calculationSetting }));
                        }}
                    />
                </div>
            ) : null}

            <div className="item">
                <div className="label">
                    <TText tkey="seller" />
                </div>
                <SelectPicker
                    data={sellerList}
                    labelKey={"username"}
                    valueKey={"id"}
                    name="seller"
                    test-id="seller"
                    renderMenuItem={label => <span test-id="seller_item">{label}</span>}
                    value={receipt.seller_id}
                    onClean={() => {
                        dispatch(updateReceipt({ key: "seller_id", value: null }));
                        const meta = { ...receipt.meta };
                        delete meta.seller_name;
                        dispatch(updateReceipt({ key: "meta", value: meta }));
                    }}
                    onSelect={(value, item, event) => {
                        dispatch(updateReceipt({ key: "seller_id", value: value }));
                        const meta = { ...receipt.meta, ...{ seller_name: item.username } };
                        dispatch(updateReceipt({ key: "meta", value: meta }));
                    }}
                    placeholder={t("select", { name: t("seller") })}
                />
            </div>
            <div className="item">
                <div className="label">
                    <TText tkey="description" />
                </div>
                <textarea
                    name="description"
                    test-id="description"
                    className="text-area"
                    rows={4}
                    placeholder={t("comment_receipt")}
                    value={receipt.description}
                    onChange={value =>
                        dispatch(
                            updateReceipt({
                                key: "description",
                                value: value.target.value,
                            }),
                        )
                    }
                />
            </div>
        </Panel>
    );
};

export default ReceiptOtherOptions;
