import { t } from "lib/i18n";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import HeaderRight from "../../../components/Header/HeaderRight";
import DataTable from "../../../components/DataTable/DataTable";
import FiltersContainer from "../../../components/DataTable/Filters/FiltersContainer";
import SearchFilter from "../../../components/DataTable/Filters/SearchFilter";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import EditCustomerGroupModal from "./EditCustomerGroupModal";
import { useDrawer } from "components/Drawers/useDrawer";
import { useTitle } from "components/Header/Title";
import CreateButton from "../../../components/DataTable/CreateButton";

const CustomerGroupPage: FCC = () => {
    const dp = useDataProvider({
        useUrl: true,
        url: "customers-group",
        sort: ["created_at", "1"],
        params: {
            withTotal: 1,
        },
    });

    const cm = useColumns<any>("customers-group", [
        { key: "name" },
        // {key: "discount_type"},
        { key: "discount_value" },
        { key: "total_amount", title: t("balance"), type: "money" },
        optionsColumn({
            dp,
            edit: (d: any) => modal.setDataId(d.id),
            delete: "/customers-group/:id",
            canEdit: "customer_group_manage_edit",
            canDelete: "customer_group_manage_delete",
        }),
    ]);

    const modal = useDrawer();

    return (
        <div className="h-100">
            {useTitle(t("customer_groups"), dp.total)}
            <HeaderRight cm={cm} dp={dp} reload />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="name" />
                </div>
                <CreateButton to={() => modal.setDataId(0)} canCreate={"customer_group_manage_create"} />
            </FiltersContainer>
            {modal.isOpen ? <EditCustomerGroupModal {...modal} revalidate={dp.revalidate} /> : null}
            <DataTable dp={dp} columns={cm.columns} />
        </div>
    );
};

export default CustomerGroupPage;
