import { t } from "lib/i18n";
import WhisperWithoutIcon from "components/mini/WhisperWithoutIcon";
import _ from "lodash";

import { Button } from "rsuite";
import CellBtn from "../../../components/DataTable/CellButton";

interface IProps {
    transaction: ITransaction;
    noReturnSupplierName?: boolean;
    isInDrawer?: boolean;
    showReturns?: (rid: number) => void;
    showReceipt?: (rid: number) => void;
    showSupply?: (op: IOperation) => void;
}

const TransactionReference: FCC<IProps> = ({ transaction, ...props }) => {
    const renderTransactionGroupReference = (d: ITransaction) => {
        if (d.category?.id !== 3) return;
        if (!d?.group_references) return;
        const relatedTransaction = d?.group_references.find(g => g.id !== d.id);
        if (!relatedTransaction) return;

        if (d.type === "IN") {
            return (
                <WhisperWithoutIcon
                    description={` ${relatedTransaction.balance?.name || relatedTransaction.from_balance?.name} ${t(
                        "from_account",
                    )} #${relatedTransaction.id}`}
                />
            );
        } else {
            return (
                <WhisperWithoutIcon
                    description={` ${relatedTransaction.balance?.name || relatedTransaction.from_balance?.name} ${t(
                        "to_account",
                    )} #${relatedTransaction.id}`}
                />
            );
        }
    };

    return (
        <div>
            {/*{transaction.receipt_id && <Button appearance="link" className="p-0"*/}
            {/*    onClick={() => props.showReceipt?.(transaction.receipt_id!)}>*/}
            {/*        {t("receipt")} #{transaction.receipt_id}*/}
            {/*</Button>}*/}

            {transaction.operation && transaction.operation.supplier && _.isNil(transaction.returns_id) && (
                <Button appearance="link" className="p-0" onClick={() => props.showSupply?.(transaction.operation)}>
                    {props.isInDrawer ? "" : transaction.operation.supplier?.name} #{transaction.operation.id}
                </Button>
            )}

            {transaction.staff &&
                (transaction.category?.id === 5 || transaction.category?.id === 26) &&
                transaction.staff.username}
            {/*{transaction.customer && transaction.customer.name}*/}
            {!transaction.returns_id &&
                !transaction.operation &&
                transaction.supplier &&
                !props.isInDrawer &&
                transaction.supplier.name}

            {transaction.category?.id === 1 ? (
                <CellBtn
                    action={() => props.showReceipt?.(transaction.receipt_id!)}
                    label={`${transaction.customer?.name ?? ""} #${transaction.receipt_id}`}
                />
            ) : (
                transaction.customer?.name
            )}

            {transaction.returns_id && (
                <CellBtn
                    action={() => props.showReturns?.(transaction.returns_id!)}
                    label={`${
                        transaction?.supplier?.name && !props?.noReturnSupplierName
                            ? transaction.supplier.name + " "
                            : ""
                    }#${transaction.returns_id}`}
                />
            )}

            {renderTransactionGroupReference(transaction)}
        </div>
    );
};

export default TransactionReference;
