import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import { useTableHeight } from "hooks/useTableHeight";
import UserWithDesc from "components/mini/UserWithDesc";
import { useDrawer } from "components/Drawers/useDrawer";
import ProductReturnDrawer from "components/Drawers/ProductReturnDrawer/ProductReturnDrawer";
import CIcon from "components/CIcon";
import { iBox, iTrash } from "assets/icons/Icons";
import { Colors } from "config/colors";

const ProductReturnsDetails: FCC = ({ r, ...props }: any) => {
    const returnSpecific = useDrawer();

    const cm = useColumns<any>(`return_product_details`, [
        {
            key: "id",
            align: "left",
            onClick: d => {
                returnSpecific.setDataId(d?.id);
                props.setIsClosable(false);
            },
        },
        { key: "user_id", render: e => <UserWithDesc {...e} /> },
        {
            key: "storage_id",
            flexGrow: 0.5,
            align: "left",
            render: d => {
                const size = 0.6;
                let isWasteValue,
                    isStorageValue = false;
                for (let i = 0; i < d.return_products?.length; i++) {
                    if (isWasteValue && isStorageValue) break;
                    if (d.return_products[i].waste) isWasteValue = true;
                    else isStorageValue = true;
                }
                if (isWasteValue && isStorageValue) {
                    return (
                        <span>
                            <CIcon path={iBox} size={size} style={{ color: Colors.TropicalGreen }} />
                            <CIcon path={iTrash} size={size} style={{ color: Colors.BrightRed }} />
                        </span>
                    );
                } else if (isStorageValue) {
                    return <CIcon path={iBox} size={size} style={{ color: Colors.TropicalGreen }} />;
                } else {
                    return <CIcon path={iTrash} size={size} style={{ color: Colors.BrightRed }} />;
                }
            },
        },
        { key: "amount", align: "left", type: "money" },

        {
            key: "operated_at",
            flexGrow: 0.5,
            type: "datetime",
            multiline: true,
        },
    ]);

    const height = useTableHeight(r.fiscal_id ? 284 : 250);

    return (
        <div className="d-flex">
            <div className="flex-fill border-left">
                <DataTable
                    noShadow
                    columns={cm.columns}
                    dp={{ data: r?.returns } as any}
                    hidePages
                    rowPadding={"20px 10px 0px"}
                    tableProps={{
                        rowHeight: 60,
                        autoHeight: false,
                        height,
                    }}
                />
            </div>
            <ProductReturnDrawer
                {...returnSpecific}
                onClose={() => {
                    props.setIsClosable(true);
                    returnSpecific.onClose();
                }}
            />
        </div>
    );
};

export default ProductReturnsDetails;
