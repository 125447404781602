import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { getParsedQuery } from "components/DataTable/useDataProvider";
import { last7, today } from "config/dates";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

export interface AppState {
    first_time: boolean;
    active_menu_item: string[];
    dates: [number, number];
    error: {
        message: string;
        hasError: boolean;
    };
    partner: IPartner;
    brandData: {
        brand_name: string;
        country: string;
        id: number;
        language: string;
        logo: any[];
        name: string;
        stage: string;
        terminal_language: string;
    };
}

const urls = window.location.search;
const urldata = getParsedQuery(urls);
const datas = urldata?.[0]?.params?.date;
const startDate = new Date(datas?.[0]).getTime();
const endDate = new Date(datas?.[1]).getTime();

const initialState: AppState = {
    first_time: false,
    active_menu_item: [],
    dates: datas?.length ? [startDate, endDate] : [last7().getTime(), today().getTime()],
    error: {
        message: "",
        hasError: false,
    },
    partner: {
        website: "",
        whatsapp: "",
        number: "",
        address: "",
    },
    brandData: {
        brand_name: "",
        country: "",
        id: 0,
        language: "",
        logo: [],
        name: "",
        stage: "",
        terminal_language: "",
    },
};

export const AppSlice = createSlice({
    name: ReduxSlicesNames.APP,
    initialState,
    reducers: {
        setFirstTimeSession: (state, action) => {
            state.first_time = action.payload;
        },
        setActiveMenuItem: (state, action) => {
            state.active_menu_item = action.payload;
        },
        setDates: (state, action: PayloadAction<[number, number]>) => {
            state.dates = action.payload;
        },
        setPartner: (state, action: PayloadAction<IPartner>) => {
            state.partner = action.payload;
        },
        setGlobalError: (state, action: PayloadAction<string>) => {
            state.error.hasError = !!action.payload;
            state.error.message = action.payload;
        },
        setBrandData: (state, action: PayloadAction<any>) => {
            state.brandData = action.payload;
        },
    },
});

export const { setPartner, setFirstTimeSession, setGlobalError, setActiveMenuItem, setBrandData } = AppSlice.actions;
export default AppSlice.reducer;
