import { t } from "lib/i18n";
import * as _ from "lodash";
import CDrawer from "components/Drawers/CDrawer";
import { ProductType } from "config/constants";
import { productName } from "lib/data-utils";
import { getLabel } from "components/Form/form-utils";
import DataTable from "components/DataTable/DataTable";
import { useTableHeight } from "hooks/useTableHeight";
import { toFixed, translateUnit } from "lib/utils";

const IngdContainsDrawer = (props: any) => {
    const d = props?.data;
    const height = useTableHeight(230);
    if (_.isNil(d)) return <></>;
    return (
        <CDrawer
            title={`${productName(d?.product)} ${
                (d?.product?.type ?? d?.type) === ProductType.INGREDIENT
                    ? "(" + getLabel(d?.product?.type ?? d?.type) + ")"
                    : ""
            }`}
            {...props}
            size="md"
        >
            <div>
                <DataTable
                    noShadow
                    columns={[
                        {
                            title: t("product"),
                            width: 230,
                            key: "name",
                        },
                        {
                            title: t("remains_of_goods"), //actual_balance,
                            width: 230,
                            key: "quantity",
                            suffix: () => translateUnit(d?.product?.unit)?.symbol,
                        },
                        {
                            title: t("qtn_in_one_unit"), //gross_rate,
                            width: 230,
                            key: "gross_rate",
                        },
                        {
                            title: t("total_qty_from_prepacked"), //prepacked_quantity
                            width: 230,
                            key: "prepacked_quantity",
                            render: d => parseFloat(toFixed(d.prepacked_quantity, 4)),
                            suffix: () => translateUnit(d?.product?.unit)?.symbol,
                        },
                    ]}
                    dp={{ data: _.values(d?.temp) ?? [] } as any}
                    hidePages
                    tableProps={{
                        rowHeight: 60,
                        autoHeight: false,
                        height,
                    }}
                />
            </div>
        </CDrawer>
    );
};

export default IngdContainsDrawer;
