import { t } from "lib/i18n";
import RemoteSelectFormGroup from "components/Form/RemoteSelectFormGroup";
import { ModificationModalCompProps } from "pages/products/dishes/edit/tabs/modifications/modal/ModificationModal";
import { dummyModificatorGroup } from "pages/products/dishes/utils";
import Ajax from "../../../../../../../lib/Ajax";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import _ from "lodash";

const ModifierSelectField: FCC<ModificationModalCompProps> = ({ defaultData, cameFrom, groups }) => {
    const ctx = useCFormContext();

    const onSelectGroup = async (value: any, item: IModifierGroup) => {
        if (item?.id) {
            const { data: newItem }: IApiResponse<IModifierGroup> = await Ajax.get({
                url: "product/modificator-group/" + item?.id,
                params: {
                    with: ["modificators.ingredient", "modificators.ingredient.unit"],
                },
            });
            // setCurrentItem(item);
            ctx.setValue("name", newItem.id ? newItem.name : "");
            ctx.setValue("max_select", newItem.max_select);
            ctx.setValue("min_select", newItem.min_select);
            ctx.setValue("modificators", newItem.modificators);
            ctx.setValue("type", newItem.type);
        } else {
            ctx.setValue("name", "");
            ctx.setValue("modificators", []);
            ctx.setValue("type", 0); //
        }
    };

    return (
        <div className={cameFrom ? "d-none" : "d-block"}>
            <RemoteSelectFormGroup
                compProps={{
                    defaultValue: defaultData?.id,
                    className: "mb-2",
                    onSelect: async (value, item, event) => await onSelectGroup(value, item as IModifierGroup),
                }}
                isItemDisabled={d => groups?.find((t: IModifierGroup) => t.id === d.id)}
                name={"id"}
                fields={"name"}
                remote={{
                    url: "product/modificator-group",
                    refineList: (list: any[]) => {
                        return _.reject(
                            [
                                {
                                    ...dummyModificatorGroup(),
                                    name: t("create_new_set"),
                                },
                                // todo hotfix important
                                ...(list ?? []),
                            ],
                            _.isNil,
                        );
                    },
                }}
                label={false}
            />
        </div>
    );
};

export default ModifierSelectField;
