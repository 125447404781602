import _ from "lodash";
import React, { FC, HTMLAttributes } from "react";
import { ControllerProps, useController } from "react-hook-form";
import { Form, FormControlLabelProps, FormGroupProps } from "rsuite";
import { getLabel } from "./form-utils";
import { TypeAttributes } from "rsuite/cjs/@types/common";
import { Colors } from "config/colors";
import { cn } from "lib/utils";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import { t } from "lib/i18n";

/**
 * We use (as 2024-09-26) rsuite components and react-hook-form. React hook form requires a ref to focus, select, ... when validation fails.
 * And not all rsuite components (example, InputPicker) have these methods. So it throws an error: "focus is not a function".
 * To avoid this error, we add these methods to the component if they don't exist.
 *
 * Don't use spread operator to add these methods. Because it will lost its reference to the original object when we clone it.
 */
export const normalizeRef = (elm: any) => {
    if (!elm) return;
    if (!elm.focus) elm.focus = (...x: any) => _.noop(x);
    if (!elm.select) elm.select = (...x: any) => _.noop(x);
    if (!elm.setCustomValidity) elm.setCustomValidity = (...x: any) => _.noop(x);
    if (!elm.reportValidity) elm.reportValidity = (...x: any) => _.noop(x);

    return elm;
};

// import { TypeAttributes } from "rsuite/lib/@types/common";
// t=InputNumberProps | InputProps
export interface CFormGroupProps<T = any> extends FormGroupProps {
    name: string;
    label?: string | false;
    comp?: FC<T>;
    compProps?: T & { "test-id"?: string };
    controlWrapperProps?: HTMLAttributes<HTMLDivElement>;
    tooltip?: string;
    error?: string;
    priorityError?: string;
    disabled?: boolean;
    labelProps?: FormControlLabelProps;
    errorPlacement?: TypeAttributes.Placement8;
    // control?: Control;
    hideLabel?: boolean;
    //check if we need to add this prop
    fields?: any;
    searchField?: any;
    placeholder?: string;
    data?: any;
    labelDescription?: string;
    popoverText?: string;
    rules?: ControllerProps["rules"];
}

const CFormGroup: FC<CFormGroupProps> = ({
    disabled,
    tooltip,
    name,
    label,
    comp: Comp,
    hideLabel,
    compProps,
    controlWrapperProps,
    labelProps,
    errorPlacement,
    children,
    labelDescription,
    rules,
    ...props
}) => {
    const ctx = useCFormContext();
    const { field, fieldState } = useController({ name, rules });
    const error = ctx ? props.priorityError || fieldState.error?.message : props.error;
    const controlProps = _.omit(controlWrapperProps, "className");
    const className = "rs-form-control-wrapper " + (controlWrapperProps?.className ?? "");

    compProps = {
        "test-id": name,
        ...compProps,
        locale: {
            noResultsText: t("no_result_found"),
            ...compProps?.locale,
        },
        disabled: disabled || ctx.disabled || compProps?.disabled,
    };

    const testId = compProps?.["test-id"];

    return (
        <Form.Group {...props}>
            {hideLabel
                ? null
                : label !== false && (
                      <Form.ControlLabel className="text-left" {...labelProps}>
                          {<span test-id={`${testId}-label`}>{label || getLabel(name)}</span>}
                          {labelDescription && (
                              <div
                                  test-id={`${testId}-label-description`}
                                  className="text-muted font-weight-lighter tw-text-xs tw-break-words "
                              >
                                  {labelDescription}
                              </div>
                          )}
                      </Form.ControlLabel>
                  )}

            <div {...controlProps} className={className}>
                {children ? (
                    children
                ) : compProps.render ? (
                    compProps.render({ field, fieldState })
                ) : Comp ? (
                    <>
                        <div
                            className={cn("tw-flex", {
                                "tw-mb-2": !!compProps.description,
                            })}
                        >
                            <Comp {...compProps} {...field} ref={(elm: any) => field.ref(normalizeRef(elm))} />
                            {props.popoverText && (
                                <div className="tw-ml-2 tw-text-center tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-px-4 tw-py-2 tw-bg-orange-50 tw-text-orange-400">
                                    {props.popoverText}
                                </div>
                            )}
                        </div>
                        {compProps.description && (
                            <div style={{ color: Colors.CadetGray }}>{compProps.description}</div>
                        )}
                    </>
                ) : null}
                {tooltip && <Form.HelpText tooltip>{tooltip}</Form.HelpText>}
                <Form.ErrorMessage show={!!error} placement={errorPlacement ?? "bottomStart"} style={{ zIndex: 1050 }}>
                    <span test-id={`${testId}-error`}>{error}</span>
                </Form.ErrorMessage>
            </div>
        </Form.Group>
    );
};

CFormGroup.defaultProps = {
    controlWrapperProps: {},
};

export default CFormGroup;
