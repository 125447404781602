import { t } from "lib/i18n";

import TagPickerFormGroup from "components/Form/TagPickerFormGroup";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import { useEffect } from "react";
import SelectFormGroup from "components/Form/SelectFormGroup";
import StorageConfig from "./StorageConfig";
import { useStorages } from "hooks/data-hooks/useStorages";
import _ from "lodash";
import { useSetting } from "hooks/useSetting";
import { UseSettingsFormReturnType } from "../useSettingsForm";

interface IProps {
    form: UseSettingsFormReturnType;
}

const UseStorageConfig = { showOnlyActive: true };

const StockSettingsTab: FCC<IProps> = props => {
    const { setValue, watch } = props.form.form;
    const storages = useStorages(UseStorageConfig);
    const storagesSortOrder = useSetting("brand_storage").value;
    const storagesList = (storages?.list ?? []).sort(
        (a, b) => storagesSortOrder.indexOf(a.id) - storagesSortOrder.indexOf(b.id),
    );
    const storageIds = watch("brand_storage.value") || [];

    useEffect(() => {
        if (storagesList.length === 0) return;

        const _storages = _.cloneDeep(storagesList);
        const availableStorage = _storages
            .filter(storage => storageIds.includes(storage.id))
            .map(storage => storage.id);

        setValue("brand_storage.value", availableStorage);
        // eslint-disable-next-line
    }, [storagesList]);

    return (
        <div className="p-3">
            <TagPickerFormGroup
                name="brand_storage.value"
                label={t("sale_storages")}
                data={storagesList}
                compProps={{ placeholder: t("select", { name: t("storage") }) }}
            />
            <ToggleFormGroup name="stock_minus.value" label={t("can_oversell")} />
            <SelectFormGroup
                name="brand_storage_select.value"
                data={[
                    { name: t("no_config"), id: "0" },
                    { name: t("setup_storage_stations"), id: "1" },
                    { name: t("setup_storage_terminals"), id: "2" },
                    { name: t("setup_storage_by_terminal_stations"), id: "3" },
                ]}
            />
            <StorageConfig
                form={props.form}
                storages={storagesList.filter(storage => storageIds.includes(storage.id))}
            />
        </div>
    );
};

export default StockSettingsTab;
