import React, { FC } from "react";
import { InputPicker, InputPickerProps } from "rsuite";
import { RegisterOptions, useController } from "react-hook-form";
import { GridItemAlignment } from "./grid-item-utils";
import { cn } from "lib/utils";
import _ from "lodash";
import { GridInputError } from "components/NewForm/Grid/GridInputError";
import { normalizeRef } from "components/Form/CFormGroup";

type GridSelectProps = {
    name: string;
    options: Array<{ label: string | number; value: string | number }>;
    selectProps?: Omit<InputPickerProps, "data" | "className">;
    rules?: RegisterOptions;
    index?: number;
    onChange?: (val: string | number) => void;

    postfix?: string;
    align?: GridItemAlignment;
    className?: string;
};

export const GridSelect: FC<GridSelectProps> = ({ name, options = [], onChange, selectProps, rules }) => {
    const renderItem = (label: any, item: any) => {
        return (
            <span test-id={`${name}_item`}>
                {_.times(item.depth).map((x, i) => (
                    <span key={i} className="tw-px-1">
                        --
                    </span>
                ))}
                {label}
            </span>
        );
    };

    const ctrl = useController({ name, rules });
    const _selectProps: GridSelectProps["selectProps"] = {
        placement: "autoVertical",
        value: ctrl.field.value,
        ...selectProps,
    };

    const props: Omit<InputPickerProps, "as"> = {
        ...ctrl.field,
        ..._selectProps,
        onChange: (val: string | number) => {
            ctrl.field.onChange(val);
            onChange?.(val);
        },
        data: options,
        id: name,
        className: cn(
            "tw-rounded tw-bg-transparent tw-w-full tw-h-full tw-flex tw-items-center",
            "hover:tw-transition-colors tw-duration-[1s] hover:tw-ring-1 tw-ring-blue-300 ",
            "hover:tw-border-blue-300 hover:focus-within:tw-border-blue-400",
        ),
        renderMenuItem: selectProps?.renderMenuItem ?? renderItem,
        // placeholder: (
        //     <div className="tw-flex tw-p-0 tw-min-w-min">
        //         <TText tkey="select" />
        //         {ctrl?.fieldState?.error?.message ? <GridInputError message={ctrl?.fieldState?.error?.message} /> : null}
        //     </div>
        // )
    };

    const valueText = options.find(o => o.value === ctrl.field.value)?.label;

    return (
        <div className={"tw-max-w-full tw-relative"}>
            <InputPicker test-id={name} {...props} ref={elm => normalizeRef(elm)} />
            {ctrl.fieldState.error && (
                <div className={"tw-absolute tw-top-0 tw-bottom-0 tw-right-8 tw-z-10"}>
                    <GridInputError message={ctrl.fieldState.error.message} />
                </div>
            )}
            {/* <SelectPicker data={options} {...props} /> */}
            <div className="tw-h-0 tw-overflow-y-hidden tw-whitespace-nowrap tw-px-10">{valueText}</div>
        </div>
    );
};
