import { TText } from "components/i18n/TText";
import React from "react";

import { Divider } from "rsuite";
import { Colors } from "config/colors";

const RowHeaders = () => {
    return (
        <div
            style={{ background: Colors.MoonlightWhite, margin: "6px 0", padding: " 8px" }}
            className="w-100 mt-2 d-flex justify-content-between align-items-center "
        >
            <span className="pl-">
                <TText tkey="type" />
            </span>
            <div className="d-flex flex-row justify-content-end">
                <span>
                    <TText tkey="font_size" />
                </span>
                <Divider vertical style={{ height: "1.5em" }} />
                <span>
                    <TText tkey="status" />
                </span>
            </div>
        </div>
    );
};

export default RowHeaders;
