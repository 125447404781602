import { ASSETS_BASE_URL } from "config/main";
import { Button } from "rsuite";
import CIcon from "../../components/CIcon";
import { iChevronLeft, iChevronRight } from "assets/icons/Icons";
import { Link } from "react-router-dom";
import { Colors } from "config/colors";

interface IProps {
    expand: boolean;
    onChange: any;
}
// const venue = config.venueId;
//
// const apps = [
//     { name: "POS Terminal", image: getAssetUrl("/img/icon-128.png"), href: config.terminalUrl },
//     {
//         name: "eMenu",
//         image: getAssetUrl("/img/qr.png"),
//         href: `${config.qrMenuUrl}?${btoa(JSON.stringify({ vn: venue }))}`,
//     },
//     {
//         name: "Clopos Manager",
//         image: getAssetUrl("/img/android.png"),
//         href: "https://play.google.com/store/apps/details?id=com.clopos.manager",
//     },
//     {
//         name: "Clopos Manager",
//         image: getAssetUrl("/img/ios.png"),
//         href: "https://apps.apple.com/gb/app/clopos-manager/id1536564379",
//     },
// ];
const SidebarHeader: FCC<IProps> = ({ expand, onChange }) => {
    return (
        <div
            style={{ background: Colors.NavyBlue }}
            className="text-center pb-3 pt-3 f-1 position-relative d-flex align-items-center justify-content-center"
        >
            <Link
                to={"/"}
                style={{
                    opacity: expand ? 1 : 0,
                    display: expand ? "block" : "none",
                    marginRight: "6px",
                }}
            >
                <img src={`${ASSETS_BASE_URL}/img/logo.png`} alt="Clopos Logo" className="logo" />
            </Link>
            {/*<a href={BRAND_URL}>*/}
            {/*    <img*/}
            {/*        src={"/img/icon-128.png"}*/}
            {/*        alt="Clopos Logo favicon"*/}
            {/*        className="logo-favicon"*/}
            {/*        style={{*/}
            {/*            opacity: expand ? 0 : 1,*/}
            {/*            margin: expand ? "1rem" : 0,*/}
            {/*            padding: expand ? 0 : "0.5rem",*/}
            {/*            display: expand ? "none" : "block",*/}
            {/*        }}*/}
            {/*    />*/}
            {/*</a>*/}
            {/*{expand && (*/}
            {/*     <div>*/}
            {/*         <Whisper*/}
            {/*             trigger="click"*/}
            {/*             placement="rightStart"*/}
            {/*             // renderTitle={() => {*/}
            {/*             //     return <Icon style={{}} icon="th2" />;*/}
            {/*             // }}*/}
            {/*             speaker={*/}
            {/*                 <Popover className="apps-btn" style={{ maxWidth: 300 }}>*/}
            {/*                     <FlexboxGrid>*/}
            {/*                         {apps.map(_app => (*/}
            {/*                             <FlexboxGrid.Item colspan={12} key={_app.name}>*/}
            {/*                                 <a*/}
            {/*                                     key={_app.name}*/}
            {/*                                     className="text-center p-3 d-flex flex-column align-items-center"*/}
            {/*                                     href={_app.href}*/}
            {/*                                     target="_blank"*/}
            {/*                                     rel="noopener noreferrer"*/}
            {/*                                 >*/}
            {/*                                     <img src={_app.image} width="50" className="" alt="" />*/}
            {/*                                     <Badge*/}
            {/*                                         className="bg-secondary text-nowrap mt-2"*/}
            {/*                                         content={_app.name}*/}
            {/*                                     />*/}
            {/*                                 </a>*/}
            {/*                             </FlexboxGrid.Item>*/}
            {/*                         ))}*/}
            {/*                     </FlexboxGrid>*/}
            {/*                 </Popover>*/}
            {/*             }*/}
            {/*         >*/}
            {/*             <IconButton appearance="subtle" icon={<Icon style={{}} icon="th2" />} />*/}
            {/*         </Whisper>*/}
            {/*     </div>*/}
            {/* )}*/}
            <Button onClick={onChange} appearance="ghost" className="border-0">
                <CIcon style={{ color: Colors.White }} size={0.5} path={expand ? iChevronLeft : iChevronRight} />
            </Button>
            {/* <Nav pullRight>
                <Nav.Item onClick={onChange} style={{ width: 40, textAlign: "center" }}>
                    <Icon style={{ color: "#fff" }} size="lg" icon={expand ? "angle-left" : "angle-right"} />
                </Nav.Item>
            </Nav> */}
        </div>
    );
};

export default SidebarHeader;
