import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import React, { useEffect, useState } from "react";
import CreateButton from "../../../components/DataTable/CreateButton";
import CellBtn from "../../../components/DataTable/CellButton";
import { getProductName } from "lib/utils";
import { useDrawer } from "components/Drawers/useDrawer";
import StorageDrawer from "../misc/StorageDrawer";
import { Loader, Message, SelectPicker, toaster, Toggle } from "rsuite";
import Ajax from "lib/Ajax";
import { useLoading } from "hooks/useLoading";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import CIcon from "../../../components/CIcon";
import { iCheckBoxChecked, iClose } from "assets/icons/Icons";
import { useAppDispatch } from "hooks/useRedux";
import { setSettingByField } from "redux/features/settings";
import { useSetting } from "hooks/useSetting";

const StoragesPage: FCC = () => {
    const auth = useAuth();
    const storageDrawer = useDrawer({ backdrop: false, overflowAuto: true });
    const [op, setOp] = useState<IStorage>();
    const { withLoading } = useLoading();

    const [activeStorages, setActiveStorages] = useState<number[]>([]);
    const [isStorageChanging, setStorageChanging] = useState(0);

    const dispatch = useAppDispatch();

    const brandStorage = useSetting("brand_storage");

    const dp = useDataProvider({
        url: "storage",
        useUrl: true,
        refineList: (data: IStorage[]) => {
            return data
                ?.map(item => {
                    const storageIndex = activeStorages?.indexOf(item?.id);
                    const isActive = storageIndex !== -1;
                    const naturalIndex = storageIndex + 1;

                    return {
                        ...item,
                        storageIndex,
                        order: naturalIndex,
                        isActive,
                    };
                })
                .sort((a, b) => {
                    if (a.isActive !== b.isActive) {
                        return a.isActive ? -1 : 1; // sort for active
                    }
                    return a.order - b.order; //sort for order first min number
                });
        },
    });

    useEffect(() => {
        if (brandStorage?.value) {
            setActiveStorages(brandStorage.value);
        }
    }, [brandStorage?.value]);

    const toggleStorage = async (d: IProduct) => {
        setStorageChanging(d.id);
        setActiveStorages(prev => {
            if (prev.indexOf(d.id) === -1) {
                prev = [...prev, d.id];
            } else {
                prev = prev.filter(p => p !== d.id);
            }
            updateStorage(prev);
            return prev;
        });
    };

    const toggleStorageStatus = async (d: IProduct, checked: boolean) => {
        setStorageChanging(d.id);
        return withLoading(() => {
            return Ajax.post({
                url: `storage/${d.id}?_method=PUT`,
                data: {
                    ...d,
                    status: checked ? 1 : 0,
                },
            });
        })
            .then(async () => {
                if (!checked && activeStorages.includes(d.id)) {
                    return await toggleStorage(d);
                }
                await dp.revalidate();
                setStorageChanging(0);
            })
            .catch((e: CustomError) => {
                toaster.push(
                    <Message type="error" showIcon closable>
                        {e.message}
                    </Message>,
                );
                setStorageChanging(0);
            });
    };

    const swapOrder = (prevIndex: number, nextIndex: number) => {
        if (prevIndex === nextIndex) return;
        setActiveStorages(prev => {
            const dummyData = [...prev];
            const currentItem = dummyData[prevIndex];
            const targetItem = dummyData[nextIndex];

            dummyData[nextIndex] = currentItem;
            dummyData[prevIndex] = targetItem;

            updateStorage(dummyData);
            return dummyData;
        });
    };

    const updateStorage = async (value: number[]) => {
        return withLoading(() => {
            return Ajax.post({
                url: "setting/brand_storage?_method=PUT",
                data: {
                    ...brandStorage?.value,
                    value: JSON.stringify(value),
                },
            });
        })
            .then(async response => {
                await dp.revalidate();
                setStorageChanging(0);

                dispatch(
                    setSettingByField({
                        brand_storage: {
                            ...brandStorage,
                            value: JSON.parse(response?.data.value || "[]"),
                        },
                    }),
                );
            })
            .catch((e: CustomError) => {
                toaster.push(
                    <Message type="error" showIcon closable>
                        {e.message}
                    </Message>,
                );
                setStorageChanging(0);
            });
    };

    const cm = useColumns<any>("storage", [
        {
            key: "name",
            align: "left",
            render: d => (
                <CellBtn
                    action={() => {
                        setOp(d);
                        storageDrawer.setDataId(d.id);
                    }}
                    label={getProductName(d)}
                />
            ),
        },
        {
            key: "status",
            width: 50,
            title: t("status"),
            render: e => {
                if (isStorageChanging === e?.id) {
                    return <Loader />;
                } else {
                    return (
                        <Toggle
                            test-id={`status_toggle`}
                            checked={!!e.status}
                            checkedChildren={<CIcon path={iCheckBoxChecked} style={{ marginBottom: "-3px" }} />}
                            unCheckedChildren={<CIcon path={iClose} style={{ marginBottom: "-3px" }} />}
                            onChange={async checked => toggleStorageStatus(e, checked)}
                            disabled={!auth.hasAbility(["setting_general"])}
                        />
                    );
                }
            },
        },
        {
            key: "sale_storage_status",
            width: 50,
            title: t("sale_storage_status"),
            render: e =>
                isStorageChanging === e?.id ? (
                    <Loader />
                ) : (
                    <Toggle
                        test-id={`sale_storage_toggle`}
                        checked={activeStorages?.indexOf(e?.id) !== -1}
                        checkedChildren={<CIcon path={iCheckBoxChecked} style={{ marginBottom: "-3px" }} />}
                        unCheckedChildren={<CIcon path={iClose} style={{ marginBottom: "-3px" }} />}
                        onChange={() => toggleStorage(e)}
                        disabled={!auth.hasAbility(["setting_general"]) || !e.status}
                    />
                ),
        },
        {
            key: "order",
            title: t("write_off_sequence"),
            type: "text",
            render: e => {
                if (isStorageChanging === e?.id) {
                    return <Loader />;
                }
                if (e.isActive) {
                    return (
                        <SelectPicker
                            test-id={`order-picker`}
                            onChange={orderIndex => swapOrder(e?.storageIndex, orderIndex - 1)}
                            data={[...Array(activeStorages.length)].map((_o, i) => ({
                                label: i + 1,
                                value: i + 1,
                            }))}
                            defaultValue={e.order}
                            value={e.order}
                            searchable={false}
                            cleanable={false}
                            size="xs"
                            disabled={!auth.hasAbility(["setting_general"]) || !e.status}
                        />
                    );
                } else {
                    return <span test-id="order-empty-data">--</span>;
                }
            },
        },
        optionsColumn({
            dp,
            edit: "/inventory/storages/edit/:id",
            delete: "storage/:id",
            canDuplicate: "stock_manage_storage_manage",
            canEdit: "stock_manage_storage_manage",
            canDelete: "stock_manage_storage_manage",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("storage"), dp.total)}
            <HeaderRight dp={dp} cm={cm} print reload />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="name" />
                </div>
                <CreateButton to="/inventory/storages/create" canCreate={"stock_manage_storage"} />
            </FiltersContainer>

            <DataTable dp={dp} columns={cm.columns} />

            <StorageDrawer
                {...storageDrawer}
                op={op}
                title={`${t("storage")} #${storageDrawer.dataId} : ${op?.name}`}
            />
        </div>
    );
};

export default StoragesPage;
