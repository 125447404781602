import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import _ from "lodash";
import { CheckPicker, Col, Divider, Grid, Row } from "rsuite";
import ConfirmModal from "../../../components/Drawers/ConfirmModal";
import { useDrawer } from "components/Drawers/useDrawer";
import CForm from "components/Form/CForm/CForm";
import CFormGroup from "../../../components/Form/CFormGroup";
import CheckboxFormGroup from "../../../components/Form/CheckboxFormGroup";
import UploaderFormGroup from "../../../components/Form/FileUploadFormGroup";
import TextFormGroup from "../../../components/Form/TextFormGroup";
import ToggleFormGroup from "../../../components/Form/ToggleFormGroup";
import JsonView from "../../../components/JsonView";
import { onConfirmCreateExists, onSubmitCheckName } from "components/Partials/CheckNameUtil";
import { CategoryTypeEnum, ProductType, TimerCalcMethod } from "config/constants";
import { useCForm } from "hooks/useCForm";
import { useData } from "hooks/useData";
import { useLoading } from "hooks/useLoading";
import { isUrl } from "lib/utils";
import { dummyTimer, dummyTimerPrice, TimerModel } from "models/GoodsModel";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import ItemsTab from "../../inventory/misc/ItemsTab";
import CreateCategoryModal from "../../menu/categories/CreateCategoryModal";
import TimerItem from "./TimerItem";
import TimerRadioGroupField from "./form/TimerRadioGroupField";
import React, { FC, useMemo } from "react";
import "./Times.scss";
import MenuCategorySelectFormGroup from "../../../components/Form/CategorySelectFormGroup/MenuCategorySelectFormGroup";
import { useCurrency } from "hooks/useCurrency";

const seviceChargeOptions = [
    {
        label: "calculated",
        value: 0,
    },
    {
        label: "not_calculated",
        value: 1,
    },
];

const discountAndGiftableOptions = [
    {
        label: "yes",
        value: 1,
    },
    {
        label: "non",
        value: 0,
    },
];

const CalculationMethodsOptions = [
    {
        label: "orders_in_timer",
        desc: "orders_in_desc",
        value: TimerCalcMethod.IN,
    },
    {
        label: "orders_exc_timer",
        desc: "orders_exc_desc",
        value: TimerCalcMethod.OUT,
    },
];

const statusOptions = [
    {
        value: 1,
        label: "deposit_payment_timer",
        subtitle: "deposit_payment_timer_desc",
        disabled: false,
    },
    {
        value: 0,
        label: "deposit_payment_once",
        subtitle: "deposit_payment_once_desc",
        disabled: true,
    },
];

const CreateTimerPage: FC = () => {
    const auth = useAuth();
    const currency = useCurrency();
    const modal = useDrawer();
    const taxes = useData<IStorage[]>("finance/taxes", {}, auth.hasAbility(["tax_view"]));
    const existGoodModal = useDrawer();
    const { isLoading } = useLoading();

    const form = useCForm({
        url: "product/timer",
        model: TimerModel(),
        // redirectUrl: "/menu/products/",
        with: ["media", "taxes"],

        dummyData: dummyTimer,
        refine: (d: any): Partial<ITimerProduct> => {
            d.calculation_methods_check = d.setting.prices.length;
            d.payment_type = 1;
            d.status = !d.hidden;
            d.ignore_service_charge = d.ignore_service_charge ? 1 : 0;
            d.setting.notification = d.setting.notification ? 1 : 0;
            if (d.calculation_methods_check) {
                d.setting.prices = d.setting.prices.map((item: ITimerPrice) => {
                    item.notification = item.notification ? 1 : 0;
                    return item;
                });
            }

            // d.setting.prices = d.setting.prices.splice(1, d.setting.prices.length - 1);
            return !_.isEmpty(d.media)
                ? {
                      images: [{ uuid: d.media[0]?.urls?.thumb }],
                      ...d,
                  }
                : d;
        },
        prepareForSubmit: (d: any) => {
            const images = d.images?.length ? d.images[0].uuid : "";
            const fields = isUrl(images) ? ["image", "images"] : ["image"];
            d = _.omit(d, fields.concat(["created_at", "updated_at"]));
            d.price = +d.price;
            d.hidden = !d.status;
            d.setting.interval = +d.setting.interval;
            d.setting.notification = d.setting.notification === 1;
            d.setting.prices = [
                // {
                //     price: +d.price,
                //     from: +d.setting.interval,
                // },
                ...(d.calculation_methods_check
                    ? d.setting.prices
                          .filter((i: ITimerPrice) => +i.from !== 0)
                          .map((i: ITimerPrice) => ({
                              price: +i.price,
                              from: +i.from,
                              notification: i.notification === 1,
                          }))
                    : []),
            ];
            d.ignore_service_charge = d.ignore_service_charge === 1;
            d.hidden = d.hidden ? 1 : 0;
            d.status = d.status ? 1 : 0;
            return _.omit(d, ["calculation_methods_check", "modifications"]);
        },
    });
    const calculation_methods_check = form.form.watch("calculation_methods_check");

    const meta = form.form.watch("meta");
    const calculation_methods = form.form.watch("setting.calculation_methods"); // 1 | 0
    const formTitle = useFormTitle(
        form.id,
        "",
        t("timer") + " / " + `${form.form.getValues("name")} - (ID: ${form.id})`,
    );
    const name = form.form.watch("name");

    const labelProps = useMemo(() => {
        return {
            style: {
                width: "250px",
            },
        };
    }, []);

    const pricePath = `setting.prices[${0}]`;

    const commonProps = (name: string, extra?: any): any => ({
        className: "",
        name: `setting.prices[${0}].${name}`,
        ...extra,
        compProps: {
            placeholder: t(name as LangKey),
            style: { width: 100 },
            type: "number",
            ...extra?.compProps,
        },
    });

    return (
        <div className="tw-p-4 h-100 timer-form">
            <>
                {formTitle}
                <HeaderRight
                    formSubmit={{
                        isLoading: form.isLoading,
                        onSave: async (event, isSaveCreate) => {
                            await onSubmitCheckName({
                                form,
                                existGoodModal,
                                type: ProductType.TIMER,
                                isSaveCreate,
                                event,
                            });
                        },
                    }}
                />
            </>
            <CForm form={form.form} onSave={form.onSave}>
                <Row className="show-grid">
                    <Col md={2} sm={3} lg={2}>
                        <span className="input-label bold">
                            <TText tkey="status" />
                        </span>
                    </Col>
                    <Col md={9} sm={12} lg={6}>
                        <ToggleFormGroup
                            compProps={{
                                defaultChecked: true,
                            }}
                            style={{ marginBottom: 0 }}
                            name="status"
                            hideLabel={true}
                            label={t("hide_on_terminal")}
                        />
                    </Col>
                </Row>
                <div className="timer-title">
                    <TText tkey="deposit_payment_type" />
                </div>
                <TimerRadioGroupField
                    hideLabel
                    options={statusOptions}
                    labelProps={labelProps}
                    radioName="payment_type"
                    form={form}
                />
                <Divider />
                <div className="timer-title">
                    <TText tkey="settings" />
                </div>
                <Grid fluid>
                    <Row className="show-grid">
                        <Col md={6} sm={6} lg={3}>
                            <span className="input-label">
                                <TText tkey="name" />
                            </span>
                        </Col>
                        <Col md={9} sm={12} lg={6}>
                            <TextFormGroup
                                compProps={{ style: { width: "100%" } }}
                                name="name"
                                label={false}
                                errorPlacement="rightEnd"
                                focus={true}
                            />
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={6} sm={6} lg={3}>
                            <span className="input-label">
                                <TText tkey="image" />
                            </span>
                        </Col>
                        <Col md={9} sm={12} lg={6}>
                            <UploaderFormGroup
                                name="images[0].uuid"
                                removeMainKey={true}
                                label={false}
                                buttonStyle={{ height: 90, width: 160 }}
                                fileInfoStyle={{ height: 90, width: 160 }}
                                compProps={{
                                    action: "media",
                                    multiple: false,
                                    listType: "picture",
                                    name: "media_file",
                                }}
                            />
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={6} sm={6} lg={3}>
                            <span className="input-label">
                                <TText tkey="category" />
                            </span>
                        </Col>
                        <Col md={9} sm={12} lg={6}>
                            <MenuCategorySelectFormGroup
                                style={{ width: "100%" }}
                                hideLabel={true}
                                compProps={{ style: { width: "100%" } }}
                                disabled={meta?.fromMenuConstruct}
                                modal={modal}
                                revalidate={modal.dataId}
                                types={[CategoryTypeEnum.PRODUCT]}
                            />
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={6} sm={6} lg={3}>
                            <span className="input-label">
                                <TText tkey="accounting_category" />
                            </span>
                        </Col>
                        <Col md={9} sm={12} lg={6}>
                            <MenuCategorySelectFormGroup
                                name="accounting_category_id"
                                hideLabel={true}
                                style={{ width: "100%" }}
                                compProps={{ style: { width: "100%" } }}
                                types={[CategoryTypeEnum.ACCOUNTING]}
                                modal={modal}
                                revalidate={modal.dataId}
                                type={CategoryTypeEnum.ACCOUNTING}
                                disabled={meta?.fromMenuConstruct}
                            />
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={6} sm={6} lg={3}>
                            <span className="input-label">
                                <TText tkey="taxes" />
                            </span>
                        </Col>
                        <Col md={9} sm={12} lg={6}>
                            <CFormGroup name="taxes" hideLabel compProps={{ style: { width: "350px !important" } }}>
                                <CheckPicker
                                    test-id="taxes"
                                    data={_.map(taxes?.data, (t: any) => ({
                                        value: t?.id,
                                        label: `${t?.name} (${t?.rate}%)`,
                                    }))}
                                    value={_.map(form.form.getValues("taxes"), (t: any) => t?.id)}
                                    onChange={tax =>
                                        form.form.setValue(
                                            "taxes",
                                            _.intersectionWith(taxes?.data as any, tax, (a: any, b) => a.id === b),
                                        )
                                    }
                                    onClean={() => form.form.setValue("taxes", [])}
                                    placeholder={t("select", { name: t("tax") })}
                                    style={{
                                        width: "100%",
                                    }}
                                />
                            </CFormGroup>
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={6} sm={6} lg={3}>
                            <span className="input-label">
                                <TText tkey="service_charge" />
                            </span>
                        </Col>
                        <Col md={9} sm={12} lg={6}>
                            <TimerRadioGroupField
                                hideLabel
                                options={seviceChargeOptions}
                                labelProps={labelProps}
                                radioName={"ignore_service_charge"}
                                form={form}
                            />
                        </Col>
                    </Row>
                    <Row className="show-grid">
                        <Col md={6} sm={6} lg={3}>
                            <span className="input-label">
                                <TText tkey="allow_discount" />
                            </span>
                        </Col>
                        <Col md={9} sm={12} lg={6}>
                            <TimerRadioGroupField
                                hideLabel
                                options={discountAndGiftableOptions}
                                labelProps={labelProps}
                                radioName={"discountable"}
                                form={form}
                            />
                        </Col>
                    </Row>
                    {/*<Row className="show-grid">*/}
                    {/*    <Col md={6} sm={6} lg={3}>*/}
                    {/*        <span className="input-label"><TText key="giftable" /></span>*/}
                    {/*    </Col>*/}
                    {/*    <Col md={9} sm={12} lg={6}>*/}
                    {/*        <TimerRadioGroupField*/}
                    {/*            hideLabel*/}
                    {/*            options={discountAndGiftableOptions}*/}
                    {/*            labelProps={labelProps}*/}
                    {/*            radioName={"giftable"}*/}
                    {/*            form={form}*/}
                    {/*        />*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}
                    <Row className="show-grid">
                        <Col md={6} sm={6} lg={3}>
                            <span className="input-label">
                                <TText tkey="orders" />
                            </span>
                        </Col>
                        <Col md={9} sm={12} lg={6}>
                            <TimerRadioGroupField
                                hideLabel
                                options={CalculationMethodsOptions}
                                labelProps={labelProps}
                                radioName={"setting.calculation_methods"}
                                form={form}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={6} sm={6} lg={3}>
                            <span className="input-label">
                                <TText tkey="price" />
                            </span>
                        </Col>
                        <Col md={9} sm={12} lg={6}>
                            <div className="py-3 px-1">
                                <div className="d-flex ml-0 mb-1">
                                    <Grid fluid className="interval-grid">
                                        <Row>
                                            <Col md={24} sm={24} lg={24}>
                                                <Divider />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} sm={12} lg={12}>
                                                <TextFormGroup
                                                    name="setting.interval"
                                                    label={t("in_every")}
                                                    type="number"
                                                    postFix={t("minute_short")}
                                                    compProps={{
                                                        style: { height: 36 },
                                                        min: 0,
                                                        spinner: true,
                                                        className: "interval-input",
                                                    }}
                                                    className="pr-2"
                                                />
                                            </Col>
                                            <Col md={12} sm={12} lg={12}>
                                                <TextFormGroup
                                                    label=" "
                                                    name="price"
                                                    className="pl-2 mt-2 interval-price"
                                                    type="money"
                                                    compProps={{
                                                        style: { height: 36 },
                                                        min: 0,
                                                        className: "interval-input",
                                                    }}
                                                    labelProps={{ style: { marginTop: "4px" } }}
                                                    postFix={currency}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} sm={12} lg={12}>
                                                <CheckboxFormGroup
                                                    compProps={{
                                                        disabled: !form.form.getValues("setting.interval"),
                                                    }}
                                                    controlWrapperProps={{
                                                        style: { paddingLeft: 0, marginLeft: "-10px" },
                                                    }}
                                                    name={"calculation_methods_check"}
                                                    label={t("has_different_duration")}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col md={12} sm={12} lg={12}>
                                                <CheckboxFormGroup
                                                    compProps={{
                                                        disabled: !form.form.getValues("setting.interval"),
                                                    }}
                                                    controlWrapperProps={{
                                                        style: { paddingLeft: 0, marginLeft: "-10px" },
                                                    }}
                                                    name="setting.notification"
                                                    label={t("notification")}
                                                />
                                            </Col>
                                        </Row>
                                    </Grid>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    {calculation_methods_check ? (
                        <Row>
                            <Col md={6} sm={6} lg={3}></Col>
                            <Col md={9} sm={12} lg={6}>
                                <ItemsTab
                                    buttonToolbarClassName="w-100"
                                    buttonClassName="interval-add-button"
                                    buttonTitle={t("add_intervals")}
                                    itemsFieldName="setting.prices"
                                    ItemComponent={TimerItem}
                                    columns={[]}
                                    dummyData={dummyTimerPrice}
                                />
                            </Col>
                        </Row>
                    ) : null}
                </Grid>
                <JsonView />
            </CForm>
            <CreateCategoryModal {...modal} />
            <ConfirmModal
                isLoading={isLoading}
                {...existGoodModal}
                onConfirm={async (id, event) => {
                    await onConfirmCreateExists({
                        event: event!,
                        form,
                        existGoodModal,
                        // saveCreate: !isDuplicate,
                    });
                }}
                onClose={() => existGoodModal.setIsOpen(false)}
                content={t("create_goods_by_exists_name", { name })}
            />
        </div>
    );
};

export default CreateTimerPage;
