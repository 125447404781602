import { useAuth } from "providers/AuthProvider/AuthProvider";
import { loadAllData } from "lib/api-utils";
import _ from "lodash";
import React, { createContext, useContext, useEffect, useState } from "react";

export interface IProductsContext {
    data: IProduct[];
    refresh: () => void;
    loading: boolean;
}

const ProductsContext = createContext<IProductsContext>({
    data: [],
    refresh: _.noop,
    loading: false,
});

export const useProducts = () => useContext(ProductsContext);

interface ProductProviderProps {
    url?: string | boolean;
    with?: string[];
    sort?: string[];
    filters?: SearchRequestFiltersMap;
    refineList?: (list: IProduct[]) => IProduct[];
    params?: any;
    noReq?: boolean;
    refresh?: any;
}

export const ProductsProvider: FCC<ProductProviderProps> = props => {
    const [products, setProducts] = useState<IProduct[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const auth = useAuth();

    const fetch = async () => {
        const url = !_.isNil(props.url) ? props.url : "product";
        if ((!auth.isLoggedIn && !_.isNil(props.noReq) && !props.noReq) || !url) {
            setIsLoading(false);
            return;
        }
        setIsLoading(true);
        loadAllData(url as string, {
            with: props.with,
            sort: props.sort,
            filters: _.values(props.filters),
            ...props.params,
        }).then(list => {
            list = props.refineList?.(list) ?? list;
            setProducts(list);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        fetch();
    }, [auth.isLoggedIn, props.refresh]);

    return (
        <ProductsContext.Provider value={{ data: products, loading: isLoading, refresh: fetch }}>
            {props.children}
        </ProductsContext.Provider>
    );
};
