import { t } from "lib/i18n";
import { Modal } from "rsuite";
import CModal from "./Drawers/CModal";

interface IProps {
    modal: any;
}

const ErrorModal: FCC<IProps> = props => {
    return (
        <CModal {...props.modal} backdrop title={t("error")}>
            <Modal.Body className="pb-0">
                {props.modal.data?.message}
                <div>
                    <pre>{props.modal.data?.models?.map((m: any) => m.context).join("\n")}</pre>
                </div>
            </Modal.Body>
        </CModal>
    );
};

export default ErrorModal;
