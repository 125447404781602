import * as Sentry from "@sentry/browser";
import { Lang, t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React, { useRef, useState } from "react";
import CModal, { ICModalProps } from "../CModal";
import { Button, Message, Modal, Notification, toaster, Uploader, UploaderProps } from "rsuite";
import CIcon from "../../CIcon";
import { iCheckCircle, iUploadCloud } from "assets/icons/Icons";
import "./CUploaderModal.scss";
import { Colors } from "config/colors";
import { cn } from "lib/utils";
import { ExcelIcon, InfoIcon } from "assets/icons";
import { useAppSelector } from "hooks/useRedux";
import { useRemoteConfig } from "hooks/useRemoteConfig";
import Ajax from "lib/Ajax";
import { useDepot } from "hooks/useDepot";
import { config } from "config/Config";
import { FileType } from "rsuite/esm/Uploader/Uploader";

interface ICUploaderModalProps extends ICModalProps {
    importOptions: IImportOption;
}

const CUploaderModal: FCC<ICUploaderModalProps> = ({ importOptions, ...props }) => {
    const [fileList, setFileList] = useState<any>([]);
    const [isLoading, setIsLoading] = useState(false);
    const uploader: any = useRef(null);
    const language = useAppSelector(state => state.lang).current as Lang;
    const { currentVenueId } = useAppSelector(state => state.venue);
    const [user] = useDepot<IUser | undefined>("user");

    const detailLink = useRemoteConfig("importScheme").config;

    const downloadTemplate = async () => {
        try {
            const url = `https://cdn.clopos.com/_clopos/client/templates/${importOptions.template}.xlsx`;
            await Ajax.download({ url: url }, `${importOptions.template}`);
        } catch (e) {
            //log.runtimeError(e);
            Sentry.captureException(e);
        }
    };

    const xhrOptions: UploaderProps = {
        action: config.apiUrl + importOptions.action,
        onSuccess: (response: any, file: FileType, event: ProgressEvent, xhr: XMLHttpRequest) => {
            toaster.push(
                <Message type="success" showIcon header={t("success")} closable>
                    <TText tkey="added_success" />
                </Message>,
            );
            importOptions.onSuccess?.(response, file, event, xhr);
        },
        onError: () => (
            <Notification closable type="error">
                <TText tkey="import_error" />
            </Notification>
        ),
        headers: {
            Authorization: "Bearer " + user?.token,
            "X-Auth-Token": user?.token,
            "x-venue": currentVenueId,
        },
    };

    return (
        <CModal {...props} backdrop size="sm">
            <Modal.Header>
                <h4 className={cn("tw-capitalize")}>
                    <TText tkey="import" />
                </h4>
                <span className={cn("tw-text-gray-500 tw-text-xs tw-font-medium tw-leading-tight")}>
                    <TText tkey="import_modal_header" />
                </span>
            </Modal.Header>
            <Modal.Body>
                <Uploader
                    onChange={fileList => {
                        setFileList(fileList.length ? [fileList[fileList.length - 1]] : []);
                    }}
                    ref={uploader}
                    className="tw-cursor-pointer"
                    draggable
                    onProgress={(percent, file, event, xhr) => {
                        setIsLoading(true);
                        xhr.onreadystatechange = function () {
                            if (this.readyState === 4 && this.status !== 200) {
                                const { error: errors } = JSON.parse(this?.responseText ?? "{}");

                                if (errors?.[0]?.message) {
                                    toaster.push(
                                        <Message type="error" showIcon header={t("error")} closable>
                                            {errors?.[0]?.message}
                                        </Message>,
                                        {
                                            duration: 4000,
                                        },
                                    );
                                } else {
                                    toaster.push(
                                        <Message type="error" showIcon header={t("error")} closable>
                                            <TText tkey="went_wrong" />
                                        </Message>,
                                        {
                                            duration: 4000,
                                        },
                                    );
                                }
                                setIsLoading(false);
                                props.setIsOpen(false);
                            } else if (this.readyState === 4 && this.status === 200) {
                                const { message } = JSON.parse(this?.responseText ?? "{}");

                                toaster.push(
                                    <Message type="success" showIcon header={t("success")} closable>
                                        {message}
                                    </Message>,
                                    { duration: 4000 },
                                );
                                setFileList([]);
                                setIsLoading(false);
                                props.setIsOpen(false);
                            }
                        };
                    }}
                    renderFileInfo={(file, fileElement) => {
                        return fileList.length ? (
                            <div className="pr-2 d-flex align-items-center tw-font-medium">
                                <span
                                    style={{
                                        color: Colors.CobaltBlue,
                                        fontSize: "14px",
                                        paddingTop: "100px",
                                    }}
                                >
                                    {fileList[fileList?.length - 1]?.name || ""}
                                </span>
                            </div>
                        ) : null;
                    }}
                    fileList={fileList}
                    autoUpload={false}
                    {...xhrOptions}
                >
                    <div
                        style={{
                            height: "160px",
                            width: "100%",
                            display: "flex",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        {fileList?.length ? (
                            <div>
                                <div className="d-flex align-items-center justify-content-center pt-2">
                                    <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{
                                            borderRadius: "50%",
                                            background: Colors.PaleMintGreen,
                                            height: "56px",
                                            width: "56px",
                                        }}
                                    >
                                        <div
                                            className="d-flex align-items-center justify-content-center"
                                            style={{
                                                borderRadius: "50%",
                                                background: Colors.MintGreen,
                                                height: "40px",
                                                width: "40px",
                                            }}
                                        >
                                            <CIcon
                                                size={1}
                                                path={iCheckCircle}
                                                style={{ color: Colors.TropicalGreen, fillRule: "evenodd" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="d-flex align-items-center justify-content-center pt-2">
                                    <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{
                                            borderRadius: "50%",
                                            background: Colors.BabyBlue,
                                            height: "56px",
                                            width: "56px",
                                        }}
                                    >
                                        <div
                                            className="d-flex align-items-center justify-content-center"
                                            style={{
                                                borderRadius: "50%",
                                                background: Colors.LightAzureBlue,
                                                height: "40px",
                                                width: "40px",
                                            }}
                                        >
                                            <CIcon
                                                size={1}
                                                path={iUploadCloud}
                                                style={{ color: Colors.CobaltBlue, fillRule: "evenodd" }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="pt-3">
                                    <span style={{ color: Colors.CobaltBlue }}>
                                        <TText tkey="select_file" />
                                    </span>{" "}
                                    <span>
                                        <TText tkey="or_drop" />
                                    </span>
                                </div>
                                <div style={{ fontSize: "11px", color: Colors.SteelGray }}>
                                    <TText tkey="max_file_size_50" />
                                </div>
                            </>
                        )}
                    </div>
                </Uploader>
            </Modal.Body>
            <Modal.Footer>
                <div className={cn("tw-flex tw-justify-between tw-items-center tw-gap-x-6")}>
                    {importOptions?.template && (
                        <div
                            onClick={downloadTemplate}
                            className={cn("tw-flex tw-justify-between tw-items-center tw-gap-x-2 tw-cursor-pointer")}
                        >
                            <img src={ExcelIcon} className={cn("tw-w-[24px] tw-h-[24px]")} />
                            <div className={cn("tw-whitespace-nowrap tw-text-left tw-font-semibold tw-underline")}>
                                <TText tkey="download_template" />
                            </div>
                        </div>
                    )}
                    {importOptions?.detail && detailLink && (
                        <a
                            className={cn("tw-flex tw-justißfy-between tw-items-center tw-gap-x-2 tw-cursor-pointer")}
                            href={detailLink?.[importOptions?.detail]?.[language]}
                            target="_blank"
                        >
                            <img src={InfoIcon} className={cn("tw-w-[24px] tw-h-[24px]")} />
                            <div className={cn("tw-text-left tw-text-blue-500 tw-text-base tw-font-semibold")}>
                                <TText tkey="more_details" />
                            </div>
                        </a>
                    )}
                    <Button
                        className="rs-btn05"
                        disabled={!fileList.length}
                        onClick={() => {
                            uploader?.current?.start();
                        }}
                        loading={isLoading}
                        appearance="primary"
                        style={{
                            fontWeight: "bold",
                            borderRadius: "8px",
                            width: "100%",
                            height: "40px",
                        }}
                    >
                        <TText tkey="import_btn" />
                    </Button>
                </div>
            </Modal.Footer>
        </CModal>
    );
};

export default CUploaderModal;
