import { useSetting } from "hooks/useSetting";
import { useIntegrationByName } from "providers/PackageProvider";

/**
 * When it was a good old days, we used to store languages in global settings.
 * But as e-menu is an integration and integrations have ability to store their own settings,
 * we decided to move these into e-menu integration settings.
 * And most used settings of e-menu integration was "langs" which was in wide use. That's why we created this hook.
 *
 * By the way, "integration settings" are called "payloads".
 */
export const useEmenuLanguages = (): string[] => {
    const oldEmenuLangs = useSetting("langs");
    const emenuIntegration = useIntegrationByName("emenu");

    return emenuIntegration?.payload?.langs || oldEmenuLangs?.value || [];
};
