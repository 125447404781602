import { TText } from "components/i18n/TText";
import React, { FC, useState } from "react";
import CModal, { ICModalProps } from "../../Drawers/CModal";
import { Colors } from "config/colors";
import { ExcelIcon } from "../../../assets/icons";
import { cn } from "lib/utils";
import ModalBody from "rsuite/cjs/Modal/ModalBody";
import ModalFooter from "rsuite/cjs/Modal/ModalFooter";
import { Modal } from "rsuite";
import { ExportModalFileList } from "./ExportModalFileList";
import { ExportModalButton } from "./ExportModalButton";
import { ExportType } from "config/constants";

type IProps = ICModalProps & {
    type?: ExportType;
    exportOptions: IExportProps[];
    haveExportList?: boolean;
};

const ExportModal: FC<IProps> = ({ type, exportOptions, haveExportList, ...props }) => {
    const [selected, setSelected] = useState<IExportProps | null>(exportOptions.length === 1 ? exportOptions[0] : null);
    const [isLoading, setIsLoading] = useState(false);

    const renderItem = (item: IExportProps) => {
        return (
            <div
                key={item.name}
                onClick={() => setSelected(item)}
                className={cn(
                    "hover:tw-bg-blue-100 tw-cursor-pointer tw-gap-6 tw-p-6 tw-inline-flex tw-border-2 tw-w-full tw-border-solid tw-rounded-xl tw-border-gray-300 tw-flex-col tw-justify-center tw-items-center",
                    {
                        "tw-border-blue-500": selected?.name === item.name,
                    },
                )}
            >
                <div
                    className="tw-flex tw-items-center tw-relative tw-justify-center rounded-circle"
                    style={{ width: "62px", height: "62px", background: Colors.BabyBlue }}
                >
                    <div className="tw-absolute rounded-circle" style={iconBack} />
                    <img src={ExcelIcon} className={cn("tw-w-[24px] tw-h-[24px] tw-z-10")} alt={item.name} />
                </div>
                <span className={cn("tw-text-center tw-text-gray-500 tw-text-[11px] tw-font-normal")}>{item.name}</span>
            </div>
        );
    };

    const handleExport = async () => {
        if (selected) {
            setIsLoading(true);
            await selected.onClick?.();
            setIsLoading(false);
        }
    };

    return (
        <CModal style={headerStyle} {...props} size="sm" testId="export-modal">
            <Modal.Header>
                <h4 className={cn("tw-capitalize")}>
                    <TText tkey="export" />
                </h4>
            </Modal.Header>
            <ModalBody>
                <div className={cn("tw-flex tw-justify-around tw-gap-x-5")}>
                    {exportOptions && exportOptions.map(option => renderItem(option))}
                </div>
            </ModalBody>
            <ModalFooter>
                {haveExportList ? (
                    <ExportModalFileList type={type} selected={selected} />
                ) : (
                    <ExportModalButton disabled={!selected?.name} isLoading={isLoading} onClick={handleExport} />
                )}
            </ModalFooter>
        </CModal>
    );
};

export default ExportModal;
const headerStyle = {
    fontWeight: 600,
    fontSize: 20,
    color: Colors.GunmetalBlue,
};
const iconBack = {
    width: "42px",
    height: "42px",
    background: Colors.LightAzureBlue,
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    zIndex: 0,
};
