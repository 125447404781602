import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import TabsView from "components/TabsView";
import _ from "lodash";
import DishModificationsGroupTab from "pages/products/dishes/drawer/DishModificationsGroupTab";
import DishRecipeTab from "pages/products/dishes/drawer/DishRecipeTab";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Message, toaster } from "rsuite";
import { iCheckBoxChecked } from "assets/icons/Icons";
import CIcon from "../../../../components/CIcon";
import CDrawerHeaderRight from "../../../../components/Drawers/CDrawerHeaderRight";
import { ProductType } from "config/constants";
import { GoodSchema } from "form-schemas/GoodSchema";
import { useCForm } from "hooks/useCForm";
import Ajax from "../../../../lib/Ajax";
import { boolToNumber, isUrl } from "lib/utils";
import { dummyGoods } from "models/GoodsModel";
import { useIntegrationByName, usePackages } from "providers/PackageProvider";
import UsageTabBeta from "../../../menu/preparations/drawer/tabs/UsageTabBeta";
import TimerInfoTab from "../../timer/drawer/TimerInfoTab";
import EMenuTap from "./EmenuTab";
import ProductInfoTab from "./InfoTab";
import InUseModifier from "./InUsedModifier";
import { Limitation } from "types/routes";
import { useSetting } from "hooks/useSetting";
import { useEmenuLanguages } from "hooks/useEmenuLanguages";

interface IProps extends Omit<ICDrawerProps, "dp"> {
    dp: {
        revalidate: (d?: any) => Promise<boolean>;
    };
}

const QuickProductDrawer: FCC<IProps> = props => {
    const [totalCount, setTotalCount] = useState<number>(0);
    const [countData, setCountData] = useState<any>();
    const [tabs, setTabs] = useState<any[]>([]);
    const packages = usePackages();
    const params: any = useParams();
    const returnsMaxAge = useSetting("returns_max_age")?.value;
    const [activeTab, setActiveTab] = useState<number>(0);
    const [response, setResponse] = useState<any>(null);
    const emenuLangs = useEmenuLanguages();

    const emenuIntegration = useIntegrationByName("emenu");

    const goodsPrepareSubmit = (d: any) => {
        const images = d.images?.length ? d.images[0].uuid : "";
        const fields = isUrl(images) ? ["image", "images"] : ["image"];
        d = _.omit(d, fields.concat(["created_at", "updated_at"]));
        d.max_age = d.max_age || null;
        d.giftable = d.giftable === 1;
        d.hidden = d.hidden === 1;
        d.discountable = d.discountable === 0;
        d.price = d.price == "" ? 0 : d.price;
        d.emenu_hidden = d.emenu_hidden ? 1 : 0;
        return params?.id || d.has_modifications ? d : _.omit(d, ["modifications"]);
    };

    const prepareLangsMeta = (sss?: any) => {
        const ll: any = {};
        emenuLangs.forEach((lang: string) => {
            ll[lang] = {
                name: sss?.[lang]?.name || "",
                description: sss?.[lang]?.description || "",
            };
        });
        return ll;
    };

    const goodRefine = (d: any) => {
        d = _.pickBy(d, _.identity);
        d.setting_max = returnsMaxAge;
        if (packages?.limitation?.[Limitation.EMENU]) {
            d.meta = {
                ...d.meta,
                langs: prepareLangsMeta(d.meta?.langs),
            };
        }
        d.hidden = boolToNumber(d.hidden, 0, 1);
        d.giftable = boolToNumber(d.giftable, 0, 1);
        d.discountable = boolToNumber(d.discountable, 1, 0);
        d.price = d.price || 0;

        return !_.isEmpty(d.media)
            ? {
                  images: [{ uuid: d.media[0]?.urls?.thumb }],
                  ...d,
              }
            : d;
    };

    const form = useCForm({
        id: props.data?.id,
        url: `product/${props.data?.type.toLowerCase()}`,
        with: [
            "taxes",
            "category",
            "station",
            "recipe.unit",
            // "goods.unit",
            "modifications.unit",
            "unit",
            "modificator_groups.modificators.ingredient.unit",
        ],
        model2: GoodSchema,
        redirectUrl: false,
        refine: goodRefine,
        prepareForSubmit: goodsPrepareSubmit,
        afterSubmit: d => {
            form.form.reset(goodRefine(d.receivedValue));
            props.dp.revalidate(d);
            props?.hide?.();
            return toaster.push(
                <Message type="success" showIcon closable test-id="success">
                    <TText tkey="success" />
                </Message>,
                { duration: 4000 },
            );
        },
    });
    const content = form.form?.watch();

    useEffect(() => {
        if (content?.id) {
            setResponse(content);
        }
    }, [content?.id]);

    useEffect(() => {
        if (form.form.getValues("id") && form.form.getValues("id") !== props.data?.id) {
            form.form.reset(dummyGoods());
        }
        if (props.data?.type === ProductType.DISH) {
            setTabs([
                {
                    label: `${t("info")}`,
                    Component: ProductInfoTab,
                    props: {
                        form,
                    },
                },
                {
                    label: `${t("recipe")} (${(response?.recipe ?? []).length})`,
                    Component: DishRecipeTab,
                    props: { data: response?.recipe ?? [] },
                },
                {
                    label: `${t("modifiers")} (${(response as any)?.modificator_groups?.length})`,
                    Component: DishModificationsGroupTab,
                    props: { data: response },
                },
                // {
                //     label: t("used_by_modifier"),
                //     Component: InUseModifier,
                //     props: {
                //         dataId: props.data?.id,
                //     },
                // },
                {
                    label: `${t("in_use")} ${totalCount}`,
                    Component: UsageTabBeta,
                    props: {
                        productId: props.data?.id,
                        countData,
                        dataId: props.data?.id || props.dataId,
                        ...props,
                    },
                },
                {
                    label: t("emenu"),
                    Component: EMenuTap,
                    hide: !emenuIntegration,
                    props: {
                        form,
                    },
                },
            ]);
        } else if (props.data?.type === ProductType.GOODS) {
            setTabs([
                {
                    label: `${t("info")}`,
                    Component: ProductInfoTab,
                    testId: "info-tab",
                    props: {
                        form,
                    },
                },
                {
                    label: t("used_by_modifier"),
                    Component: InUseModifier,
                    testId: "used-by-modifier-tab",
                    props: {
                        dataId: props.data?.id,
                    },
                },
                {
                    label: t("emenu"),
                    Component: EMenuTap,
                    testId: "emenu-tab",
                    hide: !emenuIntegration,
                    props: {
                        form,
                    },
                },
            ]);
        } else if (props.data?.type === ProductType.TIMER) {
            setTabs([
                {
                    label: `${t("info")}`,
                    Component: TimerInfoTab,
                    props: {
                        form,
                    },
                },
            ]);
        }
        // eslint-disable-next-line
    }, [form.isLoading, content?.id, response?.id, props.data?.id, props.data?.type, countData]);

    useEffect(() => {
        (async () => {
            if (props.data?.id || props.dataId) {
                const response = await Ajax.get({
                    url: `product/relatable/${props.data?.id || props?.dataId}/info`,
                });

                const countData: { [key: string]: number } = response.data;
                const totalCount = Object.values(countData).reduce((a: number, b: number) => a + b, 0);
                setTotalCount(totalCount);
                setCountData(countData);
            }
        })();
    }, [props.data?.id, props?.dataId]);

    const editLink =
        props.data?.type === ProductType.GOODS
            ? `/menu/goods/edit/${props.data?.id}`
            : props.data?.type === ProductType.DISH
              ? `/menu/dish/edit/${props.data?.id}`
              : "";

    return (
        <CDrawer size="md" title={`#${props.data?.id} ${props.data?.name}`} {..._.omit(props, ["dp"])} edit={editLink}>
            <CDrawerHeaderRight show={activeTab === 0 || activeTab === tabs.length - 1} saveIconBtn formSubmit={form} />

            <TabsView
                tabs={tabs}
                setActiveTab={id => {
                    setActiveTab(id);
                }}
            />

            <div className="drawer-footer">
                <Button
                    onClick={form.onSave}
                    test-id="save-button"
                    color="green"
                    type="submit"
                    loading={form.isLoading}
                    disabled={!form.form.getValues("id")}
                >
                    <CIcon path={iCheckBoxChecked} className="mr-2" />
                    <TText tkey="save" />
                </Button>
            </div>
        </CDrawer>
    );
};

export default QuickProductDrawer;
