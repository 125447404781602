import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames"; //created for permissions

//created for permissions
export interface PackagesState {
    partner_id: number | null;
    country: string;
    integrations: IIntegration[];
    subscription: ISubscription | null;
    limitation: ILimitation | null;
    modules: IModule[];
}

const initialState: PackagesState = {
    country: "",
    partner_id: null,
    integrations: [],
    subscription: null,
    limitation: null,
    modules: [],
};

export const PackagesSlice = createSlice({
    name: ReduxSlicesNames.PACKAGES,
    initialState,
    reducers: {
        setPackages: (state, action: PayloadAction<PackagesState>) => {
            state.country = action.payload.country;
            state.integrations = action.payload.integrations ?? [];
            state.subscription = action.payload.subscription;
            state.limitation = action.payload.limitation;
            state.modules = action.payload.subscription?.modules || [];
        },
        setIntegrations: (state, action: PayloadAction<IIntegration>) => {
            const payload = action.payload;
            const integrationIndex = state?.integrations?.findIndex((i: IIntegration) => i.id === payload.id);

            if (integrationIndex !== -1) {
                state.integrations[integrationIndex] = payload;
            } else {
                state.integrations.push(payload);
            }
        },
    },
});

export const { setPackages, setIntegrations } = PackagesSlice.actions;
export default PackagesSlice.reducer;
