const IconDishComponent = ({ color, size, style }: { color?: string; size: number; style?: any }) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            height={size}
            width={size}
            fill={color}
            x="0px"
            y="0px"
            viewBox="0 0 45 45"
            style={style}
        >
            <g>
                <path
                    d="M41.9,21.4c0-0.3-0.2-0.5-0.5-0.5H8.6c-0.3,0-0.5,0.2-0.5,0.5c0,6.1,4.1,13.8,10.9,16.3v2.1c0,0.3,0.2,0.5,0.5,0.5h11.1
		c0.3,0,0.5-0.2,0.5-0.5v-2.1C37.8,35.3,41.9,29.1,41.9,21.4z M9.6,25.1h31c-0.1,0.4-0.2,0.7-0.2,1.1H9.9
		C9.8,25.9,9.7,25.5,9.6,25.1z M40.9,21.9c0,0.8-0.1,1.6-0.2,2.4H9.4c-0.2-0.8-0.3-1.6-0.3-2.4H40.9z M20,39.2v-1.5H30v1.5H20z
		 M30.5,36.8h-11c-4.5-1.5-7.8-5.5-9.3-9.7h29.9C38.6,31.7,35.2,35.2,30.5,36.8z"
                />
                <path
                    d="M32.2,16.2c-0.1,0.7-0.4,1-1,1.4c-0.2,0.2-0.3,0.5-0.1,0.7c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1
		c0.6-0.4,1.4-1,1.4-2.2c0.1-1.2-0.6-1.9-1.1-2.5c-0.5-0.5-0.9-0.9-0.9-1.7c0.1-0.7,0.4-1,1.1-1.5c0.2-0.2,0.3-0.5,0.1-0.7
		c-0.2-0.2-0.5-0.3-0.7-0.1c-0.6,0.4-1.4,1-1.5,2.2c-0.1,1.2,0.6,1.9,1.1,2.5C31.8,15,32.2,15.4,32.2,16.2z"
                />
                <path
                    d="M18.8,16.2c-0.1,0.7-0.4,1-1,1.4c-0.2,0.2-0.3,0.5-0.1,0.7c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1
		c0.6-0.4,1.4-1,1.5-2.2c0.1-1.2-0.6-1.9-1.1-2.5c-0.5-0.5-0.9-0.9-0.9-1.7c0.1-0.7,0.4-1,1.1-1.5c0.2-0.2,0.3-0.5,0.1-0.7
		c-0.2-0.2-0.5-0.3-0.7-0.1c-0.6,0.4-1.4,1-1.5,2.2c-0.1,1.2,0.6,1.9,1.1,2.5C18.5,15,18.9,15.4,18.8,16.2z"
                />
                <path
                    d="M25.5,16.2c-0.1,0.7-0.4,1-1,1.4c-0.2,0.2-0.3,0.5-0.1,0.7c0.1,0.1,0.3,0.2,0.4,0.2c0.1,0,0.2,0,0.3-0.1
		c0.6-0.4,1.4-1,1.5-2.2c0.1-1.2-0.6-1.9-1.1-2.5c-0.5-0.5-0.9-0.9-0.9-1.7c0.1-0.7,0.4-1,1.1-1.5c0.2-0.2,0.3-0.5,0.1-0.7
		c-0.2-0.2-0.5-0.3-0.7-0.1c-0.6,0.4-1.4,1-1.5,2.2c-0.1,1.2,0.6,1.9,1.1,2.5C25.1,15,25.5,15.4,25.5,16.2z"
                />
            </g>
        </svg>
    );
};

export default IconDishComponent;
