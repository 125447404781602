export const enum ReduxSlicesNames {
    APP = "app",
    LANG = "lang",
    DETAIL_REPORT = "detailReport",
    PACKAGES = "packages",
    ITEM_MANAGEMENT = "itemManagement",
    SUPPLY_ITEMS = "supplyItems",
    RECEIPT_FILTER = "receiptFilter",
    UI = "ui",
    REMOTE_CONFIG = "remoteConfig",
    VENUES = "venues",
    RECEIPT = "receipt",
    STATION_RECEIPT = "station/receipt",
    WEB_CASHIER = "webCashier",
    WEB_CASHIER_ADDITIONALS = "webCashierAdditionals",
    DATA = "data",
    PRODUCT_SELECTOR = "productSelector",
    TRASH_PRODUCT_SELECTOR = "trashProductSelector",
    SETTINGS = "settings",
    FINANCE_CATEGORIES = "financeCategories",
    MENU_CATEGORIES = "menuCategories",
    PAYMENT_METHODS = "paymentMethods",
    ROLES = "roles",
    USERS = "users",
    STATIONS = "stations",
    SALE_TYPES = "saleTypes",
    MODIFIER_GROUPS = "modifierGroups",
    MODIFICATORS = "modificators",
    UNITS = "units",
    STORAGE = "storage",
}
