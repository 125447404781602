import { useFormContext } from "react-hook-form";
import { JSONTree } from "react-json-tree";

export const StateViewerTab = () => {
    const ctx = useFormContext();
    const value = ctx.watch?.();
    const errors = ctx.formState.errors;

    return (
        <div className="clopos-scroll overflow-auto tw-max-h-[80vh] pt-2">
            <JSONTree data={{ value, errors }} sortObjectKeys />
        </div>
    );
};
