import { useDepot } from "hooks/useDepot";
import Ajax from "lib/Ajax";
import _ from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSWRConfig } from "swr";
import { depot } from "@cloposcom/libs";
import { AuthContext } from "providers/AuthProvider/AuthContext";

export function useAuth() {
    return useContext(AuthContext);
}

export function useSetupAuth() {
    const [user, _setUser] = useDepot<IUser | undefined>("user");
    const [token, setToken] = useDepot<string | undefined>("token");
    const [isLoggedIn, setIsLoggedIn] = useState(!!user?.token);
    const cache = useSWRConfig().cache as Map<string, any>;
    if (user && !user?.token && token) {
        _setUser({ ...user, token });
    }

    Ajax.setToken(user?.token || token);

    const setUser = useCallback((u?: IUser) => {
        Ajax.setToken(u?.token);
        _setUser(u);
        setToken(u?.token);
        setIsLoggedIn(!!u?.token);
        cache.clear();
    }, []);

    const hasAbility = useCallback(
        (abilities: string[]): boolean => {
            const hasAccessToAll = user?.abilities.find(t => t.name === "*");
            const userAbilities = user?.abilities.filter(ability => abilities.includes(ability.name)).map(b => b.name);
            return hasAccessToAll ? true : _.isEqual(_.sortBy(userAbilities), _.sortBy(abilities));
        },
        [user],
    );
    useEffect(() => {
        (async () => {
            if (token) {
                await Ajax.get({ url: "auth/user" })
                    .then(resp => {
                        resp.data.token = token;
                        setUser(resp.data as IUser);
                    })
                    .catch(e => {
                        setToken(undefined);
                    });
            } else if (user && user.token) {
                setToken(user.token);
            }
        })();
        // eslint-disable-next-line
    }, [setUser]);

    const logoutFunc = async () => {
        await Ajax.post({ url: "auth/logout" })
            .then(() => {
                setUser();
                depot.flushPrefixed();
            })
            .catch(e => {
                // console.log(e);
            });
    };
    useEffect(() => {
        const s = Ajax.addListener("AUTH_ERROR", () => setUser());
        return () => s.remove();
    }, [setUser]);

    return useMemo(
        () => ({
            user,
            isLoggedIn,
            setUser,
            hasAbility,
            token: user?.token,
            logout: () => logoutFunc(),
        }),
        [user, isLoggedIn, setUser, hasAbility],
    );
}

export const AuthProvider: FCC = props => {
    const auth = useSetupAuth();
    return <AuthContext.Provider value={auth}>{props.children}</AuthContext.Provider>;
};
