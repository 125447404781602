import CForm from "components/Form/CForm/CForm";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import TabsView from "components/TabsView";
import JsonView from "components/JsonView";
import { t } from "lib/i18n";
import { MenuCustomizationTab } from "pages/qr-menu/settings/MenuCustomizationTab";
import { GeneralSettingsTab } from "pages/qr-menu/settings/GeneralSettingsTab";
import { useIntegrationForm } from "pages/settings/integrations/utils";
import { useIntegrationByName } from "providers/PackageProvider";
import { useAppDispatch } from "hooks/useRedux";
import { setIntegrations } from "redux/features/packages";

const SettingsPage: FCC = () => {
    const dispatch = useAppDispatch();
    const integration = useIntegrationByName("emenu");
    const integrationForm = useIntegrationForm({
        id: integration?.id || -1,
        refine: data => {
            if (!data.payload.meta || Array.isArray(data.payload.meta)) {
                data.payload.meta = {};
            }

            data.payload.meta.require_login ??= true;

            return data;
        },
        afterSubmit: d => dispatch(setIntegrations(d.receivedValue)),
    });

    return (
        <div className="h-100 p-2">
            {useTitle(t("settings"))}
            <HeaderRight formSubmit={integrationForm} />

            <CForm {...integrationForm}>
                <TabsView
                    tabs={[
                        {
                            label: t("setting_general"),
                            Component: GeneralSettingsTab,
                            props: { form: integrationForm },
                        },
                        {
                            label: t("menu_customization"),
                            Component: MenuCustomizationTab,
                            props: { form: integrationForm },
                        },
                    ]}
                />
                <JsonView />
            </CForm>
        </div>
    );
};

export default SettingsPage;
