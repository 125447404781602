import PortalTo from "components/PortalTo";
import { useHistory } from "react-router-dom";
import { Nav } from "rsuite";
interface IProps {
    links: any[];
    inHeader?: boolean;
}

export const QuickLinks: FCC<IProps> = ({ links, inHeader = true }) => {
    const history = useHistory();
    return (
        <PortalTo id={inHeader ? "quick-links" : "quick-links-under"}>
            <Nav id="shortcut" className="pl-3 mb-3" style={{ fontSize: "12px" }}>
                {links
                    ?.filter(r => !r.hide)
                    .map(ql => (
                        <Nav.Item
                            key={ql.link}
                            active={ql.active}
                            as={() =>
                                ql.active ? (
                                    <button className="link-btn-active mt-1 bg-white" test-id={ql.testId}>
                                        {ql.label}
                                    </button>
                                ) : (
                                    <button
                                        style={{ cursor: "pointer" }}
                                        onClick={() => history.push(ql.link)}
                                        className="mt-1 bg-white link-btn-inactive"
                                        test-id={ql.testId}
                                    >
                                        {ql.label}
                                    </button>
                                )
                            }
                        />
                    ))}
            </Nav>
        </PortalTo>
    );
};
