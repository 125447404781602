import { usePackages } from "providers/PackageProvider";
import { useEffect } from "react";
import { useSetting } from "./useSetting";
import { useAppSelector } from "./useRedux";

export function useDrdsh() {
    const packages = usePackages();
    const langSetting = useSetting("language");
    const isSA = packages.country === "SA";
    const language = useAppSelector(state => state.lang).current;

    useEffect(() => {
        if (isSA) {
            const lang = langSetting?.value !== "ar" ? "en" : langSetting?.value;
            const script = document.createElement("script");
            script.type = "text/javascript";
            script.innerHTML = `window.dc = {acid: "",_v:"", locale: "${
                language ?? lang
            }"}, window.__dc = window.dc || {}, window.dc.acid = "780922500", window.dc._v = "2.0", window.__dc.locale = "${lang}", function () { var t = document.createElement("script"); t.type = "text/javascript", t.async = !0, t.src = ("https:" == document.location.protocol ? "https://" : "http://") + "www.drdsh.live/dc_tracking.js?t=" + (new Date).getTime(); var e = document.getElementsByTagName("script")[0]; e.parentNode.insertBefore(t, e) }();`;
            document.head.appendChild(script);
        }
        // eslint-disable-next-line
    }, [isSA]);
}
