import { useDataProvider } from "components/DataTable/useDataProvider";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import TabsView from "components/TabsView";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import TransactionDetails from "./TransactionDetails";
import { FC } from "react";
import { useCurrency } from "hooks/useCurrency";

let date = {};

const SupplierDrawer: FC<ICDrawerProps> = props => {
    const auth = useAuth();
    const currency = useCurrency();
    const dp = useDataProvider({
        url: "finance/transaction",
        filters: {
            indicator: ["indicator", 1],
            balance_id: ["balance_id", props.data?.balance?.id ?? 0],
        },
        stop: !auth.hasAbility(["finance_transaction_view"]),
        params: date,
        with: [
            "category",
            "balance",
            "staff",
            "user",
            "operation.supplier",
            "operation.operation_items",
            "operation.operation_items.unit",
            "operation.operation_items.product",
            "operation.stock_operations.product",
            "from_balance",
            "supplier",
            "customer",
        ],
        sort: ["operated_at", "-1"],
    });

    const returned = useDataProvider({
        url: "finance/transaction",
        filters: {
            indicator: ["indicator", 0],
            category_id: ["category_id", 22],
            supplier_id: ["supplier_id", props.data?.id ?? 0],
        } as any,
        params: date,
        stop: !auth.hasAbility(["finance_transaction_view"]),
        with: [
            "category",
            "balance",
            "staff",
            "user",
            "operation.supplier",
            "operation.stock_operations.product",
            "from_balance",
            "supplier",
            "customer",
        ],
    });

    date = { date: dp.params.all.date };

    return (
        <CDrawer
            calendar
            style={{ width: "80vw" }}
            title={`${t("supplier")} : ${props.data?.name} ID: ${props.data?.id}, Balance: ${
                props.data?.balance?.amount
            } ${currency}, Balance ID: ${props.data?.balance?.id}`}
            dp={dp}
            {...props}
        >
            <TabsView
                tabs={[
                    {
                        label: t("transactions"),
                        Component: TransactionDetails,
                        props: {
                            supplier: dp,
                            returned,
                            showUnpaidAmount: true,
                            isSupplier: true,
                            // summary,
                            // summaryFake
                        },
                    },
                ]}
            />
        </CDrawer>
    );
};

export default SupplierDrawer;
