import { IS_PR_PREVIEW, isAlpha, isDev } from "config/main";
import { useDepot } from "hooks/useDepot";
import React from "react";
import { JSONTree } from "react-json-tree";
import { IconButton } from "rsuite";
import CIcon from "./CIcon";
import { iBasketMinus, iPlus } from "assets/icons/Icons";
import { useFormContext } from "react-hook-form";

interface IProps {
    value?: any;
    collapsed?: boolean;
}

const JsonView: FCC<IProps> = props => {
    const [isOpen, setOpen] = useDepot("jsonViewOpen", false);
    const ctx = useFormContext();
    const value = ctx?.watch?.();
    const errors = ctx.formState.errors;

    if (!isDev && !isAlpha && !IS_PR_PREVIEW) {
        return null;
    }

    return (
        <div className="bg-white shadow rounded m-3 clopos-scroll overflow-auto tw-fixed tw-right-0 tw-top-20 tw-z-50 tw-max-h-[80vh]">
            <IconButton
                appearance="subtle"
                className="float-right"
                icon={<CIcon path={isOpen ? iBasketMinus : iPlus} />}
                onClick={() => setOpen(!isOpen)}
            />
            {isOpen && (
                <div className="p-4">
                    <JSONTree
                        data={{ value: props.value || value, errors }}
                        sortObjectKeys
                        shouldExpandNode={(path, d, level) => {
                            return level === 0 ? !props.collapsed : true;
                        }}
                    />
                </div>
            )}
        </div>
    );
};

export default JsonView;
