import { useCForm } from "hooks/useCForm";
import { fixIntegrationPayloadAndStatus } from "lib/utils";
import { ValidationSchema } from "lib/custom-form-schema-resolver";

interface IIntegrationFormConf {
    id: number;
    prepareForSubmit?: (data: any) => void;
    afterSubmit?: (data: any) => void;
    refine?: (data: IIntegration) => void;
    model?: any;
    model2?: ValidationSchema;
}

export function useIntegrationForm(conf: IIntegrationFormConf) {
    return useCForm({
        url: "brand/integration",
        id: conf?.id,
        redirectUrl: false,
        model: conf.model,
        model2: conf.model2,
        refine: conf?.refine ?? fixIntegrationPayloadAndStatus,
        prepareForSubmit: conf?.prepareForSubmit
            ? conf.prepareForSubmit
            : data => {
                  data.status = data.status ? 1 : 0;
                  return data;
              },
        afterSubmit: conf.afterSubmit,
    });
}
