import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { UseFormReturnType } from "hooks/useCForm";
import { getRandomInt } from "lib/getRandomInt";
import _ from "lodash";
import { useFieldArray, UseFormReturn } from "react-hook-form";
import { Button, Col, Grid, Row } from "rsuite";
import { SyntheticEvent } from "react";

interface IProps {
    cameFrom: number;
    form: UseFormReturnType;
    outerForm: UseFormReturn;
    onClose?: (e: SyntheticEvent) => void;
    data: any;
}

const ModificationModalFooter: FCC<IProps> = ({ cameFrom, form, outerForm, onClose, data }) => {
    const { control, setValue } = outerForm;
    const { fields, append, remove } = useFieldArray({ control, name: "modificator_groups" });

    const refinedModifiers = (data: any) =>
        _.orderBy(
            data.modificators?.map((m: any) => {
                return {
                    ...m,
                    images: !_.isEmpty(m.media)
                        ? [{ uuid: m.media[0]?.urls?.thumb }]
                        : m.ingredient?.image
                          ? [{ uuid: m.ingredient?.image.thumb }]
                          : m.image,
                };
            }),
            "position",
        );

    const onAdd = (e: SyntheticEvent, data: any, type: number) => {
        data.modificators = refinedModifiers(data);
        if (data.id === 0) {
            append(_.omit(data, ["pivot", "id", "groupFormIndex"]));
        } else if (!type) {
            append(_.omit(data, ["pivot", "groupFormIndex"]));
        } else {
            setValue(`modificator_groups[${data.groupFormIndex}]`, _.omit(data, ["groupFormIndex"]));
        }
        onClose?.(e);
    };

    const onClone = (e: SyntheticEvent, data: any) => {
        data.modificators = _.map(data.modificators, (m: any) => ({
            ..._.omit(m, ["group_id", "id"]),
            images: !_.isEmpty(m.media)
                ? [{ uuid: m.media[0]?.urls?.thumb }]
                : m.ingredient?.image
                  ? [{ uuid: m.ingredient?.image.thumb }]
                  : m.image,
        }));
        append({ ..._.omit(data, ["id"]), name: `${data.name}-${getRandomInt(101, 999)}` });
        onClose?.(e);
    };

    const onRemove = (e: SyntheticEvent, data: any) => {
        let index = _.findIndex(fields, { id: data?.id });
        index = index === -1 ? data.groupFormIndex : index;

        remove(index);

        onClose?.(e);
    };

    // useEffect(() => {
    //     console.log("useEffect222", cameFrom);
    //     form.set("afterSubmit", () => {
    //         console.log("afterSubmit2222");
    //         onAdd(form.form.getValues(), cameFrom);
    //     });
    //
    // }, []);
    const name = form.form.watch("name");
    const type = form.form.watch("type");
    const min_select = form.form.watch("min_select");
    const max_select = form.form.watch("max_select");
    return (
        <Grid fluid>
            <Row className="mt-3">
                <Col md={8}>
                    <Button
                        className={cameFrom ? "d-block" : "d-none"}
                        color="red"
                        appearance="primary"
                        onClick={e => onRemove(e, data)}
                    >
                        <TText tkey="remove" />
                    </Button>
                </Col>

                <Col md={16} className="text-right">
                    {!cameFrom && form.form.getValues("id") ? (
                        <Button
                            onClick={e => onClone(e, form.form.getValues())}
                            color="orange"
                            appearance="primary"
                            className={"mr-2"}
                        >
                            <TText tkey="clone" />
                        </Button>
                    ) : null}

                    <Button
                        onClick={e => {
                            form.onSave();
                            if (name === "") return;
                            if (!!type && min_select > max_select) return;

                            onAdd(e, form.form.getValues(), cameFrom);
                        }}
                        test-id={"save-modification-group"}
                        color="green"
                        loading={form.isLoading}
                        appearance="primary"
                        className="mr-2"
                    >
                        {cameFrom ? t("save") : t("add")}
                    </Button>
                </Col>
            </Row>
        </Grid>
    );
};

export default ModificationModalFooter;
