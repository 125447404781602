import { t } from "lib/i18n";
import { Location } from "history";
import { useAuth } from "providers/AuthProvider/AuthProvider";
const NrInRoutings = (loc: Location) => {
    const auth = useAuth();
    return [
        {
            link: "/statistic/reports/products",
            label: t("products"),
            active: loc.pathname === "/statistic/reports/products",
            permissions: ["report_product"],
        },
        {
            link: "/statistic/reports/modifiers",
            label: t("modifiers"),
            active: loc.pathname === "/statistic/reports/modifiers",
            permissions: ["report_product"],
        },
        {
            link: "/statistic/reports/returns",
            label: t("return"),
            active: loc.pathname === "/statistic/reports/returns",
            permissions: ["report_product"],
        },
        {
            link: "/statistic/reports/tables",
            label: t("tables"),
            active: loc.pathname === "/statistic/reports/tables",
            permissions: ["report_table"],
        },
        {
            link: "/statistic/reports/sales-type",
            label: t("sales_type"),
            active: loc.pathname === "/statistic/reports/sales-type",
            permissions: ["report_sell"],
        },
        {
            link: "/statistic/reports/categories",
            label: t("categories"),
            active: loc.pathname === "/statistic/reports/categories",
            permissions: ["report_category"],
        },
        {
            link: "/statistic/reports/staff",
            label: t("staff"),
            active: loc.pathname === "/statistic/reports/staff",
            permissions: ["report_user"],
        },
        {
            link: "/statistic/reports/stations",
            label: t("stations"),
            active: loc.pathname === "/statistic/reports/stations",
            permissions: ["report_station"],
        },
        {
            link: "/statistic/reports/customers",
            label: t("customers"),
            active: loc.pathname === "/statistic/reports/customers",
            permissions: ["report_customer"],
        },
        {
            link: "/statistic/reports/payments",
            label: t("payments"),
            active: loc.pathname === "/statistic/reports/payments",
            permissions: ["report_payment"],
        },
    ].filter(e => (e.permissions ? auth.hasAbility(e.permissions) : true));
};

export default NrInRoutings;
