import { getHours, parse } from "date-fns";
import { todayF, tomorrowF } from "../config/dates";

export const shiftTomorrowF = (cacheStart: ISettings["cash_restart"]["value"]) => {
    const cashStartHours = getHours(parse(cacheStart, "HH:mm", new Date()));
    const currentHour = getHours(new Date());

    if (currentHour >= 0 && currentHour <= cashStartHours) {
        return todayF();
    }

    return tomorrowF();
};
