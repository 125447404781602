import { iDish, iGoods, iIngredient, iPreparation } from "assets/icons/Icons";
import CIcon from "../../../../components/CIcon";
import { Tooltip, Whisper } from "rsuite";
import { ProductType } from "config/constants";
import { t } from "lib/i18n";

interface IProps {
    type: string;
    testId?: string;
    size?: string | number;
}

const RecipeIcon: FCC<IProps> = ({ type, testId, size }) => {
    const renderIcon = () => {
        switch (type) {
            case ProductType.INGREDIENT:
                return <CIcon path={iIngredient} className="icon" size={size} />;
            case ProductType.DISH:
                return <CIcon path={iDish} className="icon" size={size} />;
            case ProductType.GOODS:
                return <CIcon path={iGoods} className="icon" size={size} />;
            case ProductType.PREPARATION:
                return <CIcon path={iPreparation} className="icon" size={size} />;
            case ProductType.MODIFICATION:
                return <CIcon path={iGoods} className="icon" size={size} />;
        }
    };

    return (
        <Whisper
            placement="bottom"
            trigger="hover"
            speaker={
                <Tooltip test-id={`${testId}-tooltip`}>{t(type?.toLowerCase() as Lowercase<ProductType>)}</Tooltip>
            }
        >
            <div test-id={testId} className="">
                {renderIcon()}
            </div>
        </Whisper>
    );
};

export default RecipeIcon;
