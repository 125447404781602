import { createAsyncThunk } from "@reduxjs/toolkit";
import Ajax from "../../../lib/Ajax";
import { PromiseChannels } from "lib/PromiseChannels";
import {
    findProductCategory,
    ProductsFilterKey,
    setAllSelectedProducts,
    setCounter,
    setFilters,
    setInitialResult,
    setLastUpdated,
    setResult,
    setSelectedProducts,
} from "./productsSelector";
import { arrayToMap } from "lib/utils";
import _ from "lodash";
import { CategoryTypeEnum, ProductType, Trash } from "config/constants";
import { Dispatch } from "react";
import { removeProduct, setAllUnits, setCategories, setProducts, setProductsLoading, setStations } from "../data";
import { convertToSlug } from "lib/convertToSlug";

const productsPerPage = 999;

const promiseChannels = new PromiseChannels(10);
// const NO_CATEGORY_KEY = -1;
const addToMap = (
    category: ICategory,
    categoriesMap: {
        byId: Record<number, ICategory>;
        INGREDIENT: Record<number, ICategory>;
        PRODUCT: Record<number, ICategory>;
    },
) => {
    const categoryType = category.type;

    categoriesMap[categoryType as "PRODUCT"] ??= {};
    categoriesMap.byId[category.id] = category;
    categoriesMap[categoryType as "PRODUCT"][category.id] = category;

    if (category.children?.length) {
        category.children.forEach(child => {
            addToMap(child, categoriesMap);
        });
    }
};

const selects = [
    "id",
    "name",
    "category_id",
    "emenu_category_id",
    "price",
    "updated_at",
    "unit_id",
    "type",
    "parent_name",
    "parent_id",
    "has_modifications",
    "barcode",
    "station_id",
    "cost_price",
    "unit_weight",
    "position",
    "emenu_position",
];

export const fetchProductsAndCategories = createAsyncThunk(
    "data/fetchProductsAndCategories",
    async (lastUpdatedOfProducts: string | undefined, { getState, dispatch }) => {
        try {
            dispatch(setProductsLoading(true));
            const { requestCount, categories, stations, total, productsLastUpdated, units } =
                (await fetchCountForProductsAndCategories(lastUpdatedOfProducts)) as {
                    requestCount: number;
                    categories: ICategory[];
                    stations: IStation[];
                    total: number;
                    productsLastUpdated: string;
                    units: IUnit[];
                };
            let progressCount = 0;
            const allResponses: any = [];
            if (lastUpdatedOfProducts && !selects.includes("deleted_at")) {
                selects.push("deleted_at");
            }
            const fetchProductsWithPage = async (page: number) => {
                const url = Ajax.buildUrl({
                    url: "/product",
                    params: {
                        limit: productsPerPage,
                        ...(lastUpdatedOfProducts ? { trash: Trash.TrashAndActive } : {}),
                        page,
                        selects: selects.join(","),
                        with: ["packages", "modifications"],
                        sort: [["id", "1"]],
                        filters: lastUpdatedOfProducts ? [["gt_updated_at", lastUpdatedOfProducts]] : undefined,
                        // type: [["type", "!=", ProductType.MODIFICATION]],
                        // stock: 2,
                    },
                });
                const response = await Ajax.get({ url });
                allResponses.push(response.data);
                progressCount += response.data.length;
                dispatch(setCounter(+((progressCount / total) * 100).toFixed()));
            };
            for (let i = 1; i <= requestCount; i++) {
                await promiseChannels.add(fetchProductsWithPage(i));
            }
            await promiseChannels.finish;

            const allProducts: IProduct[] = allResponses.flat();
            const allProductsMap: Record<number, IProduct> = {};
            const productIds: number[] = [];
            for (let i = 0; i < allProducts.length; i++) {
                const item = allProducts[i];
                if (item?.deleted_at) {
                    dispatch(removeProduct(item.id));
                    continue;
                }
                if (item.type === ProductType.MODIFICATION) {
                    item.slug = allProductsMap[item.parent_id!]?.slug + "-" + item.slug;
                }
                productIds.push(item.id);
                allProductsMap[item.id] ??= item;
            }

            const categoriesMap: {
                byId: Record<number, ICategory>;
                INGREDIENT: Record<number, ICategory>;
                PRODUCT: Record<number, ICategory>;
            } = {
                byId: {},
                INGREDIENT: {},
                PRODUCT: {},
            };
            const stationIds = stations.map(station => station.id);
            const stationsMap: Record<number, IStation> = arrayToMap(stations, "id");
            const unitsMap: Record<number, IUnit> = arrayToMap(units, "id");
            const unitIds = units.map(unit => unit.id);
            // const childCategoriesAdd = (category: ICategory) => {
            //     if (category.children?.length) {
            //         category.children.forEach(child => {
            //             categoriesMap[categoryType][child.id] = child;
            //             categoriesMap.byId[child.id] = child;
            //         });
            //     }
            // };

            categories.forEach(category => {
                addToMap(category, categoriesMap);
            });
            //
            // categories.forEach(category => {
            //     const categoryType = category.type;
            //
            //     categoriesMap[categoryType] ??= {};
            //
            //     categoriesMap.byId[category.id] = category;
            //     categoriesMap[categoryType][category.id] = category;
            //     if (category.children?.length) {
            //         category.children.forEach(child => {
            //             categoriesMap[categoryType][child.id] = child;
            //             categoriesMap.byId[child.id] = child;
            //         });
            //     }
            // });
            dispatch(
                setProducts({
                    byId: allProductsMap,
                    allIds: productIds,
                }),
            );
            dispatch(setAllUnits({ byId: unitsMap, allIds: unitIds }));
            dispatch(setInitialResult(productIds));
            dispatch(setCategories(categoriesMap));
            dispatch(setStations({ byId: stationsMap, allIds: stationIds }));
            dispatch(setLastUpdated(productsLastUpdated));
            dispatch(setProductsLoading(false));
        } catch (e) {
            console.log(e);
        }
    },
);
const fetchCountForProductsAndCategories = async (lastUpdatedOfProducts: string | undefined) => {
    try {
        let total: number | undefined;
        let requestCount = 1;

        const productsUrl = Ajax.buildUrl({
            url: "/product",
            params: {
                limit: 1,
                page: 1,
                selects: "id",
                filters: lastUpdatedOfProducts ? [["gt_updated_at", lastUpdatedOfProducts]] : undefined,
            },
        });
        const categoriesUrl = Ajax.buildUrl({
            url: "/category",
            params: {
                limit: 999,
                page: 1,
                filters: [["type", "!=", CategoryTypeEnum.ACCOUNTING]],
            },
        });

        const [categoriesRes, productsRes, stationRes, unitsRes] = await Promise.all([
            Ajax.get<ICategory[]>({ url: categoriesUrl }),
            Ajax.get<{ total: number; timestamp: string }>({
                url: productsUrl,
            }),
            Ajax.get<any>({
                url: "/station",
                params: {
                    limit: 999,
                    page: 1,
                },
            }),
            Ajax.get<any>({
                url: "/unit",
                params: {
                    limit: 999,
                    page: 1,
                },
            }),
        ]);
        if (productsRes.success) {
            total = productsRes.total;
        }

        if (total) {
            requestCount = Math.ceil(total / productsPerPage);
        }
        const categories = categoriesRes.data;
        const stations = stationRes.data;
        const units = unitsRes.data;
        return { requestCount, categories, stations, total, productsLastUpdated: productsRes.timestamp, units };
    } catch (e) {
        console.log(e);
    }
};

export const handleProductSelectAC = (productId: number) => {
    return (dispatch: any, getState: () => RootState) => {
        const selectedProductIds = getState().productSelector.selectedProductIds;
        const currentSelectedProducts = [...selectedProductIds];

        if (currentSelectedProducts.includes(productId)) {
            const newSelectedProducts = currentSelectedProducts.filter(id => id !== productId);
            dispatch(setSelectedProducts(newSelectedProducts));
        } else {
            dispatch(setSelectedProducts([...currentSelectedProducts, productId]));
        }
    };
};
export const setAllSelectedProductsAC = (select: boolean, result: number[]) => {
    return (dispatch: Dispatch<any>, getState: () => RootState) => {
        // const result = getState().productsAndCategories.result;
        const selectedProductIds = getState().productSelector.selectedProductIds;

        if (select) {
            dispatch(setAllSelectedProducts(result));
        } else {
            //in selected products delete result
            const selected = selectedProductIds.filter(id => !result.includes(id));
            dispatch(setSelectedProducts(selected));
            // dispatch(setAllSelectedProducts([]));
        }
    };
};
export const onSearchAC = () => {
    return (dispatch: Dispatch<any>, getState: () => RootState) => {
        const { products, categories } = getState().data;
        const { searchText, result } = getState().productSelector;
        if (!searchText || searchText?.trim() === "") {
            // dispatch(setResult(result));
            return;
        }
        const searchTextTrim = convertToSlug(searchText);

        const productsForSearch = result.map(id => products.byId[id]);

        const res: Record<string, number[]> = {
            products: [],
            barcodes: [],
            categories: [],
            stations: [],
        };

        for (let i = 0; i < productsForSearch.length; i++) {
            const product = productsForSearch[i];

            let index;
            if (
                (index = product.slug?.indexOf(searchTextTrim)) > -1 ||
                (index = convertToSlug(product.full_name)?.indexOf(searchTextTrim)) > -1
            ) {
                if (index === 0) {
                    res.products.unshift(product.id);
                } else {
                    res.products.push(product.id);
                }
            } else if ((index = product?.barcode?.toLowerCase().indexOf(searchTextTrim))! > -1) {
                if (index === 0) {
                    res.barcodes.unshift(product.id);
                } else {
                    res.barcodes.push(product.id);
                }
            } else if (
                (index = categories.byId[product?.category_id || -1]?.name.toLowerCase().indexOf(searchTextTrim)) > -1
            ) {
                if (index === 0) {
                    res.categories.unshift(product.id);
                } else {
                    res.categories.push(product.id);
                }
            } else if ((index = product.station?.name?.toLowerCase().indexOf(searchTextTrim)) > -1) {
                if (index === 0) {
                    res.stations.unshift(product.id);
                } else {
                    res.stations.push(product.id);
                }
            }
        }
        const resAll = Object.values(res).reduce((acc, cur) => {
            return [...acc, ...cur];
        }, []);
        dispatch(setResult(resAll));
    };
};
export const onFilterAc = () => {
    return (dispatch: Dispatch<any>, getState: () => RootState) => {
        const { products } = getState().data;
        const { selectedFilters, result } = getState().productSelector;

        if (Object.values(selectedFilters).every(filter => filter.length === 0)) {
            // dispatch(setResult(productsSelector.allIds));
            return;
        }
        const productForFilter = result.map(id => products.byId[id]);
        const filtered = productForFilter.filter(p => {
            const isCategoryOk =
                selectedFilters.category.length === 0 ||
                (p.category_id
                    ? selectedFilters.category.includes(p.category_id)
                    : selectedFilters.category.includes(-1));

            const isStationOk =
                selectedFilters.station.length === 0 ||
                (p.station_id ? selectedFilters.station.includes(p.station_id) : selectedFilters.station.includes(-1));

            const isTypeOk =
                selectedFilters.type.length === 0 ||
                (p.type &&
                    (selectedFilters.type.includes(p.type) ||
                        (p.type === ProductType.MODIFICATION && selectedFilters.type.includes(ProductType.GOODS))));
            return isCategoryOk && isStationOk && isTypeOk;
        });
        dispatch(setResult(filtered.map(p => p.id)));
    };
};
export const setAllProductsAC = () => {
    return (dispatch: Dispatch<any>, getState: () => RootState) => {
        const allIds = getState().data.products.allIds;
        // const result = getState().productSelector.result;
        const shouldShowSelected = getState().productSelector.shouldShowSelected;
        const selectedProductIds = getState().productSelector.selectedProductIds;
        const result = shouldShowSelected ? selectedProductIds : allIds;
        dispatch(setResult(result));
        //for return supplies to initial state
        dispatch(onSearchAC());
        dispatch(onFilterAc());
        dispatch(onSortAC());
    };
};

export const onSortAC = () => {
    return (dispatch: Dispatch<any>, getState: () => RootState) => {
        const { products, categories, stations } = getState().data;
        const { sortColumn, sortType, result } = getState().productSelector;
        if (!sortColumn || !sortType) {
            // dispatch(setResult(result));
            return;
        }

        const sortArr = result.map(id => products.byId[id]);

        if (sortColumn && sortType) {
            const sortedData = sortArr.sort((a, b) => {
                let x = _.get(a, sortColumn);
                let y = _.get(b, sortColumn);
                if (sortColumn === "category.name") {
                    x = categories[findProductCategory[a.type]]?.[a?.category_id || -1]?.name;
                    y = categories[findProductCategory[b.type]]?.[b?.category_id || -1]?.name;
                }
                if (sortColumn === "station.name") {
                    x = stations.byId[a?.station_id || -1]?.name;
                    y = stations.byId[b?.station_id || -1]?.name;
                }
                if (!x) x = "";
                if (!y) y = "";

                if (typeof x === "string" && typeof y === "string") {
                    return sortType === "asc" ? x.localeCompare(y) : y.localeCompare(x);
                }

                if (typeof x === "number" && typeof y === "number") {
                    return sortType === "asc" ? x - y : y - x;
                }

                if (typeof x === "string") {
                    x = x.charCodeAt(0);
                }

                if (typeof y === "string") {
                    y = y.charCodeAt(0);
                }

                return sortType === "asc" ? x - y : y - x;
            });
            dispatch(setResult(sortedData.map(p => p.id)));
        }
    };
};
export const addFilterAC = (filterKey: ProductsFilterKey, value: any[]) => {
    return (dispatch: Dispatch<any>, getState: () => RootState) => {
        const selectedFilters = getState().productSelector.selectedFilters;
        const selectedFiltersCopy = { ...selectedFilters };
        selectedFiltersCopy[filterKey] = [...value];
        dispatch(setFilters(selectedFiltersCopy));
    };
};
export const onCleanSelectedFiltersAC = (type: ProductsFilterKey) => {
    return (dispatch: Dispatch<any>, getState: () => RootState) => {
        const { selectedFilters } = getState().productSelector;
        const selectedFiltersCopy = { ...selectedFilters };
        selectedFiltersCopy[type] = [];
        dispatch(setFilters(selectedFiltersCopy));
    };
};

export const onCleanAllFiltersAC = () => {
    return (dispatch: Dispatch<any>, getState: () => RootState) => {
        dispatch(
            setFilters({
                category: [],
                station: [],
                type: [],
            }),
        );
    };
};
