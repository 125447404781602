import _ from "lodash";
import { moneyFormatter } from "lib/moneyFormatter";
import { selectSetting } from "../redux/features/settings";
import { store } from "redux/store";

export function money(num: string | number | undefined, currency: string) {
    if (_.isNil(num)) return "--";

    const currencyPosition = selectSetting("currency_position")(store.getState()).value;

    const value = Number(num);
    const formattedValue = Number.isNaN(value) ? "0" : moneyFormatter(value);

    switch (currencyPosition) {
        case "start":
            return currency + " " + formattedValue;
        case "end":
            return formattedValue + " " + currency;
        case "none":
        default:
            return formattedValue;
    }
}
