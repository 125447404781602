import DateRangeFilter from "components/DataTable/Filters/DateRangeFilter";
import { IDataProvider } from "components/DataTable/useDataProvider";
import { UseDrawerReturn } from "components/Drawers/useDrawer";
import { TText } from "components/i18n/TText";
import { omit } from "lodash/fp";
import { ReactNode, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ReactToPrint from "react-to-print";
import { Button, Drawer, DrawerProps, IconButton } from "rsuite";
import { useOnClickOutside } from "usehooks-ts";
import { iCheckBoxChecked, iClose, iEditPencil, iPlus, iPrint } from "assets/icons/Icons";
import { PopupContainerProvider } from "hooks/usePopupContainer";
import { useCDrawerId } from "providers/CDrawerProvider";
import CIcon from "../CIcon";

export interface ICDrawerProps extends Omit<UseDrawerReturn, "onClose">, DrawerProps {
    title?: string | ReactNode;
    print?: boolean;
    isLoading?: boolean;
    dp?: IDataProvider;
    formSubmit?: {
        isLoading: boolean;
        onSave: () => void;
    };
    onSubmit?: any;
    // TODO: looks like this prop is not used anywhere
    onSave?: any;
    contentHeight?: {
        original: number;
        printHeight: number;
    };
    edit?: string;
    saveIBtn?: any;
    saveIBtnIcon?: string;
    saveIconBtn?: boolean;
    style?: any;
    testId?: string;
    calendar?: boolean;
    ref?: any;
}

const omitExtraProps = omit([
    "dp",
    "setDeletedBy",
    "setDataId",
    "setOpen",
    "setData",
    "hide",
    "isOpen",
    "setIsOpen",
    "contentHeight",
    "showOnPage",
    "dataId",
    "hasBackdrop",
    "setModalType",
    "modalType",
    "print",
]);

const CDrawer: FCC<ICDrawerProps> = ({ edit, title, overflowAuto, setIsClosable, isClosable, ...props }) => {
    const { id } = useCDrawerId(); // this is the unique id for each drawer
    const drawerProps: DrawerProps = {
        size: "sm",
        backdrop: false,
        className: "",
        open: props.isOpen,
        // dialogClassName: "overflow-auto rounded-lg shadow clopos-scroll",
        ...omitExtraProps(props),
    };
    const ref = useRef(null);

    const [selectedFilterCount, setSelectedFilterCount] = useState(0);

    const contentResize = (height: number) => {
        const body = document.getElementsByClassName("rs-table-body-row-wrapper");
        const table = document.getElementById("print-table");
        if (body[1] && table) {
            (table as any).style!.height = `${height}px`;
            (body[1] as any).style!.height = `${height}px`;
        }
    };

    useEffect(() => {
        const selectedFilterCount = props?.dp?.extraFilterKeys.selectedFilterCount;
        setSelectedFilterCount(selectedFilterCount ? selectedFilterCount : 0);
    }, [props.dp?.extraFilterKeys?.selectedFilterCount]);

    const clearFilters = () => {
        props.dp?.filters.clear();
    };

    const printContent = props.contentHeight
        ? {
              onBeforeGetContent: () => contentResize(props.contentHeight?.printHeight ?? 120),
              onAfterPrint: () => contentResize(props.contentHeight?.original ?? 120),
          }
        : {};
    const history = useHistory();

    const drawerRef = useRef(null);

    useOnClickOutside(drawerRef, event => {
        if (isClosable && (event.target as HTMLElement).classList.contains("rs-drawer-wrapper")) {
            setIsClosable(true);
            props.onClose?.(event as any);
        }
    });

    return (
        <Drawer {...drawerProps}>
            <div className="d-flex flex-column h-100" test-id={props.testId} ref={drawerRef}>
                <PopupContainerProvider name="CDrawer" container={drawerRef}>
                    <div
                        className="p-2 font-weight-bold d-flex justify-content-between "
                        style={{
                            boxShadow: "0px 0px 0px #EAECF0",
                        }}
                    >
                        <span className="p-2" style={{ fontWeight: "500", fontSize: "20px", display: "inline-flex" }}>
                            <span className="d-flex">{title}</span>

                            {selectedFilterCount > 0 && (
                                <div className="clear-drawer-filters" onClick={() => clearFilters()}>
                                    <span>
                                        <TText tkey="clear_all_filters" />
                                    </span>
                                </div>
                            )}
                        </span>
                        <div className="d-flex">
                            {props.print && (
                                <ReactToPrint
                                    {...printContent}
                                    trigger={() => (
                                        <IconButton
                                            style={{ height: "35px" }}
                                            className="ml-2"
                                            icon={<CIcon path={iPrint} />}
                                        />
                                    )}
                                    content={() => ref.current}
                                />
                            )}
                            {props.saveIBtn && (
                                <IconButton
                                    style={{ height: 35 }}
                                    onClick={props.saveIBtn}
                                    loading={props?.isLoading}
                                    appearance={"primary"}
                                    color={"green"}
                                    className="ml-2"
                                    icon={<CIcon path={props?.saveIBtnIcon || iCheckBoxChecked} />}
                                />
                            )}
                            {props.calendar && <DateRangeFilter dp={props.dp!} />}
                            {/*{props.formSubmit &&  <SubmitButtonGroup isLoading={props.formSubmit.isLoading} onSubmit={props.formSubmit.onSave} />}*/}
                            <div id={`extra-btn-portal ${id}`} className="mr-2" />
                            {edit && (
                                <IconButton
                                    className="mr-2"
                                    style={{ width: "35px", height: "35px" }}
                                    onClick={() => history.push(edit)}
                                    icon={<CIcon path={iEditPencil} />}
                                />
                            )}
                            <IconButton
                                onClick={props.onClose}
                                style={{ width: "35px", height: "35px" }}
                                icon={<CIcon path={iClose} />}
                            />
                        </div>
                    </div>
                    <div className="d-print-none d-flex flex-wrap">
                        {/* TODO: looks like this prop is not used anywhere */}
                        {props.onSave && (
                            <Button color="blue" onClick={props.onSave} className={"m-2"}>
                                <CIcon path={iPlus} className="mr-2" />
                                {/*<Icon icon={"plus"} className="mr-2" />*/}
                                <TText tkey="save" />
                            </Button>
                        )}
                    </div>
                    <div
                        className={`${
                            overflowAuto ? "overflow-auto" : ""
                        } rounded-lg  clopos-scroll tw-flex-1 print:tw-overflow-visible`}
                        ref={ref}
                    >
                        {props.children}
                    </div>
                </PopupContainerProvider>
            </div>
        </Drawer>
    );
};

export default CDrawer;
