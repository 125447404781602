import SelectFormGroup from "components/Form/SelectFormGroup";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import { t } from "lib/i18n";

import { BrandType } from "types/routes";
import { useAppSelector } from "hooks/useRedux";
import { selectSettingOptionsOf } from "redux/features/settings";
import { useSetting } from "hooks/useSetting";

const TerminalSettingsTab = () => {
    const terminalLanguages = useAppSelector(s => selectSettingOptionsOf(s, "terminal_language"));
    const scaleUnits = useAppSelector(s => selectSettingOptionsOf(s, "scale_unit"));
    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;
    const tipRateOptions = useAppSelector(s => selectSettingOptionsOf(s, "tip_rate"));
    // const terminalRoundingOptions = selectSettingOptions("rounding")?.map(option => ({
    //     name: translateForRounding[option.name],
    //     id: option.id,
    // }));

    // const terminaPrecisionOptions = selectSettingOptions("precision")?.map(option => ({
    //     name: t(`precision_value_${option.id}`),
    //     id: option.id,
    // }));
    return (
        <div className="p-3">
            <SelectFormGroup name="terminal_language.value" data={terminalLanguages} />
            {isRestaurant && <ToggleFormGroup name="apply_tip_rate.value" />}
            {isRestaurant && <SelectFormGroup name="tip_rate.value" data={tipRateOptions} />}
            {isRestaurant && (
                <SelectFormGroup
                    name="terminal_meta.value.terminal_service_calc"
                    label={t("terminal_service_calc")}
                    data={[
                        {
                            name: t("terminal_service_calc_option_0"),
                            id: 0,
                        },
                        {
                            name: t("terminal_service_calc_option_1"),
                            id: 1,
                        },
                    ]}
                />
            )}
            <ToggleFormGroup name="terminal_meta.value.send_logout_user" label={t("send_logout_user")} />
            <ToggleFormGroup name="terminal_meta.value.send_logout_receipt" label={t("send_logout_receipt")} />

            <ToggleFormGroup name="auto_receipt_print.value" />
            <ToggleFormGroup name="ap_show_clients_in_terminal.value" />
            <ToggleFormGroup name="terminal_meta.value.show_product_qty" label={t("product_quantity_visibility")} />

            <SelectFormGroup
                compProps={{ placement: "top" }}
                name="scale_unit.value"
                data={scaleUnits.map(unit => {
                    return {
                        id: unit.id,
                        name: `${t(unit.name as LangKey)}`,
                    };
                })}
            />
        </div>
    );
};

export default TerminalSettingsTab;
