import React from "react";
import { Draggable } from "react-beautiful-dnd";
import ReceiptItemView from "../ReceiptEditor/ReceiptItemView";
import StationReceiptItemView from "../StationReceiptEditor/StationReceiptItemView";
import { IReceiptItem, ReceiptItemType } from "../types";

const ReceiptItemViewByType: Record<ReceiptItemType, any> = {
    receipt: ReceiptItemView,
    stationReceipt: StationReceiptItemView,
};
interface ReceiptItemDraggableProps {
    item: IReceiptItem;
    index: number;
    receiptType: ReceiptItemType;
}
const ReceiptItemDraggable: FCC<ReceiptItemDraggableProps> = ({ item, index, receiptType }) => {
    const Comp = ReceiptItemViewByType[receiptType];
    return (
        <Draggable key={item.uniqueId} isDragDisabled={item.default} draggableId={item.uniqueId} index={index}>
            {provided => (
                <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                    <Comp item={item} index={index} receiptType={receiptType} />
                    {/*<ReceiptItemView item={item} index={index} />*/}
                </div>
            )}
        </Draggable>
    );
};

export default ReceiptItemDraggable;
