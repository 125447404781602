import { GridInputError } from "components/NewForm/Grid/GridInputError";
import { alignments, GridItemAlignment } from "components/NewForm/Grid/grid-item-utils";
import { BareNumberInput } from "core/components/form/BareNumberInput";
import { cn } from "lib/utils";
import { FocusEvent, forwardRef, InputHTMLAttributes, SyntheticEvent, useRef } from "react";

export interface BaseBareInputProps {
    name: string;
    value?: string | number;
    errorMessage?: string;
    postfix?: string;
    align?: GridItemAlignment;
    onChange?: (e: any) => void;
    selectOnFocus?: boolean;
    index?: number;
    className?: string;
    fullWidth?: boolean;
}

export interface BareNumberInputProps extends BaseBareInputProps {
    type: "number";
    // value?: number;
    onChangeValue?: (val: number) => void;
    decimals?: number | [number, number];
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
}

export interface BareTextInputProps extends BaseBareInputProps {
    type?: "text";
    /** Required to automatically adjust width of input */
    // value: string;
    inputProps?: InputHTMLAttributes<HTMLInputElement>;
    onChangeValue?: (val: string) => void;
}
export type BareInputProps = BareNumberInputProps | BareTextInputProps;

export const BareTextInput = forwardRef<HTMLInputElement, BareInputProps>(function BareTextInput(
    { name, errorMessage, postfix, align, selectOnFocus, inputProps, className, fullWidth, ...props },
    ref,
) {
    const inputID = name;
    const dummyValue = props.value;
    if (props.type === "number") {
        align = "right";
        selectOnFocus ??= true;
        // const decimals = Array.isArray(props.decimals) ? props.decimals[0] : props.decimals ?? 0;
        // dummyValue = dummyValue?.toFixed(decimals);
    }
    align ??= "left";
    const inputRef = useRef<HTMLInputElement | null>();

    const onFocus = (e: FocusEvent<HTMLInputElement>) => {
        if (selectOnFocus) {
            e.target.select();
        }
    };

    // Focus input when clicking outside of input inside the cell
    const onClickOutsideInput = (e: SyntheticEvent) => {
        const target = e.target as HTMLElement;
        if (target.tagName === "INPUT") return;
        inputRef.current?.focus();
    };

    const _inputProps = {
        id: inputID,
        "test-id": inputID,
        autoComplete: "off",
        ...inputProps,
        onFocus,
        size: 1,
        className: cn(
            `tw-border-none tw-h-full tw-bg-transparent tw-w-full focus:tw-outline-none`,
            alignments[align],
            inputProps?.className,
        ),
        ref: (e: HTMLInputElement) => {
            inputRef.current = e;
            if (typeof ref === "function") {
                ref(e);
            } else if (ref) {
                ref.current = e;
            }
        },
        value: inputProps?.value as number,
        onChange: (e: any) => {
            if (props.type !== "number") {
                props.onChangeValue?.(e.target.value);
            }
        },
    };

    return (
        <div
            onClick={onClickOutsideInput}
            className={cn(
                "tw-px-2 tw-relative tw-border tw-border-solid tw-border-slate-200 tw-rounded tw-flex tw-flex-row tw-gap-1 tw-items-center ",
                "tw-min-w-20 tw-cursor-text",
                "focus-within:tw-bg-white focus-within:tw-border-blue-400 ",
                "hover:tw-transition-colors tw-duration-[1s] hover:tw-ring-1 tw-ring-blue-300",
                fullWidth ? "tw-max-w-full" : "tw-max-w-[250px]",
                className,
                {
                    "hover:tw-border-blue-300 hover:focus-within:tw-border-blue-400": !(
                        inputProps?.disabled || inputProps?.readOnly
                    ),
                },
            )}
        >
            {align === "right" ? <GridInputError message={errorMessage} /> : null}
            <div className="tw-flex-1 tw-w-min tw-overflow-hidden">
                {props.type === "number" ? (
                    <BareNumberInput
                        {..._inputProps}
                        onChangeValue={props.onChangeValue}
                        fallbackValue={0}
                        decimals={props.decimals}
                    />
                ) : (
                    <input {..._inputProps} />
                )}
                <div className="tw-h-0 tw-overflow-y-hidden tw-whitespace-nowrap tw-px-4">{inputProps?.value}</div>
            </div>
            {align !== "right" ? <GridInputError message={errorMessage} /> : null}

            {postfix && (
                <div className="tw-text-xs tw-flex tw-items-start tw-text-gray-400 tw-italic tw-font-serif tw-h-full tw-align-text-top tw-pt-2">
                    {postfix}
                </div>
            )}
        </div>
    );
});
