import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import HallTableTreeFilter from "components/DataTable/Filters/HallTableTreeFilter";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import SelectFilter from "components/DataTable/Filters/SelectFilter";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import React from "react";
import LocalCheckListFilter from "components/DataTable/Filters/LocalCheckListFilter";
import SourceFilter from "components/DataTable/Filters/SourceFilter";
import TimePickerFormGroup from "components/DataTable/Filters/TimePickerFilter";
import { Colors } from "config/colors";
import { IDataProvider } from "components/DataTable/useDataProvider";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";

interface IProps extends ICDrawerProps {
    dp: IDataProvider;
}

const ModifierReportFilters = (props: IProps) => {
    const brandType: string = useSetting("brand_type")?.value;

    const isRestaurant = brandType === BrandType.RESTAURANT;

    return (
        <CDrawer size="xs" title={t("filters")} {...props}>
            <div className="d-flex flex-column justify-content-between" style={{ height: "89vh" }}>
                <div className="px-3 pt-3 clopos-scroll" style={{ overflowY: "auto" }}>
                    <FiltersContainer hasPadding={true} dp={props.dp}>
                        <SearchFilter placeholder={t("receipt_number")} fields="receipt_id" className="tw-max-w-full" />
                        <label style={labelColStyle}>
                            <TText tkey="interval" />
                        </label>
                        <TimePickerFormGroup />

                        <label style={labelColStyle}>
                            <TText tkey="terminals" />
                        </label>
                        <LocalCheckListFilter
                            fields="receipt_terminal_id"
                            url="terminal"
                            pickerProps={{ placeholder: t("terminals") }}
                            width={450}
                            maxWidth={450}
                        />
                        <label style={labelColStyle}>
                            <TText tkey="hall" />
                        </label>
                        {isRestaurant && (
                            <HallTableTreeFilter
                                fieldName={"receipt_table_id"}
                                searchFieldName={"table_name"}
                                width={450}
                                maxWidth={450}
                            />
                        )}
                        <label style={labelColStyle}>
                            <TText tkey="staff" />
                        </label>
                        <RemoteCheckListFilter
                            fields="receipt_owner_id"
                            remote={{
                                url: "user",
                                refineList: (d: IUser[]) => d?.filter(user => user.owner || !user.hide),
                            }}
                            labelField="username"
                            searchField="username"
                            pickerProps={{ placeholder: t("staff") }}
                            width={450}
                            maxWidth={450}
                        />

                        <SourceFilter showLabel={true} className="tw-max-w-full tw-w-full" />
                        <label style={labelColStyle}>
                            <TText tkey="sales_type" />
                        </label>
                        <RemoteCheckListFilter
                            fields={"sale_type_id"}
                            remote="finance/saleType"
                            searchField="name"
                            pickerProps={{ placeholder: t("sales_type"), placement: "top" }}
                            width={450}
                            maxWidth={450}
                        />

                        <label style={labelColStyle}>
                            <TText tkey="customers" />
                        </label>
                        <RemoteCheckListFilter
                            fields="receipt_customer_id"
                            searchField="name"
                            remote="customer"
                            pickerProps={{ placeholder: t("customers"), placement: "top" }}
                            width={450}
                            maxWidth={450}
                        />
                        <label style={labelColStyle}>
                            <TText tkey="giftable" />
                        </label>
                        <SelectFilter
                            fields="gift"
                            pickerProps={{ searchable: false, placeholder: t("giftable"), placement: "top" }}
                            options={[{ label: t("stats_product_gift"), value: "1" }]}
                            width={450}
                            maxWidth={450}
                        />
                    </FiltersContainer>
                </div>
            </div>
        </CDrawer>
    );
};

export default ModifierReportFilters;

const labelColStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
    marginTop: "24px",
};
