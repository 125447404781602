import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import HallTableTreeFilter from "components/DataTable/Filters/HallTableTreeFilter";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import SelectFilter from "components/DataTable/Filters/SelectFilter";
import ToggleFilter from "components/DataTable/Filters/ToggleFilter";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { ReasonType } from "config/constants";
import { useData } from "hooks/useData";
import LocalCheckListFilter from "components/DataTable/Filters/LocalCheckListFilter";
import SourceFilter from "components/DataTable/Filters/SourceFilter";
import { Colors } from "config/colors";
import { useAppSelector } from "hooks/useRedux";
import { selectSettingOptionsOf } from "redux/features/settings";
import { PopupContainerProvider } from "hooks/usePopupContainer";
import { useRef } from "react";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";

interface IProps extends ICDrawerProps {
    dp: any;
    setDeletedBy?: (value: boolean) => void;
    showOnPage?: "openReceipt" | "deletedReceipt";
}

const col = "d-flex justify-content-between align-items-center w-100";

const ReceiptFilters = (props: IProps) => {
    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;
    const reasons = useData(`reasons`, {
        filters: [["type", ReasonType.delete]],
    });
    const suspicionActions = useAppSelector(s => selectSettingOptionsOf(s, "suspicion_action"));
    const actions = [
        ...(suspicionActions?.map((action: any) => ({
            label: t(`${action.name.toLowerCase()}_rlog_action_title` as LangKey) || action.name,
            value: action.id,
        })) ?? []),
    ];

    const courierControl = (user: IUser) => {
        return user.roles?.[0].abilities.find(i => {
            return i.name === "field_courier";
        });
    };
    const ref = useRef<HTMLElement | null>(null);

    return (
        <CDrawer size="xs" title={t("filters")} {...props}>
            <PopupContainerProvider name="ReceiptFiltersDrawer" container={ref}>
                <div className="tw-flex tw-flex-col tw-justify-between tw-min-h-full">
                    <div className="tw-px-4 tw-pt-4 clopos-scroll tw-h-full tw-overflow-y-auto">
                        <FiltersContainer
                            hasPadding={true}
                            dp={props.dp}
                            ref={reff => {
                                ref.current = reff;
                            }}
                            className="tw-relative tw-flex-col tw-justify-start tw-items-stretch tw-w-full tw-h-full"
                        >
                            {props.showOnPage === "openReceipt" || props.showOnPage === "deletedReceipt" ? null : (
                                <SearchFilter
                                    className="tw-max-w-full"
                                    placeholder={t("description")}
                                    fields="description"
                                />
                            )}
                            {props.showOnPage === "openReceipt" || props.showOnPage === "deletedReceipt" ? null : (
                                <div style={{ margin: "24px 0px" }} className={col}>
                                    <label style={labelRowStyle}>
                                        <TText tkey="deleted_receipts_filter" />
                                    </label>
                                    <ToggleFilter
                                        useParams
                                        fields="trash"
                                        value="2"
                                        title={t("deleted_receipts_filter")}
                                        afterChange={checked =>
                                            props.setDeletedBy ? props.setDeletedBy(!checked) : false
                                        }
                                    />
                                </div>
                            )}

                            {props.showOnPage === "openReceipt" || props.showOnPage === "deletedReceipt" ? null : (
                                <div className={col}>
                                    <label style={labelRowStyle}>
                                        <TText tkey="e_kassa" />{" "}
                                    </label>
                                    <ToggleFilter
                                        fields="fiscal_id"
                                        filterSymbol={"!="}
                                        value={""}
                                        title={t("e_kassa")}
                                    />
                                </div>
                            )}

                            {/* <CheckBoxFilter  /> */}
                            <label style={labelColStyle}>
                                <TText tkey="receipt_owner" />
                            </label>
                            <RemoteCheckListFilter
                                fields="user_id"
                                remote={{
                                    url: "user",
                                    refineList: (d: any[]) => d?.filter(user => user.owner || !user.hide),
                                }}
                                searchField="username"
                                labelField="username"
                                pickerProps={{ placeholder: t("receipt_owner"), className: "tw-w-full" }}
                                maxWidth={450}
                            />
                            <label style={labelColStyle}>
                                <TText tkey="open_by_user" />
                            </label>
                            <RemoteCheckListFilter
                                fields="open_by_user_id"
                                remote={{
                                    url: "user",
                                    refineList: (d: any[]) => d?.filter(user => user.owner || !user.hide),
                                }}
                                searchField="username"
                                labelField="username"
                                pickerProps={{ placeholder: t("open_by_user"), className: "tw-w-full" }}
                                // width={450}
                                maxWidth={450}
                            />
                            <label style={labelColStyle}>
                                <TText tkey="close_by_user" />
                            </label>
                            <RemoteCheckListFilter
                                fields="close_by_user_id"
                                remote={{
                                    url: "user",
                                    refineList: (d: any[]) => d?.filter(user => user.owner || !user.hide),
                                }}
                                searchField="username"
                                labelField="username"
                                pickerProps={{ placeholder: t("close_by_user"), className: "tw-w-full" }}
                                // width={450}
                                maxWidth={450}
                            />
                            <SourceFilter showLabel={true} className="tw-w-full" labelStyle={labelColStyle} />
                            {props.showOnPage === "openReceipt" || props.showOnPage === "deletedReceipt" ? null : (
                                <>
                                    <label style={labelColStyle}>
                                        <TText tkey="payment_method" />
                                    </label>
                                    <RemoteCheckListFilter
                                        fields="paymentMethods"
                                        searchField="name"
                                        remote={{ url: "finance/paymentMethod" }}
                                        pickerProps={{ placeholder: t("payment_method"), className: "tw-w-full" }}
                                        // width={450}
                                        maxWidth={450}
                                    />
                                </>
                            )}
                            <label style={labelColStyle}>
                                <TText tkey="customers" />
                            </label>
                            <RemoteCheckListFilter
                                fields="customer_id"
                                searchField="name"
                                remote="customer"
                                pickerProps={{
                                    placeholder: t("customers"),
                                    placement: "bottom",
                                    className: "tw-w-full",
                                }}
                                // width={450}
                                maxWidth={450}
                            />

                            <label style={labelColStyle}>
                                <TText tkey="suspicion_action" />
                            </label>
                            <SelectFilter
                                fields="suspicious"
                                labelField="name"
                                hasAllOption={true}
                                options={actions}
                                pickerProps={{ placeholder: t("suspicion_action"), className: "tw-w-full" }}
                                // width={450}
                                maxWidth={450}
                            />
                            {props.showOnPage === "openReceipt" || props.showOnPage === "deletedReceipt" ? null : (
                                <>
                                    <label style={labelColStyle}>
                                        <TText tkey="product_delete_tags" />
                                    </label>
                                    <SelectFilter
                                        pickerProps={{
                                            placeholder: t("product_delete_tags"),
                                            className: "tw-w-full",
                                        }}
                                        fields="reason_id"
                                        options={reasons.data?.map((reason: any) => ({
                                            value: reason.id,
                                            label: reason.name,
                                        }))}
                                        // width={450}
                                        maxWidth={450}
                                    />
                                </>
                            )}

                            <label style={labelColStyle}>
                                <TText tkey="hall" />
                            </label>
                            {isRestaurant && (
                                <HallTableTreeFilter
                                    fieldName={"table_id"}
                                    searchFieldName={"table_name"}
                                    width={"100%"}
                                    maxWidth={450}
                                    placement="topStart"
                                />
                            )}
                            {props.showOnPage === "openReceipt" || props.showOnPage === "deletedReceipt" ? null : (
                                <>
                                    <label style={labelColStyle}>
                                        <TText tkey="field_courier" />
                                    </label>
                                    <RemoteCheckListFilter
                                        fields="courier_id"
                                        remote={{
                                            url: "user",
                                            with: ["roles.abilities"],
                                            refineList: (d: IUser[]) =>
                                                d?.filter(user => courierControl(user) && (user.owner || !user.hide)),
                                        }}
                                        labelField="username"
                                        searchField="username"
                                        pickerProps={{
                                            placeholder: t("field_courier"),
                                            placement: "topStart",
                                            className: "tw-w-full",
                                        }}
                                        // width={450}
                                        maxWidth={450}
                                    />
                                </>
                            )}
                            <label style={labelColStyle}>
                                <TText tkey="terminals" />
                            </label>

                            <LocalCheckListFilter
                                fields="terminal_id"
                                url="terminal"
                                pickerProps={{
                                    placeholder: t("terminals"),
                                    placement: "topStart",
                                    className: "tw-w-full",
                                }}
                                // width={450}
                                maxWidth={450}
                            />

                            <label style={labelColStyle}>
                                <TText tkey="cid_long" />
                            </label>

                            <SearchFilter
                                className="tw-w-full tw-max-w-full"
                                placeholder={t("cid_long")}
                                fields="cid"
                            />
                        </FiltersContainer>
                    </div>
                </div>
            </PopupContainerProvider>
        </CDrawer>
    );
};

export default ReceiptFilters;

const labelRowStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
};
const labelColStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
    marginTop: "24px",
};
// const footer = {
//     padding: "28px 0px",
//     boxShadow: "0px 0px 10px #EAECF0",
// };
