import { useMenuCategories } from "hooks/data-hooks/useMenuCategories";
import _ from "lodash";
import { CategoryFilterType } from "./CategoryFilterType";
import CCheckTreePicker from "../../../CheckTreePicker/CCheckTreePicker";
import { getLabel } from "../../../Form/form-utils";
import { Checkbox } from "rsuite";
import { TText } from "../../../i18n/TText";
import React, { useContext, useState } from "react";
import { FiltersContext } from "../FiltersContainer";
import { CategoryTypeEnum } from "config/constants";

interface MenuCategoryFilterType extends CategoryFilterType {
    categoryTypes: CategoryTypeEnum[];
}

const MenuCategoryTreeFilter: FCC<MenuCategoryFilterType> = ({ categoryTypes, ...props }) => {
    const categories = useMenuCategories(true, categoryTypes);
    const fieldName: string = props.fieldName || "category_id";
    const filtersContext = useContext(FiltersContext);
    const dp = filtersContext.dp;

    const searchMaxWidth = props.maxWidth ? props.maxWidth : 225;
    const searchWidth = props.width ? props.width : 290;

    let value = dp.filters.getFilter(fieldName) ?? [];

    value = _.isArray(value[0]) ? _.last(value[0]) : _.last(value) || [];

    const [withoutCategory, setWithoutCategory] = useState(value?.includes(null) ?? false);

    const onSelect = (val: any[], event: React.SyntheticEvent | null) => {
        if (val.length) {
            dp.filters.add([fieldName, val], fieldName);
        } else {
            dp.filters.remove(fieldName);
        }
    };

    const onChangeWithoutCategory = (checked: boolean) => {
        setWithoutCategory(checked);
        let val = [];
        if (checked) {
            value.push(null);
        } else {
            val = value.filter((e: any) => e !== null);
        }

        onSelect(checked ? value : val, null);
    };

    return (
        <CCheckTreePicker
            cascade="child"
            valueKey="id"
            labelKey="name"
            defaultExpandAll={true}
            data={categories?.tree}
            value={value}
            onClean={() => dp.filters.remove(fieldName)}
            style={{ width: searchWidth, maxWidth: searchMaxWidth }}
            menuStyle={{ width: 290 }}
            placement={props.placement ? props.placement : undefined}
            placeholder={props.placeholder || getLabel(fieldName)}
            onChange={onSelect}
            hideSelectAll={true}
            groupBy={categoryTypes.length > 1 ? "type" : undefined}
            {...props?.pickerProps}
            // groupBy={categoryTypes.length > 1}
            customFooter={() => (
                <div className="tw-py-2.5 tw-px-0.5">
                    <Checkbox
                        value={withoutCategory}
                        checked={withoutCategory}
                        onChange={(val, checked) => onChangeWithoutCategory(checked)}
                    >
                        <TText tkey="without_cat" />
                    </Checkbox>
                </div>
            )}
            groupNodeTitle={nodeData => getLabel(nodeData.name)}
        />
    );
};

export default MenuCategoryTreeFilter;
