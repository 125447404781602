import SearchFilter from "components/DataTable/Filters/SearchFilter";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import ManufactureDeductedStock from "components/Drawers/ManufactureDeductedStock";
import ReturnedSuppliesTab from "components/Drawers/ReturnedSuppliesTabs/ReturnedSuppliesTab";
import SuppliedProductsTab from "components/Drawers/ReturnedSuppliesTabs/SuppliedProductsTab";
import { t } from "lib/i18n";
import TabsView from "components/TabsView";
import { useData } from "hooks/useData";
import { useTableHeight } from "hooks/useTableHeight";
import { getFormatedDate } from "lib/utils";
import _ from "lodash";
import { useEffect, useState } from "react";
import { convertToSlug } from "lib/convertToSlug";

interface IProps extends ICDrawerProps {
    op?: IOperation;
    title: string;
    cols?: string[];
    tabTitle?: string;
    showDeductedTab?: boolean;
    // if data is not supplied, it will be fetched from the server ,that means op doesn't have
    disclosed?: boolean;
    with?: string[];
    trash?: any[];
}

const drawerHeader = (title: string, operation: IOperation) => (
    <div className="flex justify-between">
        <div className="d-grid items-center">
            <span>
                {title}
                <span className="text-sm text-gray-600"> - {operation?.supplier?.name}</span>
            </span>
            <span className="tw-text-sm tw-text-secondary">{getFormatedDate(operation?.created_at, true)}</span>
        </div>
    </div>
);

const StockOperationsDrawer: FCC<IProps> = ({
    op,
    cols,
    disclosed,
    trash = [2, ["operation_items.product", 2]],
    ...props
}) => {
    // if (op?.deleted_at) {
    //     trash.push(["operation_items", 2]);
    // }
    const { data: operation } = useData(!!props.dataId && `operation/${op?.id || props.dataId}`, {
        ignoreCache: true,
        params: {
            trash: [...trash, op?.deleted_at ? ["operation_items", 2] : []],
        },
        with: [
            "operation_items.product",
            "operation_items.unit",
            "operation_items.stock",
            "operation_items.stock",
            "supplier",
            // "operation.stock_operations.unit",
            // "operation.stock_operations.product",
            // "stock_operations",
            ...(props?.with ?? []),
        ],
        // filters: {
        //     // @ts-ignore
        //     operation_id: ["operation_id", props.dataId],
        // },
    });

    op = { ...op, ...operation };

    const height = useTableHeight(150);

    const [operationData, setOperationData] = useState<IStockOperation[]>([]);
    let opi: any;
    let isDeletedData: any;
    if (disclosed) {
        opi = operation;
        isDeletedData = op?.supply_returns;
    } else {
        opi = props.data ? props.data : op?.deleted_at ? operation : op;
        isDeletedData = op?.deleted_at ? op?.operation_items : opi?.operation_items;
    }

    useEffect(() => {
        setOperationData(
            isDeletedData?.map((o: any) => ({
                ...o,
                from_storage: opi?.from_storage,
                storage: opi?.storage,
            })) ?? [],
        );
    }, [op?.operation_items, props.data, operation]);

    const onSearch = (val: string) => {
        const searchedString = val;
        const sluggedSearchString = convertToSlug(searchedString);
        if (searchedString) {
            setOperationData(
                _.orderBy(
                    isDeletedData.filter((l: IStockOperation) => {
                        return (
                            l.product?.slug.includes(sluggedSearchString) ||
                            l?.product?.parent_name?.includes(sluggedSearchString)
                        );
                    }),
                    ["id"],
                ),
            );
        } else {
            setOperationData(isDeletedData || []);
        }
    };

    const tabs: any = [
        {
            label: props?.tabTitle || t("products"),
            Component: SuppliedProductsTab,
            props: { stockOperations: operationData, op, cols },
        },
    ];

    if (opi?.supply_returns?.length || op?.supply_returns?.length) {
        tabs.push({
            label: t("returned_supplies"),
            Component: ReturnedSuppliesTab,
            props: { supply_returns: op?.supply_returns || opi?.supply_returns || operation?.supply_returns, ...props },
        });
    }

    if (props?.showDeductedTab) {
        tabs.push({
            label: t("deducted_stock"),
            Component: ManufactureDeductedStock,
            props: { all_operations: opi?.stock_operations || operation?.stock_operations || [] },
        });
    }
    return (
        <CDrawer
            print
            {...props}
            title={drawerHeader(props.title, operation ?? opi)}
            contentHeight={{
                original: height,
                printHeight: height,
            }}
        >
            <div className="tw-px-4 tw-py-2 print:tw-hidden">
                <SearchFilter
                    fields="productSearch"
                    className="tw-w-full tw-max-w-full"
                    placeholder={t("product")}
                    onChange={onSearch}
                />
            </div>
            <TabsView tabs={tabs} className="tw-flex-1 tw-h-auto" />
        </CDrawer>
    );
};

export default StockOperationsDrawer;
