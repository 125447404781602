import { t } from "lib/i18n";
import CForm from "components/Form/CForm/CForm";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import TabsView from "components/TabsView";
import BrandSettingsTab from "pages/settings/general/BrandSettingsTab";
import FinanceSettingsTab from "pages/settings/general/FinanceSettingsTab";
import StockSettingsTab from "pages/settings/general/StockSettingsTab";
import ProductReturnSettingsTab from "./ProductReturnSettingsTab";
import ReasonsDrawer from "components/Drawers/ReasonsDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import { usePackages } from "providers/PackageProvider";
import SocialNetworksDrawer from "components/Drawers/SocialNetworksDrawer";
import { onlyQRMenu } from "config/main";
import { useSettingsForm } from "pages/settings/useSettingsForm";
import JsonView from "components/JsonView";
import { Limitation } from "types/routes";
import WorkTimeDrawer from "components/Drawers/WorkTimeDrawer";

// TODO: SHOULD NOT BE STATIC
const generalPageFields = [
    "address_address",
    "address_city",
    "address_phone",
    "address_zip",
    "brand_logo",
    "brand_name",
    "brand_social_networks",
    "brand_storage",
    "brand_storage_select",
    "brand_storage_station",
    "brand_storage_terminal",
    "brand_storage_terminal_station",
    "brand_type",
    "brand_wifi_info",
    "brand_work_hours",
    "cash_restart",
    "cooking_tag",
    "currency",
    "finance_customer_minus",
    "finance_terminal_balance",
    "language",
    "location",
    "print_address",
    "returns_discount_enable",
    "returns_enable",
    "returns_max_age",
    "returns_storage",
    "money_format",
    "money_fraction",
    "currency_position",
    "stock_minus",
    "timezone",
    "tin",
];

const GeneralSettingsPage: FCC = () => {
    const reasonsDrawer = useDrawer();
    const workTimeDrawer = useDrawer();
    const socialNetworksDrawer = useDrawer();
    const packages = usePackages();
    const hasStockAccess = !!packages.limitation?.[Limitation.STOCK];
    const pp = packages.subscription?.package?.name;
    const hasQRMenu = onlyQRMenu.includes(pp!);

    const form = useSettingsForm({
        selects: generalPageFields,
    });

    return (
        <div className="h-100 p-2">
            {useTitle(t("general"))}
            <HeaderRight formSubmit={{ isLoading: form.isLoading, onSave: form.onSave }} />
            <CForm {...form}>
                <JsonView collapsed />
                <TabsView
                    tabs={[
                        {
                            label: t("brand_setting"),
                            Component: BrandSettingsTab,
                            props: {
                                form,
                                reasonsDrawer,
                                workTimeDrawer,
                                socialNetworksDrawer,
                            },
                        },
                        {
                            label: t("stock_settings"),
                            Component: StockSettingsTab,
                            props: { form },
                            hide: !hasStockAccess,
                        },
                        { label: t("finance"), Component: FinanceSettingsTab, props: form, hide: hasQRMenu },
                        {
                            label: t("product_return_setting"),
                            Component: ProductReturnSettingsTab,
                            props: { form },
                            hide: hasQRMenu,
                        },
                    ]}
                />
            </CForm>
            <ReasonsDrawer {...reasonsDrawer} />
            <WorkTimeDrawer {...workTimeDrawer} />
            <SocialNetworksDrawer {...socialNetworksDrawer} />
        </div>
    );
};

export default GeneralSettingsPage;
