interface IProps {
    val: number | string;
}

const Percentage: FCC<IProps> = props => {
    const num = typeof props.val === "string" ? Number(props.val) : props.val;

    return <span>{parseFloat(num.toFixed(2))}%</span>;
};

export default Percentage;
