import _ from "lodash";
import { Divider } from "rsuite";
import { Constants } from "config/constants";
import CustomerAddresses from "./customer-details/CustomerAddresses";
import CustomerDiscount from "./customer-details/CustomerDiscount";
import CustomerFinances from "./customer-details/CustomerFinances";
import CustomerInfo from "./customer-details/CustomerInfo";
import CustomerWallet from "./customer-details/CustomerWallet";
import CustomerHeader from "./customer-details/header";
import { parseCustomerDate } from "lib/customerDate/parseCustomerDate";
import { formatCustomerDate } from "lib/customerDate/formatCustomerDate";
import { isValidCustomerDate } from "lib/customerDate/isValidCustomerDate";

interface IProps {
    customer: ICustomer;
}

const CustomerDetails: FCC<IProps> = ({ customer }) => {
    const refine = () => {
        const date = parseCustomerDate(customer.date_of_birth);

        return {
            ...customer,
            gender: Constants.genderName[customer.gender],
            date_of_birth: isValidCustomerDate(date) ? formatCustomerDate(date) : null,
            group: customer.group?.name,
            discount: customer.group?.discount_value,
            balance: customer.balance?.amount || 0,
            cashback_balance: customer.cashback_balance?.amount || 0,
            bonus: customer.bonus?.amount || 0,
            addresses: customer.addresses?.filter(address => address),
            phones: customer.phones?.filter(phone => phone),
        };
    };
    const newCustomer = refine();
    const finances = _.pick(newCustomer, ["spent", "total_discount", "total_bonus", "receipt_count"]);
    const infos = _.pick(newCustomer, ["phones", "gender", "date_of_birth", "email", "description", "code"]);

    return (
        <div className="d-flex">
            <div className="flex-fill customer-details">
                <CustomerHeader name={newCustomer.name} phones={newCustomer.phones} customer={customer} />

                <CustomerWallet
                    bonus={newCustomer.bonus}
                    cashback_balance={newCustomer.cashback_balance}
                    balance={newCustomer.balance}
                />

                <Divider className="my-0" />

                <CustomerFinances finances={finances} />

                <Divider className="my-0" />

                {newCustomer.group && <CustomerDiscount group={newCustomer.group} discount={newCustomer.discount} />}

                <CustomerInfo infos={infos} />

                <CustomerAddresses addresses={newCustomer.addresses} />
            </div>
        </div>
    );
};

export default CustomerDetails;
