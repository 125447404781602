import React, { memo } from "react";
import { FIListItemProps, FormItemsList } from "components/FormItems/FormItemsList";
import { FormItems } from "components/FormItems/FormItems";
import { FIHeaderProps } from "components/FormItems/FormItemsHeader";
import { store } from "redux/store";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { useAppSelector } from "hooks/useRedux";
import { Button } from "rsuite";
import { LinkIcon, LinkOffIcon } from "../../../../assets/icons";
import { cn, renderDualLabel } from "lib/utils";
import { MappingTypes } from "config/constants";
import { useParams } from "react-router-dom";
import CProductsDrawer from "../../../../components/Drawers/CProductSelector/CProductsDrawer";
import CProductSelector from "../../../../components/Drawers/CProductSelector/CProductSelector";
import { ProductMappingFormParams } from "pages/menu/product-mapping/menu-product-mapping.types";
import { useCurrency } from "hooks/useCurrency";

const getColumns = (service_name: string): FIHeaderProps["columns"] => {
    return [
        ["name", "left", renderDualLabel(service_name)],
        ["price", "right"],
        ["action"],
        ["id", "left", renderDualLabel("pos")],
        ["name"],
        ["price", "right"],
    ];
};

interface IProductMappingProduct {
    data: IProductMapping[];
}

interface IProps extends FIListItemProps {
    isDraggable?: boolean;
}

const MappingProductItem = memo<IProps>(({ field, fields, itemsFieldName, index, remove, form, isDraggable }) => {
    const currency = useCurrency();
    const productById = useAppSelector(state => state.data.products.byId);
    const getName = (name: string, i?: number) => {
        return `${itemsFieldName}[${i ?? index}].${name}`;
    };

    const getProductName = (id: number) => {
        return productById[id]?.full_name ?? "";
    };

    const getProductPrice = (id: number) => {
        return (productById[id]?.price ?? 0).toFixed(2);
    };

    const onSelectProduct = (id: number) => {
        const productsById = store.getState().data.products.byId;
        const item = productsById[id];
        form.setValue(getName("mapper"), {
            ...field.mapper,
            external_product_key: field.id,
            type: MappingTypes.PRODUCT,
            clopos_product_key: item.id,
        });
    };

    const disconnectMapper = () => {
        form.setValue(getName("mapper"), {
            ...field.mapper,
            external_product_key: field.id,
            clopos_product_key: null,
        });
    };

    return (
        <>
            {/*<GridItemPlain value={getFormData("id")} />*/}
            <GridItemPlain value={field?.name?.tr} />

            <GridItemPlain value={field?.price?.toFixed(2)} postfix={currency} align="right" />

            <Button
                disabled={!field?.mapper?.clopos_product_key}
                onClick={() => disconnectMapper()}
                className={cn("", {
                    "tw-bg-red-50": !!field?.mapper?.clopos_product_key,
                    "tw-bg-gray-50": !field?.mapper?.clopos_product_key,
                })}
            >
                <img src={field?.mapper?.clopos_product_key ? LinkOffIcon : LinkIcon} alt={getName("name.tr")} />
            </Button>

            <GridItemPlain value={field?.mapper?.clopos_product_key} />

            <CProductSelector
                onSelect={onSelectProduct}
                value={field?.mapper?.clopos_product_key}
                disabled={!!field?.mapper?.clopos_product_key}
            />

            <GridItemPlain
                align="right"
                value={getProductPrice(field?.mapper?.clopos_product_key)}
                postfix={currency}
            />
        </>
    );
});

const ProductMappingProductPage: FCC<IProductMappingProduct> = ({ data }) => {
    const params = useParams<ProductMappingFormParams>();
    return (
        <div className="tw-px-4">
            <FormItems itemsFieldName="products">
                <FormItemsList<IProps>
                    showHeader
                    virtualize
                    columns={getColumns(params.service_name)}
                    children={_props => {
                        return <MappingProductItem {..._props} />;
                    }}
                />
            </FormItems>
            <CProductsDrawer />
        </div>
    );
};

export default ProductMappingProductPage;
