import { t } from "lib/i18n";
import CDrawerHeaderRight from "components/Drawers/CDrawerHeaderRight";
import CForm from "components/Form/CForm/CForm";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import JsonView from "components/JsonView";
import TabsView from "components/TabsView";
import { useCForm } from "hooks/useCForm";
import { getDataPropertiesOf, isUrl, roundAndClean } from "lib/utils";
import _ from "lodash";
import { dummyDish } from "models/GoodsModel";
import EditEDishTab from "pages/products/dishes/edit/tabs/EditEDishTab";
import GeneralTab from "pages/products/dishes/edit/tabs/GeneralTab";
import RecipeTab from "pages/products/dishes/edit/tabs/RecipeTab";
import { useIntegrationByName } from "providers/PackageProvider";
import ModificationTab from "pages/products/dishes/edit/tabs/modifications/ModificationTab";
import { useCallback, useEffect } from "react";
import { v4 } from "uuid";
import { ProductType } from "config/constants";
import { useLoading } from "hooks/useLoading";
import { useAppSelector } from "hooks/useRedux";
import { selectSettings } from "redux/features/settings";
import ConfirmModal from "../Drawers/ConfirmModal";
import { useDrawer } from "../Drawers/useDrawer";
import { onConfirmCreateExists, onSubmitCheckName } from "./CheckNameUtil";
import { DishSchema } from "form-schemas/DishSchema";
import { isInDuplicatePage } from "lib/isInDuplicatePage";

interface IProps {
    showPageHeader?: boolean;
    showDrawerHeader?: boolean;
    afterSubmit?: (d: any) => void;
    afterSubmitCallback?: boolean;
    categoryID?: string;
}

const CreateDishComponent: FCC<IProps> = props => {
    const isDuplicate = isInDuplicatePage();

    const settings = useAppSelector(selectSettings);
    const existGoodModal = useDrawer();
    const { isLoading } = useLoading();

    const _refineData = useCallback(
        (d: any) => refineData(d, settings?.returns_max_age?.value),
        [settings?.returns_max_age?.value],
    );

    const form = useCForm({
        url: "product/dish",
        isDuplicate,
        with: ["taxes", "unit", "modificator_groups.modificators.ingredient.unit", "recipe.unit"],
        mode: "all",
        // model: DishModel,
        model2: DishSchema,
        refine: _refineData,
        prepareForSubmit: prepareForSubmit,
        hasAllVenues: true,
        afterSubmit: d => props?.afterSubmit?.(d),
        afterSubmitCallback: props?.afterSubmitCallback,
        dummyData: dummyDish,
    });

    useEffect(() => {
        form.form.setValue("setting_max", settings?.returns_max_age?.value);
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if (!_.isNil(props?.categoryID)) {
            form.form.setValue("category_id", props.categoryID);
            form.form.setValue("meta", {
                ...form.form.getValues("meta"),
                fromMenuConstruct: true,
            });
        }
        // eslint-disable-next-line
    }, [props?.categoryID]);

    const getRecipeItemCount = () =>
        form.form.getValues("contents")?.length ? "(" + form.form.getValues("contents")?.length + ")" : "";
    const getModificatorGroupCount = () =>
        form.form.getValues("modificator_groups")?.length
            ? "(" + form.form.getValues("modificator_groups")?.length + ")"
            : "";
    const name = form.form.watch("name");

    const emenuIntegration = useIntegrationByName("emenu");

    const formTitle = useFormTitle(form.id, "", `${name} - (ID: ${form.id})`);

    return (
        <div>
            {props?.showPageHeader && (
                <>
                    {formTitle}
                    <HeaderRight
                        formSubmit={{
                            isLoading: form.isLoading,
                            onSave: async (e, isSaveCreate) => {
                                await onSubmitCheckName({
                                    form,
                                    existGoodModal,
                                    type: ProductType.DISH,
                                    isSaveCreate,
                                    event: e,
                                });
                            },
                        }}
                    />
                </>
            )}
            {props?.showDrawerHeader && <CDrawerHeaderRight show saveIconBtn formSubmit={form} />}

            <CForm form={form.form} onSave={form.onSave}>
                <JsonView collapsed />
                <TabsView
                    tabs={[
                        {
                            label: t("general"),
                            Component: GeneralTab,
                            testId: "general-tab",
                            hasError: !!form.form.formState.errors.name,
                        },
                        {
                            label: `${t("recipe")} ${getRecipeItemCount()}`,
                            Component: RecipeTab,
                            testId: "receipt-tab",
                            hasError: !!form.form.formState.errors.contents,
                            props: {
                                id: form?.id,
                            },
                        },
                        {
                            label: `${t("modifications_key")} ${getModificatorGroupCount()}`,
                            Component: ModificationTab,
                            testId: "modification-tab",
                            hasError: !!form.form.formState.errors.modificator_groups,
                        },
                        {
                            label: t("emenu"),
                            Component: EditEDishTab,
                            hide: !emenuIntegration,
                            testId: "qrmenu-tab",
                            hasError: !!form.form.formState.errors.meta,
                        },
                    ]}
                />
            </CForm>
            <ConfirmModal
                isLoading={isLoading}
                {...existGoodModal}
                onConfirm={async (id, event) => {
                    await onConfirmCreateExists({
                        event: event!,
                        form,
                        existGoodModal,
                        // saveCreate: !isDuplicate,
                    });
                }}
                onClose={() => existGoodModal.setIsOpen(false)}
                content={t("create_goods_by_exists_name", { name })}
            />
        </div>
    );
};

export default CreateDishComponent;

function refineModificatiorGroups(modificatorGroups: any[]) {
    return modificatorGroups.map((group: any) => {
        group.key = v4();
        group.modificators = _.orderBy(
            group.modificators.map((modificator: any) => {
                const image = !_.isEmpty(modificator.media)
                    ? [{ uuid: modificator.media[0]?.urls?.thumb }]
                    : modificator.ingredient?.image
                      ? [{ uuid: modificator.ingredient?.image?.thumb }]
                      : "";
                return image
                    ? {
                          images: image,
                          ...modificator,
                      }
                    : modificator;
            }),
            "position",
        );
        return group;
    });
}

function refineData(d: any, returns_max_age: any): Partial<IDish> {
    //TODO: transfer contents to recipe
    const recipe: IProduct[] = _.map(d.recipe, (ingredient: IIngredient) => {
        return {
            dish_id: ingredient.pivot?.dish_id,
            gross: ingredient.pivot?.gross,
            ingredient_id: ingredient.pivot?.ingredient_id,
            net: ingredient.pivot?.net,
            total_cost_price: roundAndClean(
                (ingredient.pivot?.gross || 0) * (ingredient.pivot?.cost_price || ingredient.cost_price || 0),
                4,
            ),
            ...ingredient,
        };
    });
    if (isInDuplicatePage()) {
        d.name = d.name + ` (${t("duplicate")}-${new Date().getTime().toString(36) + new Date().getUTCMilliseconds()})`;
    }

    d.contents = recipe;
    d.modificator_groups = refineModificatiorGroups(d.modificator_groups);
    d = _.omit(d, ["recipe"]);
    d.setting_max = returns_max_age;
    d.max_age = !_.isNil(d.max_age) ? `${d.max_age}` : d.max_age;
    if (d.modificator_groups?.length) {
        d.modificator_groups = _.orderBy(
            d.modificator_groups.map((modifier: any) => ({
                ...modifier,
                position: _.isNil(d.meta?.modifier_pos?.[modifier.id]) ? null : d.meta?.modifier_pos?.[modifier.id],
            })),
            "position",
        );
    }

    d.properties = getDataPropertiesOf(d);

    if (!d.meta)
        d.meta = {
            allergies: [],
            diets: [],
            langs: {},
        };
    if (!d.meta?.allergies) d.meta.allergies = [];
    if (Array.isArray(d.meta?.langs)) d.meta.langs = {};
    if (!d.meta?.diets) d.meta.diets = [];
    if (d.meta?.diets?.value) {
        d.meta.diets = d.meta.diets?.value;
    } else if (_.isObject(d.meta?.diets) && !Array.isArray(d.meta?.diets)) {
        const tmp = [];
        for (const [dietKey, dietValue] of Object.entries(d.meta?.diets ?? {})) {
            if (dietValue) {
                tmp.push(dietKey);
            }
        }
        d.meta.diets = tmp;
    }
    if (d.meta?.allergies?.value) {
        d.meta.allergies = d.meta.allergies?.value;
    } else if (_.isObject(d.meta?.allergies) && !Array.isArray(d.meta?.allergies)) {
        const tmp = [];
        for (const [allergyKey, allergyValue] of Object.entries(d.meta?.allergies ?? {})) {
            if (allergyValue) {
                tmp.push(allergyKey);
            }
        }
        d.meta.allergies = tmp;
    }

    //for refine lang to object if is array, because of PHP in backend
    for (const [lang, langValue] of Object.entries(d.meta?.langs ?? {})) {
        if (Array.isArray(langValue)) {
            d.meta.langs[lang] = {};
        }
    }
    return !_.isEmpty(d.media)
        ? {
              images: [{ uuid: d.media[0]?.urls?.thumb }],
              ...d,
          }
        : d;
}

function prepareForSubmit(d: any): any {
    if (d.meta && d.meta.availability) {
        d.meta.availability.itemAvailability = !!d.meta.availability.itemAvailability;
        d.meta.availability.itemVisibility = !!d.meta.availability.itemVisibility;
    }
    d = _.omit(d, ["created_at", "updated_at", "unit"]);
    if ("images" in d && !_.isEmpty(d.images)) {
        d = _.omit(d, isUrl(d.images[0].uuid) ? ["images"] : [""]);
    }
    if (d.contents?.length === 0) {
        d.recipe = [];
    }
    d.recipe = d.contents;

    d = _.omitBy(
        d,
        (v, i) =>
            i !== "venues" &&
            i !== "modificator_groups" &&
            i !== "images" &&
            i !== "recipe" &&
            i !== "taxes" &&
            _.isArray(v) &&
            !v.length,
    );

    d.max_age = d.max_age || null;
    d.price = d.price || 0;
    d.emenu_hidden = d.emenu_hidden ? 1 : 0;
    if (d.modificator_groups?.length) {
        d.modificator_groups = d.modificator_groups.map((modifier: any) => {
            modifier.modificators.map((modificator: any, index: number) => {
                modificator["position"] = index;
                return modificator;
            });
            return _.omit(modifier, ["key", "position"]);
        });
    }

    d.unit_weight = d.recipe?.reduce((acc: number, item: IIngredient) => {
        return acc + (item?.gross || 0) * (item.unit_weight || 0);
    }, 0);
    //added for qr menu, for always have meta
    if (!d.meta) {
        d.meta = {
            allergies: [],
            diets: [],
        };
    }

    if (!d.meta.allergies) d.meta.allergies = [];
    if (!d.meta.diets) d.meta.diets = [];
    return _.omit(d, ["contents", "image", "unit", "meta.fromMenuConstruct"]);
}
