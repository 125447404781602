import { IDataProvider } from "components/DataTable/useDataProvider";
import { useEffect, useRef, useState } from "react";
import { SelectProps } from "rsuite/SelectPicker";

interface IUseInfiniteScroll {
    (dp: IDataProvider): {
        data: any[];
        loadMore: () => void;
        selectProps: SelectProps<any>;
    };
}

export const useInfiniteScroll: IUseInfiniteScroll = dp => {
    const [data, setData] = useState<any[]>([]);
    const lastTotal = useRef<number>(0);
    const lastPage = useRef<number>(0);

    useEffect(() => {
        if (!dp.fetchedAt) return;

        const isTotalChanged = dp.total !== lastTotal.current;

        if (!isTotalChanged && lastPage.current === dp.page) return;

        lastPage.current = dp.page;

        if (isTotalChanged) {
            lastTotal.current = dp.total;
        }

        setData(prevState => [...(isTotalChanged ? [] : prevState), ...(dp.data || [])]);
    }, [dp.fetchedAt]);

    const loadMore = () => {
        dp.setPage(dp.page + 1);
    };

    const selectProps: SelectProps<any> = {
        virtualized: true,
        listProps: {
            onItemsRendered: ({ visibleStopIndex }) => {
                if (visibleStopIndex === data.length - 1 && !dp.isLoading && dp.total > data.length) {
                    loadMore();
                }
            },
        },
    };

    return {
        data,
        loadMore,
        selectProps,
    };
};
