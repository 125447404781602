import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";

import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { QuickLinks } from "components/Header/QuickLinks";
import { useTitle } from "components/Header/Title";
import OperationProcessIndicator from "components/OperationProcessIndicator";
import { Constants, INV_STATUS, OperationStatus, OperationType, ProductType } from "config/constants";
import Ajax from "lib/Ajax";
import _ from "lodash";
import React, { ReactNode, useState } from "react";
import { Badge } from "rsuite";
import CreateButton from "../../../components/DataTable/CreateButton";
import CheckListFilter from "../../../components/DataTable/Filters/CheckListFilter";
import { getLabel } from "components/Form/form-utils";
import Money from "../../../components/mini/Money";
import { useData } from "hooks/useData";
import { productNameAppend } from "lib/data-utils";
import { exportFile } from "lib/export-table";
import { toFixed } from "lib/utils";
import OperationProductsCell from "../misc/OperationProductsCell";
import StockOperationsDrawer from "../misc/StockOperationsDrawer";
import { iClose, iDownload, iWallet } from "assets/icons/Icons";
import { ItemDataType } from "rsuite/cjs/@types/common";
import ExportModal from "../../../components/Header/Export/ExportModal";
import SuppliesPageFilterDrawer from "./drawer/SuppliesPageFilterDrawer";
import ToggleFilterDrawer from "../../../components/DataTable/Filters/ToggleFilterDrawer";
import SuppliesPaymentModal from "./modals/SuppliesPaymentModal";
import DeleteBalanceModal from "./modals/DeleteBalanceModal";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { Colors } from "config/colors";
import LearnButton from "../../../components/DataTable/LearnButton";

export const supplyExportOptions = (id: number) => {
    return [
        {
            name: `${t("invoice")} - ${id}`,
            onClick: () =>
                Ajax.download({ url: `operation/${id}/invoice` }, `${t("supplies")} - ${id} ${t("invoice")}`),
        },
        {
            name: `${t("list")} - ${id}`,
            onClick: () =>
                Ajax.download(
                    { url: `operation/excel-actions-for-supply/${id}` },
                    `${t("supplies")} - ${id} ${t("list")}`,
                ),
        },
    ];
};
const SuppliesPage: FCC = () => {
    const [op, setOp] = useState<IOperation>();
    const [itemDetails, setItemDetails] = useState<any>(null);
    const auth = useAuth();
    // const brandType: string = selectSetting("brand_type")?.value;
    // const isRestaurant = brandType === BrandType.RESTAURANT;
    const paymentDrawer = useDrawer();
    const hasAccessForCategoryAndBalance = auth.hasAbility(["finance_category", "finance_balance_view"]);
    const dp = useDataProvider({
        url: "operation",
        useUrl: true,
        useDates: true,
        extraFilterKeys: ["storage_id", "product_type", "category_id", "balance_id"],
        with: [
            // "product_info_list",
            "products_label",
            "transaction_finance.balance",
            "transaction_finance.operation.supplier",
            "supplier",
            "storage",
            "user",
        ],
        withCount: ["supply_returns"],
        params: {
            trash: [["supplier", 2]],
        },
        sort: ["operated_at", "-1"],
        filters: {
            type: ["type", OperationType.IN],
        },
        refineList: list => {
            return list?.map(ls => {
                return {
                    ...ls,
                    stock_operations:
                        ls.status === 2
                            ? ls.meta?.item || []
                            : _.map(
                                  _.groupBy(ls.stock_operations, sp => sp.product_id),
                                  (a, yt) => ({
                                      ...a[0],
                                      quantity: parseFloat(
                                          toFixed(
                                              a.reduce((fd, rt) => fd + rt.quantity, 0),
                                              4,
                                          ),
                                      ),
                                      total_cost: parseFloat(
                                          toFixed(
                                              a.reduce((fd, rt) => fd + rt.total_cost, 0),
                                              4,
                                          ),
                                      ),
                                  }),
                              ),
                };
            });
        },
    });
    const drawer = useDrawer({ backdrop: false, overflowAuto: true });
    const filterDrawer = useDrawer();
    const exportDrw = useDrawer();
    const deleteBalanceDrawer = useDrawer();

    const summary = useData<any>("operation/total", {
        params: { date: dp.params?.all?.date },
        filters: {
            status: ["status", [OperationStatus.APPROVED]],
            ...dp.filters.all,
        },
    });

    const cm = useColumns<any>("operation/supplies", [
        {
            key: "id",
            align: "right",
            width: 70,
            flexGrow: undefined,
            title: t("data_id"),
            isFixed: true,
            onClick: (d: IOperation) => {
                setOp(d);
                drawer.setDataId(d.id);
            },
        },
        { key: "products", Comp: OperationProductsCell, flexGrow: 2, minWidth: 440 },
        {
            key: "operated_at",
            title: t("operation"),
            type: "datetime",

            flexGrow: 2,
            multiline: true,
        },
        {
            key: "user.username",
            title: t("transaction_by"),
            flexGrow: 2,
        },
        {
            key: "created_at",
            type: "datetime",
            flexGrow: 2,
            multiline: true,
        },
        { key: "supplier.name", title: t("supplier") },
        { key: "storage.name", title: t("storage") },
        {
            key: "transaction_finance.amount",
            type: "money",
            title: t("amount"),
            summary: () => <Money value={summary.data && summary.data[0]?.total_cost} />,
            render: e => {
                if (e.status === 2) {
                    return (
                        <Money
                            status={e.status}
                            value={!e.__finance__?.total_amount ? 0 : e.__finance__?.total_amount}
                        />
                    );
                } else {
                    return <Money value={e.transaction_finance?.amount} />;
                }
            },
        },
        {
            key: "transaction_finance.balance.name",
            title: t("account"),
            render: d =>
                d?.transaction_finance?.balance?.type === "SUPPLIER" ? (
                    <label style={{ color: "red" }}>
                        <TText tkey="unpaid" />
                    </label>
                ) : (
                    d?.transaction_finance?.balance?.name
                ),
        },
        { key: "invoice_number", title: t("invoice_number") },
        { key: "description" },
        {
            key: "inventory_status",
            isFixed: true,
            render: e => <OperationProcessIndicator status={e.inventory_status} />,
        },
        {
            key: "status",
            minWidth: 120,
            render: e => {
                return (
                    <Badge
                        style={{ background: e.status === 1 ? Colors.JungleGreen : Colors.VividOrange }}
                        content={<span>{getLabel(Constants.operationStatus[e.status])}</span>}
                    />
                );
            },
        },
        optionsColumn({
            dp,
            edit: "/inventory/supplies/edit/:id",
            duplicate: "/inventory/supplies/duplicate/:id",
            return: "/inventory/supplies/return/:id",
            delete: "operation/:id",
            preventDelete: (d: IOperation) => {
                return (d?.supply_returns_count ?? 0) > 0;
            },

            // editable: d => (!!d.is_system || d?.supplier?.deleted_at),
            editable: d => !!d.is_system,
            duplicable: d => true,
            // payable: d => d.supplier_remain === 1 && d.status === INV_STATUS.PUBLISHED,
            returnable: d => !!d.is_system || d.status === 2,
            canEdit: "stock_manage_supply_edit",
            canDuplicate: "stock_manage_supply_create",
            canReturnProduct: "returns_manage_create",
            canDelete: "stock_manage_supply_delete",
            // pay: (d: any) => {
            //     paymentDrawer.setData(d);
            //     paymentDrawer.setDataId(d.id);
            // },
            others: d => [
                {
                    label: t("export_btn"),
                    icon: iDownload,
                    onClick: d => {
                        exportDrw.setIsOpen(true);
                        setItemDetails(d);
                    },
                },
                {
                    label: t("make_payment"),
                    icon: iWallet,
                    visible: d =>
                        d?.supplier_remain === 1 &&
                        d?.status === INV_STATUS.PUBLISHED &&
                        hasAccessForCategoryAndBalance,
                    onClick: d => {
                        paymentDrawer.setData(d);
                        paymentDrawer.setDataId(d.id);
                    },
                },
                // {
                //     label: t("change_payment_method_rT"),
                //     icon: iEditPencil,
                //     visible: d => d.supplier_remain !== 1 && d.status === INV_STATUS.PUBLISHED,
                //     onClick: d => {
                //         paymentDrawer.setData(d);
                //         paymentDrawer.setDataId(d.id);
                //     },
                // },
                {
                    label: t("cancel_payment"),
                    visible: d =>
                        d.supplier_remain !== 1 && d.status === INV_STATUS.PUBLISHED && hasAccessForCategoryAndBalance,

                    icon: iClose,
                    style: { color: Colors.BrightRed },
                    onClick: d => {
                        deleteBalanceDrawer.setData(d);
                        deleteBalanceDrawer.setDataId(d.id);
                    },
                },
            ],
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("supplies"), dp.total)}
            <QuickLinks
                links={[
                    {
                        link: "/inventory/supplies",
                        label: t("general"),
                        active: true,
                    },
                    {
                        link: "/inventory/supplies/detailed",
                        label: t("details"),
                    },
                    {
                        link: "/inventory/supplies/deleted_supplies",
                        label: t("deleted_supplies"),
                    },
                    {
                        link: "/inventory/supplies/supply_draft",
                        label: t("draft"),
                    },
                ]}
            />
            <HeaderRight dp={dp} cm={cm} date reload print export={() => exportFile(dp, cm.columns, t("supplies"))} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    {/*<SearchFilter fields="barcode" placeholder={t("barcode")} />*/}
                    <SearchFilter fields="id" placeholder={t("operation_code")} />
                    <SearchFilter fields="description" placeholder={t("description")} />

                    <RemoteCheckListFilter
                        fields="product_id"
                        selectedDetails={{
                            url: "product",
                        }}
                        searchField="name"
                        remote={{
                            url: "product",
                            sort: ["updated_at", "-1"],
                            limit: 20,
                            refineList: (products?: IProduct[]) =>
                                products
                                    ?.map(i => productNameAppend(i))
                                    ?.filter(f => !(f.type === ProductType.GOODS && f.has_modifications)) ?? [],
                        }}
                        pickerProps={{
                            placeholder: t("product"),
                            groupBy: "type",
                            renderMenuGroup: (groupTitle: ReactNode, item: ItemDataType) => {
                                return getLabel((groupTitle || "") as any);
                            },
                            menuStyle: {
                                width: 500,
                            },
                        }}
                        width={140}
                    />
                    <CheckListFilter
                        pickerProps={{ searchable: false }}
                        options={[
                            { label: t("draft"), value: OperationStatus.DRAFT },
                            { label: t("approved"), value: OperationStatus.APPROVED },
                        ]}
                        fields="status"
                    />

                    <RemoteCheckListFilter
                        remote={{
                            url: "supplier",
                            limit: 100,
                        }}
                        fields="supplier_id"
                        searchField="name"
                        pickerProps={{ placeholder: t("supplier") }}
                        width={140}
                    />
                    <ToggleFilterDrawer isOpen={filterDrawer.isOpen} onChange={val => filterDrawer.setIsOpen(val)} />
                </div>

                <LearnButton page="supply" />

                {/* <CreateButton to={"/inventory/supplies/create-beta"} canCreate={"stock_manage_supply_create"} label={`${t("create")} (beta)`} color={"orange"} /> */}
                <CreateButton to={"/inventory/supplies/create"} canCreate={"stock_manage_supply_create"} />
            </FiltersContainer>
            <StockOperationsDrawer
                {...drawer}
                op={op}
                // disclosed
                title={t("total_supply") + " #" + drawer.dataId}
                cols={["product.name", "quantity", "cost", "total_cost"]}
                with={["supply_return_operations", "supply_returns"]}
            />
            <SuppliesPageFilterDrawer dp={dp} {...filterDrawer} />
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
            {itemDetails?.id && exportDrw.isOpen && (
                <ExportModal exportOptions={supplyExportOptions(itemDetails.id)} {...exportDrw} />
            )}
            {hasAccessForCategoryAndBalance && paymentDrawer.isOpen ? (
                <SuppliesPaymentModal {...paymentDrawer} revalidate={dp.revalidate} />
            ) : null}
            <DeleteBalanceModal {...deleteBalanceDrawer} revalidate={dp.revalidate} />
        </div>
    );
};

export default SuppliesPage;
