import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import Money from "components/mini/Money";
import { OperationType, ReasonType } from "config/constants";
import _ from "lodash";
import React, { useState } from "react";
import CreateButton from "../../../components/DataTable/CreateButton";
import OperationProductsCell from "../misc/OperationProductsCell";
import StockOperationsDrawer from "../misc/StockOperationsDrawer";
import { useData } from "hooks/useData";
import { Tooltip, Whisper } from "rsuite";
import { exportFile } from "lib/export-table";
import OperationProcessIndicator from "components/OperationProcessIndicator";
import WhisperWithoutIcon from "components/mini/WhisperWithoutIcon";
import CheckBoxFilter from "components/DataTable/Filters/CheckBoxFilter";
import classNames from "classnames";
import Ajax from "lib/Ajax";
import { QuickLinks } from "components/Header/QuickLinks";
import { iDownload } from "assets/icons/Icons";
import { RowDataType } from "rsuite-table/src/@types/common";
import ExportModal from "../../../components/Header/Export/ExportModal";
import LocalCheckListFilter from "../../../components/DataTable/Filters/LocalCheckListFilter";
import LearnButton from "../../../components/DataTable/LearnButton";
import { SelectStorageFilter } from "components/adhoc-filters/SelectStorageFilter";

const WastesPages: FCC = () => {
    const [op, setOp] = useState<IOperation>();
    const [itemDetails, setItemDetails] = useState<any>(null);

    const dp = useDataProvider({
        url: "operation",
        useUrl: true,
        useDates: true,
        with: ["products_label", "total_cost", "supplier", "storage", "reason_data"],
        params: {
            sort: [["operated_at", "-1"]],
        },
        filters: {
            type: ["type", OperationType.WASTE],
        },
    });
    const drawer = useDrawer({ backdrop: false, overflowAuto: true });
    const exportDrw = useDrawer();

    const summary = useData<any>("operation/total", {
        params: { date: dp.params.all.date },
        filters: {
            type: ["type", 4],
            ..._.values(dp.filters.all),
        } as any,
    });
    const cm = useColumns<any>("operation", [
        {
            key: "id",
            isFixed: true,
            align: "left",
            width: 70,
            flexGrow: undefined,
            title: t("data_id"),
            onClick: (d: IOperation) => {
                setOp(d);
                drawer.setDataId(d.id);
            },
        },
        { key: "products", Comp: OperationProductsCell, flexGrow: 3 },
        {
            key: "operated_at",
            title: t("operation"),
            type: "datetime",
            flexGrow: 1,
            multiline: true,
            showSeconds: true,
        },
        {
            key: "created_at",
            title: t("created_at"),
            type: "datetime",
            flexGrow: 1,
            multiline: true,
            showSeconds: true,
        },
        { key: "storage.name", title: t("storage"), sortable: true },
        {
            key: "inventory_status",
            isFixed: true,
            render: e => <OperationProcessIndicator status={e.inventory_status} />,
        },
        {
            key: "reason",
            align: "left",
            title: t("reason"),
            render: (d: any) =>
                d?.reason_data ? (
                    <Whisper
                        placement="bottom"
                        trigger="hover"
                        speaker={<Tooltip>{d?.reason_data?.info?.name}</Tooltip>}
                    >
                        <div className={"review-description-container"}>
                            <p className={"review-description-label"}>{d?.reason_data?.info?.name}</p>
                        </div>
                    </Whisper>
                ) : (
                    "--"
                ),
        },
        { key: "description", render: e => <WhisperWithoutIcon description={e.description} /> },
        // {
        //     key: "reason", align: "left", title: t("description"), render: (d: any) => (
        //         d?.reason ? <Whisper placement="bottom" trigger="hover" speaker={<Tooltip>
        //             {d?.reason}
        //         </Tooltip>}>
        //             <div className={"review-description-container"}>
        //                 <p className={"review-description-label"}>{d?.reason}</p>
        //             </div>
        //         </Whisper> : "--"
        //     )
        // },
        {
            key: "total",
            title: t("sum_total"),
            render: (d: IOperation) => <Money value={d.total_cost} />,
            summary: () => {
                const totalCost = summary.data && summary.data[0] ? summary.data[0].total_cost : 0;
                return <Money value={totalCost} />;
            },
        },
        optionsColumn({
            dp,
            delete: "operation/:id",
            edit: "/inventory/write-off/edit/:id",
            preventDelete: d => !!d.is_system,
            editable: d => d?.deleted_at || !!d.is_system,
            canEdit: "stock_manage_waste_manage",
            canDelete: "stock_manage_waste_manage",
            canDuplicate: "stock_manage_waste_manage",
            others: d =>
                !d.deleted_at
                    ? [
                          {
                              label: t("export_btn"),
                              icon: iDownload,

                              onClick: d => {
                                  exportDrw.setIsOpen(true);
                                  setItemDetails(d);
                              },
                          },
                      ]
                    : [],
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("write_off"), dp.total)}

            <QuickLinks
                links={[
                    {
                        link: "/inventory/write-off",
                        label: t("write_off"),
                        active: true,
                    },
                    {
                        link: "/inventory/write-off/detailed",
                        label: t("details"),
                    },
                    {
                        link: "/inventory/write-off/reason-reports",
                        label: t("write_off_reason_reports"),
                    },
                    {
                        link: "/inventory/write-off/reasons",
                        label: t("reasons"),
                    },
                ]}
            />

            <HeaderRight dp={dp} cm={cm} date reload print export={() => exportFile(dp, cm.columns, t("write_off"))} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="productSearch" placeholder={t("product")} />
                    <SelectStorageFilter multi />

                    <LocalCheckListFilter
                        fields="reason_id"
                        url="reasons"
                        config={{ params: { filters: [["type", ReasonType.wastes]], limit: 999 } } as any}
                        pickerProps={{ placeholder: t("reason") }}
                    />
                    <CheckBoxFilter
                        useParams
                        fields="trash"
                        value={[2, ["operation_items.product", 2]]}
                        title={t("show_deleted_values")}
                    />
                </div>

                <LearnButton page="write_off" />

                <CreateButton to={"/inventory/write-off/create"} canCreate={"stock_manage_waste_manage"} />
            </FiltersContainer>
            <StockOperationsDrawer
                {...drawer}
                op={op}
                tabTitle={t("write_off")}
                title={t("write_off") + " #" + drawer.dataId}
                cols={["product.name", "quantity", "cost", "total_cost"]}
            />
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                    affixHeader: true,
                    rowClassName: (d?: RowDataType) =>
                        classNames({
                            "deleted-row": d && !!d?.deleted_at,
                        }),
                }}
            />
            {itemDetails?.id && exportDrw.isOpen && (
                <ExportModal
                    exportOptions={[
                        {
                            name: `${t("write_off")} - ${itemDetails.id}`,
                            onClick: () =>
                                Ajax.download(
                                    { url: `operation/${itemDetails.id}/invoice` },
                                    `${t("write_off")} #${itemDetails.id}`,
                                ),
                        },
                    ]}
                    {...exportDrw}
                />
            )}
        </div>
    );
};

export default WastesPages;
