import { t } from "lib/i18n";
import { FC } from "react";
import {
    endOfLastMonthF,
    endOfLastWeekF,
    endOfMonthF,
    endOfWeekF,
    FORMAT_ISO_DATE,
    formatWithCustomFormat,
    last3F,
    lastDay,
    lastDayF,
    nextDayF,
    startOfLastMonthF,
    startOfLastWeekF,
    startOfMonthF,
    startOfWeekF,
} from "config/dates";
import CIcon from "components/CIcon";
import { iChevronLeft, iChevronRight } from "assets/icons/Icons";
import { DatePicker } from "rsuite";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import {
    setDashboardCategories,
    setDashboardDate,
    setDashboardDateRangeIndex,
    setDashboardDateRanges,
    setDashboardDates,
    setIsCalendarOpen,
    setPointStart,
} from "redux/features/venue-dashboard";
import { getFormatedDate } from "lib/utils";
import { passCashRestartCheck } from "lib/passCashRestartCheck";
import { format } from "date-fns";
import { useSetting } from "hooks/useSetting";

const formatDate = (date: Date) => format(date, FORMAT_ISO_DATE);

export const VenueDashboardDate: FC = () => {
    const dispatch = useAppDispatch();

    let calendarRef: any;

    const { dateRange, rangeIndex, date, isCalendarOpen } = useAppSelector(state => state.venueDashboard);

    const cashRestart = useSetting("cash_restart")?.value;
    const today = passCashRestartCheck(cashRestart);

    const _date = new Date(date);

    const dateRanges = (): Array<any> => {
        return [
            { label: t("today"), value: [formatDate(today), formatDate(today)], placement: "left" },
            { label: t("yesterday"), value: [lastDay(today), lastDay(today)], placement: "left" },
            { label: t("last_3_days"), value: [last3F(today), formatDate(today)], placement: "left" },
            { label: t("thisweek"), value: [startOfWeekF(), endOfWeekF()], placement: "left" },
            { label: t("lastweek"), value: [startOfLastWeekF(), endOfLastWeekF()], placement: "left" },
            { label: t("this_month"), value: [startOfMonthF(), endOfMonthF()], placement: "left" },
            { label: t("last_month"), value: [startOfLastMonthF(), endOfLastMonthF()], placement: "left" },
        ];
    };

    const addDays = (currentDate: any) => {
        const date = new Date(currentDate);
        date.setDate(date.getDate() + 1);
        return date;
    };

    const selectDateRange = (data: Array<string>, index: any) => {
        const categories = [];
        const dates = [];
        let currentDate: Date = new Date(data[0]);
        while (currentDate <= new Date(data[1])) {
            dates.push(formatWithCustomFormat(currentDate));
            categories.push(getFormatedDate(currentDate));
            currentDate = addDays(currentDate);
        }
        const startDate = new Date(dates[0]);
        dispatch(setPointStart(Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate())));
        dispatch(setDashboardCategories(categories));
        dispatch(setDashboardDates(dates));

        dispatch(setDashboardDateRangeIndex(index));
        dispatch(setDashboardDateRanges(data));
    };

    const _nextDay = () => {
        const nextDay = nextDayF(_date);
        dispatch(setDashboardDate(new Date(nextDay)));
        selectDateRange([nextDay, nextDay], null);
    };

    const _lastDay = () => {
        const lastDay = lastDayF(_date);
        dispatch(setDashboardDate(new Date(lastDay)));
        selectDateRange([lastDay, lastDay], null);
    };

    const selectDate = (__date: Date, event: any) => {
        event.stopPropagation();
        dispatch(setIsCalendarOpen(false));
        dispatch(setDashboardDate(__date));
        selectDateRange([formatWithCustomFormat(__date), formatWithCustomFormat(__date)], null);
    };

    const isEnableNextDayButton = (): boolean => {
        return formatWithCustomFormat(_date) < formatWithCustomFormat(new Date());
    };

    return (
        <div className="filter-container">
            <div className="date-range-container">
                {dateRanges().map((date, index) => {
                    return (
                        <div
                            key={date.label}
                            onClick={() => {
                                selectDateRange(date.value, index);
                                calendarRef?.close();
                            }}
                            className={"date-range " + (index === rangeIndex ? "active" : "")}
                        >
                            <label>{date.label}</label>
                        </div>
                    );
                })}
            </div>
            <div className="calendar-container">
                <button className="left-side" onClick={() => _lastDay()}>
                    <CIcon path={iChevronLeft} />
                </button>
                <div className="calendar" onClick={() => setIsCalendarOpen(true)}>
                    <DatePicker
                        ref={(ref: any) => (calendarRef = ref)}
                        format="dd MMMM, yyyy"
                        value={_date}
                        editable={false}
                        open={isCalendarOpen}
                        shouldDisableDate={d => {
                            const operatedAt = new Date(date);
                            operatedAt.setHours(0, 0, 0, 0);

                            const now = new Date();
                            now.setHours(23, 59, 59, 999);

                            return !!d && d.getTime() > now.getTime();
                        }}
                        renderValue={vals => {
                            let start, end, year;
                            if (dateRange[0]) {
                                const range1 = new Date(dateRange[0]);
                                const range2 = new Date(dateRange[1]);
                                if (range1.getFullYear() !== range2.getFullYear()) {
                                    start = formatWithCustomFormat(range1, "d MMM, yyyy");
                                    end = formatWithCustomFormat(range2, "d MMM, yyyy");
                                } else {
                                    year = formatWithCustomFormat(range1, ", yyyy");
                                    start = formatWithCustomFormat(range1, "d MMM");
                                    end = formatWithCustomFormat(range2, "d MMM");
                                }
                            }
                            return start + (start !== end ? " - " + end : "") + year;
                        }}
                        cleanable={false}
                        onOk={(date, event) => selectDate(date, event)}
                    />
                </div>
                <button className="right-side" disabled={!isEnableNextDayButton()} onClick={() => _nextDay()}>
                    <CIcon path={iChevronRight} />
                </button>
            </div>
        </div>
    );
};
