import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";

import { SyntheticEvent, useState } from "react";
import RemoteSelectFormState from "../RemoteSelectPickerState";
import { Button, Input, Message, Modal, toaster } from "rsuite";
import JsonViewState from "../JsonViewState";
import Ajax from "lib/Ajax";
import { dummyIngredientData } from "models/GoodsModel";
import _ from "lodash";
import { translateUnit } from "lib/utils";
import { ICDrawerProps } from "components/Drawers/CDrawer";
import CModal from "components/Drawers/CModal";

interface IProps extends ICDrawerProps {
    // closeModal: (state?: boolean) => void;
    // onChange: (state: boolean) => void;
    onSubmit: (res?: any) => void;
}

const CreateIngredientModalState: FCC<IProps> = ({ onSubmit, ...props }) => {
    const [isDataLoading, setDataLoading] = useState(false);

    const [name, setName] = useState("");
    const [category, setCategory] = useState(null);
    const [unit, setUnit] = useState(null);

    const resetForm = () => {
        setName("");
        setCategory(null);
        setUnit(null);
    };

    // const closeAction = () => {
    //     resetForm();
    //     // closeModal(true);
    //     props.onClose();
    // };

    const createAction = async (e: SyntheticEvent) => {
        if (name === "") {
            return toaster.push(
                <Message type="warning" showIcon closable>
                    <TText tkey="empty_items" />
                </Message>,
            );
        }
        setDataLoading(true);
        const res = await Ajax.post({
            url: "product/ingredient",
            data: {
                ...dummyIngredientData(),
                name,
                category_id: category,
                unit_id: unit,
            },
        }).catch(() => {
            setDataLoading(false);
            toaster.push(
                <Message type="error" showIcon closable>
                    <TText tkey="error" />
                </Message>,
            );
        });
        if (res?.success) {
            resetForm();
            props.onClose?.(e);
            onSubmit(res);
        } else {
            toaster.push(
                <Message type="error" showIcon closable>
                    <TText tkey="error" />
                </Message>,
            );
        }
        setDataLoading(false);
    };

    return (
        <CModal size={"xs"} onExited={resetForm} {...props}>
            <Modal.Header>
                <Modal.Title>{t("create_by_name", { name: t("ingredient") })}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div
                    style={{
                        margin: "0 auto",
                        width: 300,
                    }}
                >
                    <Input
                        style={{ width: 300, marginBottom: 10 }}
                        value={name}
                        onChange={(val: string) => {
                            setName(val);
                            // props.onChange(true);
                        }}
                        placeholder={t("name")}
                    />

                    <RemoteSelectFormState
                        itemLabel={"name"}
                        itemValue={"id"}
                        remote={"category"}
                        searchField={"name"}
                        onSelect={(v: any) => {
                            setCategory(v);
                            // props.onChange(true);
                        }}
                        value={category}
                        remoteQueries={{
                            filters: [["type", "INGREDIENT"]],
                            limit: 999,
                            page: 1,
                            tree: 0,
                        }}
                        pickerProps={{
                            cleanable: true,
                            placeholder: t("select", { name: t("category") }),
                            sort: () => (a: any, b: any) => a.item._lft - b.item._lft,
                            renderMenuItem: (label: any, item: any) => {
                                return (
                                    <span>
                                        {_.times(item.item.depth).map((x, i) => (
                                            <span key={i} className="px-2">
                                                --
                                            </span>
                                        ))}
                                        {label}
                                    </span>
                                );
                            },
                        }}
                        style={{ marginBottom: 10 }}
                    />

                    <RemoteSelectFormState
                        itemLabel={"name"}
                        itemValue={"id"}
                        remote={"unit"}
                        onSelect={(v: any) => {
                            setUnit(v);
                            // props.onChange(true);
                        }}
                        value={unit}
                        remoteQueries={{
                            filters: [["type", "!=", 4]],
                        }}
                        refineData={(d: any) => d.map((u: any) => translateUnit(u))}
                        pickerProps={{
                            searchable: false,
                            placeholder: t("select", { name: t("unit") }),
                        }}
                    />
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={props.onClose} appearance="subtle">
                    <TText tkey="cancel" />
                </Button>
                <Button onClick={createAction} appearance="primary" color={"green"} loading={isDataLoading}>
                    <TText tkey="save" />
                </Button>
            </Modal.Footer>
            <JsonViewState value={{ name, category, unit }} />
        </CModal>
    );
};

export default CreateIngredientModalState;
