import { TText } from "components/i18n/TText";
import { Button } from "rsuite";
import CIcon from "./CIcon";
import { iMultiSelectTable } from "assets/icons/Icons";
import { useAppDispatch } from "hooks/useRedux";
import { setIsSingleSelect, setOpenProductSelector } from "redux/features/productsSelector/productsSelector";
import { useProductSelectorContext } from "providers/ProductSelectorProvider";
import { store } from "redux/store";

interface IProps {
    onSave: (data: any[]) => void;
    // groupBy?: string;
    // refineList?: (list: any[]) => void;
    // title?: string;
    // disabledItems?: any[];
    // labelKey?: string;
    // valueKey?: string;
    // renderLabel?: (item: any) => any;
    // searchBy?: any;
}

// const isWorking = false;

const CMultiSelectBeta: FCC<IProps> = ({ onSave }) => {
    // const { products } = useAppSelector(state => state.data);
    // const { onSave } = props;
    const dispatch = useAppDispatch();
    const { updateConfig } = useProductSelectorContext();

    const renderSelectedData = (selectedIds: number[]) => {
        // console.log("selectedItems : ", selectedItems);
        // const items: any[] = [];
        const productsById = store.getState().data.products.byId;
        // _.forEach(selectedItems, (item: number) => items.push(_.find(refineProducts(), { id: item })));
        onSave(selectedIds.map((id: number) => productsById[id]));
    };

    return (
        <Button
            test-id="multi-select-btn"
            onClick={() => {
                updateConfig({ onProductSelectorSave: renderSelectedData });
                dispatch(setIsSingleSelect(false));
                dispatch(setOpenProductSelector(true));
            }}
        >
            <CIcon size={0.8} path={iMultiSelectTable} />
            <TText tkey="multiple_select" />
        </Button>
    );
};

export default CMultiSelectBeta;
