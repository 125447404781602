import { useEffect, useState } from "react";
import { DeviceType, StatusType } from "config/constants";
import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";
import { t } from "lib/i18n";
import Ajax from "lib/Ajax";
import CForm from "components/Form/CForm/CForm";
import SelectFormGroup from "components/Form/SelectFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import JsonView from "components/JsonView";
import ConfirmModal from "components/Drawers/ConfirmModal";
import { useDrawer } from "components/Drawers/useDrawer";
import CIcon from "components/CIcon";
import { useCForm } from "hooks/useCForm";
import { iInfoOutline } from "assets/icons/Icons";
import _ from "lodash";

interface ITerminalPriceData {
    prices: Record<string, string>;
    currency: string;
}

const CreateTerminalSchema: ValidationSchema = {
    name: ruleset.required,
    status: ruleset.required,
    device_type: ruleset.required,
};

export const CreateTerminalPage: FCC = () => {
    const confirmModal = useDrawer();
    const [terminalPriceData, setTerminalPriceData] = useState<ITerminalPriceData>({
        prices: {},
        currency: "",
    });

    const form = useCForm({
        url: "terminal",
        model2: CreateTerminalSchema,
    });

    useEffect(() => {
        Ajax.get({
            url: "/terminals/prices/list",
        }).then(res => {
            setTerminalPriceData(res.data);
        });
    }, []);

    const openConfirmModal = () => {
        form.form.trigger().then(valid => {
            valid && confirmModal.setIsOpen(true);
        });
    };

    const handleSave = () => {
        void form.onSave();
    };

    const deviceType = form.form.watch("device_type");
    const devicePriceWithCurrency = `${terminalPriceData.prices[deviceType]} ${terminalPriceData.currency}`;

    return (
        <div className="tw-px-4">
            {useFormTitle()}

            <HeaderRight
                customSubmitEvent={{
                    isLoading: false,
                    onSubmit: openConfirmModal,
                }}
            />

            <CForm form={form.form} onSave={form.onSave}>
                <JsonView />
                <TextFormGroup name="name" errorPlacement="rightEnd" focus={true} />
                <SelectFormGroup
                    name="status"
                    errorPlacement="rightEnd"
                    compProps={{ cleanable: true }}
                    data={[
                        { id: StatusType.INACTIVE, name: t("inactive") },
                        { id: StatusType.ACTIVE, name: t("active") },
                    ]}
                />
                <SelectFormGroup
                    name="device_type"
                    errorPlacement="rightEnd"
                    compProps={{
                        cleanable: true,
                    }}
                    data={[
                        { id: DeviceType.DESKTOP, name: t("desktop") },
                        { id: DeviceType.MOBILE, name: t("mobile") },
                    ]}
                />
                {!_.isNil(deviceType) && (
                    <div className="rs-form-group">
                        <label className="text-left rs-form-control-label"></label>
                        <div className="rs-form-control-wrapper">
                            <div className="tw-flex tw-items-center tw-gap-1 tw-text-system-blue">
                                <CIcon path={iInfoOutline} size="22" />
                                <p>{t("subscription_add_fee", { price: devicePriceWithCurrency })}</p>
                            </div>
                        </div>
                    </div>
                )}
            </CForm>

            <ConfirmModal
                {...confirmModal}
                content={`${t("subscription_add_fee", { price: devicePriceWithCurrency })}, ${t("are_you_sure")}`}
                isLoading={form.isLoading}
                onConfirm={handleSave}
            />
        </div>
    );
};
