import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React from "react";
import CDrawer, { ICDrawerProps } from "../../../components/Drawers/CDrawer";
import FiltersContainer from "../../../components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "../../../components/DataTable/Filters/RemoteCheckListFilter";
import SelectFilter from "../../../components/DataTable/Filters/SelectFilter";
import { getVenuesList } from "lib/utils";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useIntegrationByName } from "providers/PackageProvider";
import { CategoryTypeEnum } from "config/constants";
import { Colors } from "config/colors";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";

interface IProps extends ICDrawerProps {
    dp: any;
    setDeletedBy?: (value: boolean) => void;
    showOnPage?: "openReceipt" | "deletedReceipt";
}

const ProductsFilterDrawer = (props: IProps) => {
    const venueList = getVenuesList() || [];
    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;
    const auth = useAuth();
    const emenuIntegration = useIntegrationByName("emenu");

    return (
        <CDrawer size="xs" title={t("filters")} {...props}>
            <div className="d-flex flex-column justify-content-between" style={{ height: "89vh" }}>
                <div className="px-3 pt-3 clopos-scroll" style={{ overflowY: "auto" }}>
                    <FiltersContainer hasPadding={true} dp={props.dp}>
                        {venueList?.length > 1 ? (
                            <>
                                <label style={labelColStyle}>
                                    <TText tkey="active_products_venue" />
                                </label>
                                <RemoteCheckListFilter
                                    remote={{
                                        url: "venues",
                                    }}
                                    fields="venue_active"
                                    pickerProps={{
                                        placeholder: t("active_products_venue"),
                                        searchable: false,
                                    }}
                                    width={450}
                                    maxWidth={450}
                                />
                                <label style={labelColStyle}>
                                    <TText tkey="inactive" />
                                </label>
                                <RemoteCheckListFilter
                                    remote={{
                                        url: "venues",
                                    }}
                                    fields="venue_inactive"
                                    pickerProps={{
                                        placeholder: t("inactive"),
                                        searchable: false,
                                    }}
                                    width={450}
                                    maxWidth={450}
                                />
                            </>
                        ) : (
                            <>
                                <label style={labelColStyle}>
                                    <TText tkey="status" />
                                </label>
                                <SelectFilter
                                    fields="hidden"
                                    pickerProps={{
                                        searchable: false,
                                        placeholder: t("status"),
                                    }}
                                    options={[
                                        { label: t("active"), value: "0" },
                                        { label: t("inactive"), value: "1" },
                                    ]}
                                    width={450}
                                    maxWidth={450}
                                />
                            </>
                        )}

                        {isRestaurant ? (
                            <>
                                <label style={labelColStyle}>
                                    <TText tkey="have_ingredients" />
                                </label>
                                <SelectFilter
                                    fields="haveIngredients"
                                    fieldType={"params"}
                                    pickerProps={{
                                        searchable: false,
                                        placeholder: t("have_ingredients"),
                                    }}
                                    options={[
                                        { label: t("does_have_ingredients"), value: "1" },
                                        { label: t("does_not_have_ingredients"), value: "-1" },
                                    ]}
                                    width={450}
                                    maxWidth={450}
                                />
                            </>
                        ) : null}

                        <label style={labelColStyle}>
                            <TText tkey="giftable" />
                        </label>
                        <SelectFilter
                            fields="giftable"
                            pickerProps={{ searchable: false, placeholder: t("giftable") }}
                            options={[
                                { label: t("is_giftable"), value: "1" },
                                { label: t("is_not_giftable"), value: "0" },
                            ]}
                            width={450}
                            maxWidth={450}
                        />
                        <label style={labelColStyle}>
                            <TText tkey="tax" />
                        </label>
                        <RemoteCheckListFilter
                            remote={{
                                url: "finance/taxes",
                                refineList: (list: any[]) => {
                                    list?.push({ id: null, name: t("none") });
                                    return list;
                                },
                                stop: !auth.hasAbility(["tax_view"]),
                            }}
                            hasPermission={auth.hasAbility(["tax_view"])}
                            fields="tax_id"
                            pickerProps={{ placeholder: t("tax"), searchable: false }}
                            width={450}
                            maxWidth={450}
                        />
                        <label style={labelColStyle}>
                            <TText tkey="write_off_method" />
                        </label>
                        <SelectFilter
                            fields="inventory_behavior"
                            pickerProps={{ searchable: false, placeholder: t("write_off_method") }}
                            options={[
                                { label: t("write_off_ingredients"), value: "0" },
                                { label: t("write_off_ready_item"), value: "1" },
                            ]}
                            width={450}
                            maxWidth={450}
                        />
                        <label style={labelColStyle}>
                            <TText tkey="ignore_stock" />
                        </label>
                        <SelectFilter
                            fields="inventory_behavior"
                            pickerProps={{ searchable: false, placeholder: t("ignore_stock") }}
                            options={[
                                { label: t("yes"), value: "3" },
                                { label: t("non"), value: "0" },
                            ]}
                            width={450}
                            maxWidth={450}
                        />

                        {emenuIntegration ? (
                            <>
                                <label style={labelColStyle}>
                                    <TText tkey="emenu_category" />
                                </label>
                                <RemoteCheckListFilter
                                    width={450}
                                    maxWidth={450}
                                    remote={{
                                        limit: 999,
                                        params: { tree: 0 },
                                        url: "category",
                                        filters: {
                                            type: ["type", CategoryTypeEnum.PRODUCT],
                                        },
                                    }}
                                    searchField="name"
                                    fields={"emenu_category_id"}
                                    pickerProps={{
                                        placement: "topEnd",
                                        placeholder: t("emenu_category"),
                                        cleanable: true,
                                    }}
                                />
                            </>
                        ) : null}
                    </FiltersContainer>
                </div>
            </div>
        </CDrawer>
    );
};

export default ProductsFilterDrawer;

const labelRowStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
};
const labelColStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
    marginTop: "16px",
};
