import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import _ from "lodash";
import { Timeline } from "rsuite";
import { iDot } from "assets/icons/Icons";
import CIcon from "../../../components/CIcon";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { Colors } from "config/colors";
import { actionIcon } from "../../statistics/receipts/misc/ActionString";
import "./TerminalHistory.scss";
import { format } from "date-fns";

type IProps = ICDrawerProps;
type TerminalHistoryProps = {
    terminalName: string;
};
const TerminalHistoryDrawer: FCC<IProps | TerminalHistoryProps | any> = props => {
    const dataProvider = useDataProvider({
        url: "terminals/logs",
        with: ["terminal"],
        filters: {
            terminal_id: ["terminal_id", props.dataId],
        },
        sort: ["timestamp", "-1"],
    });

    const getIcon = (action: string) => {
        const _action = "Terminal" + (action.substring(0, 1).toUpperCase() + action.substring(1, action.length));
        const icon = actionIcon[_action];
        return (
            <CIcon
                path={icon?.[0]?.icon}
                size={icon?.[0]?.size || 1.3}
                style={{
                    ...icon?.[0]?.style,
                    color: icon?.[0]?.style?.color || Colors.CobaltBlue,
                    margin: " -0.61rem",
                    padding: "0.3rem 0",
                    background: "white",
                }}
            />
        );
    };

    return (
        <CDrawer
            // size="lg"
            style={{ width: "38vw" }}
            title={[`${t("terminal")} : `, <div className="text-primary ml-1"> {props.terminalName}</div>]}
            {...props}
        >
            <div style={{ height: "94%" }} className="d-flex flex-column  clopos-scroll terminal-history">
                <div className="flex-fill border-left d-flex flex-column ">
                    <div className="d-flex clopos-scroll " style={{ padding: "20px 12px" }}>
                        <div className="w-100">
                            <Timeline className="custom-timeline">
                                {dataProvider?.data?.map((items: any, i: number) => {
                                    return (
                                        <Timeline.Item
                                            key={items?.id}
                                            time={
                                                <div className="timeline-item">
                                                    <div className="mr-2">
                                                        <CIcon
                                                            path={iDot}
                                                            size={0.8}
                                                            style={{
                                                                color: items.meta.isOnline
                                                                    ? Colors.EmeraldGreen
                                                                    : Colors.BrightRed,
                                                            }}
                                                        />
                                                    </div>
                                                    <div>
                                                        <div style={{ fontSize: "12px" }}>{items?.terminal?.name}</div>
                                                        <div
                                                            style={{
                                                                fontSize: "10px",
                                                                color: items?.meta?.fresh
                                                                    ? Colors.LightSilverGray
                                                                    : Colors.BrightRed,
                                                            }}
                                                        >
                                                            {_.isNil(items?.terminal?.id)
                                                                ? "--"
                                                                : `(Fresh: ${items?.meta?.fresh || "false"})`}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                            dot={<div className="icon">{getIcon(items.action)}</div>}
                                        >
                                            <div className="d-flex">
                                                <div key={i} className="mr-5" style={{ width: "5rem" }}>
                                                    <div style={{ marginBottom: "0" }}>
                                                        <div style={{ fontSize: "12px" }}>
                                                            {format(new Date(items.timestamp), "HH:mm:ss")}
                                                        </div>
                                                        <div
                                                            style={{
                                                                fontSize: "10px",
                                                                color: Colors.LightSilverGray,
                                                            }}
                                                        >
                                                            {format(new Date(items.timestamp), "MMMM dd")}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="d-grid" style={{ width: "30rem" }}>
                                                    <span>
                                                        {t("logged_device", {
                                                            device: `${items?.meta?.ua?.os?.name} ${items?.meta?.ua?.os?.version}`,
                                                        })}
                                                    </span>
                                                    <span style={{ fontSize: "12px" }}>
                                                        <TText tkey="device_id" />: {items.meta.deviceId}
                                                    </span>
                                                </div>
                                            </div>
                                        </Timeline.Item>
                                    );
                                })}
                            </Timeline>
                        </div>
                    </div>
                </div>
            </div>
        </CDrawer>
    );
};

export default TerminalHistoryDrawer;
