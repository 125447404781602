import { selectReceiptItems } from "redux/features/receiptConstructor";
import { useAppSelector } from "hooks/useRedux";
import { CanvasPrinter } from "@cloposcom/escpos-byte";
import { useEffect, useRef, useState } from "react";
import { Loader } from "rsuite";
import { generateReceipt, loadFont } from "../common/utils";
import dotsForReceipt from "../common/ReceiptDots";
import { Colors } from "config/colors";
import { useCurrency } from "hooks/useCurrency";
import { useSetting } from "hooks/useSetting";

const ReceiptPreviewView = () => {
    const items = useAppSelector(selectReceiptItems);
    const canvasRef = useRef<HTMLDivElement>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasFont, setHasFont] = useState(false);
    const logo = useSetting("brand_logo")?.extra?.print_image;
    const brand = useSetting("brand_name")?.value;
    const phone = useSetting("address_phone")?.value;
    const address = useSetting("address_address")?.value;
    // const phone = selectSetting("brand_name")?.value;
    const currency = useCurrency();
    const printer = new CanvasPrinter(
        {
            font: "Receipt-Font",
            fontSize: 25,
            canvasHeight: 500,
            canvasWidth: 552,
            addedHeight: 150,
            bottom_margin: 150,
        },
        document.getElementById("canvas") as HTMLCanvasElement,
    );
    useEffect(() => {
        (async () => {
            setIsLoading(true);

            if (!hasFont) {
                await loadFont();
                setHasFont(true);
            }

            generateReceipt({ items, printer, logo, brand, currency, phone, address }).then(canvas => {
                if (canvasRef.current) {
                    canvasRef.current.innerHTML = "";
                    canvasRef.current.append(canvas);
                }
            });

            setIsLoading(false);
        })();
    }, [items]);

    return (
        <div className="d-flex flex-column " style={{ position: "relative" }}>
            {dotsForReceipt({ pos: "top" })}

            {/*{dotsForReceipt({ pos: "bottom" })}*/}

            <div className="p-2 m-3  " style={{ border: `1px solid ${Colors.SilverGray}`, minHeight: 200 }}>
                <div style={{ zoom: "0.58" }} ref={canvasRef} />
                <div className="" style={{ width: 320 }}>
                    {isLoading ? <Loader className="" /> : null}
                </div>
            </div>
        </div>
    );
};

export default ReceiptPreviewView;
