import { usePackages } from "providers/PackageProvider";
import React, { useEffect, useState } from "react";
import _ from "lodash";

import { IRouteWithComp } from "types/routes";

export interface IModuleExport {
    registerRoutes?: () => IRouteWithComp[];
    getSettingsPageUrl?: () => string;
}

interface IInternalModules {
    [key: string]: IModuleExport;
}

const __INTERNAL_MODULES__: IInternalModules = {};

const listeners = new Set<React.Dispatch<React.SetStateAction<IInternalModules>>>();

export const useModuleLoader = () => {
    const { integrations } = usePackages();

    useEffect(() => {
        (async () => {
            await Promise.all(
                integrations.map(async i => {
                    try {
                        __INTERNAL_MODULES__[i.service_name] = await import(`../modules/${i.service_name}/index.ts`);
                    } catch {
                        /* empty */
                    }
                }),
            );

            listeners.forEach(l => l(_.clone(__INTERNAL_MODULES__)));
        })();
    }, [integrations]);
};

export const useModules = () => {
    const [modules, setModules] = useState<IInternalModules>(__INTERNAL_MODULES__);

    useEffect(() => {
        listeners.add(setModules);

        return () => void listeners.delete(setModules);
    }, []);

    const findByName = (name: string) => modules[name];

    return {
        modules,
        findByName,
    };
};
