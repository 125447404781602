import { useEffect, useState } from "react";

interface IBarcodeHookProps {
    onBarcodeAccept: (barcode: string) => void;
}

export const barcodeTable: Record<number, string> = {
    16: "Shift",
    48: "0",
    49: "1",
    50: "2",
    51: "3",
    52: "4",
    53: "5",
    54: "6",
    55: "7",
    56: "8",
    57: "9",
    58: ":",
    59: ";",
    60: "<",
    61: "=",
    63: "ß",
    64: "",
    65: "a",
    66: "b",
    67: "c",
    68: "d",
    69: "e",
    70: "f",
    71: "g",
    72: "h",
    73: "i",
    74: "j",
    75: "k",
    76: "l",
    77: "m",
    78: "n",
    79: "o",
    80: "p",
    81: "q",
    82: "r",
    83: "s",
    84: "t",
    85: "u",
    86: "v",
    87: "w",
    88: "x",
    89: "y",
    90: "z",
    96: "0",
    97: "1",
    98: "2",
    99: "3",
    100: "4",
    101: "5",
    102: "6",
    103: "7",
    104: "8",
    105: "9",
    106: "*",
    107: "+",
    108: ",",
    109: "-",
    110: ".",
    111: "/",
    187: "=",
    189: "-",
};

export const barcodeCombinations = (code: string) => {
    const split = code.split("-");
    let barcode = "";
    let index: any = null;
    split.forEach((s, k) => {
        if (s === "16") {
            index = k + 1;
            const next = split[index];
            if (next) {
                const tc = barcodeTable?.[Number(next)];
                barcode += tc?.toUpperCase();
            }
        } else if (index !== k) {
            const tc = barcodeTable?.[Number(s)];
            barcode += tc;
        }
    });

    return barcode;
};

export function useBarcodeScanner(props?: IBarcodeHookProps) {
    const [isBarcodeMode, setIsBarcodeMode] = useState(false);
    // eslint-disable-next-line
    let code: string = "";
    // eslint-disable-next-line
    let timeout: any = null;

    useEffect(() => {
        const onKeyPressed = (e: KeyboardEvent): void => {
            clearTimeout(timeout);
            // eslint-disable-next-line
            timeout = setTimeout(() => (code = ""), 1000);
            if (e.keyCode === 13 && code.length > 5) {
                const barcode = barcodeCombinations(code);
                props?.onBarcodeAccept(barcode);
                code = "";
            } else {
                const codeValue = e.keyCode;
                e.preventDefault();
                code += `${code ? "-" : ""}${codeValue?.toString()}`;
            }
        };

        document.addEventListener("keyup", onKeyPressed);

        return () => {
            document.removeEventListener("keyup", onKeyPressed);
        };
    }, [isBarcodeMode, code, props, timeout]);

    return { barcodeMode: isBarcodeMode, setBarcodeMode: setIsBarcodeMode };
}
