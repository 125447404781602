import { t } from "lib/i18n";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import TabsView from "components/TabsView";
import { getProductName } from "lib/utils";
import GoodsStatsReceipt from "./tabs/GoodsStatsReceipt";
import { FC } from "react";

type IProps = ICDrawerProps;

export const ModifierStatsDrawer: FC<IProps> = props => {
    const id = props.data?.id;

    return (
        <CDrawer title={`#${props.data?.id} ${getProductName(props.data)}`} {...props}>
            <TabsView
                tabs={[
                    {
                        label: t("receipt"),
                        Component: GoodsStatsReceipt,
                        props: {
                            id,
                            type: props.data?.type,
                            url: `reports/receipts/by-modifier/${id}`,
                            ...props,
                        },
                    },
                ]}
            />
        </CDrawer>
    );
};
