import _ from "lodash";
import React, { useRef } from "react";
import { IHallManager } from "pages/settings/tables/useHallManager";
import { Button, Divider, IconButton, Popover, Whisper, WhisperInstance } from "rsuite";
import { iCircleOutline, iContentCopy, iEditPencil, iSquareOutline, iTrash } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import { t } from "lib/i18n";
import { twMerge } from "tailwind-merge";

interface IProps {
    table: ITable;
    index: number;
    onEditClick: () => void;
    manager: IHallManager;
}

const TableView: FCC<IProps> = ({ table, manager, ...props }) => {
    const { updateTable, deleteTable, duplicateTable } = manager;

    const whisperRef = useRef<WhisperInstance>(null);

    const handlePopoverClose = () => {
        whisperRef.current?.close();
    };

    const getBorderRoundness = (): number => {
        if (table.shape === "CIRCLE") {
            const min = _.min([table.w, table.h])!;

            return min / 2;
        } else {
            return 0;
        }
    };

    const withPopoverClose = (action: () => void) => {
        return () => {
            handlePopoverClose();
            action();
        };
    };

    const handleShapeChange = (shape: TableShape): void => {
        updateTable({ ...table, shape });
    };

    const handleDeleteItem = withPopoverClose(() => deleteTable(table));
    const handleCopyItem = withPopoverClose(() => duplicateTable(table));
    const handleClickEdit = withPopoverClose(props.onEditClick);

    return (
        <Whisper
            ref={whisperRef}
            trigger={"hover"}
            placement={"top"}
            enterable={true}
            speaker={
                <Popover className={"tw-p-1 tw-flex tw-items-stretch tw-justify-center tw-gap-0.5"}>
                    <Button appearance={"subtle"} size={"sm"} onClick={handleCopyItem}>
                        <CIcon path={iContentCopy} className="tw-mr-1" />
                        {t("duplicate")}
                    </Button>
                    <Divider vertical className={"tw-m-0"} />
                    <Button appearance={"subtle"} size={"sm"} onClick={handleClickEdit}>
                        <CIcon path={iEditPencil} className="tw-mr-1" />
                        {t("edit")}
                    </Button>
                    <Divider vertical className={"tw-m-0"} />
                    <span className="tw-inline-flex tw-gap-1 tw-px-3 tw-align-middle tw-cursor-pointer">
                        {["RECT", "CIRCLE"].map(shape => (
                            <span
                                key={shape}
                                onClick={() => handleShapeChange(shape as TableShape)}
                                className={twMerge(
                                    "tw-h-6 tw-w-6 rounded tw-flex tw-items-center tw-justify-center",
                                    table.shape === shape && "tw-bg-blue-500",
                                )}
                            >
                                <CIcon
                                    path={shape === "RECT" ? iSquareOutline : iCircleOutline}
                                    className={`${table.shape === shape ? "tw-text-white" : ""}`}
                                />
                            </span>
                        ))}
                    </span>

                    <Divider vertical className={"tw-m-0"} />
                    <IconButton
                        className="tw-group"
                        appearance={"subtle"}
                        size={"sm"}
                        onClick={handleDeleteItem}
                        icon={
                            <CIcon
                                path={iTrash}
                                className="tw-text-red-400 group-hover:tw-text-red-600 tw-transition"
                            />
                        }
                    />
                </Popover>
            }
        >
            <div className="table-item">
                <div className="resizeIcon" />

                <div
                    className="table-item-content"
                    style={{ borderRadius: getBorderRoundness() }}
                    onMouseDown={handlePopoverClose}
                >
                    <div>{table.name}</div>
                </div>
            </div>
        </Whisper>
    );
};

export default TableView;
