import { FC, ReactNode } from "react";
import { Tooltip, Whisper } from "rsuite";
import CIcon from "../CIcon";
import { iInfoCircle } from "assets/icons/Icons";
import { cn } from "lib/utils";

interface IProps {
    text: string;
    show: boolean;
    children: ReactNode;
    iconClassName?: string;
    className?: string;
}

export const WithInfoIcon: FC<IProps> = ({ text, show, iconClassName, children, className }) => {
    return (
        <div className={cn("tw-flex tw-flex-row tw-gap-2 tw-items-end", className)}>
            <div className="tw-flex-1">{children}</div>

            {show && (
                <div className={cn("tw-flex tw-items-center tw-justify-center tw-relative", iconClassName)}>
                    <Whisper placement="top" trigger="hover" speaker={<Tooltip>{text}</Tooltip>}>
                        <div className="tw-p-1">
                            <CIcon path={iInfoCircle} className="tw-text-blue-300" size={1} />
                        </div>
                    </Whisper>
                </div>
            )}
        </div>
    );
};
