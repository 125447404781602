import { t } from "lib/i18n";

import CFormGroup from "components/Form/CFormGroup";
import JsonView from "components/JsonView";
import { UseFormReturnType } from "hooks/useCForm";
import { useData } from "hooks/useData";
import _ from "lodash";
import { useEffect, useState } from "react";
import { CheckPicker, Col, Grid, Row } from "rsuite";
import { iSpinner } from "assets/icons/Icons";
import CIcon from "../../../../components/CIcon";
import { useDrawer } from "components/Drawers/useDrawer";
import CForm from "components/Form/CForm/CForm";
import CheckboxFormGroup from "../../../../components/Form/CheckboxFormGroup";
import ColorPicker from "../../../../components/Form/ColorPicker";
import UploaderFormGroup from "../../../../components/Form/FileUploadFormGroup";
import TextFormGroup from "../../../../components/Form/TextFormGroup";
import { useTableHeight } from "hooks/useTableHeight";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import CategorySelect from "../../CategorySelect";
import { useSetting } from "hooks/useSetting";

interface IProps {
    form: UseFormReturnType;
}

const TimerInfoTab: FCC<IProps> = ({ form }) => {
    const modal = useDrawer();
    const [isView] = useState(false);
    const returnsMaxAge = useSetting("returns_max_age")?.value;
    const auth = useAuth();
    const taxes = useData<IStorage[]>("finance/taxes", {}, auth.hasAbility(["tax_view"]));

    const has_modifications = form.form.watch("has_modifications");

    useEffect(() => {
        form.form.setValue("setting_max", returnsMaxAge);
        // eslint-disable-next-line
    }, []);
    const height = useTableHeight(190);

    return !form.isLoading ? (
        <div className="p-3">
            <CForm
                form={form.form}
                isLoading={form.isLoading}
                onSave={form.onSave}
                noPanel
                formProps={{
                    layout: "vertical",
                    style: {
                        height: height,
                    },
                }}
            >
                <div className="h-100 d-flex flex-column justify-content-between align-items-start">
                    <Grid fluid>
                        <Row>
                            <Col xs={24} sm={24} md={24} style={{ height: 200 }}>
                                <Col xs={24} sm={24} md={10} className="right-line">
                                    <UploaderFormGroup
                                        name="images[0].uuid"
                                        removeMainKey={true}
                                        label={t("image")}
                                        // uploadUrl="media"
                                        plaintext={isView}
                                        buttonStyle={{ height: 90, width: 160 }}
                                        fileInfoStyle={{ height: 90, width: 160 }}
                                        compProps={{
                                            action: "media",
                                            multiple: false,
                                            listType: "picture",
                                            name: "media_file",
                                        }}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={14}>
                                    <ColorPicker
                                        name="color"
                                        label={t("color")}
                                        plaintext={isView}
                                        controlWrapperProps={{
                                            style: {
                                                width: "100%",
                                            },
                                        }}
                                        width={"100%"}
                                    />
                                </Col>
                            </Col>
                            <Col xs={24} sm={24} md={12} className="mb-3">
                                <TextFormGroup
                                    name="name"
                                    errorPlacement="rightEnd"
                                    plaintext={isView}
                                    controlWrapperProps={{
                                        style: {
                                            width: "100%",
                                        },
                                    }}
                                    compProps={{
                                        style: {
                                            width: "100%",
                                        },
                                    }}
                                />
                            </Col>

                            <Col xs={24} sm={24} md={12} className="mb-3">
                                <CategorySelect modal={modal} revalidate={modal.dataId} plaintext={isView} />
                            </Col>
                            <Col xs={24} sm={24} md={12} className="mb-3">
                                <CFormGroup name="taxes">
                                    <CheckPicker
                                        data={_.map(taxes?.data, (t: any) => ({
                                            value: t?.id,
                                            label: `${t?.name} (${t?.rate}%)`,
                                        }))}
                                        value={_.map(form.form.getValues("taxes"), (t: any) => t?.id)}
                                        onChange={tax =>
                                            form.form.setValue(
                                                "taxes",
                                                _.intersectionWith(taxes?.data as any, tax, (a: any, b) => a.id === b),
                                            )
                                        }
                                        onClean={() => form.form.setValue("taxes", [])}
                                        placeholder={t("select", { name: t("tax") })}
                                        style={{
                                            width: 300,
                                        }}
                                    />
                                </CFormGroup>
                            </Col>
                            <Col xs={24} sm={24} md={12} className="mb-3">
                                <TextFormGroup
                                    name="price"
                                    label={t("sale_price")}
                                    type="money"
                                    compProps={{
                                        disabled: has_modifications,
                                        defaultValue: form.form.getValues("price") ?? 0,
                                        style: {
                                            width: "100%",
                                        },
                                    }}
                                    controlWrapperProps={{
                                        style: {
                                            width: "100%",
                                        },
                                    }}
                                    plaintext={isView}
                                />
                            </Col>

                            <Col xs={24} sm={24} md={12} className="mb-3">
                                <CheckboxFormGroup
                                    name={"hidden"}
                                    label={t("hide_on_terminal")}
                                    plaintext={isView}
                                    controlWrapperProps={{}}
                                />
                            </Col>
                        </Row>
                    </Grid>
                </div>
                <JsonView collapsed />
            </CForm>
        </div>
    ) : (
        <CIcon path={iSpinner} spin className="m-3" />
    );
};

export default TimerInfoTab;
