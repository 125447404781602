import { Lang, t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { Dropdown } from "rsuite";
import CIcon from "../CIcon";
import { iBook, iConnect2, iVideo } from "assets/icons/Icons";
import { useAppSelector } from "hooks/useRedux";
import "./LearnButton.scss";
import { cn } from "lib/utils";
import { useRemoteConfig } from "hooks/useRemoteConfig";

interface IProps {
    page: Exclude<LearnButtonPage, "training">;
}

const LearnButton: FCC<IProps> = ({ page }) => {
    const language = useAppSelector(state => state.lang).current as Lang;
    const learnData = useRemoteConfig("learnData")?.config as ILearnData;
    const pageData = learnData?.[page]?.[language];
    const videos = learnData?.videos?.[language];
    const training = learnData?.training?.[language];
    const help_center = learnData?.help_center?.[language];

    const learnDataOptions: ILearnDataParams[] = [
        {
            headTitle: t("about_", { string: t(page) }),
        },
        {
            title: t("article"),
            link: pageData?.article,
            icon: iBook,
            hide: !pageData?.article,
        },
        {
            title: t("video"),
            link: pageData?.video,
            icon: iVideo,
            hide: !pageData?.video,
        },
        {
            separator: true,
            hide: !pageData?.video || !pageData?.article,
        },
        {
            headTitle: t("others"),
        },
        {
            title: t("articles"),
            link: help_center?.link,
            icon: iBook,
        },
        {
            title: t("videos"),
            link: videos?.link,
            icon: iVideo,
        },
        {
            separator: true,
        },
    ];

    const openLink = (link?: string) => {
        if (link) {
            window.open(link, "_blank");
        }
    };

    return (
        <>
            {pageData?.video || pageData?.article ? (
                <Dropdown
                    placement="bottomEnd"
                    toggleClassName="learn-button"
                    test-id="learn-button"
                    menuStyle={{ maxHeight: "540px", overflowY: "auto", width: 200, padding: 12 }}
                    title={
                        <div className="learn-button-title">
                            <CIcon path={iBook} className="tw-w-5 tw-h-5 mr-2" />
                            <TText tkey="learn" />
                        </div>
                    }
                >
                    <div className={cn("tw-text-slate-700 tw-font-semibold tw-leading-6")}>
                        <TText tkey="learn_title" />
                    </div>
                    <div className={cn("tw-text-slate-700 tw-text-xs tw-font-normal tw-gap-y-4 tw-mb-4 tw-mt-2")}>
                        <TText tkey="learn_description" />
                    </div>
                    {learnDataOptions?.map((data: ILearnDataParams, index) => {
                        if (data.headTitle) {
                            return (
                                <div key={`title-` + index} className="tw-text-slate-400 tw-text-xs tw-font-semibold">
                                    {t("about_", { string: t(page) })}
                                </div>
                            );
                        }
                        if (data.hide) return null;
                        if (data.separator) return <Dropdown.Separator key={"separator-" + index} />;

                        return (
                            <Dropdown.Item
                                key={`${data.title}-${index}`}
                                onSelect={() => openLink(data.link!)}
                                className="tw-h-[30px] tw-px-2 hover:tw-bg-gray-100 tw-rounded-md tw-flex-col tw-items-start tw-gap-x-2.5 tw-w-full tw-flex"
                            >
                                <div className="tw-justify-start tw-items-center tw-gap-x-2.5 tw-flex">
                                    <CIcon path={data.icon} className="tw-text-slate-700 tw-w-4 tw-h-4 tw-relative" />
                                    <div className="tw-text-slate-700 tw-font-medium tw-leading-5">{data.title}</div>
                                </div>
                            </Dropdown.Item>
                        );
                    })}

                    <Dropdown.Item
                        onSelect={() => openLink(training?.link)}
                        className="tw-h-[30px] tw-px-2 hover:tw-bg-gray-100 tw-rounded-md tw-flex-col tw-items-start tw-gap-x-2.5 tw-w-full tw-flex"
                    >
                        <div className="tw-justify-start tw-items-center tw-gap-x-2.5 tw-flex">
                            <div className="tw-w-4 tw-h-4 tw-relative">
                                <CIcon
                                    path={iConnect2}
                                    className="connect-icon tw-w-4 tw-h-4 tw-left-0 tw-top-0 tw-absolute"
                                />
                            </div>
                            <div className="tw-text-blue-500 tw-font-medium tw-leading-[18px]">
                                <TText tkey="join_the_training" />
                            </div>
                        </div>
                    </Dropdown.Item>
                </Dropdown>
            ) : null}
        </>
    );
};

export default LearnButton;
