import React from "react";
import { Message } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";

const CMessage = React.forwardRef(
    ({ type, children, ...rest }: { type: TypeAttributes.Status; children?: any }, ref) => {
        return (
            <Message test-id={"message-" + type} showIcon closable ref={ref as any} {...rest} type={type} header={type}>
                <pre>{children}</pre>
            </Message>
        );
    },
);

export const MessageError = (text?: string) => React.createElement(CMessage, { type: "error" }, text);
export const MessageInfo = (text?: string) => React.createElement(CMessage, { type: "info" }, text);
export const MessageSuccess = (text?: string) => React.createElement(CMessage, { type: "success" }, text);
