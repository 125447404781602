import { IDataProvider } from "components/DataTable/useDataProvider";
import { getLabel } from "components/Form/form-utils";
import _ from "lodash";
import React, { FC, useContext } from "react";
import { CheckPicker, CheckPickerProps } from "rsuite";
import { FiltersContext } from "./FiltersContainer";
import { ItemDataType } from "rsuite/cjs/@types/common";
import { IDataConfig, useData } from "hooks/useData";
import CheckListSelectedValues from "./CheckListSelectedValue";
import { usePopupContainer } from "hooks/usePopupContainer";

interface ILocalCheckListFilterProps {
    url: string;
    dp?: IDataProvider;
    fields: string;
    labelField?: string;
    searchField?: string;
    pickerProps?: Partial<CheckPickerProps<any>>;
    extraFields?: string[];
    fieldType?: "params";
    hasPermission?: boolean;
    selectedDetails?: {
        url: string;
    };
    width?: string | number;
    maxWidth?: string | number;
    config?: IDataConfig;
}

const LocalCheckListFilter: FC<ILocalCheckListFilterProps> = ({ width = 290, maxWidth = 225, dp, ...props }) => {
    const filtersContext = useContext(FiltersContext);
    dp = dp || filtersContext.dp;
    const fieldType = (dp as any)[props.fieldType || "filters"];
    const hasPermission = props.hasPermission ?? true;
    const { data } = useData(props.url, props.config ?? {});

    let value = fieldType.getFilter(props.fields) ?? [];
    value = _.isArray(value[0]) ? _.last(value[0]) : _.last(value);
    const { container } = usePopupContainer();

    const selectedProducts = useData(value && props.selectedDetails?.url, {
        filters: {
            id: ["id", value],
        } as any,
    });

    const onSelect = (val: any[]) => {
        if (val.length) {
            let filter: any[] = [props.fields, val];
            if (!_.isEmpty(props.extraFields)) {
                filter = [filter, ...(props.extraFields?.map(field => [field, val]) ?? [])];
            }
            if (props.fieldType && props.fieldType === "params") {
                fieldType.add(props.fields, filter);
            } else {
                fieldType.add(filter, props.fields);
            }
        } else {
            fieldType.remove(props.fields);
        }
    };

    const pickerProps: Partial<CheckPickerProps<any>> = {
        placeholder: getLabel(props.fields),
        ...props.pickerProps,
    };

    return hasPermission ? (
        <CheckPicker
            data={data ?? []}
            onSelect={onSelect}
            style={{ width, maxWidth }}
            menuStyle={{ width: 290 }}
            {...pickerProps}
            labelKey={props.labelField}
            valueKey="id"
            container={() => container.current!}
            value={value || []}
            onClean={() => fieldType.remove(props.fields)}
            renderValue={(value: number[], item: ItemDataType | ItemDataType[], selectedElement: React.ReactNode) => {
                return props.selectedDetails?.url ? (
                    <CheckListSelectedValues selectedItems={selectedProducts.data || ([] as any)} keyIndex={"name"} />
                ) : (
                    selectedElement
                );
            }}
        />
    ) : null;
};

LocalCheckListFilter.defaultProps = {
    labelField: "name",
};

export default LocalCheckListFilter;
