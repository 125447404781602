import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import { CSSProperties } from "react";
import CDrawer from "../../../components/Drawers/CDrawer";

import { ICDrawerProps } from "components/Drawers/CDrawer";

type IProps = ICDrawerProps;

const ReceiptNewStationDrawer: FCC<IProps> = props => {
    const cm = useColumns<any>(`receipt-new-station`, [
        { key: "position" },
        {
            key: "name",
            fullText: true,
            flexGrow: 2,
            render: e => {
                const rpms: any[] = e.mainRPMNames.length
                    ? e.mainRPMNames
                          .map((f: string) => {
                              const main = e.rpmGroups.find((rg: any[]) => rg[1].includes(f));
                              if (main) {
                                  return `${main[0]}: ${f}`;
                              }
                              return "";
                          })
                          .filter((ss: string) => ss)
                    : [];
                const style: CSSProperties = rpms.length
                    ? {
                          display: "flex",
                          flexDirection: "column",
                          marginTop: -10,
                      }
                    : {};
                return (
                    <div style={style}>
                        <span>{e.name}</span>
                        {rpms.length ? <span>({rpms})</span> : ""}
                    </div>
                );
            },
        },
        { key: "count" },
        {
            flexGrow: 4,
            fullText: true,
            key: "others",
            title: t("note"),
            render: (e: any) => {
                const note = e?.cookingNote?.length ? `${t("note")}: ${e?.cookingNote}` : "";
                const tag = e?.cookingType?.length ? `${t("tag")}: ${e?.cookingType.join(",")}` : "";
                const text = `${note} ${tag}`;
                return <span>{text}</span>;
            },
        },
    ]);

    return (
        <CDrawer
            title={
                props?.data?.type === "SentToPrinters"
                    ? t("sent_to_printers")
                    : props?.data?.type === "SentToPrintersFail"
                      ? t("sent_to_printers_fail")
                      : "Receipt New Action String Added"
            }
            size="md"
            {...props}
        >
            <div className="">
                <div className="d-flex">
                    <div className="flex-fill border-left">
                        {props.data?.payload?.data?.map((d: any) => {
                            return (
                                <div className={"py-2"}>
                                    <div className="d-flex flex-column pl-2 pb-2">
                                        <span className="pb-1">
                                            {d.station.stationName}
                                            {d.device
                                                ? `${d.device.name ? d.device.name : ""}-${
                                                      d.device.detail?.ip || d.device.detail?.type
                                                  }`
                                                : ``}
                                            {d.device?.isQuick
                                                ? "  -  " + t("quick_print") + " " + t("active")
                                                : "  -  " + t("quick_print") + " " + t("inactive")}
                                        </span>
                                        <span>
                                            {d.station.comment ? ` ${t("comment")} : ${d.station.comment}` : ""}
                                        </span>
                                    </div>
                                    <DataTable
                                        noShadow
                                        columns={cm.columns}
                                        dp={{ data: d.station.list || [] } as any}
                                        hidePages
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </CDrawer>
    );
};

export default ReceiptNewStationDrawer;
