import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useDrawer } from "components/Drawers/useDrawer";
import { SyntheticEvent, useState } from "react";
import RemoteSelectFormState from "../RemoteSelectPickerState";
import { Button, Checkbox, Input, Message, Modal, ModalProps, toaster } from "rsuite";
import JsonViewState from "../JsonViewState";
import Ajax from "lib/Ajax";
import { dummyGoods } from "models/GoodsModel";
import CurrencyInput from "react-currency-input-field";
import _ from "lodash";
import CreateCategoryModal from "pages/menu/categories/CreateCategoryModal";
import { ICDrawerProps } from "components/Drawers/CDrawer";
import CModal from "components/Drawers/CModal";
import { useCurrency } from "hooks/useCurrency";

interface IProps extends ICDrawerProps {
    // closeModal: (state?: boolean) => void;
    onSubmit: (res?: any) => void;
    // onChange: (state: boolean) => void;
}

const CreateGoodsModalState: FCC<IProps & ModalProps> = ({ onSubmit, ...props }) => {
    const currency = useCurrency();

    const [isDataLoading, setDataLoading] = useState(false);
    const [name, setName] = useState("");
    const [barcode, setBarcode] = useState("");
    const [category, setCategory] = useState(null);
    const [station, setStation] = useState(null);
    const [shouldSoldByWeight, setShouldSoldByWeight] = useState<boolean | undefined>(false);
    const [price, setPrice] = useState("");

    const modal = useDrawer();

    const resetForm = () => {
        setName("");
        setBarcode("");
        setCategory(null);
        setStation(null);
        setShouldSoldByWeight(false);
        setPrice("");
    };

    const createAction = async (e: SyntheticEvent) => {
        if (name === "") {
            return toaster.push(
                <Message type="warning" showIcon closable>
                    <TText tkey="empty_items" />
                </Message>,
            );
        }
        setDataLoading(true);
        const res = await Ajax.post({
            url: "product/goods",
            data: {
                ...dummyGoods(),
                name,
                barcode,
                category_id: category,
                station_id: station,
                sold_by_weight: shouldSoldByWeight,
                price: price || 0,
            },
        }).catch(() => {
            toaster.push(
                <Message type="error" showIcon closable>
                    <TText tkey="error" />
                </Message>,
            );
            setDataLoading(false);
        });
        if (res?.success) {
            resetForm();
            props.onClose?.(e);
            onSubmit(res);
        } else {
            toaster.push(
                <Message type="error" showIcon closable>
                    <TText tkey="error" />
                </Message>,
            );
        }
        setDataLoading(false);
    };

    return (
        <>
            <CModal size={"xs"} onExited={resetForm} {...props}>
                <Modal.Header>
                    <Modal.Title>{t("create_by_name", { name: t("goods") })}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div
                        style={{
                            margin: "0 auto",
                            width: 300,
                        }}
                    >
                        <Input
                            style={{ width: 300, marginBottom: 10 }}
                            value={name}
                            onChange={(val: string) => {
                                setName(val);
                                // props.onChange(true);
                            }}
                            placeholder={t("name")}
                        />

                        <Input
                            style={{ width: 300, marginBottom: 10 }}
                            value={barcode}
                            onChange={(val: string) => {
                                setBarcode(val);
                                // props.onChange(true);
                            }}
                            placeholder={t("barcode")}
                        />

                        <RemoteSelectFormState
                            itemLabel={"name"}
                            itemValue={"id"}
                            remote={"category"}
                            searchField={"name"}
                            onSelect={(v: any) => {
                                setCategory(v);
                                // props.onChange(true);
                            }}
                            value={category}
                            remoteQueries={{
                                filters: [["type", "PRODUCT"]],
                                limit: 999,
                                page: 1,
                                tree: 0,
                            }}
                            pickerProps={{
                                cleanable: true,
                                sort: () => (a: any, b: any) => a.item._lft - b.item._lft,
                                placeholder: t("select", { name: t("category") }),
                                renderMenuItem: (label: any, item: any) => {
                                    return (
                                        <span>
                                            {_.times(item.item.depth).map((x, i) => (
                                                <span key={i} className="px-2">
                                                    --
                                                </span>
                                            ))}
                                            {label}
                                        </span>
                                    );
                                },
                                renderExtraFooter: () => (
                                    <div
                                        className="rs-picker-select-menu-item text-center"
                                        onClick={() => {
                                            modal.setDataId(0);
                                            modal.setData({
                                                name: name ? name : "category_id",
                                            });
                                        }}
                                    >
                                        {t("create_new_by_name", { name: t("category") })}
                                    </div>
                                ),
                            }}
                            style={{ marginBottom: 10 }}
                        />

                        <RemoteSelectFormState
                            itemLabel={"name"}
                            itemValue={"id"}
                            remote={"station"}
                            onSelect={(v: any) => {
                                setStation(v);
                                // props.onChange(true);
                            }}
                            value={station}
                            pickerProps={{
                                searchable: false,
                                placeholder: t("select", { name: t("station") }),
                            }}
                            style={{ marginBottom: 10 }}
                        />

                        <Checkbox
                            style={{ alignSelf: "flex-start", marginBottom: 10 }}
                            checked={!!shouldSoldByWeight}
                            onChange={(value, checked, event) => {
                                setShouldSoldByWeight(checked);
                                // props.onChange(true);
                            }}
                        >
                            <TText tkey="sold_by_weight" />
                        </Checkbox>

                        <CurrencyInput
                            placeholder={t("price")}
                            onValueChange={(value: any) => {
                                setPrice(value);
                                // props.onChange(true);
                            }}
                            value={price}
                            decimalsLimit={3}
                            allowNegativeValue={false}
                            style={{ width: 300 }}
                            suffix={" " + currency}
                            disableAbbreviations
                            className={"rs-input"}
                            decimalSeparator="."
                            groupSeparator=","
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onClose} appearance="subtle">
                        <TText tkey="cancel" />
                    </Button>
                    <Button onClick={createAction} appearance="primary" color={"green"} loading={isDataLoading}>
                        <TText tkey="save" />
                    </Button>
                </Modal.Footer>
                <JsonViewState value={{ name, barcode, category, station, soldByWeight: shouldSoldByWeight, price }} />
            </CModal>
            <CreateCategoryModal {...modal} />
        </>
    );
};

export default CreateGoodsModalState;
