import SubmitButtonGroup from "components/Form/SubmitButtonGroup";
import PortalTo from "components/PortalTo";

interface IProps {
    formSubmit?: {
        isLoading: boolean;
        onSave: () => void;
    };
    saveIconBtn?: boolean;
    show?: boolean;
    drawerId?: number | string;
}

const CDrawerHeaderRight: FCC<IProps> = props => {
    return (
        <PortalTo id={`extra-btn-portal ${props?.drawerId}`}>
            {props.formSubmit && props.show && (
                <SubmitButtonGroup
                    isIconBtn={props.saveIconBtn}
                    isLoading={props.formSubmit.isLoading}
                    onSubmit={props.formSubmit.onSave}
                />
            )}
        </PortalTo>
    );
};

export default CDrawerHeaderRight;
