import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import CreateButton from "components/DataTable/CreateButton";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import React, { FC } from "react";
import { TText } from "components/i18n/TText";
import { getFormatedDate } from "lib/utils";
import { EMPTY_DATA } from "config/main";
import { PerformanceType, statuses, WeekDays } from "config/constants";
import { CBadge } from "core/components/CBadge/CBadge";
import { QuickLinks } from "components/Header/QuickLinks";
import CheckListFilter from "components/DataTable/Filters/CheckListFilter";
import SelectFilter from "components/DataTable/Filters/SelectFilter";

export const IncentiveProgramPage: FC = () => {
    const dp = useDataProvider({
        url: "incentive-programs",
        useUrl: true,
    });

    const cm = useColumns<any>("incentive-programs", [
        {
            key: "name",
            align: "left",
        },
        {
            title: t("rate"),
            key: "criteria.sales_bonus_rate",
            align: "left",
        },
        {
            key: "status",
            render: (data: IServiceCharge) => {
                const status = data.status ? "active" : "inactive";
                return <CBadge color={data.status ? "green" : "red"}>{statuses[status].name}</CBadge>;
            },
        },
        {
            key: "description",
            align: "left",
        },
        {
            key: "performance_type",
            render: (d: IIncentiveProgram) => <TText tkey={`${d.performance_type}_sales`} />,
        },
        {
            key: "active_days",
            render: (d: IIncentiveProgram) => (
                <span className="tw-max-w-max">
                    {d.active_days?.includes(WeekDays.ALL)
                        ? t("all_days")
                        : d?.active_days
                              ?.map(day => {
                                  return t(`${day}_full` as LangKey);
                              })
                              .join(", ")}
                </span>
            ),
        },
        {
            key: "start_date",
            render: (d: IIncentiveProgram) =>
                d.start_date ? getFormatedDate(new Date(d.start_date), false) : EMPTY_DATA,
        },
        {
            key: "end_date",
            render: (d: IIncentiveProgram) => (d.end_date ? getFormatedDate(new Date(d.end_date), false) : EMPTY_DATA),
        },
        {
            title: t("start_hour"),
            key: "active_hours.start_time",
            render: (d: IIncentiveProgram) => d.active_hours?.start_time ?? EMPTY_DATA,
        },
        {
            title: t("end_hour"),
            key: "active_hours.end_time",
            render: (d: IIncentiveProgram) => d.active_hours?.end_time ?? EMPTY_DATA,
        },
        optionsColumn({
            dp,
            edit: "/staff/incentive-program/edit/:id",
            delete: "incentive-programs/:id",
            canEdit: "incentive_program_edit",
            canDelete: "incentive_program_delete",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("incentive_programs"))}
            <QuickLinks
                inHeader={false}
                links={[
                    {
                        link: "/staff/incentive-program",
                        label: t("incentive_program"),
                        active: true,
                    },
                    {
                        link: "/staff/incentive-program/bonus-reports",
                        label: t("bonus_report"),
                    },
                    {
                        link: `/staff/incentive-program/finance-transactions`,
                        label: t("finance_transaction"),
                    },
                ]}
            />
            <HeaderRight cm={cm} dp={dp} reload />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <CheckListFilter
                        fields="status"
                        options={[
                            { value: 1, label: t("active") },
                            { value: 0, label: t("inactive") },
                        ]}
                        width={150}
                        maxWidth={150}
                    />
                    <SelectFilter
                        fields="performance_type"
                        options={[
                            { value: PerformanceType.OVERALL, label: t(`${PerformanceType.OVERALL}_sales`) },
                            { value: PerformanceType.SELF, label: t(`${PerformanceType.SELF}_sales`) },
                        ]}
                    />

                    <div className="d-flex flex-fill" />
                    <CreateButton to={"/staff/incentive-program/create"} canCreate={"role_manage_create"} />
                </div>
            </FiltersContainer>
            <DataTable dp={dp} columns={cm.columns} />
        </div>
    );
};
