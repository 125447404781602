declare global {
    interface Window {
        // yandex metrika
        yaCounter60680980: any;
    }
}

export const ymReachGoal = (goal: string) => {
    return "yaCounter60680980" in window && window.yaCounter60680980.reachGoal("product_create_click");
};
