import { UseDrawerReturn } from "components/Drawers/useDrawer";
import { PopupContainerProvider } from "hooks/usePopupContainer";
import { omit } from "lodash/fp";
import { ReactNode, useRef } from "react";
import { Modal, ModalProps } from "rsuite";

export interface ICModalProps extends Omit<UseDrawerReturn, "onClose">, ModalProps {
    title?: string | ReactNode;
    style?: any;
}

const omitExtraProps = omit([
    "hide",
    "isOpen",
    "setDataId",
    "setIsOpen",
    "setData",
    "testId",
    "dataId",
    "hasBackdrop",
    "setModalType",
    "modalType",
    "setIsClosable",
    "isClosable",
    "overflowAuto",
]);

const CModal: FCC<ICModalProps> = ({ title, style = {}, ...props }) => {
    const modalProps: ModalProps = {
        size: "sm",
        backdrop: props?.backdrop ?? false,
        open: props.isOpen,
        ...omitExtraProps(props),
    };
    // const pc = usePopupContainer();
    const ref = useRef<HTMLElement>(null);

    // useEffect(() => {
    //     pc.setIsModal(props.isOpen);
    // }, [props.isOpen]);

    return (
        <Modal {...modalProps} test-id={props.testId} ref={ref}>
            <PopupContainerProvider name="CModal" container={ref}>
                {title && (
                    <Modal.Header>
                        <Modal.Title test-id="modal-title" style={style}>
                            {title}
                        </Modal.Title>
                    </Modal.Header>
                )}
                {props.children}
            </PopupContainerProvider>
        </Modal>
    );
};

export default CModal;
