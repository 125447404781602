import { getLabel } from "components/Form/form-utils";
import _ from "lodash";
import React, { useContext } from "react";
import { CheckPicker, CheckPickerProps } from "rsuite";
import { IDataProvider } from "../useDataProvider";
import { FiltersContext } from "./FiltersContainer";
import { ProductType } from "config/constants";

interface IProps {
    dp?: IDataProvider;
    fields: string;
    labelField?: string;
    searchField?: string;
    pickerProps?: Partial<CheckPickerProps<any>>;
    options: any[];
    noBindModGood?: boolean;
    useParams?: boolean;
    width?: string | number;
    maxWidth?: string | number;
}

const CheckListFilter: FCC<IProps> = ({ dp, ...props }) => {
    const filtersContext = useContext(FiltersContext);
    dp = dp || filtersContext.dp;

    // used to add filter toglobal dp object
    const onSelect = (val: any[]) => {
        if (!props?.noBindModGood) {
            if (val.includes(ProductType.GOODS)) {
                val.push(ProductType.MODIFICATION);
            } else {
                _.remove(val, t => t === ProductType.MODIFICATION);
            }
        }
        if (val.length) {
            if (props?.useParams) {
                dp.params.add(props.fields, val);
            } else {
                dp.filters.add([props.fields, val], props.fields);
            }
        } else {
            if (props?.useParams) {
                dp.params.remove(props.fields);
            } else {
                dp.filters.remove(props.fields);
            }
        }
    };

    const pickerProps: Partial<CheckPickerProps<any>> = {
        placeholder: getLabel(props.fields),
        ...props.pickerProps,
    };
    const searchMaxWidth = props.maxWidth ? props.maxWidth : 90;
    const searchWidth = props.width ? props.width : 90;

    return (
        <CheckPicker
            {...pickerProps}
            data={props.options}
            onSelect={onSelect}
            value={props?.useParams ? dp.params.getValue(props.fields, []) : dp.filters.getValue(props.fields, [])}
            onClean={() => (props?.useParams ? dp.params.remove(props.fields) : dp.filters.remove(props.fields))}
            style={{ maxWidth: searchMaxWidth, width: searchWidth }}
        />
    );
};

CheckListFilter.defaultProps = {
    pickerProps: {
        searchable: false,
    },
};

export default CheckListFilter;
