import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useData } from "hooks/useData";
import _ from "lodash";
import { money } from "lib/money";
import { sumPath } from "lib/utils";
import Money from "components/mini/Money";
import CIcon from "components/CIcon";
import { iClock } from "assets/icons/Icons";
import { useEffect, useState } from "react";
import { Colors } from "config/colors";
import { useCurrency } from "hooks/useCurrency";
import { format } from "date-fns";

interface IProps {
    r: IReceipt;
}

interface IReceiptPaymentMethod {
    id: number;
    amount: number | string;
    name?: string;
}

const dashedLine = () => (
    <span style={{ flexGrow: 1, borderBottom: "2px dashed #EAECF0" }} className="mx-2 my-1"></span>
);
const ProductReturnDrawerSubInfo: FCC<IProps> = ({ r }) => {
    const pmDp = useData<IPaymentMethod[]>("finance/paymentMethod");
    const [wasteTotal, setWasteTotal] = useState(0);
    const [storageTotal, setStorageTotal] = useState(0);
    const currency = useCurrency();

    useEffect(() => {
        let wasteTotal = 0;
        let storageTotal = 0;
        for (let i = 0; i < r?.return_products?.length; i++) {
            const item = r?.return_products?.[i];
            if (item.waste) {
                wasteTotal += item?.cost;
            } else {
                storageTotal += item?.cost;
            }
        }
        setWasteTotal(wasteTotal);
        setStorageTotal(storageTotal);
    }, [r?.id]);

    const mapPaymentMethods = (pMethods: IReceiptPaymentMethod[]): IReceiptPaymentMethod[] => {
        return pMethods?.map((method: IReceiptPaymentMethod) => {
            const findMethod: IPaymentMethod | undefined = _.find(pmDp.data ?? [], { id: method.id });
            return {
                ...method,
                name: findMethod?.name,
            };
        });
    };

    return (
        <div className="border-top border-light px-4" style={{ marginTop: "35px" }}>
            <div className="d-flex justify-content-between" style={{ paddingBottom: "12px", fontWeight: 500 }}>
                <p style={{ fontSize: "16px" }}>
                    <TText tkey="total" />
                </p>
                {dashedLine()}
                <Money value={r?.return_products ? sumPath("total", r?.return_products) : 0} />
            </div>
            <div className="d-flex justify-content-between pb-4">
                <div className="w-50 d-flex justify-content-between pr-2">
                    <span>
                        <TText tkey="returned_value" />
                    </span>
                    {dashedLine()}

                    <span style={{ color: Colors.TropicalGreen }}>
                        {storageTotal}
                        {currency}
                    </span>
                </div>
                <div className="w-50 d-flex justify-content-between pl-2">
                    <span>
                        <TText tkey="waste_cost" />
                    </span>
                    {dashedLine()}
                    <span style={{ color: Colors.BrightRed }}>
                        {wasteTotal}
                        {currency}
                    </span>
                </div>
            </div>
            <div style={{ paddingBottom: "12px" }} className="d-flex justify-content-between">
                <span>
                    <TText tkey="paid_back" />
                </span>
                {dashedLine()}
                <span>{wasteTotal + storageTotal}</span>
            </div>
            <div style={{ paddingBottom: "12px" }} className="d-flex justify-content-between">
                <span>
                    <TText tkey="payment_methods" />{" "}
                </span>
                {dashedLine()}
                <span>
                    {_.concat(
                        [],
                        mapPaymentMethods(r?.payment_methods)?.map(
                            (method: IReceiptPaymentMethod) => `${method.name}: ${money(method.amount, currency)}`,
                        ),
                    ).join(" | ")}
                </span>
            </div>
            <div className="d-flex justify-content-between">
                <span
                    style={{
                        minWidth: "76px",
                        background: Colors.LightAzureBlue,
                        color: Colors.CobaltBlue,
                        fontWeight: 600,
                        borderRadius: 6,
                    }}
                    className="px-2 py-1 "
                >{`${t("receipt")} ID: ${r?.id}`}</span>
                <span
                    style={{
                        minWidth: "76px",
                        background: Colors.LightAzureBlue,
                        fontWeight: 600,
                        borderRadius: 6,
                    }}
                    className={"d-flex align-items-center px-2 py-1"}
                >
                    <CIcon
                        path={iClock}
                        size={0.7}
                        style={{ display: "flex", color: Colors.CobaltBlue, alignItems: "center" }}
                    />
                    <span className={"pl-1"}>{format(new Date(r?.updated_at ?? null), "MMM dd, HH:mm:ss")}</span>
                </span>
            </div>
        </div>
    );
};

export default ProductReturnDrawerSubInfo;
