import { t } from "lib/i18n";
import { array, number, object, string } from "yup";

export const PreparationModel = () =>
    object().shape({
        name: string().required(t("required")),
        contents: array().of(
            object().shape({
                gross: number().required(t("required")),
            }),
        ),
    });

export const dummyPreparation = (): Partial<IPreparation> => ({
    name: "",
    description: "",
    contents: [],
    cost_price: 0,
    recipe: [],
    unit_id: null as any,
    net_output: 1,
    category_id: null as any,
    accounting_category_id: null as any,
    low_stock: 0,
});
