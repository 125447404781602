import React from "react";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { FormItems } from "components/FormItems/FormItems";
import { FIHeaderProps } from "components/FormItems/FormItemsHeader";
import { useAppSelector } from "hooks/useRedux";
import { Panel, PanelGroup } from "rsuite";
import { useFormContext } from "react-hook-form";
import _ from "lodash";
import MappingModifierGroupItem, { IModifierGroupItem } from "./ProductMappingModifierGroupItem";
import MappingModifierItem, { IModifierItem } from "./ProductMappingModifierItem";
import { useParams } from "react-router-dom";
import { renderDualLabel } from "lib/utils";
import { ProductMappingFormParams } from "pages/menu/product-mapping/menu-product-mapping.types";

const getColumns = (service_name: string): FIHeaderProps["columns"] => {
    return [
        ["type", "left", renderDualLabel(service_name)],
        ["name"],
        ["price", "right"],
        ["action"],
        ["id", "left", renderDualLabel("pos")],
        ["name"],
        ["price", "right"],
    ];
};

const renderGroupHeader = (item?: IProductMappingModifierGroup) => {
    return (
        <div className="tw-py-2 tw-flex tw-flex-1 tw-items-center tw-border-0 tw-border-t-0" test-id="panel-header">
            {item?.name}
        </div>
    );
};

const ModifierMappingModifierPage = () => {
    const params = useParams<ProductMappingFormParams>();
    const ctx = useFormContext();

    const modifierGroups = useAppSelector(state => state.model.modifierGroups);
    const modifierSlice = useAppSelector(state => state.model.modifiers);

    const modificator_groups = modifierGroups.ids.map(id => ({
        label: modifierGroups.byId[id].name,
        value: Number(id),
    }));

    const modificators =
        modifierSlice.ids.map(id => _.pick(modifierSlice.byId[id], ["id", "group_id", "name", "price"])) ?? [];

    const modifiers = ctx.getValues("modifiers");

    return (
        <div className="tw-px-4">
            <PanelGroup accordion>
                {modifiers?.map((item: IProductMappingModifierGroup[], modifierIndex: number) => (
                    <Panel
                        className="no-padding-header tw-p-2 mapping-accordion"
                        key={`panel-group-${modifierIndex}`}
                        eventKey={modifierIndex}
                        header={renderGroupHeader(item?.[0])}
                    >
                        <FormItems itemsFieldName={`modifiers[${modifierIndex}]`} key={modifierIndex}>
                            <FormItemsList<IModifierGroupItem | IModifierItem>
                                showHeader
                                virtualize
                                columns={getColumns(params.service_name)}
                                children={({ index, ..._props }) =>
                                    index === 0 ? (
                                        <MappingModifierGroupItem
                                            {..._props}
                                            index={index}
                                            modificator_groups={modificator_groups}
                                        />
                                    ) : (
                                        <MappingModifierItem {..._props} index={index} modificators={modificators} />
                                    )
                                }
                            />
                        </FormItems>
                    </Panel>
                ))}
            </PanelGroup>
        </div>
    );
};

export default ModifierMappingModifierPage;
