import { createSlice } from "@reduxjs/toolkit";
import { fetchRemoteConfig } from "./thunks";
import { RemoteConfigKeys } from "hooks/useRemoteConfig";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

export interface RemoteConfigState {
    configs: {
        learnData: ILearnData;
        importScheme: IImportScheme;
        govCodeUz: IGovCodeUzScheme;
    };
    status: {
        learnData: string;
        importScheme: string;
        govCodeUz: string;
    };
    error: any;
}

const initialState: RemoteConfigState = {
    configs: {
        learnData: {} as any,
        importScheme: {} as any,
        govCodeUz: {} as any,
    },
    status: {
        learnData: "idle",
        importScheme: "idle",
        govCodeUz: "idle",
    },
    error: null,
};

const remoteConfigSlice = createSlice({
    name: ReduxSlicesNames.REMOTE_CONFIG,
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(fetchRemoteConfig.pending, (state, action) => {
                state.status[action.meta.arg as RemoteConfigKeys] = "loading";
            })
            .addCase(fetchRemoteConfig.fulfilled, (state, action) => {
                const { name, data } = action.payload;
                state.configs[name as RemoteConfigKeys] = data;
                state.status[name as RemoteConfigKeys] = "succeeded";
            })
            .addCase(fetchRemoteConfig.rejected, (state, action) => {
                state.status[action.meta.arg as RemoteConfigKeys] = "failed";
                state.error = action.error.message;
            });
    },
});

export default remoteConfigSlice.reducer;
