import { useFormTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import HeaderRight from "components/Header/HeaderRight";
import CForm from "components/Form/CForm/CForm";
import JsonView from "components/JsonView";
import TextFormGroup from "components/Form/TextFormGroup";
import SelectFormGroup from "components/Form/SelectFormGroup";
import React from "react";
import { useCForm } from "hooks/useCForm";
import { ruleset } from "lib/validation-rules";
import { selectSettingOptionsOf } from "redux/features/settings";
import { useAppSelector } from "hooks/useRedux";

const EditHallPage = () => {
    const form = useCForm({
        url: "hall",
        redirectUrl: "/settings/halls",
        refine: (d: IHall) => {
            d.service_charge = d.service_charge?.toString();

            return d;
        },
    });

    const tipRateOptions = useAppSelector(s => selectSettingOptionsOf(s, "tip_rate"));

    return (
        <div className="p-3">
            {useFormTitle(form.id)}
            <HeaderRight formSubmit={form} />
            <CForm noShadow form={form.form} onSave={form.onSave} noPanel>
                <JsonView />
                <TextFormGroup
                    name="name"
                    compProps={{ placeholder: t("enter_hall_name") }}
                    rules={{
                        validate: ruleset.required,
                    }}
                />
                <SelectFormGroup
                    name="service_charge"
                    data={[{ id: null, name: t("default_service_charge") }, ...tipRateOptions]}
                />
            </CForm>
        </div>
    );
};

export default EditHallPage;
