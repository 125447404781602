import _ from "lodash";
import { t } from "lib/i18n";

export function getLabel(name: string, translate: boolean = true) {
    name = name
        ?.replace(/_id$/, "")
        .replace(/\.value$/, "")
        .toLowerCase();
    if (!translate) {
        return _.capitalize(name?.replace(/_/g, " "));
    }
    const indexSwitch = name === "id" ? "data_id" : name?.replace(" ", "_");
    return _.capitalize(t(indexSwitch as LangKey) ?? name?.replace(/_/g, " "));
}
