import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useTitle } from "components/Header/Title";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import { QuickLinks } from "components/Header/QuickLinks";
import Money from "components/mini/Money";
import { useData } from "hooks/useData";
import { h } from "lib/h";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import DataTable from "../../../components/DataTable/DataTable";
import HallTableTreeFilter from "../../../components/DataTable/Filters/HallTableTreeFilter";
import HeaderRight from "../../../components/Header/HeaderRight";
import { exportFile } from "lib/export-table";
import nrInRoutings from "./nr-in-routings";
import RemoteCheckListFilter from "../../../components/DataTable/Filters/RemoteCheckListFilter";
import React from "react";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";

const NewReportsTablesPage: FCC = () => {
    const loc = useLocation();
    const url = "reports/table";
    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;

    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        url,
        with: ["hall", "table"],
    });

    const summary = useData<any>(url, {
        filters: _.values(dp.filters.all),
        params: {
            total: 1,
            date: dp.params.all.date,
        },
    });

    const cm = useColumns<any>("reports/table", [
        {
            title: "ID",
            key: "table.id",
            align: "left",
            flexGrow: 0.3,
        },
        {
            title: t("name"),
            key: "table.name",
            flexGrow: 2,
        },
        {
            title: t("hall"),
            key: "hall.name",
        },
        {
            title: t("total_guests"),
            key: "total_guests",
            summary: () => summary.data?.[0]?.total_guests,
            align: "center",
        },
        {
            title: t("total_receipt"),
            key: "total_receipt",
            summary: () => summary.data?.[0]?.total_receipt,
            align: "center",
        },
        {
            title: t("average_guest_amount"),
            key: "average_guest",
            type: "money",
            summary: () => <Money value={summary.data?.[0]?.average_guest} />,
            numberRoundingLength: 2,
        },
        {
            title: t("average_receipt_amount"),
            key: "average_receipt",
            type: "money",
            summary: () => <Money value={summary.data?.[0]?.average_receipt} />,
            numberRoundingLength: 2,
        },
        {
            title: t("total_cash"),
            key: "total_cash",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.total_cash} />,
        },
        {
            title: t("average_time"),
            key: "average_time",
            summary: () => h.formatTime(summary.data?.[0]?.average_time),
            render: e => h.formatTime(e.average_time),
        },
    ]);

    return (
        <div className="h-100">
            {useTitle(t("tables"), dp.total)}

            <QuickLinks inHeader={false} links={nrInRoutings(loc)} />

            <HeaderRight cm={cm} print export={() => exportFile(dp, cm.columns, t("tables"))} reload date dp={dp} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    {isRestaurant && (
                        <HallTableTreeFilter
                            fieldName={"receipt_table_id"}
                            searchFieldName={"table_name"}
                            placeholder={t("table")}
                        />
                    )}

                    <RemoteCheckListFilter
                        remote={{
                            url: "hall",
                        }}
                        fields="receipt_hall_id"
                        pickerProps={{ placeholder: t("hall"), searchable: false }}
                    />
                </div>
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
        </div>
    );
};

export default NewReportsTablesPage;
