import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";

export const ReturnFromSupplySchema: ValidationSchema = {
    supplier_id: ruleset.required,
    from_storage_id: ruleset.required,
    category_id: ruleset.required,
    balance_id: ruleset.required,
    items: [
        {
            product_id: ruleset.required,
            unit_id: ruleset.required,
            quantity: ruleset.combine([ruleset.required, ruleset.gt(0), ruleset.lte("operation_item_quantity", true)]),
            cost: ruleset.combine([ruleset.required, ruleset.gte(0), ruleset.lte(Number.MAX_SAFE_INTEGER)]),
            total_cost: ruleset.combine([ruleset.gte(0), ruleset.lte(Number.MAX_SAFE_INTEGER)]),
        },
    ],
};
