import { CategoryFilterType } from "./CategoryFilterType";
import { useFinanceCategories } from "hooks/data-hooks/useFinanceCategories";
import { getLabel } from "../../../Form/form-utils";
import CCheckTreePicker from "../../../CheckTreePicker/CCheckTreePicker";
import React, { useContext } from "react";
import _ from "lodash";
import { FiltersContext } from "../FiltersContainer";

const FinanceCategoryTreeFilter: FCC<CategoryFilterType> = props => {
    const categories = useFinanceCategories(true);

    let categoryList = categories?.tree ?? [];

    if (props.refine) {
        categoryList = props.refine(categoryList);
    }

    const fieldName: string = props.fieldName || "category_id";
    const filtersContext = useContext(FiltersContext);
    const dp = filtersContext.dp;

    let value = dp.filters.getFilter(fieldName) ?? [];

    value = _.isArray(value[0]) ? _.last(value[0]) : _.last(value) || [];

    const onSelect = (val: any[], event: React.SyntheticEvent | null) => {
        if (val.length) {
            dp.filters.add([fieldName, val], fieldName);
        } else {
            dp.filters.remove(fieldName);
        }
    };

    const searchMaxWidth = props.maxWidth ? props.maxWidth : 225;
    const searchWidth = props.width ? props.width : 290;

    return (
        <CCheckTreePicker
            cascade="child"
            valueKey="id"
            labelKey="name"
            defaultExpandAll={true}
            data={categoryList}
            value={value}
            onClean={() => dp.filters.remove(fieldName)}
            style={{ width: searchWidth, maxWidth: searchMaxWidth }}
            menuStyle={{ width: 290 }}
            placement={props.placement ? props.placement : undefined}
            placeholder={props.placeholder || getLabel(fieldName)}
            onChange={onSelect}
            hideSelectAll={true}
            {...props?.pickerProps}
        />
    );
};

export default FinanceCategoryTreeFilter;
