import { t } from "lib/i18n";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import React, { useEffect, useRef } from "react";

export interface IChartProps {
    options?: Highcharts.Options;
    series: Partial<Highcharts.Series>[];
    chart?: Highcharts.ChartOptions;
    xOptions?: Highcharts.XAxisOptions;
}

const options: Highcharts.Options = {
    chart: {
        type: "areaspline",
        zoomType: "x",
        marginTop: 30,
        animation: {
            duration: 600,
        },
        height: 300,
    },
    title: {
        text: "",
    },
    legend: {
        enabled: false,
    },
    yAxis: {
        title: {
            text: null,
        },
    },
    xAxis: {
        type: "datetime",
    },
    tooltip: {
        valueDecimals: 2,
    },
    credits: {
        enabled: false,
    },
};

Highcharts.setOptions({
    lang: {
        loading: t("loading"),
        months: [
            t("january"),
            t("february"),
            t("march"),
            t("april"),
            t("may"),
            t("june"),
            t("july"),
            t("august"),
            t("september"),
            t("october"),
            t("november"),
            t("december"),
        ],
        shortMonths: [
            t("january"),
            t("february"),
            t("march"),
            t("april"),
            t("may"),
            t("june"),
            t("july"),
            t("august"),
            t("september"),
            t("october"),
            t("november"),
            t("december"),
        ],
        weekdays: [t("january"), t("february"), t("wed"), t("april"), t("fri"), t("sat"), t("sun")],
        shortWeekdays: [t("monday"), t("tue"), t("wed"), t("thu"), t("fri"), t("sat"), t("sunday")],
        thousandsSep: ".",
        decimalPoint: ",",
    },
});

const CChart: FCC<IChartProps> = props => {
    const ref = useRef<any>(null);

    const getOptions = () => {
        return {
            ...options,
            chart: props.chart ?? options.chart,
            xAxis: props.xOptions ?? options.xAxis,
            ...props.options,
            yAxis: props.options?.yAxis ?? options.yAxis,
            series: props.series,
        };
    };

    useEffect(() => {
        setTimeout(() => {
            ref.current?.chart?.reflow?.();
        }, 100);
    }, []);
    return (
        <div>
            <HighchartsReact
                highcharts={Highcharts}
                options={getOptions()}
                ref={ref}
                callback={() => {
                    (window as any)["HighchartRef"] = ref;
                }}
            />
        </div>
    );
};

export default CChart;
