import { getLabel } from "components/Form/form-utils";
import { Colors } from "config/colors";

interface IProps {
    columns: [string, number, string?][];
    width?: string;
}

/**
 * @deprecated use FormItemsHeader instead
 * @param props
 * @returns
 */
const ItemsHeaderRow: FCC<IProps> = props => {
    return (
        <div
            className="d-flex border-bottom mb-3 pb-1 "
            style={{
                padding: props.columns.length ? "6px" : 0,
                background: Colors.AzureBlue,
                minWidth: props.width ?? "fit-content",
                width: props.width ?? "fit-content",
            }}
        >
            {props.columns.map(([name, width], i) => (
                <div
                    test-id={`column-header-${name}`}
                    key={i}
                    className="mr-3"
                    style={{ color: Colors.CadetGray, width, minWidth: width, overflowWrap: "anywhere" }}
                >
                    {getLabel(name)}
                </div>
            ))}
        </div>
    );
};

export default ItemsHeaderRow;
