import { t } from "lib/i18n";
import React, { useEffect, useState } from "react";
import DoubleDataColumn from "components/mini/DoubleDataColumn";
import DataTable from "components/DataTable/DataTable";
import Money from "components/mini/Money";
import CIcon from "components/CIcon";
import { IconButton } from "rsuite";
import VenueSaleSectionExpand from "./VenueSaleSectionExpand";
import { calcDiffPercentage } from "lib/chart-utils";
import { iArrowDown, iArrowRight, iArrowUp, iChevronDown, iChevronRight } from "assets/icons/Icons";
import { ClockIcon, DiscountIcon, GuestIcon, MoneyIcon, ProfitIcon, ReceiptIcon } from "assets/icons";
import _ from "lodash";

export const tabs = [
    ["total_cash", null, <img src={MoneyIcon} alt="" />],
    ["total_cost", null, <img src={ProfitIcon} alt="" />],
    ["total_discount", "total_discount", <img src={DiscountIcon} alt="" />],
    ["total_receipt", "receipts", <img src={ReceiptIcon} alt="" />],
    ["total_guests", null, <img src={GuestIcon} alt="" />],
    ["average_time", null, <img src={ClockIcon} alt="" />],
] as const;

export const tabs2 = [
    ["total_service_charge", "total_service_charge"],
    ["total_discount"],
    ["total_tax", "tax"],
    ["total_refunded", "returned_total"],
    ["mistakes_receipts_count", "unpaid_closed_receipts"],
    ["total_delivery_fee", "delivery_fee"],
] as const;

interface IProps {
    dp: any;
    dpOld: any;
}

const VenueSaleSection: FCC<IProps> = ({ dp, dpOld }) => {
    const rowKey = "id";

    const [expandedRowKeys, setExpandedRowKeys] = useState<any>([]);
    const [resultData, setResultData] = useState<any>([]);

    const [sortColumn, setSortColumn] = useState<any>("");
    const [sortType, setSortType] = useState<any>("asc");
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        onSortAC();
    }, [sortColumn, sortType]);

    useEffect(() => {
        setResultData(dp.data);
    }, [dp.data]);

    const onSortAC = () => {
        return () => {
            if (sortColumn) {
                const sortedData = dp.data.sort((a: any, b: any) => {
                    let x = _.get(a, sortColumn);
                    let y = _.get(b, sortColumn);
                    if (!x) x = "";
                    if (!y) y = "";

                    if (typeof x === "string" && typeof y === "string") {
                        return sortType === "asc" ? x.localeCompare(y) : y.localeCompare(x);
                    }

                    if (typeof x === "number" && typeof y === "number") {
                        return sortType === "asc" ? x - y : y - x;
                    }

                    if (typeof x === "string") {
                        x = x.charCodeAt(0);
                    }

                    if (typeof y === "string") {
                        y = y.charCodeAt(0);
                    }

                    return sortType === "asc" ? x - y : y - x;
                });
            }
        };
    };

    const handleExpanded = (rowData: any, dataKey: string) => {
        const isOpen = expandedRowKeys.some((key: string) => key === rowData[rowKey]);

        setExpandedRowKeys(isOpen ? [] : [rowData[rowKey]]);
    };

    const columns: any = [
        {
            title: t("venue_name"),
            flexGrow: 5,
            key: "name",
            align: "left",
            render: (d: any) => <DoubleDataColumn type="double-data" value1={d.name} value2={""} />,
        },
        {
            title: t("total_cash"),
            sortable: true,
            key: "total_cash",
            sortField: "total_cash",
            summary: () => getTotalCashSummary(),
            flexGrow: 2,
            align: "right",
            render: (d: any, index: number) => {
                const oldDpData = dpOld?.data?.find((data: any) => data.id === d.id);
                let diff = 0;

                if (oldDpData) {
                    diff = calcDiffPercentage(d?.total_cash, oldDpData?.total_cash);
                }
                return (
                    <div className="dobule-column">
                        <Money value={d.total_cash} />
                        {diff !== 0 && d.total_cash !== oldDpData.total_cash ? (
                            <div className={"percent " + (diff < 0 ? "red" : "green")}>
                                <CIcon path={diff < 0 ? iArrowDown : iArrowUp} />
                                <span className="data">{diff.toFixed(0)}%</span>
                            </div>
                        ) : null}
                    </div>
                );
            },
        },
        {
            title: t("total_profit"),
            sortable: true,
            key: "total_profit",
            sortField: "total_profit",
            summary: () => getTotalProfitSummary(),
            flexGrow: 2,
            align: "right",
            render: (d: any) => {
                const oldDpData = dpOld?.data?.find((data: any) => data.id === d.id);
                let diff = 0;

                if (oldDpData) {
                    diff = calcDiffPercentage(d.total_profit, oldDpData.total_profit);
                }
                return (
                    <div className="dobule-column">
                        <Money value={d.total_profit} />
                        {diff !== 0 && d.total_profit !== oldDpData.total_profit ? (
                            <div className={"percent " + (diff < 0 ? "red" : "green")}>
                                <CIcon path={diff < 0 ? iArrowDown : iArrowUp} />
                                <span className="data">{diff.toFixed(0)}%</span>
                            </div>
                        ) : null}
                    </div>
                );
            },
        },
        {
            title: t("receipts"),
            sortable: true,
            key: "total_receipt",
            sortField: "total_receipt",
            align: "right",
            summary: () => getTotalReceiptSummary(),
            flexGrow: 2,
        },
        {
            title: t("guests"),
            sortable: true,
            key: "total_guests",
            sortField: "total_guests",
            align: "right",
            summary: () => getTotalGuestsSummary(),
            flexGrow: 2,
        },
        {
            title: "",
            sortable: false,
            key: "action",
            flexGrow: 1,
            render: (d: any) => (
                <button className="go-button" onClick={() => goToVenuePanel(d.id)}>
                    <CIcon path={iArrowRight} />
                </button>
            ),
        },
        {
            title: "#",
            sortable: false,
            key: "expand",
            flexGrow: 0.7,
            render: (d: any) => (
                <IconButton
                    size="xs"
                    appearance="subtle"
                    onClick={() => handleExpanded(d, "id")}
                    icon={
                        expandedRowKeys.some((key: string) => key === d["id"]) ? (
                            <CIcon path={iChevronDown} />
                        ) : (
                            <CIcon path={iChevronRight} />
                        )
                    }
                />
            ),
        },
    ];

    const renderRowExpanded = (rowData: any) => {
        const oldDpData = dpOld?.data?.find((data: any) => data.id === rowData.id);
        return (
            <div style={{ height: 196 }}>
                <VenueSaleSectionExpand data={rowData} tabs={tabs} oldData={oldDpData ?? {}} />
            </div>
        );
    };

    const getTotalCashSummary = () => {
        const sum = dp?.data?.reduce((a: any, b: any) => a + b.total_cash, 0);
        return <Money value={sum} />;
    };

    const getTotalProfitSummary = () => {
        const sum = dp?.data?.reduce((a: number, b: any) => a + b.total_profit, 0);
        return <Money value={sum} />;
    };

    const getTotalReceiptSummary = () => {
        return dp?.data?.reduce((a: any, b: any) => a + b.total_receipt, 0);
    };

    const getTotalGuestsSummary = () => {
        return dp?.data?.reduce((a: any, b: any) => a + b.total_guests, 0);
    };

    const goToVenuePanel = (venueId: number) => {
        const url = new URL(window.location.href);
        const pathnameSplit = url.pathname.split("/");
        window.location.href = url.origin + "/" + pathnameSplit[1] + "/" + venueId;
    };

    const handleSortColumn = (_sortColumn: any, _sortType: any) => {
        setIsLoading(true);
        setSortColumn(_sortColumn);
        setSortType(_sortType);
        setIsLoading(false);
    };

    const getData = () => {
        const data: Array<any> = dp.data ?? [];
        if (sortColumn && sortType) {
            return data.sort((a: any, b: any) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                if (typeof x === "string") {
                    x = x.charCodeAt(0);
                }
                if (typeof y === "string") {
                    y = y.charCodeAt(0);
                }
                if (sortType === "asc") {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return data;
    };

    return (
        <div className="general-sale-selection">
            <DataTable
                noShadow={false}
                dp={dp}
                columns={columns}
                rowPadding={"23px 10px 0px"}
                tableProps={{
                    data: getData(),
                    headerHeight: 50,
                    rowHeight: 64,
                    rowExpandedHeight: 189,
                    affixHeader: true,
                    expandedRowKeys: expandedRowKeys,
                    rowKey: rowKey,
                    loading: isLoading,
                    renderRowExpanded: renderRowExpanded,
                    onSortColumn: handleSortColumn,
                    sortColumn: sortColumn,
                    sortType: sortType,
                }}
            />
        </div>
    );
};

export default VenueSaleSection;
