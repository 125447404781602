import { t } from "lib/i18n";
import classNames from "classnames";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import CheckListFilter from "components/DataTable/Filters/CheckListFilter";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import ReceiptDrawer from "components/Drawers/ReceiptDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import DeletedBy from "components/mini/DeletedBy";
import Money from "components/mini/Money";
import { ExportType, ProductType, Trash } from "config/constants";
import { useData } from "hooks/useData";
import _ from "lodash";
import React, { FC, useState } from "react";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import ServiceCharge from "../../../components/mini/ServiceCharge";
import PaymentMethodsCell from "./PaymentMethodsCell";
import WhisperWithoutIcon from "components/mini/WhisperWithoutIcon";
import PaymentChange from "./PaymentChangeDrawer";
import { productNameAppend } from "lib/data-utils";
import GiftCell from "./GIftCell";
import IdWithMisc from "../../../components/mini/IdWithMisc";
import Ajax from "lib/Ajax";
import { QuickLinks } from "components/Header/QuickLinks";
import { Tooltip, Whisper } from "rsuite";
import { ASSETS_BASE_URL, EMPTY_DATA } from "config/main";
import DoubleDataColumn from "../../../components/mini/DoubleDataColumn";
import ReceiptFilters from "./components/ReceiptFilters";
import { iDownload } from "assets/icons/Icons";
import { RowDataType } from "rsuite-table/src/@types/common";
import ExportModal from "../../../components/Header/Export/ExportModal";
import CDrawerProvider from "../../../providers/CDrawerProvider";
import ToggleFilterDrawer from "../../../components/DataTable/Filters/ToggleFilterDrawer";
import { money } from "lib/money";
import CreateButton from "../../../components/DataTable/CreateButton";
import { useIntegrationByName } from "providers/PackageProvider";
import LearnButton from "../../../components/DataTable/LearnButton";
import { receiptStatusNames } from "pages/statistics/receipts/DeletedReceiptsStatPage";
import { BrandType } from "types/routes";
import { useCurrency } from "hooks/useCurrency";
import { useSetting } from "hooks/useSetting";

export const ReceiptBetaPage: FC = () => {
    const [hasDeletedBy, setHasDeletedBy] = useState<boolean>(true);
    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;
    const currency = useCurrency();
    const [itemDetails, setItemDetails] = useState<any>(null);
    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        url: "receipt",
        extraFilterKeys: [
            "description",
            "trash",
            "fiscal_id",
            "user_id",
            "paymentMethods",
            "customer_id",
            "suspicious",
            "reason_id",
            "table_id",
            "courier_id",
            "terminal_id",
            "open_by_user_id",
            "close_by_user_id",
            "cid",
        ],
        with: [
            "user",
            "hall",
            "table",
            "customer",
            "customer.group",
            "user_delete",
            "sale_type",
            "receipt_products",
            "review",
            "terminal",
        ],
        sort: ["created_at", "-1"],
        refineList: (ls, s) => {
            const value = s.filters?.paymentMethods ? s.filters?.paymentMethods?.[1] : null;
            if (value) {
                return ls?.filter((qs: any) => {
                    const pm = qs.payment_methods.filter((s: any) => s.amount && value.includes(s.id));
                    return pm.length;
                });
            }
            return ls;
        },
    });

    const webCashierIntegration = useIntegrationByName("web_cashier");

    const receiptDrawer = useDrawer({ backdrop: false, overflowAuto: true });
    const sd = useDrawer();
    const filter = useDrawer();

    const summary = useData<any>("report/cash/time", {
        params: { date: dp.params.all?.date },
        filters: _.values(dp.filters.all),
    });

    const removedCols = ["hall.name", "guests"];

    const columns = (
        [
            {
                title: "#" + t("receipt"),
                key: "id",
                width: 120,
                align: "left",
                flexGrow: 1,
                onClick: (d: any) => {
                    receiptDrawer.setDataId(d.id);
                    filter.setIsOpen(false);
                },

                render: (e: IReceipt) => <IdWithMisc {...e} onClick={() => receiptDrawer.setDataId(e.id)} />,
            },
            {
                title: t("staff"),
                minWidth: 120,
                key: "user.username",
                align: "left",
                render: (d: any) => (
                    <DoubleDataColumn type="double-data" value1={d.user?.username} value2={d.meta.user?.role} />
                ),
                //     (
                //     <div style={{ marginTop: "-0.6rem" }}>
                //         <div> {d.user.username} </div>
                //         <div className="text-empty text-12"> {d.meta.user.role}</div>
                //     </div>
                // ),
            },

            {
                key: "created_at",
                type: "datetime",
                flexGrow: 0.8,
                minWidth: 120,
                multiline: true,
            },
            {
                title: t("closed_at"),
                key: "closed_at",
                type: "datetime",
                minWidth: 120,
                multiline: true,
            },
            {
                title: t("terminal"),
                key: "terminal.name",
                align: "left",
                minWidth: 130,
                render: (e: any) => (
                    <DoubleDataColumn
                        type="double-data"
                        value1={e.terminal?.name}
                        value2={e.terminal?.id}
                        key2={"ID:"}
                    />
                ),
                //     (
                //     <div style={{ marginTop: "-0.6rem" }}>
                //         <div>{`${e.terminal?.name || ""}`} </div>
                //         <div className="text-empty text-12"> {`ID: ${e.terminal?.id || ""}`}</div>
                //     </div>
                // ),
            },
            {
                key: "deleted_at",
                type: "datetime",
                minWidth: 105,
                hide: hasDeletedBy,
                render: (d: any) => <DeletedBy {...d} />,
            },
            {
                key: "user_delete.username",
                title: t("deleted_by"),
                hide: hasDeletedBy,
                minWidth: 120,
                render: (d: any) => (
                    <div style={{ marginTop: d.user_delete?.username && d.meta?.user?.role ? "-0.6rem" : 0 }}>
                        <div>{d.user_delete?.username}</div>
                        {d.user_delete?.username ? (
                            <div className="text-empty text-12"> {d.meta?.user?.role}</div>
                        ) : (
                            EMPTY_DATA
                        )}
                    </div>
                ),
            },
            {
                title: `${t("sale_type")}`,
                key: "sale_type.name",
                align: "left",
                minWidth: 110,
                flexGrow: 1.1,
            },
            {
                title: `${t("table")}\n${t("hall")}`,
                key: "hall.name",
                minWidth: 100,
                align: "left",
                render: (d: any) =>
                    d.table?.name ? (
                        <DoubleDataColumn type="double-data" value1={d.table?.name} value2={d.hall?.name} />
                    ) : (
                        EMPTY_DATA
                    ),
                flexGrow: 1.1,
            },
            {
                title: t("client"),
                key: "customer.name",
                align: "left",
                minWidth: 140,
                hide: true,
                render: (d: any) => (
                    <DoubleDataColumn type="double-data" value1={d.customer?.name} value2={d.customer?.group?.name} />
                ),
                //     (
                //     <div style={{ marginTop: d.customer?.name && d.customer?.group?.name ? "-0.6rem" : 0 }}>
                //         <div>{d.customer?.name}</div>
                //         <div className="text-empty text-12">{d.customer?.group?.name}</div>
                //     </div>
                // ),
            },
            {
                title: t("total_guests"),
                key: "guests",
                align: "center",
                summary: () => summary.data?.total_guests,
                getStyle: () => ({ fontSize: "13px" }),
                minWidth: 100,
                hide: true,
                flexGrow: 0.75,
            },
            {
                title: t("subtotal"),
                key: "subtotal",
                align: "right",
                minWidth: 120,

                summary: () => {
                    const subtotal =
                        summary.data?.total_Original_subtotal &&
                        summary.data?.total_Original_subtotal !== summary.data?.total_subtotal
                            ? summary.data?.total_Original_subtotal
                            : summary.data?.total_subtotal;
                    return <Money symbol={false} value={subtotal} />;
                },
                flexGrow: 1.2,
                currency,
                render: (e: any) => {
                    // const subtotal = e.original_subtotal && e.original_subtotal !== e.subtotal ? e.original_subtotal : e.subtotal;
                    return <Money symbol={false} value={e.subtotal} style={{ fontSize: "13px" }} />;
                },
            },
            {
                title: t("product_gift_total"),
                key: "gift_total",
                render: (d: IReceipt) => (
                    <div style={{ fontSize: "13px" }}>
                        {" "}
                        <GiftCell r={d} />{" "}
                    </div>
                ),
                align: "right",
                currency,
                type: "money",
                flexGrow: 0.45,
                minWidth: 100,
                summary: () => <Money symbol={false} value={summary.data?.total_gift} />,
            },
            {
                key: "service_charge_value",
                title: t("service_charge"),
                align: "right",
                type: "money",
                minWidth: 130,
                currency,

                render: (d: any) => (
                    <div style={{ fontSize: "13px" }}>
                        <ServiceCharge symbol={false} rate={d.service_charge} amount={d.service_charge_value} />
                    </div>
                ),
                summary: () => <Money symbol={false} value={summary.data?.total_service_charge} />,
                flexGrow: 0.85,
                // minWidth: 120,
            },
            // {
            //     key: "rps_discount",
            //     title: `${t("product_discount")}`,
            //     align: "right",
            //     type: "money"R,
            //     summary: () => <Money symbol={false} value={summary.data?.rps_discount} />,
            //
            //     flexGrow: 0.45,
            // },
            {
                key: "discount",
                title: t("discount"),
                align: "right",
                type: "money",
                currency,
                summary: () => <Money value={summary.data?.total_discount} />,
                render: (d: any) => (
                    <Money symbol={false} value={d.discount_value + d.rps_discount} style={{ fontSize: "13px" }} />
                ),
                flexGrow: 0.95,
                minWidth: 150,
            },
            {
                key: "delivery_fee",
                align: "right",
                type: "money",
                currency,
                summary: () => <Money symbol={false} value={summary.data?.total_delivery_fee} />,
                render: (d: any) => <Money symbol={false} value={d.delivery_fee} style={{ fontSize: "13px" }} />,
                flexGrow: 1.35,
                minWidth: 130,
                getStyle: () => ({ fontSize: "13px" }),
            },
            {
                key: "totalCost",
                title: t("cost_price"),
                align: "right",
                type: "money",
                summary: () => <Money symbol={false} value={summary.data?.total_cost} />,
                currency,
                hide: true,
                flexGrow: 1.4,
                minWidth: 110,
                getStyle: () => ({ fontSize: "13px" }),
            },
            {
                key: "description",
                hide: true,
                flexGrow: 1.2,
                minWidth: 90,
                render: (e: any) => <WhisperWithoutIcon description={e.description} />,
            },
            {
                title: t("tax"),
                key: "total_tax",
                align: "right",
                type: "money",
                render: (e: any) =>
                    e.total_tax ? (
                        <Money symbol={false} value={e.total_tax} style={{ fontSize: "13px" }} />
                    ) : (
                        EMPTY_DATA
                    ),
                summary: () => <Money symbol={false} value={summary.data?.total_tax} />,
                currency,
                flexGrow: 0.95,
                minWidth: 80,
            },
            {
                title: t("e_kassa"),
                key: "fiscal_id",
                align: "right",
                type: "money",
                render: (e: any) =>
                    e.fiscal_id ? <img src={`${ASSETS_BASE_URL}/img/checkboxcase.svg`} alt="" /> : EMPTY_DATA,
                summary: () => <Money symbol={false} value={summary.data?.total_fiscal} />,
                currency,
                flexGrow: 1.15,
                minWidth: 100,
                getStyle: () => ({ fontSize: "13px" }),
            },
            {
                title: t("sum_total"),
                key: "total",
                align: "right",
                getStyle: () => ({ fontSize: "13px" }),
                summary: () => <Money symbol={false} value={summary.data?.total_cash} />,
                render: (e: any) => <Money symbol={false} value={e.total} style={{ fontSize: "13px" }} />,
                currency,
                flexGrow: 0.55,
                minWidth: 100,
            },
            {
                title: t("daily_id"),
                key: "properties.daily_id",
                hide: true,
                onClick: (d: any) => receiptDrawer.setDataId(d.id),
            },
            {
                title: t("monthly_id"),
                key: "properties.monthly_id",
                hide: true,
                onClick: (d: any) => receiptDrawer.setDataId(d.id),
            },
            {
                title: `${t("loyalty")}`,
                key: "loyalty_value",
                align: "right",
                render: (d: any) => (
                    <DoubleDataColumn type="double-data" value1={money(d.loyalty_value, "")} value2={d.loyalty_type} />
                ),
            },
            {
                title: t("payment_method"),
                key: "payment_methods",
                render: (d: any) => <PaymentMethodsCell r={d} />,
                align: "center",
                flexGrow: 0.85,
                minWidth: 120,
            },
            // <img src="/img/open-circle.svg" alt="" />
            {
                key: "status",
                render: (d: IReceipt) => (
                    <Whisper
                        preventOverflow
                        trigger="hover"
                        speaker={<Tooltip style={{ width: 60 }}>{t(receiptStatusNames[d.status])}</Tooltip>}
                        placement="leftStart"
                    >
                        <div style={{ width: "0.7rem", height: "2rem" }}>
                            <img
                                alt=""
                                style={{ marginLeft: "0.7rem" }}
                                src={`${ASSETS_BASE_URL}/img/${receiptStatusNames[d.status]}.svg`}
                            />
                        </div>
                    </Whisper>
                ),
                flexGrow: 0.45,
            },
            {
                title: t("courier"),
                key: "meta.courier_name",
                minWidth: 200,
            },
            optionsColumn({
                dp,
                modalType: "receiptDelete",
                delete: "receipt/:id",
                preventDelete: d => !d.closed_at,
                editable: d => {
                    if (d.status === 1) return true;
                    const hasPayments = d.payment_methods?.filter((pm: any) => pm.amount);
                    return _.isEmpty(hasPayments);
                },
                edit: d => sd.setDataId(d.id),
                canEdit: "receipt_edit",
                canDelete: "receipt_delete",
                others: () => [
                    {
                        label: t("export_btn"),
                        icon: iDownload,
                        onClick: d => {
                            exportDrw.setIsOpen(true);
                            setItemDetails(d);
                        },
                    },
                ],
            }),
        ] as ITableColumnProps<IReceipt>[]
    ).filter(c => (isRestaurant ? true : !removedCols.includes(c.key as string)));

    //Ajax.download({ url: `receipt/${d.id}/invoice?format=pdf` }, `${t("receipt")} #${d.id}`),
    const cm = useColumns<IReceipt>("receipt", columns);
    const exportDrw = useDrawer();

    return (
        <div className="h-100">
            {useTitle(t("receipts"), dp.total)}

            <QuickLinks
                links={[
                    {
                        link: "/sales/receipts",
                        label: t("receipt"),
                        active: true,
                    },
                    {
                        link: "/sales/open-receipts",
                        label: t("open_receipts"),
                    },
                    {
                        link: "/sales/deleted-receipts",
                        label: t("deleted_receipts"),
                    },
                    {
                        link: "/sales/merged-receipts",
                        label: t("merged_receipts"),
                    },
                ]}
            />
            <HeaderRight
                cm={cm}
                print
                date
                exportType={ExportType.RECEIPT}
                haveExportList={true}
                exportWithOptions={[{ name: "Excel", columns: cm.columns, dp }]}
                reload
                dp={dp}
            />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter className="search-input" placeholder={t("data_id")} fields="id" />
                    <RemoteCheckListFilter
                        fields="product_id"
                        searchField="name"
                        remote={{
                            url: "product",
                            sort: ["updated_at", "-1"],
                            limit: 20,
                            params: {
                                trash: Trash.TrashAndActive,
                            },
                            filters: {
                                type: [
                                    ["type", ProductType.GOODS],
                                    ["type", ProductType.TIMER],
                                    ["type", ProductType.DISH],
                                    ["type", ProductType.MODIFICATION],
                                ],
                            },
                            refineList: (products?: IProduct[]) =>
                                products
                                    ?.map(i => productNameAppend(i))
                                    ?.filter(f => !(f.type === ProductType.GOODS && f.has_modifications)) ?? [],
                        }}
                        pickerProps={{ placeholder: t("product") }}
                        width={150}
                        maxWidth={180}
                    />
                    <CheckListFilter fields="status" options={receiptStatusOptions()} width={150} maxWidth={150} />
                    <RemoteCheckListFilter
                        fields="sale_type_id"
                        searchField="name"
                        remote="finance/saleType"
                        pickerProps={{ placeholder: t("sale_type") }}
                        width={150}
                        maxWidth={150}
                    />
                    <ToggleFilterDrawer
                        isOpen={filter.isOpen}
                        onChange={val => {
                            filter.setIsOpen(val);
                            receiptDrawer.setIsOpen(false);
                        }}
                    />
                </div>

                <LearnButton page="receipts" />

                {webCashierIntegration?.status ? (
                    <CreateButton to={"/sales/receipts/create"} canCreate={"web_cashier"} />
                ) : null}
            </FiltersContainer>

            <DataTable
                noShadow={false}
                dp={dp}
                columns={cm.columns}
                rowPadding={"23px 10px 0px"}
                tableProps={{
                    headerHeight: 50,
                    rowHeight: 64,
                    affixHeader: true,
                    rowClassName: (d?: RowDataType) =>
                        classNames({
                            "deleted-row": d && !!d?.deleted_at,
                            "open-receipt": d && !d?.closed_at,
                        }),
                }}
            />
            <ReceiptDrawer {...receiptDrawer} />
            <CDrawerProvider>
                <PaymentChange {...sd} dp={dp} />
            </CDrawerProvider>
            <ReceiptFilters {...filter} dp={dp} setDeletedBy={setHasDeletedBy} />
            {itemDetails?.id && exportDrw.isOpen && (
                <ExportModal
                    exportOptions={[
                        {
                            name: `${t("receipt")} #${itemDetails?.id}`,
                            onClick: () =>
                                Ajax.download(
                                    { url: `receipt/${itemDetails?.id}/invoice` },
                                    `${t("receipt")} #${itemDetails?.id}`,
                                ),
                        },
                    ]}
                    {...exportDrw}
                />
            )}
        </div>
    );
};

const receiptStatusOptions = () =>
    _.map(receiptStatusNames, (label, value) => ({
        value,
        label: t(label),
    }));
