import { t } from "lib/i18n";
import { useFormTitle } from "components/Header/Title";
import { useCForm } from "hooks/useCForm";
import { CategoryModel, dummyCategory } from "models/CategoryModel";

import CForm from "components/Form/CForm/CForm";
import TextFormGroup from "../../../components/Form/TextFormGroup";
import HeaderRight from "../../../components/Header/HeaderRight";
import JsonView from "../../../components/JsonView";
import _ from "lodash";
import { isUrl } from "lib/utils";
import { CategoryTypeEnum } from "config/constants";

const AccountCategoryEditPage: FCC = () => {
    const form = useCForm({
        url: "category",
        model: CategoryModel(),
        // redirectUrl: "/menu/categories",
        dummyData: dummyCategory,
        prepareForSubmit: (d: any) => {
            d.type = CategoryTypeEnum.ACCOUNTING;
            d = _.pickBy(d, _.identity);
            const images = d.images?.length ? d.images[0].uuid : "";
            return _.omit(d, isUrl(images) ? ["image", "images"] : ["image"]);
        },
        refine: (d: any) => {
            d = _.pickBy(d, _.identity);
            return !_.isEmpty(d.media)
                ? {
                      images: [{ uuid: d.media[0]?.urls?.thumb }],
                      ...d,
                  }
                : d;
        },
    });

    return (
        <div className="tw-p-4 tw-border-0 tw-border-t tw-border-solid tw-border-slate-100">
            {useFormTitle(form.id, t("categories"))}
            <HeaderRight formSubmit={form} />
            <CForm form={form.form} onSave={form.onSave}>
                <TextFormGroup name="name" errorPlacement="rightEnd" focus={true} />
                <TextFormGroup name="code" errorPlacement="rightEnd" focus={true} label={t("accounting_code")} />
                <JsonView />
            </CForm>
        </div>
    );
};

export default AccountCategoryEditPage;
