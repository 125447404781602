import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import _ from "lodash";

interface IDateHookProps {
    start?: string | null;
    end?: string | null;
}

export function useDate() {
    const [date, setDate] = useState<IDateHookProps>({});
    const location = useLocation();

    useEffect(() => {
        const urlParams = location.search?.replace("?", "");
        const queryString = JSON.parse(decodeURI(urlParams));

        setDate({
            start: _.head(queryString?.params?.date ?? []),
            end: _.last(queryString?.params?.date ?? []),
        });
        // eslint-disable-next-line
    }, [location.pathname, location.search]);

    return {
        start: date.start,
        end: date.end,
    };
}
