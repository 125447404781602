import CForm from "components/Form/CForm/CForm";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import JsonView from "components/JsonView";
import TabsView from "components/TabsView";
import { useCForm } from "hooks/useCForm";
import _ from "lodash";
import { usePackages } from "providers/PackageProvider";
import CProductsDrawer from "components/Drawers/CProductSelector/CProductsDrawer";
import { isUrl } from "lib/utils";
import { ModifierEditTab2 } from "pages/menu/manage-modifiers/create-edit/ModifierEditTab2";
import { ModifierEditTab1 } from "pages/menu/manage-modifiers/create-edit/ModifierEditTab1";
import { ModifierGroupSchema } from "form-schemas/ModifierGroupSchema";
import { t } from "lib/i18n";
import { Limitation } from "types/routes";
import { isInDuplicatePage } from "lib/isInDuplicatePage";

const ManageModifiersCreate: FCC = () => {
    const isDuplicate = isInDuplicatePage();
    const form = useCForm<IOperation>({
        url: "product/modificator-group",
        isDuplicate,
        mode: "all",
        model2: ModifierGroupSchema,
        with: ["modificators.ingredient.unit"],
        prepareForSubmit: d => {
            d.modificators = d.modificators.map((m: IModifier, index: number) => {
                const omitList = isDuplicate ? ["id", "group_id"] : [];
                if (isUrl(m.images?.[0]?.uuid ?? "")) {
                    omitList.push("images");
                }
                m.position = index;
                return _.omit(m, omitList);
            });
            return d;
        },
        refine: (d: IModifierGroup) => {
            //@TODO: add function
            for (const [lang, value] of Object.entries(d.meta?.langs || {})) {
                if (Array.isArray(value)) {
                    d.meta.langs[lang] = {};
                }
            }
            d.modificators = _.orderBy(
                d.modificators?.map((modificator: IModifier) => {
                    for (const [lang, value] of Object.entries(modificator.meta?.langs || {})) {
                        if (Array.isArray(value)) {
                            modificator.meta.langs[lang] = {};
                        }
                    }
                    const image = !_.isEmpty(modificator.media)
                        ? [{ uuid: modificator.media[0]?.urls?.thumb }]
                        : modificator.ingredient?.image
                          ? [{ uuid: modificator.ingredient?.image?.thumb }]
                          : "";
                    return image
                        ? {
                              ...modificator,
                              images: image,
                          }
                        : modificator;
                }),
                "position",
            );
            return d;
        },
    });
    const packages = usePackages();

    return (
        <div>
            {useFormTitle(form.id, t("manage_modifiers"), t("edit") + ` (${t("data_id")}: ${form.id})`)}
            <HeaderRight formSubmit={form} />

            <CForm {...form} onSave={form.onSave}>
                <JsonView />
                <TabsView
                    tabs={[
                        {
                            label: t("general"),
                            Component: ModifierEditTab1,
                            props: {
                                form,
                            },
                        },
                        {
                            label: t("emenu"),
                            Component: ModifierEditTab2,
                            props: {
                                form,
                            },
                            hide: !packages?.limitation?.[Limitation.EMENU],
                        },
                    ]}
                />
            </CForm>
            <CProductsDrawer />
        </div>
    );
};

export default ManageModifiersCreate;
