import { t } from "lib/i18n";
import CForm from "components/Form/CForm/CForm";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import TabsView from "components/TabsView";
import TerminalSettingsTab from "pages/settings/terminal/TerminalSettingsTab";
import TerminalSettingsTab2 from "pages/settings/terminal/TerminalSettingsTab2";

import { useSettingsForm } from "pages/settings/useSettingsForm";
import TerminalSettingsTab3 from "./TerminalSettingsTab3";

const terminalPageFields = [
    //TerminalSettingsTab
    "terminal_language",
    "product_price_change",
    "apply_discount",
    "apply_tip_rate",
    "tip_rate",
    "terminal_meta",
    "rounding",
    "terminal_meta",
    "auto_receipt_print",
    "ap_show_clients_in_terminal",
    "terminal_locale",
    "scale_unit",
    //TerminalSettingsTab2
    "admin_password",
    "ap_remove_product",
];

const TerminalSettingsPage: FCC = () => {
    const form = useSettingsForm({
        selects: terminalPageFields,
    });

    return (
        <div className="h-100 p-2">
            {useTitle(t("terminals"))}
            <HeaderRight formSubmit={form} />
            <CForm {...form}>
                <TabsView
                    tabs={[
                        { label: t("terminal"), Component: TerminalSettingsTab, props: { form: form } },
                        { label: t("terminal_locale"), Component: TerminalSettingsTab2, props: { form: form } },
                        { label: t("admin_password_require"), Component: TerminalSettingsTab3 },
                    ]}
                />
            </CForm>
        </div>
    );
};

export default TerminalSettingsPage;
