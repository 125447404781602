import { IDataProvider, IDataProviderConfig, useDataProvider } from "components/DataTable/useDataProvider";
import { getLabel } from "components/Form/form-utils";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { CheckPicker, CheckPickerProps } from "rsuite";
import { FiltersContext } from "./FiltersContainer";
import { ItemDataType } from "rsuite/cjs/@types/common";
import { useData } from "hooks/useData";
import CheckListSelectedValues from "./CheckListSelectedValue";
import { useDebouncedSearch } from "hooks/useDebouncedSearch";
import { usePopupContainer } from "hooks/usePopupContainer";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";

interface IProps {
    remote: string | IDataProviderConfig;
    dp?: IDataProvider;
    fields: string;
    labelField?: string;
    searchField?: string;
    pickerProps?: Partial<CheckPickerProps<any>>;
    extraFields?: string[];
    fieldType?: "params";
    searchLocal?: boolean;
    hasPermission?: boolean;
    selectedDetails?: {
        url: string;
    };
    width?: string | number;
    maxWidth?: string | number;
    refineData?: (d: any) => any;
}

const RemoteCheckListFilter: FCC<IProps> = _props => {
    const props = _.omit(_props, "dp");
    const filtersContext = useContext(FiltersContext);
    const dp = _props.dp || filtersContext.dp;
    const fieldType = (dp as any)[props.fieldType || "filters"];
    const hasPermission = _props.hasPermission ?? true;
    const { container } = usePopupContainer();
    const requiredMinimumFilters = dp.filters.requiredFilters?.[props.fields];

    const localDp = useDataProvider(
        _.isString(props.remote)
            ? {
                  url: props.remote,
                  stop: !hasPermission,
              }
            : props.remote,
    );
    const infiniteScroll = useInfiniteScroll(localDp);
    let value = fieldType.getFilter(props.fields) ?? [];
    value = _.isArray(value[0]) ? _.last(value[0]) : _.last(value);

    const selectedProducts = useData(value && _props.selectedDetails?.url, {
        filters: {
            id: ["id", value],
        } as any,
    });
    const [localSearchVal, setLocalSearchVal] = useState("");

    // used to search values
    const { searchInputValue, setSearchInputValue } = useDebouncedSearch(
        () => {
            if (_props.searchLocal) {
                setLocalSearchVal(searchInputValue);
                return;
            }
            if (searchInputValue) {
                localDp.filters.add([props.searchField || props.fields, searchInputValue], props.fields);
            } else {
                localDp.filters.remove(props.fields);
            }
        },
        _props.searchLocal ? 0 : 500,
    );
    // used to add filter toglobal dp object
    const onSelect = (val: any[]) => {
        if (val.length) {
            let filter: any[] = [props.fields, val];
            if (!_.isEmpty(props.extraFields)) {
                filter = [filter, ...(props.extraFields?.map(field => [field, val]) ?? [])];
            }
            if (props.fieldType && props.fieldType === "params") {
                fieldType.add(props.fields, filter);
            } else {
                fieldType.add(filter, props.fields);
            }
        } else {
            onClean();
        }
    };

    const getData = () => {
        const _data = _props?.refineData?.(infiniteScroll.data) ?? infiniteScroll.data;
        if (!_props.searchLocal) return _data;
        return _data.filter((d: any) => {
            return d[_props.searchField ?? "name"].toLowerCase().includes(localSearchVal.toLowerCase());
        });
    };

    const data = getData();

    const pickerProps: Partial<CheckPickerProps<any>> = {
        placeholder: getLabel(props.fields),
        ...props.pickerProps,
    };

    const searchMaxWidth = props.maxWidth ? props.maxWidth : 225;
    const searchWidth = props.width ? props.width : 290;

    const onClean = () => {
        fieldType.remove(props.fields);

        if (requiredMinimumFilters) {
            fieldType.add(requiredMinimumFilters, props.fields);
        }
    };

    return hasPermission ? (
        <CheckPicker
            test-id={`${props.fields}-filter`}
            data={data}
            container={() => container.current!}
            onSelect={onSelect}
            style={{ width: searchWidth, maxWidth: searchMaxWidth }}
            menuStyle={{ width: 290 }}
            onSearch={(val: string) => setSearchInputValue(val)}
            value={value || []}
            searchBy={() => true}
            labelKey={props.labelField}
            valueKey="id"
            onExit={() => localDp.filters.remove(props.fields)}
            onClean={onClean}
            renderValue={(value: number[], item: ItemDataType | ItemDataType[], selectedElement: React.ReactNode) => {
                return _props.selectedDetails?.url ? (
                    <CheckListSelectedValues selectedItems={selectedProducts.data || ([] as any)} keyIndex={"name"} />
                ) : (
                    selectedElement
                );
            }}
            renderMenuItem={(label: any, item: any) => (
                <span className={`${item.deleted_at ? "tw-text-[#c0392b] font-weight-bold" : ""}`}>{label}</span>
            )}
            renderMenu={menu => {
                return (
                    <>
                        {menu}
                        {localDp.isLoading && <div className={"tw-px-2 tw-py-1 tw-text-base"}>Loading...</div>}
                    </>
                );
            }}
            {...infiniteScroll.selectProps}
            {...pickerProps}
        />
    ) : null;
};

RemoteCheckListFilter.defaultProps = {
    labelField: "name",
};

export default RemoteCheckListFilter;
