import { t } from "lib/i18n";
import { ModifierGroupType } from "config/constants";
import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";

export const ModifierSchema: ValidationSchema = {
    name: ruleset.required,
    min_select: ruleset.combineIf(
        (val, vals, par) => par?.type === ModifierGroupType.MULTI,
        [ruleset.gte(0), ruleset.lte("max_select", true), ruleset.required],
    ),
    max_select: [ruleset.gte(1), ruleset.gte("min_select", false, t("min_max_compare"))],
};
