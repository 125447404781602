import { t } from "lib/i18n";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useTitle } from "components/Header/Title";
import HeaderRight from "../../../components/Header/HeaderRight";
import FiltersContainer from "../../../components/DataTable/Filters/FiltersContainer";
import SearchFilter from "../../../components/DataTable/Filters/SearchFilter";
import DataTable from "../../../components/DataTable/DataTable";

import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import CreateButton from "../../../components/DataTable/CreateButton";
import { useDrawer } from "components/Drawers/useDrawer";
import MenuStationCreateModal from "./MenuStationCreateModal";

export const MenuStationsPage: FCC = props => {
    const createMenuStationModal = useDrawer();
    const dp = useDataProvider({
        url: "station",
        useUrl: true,
        params: { productCount: "1" },
        sort: ["created_at", "1"],
    });
    const cm = useColumns<any>("station", [
        { key: "name", flexGrow: 3 },
        { key: "products_count", title: t("total_count") },
        {
            key: "printable",
            type: "bool",
            align: "center",
        },
        {
            title: t("station_reminder"),
            key: "meta.station_reminder",
            type: "bool",
            align: "center",
        },
        optionsColumn({
            dp,
            edit: d => {
                createMenuStationModal.setDataId(d.id);
                createMenuStationModal.setData(d);
            },
            delete: "/station/:id",
            canEdit: "station_edit",
            canDelete: "station_delete",
        }),
    ]);
    return (
        <div className="h-100">
            {useTitle(t("stations"))}
            <HeaderRight cm={cm} print reload dp={dp} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter className="search-input" fields="name" />
                </div>
                <CreateButton
                    to={() => {
                        createMenuStationModal.hide();
                        createMenuStationModal.setIsOpen(true);
                    }}
                    canCreate="station_create"
                />
            </FiltersContainer>
            <DataTable
                rowPadding="20px 10px 0px"
                tableProps={{
                    rowHeight: 60,
                }}
                dp={dp}
                columns={cm.columns}
            />
            {createMenuStationModal?.isOpen ? <MenuStationCreateModal dp={dp} {...createMenuStationModal} /> : null}
        </div>
    );
};

export default MenuStationsPage;
