import { iArrowDown, iArrowUp, iSortDefault } from "assets/icons/Icons";
import CIcon from "components/CIcon";

interface IProps {
    sort: string;
}

interface sortDirectionType {
    sort: string;
    size?: number;
    icon: string;
    margin: string;
}
const sortObj: Record<string, sortDirectionType> = {
    "-1": { sort: "desc", icon: iArrowUp, size: 0.4, margin: "-1px" },
    "1": { sort: "asc", icon: iArrowDown, size: 0.4, margin: "-1px" },
    "0": { sort: "", icon: iSortDefault, margin: "-3px" },
};

const SortBtn: FCC<IProps> = ({ sort }) => {
    return (
        <CIcon
            className="rs-table-header-icon"
            style={{ color: "#3498ff", cursor: "pointer", marginLeft: "2px", marginBottom: sortObj[sort]?.margin }}
            size={sortObj[`${sort}`]?.size}
            path={sortObj[`${sort}`]?.icon}
        />
    );
};

export default SortBtn;
