import { getLabel } from "components/Form/form-utils";
import React, { CSSProperties } from "react";
import Money from "./Money";

interface IProps {
    name: LangKey;
    value?: string | number | boolean;
    className?: string;
    type?: "text" | "money";
    style?: CSSProperties;
}

const DataPair: FCC<IProps> = props => {
    return (
        <div className={`d-flex ${props.className ?? ""}`} style={props.style}>
            <strong>{getLabel(props.name)}</strong>:
            <span className="ml-2">{props.type === "money" ? <Money value={props.value as any} /> : props.value}</span>
        </div>
    );
};

export default DataPair;
