import React from "react";
import { useTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import CreateButton from "components/DataTable/CreateButton";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { Button } from "rsuite";
import { Link, useHistory } from "react-router-dom";
import DataTable from "components/DataTable/DataTable";
import CIcon from "components/CIcon";
import { TText } from "components/i18n/TText";
import { iEditPencil, iSortDefault } from "assets/icons/Icons";
import { OrderHallsDrawer } from "components/Drawers/OrderHallsDrawer";
import { useDrawer } from "components/Drawers/useDrawer";

const HallsPage: FCC = () => {
    const history = useHistory();

    const dp = useDataProvider({
        url: "hall",
        useUrl: true,
        withCount: ["tables.count"],
        localSort: [["position"], ["asc"]],
    });

    const cm = useColumns("hall", [
        {
            key: "name",
            align: "left",
            /**
             * To prevent datatable sorting we should use a field that doesn't exist.
             * This is a hack, would be better to change DataTable that respects to sortable: false
             */
            sortField: "_name",
        },
        {
            key: "service_charge",
            align: "left",
            render: h => {
                return h.service_charge !== null ? `${h.service_charge}%` : "-";
            },
        },
        {
            key: "table_count",
            align: "left",
            render: h => h.tables_count,
        },
        {
            key: "edit",
            align: "left",
            render: hall => {
                return (
                    <Link to={`/settings/halls/manageTables/${hall.id}`}>
                        <Button appearance={"link"} className={"p-0"}>
                            {t("edit_tables")}
                        </Button>
                    </Link>
                );
            },
        },

        optionsColumn({
            dp,
            others: () => [
                {
                    label: (
                        <div className="d-flex align-items-center ">
                            <CIcon className="mr-2" path={iEditPencil} />
                            <TText tkey="edit_tables" />
                        </div>
                    ),
                    onClick: hall => history.push(`/settings/halls/manageTables/${hall.id}`),
                },
            ],
            edit: "/settings/halls/edit/:id",
            delete: "/hall/:id",
        }),
    ]);

    const drawer = useDrawer({
        backdrop: true,
        overflowAuto: true,
    });

    return (
        <div className={"tw-h-100"}>
            {useTitle(t("table_management"))}
            <FiltersContainer dp={dp}>
                <div className={"flex-fill"}></div>
                <Button size={"xs"} appearance={"default"} onClick={() => drawer.setIsOpen(true)}>
                    <CIcon path={iSortDefault} style={{ fillRule: "evenodd" }} className="mr-2" />
                    <TText tkey="order_halls" />
                </Button>
                <CreateButton to={"/settings/halls/create"} />
            </FiltersContainer>
            <DataTable dp={dp} columns={cm.columns} />
            <OrderHallsDrawer halls={dp.data} {...drawer} onOrderChange={() => dp.revalidate()} />
        </div>
    );
};

export default HallsPage;
