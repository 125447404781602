import React, { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { financeCategoriesSlice } from "redux/features/model/slice";
import CategorySelectFormGroup from "./CategorySelectFormGroup";
import { CategorySelectFormGroupProps } from "./CategorySelectFormGroupProps";
import { AppDispatch, store } from "redux/store";

async function fetchCategories(dispatch: AppDispatch) {
    const state = store.getState().model.financeCategories;
    if (state.status !== "loading") {
        await dispatch(financeCategoriesSlice.fetchData(""));
    }
}

const FinanceCategorySelectFormGroup: FCC<CategorySelectFormGroupProps> = props => {
    const dispatch = useAppDispatch();
    const categories = useAppSelector(state => state.model.financeCategories);

    useEffect(() => {
        fetchCategories(dispatch);
    }, [props?.revalidate]);

    return <CategorySelectFormGroup {...props} categories={categories} />;
};

export default FinanceCategorySelectFormGroup;
