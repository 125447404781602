import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";

import _ from "lodash";
import { exportFile } from "lib/export-table";
import Money from "components/mini/Money";
import React from "react";
import { AccountPageType, accountQuickLinks } from "./utils";
import SelectFilter from "components/DataTable/Filters/SelectFilter";

const StaffAccountsPage: FCC = () => {
    const url = "user";
    const dp = useDataProvider<IUser>({
        url,
        useUrl: true,
        with: ["bonus_balance", "role"],
        useDates: true,
        filters: {
            status: ["status", 1],
        },
    });

    const cm = useColumns<IUser>("staff-account", [
        {
            key: "username",
            align: "left",
            flexGrow: 2,
        },
        { title: t("role"), key: "role.title", align: "left", flexGrow: 2 },
        {
            key: "salary",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={_.sumBy(dp.data, "salary")} />,
        },
        {
            key: "bonus_balance.amount",
            title: t("bonus_balance"),
            type: "money",
            numberRoundingLength: 2,
            summary: () => {
                return <Money value={_.sumBy(dp.data, "bonus_balance.amount")} />;
            },
        },
    ]);

    return (
        <div className="h-100">
            {useTitle(t("staff"), dp.total)}

            {accountQuickLinks(AccountPageType.STAFF)}

            <HeaderRight dp={dp} cm={cm} reload print export={() => exportFile(dp, cm.columns, t("staff_accounts"))} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="username" />
                    <SelectFilter
                        pickerProps={{ placeholder: t("status"), searchable: false }}
                        fields="status"
                        hasAllOption={true}
                        options={[
                            { value: 1, label: t("active") },
                            { value: "0", label: t("inactive") },
                        ]}
                    />
                </div>
            </FiltersContainer>

            <DataTable
                key={url}
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
        </div>
    );
};

export default StaffAccountsPage;
