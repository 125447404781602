import { t } from "lib/i18n";
import { changeKeyReceiptItemByType } from "redux/features/receiptConstructor";
import { useAppDispatch } from "hooks/useRedux";
import { Input, SelectPicker } from "rsuite";
import React from "react";

import { IReceiptItem, ReceiptItemType } from "../../types";
import { changeStationKeyReceiptItemByType } from "redux/features/stationPrintConstructor";
import { handleChangeTextSizeAC } from "redux/features/constructor/constructor-thunks";
import { fontSizeOptions } from "../utils";

const placeOptions = [
    { value: "center", label: t("align_center") },
    { value: "left", label: t("align_left") },
    { value: "right", label: t("align_right") },
    // { value: "space-between", label: t("align_space_between") },
];
const RTextAreaComp = (item: IReceiptItem) => {
    const dispatch = useAppDispatch();

    const handleChangeTextAreaValue = (value: string | undefined) => {
        if (item.receiptType === ReceiptItemType.StationReceipt) {
            dispatch(changeStationKeyReceiptItemByType({ item, value: value as string }));
        }
        if (item.receiptType === ReceiptItemType.Receipt) {
            dispatch(changeKeyReceiptItemByType({ item, value: value as string }));
        }
    };
    const handleChangePlacement = (value: string | undefined) => {
        if (item.receiptType === ReceiptItemType.StationReceipt) {
            dispatch(changeStationKeyReceiptItemByType({ item, value: value as string, key: "align" }));
        }
        if (item.receiptType === ReceiptItemType.Receipt) {
            dispatch(changeKeyReceiptItemByType({ item, value: value as string, key: "align" }));
        }
    };
    return (
        <div className="d-flex align-items-center w-100">
            <Input
                className="w-100 mr-3"
                as="textarea"
                rows={2}
                placeholder={"textarea"}
                value={item.value}
                onChange={v => handleChangeTextAreaValue(v as string)}
            />
            <SelectPicker
                cleanable={false}
                style={{ width: "147px" }}
                onSelect={v => dispatch(handleChangeTextSizeAC(v, item))}
                value={item.textSize}
                data={fontSizeOptions}
                size="sm"
                searchable={false}
            />

            <SelectPicker
                className="mr-3"
                style={{ width: "147px" }}
                cleanable={false}
                searchable={false}
                data={placeOptions}
                value={item.align}
                onSelect={handleChangePlacement}
                size="sm"
            />
        </div>
    );
};
export default RTextAreaComp;
