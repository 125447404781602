import { TText } from "components/i18n/TText";
import CheckboxFormGroup from "components/Form/CheckboxFormGroup";
import { iDelivery, iIn, iTakeAway } from "assets/icons/Icons";
import RadioFormGroup from "components/Form/RadioFormGroup";
import { UseFormReturnType } from "hooks/useCForm";
import { Radio } from "rsuite";
import { PaymentMethodType } from "config/constants";
import { cn } from "lib/utils";

interface IProps {
    form: UseFormReturnType;
    saleType: ISaleType;
    paymentMethod: IPaymentMethod[];
    index: number;
}

const icons: Record<SaleTypeSystemType, string> = {
    TAKEAWAY: iTakeAway,
    DELIVERY: iDelivery,
    IN: iIn,
};

const LoyaltySaleTypeField: FCC<IProps> = ({ form, index, saleType, paymentMethod }) => {
    const isAllPaymentMethodsChecked = form.form.watch(`payload.sale_types[${index}].all_payment_methods`);
    const isSalesTypeChecked = form.form.watch(`payload.sale_types[${index}].status`);
    const payment_methods: Array<{ id: number; status: boolean }> =
        form.form.watch(`payload.sale_types[${index}].payment_methods`) ?? [];
    const icon = icons[saleType.system_type ?? "DELIVERY"];

    return (
        <div>
            <CheckboxFormGroup
                icon={icon}
                key={index}
                name={`payload.sale_types[${index}].status`}
                label={saleType.name}
                className="tw-mb-0"
                useBoolean
                compProps={{
                    onChange: () => {
                        form.form.setValue(`"payload.sale_types[${index}].all_payment_methods"`, 1);
                    },
                }}
                controlWrapperProps={{}}
            />

            {isSalesTypeChecked && (
                <RadioFormGroup
                    name={`payload.sale_types[${index}].all_payment_methods`}
                    className="tw-m-0"
                    hideLabel
                    compProps={{ value: isAllPaymentMethodsChecked ? 1 : 0 }}
                >
                    <div className="tw-flex-col tw-ml-4">
                        <div>
                            <Radio
                                onChange={() =>
                                    form.form.setValue(`"payload.sale_types[${index}].all_payment_methods"`, 1)
                                }
                                name={`payload.sale_types[${index}].all_payment_methods`}
                                value={1}
                            >
                                <TText tkey="all_payment_methods_applied" />
                            </Radio>
                        </div>
                        <div>
                            <Radio
                                onChange={() =>
                                    form.form.setValue(`payload.sale_types[${index}].all_payment_methods`, 0)
                                }
                                name={`payload.sale_types[${index}].all_payment_methods`}
                                value={0}
                            >
                                <TText tkey="select_payment_methods" />
                            </Radio>
                            {!isAllPaymentMethodsChecked && (
                                <div className="tw-flex tw-gap-2 tw-flex-wrap">
                                    {paymentMethod
                                        ?.filter(
                                            (methodItem: any) => methodItem.id !== PaymentMethodType.CASHBACK_BALANCE,
                                        )
                                        .map((method: IPaymentMethod, i: number) => {
                                            let methodIndex = payment_methods.findIndex(
                                                pMethod => pMethod.id === method.id,
                                            );

                                            return (
                                                <button
                                                    key={method.id}
                                                    onClick={e => {
                                                        e.preventDefault();

                                                        if (methodIndex === -1) {
                                                            payment_methods.push({
                                                                id: method.id!,
                                                                status: false,
                                                            });
                                                            methodIndex = payment_methods.length - 1;
                                                        }
                                                        form.form.setValue(
                                                            `payload.sale_types[${index}].payment_methods[${methodIndex}]`,
                                                            {
                                                                status: !payment_methods[methodIndex]?.status,
                                                                id: method.id,
                                                            },
                                                        );
                                                    }}
                                                    className={cn(
                                                        `tw-bg-transparent tw-flex tw-py-1 tw-px-2 tw-items-center tw-gap-2.5 tw-rounded tw-border-border tw-border-solid tw-border tw-text-disabled tw-text-sm tw-font-medium`,
                                                        {
                                                            [`tw-bg-primary tw-text-white`]:
                                                                payment_methods[methodIndex]?.status,
                                                        },
                                                    )}
                                                >
                                                    {method.name}
                                                </button>
                                            );
                                        })}
                                </div>
                            )}
                        </div>
                    </div>
                </RadioFormGroup>
            )}
        </div>
    );
};

export default LoyaltySaleTypeField;
