import { useEffect, useState } from "react";

export function useTableHeight(hHeight: number): number {
    const [height, setHeight] = useState(window.innerHeight - hHeight);
    useEffect(() => {
        const handleResize = () => setHeight(window.innerHeight - hHeight);
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });
    return height;
}
