import { ISetting as ILibSetting } from "@cloposcom/libs/src/receipt-calculation/interfaces";
import { useSetting } from "hooks/useSetting";

// todo: precision type eslinde number olmalidi, amma libde string olaraq qeyd olunub, ona gore helelik override edirik. libde de update elesek yaxshi olar
export interface ICalculationSettings extends Omit<ILibSetting, "precision"> {
    precision: number;
}

export function useCalculationSetting(): ICalculationSettings {
    const terminalMeta = useSetting("terminal_meta").value;
    const rounding = useSetting("rounding").value;
    const precision = useSetting("precision").value;

    return {
        apply_tip_rate: false,
        terminal_meta: terminalMeta,
        rounding,
        precision,
    };
}
