import { useAppDispatch } from "hooks/useRedux";
import { useEffect } from "react";
import { depot } from "@cloposcom/libs";
import { setSettings, setSettingsLoading } from "redux/features/settings";
import { fetchVenues } from "lib/utils/fetchVenues";
import { setGlobalError } from "redux/features/app";
import { setCurrentVenue, setVenueList } from "redux/features/venue";
import Ajax from "lib/Ajax";
import { arrayToMap } from "lib/utils";

import { parseSettingValue } from "lib/settings-utils";

export const useInitializeSettings = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        (async () => {
            try {
                let selectedVenue = 0;
                selectedVenue = parseInt(window.location.pathname.split("/")[2]);
                const token = depot.getItem("token");
                if (!token) {
                    dispatch(setSettingsLoading(false));
                    return;
                }
                const venueList = await fetchVenues(token);
                if (!venueList?.length) {
                    dispatch(setGlobalError("No active venue found. Please contact your administrator."));
                    dispatch(setSettingsLoading(false));
                    return;
                }
                dispatch(setVenueList(venueList));

                //check if selected venue is still active for the user
                if (!venueList?.some((v: IVenue) => v.id === selectedVenue)) {
                    dispatch(setCurrentVenue(venueList?.[0].id));
                } else {
                    dispatch(setCurrentVenue(selectedVenue));
                }

                const resp = await Ajax.get<ISetting[]>({ url: "setting" });

                const parsedSettings = arrayToMap(resp.data, "name", parseSettingValue);

                // @ts-ignore todo: fix later
                dispatch(setSettings(parsedSettings));
            } catch (e) {
                dispatch(setSettingsLoading(false));
            }
        })();
    }, []);
};
