import { createAsyncThunk } from "@reduxjs/toolkit";
import Ajax from "../../../lib/Ajax";
import { ProductType, Trash } from "config/constants";
import { setProducts } from "../data";
import { captureMessage } from "@sentry/react";
import { resetTrashProductQueue } from "redux/features/productsSelector/trashProductSelector";
import _ from "lodash";

const selects = [
    "id",
    "name",
    "category_id",
    "emenu_category_id",
    "price",
    "updated_at",
    "unit_id",
    "type",
    "parent_name",
    "parent_id",
    "has_modifications",
    "barcode",
    "station_id",
    "cost_price",
    "unit_weight",
    "position",
    "emenu_position",
    "deleted_at",
];

const fetchTrashProducts = createAsyncThunk("data/fetchTrashProducts", async (ids: number[], { dispatch }) => {
    try {
        const url = Ajax.buildUrl({
            url: "/product",
            params: {
                limit: ids.length,
                trash: Trash.OnlyTrash,
                page: 1,
                selects: selects.join(","),
                with: ["packages", "modifications"],
                filters: [["id", ids]],
                sort: [["id", "1"]],
            },
        });

        const response = await Ajax.get({
            url: url,
        });

        const allProducts: IProduct[] = response.data;
        const byId: Record<number, IProduct> = {};

        for (let i = 0; i < allProducts.length; i++) {
            const item = allProducts[i];

            if (item.type === ProductType.MODIFICATION) {
                item.slug = byId[item.parent_id!]?.slug + "-" + item.slug;
            }

            byId[item.id] ??= item;
        }

        dispatch(
            setProducts({
                byId,
            }),
        );
        dispatch(resetTrashProductQueue());
    } catch (e) {
        captureMessage("Could not fetch trash products", {
            extra: {
                error: e,
            },
        });
    }
});

const _debouncedFetchTrashProducts = _.debounce((arg, dispatch) => {
    dispatch(fetchTrashProducts(arg));
}, 50);

export const debouncedFetchTrashProducts = (ids: number[]) => (dispatch: any) =>
    _debouncedFetchTrashProducts(ids, dispatch);
