import { Nullable } from "types/Nullable";

export const enum Lang {
    EN = "en",
    AZ = "az",
    RU = "ru",
    AR = "ar",
    TR = "tr",
    UZ = "uz",
    PL = "pl",
}

export type LangInfo = {
    name: Lang;
    title: string;
    active: boolean;
};

type LangVersion = `${number}.${number}.${number}`;
type Translations = Record<string, string>;

const toLowerKeyed = (translations: Translations) =>
    Object.keys(translations).reduce((lowerKeyedTranslations, key) => {
        lowerKeyedTranslations[key.toLowerCase()] = translations[key];

        return lowerKeyedTranslations;
    }, {} as Translations);

export class I18N {
    private static readonly SELECTED_LANG_KEY = "__app_lang_selected";
    private static readonly LANG_VERSION_KEY = "__app_lang_version";
    private static readonly LANG_DATA_KEY = "__app_lang_data_parsed";
    private static readonly baseURL = "https://translations.clopos.com";

    static removeSavedLocalLang() {
        localStorage.removeItem(I18N.SELECTED_LANG_KEY);
    }

    static get localLang(): Nullable<Lang> {
        return localStorage.getItem(I18N.SELECTED_LANG_KEY) as Nullable<Lang>;
    }

    private static set localLang(lang: Lang) {
        localStorage.setItem(I18N.SELECTED_LANG_KEY, lang);
    }

    private static get localLangVersion(): Nullable<LangVersion> {
        return localStorage.getItem(I18N.LANG_VERSION_KEY) as Nullable<LangVersion>;
    }

    private static set localLangVersion(version: LangVersion) {
        localStorage.setItem(I18N.LANG_VERSION_KEY, version);
    }

    private static get localLangData(): Translations {
        return toLowerKeyed(JSON.parse(localStorage.getItem(I18N.LANG_DATA_KEY) ?? "{}"));
    }

    private static set localLangData(translations: Translations) {
        localStorage.setItem(I18N.LANG_DATA_KEY, JSON.stringify(translations));
    }

    private static translations: Translations = I18N.localLangData;

    static getAvailableLangs = (): Promise<LangInfo[]> =>
        fetch(`${I18N.baseURL}/langs.json`)
            .then(r => r.json())
            .catch(e => {
                //@ts-ignore
                throw new Error("Failed to fetch available languages", {
                    cause: e.message,
                });
            });

    private static getTranslations = (lang: Lang, version: string): Promise<Translations> =>
        fetch(`${I18N.baseURL}/${lang}/panel.json?_v=${version}`)
            .then(r => r.json())
            .catch(e => {
                //@ts-ignore
                throw new Error(`Failed to fetch language ${lang} (${version})`, {
                    cause: e.message,
                });
            });

    static getCDNVersion = (): Promise<LangVersion> =>
        fetch(`${I18N.baseURL}/version.json?_t=${Date.now()}`)
            .then(response => response.json())
            .then(data => data.version as LangVersion);

    static async updateTranslations(newLang: Lang): Promise<void> {
        const CDNVersion = await I18N.getCDNVersion();

        if (CDNVersion === I18N.localLangVersion && newLang === I18N.localLang) return;

        const newTranslations = toLowerKeyed(await I18N.getTranslations(newLang, CDNVersion));

        I18N.localLang = newLang;
        I18N.localLangVersion = CDNVersion;
        I18N.localLangData = I18N.translations = newTranslations;
    }

    static t = (langKey: LangKey, params?: Record<string, string | number | boolean>): string => {
        if (localStorage.getItem("DEBUG_I18N_KEYS") === "true") return langKey;

        const text = I18N.translations[langKey];

        if (text === undefined) return langKey;

        if (params === undefined) return text;

        return text.replace(new RegExp("(?<group>{\\s*(?<key>.+?)\\s*})", "g"), (...args) => {
            const { group, key } = args[args.length - 1];

            return (params[key] ?? group) as string;
        });
    };
}

export const t = I18N.t;
