import _ from "lodash";

import {
    iAddUser,
    iAlert,
    iBasketMinus,
    iBasketPlus,
    iBorderedInfo,
    iCancel,
    iChangeProductSeller,
    iChangeQuest,
    iChangeTable,
    iCheckBox,
    iCommentProcessingOutline,
    iCursorCircle,
    iFiscal,
    iFiscalFail,
    iFunnySmile,
    iGiftOutline,
    iLinkVariant,
    iLock2,
    iLogin,
    iLogout,
    iMinus,
    iMoney,
    iNetwork,
    iPaymentMethodChange,
    iPercentage,
    iPercentageBordered,
    iPersonPlus,
    iPrint,
    iPrinter,
    iRefresh,
    iRemoveCustomer,
    iSadSmile,
    iSecurityBordered,
    iSend,
    iSentToKitchenKitsFail,
    iSplit,
    iSwapHorizontal,
    iTimerStart,
    iTimerStop,
    iToggleSwitch,
    iTrash,
    iUnLock,
    iUpdateCustomerInfo,
} from "assets/icons/Icons";
import CIcon from "components/CIcon";

import { CSSProperties } from "react";
import { Colors } from "config/colors";
import { useCurrency } from "hooks/useCurrency";

interface IProps {
    d: IHistory;
    onClick: () => void;
    hasIcon?: boolean;
}

const AlertIcon = (
    <CIcon
        path={iAlert}
        size={0.5}
        style={{
            color: Colors.VividOrange,
            marginBottom: "-1px",
            marginRight: "0.4rem",
        }}
    />
);

const typesWithDetails = [
    "SentToPrinters",
    "SentToKitchenKits",
    "SentToPrintersFail",
    "SentToKitchenKitsFail",
    "Split",
    "SplitIn",
    "SplitOut",
    "SentToStation",
];

const ActionString: FCC<IProps> = ({ d, onClick, hasIcon }) => {
    const currency = useCurrency();
    // const suspicion_actions = selectSetting("suspicion_action")?.value;
    const template = d.template ?? "";

    const payload = d.payload;
    if (payload) {
        if (_.isString(payload)) {
            d.payload = {};
            d.payload.pm = payload;
        } else if (d.type === "Clean" && _.isArray(payload)) {
            d.payload = {};
            d.payload.clearRps = payload;
        }
        d.payload.user = d?.userName || d?.userId;
        d.payload.currency = currency;
    }

    const hasDetails = typesWithDetails.includes(d.type);

    return (
        <>
            {hasIcon ? "" : <CIcon className="mr-3" size={"20"} path={actionIcon[d.type]?.[0]?.icon} />}

            <div style={{ textAlign: "left", width: "30rem" }} title="">
                {d.cause === 1 ? AlertIcon : null}
                {template}
                {hasDetails && (
                    <span className={"pl-2 tw-cursor-pointer position-relative"} onClick={onClick}>
                        <CIcon
                            className="position-absolute"
                            path={iBorderedInfo}
                            style={{ color: Colors.CobaltBlue, marginTop: "3px" }}
                            size={0.6}
                        />
                    </span>
                )}
            </div>
        </>
    );
};

/**
 *   SendToPrinter: undefined;
 *         SendToKitchenKit: undefined;
 *
 *         SentToPrinters: {
 *             data: any[]
 *         };
 *         SentToKitchenKits: {
 *             data: any[]
 *         };
 *
 *         SentToPrintersFail: {
 *             data: any[]
 *         };
 *         SentToKitchenKitsFail: {
 *             data: any[]
 *         };
 */

export default ActionString;
// const a = "---";
// const getTemplate = async (log: IHistory): Promise<string> => log.template ?? "";

// const getTemplate2 = async (log: IHistory, currency?: string): Promise<string> => {
//     const receiptHistory = Constants.receiptHistoryTypes;
//     let str = _.get(receiptHistory, log.type);
//     //this obj must be there, because up level translated incorrect
//     const terminalErrors: Record<string, string> = {
//         "No printers selected": t("no_printer_selected"),
//     };
//     if (log.type === "Print") {
//         const hasTotal = log.payload.total ? 0 : 1;
//         //there we don't translate key because of used in manager (override type it's not good idea :D)
//         const translateArr = ["preprint", t("preprint_type")];
//         const isPreprint = translateArr.includes(log.payload.type);
//         log.payload.type = isPreprint ? t("preprint_type") : t("receipt");

//         str = str[hasTotal];
//     } else if (
//         log.type === "AddProduct" ||
//         log.type === "RemoveProduct" ||
//         log.type === "RemoveProductAfterStation" ||
//         log.type === "RemoveProductAfterPrecheck"
//     ) {
//         if (log.payload.rpms_name) {
//             str = str.rpmName + (log.payload.tag ? t("delete_reason_rlog") : "");
//         } else if (log.payload.product_name) {
//             str = str.product + (log.payload.tag ? t("delete_reason_rlog") : "");
//         }
//         str =
//             log.payload?.portion_size !== 1 && log.payload?.portion_size
//                 ? str + ` (${log.payload?.portion_size} ${t("portion")})`
//                 : str;
//     } else if (log.type === "PrintFail") {
//         // log.payload.msg = t(log.payload.msg);
//         const isPreprint = log.payload.type === "preprint";
//         log.payload.type = isPreprint ? t("preprint_type") : t("receipt");
//         // log.payload.msg = terminalErrors[log.payload.msg];
//         // str = t("log_print_failed");
//     } else if (log.type === "AddDiscount") {
//         log.payload.value = parseFloat(log.payload.value.toFixed(4));
//     } else if (log.type === "AddSeller") {
//         str = t("receipt_log_add_seller");
//         log.payload.user = log.userName;
//         log.payload.seller_name = log.payload.user_name;
//     } else if (log.type === "AddSellerProduct") {
//         str = t("receipt_log_add_seller_product");
//         log.payload.user = log.userName;
//     } else if (log.type === "UpdateSellerProduct") {
//         str = t("receipt_log_update_seller_product");
//         log.payload.user = log.userName;
//     } else if (log.type === "AddCourier") {
//         str = t("receipt_log_add_courier");
//         log.payload.courier_name = log.payload.user_name;
//     } else if (log.type === "SplitIn") {
//         log.payload.receipt_id_from = log.payload?.splitFrom;
//     } else if (log.type === "SplitOut") {
//         if (isNaN(+log.payload?.splitTo)) {
//             const { data } = await Ajax.get({ url: `/order/${log.payload?.splitTo}` });
//             log.payload.receipt_id_to = data?.id;
//         } else {
//             log.payload.receipt_id_to = log.payload?.splitTo;
//         }
//     } else if (log.type === "SentToKitchenKitsFail") {
//         str = t("receipt_log_sent_to_kitchen_kits_fail");
//     } else if (log.type === "SentToKitchenKits") {
//         str = t("receipt_log_sent_to_kitchen_kits");
//     } else if (log.type === "UpdateCustomerInfo") {
//         if (!log.payload.type) {
//             str = t("removed_customer_discount");
//         } else {
//             log.payload.type = _.get(receiptHistory.customer_discount_type, log.payload.type) ?? log.payload.type;
//         }
//     } else if (log.type === "Merge") {
//         str = log.payload.target_id ? str.withID : str.withoutId;
//     } else if (log.type === "ReOpen") {
//         str = t("receipt_log_reopen");
//     } else if (log.type === "PrinterToggle") {
//         log.payload.toggle = log.payload.toggle ? t("on") : t("off");
//     } else if (log.type === "Create") {
//         str = !log.payload.hall_name ? str.sale_type : str.table;
//     } else if (log.type === "ChangeSaleType") {
//         str = log.payload.from_hall_id
//             ? str.table_to_delivery
//             : log.payload.to_hall_id
//             ? str.delivery_to_table
//             : str.delivery;
//     } else if (log.type === "ProductTransfer") {
//         str = log.payload.from_id ? str.from : str.to;
//     } else if (log.type === "FiscalFail") {
//         str = t("receipt_log_fiscal_fail");
//     } else if (log.type === "FiscalSent") {
//         str = t("receipt_log_fiscal_sent");
//     } else if (log.type === "FiscalAbort") {
//         str = t("receipt_log_fiscal_abort");
//     } else if (log.type === "PrecheckCheckoutConflict" || log.type === "LogComeAfterReceiptClose") {
//         str = t(log.payload.description);
//     } else if (log.type === "PaymentMethods") {
//         log.payload.pm = log.payload.name;
//     } else if (log.type === "HaveManyPrecheck") {
//         str = t("receipt_has_many_precheck");
//     } else if (log.type === "AdminPassPromptFailed") {
//         str = str + (!_.isNil(log?.payload?.action) ? ` (${t(log?.payload?.action)})` : "");
//     } else if (log.type === "ChangePaymentMethod") {
//         type methodT = { id: number; name: string; amount: number };
//         const logData: { old: methodT[]; new: methodT[] } = log.payload;
//         const prevMethods = (logData?.old || []).map(lD => lD.name + " (" + lD.amount + " " + currency + ")");
//         const newvMethods = (logData?.new || []).map(lD => lD.name + " (" + lD.amount + " " + currency + ")");
//         log.payload = Object.assign(log.payload, {
//             prev_methods: prevMethods.join(", "),
//             new_methods: newvMethods.join(", "),
//         });
//     } else if (log.type === "OrderStatus") {
//         str = t("rlog_order_status", {
//             before: log.payload.before,
//             after: log.payload.after,
//         });
//     }

//     const payload: any = {};

//     Object.entries(log.payload ?? {}).forEach(([key, value]) => {
//         if (!(!isNaN(parseFloat(key)) && isFinite(key as any))) {
//             payload[key] = value;
//         }
//     });

//     return str ? i18n.format(str, payload) : log.type;
// };

export type DoubleIconName = {
    icon: any;
    stack?: string;
    inverse?: boolean;
    size?: string | number;
    style?: CSSProperties;
}[];
const red = Colors.BrightRed;
const green = Colors.TropicalGreen;
const orange = Colors.BrightOrange;
export const actionIcon: Record<string, DoubleIconName> = {
    Create: [{ icon: iCursorCircle, stack: "2x" }],
    AddProduct: [{ icon: iBasketPlus, stack: "2x" }],
    ReOpen: [{ icon: iRefresh, stack: "2x" }],
    RemoveProduct: [{ icon: iBasketMinus, stack: "2x", style: { color: orange } }],
    RemoveProductAfterStation: [
        { icon: iBasketMinus, stack: "2x" },
        {
            icon: iBasketMinus,
            inverse: true,
            stack: "1x",
            style: { left: 2, top: -2 },
        },
    ],
    RemoveProductAfterPrecheck: [
        { icon: iBasketMinus, stack: "2x" },
        {
            icon: iBasketMinus,
            inverse: true,
            stack: "1x",
            style: { left: 2, top: -2 },
        },
    ],
    PriceChange: [{ icon: iMoney, size: "2x" }],
    ChangePaymentMethod: [{ icon: iSwapHorizontal, size: "2x" }],
    AddDiscount: [{ icon: iPercentage, style: { color: green } }],
    PrintFail: [{ icon: iPrint, style: { color: red } }],
    SystemRemove: [{ icon: iPercentage, style: { color: red } }],
    RemoveDiscount: [{ icon: iPercentage, style: { color: red } }],
    AddService: [{ icon: iFunnySmile, stack: "2x", style: { color: green } }],
    RemoveService: [{ icon: iSadSmile, stack: "2x", style: { color: red } }],
    AddCustomer: [{ icon: iAddUser, stack: "2x" }],
    RemoveCustomer: [{ icon: iRemoveCustomer, stack: "2x" }],
    Print: [{ icon: iPrinter, stack: "2x" }],
    PrecheckCheckoutConflict: [{ icon: iPrinter, stack: "2x", style: { color: red } }],
    LogComeAfterReceiptClose: [{ icon: iBorderedInfo, stack: "2x", style: { color: red } }],
    HaveManyPrecheck: [{ icon: iBorderedInfo, stack: "2x", style: { color: red } }],
    Comment: [{ icon: iCommentProcessingOutline, stack: "2x" }],
    Clean: [{ icon: iTrash, stack: "2x", style: { color: red } }],
    ChangeWaiter: [{ icon: iSwapHorizontal, stack: "2x" }],
    ChangeTable: [{ icon: iChangeTable, stack: "2x" }],
    ChangeGuestCount: [{ icon: iChangeQuest, stack: "2x" }],
    Close: [{ icon: iCheckBox, stack: "2x", size: 1.2 }],
    Merge: [{ icon: iLinkVariant, stack: "2x" }],
    Delete: [{ icon: iLinkVariant, stack: "2x" }],
    DeletedPanel: [{ icon: iTrash, stack: "2x", style: { color: red } }],
    AdminPassPrompt: [{ icon: iSecurityBordered, stack: "2x" }],
    AdminPassPromptFailed: [{ icon: iCancel, stack: "2x", style: { color: red } }],
    SendToStation: [{ icon: iNetwork, stack: "2x" }],
    SentToStation: [{ icon: iNetwork, stack: "2x" }],

    SendToPrinter: [{ icon: iSend, stack: "2x" }],
    SendToKitchenKit: [{ icon: iSend, stack: "2x" }],
    SentToPrinters: [{ icon: iSend, stack: "2x" }],
    SentToKitchenKits: [{ icon: iSend, stack: "2x" }],

    StartTimer: [{ icon: iTimerStart, stack: "2x" }],
    StopTimer: [{ icon: iTimerStop, stack: "2x" }],
    RemovedSellerProduct: [{ icon: iRemoveCustomer, stack: "2x" }],
    RemoveSeller: [{ icon: iRemoveCustomer, stack: "2x" }],

    UpdateCustomerInfo: [{ icon: iUpdateCustomerInfo, stack: "2x" }],
    PrinterToggle: [{ icon: iToggleSwitch, size: 1.5, stack: "2x" }],
    SentToStationFail: [{ icon: iCancel, stack: "2x", style: { color: red } }],
    Split: [{ icon: iSplit, stack: "2x", size: 1.2 }],
    SentToPrintersFail: [{ icon: iPrint, stack: "2x", style: { color: red } }],
    SentToKitchenKitsFail: [{ icon: iSentToKitchenKitsFail, stack: "2x", style: { color: red, fillRule: "evenodd" } }],

    AddComment: [{ icon: iCommentProcessingOutline, stack: "2x" }],
    RemoveComment: [{ icon: iMinus, stack: "2x" }],
    ChangeSaleType: [{ icon: iPercentageBordered, stack: "2x" }], //growth
    PaymentMethods: [{ icon: iPaymentMethodChange, stack: "2x" }],

    ProductTransfer: [{ icon: iSwapHorizontal, stack: "2x" }],
    ProductGift: [{ icon: iGiftOutline, stack: "2x" }],
    AddRPDiscount: [{ icon: iPercentage, style: { color: green } }],
    RemoveRPDiscount: [{ icon: iPercentage, style: { color: red } }],

    SplitIn: [{ icon: iSplit, stack: "2x", size: 1.2 }],
    SplitOut: [{ icon: iSplit, stack: "2x", size: 1.2 }],
    AddSeller: [{ icon: iPersonPlus, stack: "2x" }],
    AddSellerProduct: [{ icon: iPersonPlus, stack: "2x" }],
    UpdateSellerProduct: [{ icon: iChangeProductSeller, stack: "2x" }],
    AddCourier: [{ icon: iPersonPlus, stack: "2x" }],
    FiscalSent: [{ icon: iFiscal, stack: "2x" }],
    FiscalSuccess: [{ icon: iFiscal, stack: "2x" }],
    FiscalFail: [{ icon: iFiscal, stack: "2x", style: { color: red } }],
    FiscalAbort: [{ icon: iFiscal, stack: "2x", style: { color: red } }],
    FiscalRollback: [{ icon: iFiscal, stack: "2x" }],
    FiscalRollbackFail: [{ icon: iFiscalFail, stack: "2x", style: { color: red } }],
    TerminalLogin: [{ icon: iLogin, stack: "2x", style: { color: Colors.CobaltBlue } }],
    TerminalLogout: [{ icon: iLogout, stack: "2x", style: { color: red } }],

    Lock: [{ icon: iLock2, stack: "2x" }],
    Unlock: [{ icon: iUnLock, stack: "2x" }],
    OrderStatus: [{ icon: iFiscal, stack: "2x" }],
};
