import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { Button, ButtonGroup, Divider, Input, InputGroup, Loader, Message, Notification } from "rsuite";
import DatePickerState from "components/State/DatePickerState";
import * as Sentry from "@sentry/browser";
import RemoteSelectFormState from "components/State/RemoteSelectPickerState";
import SheetTableStateModified from "components/State/CSheet/SheetTableStateModified";
import { INV_STATUS, INVENTORY_DECLARATION, OperationType, ProductType } from "config/constants";
import InventoryProductSelector from "components/State/InventoryProductSelector";
import { toFixed } from "lib/utils";
import _ from "lodash";
import JsonViewState from "components/State/JsonViewState";
import { getLabel } from "components/Form/form-utils";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import { divideDeclared, handleItems, refineDuplicateImports } from "./methods";
import Ajax from "lib/Ajax";
import { format, fromUnixTime } from "date-fns";
import { useDrawer } from "components/Drawers/useDrawer";
import IngdContainsDrawer from "./IngdContainsDrawer";
import Money from "components/mini/Money";
import { productName } from "lib/data-utils";
import { useData } from "hooks/useData";
import HeaderRight from "components/Header/HeaderRight";
import { exportFile } from "lib/export-table";
import { useTitle } from "components/Header/Title";
import TransfersDrawer from "./TransfersDrawer";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { toggleIsFullScreen } from "redux/features/ui";
import CIcon from "../../../components/CIcon";
import { iAlert, iFullScreen, iFullScreenExit, iSearch } from "assets/icons/Icons";
import CProductsDrawer from "../../../components/Drawers/CProductSelector/CProductsDrawer";
import StorageCheckListItem from "../../../components/DataTable/Filters/StorageCheckListItem";
import { Colors } from "config/colors";
import { inventoryCheckFirstStepColumns, inventoryCheckSecondStepColumns } from "./utils";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useCurrency } from "hooks/useCurrency";
import { useLoading } from "hooks/useLoading";
import {
    setDisabledProductIds,
    setIsSingleSelect,
    setShowedTypes,
} from "redux/features/productsSelector/productsSelector";

const calcTotalCost = _.debounce((nondeclared: any, setState: any) => {
    const val = _.sumBy(_.values(nondeclared), (i: any) =>
        i.declarator !== INVENTORY_DECLARATION.IGNORED && i.declarator !== INVENTORY_DECLARATION.DECLARATOR
            ? _.isNil(i.difference_cost) || _.isNaN(i.difference_cost)
                ? 0
                : i.difference_cost
            : 0,
    );

    setState(val);
}, 1000);

const InventoryCheckEditorPage: FCC<any> = props => {
    const dispatch = useAppDispatch();
    const categoriesArr = useData("category", { params: { tree: 0 } });
    const [isFullScreen, setIsFullScreen] = useState(false);
    const isSidebarExpanded = useAppSelector(state => state.ui.isSidebarExpanded);
    const history = useHistory();
    const location = useLocation();
    const currency = useCurrency();
    const pageDataID = props?.match?.params.id;
    //there add any because dont know which type may be periodType
    const [periodType, setPeriodType] = useState<any>(false);
    const [search, setSearchValue] = useState<string>("");
    const [storageID, setStorageID] = useState<string | number>("");
    const [comment, setComment] = useState<string>("");
    const [date, setDate] = useState<number | Date>(0);
    const [step, setStep] = useState(0);
    const [isNavBlocking, setIsNavBlocking] = useState(true);
    const [totalCost, setTotalCost] = useState(0);

    const [hasNewlyItemsAdded, setNewlyItemsAdded] = useState(false);
    const [hasChangesMade, setChangesMade] = useState(false);
    const [isFromCreate, setIsFromCreate] = useState(false);

    const isAtFirstStep = step === 0;

    const ingrdContainsDrawerState = useDrawer();
    const transfersDrawerState = useDrawer();

    const [isChangeFormData, setIsChangeFormData] = useState(false);
    const [isRunInventoryCheck, setIsRunInventoryCheck] = useState(false);
    const [isProductSelected, setIsProductSelected] = useState(false);
    const [formData, setFormData] = useState<any>({});
    // todo: isProcessingi isLoadingle evezlemek lazimdi, ozum cehd eledim, amma cox deyishiklik lazim oldu deye saxladim
    const [isProcessing, setProcessing] = useState(false);
    const { isLoading, withLoading } = useLoading();
    const [isInventorization, setInventorization] = useState(false);
    const [shouldDisplayInputError, setShouldDisplayInputError] = useState(false);

    const [inventoryRes, setInventoryRes] = useState<IInventoryCheckData | null>(null);
    const [inventoryID, setInventoryID] = useState<number | null>(pageDataID ?? null);

    const [inventoryItems, setInventoryItems] = useState<any>({});
    const [lastChangeInventoryItems, setLastChangeInventoryItems] = useState<any>(new Date().toISOString());

    const [inventoryStatus, setInventoryStatus] = useState<number | null>(null);
    const [status, setStatus] = useState<number | null>(INV_STATUS.DRAFT);

    const onChange = (nextStep: number) => {
        setStep(nextStep < 0 ? 0 : nextStep > 1 ? 1 : nextStep);
        if (nextStep === 1 && status === INV_STATUS.DRAFT) {
            if (isFromCreate) {
                updateServerInventoryData();
                setIsFromCreate(false);
            } else {
                if (hasChangesMade && !hasNewlyItemsAdded) {
                    updateServerInventoryData();
                } else if (hasNewlyItemsAdded) {
                    updateServerInventoryData();
                }
            }
        }
    };
    const onNext = () => onChange(step + 1);
    const onPrevious = () => onChange(step - 1);

    // eslint-disable-next-line
    const pageHandling = (e: any) => {
        if (isChangeFormData) {
            e.preventDefault();
            e.returnValue = "";
        }
    };

    const fullScreenHandling = () => {
        if (document.fullscreenElement) {
            setIsFullScreen(true);
        } else {
            setIsFullScreen(false);
            dispatch(toggleIsFullScreen(false));
        }
    };

    const toggleFullScreen = () => {
        const elem = document.querySelector("body");
        if (!document.fullscreenElement) {
            setIsFullScreen(true);
            dispatch(toggleIsFullScreen(true));
            if (elem) elem.requestFullscreen().catch(_.noop);
        } else {
            setIsFullScreen(false);
            dispatch(toggleIsFullScreen(false));
            document.exitFullscreen();
        }
    };

    useEffect(() => {
        window.addEventListener("beforeunload", pageHandling, true);
        window.addEventListener("fullscreenchange", fullScreenHandling, true);
        return () => {
            window.removeEventListener("beforeunload", pageHandling, true);
            window.removeEventListener("fullscreenchange", fullScreenHandling, true);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageHandling, status]);

    useEffect(() => {
        if (isProductSelected) {
            if (!isAtFirstStep) window.sessionStorage.setItem("inv_last_step", step.toString());
            history.push("/inventory/editor/edit/" + formData?.id, { fromCreate: true });
            setIsChangeFormData(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isProductSelected]);

    useEffect(() => {
        if (isRunInventoryCheck && !isChangeFormData) {
            history.push("/inventory/check");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRunInventoryCheck, isChangeFormData]);

    useEffect(() => {
        const newTypes = isAtFirstStep
            ? [ProductType.DISH, ProductType.PREPARATION]
            : [ProductType.DISH, ProductType.PREPARATION, ProductType.INGREDIENT, ProductType.GOODS];
        dispatch(setShowedTypes(newTypes));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [step, isAtFirstStep]);

    useEffect(() => {
        withLoading(async () => {
            const res = await Ajax.get({
                url: "/check",
            });

            setDate(fromUnixTime(res.unix));
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const updateServerInventoryData = async (statusType: any = status) => {
        if (_.isNull(inventoryID)) return;
        setProcessing(true);
        const hasZero: string[] = [];
        const items = _.values(inventoryItems as any).map((i: any) => {
            const product_id = (i as any)?.product_id || i?.id;
            if (_.isNaN(i?.quantity)) hasZero.push(product_id);
            let cost;
            if (i?.operation_id) {
                if (i?.product?.current_average_cost > 0) {
                    cost = i?.product?.current_average_cost;
                } else {
                    cost = i?.product?.cost_price;
                }
            } else {
                cost = i?.cost_price ?? 0;
            }
            return {
                product_id,
                operation_item_id: i?.id,
                declarator: i.declarator,
                prepacked_quantity: i.prepacked_quantity,
                set_quantity: i.set_quantity ?? 0,
                total_quantity: i.total_quantity ?? 0,
                difference_quantity: i.difference_quantity,
                expected_quantity: i?.product?.expected_quantity,
                difference_cost: i.difference_cost,
                quantity: i.quantity ?? 0,
                comment: i.comment,
                cost,
            };
        });

        if (statusType === INV_STATUS.PUBLISHED && hasZero.length !== 0) {
            setShouldDisplayInputError(true);
            setProcessing(false);
            setChangesMade(false);
            setNewlyItemsAdded(false);
            return "hasZero";
        } else {
            setShouldDisplayInputError(false);
        }

        if (_.isArray(items)) {
            const reqUpdatePayload = {
                url: "operation/" + inventoryID,
                data: {
                    items,
                    operated_at: format(date, "yyyy-MM-dd HH:mm:ss"),
                    force_apply: periodType,
                    status: statusType,
                    storage_id: storageID,
                    description: comment,
                    type: OperationType.INVENTORY_CHECK,
                },
            };

            const res = await Ajax.put(reqUpdatePayload).catch(() => setProcessing(false));
            if (!_.isNil(res?.data)) preparePageDataOnStepChange(res?.data);
            setProcessing(false);
            setChangesMade(false);
            setNewlyItemsAdded(false);
            setIsChangeFormData(false);
            return res;
        } else {
            setProcessing(false);
            return null;
        }
    };

    const submitInvertization = async () => {
        setIsNavBlocking(false);
        setInventorization(true);
        const res: any = await updateServerInventoryData(INV_STATUS.PUBLISHED).catch(() => {
            setInventorization(false);
            setIsNavBlocking(true);
            return <Notification type="error" title={t("error")} children={t("inventory_check_submit_error")} />;
        });

        if (res === "hasZero") {
            setInventorization(false);
            return <Notification type="warning" title={t("msg_info")} children={t("empty_items")} />;
        }
        if (_.isNil(res?.data)) {
            setInventorization(false);
            return <Notification type="error" title={t("error")} children={t("inventory_check_submit_error")} />;
        } else {
            setIsRunInventoryCheck(true);
            setChangesMade(false);
            setIsChangeFormData(false);
            setNewlyItemsAdded(false);
        }
        setInventorization(false);
    };

    const preparePageDataOnStepChange = (data: IInventoryCheckData) => {
        preparePageDataOnInit(data);
    };

    const preparePageDataOnAdd = (data: IInventoryCheckData) => {
        setNewlyItemsAdded(true);
        setIsChangeFormData(true);
        preparePageDataOnInit(data);
    };

    const preparePageDataOnInit = (data: any) => {
        setInventoryRes(data);
        setInventoryID(data.id);
        setInventoryStatus(data.inventory_status);
        setPeriodType(data.force_apply);
        setStatus(data.status);
        setStorageID(data.storage_id);
        setComment(data.description);
        setDate(new Date(data.operated_at));

        try {
            setInventoryItems(() =>
                handleItems(
                    data?.operation_items?.map((i: any) => ({
                        ...i,
                        category: categoriesArr?.data?.find((c: any) => c.id === i?.product?.category_id) ?? null,
                    })),
                    data,
                ),
            );
        } catch (e) {
            setInventoryItems(() => handleItems(data?.operation_items));
            Sentry.captureException(e);
        }

        setLastChangeInventoryItems(new Date().toISOString());
    };

    const fetchExitstingInventory = async () => {
        if (!_.isNil(inventoryID)) {
            const lastStep = window.sessionStorage.getItem("inv_last_step");

            if (!_.isNil(lastStep)) {
                window.sessionStorage.removeItem("inv_last_step");
                setTimeout(() => onNext(), 300);
            }
            setProcessing(true);
            const res = await Ajax.get({
                url: "operation/" + inventoryID,
            }).catch(() => setProcessing(false));
            if (!_.isNil(res?.data)) {
                preparePageDataOnInit(res?.data);
            }
            setProcessing(false);
        }
    };

    useEffect(() => {
        const isFC = (location.state as { fromCreate?: boolean })?.fromCreate || false;
        setIsFromCreate(isFC);
        history?.replace({ state: {} });

        fetchExitstingInventory();
        dispatch(setIsSingleSelect(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onProductSelect = async (items: any[], isImport = false) => {
        setProcessing(true);
        let res = null;
        const selectedItems = items.map((i: any) => ({
            product_id: i?.product_id || i.id || i,
            declarator: isAtFirstStep ? INVENTORY_DECLARATION.DECLARATOR : INVENTORY_DECLARATION.NORMAL,
            quantity: i?.set_quantity ?? 0,
        }));

        if (_.isNull(inventoryID)) {
            const reqPayload = {
                url: "operation",
                data: {
                    items: selectedItems,
                    operated_at: format(date, "yyyy-MM-dd HH:mm:ss"),
                    force_apply: periodType,
                    status: 2,
                    storage_id: storageID,
                    description: comment,
                    type: OperationType.INVENTORY_CHECK,
                },
            };

            res = await Ajax.post(reqPayload).catch(() => setProcessing(false));
        } else {
            const existedItems = _.values(inventoryItems)
                .filter((i: any) => !i.product?.deleted_at)
                .map((i: any) => {
                    return {
                        product_id: i?.product_id ?? i?.id,
                        operation_item_id: i?.operation_id ? i?.id : undefined,
                        declarator: i?.declarator,
                        quantity: i?.quantity ?? 0,
                        prepacked_quantity: i.prepacked_quantity,
                        set_quantity: i.set_quantity ?? 0,
                        total_quantity: i.total_quantity,
                        difference_quantity: i.difference_quantity,
                        difference_cost: i.difference_cost,
                        comment: i.comment,
                    };
                });
            const reqUpdatePayload = {
                url: "operation/" + inventoryID,
                data: {
                    items: isImport
                        ? refineDuplicateImports([...selectedItems], [...existedItems], isAtFirstStep)
                        : [...existedItems, ...selectedItems],
                    operated_at: format(date, "yyyy-MM-dd HH:mm:ss"),
                    force_apply: periodType,
                    status,
                    storage_id: storageID,
                    description: comment,
                    type: OperationType.INVENTORY_CHECK,
                },
            };

            res = await Ajax.put(reqUpdatePayload).catch(() => setProcessing(false));
        }
        if (!_.isNil(res?.data)) {
            setFormData(res?.data);
            if (_.isNil(pageDataID)) {
                setIsChangeFormData(false);
                setIsProductSelected(true);
            } else {
                preparePageDataOnAdd(res?.data);
            }
        } else {
            return <Notification type={"error"} title={t("error")} children={t("inventory_check_submit_error")} />;
        }

        setProcessing(false);
    };

    const deleteInvItem = (data: any) => {
        try {
            const item = data?.props;
            const hasIngredients = item?.product?.ingredients;

            if (item?.product?.type === ProductType.INGREDIENT && !_.isNil(item?.temp)) {
                return <Notification type={"error"} title={t("error")} children={t("inventory_check_delete_failed")} />;
            }

            if (
                (item?.product?.type === ProductType.PREPARATION || item?.product?.type === ProductType.DISH) &&
                !_.isNil(hasIngredients)
            ) {
                if (item?.relativeIngredientID) {
                    for (const x in item?.relativeIngredientID) {
                        const relativeIngredientID = item?.relativeIngredientID[x];
                        const ingredientInState = inventoryItems[relativeIngredientID];

                        if (Object.keys(ingredientInState?.temp).length !== 1) {
                            const hasGood = ingredientInState?.temp[item.id];
                            if (!_.isNil(hasGood)) {
                                setInventoryItems((prev: any) => {
                                    const temp = _.omitBy(
                                        prev[relativeIngredientID]?.temp,
                                        iT => iT.operation_item_id === item.id,
                                    );
                                    const prepacked_quantity = _.sumBy(
                                        _.values(temp) ?? [],
                                        (d: any) => d.prepacked_quantity,
                                    );

                                    const total_quantity = _.sumBy(
                                        _.values(temp) ?? [],
                                        (d: any) => d.prepacked_quantity + d.quantity,
                                    );
                                    prev[relativeIngredientID] = {
                                        ...prev[relativeIngredientID],
                                        temp,
                                        prepacked_quantity,
                                        total_quantity,
                                    };
                                    return prev;
                                });
                                setLastChangeInventoryItems(new Date().toISOString());
                            }
                        } else {
                            setInventoryItems((prev: any) => {
                                if (!prev[relativeIngredientID]?.quantity) {
                                    return _.omitBy(prev, iT => iT.id === relativeIngredientID);
                                }

                                const temp = _.omitBy(
                                    prev[relativeIngredientID]?.temp,
                                    iT => iT.operation_item_id === item.id,
                                );

                                prev[relativeIngredientID] = {
                                    ...prev[relativeIngredientID],
                                    temp,
                                    total_quantity: prev[relativeIngredientID].quantity,
                                    prepacked_quantity: 0,
                                };
                                return prev;
                            });
                            setLastChangeInventoryItems(new Date().toISOString());
                        }
                    }
                }

                setInventoryItems((prev: any) => _.omitBy(prev, a => a.id === item.id));
                setLastChangeInventoryItems(new Date().toISOString());
            } else {
                setInventoryItems((prev: any) => _.omitBy(prev, a => a.id === item.id));
                setLastChangeInventoryItems(new Date().toISOString());
            }
        } catch (e) {
            Sentry.captureException(e);
            return <Notification type={"error"} title={t("error")} children={t("inventory_check_delete_failed")} />;
            // Notification.error({
            //     title: t("error"),
            //     description: t("inventory_check_delete_failed"),
            // });
        }
    };

    const onChangeMainStep = (item: any, value: string, key: string) => {
        try {
            if (key === "comment") {
                setChangesMade(true);
                setIsChangeFormData(true);
                setInventoryItems((prev: any) => {
                    if (!prev[item.id])
                        prev[item.id] = { quantity: item.quantity || 0, set_quantity: item.set_quantity };
                    prev[item.id] = {
                        ...prev[item.id],
                        comment: value,
                    };
                    return prev;
                });
                setLastChangeInventoryItems(new Date().toISOString());
            } else {
                const qty = parseFloat(value);
                setInventoryItems((prev: any) => {
                    if (!prev?.[item.id])
                        prev[item.id] = { quantity: item.quantity || 0, set_quantity: item.set_quantity };
                    if (prev?.[item.id]?.quantity === qty) return prev;
                    setChangesMade(true);
                    setIsChangeFormData(true);

                    prev[item.id] = {
                        ...prev[item.id],
                        quantity: qty || 0,
                        set_quantity: (qty || 0) - prev[item.id]?.expected_quantity,
                    };

                    if (prev[item.id]?.product?.ingredients?.length) {
                        const ingredients = prev[item.id]?.product?.ingredients;
                        for (const x in ingredients) {
                            const multipledGrossQty = (qty || 0) * ingredients[x].gross_rate;
                            let sumQty = 0;
                            let mm = ingredients[x];

                            for (const pr in prev) {
                                if (prev[pr].product_id === ingredients[x].id) mm = prev[pr];
                            }

                            if (prev[mm.id]?.temp) {
                                prev[mm.id].temp[item.id] = {
                                    ...prev[mm.id]?.temp[item.id],
                                    prepacked_quantity: multipledGrossQty,
                                    set_quantity: multipledGrossQty,
                                };
                                sumQty = _.sumBy(_.values(prev[mm.id]?.temp) ?? [], (d: any) =>
                                    d.operation_item_id === item.id ? 0 : d.prepacked_quantity,
                                );
                            }

                            const prepacked_quantity = sumQty + multipledGrossQty;
                            const product = prev[mm.id]?.product;

                            if (product) {
                                const total_quantity = parseFloat(
                                    toFixed((prepacked_quantity || 0) + prev[mm.id].quantity, 4),
                                );
                                const difference_quantity = parseFloat(
                                    toFixed(total_quantity - (product?.expected_quantity || 0), 4),
                                );
                                const set_quantity = parseFloat(
                                    toFixed(total_quantity - (product?.expected_quantity || 0), 4),
                                );
                                const cost_price =
                                    product?.current_average_cost !== 0
                                        ? parseFloat(toFixed(product?.current_average_cost, 4))
                                        : parseFloat(toFixed(product.cost_price, 4));
                                const difference_cost = parseFloat(toFixed(difference_quantity * cost_price, 4));
                                const total_cost = parseFloat(toFixed(total_quantity * cost_price, 4));
                                prev[mm.id] = {
                                    ...prev[mm.id],
                                    prepacked_quantity,
                                    total_quantity,
                                    difference_quantity,
                                    set_quantity,
                                    difference_cost,
                                    cost_price,
                                    total_cost,
                                };
                            }
                        }
                    }
                    return prev;
                });
                setLastChangeInventoryItems(new Date().toISOString());
            }
        } catch (e) {
            Sentry.captureException(e);
            return (
                <Notification
                    type={"error"}
                    title={t("inventory_check_not_calculated")}
                    children={`${productName(item.product)} ${
                        item.type === ProductType.INGREDIENT ? "(" + getLabel(item.product.type) + ")" : ""
                    }`}
                />
            );
            // Notification.error({
            //     title: t("inventory_check_not_calculated"),
            //     description: `${productName(item.product)} ${
            //         item.type === ProductType.INGREDIENT ? "(" + getLabel(item.product.type) + ")" : ""
            //     }`,
            // });
        }
    };

    const onChangeSecondStep = (item: any, value: string, key: string) => {
        try {
            if (key === "comment") {
                setChangesMade(true);
                setIsChangeFormData(true);
                setInventoryItems((prev: any) => {
                    if (!prev[item.id])
                        prev[item.id] = { quantity: item.quantity || 0, set_quantity: item.set_quantity || 0 };
                    prev[item.id] = {
                        ...prev[item.id],
                        comment: value,
                    };
                    return prev;
                });
                setLastChangeInventoryItems(new Date().toISOString());
            } else {
                const qty = parseFloat(value);
                const product = item?.product;

                const total_quantity = parseFloat(toFixed((item?.prepacked_quantity || 0) + (qty || 0), 4));
                const difference_quantity = parseFloat(toFixed(total_quantity - (product?.expected_quantity || 0), 4));
                const set_quantity = parseFloat(toFixed(total_quantity - (product?.expected_quantity || 0), 4));

                const cost_price =
                    product?.current_average_cost > 0 ? product?.current_average_cost : product.cost_price;

                const difference_cost = parseFloat(toFixed(difference_quantity * cost_price, 4));
                const total_cost = parseFloat(toFixed((total_quantity || 0) * cost_price, 4));
                setInventoryItems((prev: any) => {
                    if (!prev[item.id]) prev[item.id] = {};
                    if (prev?.[item.id]?.quantity === qty) return prev;
                    setChangesMade(true);
                    setIsChangeFormData(true);

                    prev[item.id] = {
                        ...prev[item.id],
                        quantity: qty || 0,
                        total_quantity,
                        difference_quantity,
                        set_quantity,
                        difference_cost,
                        cost_price,
                        total_cost,
                    };
                    return prev;
                });
                setLastChangeInventoryItems(new Date().toISOString());
            }
        } catch (e) {
            Sentry.captureException(e);
            return (
                <Notification
                    type={"error"}
                    title={t("inventory_check_not_calculated")}
                    children={`${productName(item.product)} ${
                        item.type === ProductType.INGREDIENT ? "(" + getLabel(item.product.type) + ")" : ""
                    }`}
                />
            );
            //
            // Notification.error({
            //     title: t("inventory_check_not_calculated"),
            //     description: `${productName(item.product)} ${
            //         item.type === ProductType.INGREDIENT ? "(" + getLabel(item.product.type) + ")" : ""
            //     }`,
            // });
        }
    };

    const { declared, nondeclared } = divideDeclared(inventoryItems);
    calcTotalCost(nondeclared, setTotalCost);

    const dataToggle = useCallback(
        (data: any) => {
            if (search?.length !== 0) {
                const regex = new RegExp(_.escapeRegExp(search), "gmui");
                return _.values(data).filter((d: any) => productName(d?.product)?.match(regex) !== null);
            } else {
                return _.values(data);
            }
        },
        [search],
    );
    const productSelectorDisabledItems = useMemo(
        () =>
            _.values(inventoryItems)
                ?.filter(
                    (i: any) =>
                        i.declarator !== INVENTORY_DECLARATION.IGNORED &&
                        (isAtFirstStep
                            ? i.declarator === INVENTORY_DECLARATION.DECLARATOR
                            : i.declarator === INVENTORY_DECLARATION.DECLARED ||
                              i.declarator === INVENTORY_DECLARATION.NORMAL),
                )
                ?.map(i => (i as any).product_id as never),
        [inventoryItems, isAtFirstStep],
    );

    useEffect(() => {
        dispatch(setDisabledProductIds(productSelectorDisabledItems));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productSelectorDisabledItems, isAtFirstStep]);

    const firstStepColumns = useColumns("inventory/check/first-step", inventoryCheckFirstStepColumns);
    const secondStepColumns = useColumns(
        "inventory/check/second-step",
        inventoryCheckSecondStepColumns(currency, ingrdContainsDrawerState, transfersDrawerState),
    );

    const firstStepProductsCount = Object.keys(declared)?.length ?? null;
    const secondStepProductsCount = Object.keys(nondeclared)?.length ?? null;

    return (
        <div className="d-flex flex-column justify-content-between" style={{ height: "inherit" }}>
            {useTitle(
                `${inventoryID ? t("edit") : t("create")}${
                    inventoryID ? ` (${t("inventory_check_document_no")}: ${inventoryID})` : ""
                }`,
            )}
            <HeaderRight
                export={
                    inventoryID
                        ? () =>
                              exportFile(
                                  { url: "operation/" + inventoryID, urlParams: { inventoryCheck: 1 } },
                                  secondStepColumns.columns,
                                  `${t("inventory_check")} ${
                                      inventoryID ? ` (${t("inventory_check_document_no")}: ${inventoryID})` : ""
                                  }`,
                              )
                        : undefined
                }
                columnManagerState={isAtFirstStep ? firstStepColumns : secondStepColumns}
                importOptions={
                    status === INV_STATUS.DRAFT
                        ? {
                              template: "Inventory check temp",
                              detail: "inventory_check",
                              action: "operation/import-inventory",
                              onSuccess: (response: any) => response?.data && onProductSelect(response.data, true),
                          }
                        : undefined
                }
            />
            {isChangeFormData ? <Prompt when={isChangeFormData} message={t("unsaved_changes_notice")} /> : null}
            {!isFullScreen && (
                <div
                    className={"bg-white "}
                    style={{
                        padding: 10,
                        borderRadius: 6,
                        position: "sticky",
                        top: -15,
                        left: 0,
                        right: 0,
                        zIndex: 9,
                    }}
                >
                    {isProcessing && <Loader backdrop size="md" vertical style={{ zIndex: 999 }} />}
                    <div
                        style={{
                            marginRight: "16px",
                            display: "flex",
                            gap: "10px",
                            alignItems: "center",
                            marginBottom: "1rem",
                        }}
                    >
                        <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <div className="d-flex align-items-start">
                                <DatePickerState
                                    mainstyle={{ fontSize: "12px", marginLeft: "1rem", height: "62px" }}
                                    time
                                    width={200}
                                    label={t("operation")}
                                    cleanable={false}
                                    shouldDisableDate={d => !!d && d.getTime() > Date.now()}
                                    onChangeCalendarDate={(date: any) => {
                                        setDate(new Date(date));
                                        setIsChangeFormData(true);
                                        setChangesMade(true);
                                    }}
                                    value={date}
                                    disabled={isLoading || !isAtFirstStep || status === INV_STATUS.PUBLISHED}
                                />
                            </div>
                        </div>
                        <Divider vertical style={{ height: "2rem" }} />
                        <div style={{ height: "75px" }}>
                            <RemoteSelectFormState
                                testId="storage_id"
                                style={{ width: "165px" }}
                                title={t("storage")}
                                placeholder={t("storage")}
                                remote="storage"
                                itemLabel={"name"}
                                itemValue={"id"}
                                searchField={"name"}
                                onSelect={(val: any) => {
                                    setStorageID(val);
                                    setIsChangeFormData(true);
                                    setChangesMade(true);
                                }}
                                value={storageID}
                                validation={[
                                    {
                                        key: "storage_id",
                                        value: storageID,
                                        model: yup.number().typeError(t("required")).required(t("required")),
                                    },
                                ]}
                                pickerProps={{
                                    cleanable: false,

                                    tooltip: t("choose_inventory_check_storage"),
                                    disabled: !isAtFirstStep || status === INV_STATUS.PUBLISHED,
                                    menuStyle: {
                                        zIndex: 9,
                                    },
                                    renderMenuItem: (label: any, item: any) => (
                                        <StorageCheckListItem name="storage_id" label={label} storage={item.item} />
                                    ),
                                }}
                                containerStyle={
                                    {
                                        // width: "30vw",
                                    }
                                }
                            />
                        </div>
                        <InventoryComment
                            comment={comment}
                            setComment={(val: string) => {
                                setComment(val);
                                setIsChangeFormData(true);
                            }}
                            status={status}
                        />
                        <div>
                            <p style={{ marginTop: "-19px", marginBottom: "3px" }}>
                                <TText tkey="diff" />
                            </p>
                            <h4>
                                <Money
                                    colored
                                    style={{ fontSize: "23px" }}
                                    value={
                                        status === INV_STATUS.PUBLISHED
                                            ? _.sumBy(inventoryRes?.operation_items, "difference_cost")
                                            : _.isNaN(totalCost)
                                              ? 0
                                              : totalCost
                                    }
                                />
                            </h4>
                        </div>
                    </div>

                    <div className="inv-step-container">
                        <div style={{ width: "200px" }}>
                            {/* <p style={{ marginBottom: 4 }}><TText key="search_action" /></p> */}
                            <InputGroup className="border-eight">
                                <Input
                                    style={{ height: "44px" }}
                                    value={search}
                                    onChange={setSearchValue}
                                    placeholder={t("search")}
                                />
                                <InputGroup.Addon style={{ background: "white" }}>
                                    <CIcon path={iSearch} />
                                </InputGroup.Addon>
                            </InputGroup>
                        </div>
                        <div
                            test-id={"first-step-button"}
                            className={`px-3 border-eight step-item ${step === 0 ? "step-item-selected" : ""}`}
                            onClick={() => (step === 0 ? null : onChange(0))}
                        >
                            <div className="step-number">1</div>
                            <div className="step-info">
                                <div className="d-flex ">
                                    <div className="step-title">
                                        <TText tkey="inventory_check_step_1" />
                                    </div>
                                    <div className="step-title">({firstStepProductsCount})</div>
                                </div>

                                <div className="step-desc" style={{ fontSize: "10px" }}>
                                    <TText tkey="inventory_check_step_1_desc" />
                                </div>
                            </div>
                        </div>
                        <div
                            test-id={"second-step-button"}
                            className={`px-3 border-eight step-item ${step === 1 ? "step-item-selected" : ""}`}
                            onClick={() => (step === 1 || storageID === "" ? null : onChange(1))}
                        >
                            <div className="step-number">2</div>
                            <div className="step-info ">
                                <div className="d-flex">
                                    <div className="step-title">
                                        <TText tkey="inventory_check_step_2" />
                                    </div>
                                    <div className="step-title">({secondStepProductsCount})</div>
                                </div>
                                <div className="step-desc" style={{ fontSize: "10px" }}>
                                    <TText tkey="inventory_check_step_2_desc" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <div
                className={"overflow-auto"}
                style={{
                    position: "relative",
                    padding: 10,
                    minHeight: isFullScreen ? "90%" : "70%",
                    maxWidth: isFullScreen ? "100vw" : `calc(100vw - ${isSidebarExpanded ? "184px" : "56px"})`,
                }}
            >
                {isProcessing && <Loader backdrop size="md" vertical style={{ zIndex: 999 }} />}

                {((isAtFirstStep && Object.keys(declared)?.length === 0) ||
                    (!isAtFirstStep && Object.keys(nondeclared)?.length === 0)) && (
                    <Message
                        style={{ height: "2rem" }}
                        showIcon={false}
                        type="warning"
                        children={
                            <div className="d-flex align-items-center" style={{ marginTop: "-14px" }}>
                                {" "}
                                <CIcon path={iAlert} style={{ color: Colors.AmberYellow, marginRight: "8px" }} />{" "}
                                <span test-id={"no-data"}>
                                    <TText tkey="no_data" />
                                </span>
                            </div>
                        }
                    />
                )}
                {isAtFirstStep && firstStepProductsCount ? (
                    <SheetTableStateModified
                        data={dataToggle(declared)}
                        columns={firstStepColumns.columns}
                        onChange={onChangeMainStep}
                        deleteContextMenuAction={deleteInvItem}
                        readOnly={status === INV_STATUS.PUBLISHED}
                        displayInputError={shouldDisplayInputError}
                        fullScreen={isFullScreen}
                    />
                ) : null}
                {!isAtFirstStep && secondStepProductsCount ? (
                    <SheetTableStateModified
                        data={dataToggle(nondeclared)}
                        columns={secondStepColumns.columns}
                        onChange={onChangeSecondStep}
                        deleteContextMenuAction={deleteInvItem}
                        readOnly={status === INV_STATUS.PUBLISHED}
                        displayInputError={shouldDisplayInputError}
                        fullScreen={isFullScreen}
                    />
                ) : null}
            </div>
            <div
                className={"bg-white"}
                style={{
                    padding: 10,
                    borderRadius: 6,
                    position: "sticky",
                    left: 0,
                    right: 0,
                    marginTop: 15,
                    bottom: -15,
                    zIndex: 9,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 10,
                    }}
                >
                    {/*<Button*/}
                    {/*    disabled={status === INV_STATUS.NORMAL || isProcessing}*/}
                    {/*    onClick={() => dispatch(setOpenProductSelector(!isOpenProductSelector))}*/}
                    {/*    appearance="ghost"*/}
                    {/*    size="lg"*/}
                    {/*>*/}
                    {/*    <TText key="add" />*/}
                    {/*</Button>*/}
                    <InventoryProductSelector
                        disabledItems={productSelectorDisabledItems}
                        storageID={storageID}
                        applyItems={onProductSelect}
                        disabled={status === INV_STATUS.PUBLISHED || isProcessing}
                    />
                    <Button
                        test-id={"full-screen-button"}
                        style={{ alignItems: "center", display: "flex" }}
                        onClick={() => toggleFullScreen()}
                        size="lg"
                        appearance="primary"
                    >
                        <CIcon
                            path={isFullScreen ? iFullScreenExit : iFullScreen}
                            style={{ marginRight: 10, fillRule: "evenodd" }}
                        />
                        {isFullScreen ? t("exit_full_screen") : t("enter_full_screen")}
                    </Button>
                </div>
                <ButtonGroup size="lg">
                    <Button
                        onClick={onPrevious}
                        style={{ marginRight: 10 }}
                        appearance="ghost"
                        disabled={isAtFirstStep}
                    >
                        <TText tkey="go_back" />
                    </Button>
                    <Button
                        onClick={onNext}
                        style={{ width: 100, marginRight: 10 }}
                        appearance="ghost"
                        disabled={!isAtFirstStep || storageID === ""}
                    >
                        <TText tkey="next" />
                    </Button>
                    {!_.isNil(inventoryID) && status === INV_STATUS.DRAFT && (
                        <>
                            <Button
                                onClick={() => updateServerInventoryData()}
                                loading={isProcessing}
                                disabled={isProcessing || isInventorization}
                                style={{ marginRight: 10 }}
                                appearance="primary"
                            >
                                <TText tkey="save" />
                            </Button>
                            {!isAtFirstStep && (
                                <Button
                                    test-id={"run-inventory-check-button"}
                                    onClick={submitInvertization}
                                    loading={isInventorization}
                                    disabled={isProcessing || isInventorization}
                                    appearance="primary"
                                    color="green"
                                >
                                    <TText tkey="run_inventory_check" />
                                </Button>
                            )}
                        </>
                    )}
                </ButtonGroup>
            </div>
            <IngdContainsDrawer {...ingrdContainsDrawerState} />
            <TransfersDrawer {...transfersDrawerState} />
            <JsonViewState
                value={{
                    inventoryID,
                    inventoryItems,
                    inventoryStatus,
                    hasNewlyItemsAdded,
                    hasChangesMade,
                    fromCreate: isFromCreate,
                    categoriesArr,
                    search,
                    isNavBlocking,
                    comment,
                    isAtFirstStep,
                    divided: {
                        declared,
                        nondeclared,
                    },
                    lastChangeInventoryItems,
                    status,
                    periodType,
                    storageID,
                    date,
                    step,
                    isProcessing,
                    inventoryRes,
                }}
            />
            <CProductsDrawer />
        </div>
    );
};

export default InventoryCheckEditorPage;

interface IInventoryCommentProps {
    comment: string;
    setComment: (value: string) => void;
    status: number | null;
}

const InventoryComment: FCC<IInventoryCommentProps> = ({ comment, setComment, status }) => {
    return (
        <div style={{ height: "75px" }}>
            {status === INV_STATUS.DRAFT ? (
                <div>
                    <p test-id="desc-input-label" style={{ marginBottom: 4 }}>
                        <TText tkey="description" />
                    </p>
                    <Input
                        test-id={"desc-input"}
                        disabled={status === INV_STATUS.PUBLISHED}
                        value={comment ?? ""}
                        onChange={setComment}
                        placeholder={t("description")}
                        style={{ width: "165px" }}
                    />
                </div>
            ) : (
                comment?.length !== 0 &&
                !_.isNil(comment) && (
                    <div>
                        <p test-id="desc-input-label" style={{ marginBottom: 4 }}>
                            <TText tkey="description" />
                        </p>
                        <p>{comment ?? ""}</p>
                    </div>
                )
            )}
        </div>
    );
};
