import { iAlert } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import CFormGroup from "components/Form/CFormGroup";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React, { useEffect, useState } from "react";
import CreateCategoryModal from "../categories/CreateCategoryModal";
import { CheckPicker, Message, toaster } from "rsuite";
import _ from "lodash";
import { useData } from "hooks/useData";
import JsonView from "components/JsonView";
import { CategoryTypeEnum } from "config/constants";
import { useCForm } from "hooks/useCForm";
import { getVenuesList } from "lib/utils";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import MenuCategorySelectFormGroup from "components/Form/CategorySelectFormGroup/MenuCategorySelectFormGroup";
import CForm from "components/Form/CForm/CForm";
import RemoteSelectFormGroup from "components/Form/RemoteSelectFormGroup";
import SelectFormGroup from "components/Form/SelectFormGroup";
import SubmitButtonGroup from "components/Form/SubmitButtonGroup";

type BULK_TYPES = "ALL_PRODUCTS" | "INGREDIENTS" | "PREPARATION";

interface IProps extends ICDrawerProps {
    onSubmit?: any;
    type?: BULK_TYPES;
}

const arrForRefine = ["category_id", "station_id", "accounting_category_id", "emenu_category_id"];

const fixValueForClean = (data: any, key: string) => {
    if (data[key] === 0) {
        return (data[key] = null);
    }
    if (data[key] === null) {
        data[key] = undefined;
    }
};
const ProductBulkUpdateDrawer: FCC<IProps> = props => {
    const auth = useAuth();
    const taxes = useData<IStorage[]>("finance/taxes", {}, auth.hasAbility(["tax_view"]));
    const [isDisabled, setIsDisabled] = useState(false);

    const form = useCForm({
        url: "product/bulk",
        model: false,
        isModal: false,
        redirectUrl: false,

        afterSubmit: () => {
            props.onSubmit();
            toaster.push(
                <Message type="success" showIcon closable>
                    <TText tkey="success" />
                </Message>,
            );

            form.form.reset();
        },
    });
    const createCategoryModalHook = useDrawer();
    // const venueList: any = localStorage.getItem("venueList");
    const venueList = getVenuesList() || [];

    const content = form.form.watch();
    useEffect(() => {
        form.set("prepareForSubmit", (data: any) => {
            data.taxes = data.taxes?.[0] === null ? undefined : data.taxes;
            arrForRefine.forEach(key => {
                fixValueForClean(data, key);
            });

            return {
                ids: props.data,
                data,
            };
        });

        return () => form.form.reset();
        // eslint-disable-next-line
    }, [props.data]);

    useEffect(() => {
        setIsDisabled(!_.map(_.omitBy(form.form.getValues(), _.isNil)).length);
        // eslint-disable-next-line
    }, [content]);

    const hasMoreThanOneVenue = venueList.length > 1;

    return (
        <CDrawer title={t("bulk_update_drawer_title")} testId="quick-product-drawer" {...props}>
            <CForm form={form.form} onSave={form.onSave} noShadow>
                <div className="p-3">
                    {props.type === "PREPARATION" && (
                        <SelectFormGroup
                            name={"inventory_behavior"}
                            label={t("write_off_method")}
                            data={[
                                { id: 0, name: t("write_off_ingredients") },
                                { id: 1, name: t("write_off_ready_item") },
                            ]}
                        />
                    )}

                    {(props.type === "INGREDIENTS" || props.type === "PREPARATION") && (
                        <>
                            <MenuCategorySelectFormGroup
                                no_cat_option
                                no_cat_btn
                                types={[CategoryTypeEnum.INGREDIENT]}
                                modal={createCategoryModalHook}
                                revalidate={createCategoryModalHook.dataId}
                            />

                            <MenuCategorySelectFormGroup
                                type={CategoryTypeEnum.ACCOUNTING}
                                label={t("accounting_category")}
                                name={"accounting_category_id"}
                                types={[CategoryTypeEnum.ACCOUNTING]}
                            />
                        </>
                    )}

                    {props.type === "ALL_PRODUCTS" && (
                        <>
                            {hasMoreThanOneVenue ? (
                                <RemoteSelectFormGroup
                                    searchLocal={true}
                                    label={t("venue")}
                                    remote={{
                                        url: "venues",
                                    }}
                                    name={"venue_id"}
                                    compProps={{ cleanable: true }}
                                />
                            ) : null}

                            <SelectFormGroup
                                style={{ color: hasMoreThanOneVenue ? "red" : "" }}
                                name={"hidden"}
                                label={t("status")}
                                data={[
                                    { id: 0, name: t("active") },
                                    { id: 1, name: t("inactive") },
                                ]}
                            />
                            {hasMoreThanOneVenue && (
                                <div className="tw-ml-[182px] tw-text-sm tw-flex tw-mb-6 tw-items-center tw-gap-2 tw-text-red-500">
                                    <CIcon path={iAlert} />
                                    <span>
                                        <TText tkey="select_apply_venue" />
                                    </span>
                                </div>
                            )}

                            <MenuCategorySelectFormGroup
                                label={t("category")}
                                no_cat_option
                                no_cat_btn
                                modal={createCategoryModalHook}
                                revalidate={createCategoryModalHook.dataId}
                            />

                            <RemoteSelectFormGroup
                                searchLocal={true}
                                remote={{
                                    url: "station",
                                    refineList: d => {
                                        const newList: any[] = d || [];
                                        newList.push({ id: 0, name: t("without_station"), children: [] });
                                        return newList;
                                    },
                                }}
                                name={"station_id"}
                                compProps={{
                                    cleanable: true,
                                    renderExtraFooter: () => {
                                        return (
                                            <div
                                                className="rs-picker-select-menu-item text-center"
                                                onClick={() => {
                                                    form.form.setValue("station_id", 0);
                                                }}
                                            >
                                                <TText tkey="clear_station" />
                                                {/*{t("create_new_by_name", { name: t("category") })}*/}
                                            </div>
                                        );
                                    },
                                }}
                            />

                            <SelectFormGroup
                                name={"giftable"}
                                label={t("giftable")}
                                data={[
                                    { id: 0, name: t("non") },
                                    { id: 1, name: t("yes") },
                                ]}
                            />
                            <SelectFormGroup
                                name={"meta.hasPortion"}
                                label={t("portion")}
                                data={[
                                    { id: 0, name: t("non") },
                                    { id: 1, name: t("yes") },
                                ]}
                            />
                            <SelectFormGroup
                                name={"discountable"}
                                label={t("allow_discount")}
                                data={[
                                    { id: 1, name: t("yes") },
                                    { id: 0, name: t("non") },
                                ]}
                            />
                            <SelectFormGroup
                                name={"ignore_service_charge"}
                                label={t("bulk_service_charge_calculate")}
                                data={[
                                    { id: 0, name: t("yes") },
                                    { id: 1, name: t("non") },
                                ]}
                            />
                            <SelectFormGroup
                                name={"emenu_hidden"}
                                label={t("show_on_emenu")}
                                data={[
                                    { id: 0, name: t("show") },
                                    { id: 1, name: t("hide") },
                                ]}
                            />
                            <SelectFormGroup
                                name={"inventory_behavior"}
                                label={t("write_off_method")}
                                data={[
                                    { id: 0, name: t("write_off_ingredients") },
                                    { id: 1, name: t("write_off_ready_item") },
                                ]}
                            />

                            <MenuCategorySelectFormGroup
                                name="emenu_category_id"
                                label={t("emenu") + " " + t("category").toLowerCase()}
                                types={[CategoryTypeEnum.PRODUCT, CategoryTypeEnum.EMENU]}
                                refineList={(d: ICategory[]) => {
                                    const newList: any[] = d || [];
                                    newList.push({ id: 0, name: t("without_cat") });
                                    return newList;
                                }}
                                compProps={{
                                    placement: "top",
                                    cleanable: true,
                                    renderExtraFooter: () => {
                                        return (
                                            <div
                                                className="rs-picker-select-menu-item text-center"
                                                onClick={e => {
                                                    form.form.setValue("emenu_category_id", 0);
                                                }}
                                            >
                                                <TText tkey="clear_emenu_cat" />
                                            </div>
                                        );
                                    },
                                }}
                            />

                            <CFormGroup name="taxes">
                                <CheckPicker
                                    placement="top"
                                    data={_.map(taxes?.data, (t: any) => ({
                                        value: t?.id,
                                        label: `${t?.name} (${t?.rate}%)`,
                                    }))}
                                    defaultValue={[null]}
                                    renderExtraFooter={() => {
                                        return (
                                            <div
                                                className="rs-picker-select-menu-item text-center"
                                                onClick={e => {
                                                    form.form.setValue("taxes", []);
                                                }}
                                            >
                                                <TText tkey="clear_taxes" />
                                            </div>
                                        );
                                    }}
                                    value={_.map(form.form.getValues("taxes"), (t: any) => t?.id)}
                                    onChange={tax => {
                                        if (form.form.getValues("taxes")?.[0] === null) {
                                            return;
                                        }

                                        form.form.setValue(
                                            "taxes",
                                            _.intersectionWith(taxes?.data as any, tax, (a: any, b) => a.id === b),
                                        );
                                    }}
                                    onClean={() => {
                                        form.form.setValue("taxes", [null]);
                                    }}
                                    placeholder={t("select", { name: t("tax") })}
                                    style={{
                                        width: 300,
                                    }}
                                />
                            </CFormGroup>

                            <MenuCategorySelectFormGroup
                                type={CategoryTypeEnum.ACCOUNTING}
                                label={t("accounting_category")}
                                name={"accounting_category_id"}
                                types={[CategoryTypeEnum.ACCOUNTING]}
                                refineList={(d: ICategory[]) => {
                                    const newList: any[] = d || [];
                                    newList.push({ id: 0, name: t("without_cat") });
                                    return newList;
                                }}
                                compProps={{
                                    placement: "top",
                                    cleanable: true,

                                    placeholder: t("accounting_category"),
                                    renderExtraFooter: () => {
                                        return (
                                            <div
                                                className="rs-picker-select-menu-item text-center"
                                                onClick={e => {
                                                    form.form.setValue("accounting_category_id", 0);
                                                }}
                                            >
                                                <TText tkey="clear_acc_cat" />
                                            </div>
                                        );
                                    },
                                }}
                            />
                        </>
                    )}
                    <div style={{ padding: 20, float: "right" }}>
                        <SubmitButtonGroup isLoading={form.isLoading} onSubmit={form.onSave} disabled={isDisabled} />
                    </div>
                </div>
                <JsonView />
            </CForm>
            <CreateCategoryModal
                type={props?.type === "INGREDIENTS" ? "INGREDIENT" : null}
                with={props?.type === "INGREDIENTS" ? ["ancestors"] : null}
                filters={props?.type === "INGREDIENTS" ? { type: ["type", "INGREDIENT"] } : null}
                {...createCategoryModalHook}
                mainForm={form.form}
            />
        </CDrawer>
    );
};

export default ProductBulkUpdateDrawer;
