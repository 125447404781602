import { iExchange, iMinus, iPlus } from "assets/icons/Icons";
import { ICDrawerProps } from "components/Drawers/CDrawer";
import TextAreaFormGroup from "components/Form/TextAreaFormGroup";
import TextPostfixFormGroup from "components/Form/TextPostfixFormGroup";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { BalanceWithAmount } from "components/mini/BalanceWithAmount";
import { Constants, FinanceAccountDefaultFilters } from "config/constants";
import { useCForm } from "hooks/useCForm";
import { useData } from "hooks/useData";
import { refineVenueList } from "lib/utils";
import _ from "lodash";
import { dummyTransaction, TransactionModel } from "models/TransactionModel";
import { useEffect, useState } from "react";
import { Radio, RadioGroup, RadioGroupProps } from "rsuite";
import CIcon from "components/CIcon";
import CFormModal from "components/Drawers/CFormModal";
import FinanceCategorySelectFormGroup from "components/Form/CategorySelectFormGroup/FinanceCategorySelectFormGroup";
import DatePickerFormGroup from "components/Form/DatePickerFormGroup";
import SelectFormGroup from "components/Form/SelectFormGroup";
import { Colors } from "config/colors";
import { format } from "date-fns";

interface IProps extends ICDrawerProps {
    revalidate: () => void;
}

const typeIcon: Record<string, string> = {
    IN: iPlus,
    OUT: iMinus,
    TRANSFER: iExchange,
};
const radioProps: RadioGroupProps = {
    defaultValue: "OUT",
    inline: true,
    appearance: "picker",
    style: {
        width: 350,
        justifyContent: "space-between",
    },
};

/**
 * Category Ids for staff
 * @param 5 Əməkhaqqı
 * @param 30 Bonus
 */
const staffCategoryIds = [5, 30];
const CreateTransactionModal: FCC<IProps> = props => {
    const accounts = useData<IAccount[]>("finance/balance", {
        params: {
            limit: 999,
            filters: _.values(FinanceAccountDefaultFilters),
        },
    });
    const accountsVenues = useData<WithVenue<IAccount>[]>("finance/balance", {
        params: {
            limit: 999,
            filters: _.values(FinanceAccountDefaultFilters),
        },
        hasAllVenues: true,
        with: ["venue"],
    });
    const staff = useData<IFinanceCategory[]>("user", {
        params: {
            limit: 999,
            filters: { hiddens: ["hide", 0] },
        },
    });

    const tTypes = Constants.transactionTypes;

    const form = useCForm({
        url: "finance/transaction",
        model: TransactionModel(),
        id: props.dataId || undefined,
        redirectUrl: false,
        clearForm: false,
        dummyData: () => dummyTransaction(),
        prepareForSubmit: (d: any) => {
            return {
                ...(d.type !== "TRANSFER" ? _.omit(d, ["from_balance_id", "parent_id"]) : _.omit(d, ["parent_id"])),
                operated_at: format(new Date(d.operated_at), "yyyy-MM-dd HH:mm:ss"),
            };
        },
        afterSubmit: () => {
            accounts.revalidate();
            accountsVenues.revalidate();
            staff.revalidate();
        },
        hasAllVenues: true,
    });

    const type = form.form.watch("type");
    const category = form.form.watch("category_id");
    const parentCategory_id = form.form.watch("parent_id");
    const from_balance_id = form.form.watch("from_balance_id");
    const balance_id = form.form.watch("balance_id");
    const [active, setActive] = useState<any>();

    useEffect(() => {
        if (props.data?.cid) {
            form.set("methodOnSubmit", "put");
            form.set("url", `finance/cash-shifts/${props.data?.id}/add-transaction`);
        }
    }, [props.data?.id]);
    useEffect(() => {
        if (type) {
            let timestampObj;
            if (props.data?.opened_at) {
                timestampObj = new Date(props.data?.opened_at);
                timestampObj.setMinutes(timestampObj.getMinutes() + 1);
            }
            form.form.reset(dummyTransaction(type, timestampObj));
        }

        setActive(type);
    }, [type]);

    useEffect(() => {
        if (!staffCategoryIds.includes(category)) {
            form.form.setValue("staff_id", null);
        }
    }, [category]);

    const accountProps = {
        data: accounts.data,
        compProps: {
            disabledItemValues: type === "TRANSFER" ? [from_balance_id, balance_id] : [],
            renderMenuItem: (val: any, item: any) => <BalanceWithAmount item={item} />,
            renderValue: (val: any, item: any) => <BalanceWithAmount item={item} />,
        },
    };

    const toAccountProps = {
        data: refineVenueList(accountsVenues.data ?? []),
        compProps: {
            disabledItemValues: type === "TRANSFER" ? [from_balance_id, balance_id] : [],
            renderMenuItem: (val: any, item: any) => <BalanceWithAmount item={item} />,
            renderValue: (val: any, item: any) => <BalanceWithAmount item={item} />,
            groupBy: "venue_name",
        },
    };

    return (
        <CFormModal
            {...props}
            size="xs"
            testId="create-transaction-modal"
            form={form}
            formProps={{ layout: "horizontal" }}
            title={t("create_by_name", { name: _.capitalize(t("transaction")) })}
        >
            <div className="d-flex flex-column align-items-center">
                <div className="w-100">
                    <label>
                        <TText tkey="allow_transactions" />
                    </label>
                    <RadioGroup style={{ marginTop: "13px" }} name="type" {...radioProps}>
                        {Object.keys(tTypes).map(type => (
                            <Radio
                                onChange={() => form.form.setValue("type", type)}
                                style={{
                                    width: 119.2,
                                    background: `${active === type ? Colors.PaleSkyBlue : ""}`,
                                    textAlign: "center",
                                    borderRadius: "7px",
                                }}
                                test-id={`transaction-type-${type}`}
                                value={type}
                                key={type}
                            >
                                {(tTypes as any)[type]}
                            </Radio>
                        ))}
                    </RadioGroup>
                </div>

                <TextPostfixFormGroup
                    name="amount"
                    type="number"
                    placeholder={t("amount")}
                    hideLabel={true}
                    // focus={true}
                    preFix={<CIcon path={typeIcon[type]} />}
                    style={{ marginTop: "6px", marginBottom: "18px", height: "40px", width: "100%" }}
                    controlWrapperProps={{ style: { width: 350 } }}
                    compProps={{ addonClass: `btn-${type}`, decimalLimit: 2, "test-id": "amount-input" }}
                />

                <SelectFormGroup
                    name="from_balance_id"
                    width={350}
                    style={{ width: "100%" }}
                    hideLabel={true}
                    label=""
                    testId="transaction-from-balance"
                    className={`${type !== "TRANSFER" && "d-none"}`}
                    {...accountProps}
                />

                {type === "TRANSFER" ? (
                    <SelectFormGroup
                        testId="transaction-balance"
                        hideLabel={true}
                        width={350}
                        style={{ width: "100%" }}
                        label=""
                        name="balance_id"
                        {...toAccountProps}
                    />
                ) : (
                    <SelectFormGroup
                        testId="transaction-balance"
                        hideLabel={true}
                        width={350}
                        style={{ width: "100%" }}
                        label=""
                        name="balance_id"
                        {...accountProps}
                    />
                )}

                <FinanceCategorySelectFormGroup
                    name="category_id"
                    hideLabel={true}
                    width={350}
                    style={{ width: "100%" }}
                    testId="transaction-category"
                    className={`${type === "TRANSFER" && "d-none"}`}
                    customFilter={(c: IFinanceCategory) => (c.type === type || c.type === "BOTH") && !c.hide}
                    compProps={{
                        onSelect: (val: any, item: IFinanceCategory) => {
                            form.form.setValue("parent_id", item.parent_id);
                        },
                    }}
                />

                <SelectFormGroup
                    hideLabel={true}
                    style={{ width: "100%" }}
                    name="staff_id"
                    test-id="transaction-staff"
                    compProps={{ labelKey: "username" }}
                    className={`${
                        !staffCategoryIds.includes(category) &&
                        !staffCategoryIds.includes(parentCategory_id) &&
                        "d-none"
                    }`}
                    data={staff.data?.filter((d: any) => (d.owner || !d.hide) && d.status === true)}
                    width={350}
                />
                <div>
                    <DatePickerFormGroup
                        time
                        name="operated_at"
                        width={350}
                        compProps={{
                            cleanable: false,
                            className: "text-left",
                            "test-id": "transaction-operated-at",
                            shouldDisableDate: d => {
                                const operatedAt = new Date(props.data?.opened_at);
                                const closedAt = new Date(props.data?.closed_at);
                                operatedAt.setHours(0, 0, 0, 0);
                                closedAt.setHours(23, 59, 59, 999);

                                return (
                                    (!!d && (d.getTime() > closedAt.getTime() || d.getTime() < operatedAt.getTime())) ||
                                    (!!d && d.getTime() > Date.now())
                                );
                            },
                            shouldDisableHour: (h, date) => {
                                const operatedAt = new Date(props.data?.opened_at);
                                const closedAt = new Date(props.data?.closed_at);
                                const currentDateTime = new Date(date);
                                currentDateTime.setHours(h);
                                return currentDateTime <= operatedAt || currentDateTime >= closedAt;
                            },

                            shouldDisableMinute: (m, date) => {
                                const operatedAt = new Date(props.data?.opened_at);
                                const closedAt = new Date(props.data?.closed_at);
                                const currentDateTime = new Date(date);
                                currentDateTime.setMinutes(m);
                                return currentDateTime <= operatedAt || currentDateTime >= closedAt;
                            },
                        }}
                    />
                </div>

                <TextAreaFormGroup
                    compProps={{ style: { width: 350, height: 65 }, "test-id": "transaction-description" }}
                    // type="textarea"
                    className="mb-3"
                    name="description"
                />
            </div>
        </CFormModal>
    );
};

export default CreateTransactionModal;
