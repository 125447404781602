import { t } from "lib/i18n";

import SelectFormGroup from "components/Form/SelectFormGroup";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import { WithInfoIcon } from "components/mini/WithInfoIcon";
import { useAppSelector } from "hooks/useRedux";
import { StatusType } from "config/constants";

const ReceiptSettingsTab: FCC = () => {
    const selectableLanguages = useAppSelector(state => state.lang).list;

    return (
        <div className="p-3">
            <SelectFormGroup
                name="receipt_language.value"
                data={selectableLanguages
                    .filter(sl => sl.active)
                    .map(sl => ({
                        name: sl.title,
                        id: sl.name,
                    }))}
            />
            <ToggleFormGroup name="print_double.value" />
            <WithInfoIcon text={t("daily_id_info")} show={true} className="tw-max-w-fit">
                <SelectFormGroup
                    label={t("daily_id")}
                    name="generate_receipt_number.value"
                    data={[
                        { name: t("inactive"), id: StatusType.INACTIVE },
                        { name: t("active"), id: StatusType.ACTIVE },
                    ]}
                />
            </WithInfoIcon>
        </div>
    );
};

export default ReceiptSettingsTab;
