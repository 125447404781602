import { FC, ReactNode } from "react";
import { alignments, GridItemAlignment } from "./grid-item-utils";
import { cn } from "lib/utils";

interface GridItemPlainProps {
    value?: ReactNode;
    postfix?: string;
    align?: GridItemAlignment;
    className?: string;
    testId?: string;
}

export const GridItemPlain: FC<GridItemPlainProps> = ({ value, postfix, className, align = "left", testId }) => {
    return (
        <div
            test-id={testId}
            id={testId}
            className={cn(
                `tw-flex tw-gap-1 tw-min-w-20 tw-rounded tw-p-2 tw-border tw-border-solid tw-border-slate-200 tw-bg-slate-300/10 ${alignments[align]}`,
                "tw-min-w-min",
                className,
            )}
        >
            <div className="tw-self-center">{value}</div>
            {postfix && (
                <div className="tw-text-xs tw-text-gray-400 tw-font-serif tw-italic tw-flex tw-justify-start">
                    {postfix}
                </div>
            )}
        </div>
    );
};
