import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { QuickLinks } from "components/Header/QuickLinks";
import { useTitle } from "components/Header/Title";
import { CategoryTypeEnum } from "config/constants";
import React, { useState } from "react";
import CreateButton from "../../../components/DataTable/CreateButton";
import { RowDataType } from "rsuite-table/src/@types/common";

const IngredientCategoriesPage: FCC = props => {
    const [pageType, setPageType] = useState(CategoryTypeEnum.INGREDIENT);

    const dp = useDataProvider({
        url: "category",
        useUrl: true,
        filters: { type: ["type", "INGREDIENT"] },
        params: { tree: -1 },
        sort: ["created_at", "1"],
    });

    const cm = useColumns<any>("ingredient-category", [
        /*{
            key: 'image',
            flexGrow: 0,
            render: (d: ICategory) => {
                return d.image ? <img src={d.image?.thumb} alt={d.name}/> : null;
            },
        },*/
        {
            width: 40,
            key: "id",
            title: "ID",
            hide: true,
            flexGrow: 0.2,
        },
        { key: "name", align: "left", testId: "category-list-name" },
        optionsColumn({
            dp,
            edit: "ingredientCategories/edit/:id",
            delete: "category/:id",
            canEdit: "category_edit",
            canDelete: "category_delete",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("category"), dp.total)}

            <QuickLinks
                links={[
                    {
                        link: '/menu/categories?{"page":1,"sort":["created_at","1"],"limit":500,"filters":{"type":["type","PRODUCT"]},"params":{"tree":-1,"productCount":"1"}}',
                        label: t("menu_categories"),
                        active: pageType === CategoryTypeEnum.PRODUCT,
                    },
                    {
                        link: '/menu/ingredientCategories?{"page":1,"sort":["created_at","1"],"limit":500,"filters":{"type":["type","INGREDIENT"]},"params":{"tree":-1,"productCount":"1"}}',
                        label: t("ingredient_categories"),
                        active: pageType === CategoryTypeEnum.INGREDIENT,
                    },
                    {
                        link: '/menu/accounting-categories?{"page":1,"sort":["created_at","1"],"limit":500,"filters":{"type":["type","ACCOUNTING"]},"params":{"tree":-1,"productCount":"1"}}',
                        label: t("accounting_categories"),
                        active: pageType === CategoryTypeEnum.ACCOUNTING,
                        testId: "accounting-categories",
                    },
                ]}
            />

            <HeaderRight cm={cm} print reload dp={dp} />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1">
                    <SearchFilter fields="name" />
                </div>
                <CreateButton to={"/menu/ingredientCategories/create"} canCreate={"category_create"} />
            </FiltersContainer>
            <div className="categories_db-container">
                <DataTable
                    dp={dp}
                    columns={cm.columns}
                    tableProps={{
                        isTree: true,
                        renderTreeToggle: (icon: React.ReactNode, rowData?: RowDataType) => {
                            return rowData?.children?.length && rowData?.children?.length > 0 ? (
                                <div test-id="toggler-button">{icon}</div>
                            ) : null;
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default IngredientCategoriesPage;
