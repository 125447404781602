const IconGoodsComponent = ({ color, size, style }: { color?: string; size: number; style?: any }) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            height={size}
            width={size}
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 45 45"
            style={style}
            fill={color}
        >
            <path
                d="M31.6,28.7c0-3.2-0.7-5.1-1-5.8c0.2-0.7,1-3,1-4.6c0-1.1-0.2-2-0.8-2.7c-0.5-0.6-1.1-1.1-1.6-1.5c-0.9-0.6-1.6-1.2-1.7-2.4
	h0.7c0.3,0,0.5-0.2,0.5-0.5c0-0.3-0.2-0.5-0.5-0.5H28V8.1c0-0.3-0.2-0.5-0.5-0.5h-4.9c-0.3,0-0.5,0.2-0.5,0.5v2.6h-0.2
	c-0.3,0-0.5,0.2-0.5,0.5c0,0.3,0.2,0.5,0.5,0.5h0.7c0,1.2-0.8,1.8-1.7,2.4c-0.6,0.4-1.2,0.8-1.6,1.5c-0.5,0.7-0.8,1.6-0.8,2.7
	c0,1.6,0.7,3.9,1,4.6c-0.3,0.6-1,2.6-1,5.8c0,1.7,0,7.4,0,9.9l0,1.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0.1,0,0.1
	c0.1,0.7,0.4,1.3,1.1,1.9c0.5,0.4,1.1,0.6,1.1,0.6c0,0,0.1,0,0.1,0c0.1,0,0.1,0,0.2,0c0.4,0,0.9-0.2,1.4-0.7
	c0.1-0.1,0.2-0.2,0.2-0.3l1,0.8c0.1,0.1,0.2,0.1,0.3,0.1l2.3,0c0.1,0,0.3-0.1,0.4-0.1l0.8-0.8c0.1,0.1,0.2,0.2,0.3,0.3
	c0.6,0.5,1.2,0.7,1.6,0.6c0,0,0.1,0,0.1,0c0,0,0.6-0.2,1.1-0.6c0.7-0.5,1.1-1.2,1.1-1.9c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0l0-0.1l0-1.1C31.6,36.2,31.6,30.4,31.6,28.7z M19.5,29.6h11.1c0,1.9,0,5.5,0,7.9H19.5C19.5,35.1,19.5,31.5,19.5,29.6z
	 M23.1,8.6h3.8v2.1h-3.8V8.6z M21.4,15.1c1-0.7,2.1-1.5,2.1-3.3h2.9c0,1.8,1.2,2.6,2.1,3.3c1.1,0.8,2,1.4,1.9,3.3c0,0,0,0,0,0
	c0,1.6-1,4.4-1,4.5c0,0.1,0,0.3,0,0.4c0,0,0.2,0.5,0.5,1.4c0.2,0.8,0.4,2.1,0.5,3.9H19.5c0-1.7,0.3-3.1,0.5-3.9
	c0.2-0.9,0.5-1.4,0.5-1.4c0.1-0.1,0.1-0.3,0-0.4c0,0-1-2.8-1-4.5c0,0,0,0,0,0C19.4,16.5,20.3,15.8,21.4,15.1z M30.5,39.7
	c0,0.9-1.1,1.5-1.4,1.6c-0.1,0-0.4-0.1-0.8-0.4c-0.3-0.3-0.6-0.5-0.6-0.5c-0.1-0.1-0.2-0.2-0.4-0.2c-0.1,0-0.3,0-0.4,0.1l-1,1
	l-1.9,0l-1.3-1.1c-0.1-0.1-0.3-0.1-0.4-0.1c-0.1,0-0.3,0.1-0.4,0.2c0,0-0.2,0.3-0.5,0.6c-0.3,0.3-0.5,0.4-0.6,0.4
	c-0.3-0.1-1.4-0.7-1.4-1.6l0-0.1c0,0,0,0,0,0c0-0.2,0-0.6,0-1.1c0,0,0,0,0-0.1h11.1c0,0,0,0,0,0.1C30.5,39.1,30.5,39.5,30.5,39.7
	C30.5,39.7,30.5,39.7,30.5,39.7L30.5,39.7z"
            />
        </svg>
    );
};

export default IconGoodsComponent;
