import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";

import _ from "lodash";
import { exportFile } from "lib/export-table";
import Money from "components/mini/Money";
import { AccountPageType, accountQuickLinks } from "./utils";
import { useData } from "hooks/useData";
import { NavLink } from "react-router-dom";
import CheckBoxFilter from "components/DataTable/Filters/CheckBoxFilter";

const SupplierAccountsPage: FCC = () => {
    const url = "supplier";
    const dp = useDataProvider({
        url,
        useUrl: true,
        with: ["balance"],
    });

    const total = useData("suppliers/total", {
        filters: _.values(dp.filters.all),
    });

    const cm = useColumns<ISupplier>("supplier-account", [
        {
            key: "id",
            title: "ID",
            align: "left",
            testId: "supplier-id",
            flexGrow: 0.25,
        },
        {
            key: "name",
            align: "left",
            // onClick: (d: ISupplier) => sd.setData(d),
            render: (e: ISupplier) => (
                // http://client.clopos.test:8084/alpha/1/finance/supplier-transactions?{%22page%22:1,%22limit%22:50,%22filters%22:{%22supplier_id%22:[%22supplier_id%22,%22=%22,[27]]}}
                <NavLink to={{ pathname: `/finance/supplier-transactions`, state: { supplier: e } }}>{e.name}</NavLink>
            ),
            flexGrow: 2,
        },
        {
            key: "tin",
            title: t("tin"),
        },
        {
            key: "balance.amount",
            title: t("balance"),
            type: "money",
            hideData: (d: ISupplier) => (d.balance?.amount ?? 0) < 0,
            getData: (d: ISupplier) => Math.abs(d.balance?.amount ?? 0),
            help: t("you_owe_to_supplier"),
            render: d => <Money colored value={d.balance?.amount} />,
            summary: () => <Money colored value={total?.data?.total ?? 0} />,

            sortField: "balance.amount",
        },
    ]);

    return (
        <div className="h-100">
            {useTitle(t("supplier"), dp.total)}

            {accountQuickLinks(AccountPageType.SUPPLIER)}

            <HeaderRight
                dp={dp}
                cm={cm}
                reload
                print
                export={() => exportFile(dp, cm.columns, t("supplier_accounts"))}
            />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="name" />
                    <CheckBoxFilter fields="trash" useParams title={t("show_deleted_values")} value="2" />
                </div>
            </FiltersContainer>

            <DataTable
                key={url}
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
        </div>
    );
};

export default SupplierAccountsPage;
