import { ButtonProps, Input } from "rsuite";
import { CFormGroupProps } from "./CFormGroup";

import CInputBtnFormGroup from "./CInputBtnFormGroup";

interface IProps extends CFormGroupProps {
    postFix: string | JSX.Element;
    inputBtn?: ButtonProps;
}

const TextPostfixBtnFormGroup: FCC<IProps> = props => {
    const { postFix, ...newProps } = props;
    newProps.compProps = props.compProps ?? {};
    newProps.compProps.postfix = postFix;

    return <CInputBtnFormGroup {...newProps} />;
};

TextPostfixBtnFormGroup.defaultProps = {
    comp: Input as any,
    // type: "text",
    compProps: {},
};

export default TextPostfixBtnFormGroup;
