import { CBadgeColor } from "core/components/CBadge/CBadge";
import "path";

export const enum CashShiftStatusEnum {
    OPEN = "OPEN",
    ACCEPTED = "ACCEPTED",
    NEEDS_TO_CHECK = "NEEDS_TO_CHECK",
    UNACCEPTED = "UNACCEPTED",
}

export const CashShiftColors: Record<CashShiftStatusEnum, CBadgeColor> = {
    [CashShiftStatusEnum.ACCEPTED]: "green",
    [CashShiftStatusEnum.OPEN]: "orange",
    [CashShiftStatusEnum.NEEDS_TO_CHECK]: "red",
    [CashShiftStatusEnum.UNACCEPTED]: "red",
};

export interface ICashShift {
    id: string;
    venue_id: number;
    cid: string;
    shift_no: number;
    terminal_id: number;
    opened_by_user_id: number;
    closed_by_user_id: number | null;
    initial_amount: number;
    closed_amount: number;
    opening_diff: number;
    diff: number;
    status: CashShiftStatusEnum;
    description: string | null;
    meta: {
        opening_note: string;
    };
    accounting_day_opened_at: string;
    accounting_day_closed_at: string | null;
    opened_at: string;
    closed_at: string | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export interface ICashShiftExtended extends ICashShift {
    terminal: ITerminal;
    opened_by_user: IUser;
    closed_by_user: IUser | null;
    cash_transactions_amount: number;
}
