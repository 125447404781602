export const Colors = {
    "Fire Opal": "#ee6352",
    "Yellow Crayola": "#f6e27f",
    "Royal Purple": "#745296",
    "Baby Blue Eyes": "#a3cef1",
    "Cadet Blue": "#58a4b0",
    "Magenta Crayola": "#ed51a7",
    "Vivid Tangerine": "#f5937f",
    Liberty: "#535e96",
    "Magic Mint": "#a5f2e1",
    Mint: "#58b081",

    LightRed: "#FF5151",
    CarmineRed: "#e74c3c",
    VividRed: "#f44336",
    ChiliRed: "#DC3545",
    CrimsonRed: "#e31e1e",
    BrightRed: "#F04438",
    SoftRed: "#FF6262",
    DeepPurple: "#744989",
    LavenderPurple: "#BB6BD9",
    LightSalmonPink: "#FEE4E2",
    SoftRose: "#FEF3F2",
    PalePink: "#FFF1F3",
    PalePeach: "#FFF4E7",
    SoftPeach: "#FFEDEC",
    LightCream: "#FFF6D9",
    Apricot: "#F4BD8E",
    WhisperGray: "#F7F7F7",
    LightGray: "#F2F4F7",
    LightPeriwinkle: "#EAEDFF",
    SilverGray: "#d9d9d9",
    PaleGray: "#EAECF0",
    PlatinumGray: "#ddd",
    LightSilverGray: "#bfc6d1",
    LightSlateGray: "#d4d9dd",
    WhiteGray: "#ffffffc2",
    CadetGray: "#98A2B3",
    MediumGray: "#999",
    WarmGray: "#a29f9f",
    CoolGray: "#8E8E93",
    MidGray: "#858585",
    CharcoalBlue: "#6f7274",
    SlateSteelGray: "#6C757D",
    SteelGray: "#667085",
    SilverGray2: "#D0D5DD",
    CharcoalGray: "#4d4d4d",
    DarkGray: "#575757",
    SlateGray: "#475467",
    GunmetalBlue: "#344054",
    NavyBlue: "#1F274F",
    VividBlue: "#2F80ED",
    CobaltBlue: "#2E90FA",
    VibrantSkyBlue: "#3498FF",
    BrilliantBlue: "rgb(23, 148, 255)",
    BrightBlue: "#3498DB",
    TurquoiseBlue: "#33a2b7",
    CornflowerBlue: "#7CB5EC",
    SkyBlue: "#9dc8f1",
    BabyBlue: "#F1F8FF",
    AzureBlue: "#F4F8FF",
    LightAzureBlue: "#D7EAFF",
    PaleSkyBlue: "#EDF7FF",
    IceBlue: "#F4F9FF",
    AppleGreen: "#52c41a",
    TropicalGreen: "#12B76A",
    ForestGreen: "#4caf50",
    JungleGreen: "#27ae60",
    EmeraldGreen: "#32D583",
    MintGreen: "#D8FFE8",
    PaleMint: "#ECFDF3",
    PaleMintGreen: "#E9FFF5",
    PaleYellow: "#EBDE85",
    MustardYellow: "#FEC84B",
    SunflowerYellow: "#F2C94C",
    AmberYellow: "#ffb300",
    BrightOrange: "#F79009",
    Tangerine: "#F2994A",
    VividOrange: "#f39c12",
    JetBlack: "#111111",
    White: "#fff",
    MoonlightWhite: "#F9FAFB",
    OffWhite: "#FFFAEB",
    ArcticWhite: "#F6FAFB",
};

export function getTextColorFor(name: keyof typeof Colors): string {
    return getContrastYIQ(Colors[name].substr(1));
    // return (Object.keys(Colors).indexOf(name) % 5) % 2 === 1 ? "#333" : "#fff";
}
function getContrastYIQ(hexcolor: string) {
    hexcolor = hexcolor?.replace("#", "");
    const r = parseInt(hexcolor.substr(0, 2), 16);
    const g = parseInt(hexcolor.substr(2, 2), 16);
    const b = parseInt(hexcolor.substr(4, 2), 16);
    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    return yiq >= 128 ? "black" : "white";
}
