import { Action, configureStore, ThunkAction } from "@reduxjs/toolkit";
import itemManagement from "./features/itemManagement";
import app from "./features/app";
import venueDashboard from "./features/venue-dashboard";
import lang from "./features/lang";
import supplyItems from "./features/supplyItems";
import ui from "./features/ui";
import receipt from "./features/receiptConstructor";
import stationPrint from "./features/stationPrintConstructor";
import webCashier from "./features/webCashier";
import webCashierAdditionals from "./features/webCashierAdditionals";
import packages from "./features/packages";
import venue from "./features/venue";
import remoteConfig from "./features/remoteConfig";
import settings from "./features/settings";
import receiptFilters from "./features/receiptFilters";
import productSelector from "./features/productsSelector/productsSelector";
import trashProductSelector from "./features/productsSelector/trashProductSelector";
import data from "./features/data";
import stockCounts from "./features/stockCountsSelector";
import model from "./features/model/slice";
import { isDev } from "config/main";

export const store = configureStore({
    reducer: {
        app,
        venueDashboard,
        packages,
        itemManagement,
        lang,
        supplyItems,
        receiptFilters,
        ui,
        remoteConfig,
        venue,
        receipt,
        stationPrint,
        webCashier,
        webCashierAdditionals,
        data,
        stockCounts,
        productSelector,
        trashProductSelector,
        model,
        settings,
    },
    devTools: isDev,
});

export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
