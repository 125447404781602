import CIcon from "./CIcon";
import { iSpinner } from "assets/icons/Icons";

interface IProps {
    className?: string;
}

const LoadingAnimation: FCC<IProps> = props => {
    return (
        <div className={props.className ?? "w-100 tw-flex tw-justify-center tw-items-center p-3 "}>
            <CIcon path={iSpinner} spin />
        </div>
    );
};

export default LoadingAnimation;
