import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import { CirclePicker, CirclePickerProps } from "react-color";
import { PlainColor } from "./PlainColor";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

interface IProps extends CFormGroupProps<CirclePickerProps> {
    plaintext?: boolean;
    width?: string;
}

const ColorPicker: FCC<IProps> = props => {
    const { getValues } = useCFormContext();
    return props.plaintext ? (
        <PlainColor
            name={props.name}
            label={props.label || ""}
            className={props.className}
            value={getValues(props.name)}
        />
    ) : (
        <CFormGroup
            compProps={
                {
                    render: ({ field: { onChange, value } }: any) => {
                        return (
                            <CirclePicker
                                width={props.width || "300px"}
                                circleSpacing={5}
                                color={"#" + value}
                                onChange={color => {
                                    onChange(color.hex.substr(1));
                                }}
                            />
                        );
                    },
                } as any
            }
            {...(props as any)}
        />
    );
};

export default ColorPicker;
