import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React from "react";

import SelectFilter from "./SelectFilter";
import { Source } from "config/constants";
import { Colors } from "config/colors";
import * as _ from "lodash";
import { cn } from "lib/utils";

interface IProps {
    className?: string;
    style?: object;
    labelStyle?: object;
    showLabel?: boolean;
    width?: string | number;
    maxWidth?: string | number;
    options?: Source[];
}

// const getOption

const SourceFilter: FCC<IProps> = _props => {
    const options = [Source.pos, Source.web];

    return (
        <>
            {_props.showLabel ? (
                <label style={_props.labelStyle ? _props.labelStyle : labelRowStyle}>
                    <TText tkey="source" />
                </label>
            ) : null}

            <SelectFilter
                pickerProps={{ placeholder: t("source"), searchable: false, className: cn(_props.className) }}
                fields="source"
                options={_.map(_props.options ?? options, (option: Source) => ({
                    label: t(option as LangKey),
                    value: option,
                }))}
                width={_props.width}
                maxWidth={_props.maxWidth ?? 450}
            />
        </>
    );
};

export default SourceFilter;

const labelRowStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
};
