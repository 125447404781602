import { INV_STATUS, INVENTORY_DECLARATION, INVENTORY_MAJOR_DECLARATORS } from "config/constants";
import { productName } from "lib/data-utils";
import { toFixed } from "lib/utils";
import _ from "lodash";

export const handleRelativeIngredients = (data: any) => {
    for (const x in data) {
        const hasIngredients = data[x]?.product?.ingredients;
        if (hasIngredients) {
            for (const i in hasIngredients) {
                const productId = hasIngredients[i].id;
                const actualIngredient = _.find(data, a => a.product_id === productId);
                const operationId = actualIngredient?.id;

                if (operationId) {
                    data[x] = {
                        ...data[x],
                        relativeIngredientID: !_.isNil(data[x]?.relativeIngredientID)
                            ? [...(data[x]?.relativeIngredientID ?? []), operationId]
                            : [operationId],
                    };
                    data[operationId] = {
                        ...data[operationId],
                        temp: {
                            ...data[operationId].temp,
                            [data[x].id]: {
                                name: productName(data[x].product),
                                quantity: data[x]?.quantity ?? 0,
                                gross_rate: hasIngredients[i].gross_rate,
                                prepacked_quantity: hasIngredients[i].gross_rate * data[x]?.quantity,
                                product_id: data[x]?.product_id,
                                operation_item_id: data[x]?.id,
                            },
                        },
                    };

                    const prepacked_quantity = _.sumBy(_.values(data[operationId]?.temp) ?? [], (d: any) =>
                        d.operation_item_id === operationId ? 0 : d.prepacked_quantity,
                    );

                    data[operationId] = {
                        ...data[operationId],
                        declarator: INVENTORY_DECLARATION.DECLARED,
                        prepacked_quantity,
                    };
                }
            }
        }
    }
    return data;
};
// export interface I {
//     id: number;
//     operation_id: number;
//     declarator: number;
//     receipt_id?: any;
//     receipt_product_id?: any;
//     receipt_product_modificator_id?: any;
//     product_id: number;
//     modificator_id?: any;
//     stock_id?: any;
//     storage_id: number;
//     unit_id?: any;
//     quantity: number;
//     unit_quantity?: any;
//     set_quantity: number;
//     self_quantity?: any;
//     ingredient_quantity?: any;
//     fail_quantity?: any;
//     before_quantity: number;
//     after_quantity: number;
//     waste_cost?: any;
//     total_cost?: any;
//     tax_rate?: any;
//     discount_rate?: any;
//     subtotal?: any;
//     tax_value?: any;
//     discount_value?: any;
//     cost: number;
//     unit_cost?: any;
//     custom_attributes?: any;
//     difference_cost: number;
//     difference_quantity: number;
//     total_quantity: number;
//     operational_quantity: number;
//     prepacked_quantity: number;
//     expected_quantity: number;
//     system_mistake: string;
//     real_expected: number;
//     created_at: Date;
//     updated_at: Date;
//     deleted_at?: any;
//     last_inventory_check: Date;
//     comment?: any;
//     product: IProduct;
//     category?: any;
//     cost_price: number;
// }

export const handleItems = (items: any, data?: any) => {
    const ref: any[] = [];

    for (const x in items) {
        if (
            items[x].declarator === INVENTORY_DECLARATION.DECLARED ||
            items[x].declarator === INVENTORY_DECLARATION.NORMAL
        ) {
            const total_quantity = parseFloat(toFixed((items[x]?.prepacked_quantity || 0) + items[x]?.quantity, 4));
            const difference_quantity = parseFloat(
                toFixed(total_quantity - (items[x]?.product?.expected_quantity || 0), 4),
            );
            const set_quantity = parseFloat(toFixed(total_quantity - (items[x]?.product?.expected_quantity || 0), 4));

            let cost_price = 0;
            if (data?.status === INV_STATUS.PUBLISHED) {
                cost_price = items[x]?.cost;
            } else if (items[x]?.product?.current_average_cost > 0) {
                cost_price = parseFloat(toFixed(items[x]?.product?.current_average_cost, 4));
            } else {
                cost_price = parseFloat(toFixed(items[x]?.product?.cost_price, 4));
            }

            // const cost_price =
            //     data?.status === INV_STATUS.NORMAL && items[x]?.cost
            //         ? items[x]?.cost
            //         : items[x]?.product?.current_average_cost !== 0
            //         ? parseFloat(toFixed(items[x]?.product?.current_average_cost, 4))
            //         : parseFloat(toFixed(items[x]?.product.cost_price, 4));
            const difference_cost =
                data?.status === INV_STATUS.PUBLISHED
                    ? items[x].difference_cost
                    : parseFloat(toFixed(difference_quantity * cost_price, 4));
            const total_cost =
                data?.status === INV_STATUS.PUBLISHED
                    ? items[x].total_cost
                    : parseFloat(toFixed(total_quantity * cost_price, 4));

            items[x].cost_price = cost_price;
            items[x].total_quantity = total_quantity;
            items[x].difference_quantity = difference_quantity;
            items[x].set_quantity = set_quantity;
            items[x].difference_cost = difference_cost;
            items[x].total_cost = total_cost;
        }

        ref.push(items[x]);

        const ingArr = items[x]?.product?.ingredients;
        if (ingArr?.length !== 0) {
            for (const i in ingArr) {
                const isExistsIn = items.find((r: any) => r.product_id === ingArr[i].id);
                if (!isExistsIn) {
                    ref.push(ingArr[i]);
                }
            }
        }
    }

    const refinedKV = _.keyBy(ref, "id");
    return handleRelativeIngredients(refinedKV);
};

export const divideDeclared = (items: any) => {
    const declared: any = {};
    const nondeclared: any = {};

    for (const x in items) {
        const i = items[x];

        if (i.declarator === INVENTORY_DECLARATION.DECLARATOR) {
            declared[i.id] = i;
        } else if (i.declarator !== INVENTORY_DECLARATION.IGNORED) {
            nondeclared[i.id] = i;
        }
    }

    return { declared, nondeclared };
};

type ISelectedItems = {
    declarator: number;
    product_id: number;
    quantity: number;
};

type ICurrentItems = {
    comment: string | null;
    declarator: number;
    difference_cost: number;
    difference_quantity: number;
    operation_item_id: number;
    prepacked_quantity: number | null;
    product_id: number;
    quantity: number;
    set_quantity: number;
    total_quantity: number;
}[];

export const refineDuplicateImports = (
    selectedItems: ISelectedItems[],
    currentItems: ICurrentItems,
    isAtFirstStep: boolean,
) => {
    const payload: any[] = currentItems;
    for (const x in selectedItems) {
        const foundIndex = payload.findIndex(p => {
            if (p.product_id !== selectedItems[x].product_id) {
                return false;
            }

            if (isAtFirstStep) {
                return p.declarator === INVENTORY_DECLARATION.DECLARATOR;
            }

            return INVENTORY_MAJOR_DECLARATORS.includes(p.declarator);
        });

        if (foundIndex > -1) {
            payload[foundIndex] = {
                ...selectedItems[x],
                prepacked_quantity: payload[foundIndex].prepacked_quantity ?? 0,
                declarator: payload[foundIndex].declarator ?? INVENTORY_DECLARATION.NORMAL,
            };
        } else {
            payload.push(selectedItems[x]);
        }
    }
    return payload;
};
