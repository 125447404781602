import { useDataProvider } from "components/DataTable/useDataProvider";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import TabsView from "components/TabsView";
import _ from "lodash";
import { useData } from "hooks/useData";
import CashflowDetails from "./CashflowDetails";
import { format, getDaysInMonth, getYear } from "date-fns";

type IProps = ICDrawerProps;

const CashflowDrawer: FCC<IProps> = props => {
    const data = props.data ?? {};
    const period = data.period ?? "";
    const date = new Date(data.date ?? Date.now());

    let start = format(date, "yyyy-MM-dd");
    let end = format(date, "yyyy-MM-dd");

    if (data.oneColumn) {
        start = data.start;
        end = data.end;
    } else {
        if (period.toLowerCase() === "month") {
            start = data.firstColumn ? data.start : format(date, "yyyy-MM") + "-01";
            end = data.lastColumn ? data.end : format(date, "yyyy-MM") + "-" + getDaysInMonth(date);
        } else if (period.toLowerCase() === "year") {
            start = data.firstColumn ? data.start : getYear(date) + "-01-01";
            end = data.lastColumn ? data.end : getYear(date) + "-12-31";
        }
    }

    const dp = useDataProvider({
        url: "finance/transaction",
        filters: {
            indicator: ["indicator", data.indicator],
            category_id: ["category_id", data.category_id || 1],
            type: ["type", data.type === "BOTH" ? ["IN", "OUT"] : data.type],
            balance_id: data.balance,
        },
        with: ["category", "balance", "staff"],
        params: {
            date: [start, end],
        },
        sort: ["operated_at", "-1"],
    });

    const summary = useData<any>("report/finance/total", {
        params: { date: dp.params.all.date },
        filters: _.values(dp.filters.all),
    });

    return (
        <CDrawer size="md" dp={dp} {...props}>
            <div className="">
                <TabsView
                    tabs={[
                        {
                            label: t("transactions"),
                            Component: CashflowDetails,
                            props: { supplier: dp, isSupplier: true, summary: summary },
                        },
                    ]}
                />
            </div>
        </CDrawer>
    );
};

export default CashflowDrawer;
