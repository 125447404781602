import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { TableDropdown } from "components/DataTable/TableDropdown";
import { IDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import { useLoading } from "hooks/useLoading";
import Ajax from "lib/Ajax";
import _ from "lodash";
import React, { ReactNode, SyntheticEvent, useState } from "react";
import { useHistory } from "react-router-dom";
import { Dropdown, IconButton, toaster, Tooltip, Whisper } from "rsuite";
import ConfirmModal from "../../Drawers/ConfirmModal";
import NoAccessModal from "../../NoAccessModal";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { getLabel } from "../../Form/form-utils";
import ErrorModal from "../../ErrorModal";
import CIcon from "../../CIcon";
import { iArrowDown, iContentCopy, iEditPencil, iLock, iMoreVertical, iTrash } from "assets/icons/Icons";
import { MessageSuccess } from "../../Message";
import { Colors } from "config/colors";

export interface IOptionColumnItem {
    icon?: string;
    label: ReactNode;
    onClick: (d: any) => void;
    visible?: boolean | ((d: any) => boolean);
    style?: React.CSSProperties;
    testId?: string;
}

interface IOptionsColumn {
    dp: IDataProvider;
    isLocked?: (d: any) => boolean;
    lockedText?: string;
    edit?: string | ((d: any) => void);
    // pay?: (d: any) => void;
    duplicate?: string | ((d: any) => void);
    return?: string | ((d: any) => void);
    delete?: string | ((d: any) => string);
    others?: (d: any) => IOptionColumnItem[];
    buttons?: (d: any) => React.ReactNode;
    editable?: (d: any) => boolean;
    duplicable?: (d: any) => boolean;
    preventDelete?: (d: any) => boolean;
    returnable?: (d: any) => boolean;
    // payable?: (d: any) => boolean;
    deleteId?: string;
    width?: number;
    canEdit?: string;
    canDelete?: string;
    canDuplicate?: string;
    canReturnProduct?: string;
    modalType?: string;
}

export function optionsColumn(conf: IOptionsColumn): ITableColumnProps {
    return {
        title: "",
        key: "options",
        fixed: "right",
        noPrint: true,
        align: "right",
        flexGrow: 0,
        width: conf.width || 62,
        optionsConf: conf,
        render: (d: any) => <OptionsColumn conf={conf} d={d} />,
    };
}

interface IProps {
    conf: IOptionsColumn;
    d: IData;
}

const OptionsColumn: FCC<IProps> = ({ conf, d }) => {
    const history = useHistory();
    const modal = useDrawer();
    const { isLoading, withLoading } = useLoading();
    const noAccessModal = useDrawer();
    const errorModal = useDrawer();
    const [permissionLabel, setPermissionLabel] = useState<string>("");
    const auth = useAuth();

    const onDelete = () => {
        if (conf.canDelete && !auth.hasAbility([conf.canDelete])) {
            setPermissionLabel(getLabel(conf.canDelete));
            noAccessModal.setDataId(1);
            return;
        }
        modal.setData(d);
        modal.setDataId(1);
    };

    const onConfirmDelete = (id: number, e: SyntheticEvent) => {
        withLoading(async () => {
            const deleteUrl = _.isString(conf.delete) ? conf.delete : conf.delete?.(d);
            if (!deleteUrl) return;
            const id = (deleteUrl?.indexOf("stock/:stock_id") as any) > -1 ? (d as any).stocks[0].id : d.id;
            return await Ajax.delete({ url: deleteUrl?.replace(`:${deleteUrl?.split(":")[1]}`, id.toString()) });
        })
            .then(() => {
                toaster.push(MessageSuccess(t("deleted_successfully")), { duration: 4000 });
            })
            .finally(() => {
                modal.onClose(e);
                conf.dp.revalidate();
            })
            .catch(e => {
                if (e.models?.length) {
                    errorModal.setData(e);
                }
            });
    };

    const onEdit = () => {
        if (conf.canEdit && !auth.hasAbility([conf.canEdit])) {
            setPermissionLabel(getLabel(conf.canEdit));
            noAccessModal.setDataId(1);
            return;
        }

        if (_.isString(conf.edit)) {
            history.push(conf.edit?.replace(":id", d.id.toString()));
        } else {
            conf.edit?.(d);
        }
    };

    const onDuplicate = () => {
        if (conf.canDuplicate && !auth.hasAbility([conf.canDuplicate])) {
            setPermissionLabel(getLabel(conf.canDuplicate));
            noAccessModal.setDataId(1);
            return;
        }
        if (_.isString(conf.duplicate)) {
            history.push(conf.duplicate?.replace(":id", d.id.toString()));
        } else {
            conf.duplicate?.(d);
        }
    };

    const onReturnProduct = () => {
        if (_.isString(conf.return)) {
            history.push(conf.return?.replace(":id", d.id.toString()));
        } else {
            conf.returnable?.(d);
        }
    };

    const isDropdownEmpty = !conf.others?.(d)?.length && d.deleted_at;
    const isModifable = conf.editable?.(d) && conf.preventDelete?.(d);

    return (
        <span test-id={`option-${d.id}`} style={{ display: "flex", alignItems: "center", height: "100%" }}>
            {conf.delete && (
                <ConfirmModal type={conf?.modalType} {...modal} isLoading={isLoading} onConfirm={onConfirmDelete} />
            )}
            {conf.buttons?.(d)}
            {conf.isLocked?.(d) ? (
                <Whisper
                    placement="left"
                    trigger="hover"
                    speaker={<Tooltip>{conf?.lockedText || <TText tkey="system_generated" />}</Tooltip>}
                >
                    <div
                        className="d-flex align-items-center justify-content-center border-radius-rounded"
                        style={{ width: "24px", float: "right", height: "24px", background: Colors.LightGray }}
                    >
                        <CIcon path={iLock} />
                    </div>
                </Whisper>
            ) : !isDropdownEmpty && !isModifable ? (
                <TableDropdown
                    trigger={
                        <IconButton size="xs" className=" option column-button" icon={<CIcon path={iMoreVertical} />} />
                    }
                >
                    {!conf.editable?.(d) && conf.edit && !d.deleted_at && (
                        <Dropdown.Item onSelect={onEdit} test-id="edit-button">
                            <div className="d-flex align-items-center ">
                                <CIcon className="mr-2" path={iEditPencil} />
                                <TText tkey="edit" />
                            </div>
                        </Dropdown.Item>
                    )}
                    {!conf.returnable?.(d) && conf.return && (
                        <Dropdown.Item onSelect={onReturnProduct} test-id="return-button">
                            <CIcon className="mr-2" path={iArrowDown} />
                            <TText tkey="return_product" />
                        </Dropdown.Item>
                    )}

                    {conf?.duplicable && conf.duplicable?.(d) && (
                        <Dropdown.Item onSelect={onDuplicate} test-id="dublicate-button">
                            <CIcon className="mr-2" path={iContentCopy} />
                            <TText tkey="duplicate" />
                        </Dropdown.Item>
                    )}

                    {!conf.preventDelete?.(d) && conf.delete && !d.deleted_at && (
                        <Dropdown.Item
                            onSelect={onDelete}
                            className="align-items-center d-flex"
                            test-id="delete-button"
                        >
                            <CIcon style={{ color: Colors.BrightRed }} className="mr-2" path={iTrash} />
                            <span style={{ color: Colors.BrightRed }}>
                                <TText tkey="remove" />
                            </span>
                        </Dropdown.Item>
                    )}
                    {conf.others?.(d).map(
                        opt =>
                            (_.isUndefined(opt.visible) ||
                                opt.visible === true ||
                                (typeof opt.visible === "function" && opt.visible?.(d))) && (
                                <Dropdown.Item
                                    key={Math.random()}
                                    className="align-items-center d-flex"
                                    onSelect={() => opt.onClick(d)}
                                    style={opt.style}
                                >
                                    {opt?.icon && <CIcon className="mr-2" path={opt?.icon as never} />}
                                    <span>{opt.label}</span>
                                </Dropdown.Item>
                            ),
                    )}
                </TableDropdown>
            ) : null}
            <NoAccessModal modal={noAccessModal} permission={[permissionLabel]} />
            <ErrorModal modal={errorModal} />
        </span>
    );
};
