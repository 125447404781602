import { t } from "lib/i18n";
import { useFormTitle } from "components/Header/Title";
import { useCForm } from "hooks/useCForm";
import { CategoryModel, dummyCategory } from "models/CategoryModel";
import CForm from "components/Form/CForm/CForm";
import ColorPicker from "../../../components/Form/ColorPicker";
import TextFormGroup from "../../../components/Form/TextFormGroup";
import HeaderRight from "../../../components/Header/HeaderRight";
import JsonView from "../../../components/JsonView";
import UploaderFormGroup from "../../../components/Form/FileUploadFormGroup";
import _ from "lodash";
import { isUrl } from "lib/utils";
import ToggleFormGroup from "../../../components/Form/ToggleFormGroup";
import CIcon from "../../../components/CIcon";
import { iMore } from "assets/icons/Icons";
import { CategoryTypeEnum } from "config/constants";
import { IconButton, Message } from "rsuite";
import TabsView from "../../../components/TabsView";
import { useIntegrationByName } from "providers/PackageProvider";
import { useParams } from "react-router-dom";
import { useAppSelector } from "hooks/useRedux";
import MenuCategorySelectFormGroup from "../../../components/Form/CategorySelectFormGroup/MenuCategorySelectFormGroup";
import { useDrawer } from "components/Drawers/useDrawer";
import { SelectGovCodeDrawer } from "components/Drawers/SelectGovCodeDrawer";
import { SelectGovPackageCodeDrawer } from "components/Drawers/SelectGovPackageCodeDrawer";
import { useSetting } from "hooks/useSetting";
import { useEmenuLanguages } from "hooks/useEmenuLanguages";

const MenuCategoryEditPage: FCC = () => {
    const form = useCForm({
        url: "category",
        model: CategoryModel(),
        // redirectUrl: "/menu/categories",
        dummyData: dummyCategory,
        prepareForSubmit: (d: any) => {
            d.type = "PRODUCT";
            const images = d.images?.length ? d.images[0].uuid : "";
            return _.omit(d, isUrl(images) ? ["image", "images"] : ["image"]);
        },
        refine: (d: any) => {
            if (Array.isArray(d.meta)) {
                d.meta = {};
            }

            for (const [lang, value] of Object.entries(d.meta?.langs || {})) {
                if (Array.isArray(value)) {
                    d.meta.langs[lang] = {};
                }
            }

            d = _.pickBy(d, _.identity);
            return !_.isEmpty(d.media)
                ? {
                      images: [{ uuid: d.media[0]?.urls?.thumb }],
                      ...d,
                  }
                : d;
        },
    });
    const emenuIntegration = useIntegrationByName("emenu");
    return (
        <div>
            {useFormTitle(form.id, t("categories"))}
            <HeaderRight formSubmit={form} />
            <CForm form={form.form} onSave={form.onSave}>
                <TabsView
                    tabs={[
                        {
                            label: t("general"),
                            Component: FirstTab,
                            props: { form },
                            testId: "general-tab",
                        },
                        {
                            label: t("translate"),
                            Component: SecondTab,
                            props: { form },
                            hide: !emenuIntegration,
                            testId: "translate-tab",
                        },
                    ]}
                />

                <JsonView />
            </CForm>
        </div>
    );
};

const FirstTab = (props: any) => {
    const hasEMenu = useIntegrationByName("emenu");
    const params = useParams<any>();
    const brandData = useAppSelector(state => state.app.brandData);
    const allCategories = useAppSelector(state => state.model.menuCategories);
    const cat = allCategories.byId[params.id];
    const selectGovCodeDrawer = useDrawer();
    const selectGovPackageCodeDrawer = useDrawer();

    const isHidden = props.form.form.watch("hidden");
    const isHiddenFromQRMenu = props.form.form.watch("emenu_hidden");

    return (
        <div className="tw-p-4">
            <TextFormGroup
                name="name"
                errorPlacement="rightEnd"
                focus={true}
                compProps={{
                    style: { width: "300px" },
                }}
            />
            <MenuCategorySelectFormGroup
                name="parent_id"
                label={t("parent_category")}
                style={{ width: "100%" }}
                customFilter={
                    cat ? (category: ICategory) => category._lft < cat?._lft || category._rgt > cat?._rgt : null
                }
                types={[CategoryTypeEnum.PRODUCT]}
                compProps={{
                    cleanable: true,
                }}
            />

            <div className={"tw-flex tw-gap-2 tw-mb-3 tw-items-start"}>
                <TextFormGroup name="properties.gov_code" label={t("gov_code")} />
                {brandData.country === "UZ" && (
                    <IconButton onClick={() => selectGovCodeDrawer.setIsOpen(true)} icon={<CIcon path={iMore} />} />
                )}
            </div>

            {brandData.country === "UZ" && (
                <div className={"tw-flex tw-gap-2 tw-mb-3 tw-items-start"}>
                    <TextFormGroup name="properties.gov_package_code" label={t("gov_package_code")} />
                    <IconButton
                        onClick={() => selectGovPackageCodeDrawer.setIsOpen(true)}
                        icon={<CIcon path={iMore} />}
                    />
                </div>
            )}

            <ToggleFormGroup
                name="hidden"
                label={t("status")}
                compProps={{
                    checked: !isHidden,
                    onChange: v => props.form.form.setValue("hidden", !v),
                }}
            />
            {hasEMenu ? (
                <ToggleFormGroup
                    name="emenu_hidden"
                    label={`${t("status")} (${t("emenu")})`}
                    compProps={{
                        checked: !isHiddenFromQRMenu,
                        onChange: v => props.form.form.setValue("emenu_hidden", !v),
                    }}
                />
            ) : null}

            <ColorPicker
                name="color"
                label={t("display")}
                className={!_.isEmpty(props.form.form.getValues("images")) ? "d-none" : "d-block"}
            />

            <UploaderFormGroup
                name="images[0].uuid"
                removeMainKey={true}
                label={t("image")}
                // uploadUrl={"media"}
                compProps={{
                    action: "media",
                    multiple: false,
                    listType: "picture",
                    name: "media_file",
                    "test-id": "category-image",
                }}
            />
            {selectGovCodeDrawer.isOpen && (
                <SelectGovCodeDrawer {...selectGovCodeDrawer} govCode={"properties.gov_code"} />
            )}
            {selectGovPackageCodeDrawer.isOpen && (
                <SelectGovPackageCodeDrawer
                    {...selectGovPackageCodeDrawer}
                    govCode={"properties.gov_code"}
                    govPackageCode={"properties.gov_package_code"}
                />
            )}
        </div>
    );
};

const SecondTab = () => {
    const emenuLangs = useEmenuLanguages();
    const translateForLangs = useSetting("emenu_lang")?.extra;

    return (
        <div className="tw-p-4">
            {!emenuLangs.length && (
                <Message showIcon type="info" children={t("emenu_lang_not_choosen")} style={{ maxWidth: "50%" }} />
            )}
            {emenuLangs.map((lang: string) => (
                <TextFormGroup
                    name={`meta.langs.${lang}.name`}
                    label={translateForLangs?.[lang]}
                    compProps={{ style: { width: "300px" }, placeholder: t("name") }}
                    errorPlacement="rightEnd"
                />
            ))}
        </div>
    );
};

export default MenuCategoryEditPage;
