import { TText } from "components/i18n/TText";
import React, { FC, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Form, Radio, RadioGroup } from "rsuite";

const labelProps = {
    style: {
        width: "250px",
    },
};

type IIncentiveProgramKeys = keyof IIncentiveProgram;

interface IIncentiveProgramRadioInputProps {
    value: any;
    label: LangKey;
    children?: React.ReactNode;
    fieldValue: any;
}

interface IIncentiveProgramRadioSelectProps {
    name: IIncentiveProgramKeys;
    label: string;
    labelDescription: string;
    inputs: IIncentiveProgramRadioInputProps[];
    defaultValue: string;
}

export const IncentiveProgramRadioSelect: FC<IIncentiveProgramRadioSelectProps> = ({
    name,
    label,
    labelDescription,
    inputs,
    ...props
}) => {
    const { setValue } = useFormContext<IIncentiveProgram>();
    const [radioInputValue, setRadioInputValue] = React.useState<any>(props.defaultValue);

    useEffect(() => {
        setRadioInputValue(props.defaultValue);
    }, [props.defaultValue]);

    return (
        <>
            <Form.Group>
                <Form.ControlLabel className="text-left" {...labelProps}>
                    <span test-id={`${label}-label`}>{label}</span>
                    <div test-id={labelDescription} className="tw-text-secondary tw-text-sm tw-break-words">
                        {labelDescription}
                    </div>
                </Form.ControlLabel>

                <div>
                    <RadioGroup value={radioInputValue} className="tw-break-words">
                        {inputs.map(input => (
                            <div key={input.label}>
                                <Radio
                                    value={input.value}
                                    onChange={() => {
                                        setValue(name, input.fieldValue);
                                        setRadioInputValue(input.value);
                                    }}
                                >
                                    <TText tkey={input.label} />
                                </Radio>
                                {input.value === radioInputValue && input.children ? (
                                    <div className="tw-mt-2 tw-mb-2 tw-flex tw-gap-2 tw-flex-wrap tw-w-[310px] tw-items-center">
                                        {input.children}
                                    </div>
                                ) : null}
                            </div>
                        ))}
                    </RadioGroup>
                </div>
            </Form.Group>
        </>
    );
};
