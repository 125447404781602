import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import { useTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import NoAccessModal from "components/NoAccessModal";
import _ from "lodash";
import ModificationCreateModal from "pages/products/dishes/edit/tabs/modifications/ModificationCreateModal";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useState } from "react";
import { Button, Message, toaster } from "rsuite";
import { iClose, iDoubleCheckBox, iPlus, iTrash } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import ConfirmModal from "components/Drawers/ConfirmModal";
import ErrorModal from "components/ErrorModal";
import HeaderRight from "components/Header/HeaderRight";
import { ProductType } from "config/constants";
import { useBulk } from "hooks/useBulk";
import { useLoading } from "hooks/useLoading";
import { exportFile } from "lib/export-table";
import ModifierDrawer from "./ModifierDrawer";

const ManageModifiersPage: FCC = () => {
    const bulkDeleteModal = useDrawer();
    const errorModal = useDrawer();
    const auth = useAuth();
    const modal = useDrawer();
    const noAccessModal = useDrawer();
    const hasNotAccess = !auth.hasAbility(["modificator_create"]);
    const [permissionLabel, setPermissionLabel] = useState("");
    const { isLoading, withLoading } = useLoading();

    const {
        isBulkModeOn,
        setIsBulkModeOn,
        bulkMode,
        setBulkMode,
        bulkData,
        setBulkData,
        resetBulkState,
        deleteRequest,
    } = useBulk();

    const blkPicker = (m: any) => {
        setBulkMode(m);
        setIsBulkModeOn(true);
    };

    const blkExit = () => resetBulkState();

    const blkHandleCheck = (item: any) => {
        const id = item.id as never;
        if (_.includes(bulkData, id)) {
            setBulkData(prev => prev.filter(f => f !== id));
        } else {
            setBulkData(prev => [...prev, id]);
        }
    };

    const blkHandleSelectAll = (selected: boolean) => {
        const data: Array<any> = dp.data ?? [];
        let _bulkData: Array<any> = [];
        if (selected) {
            _bulkData = data.map(d => {
                return d.id;
            });
        }

        setBulkData(_bulkData);
    };

    const blkApplyHandler = () => {
        const permission = "modificator_delete";
        if (!auth.hasAbility([permission])) {
            setPermissionLabel(permission);
            noAccessModal.setDataId(1);
            return;
        }
        bulkDeleteModal.setDataId(1);
    };

    /* Bulk delete confirmation action. */
    const onConfirmDelete = () => {
        withLoading(() => {
            return deleteRequest("/product/modificator-group/bulk/delete", bulkData);
        })
            .then(() => {
                toaster.push(
                    <Message type="success" showIcon>
                        <TText tkey="success" />
                    </Message>,
                );
                resetBulkState();
                bulkDeleteModal.onClose();
                dp.revalidate();
            })
            .catch(e => {
                bulkDeleteModal.onClose();
                if (e.models?.length) {
                    errorModal.setData(e);
                }
            });
    };

    const rd = useDrawer({ backdrop: false, overflowAuto: true });

    const dp = useDataProvider({
        useUrl: true,
        url: "product/modificator-group",
        sort: ["created_at", "-1"],
    });

    const cm = useColumns<any>("product/modificator-group", [
        { key: "id", width: 100, flexGrow: undefined },
        {
            key: "name",
            onClick: (d: IModifierGroup) => rd.setDataId(d.id!),
            flexGrow: 3,
        },
        { key: "max_select", align: "center" },
        { key: "min_select", align: "center" },
        {
            key: "type",
            title: t("modifier_group_type_multi"),
            type: "bool",
            help: `✓(${t("modifier_group_type_multi")}) ✕(${t("one")})`,
            align: "center",
        },
        optionsColumn({
            dp,
            delete: "product/modificator-group/:id",
            edit: "manageModifiers/edit/:id",
            duplicate: "manageModifiers/duplicate/:id",
            canDelete: "modificator_delete",
            canEdit: "modificator_edit",
            canDuplicate: "modificator_create",
            duplicable: () => true,
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("manage_modifiers"), dp.total)}
            <HeaderRight dp={dp} reload export={() => exportFile(dp, cm.columns, t("manage_modifiers"))} />
            <ModifierDrawer {...rd} />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    {!bulkMode ? (
                        <Button className="multiple-select-button" ripple={false} onClick={() => blkPicker("true")}>
                            <CIcon path={iDoubleCheckBox} />
                            <span>
                                <TText tkey="multiple_select" />
                            </span>
                        </Button>
                    ) : (
                        <div className="bulk-mode">
                            <span className="badge">{bulkData.length}</span>
                            <div onClick={() => blkExit()} className="d-flex">
                                <CIcon path={iClose} />
                                <span className="cancel-button">
                                    <TText tkey="cancel" />
                                </span>
                            </div>
                        </div>
                    )}
                    <SearchFilter fields="name" />

                    {bulkData.length > 0 && (
                        <div className="bulk-acton-buttons">
                            <Button ripple={false} className="delete" onClick={blkApplyHandler}>
                                <CIcon path={iTrash} />
                                <TText tkey="delete" />
                            </Button>
                        </div>
                    )}
                </div>

                <Button
                    color="blue"
                    appearance="primary"
                    className="ml-2 mt-3"
                    test-id="create-button"
                    onClick={hasNotAccess ? () => noAccessModal.setDataId(1) : () => modal.setDataId(1)}
                >
                    <CIcon path={iPlus} className="mr-2" />
                    <TText tkey="create" />
                </Button>
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                isBulkMode={isBulkModeOn}
                bulkData={bulkData}
                bulkException={["type", ProductType.MODIFICATION]}
                setBulkData={blkHandleCheck}
                setAllBulkData={blkHandleSelectAll}
            />
            <ModificationCreateModal {...modal} />
            <ErrorModal modal={errorModal} />
            <ConfirmModal {...bulkDeleteModal} isLoading={isLoading} onConfirm={onConfirmDelete} />
            <NoAccessModal modal={noAccessModal} permission={["modificator_create", ...permissionLabel]} />
        </div>
    );
};

export default ManageModifiersPage;
