import Ajax from "./Ajax";
import _ from "lodash";

export const cleanEmptyStrings: <T extends object>(v: T) => Partial<T> = data => _.pickBy(data, v => v !== "");

export const getDataById = async (url: string, id: number, _with: string[] = []): Promise<IData> => {
    const resp = await Ajax.get({ url: `${url}/${id}`, params: { with: _with } });
    return resp.data;
};

export async function loadAllData(url: string, params: any, page = 1) {
    const urlWithParams = Ajax.buildUrl({
        url: url,
        params: {
            ...params,
            page,
            limit: 999,
        },
    });
    const resp = await Ajax.get({
        url: urlWithParams,
    });
    let data = resp.data;

    if (resp.total! > page * 999) {
        data = data.concat(await loadAllData(url, params, page + 1));
    }
    return data;
}
