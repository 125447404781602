import CForm from "components/Form/CForm/CForm";
import DatePickerFormGroup from "components/Form/DatePickerFormGroup";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import JsonView from "components/JsonView";
import { ProductType } from "config/constants";
import { format } from "date-fns";
import { useCForm } from "hooks/useCForm";
import _ from "lodash";
import { ManufactureOperationModel } from "models/OperationModel";
import CProductsDrawer from "components/Drawers/CProductSelector/CProductsDrawer";
import { StorageSelectFormGroup } from "components/Form/adhoc/StorageSelectFormGroup";
import { FormItems } from "components/FormItems/FormItems";
import { FormItemsAddButton } from "components/FormItems/FormItemsAddButton";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { MassInsertButton } from "components/FormItems/MassInsertButton";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { GridItemTotal } from "components/NewForm/Grid/GridItemTotal";
import { StockPreparationSchema } from "form-schemas/StockPreparationSchema";
import { useEffect } from "react";
import { store } from "redux/store";
import { DateRangePicker } from "rsuite";
import { dummyManufactureOperation, dummySupplyItem } from "../misc/utils";
import ManufactureItem, { ManufactureItemField } from "./ManufactureItem";
import { useCurrency } from "hooks/useCurrency";
import { isEditingPage } from "lib/isEditingPage";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { setDisabledProductIds, setShowedTypes } from "redux/features/productsSelector/productsSelector";
import { isContainValue } from "lib/pure";

const columns = [["product"], ["unit"], ["qty", "right"], ["cost", "right"], ["total", "right"], [""]] as const;

function refineItemOnInsert(d: IProduct) {
    const item = store.getState().data.products.byId[d.id];
    return {
        product_id: item?.id,
        unit: item?.unit ?? null,
        // TODO: Number can't return null or undefined
        unit_id: Number(item?.unit_id) ?? null,
        quantity: 0,
        cost: item?.cost_price ?? 0,
        total_cost: (item?.quantity || 0) * item.cost_price,
    };
}

const CreateManufacturePage: FCC = () => {
    const dispatch = useAppDispatch();
    const currency = useCurrency();
    const form = useCForm<IOperation>({
        url: "operation",
        mode: "all",
        with: ["operation_items.unit", "operation_items.packages"],
        refine: (d: any) => ({
            items: d.operation_items?.map((op: IStockOperation) => ({
                id: op.id,
                product_id: op.product_id,
                unit_id: op.unit_id,
                quantity: op.quantity,
                cost: op.cost,
                total_cost: op.total_cost,
            })),
            operated_at: new Date(d.operated_at),
            finance: d.transaction_finance,
            ..._.omit(d, ["operation_items"]),
        }),
        prepareForSubmit: d => {
            return _.pick(
                _.set(d, "operated_at", format(new Date(d.operated_at), "yyyy-MM-dd HH:mm:ss")),
                _.keys(ManufactureOperationModel.fields),
            );
        },
        dummyData: dummyManufactureOperation,
        model2: StockPreparationSchema,
    });
    const items = form.form.watch("items");
    const addedItemString = items?.map((item: any) => item?.id).join(",");
    const isEdit = isEditingPage();
    const storageId = form.form.watch("storage_id");

    const productsById = useAppSelector(state => state.data.products.byId);

    const searchableColumns: SearchableColumns<Mutable<typeof columns>> = {
        product: (searchVal: string, item: any) => isContainValue(searchVal, productsById[item?.product_id]?.slug),
    };

    useEffect(() => {
        dispatch(setDisabledProductIds(items?.map((item: any) => item?.id)));
    }, [addedItemString]);

    useEffect(() => {
        dispatch(setShowedTypes([ProductType.DISH, ProductType.PREPARATION]));
    }, []);

    const isEditable = !isEdit || form.form.watch("canEdit");

    return (
        <div className="tw-h-full tw-px-4">
            {useFormTitle(form.id, t("stock_preparation"))}
            <HeaderRight
                formSubmit={{
                    ...form,
                    disabled: !isEditable,
                }}
            />

            <CForm {...form} disabled={!isEditable} formProps={{ layout: "vertical" }}>
                <JsonView />
                <div className="">
                    <StorageSelectFormGroup
                        refineList={list => list.filter(l => l.status || (isEdit && l.id === storageId))}
                    />
                    <DatePickerFormGroup
                        time
                        name="operated_at"
                        label={t("operation") as string}
                        compProps={{
                            "test-id": "operated_at",
                            cleanable: false,
                            shouldDisableDate: DateRangePicker.after?.(new Date()),
                        }}
                    />
                </div>
                <FormItems itemsFieldName="items">
                    <FormItemsList<ManufactureItemField, Mutable<typeof columns>>
                        columns={columns as Mutable<typeof columns>}
                        searchableColumns={searchableColumns}
                        footer={
                            <div className="tw-grid tw-grid-cols-subgrid tw-col-span-6">
                                <div className="tw-flex tw-col-span-3 tw-gap-2">
                                    <MassInsertButton idField="product_id" refineItem={refineItemOnInsert} />
                                    <FormItemsAddButton dummyData={dummySupplyItem} />
                                </div>
                                <GridItemPlain
                                    align="right"
                                    className="tw-bg-transparent tw-border-none"
                                    value={t("total") + ":"}
                                />
                                <GridItemTotal name="total_cost" align="right" decimals={2} postfix={currency} />
                            </div>
                        }
                    >
                        {_props => {
                            return <ManufactureItem {..._props} />;
                        }}
                    </FormItemsList>
                </FormItems>
            </CForm>
            <CProductsDrawer />
        </div>
    );
};

export default CreateManufacturePage;
