import classNames from "classnames";
import { translateUnitName } from "lib/utils";

interface IProps {
    value?: number | string;
    unit?: IUnit;
    colored?: boolean;
}

const Qty: FCC<IProps> = props => {
    const className = classNames({
        "text-success": props.colored && Number(props.value) > 0,
        "text-danger": props.colored && Number(props.value) < 0,
    });
    const unit = props.unit ? translateUnitName(props.unit) : "";
    const val: string = props.value ? props.value.toString() : "0";
    return (
        <span className={className}>
            {Number(parseFloat(val).toFixed(4))} {unit}
        </span>
    );
};

export default Qty;
