import { IDataProvider } from "components/DataTable/useDataProvider";
import _ from "lodash";
import { CSSProperties, useContext, useState } from "react";
import { Input, InputGroup } from "rsuite";
import { FiltersContext } from "./FiltersContainer";
import { iCLose2, iSearch } from "assets/icons/Icons";
import CIcon from "../../CIcon";
import { useDebouncedSearch } from "hooks/useDebouncedSearch";
import { cn } from "lib/utils";

interface IProps {
    dp?: IDataProvider;
    fields: string; //| string[];
    placeholder?: string;
    onChange?: any;
    className?: string;
    type?: string;
    style?: CSSProperties;
}

const SearchFilter: FCC<IProps> = _props => {
    const props = _.omit(_props, "dp");
    const filtersContext = useContext(FiltersContext);
    const dp = _props.dp || filtersContext.dp;

    const [onSearchValue, setOnSearchValue] = useState("");
    const { searchInputValue, setSearchInputValue } = useDebouncedSearch(() => {
        if (searchInputValue) {
            dp?.filters?.add([props.fields, searchInputValue], props.fields);
        } else {
            dp?.filters?.remove(props.fields);
        }
    });

    const removeFilter = () => {
        dp.filters.remove(props.fields);
        setSearchInputValue("");
    };

    return (
        <InputGroup style={_props.style} className={cn("tw-max-w-[125px] tw-max-h-[36px]", _props.className)}>
            <Input
                test-id="search-filter"
                value={searchInputValue || onSearchValue}
                type={props.type || "text"}
                onChange={val => {
                    if (_props.onChange) {
                        _props.onChange(val);
                        setOnSearchValue(val);
                    } else {
                        setSearchInputValue(val);
                    }
                }}
                placeholder={props.placeholder}
            />
            {searchInputValue !== "" ? (
                <InputGroup.Addon className="bg-white" test-id="search-clean" title="Clear" aria-label="Clear">
                    <CIcon path={iCLose2} size={0.7} onClick={() => removeFilter()} />
                </InputGroup.Addon>
            ) : (
                <InputGroup.Addon className="bg-white">
                    <CIcon path={iSearch} size={0.7} />
                </InputGroup.Addon>
            )}
        </InputGroup>
    );
};

export default SearchFilter;
