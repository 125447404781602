import React, { useEffect } from "react";
import { useCForm } from "hooks/useCForm";
import { useFieldArray } from "react-hook-form";
import TextPostfixBtnFormGroup from "../../../../components/Form/TextPostfixBtnFormGroup";
import CIcon from "../../../../components/CIcon";
import { iMinus, iPlus } from "assets/icons/Icons";

interface IProps {
    form: ReturnType<typeof useCForm>;
    name: string;
    label: string;
    labelProps?: any;
    className?: string;
}

const CustomerArrayField: FCC<IProps> = ({ label, name, form, labelProps, className }) => {
    const { control } = form.form;
    const { fields, append, remove } = useFieldArray({ control, name });

    useEffect(() => {
        if (!fields.length) {
            append([""]);
        }
    }, [fields]);

    const modify = (index: number) => (index === 0 ? append([""]) : remove(index));

    const icon = (isFirst: boolean) => <CIcon path={isFirst ? iPlus : iMinus} />;

    return (
        <>
            {fields.map((field: any, i: number) => {
                return (
                    <TextPostfixBtnFormGroup
                        key={field.id}
                        className={className}
                        labelProps={labelProps}
                        postFix={icon(i === 0)}
                        name={`${name}[${i}].address`}
                        controlWrapperProps={{ style: { width: 300 } }}
                        inputBtn={{ onClick: () => modify(i) }}
                        label={i === 0 ? label : ""}
                    />
                );
            })}
        </>
    );
};

export default CustomerArrayField;
