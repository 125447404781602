import { iWindowClose } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import { cn } from "lib/utils";
import { FC } from "react";
import { IconButton } from "rsuite";

interface GridButtonRemoveProps {
    onClick: () => void;
    className?: string;
    disabled?: boolean;
    "test-id"?: string;
}

export const GridButtonRemove: FC<GridButtonRemoveProps> = ({ onClick, className, disabled = false, ...props }) => {
    return (
        <IconButton
            {...props}
            disabled={disabled}
            className={cn("tw-bg-red-50 hover:tw-bg-red-100 tw-rounded tw-h-full tw-w-10", className)}
            onClick={onClick}
            icon={<CIcon path={iWindowClose} size={0.9} className="text-danger" />}
        />
    );
};
