import { t } from "lib/i18n";
import { getProductName } from "lib/utils";

import { Colors } from "config/colors";
import { ProductType } from "config/constants";
import { useCurrency } from "hooks/useCurrency";

interface IProps {
    d?: IReceiptProduct;
}

const RPname: FCC<IProps> = ({ d }) => {
    const currency = useCurrency();
    const discountV2 = d?.discount_type === 1 ? `${d?.discount_value} ${currency}` : `${d?.discount_rate} %`;
    const isWeight = d?.meta?.product?.sold_by_weight;

    return (
        <span className="d-flex tw-flex-col">
            <span className={d?.product?.deleted_at ? "text-danger" : ""}>{getProductName(d?.product)}</span>{" "}
            <span className="d-flex">
                {d?.modificators.length ? (
                    <span className="d-flex" style={{ color: Colors.LightSilverGray }}>
                        (
                        {d?.modificators
                            .map((modifier: any) => {
                                return modifier.modificator_group.type
                                    ? `${modifier.receipt_product_modificator.count}x${modifier.name}`
                                    : `${modifier.modificator_group.name}: ${modifier.name}`;
                            })
                            .join(", ")}
                        ){" "}
                    </span>
                ) : d?.portion_size !== 1 ? (
                    `${
                        isWeight ? t("weight") : d?.product?.type === ProductType.TIMER ? t("interval") : t("portion")
                    }: ${d?.portion_size}${isWeight ? t("kg_short") : ""}`
                ) : null}{" "}
                {d?.discount_type ? `(${t("discount")}: ${discountV2})` : ""}
            </span>
        </span>
    );
};

export default RPname;
