import React, { createContext, FC, RefObject, useContext, useMemo } from "react";

const PopupContainerContext = createContext<{
    container: RefObject<HTMLElement>;
    name: string;
}>({
    container: { current: document.body },
    name: "Body",
});

export function usePopupContainer() {
    return useContext(PopupContainerContext);
}

export const PopupContainerProvider: FC<{
    children: React.ReactNode;
    container: RefObject<HTMLElement>;
    name: string;
}> = ({ children, container, name }) => {
    // const hasMainContent = document.getElementById("MainContent");
    const value = useMemo(() => {
        return {
            container: container,
            name: name,
        };
    }, [container]);
    return <PopupContainerContext.Provider value={value}>{children}</PopupContainerContext.Provider>;
};
