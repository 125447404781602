import { store } from "redux/store";
import { useForm } from "react-hook-form";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { selectSettings, setSettingByField } from "redux/features/settings";
import { useEffect } from "react";
import Ajax from "../../lib/Ajax";
import { arrayToMap } from "lib/utils";
import { useLoading } from "hooks/useLoading";
import _ from "lodash";
import { customResolver, ValidationSchema } from "lib/custom-form-schema-resolver";
import { mergeSettingValue, parseSettingValue, stringifySettingValue } from "lib/settings-utils";

function getDirtyValues<DirtyFields extends Record<string, unknown>, Values extends Record<keyof DirtyFields, unknown>>(
    dirtyFields: DirtyFields,
    values: Values,
): Partial<typeof values> {
    return Object.keys(dirtyFields).reduce((prev, key) => {
        // Unsure when RFH sets this to `false`, but omit the field if so.
        if (!dirtyFields[key]) return prev;

        return {
            ...prev,
            [key]:
                typeof dirtyFields[key] === "object"
                    ? getDirtyValues(dirtyFields[key] as DirtyFields, values[key] as Values)
                    : values[key],
        };
    }, {});
}

interface IUseSettingsFormConf {
    params?: any;
    selects: string[];
    model?: ValidationSchema;
}

export type UseSettingsFormReturnType = ReturnType<typeof useSettingsForm>;

export function useSettingsForm(conf: IUseSettingsFormConf) {
    const dispatch = useAppDispatch();
    const setting = useAppSelector(selectSettings);
    const { isLoading, withLoading } = useLoading();

    const {
        formState: {
            errors,
            isDirty,
            dirtyFields,
            isValid,
            isValidating,
            isSubmitted,
            isSubmitSuccessful,
            isSubmitting,
            touchedFields,
            defaultValues,
            submitCount,
        },
        ...form
    } = useForm({
        defaultValues: setting,
        mode: "all",
        shouldUnregister: false,
        resolver: conf.model ? customResolver(conf.model) : undefined,
    });
    const formState = {
        errors,
        isDirty,
        dirtyFields,
        isLoading,
        isValid,
        isValidating,
        isSubmitted,
        isSubmitSuccessful,
        isSubmitting,
        touchedFields,
        defaultValues,
        submitCount,
    };

    useEffect(() => {
        (async () => {
            const url = Ajax.buildUrl({
                url: "/setting",
                params: {
                    ...conf.params,
                    selects: conf.selects.join(","),
                },
            });
            const res = await Ajax.get({ url });
            const parsedSettings = arrayToMap(res.data, "name", parseSettingValue);

            if (parsedSettings.cooking_tag) {
                parsedSettings.cooking_tag.temp_value = _.isString(parsedSettings.cooking_tag.value)
                    ? parsedSettings.cooking_tag.value.split(",")
                    : [];
            }

            if (parsedSettings.product_delete_tag) {
                parsedSettings.product_delete_tag.temp_value = _.isString(parsedSettings.product_delete_tag.value)
                    ? JSON.parse(parsedSettings.product_delete_tag.value)
                    : parsedSettings.product_delete_tag.value;
            }

            if (parsedSettings.brand_storage_station && Array.isArray(parsedSettings.brand_storage_station.value)) {
                parsedSettings.brand_storage_station.value = {};
            }

            if (parsedSettings.brand_storage_station && Array.isArray(parsedSettings.brand_storage_terminal.value)) {
                parsedSettings.brand_storage_terminal.value = {};
            }

            if (
                parsedSettings.brand_storage_station &&
                Array.isArray(parsedSettings.brand_storage_terminal_station.value)
            ) {
                parsedSettings.brand_storage_terminal_station.value = {};
            }

            if (parsedSettings.location && Array.isArray(parsedSettings.location.value)) {
                parsedSettings.location.value = {};
            }

            dispatch(setSettingByField(parsedSettings));
            form.reset(parsedSettings);
        })();
    }, []);

    const onSave = async (data: any) => {
        const settings = structuredClone(store.getState().settings.allSettings);
        try {
            //doesn't work in image upload
            if (!isDirty) return;

            const _cooking_tag = _.cloneDeep(data.cooking_tag) as any;
            const _product_delete_tag = _.cloneDeep(data.product_delete_tag) as any;

            if (dirtyFields.cooking_tag) {
                data.cooking_tag = {
                    ..._.omit(_cooking_tag, "temp_value"),
                    value: (_cooking_tag.temp_value as any[])?.join(","),
                } as ISetting;
                delete dirtyFields.cooking_tag.temp_value;
                dirtyFields.cooking_tag["value"] = true;
            }

            if (dirtyFields.product_delete_tag) {
                data.product_delete_tag = {
                    ..._.omit(_product_delete_tag, "temp_value"),
                    value: _product_delete_tag.temp_value as any[],
                } as ISetting;
                delete dirtyFields.product_delete_tag.temp_value;
                dirtyFields.product_delete_tag["value"] = true;
            }

            const changedValues = getDirtyValues(dirtyFields, data);
            //there get values from redux and add full object to changedValues
            for (const key in changedValues) {
                changedValues[key] = stringifySettingValue({
                    // @ts-ignore todo: fix later
                    ...settings[key],
                    // This merge is required if we have to show different parts of same settings in different tabs
                    // @ts-ignore todo: fix later
                    value: mergeSettingValue(settings[key], changedValues[key].value),
                });
            }
            const url = Ajax.buildUrl({
                url: "/setting/update",
            });

            await withLoading(async () => {
                const res = await Ajax.post({ url, data: { settings: changedValues } });
                const parsedData: Record<string, ISetting> = {};
                for (const setting in res.data) {
                    parsedData[setting] = parseSettingValue(res.data[setting]);
                }

                if (parsedData.cooking_tag) {
                    parsedData.cooking_tag = {
                        ...parsedData.cooking_tag,
                        temp_value: _.isString(parsedData.cooking_tag.value)
                            ? parsedData.cooking_tag.value.split(",")
                            : [],
                    };
                }

                if (parsedData.product_delete_tag) {
                    parsedData.product_delete_tag = {
                        ...parsedData.product_delete_tag,
                        temp_value: _.isString(parsedData.product_delete_tag.value)
                            ? JSON.parse(parsedData.product_delete_tag.value)
                            : parsedData.product_delete_tag.value,
                    };
                }

                dispatch(setSettingByField(parsedData));
                const updatedSettings = { ...settings, ...parsedData };
                form.reset(updatedSettings);
            });
        } catch (e) {
            console.log(e);
        }
    };

    const onError = (errors: any, e: any) => console.log(errors, e);

    return {
        form: { ...form, formState },
        isLoading,
        onSave: form.handleSubmit(onSave, onError),
    };
}
