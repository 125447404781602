import React, { memo } from "react";
import { FIListItemProps } from "components/FormItems/FormItemsList";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { Button } from "rsuite";
import { LinkIcon, LinkOffIcon } from "../../../../assets/icons";
import { cn } from "lib/utils";

import { GridSelect } from "components/NewForm/Grid/GridSelect";
import { MappingTypes } from "config/constants";
import { t } from "lib/i18n";
import { IModifierMappingModificatorGroups } from "pages/menu/product-mapping/menu-product-mapping.types";
import { useCurrency } from "hooks/useCurrency";

export interface IModifierItem extends FIListItemProps {
    group_id?: number;
    modificators: { name: string; id: number; group_id: number; price: number }[];
}

const MappingModifierItem = memo<IModifierItem>(
    ({ modificators, field, fields, itemsFieldName, index, remove, form, group_id }) => {
        const currency = useCurrency();
        const groupId = form.watch(`${itemsFieldName}[0].mapper.clopos_product_key`);
        const getName = (name: string, i?: number) => {
            return `${itemsFieldName}[${i ?? index}].${name}`;
        };

        const getModifierPrice = (id: number) => {
            return modificators?.find(modifier => modifier.id === id)?.price?.toFixed(2);
        };

        const disconnectMapper = () => {
            form.setValue(getName("mapper"), {
                ...field?.mapper,
                clopos_product_key: null,
            });
        };

        const getModifiers = (): IModifierMappingModificatorGroups[] => {
            return (
                modificators
                    ?.filter(m => m.group_id === groupId)
                    ?.map(modificator => {
                        return {
                            label: modificator.name,
                            value: modificator.id,
                        };
                    }) ?? []
            );
        };

        const selectModifier = (id: number) => {
            form.setValue(getName("mapper"), {
                ...field?.mapper,
                clopos_product_key: id,
                type: MappingTypes.MODIFIER,
                external_product_key: field?.id,
            });
        };

        return (
            <>
                <GridItemPlain value={t("modifier")} />

                <GridItemPlain value={field.name} />

                <GridItemPlain value={field.price?.toFixed(2)} align="right" postfix={currency} />

                <Button
                    disabled={!field?.mapper?.clopos_product_key}
                    onClick={() => disconnectMapper()}
                    className={cn("", {
                        "tw-bg-[#FFEDEC]": !!field?.mapper?.clopos_product_key,
                        "tw-bg-[#FAFBFC]": !field?.mapper?.clopos_product_key,
                    })}
                >
                    <img src={!!field?.mapper?.clopos_product_key ? LinkOffIcon : LinkIcon} alt={getName("name.tr")} />
                </Button>

                <GridItemPlain value={field?.mapper?.clopos_product_key} />

                <GridSelect
                    options={getModifiers()}
                    name={getName("mapper.clopos_product_key")}
                    onChange={val => selectModifier(val as number)}
                    selectProps={{
                        disabled: !groupId,
                        searchable: true,
                        cleanable: false,
                    }}
                />
                <GridItemPlain
                    align="right"
                    value={getModifierPrice(field?.mapper?.clopos_product_key)}
                    postfix={currency}
                />
            </>
        );
    },
);

export default MappingModifierItem;
