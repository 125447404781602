import PortalTo from "components/PortalTo";
import { t } from "lib/i18n";
import _ from "lodash";
import { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Breadcrumb } from "rsuite";
import { useRoutes } from "hooks/useRoutes";
import { IRouteWithComp } from "types/routes";

interface IProps {
    title: string;
    count?: number | string;
    links?: any[];
    subtitle?: string;
    forceLink?: boolean;
}

export const Title: FCC<IProps> = props => {
    const loc = useLocation();
    const depth = loc.pathname.split("/").length;
    const routes = useRoutes();
    const subRoutes: IRouteWithComp[] = routes.reduce(
        (subroutes, route) => subroutes.concat(route.subroutes as any),
        [],
    );

    const prevRoute = subRoutes.find(
        (a: any) =>
            a.path !== "/" &&
            loc.pathname !== a.path &&
            loc.pathname.startsWith(a.path + "/") &&
            a.path.split("/").length < depth,
    );
    useEffect(() => {
        document.title = props.title;
    }, [props.title]);

    return (
        <PortalTo id="page-title">
            {prevRoute && (_.isNil(props?.forceLink) || props?.forceLink === false) ? (
                <div className="d-flex" style={{ alignItems: "center" }}>
                    <Breadcrumb className="p-0 tw-text-2xl" style={{ marginBottom: 0 }}>
                        <Breadcrumb.Item
                            className="tw-text-2xl"
                            as={NavLink}
                            children={prevRoute.title}
                            to={prevRoute.path}
                        />
                        <Breadcrumb.Item className="tw-text-2xl" children={props.title} active />
                    </Breadcrumb>
                    {props.count !== undefined ? (
                        <h4 className="h4" style={{ marginBottom: 0 }}>
                            <small className="text-muted ml-3">{props.count}</small>
                        </h4>
                    ) : null}
                </div>
            ) : (
                <div className="d-flex" style={{ alignItems: "center" }}>
                    <h4 className="h4" style={{ marginBottom: 0 }}>
                        <span test-id="main-title">{props.title}</span>
                        {props.count !== undefined ? <small className="text-muted ml-3">{props.count}</small> : null}
                        {props.subtitle !== undefined ? (
                            <small className="text-muted ml-1">/ {props.subtitle}</small>
                        ) : null}
                    </h4>
                </div>
            )}
        </PortalTo>
    );
};

export function useTitle(str: string, count?: number | string, subtitle?: string) {
    const [title, setTitle] = useState("");

    useEffect(() => {
        setTitle(str);
    }, [str]);

    return <Title title={title} count={count} subtitle={subtitle} />;
}

export function useTitleWithOptions({
    str,
    count,
    quickLinks,
    subtitle,
    forceLink,
}: {
    str: string;
    count?: number | string;
    quickLinks?: any[];
    subtitle?: string;
    forceLink?: boolean;
}) {
    const [title, setTitle] = useState("");

    useEffect(() => {
        setTitle(str);
    }, [str]);

    return <Title title={title} count={count} links={quickLinks} subtitle={subtitle} forceLink={forceLink} />;
}

export function useFormTitle(id: any = null, str = "", cName = "") {
    return useTitle(cName && id ? cName : t(id ? "edit" : "create"), undefined);

    // return useTitle(str + (cName && id ? cName : t(id ? "edit" : "create")), undefined);
}
