import { t } from "lib/i18n";
import React, { FC, useMemo, useState } from "react";
import { Button, Checkbox, CheckPicker, CheckPickerProps } from "rsuite";
import { ItemDataType } from "rsuite/esm/@types/common";
import { TText } from "../i18n/TText";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import { getLabel } from "./form-utils";
import { usePopupContainer } from "hooks/usePopupContainer";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import _ from "lodash";

const addValue = (data: ItemDataType, valueSet: Set<string | number>, valueKey: string) => {
    data.map((child: ItemDataType) => {
        child[valueKey] && valueSet.add(child[valueKey]);
    });
};

const removeValue = (data: ItemDataType, valueSet: Set<string | number>, valueKey: string) => {
    data.map((child: ItemDataType) => {
        child[valueKey] && valueSet.delete(child[valueKey]);
    });
};

export interface ICCheckPickerFormGroupProps
    extends CFormGroupProps,
        Omit<CheckPickerProps<any>, "name" | "data" | "label" | "placeholder" | "plaintext"> {
    isItemDisabled?: (d: any) => boolean;
    width?: number | string;
    testId?: string;
    hideSelectAll?: boolean;
    hideShowOnlySelected?: boolean;
    customFooter?: React.ReactNode;
    compProps?: any;
}

const CCheckPickerFormGroup: FC<ICCheckPickerFormGroupProps> = ({
    disabled,
    isItemDisabled,
    data,
    valueKey = "id",
    labelKey,
    compProps,
    width,
    testId,
    style,
    ...props
}) => {
    const { watch, setValue } = useCFormContext();
    const disabledItemValues = useMemo(() => {
        return data?.filter(isItemDisabled ?? _.constant(false)).map((x: any) => x[valueKey ?? "id"]);
    }, [isItemDisabled, data, valueKey]);

    const [isShowOnlySelected, setIsShowOnlySelected] = useState<boolean>(false);
    const { container } = usePopupContainer();

    const totalCount = data?.length ?? 0;
    const value = props.value ?? watch(props.name!);

    const renderMenuItem = (label: string) => {
        const _testId = testId ?? props.name;
        return <span test-id={`${_testId}_item`}>{label}</span>;
    };

    const onSelectAll = () => {
        const valueSet = new Set<string | number>(value);

        if (value.length < totalCount) {
            addValue(data, valueSet, valueKey);
        } else {
            removeValue(data, valueSet, valueKey);
        }

        const v = [...valueSet];

        setValue(props.name!, v);
    };

    const renderExtraFooter = () => {
        if (props.hideSelectAll && props.hideShowOnlySelected) return null;

        return (
            <div className={"tw-p-2 tw-flex tw-justify-between tw-items-center"}>
                <div>
                    {!props.hideSelectAll && (
                        <Checkbox
                            indeterminate={value.length > 0 && value.length < totalCount}
                            checked={value.length > 0}
                            onChange={onSelectAll}
                            className={"tw-font-xs"}
                        >
                            <TText tkey="select_all" />
                        </Checkbox>
                    )}
                </div>
                <div>
                    {!props.hideShowOnlySelected && (
                        <Button size={"xs"} appearance={"link"} onClick={() => setIsShowOnlySelected(prev => !prev)}>
                            <TText tkey={isShowOnlySelected ? "show_all" : "show_selected"} />
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    const newCompProps = {
        data: isShowOnlySelected ? data.filter((item: ItemDataType) => value.includes(item[valueKey])) : data,
        labelKey,
        valueKey,
        disabled,
        disabledItemValues: disabledItemValues,
        container: () => container.current!,
        style: { width: width ?? 300 },
        ...compProps,
        "test-id": testId ?? props.name,
        placeholder:
            props.placeholder ??
            t("select", {
                name: props.hideLabel ? "" : props.label || getLabel(props.name),
            }),
        renderMenuItem: compProps?.renderMenuItem ?? renderMenuItem,
        renderExtraFooter: renderExtraFooter,
        placement: compProps.placement ?? "autoVerticalStart",
    } as CheckPickerProps<any>;

    return (
        <CFormGroup
            style={style ?? {}}
            disabled={disabled}
            comp={CheckPicker}
            {...(props as any)}
            compProps={newCompProps}
        />
    );
};

CCheckPickerFormGroup.defaultProps = {
    labelKey: "name",
    valueKey: "id",
    data: [],
};

export default CCheckPickerFormGroup;
