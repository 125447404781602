import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { QuickLinks } from "components/Header/QuickLinks";
import { useTitle } from "components/Header/Title";
import MenuMedia from "components/mini/MenuMedia";
import { CreateProductModal } from "components/Modals/CreateProductModal";
import Ajax from "lib/Ajax";
import _ from "lodash";
import React, { CSSProperties, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button, Message, toaster } from "rsuite";
import CreateButton from "../../components/DataTable/CreateButton";
import MenuCategoryTreeFilter from "../../components/DataTable/Filters/CategoryTreeFilter/MenuCategoryTreeFilter";
import RemoteCheckListFilter from "../../components/DataTable/Filters/RemoteCheckListFilter";
import ConfirmModal from "../../components/Drawers/ConfirmModal";
import { useDrawer } from "components/Drawers/useDrawer";
import ErrorModal from "../../components/ErrorModal";
import NoAccessModal from "../../components/NoAccessModal";
import { BulkModeTypes, CategoryTypeEnum, ProductType } from "config/constants";
import { useBulk } from "hooks/useBulk";
import { useLoading } from "hooks/useLoading";
import { productName } from "lib/data-utils";
import { downloadGoodsMenuExcel, exportFile } from "lib/export-table";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import QuickProductDrawer from "../products/dishes/drawer/QuickProductDrawer";
import ProductBulkUpdateDrawer from "./drawer/ProductBulkUpdateDrawer";
import { iClose, iDoubleCheckBox, iDownload, iEditPencil, iFile, iMoney, iTable, iTrash } from "assets/icons/Icons";
import ExportModal from "../../components/Header/Export/ExportModal";
import ProductsFilterDrawer from "./drawer/ProductsFilterDrawer";
import { useAppSelector } from "hooks/useRedux";
import ToggleFilterDrawer from "../../components/DataTable/Filters/ToggleFilterDrawer";
import BulkDeleteResultModal from "../../components/Drawers/BulkDeleteResultModal/BulkDeleteResultModal";
import CIcon from "../../components/CIcon";
import { useIntegrationByName } from "providers/PackageProvider";
import ProductPricesDrawer from "./drawer/ProductPrices/ProductPricesDrawer";
import { moneyFormatter } from "lib/moneyFormatter";
import { Colors } from "config/colors";
import LearnButton from "../../components/DataTable/LearnButton";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";
import { ymReachGoal } from "lib/ymReachGoal";

interface IExtraExelOptions {
    name: string;
    icon?: string;
    onClick: () => void;
    iconStyle?: CSSProperties;
}

const ProductsPage: FCC = () => {
    const bulkDeleteModal = useDrawer();
    const errorModal = useDrawer();
    const bulkUpdateModal = useDrawer();
    const bulkDeleteResultModal = useDrawer();
    const productFilter = useDrawer();
    const { isLoading, withLoading } = useLoading();
    const [pageType, setPageType] = useState(ProductType.ALL);
    const [permissionLabel, setPermissionLabel] = useState("");
    const [itemDetails, setItemDetails] = useState<any>(null);
    const [bulkDeleteResult, setBulkDeleteResult] = useState({
        deleted_count: 0,
        could_not_deleted_count: 0,
    });

    const auth = useAuth();

    const {
        isBulkModeOn,
        setIsBulkModeOn,
        bulkMode,
        setBulkMode,
        bulkData,
        setBulkData,
        resetBulkState,
        deleteRequest,
    } = useBulk();
    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;
    const history = useHistory();
    // const venue = config.venueId;
    const { currentVenueId } = useAppSelector(state => state.venue);
    const isMultiPriceActive = !!useIntegrationByName("multi_price");

    const pType = isRestaurant
        ? [
              ["type", "GOODS"],
              ["type", "DISH"],
              ["type", "TIMER"],
          ]
        : [["type", "GOODS"]];
    const dp = useDataProvider({
        useUrl: true,
        url: "product",
        with: ["category", "station", "modifications", "taxes"],
        filters: { type: pType as any },
        extraFilterKeys: [
            "venue_active",
            "venue_inactive",
            "haveIngredients",
            "giftable",
            "tax_id",
            "inventory_behavior",
            "emenu_category_id",
        ],
        refineList: list => {
            return list
                ?.map(l => ({
                    ...l,
                    hidden: !l.hidden,
                }))
                .reduce((newList, item) => {
                    const im = item.modifications.map((m: any) => ({ ...m, hidden: !m.hidden }));
                    return _.uniqBy(newList.concat(item).concat(im ?? []), "id");
                }, [] as any[]);
        },
    });
    const rd = useDrawer({ backdrop: false, overflowAuto: true });
    const createProductModal = useDrawer();
    const noAccessModal = useDrawer();
    const exportDrw = useDrawer();
    const priceListDrawer = useDrawer();
    const loc = useLocation();
    const requiredDuplicates = ["dish", "goods"];
    const cm = useColumns<any>("product", [
        {
            key: "id",
            flexGrow: undefined,
            width: 50,
        },
        {
            key: "image",
            flexGrow: undefined,
            verticalAlign: "top",
            width: 70,
            render: (d: any) => <MenuMedia {...d} />,
        },
        {
            key: "name",
            flexGrow: 3,
            render: (d: IProduct) =>
                !d.parent_name ? (
                    <Button
                        appearance={"link"}
                        test-id="name-button"
                        className="rs-btn rs-btn-link p-0"
                        onClick={() => rd.setData(d)}
                    >
                        {productName(d)}
                    </Button>
                ) : (
                    <span className="pl-2">{d.name}</span>
                ),
        },
        { key: "hidden", title: t("status"), type: "bool", align: "center", minWidth: 150 },
        {
            key: "ignore_service_charge",
            hide: true,
            title: t("ignore_service_charge"),
            type: "bool",
            align: "center",
            minWidth: 220,
        },
        { key: "giftable", title: t("giftable"), type: "bool", align: "center" },
        {
            title: t("type"),
            key: "type",
            minWidth: 120,
            flexGrow: 1,
            render: d => _.capitalize(t(d.type.toLowerCase())),
        },
        { title: t("category"), key: "category.name", sortable: true, flexGrow: 1, minWidth: 170 },
        { title: t("station"), key: "station.name", flexGrow: 1 },
        {
            title: t("tax"),
            key: "taxes",
            flexGrow: 1,
            render: t => (t?.taxes ?? [])?.map((tD: any) => tD.name)?.join(", "),
        },
        {
            title: t("cost_price"),
            key: "cost_price",
            type: "money",
            width: 100,
            render: d => (
                <span style={{ color: d.cost_price > d.price ? Colors.BrightRed : Colors.DarkGray }}>
                    {moneyFormatter(d.cost_price)}
                </span>
            ),
        },
        {
            title: t("price"),
            key: "price",
            render: (d: any) => {
                const venueObject = d?.venues?.find((val: any) => val.id === currentVenueId);
                const venuePrice = venueObject?.values?.price;
                return (
                    <div
                        onClick={() => (isMultiPriceActive ? priceListDrawer.setData(d) : null)}
                        style={{
                            height: "100%",
                            marginLeft: "1rem",
                            marginTop: "-0.4rem",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                            cursor: "pointer",
                            fontSize: `${venuePrice ? "12px" : "14px"}`,
                        }}
                    >
                        {venuePrice ? (
                            <div
                                style={{
                                    width: "2rem",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    padding: "0px 3px",
                                    backgroundColor: Colors.DeepPurple,
                                    borderRadius: "6px",
                                    color: "white",
                                    lineHeight: "1.3",
                                    fontSize: "15px",
                                }}
                            >
                                {venueObject ? venuePrice : "0"}
                            </div>
                        ) : null}

                        {moneyFormatter(d?.price)}
                        <br />
                    </div>
                );
            },
            type: "money",
            flexGrow: undefined,
            width: 100,
        },
        {
            title: t("markup_rate"),
            key: "markup_rate",
            align: "right",
            minWidth: 150,
            render: (d: any) => (d.markup_rate ? moneyFormatter(d.markup_rate) : 0),
            suffix: "%",
        },
        {
            title: t("gross_margin"),
            minWidth: 100,
            key: "gross_margin",
            align: "right",
            type: "money",
            render: (d: any) => (d.gross_margin ? moneyFormatter(d.gross_margin) : 0),
            suffix: "%",
        },
        { title: t("barcode"), key: "barcode", align: "center", hide: true, flexGrow: 2 },

        optionsColumn({
            dp,
            editable: (d: any) => d.parent_name,
            preventDelete: (d: any) => d.parent_name,
            edit: d => history.push(`/menu/${d.type.toLowerCase()}/edit/${d.id.toString()}`),
            delete: d => `product/${d.type.toLowerCase()}/:id`,
            canEdit: "product_edit",
            canDelete: "product_delete",
            duplicate: d => history.push(`/menu/${d.type.toLowerCase()}/duplicate/${d.id.toString()}`),
            duplicable: d => requiredDuplicates.includes(d.type.toLowerCase()),
            others: () => [
                {
                    label: t("export_btn"),
                    icon: iDownload,
                    onClick: d => {
                        exportDrw.setIsOpen(true);
                        setItemDetails(d);
                    },
                },
                {
                    label: t("prices"),
                    icon: iMoney,
                    visible: isMultiPriceActive,
                    onClick: d => priceListDrawer.setData(d),
                },
            ],
        }),
    ]);

    useEffect(() => {
        if (isBulkModeOn) bulkUpdateModal.setData(bulkData, true);
        // eslint-disable-next-line
    }, [bulkData]);

    useEffect(() => {
        try {
            if (!loc.search) return;
            const params = JSON.parse(decodeURI(loc.search).replace("?", ""));
            const filters = params?.filters?.type?.map((f: any) => f[1]);
            if (filters.length > 1) {
                setPageType(ProductType.ALL);
            } else {
                setPageType(filters[0]);
            }
        } catch (e: any) {
            setPageType(ProductType.ALL);
            toaster.push(
                <Message type="error" showIcon closable>
                    {t("error") + ": " + e.toString()}
                </Message>,
            );
        }
        resetBulkState();
        // eslint-disable-next-line
    }, [loc]);

    const blkPicker = (m: any) => {
        setBulkMode(m);
        setIsBulkModeOn(true);
    };

    const blkHandleCheck = (item: any) => {
        const id = item.id as never;
        if (_.includes(bulkData, id)) {
            setBulkData(prev => prev.filter(f => f !== id));
        } else {
            setBulkData(prev => [...prev, id]);
        }
    };

    const blkHandleSelectAll = (selected: boolean) => {
        const data: Array<any> = dp.data ?? [];
        let _bulkData: Array<any> = [];
        if (selected) {
            _bulkData = data.map(d => {
                return d.id;
            });
        }

        setBulkData(_bulkData);
    };

    const blkApplyHandler = (mode: string) => {
        const permission = mode === "0" ? "product_delete" : "product_edit";
        if (!auth.hasAbility([permission])) {
            setPermissionLabel(permission);
            noAccessModal.setDataId(1);
            return;
        }
        if (mode === BulkModeTypes.UPDATE) {
            bulkUpdateModal.setDataId(1);
        } else if (mode === BulkModeTypes.DELETE) {
            bulkDeleteModal.setDataId(1);
        }
    };

    /* Bulk delete confirmation action. */
    const onConfirmDelete = () => {
        withLoading(() => {
            return deleteRequest("/product/bulk", bulkData);
        })
            .then(response => {
                setBulkDeleteResult(response.data);
                bulkDeleteResultModal.setIsOpen(true);
                resetBulkState();
                bulkDeleteModal.onClose();
                dp.revalidate();
            })
            .catch(e => {
                bulkDeleteModal.onClose();
                if (e.models?.length) {
                    errorModal.setData(e);
                }
            });
    };

    const exportOptions: IExtraExelOptions[] | undefined = [
        {
            name: t("modifier"),
            icon: iFile,
            onClick: downloadGoodsMenuExcel,
            iconStyle: { fillRule: "evenodd" },
        },
        {
            name: t("list"),
            icon: iTable,
            onClick: () => exportFile(dp, cm.columns, t("products")),
        },
    ];
    if (pageType === ProductType.DISH) {
        exportOptions.push({
            name: t("dish_export_button_name"),
            icon: iTable,
            onClick: () =>
                exportFile({ url: "product/dish/documents/export", urlParams: dp.urlParams }, cm.columns, t("dishes")),
        });
    }
    return (
        <div className="h-100">
            {/*<CProductsDrawer {...productDr} />*/}
            {useTitle(t("all_product"), dp.total)}
            <QuickLinks
                links={[
                    {
                        link: '/menu/products?{"page":1,"limit":50,"filters":{"type":[["type","GOODS"],["type","DISH"],["type","TIMER"]]},"params":{}}',
                        label: t("all_product"),
                        testId: "all-product",
                        active: pageType === ProductType.ALL,
                    },
                    {
                        link: '/menu/products?{"page":1,"limit":50,"filters":{"type":[["type","GOODS"]]},"params":{}}',
                        label: t("goods"),
                        testId: "goods",
                        active: pageType === ProductType.GOODS,
                    },
                    {
                        link: '/menu/products?{"page":1,"limit":50,"filters":{"type":[["type","DISH"]]},"params":{}}',
                        label: t("dishes"),
                        testId: "dish",
                        active: pageType === ProductType.DISH,
                    },
                    {
                        link: '/menu/products?{"page":1,"limit":50,"filters":{"type":[["type","TIMER"]]},"params":{}}',
                        label: t("timer"),
                        testId: "timer",
                        active: pageType === ProductType.TIMER,
                    },
                ]}
            />

            <HeaderRight
                cm={cm}
                dp={dp}
                print
                reload
                exportWithOptions={exportOptions}
                importOptions={{
                    template: "Products temp",
                    detail: "products",
                    action: "product/import",
                    onSuccess: () => dp.revalidate(),
                }}
            />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    {bulkMode ? (
                        <div className="bulk-mode">
                            <span className="badge">{bulkData.length}</span>
                            <div
                                onClick={() => resetBulkState()}
                                test-id="multiple-select-cancel-button"
                                className="d-flex"
                            >
                                <CIcon path={iClose} />
                                <span className="cancel-button">
                                    <TText tkey="cancel" />
                                </span>
                            </div>
                        </div>
                    ) : (
                        <Button
                            className="multiple-select-button tw-w-fit"
                            test-id="multiple-select-button"
                            ripple={false}
                            onClick={() => blkPicker("true")}
                        >
                            <CIcon path={iDoubleCheckBox} />
                            <span>
                                <TText tkey="multiple_select" />
                            </span>
                        </Button>
                    )}

                    <SearchFilter fields="name" />

                    <MenuCategoryTreeFilter categoryTypes={[CategoryTypeEnum.PRODUCT]} width={180} />

                    <RemoteCheckListFilter
                        remote={{
                            url: "station",
                            refineList: (list: any[]) => {
                                list?.push({ id: null, name: t("without_station") });
                                return list;
                            },
                        }}
                        fields="station_id"
                        pickerProps={{ placeholder: t("station"), searchable: false }}
                        width={180}
                    />

                    <ToggleFilterDrawer isOpen={productFilter.isOpen} onChange={val => productFilter.setIsOpen(val)} />

                    {bulkData.length > 0 && (
                        <div className="bulk-acton-buttons">
                            <Button
                                ripple={false}
                                className="edit"
                                test-id="bulk-edit-button"
                                onClick={() => blkApplyHandler(BulkModeTypes.UPDATE)}
                            >
                                <CIcon path={iEditPencil} />
                                <TText tkey="change_set" />
                            </Button>
                            <Button
                                ripple={false}
                                className="delete"
                                test-id="bulk-delete-button"
                                onClick={() => blkApplyHandler(BulkModeTypes.DELETE)}
                            >
                                <CIcon path={iTrash} />
                                <TText tkey="delete" />
                            </Button>
                        </div>
                    )}
                </div>

                <LearnButton page="products" />
                <CreateButton
                    to={() => {
                        createProductModal.setIsOpen(true);

                        ymReachGoal("product_create_click");
                    }}
                />
            </FiltersContainer>

            <div className="table-cell-ignore">
                <DataTable
                    dp={dp}
                    columns={cm.columns}
                    isBulkMode={isBulkModeOn}
                    bulkData={bulkData}
                    bulkException={["type", ProductType.MODIFICATION]}
                    setBulkData={blkHandleCheck}
                    setAllBulkData={blkHandleSelectAll}
                    virtualized={true}
                />
            </div>
            {bulkUpdateModal.isOpen && (
                <ProductBulkUpdateDrawer
                    {...bulkUpdateModal}
                    type="ALL_PRODUCTS"
                    onSubmit={() => {
                        dp.revalidate();
                        resetBulkState();
                        bulkUpdateModal.hide();
                    }}
                />
            )}
            {itemDetails?.id && exportDrw.isOpen && (
                <ExportModal
                    exportOptions={[
                        {
                            name: itemDetails?.name,
                            onClick: () =>
                                Ajax.download(
                                    { url: `product/${itemDetails.type.toLowerCase()}/${itemDetails.id}/invoice` },
                                    `${itemDetails.name} #${itemDetails.id}`,
                                ),
                        },
                    ]}
                    {...exportDrw}
                />
            )}

            <CreateProductModal {...createProductModal} />
            {rd.isOpen ? <QuickProductDrawer {...rd} dp={dp} /> : null}
            <ConfirmModal {...bulkDeleteModal} isLoading={isLoading} onConfirm={onConfirmDelete} />
            <NoAccessModal modal={noAccessModal} permission={[permissionLabel]} />
            <ErrorModal modal={errorModal} />
            <ProductsFilterDrawer dp={dp} {...productFilter} />

            {priceListDrawer.isOpen ? <ProductPricesDrawer {...priceListDrawer} /> : null}

            <BulkDeleteResultModal modal={bulkDeleteResultModal} bulkDeleteResult={bulkDeleteResult} />
        </div>
    );
};

export default ProductsPage;
