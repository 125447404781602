import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React from "react";
import CDrawer, { ICDrawerProps } from "../../../../components/Drawers/CDrawer";
import FiltersContainer from "../../../../components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "../../../../components/DataTable/Filters/RemoteCheckListFilter";
import MenuCategoryTreeFilter from "../../../../components/DataTable/Filters/CategoryTreeFilter/MenuCategoryTreeFilter";
import CheckListFilter from "../../../../components/DataTable/Filters/CheckListFilter";
import { getLabel } from "components/Form/form-utils";
import { CategoryTypeEnum, FinanceAccountDefaultFilters, ProductType } from "config/constants";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { Colors } from "config/colors";
import { SelectStorageFilter } from "components/adhoc-filters/SelectStorageFilter";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";

interface IProps extends ICDrawerProps {
    dp: any;
    showOnPage?: "general" | "detail" | "deleted" | "draft";
}

const SuppliesPageFilterDrawer = (props: IProps) => {
    const brandType: string = useSetting("brand_type")?.value;
    const auth = useAuth();
    const isRestaurant = brandType === BrandType.RESTAURANT;

    return (
        <CDrawer size="xs" title={t("filters")} {...props}>
            <div className="d-flex flex-column justify-content-between" style={{ height: "89vh" }}>
                <div className="pt-3 clopos-scroll" style={{ overflowY: "auto" }}>
                    <FiltersContainer dp={props.dp}>
                        <label style={labelColStyle}>
                            <TText tkey="storage" />
                        </label>
                        <SelectStorageFilter
                            multi
                            pickerProps={{
                                className: "tw-w-full tw-max-w-full",
                            }}
                        />
                        {auth.hasAbility(["finance_category"]) && (
                            <>
                                <label style={labelColStyle}>
                                    <TText tkey="category" />
                                </label>
                                <MenuCategoryTreeFilter
                                    categoryTypes={[CategoryTypeEnum.INGREDIENT, CategoryTypeEnum.PRODUCT]}
                                    hasPermission={auth.hasAbility(["finance_category"])}
                                    width={450}
                                    maxWidth={450}
                                />
                            </>
                        )}
                        {isRestaurant && (
                            <>
                                <label style={labelColStyle}>
                                    <TText tkey="product_type" />
                                </label>
                                <CheckListFilter
                                    pickerProps={{ searchable: false, placeholder: t("type") }}
                                    options={[
                                        { label: getLabel(ProductType.GOODS), value: ProductType.GOODS },
                                        { label: getLabel(ProductType.PREPARATION), value: ProductType.PREPARATION },
                                        { label: getLabel(ProductType.DISH), value: ProductType.DISH },
                                        { label: getLabel(ProductType.INGREDIENT), value: ProductType.INGREDIENT },
                                    ]}
                                    fields="product_type"
                                    width={450}
                                    maxWidth={450}
                                />
                            </>
                        )}
                        {auth.hasAbility(["finance_balance_view"]) && (
                            <>
                                <label style={labelColStyle}>
                                    <TText tkey="balance" />
                                </label>
                                <RemoteCheckListFilter
                                    fields="balance_id"
                                    hasPermission={auth.hasAbility(["finance_balance_view"])}
                                    searchField="name"
                                    remote={{
                                        url: "finance/balance",
                                        filters: FinanceAccountDefaultFilters,
                                        refineList: (data: any[]) => {
                                            data?.push({ id: "-1", name: t("supplier_remain") });
                                            return data;
                                        },
                                    }}
                                    width={450}
                                    maxWidth={450}
                                />
                            </>
                        )}
                    </FiltersContainer>
                </div>
            </div>
        </CDrawer>
    );
};

export default SuppliesPageFilterDrawer;

const labelColStyle = {
    fontWeight: "500",
    fontSize: "14px",
    color: Colors.SteelGray,
    marginTop: "16px",
};
