import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import MenuCategoryTreeFilter from "components/DataTable/Filters/CategoryTreeFilter/MenuCategoryTreeFilter";
import CheckListFilter from "components/DataTable/Filters/CheckListFilter";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { getLabel } from "components/Form/form-utils";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import Money from "components/mini/Money";
import { CategoryTypeEnum, ProductType } from "config/constants";
import _ from "lodash";
import { useData } from "hooks/useData";
import { exportFile } from "lib/export-table";
import { getProductName, translateUnitName } from "lib/utils";
import { SelectStorageFilter } from "components/adhoc-filters/SelectStorageFilter";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";

const BetaIngredientMovementsPage: FCC = () => {
    const url = "report/stock/products";
    const dp = useDataProvider({
        url,
        useUrl: true,
        useDates: true,
    });

    const totaldata = useData<any>(url, {
        params: Object.assign({ total: 1 }, dp.params.all),
        filters: _.values(dp.filters.all),
    });

    const { data: total } = totaldata;

    const cm = useColumns<IProduct & any>("report/stock/products", [
        {
            key: "name",
            flexGrow: 4,
            render: d => getProductName(d),
        },
        {
            key: "type",
            render: d => _.capitalize(t(d.type.toLowerCase())),

            minWidth: 120,
        },
        {
            title: t("unit"),
            key: "unit.symbol",
            hide: true,
            render: d => translateUnitName(d.unit),
        },
        {
            key: "cost_price",
            type: "money",
            hide: true,
            minWidth: 120,
        },
        {
            title: t("initial_stock_quantity"),
            key: "total_initial_quantity",
            suffix: d => translateUnitName(d.unit),
            minWidth: 100,
            hide: true,
            render: d => (
                <div style={{ marginTop: "-0.6rem" }}>
                    {d?.total_initial_quantity} {translateUnitName(d.unit)}
                    <br />
                    <Money colored value={d?.cost_prices.INITIAL_STOCK} />
                </div>
            ),
        },
        {
            key: "start_date_quantity",
            minWidth: 120,
            render: d => (
                <div>
                    <Money colored symbol={false} value={d?.start_date_quantity} />
                    <span>{translateUnitName(d.unit)}</span> <br />
                </div>
            ),
        },
        {
            key: "total_supply",
            summary: () => <Money colored value={total?.cost_prices?.IN} />,
            minWidth: 90,
            render: d => (
                <div style={{ marginTop: "-0.6rem" }}>
                    {d?.total_supply} {translateUnitName(d.unit)}
                    <br />
                    <Money colored value={d?.cost_prices.IN} />
                </div>
            ),
        },
        {
            key: "total_sell",
            summary: () => <Money colored value={total?.cost_prices?.SELL} />,
            minWidth: 100,
            render: d => (
                <div style={{ marginTop: "-0.6rem" }}>
                    <Money symbol={false} value={d?.total_sell} />
                    <span>{translateUnitName(d.unit)}</span> <br />
                    <Money colored value={d?.cost_prices.SELL} />
                </div>
            ),
        },
        {
            key: "total_waste",
            summary: () => <Money colored value={total?.cost_prices?.WASTE} />,
            render: d => (
                <div style={{ marginTop: "-0.6rem" }}>
                    {d?.total_waste} {translateUnitName(d.unit)}
                    <br />
                    <Money colored value={d?.cost_prices.WASTE} />
                </div>
            ),
        },
        {
            key: "transfer_diff",
            summary: () => <Money colored value={total?.cost_prices?.TRANSFER} />,
            minWidth: 100,
            render: d => (
                <div style={{ marginTop: "-0.6rem" }}>
                    {d?.transfer_diff} {translateUnitName(d.unit)}
                    <br />
                    <Money colored value={d?.cost_prices.TRANSFER} />
                </div>
            ),
        },
        {
            key: "total_check",
            summary: () => <Money colored value={total?.cost_prices?.INVENTORY_CHECK} />,
            minWidth: 100,
            render: d => (
                <div style={{ marginTop: "-0.6rem" }}>
                    {d?.total_check} {translateUnitName(d.unit)}
                    <br />
                    <Money colored value={d?.cost_prices.INVENTORY_CHECK} />
                </div>
            ),
        },
        {
            key: "total_return",
            title: t("sales_return"),
            summary: () => <Money colored value={total?.cost_prices?.RETURN} />,
            minWidth: 130,
            render: d => (
                <div style={{ marginTop: "-0.6rem" }}>
                    {d?.total_return} {translateUnitName(d.unit)}
                    <br />
                    <Money colored value={d?.cost_prices.RETURN} />
                </div>
            ),
        },
        {
            key: "total_supply_return",
            title: t("supply_return"),
            minWidth: 140,
            summary: () => <Money colored value={total?.cost_prices?.SUPPLY_RETURN} />,
        },
        {
            key: "total_make",
            summary: () => <Money colored value={total?.cost_prices?.MAKE} />,
            render: d => (
                <div style={{ marginTop: "-0.6rem" }}>
                    {d?.total_make || 0}
                    {translateUnitName(d.unit)}
                    <br />
                    <Money colored value={d?.cost_prices.MAKE} />
                </div>
            ),
        },
        {
            key: "total_make_usage",
            summary: () => <Money colored value={total?.cost_prices?.MAKE_USAGE} />,
            suffix: d => translateUnitName(d.unit),
            minWidth: 130,
            render: d => (
                <div style={{ marginTop: "-0.6rem" }}>
                    {d?.total_make_usage} {translateUnitName(d.unit)}
                    <br />
                    <Money colored value={d?.cost_prices.MAKE_USAGE} />
                </div>
            ),
        },
        {
            key: "end_date_quantity",
            suffix: d => translateUnitName(d.unit),
        },
    ]);

    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;

    return (
        <div className="h-100">
            {useTitle(t("ingredients_movements"), dp.total)}
            <HeaderRight
                dp={dp}
                cm={cm}
                print
                reload
                date
                onRevalidate={() => {
                    dp?.revalidate();
                    totaldata?.revalidate();
                }}
                export={() => exportFile(dp, cm.columns, t("ingredients_movements"))}
            />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="name" />
                    <CheckListFilter
                        noBindModGood
                        pickerProps={{ searchable: false }}
                        options={[
                            { label: getLabel(ProductType.GOODS), value: ProductType.GOODS },
                            { label: getLabel(ProductType.PREPARATION), value: ProductType.PREPARATION },
                            { label: getLabel(ProductType.DISH), value: ProductType.DISH },
                            { label: getLabel(ProductType.INGREDIENT), value: ProductType.INGREDIENT },
                            { label: getLabel(ProductType.MODIFICATION), value: ProductType.MODIFICATION },
                        ].filter(t =>
                            isRestaurant ? true : t.value === ProductType.MODIFICATION || t.value === ProductType.GOODS,
                        )}
                        fields="type"
                    />
                    {/* <RemoteCheckListFilter
                        remote="storage"
                        fields="storage_id"
                        fieldType={"params"}
                        pickerProps={{
                            placeholder: t("storage"),
                            renderMenuItem: (label: any, item: any) => (
                                <StorageCheckListItem label={label} storage={item} />
                            ),
                        }}
                        searchField="name"
                        width={120}
                    /> */}

                    <SelectStorageFilter multi fieldType="params" width={120} />
                    <MenuCategoryTreeFilter
                        categoryTypes={[CategoryTypeEnum.PRODUCT, CategoryTypeEnum.INGREDIENT]}
                        width={120}
                    />
                    <MenuCategoryTreeFilter
                        fieldName={"accounting_category_id"}
                        placeholder={t("accounting_category")}
                        categoryTypes={[CategoryTypeEnum.ACCOUNTING]}
                        width={200}
                    />
                </div>
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
        </div>
    );
};

export default BetaIngredientMovementsPage;
