import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";

import Money from "components/mini/Money";
import { getProductName, translateUnitName } from "lib/utils";
import { useTableHeight } from "hooks/useTableHeight";
import { Colors } from "config/colors";

interface IProps {
    all_operations: IStockOperation[];
}

const ManufactureDeductedStock: FCC<IProps> = ({ all_operations }) => {
    const height = useTableHeight(120);

    const cm = useColumns<any>(`manufacture-deducted-stock`, [
        {
            title: t("product_and_ingredients"),
            key: "product.name",
            align: "left",
            flexGrow: 2,
            render: d =>
                !d.parent_id ? (
                    getProductName(d.product)
                ) : (
                    <span className="pl-2 tw-text-[#8e8e93]"> - {getProductName(d.product)}</span>
                ),
        },
        {
            title: t("storage"),
            key: "storage",
            getStyle: (d: any) => (!d.isParent ? { color: Colors.CoolGray } : {}),
            render: e => (e.isParent ? "" : e.storage?.name || e.storage),
        },
        {
            title: t("quantity"),
            key: "quantity",
            align: "right",
            render: d => (
                <span className={`${!d.isParent && "tw-text-[#8e8e93]"}`}>
                    {d.quantity} {d.product ? translateUnitName(d.product.unit) : ""}
                </span>
            ),
        },
        {
            title: t("total"),
            type: "money",
            key: "total_cost",
            getStyle: (d: any) => (!d.isParent ? { color: Colors.CoolGray } : {}),
            render: (d: any) => <Money value={d.total_cost} />,
        },
    ]);

    const sortByParent = (operations: IStockOperation[]) => {
        let result: IStockOperation[] = [];
        operations
            .filter((so: IStockOperation) => !so.parent_id)
            .forEach((p: IStockOperation) => {
                result.push(p);
                result = [...result, ...operations.filter(o => o.parent_id === p.id)];
            });

        return result;
    };

    return (
        <div className="d-flex">
            <div className="flex-fill border-left">
                <DataTable
                    dp={{ data: sortByParent(all_operations) } as any}
                    noShadow
                    columns={cm.columns}
                    hidePages
                    tableProps={{ headerHeight: 50, autoHeight: false, height: height }}
                />
            </div>
        </div>
    );
};

export default ManufactureDeductedStock;
