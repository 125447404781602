import { t } from "lib/i18n";
import React from "react";
import { useAppSelector } from "hooks/useRedux";
import { moneyFormatter } from "lib/moneyFormatter";
import CIcon from "../../../components/CIcon";
import { iCheckBoxChecked, iCogOutline, iPlus, iSpinner } from "assets/icons/Icons";
import { getRemoteAssetUrl } from "config/main";
import { useHistory } from "react-router-dom";
import { useModules } from "hooks/useModules";
import { useSetting } from "hooks/useSetting";

interface IProps {
    module: IModule;
    modulesPermission?: boolean;
    isLoading?: boolean;
    integrationModal: any;
    connectToModule: (d: any) => void;
    activeCategory: string;
}

const IntegrationApp: FCC<IProps> = ({ module, modulesPermission, integrationModal, isLoading }) => {
    const { findByName } = useModules();
    const { getSettingsPageUrl } = findByName(module.integration_name) || {};

    const adjustFontSizeForText = (text: string, defaultFontSize = 16, minFontSize = 13) => {
        let fontSize = defaultFontSize;
        let textWidth = text.length * fontSize;
        const targetWidth = 188;

        while (textWidth > targetWidth && fontSize > minFontSize) {
            fontSize--;
            textWidth = text.length * fontSize;
        }

        return fontSize;
    };
    const history = useHistory();

    const subscription = useAppSelector(state => state.packages.subscription);
    const currencies = useSetting("currency")?.extra?.symbols;

    const cost = module.price
        ? `${t("per_month", {
              price: `${moneyFormatter(module.price)} ${currencies[subscription?.currency?.toLowerCase() ?? "azn"]}`,
          })}`
        : t("free");
    const icon = module.subscribed && module.integration ? iCheckBoxChecked : iPlus;

    return (
        <>
            <div className="panel" key={module.name} test-id={module.name}>
                <div className="body" onClick={() => integrationModal.setData(module)}>
                    <div className="image overflow-hidden" style={{ borderRadius: "20px" }}>
                        <img
                            src={getRemoteAssetUrl(`integrations/${module.name}.png`)}
                            alt={module.name}
                            className="img-fluid"
                        />
                    </div>
                    {module.subscribed && module.integration && getSettingsPageUrl && (
                        <div className="settings" onClick={() => history.push(getSettingsPageUrl())}>
                            <CIcon path={iCogOutline} />
                        </div>
                    )}
                    <div className="title" style={{ fontSize: adjustFontSizeForText(t(module.name) || module.title) }}>
                        {t(module.name) || module.title}
                    </div>
                    <span className="description">{t(`${module?.name}_intro`)}</span>
                    <span className="price">{cost}</span>
                </div>
                <div className="footer">
                    {modulesPermission ? (
                        <div className="d-flex justify-content-between">
                            <button
                                className={`connect-button ${
                                    module.subscribed && module.integration ? "connected" : "not-connected"
                                }`}
                                onClick={() => integrationModal.setData(module)}
                            >
                                <CIcon path={isLoading ? iSpinner : icon} spin={isLoading} />
                                {module.subscribed && module.integration ? t("connected") : t("connect")}
                            </button>
                        </div>
                    ) : null}
                </div>
            </div>
        </>
    );
};

export default IntegrationApp;
