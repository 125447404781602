import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React, { FC, useEffect, useState } from "react";
import { Button, Message, toaster, Tooltip, Whisper } from "rsuite";
import { useSelector } from "react-redux";
import {
    addReceiptItem,
    deleteReceiptItem,
    recalculateAll,
    recalculateReceipt,
    selectReceiptData,
    selectReceiptProducts,
    selectSalesTypeServiceCharge,
    setSaleTypeChangeStatus,
    updateReceipt,
    updateReceiptItem,
} from "redux/features/webCashier";
import { roundAndClean } from "@cloposcom/libs";
import CIcon from "components/CIcon";
import {
    iAddUser2,
    iClose,
    iEditPencil,
    iGiftOutline,
    iPercentageBordered2,
    iPieChart,
    iPlus,
} from "assets/icons/Icons";
import { setOpenProductSelector, setSelectedProducts } from "redux/features/productsSelector/productsSelector";
import { useProductSelectorContext } from "providers/ProductSelectorProvider";
import { store } from "redux/store";
import _ from "lodash";
import Money from "components/mini/Money";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import DataTable from "components/DataTable/DataTable";
import ReceiptNumberInput from "./ReceiptItemNumberInput";
import ReceiptItemName from "./ReceiptItemName";
import { IDbProductPrice, IDbReceiptProduct } from "@cloposcom/receipt-utils/src/receipt-calculation/interfaces";
import {
    changeModalState,
    selectPriceList,
    setPriceListId,
    setSalesTypes,
    setVenuePriceListId,
} from "redux/features/webCashierAdditionals";
import Ajax from "lib/Ajax";
import { useLoading } from "hooks/useLoading";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { Colors } from "config/colors";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { toFixed } from "lib/utils";
import { useCurrency } from "hooks/useCurrency";
import { useSetting } from "hooks/useSetting";
import { useCalculationSetting } from "hooks/useCalculationSetting";

const ReceiptPageTable: FC<any> = ({ sellerList }) => {
    const currency = useCurrency();
    const dispatch = useAppDispatch();
    const selectedItemIds = useAppSelector(state => state.productSelector.selectedProductIds);
    const priceListId = useAppSelector(state => state.webCashierAdditionals.priceListId);
    const venuePriceListId = useAppSelector(state => state.webCashierAdditionals.venuePriceListId);
    const receiptProducts = useSelector(selectReceiptProducts);
    const priceList: Array<IPriceList> = useSelector(selectPriceList);
    const { receipt } = useSelector(selectReceiptData);
    const calculationSetting = useCalculationSetting();
    const serviceCharge = useSetting("tip_rate");
    const salesTypeServiceCharge = useSelector(selectSalesTypeServiceCharge);
    const { isLoading, withLoading } = useLoading();
    const { updateConfig } = useProductSelectorContext();
    const precision = useSetting("precision");
    const integrations = useAppSelector(s => s.packages.integrations);

    const [sortColumn, setSortColumn] = useState<any>("");
    const [sortType, setSortType] = useState<any>("asc");
    const [isSortLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (receipt.sale_type_id) {
            const integrationIndex = integrations?.findIndex(md => md.service_name === "multi_price");
            if (integrationIndex && integrationIndex !== -1 && integrations[integrationIndex].status) {
                Ajax.get({
                    url: "/brand/integration/" + integrations[integrationIndex].id,
                }).then(response => {
                    // id = sales type id, price_list_id = price list id
                    dispatch(setSalesTypes(response?.data?.payload?.sale_types));

                    const sale_type_index = response?.data?.payload?.sale_types?.findIndex(
                        (type: ISaleType) => type.id === receipt.sale_type_id,
                    );

                    if (
                        sale_type_index &&
                        sale_type_index !== -1 &&
                        response?.data?.payload?.sale_types[sale_type_index].price_list_id !== null
                    ) {
                        const price_list_id = response?.data?.payload?.sale_types[sale_type_index].price_list_id;
                        const price = priceList.findIndex(pr => pr.id === price_list_id && pr.status);
                        if (price !== -1) {
                            dispatch(
                                setPriceListId(response?.data?.payload?.sale_types[sale_type_index].price_list_id),
                            );
                        }
                    }
                    dispatch(setVenuePriceListId(response?.data?.payload?.venue));
                });
            }
        }
    }, [receipt.sale_type_id]);

    useEffect(() => {
        updateConfig({ onProductSelectorSave: () => onProductsSelect(selectedItemIds, priceListId, venuePriceListId) });
    }, [selectedItemIds]);

    useEffect(() => {
        onSortAC();
    }, [sortColumn, sortType]);

    const cm = useColumns<IDbReceiptProduct>("receipts/create", [
        {
            key: "meta.product.name",
            align: "left",
            flexGrow: 3,
            title: _.capitalize(t("name")),
            render: d => {
                const seller: IUser = getSellerName(d?.seller_id);
                return <ReceiptItemName product={d} sellerName={seller?.username} />;
            },
        },
        {
            key: "count",
            align: "center",
            sortable: true,
            sortField: "count",
            flexGrow: window.innerWidth < 1300 ? 2 : 0.8,
            title: _.capitalize(t("count")),
            render: d => {
                return (
                    <ReceiptNumberInput
                        hideButtons={true}
                        value={d.count}
                        min={0.001}
                        max={999999}
                        step={3}
                        onChange={(val: number) => {
                            const product = { ...d };
                            product.count = val;
                            dispatch(updateReceiptItem(product));
                            dispatch(recalculateAll({ setting: calculationSetting, product: product! }));
                            dispatch(recalculateReceipt({ setting: calculationSetting }));
                        }}
                        name="product-count"
                    />
                );
            },
        },
        {
            key: "price",
            currency,
            align: "center",
            sortable: true,
            sortField: "price",
            render: d => (
                <Button
                    appearance={"link"}
                    className="rs-btn rs-btn-link p-0 h-100"
                    onClick={() =>
                        dispatch(
                            changeModalState({
                                product: d,
                                open: true,
                                type: "price",
                                maxPrice: d.price,
                                title: t("change_price"),
                            }),
                        )
                    }
                >
                    {discounted(d, originalPrice(d), discountedPriceWithTax(d), "price")}
                </Button>
            ),
            flexGrow: 0.8,
        },
        {
            key: "cost_price",
            currency,
            align: "center",
            sortable: true,
            sortField: "cost_price",
            contentWrapClass: "text-center",
            flexGrow: 0.8,
            render: (d: any) => (
                <div className="d-grid columns">
                    <Money symbol={false} value={d?.cost_price} />
                </div>
            ),
        },
        {
            key: "total",
            currency,
            align: "center",
            render: d => discounted(d, originalTotal(d), d.total, "total"),
            flexGrow: 0.8,
        },

        optionsColumn({
            dp: { data: receiptProducts } as any,
            others: () => [
                {
                    icon: iPercentageBordered2,
                    testId: "add_discount",
                    label: t("add_discount"),
                    visible: d => d.meta!.product!.discountable,
                    onClick: d => {
                        dispatch(
                            changeModalState({
                                product: d,
                                open: true,
                                type: "productDiscount",
                                maxPrice: d.price,
                                title: t("add_discount"),
                            }),
                        );
                    },
                },
                {
                    icon: iEditPencil,
                    testId: "change_price",
                    label: t("change_price"),
                    onClick: d => {
                        dispatch(
                            changeModalState({
                                product: d,
                                open: true,
                                type: "price",
                                maxPrice: d.price,
                                title: t("change_price"),
                            }),
                        );
                    },
                },
                // {
                //     icon: iPieChart,
                //     label: t("change_portion"),
                //     onClick: (d) => {
                //         dispatch(
                //             changeModalState({
                //                 product: d,
                //                 open: true,
                //                 type: "porsion",
                //                 maxPrice: d.price,
                //             }),
                //         );
                //     },
                // },
                {
                    icon: iPieChart,
                    testId: "change_weight",
                    label: t("change_weight"),
                    visible: d => d.meta?.product.sold_by_weight ?? false,
                    onClick: d => {
                        dispatch(
                            changeModalState({
                                product: d,
                                open: true,
                                type: "porsion",
                                maxPrice: d.price,
                                title: t("change_portion"),
                            }),
                        );
                    },
                },
                {
                    icon: iGiftOutline,
                    testId: "make_a_gift",
                    label: t("make_a_gift"),
                    visible: d => (!!d.is_gift || d.meta!.product!.giftable) ?? false,
                    onClick: d => {
                        dispatch(
                            changeModalState({
                                product: d,
                                open: true,
                                type: "gift",
                                maxPrice: d.price,
                                title: t("make_a_gift"),
                            }),
                        );
                    },
                },
                {
                    icon: iAddUser2,
                    testId: "add_a_seller",
                    label: t("add_a_seller"),
                    onClick: d => {
                        dispatch(
                            changeModalState({
                                product: d,
                                open: true,
                                type: "seller",
                                maxPrice: d.price,
                                title: t("select_seller"),
                            }),
                        );
                    },
                },
                {
                    icon: iClose,
                    testId: "delete",
                    label: t("delete"),
                    onClick: (d, menuId?: any) => {
                        dispatch(deleteReceiptItem({ product: d }));
                        dispatch(recalculateReceipt({ setting: calculationSetting }));
                    },
                },
            ],
        }),
    ]);

    const onSortAC = () => {
        if (sortColumn) {
            const _receiptProducts = structuredClone(receiptProducts);
            dispatch(
                updateReceipt({
                    key: "receipt_products",
                    value: _receiptProducts.sort((a: any, b: any) => {
                        let x = _.get(a, sortColumn);
                        let y = _.get(b, sortColumn);
                        if (!x) x = "";
                        if (!y) y = "";

                        if (typeof x === "string" && typeof y === "string") {
                            return sortType === "asc" ? x.localeCompare(y) : y.localeCompare(x);
                        }

                        if (typeof x === "number" && typeof y === "number") {
                            return sortType === "asc" ? x - y : y - x;
                        }

                        if (typeof x === "string") {
                            x = x.charCodeAt(0);
                        }

                        if (typeof y === "string") {
                            y = y.charCodeAt(0);
                        }

                        return sortType === "asc" ? x - y : y - x;
                    }),
                }),
            );
        }
    };

    const handleSortColumn = (_sortColumn: any, _sortType: any) => {
        setIsLoading(true);
        setSortColumn(_sortColumn);
        setSortType(_sortType);
        setIsLoading(false);
    };

    const onProductsSelect = async (
        ids: number[],
        _priceListId: number | undefined,
        venuePriceListId: number | undefined,
    ) => {
        const productsById = store.getState().data.products.byId;
        const url = Ajax.buildUrl({
            url: "product",
            method: "get",
            params: {
                with: ["taxes", "unit", "station", _priceListId ? "prices.list" : ""],
                filters: {
                    id: ["id", ids],
                },
                limit: ids.length,
                selects:
                    "discountable,giftable,id,name,price,unit_id,type,parent_name,parent_id,has_modifications,barcode,station_id,cost_price,unit_weight,sold_by_weight",
            },
        });
        await withLoading(() =>
            Ajax.get({
                url: url,
            }),
        )
            .then(resp => {
                resp?.data?.forEach((product: IProduct) => {
                    const parentProduct = product.parent_id ? productsById[product.parent_id] : undefined;
                    const price = product.price;
                    let _priceItem: IDbProductPrice | undefined;
                    if (_priceListId) {
                        _priceItem = product.prices?.find(_price => _price.list_id === _priceListId) as any;
                    }

                    if (!_priceItem && venuePriceListId) {
                        _priceItem = product.prices?.find(_price => _price.list_id === venuePriceListId) as any;
                    }
                    product = Object.assign(product, {
                        meta: {
                            taxes: product.taxes ?? [],
                            product: {
                                sold_by_weight: parentProduct?.sold_by_weight ?? product.sold_by_weight,
                                has_portion: parentProduct ? parentProduct.meta?.hasPortion : product.meta?.hasPortion,
                            },
                        },
                    });
                    dispatch(
                        addReceiptItem({
                            product: product as any,
                            parentProduct: parentProduct as any,
                            setting: calculationSetting,
                            price: _priceItem,
                        }),
                    );
                    dispatch(setSaleTypeChangeStatus(false));
                });
            })
            .catch((e: CustomError) => {
                toaster.push(e.message);
            });
    };

    const getSellerName = (seller_id: number | undefined) => {
        return sellerList?.find((seller: IUser) => seller.id === seller_id);
    };

    const discounted = (rp: IDbReceiptProduct, original: number, after: number, label: string) => {
        return (
            <div className="d-grid columns">
                {!rp.is_gift && (rp.discount_value || rp.receipt_discount) ? (
                    <>
                        <Money
                            symbol={false}
                            value={original}
                            testId={`top-${label}`}
                            style={{
                                textDecoration: "line-through",
                                fontSize: 13,
                            }}
                        />
                        <Money
                            symbol={false}
                            value={after}
                            testId={`bottom-${label}`}
                            style={{ fontSize: "13px", marginTop: "-20px" }}
                        />
                    </>
                ) : (
                    <Money symbol={false} value={after} testId={`middle-${label}`} />
                )}
            </div>
        );
    };

    // For price and discount price
    const originalPrice = (product: IDbReceiptProduct): number => {
        const discount = product.receipt_discount || product.total_discount;
        const receiptDiscountPerItem = roundAndClean(discount / product.count, 4);
        const rate = roundAndClean((receiptDiscountPerItem / product.price) * 100, 4);
        const totalTaxPerRP = totalTax(product) / product.count;
        const originalTax = totalTaxPerRP ? roundAndClean(totalTaxPerRP / (1 - rate / 100), 4) : 0;
        return roundAndClean(product.price + originalTax, 4);
    };

    const totalTax = (product: IDbReceiptProduct): number => {
        return roundAndClean(product.taxes?.reduce((tt: any, t: any) => tt + t.tax_amount, 0) ?? 0, 4);
    };

    const discountedPriceWithTax = (product: IDbReceiptProduct): number => {
        const discount = product.receipt_discount || product.total_discount;
        const receiptDiscountPerItem = roundAndClean(discount / product.count, 4);
        return roundAndClean(priceWithTax(product) - receiptDiscountPerItem, 4);
    };

    const priceWithTax = (product: IDbReceiptProduct): number => {
        return roundAndClean(product.price + totalTax(product) / product.count, 4);
    };

    // For total price
    const originalTotal = (product: IDbReceiptProduct): number => {
        const discount = product.receipt_discount || product.total_discount;
        const receiptDiscountPerItem = roundAndClean(discount / product.count, 4);
        const rate = roundAndClean((receiptDiscountPerItem / product.price) * 100, 4);
        const originalTax = totalTax(product) ? roundAndClean(totalTax(product) / (1 - rate / 100), 4) : 0;
        return roundAndClean(product.subtotal + originalTax, 4);
    };

    // For total discount
    const getCombinedPercentDiscount = () => {
        const val = receipt.subtotal
            ? roundAndClean(
                  ((receipt.discount_value + receipt.rps_discount) / receipt.subtotal) * 100,
                  precision?.value ?? 2,
              )
            : 0;
        return `(${val % 1 !== 0 ? "~" + Math.round(val) : val}%)`;
    };

    const getMaxDiscountablePrice = (): number => {
        return receipt.receipt_products.filter(rp => rp.discount_value === 0).reduce((a, b) => a + b.subtotal, 0);
    };

    const addProduct = () => {
        if (receipt.sale_type_id) {
            dispatch(setSelectedProducts([]));
            dispatch(setOpenProductSelector(true));
        } else {
            return dispatch(
                changeModalState({
                    product: undefined,
                    open: true,
                    type: "warning",
                    maxPrice: 0,
                    title: t("warning"),
                    optional: {
                        salesTypeNotSelect: !receipt.sale_type_id,
                        productNotSelect: !receipt.receipt_products.length,
                        paymentNotSelect: !receipt.payment_methods!.length,
                    },
                }),
            );
        }
    };

    const renderEmpty = () => {
        return (
            <div className="empty-data" test-id="empty-data">
                <div className="warning">
                    <Message type="warning" showIcon>
                        {!receipt.sale_type_id ? t("no_sales_chosen") : t("no_product")}
                    </Message>
                </div>
            </div>
        );
    };

    const serviceChargeValue = Number(salesTypeServiceCharge ?? serviceCharge?.value);

    return (
        <div className="product-table">
            <div className="table-container">
                <DataTable
                    dp={{ data: receiptProducts, isLoading } as any}
                    columns={cm.columns}
                    tableProps={{
                        rowHeight: 60,
                        renderEmpty,
                        loading: isSortLoading,
                        onSortColumn: handleSortColumn,
                        sortColumn: sortColumn,
                        sortType: sortType,
                    }}
                    rowPadding="0"
                />
            </div>
            <div className="table-footer">
                <Button className="add-product-button" test-id="add-product-button" onClick={() => addProduct()}>
                    <CIcon path={iPlus} />
                    <span>
                        <TText tkey="add_product_rt" />
                    </span>
                </Button>
                <div className="summary">
                    <div className="summary-item">
                        <span className="title">
                            <TText tkey="gift" />
                        </span>
                        <span className="value">
                            <span className={"data " + (receipt.gift_total === 0 ? "disable" : "")}>
                                {toFixed(Number(receipt?.gift_total || 0), precision?.value || 2)}
                                {currency}
                            </span>
                        </span>
                    </div>
                    <div className="summary-item">
                        <div className="title">
                            <TText tkey="subtotal" />
                        </div>
                        <div className="value">
                            <div
                                className={"data " + (receipt.subtotal === 0 ? "disable" : "")}
                                test-id="total-subtotal"
                            >
                                {toFixed(Number(receipt.subtotal || 0), precision?.value || 2)}
                                {currency}
                            </div>
                        </div>
                    </div>
                    <div className="hr"></div>
                    <div className="summary-item">
                        {receipt.discount_value + receipt.rps_discount > 0 ? (
                            <>
                                <div className="title">
                                    <TText tkey="total_discount" />:
                                </div>
                                <div className="value">
                                    <div className="additional" test-id="total-discount-percentage">
                                        {getCombinedPercentDiscount()}
                                    </div>
                                    <div className="data" test-id="total-discount-value">
                                        {toFixed(
                                            Number(receipt.discount_value || 0) + Number(receipt.rps_discount || 0),
                                            precision?.value || 2,
                                        )}
                                        {currency}
                                    </div>
                                </div>
                            </>
                        ) : null}
                    </div>
                    <div className="summary-item">
                        {receipt.discount_value > 0 ? (
                            <>
                                <div className="title">
                                    <TText tkey="receipt_discount" />:
                                </div>
                                <div className="value">
                                    <div className="additional">{receipt.discount_rate}%</div>
                                    <div className="data">
                                        {toFixed(Number(receipt?.discount_value || 0), precision?.value || 2)}
                                        {currency}
                                    </div>
                                    <CIcon
                                        path={iEditPencil}
                                        onClick={() => {
                                            dispatch(
                                                changeModalState({
                                                    product: undefined,
                                                    open: true,
                                                    type: "receiptDiscount",
                                                    title: t("add_discount"),
                                                    maxPrice: getMaxDiscountablePrice(),
                                                    optional: {
                                                        discount_type: receipt.discount_type,
                                                        discount_rate: receipt.discount_rate,
                                                        discount_value: receipt.discount_value,
                                                    },
                                                }),
                                            );
                                        }}
                                    />
                                </div>
                            </>
                        ) : (
                            <Button
                                className="link-button"
                                disabled={!receiptProducts.length}
                                onClick={() => {
                                    dispatch(
                                        changeModalState({
                                            product: undefined,
                                            open: true,
                                            type: "receiptDiscount",
                                            title: t("add_discount"),
                                            maxPrice: getMaxDiscountablePrice(),
                                            optional: {
                                                discount_type: receipt.discount_type,
                                                discount_rate: receipt.discount_rate,
                                                discount_value: receipt.discount_value,
                                            },
                                        }),
                                    );
                                }}
                            >
                                <span className="text">
                                    <TText tkey="add_general_discount" />
                                </span>
                            </Button>
                        )}
                    </div>
                    <div className="summary-item">
                        {receipt.service_charge ? (
                            <>
                                <div className="title">
                                    <TText tkey="service_charge" />:
                                </div>
                                <div className="value">
                                    <div className="additional">{receipt.service_charge}%</div>
                                    <div className="data">
                                        {toFixed(Number(receipt.service_charge_value || 0), precision?.value || 2)}
                                        {currency}
                                    </div>
                                    <CIcon
                                        path={iClose}
                                        style={{ color: Colors.BrightRed }}
                                        onClick={() => {
                                            dispatch(
                                                updateReceipt({
                                                    key: "service_charge",
                                                    value: 0,
                                                }),
                                            );
                                            dispatch(recalculateReceipt({ setting: calculationSetting }));
                                        }}
                                    />
                                </div>
                            </>
                        ) : (
                            <Whisper
                                disabled={!receiptProducts.length || serviceChargeValue !== 0}
                                placement="bottomStart"
                                trigger="hover"
                                speaker={
                                    <Tooltip>
                                        <TText tkey="no_service_charge_desc" />
                                    </Tooltip>
                                }
                            >
                                <Button
                                    className="link-button"
                                    disabled={!receiptProducts.length}
                                    onClick={() => {
                                        dispatch(
                                            updateReceipt({
                                                key: "service_charge",
                                                value: serviceChargeValue,
                                            }),
                                        );
                                        dispatch(recalculateReceipt({ setting: calculationSetting }));
                                    }}
                                >
                                    <span className="text">
                                        <TText tkey="add_service_charge" />
                                    </span>
                                </Button>
                            </Whisper>
                        )}
                    </div>
                    <div className="summary-item">
                        <div className="title">
                            <TText tkey="tax" />:
                        </div>
                        <div className="value disable">
                            <div className="data disable">
                                {toFixed(Number(receipt?.total_tax || 0), precision?.value || 2)}
                                {currency}
                            </div>
                        </div>
                    </div>
                    <div className="total-item">
                        <div className="title">
                            <TText tkey="sum_total" />:
                        </div>
                        <div className="value" test-id="receipt-total">
                            {toFixed(Number(receipt.total || 0), precision?.value || 2)}
                            {currency}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ReceiptPageTable;
