import { t } from "lib/i18n";
import { UseDrawerReturn } from "./useDrawer";
import { useHistory } from "react-router-dom";
import { useDataProvider } from "../DataTable/useDataProvider";
import _ from "lodash";
import { useColumns } from "../DataTable/ColumnsManager/useColumns";
import CellBtn from "../DataTable/CellButton";
import { getProductName, translateUnitName } from "lib/utils";
import CDrawer from "./CDrawer";
import DataTable from "../DataTable/DataTable";
import React from "react";
import { ProductType } from "config/constants";

const UsageByTypeDrawer: FCC<UseDrawerReturn> = props => {
    const history = useHistory();
    const isModif = props?.data?.type === "modificator";
    const isPrep = props?.data?.type === ProductType.PREPARATION;
    const url = isModif ? `product/modificator` : `product/relatable/${props?.dataId}`;
    const modifDp = useDataProvider({
        stop: !props.dataId || !isModif,
        url,
        filters: {
            ingredient_id: ["ingredient_id", props.dataId!],
        },
        with: ["modificator_group"],
        refineList: list => {
            return _.uniqBy(list || [], "group_id");
        },
    });

    const productDp = useDataProvider({
        stop: !props.dataId || isModif,
        url,
        filters: {
            type: ["type", props.data?.type],
        },
        with: ["unit"],
    });

    const cm = useColumns<any>("drawer-preparation-dishes", [
        {
            title: t("name"),
            key: "name",
            flexGrow: 3,
            render: e => (
                <CellBtn
                    action={() =>
                        history.push(
                            isModif
                                ? `/menu/manageModifiers/edit/${e.modificator_group?.id}`
                                : `/menu/${props?.data?.type.toLowerCase()}/edit/${e.id}`,
                        )
                    }
                    label={getProductName(isModif ? e?.modificator_group : e)}
                />
            ),
        },
        {
            title: t("type"),
            key: "type",
            render: (e: IDish) => t(e.type?.toLowerCase() as Lowercase<ProductType>),
        },
        {
            key: "brutto",
            title: t("use_volume"),
            render: (e: any) => {
                // const preparation: IPreparation | undefined = _.find(e.recipe, { id: preparationId });
                return e?.pivot?.gross ? `${e?.pivot?.gross} ${translateUnitName(e?.unit)}` : e.brutto;
            },
        },
        {
            title: t("price"),
            key: "price",
            type: "money",
        },
    ]);
    const height = document.body.getBoundingClientRect().height - 125;

    return (
        <CDrawer title={props.data?.title} {...props}>
            <DataTable
                virtualized={true}
                tableProps={{
                    height,
                }}
                dp={isModif ? modifDp : productDp}
                columns={cm.columns}
            />
        </CDrawer>
    );
};
export default UsageByTypeDrawer;
