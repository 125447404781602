import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { iBox, iSpinner, iTrash } from "assets/icons/Icons";
import { Colors } from "config/colors";
import { useData } from "hooks/useData";
import { useTableHeight } from "hooks/useTableHeight";
import { getProductName } from "lib/utils";
import CIcon from "components/CIcon";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import DoubleDataColumn from "components/mini/DoubleDataColumn";
import ProductReturnDrawerSubInfo from "components/Drawers/ProductReturnDrawer/ProductReturnDrawerSubInfo";
import { Trash } from "config/constants";

type IProps = ICDrawerProps;

const ProductReturnDrawer: FCC<IProps> = props => {
    const r: any = useData(!!props.dataId && `returns/${props.dataId}`, {
        params: { trash: Trash.TrashAndActive },
        with: ["user", "return_products.product", "transaction"], //add storage name and (recipe or modificator filter)
    });

    const cm = useColumns<any>(`return_product_details-drawer`, [
        {
            title: t("product"),
            key: "product.name",
            render: (d?: any) => <DoubleDataColumn value1={getProductName(d?.product)} />,
        },
        { title: t("quantity"), key: "quantity", align: "right" },
        {
            title: t("status"),
            key: "waste",
            render: (d?: any) =>
                d.waste ? (
                    <CIcon path={iTrash} size={1} style={{ color: Colors.BrightRed }} />
                ) : (
                    <CIcon path={iBox} size={1} style={{ color: Colors.TropicalGreen }} />
                ),
            align: "right",
        },
        { title: t("cost"), key: "cost", type: "money" },
        { title: t("price"), key: "price", type: "money" },
        { title: t("total"), key: "total", type: "money" },
    ]);

    const height = useTableHeight(230);

    return (
        <CDrawer
            className=""
            size="md"
            title={`${t("product_return_setting")} - #${t("data_id")} ${props?.dataId} - #${t("receipt")} ${
                r?.data?.receipt_id
            }`}
            {...props}
        >
            <div style={{ height: "100%" }} className=" border-left ">
                {r?.isLoading ? (
                    <CIcon path={iSpinner} spin className="m-3" />
                ) : (
                    <div className="d-flex flex-column justify-content-between" style={{ height: "100%" }}>
                        <DataTable
                            noShadow
                            columns={cm.columns}
                            dp={{ data: r?.data?.return_products } as any}
                            hidePages
                            tableProps={{
                                rowHeight: 60,
                                autoHeight: false,
                                height,
                            }}
                        />
                        <ProductReturnDrawerSubInfo r={r?.data} />
                    </div>
                )}
            </div>
        </CDrawer>
    );
};

export default ProductReturnDrawer;
