import { useAppSelector } from "hooks/useRedux";
import dotsForReceipt from "../common/ReceiptDots";
import { Loader } from "rsuite";
import useGenerateReceipt from "../common/useGenerateReceipt";
import { selectStationReceiptItems } from "redux/features/stationPrintConstructor";
import { Colors } from "config/colors";

const StationReceiptPreviewView = () => {
    const items = useAppSelector(selectStationReceiptItems);

    const { isLoading, canvasRef } = useGenerateReceipt({ items });

    return (
        <div className="d-flex flex-column " style={{ position: "relative" }}>
            {dotsForReceipt({ pos: "top" })}

            {/*{dotsForReceipt({ pos: "bottom" })}*/}

            <div className="p-2 m-3  " style={{ border: `1px solid ${Colors.SilverGray}`, minHeight: 200 }}>
                <div style={{ zoom: "0.58" }} ref={canvasRef} />
                <div className="" style={{ width: 320 }}>
                    {isLoading ? <Loader className="" /> : null}
                </div>
            </div>
        </div>
    );
};

export default StationReceiptPreviewView;
