import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Nullable } from "types/Nullable";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";
import { I18N, Lang, LangInfo } from "lib/i18n";
import { captureMessage } from "@sentry/react";
import { toaster } from "rsuite";
import { MessageError } from "components/Message";

type InitialState = {
    current: Nullable<Lang>;
    list: LangInfo[];
    isLoading: boolean;
};

const initialState: InitialState = {
    current: I18N.localLang as Nullable<Lang>,
    list: [],
    isLoading: false,
};

export const setCurrentLang = createAsyncThunk("updateTranslations", (newLang: Lang) =>
    I18N.updateTranslations(newLang).then(() => newLang),
);

const LangSlice = createSlice({
    name: ReduxSlicesNames.LANG,
    initialState,
    reducers: {
        setLangsList: (state, action: PayloadAction<LangInfo[]>) => {
            state.list = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addCase(setCurrentLang.pending, (state, action) => {
                state.isLoading = true;
            })
            .addCase(setCurrentLang.rejected, (state, action) => {
                state.isLoading = false;

                captureMessage(action.error.message as string, {
                    // @ts-ignore
                    extra: { message: action.error.cause },
                });

                toaster.push(MessageError("Language didn't loaded. Please try again"));
            })
            .addCase(setCurrentLang.fulfilled, (state, action) => {
                state.current = action.payload;

                state.isLoading = false;
            });
    },
});

export const { setLangsList } = LangSlice.actions;
export default LangSlice.reducer;
