import { TText } from "components/i18n/TText";
import React, { FC } from "react";
import CIcon from "../../../../components/CIcon";
import { iFile2, iGiftOutline, iPercentageBordered2 } from "assets/icons/Icons";
import { Tooltip, Whisper } from "rsuite";
import { IDbReceiptProduct } from "@cloposcom/receipt-utils/src/receipt-calculation/interfaces";
import { toFixed } from "lib/utils";
import { useCurrency } from "hooks/useCurrency";
import { useSetting } from "hooks/useSetting";

type ReceiptNameType = {
    product: IDbReceiptProduct;
    sellerName: string;
};

const ReceiptItemName: FC<ReceiptNameType> = ({ product, sellerName }) => {
    const precision = useSetting("precision");
    const currency = useCurrency();

    const productTaxTotal = (p: IDbReceiptProduct): number => {
        return p.taxes?.reduce((total: number, tax: any) => total + tax.tax_amount, 0) ?? 0;
    };

    return (
        <div className="product-name">
            <div className="name" test-id="product-name">
                {product?.meta?.product?.name}
            </div>
            <div className="sub">
                {product?.meta?.product?.barcode && (
                    <div className="item">
                        <span test-id="product-barcode">{product?.meta?.product?.barcode}</span>
                        <div className="divider"></div>
                    </div>
                )}
                {sellerName && (
                    <div className="item">
                        <span test-id="product-seller-name">{sellerName}</span>
                        <div className="divider"></div>
                    </div>
                )}
                {product.is_gift ? (
                    <div className="item">
                        <Whisper
                            placement="bottom"
                            trigger="hover"
                            speaker={
                                <Tooltip>
                                    <TText tkey="gift" />
                                </Tooltip>
                            }
                        >
                            <span test-id="product-gift">
                                <CIcon path={iGiftOutline} />
                            </span>
                        </Whisper>
                    </div>
                ) : (
                    <div className="d-flex">
                        {product?.discount_value > 0 ? (
                            <div className="item">
                                <Whisper
                                    placement="bottom"
                                    trigger="hover"
                                    speaker={
                                        <Tooltip>
                                            <TText tkey="discount" />
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        <CIcon path={iPercentageBordered2} />
                                    </span>
                                </Whisper>
                                <span test-id="product-discount-value">
                                    {toFixed(Number(product?.discount_value || 0), precision?.value || 2)}
                                    {currency}
                                </span>
                                <div className="divider"></div>
                            </div>
                        ) : null}
                        {productTaxTotal(product) > 0 ? (
                            <div className="item ">
                                <Whisper
                                    placement="bottom"
                                    trigger="hover"
                                    speaker={
                                        <Tooltip>
                                            <TText tkey="tax" />
                                        </Tooltip>
                                    }
                                >
                                    <span>
                                        <CIcon path={iFile2} />
                                    </span>
                                </Whisper>
                                <span>
                                    {productTaxTotal(product)?.toFixed(precision?.value ?? 2)}
                                    {currency}
                                </span>
                                {/*<span>{product?.discount_value}{currency}</span>*/}
                            </div>
                        ) : null}
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReceiptItemName;
