import { t } from "lib/i18n";

import { CategoryTypeEnum, Language } from "config/constants";
import CForm from "components/Form/CForm/CForm";
import TextFormGroup from "../../../../components/Form/TextFormGroup";
import { Col, Grid, Row } from "rsuite";
import ToggleFormGroup from "../../../../components/Form/ToggleFormGroup";
import { productName } from "lib/data-utils";
import { useTableHeight } from "hooks/useTableHeight";
import { UseFormReturnType } from "hooks/useCForm";
import CategorySelect from "../../CategorySelect";
import _ from "lodash";
import React from "react";
import { useSetting } from "hooks/useSetting";
import { useEmenuLanguages } from "hooks/useEmenuLanguages";

interface IProps {
    form: UseFormReturnType;
}

const EMenuTap: FCC<IProps> = ({ form }) => {
    const emenuLangs = useEmenuLanguages();
    const has_modifications = form.form.watch("has_modifications");
    const modifications = form.form.getValues("modifications");
    const height = useTableHeight(170);
    const translateForLangs = useSetting("emenu_lang")?.extra;

    return (
        <>
            <div className="p-3">
                <CForm
                    form={form.form}
                    isLoading={form.isLoading}
                    onSave={form.onSave}
                    noPanel
                    formProps={{
                        layout: "vertical",
                        style: {
                            height: height,
                        },
                    }}
                >
                    <ToggleFormGroup name="emenu_hidden" label={t("hide")} />
                    <CategorySelect
                        name={"emenu_category_id"}
                        renderMenuItem={(label: any, item: any) => {
                            return (
                                <span>
                                    {_.times(item.depth).map((x, i) => (
                                        <span key={i}></span>
                                    ))}
                                    {label}
                                </span>
                            );
                        }}
                        types={[["type", CategoryTypeEnum.PRODUCT]]}
                    />
                    {/*<RemoteSelectFormGroup*/}
                    {/*    remote={{*/}
                    {/*        limit: 999,*/}
                    {/*        url: "category",*/}
                    {/*        filters: {*/}
                    {/*            type: [*/}
                    {/*                ["type", "EMENU"],*/}
                    {/*                ["type", "PRODUCT"],*/}
                    {/*            ],*/}
                    {/*        },*/}
                    {/*    }}*/}
                    {/*    name={"emenu_category_id"}*/}
                    {/*    label={t("category")}*/}
                    {/*    compProps={{ cleanable: true }}*/}
                    {/*/>*/}

                    {emenuLangs.map((lang: string, i: number) => (
                        <div className="rs-form-group">
                            <label className="rs-control-label">{translateForLangs?.[lang]}</label>
                            <Grid fluid>
                                <Row>
                                    <Col xs={12} sm={12} md={12} lg={12} className="pl-0 pr-1">
                                        <TextFormGroup
                                            name={`meta.langs.${lang}.name`}
                                            label={false}
                                            controlWrapperProps={{ className: "w-100" }}
                                            compProps={{ placeholder: t("name"), className: "w-100" }}
                                            errorPlacement="rightEnd"
                                        />
                                    </Col>
                                    <Col xs={12} sm={12} md={12} lg={12} className="pl-0 pr-1">
                                        <TextFormGroup
                                            name={`meta.langs.${lang}.description`}
                                            label={false}
                                            controlWrapperProps={{ className: "w-100" }}
                                            compProps={{ placeholder: t("description"), className: "w-100" }}
                                            errorPlacement="rightEnd"
                                        />
                                    </Col>
                                </Row>
                            </Grid>
                        </div>
                    ))}
                    {has_modifications &&
                        modifications.map((modification: IVariant, index: number) => (
                            <div className="rs-form-group pb-3">
                                <label className="rs-control-label" style={{ display: "block", float: "none" }}>
                                    {productName(modification)}
                                </label>
                                <hr className="mt-0" />
                                <div>
                                    {emenuLangs.map((lang: string, i: number) => (
                                        <div className="rs-form-group" key={i}>
                                            <label className="rs-control-label">{(Language as any)[lang]}</label>
                                            <Grid fluid>
                                                <Row>
                                                    <Col xs={12} sm={12} md={12} lg={12} className="pl-0 pr-1">
                                                        <TextFormGroup
                                                            name={`modifications[${index}].meta.langs.${lang}.name`}
                                                            controlWrapperProps={{ className: "w-100" }}
                                                            label={false}
                                                            compProps={{
                                                                placeholder: t("name"),
                                                                className: "w-100",
                                                            }}
                                                            errorPlacement="rightEnd"
                                                        />
                                                    </Col>
                                                    <Col xs={12} sm={12} md={12} lg={12} className="pl-0 pr-1">
                                                        <TextFormGroup
                                                            name={`modifications[${index}].meta.langs.${lang}.description`}
                                                            controlWrapperProps={{ className: "w-100" }}
                                                            label={false}
                                                            compProps={{
                                                                placeholder: t("description"),
                                                                className: "w-100",
                                                            }}
                                                            errorPlacement="rightEnd"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Grid>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                </CForm>
            </div>
        </>
    );
};

export default EMenuTap;
