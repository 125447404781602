import { t } from "lib/i18n";
import * as yup from "yup";

export const PackageModel = () =>
    yup.object({
        name: yup.string().required(t("required")),
        equal: yup.number().required(t("required")),
        // parent_id: yup.number().typeError(t("required")).required(t("required")),
        type: yup.number(),
    });
