import { t } from "lib/i18n";
import { number, object, string } from "yup";
import { BalanceType } from "../types/balance";

export const dummyAccount = (): Partial<IAccount> => {
    return {
        name: "",
        description: "",
        amount: 0,
        type: BalanceType.CASH,
    };
};

export const AccountModel = object().shape({
    name: string().required(t("required")),
    amount: number().typeError(t("required")),
    type: string(),
    description: string(),
});
