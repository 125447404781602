import { useState } from "react";

export const useLoading = (initialValue: boolean = false) => {
    const [isLoading, setIsLoading] = useState(initialValue);

    const withLoading = async <T = any>(fn: () => Promise<T>): Promise<T> => {
        setIsLoading(true);

        return await fn().finally(() => setIsLoading(false));
    };

    return { isLoading, setIsLoading, withLoading };
};
