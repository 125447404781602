import { createSlice } from "@reduxjs/toolkit";
import { Trash } from "config/constants";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

export interface AppState {
    description: any;
    trash: number | string | boolean;
    fiscal_id: number | string | boolean;
    user_id: number | string | object;
    paymentMethods: number | string | object;
    sale_type_id: number | string | object;
    reason_id: number | string | object;
    suspicious: number | string | object;
    status: number | string | object;
    customer_id: number | string | object;
    product_id: number | string | object;
    table_id: number | string | object;
    courier_id: number | string | object;
    terminal_id: number | string | object;
    confirmFilter: boolean;
    selectedField: any;
}

const initialState: AppState = {
    description: [],
    trash: Trash.OnlyTrash,
    fiscal_id: 1,
    user_id: 99,
    paymentMethods: [],
    sale_type_id: [],
    reason_id: [],
    suspicious: [],
    status: [],
    customer_id: [],
    product_id: [],
    table_id: [],
    courier_id: [],
    terminal_id: [],
    confirmFilter: false,
    selectedField: null,
};

export const receiptFilterSlice = createSlice({
    name: ReduxSlicesNames.RECEIPT_FILTER,
    initialState,
    reducers: {
        setDescription: (state, action) => {
            state.description = action.payload;
        },
        setConfirmFilter: (state, action) => {
            state.confirmFilter = action.payload;
        },
        setSelectedField: (state, action) => {
            state.selectedField = action.payload;
        },
    },
});

export const { setDescription, setConfirmFilter, setSelectedField } = receiptFilterSlice.actions;
export default receiptFilterSlice.reducer;
