import { t } from "lib/i18n";
import * as yup from "yup";

export const SupplierModel = () =>
    yup.object({
        name: yup.string().required(t("required")),
        phone: yup.string(),
        tin: yup.string(),
        description: yup.string(),
    });

export const dummySupplier = (): Partial<ISupplier> => {
    return {
        name: "",
        phone: "",
        tin: "",
        description: "",
    };
};
