import { useProducts } from "hooks/useProducts";
import Money from "../../../components/mini/Money";
import { toFixed } from "lib/utils";

interface IProps {
    p: any;
}

const PriceCell: FCC<IProps> = props => {
    const products = useProducts();
    if (products.loading) {
        return null;
    }

    // const product = _.find(products.data, ["id", props.p.payload?.product_id]);
    let price: number | string = 0;
    if (props.p.type === "Clean") {
        price = toFixed(
            props.p.payload?.clearRps?.reduce(
                (total: number, product: { price: number; count: number }) =>
                    total + (product?.price * product?.count || 1),
                0,
            ),
            2,
        );
    } else {
        price = toFixed((props.p.payload?.price || 0) * (props.p.payload?.count || 1), 2);
    }

    return price ? <Money value={price} /> : <span>--</span>;
};

export default PriceCell;
