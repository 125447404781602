import { t } from "lib/i18n";
import { number, object, string } from "yup";

export const CategoryModel = () =>
    object().shape({
        name: string().required(t("required")),
        color: string().nullable(),
        parent_id: number().nullable(),
        code: string().nullable(),
    });

export const CategoryModalModel = () =>
    object().shape({
        type: string().required(t("required")),
        name: string().required(t("required")),
        color: string().nullable(),
        parent_id: number().nullable(),
        code: string().nullable(),
    });

export const dummyCategory = (): Partial<ICategory> => ({
    name: "",
    parent_id: null,
    color: "00bcd4",
    hidden: 0,
    code: "",
});

export const dummyEMenuCategory = (): Partial<ICategory> => ({
    name: "",
    emenu_hidden: 0,
});
