import { TText } from "components/i18n/TText";

import { Checkbox } from "components/checkbox";
import { calculateFontSize } from "lib/utils";
import { moneyFormatter } from "lib/moneyFormatter";
import { useSetting } from "hooks/useSetting";

interface IProps {
    discount: number;
    discount_period_price: number;
    monthly_period: number;
    period_price: number;
    main: number;
    price_per_month: number;
    onSelect: (dur: number) => void;
    isActive: boolean;
    index: number;
    currency: string;
}

export const PeriodBox: FCC<IProps> = ({
    discount,
    discount_period_price,
    monthly_period,
    period_price,
    onSelect,
    main,
    price_per_month,
    isActive,
    index,
    currency,
}) => {
    // const currency = useCurrency();
    const currencies = useSetting("currency")?.extra?.symbols;
    const _currency = currency?.toLowerCase();

    const _calculateFontSize = (price: number | string, originalPrice: number | string) => {
        const text =
            price + currencies[_currency] + (originalPrice ? "/ " + originalPrice + currencies[_currency] : "");
        return calculateFontSize(text, 30, 0.9);
    };
    if (monthly_period === 3) {
        return (
            <div
                style={{ paddingRight: 0, paddingBottom: 0 }}
                className={`period-box tw-flex-1 ${isActive && "active"}`}
                onClick={() => onSelect(monthly_period)}
            >
                <div className="d-flex flex-column">
                    <h5 className="title">
                        {monthly_period} <TText tkey="monthly" />
                    </h5>
                    <span className="subtitle">
                        <TText tkey="subs" />
                    </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    <span className="price">
                        {moneyFormatter(price_per_month)}
                        {currencies[_currency]}
                    </span>
                    {monthly_period > 1 && discount ? (
                        <span className="original-price d-flex">
                            /{" "}
                            <span className=" d-flex">
                                <span className={"line"} />
                                {moneyFormatter(period_price)}
                                {currencies[_currency]}
                            </span>
                        </span>
                    ) : null}
                </div>

                <div
                    style={{ position: "relative", paddingBottom: "10px" }}
                    className={"d-flex align-items-center justify-content-between"}
                >
                    {!!discount && (
                        <span className={"discount-3-mount"}>
                            %{discount} <TText tkey="discount" />
                        </span>
                    )}
                    <span className={"after-discount"}>
                        {moneyFormatter(discount_period_price)}
                        {currencies[_currency]} <TText tkey="pay" />
                    </span>
                </div>

                <div className={`checkbox-select ${isActive && "active"}`}>{isActive ? <Checkbox /> : null}</div>
            </div>
        );
    } else {
        return (
            <div className={`period-box tw-flex-1 ${isActive && "active"}`} onClick={() => onSelect(monthly_period)}>
                <div className="d-flex flex-column">
                    <h5 className="title">
                        {monthly_period} <TText tkey="monthly" />
                    </h5>
                    <span className="subtitle">
                        <TText tkey="subs" />
                    </span>
                </div>
                <div className="d-flex align-items-center mb-1">
                    {/*{splitNumber?.map()}*/}
                    {/*<span className="price">{price_per_month}{h.currencyFormatByOS}</span>*/}
                    <span
                        className="price"
                        style={_calculateFontSize(
                            moneyFormatter(price_per_month)!,
                            monthly_period >= 1 && discount ? moneyFormatter(period_price)! : "",
                        )}
                    >
                        {moneyFormatter(price_per_month)}
                        {currencies[_currency]}
                    </span>
                    {monthly_period >= 1 && discount ? (
                        <span
                            className="original-price d-flex"
                            style={_calculateFontSize(
                                moneyFormatter(price_per_month)!,
                                monthly_period >= 1 && discount ? moneyFormatter(period_price)! : "",
                            )}
                        >
                            /{" "}
                            <span className=" d-flex">
                                <span className={"line"} />
                                {moneyFormatter(period_price)}
                                {currencies[_currency]}
                            </span>
                        </span>
                    ) : null}
                </div>
                <div className={"d-flex align-items-center justify-content-between"}>
                    {!!discount && (
                        <span className={"discount"}>
                            {discount}% <TText tkey="discount" />
                        </span>
                    )}
                    <span className={"after-discount"}>
                        {moneyFormatter(discount_period_price)}
                        {currencies[_currency]} <TText tkey="pay" />
                    </span>
                </div>
                <div className={`checkbox-select ${isActive && "active"}`}>{isActive ? <Checkbox /> : null}</div>
            </div>
        );
    }
};
