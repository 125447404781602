import { t } from "lib/i18n";
import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";

export const TransferSchema: ValidationSchema = {
    from_storage_id: ruleset.combine([ruleset.required, ruleset.notEqual("storage_id", t("not_same_destination"))]),
    storage_id: ruleset.required,
    items: [
        {
            product_id: ruleset.required,
            unit_id: ruleset.required,
            quantity: ruleset.combine([ruleset.required, ruleset.gt(0)]),
        },
    ],
};
