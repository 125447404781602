import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { useAppSelector } from "hooks/useRedux";
import DataTable from "components/DataTable/DataTable";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { Input, InputGroup } from "rsuite";
import CIcon from "components/CIcon";
import { iCheckBoxChecked, iClose, iSearch } from "assets/icons/Icons";
import Highlighter from "react-highlight-words";
import { Colors } from "config/colors";
import { useRemoteConfig } from "hooks/useRemoteConfig";
import { FC, useState } from "react";
import { useFormContext } from "react-hook-form";
import { convertToSlug } from "lib/convertToSlug";

interface ISelectGovCodeDrawerProps extends ICDrawerProps {
    govCode: string;
}

export const SelectGovCodeDrawer: FC<ISelectGovCodeDrawerProps> = props => {
    const govCodes = useRemoteConfig("govCodeUz").config as IGovCodeUzScheme;
    const { language } = useAppSelector(state => state.app.brandData);
    const [searchText, setSearchText] = useState("");
    const searchTextTrim = convertToSlug(searchText);

    const form = useFormContext();
    const selectedGovCode = form.getValues(props.govCode);

    const codes = language === "ru" ? govCodes["ru"] : govCodes["default"];

    const cm = useColumns<IGovCodeUz>("gov_code", [
        {
            title: t("name"),
            key: "gov_code_name",
            flexGrow: 3,
            // fullText: true,
            render: row => (
                <div className="d-flex tw-items-center tw-gap-2 tw-h-full">
                    {row.gov_code === selectedGovCode && (
                        <div
                            className="d-flex align-items-center rounded-circle justify-content-center flex-shrink-0"
                            style={{ background: Colors.CobaltBlue, width: "16px", height: "16px" }}
                        >
                            <CIcon
                                className="ps-checked-icon"
                                path={iCheckBoxChecked}
                                style={{ color: "white" }}
                                size={0.45}
                            />
                        </div>
                    )}
                    <Highlighter
                        highlightClassName="search-highlighter"
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={row.gov_code_name || "─"}
                        className="tw-text-sm tw-break-words tw-whitespace-break-spaces"
                    />
                </div>
            ),
        },
        {
            title: t("gov_code"),
            key: "gov_code",
            flexGrow: 1,
            render: row => (
                <Highlighter
                    highlightClassName="search-highlighter"
                    searchWords={[searchText]}
                    autoEscape={true}
                    textToHighlight={row.gov_code || "─"}
                    className="tw-text-sm tw-items-center tw-flex tw-h-full"
                />
            ),
        },
    ]);

    return (
        <CDrawer size={"md"} title={t("gov_code")} {...props}>
            <div className={"tw-px-4 tw-py-1 tw-mb-4"}>
                <InputGroup inside>
                    <Input
                        test-id="product-selector-search-input"
                        autoFocus={true}
                        onChange={setSearchText}
                        size="lg"
                        value={searchText}
                        placeholder={t("search")}
                    />
                    <InputGroup.Addon>
                        {searchText ? (
                            <CIcon className="tw-cursor-pointer" onClick={() => setSearchText("")} path={iClose} />
                        ) : (
                            <CIcon path={iSearch} />
                        )}
                    </InputGroup.Addon>
                </InputGroup>
            </div>
            {codes ? (
                <DataTable<IGovCodeUz>
                    dp={
                        {
                            data: codes.filter(
                                code =>
                                    convertToSlug(code.gov_code_name).includes(searchTextTrim) ||
                                    code.gov_code.includes(searchTextTrim),
                            ),
                        } as any
                    }
                    rowPadding="4px 8px"
                    columns={cm.columns}
                    tableProps={{
                        bordered: true,
                        rowClassName: "tw-cursor-pointer",
                        // rowHeight: d => (d.gov_code_name.length > 120 ? 60 : 40),
                        autoHeight: true,
                        onRowClick: row => {
                            form.setValue(props.govCode, row.gov_code);
                            props.hide();
                        },
                    }}
                />
            ) : (
                <div className={"tw-px-4"}>{t("no_data")}</div>
            )}
        </CDrawer>
    );
};
