export const dummyTerminal = (): Partial<ITerminal> => {
    return {
        name: "",
        password: "",
        status: 1,
        sale_types: [],
        halls: [],
        can_receive_notifications: false,
    };
};
