import { TText } from "components/i18n/TText";
import React from "react";
import { toggleReceiptItemActivityByType } from "redux/features/receiptConstructor";
import { Checkbox, SelectPicker } from "rsuite";

import { IReceiptItem } from "../../types";
import { allowedTypesForChangeFontSize, fontSizeOptions } from "pages/settings/receipt/common/utils";
import { handleChangeTextSizeAC } from "redux/features/constructor/constructor-thunks";
import { useAppDispatch } from "hooks/useRedux";

const RCustomerComp = (item: IReceiptItem) => {
    const dispatch = useAppDispatch();

    const handleToggleActivityByType = (key: string | undefined) => {
        if (key) {
            dispatch(toggleReceiptItemActivityByType({ key: key as keyof IReceiptItem, type: "customer" }));
        }
    };
    return (
        <div className={"tw-flex tw-justify-between mr-2"}>
            <div>
                <span>
                    <TText tkey="print_customer_name" />
                </span>
                <Checkbox
                    onChange={(v: string | number | undefined, checked, event) => {
                        event.preventDefault();

                        handleToggleActivityByType(v as string);
                    }}
                    value={"balance"}
                    checked={!!item?.balance}
                >
                    <TText tkey="balance" />
                </Checkbox>
                <Checkbox
                    onChange={(v: string | number | undefined, checked, event) => {
                        event.preventDefault();

                        handleToggleActivityByType(v as string);
                    }}
                    value={"phone"}
                    checked={!!item?.phone}
                >
                    <TText tkey="phone" />
                </Checkbox>

                <Checkbox
                    onChange={(v: string | number | undefined, checked, event) => {
                        event.preventDefault();

                        handleToggleActivityByType(v as string);
                    }}
                    value={"address"}
                    checked={!!item?.address}
                >
                    <TText tkey="address" />
                </Checkbox>
                <Checkbox
                    onChange={(v: string | number | undefined, checked, event) => {
                        event.preventDefault();
                        handleToggleActivityByType(v as string);
                    }}
                    value={"description"}
                    checked={!!item?.description}
                >
                    <TText tkey="description" />
                </Checkbox>
            </div>
            {allowedTypesForChangeFontSize.includes(item.type) && (
                <SelectPicker
                    cleanable={false}
                    onSelect={v => dispatch(handleChangeTextSizeAC(v, item))}
                    value={item.textSize}
                    data={fontSizeOptions}
                    size="sm"
                    placement="autoVertical"
                    searchable={false}
                />
            )}
        </div>
    );
};

export default RCustomerComp;
