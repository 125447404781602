import { useEffect } from "react";
import { RemoteConfigState } from "redux/features/remoteConfig";
import { useAppDispatch, useAppSelector } from "./useRedux";
import { fetchRemoteConfig } from "redux/features/remoteConfig/thunks";

export type RemoteConfigKeys = "learnData" | "importScheme" | "govCodeUz";

export const useRemoteConfig = <T extends RemoteConfigKeys>(name: T) => {
    const dispatch = useAppDispatch();
    const config: RemoteConfigState["configs"][T] = useAppSelector(state => state.remoteConfig.configs[name]);

    const status = useAppSelector(state => state.remoteConfig.status);
    const error = useAppSelector(state => state.remoteConfig.error);

    useEffect(() => {
        if (!Object.keys(config).length && status[name] === "idle") {
            dispatch(fetchRemoteConfig(name));
        }
    }, [config, dispatch, name, status]);

    return { config, status, error };
};
