import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React, { useState } from "react";
import TextFormGroup from "components/Form/TextFormGroup";
import { Radio } from "rsuite";
import { ModificationModalCompProps } from "pages/products/dishes/edit/tabs/modifications/modal/ModificationModal";
import RadioFormGroup from "components/Form/RadioFormGroup";
import { Colors } from "config/colors";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

interface RadioInputProps extends React.HTMLAttributes<HTMLInputElement> {
    "test-id": string;
}

const ModifierModalTypeField: FCC<ModificationModalCompProps> = ({ cameFrom, defaultData }) => {
    const ctx = useCFormContext();
    const [isHide, setHide] = useState<boolean>(!defaultData.type);
    const onChangeOne = () => {
        setHide(true);
        ctx.setValue(`min_select`, 1);
        ctx.setValue(`max_select`, 1);
    };

    const onChangeMulti = () => {
        setHide(false);
        ctx.setValue(`min_select`, Number(defaultData.min_select));
        ctx.setValue(`max_select`, Number(defaultData.max_select));
    };

    const commonInputProps = {
        compProps: {
            style: {
                maxWidth: 100,
            },
        },
    };
    const radioInfoStyle = () => ({ color: Colors.SlateSteelGray, fontStyle: "italic", paddingLeft: 35 });

    return (
        <div className={cameFrom || !ctx.getValues("id") ? "d-block" : "d-none"}>
            <h6>
                <TText tkey="modifier_type" />
            </h6>

            <RadioFormGroup
                name={`type`}
                compProps={{
                    defaultValue: ctx.getValues("type"),
                    value: ctx.getValues("type"),
                }}
            >
                <Radio
                    onChange={() => onChangeOne()}
                    value={0}
                    disabled={cameFrom === 1}
                    inputProps={
                        {
                            "test-id": "modifier-type-0",
                        } as RadioInputProps
                    }
                >
                    <TText tkey="one" />
                </Radio>
                <small style={radioInfoStyle()}>
                    <TText tkey="one_desc" />
                </small>
                <Radio
                    style={{ paddingTop: 8 }}
                    onChange={() => onChangeMulti()}
                    value={1}
                    disabled={cameFrom === 1}
                    inputProps={
                        {
                            "test-id": "modifier-type-1",
                        } as RadioInputProps
                    }
                >
                    <TText tkey="modifier_group_type_multi" />
                </Radio>
                <small style={radioInfoStyle()}>
                    <TText tkey="many_desc" />
                </small>
            </RadioFormGroup>

            <div className={isHide ? "d-none" : "d-block"}>
                <TextFormGroup
                    name={`min_select`}
                    type="number"
                    label={t("set_min")}
                    style={{ marginBottom: 10 }}
                    {...commonInputProps}
                    labelProps={{ style: { maxWidth: 110, textAlign: "left" } }}
                />
                <TextFormGroup
                    name={`max_select`}
                    type="number"
                    label={t("set_max")}
                    {...commonInputProps}
                    labelProps={{ style: { maxWidth: 110, textAlign: "left" } }}
                />
            </div>
        </div>
    );
};

export default ModifierModalTypeField;
