import LoadingAnimation from "components/LoadingAnimation";
import React, { useCallback, useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable, DropResult } from "react-beautiful-dnd";
import { Nav } from "rsuite";
import CIcon from "./CIcon";
import _ from "lodash";
import { iExclamationCircle2 } from "assets/icons/Icons";

interface IProps<T extends any[]> {
    tabs: {
        [I in keyof T]: ITab<T[I]>;
    };
    setActiveTab?: (id: number) => void;
    activeTab?: number;
    loading?: boolean;
    className?: string;
    sortable?: boolean;
    onSortEnd?: (result: DropResult) => void;
}

export interface ITab<T> {
    label: any;
    disabled?: boolean;
    props?: NoInfer<T>;
    id?: number;
    icon?: any;
    Component: FCC<T>;
    testId?: string;
    hide?: boolean;
    hasError?: boolean;
}

const TabsView = <T extends any[]>({ setActiveTab, tabs, loading, ...props }: IProps<T>) => {
    const [activeTab, _setActiveTab] = useState(0);
    const at = props.activeTab ?? activeTab;
    const filteredTabs = tabs.filter(t => !t.hide);
    const setAt = useCallback(
        (index?: number) => {
            if (!_.isNil(index)) {
                _setActiveTab(+index);
                setActiveTab?.(+index);
            }
        },
        [setActiveTab],
    );

    useEffect(() => {
        if (at > 0 && !filteredTabs[at]) {
            setAt(filteredTabs.length - 1);
        }
        // eslint-disable-next-line
    }, [at, tabs, setAt]);

    if (loading) {
        return <LoadingAnimation />;
    }

    if (!filteredTabs[at]) {
        return null;
    }

    const Component = filteredTabs[at].Component;

    return (
        <div style={{ height: "100%" }} className={props.className || ""}>
            <Nav appearance="subtle" className="w-100">
                <DragDropContext onDragEnd={(result: DropResult) => props.onSortEnd?.(result)}>
                    <Droppable droppableId="test" direction="horizontal">
                        {(provided: any) => (
                            <div ref={provided.innerRef} className="d-flex" {...provided.droppableProps}>
                                {filteredTabs.map((tab, i) => (
                                    <Draggable
                                        key={tab.id || tab.label}
                                        isDragDisabled={!props.sortable}
                                        draggableId={tab.id?.toString() || tab.label}
                                        index={i}
                                    >
                                        {provided => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                            >
                                                <Nav.Item
                                                    style={{ paddingLeft: "15px" }}
                                                    eventKey={`${i}`}
                                                    key={tab.id || tab.label}
                                                    active={i === at}
                                                    onSelect={i => i && setAt(+i)}
                                                    disabled={tab.disabled}
                                                    test-id={tab.testId}
                                                >
                                                    <div className="tw-flex tw-items-center tw-gap-2">
                                                        {tab.icon ? (
                                                            <CIcon style={{ marginRight: 5 }} path={tab.icon} />
                                                        ) : null}
                                                        <div style={{ paddingLeft: "0px" }}>{tab.label}</div>
                                                        {tab.hasError && (
                                                            <span className="tw-relative tw-flex tw-h-3 tw-w-3">
                                                                <span className="tw-animate-ping tw-absolute tw-inline-flex tw-h-full tw-w-full tw-rounded-full tw-bg-red-400 tw-opacity-75"></span>
                                                                {/* <span className="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-bg-red-500"></span> */}
                                                                <CIcon
                                                                    path={iExclamationCircle2}
                                                                    className="tw-relative tw-inline-flex tw-rounded-full tw-h-3 tw-w-3 tw-text-red-500"
                                                                />
                                                            </span>
                                                        )}
                                                    </div>
                                                </Nav.Item>
                                            </div>
                                        )}
                                    </Draggable>
                                ))}
                            </div>
                        )}
                    </Droppable>
                </DragDropContext>
            </Nav>
            <Component {...filteredTabs[at].props} />
        </div>
    );
};

export default TabsView;
