import { t } from "lib/i18n";
import CustomerBalanceModal from "components/Drawers/CustomerBalanceModal";
import { EMPTY_DATA } from "config/main";
import parsePhoneNumber from "libphonenumber-js";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import CreateButton from "components/DataTable/CreateButton";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import SelectFilter from "components/DataTable/Filters/SelectFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import CustomerDrawer from "components/Drawers/CustomerDrawer";
import DebtDrawer from "components/Drawers/DebtDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import { getLabel } from "components/Form/form-utils";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import { Constants, ExportType } from "config/constants";
import { iExchange, iMoney } from "assets/icons/Icons";
import Money from "components/mini/Money";
import { parseCustomerDate } from "lib/customerDate/parseCustomerDate";
import { isValidCustomerDate } from "lib/customerDate/isValidCustomerDate";
import { formatCustomerDate } from "lib/customerDate/formatCustomerDate";

const customerTypes: Record<string, string> = {
    "<": "customers_owe_to_you",
    ">": "you_owe_to_customers",
};

const CustomerPage = () => {
    const history = useHistory();
    const dp = useDataProvider({
        url: "customer",
        useUrl: true,
        with: ["group", "balance", "cashback_balance"],
        sort: ["created_at", "1"],
    });
    const balanceModal = useDrawer();

    const refineDOB = (c: ICustomer) => {
        const date = parseCustomerDate(c.date_of_birth);

        return isValidCustomerDate(date) ? formatCustomerDate(date) : "--";
    };

    const cm = useColumns<any>("customer", [
        {
            key: "name",
            align: "left",
            onClick: d => cd.setDataId(d.id),
            flexGrow: 3,
        },
        {
            key: "group.name",
            align: "left",
            flexGrow: 3,
            title: _.capitalize(t("group")),
        },
        {
            key: "phone",
            render: (e: ICustomer) => (e.phone ? parsePhoneNumber(e.phone)?.formatInternational() : EMPTY_DATA),
            flexGrow: 2,
            minWidth: 190,
        },
        { key: "addresses", align: "left", render: (e: ICustomer) => _.head(e.addresses), flexGrow: 2, minWidth: 200 },
        {
            key: "balance.amount",
            onClick: d => venueModal.setData(d),
            title: t("balance"),
            type: "money",
            flexGrow: 2,
        },
        {
            key: "cashback_balance.amount",
            onClick: d => venueModal.setData(d),
            title: t("cashback"),
            type: "money",
            hide: true,
            render: e => {
                return <Money value={e.cashback_balance?.amount} />;
            },
        },
        {
            key: "date_of_birth",
            title: _.capitalize(t("date_of_birth")),
            render: refineDOB,
            minWidth: 100,
        },
        {
            key: "gender",
            title: _.capitalize(t("gender")),
            render: (c: ICustomer) => Constants.genderName[c.gender],
        },
        { key: "code", title: _.capitalize(t("code")) },
        { key: "receipt_count", title: _.capitalize(t("total_receipt")), minWidth: 100 },
        { key: "source" },
        { key: "spent", title: _.capitalize(t("total_spent")), type: "money", minWidth: 130 },
        { key: "description", title: _.capitalize(t("description")), hide: true, minWidth: 130 },

        optionsColumn({
            dp,
            edit: "/marketing/customers/edit/:id",
            delete: "/customer/:id",
            preventDelete: (a: ICustomer) => a?.balance?.amount !== 0,
            canEdit: "customer_manage_edit",
            canDelete: "customer_manage_delete",
            others: () => [
                {
                    icon: iExchange,
                    label: t("transactions"),
                    onClick: (d: any) => {
                        history.push({ pathname: `/finance/customer-transactions`, state: { customer: d } });
                    },
                },
                {
                    icon: iMoney,
                    label: t("add_transaction"),
                    onClick: (d: any) => balanceModal.setData(d),
                },
            ],
        }),
    ]);

    const cd = useDrawer({ backdrop: false, overflowAuto: true });

    const venueModal = useDrawer({ backdrop: false, overflowAuto: true });

    return (
        <div className="h-100">
            {useTitle(t("customers"), dp.total)}
            <HeaderRight
                cm={cm}
                dp={dp}
                reload
                exportType={ExportType.CUSTOMER}
                haveExportList={true}
                exportWithOptions={[{ name: "Excel", columns: cm.columns, dp }]}
                // export={() => exportFile(dp, cm.columns, t("customers"))}
                importOptions={{
                    template: "Customer temp",
                    detail: "customer",
                    action: "customer/import",
                    onSuccess: () => dp.revalidate(),
                }}
            />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter placeholder={t("name")} fields="name" />
                    <SearchFilter placeholder={t("phone")} fields="phones" />
                    <RemoteCheckListFilter remote={{ url: "customers-group" }} fields="group_id" searchField="name" />
                    <SelectFilter
                        fields="amount"
                        options={customerTypeOptions()}
                        comparison_value={0}
                        pickerProps={{ placeholder: t("type") }}
                        hasAllOption={true}
                    />
                </div>
                <CreateButton to={"/marketing/customers/create"} canCreate={"customer_manage_create"} />
            </FiltersContainer>
            <CustomerDrawer {...cd} />
            {venueModal.isOpen && <DebtDrawer {...venueModal} />}
            <DataTable dp={dp} columns={cm.columns} />
            <CustomerBalanceModal {...balanceModal} />
        </div>
    );
};

export default CustomerPage;

export const customerTypeOptions = () =>
    Object.entries(customerTypes).map(([value, label]) => ({ value, label: getLabel(label) }));
