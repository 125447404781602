import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { Button, Modal } from "rsuite";
import CIcon from "../../CIcon";
import { iAlert, iExclamationCircle2 } from "assets/icons/Icons";
import { IConfirmModal } from "./MDefaultComp";
import { Colors } from "config/colors";
const deleteInfo = [t("deleted_items_return_to_stock"), t("transaction_will_be_deleted"), t("report_will_be_changed")];
const MReceiptDelete: FCC<IConfirmModal> = ({ content, onConfirm, isLoading, ...props }) => {
    return (
        <>
            <Modal.Header style={{ paddingRight: 0 }}>
                <div className="d-flex align-items-center justify-content-center">
                    <div
                        className="d-flex align-items-center justify-content-center"
                        style={{ borderRadius: "50%", background: Colors.SoftRose, height: "56px", width: "56px" }}
                    >
                        <div
                            className="d-flex align-items-center justify-content-center"
                            style={{ borderRadius: "50%", background: Colors.SoftRose, height: "40px", width: "40px" }}
                        >
                            <CIcon
                                size={1}
                                path={iExclamationCircle2}
                                style={{ color: Colors.BrightRed, fillRule: "evenodd" }}
                            />
                        </div>
                    </div>
                </div>
            </Modal.Header>
            <Modal.Body>
                <h6 className="d-flex justify-content-center mb-3">
                    <span style={{ color: Colors.CobaltBlue, paddingRight: "6px" }}>#{props.data?.id}</span>
                    <TText tkey="are_you_sure_delete_receipt" />
                </h6>
                {deleteInfo.map(info => (
                    <div key={info} className="d-flex align-items-center">
                        <CIcon style={{ color: Colors.VividOrange, marginRight: "10px" }} path={iAlert} />
                        {info}
                    </div>
                ))}

                <div
                    className="d-flex align-items-center"
                    style={{
                        background: Colors.OffWhite,
                        marginTop: "24px",
                        padding: "4px 8px",
                        borderRadius: "4px",
                        width: "fit-content",
                    }}
                >
                    {/*<CIcon path={iAlert} style={{ color: "#F39C12", marginRight: "7px" }} />*/}
                    <span style={{ color: Colors.BrightOrange }}>
                        <TText tkey="after_deleted_it_cannot_be_recovered" />
                    </span>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={isLoading}
                    test-id="delete-cancel-btn"
                    onClick={props.onClose}
                    className="rs-btn05"
                    style={{
                        width: "48%",
                        height: "40px",
                        background: Colors.White,
                        fontWeight: "bold",
                        borderRadius: "8px",
                        border: "solid 1px #D0D5DD",
                    }}
                >
                    <TText tkey="abort" />
                </Button>
                <Button
                    className="rs-btn05"
                    loading={isLoading}
                    appearance="primary"
                    test-id="delete-confirm-btn"
                    onClick={e => onConfirm(props?.data || props?.dataId, e)}
                    color="red"
                    style={{ fontWeight: "bold", borderRadius: "8px", width: "48%", height: "40px" }}
                >
                    <TText tkey="delete" />
                </Button>
            </Modal.Footer>
        </>
    );
};
export default MReceiptDelete;
