export const enum ErrorCode {}

export const enum ErrorType {
    IS_OFFLINE = "is_offline",
    SERVER_SIDE = "server_side",
    AUTH = "auth",
    VALIDATION = "validation",
    BRAND_NOT_SET = "brand_not_set",
    BRAND_NOT_FOUND = "brand_not_found",
    CONSTRAINT = "ConstraintError",
    RECEIPT_NOT_SAVED = "receipt not saved",
    PAYMENT_FAILED = "payment_failed",
    PERMISSION = "permission",
    EMPTY = "Empty error",
}
