import CChart from "components/Chart";
import { IDataProvider } from "components/DataTable/useDataProvider";
import { getLabel } from "components/Form/form-utils";
import { t } from "lib/i18n";
import Widget from "components/Widget";
import * as Highcharts from "highcharts";
import {
    getChartPointLabel,
    getPointerLabel,
    groupByPeriod,
    IDatesReportData,
    PERIODS,
    PeriodsType,
} from "lib/chart-utils";
import { useState } from "react";
import { Button } from "rsuite";
import { Colors } from "config/colors";
import { getFormatedDate } from "lib/utils";
import { useCurrency } from "hooks/useCurrency";

interface IProps {
    tab: keyof IDatesReportData;
    dp: IDataProvider;
    dpOld: IDataProvider;
}

const SalesChartWidget: FCC<IProps> = props => {
    const [period, setPeriod] = useState<PeriodsType>(PERIODS[0]);
    const title = getLabel(props.tab);
    const currency = useCurrency();
    /**
     * since each chart has different data types such as total earning or number of guest or even average time,
     * data needs to be formatter accordingly
     */
    const type =
        props.tab === "average_time" ? "time" : ["total_guests", "total_receipt"].includes(props.tab) ? "" : "money";

    const getSeries = (_dp: IDataProvider<IDatesReportData>, i: number) => {
        const data = groupByPeriod(period, _dp.data);

        return {
            type: "areaspline",
            name: title,
            // yAxis: i as any,
            xAxis: i as any,

            data: data.map(d => ({
                y: d[props.tab],
                x: new Date(d.date)?.getTime(),
                name: getChartPointLabel(d, period),
            })),
        } as Highcharts.Series;
    };

    const buttons = (
        <div style={{ alignSelf: "left" }}>
            {PERIODS.map(p => (
                <Button
                    key={p}
                    color={p === period ? "cyan" : undefined}
                    size="xs"
                    className="mr-3"
                    onClick={() => setPeriod(p)}
                >
                    {t(p)}
                </Button>
            ))}
        </div>
    );

    return (
        <Widget title={title} headerButtons={buttons} className="mb-3">
            <CChart
                series={[getSeries(props.dpOld, 1), getSeries(props.dp, 0)]}
                options={{
                    colors: [Colors.Apricot, Colors.CornflowerBlue],
                    tooltip: {
                        useHTML: true,
                        headerFormat: "<small>{point.key}</small><table>",
                        pointFormatter() {
                            return getPointerLabel(this, type, currency);
                        },
                        footerFormat: "</table>",
                    },
                    xAxis: [
                        {
                            type: "datetime",
                            dateTimeLabelFormats: {
                                day: "%e %b %Y",
                            },
                            labels: {
                                formatter: data => getFormatedDate(data.value),
                            },
                        },
                        {
                            type: "datetime",
                            visible: false,
                        },
                    ],
                }}
            />
        </Widget>
    );
};

export default SalesChartWidget;
