import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { Badge, Nav, SelectPicker, Sidebar, Sidenav } from "rsuite";
import SidebarHeader from "./SidebarHeader";
import { useIntegrations, usePackages } from "providers/PackageProvider";
import { menuTitleByType } from "config/constants";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { setActiveMenuItem } from "redux/features/app";
import { depot } from "@cloposcom/libs";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { config } from "config/Config";
import { updateBaseUrl } from "lib/pure";
import CIcon from "../../components/CIcon";
import "./LayoutSidebar.scss";
import { SidebarBrandSelector } from "./SidebarBrandSelector";
import { setCurrentVenue } from "redux/features/venue";
import { Colors } from "config/colors";
import { useRoutes } from "hooks/useRoutes";
import { IRoute, IRouteWithComp, IRouteWithSubRoutes } from "types/routes";
import { useSetting } from "hooks/useSetting";
import { toggleIsSidebarExpanded } from "redux/features/ui";

function isRouteWithComp(route: IRoute): route is IRouteWithComp {
    return (route as IRouteWithComp).Comp !== undefined;
}

const LayoutSidebar: FCC = () => {
    const dispatch = useAppDispatch();
    const loc = useLocation();
    const packages = usePackages();
    const routes = useRoutes();
    const brandType: string = useSetting("brand_type")?.value;
    const auth = useAuth();
    const { venues, currentVenueId } = useAppSelector(state => state.venue);
    const [isVenueChangeLoading, setVenueLoading] = useState(false);
    const { isFullScreen, isSidebarExpanded } = useAppSelector(state => state.ui);
    const openKeys = useAppSelector(state => state.app.active_menu_item);
    const setOpenKeys = (data: string[]) => dispatch(setActiveMenuItem(data));
    // const brandNames = config.getBrands().map(item => ({ label: item, value: item }));

    const integrations = useIntegrations();

    function isActive(path: string, parent?: boolean) {
        const p = parent ? (path !== "/" ? "/" + path.split("/")[1] : path) : path !== "/" ? `${path}/` : path;
        return p === "/" ? loc.pathname === p : `${loc.pathname}/`.startsWith(p);
    }

    /**
     * Checking if route has any params then adding the params to route's origin,
     * this solves issue of route having two different paths (one with params and one without)
     * Now if any route has params, it will be inserted to navigation,
     * so that navigation will point to correct path with params
     * @param path
     * @param parent
     */
    function getRouteWithParam(path: string, parent?: boolean) {
        if (isActive(path, parent) && Object.keys(loc.search).length) {
            return `${loc.pathname}${loc.search}`;
        }
        return path;
    }

    const checkIntegrationAvailability = (route: IRouteWithSubRoutes | IRouteWithComp) => {
        return route.integrations?.every(
            integration =>
                integrations.findIndex((_integration: IIntegration) => _integration.service_name === integration) !==
                -1,
        );
    };

    function hasModules(route: IRouteWithSubRoutes | IRouteWithComp) {
        // if (route.modules?.length) {
        //     const modules = store.getState().packages.modules?.map((module: any) => module.name);
        //     const hasModule = route.modules?.every((module: string) => modules?.includes(module));
        //
        //     if (!hasModule) {
        //         return false;
        //     }
        // }

        if (route.integrations && !checkIntegrationAvailability(route)) {
            return false;
        }

        let hasPermission: boolean;
        if (isRouteWithComp(route) && route.permissions) {
            if (!Array.isArray(route.permissions)) {
                hasPermission = route.permissions(auth.hasAbility);
            } else {
                hasPermission = auth.hasAbility(route.permissions);
            }
        } else {
            hasPermission = true;
        }

        const isBothExist = route.module && route.packages && hasPermission;
        return (
            hasPermission &&
            (isBothExist
                ? hasPackage(route) || packages?.limitation?.[route.module!]
                : route.packages
                  ? hasPackage(route)
                  : route.module
                    ? packages?.limitation?.[route.module]
                    : true)
        );
    }

    function hasPackage(route: IRouteWithSubRoutes | IRouteWithComp) {
        const pp = packages?.subscription?.package?.name;
        return route.packages ? route.packages?.includes(pp!) : true;
    }

    function isRestaurant(route: any) {
        if (route.type) {
            return route.type === brandType;
        }
        return true;
    }

    function onChangeVenue(venueID: string | null) {
        setVenueLoading(true);

        if (venueID) {
            depot.setItem("lastActiveVenue", venueID);
            dispatch(setCurrentVenue(+venueID));
            setVenueLoading(false);
            updateBaseUrl(config.brand, +venueID!);
        }
    }

    const handleToggleSidebar = () => {
        dispatch(toggleIsSidebarExpanded());
    };

    useEffect(() => {
        const isWidthLess = window.innerWidth < 1050;
        if (isWidthLess) {
            handleToggleSidebar();
        }
    }, []);
    // const venueListArr = getVenuesList() || [];
    return (
        <>
            {isFullScreen ? null : (
                <div style={{ display: "flex", flexDirection: "column", backgroundColor: Colors.NavyBlue }}>
                    <Sidebar
                        id="CL_sidebar_menu"
                        width={isSidebarExpanded ? 184 : 56}
                        collapsible
                        style={{ zIndex: 20, background: "1F274F", display: "flex", flex: 1, height: 0 }}
                        className={classNames("d-print-none z-1  d-flex flex-column", {
                            // "border-right": expand
                        })}
                    >
                        <Sidenav
                            expanded={isSidebarExpanded}
                            openKeys={openKeys}
                            onOpenChange={keys => {
                                setOpenKeys(
                                    typeof keys.at(-1) === "undefined" ? [] : [keys.at(-1) as (typeof keys)[number]],
                                );
                            }}
                            className={classNames("flex-fill ", {
                                // "overflow-auto ": expand,
                                "p-1": !isSidebarExpanded,
                            })}
                        >
                            <Sidenav.Header>
                                <SidebarHeader onChange={handleToggleSidebar} expand={isSidebarExpanded} />
                                {isSidebarExpanded && venues?.length > 1 && (
                                    <div className="venue-selection">
                                        {/*TODO: type this properly */}
                                        <label>
                                            <TText tkey="venue" />:
                                        </label>
                                        <SelectPicker
                                            data={(venues || []) as any}
                                            value={currentVenueId as any}
                                            defaultValue={currentVenueId as any}
                                            className={"venue-list"}
                                            placement={"bottomEnd"}
                                            menuClassName={"venue-list-item"}
                                            menuStyle={{
                                                background: Colors.LightPeriwinkle,
                                                borderRadius: 0,
                                                paddingTop: 0,
                                                width: 184,
                                            }}
                                            appearance="subtle"
                                            placeholder={t("venue")}
                                            disabled={isVenueChangeLoading}
                                            searchable={false}
                                            cleanable={false}
                                            style={{ width: 165 }}
                                            onChange={onChangeVenue}
                                        />
                                    </div>
                                )}
                            </Sidenav.Header>

                            <Sidenav.Body
                                className={`clopos-scroll ${isSidebarExpanded ? "overflow-auto" : ""}`}
                                style={{
                                    padding: isSidebarExpanded ? "6px 12px 0 12px" : 0,
                                    flex: 1,
                                    //`calc(100vh - 95px- ${brandNames.length > 2 ? "36px" : "10px"})`,
                                    height: 0,
                                }}
                            >
                                <Nav>
                                    {routes
                                        .filter(hasModules)
                                        .filter(
                                            filteredRoutes => filteredRoutes.subroutes.filter(hasModules).length > 0,
                                        )
                                        .map(r => (
                                            <Nav.Menu
                                                id={r.id}
                                                key={r.title}
                                                eventKey={r.id}
                                                noCaret
                                                trigger={["click", "hover"]}
                                                title={
                                                    <Badge content={!!r?.isNew}>
                                                        <span className="r--10" test-id="menu-name">
                                                            {r.key
                                                                ? menuTitleByType.menuTitleVariation[r.key][brandType]
                                                                : r.title}
                                                        </span>
                                                    </Badge>
                                                }
                                                icon={
                                                    <CIcon
                                                        style={{
                                                            margin: isSidebarExpanded ? "0 8px 0 0 " : "0 auto",
                                                            color: "white",
                                                        }}
                                                        size={0.7}
                                                        path={r.icon as string}
                                                    />
                                                }
                                                placement="rightStart"
                                                className={classNames({
                                                    active: isActive(r.subroutes[0].path, true),
                                                    // FIX: This case only works if title is key. Normally don't work, but works on i18n_debug mode
                                                    "tw-bg-white/5 tw-rounded ": r.title === openKeys[0],
                                                })}
                                            >
                                                {r.subroutes
                                                    .filter(sub => hasModules(sub) && !sub.hide && isRestaurant(sub))
                                                    .map(subr => {
                                                        if (!subr.title) return null;

                                                        const id = subr.path?.replace(/\//g, "_");

                                                        return (
                                                            <Nav.Item
                                                                id={id}
                                                                test-id={id}
                                                                key={subr.path}
                                                                active={isActive(subr.path)}
                                                                as={WrappedNavLink}
                                                                to={getRouteWithParam(subr.path)}
                                                                eventKey={id}
                                                                className="d-flex"
                                                            >
                                                                <Badge content={!!subr.isNew} className="r--10">
                                                                    <span>{subr.title}</span>
                                                                </Badge>
                                                            </Nav.Item>
                                                        );
                                                    })}
                                            </Nav.Menu>
                                        ))}
                                </Nav>
                            </Sidenav.Body>
                        </Sidenav>

                        {/*<NavToggle expand={expand} onChange={() => setExpand(e => !e)} />*/}
                    </Sidebar>
                    <SidebarBrandSelector expand={isSidebarExpanded} />
                </div>
            )}
        </>
    );
};
export default LayoutSidebar;

const WrappedNavLink = React.forwardRef((props: any, ref) => {
    const newProps = { ...props };
    delete newProps["aria-current"];
    return <NavLink ref={ref} {...newProps} />;
});
