import { iCheckBoxChecked, iClose } from "assets/icons/Icons";
import CForm from "components/Form/CForm/CForm";
import SelectFormGroup from "components/Form/SelectFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import CFormGroup from "components/Form/CFormGroup";
import UploaderFormGroup from "components/Form/FileUploadFormGroup";
import HeaderRight from "components/Header/HeaderRight";
import { useFormTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import JsonView from "components/JsonView";
import InfoWhisper from "components/mini/InfoWhisper";
import { Constants } from "config/constants";
import { useCForm } from "hooks/useCForm";
import { useData } from "hooks/useData";
import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { getDataPropertiesOf, isUrl } from "lib/utils";
import { ruleset } from "lib/validation-rules";
import _ from "lodash";
import { dummyTerminal } from "models/TerminalModel";
import { CheckPicker, Divider } from "rsuite";
import CIcon from "components/CIcon";

const TerminalSchema: ValidationSchema = {
    name: ruleset.required,
    password: [ruleset.required, ruleset.minLength(5)],
    properties: {
        auto_sign_out: {
            after_idle_time_seconds: ruleset.combineIf(
                (v, values) => getDataPropertiesOf(values).auto_sign_out?.after_idle_time,
                [ruleset.required, ruleset.gte(0), ruleset.lte(3600)],
            ),
        },
    },
};

const TerminalEditPage: FCC = () => {
    const form = useCForm({
        url: "terminal",
        // model: TerminalModel(),
        model2: TerminalSchema,
        dummyData: dummyTerminal,
        refine: (d: any) => {
            d.properties = getDataPropertiesOf(d);

            /**
             * Ensure auto_sign_out is an object to prevent issues with settings toggles when it is an empty array
             */
            if (Array.isArray(d.properties.auto_sign_out) && d.properties.auto_sign_out.length === 0) {
                d.properties.auto_sign_out = {};
            }

            return !_.isEmpty(d.media)
                ? {
                      images: [{ uuid: d.media[0]?.urls?.original }],
                      ...d,
                  }
                : d;
        },
        prepareForSubmit: (d: any) => {
            if ("images" in d && !_.isEmpty(d.images)) {
                d = _.omit(d, isUrl(d.images[0].uuid) ? ["images"] : [""]);
            }
            return _.omit(d, ["image"]);
        },
    });

    const halls = useData<IHall[]>("hall");
    const saleTypes = useData<ISaleType[]>("finance/saleType");

    const signOutAfterIdleTime = form.form.watch("properties.auto_sign_out.after_idle_time");

    const setHalls = (data: any[]) => {
        const saleType = (form.form.getValues("sale_types") as ISaleType[]).find(st => st.id === 1);
        const hs = _.map(data, dataT => ({
            ...halls?.data?.find((t: any) => t?.id === dataT),
            sale_type_id: saleType?.id,
        }));
        form.form.setValue("halls", hs);
    };

    return (
        <div className="tw-h-full tw-relative tw-px-4">
            {useFormTitle(form.id, t("staff"))}
            <HeaderRight formSubmit={{ isLoading: form.isLoading, onSave: form.onSave }} />

            <CForm form={form.form} onSave={form.onSave}>
                <JsonView />
                <TextFormGroup name="name" errorPlacement="rightEnd" focus={true} />
                <TextFormGroup name="password" errorPlacement="rightEnd" />
                <CFormGroup name="sale_types" label={t("sales_type")}>
                    <CheckPicker
                        test-id="sale-types"
                        data={_.map(saleTypes?.data, t => ({ value: t?.id, label: t?.name }))}
                        value={_.map(form.form.getValues("sale_types"), t => t.id)}
                        onChange={data => {
                            form.form.setValue(
                                "sale_types",
                                _.map(data, dataT => saleTypes?.data?.find((t: any) => t?.id === dataT)),
                            );
                            if (data.includes(1)) {
                                const saleType = (form.form.getValues("sale_types") as ISaleType[]).find(
                                    st => st.id === 1,
                                );
                                form.form.setValue(
                                    "halls",
                                    halls?.data?.map(h => ({
                                        ...h,
                                        sale_type_id: saleType?.id,
                                    })),
                                );
                            } else {
                                form.form.setValue("halls", []);
                            }
                        }}
                        placeholder={t("select", { name: t("sale_type") })}
                        onClean={() => form.form.setValue("sale_types", [])}
                        style={{
                            width: 300,
                        }}
                    />
                </CFormGroup>
                {form.form.getValues("sale_types")?.find((s: any) => s.id === 1) ? (
                    <CFormGroup name="halls">
                        <CheckPicker
                            data={_.map(halls?.data, t => ({ value: t?.id, label: t?.name }))}
                            value={_.map(form.form.getValues("halls"), t => t.id)}
                            onChange={data => {
                                setHalls(data);
                                if (!data.length) {
                                    const tt = form.form.getValues("sale_types") as ISaleType[];
                                    form.form.setValue(
                                        "sale_types",
                                        tt.filter(t => t.id !== 1),
                                    );
                                }
                            }}
                            placeholder={t("select", { name: t("halls") })}
                            onClean={() => form.form.setValue("halls", [])}
                            style={{
                                width: 300,
                            }}
                        />
                    </CFormGroup>
                ) : null}
                <SelectFormGroup
                    name="status"
                    errorPlacement="rightEnd"
                    compProps={{ cleanable: true }}
                    data={_.map(Constants.terminalStatus, (name, id) => ({ name, id: parseInt(id) }))}
                />

                <InfoWhisper infoKey={"terminal_edit_notification_i"}>
                    <ToggleFormGroup
                        name="can_receive_notifications"
                        label={t("notifications")}
                        compProps={{
                            checkedChildren: <CIcon path={iCheckBoxChecked} style={{ marginBottom: "-3px" }} />,
                            unCheckedChildren: <CIcon path={iClose} style={{ marginBottom: "-3px" }} />,
                        }}
                    />
                </InfoWhisper>

                <UploaderFormGroup
                    name="images[0].uuid"
                    removeMainKey={true}
                    label={t("image")}
                    // uploadUrl="media"
                    buttonStyle={{ height: 90, width: 160 }}
                    fileInfoStyle={{ height: 90, width: 160 }}
                    compProps={{
                        action: "media",
                        multiple: false,
                        listType: "picture",
                        name: "media_file",
                        "test-id": "terminal-image",
                    }}
                />

                <Divider />
                <div className={"tw-text-lg tw-font-medium tw-mb-4"}>
                    <TText tkey="auto_sign_out_setting" />
                </div>

                <ToggleFormGroup
                    name="properties.auto_sign_out.after_sent_to_station"
                    label={t("after_sent_to_station")}
                />
                <ToggleFormGroup name="properties.auto_sign_out.after_close_receipt" label={t("after_close_receipt")} />
                <ToggleFormGroup name="properties.auto_sign_out.after_idle_time" label={t("after_idle_time")} />
                {signOutAfterIdleTime && (
                    <TextFormGroup
                        name="properties.auto_sign_out.after_idle_time_seconds"
                        label={t("after_idle_time_seconds")}
                        type={"number"}
                        rules={{
                            validate: {},
                        }}
                    />
                )}
            </CForm>
        </div>
    );
};

export default TerminalEditPage;
