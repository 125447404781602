import { t } from "lib/i18n";
import React from "react";
import DataTable from "../../DataTable/DataTable";
import { useTableHeight } from "hooks/useTableHeight";
import { useDrawer } from "../useDrawer";
import ReturnedSuppliesProductsDrawer from "../../../pages/inventory/misc/ReturnedSuppliesProductsDrawer";

export default function ReturnedSuppliesTab(props: any) {
    const height = useTableHeight(140);
    const drawer = useDrawer();
    const columns: ITableColumnProps<IStockOperation>[] = [
        {
            key: "id",
            title: t("data_id"),
            align: "left",
            onClick: d => {
                drawer.setDataId(d.id);
                props.setIsClosable(false);
            },
        },
        {
            key: "operated_at",
            title: t("date"),
            align: "center",
            type: "date",
        },
        // {
        //     key: "transaction.amount",
        //     title: t("amount"),
        //     align: "right",
        //     type: 'money',
        // },
    ];

    return (
        <>
            <DataTable
                hidePages
                noShadow
                columns={columns}
                dp={{ data: props?.supply_returns } as any}
                tableProps={{
                    id: "print-table",
                    headerHeight: 49,
                    autoHeight: false,
                    height,
                }}
            />
            <ReturnedSuppliesProductsDrawer
                {...drawer}
                onClose={e => {
                    drawer.onClose(e);
                    props.setIsClosable(true);
                }}
            />
        </>
    );
}
