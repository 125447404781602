import { createSlice } from "@reduxjs/toolkit";
import { formatWithCustomFormat, todayF } from "config/dates";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

export interface VenueDashboardState {
    pointStart: number;
    rangeIndex: number;
    date: number;
    isCalendarOpen: boolean;
    dateRange: Array<string>;
    categories: Array<string>;
    dates: Array<string>;
    error: {
        message: string;
        hasError: boolean;
    };
}

const initialState: VenueDashboardState = {
    pointStart: new Date(todayF()).getTime(),
    date: new Date().getTime(),
    rangeIndex: 0,
    isCalendarOpen: false,
    dateRange: [todayF(), todayF()],
    categories: [formatWithCustomFormat(new Date(), "MMM dd")],
    dates: [todayF()],
    error: {
        message: "",
        hasError: false,
    },
};

export const VenueDashboardSlice = createSlice({
    name: ReduxSlicesNames.DETAIL_REPORT,
    initialState,
    reducers: {
        setPointStart: (state, action) => {
            state.pointStart = action.payload;
        },
        setDashboardCategories: (state, action) => {
            state.categories = action.payload;
        },
        setDashboardDates: (state, action) => {
            state.dates = action.payload;
        },
        setDashboardDateRanges: (state, action) => {
            state.dateRange = action.payload;
        },
        setDashboardDateRangeIndex: (state, action) => {
            state.rangeIndex = action.payload;
        },
        setDashboardDate: (state, action) => {
            state.date = action.payload;
        },
        setIsCalendarOpen: (state, action) => {
            state.isCalendarOpen = action.payload;
        },
    },
});

export const {
    setIsCalendarOpen,
    setDashboardDate,
    setDashboardDateRangeIndex,
    setDashboardDateRanges,
    setPointStart,
    setDashboardCategories,
    setDashboardDates,
} = VenueDashboardSlice.actions;
export default VenueDashboardSlice.reducer;
