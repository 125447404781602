import { renderProductName } from "lib/utils";
import { iSpinner } from "assets/icons/Icons";
import CIcon from "../../CIcon";

interface IProps {
    selectedItems: any[];
    keyIndex: string;
}

const CheckListSelectedValues: FCC<IProps> = ({ selectedItems, keyIndex }) => {
    return selectedItems.length ? (
        <span className="rs-picker-toggle-value">
            <span className="rs-picker-value-list" title={`${selectedItems.map(t => t[keyIndex]).join(" , ")}`}>
                {selectedItems.map((t, i) => {
                    return (
                        <>
                            <span className="rs-picker-value-item">
                                {" "}
                                {renderProductName(t[keyIndex], t.parent_name)}
                            </span>
                            {selectedItems.length - 1 !== i && <span className="rs-picker-value-separator">,</span>}
                        </>
                    );
                })}
            </span>
            <span className="rs-picker-value-count" title={selectedItems.length.toString()}>
                {selectedItems.length}
            </span>
        </span>
    ) : (
        <p style={{ padding: 4, color: "#999", textAlign: "center" }}>
            <CIcon path={iSpinner} spin />
        </p>
    );
};

export default CheckListSelectedValues;
