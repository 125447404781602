import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useTitle } from "components/Header/Title";
import HeaderRight from "components/Header/HeaderRight";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SelectFilter from "components/DataTable/Filters/SelectFilter";
import DataTable from "components/DataTable/DataTable";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import DoubleDataColumn from "components/mini/DoubleDataColumn";
import { useData } from "hooks/useData";
import ChangeCashShiftBalanceModal from "./ChangeCashShiftBalanceModal";
import { useDrawer } from "components/Drawers/useDrawer";
import { iEditPencil, iWallet } from "assets/icons/Icons";
import CreateTransactionModal from "../transactions/CreateTransactionModal";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import { EMPTY_DATA } from "config/main";
import CellBtn from "components/DataTable/CellButton";
import CashShiftTransactionsDrawer from "./CashShiftTransactionsDrawer";
import { moneyFormatter } from "lib/moneyFormatter";
import { Colors } from "config/colors";
import { CBadge } from "core/components/CBadge/CBadge";
import { CashShiftColors, CashShiftStatusEnum, ICashShiftExtended } from "../finance-types";
import _ from "lodash";

const CashShiftPage = () => {
    const cashShiftBalance = useDrawer();
    const createTransactionModal = useDrawer();
    const transactionsModal = useDrawer();
    const dp = useDataProvider({
        url: "finance/cash-shifts",
        useUrl: true,
        useDates: true,
        sort: ["shift_no", "-1"],

        with: ["terminal", "opened_by_user", "closed_by_user", "cash_transactions_amount"],
    });

    const total = useData("finance/cash-shifts", {
        params: {
            total: 1,
            date: dp.params?.all?.date,
        },
        filters: _.values(dp.filters.all),
    });

    const cm = useColumns<ICashShiftExtended>("finance/cash-shift", [
        {
            key: "shift_no",
            title: t("shift_no"),
            flexGrow: 0.65,
        },
        {
            key: "terminal",
            render: d => (
                <DoubleDataColumn value1={`${t("terminal")} ${d.terminal?.name}`} value2={`Id: ${d.terminal?.id}`} />
            ),
        },
        {
            key: "opened_by_user.username",
            title: t("opener"),
        },
        {
            key: "closed_by_user.username",
            title: t("closer"),
        },
        {
            key: "opened_at",
            type: "datetime",
            multiline: true,
        },
        {
            key: "closed_at",
            type: "datetime",
            multiline: true,
        },

        {
            key: "group_opening",
            customTitle: (
                <>
                    <b style={{ color: Colors.GunmetalBlue }}>
                        <TText tkey="opening_balance" />
                    </b>
                    <div className={"d-flex justify-content-between"}>
                        <div>
                            <TText tkey="amount" />
                        </div>
                        <div>
                            <TText tkey="diff" />
                        </div>
                    </div>
                </>
            ),
            title: t("opening_balance"),
            flexGrow: 1.15,
            minWidth: 150,
            render: d => {
                const opening_diff = moneyFormatter(d.opening_diff);
                return (
                    <div className="d-flex justify-content-between">
                        <div test-id="cash-shift-opening-amount">{moneyFormatter(d.initial_amount)}</div>
                        <div
                            test-id="cash-shift-opening-diff"
                            style={{ color: d.opening_diff === 0 ? Colors.TropicalGreen : Colors.BrightRed }}
                        >
                            {d.opening_diff > 0 ? "+" + opening_diff : opening_diff}
                        </div>
                    </div>
                );
            },
        },
        {
            key: "group_closing",
            customTitle: (
                <>
                    <b style={{ color: Colors.GunmetalBlue }}>
                        <TText tkey="closing_balance" />
                    </b>
                    <div className={"d-flex justify-content-between"}>
                        <div>
                            <TText tkey="amount" />
                        </div>
                        <div>
                            <TText tkey="diff" />
                        </div>
                    </div>
                </>
            ),
            title: t("closing_balance"),
            flexGrow: 1.15,
            minWidth: 150,
            render: d => {
                const _diffMoney = moneyFormatter(d.diff);
                const diff =
                    d.status === CashShiftStatusEnum.OPEN ? EMPTY_DATA : d.diff > 0 ? `+${_diffMoney}` : _diffMoney;

                return (
                    <div className="d-flex justify-content-between">
                        <div test-id="cash-shift-closing-amount">{moneyFormatter(d.closed_amount)}</div>
                        <div
                            test-id="cash-shift-closing-diff"
                            style={{ color: d.diff === 0 ? Colors.TropicalGreen : Colors.BrightRed }}
                        >
                            {diff}
                        </div>
                    </div>
                );
            },
        },
        {
            key: "cash_transactions_amount",
            align: "right",
            render: d => (
                <CellBtn
                    label={moneyFormatter(d.cash_transactions_amount)}
                    action={() => {
                        transactionsModal.setData(d);
                    }}
                />
            ),
            summary: () => moneyFormatter(total.data?.cash_transactions),
        },
        {
            key: "status",
            align: "right",
            contentWrapClass: "tw-text-right",
            flexGrow: 1.5,
            render: d => (
                <CBadge color={CashShiftColors[d.status]}>
                    {t(`cash_shift_${d.status}`.toLowerCase() as `cash_shift_${Lowercase<CashShiftStatusEnum>}`)}
                </CBadge>
            ),
        },

        optionsColumn({
            dp,
            isLocked: (d: any) => d.status === CashShiftStatusEnum.OPEN,
            lockedText: t("cash_shift_open"),
            others: (d: any) => [
                ...(d?.status !== CashShiftStatusEnum.OPEN
                    ? [
                          {
                              label: t("add_transaction"),
                              icon: iWallet,
                              testId: "cash-shift-add-transaction",
                              visible: (d: any) => d.status !== CashShiftStatusEnum.OPEN,
                              onClick: (d: any) => {
                                  createTransactionModal.setData(d);
                              },
                          },
                          {
                              label: t("cash_shift_change_opening_balance"),
                              icon: iEditPencil,
                              visible: (d: any) => d.status !== CashShiftStatusEnum.OPEN,
                              testId: "cash-shift-change-opening-balance",
                              onClick: (d: any) => {
                                  cashShiftBalance.setData({
                                      ...d,
                                      type: "opening",
                                  });
                              },
                          },
                          {
                              label: t("cash_shift_change_closing_balance"),
                              icon: iEditPencil,
                              visible: (d: any) => d.status !== CashShiftStatusEnum.OPEN,
                              testId: "cash-shift-change-closing-balance",
                              onClick: (d: any) => {
                                  cashShiftBalance.setData({
                                      ...d,
                                      type: "closing",
                                  });
                              },
                          },
                      ]
                    : []),
            ],
        }),
    ]);

    return (
        <div>
            {useTitle(t("cash_shift"))}
            <HeaderRight dp={dp} cm={cm} reload date />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <RemoteCheckListFilter
                        fields="terminal_id"
                        remote={{
                            url: "terminal",
                        }}
                        pickerProps={{ placeholder: t("terminals") }}
                    />
                    <SelectFilter
                        pickerProps={{ placeholder: t("status") }}
                        fields="status"
                        options={[
                            { value: CashShiftStatusEnum.ACCEPTED, label: t("cash_shift_accepted") },
                            { value: CashShiftStatusEnum.OPEN, label: t("cash_shift_open") },
                            { value: CashShiftStatusEnum.NEEDS_TO_CHECK, label: t("cash_shift_needs_to_check") },
                            { value: CashShiftStatusEnum.UNACCEPTED, label: t("cash_shift_unaccepted") },
                        ]}
                    />
                </div>
            </FiltersContainer>
            <DataTable
                tableProps={{
                    cellBordered: true,
                    bordered: true,
                }}
                dp={dp}
                columns={cm.columns}
            />
            {createTransactionModal.isOpen && (
                <CreateTransactionModal {...createTransactionModal} revalidate={dp.revalidate} />
            )}
            <CashShiftTransactionsDrawer {...transactionsModal} />
            {cashShiftBalance.isOpen && (
                <ChangeCashShiftBalanceModal {...cashShiftBalance} revalidate={dp.revalidate} />
            )}
        </div>
    );
};

export default CashShiftPage;
