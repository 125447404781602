import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { useSelector } from "react-redux";
import { useCallback } from "react";
import StationReceiptPreviewView from "./StationReceiptPreviewView";
import { selectStationReceiptItems, updateStationReceiptItemPosition } from "redux/features/stationPrintConstructor";
import { useAppDispatch } from "hooks/useRedux";
import ReceiptItemList from "../common/ReceiptItemList";
import RConstructorHeaderButtons from "../common/RConstructorHeaderButtons";
import { ReceiptItemType } from "../types";
import RowHeaders from "../common/RowHeaders";

const StationReceiptEditor: FCC = () => {
    const items = useSelector(selectStationReceiptItems);
    const dispatch = useAppDispatch();

    const handleDragAndDrop = useCallback(
        (result: DropResult) => {
            const { source, destination } = result;

            if (!destination) {
                return;
            }

            if (source.index === destination.index) {
                return;
            }
            dispatch(updateStationReceiptItemPosition({ startIndex: source.index, endIndex: destination.index }));
        },
        [dispatch],
    );
    return (
        <div className="d-flex flex-row">
            <div className="w-50" style={{ minWidth: "580px" }}>
                <RConstructorHeaderButtons receiptType={ReceiptItemType.StationReceipt} />
                <RowHeaders />

                <DragDropContext onDragEnd={handleDragAndDrop}>
                    <ReceiptItemList receiptType={ReceiptItemType.StationReceipt} items={items} />
                </DragDropContext>
            </div>
            <StationReceiptPreviewView />
        </div>
    );
};

export default StationReceiptEditor;
