import { t } from "lib/i18n";
import _ from "lodash";
import { Link } from "react-router-dom";
import { Button } from "rsuite";

import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useDrawer } from "../Drawers/useDrawer";
import NoAccessModal from "../NoAccessModal";
import CIcon from "../CIcon";
import { iPlus } from "assets/icons/Icons";

interface IProps {
    to: string | (() => void);
    label?: string;
    icon?: any;
    canCreate?: string;
    color?: string | any;
    classname?: string;
    testId?: string;
}

const CreateButton: FCC<IProps> = props => {
    const auth = useAuth();
    const noAccessModal = useDrawer();
    const canNotAccess = props.canCreate && !auth.hasAbility([props.canCreate]);

    const createButton = (className?: string, click?: () => void) => (
        <Button
            appearance="primary"
            color={props?.color || "blue"}
            onClick={click}
            className={`${className} primary-blue-btn`}
        >
            <CIcon size={0.5} path={props.icon ?? iPlus} className="mr-2" />
            {props.label ?? t("create")}
        </Button>
    );

    return (
        <>
            {_.isString(props.to) ? (
                canNotAccess ? (
                    createButton("", () => noAccessModal.setDataId(1))
                ) : (
                    <Link test-id={props?.testId ?? "create-button"} to={props.to}>
                        {createButton()}
                    </Link>
                )
            ) : (
                <Button
                    test-id={props?.testId ?? "create-button"}
                    appearance="primary"
                    color={props?.color || "blue"}
                    className="ml-2 primary-blue-btn"
                    onClick={() => (canNotAccess ? noAccessModal.setDataId(1) : (props.to as any)())}
                >
                    <CIcon path={props.icon ?? iPlus} className="mr-2" />
                    {props.label ?? t("create")}
                </Button>
            )}
            <NoAccessModal modal={noAccessModal} permission={[props.canCreate || ""]} />
        </>
    );
};

export default CreateButton;
