import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 } from "uuid";
import _ from "lodash";
import {
    CustomerDiscountType,
    DiscountType,
    IDbProduct,
    IDbProductPrice,
    IDbReceipt,
    IDbReceiptProduct,
    IReceiptMeta,
    IReceiptPaymentMethod,
    IRPMeta,
    ISetting,
    ITax,
    ReceiptStatus,
} from "@cloposcom/receipt-utils/dist/receipt-calculation/interfaces";
import { productHash } from "@cloposcom/receipt-utils/dist/receipt-calculation/util";
import { ReceiptCalculation, removeIncludedFlatTaxes } from "@cloposcom/receipt-utils";
import { SalesChannelTypes } from "config/constants";
import { ICalculationSettings } from "hooks/useCalculationSetting";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

const initialState = (): IWebCashier => {
    return {
        receipt: {
            address: "",
            by_card: 0,
            by_cash: 0,
            cid: v4(),
            closed_at: "",
            created_at: new Date().getTime().toString(),
            customer_discount_type: CustomerDiscountType.NONE,
            customer_id: "",
            delivery_fee: 0,
            description: "",
            discount_rate: 0,
            discount_type: 0,
            discount_value: 0,
            e_tax: 0,
            gift_total: 0,
            guests: 1,
            i_tax: 0,
            local_status: 0,
            meta: {
                preprint_count: 0,
                sale_type: {
                    name: "",
                },
                user: {
                    name: "",
                    role: "",
                },
            },
            original_subtotal: 0,
            payment_methods: [],
            printed: false,
            receipt_products: [],
            remaining: 0,
            rps_discount: 0,
            sale_type_id: 0,
            service_charge: 0,
            service_charge_value: 0,
            service_notification_id: null,
            status: ReceiptStatus.PAYED,
            subtotal: 0,
            terminal_id: 0,
            total: 0,
            total_tax: 0,
            user_id: 0,
            deleted_at: "",
            updated_at: "",
        },
        close_without_payment: false,
        can_change_sale_type: true,
        sales_channel: SalesChannelTypes.DELIVERY,
        sales_type_service_charge: 0,
    };
};
const calc = new ReceiptCalculation();

function generateInitialMeta(p: IProduct | any, priceWithoutTaxes: number, parent?: IProduct, isGift?: number) {
    return {
        product: {
            name: p.name,
            giftable: !!p.giftable,
            modifier_name: "",
            discountable: !!p.discountable,
            sold_by_weight: p.meta.product.sold_by_weight,
            weight_unit: "kg",
            priceWithoutTaxes: priceWithoutTaxes,
            ignore_service_charge: !!p.ignore_service_charge,
            barcode: p.barcode ?? "",
            taxes: p.meta.taxes ?? [], //parent?.
            price: priceWithoutTaxes,
        },
        total_gift: isGift ? p.price : 0,
        originalPrice: priceWithoutTaxes, // used to recalculate price for portions
        taxes: p.taxes
            ? p.taxes.map((tax: ITax) => {
                  return {
                      tax_id: tax.id,
                      priority: tax.priority,
                      calculate_type: tax.calculation_type,
                      tax_type_id: tax.type_id,
                      tax_rate: tax.rate,
                      tax_value: 0,
                      tax_value_total: 0,
                      tax_name: tax.type.name,
                      tax_o_name: tax.name,
                  };
              })
            : [],
        subtotalWithTax: 0,
        discountedPrice: p.price,
    };
}

function addMeta(prMeta: any, meta: Partial<IRPMeta>): IReceiptMeta {
    return Object.assign(prMeta ?? {}, meta);
}

const createProductJson = (conf: {
    product: IProduct | IDbReceiptProduct | any;
    parentProduct: IProduct | undefined;
    _productHash: string;
    count: number;
    discountType: number;
    isGift: number;
    discountValue: number;
    discountRate: number;
    isNewProduct: boolean;
    setting: ISetting;
}) => {
    const {
        isNewProduct,
        product,
        parentProduct,
        _productHash,
        count,
        isGift,
        discountType,
        discountValue,
        discountRate,
        setting,
    } = conf;
    const priceWithoutTaxes = removeIncludedFlatTaxes(product.price, product.taxes, setting);
    const cid = v4();
    return {
        cid: cid,
        count: count,
        meta: isNewProduct ? generateInitialMeta(product, priceWithoutTaxes, parentProduct, isGift) : product.meta,
        created_at: new Date().getTime().toString(),
        discount_rate: discountRate,
        discount_type: discountType,
        discount_value: discountValue,
        cost_price: product.cost_price,
        is_gift: isGift,
        portion_size: isNewProduct ? 1 : product.portion_size,
        price: isNewProduct ? priceWithoutTaxes : product.price,
        product_hash: _productHash,
        product_id: isNewProduct ? product.id : product.product_id,
        receipt_discount: 0,
        receipt_product_modificators: [],
        subtotal: isGift ? 0 : isNewProduct ? priceWithoutTaxes : product.subtotal,
        taxes: product.taxes
            ? isNewProduct
                ? product.taxes.map((tax: any) => {
                      return {
                          tax_id: tax.id,
                          tax_amount: 0,
                      };
                  })
                : product.taxes
            : [],
        total: isGift ? 0 : isNewProduct ? product.price : product.total,
        total_discount: 0,
    };
};

const webCashierSlice = createSlice({
    name: ReduxSlicesNames.WEB_CASHIER,
    initialState: initialState(),
    reducers: {
        addDiscountToLine: (
            state,
            action: PayloadAction<{
                product: IDbReceiptProduct;
                discountType: number;
                discountValue: any;
                count: number;
                _product: IProduct;
                parentProduct: IProduct | undefined;
                setting: ICalculationSettings;
            }>,
        ) => {
            const { product, discountType, discountValue, count, parentProduct, setting } = action.payload;
            const oldProductHash = product.product_hash;
            const _discountValue = discountType === 2 ? discountValue : (product.price * discountValue) / 100;
            const _productHash = productHash(
                product.product_id,
                undefined,
                false,
                product.portion_size,
                _discountValue,
            );
            const index = state.receipt.receipt_products.findIndex(rp => rp.cid === product.cid);
            if (oldProductHash !== _productHash) {
                if (product.count === count) {
                    state.receipt.receipt_products.splice(index, 1);
                } else {
                    state.receipt.receipt_products[index]["count"] = product.count - count;
                }

                const productIndexByHash = state.receipt.receipt_products.findIndex(
                    pr => pr.product_hash === _productHash,
                );

                if (productIndexByHash === -1) {
                    const newProduct: IDbReceiptProduct = {
                        ...createProductJson({
                            product,
                            parentProduct,
                            _productHash,
                            count,
                            discountValue: discountType === 2 ? discountValue : 0,
                            discountType: discountType,
                            discountRate: discountType === 1 ? discountValue : 0,
                            isNewProduct: false,
                            isGift: 0,
                            setting: setting as unknown as ISetting,
                        }),
                        seller_id: product.seller_id,
                    } as any;
                    state.receipt.receipt_products.push(newProduct);
                } else {
                    const __product = state.receipt.receipt_products[productIndexByHash];
                    __product.count += count;
                    state.receipt.receipt_products[productIndexByHash] = __product;
                }
            } else {
                if (discountValue !== 0) {
                    state.receipt.receipt_products[index] = { ...product, count: product.count + count };
                }
            }
        },
        addDiscountToReceipt: (
            state,
            action: PayloadAction<{
                discountType: number;
                discountValue: any;
            }>,
        ) => {
            const { discountType, discountValue } = action.payload;
            state.receipt.customer_discount_type = 0;
            state.receipt.discount_rate = discountType === 1 ? discountValue : 0;
            state.receipt.discount_value = discountType === 2 ? discountValue : 0;
            state.receipt.discount_type = discountType;
        },
        addGift: (
            state,
            action: PayloadAction<{
                product: IDbReceiptProduct;
                parentProduct?: IProduct;
                count: number;
                setting: ICalculationSettings;
            }>,
        ) => {
            const { product, parentProduct, setting, count } = action.payload;
            const _productHash = productHash(product.product_id, undefined, true, product.portion_size);
            const index = state.receipt.receipt_products.findIndex(rp => rp.cid === product.cid);

            if (product.count === count) {
                state.receipt.receipt_products.splice(index, 1);
            } else {
                state.receipt.receipt_products[index]["count"] = product.count - count;
            }

            const productIndexByHash = state.receipt.receipt_products.findIndex(pr => pr.product_hash === _productHash);

            if (productIndexByHash === -1) {
                const createdProduct = createProductJson({
                    product,
                    parentProduct,
                    _productHash,
                    count,
                    discountValue: 0,
                    discountType: 0,
                    discountRate: 0,
                    isGift: 1,
                    isNewProduct: false,
                    setting: setting as unknown as ISetting,
                });

                const taxes = _.cloneDeep(product.taxes!);

                const newProduct: IDbReceiptProduct = {
                    ...createdProduct,
                    seller_id: product.seller_id,
                    taxes: taxes?.map((tax: any) => {
                        tax.tax_amount = 0;
                        return tax;
                    }),
                } as any;
                state.receipt.receipt_products.push(newProduct);
            } else {
                const __product = state.receipt.receipt_products[productIndexByHash];
                __product.count += count;
                state.receipt.receipt_products[productIndexByHash] = __product;
            }
        },
        addPaymentMethod: (state, action: PayloadAction<IReceiptPaymentMethod>) => {
            state.receipt.payment_methods!.push(action.payload);
        },
        addReceiptItem: (
            state,
            action: PayloadAction<{
                product: IDbProduct;
                parentProduct?: IDbProduct;
                setting: ICalculationSettings;
                price: IDbProductPrice | undefined;
            }>,
        ) => {
            const { product, parentProduct, setting, price } = action.payload;
            try {
                calc.addProduct({
                    rConf: {
                        r: state.receipt,
                        s: setting as unknown as ISetting,
                        rSaleType: state.receipt.saleType!,
                    },
                    product: product!,
                    parentProduct: parentProduct ?? undefined,
                    multiPrice: price ?? undefined,
                });
            } catch (error) {
                console.log(error);
            }
        },
        deleteReceiptItem: (state, action: PayloadAction<{ product: IDbReceiptProduct }>) => {
            const { product } = action.payload;
            const index = state.receipt.receipt_products.findIndex(pr => pr.cid === product.cid);
            // Hard delete olduğu üçün calc.removeProduct(); istifadə edə bilmirik
            state.receipt.receipt_products.splice(index, 1);
        },
        deletePaymentMethod: (state, action: PayloadAction<number>) => {
            state.receipt.payment_methods!.splice(action.payload, 1);
        },
        updateReceipt: (state, action: PayloadAction<{ key: string; value: any }>) => {
            const receipt: IDbReceipt = state.receipt;
            receipt[action.payload.key as "address"] = action.payload.value;
        },
        changeSalesChannel: (state, action: PayloadAction<SalesChannelTypes>) => {
            state.sales_channel = action.payload;
        },
        updateReceiptItem: (state, action: PayloadAction<IDbReceiptProduct>) => {
            const data = action.payload;
            const index = state.receipt.receipt_products.findIndex(pr => pr.cid === data.cid);
            state.receipt.receipt_products[index] = data;
        },
        updateReceiptPaymentItem: (
            state,
            action: PayloadAction<{
                data: IReceiptPaymentMethod;
                index: number;
            }>,
        ) => {
            const { data, index } = action.payload;
            state.receipt.payment_methods![index] = data;
        },
        setSalesTypeServiceCharge: (state, action: PayloadAction<number | null>) => {
            state.sales_type_service_charge = action.payload;
        },
        setSaleTypeChangeStatus: (state, action: PayloadAction<boolean>) => {
            state.can_change_sale_type = action.payload;
        },
        updateReceiptProducts: (state, action: PayloadAction<any>) => {
            state.receipt.receipt_products = action.payload;
        },
        recalculateAll: (
            state,
            action: PayloadAction<{ setting: ICalculationSettings; product: IDbReceiptProduct }>,
        ) => {
            const { setting, product } = action.payload;
            const productIndex = state.receipt.receipt_products.findIndex(rp => rp.cid === product.cid);
            if (productIndex !== -1) {
                const _product = _.cloneDeep(state.receipt.receipt_products[productIndex]);
                state.receipt.receipt_products[productIndex] = calc.recalculateAll(
                    {
                        r: state.receipt,
                        s: setting as unknown as ISetting,
                        rSaleType: state.receipt.saleType!,
                    },
                    _product,
                    true,
                );
            }
        },
        recalculateReceipt: (state, action: PayloadAction<{ setting: ICalculationSettings }>) => {
            const { setting } = action.payload;
            calc.recalculate({
                r: state.receipt,
                s: setting as unknown as ISetting,
                rSaleType: state.receipt.saleType!,
            });
        },
        resetReceipt: (state, action: PayloadAction<any>) => {
            state.receipt.receipt_products = state.receipt.receipt_products.map(rp => {
                const meta = addMeta(rp.meta, {
                    taxes: [],
                    mistake: {
                        receipt_discount: rp.receipt_discount,
                        discount_type: rp.discount_type,
                        discount_rate: rp.discount_rate,
                        discount_value: rp.discount_value,
                        total_discount: rp.total_discount,
                        taxes: rp.taxes,
                        metaTaxes: rp.meta?.taxes,
                        total: rp.total,
                    },
                }) as any;
                rp.receipt_discount = 0;
                rp.discount_type = 0;
                rp.discount_rate = 0;
                rp.discount_value = 0;
                rp.total_discount = 0;
                rp.taxes = [];
                rp.total = 0;
                return { ...rp, meta };
            });

            state.receipt.meta = addMeta(
                {},
                {
                    mistake: _.pick(state, [
                        "payment_methods",
                        "service_charge",
                        "discount_value",
                        "discount_rate",
                        "discount_type",
                        "total",
                    ]),
                },
            );
            state.receipt.payment_methods = [];
            state.receipt.service_charge = 0;
            state.receipt.rps_discount = 0;
            if (state.receipt.discount_type !== DiscountType.NONE) {
                state.receipt.discount_value = 0;
                state.receipt.rps_discount = 0;
                state.receipt.discount_rate = 0;
                state.receipt.discount_type = DiscountType.NONE;
            }
            state.receipt.total = 0;
        },
        setInitialValues: (state, action: PayloadAction<any>) => {
            const _initialState = initialState();
            Object.assign(state, _initialState);
            delete state.receipt.saleType;
            delete state.receipt.sale_type_id;
            delete state.receipt.customer;
            delete state.receipt.courier_id;
            delete state.receipt.seller_id;
        },
    },
});

export const selectReceiptProducts = createSelector(
    (state: RootState) => state.webCashier.receipt.receipt_products,
    products => products,
);
export const selectSalesTypeServiceCharge = createSelector(
    (state: RootState) => state.webCashier.sales_type_service_charge,
    products => products,
);

export const selectReceiptSalesChannel = createSelector(
    (state: RootState) => state.webCashier.sales_channel,
    products => products,
);

export const selectReceiptPaymentMethods = createSelector(
    (state: RootState) => state.webCashier.receipt.payment_methods,
    payment_methods => payment_methods,
);

export const selectReceiptData = createSelector(
    (state: RootState) => state.webCashier,
    items => items,
);

export const selectSaleTypeChangeStatus = createSelector(
    (state: RootState) => state.webCashier.can_change_sale_type,
    items => items,
);

export const {
    resetReceipt,
    setInitialValues,
    updateReceiptItem,
    recalculateReceipt,
    recalculateAll,
    addReceiptItem,
    updateReceipt,
    deleteReceiptItem,
    updateReceiptPaymentItem,
    deletePaymentMethod,
    addGift,
    addDiscountToLine,
    addDiscountToReceipt,
    changeSalesChannel,
    addPaymentMethod,
    setSalesTypeServiceCharge,
    setSaleTypeChangeStatus,
} = webCashierSlice.actions;

export default webCashierSlice.reducer;
