import React, { ChangeEvent, forwardRef, SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Checkbox, CheckTreePicker, CheckTreePickerProps, PickerHandle } from "rsuite";
import { ValueType } from "rsuite/esm/CheckPicker/CheckPicker";
import { ItemDataType } from "rsuite/esm/@types/common";
import _ from "lodash";
import { TText } from "../i18n/TText";
import { usePopupContainer } from "hooks/usePopupContainer";
import { flattenData, renderCategoryParentNames } from "lib/tree-picker-utils";

//region utils
const addValue = (children: ItemDataType, valueSet: Set<string | number>, childrenKey: string, valueKey: string) => {
    children.map((child: ItemDataType) => {
        child[valueKey] && valueSet.add(child[valueKey]);

        child[childrenKey]?.length && addValue(child[childrenKey], valueSet, childrenKey, valueKey);
    });
};

const removeValue = (children: ItemDataType, valueSet: Set<string | number>, childrenKey: string, valueKey: string) => {
    children.map((child: ItemDataType) => {
        child[valueKey] && valueSet.delete(child[valueKey]);

        child[childrenKey]?.length && removeValue(child[childrenKey], valueSet, childrenKey, valueKey);
    });
};
//endregion

export interface ICheckTreePickerProps extends Omit<CheckTreePickerProps, "cascade"> {
    cascade: CheckTreePickerProps["cascade"] | "child";
    hideSelectAll?: boolean;
    hideShowOnlySelected?: boolean;
    customFooter?: () => React.ReactNode;
    groupBy?: string | undefined;
    groupNodeTitle?: (nodeData: ItemDataType) => React.ReactNode;
}

const CCheckTreePicker = forwardRef<PickerHandle, ICheckTreePickerProps>((props, ref) => {
    const isControlled = props.value !== undefined;
    const valueKey = props.valueKey || "value";
    const labelKey = props.labelKey || "label";
    const childrenKey = props.childrenKey || "children";

    const [showOnlySelected, setShowOnlySelected] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [_value, _setValue] = useState(props.defaultValue);
    const [expandItemValues, setExpandItemValues] = useState<ItemDataType[]>([]);
    const { container } = usePopupContainer();

    /**
     * We only need flatten version of the tree when search results or selected items are shown.
     */
    const showFlatten = searchQuery.trim() !== "" || showOnlySelected;
    const value = (isControlled ? props.value : _value) || [];
    const groupedData = props.groupBy ? _.groupBy(props.data, props.groupBy) : {};

    /**
     * We should clean children prop if it's empty.
     * Otherwise rsuite's CheckTreePicker component will add arrow icon for last child even if it doesn't have any children.
     */
    const cleanedData = useMemo(() => {
        let _data = props.data;

        if (props.groupBy) {
            _data = Object.keys(groupedData).map(k => {
                return {
                    [valueKey]: k,
                    [labelKey]: k,
                    [childrenKey]: groupedData[k],
                };
            });
        }

        const omitChildrenPropIfIsEmpty = (d: ItemDataType) => {
            return d.reduce((prev: ItemDataType[], curr: ItemDataType) => {
                return [
                    ...prev,
                    {
                        ..._.omit(curr, childrenKey),
                        ...(curr[childrenKey]?.length && {
                            [childrenKey]: omitChildrenPropIfIsEmpty(curr[childrenKey]),
                        }),
                    },
                ];
            }, []);
        };

        return omitChildrenPropIfIsEmpty(_.cloneDeep(_data));
    }, [props.data]);

    const data = useMemo(() => {
        let d = cleanedData;

        if (showFlatten) {
            d = flattenData(d, childrenKey);
        }

        return d;
    }, [cleanedData, showFlatten]);

    const totalCount = useMemo(() => {
        if (!data) return 0;

        const calculate: (d: ItemDataType[]) => number = d => {
            return d.reduce((prev, cur) => {
                if (cur[childrenKey]?.length) {
                    return prev + 1 + calculate(cur[childrenKey]);
                }

                return prev + 1;
            }, 0);
        };

        return calculate(data);
    }, [data, childrenKey]);

    const onPickerSelect = useCallback(
        (activeNode: ItemDataType, value: ValueType, event: React.SyntheticEvent) => {
            const valueSet = new Set<string | number>(value);

            if (activeNode.children?.length) {
                if (activeNode.check) {
                    addValue(activeNode.children, valueSet, childrenKey, valueKey);
                } else {
                    removeValue(activeNode.children, valueSet, childrenKey, valueKey);
                }
            }

            const v = [...valueSet];

            if (!isControlled) _setValue(v);

            props.onChange?.(v, event);
        },
        [props.onChange],
    );

    const onChange = (v: ValueType, e: SyntheticEvent) => {
        if (!isControlled) _setValue(v);

        props.onChange?.(v, e);
    };

    const onSelectAll = (_: unknown, checked: boolean, e: ChangeEvent<HTMLInputElement>) => {
        const valueSet = new Set<string | number>(value);

        if (value.length < totalCount) {
            addValue(data, valueSet, childrenKey, valueKey);
        } else {
            removeValue(data, valueSet, childrenKey, valueKey);
        }

        const v = [...valueSet];

        if (!isControlled) _setValue(v);

        props.onChange?.(v, e);
    };

    const renderExtraFooter = () => {
        if (props.hideSelectAll && props.hideShowOnlySelected) return null;

        return (
            <div className={"tw-p-2 tw-flex tw-justify-between tw-items-center"}>
                <div>
                    {!props.hideSelectAll && (
                        <Checkbox
                            indeterminate={value.length > 0 && value.length < totalCount}
                            checked={value.length > 0}
                            onChange={onSelectAll}
                            className={"tw-font-xs"}
                        >
                            <TText tkey="select_all" />
                        </Checkbox>
                    )}
                </div>
                <div>
                    {!props.hideShowOnlySelected && (
                        <Button size={"xs"} appearance={"link"} onClick={() => setShowOnlySelected(prev => !prev)}>
                            <TText tkey={showOnlySelected ? "show_all" : "show_selected"} />
                        </Button>
                    )}
                </div>
            </div>
        );
    };

    const renderTreeNode = (nodeData: ItemDataType) => {
        const parent = _.find(data, { id: nodeData.parent_id });

        return parent ? (
            <div className={"tw-flex tw-flex-col"}>
                <div className={"tw-text-base tw-whitespace-break-spaces"}>
                    {props.groupBy && props.groupNodeTitle && Object.keys(groupedData).includes(nodeData[valueKey])
                        ? props.groupNodeTitle(nodeData)
                        : nodeData[labelKey]}
                </div>
                {showFlatten && nodeData.parent_id && (
                    <div className={"tw-text-sm tw-text-gray-400"}>
                        {renderCategoryParentNames(data, parent, labelKey)}
                    </div>
                )}
            </div>
        ) : (
            <div className={"tw-text-base tw-whitespace-break-spaces"}>
                {props.groupBy && props.groupNodeTitle && Object.keys(groupedData).includes(nodeData[valueKey])
                    ? props.groupNodeTitle(nodeData)
                    : nodeData[labelKey]}
            </div>
        );
    };

    useEffect(() => {
        if (props.defaultExpandAll) {
            setExpandItemValues(flattenData(cleanedData, childrenKey).map(d => d[valueKey]));
        }
    }, [props.defaultExpandAll, cleanedData, valueKey]);

    return (
        <CheckTreePicker
            container={() => container.current!}
            placement="autoVerticalStart"
            {..._.omit(props, ["customFooter", "hideSelectAll", "groupNodeTitle", "groupBy"])}
            data={
                showFlatten && showOnlySelected
                    ? data.filter((item: ItemDataType) => value.includes(item[valueKey]))
                    : data
            }
            value={isControlled ? props.value : value}
            onChange={onChange}
            cascade={props.cascade === "child" ? false : props.cascade}
            onSelect={props.cascade === "child" ? onPickerSelect : props.onSelect}
            onSearch={q => {
                setSearchQuery(q);
            }}
            onClose={() => {
                setSearchQuery("");
                setShowOnlySelected(false);
            }}
            renderExtraFooter={() => {
                return (
                    <>
                        {props?.customFooter?.()}
                        {renderExtraFooter()}
                    </>
                );
            }}
            uncheckableItemValues={Object.keys(groupedData)}
            renderTreeNode={renderTreeNode}
            expandItemValues={expandItemValues}
            onExpand={(v: ItemDataType[]) => setExpandItemValues(v)}
            ref={ref}
        />
    );
});

export default CCheckTreePicker;
