import { iDot } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import { ICDrawerProps } from "components/Drawers/CDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import { Colors } from "config/colors";
import _ from "lodash";
import ActionString, { actionIcon } from "pages/statistics/receipts/misc/ActionString";
import { Timeline, Tooltip, Whisper } from "rsuite";
import ReceiptHistoryDetailSubInfo from "./ReceiptHistoryDetailSubInfo";
import ReceiptNewStationDrawer from "./ReceiptNewStation";
import ReceiptSplittedDrawer from "./ReceiptSplittedDrawer";
import ReceiptStationDrawer from "./ReceiptStation";
import { format } from "date-fns";

interface IProps extends ICDrawerProps {
    r: IReceipt;
    history: {
        data?: IHistory[];
        loading: boolean;
    };
}

const ReceiptHistory: FCC<IProps> = ({ history, ...props }) => {
    const data = history.data;

    if (props.r.deleted_at && history.data) {
        (history.data as any).push({
            rid: props.r.id,
            terminalId: "",
            timestamp: new Date(props.r.deleted_at).getTime(),
            type: "DeletedPanel",
            updated_at: props.r.updated_at,
            userId: 1,
            userName: props.r.user_delete?.username,
            cause: 1,
            payload: {},
        });
    }

    const rd = useDrawer();
    const newrd = useDrawer();
    const splitted = useDrawer();
    const copyToClipboard = (cid: string) => {
        navigator.clipboard?.writeText(cid);
    };

    return (
        <div style={{ height: "94%" }} className="d-flex flex-column justify-content-between clopos-scroll ">
            <div className="flex-fill border-left d-flex flex-column justify-content-between">
                <ReceiptStationDrawer
                    {...rd}
                    onClose={() => {
                        props.setIsClosable(true);
                        rd.onClose();
                    }}
                />
                <ReceiptNewStationDrawer
                    {...newrd}
                    onClose={() => {
                        props.setIsClosable(true);
                        newrd.onClose();
                    }}
                />
                <ReceiptSplittedDrawer
                    {...splitted}
                    onClose={() => {
                        props.setIsClosable(true);
                        splitted.onClose();
                    }}
                />
                <div className="d-flex clopos-scroll " style={{ padding: "20px 12px" }}>
                    <div>
                        <Timeline className="custom-timeline">
                            {data?.map((historyItem, i) => (
                                <Timeline.Item
                                    key={historyItem?.id}
                                    time={
                                        <div
                                            style={{
                                                margin: "0.2rem 1.5rem 1rem -1rem",
                                                textAlign: "left",
                                                width: "10rem",
                                                height: "2rem",
                                                display: "flex",
                                                alignItems: "center",
                                            }}
                                        >
                                            <div className="mr-2">
                                                {historyItem.payload?.isOnline ? (
                                                    <CIcon
                                                        path={iDot}
                                                        size={0.8}
                                                        style={{ color: Colors.EmeraldGreen }}
                                                    />
                                                ) : (
                                                    <CIcon path={iDot} size={0.8} style={{ color: Colors.BrightRed }} />
                                                )}
                                            </div>
                                            <div>
                                                <div style={{ fontSize: "12px" }}>{historyItem?.userName}</div>
                                                <div style={{ fontSize: "10px", color: Colors.LightSilverGray }}>
                                                    {_.isNil(historyItem?.terminal?.id)
                                                        ? "--"
                                                        : `${historyItem?.terminal?.name || ""} (ID: ${
                                                              historyItem?.terminal?.id || ""
                                                          })`}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    dot={
                                        <Whisper
                                            placement="top"
                                            trigger={i === 0 ? "hover" : "click"}
                                            speaker={<Tooltip>{historyItem.cid}</Tooltip>}
                                        >
                                            <div onClick={() => copyToClipboard(historyItem?.cid)}>
                                                <CIcon
                                                    path={actionIcon[historyItem.type]?.[0]?.icon}
                                                    size={actionIcon[historyItem.type]?.[0]?.size || 1.3}
                                                    style={{
                                                        ...actionIcon[historyItem.type]?.[0]?.style,
                                                        color:
                                                            historyItem.cause === 1
                                                                ? Colors.BrightOrange
                                                                : actionIcon[historyItem.type]?.[0]?.style?.color ||
                                                                  Colors.CobaltBlue,
                                                        margin: " -0.61rem",
                                                        padding: "0.3rem 0",
                                                        background: "white",
                                                    }}
                                                />
                                            </div>
                                        </Whisper>
                                    }
                                >
                                    <div className="d-flex">
                                        <div key={i} className="mr-5" style={{ width: "5rem" }}>
                                            <div style={{ marginBottom: "0" }}>
                                                <div style={{ fontSize: "12px" }}>
                                                    {format(new Date(historyItem.timestamp), "HH:mm:ss")}
                                                </div>
                                                <div style={{ fontSize: "10px", color: Colors.LightSilverGray }}>
                                                    {format(new Date(historyItem.timestamp), "MMMM dd")}
                                                </div>
                                            </div>
                                        </div>

                                        <ActionString
                                            hasIcon={true}
                                            d={historyItem}
                                            onClick={() => {
                                                switch (historyItem.type) {
                                                    case "Split":
                                                        splitted.setData(historyItem.payload);
                                                        props.setIsClosable(false);
                                                        break;
                                                    case "SplitIn":
                                                    case "SplitOut":
                                                        splitted.setData(historyItem.payload.rps);
                                                        props.setIsClosable(false);
                                                        break;
                                                    case "SentToPrinters":
                                                    case "SentToKitchenKits":
                                                    case "SentToPrintersFail":
                                                    case "SentToKitchenKitsFail":
                                                        newrd.setData(historyItem);
                                                        props.setIsClosable(false);
                                                        break;
                                                    default:
                                                        rd.setData(historyItem.payload.data);
                                                        props.setIsClosable(false);
                                                }
                                            }}
                                        />
                                    </div>
                                </Timeline.Item>
                            ))}
                        </Timeline>
                    </div>
                </div>
                <ReceiptHistoryDetailSubInfo r={props.r} susCount={history.data?.filter(h => h.cause === 1).length} />
            </div>
        </div>
    );
};

export default ReceiptHistory;

// function isSuspicious(
//     orderHistory: IHistory[],
//     action: IHistory,
//     closedActionIndex: number,
//     suspicion_actions: any,
// ): boolean {
//     // const index = orderHistory.findIndex(h => h.id === action?.id);
//     // const closedAction = closedActionIndex > -1 ? orderHistory[closedActionIndex] : null;

//     // return (
//     //     closedAction
//     //     && closedActionIndex < index
//     //     && orderHistory[closedActionIndex + 1].type !== "Print"
//     // ) || suspicion_actions?.indexOf(action?.type) > -1;

//     return suspicion_actions?.indexOf(action?.type) > -1 || !!action?.cause;
// }
