import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import ColumnsManagerButton from "components/DataTable/ColumnsManager/ColumnsManagerButton";
import { ColumnsManagerType } from "components/DataTable/ColumnsManager/useColumns";
import CreateButton from "components/DataTable/CreateButton";
import DateRangeFilter from "components/DataTable/Filters/DateRangeFilter";
import { IDataProvider } from "components/DataTable/useDataProvider";
import SubmitButtonGroup from "components/Form/SubmitButtonGroup";
import PortalTo from "components/PortalTo";
import ReloadButton from "components/ReloadButton";

import { ButtonToolbar, Divider, Tooltip, Whisper } from "rsuite";
import CIcon from "../CIcon";
import { iDownload, iInfoCircle, iPrint, iUpload } from "assets/icons/Icons";
import React, { BaseSyntheticEvent, CSSProperties } from "react";
import { useDrawer } from "../Drawers/useDrawer";
import CUploaderModal from "../Drawers/CUploaderModal/CUploaderModal";
import { Colors } from "config/colors";
import ExportModal from "./Export/ExportModal";
import { ExportType } from "config/constants";

interface IProps<T extends IData = any> {
    cm?: ColumnsManagerType<T>;
    columnManagerState?: any;
    print?: boolean | any;
    exportType?: ExportType;
    export?: () => void;
    exportWithOptions?: {
        name: string;
        icon?: string;
        exportListUrl?: string;
        onClick?: () => void;
        iconStyle?: CSSProperties;
        dp?: IDataProvider;
        columns?: ITableColumnProps[];
    }[];
    importOptions?: IImportOption;
    reload?: boolean;
    onRevalidate?: () => void;
    create?: string | (() => void);
    customSubmitEvent?: {
        onSubmit: () => void;
        isLoading: boolean;
        title?: string;
        disabled?: boolean;
    };
    dp?: IDataProvider | any;
    date?: boolean;
    formSubmit?: {
        isLoading: boolean;
        onSave: (e: BaseSyntheticEvent, isSaveCreate?: boolean) => void;
        label?: string;
        disabled?: boolean;
        confirmModal?: any;
    };
    customButton?: React.ReactNode;
    printRef?: any;
    haveExportList?: boolean;
    showSaveCreateButton?: boolean;
    callout?: string;
}

const HeaderRight: FCC<IProps> = props => {
    const importModal = useDrawer();
    const exportModal = useDrawer();
    return (
        <>
            <PortalTo id="header-portal" className="d-flex">
                {props?.callout && (
                    <div
                        style={{
                            height: "36px",
                            backgroundColor: Colors.BabyBlue,
                            borderRadius: 5,
                            padding: 7,
                            marginRight: 10,
                        }}
                    >
                        <CIcon path={iInfoCircle} style={{ marginRight: 5 }} /> {props?.callout}
                    </div>
                )}
                {props.children}
                {props.create && (
                    <>
                        <CreateButton to={props.create} />
                        <Divider vertical />
                    </>
                )}
                <ButtonToolbar>
                    {props.customButton}

                    {!props.customSubmitEvent && props.formSubmit && (
                        <SubmitButtonGroup
                            disabled={props.formSubmit?.disabled || false}
                            isLoading={props.formSubmit.isLoading}
                            onSubmit={props.formSubmit.onSave}
                            label={props.formSubmit?.label}
                            confirmModal={props.formSubmit?.confirmModal}
                        />
                    )}

                    {!props.customSubmitEvent && props.formSubmit && props.showSaveCreateButton && (
                        <SubmitButtonGroup
                            isLoading={props.formSubmit.isLoading}
                            label={t("save_create")}
                            onSubmit={props.formSubmit?.onSave}
                            isSaveCreate
                        />
                    )}

                    {props.customSubmitEvent && (
                        <SubmitButtonGroup
                            isLoading={props.customSubmitEvent.isLoading}
                            disabled={props?.customSubmitEvent?.disabled}
                            label={props?.customSubmitEvent?.title || t("create")}
                            onSubmit={props.customSubmitEvent.onSubmit}
                            isSaveCreate
                        />
                    )}
                </ButtonToolbar>

                {props.date && <DateRangeFilter dp={props.dp} />}
                {props.print ||
                props.cm ||
                props.columnManagerState ||
                props.exportWithOptions ||
                props.importOptions ||
                props.export ? (
                    <div
                        className="d-flex align-items-center"
                        style={{
                            padding: "5px 10px",
                            height: "36px",
                            background: Colors.LightGray,
                            borderRadius: "30px",
                            gap: "11px",
                            width: "inherit",
                        }}
                    >
                        {props.cm && <ColumnsManagerButton cm={props.cm} />}
                        {props.columnManagerState && <ColumnsManagerButton cm={props.columnManagerState} />}
                        {props.print && (
                            <Whisper
                                placement="bottomStart"
                                trigger="hover"
                                speaker={
                                    <Tooltip>
                                        <TText tkey={"print_rt" as LangKey} />
                                    </Tooltip>
                                }
                            >
                                <div onClick={window.print}>
                                    <CIcon
                                        path={iPrint}
                                        size={0.75}
                                        style={{ color: Colors.CoolGray, marginBottom: "-3px" }}
                                        className="mt-1"
                                    />
                                </div>
                            </Whisper>
                        )}
                        {/*{props.exportWithOptions && (*/}
                        {/*    <Dropdown*/}
                        {/*        title={t("export_btn")}*/}
                        {/*        className="ml-2"*/}
                        {/*        icon={<CIcon className="mr-2" style={{ color: Colors.CoolGray }} path={iDownload} />}*/}
                        {/*    >*/}
                        {/*        {props.exportWithOptions.map(e => (*/}
                        {/*            <Dropdown.Item*/}
                        {/*                key={e.name}*/}
                        {/*                onSelect={e.onClick}*/}
                        {/*                className="mr-2"*/}
                        {/*                icon={*/}
                        {/*                    <CIcon path={e?.icon ?? "yc"} style={{ ...e.iconStyle }} className="mr-2" />*/}
                        {/*                }*/}
                        {/*            >*/}
                        {/*                <span className="ml-2">{e.name}</span>*/}
                        {/*            </Dropdown.Item>*/}
                        {/*        ))}*/}
                        {/*    </Dropdown>*/}
                        {/*)}*/}
                        {props.importOptions && (
                            <Whisper
                                placement="bottom"
                                trigger="hover"
                                speaker={
                                    <Tooltip>
                                        <TText tkey="import_btn" />
                                    </Tooltip>
                                }
                            >
                                <button
                                    onClick={() => importModal.setIsOpen(true)}
                                    style={{
                                        width: "2rem",
                                        height: "2rem",
                                        padding: "1px 1px 0px 8px",
                                        borderRadius: "0px",
                                        background: Colors.LightGray,
                                    }}
                                >
                                    <CIcon
                                        path={iUpload}
                                        size={0.7}
                                        style={{ color: Colors.CoolGray }}
                                        className="mr-2 mt-1"
                                    />
                                </button>
                            </Whisper>
                        )}
                        {(props.export || props.exportWithOptions) && (
                            <Whisper
                                placement="bottom"
                                trigger="hover"
                                speaker={
                                    <Tooltip>
                                        <TText tkey="export_btn" />
                                    </Tooltip>
                                }
                            >
                                <button
                                    test-id="export-button"
                                    style={{ background: Colors.LightGray, padding: "0" }}
                                    onClick={() => exportModal.setIsOpen(true)}
                                >
                                    <CIcon
                                        path={iDownload}
                                        size={0.7}
                                        style={{ color: Colors.CoolGray }}
                                        className="mt-1"
                                    />
                                </button>
                            </Whisper>
                        )}
                    </div>
                ) : null}
                {props.reload && (
                    <ReloadButton
                        loading={props.dp?.isValidating}
                        onClick={props.onRevalidate ? props.onRevalidate : props.dp?.revalidate}
                    />
                )}
            </PortalTo>

            {importModal.isOpen && <CUploaderModal {...importModal} importOptions={props.importOptions!} />}
            {(props.exportWithOptions || props.export) && exportModal.isOpen && (
                <ExportModal
                    {...exportModal}
                    type={props.exportType}
                    haveExportList={!!props?.haveExportList}
                    exportOptions={props?.exportWithOptions ?? [{ name: "Excel", onClick: props?.export }]}
                />
            )}
        </>
    );
};

export default HeaderRight;
