import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import React, { useEffect, useState } from "react";
import CreateButton from "../../../components/DataTable/CreateButton";
import MenuMedia from "components/mini/MenuMedia";
import _ from "lodash";
import ConfirmModal from "../../../components/Drawers/ConfirmModal";
import { Button, Message, toaster } from "rsuite";
import { useDrawer } from "components/Drawers/useDrawer";
import { useBulk } from "hooks/useBulk";
import { BulkModeTypes, CategoryTypeEnum } from "config/constants";
import CategoryBulkUpdateDrawer from "../drawer/CategoryBulkUpdateDrawer";
import { useLoading } from "hooks/useLoading";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import NoAccessModal from "../../../components/NoAccessModal";
import { useLocation } from "react-router-dom";
import { QuickLinks } from "components/Header/QuickLinks";
import CIcon from "../../../components/CIcon";
import { iCheckBoxChecked, iClose, iDoubleCheckBox, iEditPencil, iTrash } from "assets/icons/Icons";
import { RowDataType } from "rsuite-table/src/@types/common";

const MenuCategoriesPage: FCC = props => {
    const bulkDeleteModal = useDrawer();
    const bulkUpdateModal = useDrawer();
    const [pageType, setPageType] = useState(CategoryTypeEnum.PRODUCT);
    const { isLoading, withLoading } = useLoading();
    const [permissionLabel, setPermissionLabel] = useState("");
    const {
        isBulkModeOn,
        setIsBulkModeOn,
        bulkMode,
        setBulkMode,
        bulkData,
        setBulkData,
        resetBulkState,
        deleteRequest,
    } = useBulk();

    const dp = useDataProvider({
        url: "category",
        useUrl: true,
        filters: { type: ["type", "PRODUCT"] },
        params: { tree: -1, productCount: "1", emenuProductCount: "1" },
        sort: ["created_at", "1"],
        limit: 500,
        localSort: [["position"], ["asc"]],
    });
    const noAccessModal = useDrawer();

    const auth = useAuth();
    const cm = useColumns<any>("menu-category", [
        {
            key: "id",
            title: "ID",
            hide: true,
            flexGrow: 0.5,
        },
        {
            key: "name",
            verticalAlign: "top",
            minWidth: 120,
            align: "left",
            contentWrapClass: "treeView",
            render: (d: any) => (
                <div style={{ display: "flex", gap: 16 }}>
                    <MenuMedia {...d} style={{ marginLeft: _.isEmpty(d.children) && !_.isNull(d.parent_id) && 10 }} />
                    <div>{d.name}</div>
                </div>
            ),
        },
        // {
        //     key: "name",
        //     align: "left",
        //     flexGrow: 3,
        //     render: e => {
        //         return e.name
        //     },
        // },
        {
            key: "hidden",
            title: t("status"),
            type: "bool",
            render: e =>
                !e.hidden ? (
                    <CIcon path={iCheckBoxChecked} style={{ marginLeft: "1.5rem" }} className="text-success" />
                ) : (
                    // <Icon icon="check" style={{ marginLeft: "1.5rem" }} className="text-success" /> //h
                    <CIcon path={iClose} style={{ marginLeft: "1.5rem" }} className="text-danger" /> //h
                ),
        },
        {
            key: "products_count",
            getStyle: (d: ITransaction) => ({ marginLeft: "1.5rem" }),
            title: t("total_count"),
        },
        {
            key: "emenu_products_count",
            getStyle: (d: ITransaction) => ({ marginLeft: "1.5rem" }),
            title: t("emenu_category_product_count"),
        },
        optionsColumn({
            dp,
            edit: "/menu/categories/edit/:id",
            delete: "category/:id",
            canEdit: "category_edit",
            canDelete: "category_delete",
        }),
    ]);
    const loc = useLocation();

    const [bulkDeleteResult, setBulkDeleteResult] = useState({
        deleted_count: 0,
        could_not_deleted_count: 0,
    });

    useEffect(() => {
        if (isBulkModeOn) bulkUpdateModal.setData(bulkData, true);
        // eslint-disable-next-line
    }, [bulkData]);

    useEffect(() => {
        blkExit();
        // eslint-disable-next-line
    }, [loc]);

    const getAllIds = (item: ICategory[], ids: number[]) => {
        item.forEach(c => {
            ids.push(c.id);
            if (c.children?.length) {
                getAllIds(c.children, ids);
            }
        });
    };

    const blkHandleCheck = (item: any) => {
        const id = item.id as never;
        if (_.includes(bulkData, id)) {
            setBulkData(prev => prev.filter(f => f !== id));
        } else {
            setBulkData(prev => [...prev, id]);
        }
    };

    const blkHandleSelectAll = (selected: boolean) => {
        const data: Array<any> = dp.data ?? [];
        let _bulkData = [];
        if (selected) {
            _bulkData = data.map(d => {
                return d.id;
            });
        }

        setBulkData(_bulkData);
    };

    const blkApplyHandler = (mode: string) => {
        const permission = mode === BulkModeTypes.DELETE ? "category_delete" : "category_edit";
        if (!auth.hasAbility([permission])) {
            setPermissionLabel(permission);
            noAccessModal.setDataId(1);
            return;
        }
        if (mode === BulkModeTypes.UPDATE) {
            bulkUpdateModal.setDataId(1);
        } else if (mode === BulkModeTypes.DELETE) {
            bulkDeleteModal.setDataId(1);
        }
    };

    const blkPicker = (m: any) => {
        setBulkMode(m);
        setIsBulkModeOn(true);
    };

    const blkExit = () => resetBulkState();

    /* Bulk delete confirmation action. */
    const onConfirmDelete = () => {
        withLoading(() => {
            return deleteRequest("/category/bulk", bulkData);
        })
            .then(response => {
                setBulkDeleteResult(response.data);
                resetBulkState();
                bulkDeleteModal.onClose();
                dp.revalidate();
                toaster.push(
                    <Message type="success" showIcon closable>
                        {" "}
                        <TText tkey="success" />
                    </Message>,
                );
            })
            .catch(() => {
                bulkDeleteModal.onClose();
            });
    };

    return (
        <div className="h-100">
            {useTitle(t("category"), dp.total)}

            <QuickLinks
                links={[
                    {
                        link: '/menu/categories?{"page":1,"sort":["created_at","1"],"limit":500,"filters":{"type":["type","PRODUCT"]},"params":{"tree":-1,"productCount":"1"}}',
                        label: t("menu_categories"),
                        active: pageType === CategoryTypeEnum.PRODUCT,
                    },
                    {
                        link: '/menu/ingredientCategories?{"page":1,"sort":["created_at","1"],"limit":500,"filters":{"type":["type","INGREDIENT"]},"params":{"tree":-1,"productCount":"1"}}',
                        label: t("ingredient_categories"),
                        active: pageType === CategoryTypeEnum.INGREDIENT,
                        testId: "ingredient-categories",
                    },
                    {
                        link: '/menu/accounting-categories?{"page":1,"sort":["created_at","1"],"limit":500,"filters":{"type":["type","ACCOUNTING"]},"params":{"tree":-1,"productCount":"1"}}',
                        label: t("accounting_categories"),
                        active: pageType === CategoryTypeEnum.ACCOUNTING,
                        testId: "accounting-categories",
                    },
                ]}
            />

            <HeaderRight cm={cm} print reload dp={dp} />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    {!bulkMode && (
                        <Button className="multiple-select-button" ripple={false} onClick={() => blkPicker("true")}>
                            <CIcon path={iDoubleCheckBox} />
                            <span>
                                <TText tkey="multiple_select" />
                            </span>
                        </Button>
                    )}
                    {bulkMode && (
                        <div className="bulk-mode">
                            <span className="badge">{bulkData.length}</span>
                            <div onClick={() => blkExit()} className="d-flex">
                                <CIcon path={iClose} />
                                <span className="cancel-button">
                                    <TText tkey="cancel" />
                                </span>
                            </div>
                        </div>
                    )}
                    <SearchFilter fields="name" />
                    {bulkData.length > 0 && (
                        <div className="bulk-acton-buttons">
                            <Button
                                ripple={false}
                                className="edit"
                                onClick={() => blkApplyHandler(BulkModeTypes.UPDATE)}
                            >
                                <CIcon path={iEditPencil} />
                                <TText tkey="change_set" />
                            </Button>
                            <Button
                                ripple={false}
                                className="delete"
                                onClick={() => blkApplyHandler(BulkModeTypes.DELETE)}
                            >
                                <CIcon path={iTrash} />
                                <TText tkey="delete" />
                            </Button>
                        </div>
                    )}
                </div>
                <CreateButton to={"/menu/categories/create/"} canCreate={"category_create"} />
            </FiltersContainer>
            <div className="categories_db-container">
                <DataTable
                    dp={dp}
                    columns={cm.columns}
                    tableProps={{
                        isTree: true,
                        renderTreeToggle: (icon: React.ReactNode, rowData?: RowDataType) => {
                            return rowData?.children?.length && rowData?.children?.length > 0 ? (
                                <div test-id="toggler-button">{icon}</div>
                            ) : (
                                <span style={{ width: 7, display: "block" }}> </span>
                            );
                        },
                    }}
                    isBulkMode={isBulkModeOn}
                    bulkData={bulkData}
                    setBulkData={blkHandleCheck}
                    setAllBulkData={blkHandleSelectAll}
                />
            </div>
            <CategoryBulkUpdateDrawer
                {...bulkUpdateModal}
                onSubmit={() => {
                    dp.revalidate();
                    blkExit();
                    bulkUpdateModal.hide();
                }}
            />
            <ConfirmModal {...bulkDeleteModal} isLoading={isLoading} onConfirm={onConfirmDelete} />
            <NoAccessModal modal={noAccessModal} permission={[permissionLabel]} />
        </div>
    );
};

export default MenuCategoriesPage;
