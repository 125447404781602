import { ServiceChargeFixedTypes, ServiceChargeTypeEnum } from "config/constants";
import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";
import { getDataPropertiesOf } from "lib/utils";

const priceMax = (value: any, values: any, parentData?: any): string | undefined => {
    let limit = Number.MAX_SAFE_INTEGER;
    if (getDataPropertiesOf(values).type === ServiceChargeTypeEnum.FIXED_PERCENTAGE) {
        limit = 100;
    }
    return ruleset.lte(limit)(value, values, parentData);
};

export const ServiceChargeSchema: ValidationSchema = {
    name: ruleset.required,
    price: ruleset.combineIf(
        (val, vals, par) => vals.status && ServiceChargeFixedTypes.includes(getDataPropertiesOf(vals).type),
        [ruleset.gt(0), priceMax],
    ),
    properties: {
        type: ruleset.required,
        calculation_method: ruleset.required,
    },
};
