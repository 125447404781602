import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import React, { useEffect, useMemo, useState } from "react";
import MenuMedia from "components/mini/MenuMedia";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { Button, Loader, Message, toaster, Toggle } from "rsuite";
import Ajax from "../../../lib/Ajax";
import { useLoading } from "hooks/useLoading";
import { BulkModeTypes, ProductType } from "config/constants";
import { useDrawer } from "components/Drawers/useDrawer";
import { useBulk } from "hooks/useBulk";
import EMenuBulkUpdateDrawer from "../../menu/drawer/EMenuBulkUpdateDrawer";
import { useAppSelector } from "hooks/useRedux";
import { getLabel } from "components/Form/form-utils";
import { iCheckBoxChecked, iClose, iDoubleCheckBox, iEditPencil, iTrash } from "assets/icons/Icons";
import CIcon from "../../../components/CIcon";
import { useData } from "hooks/useData";
import CheckListFilter from "../../../components/DataTable/Filters/CheckListFilter";
import BulkDeleteResultModal from "components/Drawers/BulkDeleteResultModal/BulkDeleteResultModal";
import ConfirmModal from "components/Drawers/ConfirmModal";
import ErrorModal from "components/ErrorModal";
import { useSetting } from "hooks/useSetting";
import { useEmenuLanguages } from "hooks/useEmenuLanguages";

const EMenuProductsPage: FCC = props => {
    const bulkDeleteModal = useDrawer();
    const bulkDeleteResultModal = useDrawer();
    const errorModal = useDrawer();
    const bulkUpdateModal = useDrawer();
    const {
        isBulkModeOn,
        deleteRequest,
        setIsBulkModeOn,
        bulkMode,
        setBulkMode,
        bulkData,
        setBulkData,
        resetBulkState,
    } = useBulk();
    const history = useHistory();
    const remoteLang = useSetting("language")?.value;
    const localLang = useAppSelector(state => state.lang).current;
    const dashboardLang = localLang ?? remoteLang;
    const emenuLangs = useEmenuLanguages().filter((l: string) => l !== dashboardLang);
    const { isLoading, withLoading } = useLoading();
    const [changedProduct, setChangedProduct] = useState(0);
    const [bulkDeleteResult, setBulkDeleteResult] = useState({
        deleted_count: 0,
        could_not_deleted_count: 0,
    });
    const translateForLangs = useSetting("emenu_lang")?.extra;

    const dp = useDataProvider({
        useUrl: true,
        url: "product",
        with: ["category", "emenu_category", "station", "modifications"],
        filters: {
            type: [
                ["type", "GOODS"],
                ["type", "DISH"],
            ],
        },
        refineList: list => {
            return list?.reduce((newList, item) => {
                return _.uniqBy(newList.concat(item).concat(item.modifications ?? []), "id");
            }, [] as any[]);
        },
    });

    const refineColumnsCat = useMemo((): ITableColumnProps[] => {
        return _.map(emenuLangs ?? [], (lang: string): ITableColumnProps => {
            return {
                title: `${t("category")} (${(translateForLangs as any)[lang]})`,
                key: `emenu_category.meta.langs.${lang}`,
                minWidth: 120,
                render: e => {
                    return e.emenu_category?.meta?.langs?.[lang]?.name || "";
                },
            };
        });
        // eslint-disable-next-line
    }, [dp.data]);

    const refineColumnsDesc = useMemo((): ITableColumnProps[] => {
        return _.map(emenuLangs ?? [], (lang: string): ITableColumnProps => {
            return {
                key: `langs${lang}.description`,
                title: `${t("desc")} (${(translateForLangs as any)[lang]})`,
                align: "left",
                hide: false,
                minWidth: 140,

                render: e => {
                    // const modificationParent = dp.data.find((d: IProduct) => d.id === e.parent_id);
                    return e.meta?.langs?.[lang]?.description || "";
                },
            };
        });
        // eslint-disable-next-line
    }, [dp.data]);

    const refineColumns = useMemo((): ITableColumnProps[] => {
        return _.map(emenuLangs ?? [], (lang: string): ITableColumnProps => {
            return {
                title: `${t("name")} (${(translateForLangs as any)[lang]})`,
                key: `langs.${lang}.name`,
                render: (e: any) => {
                    return e.meta?.langs?.[lang]?.name || "";
                },
                hide: true,
                minWidth: 100,
            };
        });
        // eslint-disable-next-line
    }, [dp.data]);

    const toggleHide = (checked: boolean, event: any, d: IProduct) => {
        const updateUrl = `product/${d.type.toLowerCase()}/${d.id}`;
        const data =
            d.type === "DISH"
                ? _.omit(d, ["contents", "image", "unit"])
                : d.has_modifications
                  ? d
                  : _.omit(d, ["modifications", "image"]);
        const hide = !checked ? 1 : 0;
        setChangedProduct(d.id);
        withLoading(() => {
            return Ajax.put({
                url: updateUrl,
                data: {
                    ...data,
                    emenu_hidden: hide,
                },
            });
        })
            .then(async resp => {
                await dp.revalidate();
                setChangedProduct(0);
            })
            .catch((e: CustomError) => {
                toaster.push(
                    <Message type="error" showIcon closable>
                        {e.message}
                    </Message>,
                );
                setChangedProduct(0);
            });
    };

    const cm = useColumns<any>("emenu-product", [
        {
            key: "image",
            flexGrow: undefined,
            width: 70,
            verticalAlign: "top",
            render: (d: any) => (
                <MenuMedia {...d} style={{ marginLeft: _.isEmpty(d.children) && !_.isNull(d.parent_id) && 10 }} />
            ),
        },
        { key: `name`, align: "left" },
        {
            key: "emenu_hidden",
            width: 50,
            title: t("show"),
            render: e =>
                e.parent_id ? null : changedProduct === e.id ? (
                    <Loader />
                ) : (
                    <Toggle
                        checkedChildren={<CIcon path={iCheckBoxChecked} style={{ marginBottom: "-3px" }} />}
                        checked={!e.emenu_hidden}
                        unCheckedChildren={<CIcon path={iClose} style={{ marginBottom: "-3px" }} />}
                        onChange={(checked: boolean, event: any) => toggleHide(checked, event, e)}
                    />
                ),
        },
        {
            title: `${t("category")} (${(translateForLangs as any)[dashboardLang]})`,
            key: `emenu_category.meta.langs.${dashboardLang}`,
            width: 150,
            render: e => {
                return e.emenu_category?.meta?.langs?.[dashboardLang]?.name || "";
            },
        },
        ...refineColumnsCat,
        {
            key: `langs${dashboardLang}.name`,
            title: `${t("name")} (${(translateForLangs as any)[dashboardLang]})`,
            align: "left",
            minWidth: 300,
            render: e => {
                // const modificationParent = dp.data.find((d: IProduct) => d.id === e.parent_id);
                return e.meta?.langs?.[dashboardLang]?.name || "";
            },
        },
        {
            key: `langs${dashboardLang}.description`,
            title: `${t("desc")} (${(translateForLangs as any)[dashboardLang]})`,
            align: "left",
            hide: false,
            minWidth: 200,
            render: e => {
                // const modificationParent = dp.data.find((d: IProduct) => d.id === e.parent_id);
                return e.meta?.langs?.[dashboardLang]?.description || "";
            },
        },
        ...refineColumns,
        ...refineColumnsDesc,
        {
            key: `type`,
            width: 70,
            align: "left",
            render: d => _.capitalize(t(d.type.toLowerCase())),
        },
        optionsColumn({
            dp,
            editable: (d: any) => d.parent_name,
            preventDelete: (d: any) => d.parent_name,
            edit: d => {
                history.push(`/e-menu/products/${d.type.toLowerCase()}/edit/:id`?.replace(":id", d.id.toString()));
            },
            delete: d => `product/${d.type.toLowerCase()}/:id`,
        }),
    ]);

    useEffect(() => {
        if (isBulkModeOn) bulkUpdateModal.setData(bulkData, true);
        // eslint-disable-next-line
    }, [bulkData]);

    const blkPicker = (m: any) => {
        setBulkMode(m);
        setIsBulkModeOn(true);
    };

    const blkExit = () => resetBulkState();

    const blkHandleCheck = (item: any) => {
        const id = item.id as never;
        if (bulkData.includes(id)) {
            setBulkData(prev => prev.filter(f => f !== id));
        } else {
            setBulkData(prev => [...prev, id]);
        }
    };

    const blkHandleSelectAll = (selected: boolean) => {
        const data: Array<any> = dp.data ?? [];
        let _bulkData = [];
        if (selected) {
            _bulkData = data.map(d => {
                return d.id;
            });
        }

        setBulkData(_bulkData);
    };

    const blkApplyHandler = (mode: string) => {
        if (mode === BulkModeTypes.UPDATE) {
            bulkUpdateModal.setDataId(1);
        } else if (mode === BulkModeTypes.DELETE) {
            bulkDeleteModal.setDataId(1);
        }
    };

    const onConfirmDelete = () => {
        withLoading(() => {
            return deleteRequest("/product/bulk", bulkData);
        })
            .then(response => {
                setBulkDeleteResult(response.data);
                bulkDeleteResultModal.setIsOpen(true);
                resetBulkState();
                bulkDeleteModal.onClose();
                dp.revalidate();
            })
            .catch(e => {
                bulkDeleteModal.onClose();
                if (e.models?.length) {
                    errorModal.setData(e);
                }
            });
    };

    const emenuCategories = useData("category", {
        params: {
            tree: 0,
            limit: 999,
        },
        filters: { type: ["type", "PRODUCT"] } as any,
    }).data?.map((i: any) => ({ ...i, value: i.id, label: i.name }));

    return (
        <div className="h-100">
            {useTitle(t("products"), dp.total)}
            <HeaderRight cm={cm} print reload dp={dp} />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    {!bulkMode && (
                        <Button className="multiple-select-button" ripple={false} onClick={() => blkPicker("true")}>
                            <CIcon path={iDoubleCheckBox} />
                            <span>
                                <TText tkey="multiple_select" />
                            </span>
                        </Button>
                    )}
                    {bulkMode && (
                        <div className="bulk-mode">
                            <span className="badge">{bulkData.length}</span>
                            <div onClick={() => blkExit()} className="d-flex">
                                <CIcon path={iClose} />
                                <span className="cancel-button">
                                    <TText tkey="cancel" />
                                </span>
                            </div>
                        </div>
                    )}

                    <SearchFilter fields="name" />
                    <CheckListFilter
                        fields={"emenu_category_id"}
                        options={emenuCategories}
                        pickerProps={{
                            placeholder: t("category"),
                            cleanable: true,
                            renderMenuGroup: (_title: React.ReactNode, item: any) =>
                                `(${t("category")}) ${getLabel(item.groupTitle)}`,
                        }}
                        maxWidth={200}
                        width={200}
                    />
                    {bulkData.length > 0 && (
                        <div className="bulk-acton-buttons">
                            <Button
                                ripple={false}
                                className="edit"
                                onClick={() => blkApplyHandler(BulkModeTypes.UPDATE)}
                            >
                                <CIcon path={iEditPencil} />
                                <TText tkey="change_set" />
                            </Button>
                            <Button
                                ripple={false}
                                className="delete"
                                onClick={() => blkApplyHandler(BulkModeTypes.DELETE)}
                            >
                                <CIcon path={iTrash} />
                                <TText tkey="delete" />
                            </Button>
                        </div>
                    )}
                </div>
            </FiltersContainer>

            <DataTable
                dp={dp}
                columns={cm.columns}
                isBulkMode={isBulkModeOn}
                bulkData={bulkData}
                bulkException={["type", ProductType.MODIFICATION]}
                setBulkData={blkHandleCheck}
                setAllBulkData={blkHandleSelectAll}
                virtualized={true}
            />
            <EMenuBulkUpdateDrawer
                {...bulkUpdateModal}
                onSubmit={() => {
                    dp.revalidate();
                    blkExit();
                    bulkUpdateModal.hide();
                }}
            />
            <ErrorModal modal={errorModal} />
            <ConfirmModal {...bulkDeleteModal} isLoading={isLoading} onConfirm={onConfirmDelete} />
            <BulkDeleteResultModal modal={bulkDeleteResultModal} bulkDeleteResult={bulkDeleteResult} />
        </div>
    );
};

export default EMenuProductsPage;
