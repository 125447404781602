import { TText } from "components/i18n/TText";
import RadioFormGroup from "components/Form/RadioFormGroup";
import { UseFormReturnType } from "hooks/useCForm";
import { FlexboxGrid, Radio } from "rsuite";
import { t } from "lib/i18n";

interface IProps {
    form: UseFormReturnType;
    description?: string;
    radioName: string;
    labelProps: any;
    label?: string;
    hideLabel?: boolean;
    options: Array<any>;
}

const TimerRadioGroupField: FCC<IProps> = ({ labelProps, radioName, hideLabel, label, description, form, options }) => {
    const value: any = form.form.getValues(radioName);
    return (
        <RadioFormGroup
            name={radioName}
            hideLabel={hideLabel}
            label={label}
            labelProps={labelProps}
            labelDescription={description ?? ""}
            style={{ wordBreak: "break-all", wordWrap: "break-word" }}
            compProps={{ value: value ? 1 : 0 }}
        >
            <FlexboxGrid className="flex-column">
                {options.map(opt => {
                    return (
                        <FlexboxGrid.Item>
                            <Radio value={opt.value} disabled={opt.disabled} test-id={`${radioName}_${opt.value}`}>
                                <div className="radio-desc">
                                    <span className="title">
                                        {t(opt.label)}
                                        {opt.disabled ? (
                                            <span className="m-2 loyalty-soon__text">
                                                <TText tkey="soon" />
                                            </span>
                                        ) : null}
                                    </span>
                                    {opt.desc && <span className="subtitle">{t(opt.desc)}</span>}
                                </div>
                            </Radio>
                        </FlexboxGrid.Item>
                    );
                })}
            </FlexboxGrid>
        </RadioFormGroup>
    );
};

export default TimerRadioGroupField;
