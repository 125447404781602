import { t } from "lib/i18n";
import React, { memo } from "react";
import { roundAndClean, translateUnitName } from "lib/utils";
import _ from "lodash";
import RecipeIcon from "../../products/dishes/edit/RecipeIcon";
import { store } from "redux/store";
import { useAppSelector } from "hooks/useRedux";
import { GridInput } from "components/NewForm/Grid/GridInput";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { FIListItemProps } from "components/FormItems/FormItemsList";
import { GridButtonRemove } from "components/NewForm/Grid/GridButtonRemove";
import { GridProductSelectItem } from "components/NewForm/Grid/GridProductSelectItem";
import { useCurrency } from "hooks/useCurrency";

interface IPreparationItemProps extends FIListItemProps<PreparationItemField> {}

export interface PreparationItemField {
    id: number;
    name: string;
    type: string;
    unit_id: number;
    unit_weight: number;
    cost_price: number;
    gross: number;
    full_name?: string;
}

const PreparationItem: FCC<IPreparationItemProps> = memo(
    ({ field, index, remove, form, error }) => {
        const { setValue, register } = form;

        const currency = useCurrency();
        const unitsById = useAppSelector(state => state.data.units.byId);
        const onSelectItem = (id: number) => {
            const productsById = store.getState().data.products.byId;
            const selected = productsById[id];

            setValue(`contents[${index}]`, {
                price: 0,
                gross: 0,
                id: selected?.id ?? null,
                cost_price: selected?.cost_price ?? 0,
                unit: selected.unit ?? null,
                unit_id: selected?.unit?.id ?? selected.unit_id ?? null,
                name: selected?.name ?? null,
                type: selected?.type,
                unit_weight: Number(selected?.unit_weight) ?? null,
            });
        };

        const grossWeight: number = Number(field.unit_weight) * Number(field.gross);
        const price: number = Number(field.cost_price) * Number(field.gross);

        return (
            <>
                <GridProductSelectItem onSelect={onSelectItem} name={`contents[${index}].id`} />

                <div className="tw-flex tw-justify-center tw-items-center tw-h-full">
                    {field.type ? <RecipeIcon size={1} testId={`contents[${index}].icon`} type={field.type} /> : "-"}
                </div>

                <GridInput
                    name={`contents[${index}].gross`}
                    index={index}
                    postfix={translateUnitName(unitsById[field.unit_id]) ?? undefined}
                    type="number"
                    decimals={[0, 4]}
                />

                <GridItemPlain
                    testId={`contents[${index}].gross_weight`}
                    align="right"
                    value={roundAndClean(grossWeight, 4)}
                    postfix={t("kg_short")}
                />

                <GridItemPlain
                    testId={`contents[${index}].gross_price`}
                    align="right"
                    value={roundAndClean(price, 4)}
                    postfix={currency}
                />
                <GridButtonRemove onClick={() => remove(index)} />
            </>
        );
    },
    (prev, next) => {
        return prev.index === next.index && _.isEqual(prev.field, next.field) && next.error === prev.error;
    },
);

export default PreparationItem;
