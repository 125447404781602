import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import CreateButton from "components/DataTable/CreateButton";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";

import CIcon from "../../../components/CIcon";
import { iCheckBoxChecked, iClose } from "assets/icons/Icons";
import React from "react";
import { Colors } from "config/colors";

const RolesPage: FCC = () => {
    const dp = useDataProvider({
        url: "role",
        with: ["abilities"],
        useUrl: true,
        refineList: (d?: any) => d?.filter((role: IRole) => role.name !== "owner"),
    });

    const cm = useColumns<any>("role", [
        {
            key: "title",
            align: "left",
        },
        {
            key: "web_access",

            render: (data: any) => {
                if (data.abilities.find((i: any) => i.name === "web_access")) {
                    // h
                    return (
                        <div
                            className="d-flex align-items-center rounded-circle justify-content-center"
                            style={{ background: Colors.MintGreen, width: "16px", height: "16px" }}
                        >
                            <CIcon path={iCheckBoxChecked} style={{ color: Colors.TropicalGreen }} size={0.45} />
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="d-flex align-items-center rounded-circle justify-content-center"
                            style={{ background: Colors.SoftPeach, width: "16px", height: "16px" }}
                        >
                            <CIcon path={iClose} style={{ color: Colors.BrightRed }} size={0.45} />
                        </div>
                    );
                }
            },
        },
        {
            key: "terminal_access",

            render: (data: any) => {
                if (data.abilities.find((i: any) => i.name === "terminal_access")) {
                    return (
                        <div
                            className="d-flex align-items-center rounded-circle justify-content-center"
                            style={{ background: Colors.MintGreen, width: "16px", height: "16px" }}
                        >
                            <CIcon path={iCheckBoxChecked} style={{ color: Colors.TropicalGreen }} size={0.45} />
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="d-flex align-items-center rounded-circle justify-content-center"
                            style={{ background: Colors.SoftPeach, width: "16px", height: "16px" }}
                        >
                            <CIcon path={iClose} style={{ color: Colors.BrightRed }} size={0.45} />
                        </div>
                    );
                }
            },
        },
        {
            key: "field_seller",

            render: (data: any) => {
                if (data.abilities.find((i: any) => i.name === "field_seller")) {
                    return (
                        <div
                            className="d-flex align-items-center rounded-circle justify-content-center"
                            style={{ background: Colors.MintGreen, width: "16px", height: "16px" }}
                        >
                            <CIcon path={iCheckBoxChecked} style={{ color: Colors.TropicalGreen }} size={0.45} />
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="d-flex align-items-center rounded-circle justify-content-center"
                            style={{ background: Colors.SoftPeach, width: "16px", height: "16px" }}
                        >
                            <CIcon path={iClose} style={{ color: Colors.BrightRed }} size={0.45} />
                        </div>
                    );
                }
            },
        },
        {
            key: "field_courier",

            render: (data: any) => {
                if (data.abilities.find((i: any) => i.name === "field_courier")) {
                    return (
                        <div
                            className="d-flex align-items-center rounded-circle justify-content-center"
                            style={{ background: Colors.MintGreen, width: "16px", height: "16px" }}
                        >
                            <CIcon path={iCheckBoxChecked} style={{ color: Colors.TropicalGreen }} size={0.45} />
                        </div>
                    );
                } else {
                    return (
                        <div
                            className="d-flex align-items-center rounded-circle justify-content-center"
                            style={{ background: Colors.SoftPeach, width: "16px", height: "16px" }}
                        >
                            <CIcon path={iClose} style={{ color: Colors.BrightRed }} size={0.45} />
                        </div>
                    );
                }
            },
        },
        optionsColumn({
            dp,
            edit: "/staff/roles/edit/:id",
            delete: "role/:id",
            canEdit: "role_manage_edit",
            canDelete: "role_manage_delete",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("roles"))}
            <HeaderRight cm={cm} dp={dp} reload />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill" />
                <CreateButton to={"/staff/roles/create"} canCreate={"role_manage_create"} />
            </FiltersContainer>
            <DataTable dp={dp} columns={cm.columns} />
        </div>
    );
};

export default RolesPage;
