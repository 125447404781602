import StorageCheckListItem from "components/DataTable/Filters/StorageCheckListItem";
import SelectFormGroup, { SelectFormGroupProps } from "components/Form/SelectFormGroup";
import { t } from "lib/i18n";
import { useStorages } from "hooks/data-hooks/useStorages";
import { FC } from "react";

interface IStorageSelectFormGroupProps extends Omit<SelectFormGroupProps, "data" | "name"> {
    name?: string;
    showOnlyActive?: boolean;
    showAllVenues?: boolean;
    hideStatus?: boolean;
    refineList?: (list: IStorageWithVenue[]) => IStorageWithVenue[];
}

export const StorageSelectFormGroup: FC<IStorageSelectFormGroupProps> = ({
    name = "storage_id",
    label = t("storage_id"),
    showOnlyActive,
    showAllVenues,
    hideStatus,
    refineList,
    compProps,
    ...props
}) => {
    const storages = useStorages({ showOnlyActive, showAllVenues, refineList });

    return (
        <SelectFormGroup
            name={name}
            label={label}
            data={storages.list}
            errorPlacement="rightEnd"
            {...props}
            compProps={{
                cleanable: true,
                "test-id": name,
                renderMenuItem: (label: any, item: any) => (
                    <StorageCheckListItem
                        label={label}
                        storage={item}
                        testId={`${name}_item`}
                        hideStatus={hideStatus}
                    />
                ),
                ...compProps,
                locale: {
                    noResultsText: t("no_active_storage_found"),
                    ...compProps?.locale,
                },
            }}
        />
    );
};
