import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import _ from "lodash";
import RemoteSelectFormGroup from "../../components/Form/RemoteSelectFormGroup";
import { CategoryTypeEnum } from "config/constants";
import React from "react";

interface IProps {
    label?: string;
    hideLabel?: boolean;
    name?: string;
    modal?: any;
    revalidate?: any;
    plaintext?: boolean;
    no_cat_option?: boolean;
    no_cat_btn?: boolean;
    filters?: any;
    with?: any;
    create?: boolean;
    disabledIds?: number[];
    style?: any;
    disabled?: boolean;
    testId?: string;
    types?: any[][];
    type?: CategoryTypeEnum;
    onClean?: () => void;
    onOpen?: () => void;
    form?: any;
    renderMenuItem?: (title: React.ReactNode, item: any) => React.ReactNode;
}

const CategorySelect: FCC<IProps> = props => {
    return (
        <RemoteSelectFormGroup
            disabled={props?.disabled}
            hideLabel={props.hideLabel}
            revalidate={props.revalidate}
            plaintext={props.plaintext}
            testId={props.testId}
            searchLocal={true}
            name={props.name ? props.name : "category_id"}
            remote={{
                url: "category",
                params: { tree: 0 },
                filters: props.filters
                    ? props.filters
                    : {
                          type: props.types ? props.types : ["type", "PRODUCT"],
                      },
                with: props?.with || undefined,
                refineList: d => {
                    const newList: any[] =
                        d?.filter(s => {
                            return props.disabledIds ? !props.disabledIds?.includes(s.id) : true;
                        }) || [];
                    if (props.no_cat_option) {
                        newList.push({ id: 0, name: t("without_cat"), children: [] });
                    }
                    return newList;
                },
            }}
            label={props.label ? props.label : t("category")}
            compProps={{
                style: props.style,
                sort: () => (a, b) => a._lft - b._lft,
                onOpen: props.onOpen,
                onClean: props.onClean,
                // sort: () => (a, b) => a.position - b.position,
                renderMenuItem: props.renderMenuItem
                    ? props.renderMenuItem
                    : (label: any, item: any) => {
                          return (
                              <span test-id={`${props.name ?? "category_id"}_item`}>
                                  {_.times(item.depth).map((x, i) => (
                                      <span key={i} className="px-2">
                                          --
                                      </span>
                                  ))}
                                  {label}
                              </span>
                          );
                      },
                renderExtraFooter: () => {
                    if (props.modal && props.no_cat_btn && props.form) {
                        return (
                            <div
                                className="rs-picker-select-menu-item text-center"
                                onClick={e => {
                                    props.form.form.setValue(props.name ? props.name : "category_id", 0);
                                }}
                            >
                                <TText tkey="clear_category" />
                                {/*{t("create_new_by_name", { name: t("category") })}*/}
                            </div>
                        );
                    }
                    if (props.modal && (props.create === undefined || props.create)) {
                        return (
                            <div
                                test-id={`create-${props.name ?? "category_id"}`}
                                className="rs-picker-select-menu-item text-center"
                                onClick={() => {
                                    props.modal.setDataId(0);
                                    props.modal.setData({
                                        name: props.name ? props.name : "category_id",
                                        type: props.type ? props.type : CategoryTypeEnum.PRODUCT,
                                    });
                                }}
                            >
                                {t("create_new_by_name", { name: t("category") })}
                            </div>
                        );
                    }
                },

                cleanable: true,
            }}
            className="mr-1"
            errorPlacement="rightEnd"
        />
    );
};
export default CategorySelect;
