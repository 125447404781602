import React from "react";
import { getLabel } from "./form-utils";

interface IProps {
    name: string;
    form?: any;
    value?: any;
    className?: string;
    label?: string;
}

export const PlainImage: FCC<IProps> = ({ label, name, form, value, className }) => {
    return (
        <div className={`rs-form-group mb-2 ${className}`} role="group">
            <label className="rs-control-label">{label || getLabel(name)}</label>
            <div className="rs-form-control-wrapper">
                <div className="rs-uploader rs-uploader-picture rs-uploader-draggable">
                    <div className="rs-uploader-trigger rs-uploader-trigger-customize">
                        {value ? <img src={value} alt="Images[0].uuid" className="img-fluid" /> : null}
                    </div>
                </div>
            </div>
        </div>
    );
};
