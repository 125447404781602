import { TText } from "components/i18n/TText";

import * as React from "react";
import { ErrorInfo } from "react";
import * as Sentry from "@sentry/browser";

export class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { error: any }> {
    constructor(props: any) {
        super(props);
        this.state = { error: null };
    }

    public componentDidCatch(error: Error, info: ErrorInfo): void {
        // log.runtimeError(error);
        Sentry.captureException(error);
        this.setState({ error });
    }

    public componentWillUnmount() {
        this.setState({ error: null });
    }

    public render() {
        const error = this.state.error;
        if (error) {
            return (
                <div className="alert alert-danger">
                    <h3 className="alert-heading">
                        <TText tkey="dom_error" />
                    </h3>
                    <p>{error?.message}</p>
                    {/* <pre>{error.info?.componentStack}</pre> */}
                    {/* <pre>{error.error?.toString()}</pre> */}
                </div>
            );
        }
        return this.props.children;
    }
}
