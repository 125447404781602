import { TText } from "components/i18n/TText";
import { fontSizeOptions, textForDADTitle } from "../utils";
import { Checkbox, SelectPicker } from "rsuite";
import { IReceiptItem } from "../../types";
import { useAppDispatch } from "hooks/useRedux";
import { handleChangeTextSizeAC } from "redux/features/constructor/constructor-thunks";

import React from "react";
import { toggleStationReceiptItemActivityByType } from "redux/features/stationPrintConstructor";

const RDefaultComp = (item: IReceiptItem) => {
    const dispatch = useAppDispatch();
    const handleToggleActivityByType = (key: string | undefined) => {
        if (key) {
            dispatch(toggleStationReceiptItemActivityByType({ key: key as keyof IReceiptItem, type: "receiptInfo" }));
        }
    };
    return (
        <div className="d-flex align-items-center justify-content-between mr-2">
            <div>
                {textForDADTitle[item.type]}
                <Checkbox
                    className="ml-2"
                    onChange={(v: string | number | undefined) => handleToggleActivityByType(v as keyof IReceiptItem)}
                    value={"owner"}
                    checked={!!item?.owner}
                >
                    <TText tkey="owner" />
                </Checkbox>
            </div>

            <SelectPicker
                cleanable={false}
                onSelect={v => dispatch(handleChangeTextSizeAC(v, item))}
                value={item.textSize}
                data={fontSizeOptions}
                size="sm"
                searchable={false}
            />
        </div>
    );
};
export default RDefaultComp;
