import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import JsonViewState from "components/State/JsonViewState";
import Ajax from "lib/Ajax";
import _ from "lodash";
import { useEffect, useState } from "react";
import { IconButton, Input, Message, toaster } from "rsuite";
import { iClose } from "assets/icons/Icons";
import CIcon from "../CIcon";
import { useSetting } from "hooks/useSetting";
import { useAppDispatch } from "hooks/useRedux";
import { setSettingByField } from "redux/features/settings";

type IProps = ICDrawerProps;

export type SocialNetworkList = {
    name: string;
    value: string;
    orName: string;
};

const SNPickerList = [
    {
        label: "Veb sayt",
        value: "web",
    },
    {
        label: "Instagram",
        value: "instagram",
    },
    {
        label: "Facebook",
        value: "facebook",
    },
    {
        label: "Youtube",
        value: "youtube",
    },
    {
        label: "TikTok",
        value: "tiktok",
    },
    {
        label: "Twitter",
        value: "twitter",
    },
    {
        label: "TripAdvisor",
        value: "tripadvisor",
    },
    {
        label: "Yelp",
        value: "yelp",
    },
    {
        label: "Vkontakt",
        value: "vkontakt",
    },
    {
        label: "Odnoklassniki",
        value: "odnoklassniki",
    },
    {
        label: t("other"),
        value: "",
    },
];

const SocialNetworksDrawer: FCC<IProps> = props => {
    const dispatch = useAppDispatch();
    const socialNetworksData: any = useSetting("brand_social_networks")?.value;
    const [SNList, setSNList] = useState<SocialNetworkList[]>([
        {
            name: "",
            orName: "",
            value: "",
        },
    ]);

    useEffect(() => {
        setSNList(SNPickerList.map(sn => ({ name: sn.value, orName: sn.label, value: "" })));
    }, []);

    useEffect(() => {
        if (!_.isString(socialNetworksData)) {
            setSNList(prev =>
                prev.map(sn => ({
                    ...sn,
                    value: socialNetworksData.find((data: SocialNetworkList) => data.name === sn.name)?.value || "",
                })),
            );
        }
    }, [socialNetworksData]);

    const handleInputChange = (index: number, value: string) => {
        setSNList(prev => prev.map((sn, i) => (i === index ? { ...sn, value } : sn)));
    };

    const handleClearInput = (index: number) => {
        setSNList(prev => prev.map((sn, i) => (i === index ? { ...sn, value: "" } : sn)));
    };

    const submitAction = async () => {
        await Ajax.post({
            url: "setting/update/",
            data: {
                settings: {
                    brand_social_networks: {
                        access: true,
                        group: "brand",
                        name: "brand_social_networks",
                        type: "string",
                        value: JSON.stringify(SNList),
                    },
                },
            },
        })
            .then(res => {
                dispatch(
                    setSettingByField({
                        brand_social_networks: {
                            ...res.data.brand_social_networks,
                            value: SNList,
                        },
                    }),
                );
            })
            .catch(() =>
                toaster.push(
                    <Message type="error" showIcon closable>
                        <TText tkey="error" />
                    </Message>,
                ),
            );
        props.onClose?.({} as any);
    };

    return (
        <CDrawer {...props} title={t("brand_social_networks")} saveIBtn={submitAction} style={{ width: 500 }}>
            <div style={{ marginTop: 10 }}>
                {SNList.map((sn: SocialNetworkList, index: number) => (
                    <div
                        key={sn.name}
                        style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            justifyContent: "space-around",
                            marginTop: 5,
                        }}
                    >
                        <p style={{ width: 150 }}>{sn.orName}</p>
                        {/* <SelectPicker
                            size="md"
                            placeholder={t("brand_social_networks")}
                            data={SNPickerList}
                            value={sn.name}
                            onChange={(name: string) => setSNList(prev => prev.map((p, pI) => pI === index ? {
                                name,
                                value: p.value
                            } : p))}
                            placement={"bottom"}
                            cleanable={false}
                            style={{ width: 150 }}
                        /> */}
                        <Input
                            value={sn.value}
                            placeholder={"https://"}
                            onChange={value => handleInputChange(index, value)}
                            style={{ width: 230 }}
                        />

                        <IconButton icon={<CIcon path={iClose} />} onClick={() => handleClearInput(index)} />
                    </div>
                ))}
                {/* <Button
                style={{ width: 458, marginLeft: 5, marginRight: 5, marginTop: 10 }}
                onClick={() => setSNList(prev => [...prev, { name: "", value: "" }])}
            >
                <Icon icon={"plus"} style={{ marginRight: 5 }} />
                <TText key="add" />
            </Button> */}
            </div>
            <JsonViewState value={{ SNList }} style={{ position: "relative", top: 0 }} />
        </CDrawer>
    );
};

export default SocialNetworksDrawer;
