import { t } from "lib/i18n";
import _ from "lodash";
import { iCheckBoxChecked, iClose, iCogOutline, iExclamationCircle, iRefresh } from "assets/icons/Icons";
import { Colors } from "./colors";

export const enum ReportTypeEnums {
    PIE = "pie",
    COLUMN = "column",
    AREA = "area",
}

export const enum OperationType {
    IN = 1,
    OUT = 2,
    TRANSFER = 3,
    WASTE = 4,
    RETURN = 5,
    FIXATION = 6,
    MANUFACTURE = 7,
    // MERGE = 8, this is not used anymore
    INVENTORY_CHECK = 9,
    SUPPLY_RETURN = 10,
    INITIAL_STOCK = 11,
}

export const enum InventoryBehavior {
    MINUS_INGREDIENTS = 0,
    MINUS_SELF = 1,
    PASSIVE = 3,
}

export const enum StatusType {
    INACTIVE = 0,
    ACTIVE = 1,
}

export const enum DeviceType {
    DESKTOP = 0,
    MOBILE = 1,
}

export const enum TimerCalcMethod {
    IN,
    OUT,
}

export const enum IgnoreServiceCharge {
    NOT_CALCULATED,
    CALCULATED,
}

export const enum INVENTORY_DECLARATION {
    NORMAL = 0, // added in 2nd step manually or by import
    DECLARATOR = 1, // declared in 1st step
    DECLARED = 2, // comes from 1st step
    IGNORED = 3,
}

export const INVENTORY_MAJOR_DECLARATORS = [INVENTORY_DECLARATION.NORMAL, INVENTORY_DECLARATION.DECLARED];

export enum ReasonType {
    wastes = 1,
    delete = 2,
}

export const taxStatus: any = {
    1: t("active"),
    2: t("deactivated"),
} as any;

export const reasonTypesById = {
    1: t("write_off"),
    2: t("delete"),
};

export const enum CategoryTypeEnum {
    ACCOUNTING = "ACCOUNTING",
    PRODUCT = "PRODUCT",
    INGREDIENT = "INGREDIENT",
    EMENU = "EMENU",
}

export const enum ProductType {
    ALL = "ALL",
    DISH = "DISH",
    GOODS = "GOODS",
    MODIFICATION = "MODIFICATION",
    INGREDIENT = "INGREDIENT",
    PREPARATION = "PREPARATION",
    TIMER = "TIMER",
}

export const enum ReceiptType {
    RECEIPT = "RECEIPT",
    OPEN_RECEIPT = "OPEN_RECEIPT",
    DELETED_RECEIPT = "DELETED_RECEIPT",
    MERGED_RECEIPT = "MERGED_RECEIPT",
}

export const enum TransactionPageType {
    TRANSACTION = "TRANSACTION",
    CUSTOMER_TRANSACTION = "CUSTOMER_TRANSACTION",
    SUPPLIER_TRANSACTION = "SUPPLIER_TRANSACTION",
}

export const OperationTypes = {
    1: "supply",
    2: "sale",
    3: "transfer",
    4: "waste",
    5: "return",
    6: "fixation",
    7: "make",
    // 8: "merge", This is not used anymore
    9: "inventory_check",
    10: "supply_return",
    11: "initial_stock_storage_id",
} as const;

export const ReviewType: Record<number, string> = {
    2: "delivery",
    3: "service",
    4: "food",
};

export const enum ReviewTypeByName {
    delivery = 2,
    service = 3,
    food = 4,
}

export const INVENTORY_STATUS = {
    NO_ACTION: 0,
    PROCESSED: 1,
    IN_PROCESSES: 2,
    RESTORED: 3,
    FAILED: 4,
};
export const INV_STATUS = {
    DRAFT: 2,
    PUBLISHED: 1,
};

export const allowedStages: Record<string, string[]> = {
    alpha: ["alpha", "beta", "rc", "prod"],
    beta: ["beta", "rc", "prod"],
    rc: ["rc", "prod"],
    prod: ["prod"],
};

interface INVENTORY_STATUS {
    name: string;
    icon: string;
    color: string;
    testId?: string;
    background?: string;
}

export const enum InventoryStatusEnum {
    NO_ACTION = 0,
    PROCESSED = 1,
    IN_PROCESSES = 2,
    RESTORED = 3,
    FAILED = 4,
}

export const INVENTORY_STATUS_BY_ID = {
    0: {
        name: "NO_ACTION",
        icon: iExclamationCircle,
        color: Colors.MustardYellow,
        background: Colors.PalePeach,
        testId: "no-action",
    },
    1: {
        name: "PROCESSED",
        icon: iCheckBoxChecked,
        color: Colors.EmeraldGreen,
        background: Colors.MintGreen,
        testId: "processed",
    },
    2: {
        name: "IN_PROCESSES",
        icon: iCogOutline,
        color: Colors.SlateGray,
        background: Colors.LightGray,
        testId: "in-processes",
    },
    3: {
        name: "RESTORED",
        icon: iRefresh,
        color: Colors.TurquoiseBlue,
        background: Colors.PaleSkyBlue,
        testId: "restored",
    },
    4: {
        name: "FAILED",
        icon: iClose,
        color: Colors.LightRed,
        background: Colors.SoftPeach,
        testId: "failed",
    },
} satisfies Record<number, INVENTORY_STATUS>;

export const enum OperatorType {
    AZSMART = "AZSMART",
    CASPOS = "CASPOS",
    NBA = "NBA",
    OMNISOFT = "OMNISOFT",
    TINDA = "TINDA",
}

export const enum OperationStatus {
    APPROVED = 1,
    DRAFT = 2,
}

export const enum BulkModeTypes {
    DELETE = "0",
    UPDATE = "1",
}

export const enum MobileDeviceTypeEnums {
    Android = "Android",
    iOS = "iOS",
    WindowsPhone = "Windows Phone",
    Unknown = "Unknown",
}

export type MobileDeviceTypes = {
    Android: string;
    iOS: string;
    Unknown?: string;
};

export const CloposManagerStoreLinks: MobileDeviceTypes = {
    Unknown: "https://link-to.app/iJP3ePNcXz",
    Android: "https://play.google.com/store/apps/details?id=com.clopos.manager&hl=en&gl=US&pli=1",
    iOS: "https://apps.apple.com/az/app/clopos-manager/id1536564379",
};

const countries = {
    IL: "il",
    AF: "af",
    AL: "al",
    DZ: "dz",
    AS: "as",
    AD: "ad",
    AO: "ao",
    AI: "ai",
    AG: "ag",
    AR: "ar",
    AM: "am",
    AW: "aw",
    AU: "au",
    AT: "at",
    AZ: "az",
    BS: "bs",
    BH: "bh",
    BD: "bd",
    BB: "bb",
    BY: "by",
    BE: "be",
    BZ: "bz",
    BJ: "bj",
    BM: "bm",
    BT: "bt",
    BA: "ba",
    BW: "bw",
    BR: "br",
    IO: "io",
    BG: "bg",
    BF: "bf",
    BI: "bi",
    KH: "kh",
    CM: "cm",
    CA: "ca",
    CV: "cv",
    KY: "ky",
    CF: "cf",
    TD: "td",
    CL: "cl",
    CN: "cn",
    CX: "cx",
    CO: "co",
    KM: "km",
    CG: "cg",
    CK: "ck",
    CR: "cr",
    HR: "hr",
    CU: "cu",
    CY: "cy",
    CZ: "cz",
    DK: "dk",
    DJ: "dj",
    DM: "dm",
    DO: "do",
    EC: "ec",
    EG: "eg",
    SV: "sv",
    GQ: "gq",
    ER: "er",
    EE: "ee",
    ET: "et",
    FO: "fo",
    FJ: "fj",
    FI: "fi",
    FR: "fr",
    GF: "gf",
    PF: "pf",
    GA: "ga",
    GM: "gm",
    GE: "ge",
    DE: "de",
    GH: "gh",
    GI: "gi",
    GR: "gr",
    GL: "gl",
    GD: "gd",
    GP: "gp",
    GU: "gu",
    GT: "gt",
    GN: "gn",
    GW: "gw",
    GY: "gy",
    HT: "ht",
    HN: "hn",
    HU: "hu",
    IS: "is",
    IN: "in",
    ID: "id",
    IQ: "iq",
    IE: "ie",
    IT: "it",
    JM: "jm",
    JP: "jp",
    JO: "jo",
    KZ: "kz",
    KE: "ke",
    KI: "ki",
    KW: "kw",
    KG: "kg",
    LV: "lv",
    LB: "lb",
    LS: "ls",
    LR: "lr",
    LI: "li",
    LT: "lt",
    LU: "lu",
    MG: "mg",
    MW: "mw",
    MY: "my",
    MV: "mv",
    ML: "ml",
    MT: "mt",
    MH: "mh",
    MQ: "mq",
    MR: "mr",
    MU: "mu",
    YT: "yt",
    MX: "mx",
    MC: "mc",
    MN: "mn",
    ME: "me",
    MS: "ms",
    MA: "ma",
    MM: "mm",
    NA: "na",
    NR: "nr",
    NP: "np",
    NL: "nl",
    AN: "an",
    NC: "nc",
    NZ: "nz",
    NI: "ni",
    NE: "ne",
    NG: "ng",
    NU: "nu",
    NF: "nf",
    MP: "mp",
    NO: "no",
    OM: "om",
    PK: "pk",
    PW: "pw",
    PA: "pa",
    PG: "pg",
    PY: "py",
    PE: "pe",
    PH: "ph",
    PL: "pl",
    PT: "pt",
    PR: "pr",
    QA: "qa",
    RO: "ro",
    RW: "rw",
    WS: "ws",
    SM: "sm",
    SA: "sa",
    SN: "sn",
    RS: "rs",
    SC: "sc",
    SL: "sl",
    SG: "sg",
    SK: "sk",
    SI: "si",
    SB: "sb",
    ZA: "za",
    GS: "gs",
    ES: "es",
    LK: "lk",
    SD: "sd",
    SR: "sr",
    SZ: "sz",
    SE: "se",
    CH: "ch",
    TJ: "tj",
    TH: "th",
    TG: "tg",
    TK: "tk",
    TO: "to",
    TT: "tt",
    TN: "tn",
    TR: "tr",
    TM: "tm",
    TC: "tc",
    TV: "tv",
    UG: "ug",
    UA: "ua",
    AE: "ae",
    GB: "gb",
    US: "us",
    UY: "uy",
    UZ: "uz",
    VU: "vu",
    WF: "wf",
    YE: "ye",
    ZM: "zm",
    ZW: "zw",
    AX: "ax",
    AQ: "aq",
    BO: "bo",
    BN: "bn",
    CC: "cc",
    CD: "cd",
    CI: "ci",
    FK: "fk",
    GG: "gg",
    VA: "va",
    HK: "hk",
    IR: "ir",
    IM: "im",
    JE: "je",
    KP: "kp",
    KR: "kr",
    LA: "la",
    LY: "ly",
    MO: "mo",
    MK: "mk",
    FM: "fm",
    MD: "md",
    MZ: "mz",
    PS: "ps",
    PN: "pn",
    RE: "re",
    RU: "ru",
    BL: "bl",
    SH: "sh",
    KN: "kn",
    LC: "lc",
    MF: "mf",
    PM: "pm",
    VC: "vc",
    ST: "st",
    SO: "so",
    SJ: "sj",
    SY: "sy",
    TW: "tw",
    TZ: "tz",
    TL: "tl",
    VE: "ve",
    VN: "vn",
    VG: "vg",
    VI: "vi",
};
export const FORMATS = {
    date: "MMM dd",
    date_full: "dd MMMM yyyy",
    datetime: "MMM dd, HH:mm:ss",
};

export const Constants = {
    get validationMsgs() {
        return {
            complete_phone: t("complete_phone"),
            must_be_2_decimal: t("must_be_2_decimal"),
            must_be_greater_equal_0: t("must_be_greater_equal_0"),
            no_decimal: t("no_decimal"),
            not_same_destination: t("not_same_destination"),
            complete_pin: t("complete_pin"),
            date_format: t("date_format"),
            required: t("required"),
            not_valid_email: t("not_valid_email"),
            pin_required: t("pin_required"),
            fill_amount: t("fill_amount"),
        };
    },
    get financeTypes() {
        return {
            IN: t("income"),
            OUT: t("expense"),
            BOTH: t("both"),
        };
    },
    get financeAccountTypes() {
        return {
            CASH: _.capitalize(t("cash")),
            CARD: _.capitalize(t("card")),
            BANK: _.capitalize(t("bank")),
        };
    },
    get transactionTypes() {
        return {
            OUT: t("expense"),
            IN: t("income"),
            TRANSFER: t("transfer"),
        };
    },
    get receiptHistoryTypes() {
        return {
            terminal: t("terminal"),
            type: t("receipt_log_type"),
            timestamp: t("timestamp"),
            device: t("device"),
            changes: t("changes"),
            PrintFail: t("fail_print"),
            Create: {
                table: t("receipt_log_create"),
                sale_type: t("receipt_log_create_sale_type"),
            },
            AddProduct: {
                product: t("receipt_log_product_add"),
                rpmName: t("rpm_name"),
            },
            RemoveProduct: {
                product: t("product_remove"),
                rpmName: t("rpm_name_remove"),
            },
            RemoveProductAfterStation: {
                product: t("product_remove_after_station"),
                rpmName: t("rpm_name_remove_after_station"),
            },
            RemoveProductAfterPrecheck: {
                product: t("product_remove_after_precheck_rlog"),
                rpmName: t("product_remove_after_precheck_rlog"),
            },
            PriceChange: t("receipt_log_price_change"),
            AddDiscount: t("receipt_log_add_discount"),
            RemoveDiscount: t("receipt_log_remove_discount"),
            AddService: t("add_service"),
            RemoveService: t("remove_service"),
            AddCustomer: t("receipt_log_add_customer"),
            RemoveCustomer: t("remove_customer"),
            Print: [t("print_preprint"), t("print_checkout")],
            PrePrint: t("preprint"),
            Comment: t("receipt_log_comment"),
            Clean: t("receipt_log_clean"),
            ChangeWaiter: t("receipt_log_change_waiter"),
            ChangeTable: t("receipt_log_change_table"),
            ChangeGuestCount: t("change_guest_count"),
            Close: t("receipt_log_close"),
            Merge: {
                withID: t("with_id"),
                withoutId: t("without_id"),
            },
            Delete: t("receipt_log_delete"),
            AdminPassPrompt: t("receipt_log_admin_pass_prompt"),
            Split: t("receipt_log_splitted"),
            SplitIn: t("receipt_log_splitted_in"),
            SplitOut: t("receipt_log_splitted_out"),
            SentToPrinters: t("sent_to_printers"),
            SentToPrintersFail: t("sent_to_printers_fail"),
            SentToStation: t("sent_to_station"),
            SentToStationFail: t("sent_to_station_fail"),
            UpdateCustomerInfo: t("update_customer_info"),
            RemovedCustomerDiscount: t("removed_customer_discount"),
            PrinterToggle: t("printer_toggle"),
            customer_discount_type: {
                1: t("group"),
                2: t("customer_discount"),
            },
            DeletedPanel: t("delete_from_panel"),
            AddComment: t("add_comment"),
            RemoveComment: t("remove_comment"),
            ChangeSaleType: {
                delivery: t("receipt_log_change_sale_type"),
                delivery_to_table: t("receipt_log_change_sale_type_delivery_to_table"),
                table_to_delivery: t("receipt_log_change_sale_type_table_to_delivery"),
            },
            PaymentMethods: t("receipt_log_payment_method"),
            ChangePaymentMethod: t("receipt_log_change_payment_method"),
            ProductTransfer: {
                to: t("receipt_log_transfer_product_to"),
                from: t("receipt_log_transfer_product_from"),
            },
            ProductGift: t("receipt_log_gift_product"),
            AdminPassPromptFailed: t("receipt_log_admin_pass_prompt_failed"),
            SendToStation: t("receipt_log_send_staion"),
            AddRPDiscount: t("rp_log_add_discount"),
            RemoveRPDiscount: t("rp_log_remove_discount"),
            SystemRemove: t("rlog_system_remove"),
            FiscalSuccess: t("fiscal_success"),
            FiscalRollback: t("fiscal_rollback"),
            FiscalRollbackFail: t("fiscal_rollback_fail"),
            HaveManyPrecheck: t("receipt_has_many_precheck"),
            PrecheckCheckoutConflict: t("PrecheckCheckoutConflict_rlog_action_title"),
            StartTimer: t("start_timer_rlog"),
            StopTimer: t("stop_timer_rlog"),
            RemovedSellerProduct: t("seller_removed_from_product_rlog"),
            RemoveSeller: t("seller_removed_from_receipt_rlog"),
            Lock: t("rlog_lock"),
            Unlock: t("rlog_unlock"),
        };
    },
    get unitNames() {
        return {
            g: t("gram_short"),
            l: t("liter_short"),
            kg: t("kg_short"),
            pcs: t("pcs_short"),
            pc: t("pcs_short"),
            m: t("meter_short"),
            gram: t("gram"),
            liter: t("liter"),
            litre: t("liter"),
            kilogram: t("kilogram"),
            pieces: t("pieces"),
            piece: t("pieces"),
            meter: t("meter"),
            metre: t("meter"),
            millilitre: t("millilitre"),
        };
    },

    get weekDays() {
        return [t("sun"), t("mon"), t("tue"), t("wed"), t("thu"), t("fri"), t("sat")];
    },
    get weekDaysForSale() {
        return [t("mon"), t("tue"), t("wed"), t("thu"), t("fri"), t("sat"), t("sun")];
    },

    get genderName() {
        return {
            1: t("male"),
            2: t("female"),
            9: t("other"),
        } as Record<number, string>;
    },

    get countries() {
        return _.map(countries, (country: string, index: string) => ({ value: index, label: country }));
    },

    get terminalStatus() {
        return {
            // 2: t("out_of_service"),
            1: t("activated"),
            0: t("deactivated"),
        };
    },

    get operationStatus(): Record<number, string> {
        return {
            1: t("approved"),
            2: t("draft"),
        };
    },
    get reasonPageRoutes(): any {
        return {
            wastes: {
                link: "/inventory/write-off",
                label: t("write_off"),
            },
        };
    },
};

export const getUnitNames = (symbol?: any) => {
    const _unitNames: any = Constants.unitNames;

    return symbol ? _unitNames[symbol] : symbol;
};

/*
 * TODO: refactor the FinanceAccountDefaultFilters object
 * The filters `customer`, `bonus`, `supplier`, and `CASHBACK` in FinanceAccountDefaultFilters might be unnecessary.
 * The filter `type: ["type", ["CARD", "CASH", "BANK"]]` already ensures that only these types are selected.
 * As a result, the additional filters are redundant.
 * The fix applied at line 36 in `src/pages/finance/accounts/AccountPage.tsx` supports this change.
 * FinanceAccountDefaultFilters can be removed entirely, and its usages can be replaced with `type: ["type", ["CARD", "CASH", "BANK"]]` directly.
 */
export const FinanceAccountDefaultFilters: Record<string, SearchRequestFilters> = {
    customer: ["type", "!=", "CUSTOMER"],
    bonus: ["type", "!=", "BONUS"],
    supplier: ["type", "!=", "SUPPLIER"],
    CASHBACK: ["type", "!=", "CASHBACK"],
    type: ["type", ["CARD", "CASH", "BANK"]],
};

export const Currencies = {
    azn: "\u20BC",
    usd: "\u0024",
    gel: "\u20BE",
    try: "\u20BA",
    rub: "\u20BD",
};

export const enum ModifierGroupType {
    SINGLE = 0,
    MULTI = 1,
}

export const ReceiptHistoryType = {
    terminal: "terminal",
    type: "receipt_log_type",
    timestamp: "timestamp",
    device: "device",
    changes: "changes",
    Create: "receipt_log_create",
    AddProduct: {
        product: "receipt_log_product_add",
        rpmName: "rpm_name",
    },
    RemoveProduct: {
        product: "product_remove",
        rpmName: "rpm_name_remove",
    },
    PriceChange: "receipt_log_price_change",
    AddDiscount: "receipt_log_add_discount",
    RemoveDiscount: "receipt_log_remove_discount",
    AddService: "{user} has added service charge of {rate}%",
    RemoveService: "{user} has removed service charge",
    AddCustomer: "{user} has added a customer {customer_name}",
    RemoveCustomer: "{user} has removed a customer",
    Print: [t("print_preprint"), t("print_checkout")],
    PrePrint: "{user} has printed the receipt from pre-print with total of {total}{currency}",
    AddComment: "{user} has added a comment {text}",
    Clean: "{user} has cleared the receipt",
    ChangeWaiter: "{user} has changed waiter of the receipt from {from_name} to {to_name}",
    ChangeTable: "{user} has changed table from ({from_hall_name}) {from_name} to ({to_hall_name}) {to_name}",
    ChangeGuestCount: "{user} has changed guest count from {from} to {to}",
    Close: "{user} has closed the receipt",
    Merge: {
        withID: t("with_id"),
        withoutId: t("without_id"),
    },
    Delete: "{user} has merged the receipt to {id}",
    AdminPassPrompt: "{user} has used admin password",
    // Split: "splitted products",
    SentToStation: "{user} has sent goods to station(s)",
    SentToStationFail: "{user} has tried to print station {stationId}, but failed",
    UpdateCustomerInfo: "{user} has modified customer's discount {type} ({amount}%)",
    RemovedCustomerDiscount: "{user} has removed customer's discount",
    PrinterToggle: "{user} has switched print button {toggle}",
    customer_discount_type: {
        1: t("group"),
        2: t("customer_discount"),
    },
};

export interface ICountryPhoneNumber {
    name: string;
    dial_code: string | null;
    code: string;
}

export const taxCalculationType = {
    percentage: 1,
    amount: 2,
};

export const countryCodeAndName: ICountryPhoneNumber[] = [
    { name: "Israel", dial_code: "+972", code: "IL" },
    {
        name: "Afghanistan",
        dial_code: "+93",
        code: "AF",
    },
    { name: "Albania", dial_code: "+355", code: "AL" },
    {
        name: "Algeria",
        dial_code: "+213",
        code: "DZ",
    },
    { name: "AmericanSamoa", dial_code: "+1 684", code: "AS" },
    {
        name: "Andorra",
        dial_code: "+376",
        code: "AD",
    },
    { name: "Angola", dial_code: "+244", code: "AO" },
    {
        name: "Anguilla",
        dial_code: "+1 264",
        code: "AI",
    },
    { name: "Antigua and Barbuda", dial_code: "+1268", code: "AG" },
    {
        name: "Argentina",
        dial_code: "+54",
        code: "AR",
    },
    { name: "Armenia", dial_code: "+374", code: "AM" },
    {
        name: "Aruba",
        dial_code: "+297",
        code: "AW",
    },
    { name: "Australia", dial_code: "+61", code: "AU" },
    {
        name: "Austria",
        dial_code: "+43",
        code: "AT",
    },
    { name: "Azerbaijan", dial_code: "+994", code: "AZ" },
    {
        name: "Bahamas",
        dial_code: "+1 242",
        code: "BS",
    },
    { name: "Bahrain", dial_code: "+973", code: "BH" },
    {
        name: "Bangladesh",
        dial_code: "+880",
        code: "BD",
    },
    { name: "Barbados", dial_code: "+1 246", code: "BB" },
    {
        name: "Belarus",
        dial_code: "+375",
        code: "BY",
    },
    { name: "Belgium", dial_code: "+32", code: "BE" },
    {
        name: "Belize",
        dial_code: "+501",
        code: "BZ",
    },
    { name: "Benin", dial_code: "+229", code: "BJ" },
    {
        name: "Bermuda",
        dial_code: "+1 441",
        code: "BM",
    },
    { name: "Bhutan", dial_code: "+975", code: "BT" },
    {
        name: "Bosnia and Herzegovina",
        dial_code: "+387",
        code: "BA",
    },
    { name: "Botswana", dial_code: "+267", code: "BW" },
    {
        name: "Brazil",
        dial_code: "+55",
        code: "BR",
    },
    { name: "British Indian Ocean Territory", dial_code: "+246", code: "IO" },
    {
        name: "Bulgaria",
        dial_code: "+359",
        code: "BG",
    },
    { name: "Burkina Faso", dial_code: "+226", code: "BF" },
    {
        name: "Burundi",
        dial_code: "+257",
        code: "BI",
    },
    { name: "Cambodia", dial_code: "+855", code: "KH" },
    {
        name: "Cameroon",
        dial_code: "+237",
        code: "CM",
    },
    { name: "Canada", dial_code: "+1", code: "CA" },
    {
        name: "Cape Verde",
        dial_code: "+238",
        code: "CV",
    },
    { name: "Cayman Islands", dial_code: "+ 345", code: "KY" },
    {
        name: "Central African Republic",
        dial_code: "+236",
        code: "CF",
    },
    { name: "Chad", dial_code: "+235", code: "TD" },
    {
        name: "Chile",
        dial_code: "+56",
        code: "CL",
    },
    { name: "China", dial_code: "+86", code: "CN" },
    {
        name: "Christmas Island",
        dial_code: "+61",
        code: "CX",
    },
    { name: "Colombia", dial_code: "+57", code: "CO" },
    {
        name: "Comoros",
        dial_code: "+269",
        code: "KM",
    },
    { name: "Congo", dial_code: "+242", code: "CG" },
    {
        name: "Cook Islands",
        dial_code: "+682",
        code: "CK",
    },
    { name: "Costa Rica", dial_code: "+506", code: "CR" },
    {
        name: "Croatia",
        dial_code: "+385",
        code: "HR",
    },
    { name: "Cuba", dial_code: "+53", code: "CU" },
    {
        name: "Cyprus",
        dial_code: "+537",
        code: "CY",
    },
    { name: "Czech Republic", dial_code: "+420", code: "CZ" },
    {
        name: "Denmark",
        dial_code: "+45",
        code: "DK",
    },
    { name: "Djibouti", dial_code: "+253", code: "DJ" },
    {
        name: "Dominica",
        dial_code: "+1 767",
        code: "DM",
    },
    { name: "Dominican Republic", dial_code: "+1 849", code: "DO" },
    {
        name: "Ecuador",
        dial_code: "+593",
        code: "EC",
    },
    { name: "Egypt", dial_code: "+20", code: "EG" },
    {
        name: "El Salvador",
        dial_code: "+503",
        code: "SV",
    },
    { name: "Equatorial Guinea", dial_code: "+240", code: "GQ" },
    {
        name: "Eritrea",
        dial_code: "+291",
        code: "ER",
    },
    { name: "Estonia", dial_code: "+372", code: "EE" },
    {
        name: "Ethiopia",
        dial_code: "+251",
        code: "ET",
    },
    { name: "Faroe Islands", dial_code: "+298", code: "FO" },
    {
        name: "Fiji",
        dial_code: "+679",
        code: "FJ",
    },
    { name: "Finland", dial_code: "+358", code: "FI" },
    {
        name: "France",
        dial_code: "+33",
        code: "FR",
    },
    { name: "French Guiana", dial_code: "+594", code: "GF" },
    {
        name: "French Polynesia",
        dial_code: "+689",
        code: "PF",
    },
    { name: "Gabon", dial_code: "+241", code: "GA" },
    {
        name: "Gambia",
        dial_code: "+220",
        code: "GM",
    },
    { name: "Georgia", dial_code: "+995", code: "GE" },
    {
        name: "Germany",
        dial_code: "+49",
        code: "DE",
    },
    { name: "Ghana", dial_code: "+233", code: "GH" },
    {
        name: "Gibraltar",
        dial_code: "+350",
        code: "GI",
    },
    { name: "Greece", dial_code: "+30", code: "GR" },
    {
        name: "Greenland",
        dial_code: "+299",
        code: "GL",
    },
    { name: "Grenada", dial_code: "+1 473", code: "GD" },
    {
        name: "Guadeloupe",
        dial_code: "+590",
        code: "GP",
    },
    { name: "Guam", dial_code: "+1 671", code: "GU" },
    {
        name: "Guatemala",
        dial_code: "+502",
        code: "GT",
    },
    { name: "Guinea", dial_code: "+224", code: "GN" },
    {
        name: "Guinea-Bissau",
        dial_code: "+245",
        code: "GW",
    },
    { name: "Guyana", dial_code: "+595", code: "GY" },
    {
        name: "Haiti",
        dial_code: "+509",
        code: "HT",
    },
    { name: "Honduras", dial_code: "+504", code: "HN" },
    {
        name: "Hungary",
        dial_code: "+36",
        code: "HU",
    },
    { name: "Iceland", dial_code: "+354", code: "IS" },
    {
        name: "India",
        dial_code: "+91",
        code: "IN",
    },
    { name: "Indonesia", dial_code: "+62", code: "ID" },
    {
        name: "Iraq",
        dial_code: "+964",
        code: "IQ",
    },
    { name: "Ireland", dial_code: "+353", code: "IE" },
    { name: "Italy", dial_code: "+39", code: "IT" },
    {
        name: "Jamaica",
        dial_code: "+1 876",
        code: "JM",
    },
    { name: "Japan", dial_code: "+81", code: "JP" },
    {
        name: "Jordan",
        dial_code: "+962",
        code: "JO",
    },
    { name: "Kazakhstan", dial_code: "+7 7", code: "KZ" },
    {
        name: "Kenya",
        dial_code: "+254",
        code: "KE",
    },
    { name: "Kiribati", dial_code: "+686", code: "KI" },
    {
        name: "Kuwait",
        dial_code: "+965",
        code: "KW",
    },
    { name: "Kyrgyzstan", dial_code: "+996", code: "KG" },
    {
        name: "Latvia",
        dial_code: "+371",
        code: "LV",
    },
    { name: "Lebanon", dial_code: "+961", code: "LB" },
    {
        name: "Lesotho",
        dial_code: "+266",
        code: "LS",
    },
    { name: "Liberia", dial_code: "+231", code: "LR" },
    {
        name: "Liechtenstein",
        dial_code: "+423",
        code: "LI",
    },
    { name: "Lithuania", dial_code: "+370", code: "LT" },
    {
        name: "Luxembourg",
        dial_code: "+352",
        code: "LU",
    },
    { name: "Madagascar", dial_code: "+261", code: "MG" },
    {
        name: "Malawi",
        dial_code: "+265",
        code: "MW",
    },
    { name: "Malaysia", dial_code: "+60", code: "MY" },
    {
        name: "Maldives",
        dial_code: "+960",
        code: "MV",
    },
    { name: "Mali", dial_code: "+223", code: "ML" },
    {
        name: "Malta",
        dial_code: "+356",
        code: "MT",
    },
    { name: "Marshall Islands", dial_code: "+692", code: "MH" },
    {
        name: "Martinique",
        dial_code: "+596",
        code: "MQ",
    },
    { name: "Mauritania", dial_code: "+222", code: "MR" },
    {
        name: "Mauritius",
        dial_code: "+230",
        code: "MU",
    },
    { name: "Mayotte", dial_code: "+262", code: "YT" },
    {
        name: "Mexico",
        dial_code: "+52",
        code: "MX",
    },
    { name: "Monaco", dial_code: "+377", code: "MC" },
    {
        name: "Mongolia",
        dial_code: "+976",
        code: "MN",
    },
    { name: "Montenegro", dial_code: "+382", code: "ME" },
    {
        name: "Montserrat",
        dial_code: "+1664",
        code: "MS",
    },
    { name: "Morocco", dial_code: "+212", code: "MA" },
    {
        name: "Myanmar",
        dial_code: "+95",
        code: "MM",
    },
    { name: "Namibia", dial_code: "+264", code: "NA" },
    {
        name: "Nauru",
        dial_code: "+674",
        code: "NR",
    },
    { name: "Nepal", dial_code: "+977", code: "NP" },
    {
        name: "Netherlands",
        dial_code: "+31",
        code: "NL",
    },
    { name: "Netherlands Antilles", dial_code: "+599", code: "AN" },
    {
        name: "New Caledonia",
        dial_code: "+687",
        code: "NC",
    },
    { name: "New Zealand", dial_code: "+64", code: "NZ" },
    {
        name: "Nicaragua",
        dial_code: "+505",
        code: "NI",
    },
    { name: "Niger", dial_code: "+227", code: "NE" },
    {
        name: "Nigeria",
        dial_code: "+234",
        code: "NG",
    },
    { name: "Niue", dial_code: "+683", code: "NU" },
    {
        name: "Norfolk Island",
        dial_code: "+672",
        code: "NF",
    },
    { name: "Northern Mariana Islands", dial_code: "+1 670", code: "MP" },
    {
        name: "Norway",
        dial_code: "+47",
        code: "NO",
    },
    { name: "Oman", dial_code: "+968", code: "OM" },
    {
        name: "Pakistan",
        dial_code: "+92",
        code: "PK",
    },
    { name: "Palau", dial_code: "+680", code: "PW" },
    {
        name: "Panama",
        dial_code: "+507",
        code: "PA",
    },
    { name: "Papua New Guinea", dial_code: "+675", code: "PG" },
    {
        name: "Paraguay",
        dial_code: "+595",
        code: "PY",
    },
    { name: "Peru", dial_code: "+51", code: "PE" },
    {
        name: "Philippines",
        dial_code: "+63",
        code: "PH",
    },
    { name: "Poland", dial_code: "+48", code: "PL" },
    {
        name: "Portugal",
        dial_code: "+351",
        code: "PT",
    },
    { name: "Puerto Rico", dial_code: "+1 939", code: "PR" },
    {
        name: "Qatar",
        dial_code: "+974",
        code: "QA",
    },
    { name: "Romania", dial_code: "+40", code: "RO" },
    {
        name: "Rwanda",
        dial_code: "+250",
        code: "RW",
    },
    { name: "Samoa", dial_code: "+685", code: "WS" },
    {
        name: "San Marino",
        dial_code: "+378",
        code: "SM",
    },
    { name: "Saudi Arabia", dial_code: "+966", code: "SA" },
    {
        name: "Senegal",
        dial_code: "+221",
        code: "SN",
    },
    { name: "Serbia", dial_code: "+381", code: "RS" },
    {
        name: "Seychelles",
        dial_code: "+248",
        code: "SC",
    },
    { name: "Sierra Leone", dial_code: "+232", code: "SL" },
    {
        name: "Singapore",
        dial_code: "+65",
        code: "SG",
    },
    { name: "Slovakia", dial_code: "+421", code: "SK" },
    {
        name: "Slovenia",
        dial_code: "+386",
        code: "SI",
    },
    { name: "Solomon Islands", dial_code: "+677", code: "SB" },
    {
        name: "South Africa",
        dial_code: "+27",
        code: "ZA",
    },
    { name: "South Georgia and the South Sandwich Islands", dial_code: "+500", code: "GS" },
    {
        name: "Spain",
        dial_code: "+34",
        code: "ES",
    },
    { name: "Sri Lanka", dial_code: "+94", code: "LK" },
    {
        name: "Sudan",
        dial_code: "+249",
        code: "SD",
    },
    { name: "Suriname", dial_code: "+597", code: "SR" },
    {
        name: "Swaziland",
        dial_code: "+268",
        code: "SZ",
    },
    { name: "Sweden", dial_code: "+46", code: "SE" },
    {
        name: "Switzerland",
        dial_code: "+41",
        code: "CH",
    },
    { name: "Tajikistan", dial_code: "+992", code: "TJ" },
    {
        name: "Thailand",
        dial_code: "+66",
        code: "TH",
    },
    { name: "Togo", dial_code: "+228", code: "TG" },
    {
        name: "Tokelau",
        dial_code: "+690",
        code: "TK",
    },
    { name: "Tonga", dial_code: "+676", code: "TO" },
    {
        name: "Trinidad and Tobago",
        dial_code: "+1 868",
        code: "TT",
    },
    { name: "Tunisia", dial_code: "+216", code: "TN" },
    {
        name: "Turkey",
        dial_code: "+90",
        code: "TR",
    },
    { name: "Turkmenistan", dial_code: "+993", code: "TM" },
    {
        name: "Turks and Caicos Islands",
        dial_code: "+1 649",
        code: "TC",
    },
    { name: "Tuvalu", dial_code: "+688", code: "TV" },
    {
        name: "Uganda",
        dial_code: "+256",
        code: "UG",
    },
    { name: "Ukraine", dial_code: "+380", code: "UA" },
    {
        name: "United Arab Emirates",
        dial_code: "+971",
        code: "AE",
    },
    { name: "United Kingdom", dial_code: "+44", code: "GB" },
    {
        name: "United States",
        dial_code: "+1",
        code: "US",
    },
    { name: "Uruguay", dial_code: "+598", code: "UY" },
    {
        name: "Uzbekistan",
        dial_code: "+998",
        code: "UZ",
    },
    { name: "Vanuatu", dial_code: "+678", code: "VU" },
    {
        name: "Wallis and Futuna",
        dial_code: "+681",
        code: "WF",
    },
    { name: "Yemen", dial_code: "+967", code: "YE" },
    {
        name: "Zambia",
        dial_code: "+260",
        code: "ZM",
    },
    { name: "Zimbabwe", dial_code: "+263", code: "ZW" },
    {
        name: "land Islands",
        dial_code: "",
        code: "AX",
    },
    { name: "Antarctica", dial_code: null, code: "AQ" },
    {
        name: "Bolivia, Plurinational State of",
        dial_code: "+591",
        code: "BO",
    },
    { name: "Brunei Darussalam", dial_code: "+673", code: "BN" },
    {
        name: "Cocos (Keeling) Islands",
        dial_code: "+61",
        code: "CC",
    },
    { name: "Congo, The Democratic Republic of the", dial_code: "+243", code: "CD" },
    {
        name: "Cote d'Ivoire",
        dial_code: "+225",
        code: "CI",
    },
    { name: "Falkland Islands (Malvinas)", dial_code: "+500", code: "FK" },
    {
        name: "Guernsey",
        dial_code: "+44",
        code: "GG",
    },
    { name: "Holy See (Vatican City State)", dial_code: "+379", code: "VA" },
    {
        name: "Hong Kong",
        dial_code: "+852",
        code: "HK",
    },
    { name: "Iran, Islamic Republic of", dial_code: "+98", code: "IR" },
    {
        name: "Isle of Man",
        dial_code: "+44",
        code: "IM",
    },
    { name: "Jersey", dial_code: "+44", code: "JE" },
    {
        name: "Korea, Democratic People's Republic of",
        dial_code: "+850",
        code: "KP",
    },
    { name: "Korea, Republic of", dial_code: "+82", code: "KR" },
    {
        name: "Lao People's Democratic Republic",
        dial_code: "+856",
        code: "LA",
    },
    { name: "Libyan Arab Jamahiriya", dial_code: "+218", code: "LY" },
    {
        name: "Macao",
        dial_code: "+853",
        code: "MO",
    },
    {
        name: "Macedonia, The Former Yugoslav Republic of",
        dial_code: "+389",
        code: "MK",
    },
    { name: "Micronesia, Federated States of", dial_code: "+691", code: "FM" },
    {
        name: "Moldova, Republic of",
        dial_code: "+373",
        code: "MD",
    },
    { name: "Mozambique", dial_code: "+258", code: "MZ" },
    {
        name: "Palestinian Territory, Occupied",
        dial_code: "+970",
        code: "PS",
    },
    { name: "Pitcairn", dial_code: "+872", code: "PN" },
    {
        name: "Réunion",
        dial_code: "+262",
        code: "RE",
    },
    { name: "Russia", dial_code: "+7", code: "RU" },
    {
        name: "Saint Barthélemy",
        dial_code: "+590",
        code: "BL",
    },
    {
        name: "Saint Helena, Ascension and Tristan Da Cunha",
        dial_code: "+290",
        code: "SH",
    },
    { name: "Saint Kitts and Nevis", dial_code: "+1 869", code: "KN" },
    {
        name: "Saint Lucia",
        dial_code: "+1 758",
        code: "LC",
    },
    { name: "Saint Martin", dial_code: "+590", code: "MF" },
    {
        name: "Saint Pierre and Miquelon",
        dial_code: "+508",
        code: "PM",
    },
    { name: "Saint Vincent and the Grenadines", dial_code: "+1 784", code: "VC" },
    {
        name: "Sao Tome and Principe",
        dial_code: "+239",
        code: "ST",
    },
    { name: "Somalia", dial_code: "+252", code: "SO" },
    {
        name: "Svalbard and Jan Mayen",
        dial_code: "+47",
        code: "SJ",
    },
    { name: "Syrian Arab Republic", dial_code: "+963", code: "SY" },
    {
        name: "Taiwan, Province of China",
        dial_code: "+886",
        code: "TW",
    },
    { name: "Tanzania, United Republic of", dial_code: "+255", code: "TZ" },
    {
        name: "Timor-Leste",
        dial_code: "+670",
        code: "TL",
    },
    { name: "Venezuela, Bolivarian Republic of", dial_code: "+58", code: "VE" },
    {
        name: "Viet Nam",
        dial_code: "+84",
        code: "VN",
    },
    { name: "Virgin Islands, British", dial_code: "+1 284", code: "VG" },
    {
        name: "Virgin Islands, U.S.",
        dial_code: "+1 340",
        code: "VI",
    },
];

export const Language: Record<string, string> = {
    az: "Azərbaycanca",
    ru: "Русский",
    en: "English",
    tr: "Türkçe",
    ka: "ქართული",
    ar: "عربى",
    uz: "Uzbek",
};

export const USER_ABILITIES = [
    {
        name: "user_manage",
        all: "user_manage",
        view: "user_manage_view",
        create: "user_manage_create",
        edit: "user_manage_edit",
        delete: "user_manage_delete",
        group: "staff",
    },

    {
        name: "role_manage",
        all: "role_manage",
        view: "role_manage_view",
        create: "role_manage_create",
        edit: "role_manage_edit",
        delete: "role_manage_delete",
        group: "staff",
    },
    {
        name: "incentive_program_manage",
        all: "incentive_program_manage",
        view: "incentive_program_view",
        create: "incentive_program_create",
        edit: "incentive_program_edit",
        delete: "incentive_program_delete",
        group: "staff",
    },

    {
        name: "terminal_manage",
        all: "terminal_manage",
        view: "terminal_manage_view",
        edit: "terminal_manage_edit",
        group: "access",
    },

    {
        name: "stock_manage",
        all: "stock_manage",
        edit: "stock_manage_view",
        delete: "stock_manage_delete",
        group: "inventory",
    },

    {
        name: "stock_manage_supplier",
        all: "stock_manage_supplier",
        group: "inventory",
    },

    {
        name: "stock_manage_supply",
        all: "stock_manage_supply",
        create: "stock_manage_supply_create",
        edit: "stock_manage_supply_edit",
        delete: "stock_manage_supply_delete",
        view: "stock_manage_view_supply",
        group: "inventory",
    },

    {
        name: "supply_returns_manage",
        all: "supply_returns_manage",
        create: "supply_returns_manage_create",
        delete: "supply_returns_manage_delete",
        view: "supply_returns_manage_view",
        group: "inventory",
    },

    {
        name: "stock_manage_waste",
        all: "stock_manage_waste_manage",
        view: "stock_manage_waste_view",
        group: "inventory",
    },

    {
        name: "stock_manage_storage",
        all: "stock_manage_storage",
        view: "stock_manage_storage_view",
        delete: "stock_manage_storage_manage",
        create: "stock_manage_storage_manage",
        edit: "stock_manage_storage_manage",
        group: "inventory",
    },

    {
        name: "stock_packages",
        all: "stock_packages_manage",
        view: "stock_packages_view",
        group: "inventory",
    },

    {
        name: "customer_manage",
        all: "customer_manage",
        view: "customer_manage_view",
        create: "customer_manage_create",
        edit: "customer_manage_edit",
        delete: "customer_manage_delete",
        group: "marketing",
    },

    {
        name: "customer_group_manage",
        all: "customer_group_manage",
        view: "customer_group_manage_view",
        create: "customer_group_manage_create",
        edit: "customer_group_manage_edit",
        delete: "customer_group_manage_delete",
        group: "marketing",
    },

    {
        name: "reviews",
        all: "review_manage",
        view: "review_view",
        delete: "review_delete",
        group: "marketing",
    },
    {
        name: "loyalty_manage",
        all: "loyalty_manage",
        view: "loyalty_view",
        edit: "loyalty_edit",
        group: "marketing",
    },

    {
        name: "finance_balance",
        all: "finance_balance",
        view: "finance_balance_view",
        create: "finance_balance_create",
        edit: "finance_balance_edit",
        delete: "finance_balance_delete",
        group: "finances",
    },

    {
        name: "finance_transaction",
        all: "finance_transaction",
        view: "finance_transaction_view",
        create: "finance_transaction_create",
        delete: "finance_transaction_delete",
        group: "finances",
    },

    {
        name: "finance_payment_method",
        all: "finance_payment_method",
        view: "finance_payment_method_view",
        create: "finance_payment_method_create",
        edit: "finance_payment_method_edit",
        delete: "finance_payment_method_delete",
        group: "sale",
    },

    {
        name: "finance_category",
        all: "finance_category",
        view: "finance_category_view",
        create: "finance_category_create",
        edit: "finance_category_edit",
        delete: "finance_category_delete",
        group: "finances",
    },
    {
        name: "cash_shift_view",
        all: "cash_shift_view",
        view: "cash_shift_view",
        group: "finances",
    },
    {
        name: "product_manage",
        all: "product_manage",
        view: "product_view",
        create: "product_create",
        edit: "product_edit",
        delete: "product_delete",
        group: "menu",
    },

    {
        name: "category_manage",
        all: "category_manage",
        view: "category_view",
        create: "category_create",
        edit: "category_edit",
        delete: "category_delete",
        group: "menu",
    },

    {
        name: "station_manage",
        all: "station_manage",
        view: "station_view",
        create: "station_create",
        edit: "station_edit",
        delete: "station_delete",
        group: "menu",
    },

    {
        name: "modificator_manage",
        all: "modificator_manage",
        view: "modificator_view",
        create: "modificator_create",
        edit: "modificator_edit",
        delete: "modificator_delete",
        group: "menu",
    },

    {
        name: "receipt_manage",
        all: "receipt_manage",
        view: "receipt_view",
        edit: "receipt_edit",
        delete: "receipt_delete",
        group: "sale",
    },
    {
        name: "report_sell",
        all: "report_sell",
        group: "stats",
    },
    {
        name: "report_customer",
        all: "report_customer",
        group: "stats",
    },
    {
        name: "report_user",
        all: "report_user",
        group: "stats",
    },
    {
        name: "report_station",
        all: "report_station",
        group: "stats",
    },
    {
        name: "report_table",
        all: "report_table",
        group: "stats",
    },
    {
        name: "report_category",
        all: "report_category",
        group: "stats",
    },
    {
        name: "report_product",
        all: "report_product",
        group: "stats",
    },
    {
        name: "tax_report_show",
        all: "tax_report_show",
        group: "stats",
    },
    {
        name: "report_deleted_product",
        all: "report_deleted_product",
        group: "stats",
    },
    {
        name: "report_payment",
        all: "report_payment",
        group: "stats",
    },
    {
        name: "setting_general",
        all: "setting_general",
        group: "settings",
    },
    {
        name: "setting_table",
        all: "setting_table",
        group: "settings",
    },
    {
        name: "module_manage",
        all: "module_manage",
        group: "settings",
    },
    {
        name: "setting_terminal",
        all: "setting_terminal",
        group: "settings",
    },
    {
        name: "setting_receipt",
        all: "setting_receipt",
        group: "settings",
    },
    {
        name: "taxes",
        all: "tax_manage",
        view: "tax_view",
        create: "tax_create",
        edit: "tax_edit",
        delete: "tax_delete",
        group: "settings",
    },
    {
        name: "stock_manage_fixation",
        all: "stock_manage_fixation",
        group: "inventory",
    },
    {
        name: "stock_manage_transfer",
        all: "stock_manage_transfer",
        group: "inventory",
    },
    {
        name: "stock_manage_movement",
        all: "stock_manage_movement",
        group: "inventory",
    },

    {
        name: "stock_manage_preparation",
        all: "stock_manage_preparation",
        group: "inventory",
    },

    {
        name: "sale_type_manage",
        all: "sale_type_manage",
        group: "sale",
    },

    {
        name: "menu_order",
        all: "menu_order",
        group: "menu",
    },

    {
        name: "returns_manage",
        all: "returns_manage",
        view: "returns_manage_view",
        create: "returns_manage_create",
        delete: "returns_manage_delete",
        group: "sale",
    },
    {
        name: "web_cashier",
        all: "web_cashier",
        create: "web_cashier",
        group: "sale",
    },

    { name: "emenu_manage", all: "emenu_manage", group: "emenu" },
    { name: "add_ons_manage", all: "add_ons_manage", group: "add_ons" },
];

export const TERMINAL_ABILITIES = [
    {
        name: "terminal_finance_access",
        all: "terminal_finance_access",
        group: "finance",
    },
    {
        name: "terminal_table_access",
        all: "terminal_table_access",
        group: "table_management",
    },
    {
        name: "terminal_report",
        all: "terminal_report",
        group: "reporting",
    },
    {
        name: "close_receipt",
        all: "close_receipt",
        group: "receipt_management",
    },
    {
        name: "guests_select",
        all: "guests_select",
        group: "receipt_management",
    },
    {
        name: "terminal_discount_select",
        all: "terminal_discount_select",
        group: "discount_management",
    },
    {
        name: "terminal_printer_manage",
        all: "terminal_printer_manage",
        group: "printing",
    },
    {
        name: "terminal_pre_print",
        all: "terminal_pre_print",
        group: "printing",
    },
    {
        name: "terminal_remove_after_preprint",
        all: "terminal_remove_after_preprint",
        group: "printing",
    },
    {
        name: "terminal_remove_after_station_print",
        all: "terminal_remove_after_station_print",
        group: "printing",
    },
    {
        name: "terminal_receipt_archive",
        all: "terminal_receipt_archive",
        group: "receipt_management",
    },
    {
        name: "terminal_gift",
        all: "terminal_gift",
        group: "discount_management",
    },
    {
        name: "terminal_customer",
        all: "terminal_customer",
        group: "customer_management",
    },
    {
        name: "terminal_returns",
        all: "terminal_returns",
        group: "receipt_management",
    },
    {
        name: "terminal_finance_accounts_manage",
        all: "terminal_finance_accounts_manage",
        group: "finance",
    },
    {
        name: "terminal_product_transfer",
        all: "terminal_product_transfer",
        group: "product_and_menu_management",
    },
    {
        name: "terminal_receipt_close_mistake",
        all: "terminal_receipt_close_mistake",
        group: "receipt_management",
    },
    {
        name: "terminal_manage_service_charge",
        all: "terminal_manage_service_charge",
        group: "service_charge_management",
    },
    {
        name: "terminal_menu_manage",
        all: "terminal_menu_manage",
        group: "product_and_menu_management",
    },
    {
        name: "terminal_product_create",
        all: "terminal_product_create",
        group: "product_and_menu_management",
    },
    {
        name: "terminal_product_edit",
        all: "terminal_product_edit",
        group: "product_and_menu_management",
    },
    { name: "terminal_change_table", all: "terminal_change_table", group: "receipt_management" },
    { name: "terminal_merge_receipts", all: "terminal_merge_receipts", group: "receipt_management" },
    { name: "terminal_receipt_reopen", all: "terminal_receipt_reopen", group: "receipt_management" },
    { name: "terminal_receipt_lock_manage", all: "terminal_receipt_lock_manage", group: "receipt_management" },
    { name: "terminal_change_receipt_owner", all: "terminal_change_receipt_owner", group: "receipt_management" },
    { name: "terminal_product_reprice", all: "terminal_product_reprice", group: "product_and_menu_management" },
    { name: "terminal_receipt_add_client", all: "terminal_receipt_add_client", group: "receipt_management" },
    { name: "terminal_loyalty_balance_usage", all: "terminal_loyalty_balance_usage", group: "customer_management" },
    { name: "terminal_cash_shift_can_open", all: "terminal_cash_shift_can_open", group: "cash_shift_management" },
    { name: "terminal_cash_shift_can_close", all: "terminal_cash_shift_can_close", group: "cash_shift_management" },
    {
        name: "terminal_enable_actions_after_precheck",
        all: "terminal_enable_actions_after_precheck",
        group: "receipt_management",
    },
    { name: "terminal_full_screen_control", all: "terminal_full_screen_control", group: "controls" },
    { name: "terminal_discount_after_precheck", all: "terminal_discount_after_precheck", group: "discount_management" },
    {
        name: "terminal_close_receipt_without_send_station",
        all: "terminal_close_receipt_without_send_station",
        group: "receipt_management",
    },
    {
        name: "terminal_fiscal_manage",
        all: "terminal_fiscal_manage",
        group: "printing",
    },
];

export const menuTitleByType = {
    get menuTitleVariation(): any {
        return {
            menu: {
                restaurant: t("menu"),
                shop: t("showcase"),
            },
        };
    },
};

export const allergiesAndDietsData = {
    get allergies(): any {
        return [
            {
                name: t("crustacean_shellfish"),
                id: "crustacean_shellfish",
            },
            {
                name: t("egg"),
                id: "egg",
            },
            {
                name: t("fish"),
                id: "fish",
            },
            {
                name: t("milk"),
                id: "milk",
            },
            {
                name: t("peanut"),
                id: "peanut",
            },
            {
                name: t("soy"),
                id: "soy",
            },
            {
                name: t("tree_nuts"),
                id: "tree_nuts",
            },
            {
                name: t("wheat"),
                id: "wheat",
            },
            {
                name: t("cereals_gluten"),
                id: "cereals_gluten",
            },
            {
                name: t("sulfites"),
                id: "sulfites",
            },
            {
                name: t("buckwheat"),
                id: "buckwheat",
            },
            {
                name: t("celery"),
                id: "celery",
            },
            {
                name: t("lupin"),
                id: "lupin",
            },
            {
                name: t("molluscan_shellfish"),
                id: "molluscan_shellfish",
            },
            {
                name: t("mustard"),
                id: "mustard",
            },
            {
                name: t("sesame"),
                id: "sesame",
            },
        ];
    },
    get diets(): any {
        return [
            {
                name: t("halal"),
                id: "halal",
            },
            {
                name: t("kosher"),
                id: "kosher",
            },
            {
                name: t("vegeterian"),
                id: "vegeterian",
            },
            {
                name: t("vegan"),
                id: "vegan",
            },
            {
                name: t("spicy"),
                id: "spicy",
            },
        ];
    },
};

export const enum PaymentMethodType {
    CASH = 1,
    CARD = 2,
    CUSTOMER_BALANCE = 3,
    CASHBACK_BALANCE = 4,
}

export const enum Source {
    web = "web",
    pos = "pos",
    manager = "manager",
}

export const enum LoyaltyTypeEnum {
    CASHBACK = "CashBack",
    POINTS = "Points",
    DISCOUNTS = "Discounts",
}

export const enum SalesChannelTypes {
    DELIVERY = "DELIVERY",
    TAKEAWAY = "TAKEAWAY",
}

export const enum NotificationStatusEnum {
    PENDING = "PENDING",
    IGNORE = "IGNORE",
    RECEIVED = "RECEIVED",
}

export const enum SaleTypeId {
    DELIVERY = 2,
    TAKEAWAY = 3,
}

export const enum AcceptancePoint {
    POS = "POS",
    WHATSAPP = "WHATSAPP",
}

export const enum ModuleEnums {
    CASH_SHIFT = "cash_shift",
    CLOPOS_PAY = "clopos_pay",
    EMENU = "emenu",
    WEB_CASHIER = "web_cashier",
    INCENTIVE_PROGRAM = "incentive_program",
    LOYALTY = "loyalty",
    GETIR = "getir",
    TRENDYOL = "trendyol",
    REVIEW = "review",
    CALL_CENTER = "call_center",
    SMART_KASSA = "smart_kassa",
    KITCHEN_KIT = "kitchen_kit",
    AKIAB = "akiab",
    WOLT = "wolt",
}

export const enum MappingTypes {
    PRODUCT = "product",
    MODIFIER = "modifier",
    MODIFIER_GROUP = "modifier_group",
}

export const enum ExportType {
    RECEIPT = "receipt",
    FINANCE_TRANSACTION = "finance_transaction",
    CUSTOMER = "customer",
}

export const enum FileExportProcessStatus {
    PENDING = "pending",
    PROCESSING = "processing",
    COMPLETED = "completed",
    FAILED = "failed",
    CANCELLED = "cancelled",
}

export enum ServiceChargeTypeEnum {
    OPEN_PERCENTAGE = "OPEN_PERCENTAGE",
    OPEN_AMOUNT = "OPEN_AMOUNT",
    FIXED_AMOUNT = "FIXED_AMOUNT",
    FIXED_PERCENTAGE = "FIXED_PERCENTAGE",
}

export const enum ServiceCharceCalculationMethodEnum {
    PRE_DISCOUNT = "PRE_DISCOUNT",
    AFTER_DISCOUNT = "AFTER_DISCOUNT",
}

export const ServiceChargeFixedTypes = [ServiceChargeTypeEnum.FIXED_AMOUNT, ServiceChargeTypeEnum.FIXED_PERCENTAGE];

export const enum PerformanceType {
    SELF = "self",
    OVERALL = "overall",
}

export const enum WeekDays {
    MONDAY = "Monday",
    TUESDAY = "Tuesday",
    WEDNESDAY = "Wednesday",
    THURSDAY = "Thursday",
    FRIDAY = "Friday",
    SATURDAY = "Saturday",
    SUNDAY = "Sunday",
    ALL = "*",
}

export const statuses = {
    active: {
        color: "green",
        name: t("active"),
    },
    inactive: {
        color: "orange",
        name: t("inactive"),
    },
};

export const enum cBoolean {
    False = 0,
    True = 1,
}

export const enum Trash {
    OnlyTrash = 1,
    TrashAndActive = 2,
}
