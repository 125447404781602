import React, { useCallback, useState } from "react";
import { UseFormReturn } from "react-hook-form";

export interface UseDrawerReturn {
    dataId?: number;
    onClose: (event?: React.SyntheticEvent) => void;
    isOpen: boolean;
    data?: any;
    testId?: string;
    mainForm?: UseFormReturn;
    hide: () => void;
    isClosable: boolean;
    setIsClosable: (isClosable: boolean) => void;
    setDataId: (id: number) => void;
    setIsOpen: (isOpen: boolean) => void;
    setData: (data: any, notShow?: boolean) => void;
    modalType: string;
    setModalType: (modalType: any) => void;
    overflowAuto: boolean;
    backdrop?: boolean | "static";
    hasBackdrop?: "yes" | "no";
}

export function useDrawer(conf?: { backdrop: boolean; overflowAuto: boolean }): UseDrawerReturn {
    conf = conf ?? { backdrop: true, overflowAuto: true };
    const [isOpen, setIsOpen] = useState(false);
    const [dataId, setDataId] = useState<number>();
    const [data, setData] = useState<any>(null);
    //for another design by modal type
    const [modalType, setModalType] = useState<string>("default");
    const [isClosable, setIsClosable] = useState(true);

    const hide = useCallback(() => {
        setIsOpen(false);
        setDataId(undefined);
        setData(null);
    }, []);

    const setDataIdCallback = useCallback((id: number) => {
        setDataId(id);
        setIsOpen(true);
    }, []);

    const setDataCallback = useCallback((data: any, notShow = false) => {
        setData(data);
        if (!notShow) setIsOpen(true);
    }, []);

    const onClose = useCallback(() => {
        setIsOpen(false);
        setDataId(undefined);
    }, []);

    return {
        isOpen,
        hide: hide,
        dataId,
        data,
        setDataId: setDataIdCallback,
        setIsOpen,
        setData: setDataCallback,
        onClose: onClose,
        modalType,
        setModalType,
        isClosable,
        setIsClosable,
        overflowAuto: conf?.overflowAuto,
        backdrop: conf?.backdrop,
        // wrapper classa ccs ilə müdaxilə edə bilmək üçün lazımdır. rsuite-default.css line 708
        hasBackdrop: conf?.backdrop ? "yes" : "no",
    };
}
