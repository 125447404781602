import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useDataProvider } from "components/DataTable/useDataProvider";
import ConfirmModal from "components/Drawers/ConfirmModal";
import { useDrawer } from "components/Drawers/useDrawer";
import { useTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import NoAccessModal from "components/NoAccessModal";
import { BulkModeTypes, CategoryTypeEnum } from "config/constants";
import { useBulk } from "hooks/useBulk";
import { useLoading } from "hooks/useLoading";
import { getProductName, translateUnitName } from "lib/utils";
import _ from "lodash";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useEffect, useState } from "react";
import { Button } from "rsuite";
import { iClose, iDoubleCheckBox, iEditPencil, iTrash } from "assets/icons/Icons";
import CIcon from "../../../components/CIcon";
import CellBtn from "../../../components/DataTable/CellButton";
import CreateButton from "../../../components/DataTable/CreateButton";
import DataTable from "../../../components/DataTable/DataTable";
import MenuCategoryTreeFilter from "../../../components/DataTable/Filters/CategoryTreeFilter/MenuCategoryTreeFilter";
import FiltersContainer from "../../../components/DataTable/Filters/FiltersContainer";
import SearchFilter from "../../../components/DataTable/Filters/SearchFilter";
import SelectFilter from "../../../components/DataTable/Filters/SelectFilter";
import ToggleFilterDrawer from "../../../components/DataTable/Filters/ToggleFilterDrawer";
import LearnButton from "../../../components/DataTable/LearnButton";
import BulkDeleteResultModal from "../../../components/Drawers/BulkDeleteResultModal/BulkDeleteResultModal";
import HeaderRight from "../../../components/Header/HeaderRight";
import { exportFile } from "lib/export-table";
import IngredientFIlterDrawer from "../drawer/IngredientFIlterDrawer";
import ProductBulkUpdateDrawer from "../drawer/ProductBulkUpdateDrawer";
import IngredientDrawer from "./IngredientDrawer";

const IngredientPage: FCC = () => {
    const rd = useDrawer({ backdrop: false, overflowAuto: true });
    const { isLoading, withLoading } = useLoading();
    const noAccessModal = useDrawer();
    const bulkDeleteModal = useDrawer();
    const bulkUpdateModal = useDrawer();
    const filterDrawer = useDrawer();
    const bulkDeleteResultModal = useDrawer();
    const [permissionLabel, setPermissionLabel] = useState("");
    const {
        isBulkModeOn,
        setIsBulkModeOn,
        bulkMode,
        setBulkMode,
        bulkData,
        setBulkData,
        resetBulkState,
        deleteRequest,
    } = useBulk();
    const auth = useAuth();
    const [bulkDeleteResult, setBulkDeleteResult] = useState({
        deleted_count: 0,
        could_not_deleted_count: 0,
    });

    const dp = useDataProvider({
        url: "product/ingredient",
        useUrl: true,
        extraFilterKeys: ["accounting_category_id", "inDish"],
        with: ["category", "unit", "accounting_category"],
        sort: ["created_at", "1"],
    });

    const cm = useColumns<any>("product/ingredient", [
        {
            title: "Id",
            key: "id",
            hide: true,
            flexGrow: 0.5,
        },
        {
            title: t("name"),
            key: "name",
            render: d => (
                <CellBtn
                    label={getProductName(d)}
                    action={() => {
                        rd.setData({ name: d.name });
                        rd.setDataId(d.id);
                    }}
                />
            ),
            flexGrow: 3,
        },
        {
            title: t("category"),
            key: "category_id",
            render: e => (_.isNil(e.category_id) ? t("without_cat") : e.category?.name),
            align: "center",
            minWidth: 150,
        },
        {
            title: t("accounting_category"),
            key: "accounting_category.name",
            align: "center",
            hide: true,
            minWidth: 120,
        },
        {
            title: t("unit"),
            key: "unit.symbol",
            render: u => translateUnitName(u.unit),
            align: "center",
        },
        {
            title: t("cost_price"),
            key: "cost_price",
            type: "money",
            align: "center",
        },
        {
            title: t("count_filter"),
            key: "inventory_behavior",
            render: c => (c.inventory_behavior === 3 ? t("uncountable") : t("countable")),
            align: "center",
            hide: true,
        },
        {
            title: t("unit_weight"),
            key: "unit_weight",
            suffix: t("kg_short"),
            align: "center",
            minWidth: 150,
        },
        { title: t("barcode"), key: "barcode", align: "center", hide: true, flexGrow: 2 },
        optionsColumn({
            dp,
            edit: "/menu/ingredient/edit/:id",
            delete: "/product/ingredient/:id",
            canEdit: "product_edit",
            canDelete: "product_delete",
        }),
    ]);

    const blkPicker = (m: any) => {
        setBulkMode(m);
        setIsBulkModeOn(true);
    };

    const onConfirmDelete = () => {
        withLoading(() => {
            return deleteRequest("/product/bulk", bulkData);
        })
            .then(response => {
                setBulkDeleteResult(response.data);
                bulkDeleteResultModal.setIsOpen(true);
                resetBulkState();
                bulkDeleteModal.onClose();
                dp.revalidate();
            })
            .catch(() => {
                bulkDeleteModal.onClose();
            });
    };

    const blkExit = () => resetBulkState();

    const blkApplyHandler = (mode: string) => {
        const permission = mode === BulkModeTypes.DELETE ? "product_delete" : "product_edit";
        if (!auth.hasAbility([permission])) {
            setPermissionLabel(permission);
            noAccessModal.setDataId(1);
            return;
        }
        if (mode === BulkModeTypes.UPDATE) {
            bulkUpdateModal.setDataId(1);
        } else if (mode === BulkModeTypes.DELETE) {
            bulkDeleteModal.setDataId(1);
        }
    };

    const blkHandleCheck = (item: any) => {
        const id = item.id as never;
        if (bulkData.includes(id)) {
            setBulkData(prev => prev.filter(f => f !== id));
        } else {
            setBulkData(prev => [...prev, id]);
        }
    };

    const blkHandleSelectAll = (selected: boolean) => {
        const data: Array<any> = dp.data ?? [];
        let _bulkData = [];
        if (selected) {
            _bulkData = data.map(d => {
                return d.id;
            });
        }

        setBulkData(_bulkData);
    };

    useEffect(() => {
        if (isBulkModeOn) bulkUpdateModal.setData(bulkData, true);
        // eslint-disable-next-line
    }, [bulkData]);

    return (
        <div className="h-100">
            {useTitle(t("ingredient"), dp.total)}
            <HeaderRight
                cm={cm}
                print
                reload
                dp={dp}
                export={() => exportFile(dp, cm.columns, t("ingredient"))}
                importOptions={{
                    template: "Ingredient temp",
                    detail: "ingredient",
                    action: "product/import",
                    onSuccess: () => dp.revalidate(),
                }}
            />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    {!bulkMode && (
                        <Button className="multiple-select-button" ripple={false} onClick={() => blkPicker("true")}>
                            <CIcon path={iDoubleCheckBox} />
                            <span>
                                <TText tkey="multiple_select" />
                            </span>
                        </Button>
                    )}
                    {bulkMode && (
                        <div className="bulk-mode">
                            <span className="badge">{bulkData.length}</span>
                            <div onClick={() => blkExit()} className="d-flex">
                                <CIcon path={iClose} />
                                <span className="cancel-button">
                                    <TText tkey="cancel" />
                                </span>
                            </div>
                        </div>
                    )}
                    <SearchFilter fields="name" />
                    <MenuCategoryTreeFilter categoryTypes={[CategoryTypeEnum.INGREDIENT]} width={120} />

                    <SelectFilter
                        fields="inventory_behavior"
                        pickerProps={{ searchable: false, placeholder: t("count_filter") }}
                        options={[
                            { label: t("countable"), value: "0" },
                            { label: t("uncountable"), value: "3" },
                        ]}
                        width={150}
                    />
                    <ToggleFilterDrawer isOpen={filterDrawer.isOpen} onChange={val => filterDrawer.setIsOpen(val)} />
                    {bulkData.length > 0 && (
                        <div className="bulk-acton-buttons">
                            <Button
                                ripple={false}
                                className="edit"
                                onClick={() => blkApplyHandler(BulkModeTypes.UPDATE)}
                            >
                                <CIcon path={iEditPencil} />
                                <TText tkey="change_set" />
                            </Button>
                            <Button
                                ripple={false}
                                className="delete"
                                onClick={() => blkApplyHandler(BulkModeTypes.DELETE)}
                            >
                                <CIcon path={iTrash} />
                                <TText tkey="delete" />
                            </Button>
                        </div>
                    )}
                </div>

                <LearnButton page="ingredient" />

                <CreateButton
                    to={"/menu/ingredient/bulk-create"}
                    canCreate={"product_create"}
                    testId="bulk-create-button"
                    label={t("bulk_create")}
                />
                <CreateButton to={"/menu/ingredient/create"} canCreate={"product_create"} />
            </FiltersContainer>
            <IngredientDrawer {...rd} />
            <DataTable
                dp={dp}
                columns={cm.columns}
                isBulkMode={isBulkModeOn}
                bulkData={bulkData}
                setBulkData={blkHandleCheck}
                setAllBulkData={blkHandleSelectAll}
                tableProps={{
                    headerHeight: 40,
                }}
            />
            <ProductBulkUpdateDrawer
                {...bulkUpdateModal}
                onSubmit={() => {
                    dp.revalidate();
                    blkExit();
                    bulkUpdateModal.hide();
                }}
                type={"INGREDIENTS"}
            />
            <ConfirmModal {...bulkDeleteModal} isLoading={isLoading} onConfirm={onConfirmDelete} />
            <NoAccessModal modal={noAccessModal} permission={[permissionLabel]} />
            <IngredientFIlterDrawer dp={dp} {...filterDrawer} />
            <BulkDeleteResultModal modal={bulkDeleteResultModal} bulkDeleteResult={bulkDeleteResult} />
        </div>
    );
};

export default IngredientPage;
