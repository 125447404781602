import { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { useState } from "react";
import { iSpinner } from "assets/icons/Icons";
import CIcon from "../../../components/CIcon";
import CellBtn from "../../../components/DataTable/CellButton";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import DataTable from "../../../components/DataTable/DataTable";
import { useDrawer } from "components/Drawers/useDrawer";
import { getLabel } from "components/Form/form-utils";
import Money from "../../../components/mini/Money";
import Qty from "../../../components/mini/Qty";
import StorageWithStatus from "../../../components/mini/StorageWithStatus";
import { OperationTypes } from "config/constants";
import { useData } from "hooks/useData";
import StockOperationsDrawer from "./StockOperationsDrawer";

interface IProps extends ICDrawerProps {
    op?: IProduct;
    activeStorages: number[];
}

const StockDetails: FCC<IProps> = ({ op, activeStorages, ...props }) => {
    const dp = useData<IProduct>(op?.id ? `product/${op.id}` : false, {
        with: ["stocks.storage"],
    });
    const rd = useDrawer();
    const [ops, setOps] = useState<IOperation>();
    const [title, setTitle] = useState<string>("");
    const cm: ITableColumnProps<IStockItem>[] = [
        {
            key: "created_at",
            align: "left",
            type: "datetime",
            flexGrow: undefined,
            width: 120,
            multiline: true,
            reverse: true,
        },
        {
            key: "origin",
            title: t("reference"),
            flexGrow: 1.5,
            render: e =>
                e.origin ? (
                    <CellBtn
                        action={() => {
                            const operation = e.operations?.find(s => s.id === e.origin_id);
                            if (operation) {
                                setOps(operation);
                                rd.setDataId(e.origin_id);
                                props.setIsClosable(false);
                                setTitle(getLabel(OperationTypes?.[e?.origin] || ""));
                            }
                        }}
                        label={`${getLabel(OperationTypes[e.origin] || "")} #${e.origin_id}`}
                    />
                ) : (
                    `${getLabel(OperationTypes[e.origin] || "")} #${e.origin_id}`
                ),
        },
        {
            key: "storage.name",
            title: t("storage"),
            flexGrow: 1.5,
            minWidth: 180,
            render: e => (e.storage ? <StorageWithStatus storage={e.storage} activeStorages={activeStorages} /> : null),
        },
        {
            key: "cost",
            title: t("cost"),
            type: "money",
            render: d => <Money value={d?.cost} />,
        },
        { key: "quantity", title: t("qty"), render: d => <Qty value={d.quantity} unit={op?.unit} /> },
        optionsColumn({
            dp: dp as any,
            preventDelete: d => d.quantity !== 0,
            delete: "stock/:id",
            editable: () => true,
        }),
    ];

    return (
        <div className="d-flex">
            <div className="flex-fill border-left">
                <StockOperationsDrawer
                    {...rd}
                    op={ops}
                    onClose={() => {
                        rd.onClose();
                        props.setIsClosable(true);
                    }}
                    title={title || t("total_supply") + " #" + rd.dataId}
                    cols={["product.name", "quantity", "cost", "total_cost"]}
                />
                {dp.data ? (
                    <DataTable hidePages noShadow columns={cm} dp={{ data: dp.data?.stocks } as any} />
                ) : (
                    <CIcon path={iSpinner} spin className="m-3" />
                )}
            </div>
        </div>
    );
};

export default StockDetails;
