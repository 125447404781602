import { useProducts } from "hooks/useProducts";
import Money from "../../../components/mini/Money";
import { toFixed } from "lib/utils";

interface IProps {
    p: any;
}

const SummaryCell: FCC<IProps> = props => {
    const products = useProducts();
    if (products.loading) {
        return null;
    }

    const total = props.p?.reduce((total: number, product: any) => {
        const pp =
            product.payload?.clearRps?.reduce(
                (total: number, s: { price: number; count: number }) => total + s?.price * (s?.count || 1),
                0,
            ) || 0;
        return (
            total +
            (product.type === "Clean"
                ? pp
                : parseFloat(toFixed((product.payload?.price || 0) * (product.payload?.count || 1), 2)))
        );
    }, 0);

    return total ? <Money value={total} /> : <span>--</span>;
};

export default SummaryCell;
