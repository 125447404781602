import _ from "lodash";
import React, { useContext, useEffect } from "react";
import { Checkbox } from "rsuite";
import { IDataProvider } from "../useDataProvider";
import { FiltersContext } from "./FiltersContainer";

interface IProps {
    dp?: IDataProvider;
    fields: string;
    title: string;
    value?: string | number | any[];
    onChange?: (checked: boolean) => void;
    afterChange?: (checked: boolean) => void;
    useParams?: boolean;
    extraFields?: string[];
    negate?: boolean;
    filterSymbol?: OP;
}

const CheckBoxFilter: FCC<IProps> = _props => {
    const props = _.omit(_props, "dp");
    const filtersContext = useContext(FiltersContext);
    const dp = _props.dp || filtersContext.dp;
    const path = props.useParams ? "params" : "filters";
    const val = dp[path].getValue(props.fields);
    const isChecked = props.negate ? !(val !== undefined) : val !== undefined;

    useEffect(() => {
        if (props.fields === "suspicion" && dp.filters.getValue("suspicious") && dp.filters.getValue("suspicion")) {
            dp.filters.remove("suspicious");
        }
        // eslint-disable-next-line
    }, [val]);

    const onChange = (__: any, v: boolean) => {
        if (props.negate) {
            v = !v;
        }
        if (props.onChange) {
            props.onChange(v);
        } else {
            if (v) {
                if (props.useParams) {
                    dp.params.add(props.fields, props.value ?? 1);
                } else {
                    let filter: any[] = [props.fields, props.filterSymbol ?? ("=" as any), props.value ?? 1];
                    if (!_.isEmpty(props.extraFields)) {
                        filter = [filter, ...[props.extraFields]];
                    }
                    dp.filters.add(filter, props.fields);
                }
            } else {
                dp[path].remove(props.fields);
            }
        }
        props.afterChange && props.afterChange(v);
    };

    return (
        <Checkbox checked={isChecked} test-id="checkbox-filter" onChange={onChange} style={{ whiteSpace: "nowrap" }}>
            {props.title}
        </Checkbox>
    );
};

export default CheckBoxFilter;
