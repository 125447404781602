import { TText } from "components/i18n/TText";
import React from "react";
import { useAppSelector } from "hooks/useRedux";

const GlobalError: FCC = props => {
    const { message, hasError } = useAppSelector(state => state.app.error);
    if (!hasError) return props.children as any;

    return (
        <div className="d-flex align-items-center justify-content-center flex-column m-5">
            <h1>
                <TText tkey="error" />
            </h1>
            <p>{message}</p>
        </div>
    );
};

export default GlobalError;
