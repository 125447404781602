import { t } from "lib/i18n";

import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";

import CreateButton from "../../../components/DataTable/CreateButton";

const InventoryPackagesPage: FCC = props => {
    const dp = useDataProvider({
        url: "unit",
        useUrl: true,
        with: ["parent"],
        filters: {
            type: ["type", 4],
        },
    });

    const cm = useColumns<any>("unit", [
        { key: "name", align: "left", testId: "name" },
        { key: "equal", testId: "equal", render: (e: any) => parseFloat(e.equal).toFixed(0) },
        // {
        //     key: "parent",
        //     title: t("unit"),
        //     getData: d => translateUnitName(units.idMap?.[d.parent_id]),
        //
        //     sortField: "unit.name",
        // },
        optionsColumn({
            dp,
            edit: "/inventory/packages/edit/:id",
            delete: "unit/:id",
            canEdit: "stock_packages_manage",
            canDelete: "stock_packages_manage",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("packages"), dp.total)}
            <HeaderRight dp={dp} reload print />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="name" />
                </div>
                <CreateButton to="/inventory/packages/create" canCreate={"stock_packages_manage"} />
            </FiltersContainer>

            <DataTable dp={dp} columns={cm.columns} />
        </div>
    );
};

export default InventoryPackagesPage;
