import { useParams } from "react-router-dom";
import { Rnd } from "react-rnd";
import TableView from "pages/settings/tables/TableView";
import TableEditModal from "pages/settings/tables/TableEditModal";
import { useDrawer } from "components/Drawers/useDrawer";
import { useHallManager } from "pages/settings/tables/useHallManager";
import React from "react";
import { useTitle } from "components/Header/Title";
import PortalTo from "components/PortalTo";
import CIcon from "components/CIcon";
import { iCheckBoxChecked, iEraser, iPlus, iRefresh } from "assets/icons/Icons";
import { TText } from "components/i18n/TText";
import { Button, IconButton, Loader } from "rsuite";
import { t } from "lib/i18n";

interface IParams {
    id: string;
}

const ManageTablesPage = () => {
    const { id } = useParams<IParams>();
    const modal = useDrawer();
    const manager = useHallManager(+id);

    const style = {
        width: "801px",
        height: "501px",
    };

    const gridSize = 20;

    return (
        <div className={"tw-h-100"}>
            {useTitle(manager.hall ? manager.hall.name : t("loading"))}
            <PortalTo id="header-portal" className="d-flex">
                <Button color="green" onClick={manager.save} loading={manager.isLoading}>
                    <CIcon path={iCheckBoxChecked} className="mr-2" />
                    <TText tkey="save" />
                </Button>
            </PortalTo>
            <div className={`tw-px-4 tw-flex tw-gap-2 tw-justify-end`}>
                <IconButton icon={<CIcon path={iRefresh} />} onClick={manager.reset} />
                <Button appearance={"default"} onClick={manager.cleanHall}>
                    <CIcon path={iEraser} style={{ fillRule: "evenodd" }} className="mr-2" />
                    <TText tkey="clear_tables" />
                </Button>
                <Button appearance={"primary"} onClick={manager.addTable}>
                    <CIcon path={iPlus} className="mr-2" />
                    <TText tkey="add_table" />
                </Button>
            </div>
            <div className="tables-grid" style={style}>
                {manager.hall?.tables?.map((t, k) => (
                    <Rnd
                        /**
                         * key is used to force the component to re-render when the table size/position is updated
                         * otherwise if we reset table's position/size the component will not re-render
                         */
                        key={"" + t.id + t.x + t.y + t.w + t.h}
                        resizeGrid={[gridSize, gridSize]}
                        dragGrid={[gridSize, gridSize]}
                        minHeight={50}
                        minWidth={50}
                        bounds="parent"
                        onDragStop={(e, d) => manager.updateTable({ ...t, x: d.lastX, y: d.lastY })}
                        onResizeStop={(e, d, ref, delta, position) => {
                            manager.updateTable({
                                ...t,
                                x: position.x,
                                y: position.y,
                                w: parseInt(ref.style.width),
                                h: parseInt(ref.style.height),
                            });
                        }}
                        default={{
                            x: t.x,
                            y: t.y,
                            width: t.w,
                            height: t.h,
                        }}
                    >
                        <TableView
                            key={t.id}
                            index={k}
                            table={t}
                            manager={manager}
                            onEditClick={() => {
                                modal.setData({ table: t });
                                modal.setIsOpen(true);
                            }}
                        />
                    </Rnd>
                ))}

                {modal.isOpen && modal.data.table && (
                    <TableEditModal {...modal} table={modal.data.table} manager={manager} />
                )}
            </div>

            {manager.hallIsLoading && <Loader backdrop center />}
        </div>
    );
};

export default ManageTablesPage;
