import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useTitle } from "components/Header/Title";
import DataTable from "components/DataTable/DataTable";
import HeaderRight from "components/Header/HeaderRight";
import _ from "lodash";
import { getProductName } from "lib/utils";
import { exportFile } from "lib/export-table";
import { useDrawer } from "components/Drawers/useDrawer";
import Money from "components/mini/Money";
import CellBtn from "components/DataTable/CellButton";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import { useData } from "hooks/useData";
import { useLocation } from "react-router-dom";
import nrInRoutings from "./nr-in-routings";
import { QuickLinks } from "components/Header/QuickLinks";
import { useEffect } from "react";
import ToggleFilterDrawer from "components/DataTable/Filters/ToggleFilterDrawer";
import ModifierReportFilters from "./components/ModifierReportFilters";
import SelectFilter from "components/DataTable/Filters/SelectFilter";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { modifiersSlice } from "redux/features/model/slice";
import { ModifierStatsDrawer } from "pages/statistics/goods/drawer/ModifierStatsDrawer";

const NewReportsModifierPage: FCC = () => {
    const loc = useLocation();
    const url = "reports/modifiers";
    const rd = useDrawer({ backdrop: false, overflowAuto: true });
    const filter = useDrawer();
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(modifiersSlice.fetchData(""));
    }, []);

    const modificatorSelector = useAppSelector(state => state.model.modifiers);

    const modificators =
        modificatorSelector.ids.map(id => ({
            label: modificatorSelector.byId[id].name,
            value: modificatorSelector.byId[id].id,
        })) ?? [];

    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        url,
        with: ["modifier:id,group_id,name.modificator_group:id,name"],
        extraFilterKeys: [
            "receipt_id",
            "receipt_terminal_id",
            "receipt_table_id",
            "receipt_owner_id",
            "product_type",
            "sale_type_id",
            "accounting_category_id",
            "receipt_customer_id",
            "gift",
            "source",
        ],
    });

    const summary = useData<any>(url, {
        filters: _.values(dp.filters.all),
        params: {
            total: 1,
            date: dp.params.all.date,
            time: dp.params.all?.time,
        },
    });

    const cm = useColumns<any>("reports/modifiers", [
        {
            title: "ID",
            key: "modificator_id",
            align: "left",
            flexGrow: 0.3,
        },
        {
            title: t("name"),
            key: "modifier.name",
            render: d => <CellBtn label={getProductName(d?.modifier)} action={() => rd.setData(d?.modifier)} />,
            align: "left",
            flexGrow: 2,
        },
        {
            title: t("group"),
            key: "modifier.modificator_group.name",
            align: "left",
            flexGrow: 2,
        },
        {
            title: t("total_count"),
            key: "count",
            summary: () => summary.data?.[0]?.count,
            render: e => `${e.count} ${t("pcs_short")}`,
        },
        {
            key: "gift_total_amount",
            hide: true,
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.gift_total_amount} />,
            minWidth: 120,
        },
        {
            key: "total_cost",
            hide: true,
            type: "money",
            title: t("cost"),
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.total_cost} />,
            minWidth: 120,
        },
        {
            key: "tax",
            hide: true,
            type: "money",
            title: t("tax"),
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.tax} />,
            minWidth: 120,
        },
        {
            key: "gift_total_count",
            hide: true,
            summary: () => summary.data?.[0]?.gift_total_count,
        },
        {
            title: t("price"),
            key: "average_price",
            type: "money",
            numberRoundingLength: 2,
        },
        {
            title: t("average_sale_price"),
            key: "average_sale_price",
            type: "money",
            numberRoundingLength: 2,
            minWidth: 140,
        },
        {
            title: t("total_cash"),
            key: "total",
            type: "money",
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.total} />,
        },
        {
            title: t("profit"),
            key: "profit",
            type: "money",
            render: (d: any) => <Money value={d.profit} />,
            summary: () => <Money value={summary.data?.[0]?.profit} />,
        },
    ]);
    return (
        <div className="h-100">
            {useTitle(t("modifiers"), dp.total)}
            <QuickLinks inHeader={false} links={nrInRoutings(loc)} />

            <HeaderRight cm={cm} print export={() => exportFile(dp, cm.columns, t("products"))} reload date dp={dp} />

            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SelectFilter
                        options={modificators}
                        fields="modificator_id"
                        labelField="name"
                        pickerProps={{
                            searchable: true,
                        }}
                    />
                    <RemoteCheckListFilter
                        remote={{
                            url: "station",
                            refineList: (list: any[]) => {
                                list?.push({ id: null, name: t("without_station") });
                                return list;
                            },
                        }}
                        searchField="name"
                        fields="receipt_station_id"
                        pickerProps={{ placeholder: t("station") }}
                    />

                    <ToggleFilterDrawer
                        isOpen={filter.isOpen}
                        onChange={val => {
                            filter.setIsOpen(val);
                            rd.setIsOpen(false);
                        }}
                    />
                </div>
            </FiltersContainer>

            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
            <ModifierReportFilters {...filter} dp={dp} />
            <ModifierStatsDrawer {...rd} />
        </div>
    );
};

export default NewReportsModifierPage;
