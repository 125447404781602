import { t } from "lib/i18n";
import React, { useContext } from "react";
import { CheckPicker } from "rsuite";
import _ from "lodash";
import { FiltersContext } from "components/DataTable/Filters/FiltersContainer";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { iSpinner } from "assets/icons/Icons";
import CIcon from "../../CIcon";
import { Colors } from "config/colors";
import { usePopupContainer } from "hooks/usePopupContainer";

interface IProps {
    url?: string;
    filter?: any;
    fieldName?: string;
    subFieldName?: string;
    searchFieldName?: string;
    subFieldTableName?: string;
    placeholder?: string;
    width?: string | number;
    maxWidth?: string | number;
    placement?: any;
}

const HallTableTreeFilter: FCC<IProps> = _props => {
    const fieldName: string = _props.fieldName || "table_id";
    const searchFieldName: string = _props.searchFieldName || "table_name";
    const filtersContext = useContext(FiltersContext);
    const dp = filtersContext.dp;
    const { container } = usePopupContainer();

    const localDp = useDataProvider({
        url: _props.url ?? "hall",
        with: ["tables"],
        filters: _props.filter,
        refineList: (list: any[]) => {
            const newList: any[] = [];
            const tree = (e: any) => {
                !newList.includes(e) && newList.push(e);
                if (e?.tables) e.tables.forEach(tree);
            };
            !_.isNil(list) && list.forEach(tree);
            return newList.map(m => {
                if (!m?.hall_id) return { ...m, id: "_" + m.id };
                return m;
            });
        },
    });

    const options = localDp.isLoading ? [] : localDp.data;
    let value = dp.filters.getFilter(fieldName) ?? [];
    value = _.isArray(value[0]) ? _.last(value[0]) : _.last(value) || [];

    // used to search values
    const onSearch = (val: string) => {
        if (val) {
            localDp.filters.add([searchFieldName, val], searchFieldName);
        } else {
            localDp.filters.remove(searchFieldName);
        }
    };

    // used to add filter to global dp object
    const onSelect = (val: any[], item: any) => {
        const tree = (e: any) => {
            val.push(e.id);
            e.tables?.length && e?.tables.forEach(tree);
        };
        const removeTree = (e: any) => {
            val = _.without(val, e.id);
            e.tables?.length && e?.tables.forEach(removeTree);
        };

        if (val.includes(item.id)) {
            if (item?.tables) item?.tables.forEach(tree);
            const filter: any[] = [fieldName, val];
            dp.filters.add(filter, fieldName);
        } else {
            if (item?.tables) item?.tables.forEach(removeTree);
            const filter: any[] = [fieldName, val];
            if (val.length) {
                dp.filters.add(filter, fieldName);
            } else {
                dp.filters.remove(fieldName);
            }
        }
    };
    const searchMaxWidth = _props.maxWidth ? _props.maxWidth : 80;
    const searchWidth = _props.width ? _props.width : 80;
    return (
        <CheckPicker
            // appearance="subtle"
            data={options}
            onSelect={onSelect}
            onSearch={onSearch}
            container={() => container.current!}
            value={value}
            valueKey="id"
            placement={_props.placement}
            labelKey="name"
            placeholder={_props.placeholder || t("hall")}
            onExit={() => localDp.filters.remove(fieldName)}
            onClean={() => dp.filters.remove(fieldName)}
            renderMenu={menu => {
                if (localDp.isLoading) {
                    return (
                        <p style={{ padding: 4, color: Colors.MediumGray, textAlign: "center" }}>
                            <CIcon path={iSpinner} spin /> loading...
                        </p>
                    );
                }
                return menu;
            }}
            sort={() => (a, b) => a._lft - b._lft}
            renderMenuItem={(label: any, item: any) => {
                return (
                    <span>
                        {item?.hall_id && <span className="px-2">--</span>}
                        {label}
                    </span>
                );
            }}
            style={{ maxWidth: searchMaxWidth, width: searchWidth }}
            menuStyle={{ width: 290 }}
        />
    );
};

export default HallTableTreeFilter;
