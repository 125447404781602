import { Tooltip, Whisper } from "rsuite";
import { iCommentProcessingOutline } from "assets/icons/Icons";
import CIcon from "../CIcon";

interface IProps {
    id: number;
    user: { username: string };
    description: string | null;
}

const UserWithDesc: FCC<IProps> = ({ user, id, description }) => {
    return (
        <>
            <span className="pr-2">{user?.username}</span>
            {description && (
                <Whisper placement="right" trigger="hover" speaker={<Tooltip>{description}</Tooltip>}>
                    <CIcon path={iCommentProcessingOutline} size={"15"} />
                </Whisper>
            )}
        </>
    );
};

export default UserWithDesc;
