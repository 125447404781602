import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useEffect, useMemo, useRef, useState } from "react";
import {
    CheckPicker,
    Drawer,
    DrawerProps,
    Dropdown,
    IconButton,
    Input,
    InputGroup,
    Popover,
    Progress,
    Whisper,
} from "rsuite";
import DataTable from "../../DataTable/DataTable";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { useColumns } from "../../DataTable/ColumnsManager/useColumns";
import CIcon from "../../CIcon";
import { iCheckBoxChecked, iClose, iPlus, iSearch, iWindowClose } from "assets/icons/Icons";
import Highlighter from "react-highlight-words";
import {
    clearState,
    findProductCategory,
    IProductsFilters,
    ProductsFilterKey,
    setDisabledProductIds,
    setIsSingleSelect,
    setOpenProductSelector,
    setResult,
    setSearchText,
    setSelectedProducts,
    setShouldShowSelected,
    setShowedCategoryTypes,
    setShowedTypes,
    setSortColumn,
    setSortType,
} from "redux/features/productsSelector/productsSelector";
import { translateUnitName } from "lib/utils";
import { EMPTY_DATA } from "config/main";
import "./CProductDrawer.scss";
import { CategoryTypeEnum, ProductType } from "config/constants";
import {
    addFilterAC,
    fetchProductsAndCategories,
    handleProductSelectAC,
    onCleanAllFiltersAC,
    onCleanSelectedFiltersAC,
    setAllProductsAC,
    setAllSelectedProductsAC,
} from "redux/features/productsSelector/products-thunk";
import ColumnsManagerButton from "../../DataTable/ColumnsManager/ColumnsManagerButton";
import { useProductSelectorContext } from "providers/ProductSelectorProvider";
import { countMatchingElements, getResultDataByType, SortType } from "./utils";
import CProductsDrawerFooter from "./CProductDrawerFooter";
import CreateIngredientModalState from "../../State/Modals/CreateIngredientModalState";
import CreateGoodsModalState from "../../State/Modals/CreateGoodsModalState";
import LoadingSvg from "../../../assets/img/cube.svg";
import { getLabel } from "../../Form/form-utils";
import { setProducts } from "redux/features/data";
import { Colors } from "config/colors";
import { useDrawer } from "../useDrawer";
import CCheckTreePicker from "components/CheckTreePicker/CCheckTreePicker";
import { useMenuCategories } from "hooks/data-hooks/useMenuCategories";
import { PopupContainerProvider } from "hooks/usePopupContainer";
import { debouncedFetchTrashProducts } from "redux/features/productsSelector/trash-products-thunk";

interface CProductsDrawerProps extends Pick<DrawerProps, "style"> {}

const CProductsDrawer: FCC<CProductsDrawerProps> = props => {
    const dispatch = useAppDispatch();
    const { updateConfig, onProductSelectorSave } = useProductSelectorContext();
    const [drawerHeight, setDrawerHeight] = useState(0);
    const {
        selectedProductIds,
        lastUpdatedOfProducts,
        shouldShowSelected,
        selectedFilters,
        searchText,
        sortColumn,
        sortType,
        result,
        isOpenProductSelector,
        counter,
        isSingleSelect,
        disabledProductIds,
        showedTypes,
        showedCategoryTypes,
    } = useAppSelector(state => state.productSelector);
    const { products, stations, categories, units } = useAppSelector(state => state.data);

    const { queue } = useAppSelector(state => state.trashProductSelector);
    const ingredientModal = useDrawer();
    const goodsModal = useDrawer();

    const hasFilter = useMemo(() => {
        return (
            selectedFilters.type.length > 0 || selectedFilters.station.length > 0 || selectedFilters.category.length > 0
        );
    }, [selectedFilters]);

    useEffect(() => {
        if (queue.length) {
            dispatch(debouncedFetchTrashProducts(queue));
        }
    }, [queue.length]);

    useEffect(() => {
        if (isOpenProductSelector) {
            const height = document.body.getBoundingClientRect().height - 210;
            setDrawerHeight(height);

            if (shouldShowSelected) {
                dispatch(setResult(selectedProductIds));
            } else {
                if (!hasFilter) {
                    dispatch(setResult(products.allIds));
                }
            }
        } else {
            dispatch(setSelectedProducts([]));
            dispatch(setShouldShowSelected(false));
            dispatch(setSearchText(""));
        }
    }, [isOpenProductSelector, shouldShowSelected]);

    useEffect(() => {
        dispatch(fetchProductsAndCategories(lastUpdatedOfProducts));

        return () => {
            dispatch(clearState());
            clearAllFilter();
            updateConfig({
                onProductSelectorSave: null,
            });
            dispatch(setIsSingleSelect(false));
            dispatch(setDisabledProductIds([]));
            dispatch(
                setShowedTypes([ProductType.GOODS, ProductType.DISH, ProductType.INGREDIENT, ProductType.PREPARATION]),
            );
            dispatch(setShowedCategoryTypes([CategoryTypeEnum.INGREDIENT, CategoryTypeEnum.PRODUCT]));
        };
    }, []);

    useEffect(() => {
        dispatch(setAllProductsAC());
    }, [selectedFilters, searchText, sortColumn, sortType, shouldShowSelected]);

    const cm = useColumns("product-selector", [
        {
            title: "id",
            key: "id",
            sortable: true,
            sortField: "id",
            flexGrow: 1,
            testId: "product-selector-id",
        },
        {
            title: t("name"),
            key: "full_name",
            sortable: true,
            flexGrow: 3,
            fullText: true,
            sortField: "full_name",
            render: p => {
                if (isSingleSelect && [...disabledProductIds, ...selectedProductIds].includes(p.id)) {
                    return (
                        <div className="tw-flex tw-items-center tw-gap-2">
                            <div className="tw-flex tw-items-center tw-rounded-full tw-justify-center tw-bg-blue-500 tw-w-4 tw-h-4">
                                <CIcon
                                    test-id="product-selector-selected-icon"
                                    className="ps-checked-icon"
                                    path={iCheckBoxChecked}
                                    style={{ color: "white" }}
                                    size={0.45}
                                />
                            </div>
                            <div className={"tw-text-blue-500"} style={{ color: Colors.CobaltBlue }}>
                                {p?.full_name}
                            </div>
                        </div>
                    );
                }

                return (
                    <Highlighter
                        highlightClassName="search-highlighter"
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={p?.full_name}
                    />
                );
            },
        },
        {
            title: t("unit"),
            key: "unit",
            verticalAlign: "middle",
            flexGrow: 1,
            render: p => {
                if (!p.unit_id) return EMPTY_DATA;
                const unit = units.byId[p.unit_id];
                return translateUnitName(unit);
            },
        },
        {
            title: t("type"),
            key: "type",
            verticalAlign: "middle",
            flexGrow: 1,
            render(p: IProduct) {
                if (p.type === ProductType.MODIFICATION) {
                    return (
                        <div>
                            <TText tkey="goods" />
                        </div>
                    );
                }
                return <div>{t(p.type?.toLowerCase() as Lowercase<ProductType>)}</div>;
            },
        },
        {
            title: t("barcode"),
            key: "barcode",
            verticalAlign: "middle",
            sortable: true,
            sortField: "barcode",
            flexGrow: 1,
            render: p => {
                return (
                    <Highlighter
                        highlightClassName="search-highlighter"
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={p?.barcode || "─"}
                    />
                );
            },
        },
        {
            title: t("category"),
            key: "category",
            verticalAlign: "middle",
            sortable: true,
            flexGrow: 1,
            sortField: "category.name",
            fullText: true,
            render: (p: IProduct) => {
                if (!p.category_id) return EMPTY_DATA;
                const type = p.type;
                const category = categories[findProductCategory[type]]?.[p.category_id];
                return (
                    <Highlighter
                        highlightClassName="search-highlighter"
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={category?.name || "─"}
                    />
                );
            },
        },
        {
            title: t("station"),
            key: "station.name",
            verticalAlign: "middle",
            sortable: true,
            flexGrow: 1,
            fullText: true,
            sortField: "station.name",

            render: (p: IProduct) => {
                const station = stations.byId[p.station_id || -1];
                return (
                    <Highlighter
                        highlightClassName="search-highlighter"
                        searchWords={[searchText]}
                        autoEscape={true}
                        textToHighlight={station?.name || "─"}
                    />
                );
            },
        },
    ]);

    const blkHandleSelectAll = (select: boolean) => {
        dispatch(
            setAllSelectedProductsAC(
                select,
                resultData.map(p => p.id).filter(p => !disabledProductIds.includes(p)),
            ),
        );
    };

    const blkHandleSelect = (product: IProduct) => {
        dispatch(handleProductSelectAC(product.id));
    };

    const onSearch = (searchText: string) => {
        dispatch(setSearchText(searchText));
    };

    const handleSortColumn = (sortColumn?: string, sortType?: SortType) => {
        if (sortColumn) {
            dispatch(setSortColumn(sortColumn));
            dispatch(setSortType(sortType));
        }
    };

    const handleAddFilter = (type: ProductsFilterKey, value: any[]) => {
        dispatch(addFilterAC(type, value));
    };

    const handleCleanFilter = (type: keyof IProductsFilters) => {
        dispatch(onCleanSelectedFiltersAC(type));
    };

    const clearAllFilter = () => {
        dispatch(onCleanAllFiltersAC());
    };

    const handleClearSearch = () => {
        dispatch(setSearchText(""));
    };

    const addCreatedProduct = (res: { data: IProduct; success: boolean }) => {
        if (res.success) {
            const product = res.data;
            dispatch(setProducts({ allIds: [product.id], byId: { [product.id]: product } }));
        }
    };

    const resultData = getResultDataByType(result, products.byId, showedTypes);

    const matchingDisabledProductsInResult = countMatchingElements(
        resultData.map(p => p.id),
        disabledProductIds,
    );
    const isBulkSelected = selectedProductIds.length === resultData.length - matchingDisabledProductsInResult;

    const stationsForFilter = [...Object.values(stations.byId), { id: -1, name: t("without_station") }];

    const categoriesFilter = useMenuCategories(true, showedCategoryTypes);

    const drawerRef = useRef(null);

    return (
        <>
            <Drawer
                open={isOpenProductSelector}
                onClose={() => dispatch(setOpenProductSelector(false))}
                backdrop={true}
                size="md"
                className="all-products__drawer"
                {...props}
            >
                <div className={"h-100"} ref={drawerRef}>
                    <PopupContainerProvider name="CDrawer" container={drawerRef}>
                        <Drawer.Header
                            test-id="product-selector-header"
                            style={{ display: "flex", flexDirection: "column" }}
                        >
                            <div className="d-flex justify-content-between align-items-center">
                                <h5>
                                    <TText tkey="select_product" /> /{" "}
                                    <span test-id="select_product_length">{resultData.length}</span>
                                </h5>
                                <div className="d-flex align-items-center" style={{ marginTop: "-10px" }}>
                                    <div
                                        className="d-flex align-items-center tw-cursor-pointer p-1 mr-3"
                                        style={{ background: Colors.LightGray, borderRadius: "6px" }}
                                    >
                                        <ColumnsManagerButton title={t("show_in_list")} cm={cm} />
                                    </div>
                                    <CIcon
                                        path={iWindowClose}
                                        onClick={() => dispatch(setOpenProductSelector(false))}
                                        size={1}
                                        className="tw-cursor-pointer"
                                    />
                                </div>
                            </div>
                            <div>
                                <InputGroup inside>
                                    <Input
                                        test-id="product-selector-search-input"
                                        autoFocus={true}
                                        onChange={onSearch}
                                        size="lg"
                                        value={searchText}
                                        placeholder={t("search")}
                                    />
                                    <InputGroup.Addon>
                                        {searchText ? (
                                            <CIcon
                                                className="tw-cursor-pointer"
                                                onClick={handleClearSearch}
                                                path={iClose}
                                            />
                                        ) : (
                                            <CIcon path={iSearch} />
                                        )}
                                    </InputGroup.Addon>
                                </InputGroup>
                            </div>
                            <div className="d-flex mt-2 align-items-center">
                                <div className="table-filter d-flex flex-fill flex-wrap tw-gap-1 ">
                                    <CheckPicker
                                        onClean={() => handleCleanFilter("type")}
                                        onSelect={value => handleAddFilter("type", value)}
                                        style={{ width: 90, maxWidth: 90 }}
                                        value={selectedFilters.type}
                                        placeholder={t("type")}
                                        data={showedTypes.map(type => ({
                                            value: type,
                                            label: t(type.toLowerCase() as Lowercase<ProductType>),
                                        }))}
                                    />
                                    <CCheckTreePicker
                                        valueKey={"id"}
                                        labelKey={"name"}
                                        cascade={"child"}
                                        data={categoriesFilter.tree}
                                        value={selectedFilters.category}
                                        groupBy={"type"}
                                        onChange={value => handleAddFilter("category", value)}
                                        placeholder={t("category")}
                                        defaultExpandAll={true}
                                        style={{ width: 120, maxWidth: 120 }}
                                        groupNodeTitle={nodeData => getLabel(nodeData.name)}
                                    />

                                    <CheckPicker
                                        // onSearch={}
                                        labelKey={"name"}
                                        valueKey={"id"}
                                        value={selectedFilters.station}
                                        onClean={() => handleCleanFilter("station")}
                                        onSelect={value => handleAddFilter("station", value)}
                                        placeholder={t("station")}
                                        style={{ width: 90, maxWidth: 90 }}
                                        data={stationsForFilter}
                                    />
                                    {hasFilter ? (
                                        <div
                                            className="tw-ml-4 tw-cursor-pointer tw-flex tw-items-center"
                                            onClick={() => clearAllFilter()}
                                        >
                                            <div
                                                className="tw-text-secondary tw-text-sm tw-border-b tw-border-b-border"
                                                style={{ borderBottomStyle: "solid" }}
                                            >
                                                <TText tkey="clear_all_filters" />
                                            </div>
                                        </div>
                                    ) : null}
                                </div>
                                <Whisper
                                    placement="bottomEnd"
                                    trigger="click"
                                    speaker={
                                        <Popover full className="shadow">
                                            {showedTypes.includes(ProductType.INGREDIENT) ? (
                                                <Dropdown.Menu
                                                    className="tw-cursor-pointer create-opt"
                                                    onClick={() => ingredientModal.setIsOpen(true)}
                                                >
                                                    <TText tkey="ingredient" />
                                                </Dropdown.Menu>
                                            ) : null}
                                            {showedTypes.includes(ProductType.GOODS) ? (
                                                <Dropdown.Menu
                                                    onClick={() => goodsModal.setIsOpen(true)}
                                                    className="tw-cursor-pointer create-opt"
                                                >
                                                    <TText tkey="goods" />
                                                </Dropdown.Menu>
                                            ) : null}
                                        </Popover>
                                    }
                                >
                                    <IconButton
                                        style={{ padding: "4px 8px", borderRadius: "8px" }}
                                        appearance="primary"
                                        icon={<CIcon path={iPlus} size={0.5} className="mr-1" />}
                                        placement="left"
                                    >
                                        <TText tkey="create" />
                                    </IconButton>
                                </Whisper>
                            </div>
                        </Drawer.Header>

                        <Drawer.Body>
                            {products.isLoading ? (
                                <div
                                    className="d-flex justify-content-center align-items-center flex-column"
                                    style={{ height: 600 }}
                                >
                                    <img
                                        src={LoadingSvg}
                                        alt="loading animation"
                                        style={{ width: 40, marginBottom: 30 }}
                                    />
                                    <Progress showInfo={false} percent={counter} status="active" />
                                </div>
                            ) : (
                                <DataTable
                                    dp={{ data: resultData } as any}
                                    columns={cm.columns}
                                    setBulkData={blkHandleSelect}
                                    isBulkMode={!isSingleSelect}
                                    isBulkSelected={isBulkSelected}
                                    bulkData={selectedProductIds}
                                    setAllBulkData={blkHandleSelectAll}
                                    disabledProductIds={disabledProductIds}
                                    virtualized={true}
                                    tableProps={{
                                        "test-id": "product-selector-table",
                                        affixHeader: false,
                                        onRowClick: (data: IProduct) => {
                                            if (disabledProductIds.includes(data.id) || data.deleted_at) {
                                                return;
                                            }
                                            if (isSingleSelect) {
                                                onProductSelectorSave?.([data.id]);
                                                dispatch(handleProductSelectAC(data.id));
                                                dispatch(setOpenProductSelector(false));
                                            } else {
                                                dispatch(handleProductSelectAC(data.id));
                                            }
                                        },
                                        onSortColumn: handleSortColumn,
                                        sortColumn,
                                        sortType,
                                        rowHeight: 40,
                                        headerHeight: 40,
                                        height: drawerHeight,
                                        loading: false,
                                    }}
                                />
                            )}
                        </Drawer.Body>

                        {!isSingleSelect ? (
                            <CProductsDrawerFooter onProductSelectorSave={onProductSelectorSave} />
                        ) : null}
                    </PopupContainerProvider>
                </div>
            </Drawer>

            <CreateIngredientModalState
                {...ingredientModal}
                // open={isOpenIngModal}
                // onChange={val => _.noop()}
                onSubmit={addCreatedProduct}
                // closeModal={() => setIsOpenIngModal(false)}
            />
            <CreateGoodsModalState
                {...goodsModal}
                // onChange={val => _.noop()}
                // open={isOpenGoodsModal}
                onSubmit={addCreatedProduct}
                // closeModal={() => setIsOpenGoodsModal(false)}
            />
        </>
    );
};

export default CProductsDrawer;
