import { useFormItemsContext } from "components/FormItems/FormItems";
import { BareNumberInputProps, BareTextInput, BareTextInputProps } from "core/components/form/BareTextInput";
import React, { FC } from "react";
import { RegisterOptions, useController, UseFormReturn } from "react-hook-form";

type BaseGridEditableCellProps = {
    rules?: RegisterOptions;
    trigger?: UseFormReturn["trigger"];
    index?: number;
    control?: UseFormReturn["control"];
};

export type GridEditableCellProps =
    | (Omit<BareNumberInputProps, "value"> & BaseGridEditableCellProps)
    | (Omit<BareTextInputProps, "value"> & BaseGridEditableCellProps);

export const GridInput: FC<GridEditableCellProps> = ({
    name,
    onChange,
    inputProps,
    rules,
    index,
    trigger,
    ...props
}) => {
    const { itemsFieldName } = useFormItemsContext();
    const ctrl = useController({ name, rules });

    const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (index === undefined || !["ArrowDown", "ArrowUp"].includes(e.key)) return;
        e.preventDefault();
        e.stopPropagation();

        const i = e.code === "ArrowDown" ? index + 1 : e.code === "ArrowUp" ? index - 1 : index;
        if (i !== index) {
            const nextName = name.replace(`${itemsFieldName}[${index}].`, `${itemsFieldName}[${i}].`);
            document.getElementsByName(nextName)?.[0]?.focus();
        }
    };

    const _inputProps = {
        ...inputProps,
        ...ctrl.field,
        onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => {
            inputProps?.onKeyDown?.(e);

            onKeyDown(e);
        },
        onChange: (e: any) => {
            onChange?.(e);
        },
    };

    return (
        <BareTextInput
            name={name}
            {...(props as any)}
            onChangeValue={val => {
                ctrl.field.onChange(val);
                props.onChangeValue?.(val as never);
            }}
            inputProps={_inputProps}
            errorMessage={ctrl.fieldState.error?.message}
        />
    );
};
