import _ from "lodash";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDate } from "hooks/useDate";
import { useLocation } from "react-router-dom";

interface IProps {
    id: number | undefined;
}

const GoodsStatsModifierTab: FCC<IProps> = ({ id }) => {
    const { start, end } = useDate();

    const location = useLocation();
    const urlParams = location.search?.replace("?", "");
    const queryString = JSON.parse(decodeURI(urlParams));
    const terminal_id = queryString?.filters?.terminal_id;

    const dp = useDataProvider({
        url: "report/modificator/time",
        filters: !_.isNil(id) ? { dish: ["dish_id", id], terminal: terminal_id } : {},
        params: {
            date: [start, end],
        },
        stop: _.isNil(id),
    });

    const cm = useColumns<any>(`drawer-report/modificator/time`, [
        { key: "name" },
        { key: "price", type: "money" },
        { key: "total_cash", type: "money" },
        { key: "total_count" },
    ]);

    return (
        <DataTable
            dp={dp}
            columns={cm.columns}
            noShadow
            tableProps={{ loading: !_.isNil(id) ? dp.isLoading : false }}
        />
    );
};

export default GoodsStatsModifierTab;
