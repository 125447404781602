import { Droppable } from "react-beautiful-dnd";

import React, { FC } from "react";
import ReceiptItemDraggable from "./ReceiptItemDraggable";
import { IReceiptItem, ReceiptItemType } from "../types";

interface ReceiptItemListProps {
    items: IReceiptItem[];
    receiptType: ReceiptItemType;
}
const ReceiptItemList: FC<ReceiptItemListProps> = ({ items, receiptType }) => {
    return (
        <Droppable droppableId={receiptType}>
            {provided => (
                <div ref={provided.innerRef} {...provided.droppableProps}>
                    {items.map((item, index) => (
                        <ReceiptItemDraggable receiptType={receiptType} key={item.uniqueId} item={item} index={index} />
                    ))}
                    {provided.placeholder}
                </div>
            )}
        </Droppable>
    );
};

export default ReceiptItemList;
