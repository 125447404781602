import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import CFormGroup from "components/Form/CFormGroup";
import CheckboxFormGroup from "components/Form/CheckboxFormGroup";
import UploaderFormGroup from "components/Form/FileUploadFormGroup";
import RemoteSelectFormGroup from "components/Form/RemoteSelectFormGroup";
import TextAreaFormGroup from "components/Form/TextAreaFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import GoodsSettingsByVenue from "components/GoodsSettingsByVenue";
import { useData } from "hooks/useData";
import _ from "lodash";
import React, { FC } from "react";
import { useParams } from "react-router-dom";
import { CheckPicker, IconButton } from "rsuite";
import { allergiesAndDietsData, CategoryTypeEnum } from "config/constants";
import CreateCategoryModal from "../../../../menu/categories/CreateCategoryModal";
import MenuCategorySelectFormGroup from "../../../../../components/Form/CategorySelectFormGroup/MenuCategorySelectFormGroup";
import { iCheckBoxChecked, iClose, iMore } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import { useDrawer } from "components/Drawers/useDrawer";
import ColorPicker from "components/Form/ColorPicker";
import TagPickerFormGroup from "components/Form/TagPickerFormGroup";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useAppSelector } from "hooks/useRedux";
import { translateUnit } from "lib/utils";
import { SelectGovCodeDrawer } from "components/Drawers/SelectGovCodeDrawer";
import { SelectGovPackageCodeDrawer } from "components/Drawers/SelectGovPackageCodeDrawer";
import { usePopupContainer } from "hooks/usePopupContainer";
import { useSetting } from "hooks/useSetting";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import { useIntegrationByName } from "providers/PackageProvider";

const GeneralTab: FC = () => {
    const params = useParams<any>();
    const returnsMaxAge = useSetting("returns_max_age")?.value;
    const brandData = useAppSelector(state => state.app.brandData);
    const form = useCFormContext();
    const sold_by_weight = form.watch("sold_by_weight");
    // const inventory_behavior = watch("inventory_behavior");
    const meta = form.watch("meta");
    const modal = useDrawer();
    const selectGovCodeDrawer = useDrawer();
    const selectGovPackageCodeDrawer = useDrawer();
    const auth = useAuth();
    const taxes = useData<IStorage[]>("finance/taxes", { params: { limit: 100 } }, auth.hasAbility(["tax_view"]));
    const { container } = usePopupContainer();
    const hasExciseIntegration = !!useIntegrationByName("excise");

    return (
        <div className="tw-p-4">
            <TextFormGroup name="name" errorPlacement="rightStart" focus={true} />
            <TextFormGroup
                name="barcode"
                compProps={{
                    onPressEnter: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                        e.key === "Enter" && e.preventDefault();
                    },
                    maxLength: sold_by_weight ? 5 : undefined,
                }}
                errorPlacement="topEnd"
            />
            <MenuCategorySelectFormGroup
                disabled={meta?.fromMenuConstruct}
                name="category_id"
                label={t("category")}
                types={[CategoryTypeEnum.PRODUCT]}
                modal={modal}
                compProps={{ cleanable: true }}
                revalidate={modal.dataId}
            />
            <RemoteSelectFormGroup
                searchLocal={true}
                remote={{ url: "station" }}
                name={"station_id"}
                compProps={{ cleanable: true }}
            />
            <ColorPicker
                name="color"
                label={t("display")}
                className={!_.isEmpty(form.getValues("images")) ? "d-none" : "d-block"}
            />
            <UploaderFormGroup
                name="images[0].uuid"
                removeMainKey={true}
                label={t("image")}
                // uploadUrl="media"
                buttonStyle={{ height: 90, width: 160 }}
                fileInfoStyle={{ height: 90, width: 160 }}
                compProps={{
                    action: "media",
                    multiple: false,
                    listType: "picture",
                    name: "media_file",
                }}
            />

            <TextFormGroup
                className="tw-text-secondary-200 tw-mb-2"
                name="low_stock"
                type={"number"}
                compProps={{
                    description: (
                        <CheckboxFormGroup
                            className={"tw-text-secondary-200"}
                            controlWrapperProps={{}}
                            name={"properties.should_notify_low_stock"}
                            label={t("notification")}
                        />
                    ),
                }}
            />

            <TextFormGroup
                name="price"
                type="money"
                label={t("sale_price")}
                compProps={{
                    // style: { maxWidth: 130, height: 40 },
                    className: "tw-p-2",
                    defaultValue: params?.id ? (form.getValues("price") ?? 0) : undefined,
                    description: t("cost_price") + " " + form.getValues("cost_price"),
                }}
                labelProps={{
                    className: "!tw-py-4 tw-font-bold ",
                }}
            />

            <h5 className="tw-m-0 tw-text-xl tw-mt-4">
                <TText tkey="details" />
            </h5>
            <hr className="tw-mt-1 tw-mb-4" />
            <MenuCategorySelectFormGroup
                name="accounting_category_id"
                label={t("accounting_category")}
                types={[CategoryTypeEnum.ACCOUNTING]}
                modal={modal}
                compProps={{ cleanable: true }}
                revalidate={modal.dataId}
                type={CategoryTypeEnum.ACCOUNTING}
                disabled={meta?.fromMenuConstruct}
            />
            <TextFormGroup
                name="max_age"
                type={"number"}
                label={t("product_return_date_range")}
                className="tw-input-lg"
                compProps={{
                    placeholder: `Default: ${returnsMaxAge}`,
                }}
            />
            <TextFormGroup
                name="cooking_time"
                errorPlacement="topEnd"
                type="number"
                compProps={{
                    style: {
                        width: 300,
                    },
                }}
            />
            <TextAreaFormGroup name="description" />
            <CFormGroup name="taxes">
                <CheckPicker
                    test-id="taxes"
                    data={_.map(taxes?.data, (t: any) => ({
                        value: t?.id,
                        label: `${t?.name} (${t?.rate}%)`,
                    }))}
                    container={() => container.current!}
                    value={_.map(form.getValues("taxes"), (t: any) => t?.id)}
                    onChange={tax =>
                        form.setValue(
                            "taxes",
                            _.intersectionWith(taxes?.data as any, tax, (a: any, b) => a.id === b),
                        )
                    }
                    onClean={() => form.setValue("taxes", [])}
                    placeholder={t("select", { name: t("tax") })}
                    style={{
                        width: 300,
                    }}
                />
            </CFormGroup>
            {/*<TextFormGroup name="gov_code" />*/}

            <div className={"tw-flex tw-gap-2 tw-mb-3 tw-items-start"}>
                <TextFormGroup name="gov_code" />
                {brandData.country === "UZ" && (
                    <IconButton onClick={() => selectGovCodeDrawer.setIsOpen(true)} icon={<CIcon path={iMore} />} />
                )}
            </div>

            {brandData.country === "UZ" && (
                <div className={"tw-flex tw-gap-2 tw-mb-3 tw-items-start"}>
                    <TextFormGroup name="properties.gov_package_code" label={t("gov_package_code")} />
                    <IconButton
                        onClick={() => selectGovPackageCodeDrawer.setIsOpen(true)}
                        icon={<CIcon path={iMore} />}
                    />
                </div>
            )}

            <TagPickerFormGroup
                name="meta.allergies"
                label={t("allergies")}
                data={allergiesAndDietsData.allergies}
                compProps={{
                    placeholder: t("select", { name: t("allergies") }),
                }}
            />
            <TagPickerFormGroup
                name="meta.diets"
                label={t("diets")}
                data={allergiesAndDietsData.diets}
                compProps={{
                    placeholder: t("select", { name: t("diets") }),
                }}
            />
            <TextFormGroup
                name="properties.measure"
                label={t("measure")}
                type="number"
                compProps={{
                    style: {
                        width: 300,
                    },
                }}
            />
            <RemoteSelectFormGroup
                name="properties.measure_unit_id"
                label={t("measure_unit")}
                searchLocal={true}
                remote={{
                    url: "unit",
                    filters: { categories: [["type", "!=", 4]] },
                    refineList: d => d?.map(translateUnit),
                }}
            />

            <ToggleFormGroup
                name="hidden"
                label={t("hide_on_terminal")}
                compProps={{
                    checkedChildren: <CIcon path={iCheckBoxChecked} style={{ marginBottom: "-3px" }} />,
                    unCheckedChildren: <CIcon path={iClose} style={{ marginBottom: "-3px" }} />,
                }}
            />

            <ToggleFormGroup
                name="ignore_service_charge"
                label={t("ignore_service_charge")}
                compProps={{
                    checkedChildren: <CIcon path={iCheckBoxChecked} style={{ marginBottom: "-3px" }} />,
                    unCheckedChildren: <CIcon path={iClose} style={{ marginBottom: "-3px" }} />,
                }}
            />
            <CheckboxFormGroup name={"giftable"} />
            <CheckboxFormGroup
                name={"meta.hasPortion"}
                label={t("portion")}
                compProps={{ disabled: form.getValues("modificator_groups")?.length }}
            />
            <CheckboxFormGroup name={"meta.is_set"} label={t("set")} />
            <CheckboxFormGroup name={"discountable"} label={t("not_allow_apply_discount")} reverseValue={true} />
            <CheckboxFormGroup
                name={"sold_by_weight"}
                compProps={{
                    disabled: !_.isNil(params.id) || form.getValues("modificator_groups")?.length,
                }}
                rules={{
                    deps: ["barcode"],
                }}
            />
            {hasExciseIntegration && (
                <CheckboxFormGroup name={"properties.hasExcise"} label={t("excise_branded_product")} />
            )}
            <GoodsSettingsByVenue />
            <CreateCategoryModal {...modal} mainForm={form} />
            {selectGovCodeDrawer.isOpen && <SelectGovCodeDrawer {...selectGovCodeDrawer} govCode={"gov_code"} />}
            {selectGovPackageCodeDrawer.isOpen && (
                <SelectGovPackageCodeDrawer
                    {...selectGovPackageCodeDrawer}
                    govCode={"gov_code"}
                    govPackageCode={"properties.gov_package_code"}
                />
            )}
        </div>
    );
};

export default GeneralTab;
