import _ from "lodash";
import * as Sentry from "@sentry/browser";
import { mergeDeepRight } from "lib/mergeDeepRight";

const arrayMetaParser = (s: ISetting) => {
    const value = typeof s.value === "string" ? (s.value ? JSON.parse(s.value) : {}) : s.value;
    if (Array.isArray(value)) {
        return {};
    }
    return value;
};
const customParsers: Record<string, any> = {
    brand_wifi_info: arrayMetaParser,
};

export function parseSettingValue(s: ISetting): ISetting {
    switch (s.type) {
        case "image":
            if (s.value?.print) {
                s.print_image = s.value.print;
            }
            s.value = s.value?.original;

            break;
        case "json":
            if (customParsers[s.name]) {
                s.value = customParsers[s.name](s);
            } else {
                s.value = typeof s.value === "string" ? (s.value ? JSON.parse(s.value) : []) : s.value;
            }
            break;
        case "multiple_select":
            //
            if (_.isString(s.value)) {
                s.value = JSON.parse(s.value);
            }
            break;
        case "text":
            //
            break;
        case "select":
            //
            break;
        case "string":
            //
            break;
        default:
            try {
                const value = Number(s.value);
                if (s.value !== "" && !isNaN(value)) {
                    s.value = value;
                }
            } catch (e) {
                Sentry.captureException(e);
                // ignore
            }
    }

    return s;
}

export function stringifySettingValue(s: ISetting): ISetting {
    switch (s.type) {
        case "json":
            s.value = JSON.stringify(s.value);
            break;
        //TODO: there must be select type
        case "multiple_select":
            s.value = JSON.stringify(s.value);
            break;
    }
    return s;
}

export const mergeSettingValue = (s: ISetting, value: any): any => {
    switch (s.type) {
        case "json":
            if (Array.isArray(value)) {
                return value;
            }

            return mergeDeepRight(s.value, value);
    }
    return value;
};
