import CModal from "components/Drawers/CModal";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import React, { FC, useEffect } from "react";
import { Button } from "rsuite";
import CForm from "components/Form/CForm/CForm";
import TextFormGroup from "components/Form/TextFormGroup";
import JsonView from "components/JsonView";
import { ModifierSchema } from "form-schemas/ModifierSchema";
import { withDrawer } from "hocs/withDrawer";
import { useCForm } from "hooks/useCForm";
import ModifierModalTypeField from "./modal/ModifierModalTypeField";
import { ICDrawerProps } from "components/Drawers/CDrawer";

export interface ModificationModalCompProps {
    defaultData?: any;
    cameFrom: number;
    groups?: any[];
}

const defaultData = {
    name: "",
    type: 0,
    min_select: 1,
    max_select: 1,
};

const ModificationModalState: FC<ICDrawerProps> = props => {
    const form = useCForm<IModifierGroup>({
        url: "product/modificator-group",
        dummyData: () => defaultData,
        clearForm: true,
        model2: ModifierSchema,
        redirectUrl: "manageModifiers/edit/:id",
        afterSubmit: ({ formValue, sentValue, receivedValue }) => {
            props.onClose?.({} as any);
        },
    });

    const type = form.form.watch("type");

    useEffect(() => {
        if (type === 0) {
            form.form.setValue("min_select", 1);
            form.form.setValue("max_select", 1);
        }
    }, [type]);

    return (
        <CModal testId="modifier-modal" {...props} backdrop="static" title={t("create_new_set")} size="xs">
            <CForm {...form} unCheckChanges={true}>
                <JsonView />
                <div className="tw-py-2.5">
                    <TextFormGroup
                        name="name"
                        compProps={{
                            "test-id": "modifier-name",
                        }}
                        placeholder={t("text", { name: t("name") })}
                        style={{ marginBottom: 20 }}
                    />

                    <ModifierModalTypeField cameFrom={0} defaultData={defaultData} />

                    <div className="tw-w-full tw-justify-end tw-flex">
                        <Button
                            onClick={form.onSave}
                            color="green"
                            loading={form.isLoading}
                            appearance="primary"
                            test-id="create-form-save-btn"
                        >
                            <TText tkey="create" />
                        </Button>
                    </div>
                </div>
            </CForm>
        </CModal>
    );
};

export default withDrawer(ModificationModalState);
