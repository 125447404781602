import { useTitle } from "components/Header/Title";
import DataTable from "../../../../components/DataTable/DataTable";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import React, { FC, useEffect, useState } from "react";
import { useIntegrations } from "providers/PackageProvider";
import { ModuleEnums } from "config/constants";
import { useHistory } from "react-router-dom";
import { t } from "lib/i18n";

interface IProductMapping {
    service_name: string;
    name: string;
}

const ProductMappingPage: FC = () => {
    const integrations = useIntegrations();
    const history = useHistory();
    const [data, setData] = useState<IProductMapping[]>([]);

    useEffect(() => {
        integrations.forEach(integration => {
            if (integration.service_name === ModuleEnums.GETIR) {
                data.push({
                    name: "GetirYemek",
                    service_name: integration.service_name,
                });
                setData(data);
            } else if (integration.service_name === ModuleEnums.TRENDYOL) {
                data.push({
                    name: "TrendyolYemek",
                    service_name: integration.service_name,
                });
                setData(data);
            }
        });
    }, [integrations]);

    const cm = [
        {
            key: "name",
            flexGrow: 3,
        },
        optionsColumn({
            dp: { data } as any,
            edit: (d: IProductMapping) => history.push(`/menu/mapping/edit/${d.service_name}`),
        }),
    ];

    return (
        <div className="h-100">
            {useTitle(t("product_mapping"))}

            <DataTable dp={{ data } as any} columns={cm} />
        </div>
    );
};

export default ProductMappingPage;
