import { TText } from "components/i18n/TText";
import CForm from "components/Form/CForm/CForm";
import CFormGroup from "components/Form/CFormGroup";
import { getLabel } from "components/Form/form-utils";
import { BUILDVERSION } from "config/main";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useCForm } from "hooks/useCForm";
import { FC, useEffect, useState } from "react";
import { Button, ButtonToolbar, Container, Form, Input, InputGroup, Message, toaster } from "rsuite";
import { depot } from "@cloposcom/libs";
import _ from "lodash";
import { useAppDispatch } from "hooks/useRedux";
import { setFirstTimeSession } from "redux/features/app";
import Ajax from "lib/Ajax";
import { BrandInput } from "./BrandInput";
import CIcon from "components/CIcon";
import { iAccount, iCode, iLock } from "assets/icons/Icons";
import { setCurrentVenue, setVenueList } from "redux/features/venue";
import MobileDetectModal from "components/MobileDetectModal";
import { Colors } from "config/colors";
import { LanguagePicker } from "components/i18n/LanguagePicker";
import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";
import { t } from "lib/i18n";
import { format } from "date-fns";

export const LoginScheme: ValidationSchema = {
    email: ruleset.combine([ruleset.required, ruleset.email]),
    password: ruleset.required,
};

const messages: Record<ResetState, LangKey> = {
    none: "" as LangKey,
    forgot: "email_to_search_your_account", //"Please enter your email to search for your account.",
    check: "check_email_code", //"Please check your email for a message with your code.",
    reset: "create_new_password", //"Create a new password that is at least 6 characters long. A strong password is combination of letters, numbers, and punctuation marks.",
};

const enum ResetState {
    NONE = "none",
    RESET = "reset",
    CHECK = "check",
    FORGOT = "forgot",
}

const LoginLayout: FCC = () => {
    const dispatch = useAppDispatch();

    const [resetState, setResetState] = useState(ResetState.NONE);
    const [msg, setMsg] = useState("");
    const [resetToken, setResetToken] = useState("");
    const [isBrandValid, setIsBrandValid] = useState(false);
    const auth = useAuth();
    const form = useCForm({
        url: "auth/login",
        model2: LoginScheme,
        redirectUrl: false,
        dummyData: () => ({
            email: "",
            password: "",
        }),
        afterSubmit: async ({ receivedValue }) => {
            const checkPermission = _.find(receivedValue.abilities, { name: "web_access" });
            if (_.isNil(checkPermission)) {
                toaster.push(
                    <Message type="error" showIcon closable>
                        You don't have permission
                    </Message>,
                );
                return false;
            }
            dispatch(setFirstTimeSession(receivedValue?.first_time));

            auth.setUser(receivedValue);
            dispatch(setVenueList(receivedValue?.venues));
            dispatch(setCurrentVenue(receivedValue?.venues?.[0].id));

            toaster.push(
                <Message type="success" showIcon closable>
                    Logged in successful!!!
                </Message>,
            );
            Ajax.setToken(receivedValue.token);
            depot.setItem("lastActiveVenue", receivedValue.venues?.[0].id);
            window.location.reload();
        },
    });

    useEffect(() => {
        const messageKey = messages[resetState];

        if (messageKey) {
            const message = t(messages[resetState]);
            setMsg(message);
        }
    }, [resetState]);

    const sendEmail = async () => {
        try {
            await Ajax.post({
                url: `auth/password/forgot`,
                data: {
                    email: form.form.getValues("email"),
                },
            });
            setResetState(ResetState.CHECK);
        } catch (e: any) {
            // toaster.push(
            //     <Message type="error" showIcon closable>
            //         {e.message}
            //     </Message>,
            // );
            _.each(e.field, (message, field: string) => {
                form.form.setError(field, { type: "validate", message: message.join(", ") });
            });
        }
    };

    const checkCode = async () => {
        setMsg("");
        try {
            const resp = await Ajax.post({
                url: `auth/password/check`,
                data: {
                    email: form.form.getValues("email"),
                    token: form.form.getValues("code"),
                },
            });

            setResetToken(resp?.data.reset_key);
            setResetState(ResetState.RESET);
        } catch (e: any) {
            // toaster.push(
            //     <Message type="error" showIcon closable>
            //         {e.message}
            //     </Message>,
            // );
            _.each(e.field, (message, field: string) => {
                form.form.setError(field, { type: "validate", message: message.join(", ") });
            });
        }
    };

    const resetPassword = async () => {
        try {
            await Ajax.post({
                url: `auth/password/reset`,
                data: {
                    password: form.form.getValues("password"),
                    reset_key: resetToken,
                },
            });

            setResetState(ResetState.NONE);
            setResetToken("");
            form.form.setValue("password", "");
            form.form.setValue("email", "");
            form.form.setValue("code", "");
        } catch (e: any) {
            // toaster.push(
            //     <Message type="error" showIcon closable>
            //         {e.message}
            //     </Message>,
            // );
            _.each(e.field, (message, field: string) => {
                form.form.setError(field, { type: "validate", message: message.join(", ") });
            });
        }
    };

    useEffect(() => {
        if (isBrandValid) {
            document.getElementsByName("email")[0]?.focus();
        }
    }, [isBrandValid]);

    // const isNone = resetState === ResetState.NONE;
    return (
        <Container id="LoginLayout" className="h-100 tw-flex tw-justify-center tw-items-center bg-light">
            <MobileDetectModal />
            <div className={"tw-fixed tw-top-2 tw-right-2"}>
                <LanguagePicker />
            </div>
            <div>
                <div className="bg-white rounded border shadow">
                    <div className={`text-center p-3 px-5 pt-5 mb-3`}>
                        <img src={"/img/clopos-logo-pos.png"} alt="Clopos Logo" />
                    </div>

                    <BrandInput setIsBrandValid={setIsBrandValid} />

                    <CForm
                        unCheckChanges={true}
                        noPanel
                        {...form}
                        formProps={{
                            layout: "vertical",
                            className: "border-0 p-5 m-0 bg-light",
                        }}
                    >
                        <div className="mb-3">
                            {msg && resetState !== "none" && <Message type="info" children={msg} />}
                        </div>

                        {(resetState === ResetState.NONE || resetState === ResetState.FORGOT) && (
                            <LoginInput name="email" icon={iAccount} disabled={!isBrandValid} />
                        )}
                        {resetState === ResetState.NONE && (
                            <LoginInput name="password" icon={iLock} type="password" disabled={!isBrandValid} />
                        )}
                        {resetState === ResetState.RESET && (
                            <LoginInput
                                name="password"
                                label="new_password"
                                icon={iLock}
                                type="password"
                                disabled={!isBrandValid}
                            />
                        )}
                        {resetState === ResetState.CHECK && <LoginInput name="code" icon={iCode} type="text" />}

                        <Form.Group>
                            <ButtonToolbar className={"d-flex justify-content-between"}>
                                {resetState === ResetState.NONE ? (
                                    <Button
                                        appearance="link"
                                        size="lg"
                                        onClick={() => setResetState(ResetState.FORGOT)}
                                    >
                                        <TText tkey="forgot_password" />
                                    </Button>
                                ) : (
                                    <Button
                                        loading={form.isLoading}
                                        appearance="default"
                                        size="lg"
                                        onClick={() => setResetState(ResetState.NONE)}
                                        className="rounded-sm px-5 py-3 text-uppercase"
                                    >
                                        <TText tkey="cancel" />
                                    </Button>
                                )}
                                {resetState === ResetState.CHECK && (
                                    <Button
                                        loading={form.isLoading}
                                        appearance="primary"
                                        size="lg"
                                        onClick={checkCode}
                                        className="tw-py-[15px] tw-px-[35px] rounded-sm px-5 py-3 text-uppercase"
                                    >
                                        <TText tkey="check" />
                                    </Button>
                                )}
                                {resetState === ResetState.NONE && (
                                    <Button
                                        type="submit"
                                        loading={form.isLoading}
                                        appearance="primary"
                                        size="lg"
                                        className="tw-py-[15px] tw-px-[35px] rounded-sm px-5 py-3 text-uppercase"
                                    >
                                        <TText tkey="sign_in" />
                                    </Button>
                                )}
                                {resetState === ResetState.RESET && (
                                    <Button
                                        loading={form.isLoading}
                                        appearance="primary"
                                        size="lg"
                                        onClick={resetPassword}
                                        className="tw-py-[15px] tw-px-[35px] rounded-sm px-5 py-3 text-uppercase"
                                    >
                                        <TText tkey="reset_password" />
                                    </Button>
                                )}
                                {resetState === ResetState.FORGOT && (
                                    <Button
                                        loading={form.isLoading}
                                        appearance="primary"
                                        size="lg"
                                        onClick={sendEmail}
                                        className="tw-py-[15px] tw-px-[35px] rounded-sm px-5 py-3 text-uppercase"
                                    >
                                        <TText tkey="send" />
                                    </Button>
                                )}
                            </ButtonToolbar>
                        </Form.Group>
                    </CForm>
                </div>
                <div className="text-center py-3" style={{ color: "#3337" }}>
                    <p className="">
                        {/* <sub className="h4 mr-1"></sub> */}
                        <span>&copy;</span>
                        {t("clopos") + " - " + format(new Date(), "yyyy")}
                    </p>
                    <p className="mb-0 ff-demi-bold">{BUILDVERSION}</p>
                </div>
            </div>
        </Container>
    );
};

export default LoginLayout;

const LoginInput: FC<{
    name: string;
    icon: string;
    type?: string;
    disabled?: boolean;
    label?: string;
}> = props => {
    const color = "#d4d9dd";

    return (
        <CFormGroup
            name={props?.name}
            label={getLabel(props.label || props.name)}
            controlWrapperProps={{ className: "w-100" }}
            compProps={{
                className: "rounded-sm py-3 pl-5",
                render: ({ field: { value, onChange } }: any) => {
                    return (
                        <InputGroup inside className="w-100" size="lg">
                            <InputGroup.Addon className="tw-flex tw-justify-center tw-items-center h-100 px-3 ml-1">
                                <CIcon path={props.icon} style={{ color, fontSize: 16 }} />
                            </InputGroup.Addon>
                            <Input
                                name={props.name}
                                disabled={props.disabled}
                                onChange={onChange}
                                type={props.type}
                                value={value}
                                placeholder={getLabel(props.label || props.name)}
                                className="rounded-sm py-3 pl-5"
                                style={{ borderColor: color }}
                                // {...field}
                            />
                        </InputGroup>
                    );
                },
            }}
            labelProps={{ style: { color: Colors.CharcoalBlue } }}
        />
    );
};
