import Money from "components/mini/Money";
import { EMPTY_DATA } from "config/main";
import _ from "lodash";
import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { Button } from "rsuite";
import CIcon from "../CIcon";
import { iCheckBoxChecked, iClose } from "assets/icons/Icons";
import { cn, getFormatedDate } from "lib/utils";
import { DateTimeCell } from "components/DataTable/cell-types/DateTimeCell";

type CellRenderer<T extends ITableColumnProps = ITableColumnPropsBase> = (col: T, d: any) => React.ReactNode;

const renderMoney: CellRenderer = (col, d) => {
    const val = getValue(col, d);
    return <Money value={val} symbol={false} />;
};

const renderDate: CellRenderer = (col, d) => {
    const val = new Date(getValue(col, d));
    return getFormatedDate(val.getTime(), false);
};

const renderDateTime: CellRenderer<ITableDateTimeColumnProps> = (col, d) => {
    return (
        <DateTimeCell
            date={getValue(col, d)}
            style={col.getStyle?.(d)}
            showSeconds={col.showSeconds}
            reverse={col.reverse}
            multiline={col.multiline}
            className={cn({
                "tw-items-start": col.align === "left",
                "tw-items-center": col.align === "center",
                "tw-items-end": col.align === "right",
            })}
        />
    );
};

const renderLink: CellRenderer<ITableLinkColumnProps> = (col, d) => {
    const val = getValue(col, d);

    if (!col.to) {
        // eslint-disable-next-line no-console
        console.error("Column type link must have attribute 'to': ", col.title);
        return val;
    }

    return <Link to={col.to?.replace(":id", d.id) ?? ""}>{val}</Link>;
};

const renderBool: CellRenderer = (col, d) => {
    const val = getValue(col, d);
    return val ? (
        <div
            className="d-flex align-items-center rounded-circle justify-content-center"
            style={{ background: "#D8FFE8", width: "16px", height: "16px" }}
        >
            <CIcon
                path={iCheckBoxChecked}
                test-id={col.key ? `${col.key as string}-checked` : undefined}
                style={{ color: "#12B76A" }}
                size={0.45}
            />
        </div>
    ) : (
        <div
            className="d-flex align-items-center rounded-circle justify-content-center"
            style={{ background: "#FFEDEC", width: "16px", height: "16px" }}
        >
            <CIcon
                path={iClose}
                test-id={col.key ? `${col.key as string}-unchecked` : undefined}
                style={{ color: "#F04438" }}
                size={0.45}
            />
        </div>
        // <Icon icon="close" className="text-danger" />
    );
};

function renderByType(col: ITableColumnProps, d: any) {
    switch (col.type) {
        case "money":
            return renderMoney(col, d);
        case "date":
            return renderDate(col, d);
        case "datetime":
            return renderDateTime(col, d);
        case "link":
            return renderLink(col, d);
        case "bool":
            return renderBool(col, d);
        default:
            return getValue(col, d);
    }
}

function getValue(col: ITableColumnProps, d: any) {
    if (col.getData) {
        return col.getData(d);
    }
    return _.get(d, col.key) ?? col.staticContent;
}

function wrapEl(val: any, col: ITableColumnProps, d: any) {
    const prefix = _.isFunction(col.prefix) ? col.prefix(d) : col.prefix;
    const suffix = _.isFunction(col.suffix) ? col.suffix(d) : col.suffix;
    if (prefix || suffix) {
        return (
            <div
                className={cn("tw-flex tw-flex-row tw-gap-2 tw-flex-1", {
                    "tw-justify-start": col.align === "left",
                    "tw-justify-center": col.align === "center",
                    "tw-justify-end": col.align === "right",
                })}
            >
                {prefix} {val} {suffix}
            </div>
        );
    }
    return val;
}

export const renderCell = (col: ITableColumnProps) => (d: any) => {
    if (col.Comp) {
        const Comp = col.Comp;
        return <Comp d={d} />;
    }
    if (col.render) {
        return wrapEl(col.render(d), col, d);
    }

    const val = getValue(col, d);

    if ((!val || col.hideData?.(d)) && col.type !== "bool") {
        return EMPTY_DATA;
    }

    let el = renderByType(col, d);
    el = wrapEl(renderByType(col, d), col, d);

    if (col.onClick) {
        el = (
            <Button appearance="link" className="p-0" onClick={e => col.onClick?.(d)}>
                {el}
            </Button>
        );
    }
    if (col.type === "datetime") {
        return el;
    }

    const style: CSSProperties = {
        display: "inline-block",
        ...col.getStyle?.(d),
    };

    return <div style={style}>{el}</div>;
};
