import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import { useDataProvider } from "components/DataTable/useDataProvider";
import Widget from "components/Widget";
import { productName } from "lib/data-utils";
import { translateUnitName } from "lib/utils";
import _ from "lodash";

import { Col, Grid, Row } from "rsuite";

interface IProps {
    date: [string, string];
}

const PopularProductsWidget: FCC<IProps> = props => {
    const dp = useDataProvider({
        url: "report/popular/sellable",
        with: ["unit"],
        params: { date: props.date },
    });

    const cm = useColumns<any>("report/popular/sellable", [
        { key: "product", render: item => productName(item) },
        {
            key: "orders",
            render: item => (
                <>
                    <span>{item.total_count} </span>
                    <i>{translateUnitName(item.unit)}</i>
                </>
            ),
        },
    ]);

    const dpData = _.cloneDeep(dp?.data || []);

    const half = Math.ceil(_.isNaN(dp?.data?.length) ? 0 : dp?.data?.length / 2);

    return (
        <Widget title={t("popular_dishes")} className="mb-3">
            <Grid fluid>
                <Row gutter={80}>
                    <Col md={12}>
                        <DataTable
                            noShadow
                            hidePages
                            dp={
                                {
                                    data: dpData?.splice(0, half) ?? [],
                                } as any
                            }
                            columns={cm.columns}
                        />
                    </Col>

                    <Col md={12}>
                        <DataTable
                            noShadow
                            hidePages
                            dp={
                                {
                                    data: dpData?.splice(-half) ?? [],
                                } as any
                            }
                            columns={cm.columns}
                        />
                    </Col>
                </Row>
            </Grid>
        </Widget>
    );
};

export default PopularProductsWidget;
