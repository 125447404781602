import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useEffect } from "react";
import * as Sentry from "@sentry/browser";
import { config } from "config/Config";

export function useSentryInitialize() {
    const auth = useAuth();
    useEffect(() => {
        try {
            if (!auth.isLoggedIn) {
                return;
            }
            const user = auth.user;
            Sentry.setContext("Account", {
                ID: user?.id,
                "Full name": user?.first_name + " " + user?.last_name,
                "E-mail": user?.email,
                Username: user?.username,
                Brand: config.brand,
            });
            Sentry.setTag("account_brand", config.brand);
            Sentry.setTag("account_id", user?.id);
            Sentry.setTag("account_username", user?.username);
            Sentry.setTag("account_fullName", user?.first_name + " " + user?.last_name);
            Sentry.setTag("account_email", user?.email);
        } catch (e) {
            // ignore
        }
        // eslint-disable-next-line
    }, [auth.isLoggedIn, config.brand]);
}
