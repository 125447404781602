import { t } from "lib/i18n";
import _ from "lodash";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import { translateUnitName } from "lib/utils";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDate } from "hooks/useDate";
import { useLocation } from "react-router-dom";

interface IProps {
    id: number | undefined;
}

const GoodsStatsIngredients: FCC<IProps> = ({ id }) => {
    const { start, end } = useDate();
    const url = `report/dish/stock/${id}`;

    const location = useLocation();
    const urlParams = location.search?.replace("?", "");
    const queryString = JSON.parse(decodeURI(urlParams));
    const terminal_id = queryString?.filters?.terminal_id;

    const dp = useDataProvider({
        url: url,
        with: ["product.unit"],
        filters: !_.isNil(id) ? { terminal: terminal_id } : {},
        params: {
            date: [start, end],
        },
        stop: _.isNil(id),
    });

    const cm = useColumns<any>(`report/dish/stock-drawer`, [
        { key: "product.name", title: t("name") },
        {
            key: "total_quantity",
            title: t("quantity"),
            render: (e: any) => `${e.total_quantity} ${translateUnitName(e.product?.unit)}`,
        },
        { key: "total_cost", type: "money" },
    ]);

    return (
        <DataTable
            dp={dp}
            columns={cm.columns}
            noShadow
            tableProps={{ loading: !_.isNil(id) ? dp.isLoading : false }}
        />
    );
};

export default GoodsStatsIngredients;
