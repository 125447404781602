import { depot } from "@cloposcom/libs";
import { config } from "config/Config";

// add venue id to url if not exist
// if (IS_NEW_URL && config.brand) {
//     window.history.replaceState({}, "", refineUrl(config.brand, config.venueId));
// }
depot.setPrefix("_client_" + config.brand + "_");

config.on("brandChange", brand => depot.setPrefix("_client_" + brand + "_"));

moveOldPrefix(config.brand);

function moveOldPrefix(brand: string) {
    const keys = ["token", "user", "subscription", "venueList"];
    keys.forEach(key => {
        const val = localStorage.getItem(`_client_${key}`);
        if (val) {
            localStorage.setItem(`_client_${brand}_${key}`, val);
            localStorage.removeItem(`_client_${key}`);
        }
    });
}
