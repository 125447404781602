import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";

import { renderProductName, translateUnitName } from "lib/utils";
import DataTable from "components/DataTable/DataTable";

interface IProps {
    ingredients: IIngredient[] | IPreparation[];
}

const PreparationIngredientTab: FCC<IProps> = ({ ingredients }) => {
    const cm = useColumns<any>("drawer-preparation-ingredient", [
        {
            title: t("name"),
            key: "name",
            flexGrow: 4,
            render: e => renderProductName(e.name, e.parent_name),
        },
        {
            title: t("gross"),
            key: "pivot.gross",
            align: "right",
            render: (d: IIngredient) => `${d.pivot?.gross || 0} ${translateUnitName(d.unit)}`,
        },
    ]);

    const dp = { data: ingredients } as any;

    return (
        <div className="p-3">
            <div className="mb-1">
                <TText tkey="usage_per_unit" />
            </div>
            <DataTable noShadow={true} dp={dp} columns={cm.columns} />
        </div>
    );
};

export default PreparationIngredientTab;
