import { t } from "lib/i18n";
import { getPreviousRange, prepareArrayWithDates } from "lib/chart-utils";
import SalesChartWidget from "pages/statistics/sales/SalesChartWidget";
import HoursChartWidget from "pages/statistics/sales/HoursChartWidget";
import PopularProductsWidget from "pages/statistics/sales/PopularProductsWidget";
import WeekDayChartWidget from "pages/statistics/sales/WeekDayChartWidget";
import React, { useState } from "react";
import { Col, Row } from "rsuite";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "../../../components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import _ from "lodash";
import DataTabWidgetCopy from "./DataTabWidget";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";
import { useData } from "hooks/useData";

export const tabs = [
    ["total_cash"],
    ["total_profit"],
    ["average_cash", "average_receipt"],
    ["total_receipt", "receipts"],
    ["total_guests"],
    ["average_time"],
] as const;

const SaleStatsPage: FCC = () => {
    const [index, setActiveIndex] = useState(0);
    const activeTab = tabs[index][0];

    const dp = useDataProvider({
        // url: "report/cash/date",
        url: "reports/date",
        useDates: true,
        useUrl: true,
        refineList: (arr, state) =>
            prepareArrayWithDates({
                arr,
                props: tabs.map(t => t[0]),
                range: state.params.date?.map((d: string) => new Date(d)) ?? [new Date(), new Date()],
            }),
    });
    const totals = useData("reports/date", {
        params: {
            total: 1,
            date: dp.params.all.date,
        },
    });

    const dpDate = dp.params.getValue("date");
    const dateRange = dpDate ? getPreviousRange(dpDate) : undefined;

    const dpOld = useDataProvider({
        // url: "report/cash/date",
        url: "reports/date",
        params: { date: dateRange },
        refineList: (arr, state) =>
            prepareArrayWithDates({
                arr,
                props: tabs.map(t => t[0]),
                range: state.params.date?.map((d: string) => new Date(d)) ?? [new Date(), new Date()],
            }),
        stop: _.isNil(dpDate),
    });

    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;

    return (
        <div className="h-100 p-2">
            {useTitle(t("sales"))}
            <HeaderRight date dp={dp} reload />

            <div className="mb-3 text-center d-flex justify-content-between">
                {tabs
                    .filter(tab => (isRestaurant ? true : tab[0] !== "total_guests"))
                    .map((tab, i) => {
                        return (
                            <DataTabWidgetCopy
                                key={tab[0]}
                                tab={tab}
                                isActive={tab[0] === activeTab}
                                data={dp?.data}
                                onClick={() => setActiveIndex(i)}
                                total={totals.data?.[tab[0]]}
                            />
                        );
                    })}
            </div>

            <SalesChartWidget tab={activeTab} dp={dp} dpOld={dpOld} />
            <Row>
                <Col md={16} className="mb-3">
                    <HoursChartWidget
                        betaVersion={true}
                        activeTab={activeTab}
                        tabs={tabs.map(t => t[0])}
                        date={dp.params.getValue("date")}
                    />
                </Col>
                <Col md={8} className="mb-3">
                    <WeekDayChartWidget dp={dp} activeTab={activeTab} />
                </Col>
            </Row>
            <PopularProductsWidget date={dp.params.getValue("date")} />
        </div>
    );
};

export default SaleStatsPage;
