import { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { Button, Input, Modal } from "rsuite";
import CModal from "../../../components/Drawers/CModal";
import CForm from "components/Form/CForm/CForm";
import TextFormGroup from "../../../components/Form/TextFormGroup";
import { useCForm } from "hooks/useCForm";

interface IChangeCashShiftBalanceModalProps extends ICDrawerProps {
    revalidate: () => void;
}

const ChangeCashShiftBalanceModal: FCC<IChangeCashShiftBalanceModalProps> = props => {
    const form = useCForm({
        methodOnSubmit: "put",
        redirectUrl: false,
        url: `finance/cash-shifts/${props.data?.id}/adjust-amounts`,
        afterSubmit: () => {
            props.revalidate();
        },
        prepareForSubmit: (data: any) => {
            const dataToSubmit: any = {};
            if (props.data?.type === "opening") {
                dataToSubmit["initial_amount"] = data.initial_amount;
            } else {
                dataToSubmit["closed_amount"] = data.closed_amount;
            }
            return dataToSubmit;
        },
    });

    return (
        <CForm onSave={form.onSave} form={form.form}>
            <CModal
                title={
                    props.data?.type === "opening"
                        ? t("cash_shift_change_opening_balance")
                        : t("cash_shift_change_closing_balance")
                }
                {...props}
                size="xs"
            >
                <Modal.Body test-id="change-cash-shift-balance-modal">
                    <span>
                        <TText tkey="cash_shift_previous_balance" />
                    </span>
                    <Input
                        test-id="cash-shift-previous-balance"
                        value={props.data?.type === "opening" ? props.data?.initial_amount : props.data?.closed_amount}
                        disabled={true}
                    />
                    <br />
                    <TextFormGroup
                        name={props.data?.type === "opening" ? "initial_amount" : "closed_amount"}
                        type="number"
                        label={t("amount")}
                        compProps={{ style: { width: "100%" }, "test-id": "cash-shift-balance" }}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        loading={form.isLoading}
                        appearance="primary"
                        color="green"
                        test-id="cash-shift-balance-save-button"
                        onClick={event => {
                            form.onSave();
                            props?.onClose?.(event);
                        }}
                    >
                        <TText tkey="save" />
                    </Button>
                </Modal.Footer>
            </CModal>
        </CForm>
    );
};

export default ChangeCashShiftBalanceModal;
