import { t } from "lib/i18n";

import { array, boolean, number, object, string } from "yup";

export const UserModel = () =>
    object().shape(
        {
            username: string().required(t("required")),
            role: string().required(t("required")),
            role_id: number(),
            salary: string().nullable().typeError(t("required")),
            mobile_number: string().nullable().typeError(t("required")),
            status: boolean().nullable().typeError(t("required")),

            venues: array().required(t("required")),
            web: boolean(),
            terminal: boolean(),

            card: string().when(["pin", "terminal"], {
                is: (pin: any, terminal: any) => (pin === undefined || pin === "") && terminal,
                then: string().required(t("pin_or_card")),
                otherwise: string().notRequired(),
            }),

            pin: string().when(["card", "terminal"], {
                is: (card: any, terminal: any) => (card === undefined || card === "") && terminal,
                then: string()
                    .matches(/^((?!_).)*$/, t("complete_pin"))
                    .length(4, t("pin_or_card"))
                    .required(t("required")),
                otherwise: string().notRequired(),
            }),

            email: string()
                .email(t("not_valid_email"))
                .when("web", {
                    is: true,
                    then: string().email(t("not_valid_email")).required(t("required")),
                    otherwise: string().notRequired(),
                }),

            password: string().when(["isCreate", "web"], {
                is: true,
                then: string().required(t("required")),
                otherwise: string().notRequired(),
            }),
            barcode: string(),
        },
        [["pin", "card"]],
    );

export const RoleModel = () =>
    object().shape({
        name: string().required(t("required")),
        abilities: array(),
    });

export const dummyRole = (): Partial<IRole> => {
    return {
        name: "",
        abilities: [],
    };
};

export const dummyUser = (): Partial<IUser> => {
    // const isWeb = roleAbility?.find((ability: IAbility) => ability.name === "web_access");
    // const isTerminal = roleAbility?.find((ability: IAbility) => ability.name === "terminal_access");

    const data: any = {
        username: "",
        role: "",
        role_id: null,
        email: "",
        password: "",
        pin: "",
        card: "",
        salary: 0,
        mobile_number: "",
        status: true,
        venues: [],
        roles: [],
        barcode: "",
    };
    // const {email, password, pin, card, ...finalVal} = datas;
    // if (isWeb && isTerminal) {
    //        return datas
    // }
    // else if (!!isWeb) {
    //     const {pin, card, ...isWebObj} = datas;
    //     return isWebObj
    // }
    // else if (!!isTerminal) {
    //     const {email, password, ...isTerminalObj} = datas;
    //     return isTerminalObj
    // }
    return data;
};
