import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useTitle } from "components/Header/Title";

import DataTable from "../../../components/DataTable/DataTable";
import HeaderRight from "../../../components/Header/HeaderRight";
import { exportFile } from "lib/export-table";
import nrInRoutings from "./nr-in-routings";
import { useLocation } from "react-router-dom";
import Money from "components/mini/Money";
import { useData } from "hooks/useData";
import _ from "lodash";
import { QuickLinks } from "components/Header/QuickLinks";
import FiltersContainer from "../../../components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "../../../components/DataTable/Filters/RemoteCheckListFilter";
import React from "react";
import MenuCategoryTreeFilter from "../../../components/DataTable/Filters/CategoryTreeFilter/MenuCategoryTreeFilter";
import { CategoryTypeEnum } from "config/constants";
import SourceFilter from "../../../components/DataTable/Filters/SourceFilter";

const NewReportsSalesTypePage: FCC = () => {
    const loc = useLocation();
    const url = "reports/sale-type";

    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        url,
        with: ["sale_type"],
    });

    const summary = useData<any>(url, {
        filters: _.values(dp.filters.all),
        params: {
            total: 1,
            date: dp.params.all.date,
        },
    });

    const cm = useColumns<any>("reports/sale-type", [
        {
            title: "ID",
            key: "sale_type.id",
            align: "left",
            flexGrow: 0.3,
        },
        {
            title: t("name"),
            key: "sale_type.name",
        },
        {
            title: t("total_cash"),
            key: "total_cash",
            numberRoundingLength: 2,
            summary: () => <Money value={summary.data?.[0]?.total_cash} />,
            type: "money",
        },
    ]);

    return (
        <div className="h-100">
            {useTitle(t("sales_type"), dp.total)}

            <QuickLinks inHeader={false} links={nrInRoutings(loc)} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <RemoteCheckListFilter
                        remote={{
                            url: "station",
                            refineList: (list: any[]) => {
                                list?.push({ id: null, name: t("without_station") });
                                return list;
                            },
                        }}
                        fields="receipt_station_id"
                        pickerProps={{ placeholder: t("station"), searchable: false }}
                    />
                    <MenuCategoryTreeFilter categoryTypes={[CategoryTypeEnum.PRODUCT]} />
                    <SourceFilter />
                </div>
            </FiltersContainer>
            <HeaderRight cm={cm} print export={() => exportFile(dp, cm.columns, t("sales_type"))} reload date dp={dp} />
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
            />
        </div>
    );
};

export default NewReportsSalesTypePage;
