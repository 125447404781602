import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { ICDrawerProps } from "components/Drawers/CDrawer";
import ReceiptDrawer from "components/Drawers/ReceiptDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import { t } from "lib/i18n";
import IdWithMisc from "components/mini/IdWithMisc";
import { useDate } from "hooks/useDate";
import _ from "lodash";

type ReceiptByProduct = {
    average_price: string;
    receipt_id: number;
    total: number;
    total_count: number;
};

interface IProps extends Omit<ICDrawerProps, "id"> {
    id: number | undefined;
    url: string;
    type: string;
}

const GoodsStatsReceipt: FCC<IProps> = ({ id, url, ...props }) => {
    const { start, end } = useDate();
    const rd = useDrawer();

    const dp = useDataProvider({
        url: url,
        params: {
            date: [start, end],
        },
    });

    const cm = useColumns<any>(`drawer-${url}`, [
        {
            key: "receipt_id",
            title: "#" + t("receipt"),
            render: (e: ReceiptByProduct) => (
                <IdWithMisc
                    id={e.receipt_id}
                    onClick={() => {
                        props.setIsClosable(false);
                        rd.setDataId(e.receipt_id);
                    }}
                />
            ),
        },
        { key: "total_count", title: t("quantity") },
        { key: "average_price", title: t("cost"), type: "money" },
        { key: "total", type: "money" },
    ]);

    return (
        <div>
            <DataTable
                dp={dp}
                columns={cm.columns}
                noShadow
                tableProps={{ loading: !_.isNil(id) ? dp.isLoading : false }}
            />
            <ReceiptDrawer
                {...rd}
                onClose={() => {
                    rd.onClose();
                    props.setIsClosable(true);
                }}
            />
        </div>
    );
};

export default GoodsStatsReceipt;
