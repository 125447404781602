import { t } from "lib/i18n";
import { array, boolean, lazy, number, object, string } from "yup";

import _ from "lodash";
import { InventoryBehavior } from "config/constants";

const ProductModel = () =>
    object().shape({
        deleted_at: string().nullable(),
        name: string().when("deleted_at", (deleted_at: any, schema: any) => {
            return deleted_at ? schema.notRequired() : schema.required(t("required"));
        }),
        // category_id: number().nullable(),
        // accounting_category_id: number().nullable(),
        category_id: lazy(
            value => (typeof value === "number" ? number().nullable() : string().nullable()), // for error with string
        ),
        accounting_category_id: lazy(value => (typeof value === "number" ? number().nullable() : string().nullable())),
        station_id: lazy(value => (typeof value === "number" ? number().nullable() : string().nullable())),
        // station_id: number().nullable(),
        color: string().default("1abc9c").nullable(),
        has_modifications: boolean(),
        description: string().nullable(),
        low_stock: number()
            .transform(value => (Number.isNaN(value) ? null : value))
            .nullable()
            .min(0, t("must_be_greater_0")),
    });

export const SellableModel = () =>
    object().shape({
        barcode: string().nullable(),
        cost_price: number(),
        price: string()
            .required(t("required"))
            .matches(/^[0-9]+([.][0-9]{0,2})?$/, t("must_be_2_decimal")),
        discountable: boolean(),
        color: string().required(t("required")),
    });

// export const variantModel = () =>
//     object().shape({
//         name: string().required(t("required")),
//         price: number().required(t("required")).typeError(t("required")),
//         cost_price: number(),
//         barcode: string().nullable(),
//     });

export const VariantModel = () =>
    object()
        .concat(
            ProductModel().test("unique", t("name_must_be_unique"), function validateUnique(currentItem: any) {
                const otherItems: any = this.parent.filter((person: any) => person !== currentItem);

                const isDuplicate: boolean = otherItems.some(
                    (otherPerson: any) => otherPerson.name?.toLowerCase() === currentItem.name?.toLowerCase(),
                );

                return isDuplicate ? this.createError({ path: `${this.path}.name` }) : true;
            }),
        )
        .concat(SellableModel());

export const GoodsModel = () =>
    object()
        .concat(ProductModel())
        .concat(
            object()
                .shape({
                    // modifications: array().of(variantModel()),
                    modifications: array().of(VariantModel()),
                    price: string().when("modifications", {
                        is: (modifications: any) => !_.isEmpty(modifications),
                        then: string()
                            .nullable()
                            .matches(/^[0-9]+([.][0-9]{0,4})?$/, t("must_be_4_decimal")),
                        otherwise: string()
                            .required(t("required"))
                            .matches(/^[0-9]+([.][0-9]{0,4})?$/, t("must_be_4_decimal")),
                    }),
                    cost_price: number(),
                    discountable: boolean(),
                    giftable: boolean(),
                    // color: string().required(t("required")),// ProductModel has this key
                    max_age: string()
                        .nullable()
                        .when("setting_max", (setting_max, schema) => {
                            return schema.test({
                                test: (max_age: number) => !max_age || setting_max >= max_age,
                                message: t("no_greater_than", { amount: setting_max }),
                            });
                        }),
                })
                .test("barcodeWeight", t("form_sold_by_weight_length_error"), function abc(currentItem: any) {
                    const { barcode, sold_by_weight } = currentItem;
                    if (barcode?.length === 0) {
                        return true;
                    } else {
                        if (sold_by_weight === 1) {
                            if (barcode?.length === 5) {
                                return true;
                            } else {
                                return this.createError({ path: "barcode" });
                            }
                        } else {
                            return true;
                        }
                    }
                }),
        );

export const TimerModel = () =>
    object()
        .concat(ProductModel())
        .concat(
            object().shape({
                discountable: boolean(),
                type: string(),
                price: number().required(t("required")).typeError(t("required")),
                // description: string().nullable(),
                setting: object().shape({
                    calculation_methods: boolean(),
                    interval: number().min(1, t("required")).required(t("required")).typeError(t("required")),
                    prices: array(
                        object({
                            price: number().transform(value => (Number.isNaN(value) ? 0 : value)),
                            from: number().transform(value => (Number.isNaN(value) ? 0 : value)),
                        }),
                    ).test("prices-check", t("not_fit_interval"), function () {
                        const { interval, prices } = this.parent;
                        for (let i = 0; i < prices?.length; i++) {
                            const currentPrice = prices[i];
                            const { from } = currentPrice;

                            if (+from % +interval !== 0 || +prices[i - 1]?.from >= +from || from < 0) {
                                return this.createError({
                                    path: `${this.path}[${i}].from`,
                                    message: t("not_fit_interval"),
                                });
                            }
                        }
                        return true;
                    }),
                }),
                giftable: boolean(),
            }),
        );

// const ModifcatorGroupItemModel = object().shape({
//     id: number().nullable().typeError(t("must_be_number")),
//     ingredient_id: number().nullable(),
//     name: string().required(t("required")),
//     price: number().required(t("required")).typeError(t("must_be_number")),
//     // cost_price: number().required(t("required")).typeError(t("required")),
//     brutto: number().required(t("required")).typeError(t("must_be_number")),
//     default_count: number()
//         .when("max_count", {
//             is: val => val !== null && val !== 0, // is multiple
//             then: number()
//                 .max(
//                     ref("max_count"),
//                     t("default_count_greater_than_max_count") || "Default count must be less than max count",
//                 )
//                 .min(
//                     ref("min_count"),
//                     t("default_count_less_than_min_count") || "Default count must be greater than min count",
//                 ),
//             otherwise: number().oneOf([0, 1]),
//         })
//         .required(t("required"))
//         .typeError(t("must_be_number")),
//     max_count: number()
//         .nullable()
//         // .when("type", {
//         //     is: 1,
//         //     then: number().required(t("required")).typeError(t("must_be_number")),
//         //     otherwise: number().notRequired().typeError(t("must_be_number")),
//         // })
//         .min(ref("min_count"), t("min_max_compare"))
//         .typeError(t("must_be_number")),
//     min_count: number()
//         .nullable()
//         // .when("type", {
//         //     is: 1,
//         //     then: number().required(t("required")).typeError(t("must_be_number")),
//         //     otherwise: number().notRequired().typeError(t("must_be_number")),
//         // })
//         .max(ref("max_count"), t("min_max_compare"))
//         .typeError(t("must_be_number")),
// });

// export const ModificatorGroupModel = object().shape({
//     name: string().required(t("required")),
//     type: number().required(t("required")),
//     min_select: number().required(t("required")).max(ref("max_select"), t("min_max_compare")).default(1),
//     max_select: number().required(t("required")).min(ref("min_select"), t("min_max_compare")).default(1),
//     modificators: array()
//         .of(ModifcatorGroupItemModel)
//         .test("min-check", t("not_fit_interval"), function (value: any) {
//             if (this.parent.type === 0) {
//                 // if type is single then ignore this check
//                 return true;
//             }
//             //check if min_count of value is greater than max_select
//             let totalMinCount = 0;
//             let totalDefaultCount = 0;
//             if (value?.length) {
//                 for (let i = 0; i < value?.length; i++) {
//                     const currentModifier = value[i];
//                     totalMinCount += Number(currentModifier?.min_count || 0);
//                     totalDefaultCount += Number(currentModifier?.default_count || 0);
//                     if (totalMinCount > this.parent?.max_select) {
//                         return this.createError({
//                             path: `${this.path}[${i}].min_count`,
//                             message: t("sum_of_min_values_greater_than_max_select"),
//                         });
//                     }
//                     if (totalDefaultCount > this.parent?.max_select) {
//                         return this.createError({
//                             path: `${this.path}[${i}].default_count`,
//                             message:
//                                 t("sum_of_default_values_greater_than_max_select") ||
//                                 "Sum of default values greater than max select",
//                         });
//                     }
//                 }
//             }

//             return true;
//         }),
// });

// const DishModel = object()
//     .concat(ProductModel())
//     .shape({
//         unit_id: number(),
//         type: string(),
//         cost_price: number(),
//         price: string()
//             .typeError(t("required"))
//             .required(t("fill_amount"))
//             .matches(/^[0-9]+([.][0-9]{0,4})?$/, t("must_be_4_decimal")),
//         modificator_groups: array().of(ModificatorGroupModel),
//         // color: string().nullable(),
//         giftable: boolean(),
//         max_age: string()
//             .nullable()
//             .when("setting_max", (setting_max, schema) => {
//                 return schema.test({
//                     test: (max_age: number) => !max_age || setting_max >= max_age,
//                     message: t("no_greater_than", { amount: setting_max }),
//                 });
//             }),
//         meta: object().shape({
//             availability: object().shape({
//                 schedules: object().shape({
//                     itemAvailability: array().of(
//                         object().shape({
//                             days: array().of(number()).required(t("required")).min(1, t("required")),
//                         }),
//                     ),
//                     itemVisibility: array().of(
//                         object().shape({
//                             days: array().of(number()).required(t("required")).min(1, t("required")),
//                         }),
//                     ),
//                 }),
//             }),
//         }),
//     })
//     .test("barcodeWeight", t("form_sold_by_weight_length_error"), function abc(currentItem: any) {
//         const { barcode, sold_by_weight } = currentItem;
//         if (barcode?.length === 0) {
//             return true;
//         } else {
//             if (sold_by_weight === 1) {
//                 if (barcode?.length === 5) {
//                     return true;
//                 } else {
//                     return this.createError({ path: "barcode" });
//                 }
//             } else {
//                 return true;
//             }
//         }
//     });

export const IngredientModel = () => ProductModel().clone();

export const IngredientBulkModel = () =>
    object({
        products: array().of(
            object({
                name: string().typeError(t("required")).required(t("required")),
                category_id: number().nullable(),
                unit_id: number().nullable(),
            }).test("unique", t("name_must_be_unique"), function validateUnique(currentItem: any) {
                const otherItems = this.parent.filter((person: any) => person !== currentItem);

                const isDuplicate = otherItems.some(
                    (otherPerson: any) => otherPerson.name.toLowerCase() === currentItem.name.toLowerCase(),
                );

                return isDuplicate ? this.createError({ path: `${this.path}.name` }) : true;
            }),
        ),
    });
export const dummyTimerPrice = (): Partial<ITimerPrice> => ({
    price: 0,
    from: 0,
});
export const dummyTimer = (): Partial<ITimerProduct> => ({
    name: "",
    category_id: null,
    accounting_category_id: null,
    color: "00bcd4",
    type: "TIMER",
    slug: "",
    position: 0,
    discountable: 1,
    payment_type: 1,
    giftable: 0,
    price: 0,
    description: "",
    emenu_hidden: 0,
    emenu_category_id: 0,
    emenu_position: 0,
    ignore_service_charge: 0,
    hidden: false,
    status: true,
    setting: {
        calculation_methods: 0,
        interval: 0,
        notification: 0,
        prices: [
            {
                price: 0,
                from: 0,
                notification: 0,
            },
        ],
    },
});

export const dummyGoods = (): Partial<IGoods> => ({
    id: 0,
    name: "",
    category_id: null,
    station_id: null,
    has_modifications: false,
    color: "00bcd4",
    cost_price: 0,
    discountable: 1,
    giftable: 0,
    price: 0,
    barcode: "",
    description: "",
    modifications: [],
    emenu_category_id: null,
    sold_by_weight: false,
    emenu_hidden: 0,
    hidden: 0,
    setting_max: 0,
    ignore_service_charge: false,
    inventory_behavior: 0,
    properties: {
        should_notify_low_stock: false,
    },
    low_stock: 0,
});

export const dummyVariant = (): Partial<IVariant> => ({
    name: "",
    price: 0,
    cost_price: 0,
    barcode: "",
});

export const dummyDish = (): Partial<IDish> => ({
    name: "",
    modificator_groups: [],
    discountable: 1,
    giftable: 0,
    sold_by_weight: false,
    // ingredients: [],
    // preparations: [],
    recipe: [],
    price: 0,
    color: "00bcd4",
    cost_price: 0,
    barcode: "",
    category_id: null,
    accounting_category_id: null,
    station_id: null,
    parent_id: null,
    description: "",
    cooking_time: null,
    emenu_hidden: 0,
    hidden: 0,
    ignore_service_charge: false,
    properties: {
        should_notify_low_stock: false,
    },
    low_stock: 0,
});

export const dummyIngredientData = (): Partial<IIngredient> => ({
    name: "",
    inventory_behavior: InventoryBehavior.MINUS_INGREDIENTS,
    packages: [],
    properties: {
        should_notify_low_stock: false,
    },
});

export const dummyIngredientItem = () => ({
    name: "",
    category_id: null,
    accounting_category_id: null,
    unit_id: null,
});

export const dummyIngredientBulk = () => ({
    products: [dummyIngredientItem()],
});
