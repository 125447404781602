import { t } from "lib/i18n";

import Ajax from "lib/Ajax";
import _ from "lodash";
import qs from "qs";
import { useEffect, useState } from "react";
import { SelectPicker } from "rsuite";
import * as yup from "yup";
import { useDebouncedSearch } from "hooks/useDebouncedSearch";

interface IProps {
    title?: string;
    placeholder?: string;
    searchPlaceholder?: string; // not supported yet
    remote: string;
    remoteQueries?: object;
    revalidate?: any;
    style?: any;
    containerStyle?: any;
    errorStyle?: any;
    itemLabel?: string;
    itemValue?: string;
    testId?: string;
    searchField?: string;
    pickerProps?: any; //PickerBaseProps<any>
    value?: any;
    onSelect?: (label: string, value: string) => void | null;
    refineData?: (data: any[]) => any;
    validation?: any;
    displayValidationWarning?: boolean;
    titleStyle?: any;
}

const warningBalloonStyle: any = {
    fontSize: 9,
    backgroundColor: "whitesmoke",
    color: "red",
    borderRadius: 5,
    padding: 3,
    fontWeight: 800,
    marginTop: 5,
    textAlign: "center",
};

/**
 * @deprecated dont use this component. its leftout from old supplies page
 */
const RemoteSelectFormState: FCC<IProps> = (props: any) => {
    const [data, setData] = useState<{ label: string; value: string; item: any }[]>([]);
    const [isLoading, setLoading] = useState(false);
    const [hasError, setHasError]: any = useState(null);

    const { searchInputValue, setSearchInputValue } = useDebouncedSearch(() => dataFetch(searchInputValue));

    const dataFetch = async (searchText = "") => {
        setLoading(true);
        const searchName = props.searchField || "name";
        const remoteQueries = props.remoteQueries ?? {};
        if (remoteQueries.filters) remoteQueries?.filters.unshift(undefined);
        const additionalParams =
            remoteQueries && Object.keys(remoteQueries)?.length > 0
                ? qs.stringify(remoteQueries ?? {}, {
                      arrayFormat: "indices",
                      encode: false,
                      encodeValuesOnly: true,
                      skipNulls: true,
                  })
                : "";
        const searchQuery = searchText ? `&filters[1][0]=${searchName}&filters[1][1]=${searchText}&` : "";
        const res = await Ajax.get({
            url: props.remote + `?page=1&limit=50&${searchQuery}${additionalParams}`,
        }).catch(() => setLoading(false));
        if (res?.success) {
            const dummyData = props?.refineData ? props?.refineData(res?.data) : res?.data;
            const refinedDummy = dummyData?.map((d: any) => ({
                label: d[props?.itemLabel],
                value: d[props?.itemValue],
                item: d,
            }));
            if (data.length > 0) {
                setData(_.values(_.merge(_.keyBy(data, "value"), _.keyBy(refinedDummy, "value"))));
            } else {
                setData(refinedDummy);
            }
        }
        setLoading(false);
    };

    useEffect(() => {
        dataFetch();
    }, [props.revalidate]);

    useEffect(() => {
        if (props?.validation) {
            let vObjModel = {};
            for (const x in props?.validation) {
                vObjModel = Object.assign(vObjModel, { [props?.validation[x]?.key]: props?.validation[x]?.model });
            }
            const model = yup.object(vObjModel);
            try {
                let vObjState = {};
                for (const x in props?.validation) {
                    vObjState = Object.assign(vObjState, { [props?.validation[x]?.key]: props?.validation[x]?.value });
                }
                const hErr: any = model.validateSync(vObjState);
                setHasError(hErr);
            } catch (ex: any) {
                setHasError(ex);
            }
        }
    }, [props?.validation]);

    const testId = props?.testId ?? "remote-select-picker-state";

    return (
        <div style={props?.containerStyle}>
            {props?.title && (
                <p test-id={`${testId}-label`} style={{ marginBottom: 4, ...props.titleStyle }}>
                    {props?.title}
                </p>
            )}
            <SelectPicker
                test-id={props?.testId ?? `${testId}-picker`}
                placeholder={props?.placeholder ? t("select", { name: props?.placeholder }) : undefined}
                loading={isLoading}
                onSearch={(val: string) => setSearchInputValue(val)}
                onSelect={props?.onSelect}
                value={props?.value}
                onClean={() => props?.onSelect("")}
                onClose={dataFetch}
                data={data}
                style={{ width: 300, ...props?.style }}
                {...props?.pickerProps}
            />
            {hasError?.message &&
                (typeof props?.displayValidationWarning === "undefined" ||
                    props?.displayValidationWarning === true) && (
                    <p
                        test-id={`remote-select-picker-state-error`}
                        style={{ ...props?.errorStyle, ...warningBalloonStyle }}
                    >
                        {hasError?.message}
                    </p>
                )}
        </div>
    );
};

export default RemoteSelectFormState;
