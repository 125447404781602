import DataTable from "components/DataTable/DataTable";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { useTableHeight } from "hooks/useTableHeight";
import { toFixed, translateUnitName } from "lib/utils";
import { iSpinner } from "assets/icons/Icons";
import { FC, useState } from "react";
import CIcon from "components/CIcon";
import Money from "components/mini/Money";
import { useData } from "hooks/useData";
import { productName } from "lib/data-utils";

interface IProps extends ICDrawerProps {
    op?: IOperation;
    title?: string;
    cols?: string[];
}

const getColumns = (cols?: string[]) => {
    const columns: ITableColumnProps[] = [
        {
            key: "name",
            title: t("product"),
            align: "left",
            render: d => productName(d),
        },
        {
            key: "quantity",
            title: t("qty"),
            align: "right",
            render: d => parseFloat(toFixed(d.pivot?.quantity, 4)),
            suffix: (d: any) => <i>{translateUnitName(d.unit, "")}</i>,
        },
        {
            key: "price",
            title: t("price"),
            align: "right",
            render: d => <Money value={d?.cost_price} />,
        },
        {
            key: "total",
            align: "right",
            render: d => <Money value={parseFloat(toFixed(d.pivot?.quantity, 4)) * d?.cost_price} />,
        },
    ];
    return cols ? columns.filter(c => cols.includes(c.key as string)) : columns;
};

const ReturnedSuppliesProductsDrawer: FC<IProps> = props => {
    const height = useTableHeight(140);
    const [stockOperations] = useState<IStockOperation[]>([]);

    const r: any = useData(!!props.dataId && `/product/supply-return/${props?.dataId}`, {
        with: ["products", "products.unit"],
    });

    return (
        <CDrawer
            print
            title={t("returned_supplies") + " - #" + props.dataId}
            {...props}
            contentHeight={{
                original: height,
                printHeight: stockOperations.length * 50 < height ? height : stockOperations.length * 50,
            }}
        >
            {r?.isLoading ? (
                <CIcon path={iSpinner} spin className="m-3" />
            ) : (
                <DataTable
                    hidePages
                    noShadow
                    columns={getColumns(props?.cols)}
                    dp={{ data: r?.data?.products } as any}
                    tableProps={{
                        id: "print-table",
                        headerHeight: 49,
                        autoHeight: false,
                        height,
                    }}
                />
            )}
        </CDrawer>
    );
};

export default ReturnedSuppliesProductsDrawer;
