import { ICDrawerProps } from "components/Drawers/CDrawer";
import TextFormGroup from "components/Form/TextFormGroup";
import { t } from "lib/i18n";
import { useCForm } from "hooks/useCForm";
import { useAppDispatch } from "hooks/useRedux";
import { ruleset } from "lib/validation-rules";
import _ from "lodash";
import { useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { menuCategoriesSlice } from "redux/features/model/slice";
import CFormModal from "../../../components/Drawers/CFormModal";
import MenuCategorySelectFormGroup from "../../../components/Form/CategorySelectFormGroup/MenuCategorySelectFormGroup";
import ColorPicker from "../../../components/Form/ColorPicker";
import UploaderFormGroup from "../../../components/Form/FileUploadFormGroup";
import JsonView from "../../../components/JsonView";
import { CategoryTypeEnum } from "config/constants";
import { isUrl } from "lib/utils";
import { dummyCategory } from "models/CategoryModel";

interface IProps extends ICDrawerProps {
    mainForm?: UseFormReturn;
    type?: string | null;
    filters?: any;
    with?: string[] | null;
}

const CreateCategoryModal: FCC<IProps> = props => {
    const dispatch = useAppDispatch();
    const type = props.data?.type;
    const form = useCForm({
        url: "category",
        // model: CategoryModel(),
        redirectUrl: false,
        isModal: true,
        id: props.dataId || undefined,
        dummyData: dummyCategory,
        prepareForSubmit: (d: any) => {
            d.type = d.type || props?.type || CategoryTypeEnum.PRODUCT;
            const images = d.images?.length ? d.images[0].uuid : "";
            return _.omit(d, isUrl(images) ? ["image", "images"] : ["image"]);
        },
        refine: (d: any) => {
            d = _.pickBy(d, _.identity);
            return !_.isEmpty(d.media)
                ? {
                      images: [{ uuid: d.media[0]?.urls?.thumb }],
                      ...d,
                  }
                : d;
        },
    });

    useEffect(() => {
        if (props.data?.type) {
            form.form.setValue("name", "");
            form.form.setValue("type", props.data?.type);
        }
    }, [props.data?.type]);

    return (
        <CFormModal
            {...props}
            receivedValue={(d: any, name: any) => {
                props.mainForm?.setValue(name, d.id);
            }}
            revalidate={() => {
                dispatch(menuCategoriesSlice.fetchData(""));
            }}
            form={form}
            formProps={{ layout: "horizontal" }}
            title={t("create_by_name", { name: t("category") })}
            size="sm"
        >
            <TextFormGroup
                name="name"
                errorPlacement="rightEnd"
                compProps={{ "test-id": "category-name" }}
                focus={true}
                rules={{
                    validate: {
                        required: ruleset.required,
                    },
                }}
            />

            {type === CategoryTypeEnum.ACCOUNTING && (
                <TextFormGroup name="code" label={t("accounting_code")} errorPlacement="rightEnd" focus={true} />
            )}

            {(type !== CategoryTypeEnum.ACCOUNTING || props.type) && (
                <MenuCategorySelectFormGroup
                    name="parent_id"
                    label={t("category")}
                    types={[CategoryTypeEnum.PRODUCT]}
                />
            )}
            {(type !== CategoryTypeEnum.ACCOUNTING || props.type) && (
                <ColorPicker
                    name="color"
                    label={t("display")}
                    className={!_.isEmpty(form.form.getValues("images")) ? "d-none" : "d-block"}
                />
            )}

            {(type !== CategoryTypeEnum.ACCOUNTING || props.type) && (
                <UploaderFormGroup
                    name="images[0].uuid"
                    removeMainKey={true}
                    label={t("image")}
                    // uploadUrl={"media"}
                    compProps={{
                        action: "media",
                        multiple: false,
                        listType: "picture",
                        name: "media_file",
                    }}
                />
            )}
            <JsonView />
        </CFormModal>
    );
};

export default CreateCategoryModal;
