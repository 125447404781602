import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { convertToSlug } from "lib/convertToSlug";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

export interface IProductsFilters {
    category: number[];
    station: number[];
    type: string[];
}

export type ProductsFilterKey = keyof IProductsFilters;

interface ProductsAndCategoriesState {
    products: {
        byId: Record<number, IProduct>;
        allIds: number[];
        isLoading: boolean;
    };
    categories: {
        byId: Record<number, ICategory>;
        INGREDIENT: Record<number, ICategory>;
        PRODUCT: Record<number, ICategory>;
    };
    stations: {
        byId: Record<number, IStation>;
        allIds: number[];
    };
    units: {
        byId: Record<number, IUnit>;
        allIds: number[];
    };
}

interface ISetProductsCallback {
    (products: ProductsAndCategoriesState["products"]): void;
}

const initialState: ProductsAndCategoriesState = {
    products: {
        byId: {},
        allIds: [],
        isLoading: false,
    },
    categories: {
        byId: {},
        INGREDIENT: {},
        PRODUCT: {},
    },
    stations: {
        byId: {},
        allIds: [],
    },
    units: {
        byId: {},
        allIds: [],
    },
};

const dataSlice = createSlice({
    name: ReduxSlicesNames.DATA,
    initialState,

    reducers: {
        setProducts: (
            state,
            action: PayloadAction<{
                byId?: Record<number, IProduct>;
                allIds?: number[];
            }>,
        ) => {
            if (action.payload.byId) {
                for (const id in action.payload.byId) {
                    action.payload.byId[id].slug = convertToSlug(action.payload.byId[id].name);
                }

                state.products.byId = { ...state.products.byId, ...action.payload.byId };
            }

            if (action.payload.allIds) {
                state.products.allIds = [...new Set([...state.products.allIds, ...action.payload.allIds])];
            }
        },
        setProductsCallback: (state, action: PayloadAction<ISetProductsCallback>) => action.payload(state.products),
        removeProduct: (state, action: PayloadAction<number>) => {
            delete state.products.byId[action.payload];

            const productIdIndex = state.products.allIds.indexOf(action.payload);

            if (productIdIndex !== -1) {
                state.products.allIds.splice(productIdIndex, 1);
            }
        },
        setCategories: (
            state,
            action: PayloadAction<{
                byId: Record<number, ICategory>;
                INGREDIENT: Record<number, ICategory>;
                PRODUCT: Record<number, ICategory>;
            }>,
        ) => {
            state.categories = action.payload;
        },
        setStations: (state, action: PayloadAction<{ byId: Record<number, IStation>; allIds: number[] }>) => {
            state.stations = action.payload;
        },
        setAllUnits: (state, action: PayloadAction<{ byId: Record<number, IUnit>; allIds: number[] }>) => {
            state.units = action.payload;
        },
        setProductsLoading: (state, action: PayloadAction<boolean>) => {
            state.products.isLoading = action.payload;
        },
    },
});

export const {
    setProducts,
    removeProduct,
    setProductsLoading,
    setCategories,
    setStations,
    setAllUnits,
    setProductsCallback,
} = dataSlice.actions;
export default dataSlice.reducer;
