import { getLabel } from "components/Form/form-utils";
import { FIListItemProps } from "components/FormItems/FormItemsList";
import { GridButtonRemove } from "components/NewForm/Grid/GridButtonRemove";
import { GridInput } from "components/NewForm/Grid/GridInput";
import { GridSelect } from "components/NewForm/Grid/GridSelect";
import { usePaymentMethods } from "hooks/data-hooks/usePaymentMethods";
import { useCurrency } from "hooks/useCurrency";

interface IProps extends FIListItemProps<PaymentItemField> {}

export interface PaymentItemField {
    id: number;
    name: string;
    amount: number;
}

const PaymentItem: FCC<IProps> = ({ field, index, remove, fields, form }) => {
    const itemIds = fields.map(f => f.id);
    const pm = usePaymentMethods();
    const currency = useCurrency();

    const isItemDisabled = (d: any) => {
        return d.id === 3 || d.id === 4 || itemIds.includes(d.id!) || !d.status;
    };

    const removeItem = (i: number) => remove(i);

    return (
        <>
            <GridSelect
                name={`payment_methods[${index}].id`}
                options={
                    pm.list.filter(item => !item.deleted_at).map(item => ({ value: item.id!, label: item.name })) ?? []
                }
                onChange={id => {
                    // form.setValue(`payment_methods[${index}].id`, id);
                    const method = pm.list.find(pmD => pmD.id === id);
                    form.setValue(`payment_methods[${index}].name`, method ? getLabel(method.name) : "");
                }}
                selectProps={{
                    disabled: !isItemDisabled(field),
                    disabledItemValues: pm.list.filter(isItemDisabled).map(i => i.id),
                    cleanable: false,
                    creatable: false,
                }}
            />
            <GridInput
                name={`payment_methods[${index}].amount`}
                index={index}
                type="number"
                decimals={[2, 2]}
                postfix={currency}
            />
            <GridButtonRemove disabled={fields.length < 2} onClick={() => removeItem(index)} />
        </>
    );
};

export default PaymentItem;
