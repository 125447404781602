import { t } from "lib/i18n";
import { prepareArrayWithDates } from "lib/chart-utils";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "../../../components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import PageViewChartWidget from "./PageViewChartWidget";

export const tabs = [["count"]] as const;

const EMenuStatsPage: FCC = () => {
    const dpss = useDataProvider({
        url: "report/page-views/emenu",
        useUrl: true,
        useDates: true,
        refineList: (arr: any, state) =>
            prepareArrayWithDates({
                arr: arr?.result as any,
                props: tabs.map(t => t[0]),
                range: state.params.date?.map((d: string) => new Date(d)) ?? [new Date(), new Date()],
            }),
    });

    return (
        <div className="h-100">
            {useTitle(t("emenu"))}
            <HeaderRight date dp={dpss} reload />

            <PageViewChartWidget dp={dpss} />
        </div>
    );
};

export default EMenuStatsPage;
