import { t } from "lib/i18n";
import classNames from "classnames";
import LoadingAnimation from "components/LoadingAnimation";
import React, { FC, ReactNode, useEffect } from "react";
import { UseFormReturn } from "react-hook-form";
import { Form, FormProps } from "rsuite";
import { Prompt } from "react-router-dom";
import { Colors } from "config/colors";
import CIcon from "components/CIcon";
import { iSpinner } from "assets/icons/Icons";
import { CFormProvider } from "components/Form/CForm/CFormProvider";
import { DebugModal } from "components/DebugModal/DebugModal";

type FormPropsExtended = FormProps & { paddingY?: number; "test-id"?: string };

export interface ICFormProps {
    disabled?: boolean;
    form: UseFormReturn;
    onSave: () => void;
    formProps?: FormPropsExtended;
    noShadow?: boolean;
    noPanel?: boolean;
    unCheckChanges?: boolean;
    isLoading?: boolean;
    children: ReactNode;
}

const CForm: FC<ICFormProps> = props => {
    useEffect(() => {
        if (!props.unCheckChanges) {
            const handleBeforeUnload = (e: any) => {
                e.preventDefault();
                e.returnValue = "You have unsaved changes! Are you sure you want to leave?";
            };
            if (props.form.formState.isDirty) {
                window.addEventListener("beforeunload", handleBeforeUnload, true);
                return () => {
                    window.removeEventListener("beforeunload", handleBeforeUnload, true);
                };
            } else {
                window.removeEventListener("beforeunload", handleBeforeUnload, true);
            }
        }
    }, [props.form.formState.isDirty]);

    const onSubmit = () => props.onSave();

    const formProps: FormProps & { "test-id": string } = {
        layout: "horizontal",
        onSubmit,
        ...props.formProps,
        "test-id": "form",
        className: "form position-relative clopos-scroll " + (props.formProps?.className ?? ""),
        style: {
            // overflowX: "auto",
            ...props.formProps?.style,
        },
    };

    // console.log("props.isLoading", props.isLoading, props.form.formState.isLoading);

    const form = () => {
        if (props.isLoading || props.form.formState.isLoading) {
            return (
                <div className="tw-w-full tw-h-full tw-flex tw-flex-1 tw-justify-center tw-items-center tw-min-h-60">
                    <CIcon path={iSpinner} spin size={1} />
                </div>
            );
        }
        return (
            <Form {...formProps}>
                {props.children}
                {/* hidden submit button ro submit on enter keypress */}
                <button
                    type="submit"
                    style={{ width: 0, height: 0, border: "none", padding: 0, margin: 0, display: "none" }}
                />
                {!props.unCheckChanges ? (
                    <Prompt when={props.form.formState.isDirty} message={t("unsaved_changes_notice")} />
                ) : null}
                {props.isLoading && (
                    <div
                        style={{
                            position: "absolute",
                            display: "flex",
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            backgroundColor: Colors.WhiteGray,
                            zIndex: 10,
                        }}
                    >
                        <LoadingAnimation className="w-100 tw-flex tw-justify-center tw-items-center" />
                    </div>
                )}
            </Form>
        );
    };

    return (
        <CFormProvider {...props}>
            {props.noPanel ? (
                form()
            ) : (
                <div
                    className={classNames(`tw-overflow-visible bg-white form-p py-${props.formProps?.paddingY ?? 0} `)}
                >
                    {form()}
                </div>
            )}
            <DebugModal />
        </CFormProvider>
    );
};

export default CForm;
