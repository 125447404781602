import { t } from "lib/i18n";
import React from "react";
import { useCForm } from "hooks/useCForm";
import TextFormGroup from "components/Form/TextFormGroup";
import { useFormTitle } from "components/Header/Title";
import HeaderRight from "components/Header/HeaderRight";
import CForm from "components/Form/CForm/CForm";
import JsonView from "components/JsonView";
import SelectFormGroup from "components/Form/SelectFormGroup";
import { ServiceChargeSchema } from "form-schemas/ServiceChargeSchema";
import { ServiceCharceCalculationMethodEnum, ServiceChargeFixedTypes, ServiceChargeTypeEnum } from "config/constants";
import { serviceChargeDummyData } from "./ServiceChargeDummyData";
import RadioFormGroup from "components/Form/RadioFormGroup";
import { CheckPicker, Radio } from "rsuite";
import { TText } from "components/i18n/TText";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import _ from "lodash";
import CFormGroup from "components/Form/CFormGroup";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useData } from "hooks/useData";
import { useCurrency } from "hooks/useCurrency";

const types = Object.values(ServiceChargeTypeEnum).map(type => ({
    id: type,
    name: t(type.toLowerCase() as Lowercase<ServiceChargeTypeEnum>),
}));

const calculationMethods = [
    {
        id: ServiceCharceCalculationMethodEnum.PRE_DISCOUNT,
        name: "pre_discount",
    },
    {
        id: ServiceCharceCalculationMethodEnum.AFTER_DISCOUNT,
        name: "after_discount",
    },
] as const;

const ServiceChargeCreateEditPage: FCC = () => {
    const form = useCForm<IServiceCharge>({
        url: "product/serviceCharges",
        model2: ServiceChargeSchema,
        dummyData: serviceChargeDummyData,
        with: ["taxes:id"],
        rhfOptions: {
            reValidateMode: "onChange",
        },
        prepareForSubmit: (data: IServiceCharge) => {
            return _.omit(data, ["setting", "image"]);
        },
    });

    const auth = useAuth();
    const taxes = useData<IStorage[]>("finance/taxes", { params: { limit: 100 } }, auth.hasAbility(["tax_view"]));

    const type = form.form.getValues("properties.type");
    const currency = useCurrency();

    return (
        <div className="p-3">
            {useFormTitle(form.id, "", form.form.getValues("name"))}
            <HeaderRight formSubmit={form} />
            <CForm form={form.form} onSave={form.onSave}>
                <ToggleFormGroup name="status" />
                <TextFormGroup name="name" errorPlacement="rightEnd" focus={true} />

                <SelectFormGroup name="properties.type" data={types} label={t("type")} />

                {ServiceChargeFixedTypes.includes(type) ? (
                    <TextFormGroup
                        name="price"
                        type="number"
                        label={t("value")}
                        postFix={type === ServiceChargeTypeEnum.FIXED_AMOUNT ? currency : "%"}
                    />
                ) : null}

                <CFormGroup name="taxes">
                    <CheckPicker
                        test-id="taxes"
                        data={_.map(taxes?.data, (t: any) => ({
                            value: t?.id,
                            label: `${t?.name} (${t?.rate}%)`,
                        }))}
                        value={_.map(form.form.getValues("taxes"), (t: any) => t?.id)}
                        onChange={tax =>
                            form.form.setValue(
                                "taxes",
                                _.intersectionWith(taxes?.data as any, tax, (a: any, b) => a.id === b),
                            )
                        }
                        onClean={() => form.form.setValue("taxes", [])}
                        placeholder={t("select", { name: t("tax") })}
                        style={{
                            width: 300,
                        }}
                    />
                </CFormGroup>

                <RadioFormGroup
                    name="properties.calculation_method"
                    label={t("calculation_method")}
                    errorPlacement="rightEnd"
                >
                    {calculationMethods.map(method => (
                        <Radio key={method.id} value={method.id}>
                            <div className="tw-flex tw-flex-col tw-items-start">
                                <TText tkey={method.name} />
                                <TText
                                    tkey={`service_charge_${method.name}_desc`}
                                    className="tw-text-sm tw-text-secondary"
                                />
                            </div>
                        </Radio>
                    ))}
                </RadioFormGroup>
                <JsonView />
            </CForm>
        </div>
    );
};

export default ServiceChargeCreateEditPage;
