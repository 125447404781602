import React from "react";
import * as Icons from "../assets/icons/Icons";
import { Tooltip, Whisper } from "rsuite";
import CIcon from "./CIcon";

const IconsPage = () => {
    const iconsPath = Object.entries(Icons);
    return (
        <div>
            {/*{iconsPath.map(i => (*/}
            {/*    <div>*/}
            {/*        <Icon size={1} style={{ margin: 5, borderRadius: 2 }} title={i[0]} description={i[0]} path={i[1]} />*/}
            {/*        <span>{i[0]} </span>*/}
            {/*    </div>*/}
            {/*))}*/}

            {iconsPath.map(i => (
                <Whisper key={i[0]} placement="bottom" trigger="hover" speaker={<Tooltip>{i[0]}</Tooltip>}>
                    <span>
                        <CIcon size={1} style={{ margin: 5, borderRadius: 2 }} path={i[1]} />
                    </span>
                </Whisper>
            ))}
        </div>
    );
};

export default IconsPage;
