export const reportAccessRoutes: Record<string, any> = {
    report_product: "/statistic/reports/products",
    report_modifier: "/statistic/reports/modifiers",
    report_payment: "/statistic/reports/payments",
    report_customer: "/statistic/reports/customers",
    report_station: "/statistic/reports/stations",
    report_user: "/statistic/reports/staff",
    report_category: "/statistic/reports/categories",
    report_sell: "/statistic/reports/sales-type",
    report_table: "/statistic/reports/tables",
};
