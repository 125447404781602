import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { changeKeyReceiptItemByType } from "redux/features/receiptConstructor";
import { Checkbox, SelectPicker } from "rsuite";

import React from "react";
import { IReceiptItem, ReceiptFontSize } from "../../types";
import { handleChangeTextSizeAC, handleToggleActivityByTypeAC } from "redux/features/constructor/constructor-thunks";
import { useAppDispatch } from "hooks/useRedux";
import { allowedTypesForChangeFontSize, fontSizeOptions } from "pages/settings/receipt/common/utils";

const priceOptions = [
    {
        value: "price",
        label: t("price"),
    },
    {
        value: "price-tax",
        label: t("price_without_tax"),
    },
    {
        value: "price-discount",
        label: t("discounted_price"),
    },
    {
        value: "price-discount-tax",
        label: t("discounted_price_without_tax"),
    },
];

type ReceiptItemKey = "tax" | "barcode" | "discount" | "modificator" | "gov_code";

const checkboxOptions: { value: ReceiptItemKey; label: string }[] = [
    { value: "tax", label: t("tax") },
    { value: "barcode", label: t("barcode") },
    { value: "discount", label: t("discount") },
    { value: "modificator", label: t("modificator") },
    { value: "gov_code", label: t("gov_code") },
];
const RReceiptProductsComp = (item: IReceiptItem) => {
    const dispatch = useAppDispatch();

    const handleSelect = (value: string | undefined, key: string) => {
        dispatch(changeKeyReceiptItemByType({ item, value: value as string, key: key as keyof IReceiptItem }));
    };
    return (
        <div className="tw-flex tw-gap-4 mr-2">
            <div className="tw-flex tw-flex-col tw-flex-2">
                <span>
                    <TText tkey="receipt_product" />
                </span>
                {checkboxOptions.map(({ value, label }) => (
                    <Checkbox
                        key={value}
                        onChange={(v: string | number | undefined) =>
                            dispatch(handleToggleActivityByTypeAC(v as keyof IReceiptItem))
                        }
                        value={value}
                        checked={!!item?.[value]}
                    >
                        {label}
                    </Checkbox>
                ))}
            </div>
            <div className="tw-flex tw-flex-col tw-flex-1 tw-gap-1">
                <span>
                    <TText tkey="qty" />
                </span>
                <span>1</span>
            </div>
            <div className="tw-flex tw-flex-col tw-flex-1 tw-gap-1">
                <span>
                    <TText tkey="price" />
                </span>
                <SelectPicker
                    cleanable={false}
                    searchable={false}
                    data={priceOptions}
                    value={item.price}
                    // defaultValue={priceOptions[0].value}
                    onSelect={value => handleSelect(value, "price")}
                    size="sm"
                />
            </div>
            <div className="tw-flex tw-flex-col tw-flex-1 tw-gap-1">
                <span>
                    <TText tkey="total" />
                </span>
                <SelectPicker
                    cleanable={false}
                    searchable={false}
                    data={priceOptions}
                    value={item.total}
                    // defaultValue={priceOptions[0].value}
                    onSelect={value => handleSelect(value, "total")}
                    size="sm"
                />
            </div>

            {allowedTypesForChangeFontSize.includes(item.type) && (
                <div className="tw-flex tw-flex-col tw-gap-1 tw-flex-1">
                    <span>
                        <TText tkey="font_size" />
                    </span>
                    <SelectPicker
                        cleanable={false}
                        onSelect={v => dispatch(handleChangeTextSizeAC(v, item))}
                        value={item.textSize}
                        data={fontSizeOptions.filter(fs =>
                            [ReceiptFontSize.Small, ReceiptFontSize.Medium].includes(fs.value),
                        )}
                        size="sm"
                        placement="autoVertical"
                        searchable={false}
                    />
                </div>
            )}
        </div>
    );
};
export default RReceiptProductsComp;
