import { ServiceCharceCalculationMethodEnum, ServiceChargeTypeEnum } from "config/constants";

export const serviceChargeDummyData = (): IServiceCharge => {
    return {
        name: "",
        properties: {
            type: ServiceChargeTypeEnum.FIXED_PERCENTAGE,
            calculation_method: ServiceCharceCalculationMethodEnum.PRE_DISCOUNT,
        },
        taxes: [],
        price: 0,
        status: true,
    };
};
