import { useEffect } from "react";
const invalidChars = ["-", "+", "e"];
export function useNumberInput(type: string, inputID: string): void {
    useEffect(() => {
        //don't allow - + e in number input
        if (type !== "number" && type !== "money") return;
        const inputBox = document.getElementById(inputID) as HTMLInputElement;
        const validate = (e: KeyboardEvent) => {
            if (invalidChars.includes(e.key)) {
                e.preventDefault();
            }
        };
        inputBox?.addEventListener("keydown", validate);
        return () => {
            inputBox?.removeEventListener("keydown", validate);
        };
    }, []);
}
