import { makeUseReduxModelData, UseReduxModelDataReturn } from "hooks/data-hooks/useReduxModelData";
import { GenericDataState } from "redux/features/model/utils";

function extraDataFn(_categories: GenericDataState<IFinanceCategory>, tree: boolean) {
    if (tree) {
        const categoriesArray = Object.values(_categories.byId).sort((a, b) => a._lft - b._lft);

        const buildTree = (nodes: IFinanceCategory[]): IFinanceCategory[] => {
            return nodes.map(node => {
                const children = categoriesArray.filter(c => c.parent_id === node.id);

                return {
                    ...node,
                    ...(children.length > 0 && { children: buildTree(children) }),
                };
            });
        };

        return {
            tree: buildTree(categoriesArray.filter(c => c.depth === 0)),
            list: categoriesArray,
        };
    }
    return {};
}

interface WithTree {
    tree: IFinanceCategory[];
}
type WithConditionalTree<IsTree> = IsTree extends false ? EmptyObject : WithTree;
type useFinanceCategoriesType = <IsTree extends boolean = false>(
    tree?: IsTree,
) => UseReduxModelDataReturn<"financeCategories", WithConditionalTree<IsTree>>;

export const useFinanceCategories = makeUseReduxModelData("financeCategories", extraDataFn) as useFinanceCategoriesType;
