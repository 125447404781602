import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDbReceiptProduct } from "@cloposcom/receipt-utils/src/receipt-calculation/interfaces";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

type WebCashierModal = {
    modalState: {
        product: IDbReceiptProduct | undefined;
        maxPrice: number;
        open: boolean;
        type: string;
        optional?: any;
        title: string;
    };
    priceListId: number | undefined;
    venuePriceListId: number | undefined;
    priceList: Array<IPriceList>;
    salesTypes: Array<ISaleType>;
};

const initialState: WebCashierModal = {
    modalState: {
        product: undefined,
        open: false,
        maxPrice: 0,
        type: "",
        title: "",
        optional: null,
    },
    priceListId: undefined,
    venuePriceListId: undefined,
    priceList: [],
    salesTypes: [],
};

const webCashierAdditionalsSlice = createSlice({
    name: ReduxSlicesNames.WEB_CASHIER_ADDITIONALS,
    initialState,
    reducers: {
        changeModalState: (
            state,
            action: PayloadAction<{
                product: IDbReceiptProduct | undefined;
                open: boolean;
                type: string;
                title: string;
                maxPrice: number;
                optional?: any;
            }>,
        ) => {
            state.modalState = action.payload;
        },
        setPriceListId: (state, action: PayloadAction<number>) => {
            state.priceListId = action.payload;
        },
        setVenuePriceListId: (state, action: PayloadAction<number>) => {
            state.venuePriceListId = action.payload;
        },
        setSalesTypes: (state, action: PayloadAction<Array<ISaleType>>) => {
            state.salesTypes = action.payload;
        },
        setPriceList: (state, action: PayloadAction<Array<IPriceList>>) => {
            state.priceList = action.payload;
        },
    },
});

export const modalState = createSelector(
    (state: RootState) => state.webCashierAdditionals.modalState,
    state => state,
);

export const selectPriceListId = createSelector(
    (state: RootState) => state.webCashierAdditionals.priceListId,
    state => state,
);

export const selectPriceList = createSelector(
    (state: RootState) => state.webCashierAdditionals.priceList,
    state => state,
);

export const selectSalesTypeList = createSelector(
    (state: RootState) => state.webCashierAdditionals.salesTypes,
    state => state,
);

export const { changeModalState, setVenuePriceListId, setPriceListId, setSalesTypes, setPriceList } =
    webCashierAdditionalsSlice.actions;

export default webCashierAdditionalsSlice.reducer;
