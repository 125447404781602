import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import CellBtn from "components/DataTable/CellButton";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import { useTitle } from "components/Header/Title";
import { getProductName, translateUnitName } from "lib/utils";
import PreparationDrawer from "pages/menu/preparations/drawer/PreparationDrawer";
import React, { useEffect, useState } from "react";
import CreateButton from "../../../components/DataTable/CreateButton";
import DataTable from "../../../components/DataTable/DataTable";
import FiltersContainer from "../../../components/DataTable/Filters/FiltersContainer";
import HeaderRight from "../../../components/Header/HeaderRight";
import SearchFilter from "../../../components/DataTable/Filters/SearchFilter";
import SelectFilter from "../../../components/DataTable/Filters/SelectFilter";
import { exportFile } from "lib/export-table";
import Ajax from "lib/Ajax";
import { Button } from "rsuite";
import { iClose, iDoubleCheckBox, iDownload, iEditPencil, iTrash } from "assets/icons/Icons";
import ExportModal from "../../../components/Header/Export/ExportModal";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useBulk } from "hooks/useBulk";
import NoAccessModal from "../../../components/NoAccessModal";
import ProductBulkUpdateDrawer from "../drawer/ProductBulkUpdateDrawer";
import { useLoading } from "hooks/useLoading";
import ConfirmModal from "../../../components/Drawers/ConfirmModal";
import { BulkModeTypes, CategoryTypeEnum } from "config/constants";
import BulkDeleteResultModal from "../../../components/Drawers/BulkDeleteResultModal/BulkDeleteResultModal";
import CIcon from "../../../components/CIcon";
import MenuCategoryTreeFilter from "../../../components/DataTable/Filters/CategoryTreeFilter/MenuCategoryTreeFilter";
import LearnButton from "../../../components/DataTable/LearnButton";

const PreparationPage = () => {
    const [itemDetails, setItemDetails] = useState<any>(null);
    const [bulkDeleteResult, setBulkDeleteResult] = useState({
        deleted_count: 0,
        could_not_deleted_count: 0,
    });
    const auth = useAuth();
    const [permissionLabel, setPermissionLabel] = useState("");
    const noAccessModal = useDrawer();
    const bulkUpdateModal = useDrawer();
    const bulkDeleteModal = useDrawer();
    const bulkDeleteResultModal = useDrawer();

    const {
        isBulkModeOn,
        setIsBulkModeOn,
        bulkMode,
        setBulkMode,
        bulkData,
        setBulkData,
        resetBulkState,
        deleteRequest,
    } = useBulk();
    const url = "product/preparation";
    const { isLoading, withLoading } = useLoading();

    const onConfirmDelete = () => {
        withLoading(() => {
            return deleteRequest("/product/bulk", bulkData);
        })
            .then(response => {
                setBulkDeleteResult(response.data);
                bulkDeleteResultModal.setIsOpen(true);
                resetBulkState();
                bulkDeleteModal.onClose();
                dp.revalidate();
            })
            .catch(() => {
                bulkDeleteModal.onClose();
            });
    };

    const blkApplyHandler = (mode: string) => {
        const permission = mode === BulkModeTypes.DELETE ? "product_delete" : "product_edit";
        if (!auth.hasAbility([permission])) {
            setPermissionLabel(permission);
            noAccessModal.setDataId(1);
            return;
        }
        if (mode === BulkModeTypes.UPDATE) {
            bulkUpdateModal.setDataId(1);
        } else if (mode === BulkModeTypes.DELETE) {
            bulkDeleteModal.setDataId(1);
        }
    };
    const blkHandleCheck = (item: any) => {
        const id = item.id as never;
        if (bulkData.includes(id)) {
            setBulkData(prev => prev.filter(f => f !== id));
        } else {
            setBulkData(prev => [...prev, id]);
        }
    };

    const blkHandleSelectAll = (selected: boolean) => {
        const data: Array<any> = dp.data ?? [];
        let _bulkData = [];
        if (selected) {
            _bulkData = data.map(d => {
                return d.id;
            });
        }

        setBulkData(_bulkData);
    };

    const dp = useDataProvider({
        url: url,
        with: ["unit", "category"],
        sort: ["created_at", "1"],
        useUrl: true,
    });
    useEffect(() => {
        if (isBulkModeOn) bulkUpdateModal.setData(bulkData, true);
        // eslint-disable-next-line
    }, [bulkData]);
    const rd = useDrawer({ backdrop: false, overflowAuto: true });
    const exportDrw = useDrawer();
    const cm = useColumns<any>("product/preparation", [
        {
            title: t("id"),
            key: "id",
            flexGrow: 0.8,
        },
        {
            title: t("name"),
            key: "name",
            render: d => <CellBtn action={() => rd.setDataId(d?.id)} label={getProductName(d)} />,
            flexGrow: 3,
        },
        {
            key: "category.name",
            title: t("category"),
            flexGrow: 1,
        },
        {
            key: "unit",
            render: e => translateUnitName(e.unit),
        },
        {
            key: "inventory_behavior",
            title: t("write_off_method"),
            render: e =>
                e.inventory_behavior === 0
                    ? t("write_off_ingredients")
                    : e.inventory_behavior === 1
                      ? t("write_off_ready_item")
                      : "",
        },
        {
            title: t("unit_weight"),
            key: "unit_weight",
            suffix: t("kg_short"),
        },
        {
            title: t("cost_price"),
            key: "cost_price",
            type: "money",
        },
        optionsColumn({
            dp,
            edit: "preparation/edit/:id",
            delete: "product/preparation/:id",
            canEdit: "product_edit",
            canDelete: "product_delete",
            duplicate: "/menu/preparation/duplicate/:id",
            duplicable: () => true,
            others: () => [
                {
                    label: t("export_btn"),
                    icon: iDownload,

                    onClick: d => {
                        exportDrw.setIsOpen(true);
                        setItemDetails(d);
                    },
                },
            ],
        }),
    ]);
    const blkExit = () => resetBulkState();

    const blkPicker = (m: any) => {
        setBulkMode(m);
        setIsBulkModeOn(true);
    };

    return (
        <div className="h-100">
            {useTitle(t("preparations"), dp.total)}
            <HeaderRight
                cm={cm}
                print
                reload
                dp={dp}
                export={() => exportFile(dp, cm.columns, t("preparations"))}
                importOptions={{
                    template: "Preparation temp",
                    detail: "preparation",
                    action: "product/import",
                    onSuccess: () => dp.revalidate(),
                }}
            />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    {!bulkMode && (
                        <Button className="multiple-select-button" ripple={false} onClick={() => blkPicker("true")}>
                            <CIcon path={iDoubleCheckBox} />
                            <span>
                                <TText tkey="multiple_select" />
                            </span>
                        </Button>
                    )}
                    {bulkMode && (
                        <div className="bulk-mode">
                            <span className="badge">{bulkData.length}</span>
                            <div onClick={() => blkExit()} className="d-flex">
                                <CIcon path={iClose} />
                                <span className="cancel-button">
                                    <TText tkey="cancel" />
                                </span>
                            </div>
                        </div>
                    )}
                    <SearchFilter className="search-input" fields="name" />
                    <SelectFilter
                        fields="inDish"
                        fieldType={"params"}
                        pickerProps={{ searchable: false, placeholder: t("in_dish") }}
                        options={[
                            { label: t("in_use"), value: "1" },
                            { label: t("not_in_use"), value: "-1" },
                        ]}
                    />
                    <SelectFilter
                        fields="haveIngredients"
                        fieldType={"params"}
                        pickerProps={{ searchable: false, placeholder: t("have_ingredients") }}
                        options={[
                            { label: t("does_have_ingredients"), value: "1" },
                            { label: t("does_not_have_ingredients"), value: "-1" },
                        ]}
                    />
                    <MenuCategoryTreeFilter categoryTypes={[CategoryTypeEnum.INGREDIENT]} width={120} maxWidth={450} />

                    {bulkData.length > 0 && (
                        <div className="bulk-acton-buttons">
                            <Button
                                ripple={false}
                                className="edit"
                                onClick={() => blkApplyHandler(BulkModeTypes.UPDATE)}
                            >
                                <CIcon path={iEditPencil} />
                                <TText tkey="change_set" />
                            </Button>
                            <Button
                                ripple={false}
                                className="delete"
                                onClick={() => blkApplyHandler(BulkModeTypes.DELETE)}
                            >
                                <CIcon path={iTrash} />
                                <TText tkey="delete" />
                            </Button>
                        </div>
                    )}
                </div>

                <LearnButton page="preparation" />

                <CreateButton to={"/menu/preparation/create"} canCreate={"product_create"} />
            </FiltersContainer>

            <DataTable
                dp={dp}
                rowPadding="22px 10px 0"
                columns={cm.columns}
                setBulkData={blkHandleCheck}
                setAllBulkData={blkHandleSelectAll}
                isBulkMode={isBulkModeOn}
                bulkData={bulkData}
                tableProps={{
                    rowHeight: 60,
                    headerHeight: 40,
                }}
            />
            {itemDetails?.id && exportDrw.isOpen && (
                <ExportModal
                    exportOptions={[
                        {
                            name: itemDetails?.name,
                            onClick: () =>
                                Ajax.download(
                                    { url: `product/preparation/${itemDetails.id}/invoice` },
                                    `${itemDetails.name} #${itemDetails.id}`,
                                ),
                        },
                    ]}
                    {...exportDrw}
                />
            )}
            {bulkUpdateModal.isOpen && (
                <ProductBulkUpdateDrawer
                    {...bulkUpdateModal}
                    onSubmit={() => {
                        dp.revalidate();
                        blkExit();
                        bulkUpdateModal.hide();
                    }}
                    type={"PREPARATION"}
                />
            )}
            <ConfirmModal {...bulkDeleteModal} isLoading={isLoading} onConfirm={onConfirmDelete} />
            <PreparationDrawer {...rd} />
            <NoAccessModal modal={noAccessModal} permission={[permissionLabel]} />
            <BulkDeleteResultModal modal={bulkDeleteResultModal} bulkDeleteResult={bulkDeleteResult} />
        </div>
    );
};

export default PreparationPage;
