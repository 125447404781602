import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { RemoteConfigKeys } from "hooks/useRemoteConfig";

const configUrls: Record<RemoteConfigKeys, string> = {
    learnData: "https://cdn.clopos.com/_clopos/client/links/learn.json",
    importScheme: "https://cdn.clopos.com/_clopos/client/links/import_details.json",
    govCodeUz: "https://cdn.clopos.com/_clopos/client/configs/gov_code_uz.json",
};

export const fetchRemoteConfig = createAsyncThunk("remoteConfig/fetchRemoteConfig", async (name: string) => {
    const response = await axios.get(configUrls[name as RemoteConfigKeys]);
    return { name, data: response.data };
});
