import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";

import { renderProductName, toFixed, translateUnitName } from "lib/utils";
import DataTable from "components/DataTable/DataTable";

interface IProps {
    data: any;
}

const DishRecipeTab: FCC<IProps> = ({ data }) => {
    const dp = {
        data: data ?? [],
    } as any;

    const cm = useColumns<IDish & { pivot: { gross: string } }>("drawer-ingredient-dishes", [
        {
            title: t("name"),
            key: "name",
            flexGrow: 2.5,
            render: e => renderProductName(e.name, e.parent_name),
        },
        { key: "type", render: e => t(e.type?.toLowerCase() as Lowercase<ProductType>) },
        {
            title: t("unit"),
            key: "pivot.gross",
            render: e => `${parseFloat(e?.pivot.gross)} ${translateUnitName(e?.unit)}`,
            align: "right",
        },
        {
            title: t("cost"),
            key: "total_cost_price",
            type: "money",
            render: e => parseFloat(toFixed(parseFloat(e?.pivot.gross) * e.cost_price, 4)),
        },
    ]);

    return (
        <div className="p-3">
            <DataTable noShadow dp={dp} columns={cm.columns} hidePages={true} />
        </div>
    );
};

export default DishRecipeTab;
