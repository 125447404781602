import { t } from "lib/i18n";
import Money from "components/mini/Money";

import { countdown } from "lib/utils";

interface IProps {
    name: string;
    value: number;
}

const TabDataDisplay: FCC<IProps> = ({ name, value }) => {
    return name === "average_time" ? (
        <span>{t("time_track", countdown(value))}</span>
    ) : ["total_guests", "total_receipt"].includes(name) ? (
        <span>{value}</span>
    ) : (
        <Money value={value} />
    );
};

export default TabDataDisplay;
