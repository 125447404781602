import { Badge } from "rsuite";
import _ from "lodash";
import { translateUnitName } from "lib/utils";
import { Constants } from "config/constants";
import { Colors } from "config/colors";

interface IProps {
    label: any;
    item: any;
    testId?: string;
}

const UnitWithEqual: FCC<IProps> = ({ label, item, testId }) => {
    return (
        <div className="d-flex justify-content-between">
            <div className="elipse-text" test-id={testId}>
                {(Constants.unitNames as any)[item?.name] || item?.name}
            </div>
            {!_.isNil(item.equal) ? (
                <Badge
                    className="float-right"
                    style={{
                        background: "transparent",
                        border: `1px solid ${Colors.WarmGray}`,
                        color: Colors.DarkGray,
                    }}
                    content={<span>{`${Number(item.equal)}/${translateUnitName(_.head(item.ancestors))}`}</span>}
                />
            ) : null}
        </div>
    );
};

export default UnitWithEqual;
