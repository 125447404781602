import _, { Falsey } from "lodash";
import { Resolver } from "react-hook-form";

export type ValidationFunction<T> = (
    value: T,
    fullData: any,
    parentData: any,
    meta: { currentIndex: null | number; fieldName: string },
) => string | Falsey;

export type ValidationSchema = {
    [key: string]: ValidationFunction<any> | ValidationFunction<any>[] | ValidationSchema | ValidationSchema[];
};

type CustomResolver = (schema: ValidationSchema) => Resolver<any>;

const parentSymbol = Symbol("parent");

const generateNames = (fields: Record<string, any>) => {
    const names: string[] = [];

    Object.keys(fields).forEach(field => {
        if (!fields[field]) {
            return;
        } else if (fields[field].ref) {
            names.push(fields[field].name);
        } else if (Array.isArray(fields[field])) {
            fields[field].forEach(subField => {
                names.push(...generateNames(subField));
            });
        } else {
            names.push(...generateNames(fields[field]));
        }
    });

    return names;
};

function setParent(data: any, parent: any) {
    if (typeof data !== "object" || data === null) return;
    Object.defineProperty(data, parentSymbol, {
        value: parent,
        writable: false,
        enumerable: false,
        configurable: false,
    });
}

export function getParent(data: any): any {
    return data?.[parentSymbol];
}

export const customResolver: CustomResolver = schema => async (data, context, options) => {
    const errors: Record<string, { type: string; message: string }> = {};

    const validateField = (
        fullData: any,
        schema: ValidationSchema,
        field: string,
    ): { type: string; message: string } | null => {
        const parts = field.split(/[[\].]+/).filter(Boolean);
        let currentData = fullData;
        let parentData = null;
        let currentSchema: any = schema;
        let currentIndex = null;

        function setCurrentData(value: any) {
            parentData = currentData;
            currentData = value;
            setParent(currentData, parentData);
        }

        for (let i = 0; i < parts.length; i++) {
            if (Array.isArray(currentSchema[parts[i]]) && typeof currentSchema[parts[i]][0] === "object") {
                const index = parseInt(parts[i + 1], 10);
                currentIndex = index;
                setCurrentData(currentData?.[parts[i]]?.[index]);
                currentSchema = currentSchema[parts[i]][0];
                i++;
            } else if (typeof currentSchema[parts[i]] === "object" && !Array.isArray(currentSchema[parts[i]])) {
                setCurrentData(currentData?.[parts[i]]);
                currentSchema = currentSchema[parts[i]];
            } else {
                const validationRules = Array.isArray(currentSchema[parts[i]])
                    ? currentSchema[parts[i]]
                    : [currentSchema[parts[i]]];

                setCurrentData(currentData?.[parts[i]]);
                for (const rule of validationRules) {
                    if (typeof rule === "function") {
                        const validationResult = rule(currentData, fullData, parentData, {
                            currentIndex,
                            fieldName: field,
                        });
                        if (validationResult) {
                            return {
                                type: "custom",
                                message: validationResult,
                            };
                        }
                    }
                }
                return null;
            }
        }
        return null;
    };

    const names = generateNames(options.fields);

    if (names && names.length > 0) {
        names.forEach(fieldToValidate => {
            const error = validateField(data, schema, fieldToValidate);
            if (error) _.set(errors, fieldToValidate, error);
        });
    }

    return {
        values: data,
        errors: Object.keys(errors).length ? errors : {},
    };
};
