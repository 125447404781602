import { cBoolean } from "config/constants";

export const dummyLoyaltyData = () => {
    return {
        payload: {
            name: "Loyalty Program",
            type: "CashBack",
            description: "",
            daily_spend_limit: {
                status: cBoolean.True,
                receipt_count: 3,
                receipt_amount: 100,
            },
            daily_earn_limit: {
                status: cBoolean.True,
                receipt_count: 3,
                receipt_amount: 100,
            },
            rates: {
                default: 5,
            },
            all_sale_types: cBoolean.True,
            sale_types: [],
            excluded_products: [],
            excluded_products_categories: [],
        },
    };
};
