import {
    closestCenter,
    DndContext,
    DragEndEvent,
    KeyboardSensor,
    PointerSensor,
    useSensor,
    useSensors,
} from "@dnd-kit/core";
import { restrictToParentElement, restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
    arrayMove,
    SortableContext,
    sortableKeyboardCoordinates,
    useSortable,
    verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { iCheckBoxChecked, iDragAndDrop } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useLoading } from "hooks/useLoading";
import Ajax from "lib/Ajax";
import { cn } from "lib/utils";
import _ from "lodash";
import { FC, useEffect, useState } from "react";
import { Button, Message, toaster } from "rsuite";

interface ISortableItemProps {
    hall: IHall;
}

const SortableItem: FC<ISortableItemProps> = props => {
    const { isDragging, attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.hall.id });

    return (
        <div
            ref={setNodeRef}
            className={cn("tw-py-2 tw-px-4 tw-mb-2 tw-bg-gray-100 tw-rounded tw-flex tw-items-center tw-gap-4", {
                "tw-cursor-grabbing": isDragging,
                "tw-cursor-grab": !isDragging,
            })}
            style={{
                transform: CSS.Transform.toString(transform),
                transition,
            }}
            {...attributes}
            {...listeners}
        >
            <CIcon path={iDragAndDrop} />
            <div className={"tw-text-black"}>{props.hall.name}</div>
        </div>
    );
};

interface IOrderHallsDrawerProps extends ICDrawerProps {
    halls: IHall[];
    onOrderChange?: () => void;
}

export const OrderHallsDrawer: FC<IOrderHallsDrawerProps> = ({ halls, onOrderChange, ...props }) => {
    const { withLoading, isLoading } = useLoading();

    const sensors = useSensors(
        useSensor(PointerSensor),
        useSensor(KeyboardSensor, {
            coordinateGetter: sortableKeyboardCoordinates,
        }),
    );

    const [hallIds, setHallIds] = useState<number[]>([]);

    const onDragEnd = (e: DragEndEvent) => {
        if (!e.over) return;

        const { active, over } = e;

        if (active.id !== over.id) {
            setHallIds(prev => {
                return arrayMove(prev, prev.indexOf(+active.id), prev.indexOf(+over.id));
            });
        }
    };

    useEffect(() => {
        setHallIds(halls.map(hall => hall.id));
    }, [halls]);

    const onSave = () => {
        void withLoading(async () => {
            const sortedHalls = hallIds.map((hallId, index) => {
                const h = halls.find(h => h.id === hallId)!;

                return {
                    ...h,
                    position: index + 1,
                };
            });

            await Ajax.post({
                url: "hall/update",
                data: {
                    halls: JSON.stringify(sortedHalls),
                },
            }).catch(_.noop);

            toaster.push(
                <Message type="success" showIcon closable>
                    <TText tkey="success" />
                </Message>,
            );

            onOrderChange?.();
            props.hide();
        });
    };

    return (
        <CDrawer size={"xs"} title={t("order_halls")} {...props}>
            <div className={"tw-px-4 tw-py-0 tw-mb-10"}>
                <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={onDragEnd}
                    modifiers={[restrictToVerticalAxis, restrictToParentElement]}
                >
                    <SortableContext items={hallIds} strategy={verticalListSortingStrategy}>
                        {hallIds.map(hallId => (
                            <SortableItem key={hallId} hall={halls.find(h => h.id === hallId)!} />
                        ))}
                    </SortableContext>
                </DndContext>
            </div>

            <div className="drawer-footer">
                <Button loading={isLoading} color={"green"} appearance={"primary"} onClick={onSave}>
                    <CIcon path={iCheckBoxChecked} className="mr-2" />
                    <TText tkey="save" />
                </Button>
            </div>
        </CDrawer>
    );
};
