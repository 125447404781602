import { t } from "lib/i18n";
import React, { useContext, useState } from "react";
import { DatePicker, IconButton } from "rsuite";
import { FiltersContext } from "./FiltersContainer";
import { format, set } from "date-fns";
import CIcon from "../../CIcon";
import { iClose } from "assets/icons/Icons";

interface IProps {
    dp?: any;
    fieldType?: string;
}

const TimePickerFilter: React.FC<IProps> = ({ dp, ...props }) => {
    const filtersContext = useContext(FiltersContext);
    // const { dp, ...props } = _props;

    const defaultDp = dp || filtersContext.dp;
    const [time, setTime] = useState<Date[] | null | any>([]);
    // const toTimePickerRef = useRef<any>(null);
    const dpTime = defaultDp.params.getValue("time");
    const fieldType = (defaultDp as any)[props.fieldType || "params"];

    const onChange = (val: Date | null, fieldName: string) => {
        //there check if time is valid or not
        if (fieldName === "from" && time?.[1] && val && val > time[1]) {
            //if from time is greater than to time then set to time to null
            setTime([val, null]);
            return;
        } else if (fieldName === "to" && time?.[0] && val && val < time[0]) {
            //if to time is less than from time then set from time to null
            setTime([null, val]);
            return;
        }

        const newTime = [...(time || [])];
        if (val) {
            if (fieldName === "from") {
                newTime[0] = val;
            } else if (fieldName === "to") {
                newTime[1] = val;
            }
        }

        setTime(newTime);

        if (newTime[0] && newTime[1]) {
            fieldType.add(
                "time",
                newTime.map((t: Date) => {
                    // используем date-fns для форматирования в 24-часовой формат
                    return format(t, "HH:mm:ss");
                }),
            );
        } else if (!newTime[0] && !newTime[1]) {
            fieldType.remove("time");
        }
    };

    function timeStringToDate(timeStr: string): Date {
        const [hours, minutes, seconds] = timeStr.split(":").map(Number);
        return set(new Date(), { hours, minutes, seconds });
    }

    const formattedDateFromDp = dpTime?.map((t: string) => timeStringToDate(t));

    return (
        <div className="tw-flex tw-items-center tw-gap-2 tw-w-full">
            <DatePicker
                defaultValue={formattedDateFromDp?.[0] || null}
                onChange={value => onChange(value, "from")}
                placement="auto"
                value={time?.[0] || null}
                placeholder={t("hour_minute")}
                format="HH:mm"
                style={{ width: 165 }}
                cleanable={false}
                shouldDisableHour={hour => {
                    if (time?.[1]) {
                        const toHour = time?.[1]?.getHours() || 0;
                        return hour > toHour;
                    }
                    return false;
                }}
            />
            <DatePicker
                value={time?.[1] || null}
                defaultValue={formattedDateFromDp?.[1] || null}
                // className={"ml-2"}
                onChange={value => onChange(value, "to")}
                placement="bottomEnd"
                placeholder={t("hour_minute")}
                format="HH:mm"
                style={{ width: 165 }}
                cleanable={false}
                shouldDisableMinute={(minute, date) => {
                    if (time?.[0]) {
                        const fromHour = time[0].getHours();
                        const fromMinute = time[0].getMinutes();
                        const toHour = date.getHours() || 0;
                        if (fromHour === toHour) {
                            return minute < fromMinute;
                        }
                    }
                    return false;
                }}
                shouldDisableHour={hour => {
                    const fromHour = time?.[0]?.getHours() || 0;
                    return hour < fromHour;
                }}
            />
            <IconButton
                icon={<CIcon path={iClose} />}
                onClick={() => {
                    setTime([null, null]);
                    fieldType.remove("time");
                }}
            />
        </div>
    );
};

export default TimePickerFilter;
