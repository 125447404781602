import { depot } from "@cloposcom/libs";
import { isFunction } from "lodash";
import { Dispatch, SetStateAction, useCallback, useState } from "react";

export function useDepot<T = any>(name: string, defaultValue?: T): [T, Dispatch<SetStateAction<T>>] {
    const [val, setVal] = useState<T>(depot.getItem(name) ?? defaultValue!);
    const updateVal = useCallback(
        (v: T | ((prevVal: T) => T)) => {
            depot.setItem(name, isFunction(v) ? v(val) : v);
            setVal(v);
        },
        [name, val],
    );
    return [val, updateVal];
}
