import { useTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import { CategoryTypeEnum } from "config/constants";
import { useMenuCategories } from "hooks/data-hooks/useMenuCategories";
import { useLoading } from "hooks/useLoading";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import _ from "lodash";
import { FC, SyntheticEvent, useEffect, useState } from "react";
import { fetchProductsAndCategories } from "redux/features/productsSelector/products-thunk";
import { Button, Loader, Message, toaster } from "rsuite";
import { iBox2, iDoubleLine, iRefresh } from "assets/icons/Icons";
import CSortableList, { ISortListData } from "../../components/CSortableList";
import HeaderRight from "../../components/Header/HeaderRight";
import Ajax from "../../lib/Ajax";
import CIcon from "components/CIcon";
import { selectIsShop } from "redux/features/settings";

interface ITree {
    categories: ISortListData[];
    products: ISortListData[];
    activeCategoryId: number;
}

interface IPayload {
    collection: string | number;
    node: HTMLElement;
    newIndex: number;
    oldIndex: number;
}

export const MenuOrderPage: FC = () => {
    const isEmenu = window.location.href.indexOf("e-menu/orders") > -1;
    const dispatch = useAppDispatch();
    const isShop = useAppSelector(selectIsShop);
    const [tree, setTree] = useState<ITree[]>([]);
    const { isLoading, withLoading } = useLoading(false);
    const categories = useMenuCategories(
        false,
        isEmenu ? [CategoryTypeEnum.PRODUCT, CategoryTypeEnum.EMENU] : [CategoryTypeEnum.PRODUCT],
    );
    const { lastUpdatedOfProducts } = useAppSelector(state => state.productSelector);
    const { products } = useAppSelector(state => state.data);

    const orderParam = isEmenu ? ["emenu_position", "position"] : "position";
    useEffect(() => {
        dispatch(fetchProductsAndCategories(lastUpdatedOfProducts));
        categories.revalidate();
    }, []);

    useEffect(() => {
        resetTree(false);
    }, [categories.lastUpdatedAt, lastUpdatedOfProducts]);

    const resetTree = (reload = true) => {
        if (reload) {
            dispatch(fetchProductsAndCategories(lastUpdatedOfProducts));
            categories.revalidate();
        }
        setTree([
            {
                products: [],
                categories: _.orderBy(_.filter(categories.list, { parent_id: null }), orderParam),
                activeCategoryId: 0,
            },
        ]);
    };

    const isProductOfCategory = (productId: number, listData: ISortListData) =>
        isEmenu
            ? products.byId[productId]?.emenu_category_id === listData.id
            : products.byId[productId]?.category_id === listData.id;

    const onClickList = (index: number) => (listData: ISortListData) => (event: SyntheticEvent) => {
        const newSubCats = _.orderBy(_.filter(categories.list, { parent_id: listData.id }), orderParam);
        const productsOfCat = _.orderBy(
            products.allIds.filter(p => isProductOfCategory(p, listData)).map(p => products.byId[p]),
            orderParam,
        );
        //remove branch of tree
        tree.splice(index === 0 ? 1 : index + 1, 9999);
        setTree(_.concat(tree, { categories: newSubCats, products: productsOfCat, activeCategoryId: listData.id }));
    };

    const onSortEnd = (index: number, key: "products" | "categories") => (payload: IPayload) => {
        const newTree = _.cloneDeep(tree);
        const moveData: any = newTree[index][key].splice(payload.oldIndex, 1);
        newTree[index][key] = [...newTree[index][key]];
        newTree[index][key].splice(payload.newIndex, 0, moveData[0]);
        setTree(newTree);
    };

    const onSave = async () => {
        //re-order category
        const orderCategory: Record<string, number> = {};
        _.map(tree, list => {
            _.map(list.categories, (category: ICategory, index: number) => (orderCategory[category.id] = index));
        });

        //re-order products
        const productOrder: Record<string, number> = {};
        _.map(tree, list => {
            _.map(list.products, (product: IProduct, index: number) => (productOrder[product.id] = index));
        });
        const promises = [];
        //update category
        !_.isEmpty(orderCategory) &&
            promises.push(
                withLoading(() =>
                    Ajax.post({
                        url: "position/category",
                        params: isEmenu
                            ? {
                                  type: "emenu",
                              }
                            : {},
                        data: { categories: orderCategory },
                    }),
                )
                    .then(response => {
                        toaster.push(
                            <Message type="success" showIcon closable>{`${t("categories")} ${t("success")}`}</Message>,
                        );
                    })
                    .catch(reason =>
                        toaster.push(
                            <Message type="error" showIcon closable>
                                {reason.message}
                            </Message>,
                        ),
                    ),
            );

        //update product
        !_.isEmpty(productOrder) &&
            promises.push(
                withLoading(() =>
                    Ajax.post({
                        url: isEmenu ? "position/emenu" : "position/product",
                        data: { products: productOrder },
                    }),
                )
                    .then(response => {
                        toaster.push(
                            <Message type="success" showIcon closable>{`${t("products")} ${t("success")}`}</Message>,
                        );
                    })
                    .catch(reason =>
                        toaster.push(
                            <Message type="error" showIcon closable>
                                {reason.message}
                            </Message>,
                        ),
                    ),
            );
        await Promise.allSettled(promises);
        dispatch(fetchProductsAndCategories(lastUpdatedOfProducts));
        categories.revalidate();
    };

    return (
        <div>
            {useTitle(isShop ? t("showcase_rearrangement") : t("menu_rearrangement"))}

            <HeaderRight
                formSubmit={{ isLoading, onSave }}
                customButton={
                    <Button appearance="primary" color="yellow" onClick={() => resetTree()}>
                        <CIcon path={iRefresh} size={0.65} className="mr-2" />
                        {t("reset")}
                    </Button>
                }
            />
            {categories.status === "loading" || products.isLoading ? (
                <Loader size="lg" center backdrop vertical content={t("loading")} style={{ zIndex: 1 }} />
            ) : null}
            <div className="tw-w-full tw-h-full tw-overflow-x-auto tw-p-4 clopos-scroll">
                <div className="tw-flex tw-gap-2">
                    {_.map(tree, ({ products, categories }, index: number) => (
                        <div key={index} className="tw-flex tw-flex-col tw-gap-4">
                            {!_.isEmpty(categories) ? (
                                <CSortableList
                                    title={t("categories")}
                                    list={categories}
                                    icon={iDoubleLine}
                                    onClick={onClickList(index)}
                                    onSortEnd={onSortEnd(index, "categories")}
                                    activeId={tree[index + 1]?.activeCategoryId}
                                />
                            ) : null}

                            {/* {!_.isEmpty(categories) && !_.isEmpty(products) ? <hr /> : null} */}
                            {!_.isEmpty(products) ? (
                                <CSortableList
                                    title={t("products")}
                                    list={products}
                                    icon={iBox2}
                                    // onClick={onClickList(index)}
                                    onSortEnd={onSortEnd(index, "products")}
                                />
                            ) : null}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};
