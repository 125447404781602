import {
    addDays,
    endOfMonth as eom,
    endOfWeek as eow,
    format,
    startOfMonth as sof,
    startOfWeek as sofW,
    subDays,
    subMonths,
    subWeeks,
} from "date-fns";
import { I18N } from "lib/i18n";
import { getDateFNSLocale } from "../lib/getDateFNSLocale";

export const FORMAT_ISO_DATE = "yyyy-MM-dd";

export const today = () => new Date();
export const yesterday = () => subDays(new Date(), 1);
export const tomorrow = () => addDays(new Date(), 1);
export const last3 = (date?: Date) => subDays(date ?? new Date(), 2);
export const last7 = (date?: Date) => subDays(date ?? new Date(), 6);
export const last30 = (date?: Date) => subDays(date ?? new Date(), 29);
export const startOfWeek = () => sofW(new Date(), { weekStartsOn: 1 });
export const endOfWeek = () => eow(new Date(), { weekStartsOn: 1 });
export const startOfMonth = () => sof(new Date());
export const endOfMonth = () => eom(new Date());
export const startOfLastWeek = () =>
    sofW(subWeeks(new Date(), 1), {
        weekStartsOn: 1,
    });
export const endOfLastWeek = () =>
    eow(subWeeks(new Date(), 1), {
        weekStartsOn: 1,
    });
export const startOfLastMonth = () => sof(subMonths(new Date(), 1));
export const endOfLastMonth = () => eom(subMonths(new Date(), 1));

export const lastDay = (date: Date) => subDays(date, 1);
export const nextDay = (date: Date) => addDays(date, 1);

export const todayF = () => format(today(), FORMAT_ISO_DATE);
export const yesterdayF = () => format(yesterday(), FORMAT_ISO_DATE);
export const tomorrowF = () => format(tomorrow(), FORMAT_ISO_DATE);
export const last3F = (date?: Date) => format(last3(date), FORMAT_ISO_DATE);
export const startOfWeekF = () => format(startOfWeek(), FORMAT_ISO_DATE);
export const endOfWeekF = () => format(endOfWeek(), FORMAT_ISO_DATE);
export const startOfMonthF = () => format(startOfMonth(), FORMAT_ISO_DATE);
export const endOfMonthF = () => format(endOfMonth(), FORMAT_ISO_DATE);
export const startOfLastWeekF = () => format(startOfLastWeek(), FORMAT_ISO_DATE);
export const endOfLastWeekF = () => format(endOfLastWeek(), FORMAT_ISO_DATE);
export const startOfLastMonthF = () => format(startOfLastMonth(), FORMAT_ISO_DATE);
export const endOfLastMonthF = () => format(endOfLastMonth(), FORMAT_ISO_DATE);

export const lastDayF = (date: Date) => format(lastDay(date), FORMAT_ISO_DATE);
export const nextDayF = (date: Date) => format(nextDay(date), FORMAT_ISO_DATE);

export const formatWithCustomFormat = (date: Date, dateFormat = FORMAT_ISO_DATE) =>
    format(date, dateFormat, { locale: getDateFNSLocale(I18N.localLang) });
