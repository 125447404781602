import { IDbReceiptProduct } from "@cloposcom/receipt-utils/src/receipt-calculation/interfaces";
import { ICDrawerProps } from "components/Drawers/CDrawer";
import { TText } from "components/i18n/TText";
import { FC, useState } from "react";
import { addGift, recalculateAll, recalculateReceipt } from "redux/features/webCashier";
import { changeModalState } from "redux/features/webCashierAdditionals";
import { store } from "redux/store";
import { Button, Modal } from "rsuite";
import ReceiptNumberInput from "../ReceiptItemNumberInput";
import { useCalculationSetting } from "hooks/useCalculationSetting";
import { useAppDispatch } from "hooks/useRedux";

interface IProps extends ICDrawerProps {
    detail: {
        product: IDbReceiptProduct;
        index: number;
        maxPrice: number;
        open: boolean;
        type: string;
        optional: any;
    };
}

const ProductGiftModal: FC<IProps> = ({ detail }) => {
    const { product } = detail;
    const [count, setCount] = useState(1);
    const dispatch = useAppDispatch();

    const productsById = store.getState().data.products.byId;
    const _product = productsById[product?.product_id ?? 0];
    const parentProduct = _product.parent_id ? productsById[_product.parent_id] : undefined;

    const calculationSetting = useCalculationSetting();

    const addGiftFunc = () => {
        dispatch(
            addGift({
                product,
                count,
                parentProduct,
                setting: calculationSetting,
            }),
        );
        closeModal();
        dispatch(recalculateAll({ setting: calculationSetting, product: product! }));
        dispatch(recalculateReceipt({ setting: calculationSetting }));
    };

    const closeModal = () => {
        dispatch(changeModalState({ ...detail, open: false, title: "" }));
    };

    return (
        <>
            <Modal.Body>
                <div className="discount-form">
                    <div className="count" style={{ marginBottom: 0 }}>
                        <ReceiptNumberInput
                            min={1}
                            step={0}
                            max={product?.count}
                            value={count}
                            name="gift_count"
                            onChange={(count: number) => setCount(count)}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button appearance="primary" color="green" test-id="add-gift" onClick={() => addGiftFunc()}>
                    <TText tkey="add" />
                </Button>
            </Modal.Footer>
        </>
    );
};

export default ProductGiftModal;
