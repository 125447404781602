import classNames from "classnames";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import ToggleFilterDrawer from "components/DataTable/Filters/ToggleFilterDrawer";
import { useDataProvider } from "components/DataTable/useDataProvider";
import ReceiptDrawer from "components/Drawers/ReceiptDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { QuickLinks } from "components/Header/QuickLinks";
import { useTitle } from "components/Header/Title";
import { t } from "lib/i18n";
import DeletedBy from "components/mini/DeletedBy";
import DoubleDataColumn from "components/mini/DoubleDataColumn";
import Money from "components/mini/Money";
import ServiceCharge from "components/mini/ServiceCharge";
import WhisperWithoutIcon from "components/mini/WhisperWithoutIcon";
import { ProductType, Trash } from "config/constants";
import { ASSETS_BASE_URL, EMPTY_DATA } from "config/main";
import { useData } from "hooks/useData";
import { productNameAppend } from "lib/data-utils";
import { exportFile } from "lib/export-table";
import { moneyFormatter } from "lib/moneyFormatter";
import _ from "lodash";
import { Tooltip, Whisper } from "rsuite";
import { RowDataType } from "rsuite-table/src/@types/common";
import ReceiptFilters from "./components/ReceiptFilters";
import GiftCell from "./GIftCell";
import PaymentChange from "./PaymentChangeDrawer";
import PaymentMethodsCell from "./PaymentMethodsCell";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";
import { format, isValid } from "date-fns";

export const receiptStatusNames = {
    1: "open",
    2: "paid",
    3: "mistake",
    4: "on_the_house",
    5: "guest_left",
    6: "merged",
} as const;

const DeletedReceiptsStatPage: FCC = () => {
    // const [hasDeletedBy] = useState<boolean>(true);
    const hasDeletedBy = true;
    const url = "receipt";
    const dp = useDataProvider({
        useUrl: true,
        url,
        extraFilterKeys: ["user_id", "customer_id", "reason_id", "table_id", "terminal_id"],
        with: [
            "user",
            "hall",
            "table",
            "customer",
            "customer.group",
            "user_delete",
            "sale_type",
            "receipt_products",
            "terminal",
        ],
        filters: {
            status: ["status", "!=", 6],
        },
        sort: ["created_at", "-1"],
        params: {
            trash: Trash.OnlyTrash,
        },
        refineList: (ls, s) => {
            const value = s.filters?.paymentMethods ? s.filters?.paymentMethods?.[1] : null;
            if (value) {
                return ls?.filter((qs: any) => {
                    const pm = qs.payment_methods.filter((s: any) => s.amount && value.includes(s.id));
                    return pm.length;
                });
            }
            return ls;
        },
    });

    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;

    const summary = useData<any>("report/cash/time", {
        params: { trash: Trash.OnlyTrash },
        filters: _.values(dp.filters.all),
    });

    const rd = useDrawer({ backdrop: false, overflowAuto: true });
    const sd = useDrawer();
    const filter = useDrawer();

    const removedCols = ["hall.name", "guests"];

    const columns: ITableColumnProps<IReceipt>[] = [
        {
            title: "#" + t("receipt"),
            key: "id",
            width: 70,
            align: "left",
            flexGrow: undefined,
            onClick: (d: any) => rd.setDataId(d.id),
        },
        {
            title: t("staff"),
            minWidth: 120,
            key: "user.username",
            align: "left",
            render: (d: any) => (
                <DoubleDataColumn type="double-data" value1={d.user?.username} value2={d.meta?.user?.role} />
            ),
        },
        {
            key: "created_at",
            type: "datetime",
            flexGrow: 1.1,
            multiline: true,
        },
        {
            title: t("closed_at"),
            key: "closed_at",
            type: "datetime",

            flexGrow: 1.1,
            render: (d: any) => {
                const date = new Date(d.closed_at);

                return (
                    <div style={{ marginTop: "-0.6rem" }}>
                        {isValid(date) ? (
                            <>
                                <div>{format(date, "HH:mm")}</div>
                                <div className="text-empty-dark text-12">{format(date, "MMMM dd")}</div>
                            </>
                        ) : (
                            <>
                                <div>
                                    <div className="text-empty-dark">{EMPTY_DATA}</div>
                                </div>
                                <div className="text-empty-dark text-12">
                                    <div>{EMPTY_DATA}</div>
                                </div>
                            </>
                        )}
                    </div>
                );
            },
        },
        {
            title: t("terminal"),
            key: "terminal.name",
            align: "left",
            minWidth: 100,
            render: (e: any) => (
                <DoubleDataColumn type="double-data" value1={e.terminal?.name} value2={e.terminal?.id} key2={"ID:"} />
            ),
        },
        {
            key: "deleted_at",
            type: "datetime",
            minWidth: 105,
            hide: hasDeletedBy,

            render: (d: any) => <DeletedBy {...d} />,
        },
        {
            key: "user_delete.username",
            title: t("deleted_by"),
            hide: hasDeletedBy,
            flexGrow: 1.2,
            render: (d: any) => (
                <div style={{ marginTop: d.user_delete?.username && d.meta?.user?.role ? "-0.6rem" : 0 }}>
                    <div>{d.user_delete?.username}</div>
                    {d.user_delete?.username ? (
                        <div className="text-empty-dark text-12"> {d.meta?.user?.role}</div>
                    ) : (
                        EMPTY_DATA
                    )}
                </div>
            ),
        },
        {
            title: `${t("sale_type")}`,
            key: "sale_type.name",
            align: "left",

            flexGrow: 0.85,
        },
        {
            title: `${t("hall")} / ${t("table")}`,
            key: "hall.name",
            minWidth: 120,
            align: "left",
            render: (d: any) => (
                <DoubleDataColumn
                    type="double-data"
                    value1={d.table?.name}
                    value2={d.sale_type_id && (d.sale_type_id !== 1 || !d.hall_id) ? d.sale_type?.name : d.hall?.name}
                />
            ),

            flexGrow: 0.85,
        },
        {
            title: t("client"),
            key: "customer.name",

            align: "left",
            minWidth: 140,
            hide: true,
            render: (d: any) => (
                <DoubleDataColumn type="double-data" value1={d.customer?.name} value2={d.customer?.group?.name} />
            ),
        },
        {
            title: t("total_guests"),
            key: "guests",
            align: "right",
            summary: () => summary.data?.total_guests,

            hide: true,
        },
        {
            title: t("subtotal"),
            key: "subtotal",
            align: "right",
            summary: () => {
                const subtotal =
                    summary.data?.total_Original_subtotal &&
                    summary.data?.total_Original_subtotal !== summary.data?.total_subtotal
                        ? summary.data?.total_Original_subtotal
                        : summary.data?.total_subtotal;
                return <Money symbol={false} value={subtotal} />;
            },

            flexGrow: 0.65,
            currency: true,
            render: (e: any) => {
                // const subtotal = e.original_subtotal && e.original_subtotal !== e.subtotal ? e.original_subtotal : e.subtotal;
                return <Money symbol={false} value={e.subtotal} />;
            },
        },
        {
            title: t("product_gift_total"),
            key: "gift_total",
            render: (d: IReceipt) => <GiftCell r={d} />,
            align: "right",
            currency: true,
            type: "money",
            flexGrow: 0.45,
            minWidth: 100,
            summary: () => <Money symbol={false} value={summary.data?.total_gift} />,
        },
        {
            key: "service_charge_value",
            title: t("service_charge"),
            align: "right",
            type: "money",
            minWidth: 130,
            currency: true,

            render: (d: any) => (
                <ServiceCharge symbol={false} rate={d.service_charge} amount={d.service_charge_value} />
            ),
            summary: () => <Money symbol={false} value={summary.data?.total_service_charge} />,

            flexGrow: 0.85,
            // minWidth: 120,
        },
        {
            key: "discount_value",
            align: "right",
            type: "money",
            currency: true,
            summary: () => moneyFormatter(summary.data?.total_discount),
            render: (d: any) => <Money symbol={false} value={d.discount_value + d.rps_discount} />,
            flexGrow: 0.95,
            minWidth: 150,
        },
        {
            key: "totalCost",
            title: t("cost_price"),
            align: "right",
            type: "money",
            summary: () => <Money symbol={false} value={summary.data?.total_cost} />,

            currency: true,
            hide: true,
        },
        {
            key: "description",
            hide: true,
            render: (e: any) => <WhisperWithoutIcon description={e.description} />,
        },
        {
            title: t("fiscal_total"),
            key: "total_tax",
            align: "right",
            type: "money",
            render: (e: any) => (e.total_tax ? <Money symbol={false} value={e.total_tax} /> : "--"),
            summary: () => <Money symbol={false} value={summary.data?.total_tax} />,
            currency: true,
            flexGrow: 0.55,
        },
        {
            title: t("sum_total"),
            key: "total",
            align: "right",

            summary: () => <Money symbol={false} value={summary.data?.total_cash} />,
            currency: true,
            render: (d: any) => <Money symbol={false} value={d.total} />,
            flexGrow: 0.55,
            minWidth: 100,
        },
        {
            title: t("payment_method"),
            key: "payment_methods",
            render: (d: any) => <PaymentMethodsCell r={d} />,

            align: "center",
            flexGrow: 1.35,
        },
        {
            title: t("daily_id"),
            key: "properties.daily_id",
            hide: true,
            onClick: (d: any) => rd.setDataId(d.id), // TODO: type fix
        },
        {
            title: t("monthly_id"),
            key: "properties.monthly_id",
            hide: true,
            onClick: (d: any) => rd.setDataId(d.id),
        },
        {
            key: "status",
            render: (d: IReceipt) => (
                <Whisper
                    preventOverflow
                    trigger="hover"
                    speaker={<Tooltip style={{ width: 120 }}>{t(receiptStatusNames[d.status])}</Tooltip>}
                    placement="leftStart"
                >
                    <div style={{ width: "0.7rem", height: "2rem" }}>
                        <img
                            alt="receipt status"
                            style={{ marginLeft: "0.7rem" }}
                            src={`${ASSETS_BASE_URL}/img/${receiptStatusNames[d.status]}.svg`}
                        />
                    </div>
                </Whisper>
            ),

            flexGrow: 0.45,
        },
        optionsColumn({
            dp,
            delete: "receipt/:id",
            preventDelete: d => !d.closed_at,
            editable: d => {
                const hasPayments = d.payment_methods?.filter((pm: any) => pm.amount);
                return _.isEmpty(hasPayments) || hasPayments.length > 2;
            },
            edit: d => sd.setData(d),
            canEdit: "receipt_edit",
            canDelete: "receipt_delete",
        }),
    ].filter(c => (isRestaurant ? true : !removedCols.includes(c.key as string))) as ITableColumnProps<IReceipt>[];

    const cm = useColumns<IReceipt>("deleted-receipts", columns);

    return (
        <div className="h-100">
            {/*{useTitle(t("receipts"), summary.data?.total_open)}*/}
            {useTitle(t("receipts"), dp.total)}

            <QuickLinks
                links={[
                    {
                        link: "/sales/receipts",
                        label: t("receipt"),
                    },
                    {
                        link: "/sales/open-receipts",
                        label: t("open_receipts"),
                    },
                    {
                        link: "/sales/deleted-receipts",
                        label: t("deleted_receipts"),
                        active: true,
                    },
                    {
                        link: "/sales/merged-receipts",
                        label: t("merged_receipts"),
                    },
                ]}
            />

            <HeaderRight cm={cm} print export={() => exportFile(dp, cm.columns, t("receipts"))} reload dp={dp} />
            <ReceiptDrawer {...rd} />
            <PaymentChange {...sd} dp={dp} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="id" />
                    {/* <RemoteCheckListFilter
                    fields="terminal_id"
                    remote={{
                        url: "terminal",
                    }}
                    pickerProps={{ placeholder: t("terminals") }}
                /> */}
                    {/* <RemoteCheckListFilter
                    fields="user_id"
                    remote={{
                        url: "user",
                        refineList: (d: any[]) => d?.filter(user => user.owner || !user.hide),
                    }}
                    labelField="username"
                    pickerProps={{ placeholder: t("staff") }}
                /> */}
                    {/* {isRestaurant && <HallTableTreeFilter fieldName={"table_id"} searchFieldName={"table_name"} />} */}
                    <RemoteCheckListFilter
                        fields="product_id"
                        searchField="name"
                        remote={{
                            url: "product",
                            sort: ["updated_at", "-1"],
                            limit: 20,
                            params: {
                                trash: Trash.TrashAndActive,
                            },
                            filters: {
                                type: [
                                    ["type", ProductType.GOODS],
                                    ["type", ProductType.DISH],
                                    ["type", ProductType.MODIFICATION],
                                ],
                            },
                            refineList: (products?: IProduct[]) =>
                                products
                                    ?.map(i => productNameAppend(i))
                                    ?.filter(f => !(f.type === ProductType.GOODS && f.has_modifications)) ?? [],
                        }}
                        pickerProps={{ placeholder: t("product") }}
                    />
                    {/* <RemoteCheckListFilter
                    fields="customer_id"
                    searchField="name"
                    remote="customer"
                    pickerProps={{ placeholder: t("customers") }}
                /> */}
                    {/* <SelectFilter
                    fields="suspicious"
                    labelField="name"
                    options={actions}
                    pickerProps={{ placeholder: t("suspicion_action") }}
                /> */}
                    <RemoteCheckListFilter
                        fields="sale_type_id"
                        searchField="name"
                        remote="finance/saleType"
                        pickerProps={{ placeholder: t("sale_type") }}
                    />
                    <ToggleFilterDrawer
                        isOpen={filter.isOpen}
                        onChange={val => {
                            filter.setIsOpen(val);
                            rd.setIsOpen(false);
                        }}
                    />
                </div>
            </FiltersContainer>
            <ReceiptFilters showOnPage="deletedReceipt" {...filter} dp={dp} />
            <DataTable
                dp={dp}
                columns={cm.columns}
                rowPadding={"23px 10px 0px"}
                tableProps={{
                    headerHeight: 50,
                    affixHeader: true,
                    rowHeight: 64,
                    rowClassName: (d?: RowDataType) =>
                        classNames({
                            suspicious: d?.suspicion,
                            "deleted-row": d && !!d?.deleted_at,
                            "open-receipt": d && !d?.closed_at,
                        }),
                }}
            />
        </div>
    );
};

export default DeletedReceiptsStatPage;
