import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";

import { getVenuesList } from "lib/utils";
import { useEffect, useState } from "react";
import { Toggle } from "rsuite";
import { Cell, Column, HeaderCell, Table } from "rsuite-table";
import CFormGroup from "./Form/CFormGroup";
import _ from "lodash";
import CheckboxFormGroup from "./Form/CheckboxFormGroup";
import { useCurrency } from "hooks/useCurrency";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

// const Column = Table.Column as FCC<ColumnProps<any>>;

const GoodsSettingsByVenue: FCC = () => {
    const { watch, getValues, setValue } = useCFormContext();
    const venueList = getVenuesList();
    const currency = useCurrency();

    const product = getValues();
    const product_id = product.id;
    const insertedVenues: IVenueType[] = product?.venues;
    const [shouldShowByVenue, setShouldShowByVenue] = useState(insertedVenues?.length > 0);
    // const hasModifications = product?.modifications?.length > 0;
    const has_variant = watch("has_modifications");

    useEffect(() => {
        if (!shouldShowByVenue) {
            setValue("venues", []);
        } else {
            fillVenues();
        }
        // eslint-disable-next-line
    }, [shouldShowByVenue]);

    useEffect(() => {
        if (insertedVenues?.length > 0) {
            setShouldShowByVenue(true);
            fillVenues();
        }
        // eslint-disable-next-line
    }, [insertedVenues?.length]);

    const fillVenues = () => {
        const fullVenueList: any[] = [];
        for (const x in venueList) {
            const hasValues = insertedVenues?.find(iB => iB.id === venueList[x].id);
            if (hasValues) {
                fullVenueList.push(hasValues);
            } else {
                fullVenueList.push({
                    id: venueList[x].id,
                    name: venueList[x].name,
                    title: venueList[x].title,
                    values: {
                        product_id,
                        // price: product.price || 0,
                        hidden: product.hidden,
                        emenu_hidden: product.emenu_hidden,
                        venue_id: venueList[x].id,
                    },
                });
            }
        }
        setValue("venues", fullVenueList);
    };

    // control.getValues(`venue[${i}].values.price`)
    // if(hasModifications && product.type === ProductType.GOODS) return <></>;
    if (venueList.length === 1) return <></>;
    return (
        <CFormGroup name="venue" label={t("according_to_venue")}>
            <div style={{ marginTop: 5 }}>
                <Toggle
                    test-id="show-by-venue"
                    defaultChecked={insertedVenues?.length > 0}
                    checked={shouldShowByVenue}
                    onChange={setShouldShowByVenue}
                />
            </div>
            {shouldShowByVenue && (
                <Table
                    rowKey="id"
                    test-id="venue-list"
                    data={insertedVenues}
                    width={600}
                    autoHeight
                    sortColumn="id"
                    className="tw-mt-4"
                >
                    <Column width={150}>
                        <HeaderCell>
                            <TText tkey="name" />
                        </HeaderCell>
                        <Cell dataKey="name" style={{ marginTop: 13, paddingLeft: 5 }} />
                    </Column>
                    {/*{!has_variant ? (*/}
                    {/*    <TableColumn width={150}>*/}
                    {/*        <Table.HeaderCell><TText key="price" /></Table.HeaderCell>*/}
                    {/*        <Table.Cell dataKey="values.price" style={{ display: "flex", alignItems: "center" }}>*/}
                    {/*            {(_d, i) => (*/}
                    {/*                <InputGroup style={{ marginTop: 6, width: 120 }}>*/}
                    {/*                    <InputNumber*/}
                    {/*                        type="number"*/}
                    {/*                        size="sm"*/}
                    {/*                        defaultValue={undefined}*/}
                    {/*                        scrollable={false}*/}
                    {/*                        onWheel={(e: any) => e.target?.blur()}*/}
                    {/*                        value={getValues(`venues[${i}].values.price`)}*/}
                    {/*                        onChange={e => setValue(`venues[${i}].values.price`, e)}*/}
                    {/*                    />*/}
                    {/*                    <InputGroup.Addon>{currency}</InputGroup.Addon>*/}
                    {/*                </InputGroup>*/}
                    {/*            )}*/}
                    {/*        </Table.Cell>*/}
                    {/*    </TableColumn>*/}
                    {/*) : null}*/}
                    {_.has(product, "hidden") && (
                        <Column width={150}>
                            <HeaderCell>
                                <TText tkey="inactive" />{" "}
                            </HeaderCell>
                            <Cell dataKey="values.hidden">
                                {(_d, i) => (
                                    <CheckboxFormGroup
                                        hideLabel
                                        name={`venues[${i}].values.hidden`}
                                        label={false}
                                        controlWrapperProps={{
                                            style: { paddingLeft: 0 },
                                        }}
                                    />
                                    // <Checkbox
                                    //     style={{ marginTop: 5 }}
                                    //     defaultChecked={getValues(`venues[${i}].values.hidden`)}
                                    //     checked={_d.values.hidden}
                                    //     onChange={(_e, checked) => setValue(`venues[${i}].values.hidden`, checked)}
                                    // />
                                )}
                            </Cell>
                        </Column>
                    )}
                    {_.has(product, "emenu_hidden") && (
                        <Column width={150}>
                            <HeaderCell>
                                <TText tkey="emenu_hidden" />
                            </HeaderCell>
                            <Cell dataKey="values.emenu_hidden">
                                {(_d, i) => (
                                    <CheckboxFormGroup
                                        hideLabel
                                        name={`venues[${i}].values.emenu_hidden`}
                                        label={false}
                                        controlWrapperProps={{
                                            style: { paddingLeft: 0 },
                                        }}
                                    />

                                    //     <Checkbox
                                    //     style={{marginTop: 5}}
                                    //     defaultChecked={getValues(`venues[${i}].values.emenu_hidden`)}
                                    //     checked={_d.values.emenu_hidden}
                                    //     onChange={(_e, checked) =>
                                    //     setValue(`venues[${i}].values.emenu_hidden`, checked)
                                    // }
                                    //     />
                                )}
                            </Cell>
                        </Column>
                    )}
                </Table>
            )}
        </CFormGroup>
    );
};

export default GoodsSettingsByVenue;
