import { t } from "lib/i18n";
import { useCForm } from "hooks/useCForm";
import { dummyIngredientBulk, dummyIngredientItem, IngredientBulkModel } from "models/GoodsModel";
import { useFormTitle } from "components/Header/Title";

import HeaderRight from "../../../components/Header/HeaderRight";
import CForm from "components/Form/CForm/CForm";
import JsonView from "../../../components/JsonView";
import IngredientItem, { IngredientItemField } from "./IngredientItem";
import _ from "lodash";
import { FormItems } from "components/FormItems/FormItems";
import { FIHeaderProps } from "components/FormItems/FormItemsHeader";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { FormItemsAddButton } from "components/FormItems/FormItemsAddButton";
import { useEffect } from "react";
import { useAppDispatch } from "hooks/useRedux";
import { menuCategoriesSlice, unitsSlice } from "redux/features/model/slice";

const columns: FIHeaderProps["columns"] = [["name"], ["accounting_category"], ["category"], ["unit"], [""]];

const IngredientBulkCreatePage: FCC = () => {
    const dispatch = useAppDispatch();
    const form = useCForm({
        url: "product/ingredient/storeBulk",
        dummyData: dummyIngredientBulk,
        mode: "onSubmit",
        model: IngredientBulkModel(),
        prepareForSubmit: d => ({
            products: d.products.map((product: IIngredient) => _.omitBy(product, p => _.isNil(p))),
        }),
    });

    useEffect(() => {
        dispatch(menuCategoriesSlice.fetchData(""));
        dispatch(unitsSlice.fetchData(""));
    }, []);

    return (
        <div className="tw-p-4">
            {useFormTitle(form.id, t("bulk_by_type", { type: t("ingredient") }))}
            <HeaderRight formSubmit={form} />
            <CForm {...form} formProps={{ layout: "inline" }}>
                <FormItems itemsFieldName="products">
                    <FormItemsList<IngredientItemField>
                        virtualize
                        columns={columns}
                        children={_props => <IngredientItem {..._props} />}
                        footer={
                            <div className="tw-grid tw-grid-cols-subgrid tw-col-span-5">
                                <FormItemsAddButton dummyData={dummyIngredientItem} />
                            </div>
                        }
                    />
                </FormItems>
                {/* <ItemsList
                    dummyData={dummyIngredientItem}
                    ItemComponent={IngredientItem}
                    itemsFieldName={"products"}
                    columns={[
                        ["name", 300],
                        ["accounting_category", 150],
                        ["category", 300],
                        ["unit", 100],
                    ]}
                /> */}
                <JsonView />
            </CForm>
        </div>
    );
};

export default IngredientBulkCreatePage;
