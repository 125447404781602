import { PerformanceType } from "config/constants";

export const dummyIncentiveProgram = () => {
    return {
        name: "",
        description: "",
        criteria: {
            sales_threshold: 0,
            sales_bonus_rate: 0,
        },
        excluded_items: [],
        excluded_categories: [],
        excluded_stations: [],
        assigned_roles: [],
        selected_sale_types: ["*"],
        assigned_staff: [],
        start_date: "",
        end_date: "",
        status: false,
        active_hours: {
            start_time: null,
            end_time: null,
        },
        active_days: ["*"],
        performance_type: PerformanceType.SELF,
        active_days_select: "all",
        created_at: "",
        updated_at: "",
    };
};
