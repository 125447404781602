import { toFixedMinMax } from "lib/utils";
import { ChangeEvent, forwardRef, InputHTMLAttributes, useEffect, useRef, useState } from "react";

export interface BareNumberInputProps extends InputHTMLAttributes<HTMLInputElement> {
    value: number;
    onChangeValue?: (val: number) => void;
    fallbackValue: 0 | null | undefined;
    decimals?: number | [number, number];
}

export const BareNumberInput = forwardRef<HTMLInputElement, BareNumberInputProps>(function NumberInput(
    { value, onChangeValue, fallbackValue = 0, decimals = [2, 2], ...props },
    ref,
) {
    const _decimals: [number, number] = Array.isArray(decimals) ? decimals : [decimals, decimals];
    const inputRef = useRef<HTMLInputElement | null>(null);
    // State to keep track of the input's display value
    const [displayValue, setDisplayValue] = useState(formatNumber(value));
    // Function to format the number for display purposes
    function formatNumber(n: number) {
        if (typeof n === "string") {
            n = parseFloat(n);
        }
        // console.log("formatNumber", props.name, n, _decimals, toFixedMinMax(n, ..._decimals));
        return toFixedMinMax(n, ..._decimals);
    }

    // Handle changes in the input
    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        const inputVal = event.currentTarget.value;
        // Remove any non-numeric characters (but keep '-' and '.')
        const numericValue = inputVal.replace(/[^\d.-]/g, "");
        // Update the display value to what the user types
        setDisplayValue(inputVal);

        // If the numericValue is a valid number, update the state
        if (!isNaN(parseFloat(numericValue)) && numericValue !== "") {
            onChangeValue?.(parseFloat(numericValue) ?? fallbackValue);

            props.onChange?.(event);
        }
    };

    const setRef = (el: HTMLInputElement) => {
        inputRef.current = el;
        if (typeof ref === "function") {
            ref(el);
        } else if (ref) {
            ref.current = el;
        }
    };

    useEffect(() => {
        if (inputRef?.current === document.activeElement || value === parseFloat(displayValue)) return;
        // If the value prop changes, update the state
        setDisplayValue(formatNumber(value));
    }, [value]);

    return (
        <input
            {...props}
            type="text" // Using "text" type to allow custom formatting
            value={displayValue}
            onChange={handleChange}
            onBlur={e => {
                // This is where we format the displayed number
                setDisplayValue(formatNumber(value));
                onChangeValue?.(parseFloat(formatNumber(value)));
                props.onChange?.(e);
                props.onBlur?.(e);
            }}
            ref={setRef}
        />
    );
});
