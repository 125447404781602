import { useDrawer } from "components/Drawers/useDrawer";
import TextFormGroup from "components/Form/TextFormGroup";
import { ModifierGroupType, ProductType } from "config/constants";
import { ruleset } from "lib/validation-rules";
import ModificationGroup from "pages/products/dishes/edit/tabs/modifications/group/ModificationGroup";
import ModificationModal from "pages/products/dishes/edit/tabs/modifications/modal/ModificationModal";
import { useEffect } from "react";
import { useAppDispatch } from "hooks/useRedux";
import { setDisabledProductIds, setShowedTypes } from "redux/features/productsSelector/productsSelector";

export const ModifierEditTab1 = ({ form }: any) => {
    const dispatch = useAppDispatch();
    const fData = form.form.getValues();
    // const lastUpdatedOfProducts = useAppSelector(state => state.productSelector.lastUpdatedOfProducts);
    const modal = useDrawer();

    useEffect(() => {
        dispatch(setDisabledProductIds([]));
        dispatch(
            setShowedTypes([ProductType.DISH, ProductType.PREPARATION, ProductType.GOODS, ProductType.INGREDIENT]),
        );
    }, []);

    return (
        <div className="tw-p-4">
            <TextFormGroup
                name="name"
                rules={{
                    validate: {
                        required: ruleset.required,
                    },
                }}
            />
            {fData.type === ModifierGroupType.MULTI && (
                <>
                    <TextFormGroup
                        name="min_select"
                        type="number"
                        rules={{
                            validate: {
                                required: ruleset.required,
                                min: ruleset.gte(0),
                                max: ruleset.lte("max_select"),
                            },
                        }}
                    />
                    <TextFormGroup
                        name="max_select"
                        type="number"
                        rules={{
                            validate: {
                                required: ruleset.required,
                                min: ruleset.gte("min_select"),
                                minGround: ruleset.gte(0),
                            },
                        }}
                    />
                </>
            )}
            <ModificationGroup group={fData} />
            <ModificationModal {...modal} />
        </div>
    );
};
