import { t } from "lib/i18n";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";

import { ServiceChargeFixedTypes, ServiceChargeTypeEnum, statuses } from "config/constants";
import CreateButton from "../../../components/DataTable/CreateButton";
import React, { FC } from "react";
import { EMPTY_DATA } from "config/main";
import { CBadge } from "core/components/CBadge/CBadge";
import { useCurrency } from "hooks/useCurrency";
import { getDataPropertiesOf } from "lib/utils";

const renderPriceColumn = (data: IServiceCharge, currency: string) => {
    if (!data?.price) return EMPTY_DATA;
    if (!ServiceChargeFixedTypes.includes(getDataPropertiesOf(data).type)) return EMPTY_DATA;
    return data?.price + (getDataPropertiesOf(data).type === ServiceChargeTypeEnum.FIXED_AMOUNT ? currency : "%");
};

const ServiceChargePage: FC = () => {
    const dp = useDataProvider({
        url: "product/serviceCharges",
        useUrl: true,
        with: ["taxes:id,name"],
    });

    const currency = useCurrency();

    const cm = useColumns("product/serviceCharges", [
        { key: "name", align: "left" },
        {
            key: "price",
            title: t("value"),
            align: "right",
            render: (data: IServiceCharge) => renderPriceColumn(data, currency),
        },
        {
            key: "properties.type",
            title: t("type"),
            align: "center",
            render: data => t(getDataPropertiesOf(data).type),
        },
        {
            key: "properties.calculation_method",
            title: t("calculation_method"),
            align: "left",
            render: d => t(getDataPropertiesOf(d).calculation_method?.toLowerCase()),
        },
        {
            key: "tax",
            align: "left",
            render: (data: IServiceCharge) => data.taxes?.map(t => t.name)?.join(", ") ?? EMPTY_DATA,
        },
        {
            key: "status",
            render: (data: IServiceCharge) => {
                const status = data.status ? "active" : "inactive";
                return <CBadge color={data.status ? "green" : "red"}>{statuses[status].name}</CBadge>;
            },
        },
        optionsColumn({
            dp,
            edit: "/settings/service-charges/edit/:id",
            delete: "finance/service-charges/:id",
            preventDelete: d => !!d.is_system,
            canDelete: "tax_delete",
            canEdit: "tax_edit",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("service_charges"), dp.total)}
            <HeaderRight dp={dp} cm={cm} reload print />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="name" />
                </div>
                <CreateButton to="/settings/service-charges/create" />
            </FiltersContainer>

            <DataTable dp={dp} columns={cm.columns} />
        </div>
    );
};

export default ServiceChargePage;
