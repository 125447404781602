import { t } from "lib/i18n";
import CellBtn from "components/DataTable/CellButton";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { getProductName, translateUnitName } from "lib/utils";
import { useHistory } from "react-router-dom";
import DataTable from "components/DataTable/DataTable";
import CDrawer, { ICDrawerProps } from "components/Drawers/CDrawer";
import TabsView from "components/TabsView";

type IProps = ICDrawerProps;

const ModifierDrawer: FCC<IProps> = props => {
    const url = `product/modificator-group/${props.dataId}/`;
    const history = useHistory();

    // convert to useData
    const dp = useDataProvider({
        stop: !props.dataId,
        url: url,
        with: ["products.unit", "modificators.ingredient.unit"],
    });

    const productCm = useColumns<IProduct>("modifier_product", [
        // {key: 'id', title: `#${t("product")}`, flexGrow: undefined, width: 75},
        {
            key: "name",
            render: e => <CellBtn action={() => history.push(`/menu/dish/edit/${e.id}`)} label={getProductName(e)} />,
        },
        {
            key: "type",
            render: e => t(e.type?.toLowerCase() as Lowercase<ProductType>),
        },
    ]);

    const modifierCm = useColumns<IModifier>("modifier_modificator-group", [
        { key: "id", title: `#${t("modifier_groups")}`, flexGrow: undefined, width: 100 },
        { key: "name" },
        { key: "ingredient.name", title: t("recipe"), width: 140 },
        {
            key: "brutto",
            title: t("quantity"),
            render: e => e?.brutto + " " + translateUnitName(e.ingredient?.unit),
        },
    ]);

    const renderProducts = () => {
        return (
            <div>
                <DataTable dp={dp} columns={productCm.columns} dataPath={"products"} noShadow />
            </div>
        );
    };

    const renderModifications = () => {
        return (
            <div>
                <DataTable dp={dp} columns={modifierCm.columns} dataPath={"modificators"} noShadow />
            </div>
        );
    };

    const mg = dp.data as any;

    return (
        <CDrawer title={`#${props.dataId ?? 0} ${mg?.name}`} {...props}>
            <TabsView
                tabs={[
                    { label: `${t("products")} (${mg?.products?.length})`, Component: renderProducts },
                    {
                        label: `${t("modifier_groups")} (${mg?.modificators?.length})`,
                        Component: renderModifications,
                    },
                ]}
            />
        </CDrawer>
    );
};

export default ModifierDrawer;
