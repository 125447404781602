import React, { CSSProperties } from "react";
import { Checkbox } from "rsuite";

interface IProps {
    style?: CSSProperties;
    checked?: boolean;
    disabled?: boolean;
    onChange: () => void;
    testIdSuffix?: string;
}

const BulkCheckBox: FCC<IProps> = ({ style, testIdSuffix, onChange, checked, disabled }) => {
    return (
        <div onClick={event => event.stopPropagation()} className="bulk-update-wrapper" style={{ ...style }}>
            <Checkbox
                test-id={`product-selector-product-checkbox${testIdSuffix ?? ""}`}
                checked={checked}
                disabled={disabled}
                onChange={(value, checked, event) => onChange()}
            />
        </div>
    );
};

export default BulkCheckBox;
