import { Button, Toggle } from "rsuite";

const handleI18NDebug = (checked: boolean) => {
    if (checked) {
        localStorage.setItem("DEBUG_I18N_KEYS", "true");
    } else {
        localStorage.removeItem("DEBUG_I18N_KEYS");
    }

    window.location.reload();
};

const handleDisableDebugMode = () => {
    localStorage.removeItem("CLOPOS_DEBUG_MODE");
    window.location.reload();
};

export const SettingsTab = () => {
    const isDebugI18NKeys = Boolean(localStorage.getItem("DEBUG_I18N_KEYS"));
    const isDebugMode = Boolean(localStorage.getItem("CLOPOS_DEBUG_MODE"));

    return (
        <div className="tw-mt-3 tw-max-w-lg">
            <div className="tw-grid tw-items-center tw-grid-cols-3">
                <label className="tw-col-span-2">Show translate keys</label>
                <Toggle className="my-1 pt-1 tw-col-span-1" checked={isDebugI18NKeys} onChange={handleI18NDebug} />
            </div>
            {isDebugMode && (
                <div className="tw-mt-4">
                    <Button className="tw-bg-blue-500 tw-text-white" onClick={handleDisableDebugMode}>
                        Disable debug mode
                    </Button>
                </div>
            )}
        </div>
    );
};
