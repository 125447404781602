import { t } from "lib/i18n";

import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import Money from "components/mini/Money";
import { EMPTY_DATA } from "config/main";
import CIcon from "../../../components/CIcon";
import { iArrowDown, iArrowUp, iExchange } from "assets/icons/Icons";
import { Colors } from "config/colors";
import { useTableHeight } from "hooks/useTableHeight";

interface IProps {
    supplier: any;
    isSupplier?: boolean;
    summary: any;
}

const transactionColors: Record<TransactionType, string> = {
    IN: Colors.EmeraldGreen,
    OUT: Colors.CarmineRed,
    TRANSFER: Colors.JetBlack,
    SUPPLIER_PAY: Colors.JetBlack,
};

const CashflowDetails: FCC<IProps> = ({ supplier, isSupplier, summary }) => {
    const transactionIcons = {
        IN: <CIcon path={iArrowUp} className="mr-2" style={{ color: transactionColors.IN }} />,
        OUT: <CIcon path={iArrowDown} className="mr-2" style={{ color: transactionColors.OUT }} />,
        TRANSFER: <CIcon path={iExchange} className="mr-2" style={{ color: transactionColors.TRANSFER }} />,
        SUPPLIER_PAY: null,
    };

    const cm = useColumns<ITransaction>(`cashflow-details`, [
        {
            key: "operated_at",
            type: "datetime",
            align: "center",
            minWidth: 135,
            title: t("operation"),
            multiline: true,
            reverse: true,
            prefix: d => <div>{transactionIcons[d.type]}</div>,
        },
        { key: "category.name", title: t("category") },
        { key: "description" },
        {
            key: "amount",
            type: "money",
            flexGrow: undefined,
            getStyle: (d: ITransaction) => ({ color: transactionColors[d.type] }),
            summary: () => <Money colored value={summary.data && summary.data[0]?.total_diff} />,
        },
        {
            key: isSupplier ? "from_balance.name" : "balance.name",
            title: isSupplier ? t("from_account") : t("to_account"),
            render: (d: ITransaction) =>
                isSupplier
                    ? d.type === "OUT"
                        ? d.balance?.name
                        : d.from_balance?.name
                    : d.type === "OUT"
                      ? EMPTY_DATA
                      : d.balance?.name,
        },
    ]);

    const height = useTableHeight(185); //  simple table height calculator. Sorry bashqa yol tapmadim :(

    return (
        <div className="d-flex">
            <div className="flex-fill border-left">
                <DataTable
                    noShadow
                    columns={cm.columns}
                    dp={supplier}
                    tableProps={{
                        headerHeight: 50,
                        autoHeight: false,
                        height: height,
                    }}
                />
            </div>
        </div>
    );
};

export default CashflowDetails;
