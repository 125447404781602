import { useState } from "react";
import Ajax from "lib/Ajax";
import { t } from "lib/i18n";

import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import { useDataProvider } from "components/DataTable/useDataProvider";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import CreateButton from "components/DataTable/CreateButton";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import { useDrawer } from "components/Drawers/useDrawer";
import { TText } from "components/i18n/TText";
import CIcon from "components/CIcon";

import { Message, toaster } from "rsuite";
import { iSignOut } from "assets/icons/Icons";
import TerminalHistoryDrawer from "./TerminalHistoryDrawer";
import { useAuth } from "providers/AuthProvider/AuthProvider";

export const TerminalsPage: FCC = () => {
    const auth = useAuth();
    const dp = useDataProvider({
        url: "terminal",
        useUrl: true,
        with: ["info"],
    });

    const [terminalName, setTerminalName] = useState("");

    const historyDrawer = useDrawer({ backdrop: false, overflowAuto: true });

    const cm = useColumns<any>("terminal", [
        {
            title: "ID",
            key: "id",
            flexGrow: 0.3,
            align: "left",
            onClick: (d: any) => {
                historyDrawer.setDataId(d.id);
                setTerminalName(d.name);
            },
        },
        { key: "name", align: "left", flexGrow: 2 },
        { key: "password" },
        { key: "device_type" },
        { key: "status", type: "bool", align: "center" },
        { key: "can_receive_notifications", type: "bool" },
        { key: "online", type: "bool", align: "center" },
        optionsColumn({
            dp,
            edit: "/access/terminals/edit/:id",
            others: () => [
                {
                    label: (
                        <div className="d-flex align-items-center ">
                            <CIcon className="mr-2" path={iSignOut} />
                            <TText tkey="logout" />
                        </div>
                    ),
                    onClick: (d: ITerminal) => {
                        Ajax.post({ url: `terminal/${d.id}/logout` })
                            .then(() =>
                                toaster.push(
                                    <Message type="success" showIcon closable>
                                        <TText tkey="terminal_logout_success" />
                                    </Message>,
                                ),
                            )
                            .catch(() =>
                                toaster.push(
                                    <Message type="error" showIcon closable>
                                        <TText tkey="terminal_logout_error" />
                                    </Message>,
                                ),
                            );
                    },
                },
            ],
            canEdit: "terminal_manage_edit",
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("terminals"), dp.total)}
            <HeaderRight dp={dp} cm={cm} reload print />
            {auth.user?.owner === 1 && (
                <FiltersContainer dp={dp}>
                    <div className="flex-fill" />
                    <CreateButton to="/access/terminals/create" canCreate={"tax_create"} />
                </FiltersContainer>
            )}
            <DataTable dp={dp} columns={cm.columns} />
            <TerminalHistoryDrawer {...historyDrawer} terminalName={terminalName} />
        </div>
    );
};
