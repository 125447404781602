import { CheckboxProps } from "rsuite";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";
import InputMask from "react-input-mask";

interface IProps extends CFormGroupProps<CheckboxProps> {
    mask: string;
}

const MaskInputFormGroup: FCC<IProps> = ({ label, ...props }) => {
    const cprops: any = {
        render: ({ field: { name, value, onChange } }: any) => (
            <InputMask
                name={name}
                test-id={name}
                mask={props.mask}
                className="rs-input"
                value={value ?? ""}
                onChange={onChange}
            />
        ),
        ...props.compProps,
    };

    return <CFormGroup {...(props as any)} compProps={cprops} controlWrapperProps={props.controlWrapperProps} />;
};

export default MaskInputFormGroup;
