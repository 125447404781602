import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import TextFormGroup from "components/Form/TextFormGroup";
import { Col, Divider, Grid, IconButton, Row } from "rsuite";
import { iClose } from "assets/icons/Icons";
import CIcon from "../../../components/CIcon";
import { Colors } from "config/colors";
import CheckboxFormGroup from "../../../components/Form/CheckboxFormGroup";
import React from "react";
import { useCurrency } from "hooks/useCurrency";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

interface IProps {
    field: any;
    index: number;
    size: number;
    onRemove: (i: number) => void;
}

const TimerItem: FCC<IProps> = ({ index, field, onRemove, size }) => {
    const { watch, trigger, setValue } = useCFormContext();
    const pricePath = `setting.prices[${index}]`;
    const interval = watch("setting.interval");
    const price = watch(`setting.prices[${index}]`);
    const prevPrice = watch(`setting.prices[${index - 1}]`);
    const currency = useCurrency();
    const handleMinus = () => {
        let value = +price.from - +interval;

        if (price.from % +interval !== 0 || price.from < 0) {
            value = price.from - +(+price.from % interval);
        } else if (price.from - interval < 0) {
            value = 0;
        }
        setValue(`${pricePath}.from`, value);
        trigger(`${pricePath}.from`);
    };

    const handlePlus = () => {
        let value = +price.from + +interval;

        if (price.from % +interval || price.from < 0) {
            value = +price.from + +(interval - (price.from % interval));
        } else if (index > 0 && prevPrice.from && !price.from) {
            value = +prevPrice.from + +interval;
        }
        setValue(`${pricePath}.from`, value);
        trigger(`${pricePath}.from`);
    };
    const commonProps = (name: string, extra?: any): any => ({
        className: "",
        name: `setting.prices[${index}].${name}`,
        ...extra,
        compProps: {
            interval: interval,
            placeholder: t(name as LangKey),
            style: { width: 100 },
            defaultValue: field[name],
            type: "number",
            ...extra?.compProps,
        },
    });

    return (
        <div key={index} className={field.deleted_at ? "d-none" : "d-flex ml-0 mb-1"}>
            <Grid fluid className="interval-grid">
                <Row>
                    <Col md={24} sm={24} lg={24}>
                        <Divider />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={12} lg={12}>
                        <TextFormGroup
                            hideLabel
                            type="number"
                            name={`${pricePath}.from`}
                            postFix={t("minute_short")}
                            {...commonProps("from", {
                                compProps: {
                                    style: {
                                        height: 36,
                                    },
                                    type: "money",
                                    min: 0,
                                    spinner: true,
                                    textAlign: "center",
                                    interval: interval,
                                    className: "interval-input",
                                    increaseValue: (val: any) => handlePlus(),
                                    decreaseValue: (val: any) => handleMinus(),
                                },
                            })}
                            className="pr-2"
                        />
                    </Col>
                    <Col md={12} sm={12} lg={12}>
                        <div className="interval-right-side">
                            <span>
                                <TText tkey="mins_later" />
                            </span>
                            <IconButton
                                test-id={`${pricePath}-clear`}
                                style={{ height: "35px" }}
                                onClick={() => onRemove(index)}
                                icon={<CIcon path={iClose} className="text-danger" />}
                            />
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={12} lg={12}>
                        <TextFormGroup
                            type="number"
                            label={t("in_every")}
                            postFix={t("minute_short")}
                            name="setting.interval"
                            labelProps={{
                                style: {
                                    color: Colors.CadetGray,
                                    fontSize: "12px",
                                    fontWeight: 400,
                                },
                            }}
                            compProps={{
                                style: {
                                    height: 36,
                                    width: "100%",
                                },
                                disabled: true,
                                className: "interval-input",
                                "test-id": `${pricePath}-interval`,
                            }}
                            className="pr-2"
                        />
                    </Col>
                    <Col md={12} sm={12} lg={12}>
                        <TextFormGroup
                            label=" "
                            labelProps={{ style: { marginTop: "14px" } }}
                            {...commonProps("price", {
                                compProps: {
                                    style: { marginRight: 0 },
                                    type: "number",
                                    className: "interval-input",
                                },
                            })}
                            postFix={currency}
                            className="pl-2"
                        />
                    </Col>
                </Row>
                <Row>
                    <Col md={12} sm={12} lg={12}>
                        <CheckboxFormGroup
                            controlWrapperProps={{
                                style: { paddingLeft: 0, marginLeft: "-10px" },
                            }}
                            name={`${pricePath}.notification`}
                            label={t("notification")}
                        />
                    </Col>
                </Row>
            </Grid>
        </div>
    );
};

export default TimerItem;
