import { ValidationSchema } from "lib/custom-form-schema-resolver";
import { ruleset } from "lib/validation-rules";
import { t } from "lib/i18n";

export const IncentiveProgramSchema: ValidationSchema = {
    name: ruleset.required,
    performance_type: ruleset.required,
    criteria: {
        sales_bonus_rate: ruleset.combine([ruleset.required, ruleset.number, ruleset.gt(0), ruleset.lte(100)]),
    },
    assigned_roles: ruleset.combineIf(
        (val, vals, par) => vals.status && vals.assigned_staff?.length === 0,
        [ruleset.required, ruleset.minLength(1, t("role_or_staff_required"))],
    ),
    assigned_staff: ruleset.combineIf(
        (val, vals, par) => vals.status && vals.assigned_roles?.length === 0,
        [ruleset.required, ruleset.minLength(1, t("role_or_staff_required"))],
    ),
    selected_sale_types: ruleset.combineIf(
        (val, vals, par) => !val.includes("*"),
        [ruleset.required, ruleset.minLength(1)],
    ),
    active_hours: {
        end_time: ruleset.gtTime("start_time", t("start_date_can_not_be_great_end_date"), true),
    },
    active_dates: {
        end_date: ruleset.gteDate("start_date", t("start_date_can_not_be_great_end_date"), true),
    },
};
