import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { Button, IconButton, Modal } from "rsuite";

import CModal from "../Drawers/CModal";
import CIcon from "../CIcon";
import { iCheckBoxChecked } from "assets/icons/Icons";
import { BaseSyntheticEvent } from "react";

interface IProps {
    isLoading: boolean;
    onSubmit: (e: BaseSyntheticEvent, isSaveCreate?: boolean) => void;
    label?: string;
    isSaveCreate?: boolean;
    disabled?: boolean;
    confirmModal?: any;
    isIconBtn?: boolean;
}

const SubmitButtonGroup: FCC<IProps> = props => {
    const onSubmit = (e: BaseSyntheticEvent) => {
        if (props.confirmModal) {
            props.confirmModal.setDataId(0);
        } else {
            props.onSubmit(e, props.isSaveCreate);
        }
    };

    return (
        <>
            {props.isIconBtn ? (
                <IconButton onClick={onSubmit} color="green" icon={<CIcon path={iCheckBoxChecked} />} />
            ) : (
                <Button
                    onClick={onSubmit}
                    className="align-self-center mr-2"
                    color="green"
                    type="submit"
                    test-id={`create-form-save-btn${props.isSaveCreate ? "-and-new" : ""}`}
                    disabled={props.disabled}
                    loading={props.isLoading}
                >
                    {/*<Icon icon="check" className="mr-2" />*/}
                    <CIcon path={iCheckBoxChecked} className="mr-2 " />
                    {props.label || t("save")}
                </Button>
            )}
            {props.confirmModal && (
                <CModal {...props.confirmModal} backdrop title={t("confirm")}>
                    <Modal.Body className="pb-0">
                        <p>
                            <TText tkey="confirm_msg" />
                        </p>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button
                            loading={props.isLoading}
                            appearance="primary"
                            color="green"
                            onClick={e => {
                                props.onSubmit(e);
                                props.confirmModal.onClose?.({} as any);
                            }}
                        >
                            <TText tkey="yes" />
                        </Button>
                    </Modal.Footer>
                </CModal>
            )}
        </>
    );
};

export default SubmitButtonGroup;
