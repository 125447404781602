import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { Button, Drawer } from "rsuite";
import { setOpenProductSelector, setShouldShowSelected } from "redux/features/productsSelector/productsSelector";
import React from "react";

interface IProductSelectorFooterProps {
    onProductSelectorSave: ((selectedProductsIds: number[]) => void) | null;
}

const CProductsDrawerFooter: FCC<IProductSelectorFooterProps> = ({ onProductSelectorSave }) => {
    const dispatch = useAppDispatch();
    const { selectedProductIds, shouldShowSelected } = useAppSelector(state => state.productSelector);
    return (
        <Drawer.Actions>
            <div className="d-flex justify-content-between align-items-center p-3 ">
                <Button
                    disabled={!selectedProductIds.length && !shouldShowSelected}
                    onClick={() => dispatch(setShouldShowSelected(!shouldShowSelected))}
                    className="show-selected__btn"
                >
                    {shouldShowSelected ? t("go_back_to_products") : t("show_selected")}

                    {selectedProductIds.length ? (
                        <span className="all-products__count">{selectedProductIds.length}</span>
                    ) : null}
                </Button>

                <Button
                    // disabled={!selectedProductIds.length}
                    test-id="product-selector-save-button"
                    onClick={() => {
                        onProductSelectorSave?.(selectedProductIds);
                        dispatch(setOpenProductSelector(false));
                        // dispatch(clearState());
                    }}
                    className="rs-btn-green"
                >
                    <TText tkey="save" />
                </Button>
            </div>
        </Drawer.Actions>
    );
};
export default CProductsDrawerFooter;
