import { cn } from "lib/utils";
import { FC, ReactNode } from "react";

export type CBadgeColor = "orange" | "green" | "red" | "default";

interface ICBadgeProps {
    children: ReactNode;
    className?: string;
    color: CBadgeColor;
}

const colors: Record<CBadgeColor, string> = {
    default: "tw-bg-badge tw-text-badge-foreground",
    green: "tw-bg-badge-green tw-text-badge-green-foreground",
    orange: "tw-bg-badge-orange tw-text-badge-orange-foreground",
    red: "tw-bg-badge-red tw-text-badge-red-foreground",
};

export const CBadge: FC<ICBadgeProps> = ({ children, className, color }) => {
    return (
        <div
            className={cn(
                `tw-rounded-xl tw-h-6 tw-px-3 tw-items-center tw-justify-center tw-inline-flex tw-w-fit`,
                "tw-text-green-500",
                colors[color],
                className,
            )}
        >
            <div className={cn(`tw-text-xs tw-font-medium tw-mt-1`)}>{children}</div>
        </div>
    );
};
