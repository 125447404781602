import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { getProductName, toFixed, translateUnitName } from "lib/utils";
import _ from "lodash";
import Money from "components/mini/Money";
import { useTableHeight } from "hooks/useTableHeight";
import OperationProcessIndicator from "components/OperationProcessIndicator";
import { INVENTORY_STATUS_BY_ID } from "config/constants";
import React from "react";
import { RowDataType } from "rsuite-table/src/@types/common";
import { Colors } from "config/colors";
import { useCurrency } from "hooks/useCurrency";

interface IProps {
    r: IReceipt;
}

interface IReceiptDeductedStock {
    id: number;
    product: IProduct | undefined;
    quantity: number;
    storage: string;
    total_cost: number;
    isParent: number;
}

const ReceiptDeductedStock: FCC<IProps> = ({ r }) => {
    const stockOperation: IReceiptDeductedStock[] = [];
    const currency = useCurrency();
    const prepareDeductedStock = (list: any) => {
        const op = list?.stock_operations || [];
        const operations = _.map(
            _.groupBy(op || [], sp => sp.id),
            s =>
                _.map(
                    _.groupBy(s, sp => {
                        const hasDiffStorage = s.filter(
                            fff =>
                                fff.stock?.product_id === sp.stock?.product_id &&
                                fff.stock?.storage_id !== sp.stock?.product_id,
                        );
                        return hasDiffStorage.length > 1 ? sp.stock?.storage_id : sp.stock?.product_id;
                    }),
                    a => ({
                        ...a[0],
                        quantity: parseFloat(
                            toFixed(
                                a.reduce((fd, rt) => fd + rt.quantity, 0),
                                4,
                            ),
                        ),
                        total_cost: parseFloat(
                            toFixed(
                                a.reduce((fd, rt) => fd + rt.total_cost, 0),
                                4,
                            ),
                        ),
                    }),
                ),
        );

        const rps = _.isNil(list?.deleted_at) ? _.filter(list?.receipt_products, { deleted_at: null }) : [];
        rps.forEach((rp: IReceiptProduct) => {
            const rpStockOperations = operations
                .flat()
                .filter((rpStockOperation: any) => rpStockOperation.receipt_product_id === rp.id);

            stockOperation.push({
                id: rp.id,
                product: rp.product,
                quantity: rp.count,
                storage: _.uniq(
                    rpStockOperations.map((rpStockOperation: any) => rpStockOperation.stock?.storage?.name),
                ).join(" - "),
                total_cost: rp.cost,
                isParent: 1,
            });
            rpStockOperations.forEach((rpStockOperation: any) => {
                stockOperation.push({
                    id: rpStockOperation.id,
                    product: rpStockOperation.product,
                    quantity: rpStockOperation.quantity,
                    storage: rpStockOperation.stock?.storage?.name,
                    total_cost: rpStockOperation.total_cost,
                    isParent: 0,
                });
            });
        });
        return stockOperation;
    };

    const dp = useDataProvider({
        url: `receipt/${r.id}`,
        with: ["receipt_products.product.unit", "stock_operations.product", "stock_operations.stock.storage"],
        params: {
            trash: [
                2,
                ["receipt_products", 2],
                ["stock_operations.stock", 2],
                ["receipt_products.product", 2],
                ["stock_operations.product", 2],
                ["stock_operations.stock.storage", 2],
            ],
        },
        refineList: prepareDeductedStock,
    });
    const cm = useColumns<any>(`receipt-deducted-stock`, [
        {
            title: t("product_and_ingredients"),
            key: "product.name",
            align: "left",
            flexGrow: 2,
            render: d =>
                d.isParent ? (
                    <span className={d.product?.deleted_at ? "text-danger" : ""}>{getProductName(d.product)}</span>
                ) : (
                    <span className="pl-2 tw-text-[#8e8e93]"> - {getProductName(d.product)}</span>
                ),
        },
        {
            title: t("storage"),
            key: "storage",
            align: "left",
            getStyle: (d: any) => (!d.isParent ? { color: Colors.CoolGray } : {}),
            render: e => (e.isParent ? "" : e.storage),
        },
        {
            title: t("quantity"),
            key: "quantity",
            align: "left",
            render: d => (
                <span className={`${!d.isParent && "tw-text-[#8e8e93]"}`}>
                    {d.quantity} {d.product ? translateUnitName(d.product.unit) : ""}
                </span>
            ),
        },
        {
            title: t("gross_rate"),
            key: "gross_rate",
            align: "left",
            render: d => (d.total_cost ? toFixed(d.total_cost / d.quantity, 4) : 0) + ` ${currency}`,
        },
        {
            title: t("total"),
            type: "money",
            align: "left",
            key: "total_cost",
            getStyle: (d: any) => (!d.isParent ? { color: Colors.CoolGray } : {}),
            render: (d: any) => <Money symbol={false} value={d.total_cost} />,
            summary: () => (
                <Money
                    style={{ color: Colors.CobaltBlue }}
                    value={_.sumBy(
                        dp.data.filter((d: IReceiptDeductedStock) => d.isParent),
                        "total_cost",
                    )}
                />
            ),
        },
    ]);
    const height = useTableHeight(170);
    const status = (r.inventory_status || 0) as keyof typeof INVENTORY_STATUS_BY_ID;

    return (
        <div className="d-flex flex-column">
            <div style={{ margin: "10px 0 10px 10px" }}>
                <div
                    className="fit-content"
                    style={{ padding: "5px 12px", background: Colors.LightGray, borderRadius: "1rem" }}
                >
                    <TText tkey="status" />:{" "}
                    <OperationProcessIndicator
                        status={status}
                        style={{
                            color: "white",
                            backgroundColor: `${INVENTORY_STATUS_BY_ID[status].color}`,
                            borderRadius: "1rem",
                        }}
                        tagMode
                    >
                        <OperationProcessIndicator
                            iconStyle={{
                                fontSize: "0.7rem",
                                color: "white",
                                marginBottom: "-3px",
                            }}
                            status={status}
                            receipt={true}
                        />
                    </OperationProcessIndicator>
                </div>
            </div>
            <div className="drawerTree_db-container">
                <DataTable
                    noShadow
                    columns={cm.columns}
                    dp={dp}
                    hidePages
                    tableProps={{
                        headerHeight: 50,
                        autoHeight: false,
                        height: height,
                        isTree: true,
                        renderTreeToggle: (icon: React.ReactNode, rowData?: RowDataType) => {
                            return rowData?.children?.length && rowData?.children?.length > 0 ? icon : null;
                        },
                    }}
                />
            </div>
        </div>
    );
};

export default ReceiptDeductedStock;
