import { t } from "lib/i18n";
import React from "react";
import { UseDrawerReturn } from "components/Drawers/useDrawer";
import CDrawer from "../../../components/Drawers/CDrawer";
import DataTable from "../../../components/DataTable/DataTable";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { Colors } from "config/colors";

const CashShiftTransactionsDrawer: FCC<UseDrawerReturn> = props => {
    const dp = useDataProvider({
        url: "/finance/transaction",
        filters: {
            cash_shift_cid: ["cash_shift_cid", props.data?.cid],
            account_type: ["account_type", "CASH"],
        },
        with: ["category"],
    });
    const cm = useColumns<any>("cash-shift-transactions", [
        {
            key: "id",
            title: "Id",
        },
        {
            key: "operated_at",
            type: "datetime",
            multiline: true,
        },
        {
            key: "created_at",
            type: "datetime",
            multiline: true,
        },
        {
            title: t("category"),
            key: "category.name",
        },
        {
            key: "amount",
            render: d => (
                <div style={{ color: d.type === "IN" ? Colors.TropicalGreen : Colors.BrightRed }}>{d.amount}</div>
            ),
        },
    ]);

    return (
        <CDrawer title={t("cash_transactions")} {...props}>
            <DataTable dp={dp} columns={cm.columns} />
        </CDrawer>
    );
};

export default CashShiftTransactionsDrawer;
