import { t } from "lib/i18n";
import { Col } from "rsuite";
import PaymentTypeWidget from "./PaymentTypeWidget";
import ExpenseWidget from "./ExpenseWidget";
import AccountWidget from "./AccountWidget";
import CSection from "../../components/CSection";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import NoAccessWidget from "./NoAccessWidget";

const FinanceSection: FCC = () => {
    const auth = useAuth();

    return (
        <CSection title={t("finances")} rowProps={{ className: "mt-4" }}>
            <Col md={8}>
                {auth.hasAbility(["report_sell"]) ? (
                    <PaymentTypeWidget />
                ) : (
                    <NoAccessWidget
                        title={t("profit_by_payment_method")}
                        label={"no_permission"}
                        permissions={["report_sell"]}
                    />
                )}
            </Col>
            <Col md={8}>
                {auth.hasAbility(["finance_transaction_view"]) ? (
                    <ExpenseWidget />
                ) : (
                    <NoAccessWidget
                        title={t("expense_by_category_type")}
                        label={"no_permission"}
                        permissions={["finance_transaction_view"]}
                    />
                )}
            </Col>
            <Col md={8}>
                {auth.hasAbility(["finance_balance_view"]) ? (
                    <AccountWidget />
                ) : (
                    <NoAccessWidget
                        title={t("accounts")}
                        label={"no_permission"}
                        permissions={["finance_balance_view"]}
                    />
                )}
            </Col>
        </CSection>
    );
};
export default FinanceSection;
