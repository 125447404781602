import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { selectReceiptItems, updateReceiptItemPosition } from "redux/features/receiptConstructor";
import { useCallback } from "react";
import ReceiptItemList from "../common/ReceiptItemList";
import ReceiptPreviewView from "./ReceiptPreviewView";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import RConstructorHeaderButtons from "../common/RConstructorHeaderButtons";
import { ReceiptItemType } from "../types";
import RowHeaders from "../common/RowHeaders";

const ReceiptEditor: FCC = () => {
    const items = useAppSelector(selectReceiptItems);
    const dispatch = useAppDispatch();

    const handleDragAndDrop = useCallback(
        (result: DropResult) => {
            const { source, destination } = result;

            if (!destination) {
                return;
            }
            if (source.index === 1 || source.index === 0 || destination.index === 1 || destination.index === 0) return;
            if (source.index === destination.index) {
                return;
            }

            dispatch(updateReceiptItemPosition({ startIndex: source.index, endIndex: destination.index }));
        },
        [dispatch],
    );

    return (
        <div className="d-flex flex-row">
            <div className="w-50" style={{ minWidth: "580px" }}>
                <RConstructorHeaderButtons receiptType={ReceiptItemType.Receipt} />
                <RowHeaders />
                <DragDropContext onDragEnd={handleDragAndDrop}>
                    <ReceiptItemList receiptType={ReceiptItemType.Receipt} items={items} />
                </DragDropContext>
            </div>
            <ReceiptPreviewView />
        </div>
    );
};

export default ReceiptEditor;
