import { t } from "lib/i18n";
import Widget from "components/Widget";
import { useDataProvider } from "components/DataTable/useDataProvider";
import CChart from "components/Chart";
import { formatByType, getPointerLabel } from "lib/chart-utils";
import _ from "lodash";
import NoData from "components/NoData";
import { translateUnitName } from "lib/utils";
import { useDepot } from "hooks/useDepot";
import { IconButton } from "rsuite";
import CIcon from "components/CIcon";
import { iBarChart, iPieChart } from "assets/icons/Icons";
import { useCurrency } from "hooks/useCurrency";
import { useSetting } from "hooks/useSetting";
import { passCashRestartCheckStart } from "lib/passCashRestartCheckStart";
import { passCashRestartCheckEnd } from "lib/passCashRestartCheckEnd";

const TopGoodsWidget: FCC = () => {
    const [chartType, setChartType] = useDepot("TopGoodsWidget", "column");
    const cashRestart = useSetting("cash_restart")?.value;

    const dp = useDataProvider({
        url: "reports/product",
        with: ["category", "product.unit"],
        sort: ["count", "-1"],
        limit: 5,
        params: {
            date: [passCashRestartCheckStart(cashRestart), passCashRestartCheckEnd(cashRestart)],
        },
    });
    const btnProps = (name: string): any => ({
        size: "xs",
        appearance: name === chartType ? "primary" : undefined,
        onClick: () => setChartType(name),
    });

    const data = dp.data?.map((product: any) => ({
        y: product.count,
        name: product.product?.name,
        unit: product.product?.unit,
    }));
    const currency = useCurrency();
    return (
        <Widget
            title={t("most_selling_products")}
            headerButtons={
                <>
                    <IconButton className="mr-2" icon={<CIcon path={iBarChart} />} {...btnProps("column")} />
                    <IconButton className="mr-2" icon={<CIcon path={iPieChart} />} {...btnProps("pie")} />
                </>
            }
        >
            {_.isEmpty(data) ? (
                <NoData />
            ) : (
                <CChart
                    options={{
                        chart: { height: 200 },
                        xAxis: {
                            categories: data.map((m: any) => m.name),
                        },
                        tooltip: {
                            useHTML: true,
                            headerFormat: "<small>{point.key}</small><table>",
                            pointFormatter() {
                                const { unit } = this as any;
                                return getPointerLabel(this, "money", translateUnitName(unit as IUnit));
                            },
                            footerFormat: "</table>",
                        },
                        plotOptions: {
                            column: {
                                dataLabels: {
                                    enabled: true,
                                    formatter() {
                                        return formatByType("", this.y, currency);
                                    },
                                },
                            },
                        },
                    }}
                    series={[{ type: chartType, data, name: t("total_cash") } as any]}
                />
            )}
        </Widget>
    );
};

export default TopGoodsWidget;
