import TextFormGroup from "components/Form/TextFormGroup";
import { t } from "lib/i18n";
import { useCForm } from "hooks/useCForm";
import _ from "lodash";

import { ICDrawerProps } from "components/Drawers/CDrawer";
import CFormModal from "../../../components/Drawers/CFormModal";
import { CustomerGroupModel, dummyCustomerGroup } from "models/CustomerModel";

interface IProps extends ICDrawerProps {
    revalidate: () => void;
}

const EditCustomerGroupModal: FCC<IProps> = props => {
    const form = useCForm({
        url: "customers-group",
        model: CustomerGroupModel(),
        id: props.dataId || undefined,
        redirectUrl: false,
        dummyData: () => dummyCustomerGroup(),
    });

    return (
        <CFormModal
            {...props}
            size="sm"
            form={form}
            formProps={{ layout: "horizontal" }}
            title={t("create_by_name", { name: _.capitalize(t("customer_group")) })}
        >
            <div className="pb-4" style={{ marginBottom: 20 }}>
                <TextFormGroup name="name" focus={true} />
                <TextFormGroup name="discount_value" postFix="%" type="number" compProps={{ style: { width: 260 } }} />
            </div>
        </CFormModal>
    );
};

export default EditCustomerGroupModal;
