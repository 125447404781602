import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";

import CModal, { ICModalProps } from "components/Drawers/CModal";
import { useHistory } from "react-router-dom";
import { ASSETS_BASE_URL } from "config/main";
import CIcon from "../CIcon";
import { iPlus } from "assets/icons/Icons";

type IProps = ICModalProps;

export const CreateProductModal = (props: IProps) => {
    const history = useHistory();

    return (
        <CModal
            {...props}
            size="sm"
            testId="create-product-modal"
            // formProps={{ layout: "horizontal" }}
            title={t("create_product_type_create")}
        >
            <div>
                <div
                    test-id="create-dish"
                    onClick={() => history.push("/menu/dish/create")}
                    style={{ border: "1px solid #EAECF0", borderRadius: "8px" }}
                    className="d-flex align-items-center p-3 justify-content-between mt-5 tw-cursor-pointer"
                >
                    <div className="d-flex">
                        <img src={`${ASSETS_BASE_URL}/img/dishIcon.svg`} alt="" />
                        <div className="ml-3">
                            <h6>
                                <TText tkey="dish" />
                            </h6>
                            <p className="mt-1">
                                <TText tkey="dish_create_description" />
                            </p>
                        </div>
                    </div>
                    <CIcon path={iPlus} className="mr-2" />
                </div>
                <div
                    test-id="create-goods"
                    onClick={() => history.push("/menu/goods/create")}
                    style={{ border: "1px solid #EAECF0", borderRadius: "8px" }}
                    className="d-flex align-items-center p-3 justify-content-between mt-3 tw-cursor-pointer"
                >
                    <div className="d-flex">
                        <img src={`${ASSETS_BASE_URL}/img/goodsIcon.svg`} alt="" />
                        <div className="ml-3">
                            <h6>
                                <TText tkey="goods" />
                            </h6>
                            <p className="mt-1">
                                <TText tkey="goods_create_description" />
                            </p>
                        </div>
                    </div>
                    <CIcon path={iPlus} className="mr-2" />
                </div>
                <div
                    test-id="create-timer"
                    onClick={() => history.push("/menu/timer/create")}
                    style={{ border: "1px solid #EAECF0", borderRadius: "8px" }}
                    className="d-flex align-items-center p-3 justify-content-between mt-3 tw-cursor-pointer"
                >
                    <div className="d-flex">
                        <img src={`${ASSETS_BASE_URL}/img/timerIcon.svg`} alt="" />
                        <div className="ml-3">
                            <h6>
                                <TText tkey="timer" />
                            </h6>
                            <p className="mt-1">
                                <TText tkey="timer_create_description" />
                            </p>
                        </div>
                    </div>
                    <CIcon path={iPlus} className="mr-2" />
                </div>
            </div>
        </CModal>
    );
};
