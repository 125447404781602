import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import RadioFormGroup from "components/Form/RadioFormGroup";
import TextFormGroup from "components/Form/TextFormGroup";
import { UseFormReturnType } from "hooks/useCForm";
import { Radio } from "rsuite";
import { cBoolean } from "config/constants";

interface IProps {
    dailyEarnLimit: ISetting | undefined;
    form: UseFormReturnType;
    defaultValue: cBoolean;
    currencyName: string;
    descrLetItBe: string;
    descrDontBe: string;
    receiptName: string;
    radioName: string;
    currency: string;
    labelProps: any;
    descr: string;
    label: string;
}

const LoyaltyRadioLimitGroupField: FCC<IProps> = ({
    defaultValue,
    currencyName,
    receiptName,
    descrLetItBe,
    descrDontBe,
    labelProps,
    radioName,
    currency,
    dailyEarnLimit,
    label,
    descr,
    form,
}) => {
    const watchRadio = form.form.watch(`${radioName}.status`);
    return (
        <RadioFormGroup
            name={radioName}
            className="mt-4"
            label={label}
            labelProps={labelProps}
            labelDescription={descr}
            style={{ wordBreak: "break-all", wordWrap: "break-word" }}
            compProps={{ value: defaultValue }}
        >
            <div className="tw-flex-col">
                <div className="tw-mb-2">
                    <Radio
                        name={radioName}
                        value={0}
                        onChange={() => form.form.setValue(radioName, { ...dailyEarnLimit, status: 0 })}
                    >
                        <TText tkey="no_limit" />
                    </Radio>

                    <div className={`tw-pl-3 tw-text-secondary tw-text-xs tw-font-normal tw-break-words tw-text-wrap`}>
                        {descrDontBe}
                    </div>
                </div>
                <div>
                    <Radio
                        name={radioName}
                        value={1}
                        checked
                        onChange={() => form.form.setValue(radioName, { ...dailyEarnLimit, status: 1 })}
                    >
                        <TText tkey="set_limits" />
                    </Radio>
                    <div className={`tw-pl-3 tw-text-secondary tw-text-xs tw-font-normal tw-break-words tw-text-wrap`}>
                        {descrLetItBe}
                    </div>
                </div>
            </div>
            {watchRadio ? (
                <>
                    <div className="tw-flex tw-mt-2 tw-gap-4">
                        <div>
                            <TextFormGroup
                                errorPlacement="bottomStart"
                                focus={true}
                                name={receiptName}
                                postFix={t("receipt")}
                                type="number"
                                hideLabel
                                compProps={{
                                    className: "tw-w-input-xs",
                                }}
                            />
                        </div>
                        <div>
                            <TextFormGroup
                                errorPlacement="bottomStart"
                                focus={true}
                                hideLabel
                                type="number"
                                name={currencyName}
                                postFix={`${currency}`}
                                compProps={{
                                    className: "tw-w-input-xs",
                                }}
                            />
                        </div>
                    </div>
                </>
            ) : null}
        </RadioFormGroup>
    );
};

export default LoyaltyRadioLimitGroupField;
