import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { getLabel } from "components/Form/form-utils";
import { useTitle } from "components/Header/Title";
import Money from "components/mini/Money";
import { sumPath } from "lib/utils";
import React, { useMemo } from "react";
import DataTable from "../../../components/DataTable/DataTable";
import HeaderRight from "../../../components/Header/HeaderRight";
import { exportFile } from "lib/export-table";
import { useLocation } from "react-router-dom";
import nrInRoutings from "./nr-in-routings";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import RemoteCheckListFilter from "components/DataTable/Filters/RemoteCheckListFilter";
import { QuickLinks } from "components/Header/QuickLinks";
import LocalCheckListFilter from "../../../components/DataTable/Filters/LocalCheckListFilter";
import SourceFilter from "../../../components/DataTable/Filters/SourceFilter";

const NewReportsPaymentPage: FCC = () => {
    const loc = useLocation();

    const dp = useDataProvider({
        useUrl: true,
        useDates: true,
        url: "report/finance/dailyPayments",
        limit: 999,
        with: ["terminal"],
        filters: {
            indicator: ["indicator", 0],
            receipt_id: ["receipt_id", "!=", ""],
        },
        sort: ["date", "-1"],
    });

    const extractPaymentMethods = (data: any) => {
        const methods: Record<number, IPaymentMethod> = {};
        const totals: Record<number, number> = {};

        for (const row of data) {
            for (const [id, method] of Object.entries(row?.payment_methods as Record<number, IPaymentMethod>)) {
                if (totals?.[Number(id)]) {
                    totals[Number(id)] += method.total_amount ?? 0;
                } else {
                    totals[Number(id)] = method.total_amount ?? 0;
                }

                methods[Number(id)] = { ...method };
            }
        }
        return {
            methods: Object.values(methods),
            totals,
        };
    };

    const refineColumns: ITableColumnProps[] = useMemo(() => {
        if (dp?.data) {
            const extractPaymentMethodsData = extractPaymentMethods(dp.data ?? []);
            const paymentMethods: IPaymentMethod[] = extractPaymentMethodsData?.methods ?? [];
            return paymentMethods.map(method => {
                return {
                    title: getLabel(method.name),
                    key: `payment_methods.${method.id}.total_amount`,
                    type: "money",
                    render: (e: any) => {
                        return <Money value={e.payment_methods[method.id!]?.total_amount} />;
                    },
                    summary: () => {
                        return <Money value={extractPaymentMethodsData?.totals?.[method.id as number] ?? 0} />;
                    },
                    hide: method.id !== 1 && method.id !== 2,
                    minWidth: 130,
                };
            });
        } else {
            return [];
        }
    }, [dp.data]);

    const cm = useColumns<any>("report/finance/dailyPayments", [
        {
            title: t("date"),
            key: "date",
            type: "date",
        },
        ...refineColumns,
        {
            title: t("total"),
            key: "total_amount",
            numberRoundingLength: 2,
            summary: () => <Money value={sumPath("total_amount", dp.data ?? [])} />,
            type: "money",
        },
    ]);

    return (
        <div className="h-100">
            {useTitle(t("payments"), dp.total)}

            <QuickLinks inHeader={false} links={nrInRoutings(loc)} />

            <HeaderRight cm={cm} print export={() => exportFile(dp, cm.columns, t("payments"))} reload date dp={dp} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <LocalCheckListFilter
                        fields="terminal_id"
                        url="terminal"
                        pickerProps={{ placeholder: t("terminals") }}
                    />

                    <RemoteCheckListFilter
                        fields={"sale_type_id"}
                        remote="finance/saleType"
                        searchField="name"
                        pickerProps={{ placeholder: t("sales_type") }}
                    />
                    <RemoteCheckListFilter
                        fields="user_id"
                        remote={{
                            url: "user",
                            refineList: (d: any[]) => d?.filter(user => user.owner || !user.hide),
                        }}
                        labelField="username"
                        searchField="username"
                        pickerProps={{ placeholder: t("staff") }}
                    />
                    <SourceFilter />
                </div>
            </FiltersContainer>
            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                }}
                hidePages
            />
        </div>
    );
};

export default NewReportsPaymentPage;
