import { FC } from "react";
import { t } from "lib/i18n";

interface TTextProps {
    tkey: LangKey;
    params?: Record<string, string | number>;
    className?: string;
}

export const TText: FC<TTextProps> = ({ tkey, params, className }) => {
    return (
        <span data-tkey={tkey} className={className}>
            {t(tkey, params)}
        </span>
    );
};
