import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import TextFormGroup from "components/Form/TextFormGroup";

import ToggleFormGroup from "../../../../../components/Form/ToggleFormGroup";
import { CategoryTypeEnum } from "config/constants";
import { Col, Grid, Message, Row } from "rsuite";
import ProductAvailability from "components/ProductAvailability";
import MenuCategorySelectFormGroup from "../../../../../components/Form/CategorySelectFormGroup/MenuCategorySelectFormGroup";
import { useSetting } from "hooks/useSetting";
import { useEmenuLanguages } from "hooks/useEmenuLanguages";

const EditEDishTab: FCC = () => {
    const emenuLangs = useEmenuLanguages();
    const translateForLangs = useSetting("emenu_lang")?.extra;
    const meta_original_price = "meta.original_price";

    return (
        <div className="tw-p-4">
            <ToggleFormGroup name="emenu_hidden" label={t("hide")} />

            <MenuCategorySelectFormGroup
                compProps={{ cleanable: true }}
                name={"emenu_category_id"}
                types={[CategoryTypeEnum.PRODUCT]}
            />

            {!emenuLangs.length && (
                <div className="pb-3">
                    <Message showIcon type="info" children={t("emenu_lang_not_choosen")} style={{ maxWidth: "50%" }} />
                </div>
            )}

            {emenuLangs.map((lang: string, i: number) => (
                <div key={i + "setv"} className="rs-form-group" test-id={`lang-${lang}`}>
                    <label className="rs-control-label">{translateForLangs?.[lang]}</label>
                    <Grid fluid>
                        <Row gutter={100}>
                            <Col xs={12} sm={12} md={8} lg={6} className="pl-0 pr-1">
                                <TextFormGroup
                                    name={`meta.langs.${lang}.name`}
                                    label={false}
                                    controlWrapperProps={{ className: "w-100" }}
                                    compProps={{ placeholder: t("name"), className: "w-100" }}
                                    errorPlacement="rightEnd"
                                />
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} className="pl-0 pr-1">
                                <TextFormGroup
                                    name={`meta.langs.${lang}.description`}
                                    label={false}
                                    controlWrapperProps={{ className: "w-100" }}
                                    compProps={{ placeholder: t("description"), className: "w-100" }}
                                    errorPlacement="rightEnd"
                                />
                            </Col>
                        </Row>
                    </Grid>
                </div>
            ))}

            <ProductAvailability />

            <div className="rs-form-group">
                <label className="rs-control-label">
                    <TText tkey="original_price" />
                </label>
                <Grid fluid>
                    <Row gutter={100}>
                        <Col xs={12} sm={12} md={8} lg={6} className="pl-0 pr-1">
                            <TextFormGroup
                                name={`${meta_original_price}`}
                                label={false}
                                type="number"
                                controlWrapperProps={{ className: "w-100" }}
                                compProps={{ placeholder: t("original_price"), className: "w-100" }}
                                errorPlacement="rightEnd"
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default EditEDishTab;
