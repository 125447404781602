import { t } from "lib/i18n";
import React, { FC, useEffect } from "react";
import CModal from "../../../../components/Drawers/CModal";
import { useDrawer } from "components/Drawers/useDrawer";
import { useSelector } from "react-redux";
import { changeModalState, modalState } from "redux/features/webCashierAdditionals";
import ProductPriceModal from "./Modals/ProductPriceModal";
import ProductDiscountModal from "./Modals/ProductDiscountModal";
import ReceiptDiscountModal from "./Modals/ReceiptDiscountModal";
import ProductSellerModal from "./Modals/ProductSellerModal";
import ProductGiftModal from "./Modals/ProductGiftModal";
import ProductPortionModal from "./Modals/ProductPortionModal";
import ProductWarningModal from "./Modals/ProductWarningModal";
import { useAppDispatch } from "hooks/useRedux";

const modals: Record<string, any> = {
    default: null,
    price: ProductPriceModal,
    gift: ProductGiftModal,
    porsion: ProductPortionModal,
    seller: ProductSellerModal,
    receiptDiscount: ReceiptDiscountModal,
    productDiscount: ProductDiscountModal,
    warning: ProductWarningModal,
};

const titles: Record<string, any> = {
    price: t("change_price"),
    gift: t("make_a_gift"),
    porsion: t("change_portion"),
    seller: t("select_seller"),
    receiptDiscount: t("add_discount"),
    productDiscount: t("add_discount"),
};

const ReceiptModals: FC = () => {
    const { isOpen, ...modal } = useDrawer();
    const dispatch = useAppDispatch();
    const _modalState = useSelector(modalState);

    useEffect(() => {
        modal.setIsOpen(_modalState.open);
    }, [_modalState]);

    const Comp = modals[_modalState?.type];

    const closeModal = () => {
        dispatch(changeModalState({ ..._modalState, open: false }));
    };

    if (!isOpen) return null;

    return (
        <CModal
            {...modal}
            isOpen={isOpen}
            onClose={() => closeModal()}
            title={_modalState?.title}
            className="receipt-modals"
            testId="receipt-modals"
            backdrop="static"
            size="xs"
        >
            {Comp ? <Comp detail={_modalState} /> : null}
        </CModal>
    );
};

export default ReceiptModals;
