import { CanvasPrinter } from "@cloposcom/escpos-byte";

export interface IReceiptItem {
    id?: boolean;
    type: IReceiptItemType;
    value: string;
    status?: boolean;
    isCustom?: boolean;
    textSize?: 1 | 1.5 | 2 | 3;
    isBold?: boolean;
    showLabel?: boolean;
    align?: "center" | "left" | "right" | "space-between";
    description?: string | boolean;
    default?: boolean;
    tax?: boolean;
    barcode?: boolean;
    discount?: boolean;
    balance?: boolean;
    phone?: boolean;
    address?: boolean;
    price?: string;
    modificator?: boolean;
    gov_code?: boolean;
    total?: string;
    owner?: boolean;
    comment?: boolean;
    receiptType?: ReceiptItemType; // for using component in receipt constructor and station receipt constructor
    uniqueId: string;
}

export interface IGenerateProps {
    printer: CanvasPrinter;
    items: IReceiptItem[];
    currency: string;
    logo?: string;
    brand?: string;
    phone?: string;
    address?: string;
}

export type IReceiptItemType =
    | "brandLogo"
    | "brandName"
    | "gift"
    | "delivery"
    | "receiptNumber"
    | "dailyId"
    | "monthlyId"
    // | "receiptDetails"
    | "Cashier"
    | "Waiter"
    | "openDate"
    | "closedDate"
    | "saleType"
    | "receiptProduct"
    | "tax"
    | "serviceCharge"
    | "brandPhone"
    | "discount"
    | "subtotal"
    | "receiptTotal"
    | "address"
    | "paymentMethod"
    | "line"
    | "qrCode"
    | "brandAddress"
    | "brandCity"
    | "textArea"
    | "whiteSpace"
    | "customer"
    | "description"
    | "taxDetail"
    | "receipt_owner"
    | "guestCount"
    | "courier"
    | "ByClopos"
    //this for station receipt
    | "receiptInfo"
    | "date"
    | "pos"
    | "station"
    | "products"
    | "stationComment"
    | "orderNumber";

//change font size in receipt constructor and station receipt constructor
export const enum ReceiptFontSize {
    Small = 1,
    Large = 2,
    Medium = 1.5,
    ExtraLarge = 3,
}

// these for using component in receipt constructor and station receipt constructor
export const enum ReceiptItemType {
    Receipt = "receipt",
    StationReceipt = "stationReceipt",
}
