import { Colors } from "config/colors";

const styleForDots = {
    background: "white",
    margin: "-12px 4px 0 5px",
    borderRadius: "50%",
    width: "18px",
    border: `1px solid ${Colors.SilverGray}`,
};
const dotsForReceipt = (props: { pos: string }) => {
    return (
        <div
            style={
                props.pos === "top"
                    ? { position: "absolute", left: "19px", height: "12px", zIndex: 2, display: "flex", top: "16px" }
                    : { position: "absolute", zIndex: 2, display: "flex", bottom: 0, height: "12px" }
            }
        >
            <div style={{ width: "100%", top: "-12px", height: "12px", position: "absolute", background: "white" }} />
            {Array.from(Array(12).keys()).map(dot => (
                <div style={styleForDots} key={dot} />
            ))}
        </div>
    );
};
export default dotsForReceipt;
