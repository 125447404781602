import { t } from "lib/i18n";
import { CanvasPrinter } from "@cloposcom/escpos-byte";
import { IGenerateProps, IReceiptItem, ReceiptFontSize } from "../types";

//returned only name for showing left side of constructor
export const textForDADTitle: Record<string, string> = {
    brandLogo: t("brand_logo"),
    brandName: t("brand_name"),
    delivery: t("delivery"),
    receiptDetails: t("receipt_details"),
    receiptNumber: t("receipt_number_constructor"),
    dailyId: t("daily_id"),
    monthlyId: t("monthly_id"),
    Cashier: t("cashier"),
    receipt_owner: t("receipt_owner"),
    Waiter: t("waiter"),
    openDate: t("open_date"),
    closedDate: t("closed_date"),
    saleType: t("sale_type_table"),
    receiptProduct: t("receipt_product"),
    tax: t("tax"),
    serviceCharge: t("service_charge"),
    brandAddress: t("brand_address"),
    brandCity: t("brand_city"),
    brandPhone: t("brand_phone"),
    discount: t("discount"),
    subtotal: t("subtotal"),
    receiptTotal: t("sum_total"),
    address: t("address"),
    gift: t("gift"),
    line: "--------------------------------------------",
    qrCode: t("qr_code"),
    whiteSpace: t("white_space"),
    paymentMethod: t("payment_method"),
    customer: t("customer"),
    description: t("description"),
    ByClopos: t("love_by_clopos"),
    taxDetail: t("tax_info"),
    guestCount: t("guest_count"),
    courier: t("courier"),
    receiptInfo: t("receipt_info"),
    date: t("date"),
    pos: t("pos_terminal"),
    station: t("station"),
    products: t("products"),
    stationComment: t("add_station_comment"),
    orderNumber: t("order_number"),
};

export const generateReceipt = async (props: IGenerateProps) => {
    const { printer, items, logo, brand, currency, phone, address } = props;

    printer.feed(1);

    printer.fontSize(1);
    for (const item of items) {
        if (item.status) {
            await designForType(item, printer, logo, brand, currency, phone, address);
        }
    }
    return printer.canvas;
    // return items.map(item => designForType(item, printer));
};

export async function loadFont(): Promise<void> {
    const font = new FontFace("Receipt-Font", `url("/fonts/NotoSansMono_Condensed-Light.ttf")`, {
        style: "normal",
    });
    // wait for font to be loaded
    await font.load();
    document.fonts.add(font);
}

const itemAlign: Record<"center" | "left" | "right", "CT" | "LT" | "RT"> = {
    center: "CT",
    left: "LT",
    right: "RT",
};
export const designForType = async (
    item: IReceiptItem,
    p: CanvasPrinter,
    logo?: string,
    brand?: string,
    currency?: string,
    phone?: string,
    address?: string,
) => {
    if (item.textSize === ReceiptFontSize.ExtraLarge) {
        p.fontSize(3);
    } else if (item.textSize === ReceiptFontSize.Large) {
        p.fontSize(2);
    } else if (item.textSize === ReceiptFontSize.Medium) {
        p.fontSize(1.5);
    } else {
        p.fontSize(1);
    }
    switch (item.type) {
        case "brandLogo":
            try {
                if (logo) {
                    p.fontSize(0.2);
                    await p.image(logo);
                    p.fontSize(1);
                }
                p.feed(1);
            } catch (e) {
                console.log(e);
            }
            break;
        case "brandName":
            if (brand) {
                p.longText(brand, "CT", "B");
            } else {
                p.longText("Brand Name", "CT", "B");
            }

            break;
        case "receiptNumber":
            p.longText(`${t("receipt")}: 1234567890`, "LT");

            break;

        case "dailyId":
            p.longText(`${t("daily_id")}: 12`, "LT");

            break;

        case "monthlyId":
            p.longText(`${t("monthly_id")}: 123`, "LT");

            break;

        case "saleType":
            p.longText(`${t("table")}: ${t("table")} 10`, "LT");

            break;

        case "closedDate":
            p.pair(t("date"), "June 29, 2023");

            break;
        case "Cashier":
            p.longText(`${t("cashier")}: Azik`, "LT");
            break;
        case "receipt_owner":
            p.longText(`${t("owner")}: Azik`, "LT");
            break;
        case "courier":
            p.longText(`${t("courier")}: Azik`, "LT");
            break;
        // case "Waiter":
        //     p.longText("Waiter", "LT");
        //     break;
        case "openDate":
            p.longText(`${t("open_date")}: 12.01.2023`, "LT");
            break;

        case "receiptProduct": {
            const subText = `${item.modificator ? "Size:M, Extra:Gauda Chess" : ""}
            ${item.barcode ? t("barcode") + ": 9 87654 32109 8" : ""} 
            ${item.discount ? t("discount") + ": 6.00" + currency : ""} 
            ${item?.tax ? t("tax") + ": 3.20" + currency : ""} 
            ${item.gov_code ? t("gov_code") + ": 10202" : ""}`.trim();

            const subTextCoffee = `${item.modificator ? "With: Milk" : ""}
            ${item.discount ? t("discount") + ": 8.00 " + currency : ""} 
            ${item?.tax ? t("tax") + ": 7.20" + currency : ""} 
            ${item.gov_code ? t("gov_code") + ": 10202" : ""}
            `.trim();

            const subTextFries = `
            ${item.modificator ? "With: Milk" : ""}
            ${item.barcode ? t("barcode") + ": 987654321098" : ""} 
            ${item.gov_code ? t("gov_code") + ": 10202" : ""}`.trim();

            const subTextKetchup = `${item.barcode ? t("barcode") + ": 987654321098" : ""} 
            ${item.gov_code ? t("gov_code") + ": 10202" : ""}`.trim();

            let pizzaPrice, coffeePrice, pizzaTotal, coffeeTotal;

            if (item.price === "subtotal") {
                pizzaPrice = "18.39";
                coffeePrice = "32.29";
            } else if (item.price === "total") {
                pizzaPrice = "19.99";
                coffeePrice = "39.99";
            } else if (item.price === "subtotal+tax-discount") {
                pizzaPrice = "16.99";
                coffeePrice = "31.99";
            } else {
                pizzaPrice = "15.69";
                coffeePrice = "25.79";
            }

            if (item.total === "subtotal") {
                pizzaTotal = "38.78";
                coffeeTotal = "32.29";
            } else if (item.total === "total") {
                pizzaTotal = "39.98";
                coffeeTotal = "39.99";
            } else if (item.total === "subtotal+tax-discount") {
                pizzaTotal = "33.98";
                coffeeTotal = "31.99";
            } else {
                pizzaTotal = "31.38";
                coffeeTotal = "25.79";
            }

            p.table([
                [
                    { text: t("product"), align: "LT" },
                    { text: t("qty"), align: "RT" },
                    { text: t("price"), align: "RT" },
                    { text: t("total"), align: "RT" },
                ],

                [
                    {
                        text: "Pizza",
                        subText: subText.length ? (subText as any) : undefined,
                        align: "LT",
                    },
                    { text: "2", align: "RT" },
                    { text: pizzaPrice + currency, align: "RT" },
                    { text: pizzaTotal + currency, align: "RT" },
                ],
                [
                    {
                        text: "Coffee",
                        subText: subTextCoffee.length ? subTextCoffee : undefined,
                        align: "LT",
                    },
                    { text: "1", align: "RT" },
                    { text: coffeePrice + currency, align: "RT" },
                    { text: coffeeTotal + currency, align: "RT" },
                ],
                [
                    {
                        text: "Fries",
                        subText: subTextFries.length ? subTextFries : undefined,
                        align: "LT",
                    },
                    { text: "1", align: "RT" },
                    { text: "49.99" + currency, align: "RT" },
                    { text: "49.99" + currency, align: "RT" },
                ],
                [
                    {
                        text: "Ketchup ( Gift )",
                        subText: subTextKetchup.length ? subTextKetchup : undefined,
                        align: "LT",
                    },
                    { text: "2", align: "RT" },
                    { text: "1" + currency, align: "RT" },
                    { text: "0" + currency, align: "RT" },
                ],
            ]);
            p.drawLine(true);
            break;
        }

        case "tax":
            p.pair(t("tax"), "10.40" + currency);
            break;

        case "serviceCharge":
            p.pair(t("service_charge"), "2.50" + currency);
            break;

        case "discount":
            p.pair(t("discount"), "14.00" + currency);
            break;

        case "subtotal":
            if (item.value === "subtotal") {
                p.pair(t("subtotal"), "119.56" + currency);
            } else if (item.value === "total") {
                p.pair(t("subtotal"), "129.96" + currency);
            } else if (item.value === "subtotal+tax+gift") {
                p.pair(t("subtotal"), "131.96" + currency);
            }
            break;
        case "gift":
            p.pair(t("gift"), "2" + currency);

            break;

        case "receiptTotal":
            p.fontSize(2);

            p.pair(t("sum_total"), "123.86" + currency, false, "B", "B");
            p.fontSize(1);
            p.drawLine(true);
            break;

        case "brandAddress":
            p.pair(t("brand_address"), address || "123 Main Street, City, State, Zip Code", true);

            break;
        case "brandCity":
            p.pair(t("brand_city"), "Baku", true);

            break;
        case "paymentMethod":
            p.longText(t("payment_method"), "CT");
            p.pair(t("cash"), "67.35" + currency);
            p.pair(t("card"), "56.51" + currency);
            p.drawLine(true);
            break;

        case "line":
            p.drawLine(true);
            break;

        case "qrCode":
            p.printQRCode("clopos.com");
            break;

        case "textArea":
            if (item.align) {
                if (item.align === "space-between") {
                    p.pair(t("name"), "Marik bey", true);
                } else {
                    p.longText(item.value, itemAlign[item.align]);
                }
            }
            break;

        case "whiteSpace":
            p.feed(1);

            break;
        case "delivery":
            p.pair(t("delivery"), "5.00" + currency);

            break;
        case "brandPhone":
            p.pair(t("brand_phone"), phone || "", true);

            break;

        case "customer":
            p.longText(t("customer_details") + ":");
            p.pair(t("name"), "John Smith", true);
            if (item.balance) p.pair(t("balance"), "50.00" + currency, true);
            if (item.phone) p.pair(t("phone"), "(987) 654-3210", true);
            if (item.address) p.pair(t("address"), "456 Elm Street, City, State, Zip Code", true);
            if (item.description) p.pair(t("description"), "description", true);
            p.drawLine(true);

            break;
        case "taxDetail":
            p.longText(t("tax_info") + ":");
            p.pair(t("simplified_tax"), "(8%): 3.20" + currency, true);
            p.pair(t("vat_tax"), "(18%): 7.20" + currency, true);
            p.drawLine(true);

            break;
        case "guestCount":
            p.longText(t("guest_count") + ":" + "3");
            break;
        case "receiptInfo":
            p.longText(`#9612 ${item.owner ? "Clopos Test" : ""}`, "CT");
            break;
        case "date":
            p.longText(t("date") + ":" + `15 yanvar 11:18`);
            break;

        case "pos":
            p.longText(t("pos_terminal") + ":" + `POS 1`);
            break;
        case "station":
            p.longText(t("station") + ":" + `Station 1`);
            break;
        case "products":
            p.drawLine();
            p.pair("Pomidor Sorbasi", "X1", true);
            p.longText("test comment for product");
            p.pair("Brokoli Sorbasi", "X1", true);
            p.longText("Duzlu olsun");
            p.pair("Mecimek Sorbasi", "X1", true);
            p.drawLine();
            break;
        case "stationComment":
            p.longText("test comment for station");
            break;
        case "orderNumber":
            p.longText(t("order_number") + ":" + "WT-15", "LT");

            break;
        default:
            if (textForDADTitle[item.type]) {
                p.longText(textForDADTitle[item.type], "CT");
            }
            break;
    }
};
export const allowedTypesForChangeFontSize = [
    "receiptProduct",
    "brandName",
    "receiptNumber",
    "dailyId",
    "monthlyId",
    "saleType",
    "Cashier",
    "receipt_owner",
    "gift",
    "subtotal",
    "discount",
    "tax",
    "serviceCharge",
    "delivery",
    "customer",
    "description",
    "guestCount",
    "courier",
    "products",
    "receiptInfo",
    "date",
    "pos",
    "guestCount",
    "station",
    "products",
    "stationComment",

    /**
     * deyilenlere gore evveller taxDetailde textSize olub, amma problem cixarib, ona gore de textSize secimini silibler
     * amma bu defe de hansisa mushteride textSize 2,3 olanlar var idise, onlar o cur qalib. onlarda cekde cox boyuk gorunur yazilar.
     * uzun muddet sonra bezi mushteriler reportlamaga bashladilar ki, text size cox boyukdu. sorgulamaga bashladiq ki,
     * ne ucun textSize silmishik ve cavabini tapa bilmedik. ona gore qaytaririq geri bu secimi.
     */
    "taxDetail",
];
export const fontSizeOptions = [
    {
        label: t("kds_small"),
        value: ReceiptFontSize.Small,
    },
    {
        label: t("kds_medium"),
        value: ReceiptFontSize.Medium,
    },
    { label: t("kds_large"), value: ReceiptFontSize.Large },
    { label: t("kds_extra_large"), value: ReceiptFontSize.ExtraLarge },
];
