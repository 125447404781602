import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import TextFormGroup from "components/Form/TextFormGroup";
import { CategoryTypeEnum, Language } from "config/constants";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import { Col, Grid, Message, Row } from "rsuite";
import { productName } from "lib/data-utils";
import ProductAvailability from "components/ProductAvailability";
import MenuCategorySelectFormGroup from "components/Form/CategorySelectFormGroup/MenuCategorySelectFormGroup";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import { useEmenuLanguages } from "hooks/useEmenuLanguages";
import React from "react";

const EditEGoodsTab: FCC = props => {
    const emenuLangs = useEmenuLanguages();
    const { watch, getValues } = useCFormContext();
    const has_modifications = watch("has_modifications");
    const modifications = getValues("modifications");
    const meta_original_price = "meta.original_price";

    return (
        <div className="tw-p-4">
            <ToggleFormGroup name="emenu_hidden" label={t("hide")} />

            <MenuCategorySelectFormGroup
                label={t("category")}
                name={"emenu_category_id"}
                compProps={{
                    cleanable: true,
                }}
                types={[CategoryTypeEnum.PRODUCT]}
            />

            {!emenuLangs.length && (
                <div className="pb-3">
                    <Message showIcon type="info" children={t("emenu_lang_not_choosen")} style={{ maxWidth: "50%" }} />
                </div>
            )}

            {emenuLangs.map((lang: string, i: number) => (
                <div className="rs-form-group">
                    <label className="rs-control-label">{(Language as any)[lang]}</label>
                    <Grid fluid>
                        <Row gutter={100}>
                            <Col xs={12} sm={12} md={8} lg={6} className="pl-0 pr-1">
                                <TextFormGroup
                                    name={`meta.langs.${lang}.name`}
                                    label={false}
                                    controlWrapperProps={{ className: "w-100" }}
                                    compProps={{ placeholder: t("name"), className: "w-100" }}
                                    errorPlacement="rightEnd"
                                />
                            </Col>
                            <Col xs={12} sm={12} md={8} lg={6} className="pl-0 pr-1">
                                <TextFormGroup
                                    name={`meta.langs.${lang}.description`}
                                    label={false}
                                    controlWrapperProps={{ className: "w-100" }}
                                    compProps={{ placeholder: t("description"), className: "w-100" }}
                                    errorPlacement="rightEnd"
                                />
                            </Col>
                        </Row>
                    </Grid>
                </div>
            ))}

            {has_modifications &&
                modifications.map((modification: IVariant, index: number) => (
                    <div className="rs-form-group pb-3">
                        <label className="rs-control-label" style={{ display: "block", float: "none" }}>
                            {productName(modification)}
                        </label>
                        <hr className="mt-0" />
                        <div>
                            {emenuLangs.map((lang: string, i: number) => (
                                <div className="rs-form-group" key={i}>
                                    <label className="rs-control-label">{(Language as any)[lang]}</label>
                                    <Grid fluid>
                                        <Row gutter={100}>
                                            <Col xs={12} sm={12} md={8} lg={6} className="pl-0 pr-1">
                                                <TextFormGroup
                                                    name={`modifications[${index}].meta.langs.${lang}.name`}
                                                    controlWrapperProps={{ className: "w-100" }}
                                                    label={false}
                                                    compProps={{ placeholder: t("name"), className: "w-100" }}
                                                    errorPlacement="rightEnd"
                                                />
                                            </Col>
                                            <Col xs={12} sm={12} md={8} lg={6} className="pl-0 pr-1">
                                                <TextFormGroup
                                                    name={`modifications[${index}].meta.langs.${lang}.description`}
                                                    controlWrapperProps={{ className: "w-100" }}
                                                    label={false}
                                                    compProps={{
                                                        placeholder: t("description"),
                                                        className: "w-100",
                                                    }}
                                                    errorPlacement="rightEnd"
                                                />
                                            </Col>
                                        </Row>
                                    </Grid>
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            <ProductAvailability />
            <div className="rs-form-group">
                <label className="rs-control-label">
                    <TText tkey="original_price" />
                </label>
                <Grid fluid>
                    <Row gutter={100}>
                        <Col xs={12} sm={12} md={8} lg={6} className="pl-0 pr-1">
                            <TextFormGroup
                                name={`${meta_original_price}`}
                                label={false}
                                type="number"
                                controlWrapperProps={{ className: "w-100" }}
                                compProps={{ placeholder: t("original_price"), className: "w-100" }}
                                errorPlacement="rightEnd"
                            />
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
    );
};

export default EditEGoodsTab;
