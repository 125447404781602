import { t } from "lib/i18n";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "../../../components/DataTable/DataTable";
import { useData } from "hooks/useData";
import { translateUnitName } from "lib/utils";
import CIcon from "../../../components/CIcon";
import { iSpinner } from "assets/icons/Icons";
import { Trash } from "config/constants";

interface IProps {
    dataId?: any;
    unit?: IUnit;
}

const StockHistory: FCC<IProps> = ({ dataId, unit }) => {
    const dp = useData(!!dataId && `data-state`, {
        params: { trash: Trash.TrashAndActive, limit: 999 },
        filters: [
            ["model_type", "PRODUCT"],
            ["model_id", dataId],
        ] as any,
        with: ["storage"],
    });

    const cm = useColumns<any>(`stock-history`, [
        {
            title: t("date"),
            key: "updated_at",
            type: "datetime",
            align: "left",
            flexGrow: 3,
            multiline: true,
            reverse: true,
        },
        {
            title: t("storage"),
            key: "storage.name",
            align: "left",
        },
        { title: t("qty"), key: "value", render: e => `${e.value} ${translateUnitName(unit)}` },
    ]);

    return (
        <div className="d-flex">
            <div className="flex-fill border-left">
                {dp.data ? (
                    <DataTable hidePages noShadow columns={cm.columns} dp={{ data: dp.data } as any} />
                ) : (
                    <CIcon path={iSpinner} spin className="m-3" />
                )}
            </div>
        </div>
    );
};

export default StockHistory;
