import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import { ICDrawerProps } from "components/Drawers/CDrawer";
import TaxesDrawer from "components/Drawers/TaxesDrawer";
import { useDrawer } from "components/Drawers/useDrawer";
import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useData } from "hooks/useData";
import { useTableHeight } from "hooks/useTableHeight";
import _ from "lodash";
import { Button } from "rsuite";
import { iCheckBoxChecked, iClose } from "assets/icons/Icons";
import CIcon from "../../../components/CIcon";
import InfoWhisper from "../../../components/mini/InfoWhisper";
import Money from "../../../components/mini/Money";
import { Colors } from "config/colors";
import RPname from "./misc/RPname";
import ReceiptDetailSubInfo from "./ReceiptDetailSubInfo";
import { useCurrency } from "hooks/useCurrency";

interface IProps extends ICDrawerProps {
    r: IReceipt;
}

const ReceiptDetails: FCC<IProps> = ({ r, ...props }) => {
    const modal = useDrawer();

    const receipt = useData<IReceipt>(`receipt/${r.id}`, {
        // dataPath: "data.receipt_products",
        params: {
            trash: [
                2,
                ["receipt_products", 2],
                ["receipt_products.product", 2],
                ["receipt_products.modificators", 2],
                ["receipt_products.modificators.modificator_group", 2],
            ],
        },
        refine: (receipt: IReceipt) => {
            if (!_.isNil(receipt)) {
                const rps = _.isNil(receipt?.deleted_at)
                    ? _.filter(receipt?.receipt_products, { deleted_at: null })
                    : receipt?.receipt_products;

                receipt.receipt_products = _.orderBy(rps, ["created_at"]) as any[];
            }
            return receipt;
        },
        with: [
            "receipt_products.product.unit",
            "receipt_products.product.station",
            "receipt_products.modificators.modificator_group",
            "hall",
            "table",
            "sale_type",
            "receipt_products.taxes.tax",
        ],
    });
    const discountProduct = (before: number, after: number) =>
        before !== after ? (
            <div className="d-flex justify-content-end">
                <Money
                    style={{ position: "absolute", top: 5, textDecoration: "line-through", fontSize: 12 }}
                    value={before}
                />
                <Money style={{ position: "absolute", top: 22 }} value={after} />
            </div>
        ) : (
            <Money value={after} />
        );

    const currency = useCurrency();

    const cm = useColumns<any>(`receipt-details`, [
        {
            title: t("product"),
            key: "product.name",
            align: "left",
            flexGrow: 5,
            minWidth: 380,
            fullText: true,
            render: (d?: any) => {
                return (
                    <>
                        <InfoWhisper
                            infoKey={"info"}
                            infoItem={
                                <>
                                    <div>
                                        <span className="d-flex">
                                            <span style={{ fontSize: 11 }}>
                                                <TText tkey="terminal_service_calc" />
                                            </span>
                                            {d.meta?.product?.ignore_service_charge ? (
                                                <CIcon
                                                    style={{ color: Colors.BrightRed, marginLeft: 4 }}
                                                    path={iClose}
                                                />
                                            ) : (
                                                <CIcon
                                                    style={{ color: Colors.TropicalGreen, marginLeft: 4 }}
                                                    path={iCheckBoxChecked}
                                                />
                                            )}
                                        </span>

                                        <span className="d-flex">
                                            <span style={{ fontSize: 11 }}>
                                                <TText tkey="discountable" />
                                            </span>
                                            {d.meta?.product?.discountable ? (
                                                <CIcon
                                                    style={{ color: Colors.TropicalGreen, marginLeft: 4 }}
                                                    path={iCheckBoxChecked}
                                                />
                                            ) : (
                                                <CIcon
                                                    style={{ color: Colors.BrightRed, marginLeft: 4 }}
                                                    path={iClose}
                                                />
                                            )}
                                        </span>
                                    </div>
                                </>
                            }
                        >
                            <RPname d={d} />
                        </InfoWhisper>
                    </>
                );
            },
        },
        {
            title: t("station"),
            key: "product.station.name",
            render: (d?: any) => {
                // It was taking from product.station.name but it can change afterwards, so we started to put it in meta
                return d?.meta?.product?.station?.name ?? d?.product?.station?.name;
            },
        },
        {
            title: t("quantity"),
            key: "count",
            align: "right",
            render: d => <Money unit={d.product?.unit} value={d?.count} />,
        },
        {
            title: t("cost"),
            key: "cost",
            type: "money",
            currency,
            summary: () => {
                return <Money value={receipt.data?.totalCost} symbol={false} />;
            }, //<div>{dp.data?.total_tax}</div>,
        },
        // {
        //     title: t("sale_price"),
        //     key: "price",
        //     type: "money",
        //     currency,
        //     summary: () => {
        //         const res = dp.data?.receipt_products?.reduce((acc: number, res: any) => acc + res?.product?.price, 0);
        //         return <Money value={res} symbol={false} />;
        //     },
        //     render: d => <Money value={d?.product?.price} symbol={false} />,
        // },
        {
            title: t("subtotal"),
            key: "subtotal",
            type: "money",
            currency,
            summary: () => <Money value={receipt.data?.subtotal ?? 0} symbol={false} />, //<div>{dp.data?.total_tax}</div>,
            render: d => <Money value={d.price * d.count} symbol={false} />,
        },
        {
            title: t("discount"),
            key: "receipt_discount",
            currency,
            type: "money",
            summary: () => <Money value={receipt?.data?.discount_value ?? 0} symbol={false} />,
            render: d => <Money value={d?.receipt_discount + d?.discount_value} symbol={false} />,
        },
        {
            title: t("tax"),
            key: "taxes",
            currency,
            type: "money",
            summary: () => <Money value={receipt.data?.total_tax} symbol={false} />, //<div>{dp.data?.total_tax}</div>,

            render: d => (
                <Button
                    appearance="link"
                    className="p-0 text-primary"
                    onClick={() => {
                        modal.setData(d.taxes);
                        props.setIsClosable(false);
                    }}
                >
                    <Money value={_.sumBy(d.taxes, "tax_amount")} symbol={false} />
                </Button>
            ),
        },
        {
            title: t("sum_total"),
            key: "total",
            type: "money",
            currency,
            summary: () => <Money value={receipt.data?.total} symbol={false} />,

            render: d =>
                d.discount_value ? discountProduct(d.subtotal, d.total) : <Money value={d.total} symbol={false} />,
        },
    ]);

    const subInfoHeight = r.loyalty_value && r.customer_id ? 40 : 0;

    const height = useTableHeight((r.fiscal_id ? 255 : 215) + subInfoHeight);

    return (
        <div style={{ height: "94%" }} className="d-flex">
            <div className="flex-fill border-left h-100 d-flex flex-column justify-content-between">
                <DataTable
                    noShadow
                    columns={cm.columns}
                    dp={{ data: receipt.data?.receipt_products } as any}
                    hidePages
                    resizeRowHeight={(d: any) =>
                        d?.filter((rp: IReceiptProduct) => !!rp.modificators?.length || !!rp.portion_size)?.length
                    }
                    tableProps={{
                        rowHeight: 60,
                        autoHeight: false,
                        height,
                    }}
                />
                <ReceiptDetailSubInfo r={r} />
                <TaxesDrawer
                    {...modal}
                    onClose={() => {
                        props.setIsClosable(true);
                        modal.onClose();
                    }}
                />
            </div>
        </div>
    );
};

export default ReceiptDetails;
