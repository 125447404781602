import _ from "lodash";
import { ItemDataType } from "rsuite/esm/@types/common";

/**
 * When search results or selected items are shown, results could lose their parent nodes.
 * In this case we should use flatten version of the tree.
 */
export const flattenData = (data: ItemDataType, childrenKey: string): [] => {
    return data.reduce((prev: ItemDataType[], cur: ItemDataType) => {
        const c = cur[childrenKey]?.length ? flattenData(cur[childrenKey], childrenKey) : [];

        return [...prev, _.omit(cur, childrenKey), ...c];
    }, []);
};

export const renderCategoryParentNames = (data: ItemDataType, nodeData: ItemDataType, labelKey: string): string => {
    if (nodeData.parent_id) {
        const parent = _.find(data, { id: nodeData.parent_id });

        return parent ? renderCategoryParentNames(data, parent, labelKey) + " / " + nodeData[labelKey] : "";
    } else {
        return nodeData[labelKey];
    }
};
