import { t } from "lib/i18n";
import ToggleFormGroup from "../../../components/Form/ToggleFormGroup";
import RemoteSelectFormGroup from "../../../components/Form/RemoteSelectFormGroup";
import React from "react";
import { UseSettingsFormReturnType } from "../useSettingsForm";

type Props = {
    form: UseSettingsFormReturnType;
};

const TerminalSettingsTab2 = (props: Props) => {
    return (
        <div className="p-3">
            <ToggleFormGroup name="terminal_locale.value" />
            <RemoteSelectFormGroup
                name="terminal_meta.value.main_terminal"
                label={t("main_terminal")}
                searchLocal={true}
                compProps={{
                    cleanable: true,
                }}
                remote={{
                    url: "terminal",
                }}
            />
        </div>
    );
};

export default TerminalSettingsTab2;
