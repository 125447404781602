import { roundAndClean } from "@cloposcom/receipt-utils";
import { iInfoCircle } from "assets/icons/Icons";
import CIcon from "components/CIcon";
import { FIListItemProps } from "components/FormItems/FormItemsList";
import { t } from "lib/i18n";
import { GridButtonRemove } from "components/NewForm/Grid/GridButtonRemove";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { GridSelect } from "components/NewForm/Grid/GridSelect";
import { useProductUnitOptions } from "hooks/useProductUnits";
import { dummySupplyItem } from "pages/inventory/misc/utils";
import { FC, useEffect } from "react";
import { Tooltip, Whisper } from "rsuite";
import { GridInput } from "components/NewForm/Grid/GridInput";
import { GridProductSelectItem } from "components/NewForm/Grid/GridProductSelectItem";
import { cn } from "lib/utils";
import { store } from "redux/store";
import { useCurrency } from "hooks/useCurrency";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { queueStockCount, selectStockCountOf } from "redux/features/stockCountsSelector";

interface IProps extends FIListItemProps<SupplyItemField> {}

export interface SupplyItemField {
    id?: number;
    product_id: number;
    unit_id: number;
    quantity: number;
    cost: number;
    total_cost: number;
    operation_item_id: number;
    tax_rate: number;
    tax_value: number;
    discount_rate: number;
    discount_value: number;
    product_full_name?: string;
    disabled: boolean;
    disabled_reason?: string;
}

const recalculateTotal = (item: SupplyItemField) => {
    const subtotal = item.cost * item.quantity;
    const discount_value = roundAndClean((subtotal * item.discount_rate) / 100, 2);
    const tax_value = roundAndClean(((subtotal - discount_value) * item.tax_rate) / 100, 2);
    const total_cost = roundAndClean(subtotal - discount_value + tax_value, 2);

    return {
        ...item,
        subtotal,
        discount_value,
        tax_value,
        total_cost,
    };
};

export const SupplyItem: FC<IProps> = ({ form, field, index, remove, error }) => {
    const currency = useCurrency();
    const dispatch = useAppDispatch();
    const unitOptions = useProductUnitOptions(field.product_id);
    const stockCount = useAppSelector(selectStockCountOf(field.product_id));
    const productUnit = unitOptions[0];
    const items = form.watch("items");

    const onChangeProduct = (id: number) => {
        const productsById = store.getState().data.products.byId;
        const product = productsById[id];
        const item = dummySupplyItem({
            product_id: product.id,
            unit_id: product.unit_id,
            cost: product.cost_price,
        });
        form.setValue(`items[${index}]`, item);
    };

    const onChangeNonTotal = (item: Partial<SupplyItemField>) => {
        for (const forInKey in item) {
            const key = forInKey as keyof typeof item;

            if (typeof item[key] !== "undefined") {
                // @ts-ignore
                item[key] = Number(item[key]);
            }
        }

        const newItem = recalculateTotal({ ...field, ...item });
        form.setValue(`items[${index}]`, newItem);
    };

    const onChangeTotal = (total: number) => {
        if (total === 0 && field.quantity === 0) {
            return;
        }
        const tax_value = roundAndClean((total * field.tax_rate) / (100 + field.tax_rate), 2);
        const discount_value = roundAndClean(
            ((total - tax_value) * field.discount_rate) / (100 - field.discount_rate),
            2,
        );
        const subtotal = roundAndClean(total + discount_value - tax_value, 2);
        const cost = roundAndClean(subtotal / field.quantity, 4);

        form.setValue(`items[${index}]`, { ...field, cost, tax_value, discount_value, total_cost: total });
    };

    const isRowDisabled = field.disabled;

    useEffect(() => {
        dispatch(queueStockCount(field.product_id));
    }, [field.product_id]);

    return (
        <>
            <GridProductSelectItem
                disabled={isRowDisabled}
                onSelect={onChangeProduct}
                name={`items[${index}].product_id`}
            />

            <GridItemPlain
                value={stockCount}
                align="right"
                testId={`items[${index}].stock_count`}
                postfix={productUnit?.name}
                className={cn({
                    "tw-text-red-500": stockCount < 0,
                })}
            />

            <GridSelect
                name={`items[${index}].unit_id`}
                options={unitOptions}
                selectProps={{
                    placeholder: t("unit"),
                    cleanable: false,
                    readOnly: isRowDisabled,
                    searchable: false,
                }}
            />
            <GridInput
                name={`items[${index}].quantity`}
                type="number"
                onChangeValue={quantity => {
                    onChangeNonTotal({ quantity });
                }}
                decimals={[0, 3]}
                index={index}
                inputProps={{
                    readOnly: isRowDisabled,
                }}
                postfix={unitOptions.find(u => u.id === field.unit_id)?.name}
            />
            <GridInput
                name={`items[${index}].cost`}
                index={index}
                postfix={currency}
                type="number"
                decimals={[2, 4]}
                onChangeValue={val => {
                    onChangeNonTotal({ cost: val });
                }}
                inputProps={{
                    readOnly: isRowDisabled,
                }}
            />
            <GridInput
                name={`items[${index}].discount_rate`}
                type="number"
                onChangeValue={discount_rate => {
                    onChangeNonTotal({ discount_rate });
                }}
                decimals={[0, 2]}
                index={index}
                postfix="%"
                inputProps={{
                    readOnly: isRowDisabled,
                }}
            />
            <GridInput
                name={`items[${index}].tax_rate`}
                type="number"
                onChangeValue={tax_rate => {
                    onChangeNonTotal({ tax_rate });
                }}
                decimals={[0, 2]}
                index={index}
                postfix="%"
                inputProps={{
                    readOnly: isRowDisabled,
                }}
            />
            <GridInput
                name={`items[${index}].total_cost`}
                type="number"
                decimals={2}
                onChangeValue={onChangeTotal}
                index={index}
                postfix={currency}
                inputProps={{
                    readOnly: isRowDisabled,
                }}
            />
            {isRowDisabled ? (
                <Whisper placement="top" trigger="hover" speaker={<Tooltip>{field.disabled_reason}</Tooltip>}>
                    <div className="tw-flex tw-items-center tw-justify-center tw-rounded-lg">
                        <CIcon size={1} path={iInfoCircle} />
                    </div>
                </Whisper>
            ) : (
                <GridButtonRemove onClick={() => remove(index)} disabled={items.length < 2} />
            )}
        </>
    );
};
