import CIcon from "../../../../components/CIcon";
import { iClose, iDragAndDrop } from "assets/icons/Icons";
import { Checkbox, IconButton } from "rsuite";
import DesignForType from "../common/CompForShowDAD/DesignForType";
import { deleteStationReceiptItem, toggleStationReceiptItemActivity } from "redux/features/stationPrintConstructor";
import { IReceiptItem, ReceiptItemType } from "../types";
import { Colors } from "config/colors";
import { useAppDispatch } from "hooks/useRedux";

type ReceiptItemProps = {
    item: IReceiptItem;
    index: number;
    receiptType: ReceiptItemType;
};

const StationReceiptItemView: FCC<ReceiptItemProps> = ({ item, index, receiptType }) => {
    const dispatch = useAppDispatch();

    const handleDelete = () => {
        dispatch(deleteStationReceiptItem(index));
    };

    const handleToggleActivity = () => {
        dispatch(toggleStationReceiptItemActivity(index));
    };

    return (
        <div
            className="d-flex align-items-center "
            style={{ borderRadius: "8px", padding: "0px 12px", background: Colors.MoonlightWhite, margin: "6px 0" }}
        >
            <CIcon path={iDragAndDrop} />
            <DesignForType item={item} receiptType={receiptType} />
            <span className="d-flex align-items-center " style={{ marginLeft: "auto" }}>
                {item.isCustom || item.type === "line" ? (
                    <IconButton
                        style={{ height: "20px", width: "20px", padding: "0", background: Colors.SoftPeach }}
                        onClick={handleDelete}
                        icon={
                            <CIcon
                                style={{ width: "6px", height: "6px" }}
                                size={0.4}
                                path={iClose}
                                className="text-danger"
                            />
                        }
                    />
                ) : null}
                <Checkbox onChange={handleToggleActivity} checked={!!item?.status} />
            </span>
        </div>
    );
};
export default StationReceiptItemView;
