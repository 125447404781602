import React, { forwardRef, TextareaHTMLAttributes } from "react";
import CFormGroup, { CFormGroupProps } from "./CFormGroup";

interface IProps extends CFormGroupProps {
    rows?: number;
}
type TextareaProps = TextareaHTMLAttributes<HTMLTextAreaElement>;
const Textarea: FCC<TextareaProps> = forwardRef<any, any>((props, ref) => <textarea ref={ref} {...props} />) as any;

const TextAreaFormGroup: FCC<IProps> = ({ rows, compProps, ...props }) => {
    const areaProps: any = {
        rows: rows ?? 5,
        style: { width: "300px", ...compProps.style },
        ...compProps,
        "test-id": compProps["test-id"] ?? props.name,
        className: "rs-input " + (compProps.className ?? ""),
    };

    return <CFormGroup {...props} compProps={areaProps} />;
};

TextAreaFormGroup.defaultProps = {
    comp: Textarea as any,
    // type: "textarea",
    compProps: {},
};

export default TextAreaFormGroup;
