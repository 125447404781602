import { isAlpha, isDev } from "config/main";
import { useDepot } from "hooks/useDepot";
import { useAppSelector } from "hooks/useRedux";

import { JSONTree } from "react-json-tree";
import { IconButton } from "rsuite";
import { iBasketMinus, iPlus } from "assets/icons/Icons";
import CIcon from "../CIcon";

interface IProps {
    value?: any;
    reducerName?: string;
    style?: any;
}
/**
 *
 * @deprecated Use JsonView instead
 */
const JsonViewState: FCC<IProps> = props => {
    const stateData: any = useAppSelector(state => state);
    const [isOpen, setIsOpen] = useDepot("jsonViewOpen", true);
    const currentValue = props?.reducerName ? stateData[props?.reducerName] : null;

    if (!isDev && !isAlpha) return null;

    const src = { ...props?.value };
    if (currentValue) src.redux = currentValue;

    return (
        <div
            className="bg-white shadow rounded m-3"
            style={{ position: "absolute", top: 60, right: 0, zIndex: 100, ...props?.style }}
        >
            <IconButton
                appearance="subtle"
                className="float-right"
                icon={<CIcon path={isOpen ? iBasketMinus : iPlus} />}
                onClick={() => setIsOpen(!isOpen)}
            />
            {isOpen && (
                <div className="p-4">
                    <JSONTree data={src} shouldExpandNode={() => false} />
                </div>
            )}
        </div>
    );
};

export default JsonViewState;
