import React, { useRef } from "react";
import { Dropdown, Popover, Whisper } from "rsuite";

interface IProps {
    trigger: React.ReactNode;
    // trigger:
    //     | ReactElement<any, string | JSXElementConstructor<any>>
    //     | ((props: any, ref: any) => ReactElement<any, string | JSXElementConstructor<any>>);
}

export const TableDropdown: FCC<IProps> = props => {
    const triggerRef = useRef<any>(null);

    return (
        <Whisper
            placement="auto"
            preventOverflow
            trigger="click"
            ref={triggerRef}
            speaker={
                <Popover full className="shadow">
                    <Dropdown.Menu style={{ minWidth: 150 }} onSelect={() => triggerRef.current.close()}>
                        {props.children}
                    </Dropdown.Menu>
                </Popover>
            }
        >
            <span>{props.trigger}</span>
        </Whisper>
    );
};
