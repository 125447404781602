import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

export interface IMState {
    searchValue: string;
    items: Record<string, any>;
    displayError: boolean;
    taxRate: string;
    discountRate: string;
    form: {
        supplier_id: any;
        storage_id: any;
        operated_at: any;
        //for disable date picker
        operated_at_default: any;
        invoice_number: string;
        status: any;
        category_id: any;
        balance_id: any;
        description: string;
        supply_returns: any[];
        cost: any;
        quantity: any;
        tax_rate: any;
        tax_value: any;
        discount_rate: any;
        discount_value: any;
    };
}

const initialState: IMState = {
    searchValue: "",
    items: {},
    displayError: false,
    taxRate: "",
    discountRate: "",
    form: {
        supplier_id: null,
        storage_id: null,
        operated_at: new Date().toISOString(),
        //for disable date picker
        operated_at_default: new Date().toISOString(),
        invoice_number: "",
        status: null,
        category_id: null,
        balance_id: null,
        description: "",
        supply_returns: [],
        cost: null,
        quantity: null,
        tax_rate: null,
        tax_value: null,
        discount_rate: null,
        discount_value: null,
    },
};

export const IMSlice = createSlice({
    name: ReduxSlicesNames.ITEM_MANAGEMENT,
    initialState,
    reducers: {
        setIMFormBulk: (state, action) => {
            state.form = action.payload;
        },

        setTax_rate: (state, action) => {
            state.form.tax_rate = action.payload;
        },
        setTax_value: (state, action) => {
            state.form.tax_value = action.payload;
        },

        setCost: (state, action) => {
            state.form.cost = action.payload;
        },
        setQuantity: (state, action) => {
            state.form.quantity = action.payload;
        },
        setDiscount_rate: (state, action) => {
            state.form.discount_rate = action.payload;
        },
        setDiscount_value: (state, action) => {
            state.form.discount_value = action.payload;
        },
        setTaxToAll: (state, action) => {
            state.taxRate = state.form.cost.map((items: any) => (items * action.payload) / 100);
        },
        setDiscountToAll: (state, action) => {
            state.discountRate = action.payload;
        },
        setIMForm: (state, action: PayloadAction<{ key: keyof typeof state.form; value: any }>) => {
            state.form[action.payload.key] = action.payload.value;
        },
        updateItem: (state, action: PayloadAction<{ id: number; data: any }>) => {
            if (!state.items[action.payload.id]) state.items[action.payload.id] = {};
            state.items[action.payload.id] = {
                ...state.items[action.payload.id],
                ...action.payload.data,
            };
        },
        deleteIMItem: (state, action: { payload: number }) => {
            return {
                ...state,
                items: _.omit(state.items, action.payload),
            };
        },
        setSearchText: (state, action) => {
            state.searchValue = action.payload;
        },
        setDisplayError: (state, action) => {
            state.displayError = action.payload;
        },
        resetIMData: () => {
            return {
                ...initialState,
            };
        },
    },
});

export const {
    setIMFormBulk,
    setIMForm,
    updateItem,
    deleteIMItem,
    setSearchText,
    setDisplayError,
    resetIMData,
    setTaxToAll,
    setDiscountToAll,
    setTax_rate,
    setTax_value,
    setDiscount_rate,
    setDiscount_value,
    setCost,
    setQuantity,
} = IMSlice.actions;
export default IMSlice.reducer;
