import { t } from "lib/i18n";

import ToggleFormGroup from "components/Form/ToggleFormGroup";
import { dummyVariant } from "models/GoodsModel";
import _ from "lodash";
import GoodsVariantItem, { GoodsVariantItemField } from "pages/products/goods/GoodsVariantItem";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { FormItems } from "components/FormItems/FormItems";
import { FormItemsList } from "components/FormItems/FormItemsList";
import { FIHeaderProps } from "components/FormItems/FormItemsHeader";
import { FormItemsAddButton } from "components/FormItems/FormItemsAddButton";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

const columns: FIHeaderProps["columns"] = [["name"], ["barcode"], ["cost", "right"], ["price", "right"], [""]];

const EditVariantsTab: FCC = () => {
    const { watch, setValue, getValues } = useCFormContext();
    const has_modifications = watch("has_modifications");
    const modifications = getValues("modifications");
    const params = useParams<any>();

    useEffect(() => {
        if (!has_modifications) {
            setValue("modifications", []);
            return;
        }

        setValue("sold_by_weight", false);

        if (_.isEmpty(getValues("modifications"))) {
            setValue("modifications", [dummyVariant()]);
        }

        // eslint-disable-next-line
    }, [has_modifications]);

    useEffect(() => {
        //reset price when add modification
        if (has_modifications && !_.isEmpty(modifications)) {
            setValue("price", 0);
        }

        // eslint-disable-next-line
    }, [modifications]);

    return (
        <div className="tw-p-4">
            <ToggleFormGroup
                name="has_modifications"
                label={t("has_variants")}
                compProps={{
                    defaultChecked: has_modifications,
                    disabled: !!params.id,
                }}
            />

            {has_modifications && (
                <FormItems itemsFieldName="modifications">
                    <FormItemsList<GoodsVariantItemField>
                        columns={columns}
                        children={_props => (_props.field.deleted_at ? null : <GoodsVariantItem {..._props} />)}
                        footer={
                            <div className="tw-col-span-2">
                                <FormItemsAddButton dummyData={dummyVariant} />
                            </div>
                        }
                    />
                </FormItems>
            )}
        </div>
    );
};

export default EditVariantsTab;
