import React, { FC, useEffect, useState } from "react";
import { cn } from "lib/utils";
import { ExportType, FileExportProcessStatus } from "config/constants";
import DoubleDataColumn from "../../mini/DoubleDataColumn";
import CIcon from "../../CIcon";
import { iAlert, iCancel, iDownload, iSpinner } from "assets/icons/Icons";
import { Colors } from "config/colors";
import Ajax from "../../../lib/Ajax";
import { IDataProvider } from "../../DataTable/useDataProvider";
import { getLabel } from "../../Form/form-utils";
import qs from "qs";
import _ from "lodash";
import { ExportModalButton } from "./ExportModalButton";
import { TText } from "../../i18n/TText";
import * as Sentry from "@sentry/browser";

const icons: Record<FileExportProcessStatus, IExportIcon> = {
    pending: {
        icon: iSpinner,
        color: Colors.BrightOrange,
    },
    processing: {
        icon: iSpinner,
        color: Colors.BrightOrange,
    },
    completed: {
        icon: iDownload,
        color: Colors.EmeraldGreen,
    },
    failed: {
        icon: iAlert,
        color: Colors.BrightRed,
    },
    cancelled: {
        icon: iCancel,
        color: "red",
    },
};

interface IExportModalFileList {
    type?: ExportType;
    selected: IExportProps | null;
}

export const ExportModalFileList: FC<IExportModalFileList> = ({ selected, type }) => {
    const [exportFileList, setExportFileList] = useState<IExportList[]>([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getExportList();
    }, []);

    useEffect(() => {
        let checkInterval: any;

        const progressStatuses = [FileExportProcessStatus.PROCESSING, FileExportProcessStatus.PENDING];

        const hasPendingFile = exportFileList?.some(list => progressStatuses.includes(list.status));

        if (hasPendingFile) {
            checkInterval = setInterval(async () => {
                await getExportList();
            }, 5000);
        } else if (checkInterval) {
            clearInterval(checkInterval);
        }

        return () => {
            if (checkInterval) {
                clearInterval(checkInterval);
            }
        };
    }, [exportFileList]);

    const startDownloadProcess = async (name: string, dp: IDataProvider, columns: ITableColumnProps[]) => {
        let url = dp.url;
        const params = dp.urlParams;

        setIsLoading(true);

        params.exports = columns
            .filter((c: any) => c.key !== "options")
            .map((a: any) => ({ [a.title || getLabel(a.key as string)]: a.key }));
        const result: any = {};

        params.exports.forEach((obj: any) => {
            const key = Object.keys(obj)[0];
            result[key] = obj[key];
        });

        params.exports = btoa(unescape(encodeURIComponent(JSON.stringify(result))));

        url += "?" + qs.stringify(params, { encode: true });
        url += "&background_export=1";

        try {
            const response = await Ajax.get({ url });
            if (response?.data) {
                const _exportFileList = _.cloneDeep(exportFileList);
                _exportFileList.unshift(response?.data);

                setExportFileList(_exportFileList);
            }
            setIsLoading(false);
        } catch (e) {
            Sentry.captureException(e);
            setIsLoading(false);
        }
    };

    const getExportList = async () => {
        const url = Ajax.buildUrl({
            url: "exports",
            params: {
                with: ["user:id,username.role:name"],
                sort: ["created_at", "-1"],
                ...(type ? { filters: [["type", type]] } : {}),
            },
        });
        const response = await Ajax.get({ url });
        setExportFileList(response?.data ?? []);
    };

    const downloadFile = async (url: string, fileName: string) => {
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const renderItem = (item: IExportList, index: number) => {
        const icon = icons[item.status]?.icon;
        const color = icons[item.status]?.color;
        const isCompleted = item.status === FileExportProcessStatus.COMPLETED;
        const shouldSpin =
            item.status === FileExportProcessStatus.PENDING || item.status === FileExportProcessStatus.PROCESSING;
        return (
            <div
                test-id={`export-file-${index}`}
                key={item.id}
                style={{ borderBottomStyle: "solid" }}
                className={cn(
                    "tw-grid tw-grid-cols-subgrid tw-col-span-4 tw-py-2 tw-border-b tw-border-gray-200 tw-items-center hover:tw-bg-amber-50",
                    {
                        "tw-cursor-pointer": isCompleted,
                        "tw-cursor-not-allowed": !isCompleted,
                    },
                )}
                onClick={() => (isCompleted ? downloadFile(item.url, `${item.title}.${item.extension}`) : null)}
            >
                <div className={cn("tw-justify-start tw-gap-y-1 tw-w-max")}>
                    <div
                        className={cn("tw-text-blue-500 tw-text-sm tw-font-normal")}
                        test-id={`export-file-${index}-name`}
                    >
                        {item.title}.{item.extension}
                    </div>
                    <div className={cn("tw-text-slate-300 tw-text-xs tw-font-normal tw-capitalize")}>
                        {item.extension}
                    </div>
                </div>

                <DoubleDataColumn
                    className={cn("tw-max-w-fit")}
                    type="date-time"
                    date={item.created_at}
                    style={{ marginTop: 0 }}
                />

                <DoubleDataColumn
                    type="double-data"
                    className={cn("tw-max-w-fit tw-mt-0")}
                    value1={item?.user?.username}
                    value2={item.user?.role?.name}
                />
                <div className={cn("tw-max-w-fit tw-justify-center")}>
                    <CIcon
                        path={icon}
                        test-id={`export-file-${index}-${shouldSpin ? "pending" : "ready"}`}
                        className={cn("tw-w-4 tw-h-4")}
                        style={{ color }}
                        spin={shouldSpin}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            <ExportModalButton
                isLoading={isLoading}
                disabled={!selected?.name}
                onClick={() =>
                    selected?.name && selected?.dp && selected?.columns
                        ? startDownloadProcess(selected.name, selected.dp, selected.columns)
                        : null
                }
            />
            {exportFileList?.length ? (
                <div className={cn("tw-w-full tw-mt-4 tw-text-left")}>
                    <div className={cn("tw-text-slate-700 tw-text-sm tw-font-medium tw-mb-4")}>
                        <TText tkey="files" />
                    </div>
                    <div className={cn("tw-max-h-[315px] tw-overflow-y-auto tw-grid tw-gap-6")}>
                        {exportFileList?.map((item: IExportList, index: number) => renderItem(item, index))}
                    </div>
                </div>
            ) : null}
        </>
    );
};
