import { FIListItemProps } from "components/FormItems/FormItemsList";
import { multiplication, toFixed } from "lib/utils";
import React from "react";
import { store } from "redux/store";
import { GridButtonRemove } from "components/NewForm/Grid/GridButtonRemove";
import { GridInput } from "components/NewForm/Grid/GridInput";
import { GridItemPlain } from "components/NewForm/Grid/GridItemPlain";
import { GridSelect } from "components/NewForm/Grid/GridSelect";
import { useProductUnitOptions } from "hooks/useProductUnits";
import { GridProductSelectItem } from "components/NewForm/Grid/GridProductSelectItem";
import { useCurrency } from "hooks/useCurrency";
import { t } from "lib/i18n";

interface IProps extends FIListItemProps<ManufactureItemField> {}

export interface ManufactureItemField {
    product_id: number;
    unit_id: number;
    quantity: number;
    cost: number;
    total_cost: number;
}

const ManufactureItem: FCC<IProps> = ({ field, index, remove, fields, form, error }) => {
    const currency = useCurrency();
    const units = useProductUnitOptions(field.product_id);

    const onChangeQuantity = (val: number) => {
        // form.setValue(`items[${index}].quantity`, val);
        form.setValue(
            `items[${index}].total_cost`,
            toFixed(multiplication(val, field?.cost, form.getValues(`items[${index}].total_cost`)), 2),
        );
    };

    const onSelectProduct = (id: number) => {
        const product = store.getState().data.products.byId[id];
        form.setValue(`items[${index}]`, {
            product_id: product?.id,
            unit: product?.unit ?? null,
            unit_id: Number(product?.unit_id) ?? null,
            quantity: 0,
            cost: toFixed(product?.cost_price ?? 0, 4),
            total_cost: toFixed(fields[index]?.quantity * product.cost_price, 2),
        });
    };

    return (
        <>
            <GridProductSelectItem onSelect={onSelectProduct} name={`items[${index}].product_id`} />

            <GridSelect
                name={`items[${index}].unit_id`}
                options={units}
                align="right"
                index={index}
                selectProps={{ placeholder: t("select", { name: t("unit") }) }}
            />

            <GridInput index={index} name={`items[${index}].quantity`} onChangeValue={onChangeQuantity} type="number" />
            <GridItemPlain align="right" value={field.cost} postfix={currency} testId="manufacture-item-cost" />

            <GridItemPlain
                align="right"
                value={field.total_cost}
                postfix={currency}
                testId={`items[${index}].total_cost`}
            />

            <GridButtonRemove disabled={fields.length < 2} onClick={() => remove(index)} />
        </>
    );
};

export default ManufactureItem;
