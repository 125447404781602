import React, { useEffect } from "react";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { useHistory } from "react-router-dom";
import { reportAccessRoutes } from "config/reportAccessRoutes";

const NewReportsRoute = () => {
    const auth = useAuth();
    const history = useHistory();
    useEffect(() => {
        const key = Object.keys(reportAccessRoutes).find(ab => auth.hasAbility([ab]));
        if (key) history.push(reportAccessRoutes[key]);
    }, []);

    return <div />;
};
export default NewReportsRoute;
