import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import classNames from "classnames";
import { optionsColumn } from "components/DataTable/ColumnsManager/OptionsColumn";
import { useColumns } from "components/DataTable/ColumnsManager/useColumns";
import DataTable from "components/DataTable/DataTable";
import CheckBoxFilter from "components/DataTable/Filters/CheckBoxFilter";
import FiltersContainer from "components/DataTable/Filters/FiltersContainer";
import SearchFilter from "components/DataTable/Filters/SearchFilter";
import { useDataProvider } from "components/DataTable/useDataProvider";
import { useDrawer } from "components/Drawers/useDrawer";
import HeaderRight from "components/Header/HeaderRight";
import { useTitle } from "components/Header/Title";
import OperationProcessIndicator from "components/OperationProcessIndicator";
import { OperationType } from "config/constants";
import Ajax from "lib/Ajax";
import _ from "lodash";
import React, { useState } from "react";
import { Tooltip, Whisper } from "rsuite";
import CreateButton from "../../../components/DataTable/CreateButton";
import Money from "../../../components/mini/Money";
import { useData } from "hooks/useData";
import { refineVenueList, toFixed } from "lib/utils";
import OperationProductsCell from "../misc/OperationProductsCell";
import StockOperationsDrawer from "../misc/StockOperationsDrawer";
import { iArrowUp, iDownload, iInfoCircle } from "assets/icons/Icons";
import CIcon from "../../../components/CIcon";
import { RowDataType } from "rsuite-table/src/@types/common";
import ExportModal from "../../../components/Header/Export/ExportModal";
import DoubleDataColumn from "../../../components/mini/DoubleDataColumn";
import { Colors } from "config/colors";
import { exportFile } from "lib/export-table";
import LearnButton from "../../../components/DataTable/LearnButton";
import { SelectStorageFilter } from "components/adhoc-filters/SelectStorageFilter";

const TransfersPage: FCC = props => {
    const url = "operation";
    const [op, setOp] = useState<IOperation>();
    const [itemDetails, setItemDetails] = useState<any>(null);

    const dp = useDataProvider({
        url,
        useUrl: true,
        useDates: true,
        with: ["storage", "from_storage", "products_label", "total_cost"],
        filters: {
            type: ["type", OperationType.TRANSFER],
        },
        sort: ["id", "-1"],
        refineList: list => {
            return list?.map(ls => {
                return {
                    ...ls,
                    stock_operations: _.map(
                        _.groupBy(ls.stock_operations, sp => sp.product_id),
                        (a, yt) => ({
                            ...a[0],
                            quantity:
                                parseFloat(
                                    toFixed(
                                        a.reduce((fd, rt) => fd + rt.quantity, 0),
                                        4,
                                    ),
                                ) / 2,
                            total_cost:
                                parseFloat(
                                    toFixed(
                                        a.reduce((fd, rt) => fd + rt.total_cost, 0),
                                        4,
                                    ),
                                ) / 2,
                        }),
                    ),
                };
            });
        },
    });
    const drawer = useDrawer({ backdrop: false, overflowAuto: true });
    const exportDrw = useDrawer();

    const summary = useData<any>("operation/total", {
        params: {
            date: dp.params.all.date,
        },
        filters: {
            type: ["type", OperationType.TRANSFER],
            ..._.values(dp.filters.all),
        } as any,
    });

    const cm = useColumns<any>("operation/transfers", [
        {
            key: "id",
            isFixed: true,
            align: "left",
            width: 70,
            flexGrow: undefined,
            title: t("data_id"),
            onClick: (d: IOperation) => {
                setOp(d);
                drawer.setDataId(d.id);
            },
        },
        { key: "products_label", title: t("products"), Comp: OperationProductsCell, flexGrow: 3 },
        {
            key: "operated_at",
            title: t("operation"),
            type: "datetime",
            flexGrow: 1.5,
            render: d => <DoubleDataColumn showSeconds={true} type="date-time" date={d.operated_at} />,
        },
        {
            key: "from_storage.name",
            title: t("from_storage"),
            render: d => (
                <>
                    {d?.from_storage?.deleted_at && (
                        <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={
                                <Tooltip>
                                    <TText tkey="app_deleted" />
                                </Tooltip>
                            }
                        >
                            <CIcon path={iInfoCircle} style={{ color: Colors.CrimsonRed }} />
                        </Whisper>
                    )}
                    <span style={{ marginLeft: 5 }}>{d?.from_storage?.name}</span>
                </>
            ),
        },
        {
            key: "storage.name",
            title: t("to_storage"),
            render: d => (
                <>
                    {d?.storage?.deleted_at && (
                        <Whisper
                            placement="top"
                            trigger="hover"
                            speaker={
                                <Tooltip>
                                    <TText tkey="app_deleted" />
                                </Tooltip>
                            }
                        >
                            <CIcon path={iInfoCircle} style={{ color: Colors.CrimsonRed }} />
                        </Whisper>
                    )}
                    <span style={{ marginLeft: 5 }}>{d?.storage?.name}</span>
                </>
            ),
        },
        { key: "reason", title: t("reason") },
        {
            key: "inventory_status",
            isFixed: true,
            render: e => <OperationProcessIndicator status={e.inventory_status} />,
        },
        {
            key: "total_cost",
            title: t("total_cost"),
            render: d => {
                return <Money value={d.total_cost} />;
            },
            summary: () => {
                const totalCost = summary.data && summary.data[0] ? summary.data[0].total_cost : 0;
                return <Money value={totalCost} />;
            },
        },
        optionsColumn({
            dp,
            edit: "/inventory/transfers/edit/:id",
            delete: "operation/:id",
            preventDelete: d => !!d.is_system || d?.storage?.deleted_at || d?.from_storage?.deleted_at,
            editable: d => d?.deleted_at,
            canEdit: "stock_manage_transfer",
            canDelete: "stock_manage_transfer",
            others: () => [
                {
                    label: t("export_btn"),
                    icon: iDownload,
                    onClick: d => {
                        exportDrw.setIsOpen(true);
                        setItemDetails(d);
                    },
                },
            ],
        }),
    ]);

    return (
        <div className="h-100">
            {useTitle(t("transfers"), dp.total)}
            <HeaderRight dp={dp} cm={cm} date reload print export={() => exportFile(dp, cm.columns, t("transfers"))} />
            <FiltersContainer dp={dp}>
                <div className="d-flex flex-fill flex-wrap tw-gap-1 table-filter">
                    <SearchFilter fields="productSearch" placeholder={t("product")} />
                    {/* <RemoteCheckListFilter
                        remote="storage"
                        fields="from_storage_id"
                        searchField="name"
                        pickerProps={{
                            placeholder: t("from_storage"),
                            renderMenuItem: (label: any, item: any) => (
                                <StorageCheckListItem label={label} storage={item} />
                            ),
                        }}
                    /> */}
                    <SelectStorageFilter
                        multi
                        fields="from_storage_id"
                        pickerProps={{
                            placeholder: t("from_storage"),
                        }}
                    />
                    <div className="py-2">
                        <CIcon path={iArrowUp} rotate={90} />
                    </div>
                    <SelectStorageFilter
                        multi
                        showAllVenues={true}
                        refineList={refineVenueList}
                        pickerProps={{
                            groupBy: "venue_name",
                            placeholder: t("to_storage"),
                        }}
                        hideStatus={true}
                    />
                    <CheckBoxFilter
                        useParams
                        fields="trash"
                        value={[2, ["operation_items.product", 2]]}
                        title={t("show_deleted_values")}
                    />
                </div>

                <LearnButton page="transfers" />

                <CreateButton to={"/inventory/transfers/create"} canCreate={"stock_manage_transfer"} />
            </FiltersContainer>
            <StockOperationsDrawer
                {...drawer}
                op={op}
                title={`${t("transfer")} #${drawer.dataId} (${(op as any)?.from_storage?.name || "---"} -> ${
                    (op as any)?.storage?.name || "--"
                })`}
                cols={["product.name", "cost", "total_cost", "quantity"]}
            />

            <DataTable
                dp={dp}
                columns={cm.columns}
                tableProps={{
                    headerHeight: 50,
                    affixHeader: true,
                    rowClassName: (d?: RowDataType) =>
                        classNames({
                            "deleted-row": d && !!d?.deleted_at,
                        }),
                }}
            />
            {itemDetails?.id && exportDrw.isOpen && (
                <ExportModal
                    exportOptions={[
                        {
                            name: `${t("transfer")} - ${itemDetails.id}`,
                            onClick: () =>
                                Ajax.download(
                                    { url: `operation/${itemDetails.id}/invoice` },
                                    `${t("transfer")} #${itemDetails.id}`,
                                ),
                        },
                    ]}
                    {...exportDrw}
                />
            )}
        </div>
    );
};

export default TransfersPage;
