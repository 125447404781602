import React, { useEffect, useState } from "react";

import { IRouteWithSubRoutes } from "types/routes";

/**
 * Because redux does not allow storing components,
 * we store the routes in a separate variable which
 * is exported to global scope using useRoutes hook.
 */
let __INTERNAL_ROUTES__: IRouteWithSubRoutes[] = [];

const listeners = new Set<React.Dispatch<React.SetStateAction<IRouteWithSubRoutes[]>>>();

export const setRoutes = (routes: IRouteWithSubRoutes[]) => {
    __INTERNAL_ROUTES__ = routes;

    listeners.forEach(l => l(routes));
};

export const useRoutes = () => {
    const [routes, setRoutes] = useState<IRouteWithSubRoutes[]>(__INTERNAL_ROUTES__);

    useEffect(() => {
        listeners.add(setRoutes);

        return () => void listeners.delete(setRoutes);
    }, []);

    return routes;
};
