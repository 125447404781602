import { ICDrawerProps } from "components/Drawers/CDrawer";
import { t } from "lib/i18n";
import _ from "lodash";
import { useEffect } from "react";
import { iMinus } from "assets/icons/Icons";
import CIcon from "../../../../components/CIcon";
import CFormModal from "../../../../components/Drawers/CFormModal";
import DatePickerFormGroup from "../../../../components/Form/DatePickerFormGroup";
import SelectFormGroup from "../../../../components/Form/SelectFormGroup";
import TextAreaFormGroup from "../../../../components/Form/TextAreaFormGroup";
import TextPostfixFormGroup from "../../../../components/Form/TextPostfixFormGroup";
import { BalanceWithAmount } from "components/mini/BalanceWithAmount";
import { FinanceAccountDefaultFilters } from "config/constants";
import { useCForm } from "hooks/useCForm";
import { useData } from "hooks/useData";
import { SupplierPaymentTransactionDummy, SupplierPaymentTransactionModel } from "models/TransactionModel";
import { format } from "date-fns";

interface IProps extends ICDrawerProps {
    revalidate?: () => void;
    onClosed?: () => void;
}

const SuppliesPaymentModal: FCC<IProps> = props => {
    const categories = useData<IFinanceCategory[]>("finance/category", {
        filters: [["parent_id", 9]],
        params: {
            limit: 999,
            tree: 0,
        },
    });

    const form = useCForm({
        url: `operation/${props.data?.id || props.dataId}/pay`,
        model: SupplierPaymentTransactionModel(),
        dummyData: () => SupplierPaymentTransactionDummy(),
        prepareForSubmit: (d: any) => ({
            ...d,
            operated_at: format(new Date(d.operated_at), "yyyy-MM-dd HH:mm:ss"),
        }),
        afterSubmit: () => {
            // props?.onClosed?.();
            props.revalidate?.();
        },
        redirectUrl: false,
        hasAllVenues: true,
        clearForm: false,
    });
    useEffect(() => {
        form.set("url", `operation/${props.data?.id || props.dataId}/pay`);
        if (props.data?.transaction_finance) {
            // form.form.setValue("operated_at", props.data.transaction_finance?.operated_at);
            form.form.setValue("finance.category_id", props.data.transaction_finance?.category_id);
            if (props.data?.supplier_remain === 0 && props.data?.transaction_finance?.balance_id) {
                form.form.setValue("finance.balance_id", props.data?.transaction_finance?.balance_id);
            } else {
                form.form.setValue("finance.balance_id", null);
            }
        }
        // return form.form.reset();
    }, [props.dataId, props.data?.id]);

    const accounts = useData<IAccount[]>("finance/balance", {
        params: {
            limit: 999,
            filters: _.values(FinanceAccountDefaultFilters),
        },
    });
    // console.log(form.form.control._fields, "accounts");

    useEffect(() => {
        if (props.isOpen) {
            accounts.revalidate();
        }
    }, [props.isOpen]);

    const accountProps = {
        data: accounts.data,
        compProps: {
            placeholder: t("balance"),

            renderMenuItem: (val: any, item: any) => <BalanceWithAmount item={item} />,
            renderValue: (val: any, item: any) => <BalanceWithAmount item={item} />,
        },
    };
    return (
        <CFormModal
            {...props}
            size="xs"
            form={form}
            formProps={{ layout: "horizontal" }}
            title={t("make_pay") + " #" + props.data?.id}
        >
            <div className="d-flex flex-column align-items-center">
                <TextPostfixFormGroup
                    name="name"
                    placeholder={t("supplier")}
                    hideLabel={true}
                    disabled={true}
                    style={{ marginTop: "6px", marginBottom: "8px", height: "40px", width: "100%" }}
                    controlWrapperProps={{ style: { width: 360 } }}
                    compProps={{
                        disabled: true,
                        value: props.data?.supplier?.name,
                    }}
                />

                <TextPostfixFormGroup
                    name="amount"
                    type="number"
                    placeholder={t("amount")}
                    hideLabel={true}
                    disabled={true}
                    preFix={<CIcon path={iMinus} />}
                    style={{ marginTop: "6px", marginBottom: "18px", height: "40px", width: "100%" }}
                    controlWrapperProps={{ style: { width: 360 } }}
                    compProps={{
                        addonClass: `tw-bg-[#ffedec] tw-text-[#f04438] tw-my-[7px] tw-mx-[16px] tw-rounded-[7px]`,
                        style: { textAlign: "left" },
                        disabled: true,
                        value: props.data?.transaction_finance?.amount,
                    }}
                />

                <SelectFormGroup
                    name="finance.balance_id"
                    width={360}
                    placeholder={t("balance")}
                    hideLabel={true}
                    priorityError={_.isNil(form.form.getValues("finance.balance_id")) ? t("required") : undefined}
                    label={t("balance")}
                    // className={`${type !== "TRANSFER" && "d-none"}`}
                    {...accountProps}
                />

                <SelectFormGroup
                    name="finance.category_id"
                    hideLabel={true}
                    width={360}
                    label=""
                    data={categories.data}
                    compProps={{
                        sort: () => (a, b) => a._lft - b._lft,
                        renderMenuItem: (label: any, item: any) => {
                            return (
                                <span>
                                    {_.times(item.depth).map((x, i) => (
                                        <span key={i} className="px-2">
                                            --
                                        </span>
                                    ))}
                                    {label}
                                </span>
                            );
                        },
                    }}
                />

                <div>
                    <DatePickerFormGroup
                        time
                        hideLabel
                        name="operated_at"
                        width={360}
                        compProps={{
                            cleanable: false,
                            className: "text-left",
                            shouldDisableDate: d => {
                                const operatedAt = new Date(props.data.operated_at);
                                operatedAt.setHours(0, 0, 0, 0);

                                const now = new Date();
                                now.setHours(23, 59, 59, 999);

                                return !!d && (d.getTime() > now.getTime() || d.getTime() < operatedAt.getTime());
                            },
                            shouldDisableHour: (h, date) => {
                                const operatedAt = new Date(props.data.operated_at);
                                const now = new Date();

                                if (operatedAt.toDateString() === date.toDateString()) {
                                    return h < operatedAt.getHours();
                                } else if (now.toDateString() === date.toDateString()) {
                                    return h > now.getHours();
                                }
                                return false;
                            },
                            shouldDisableMinute: (m, date) => {
                                const operatedAt = new Date(props.data.operated_at);
                                const now = new Date();

                                if (operatedAt.toDateString() === date.toDateString()) {
                                    if (operatedAt.getHours() === date.getHours()) {
                                        return m < operatedAt.getMinutes();
                                    }
                                } else if (now.toDateString() === date.toDateString()) {
                                    if (now.getHours() === date.getHours()) {
                                        return m > now.getMinutes();
                                    }
                                }
                                return false;
                            },
                        }}
                    />
                </div>

                <TextAreaFormGroup
                    compProps={{ style: { width: 360, height: 65 } }}
                    className="mb-3"
                    name="description"
                />
            </div>
        </CFormModal>
    );
};
export default SuppliesPaymentModal;
