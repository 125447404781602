import React from "react";
import "./Settings.scss";
import { CategoryPageLayout } from "pages/qr-menu/settings/CategoryPageLayout";
import { ProductsPageLayout } from "pages/qr-menu/settings/ProductsPageLayout";
import { CategoriesOnProductPageLayout } from "pages/qr-menu/settings/CategoriesOnProductPageLayout";
import ToggleFormGroup from "components/Form/ToggleFormGroup";
import { t } from "lib/i18n";
import { useSetting } from "hooks/useSetting";
import { BrandType } from "types/routes";
import { useIntegrationForm } from "pages/settings/integrations/utils";

export interface IMenuCustomizationTabProps {
    form: ReturnType<typeof useIntegrationForm>;
}

export const MenuCustomizationTab: FCC<IMenuCustomizationTabProps> = ({ form }) => {
    const brandType: string = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;

    return (
        <div className="p-4">
            <div className={"tw-flex tw-justify-start"}>
                <div className={"tw-flex tw-gap-3 tw-bg-[#F2F4F5] tw-px-3 tw-py-1 tw-rounded-lg"}>
                    <div className={"tw-flex tw-gap-1 tw-font-medium tw-text-sm tw-items-center"}>
                        <img src={"/img/qr-menu-layouts/category-symbol.png"} alt={"category"} />
                        {t("category")}
                    </div>
                    <div className={"tw-flex tw-gap-1 tw-font-medium tw-text-sm tw-items-center"}>
                        {isRestaurant ? (
                            <img src={"/img/qr-menu-layouts/product-symbol.png"} alt={"product"} />
                        ) : (
                            <img src={"/img/qr-menu-layouts/shop-product-symbol.png"} alt={"shop"} />
                        )}
                        {t("product")}
                    </div>
                </div>
            </div>
            <ProductsPageLayout form={form} />
            <CategoryPageLayout form={form} />
            <CategoriesOnProductPageLayout form={form} />
            <ToggleFormGroup
                name="payload.meta.menu_layout.show_only_active_category_products"
                label={t("show_only_active_category_products")}
            />
        </div>
    );
};
