import { ChangeEvent } from "react";
import { Checkbox, ColumnProps, HeaderProps, Table } from "rsuite";
import { getLabel } from "./form-utils";
import { menuTitleByType, TERMINAL_ABILITIES } from "config/constants";
import { useIntegrationByName } from "providers/PackageProvider";
import { BrandType } from "types/routes";
import { useSetting } from "hooks/useSetting";
import _ from "lodash";
import { ValueType } from "rsuite/esm/Radio/Radio";

const { Cell } = Table;
const Column = Table.Column as FCC<ColumnProps>;
const HeaderCell = Table.HeaderCell as FCC<HeaderProps>;

export interface IGrouppedAbility {
    name: string;
    all: string;
    group?: string;
}

interface IProps {
    onChange: (value: ValueType | undefined, checked: boolean, event: ChangeEvent<HTMLInputElement>) => void;
    ability: string[];
    abilities: Array<IAbility>;
    readonly: boolean;
}

const NewCheckboxTableTerminal: FCC<IProps> = ({ onChange, abilities, ability, readonly }) => {
    const brandType = useSetting("brand_type")?.value;
    const isRestaurant = brandType === BrandType.RESTAURANT;
    const isLoyaltyEnabled = !!useIntegrationByName("loyalty");

    const getDisallowedRoles = () => {
        const roles = isRestaurant ? [] : ["guests_select", "terminal_remove_after_station_print"];
        if (!isLoyaltyEnabled) {
            roles.push("terminal_loyalty_balance_usage");
        }
        return roles;
    };

    const filterAbilities = () => {
        const disallowedRoles = getDisallowedRoles();
        return TERMINAL_ABILITIES.filter(ta => {
            return abilities.findIndex(ab => ab.name === ta.name) !== -1 && !disallowedRoles.includes(ta.all);
        });
    };

    const renderTable = (groupedAbilities: IGrouppedAbility[]) => {
        const group = groupedAbilities[0]?.group;

        return (
            <div
                key={group}
                className="tw-pb-3 tw-border-0 tw-border-x tw-border-solid tw-border-basic-metal-grey-500 last:tw-rounded-b-xl last:tw-border-b"
            >
                <Table
                    virtualized
                    autoHeight
                    width={602}
                    data={groupedAbilities}
                    headerHeight={44}
                    rowHeight={40}
                    className="ability-check-form tw-w-full"
                >
                    <Column flexGrow={1} align={"left"}>
                        <HeaderCell>
                            {group && (
                                <h5 className="text-subtitle2-medium tw-py-[8.5px] !tw-m-0">
                                    {menuTitleByType.menuTitleVariation[group]?.[brandType] ?? getLabel(group)}
                                </h5>
                            )}
                        </HeaderCell>
                        <Cell>
                            {rowData => (
                                <div className="d-flex h-100 align-items-center">
                                    <Checkbox
                                        value={rowData?.all}
                                        test-id={rowData?.all}
                                        onChange={onChange}
                                        readOnly={readonly}
                                        className="tw-text-text-600 text-body2-regular"
                                    >
                                        {getLabel(rowData?.name)}
                                    </Checkbox>
                                </div>
                            )}
                        </Cell>
                    </Column>
                </Table>
            </div>
        );
    };
    const _abilities = filterAbilities();

    return _.map(_.groupBy(_abilities, "group")).map(renderTable);
};

export default NewCheckboxTableTerminal;
