import { ICDrawerProps } from "components/Drawers/CDrawer";
import CFormModal from "components/Drawers/CFormModal";
import TextFormGroup from "components/Form/TextFormGroup";
import { t } from "lib/i18n";
import JsonView from "components/JsonView";
import { useCForm } from "hooks/useCForm";
import { FC, useEffect } from "react";
import ModificationModalFooter from "./ModificationModalFooter";
import ModifierModalTypeField from "./ModifierModalTypeField";
import ModifierSelectField from "./ModifierSelectField";
import { ModifierSchema } from "form-schemas/ModifierSchema";
import { useCFormContext } from "components/Form/CForm/CFormProvider";

export interface ModificationModalCompProps {
    defaultData?: any;
    cameFrom: number;
    groups?: any[];
}

const ModificationModal: FC<ICDrawerProps> = props => {
    const defaultData: IModifierGroup | undefined = props.data;
    const outerForm = useCFormContext();
    const groups = outerForm.watch("modificator_groups");

    const form = useCForm({
        dummyData: () => defaultData,
        model2: ModifierSchema,
        mode: "onChange",
        isModal: true,
    });
    useEffect(() => {
        form.form.reset(defaultData);
    }, [defaultData]);

    return (
        <CFormModal
            {...props}
            backdrop="static"
            testId="modification-modal"
            title={props.data?.id ? t("change_set") : t("create_new_set")}
            size="xs"
            form={form}
            showFooter={false}
        >
            <ModifierSelectField defaultData={defaultData} cameFrom={props.dataId!} groups={groups} />

            <TextFormGroup
                name={"name"}
                label={false}
                compProps={{
                    placeholder: t("text", { name: t("name") }),
                    defaultValue: defaultData?.name,
                    style: { width: 300 },
                    "test-id": "modification-name",
                }}
            />

            <ModifierModalTypeField cameFrom={props.dataId!} defaultData={defaultData} />

            <ModificationModalFooter
                onClose={props.onClose}
                outerForm={outerForm}
                form={form}
                cameFrom={props.dataId!}
                data={props.data}
            />

            <JsonView />
        </CFormModal>
    );
};

export default ModificationModal;
