import { t } from "lib/i18n";
import { CategoryTypeEnum } from "config/constants";
import _ from "lodash";
import { FIListItemProps } from "components/FormItems/FormItemsList";
import { GridInput } from "components/NewForm/Grid/GridInput";
import { GridSelect } from "components/NewForm/Grid/GridSelect";
import { useAppSelector } from "hooks/useRedux";
import { GridButtonRemove } from "components/NewForm/Grid/GridButtonRemove";
import { translateUnitNameLong } from "lib/utils";
interface IProps extends FIListItemProps<IngredientItemField> {}

export interface IngredientItemField {
    name: string;
    accounting_category_id: number;
    category_id: number;
    unit_id: number;
}

const IngredientItem = ({ index, remove, fields }: IProps) => {
    const categories = useAppSelector(state => state.model.menuCategories);
    const accountingCats = categories.ids
        .filter(id => categories.byId[id].type === CategoryTypeEnum.ACCOUNTING)
        .map(id => ({ label: categories.byId[id].name, value: id }));
    const ingredientCats = categories.ids
        .filter(id => categories.byId[id].type === CategoryTypeEnum.INGREDIENT)
        .map(id => ({ label: categories.byId[id].name, value: id }));

    const units = useAppSelector(state => state.model.units);
    const unitOptions = units.ids
        .filter(id => units.byId[id].type !== "PACKAGE")
        .map(id => ({ label: translateUnitNameLong(units.byId[id]), value: id }));

    return (
        <>
            <GridInput
                index={index}
                name={`products[${index}].name`}
                inputProps={{
                    placeholder: t("name"),
                }}
            />
            {/* <TextFormGroup name={`products[${index}].name`} label={false} focus={true} /> */}
            <GridSelect
                options={accountingCats}
                name={`products[${index}].accounting_category_id`}
                selectProps={{
                    placeholder: t("accounting_category"),
                }}
            />

            <GridSelect
                options={ingredientCats}
                name={`products[${index}].category_id`}
                selectProps={{
                    placeholder: t("select_category"),
                    sort: () => (a, b) => a._lft - b._lft,
                    renderMenuItem: (label: any, item: any) => {
                        return (
                            <span>
                                {_.times(item.depth).map((x, i) => (
                                    <span key={i} className="tw-px-1">
                                        --
                                    </span>
                                ))}
                                {label}
                            </span>
                        );
                    },
                }}
            />

            <GridSelect
                options={unitOptions}
                name={`products[${index}].unit_id`}
                selectProps={{
                    placeholder: t("unit"),
                }}
            />

            <GridButtonRemove disabled={fields.length < 2} onClick={() => remove(index)} />
        </>
    );
};
// (prev, next) => _.isEqual(prev.field, next.field) && prev.fields.length === next.fields.length,
// );

export default IngredientItem;
