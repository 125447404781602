import _ from "lodash";
import { Link } from "react-router-dom";
import { Button } from "rsuite";

interface IProps {
    action: string | (() => void);
    label: string;
    testId?: string;
    deleted?: boolean;
}

const CellBtn: FCC<IProps> = props => {
    return _.isString(props.action) ? (
        <Link to={props.action}>
            <Button
                appearance="link"
                test-id={props.testId}
                className="p-0"
                style={{ color: props.deleted ? "#e74c3c" : "#1675e0" }}
            >
                {props.label}
            </Button>
        </Link>
    ) : (
        <Button
            appearance="link"
            className="p-0"
            onClick={props.action}
            test-id={props.testId}
            style={{ color: props.deleted ? "#e74c3c" : "#1675e0" }}
        >
            {props.label}
        </Button>
    );
};

export default CellBtn;
