import { TText } from "components/i18n/TText";
import { selectReceiptItems, setReceiptItems } from "redux/features/receiptConstructor";
import { IconButton } from "rsuite";
import CIcon from "../../../../components/CIcon";
import { iFragment, iTrash } from "assets/icons/Icons";

import AddReceiptItemButton from "./AddReceiptItemButton";
import { useAppDispatch, useAppSelector } from "hooks/useRedux";
import { selectStationReceiptItems, setStationReceiptItems } from "redux/features/stationPrintConstructor";
import { ReceiptItemType } from "../types";
import { FC } from "react";
import { useSetting } from "hooks/useSetting";

interface IProps {
    receiptType: ReceiptItemType;
}
const RConstructorHeaderButtons: FC<IProps> = ({ receiptType }) => {
    const defaultReceiptItems = useSetting("receipt_print_config");
    const defaultStationReceiptItems = useSetting("station_print_config");
    const dispatch = useAppDispatch();
    const receiptItems = useAppSelector(selectReceiptItems);
    const stationReceiptItems = useAppSelector(selectStationReceiptItems);
    const reset = (e: any) => {
        e.preventDefault();
        if (receiptType === ReceiptItemType.Receipt) {
            dispatch(setReceiptItems({ items: JSON.parse(defaultReceiptItems?.default as any) }));
        }
        if (receiptType === ReceiptItemType.StationReceipt) {
            dispatch(setStationReceiptItems({ items: JSON.parse(defaultStationReceiptItems?.default as any) }));
        }
    };
    const log = (e: any) => {
        e.preventDefault();
        if (receiptType === ReceiptItemType.Receipt) {
            console.log(receiptItems);
        }
        if (receiptType === ReceiptItemType.StationReceipt) {
            console.log(stationReceiptItems);
        }
    };
    return (
        <div className="w-100 mt-2 d-flex justify-content-between">
            <IconButton
                className="mr-2 pl-2 "
                onClick={reset}
                icon={<CIcon style={{ fillRule: "evenodd" }} className="mr-1" path={iTrash} />}
            >
                <TText tkey="reset" />
            </IconButton>
            <div className=" d-flex flex-row justify-content-end">
                <IconButton
                    className="mr-2 pl-2 "
                    onClick={log}
                    icon={<CIcon style={{ fillRule: "evenodd" }} className="mr-1" path={iFragment} />}
                >
                    <TText tkey="config" />
                </IconButton>
                <AddReceiptItemButton receiptType={receiptType} />
            </div>
        </div>
    );
};
export default RConstructorHeaderButtons;
