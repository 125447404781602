import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IReceiptItem } from "pages/settings/receipt/types";
import { v4 } from "uuid";
import { ReduxSlicesNames } from "redux/features/ReduxSlicesNames";

interface ReceiptState {
    items: IReceiptItem[];
    changeCount: number;
}

export const receiptConstructorItems: IReceiptItem[] = [
    {
        type: "brandLogo",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: true,
        uniqueId: "f35581a3-67bb-4354-a741-55411c7e74bc",
    },
    {
        type: "brandName",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: true,
        uniqueId: "93414c56-d471-49d3-948b-b3a028d735ca",
    },

    {
        type: "receiptNumber",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "63963309-b88b-4b7b-9079-1e3a1cf1f518",
    },

    {
        type: "dailyId",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "06605fb6-94bc-4c61-9bf7-4d4c8ef62c78",
    },

    {
        type: "monthlyId",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "ca7f05f8-fba7-4cc4-b1ad-2953755ff7dc",
    },

    {
        type: "saleType",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "8b1460a5-69a4-4385-af03-27702d4278e6",
    },

    // Azer dedi ki, Cashier evvel olub, sonra silinib
    // {
    //     type: "Cashier",
    //     value: "",
    //     status: false,
    //     position: 4,
    //     isCustom: false,
    //     textSize: 1,
    //     isBold: false,
    //     showLabel: false,
    //     align: "left",
    //     description: "",
    //     default: false,
    // },
    {
        type: "receipt_owner",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "7842e022-f103-45c6-ba55-202bc139225a",
    },
    {
        type: "openDate",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "8f7f921f-8745-4f7e-a12d-fb01a2046be2",
    },
    {
        type: "closedDate",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "41eb4c1f-f0c0-4d1b-9465-b280b1a79138",
    },
    {
        type: "line",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        default: false,
        uniqueId: "69eeca7b-7e3d-4887-ab4b-424b911d0974",
    },
    {
        type: "receiptProduct",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        price: "subtotal",
        total: "total",
        tax: false,
        barcode: true,
        discount: true,
        modificator: true,
        gov_code: false,
        uniqueId: "eb9f6261-b3fa-4dd0-b88f-6fdaa3834464",
    },
    {
        type: "orderNumber",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "0ed65793-08cc-40a4-a276-1f720548bda9",
    },
    {
        type: "gift",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "b75cdfec-9d76-466d-9539-655bb5daec5b",
    },
    {
        type: "subtotal",
        value: "subtotal",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "8eb2c045-17e9-481b-ab49-839e7bd45b83",
    },
    {
        type: "discount",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "8c930cb5-94c7-4379-8289-35ae2ec7f442",
    },
    {
        type: "tax",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "22488851-1524-44e8-bc75-e4e19a68bf2e",
    },
    {
        type: "serviceCharge",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "89ef7dca-a336-4dd3-b1c9-f0aaad5144af",
    },
    {
        type: "delivery",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "5655983f-3a36-4ca1-9bc4-9d373c0feb02",
    },

    {
        type: "line",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        default: false,
        uniqueId: "f54389a1-f3c4-49ff-923d-fc8f54d00c16",
    },
    {
        type: "receiptTotal",
        value: "",
        status: false,
        isCustom: false,
        textSize: 2,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "5cc9f36f-849c-4380-a382-e6cd536904fd",
    },

    {
        type: "taxDetail",
        value: "",
        status: false,
        isCustom: false,
        textSize: 2,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "ef4d65e0-34ae-4873-9ef2-a496db008e5c",
    },

    {
        type: "customer",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        default: false,
        balance: false,
        phone: false,
        address: false,
        description: false,
        uniqueId: "cc5cd53a-c0ae-49e2-b7e6-ba7f3e929534",
    },
    {
        type: "paymentMethod",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "1448022d-82a1-45de-ad18-51f9a9ffab40",
    },
    {
        type: "brandAddress",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "203549f8-9b58-49ed-9b22-e70211e60200",
    },
    // Azer dedi ki, brandCity evvel olub, sonra silinib
    // {
    //     type: "brandCity",
    //     value: "",
    //     status: true,
    //     position: 23,
    //     isCustom: false,
    //     textSize: 1,
    //     isBold: false,
    //     showLabel: false,
    //     align: "left",
    //     description: "",
    //     default: false,
    // },

    // todo: ashagidakilar niye comment olunub bilmirem, backendde hele de qalir bu data
    // {
    //     type: "brandPhone",
    //     value: "",
    //     status: true,
    //     position: 21,
    //     isCustom: false,
    //     textSize: 1,
    //     isBold: false,
    //     showLabel: false,
    //     align: "left",
    //     description: "",
    //     default: false,
    // },
    {
        type: "description",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "2f6bd2d6-10d2-48eb-b8b1-1978deab9d41",
    },

    {
        type: "ByClopos",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: true,
        uniqueId: "f54011f7-e7e6-46c8-a209-78a7c6c5373a",
    },
    {
        type: "guestCount",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "6bbdbb89-d38e-4615-a34c-5100e18555b7",
    },
    {
        type: "courier",
        value: "",
        status: false,
        isCustom: false,
        textSize: 1,
        isBold: false,
        showLabel: false,
        align: "left",
        description: "",
        default: false,
        uniqueId: "3847dedf-0f45-4640-9a49-83440b33e8ee",
    },
];

const initialState: ReceiptState = {
    items: receiptConstructorItems,
    changeCount: 0, // this need for check if receipt is changed
};

const receiptSlice = createSlice({
    name: ReduxSlicesNames.RECEIPT,
    initialState,
    reducers: {
        setReceiptItems: (state, action: PayloadAction<{ items: IReceiptItem[] }>) => {
            state.items = action.payload.items.map(item => ({
                ...item,
                uniqueId: item.uniqueId || v4(),
            }));
            state.changeCount++;
        },
        addReceiptItem: (state, action: PayloadAction<IReceiptItem>) => {
            state.items.push(action.payload);
            state.changeCount++;
        },
        deleteReceiptItem: (state, action: PayloadAction<number>) => {
            state.items.splice(action.payload, 1);
            state.changeCount++;
        },
        updateReceiptItemPosition: (state, action: PayloadAction<{ startIndex: number; endIndex: number }>) => {
            const { startIndex, endIndex } = action.payload;

            if (startIndex < 0 || endIndex < 0 || startIndex >= state.items.length || endIndex >= state.items.length) {
                return;
            }

            const [removed] = state.items.splice(startIndex, 1);
            state.items.splice(endIndex, 0, removed);

            state.changeCount++;
        },
        toggleReceiptItemActivity: (state, action: PayloadAction<number>) => {
            const item = state.items[action.payload];
            item.status = !item.status;
            state.changeCount++;
        },
        toggleReceiptItemActivityByType: (state, action: PayloadAction<{ key: keyof IReceiptItem; type: string }>) => {
            const index = state.items?.findIndex(i => i.type === action.payload.type);

            if (index !== -1) {
                (state.items[index] as any)[action.payload.key] = !state.items[index][action.payload.key]; //as any typescript 4.8.3 don't access
            }
            state.changeCount++;
        },
        changeKeyReceiptItemByType: (
            state,
            action: PayloadAction<{ item: IReceiptItem; value: string; key?: keyof IReceiptItem }>,
        ) => {
            const item: IReceiptItem | undefined = state.items?.find(i => i.uniqueId === action.payload.item.uniqueId);
            const key: keyof IReceiptItem | undefined = action.payload.key;
            if (key && item) {
                (item as any)[key] = action.payload.value; //as any typescript 4.8.3 don't access
            } else if (item) {
                item.value = action.payload.value;
            }
            state.changeCount++;
        },
    },
});

export const selectReceiptItems = createSelector(
    (state: RootState) => state.receipt.items,
    items => items,
);
export const {
    updateReceiptItemPosition,
    deleteReceiptItem,
    addReceiptItem,
    toggleReceiptItemActivity,
    toggleReceiptItemActivityByType,
    changeKeyReceiptItemByType,
    setReceiptItems,
} = receiptSlice.actions;

export default receiptSlice.reducer;
