export const dummyFinanceCategory = (): Partial<IFinanceCategory> => {
    return {
        parent_id: null,
        name: "",
        type: "BOTH",
    };
};

// export const FinanceCategoryModel = () =>
//     object().shape({
//         parent_id: mixed().nullable(),
//         name: string().required(t("required")),
//         type: string().required(t("required")),
//     });
