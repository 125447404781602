import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { useDrawer } from "components/Drawers/useDrawer";
import { useData } from "hooks/useData";
import PaymentModal from "pages/settings/subscription/PaymentModal";
import { Button, Panel } from "rsuite";
import { useLoading } from "hooks/useLoading";
import { usePackages } from "providers/PackageProvider";
import { moneyFormatter } from "lib/moneyFormatter";
import { useSetting } from "hooks/useSetting";
import { format } from "date-fns";

const SubsHeader: FCC = () => {
    const { data } = useData<any>("brand/subscription", { with: ["packages"] });
    const modal = useDrawer();
    const { isLoading } = useLoading();
    const packages = usePackages();
    const currencies = useSetting("currency")?.extra?.symbols;
    const isSA = packages?.country === "SA";

    if (!data) {
        return null;
    }

    const subs = data;
    let date = format(new Date(subs.expired_at), "MMM d yyyy");

    if (subs.expired_at === null) {
        date = "∞";
    }

    const price = subs.period_price;
    const currency = subs?.currency?.toLowerCase();
    const period = subs.monthly_period;
    const monthWord = t("month");
    const subsTitle = t("subs_title", { price: moneyFormatter(price)!, period, monthWord });

    const packageTitle = () => data?.package?.title;

    return (
        <Panel className="bg-white mb-3 shadow">
            <PaymentModal {...modal} />
            <div className="subs-header">
                <div className="subs-header__block">
                    <h3 className="subs-header__title-desc">
                        {" "}
                        <TText tkey="is_valid_till" />{" "}
                    </h3>
                    <h1 className="subs-header__title-value">{date}</h1>
                </div>
                <div className="subs-header__block">
                    <h3 className="subs-header__title-desc">
                        {" "}
                        <TText tkey="subscription" />{" "}
                    </h3>
                    <h1 className="subs-header__title-value">
                        <span className="d-flex flex-column">
                            <span>
                                {subsTitle} {currencies[currency]}
                            </span>
                            {isSA && <small style={{ marginTop: -15 }}>{t("including_vat", { vat: "15" })}</small>}
                        </span>
                    </h1>
                </div>
                <div className="subs-header__block">
                    <h3 className="subs-header__title-desc">
                        <TText tkey="package_name" />
                    </h3>
                    <h1 className="subs-header__title-value">{packageTitle()}</h1>
                </div>
                <div className="subs-header__block tw-flex tw-justify-center tw-items-center">
                    <Button
                        appearance="primary"
                        color="green"
                        size="lg"
                        className="rounded"
                        loading={isLoading}
                        onClick={() => {
                            modal.setDataId(1);
                        }}
                    >
                        <TText tkey="pay" />
                    </Button>
                </div>
            </div>
        </Panel>
    );
};

export default SubsHeader;
