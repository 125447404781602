import NoData from "../../components/NoData";
import Widget from "../../components/Widget";

interface IProps {
    title: string;
    label: LangKey;
    permissions: string[];
}

const NoAccessWidget: FCC<IProps> = ({ title, label, permissions }) => (
    <Widget title={title}>
        <NoData title={title} label={label} permissions={permissions} />
    </Widget>
);

export default NoAccessWidget;
