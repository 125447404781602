import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { CategoryTypeEnum } from "config/constants";
import { renderCategoryMenuItem } from "lib/category-utils";
import { GenericDataState } from "redux/features/model/utils";
import { getLabel } from "../form-utils";
import SelectFormGroup from "../SelectFormGroup";
import { CategorySelectFormGroupProps } from "./CategorySelectFormGroupProps";
import { usePopupContainer } from "hooks/usePopupContainer";
import { ItemDataType } from "rsuite/cjs/@types/common";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import { convertToSlug } from "lib/convertToSlug";

interface IProps extends CategorySelectFormGroupProps {
    categories: GenericDataState<ICategory> | GenericDataState<IFinanceCategory>;
    disabledItemValues?: any[];
}

const CategorySelectFormGroup: FCC<IProps> = _porps => {
    const {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        disabled,
        isItemDisabled,
        valueKey = "id",
        labelKey = "name",
        width,
        testId,
        style,
        categories,
        revalidate,
        types = [],
        type,
        compProps,
        refineList,
        customFilter,
        ...props
    } = _porps;

    const { setValue } = useCFormContext();

    const name = props.name || "category_id";
    const [searchValue, setSearchValue] = useState<string>("");
    const hasSearchValue = !!searchValue.length;
    const [categoryList, setCategoryList] = useState<Array<ICategory | IFinanceCategory>>([]);
    const { container } = usePopupContainer();

    useEffect(() => {
        if (categories.status === "succeeded") {
            let _categoryList = [];

            if (types && types.length > 0) {
                _categoryList = categories.ids.filter(
                    id =>
                        types?.includes(categories.byId[id].type) &&
                        (!props.disabledIds || !props.disabledIds.includes(Number(id))),
                );
            } else {
                _categoryList = categories.ids;
            }

            _categoryList = _categoryList.map(id => categories.byId[id]);

            if (props.no_cat_option) {
                _categoryList.push({ id: 0, name: t("without_cat") } as ICategory);
            }

            if (customFilter) {
                _categoryList = _categoryList.filter(customFilter);
            }

            if (refineList) {
                _categoryList = refineList(_categoryList);
            }

            setCategoryList(_.cloneDeep(_categoryList));
        }
    }, [categories, revalidate, customFilter]);

    const disabledItemValues = useMemo(() => {
        return categoryList
            .filter(isItemDisabled ?? _.constant(false))
            .map(x => x[(valueKey as keyof typeof x) ?? "id"]);
    }, [isItemDisabled, categoryList, valueKey]);

    const InputWidth = width ? width : 300;

    const newCompProps = {
        labelKey,
        valueKey,
        disabled,
        disabledItemValues,
        style: { width: InputWidth },
        container: () => container.current!,
        "test-id": testId ?? name,
        placeholder: t("select", {
            name: props.hideLabel !== false ? props.label || getLabel(name) : "",
        }),
        onSearch: (keyword: string) => setSearchValue(keyword),
        searchBy: (keyword: string, label: React.ReactNode, item: ItemDataType) =>
            item?.slug?.includes(convertToSlug(keyword)),
        sort: (isGroup: boolean) => (a: any, b: any) => a._lft - b._lft,
        onClose: () => setSearchValue(""), // clear search value
        renderMenuItem: (label: any, item: any) =>
            renderCategoryMenuItem(label, item, hasSearchValue, testId ?? name, categories),
        renderExtraFooter: () => {
            if (props.modal && props.no_cat_btn && setValue) {
                return (
                    <div
                        className="rs-picker-select-menu-item text-center"
                        onClick={e => {
                            setValue(props.name ? props.name : "category_id", 0);
                        }}
                    >
                        <TText tkey="clear_category" />
                        {/*{t("create_new_by_name", { name: t("category") })}*/}
                    </div>
                );
            }
            if (props.modal && (props.create === undefined || props.create)) {
                return (
                    <div
                        test-id={`create-${props.name ?? "category_id"}`}
                        className="rs-picker-select-menu-item text-center"
                        onClick={() => {
                            props.modal.setDataId(0);
                            props.modal.setData({
                                name: props.name ? props.name : "category_id",
                                type: type ? type : CategoryTypeEnum.PRODUCT,
                            });
                        }}
                    >
                        {t("create_new_by_name", { name: t("category") })}
                    </div>
                );
            }
        },
        ...compProps,
    };

    const _props = {
        ...props,
        label: props.label,
        name: props.name ? props.name : "category_id",
    };

    const Comp = props.comp ?? SelectFormGroup;

    return (
        <Comp
            {..._props}
            className={props.className ?? ""}
            data={categoryList ?? []}
            plaintext={props.plaintext}
            testId={testId}
            style={style ?? {}}
            compProps={newCompProps}
        />
    );
};

export default CategorySelectFormGroup;
