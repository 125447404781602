import { t } from "lib/i18n";
import { TText } from "components/i18n/TText";
import { Constants } from "config/constants";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Button, Divider, Radio, RadioGroup } from "rsuite";
import DatePickerFormGroup from "./Form/DatePickerFormGroup";
import RadioFormGroup from "./Form/RadioFormGroup";
import { iClose, iPlus, iTrash } from "assets/icons/Icons";
import CIcon from "./CIcon";
import TagPickerFormGroup from "./Form/TagPickerFormGroup";
import { useFieldArray } from "react-hook-form";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import { format } from "date-fns";

const formKey = "meta.availability";
const itemAvailability = "itemAvailability";
const itemVisibility = "itemVisibility";

const generateNumber = (): number => {
    return Math.floor(100000 + Math.random() * 900000);
};

type IScheduleItemType = {
    type: string;
    deleteWeekEntry: () => void;
    index: number;
};

const ScheduleItem = ({ type, index, deleteWeekEntry }: IScheduleItemType) => {
    const ctx = useCFormContext();
    const wholeData = ctx.getValues(`${formKey}.schedules.${type}`);
    const hoursItems = ctx.watch(`${formKey}.schedules.${type}[${index}].hours`) ?? [];

    const ctxItemData = wholeData?.[index];
    const ctxHoursData = ctxItemData?.hours;

    const [is24Hours, set24Hours] = useState(_.isNil(ctxHoursData) || ctxHoursData?.length === 0);
    const wDays = Constants.weekDays.map((label, id) => ({ value: id + 1, label }));

    useEffect(() => {
        if (!is24Hours) return;
        ctx.setValue(`${formKey}.schedules.${type}[${index}].hours`, []);
    }, [is24Hours]);

    const addTimeEntry = () => {
        ctx.setValue(`${formKey}.schedules.${type}[${index}].hours`, [
            ...hoursItems,
            { from: new Date().toISOString(), to: new Date().toISOString(), key: generateNumber() },
        ]);
    };

    const deleteTimeEntry = (hourIndex: number) => {
        const hours = _.cloneDeep(hoursItems);
        hours.splice(hourIndex, 1);
        ctx.setValue(`${formKey}.schedules.${type}[${index}].hours`, hours);

        /**
         * todo: remove these unregisters as they are not needed after this commit: https://github.com/cloposcom/client/pull/792/commits/f8f0924915dcef02405d347f0364e7c14d970369
         * but removing these at the moment creates a bug - deleting time entry and clicking save button causes a crash
         */
        ctx.unregister(`${formKey}.schedules.${type}[${index}].hours[${hours.length}].to`);
        ctx.unregister(`${formKey}.schedules.${type}[${index}].hours[${hours.length}].from`);
    };

    return (
        <div className="date-hour-group">
            <Divider />
            <TagPickerFormGroup
                data={wDays}
                placeholder={t("week")}
                labelKey="label"
                label={false}
                valueKey="value"
                errorPlacement="rightEnd"
                name={`${formKey}.schedules.${type}[${index}].days`}
                compProps={{
                    placeholder: t("week"),
                    cleanable: true,
                    style: { width: 300 },
                    onChange: value => {
                        if (_.isArray(value))
                            ctx.setValue(
                                `${formKey}.schedules.${type}[${index}].days`,
                                value?.sort((a: number, b: number) => a - b),
                            );
                    },
                }}
            />
            <div>
                <RadioGroup value={is24Hours ? 1 : 0} style={{ display: "flex" }}>
                    <Radio value={0} onChange={() => set24Hours(false)}>
                        <TText tkey="left_chart_title" />
                    </Radio>
                    <Radio value={1} onChange={() => set24Hours(true)}>
                        <TText tkey="t_24_hourly" />
                    </Radio>
                </RadioGroup>
            </div>
            {!is24Hours &&
                hoursItems?.map((_hD: any, i: number) => (
                    <div key={_hD?.key ?? i} className="hours">
                        <DatePickerFormGroup
                            compProps={{
                                onClean: () =>
                                    ctx.setValue(`${formKey}.schedules.${type}[${index}].hours[${i}].from`, new Date()),
                                format: "HH:mm",
                                renderValue: v => format(v, "HH:mm"),
                                style: { width: 100 },
                                "test-id": "availability_start_time_" + i,
                            }}
                            label={false}
                            name={`${formKey}.schedules.${type}[${index}].hours[${i}].from`}
                        />
                        <DatePickerFormGroup
                            compProps={{
                                onClean: () =>
                                    ctx.setValue(`${formKey}.schedules.${type}[${index}].hours[${i}].to`, new Date()),
                                format: "HH:mm",
                                renderValue: v => format(v, "HH:mm"),
                                style: { width: 100 },
                                "test-id": "availability_end_time_" + i,
                            }}
                            label={false}
                            name={`${formKey}.schedules.${type}[${index}].hours[${i}].to`}
                        />
                        {hoursItems?.length !== 1 && (
                            <Button appearance="link" onClick={() => deleteTimeEntry(i)}>
                                <CIcon path={iClose} /> <TText tkey="delete" />
                            </Button>
                        )}
                    </div>
                ))}
            <div className="footer-actions">
                {!is24Hours && (
                    <Button appearance="link" onClick={() => addTimeEntry()}>
                        <CIcon path={iPlus} /> <TText tkey="add_time_slot" />
                    </Button>
                )}
                {wholeData?.length !== 1 && (
                    <Button appearance="link" onClick={() => deleteWeekEntry()}>
                        <CIcon path={iTrash} /> <TText tkey="delete_entered_entry" />
                    </Button>
                )}
            </div>
        </div>
    );
};

const ProductAvailability = () => {
    const ctx = useCFormContext();

    const itemAvailabilityField = useFieldArray({
        control: ctx.control,
        name: `${formKey}.schedules.${itemAvailability}`,
    });

    const itemVisibilityField = useFieldArray({
        control: ctx.control,
        name: `${formKey}.schedules.${itemVisibility}`,
    });

    const hasAvailabilityStatusEnabled = !!ctx.getValues(`${formKey}.${itemAvailability}`);
    const hasVisibilityStatusEnabled = !!ctx.getValues(`${formKey}.${itemVisibility}`);

    const ctxAviabilityData = ctx.watch(`${formKey}.schedules.${itemAvailability}`);
    const ctxVisibilityData = ctx.watch(`${formKey}.schedules.${itemVisibility}`);

    useEffect(() => {
        if (hasAvailabilityStatusEnabled) {
            if (ctxAviabilityData?.length === 0)
                ctx.setValue(`${formKey}.schedules.${itemAvailability}`, [
                    { days: [], hours: [], key: generateNumber() },
                ]);
        } else {
            ctx.setValue(`${formKey}.schedules.${itemAvailability}`, []);
        }
    }, [hasAvailabilityStatusEnabled]);

    useEffect(() => {
        if (hasVisibilityStatusEnabled) {
            if (ctxVisibilityData?.length === 0)
                ctx.setValue(`${formKey}.schedules.${itemVisibility}`, [
                    { days: [], hours: [], key: generateNumber() },
                ]);
        } else {
            ctx.setValue(`${formKey}.schedules.${itemVisibility}`, []);
        }
    }, [hasVisibilityStatusEnabled]);

    const addWeekEntry = (type: string) => {
        if (type === itemAvailability) {
            ctx.setValue(`${formKey}.schedules.${itemAvailability}`, [
                ...ctxAviabilityData,
                { days: [], hours: [], key: generateNumber() },
            ]);
        } else if (type === itemVisibility) {
            ctx.setValue(`${formKey}.schedules.${itemVisibility}`, [
                ...ctxVisibilityData,
                { days: [], hours: [], key: generateNumber() },
            ]);
        }
    };

    const deleteWeekEntry = (type: string, index: number) => {
        const values = ctx.getValues(`${formKey}.schedules.${type}`);

        const fieldToRemove = type === itemAvailability ? itemAvailabilityField : itemVisibilityField;
        fieldToRemove.remove(index);

        /**
         * todo: remove these unregisters as they are not needed after this commit: https://github.com/cloposcom/client/pull/792/commits/f8f0924915dcef02405d347f0364e7c14d970369
         * but removing these at the moment creates a bug - deleting time entry and clicking save button causes a crash
         */
        values[index]?.hours.forEach((v: any, i: number) => {
            ctx.unregister(`${formKey}.schedules.${type}[${index}].hours[${i}].to`);
            ctx.unregister(`${formKey}.schedules.${type}[${index}].hours[${i}].from`);
        });

        ctx.unregister(`${formKey}.schedules.${type}[${values.length - 1}].days`);

        values[values.length - 1]?.hours?.forEach((hour: string, i: number) => {
            ctx.unregister(`${formKey}.schedules.${type}[${values.length - 1}].hours[${i}].to`);
            ctx.unregister(`${formKey}.schedules.${type}[${values.length - 1}].hours[${i}].from`);
        });
    };

    return (
        <div className="product-aviability-container">
            <div className="area">
                <RadioFormGroup
                    name={`${formKey}.${itemAvailability}`}
                    label={t("product_availability")}
                    compProps={{
                        value: hasAvailabilityStatusEnabled ? 1 : 0,
                    }}
                >
                    <Radio value={0}>
                        <TText tkey="product_availability_on" />
                    </Radio>
                    <Radio value={1}>
                        <TText tkey="product_availability_off" />
                    </Radio>
                    {hasAvailabilityStatusEnabled && (
                        <div style={{ padding: 10 }}>
                            {ctxAviabilityData?.map((_sI: any, i: number) => (
                                <ScheduleItem
                                    key={_sI.key}
                                    type={itemAvailability}
                                    index={i}
                                    deleteWeekEntry={() => deleteWeekEntry(itemAvailability, i)}
                                />
                            ))}
                        </div>
                    )}
                    {hasAvailabilityStatusEnabled && (
                        <Button appearance="primary" onClick={() => addWeekEntry(itemAvailability)}>
                            <CIcon path={iPlus} /> <TText tkey="add" />
                        </Button>
                    )}
                </RadioFormGroup>
            </div>
            <div className="area">
                <RadioFormGroup
                    name={`${formKey}.${itemVisibility}`}
                    label={t("product_visibility")}
                    compProps={{
                        value: hasVisibilityStatusEnabled ? 1 : 0,
                    }}
                >
                    <Radio value={0}>
                        <TText tkey="product_visibility_on" />
                    </Radio>
                    <Radio value={1}>
                        <TText tkey="product_visibility_off" />
                    </Radio>
                    {hasVisibilityStatusEnabled && (
                        <div style={{ padding: 10 }}>
                            {ctxVisibilityData?.map((_sI: any, i: number) => (
                                <ScheduleItem
                                    key={_sI.key}
                                    type={itemVisibility}
                                    index={i}
                                    deleteWeekEntry={() => deleteWeekEntry(itemVisibility, i)}
                                />
                            ))}
                        </div>
                    )}
                    {hasVisibilityStatusEnabled && (
                        <Button
                            className="create-week-btn"
                            appearance="primary"
                            onClick={() => addWeekEntry(itemVisibility)}
                        >
                            <CIcon path={iPlus} /> <TText tkey="add" />
                        </Button>
                    )}
                </RadioFormGroup>
            </div>
        </div>
    );
};

export default ProductAvailability;
