import { t } from "lib/i18n";
import React, { useEffect, useState } from "react";
import CForm from "components/Form/CForm/CForm";
import TextFormGroup from "../../../../components/Form/TextFormGroup";
import RemoteSelectFormGroup from "../../../../components/Form/RemoteSelectFormGroup";
import CheckboxFormGroup from "../../../../components/Form/CheckboxFormGroup";
import ColorPicker from "../../../../components/Form/ColorPicker";
import UploaderFormGroup from "../../../../components/Form/FileUploadFormGroup";
import { useDrawer } from "components/Drawers/useDrawer";
import { useTableHeight } from "hooks/useTableHeight";
import { CheckPicker, Col, Grid, Row } from "rsuite";
import CFormGroup from "components/Form/CFormGroup";
import _ from "lodash";
import { useData } from "hooks/useData";
import JsonView from "components/JsonView";
import { useAuth } from "providers/AuthProvider/AuthProvider";
import { UseFormReturnType } from "hooks/useCForm";
import CIcon from "../../../../components/CIcon";
import { iSpinner } from "assets/icons/Icons";
import CreateCategoryModal from "../../../menu/categories/CreateCategoryModal";
import { ruleset } from "lib/validation-rules";
import { CategoryTypeEnum } from "config/constants";
import MenuCategorySelectFormGroup from "../../../../components/Form/CategorySelectFormGroup/MenuCategorySelectFormGroup";
import { useSetting } from "hooks/useSetting";

interface IProps {
    form: UseFormReturnType;
}

const ProductInfoTab: FCC<IProps> = ({ form }) => {
    const modal = useDrawer();
    const [isView] = useState(false);
    const returnsMaxAge = useSetting("returns_max_age")?.value;
    const auth = useAuth();
    const taxes = useData<IStorage[]>("finance/taxes", {}, auth.hasAbility(["tax_view"]));

    const sold_by_weight = form.form.watch("sold_by_weight");
    const has_modifications = form.form.watch("has_modifications");

    useEffect(() => {
        form.form.setValue("setting_max", returnsMaxAge);
        // eslint-disable-next-line
    }, []);
    const height = useTableHeight(190);

    return !form.isLoading ? (
        <div className="p-3">
            <CForm
                form={form.form}
                isLoading={form.isLoading}
                onSave={form.onSave}
                noPanel
                formProps={{
                    layout: "vertical",
                    style: {
                        height: height,
                    },
                }}
            >
                <div className="d-flex flex-column justify-content-between align-items-start">
                    <Grid fluid>
                        <Row>
                            <Col xs={24} sm={24} md={24} style={{ height: 200 }}>
                                <Col xs={24} sm={24} md={10} className="right-line">
                                    <UploaderFormGroup
                                        name="images[0].uuid"
                                        removeMainKey={true}
                                        label={t("image")}
                                        // uploadUrl="media"
                                        plaintext={isView}
                                        buttonStyle={{ height: 90, width: 160 }}
                                        fileInfoStyle={{ height: 90, width: 160 }}
                                        compProps={{
                                            action: "media",
                                            multiple: false,
                                            listType: "picture",
                                            name: "media_file",
                                        }}
                                    />
                                </Col>
                                <Col xs={24} sm={24} md={14}>
                                    <ColorPicker
                                        name="color"
                                        label={t("color")}
                                        plaintext={isView}
                                        controlWrapperProps={{
                                            style: {
                                                width: "100%",
                                            },
                                        }}
                                        width={"100%"}
                                    />
                                </Col>
                            </Col>
                            <Col xs={24} sm={24} md={12} className="mb-3">
                                <TextFormGroup
                                    name="name"
                                    errorPlacement="bottomStart"
                                    plaintext={isView}
                                    controlWrapperProps={{
                                        style: {
                                            width: "100%",
                                        },
                                    }}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={12} className="mb-3">
                                <TextFormGroup
                                    name="barcode"
                                    compProps={{
                                        onPressEnter: (
                                            e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
                                        ) => {
                                            e.key === "Enter" && e.preventDefault();
                                        },
                                        maxLength: sold_by_weight ? 5 : undefined,
                                        style: {
                                            // width: "100%",
                                        },
                                    }}
                                    errorPlacement="bottomStart"
                                    plaintext={isView}
                                    controlWrapperProps={{
                                        style: {
                                            width: "100%",
                                        },
                                    }}
                                    rules={{
                                        validate: {
                                            barcodeWeight: ruleset.barcodeWeight,
                                        },
                                    }}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={12} className="mb-3">
                                <MenuCategorySelectFormGroup
                                    modal={modal}
                                    revalidate={modal.dataId}
                                    plaintext={isView}
                                    types={[CategoryTypeEnum.PRODUCT]}
                                />
                                {!has_modifications && (
                                    <TextFormGroup
                                        name="cost_price"
                                        type="money"
                                        compProps={{
                                            style: {
                                                width: 300,
                                                // width: "100%",
                                            },
                                            disabled: true,
                                        }}
                                        controlWrapperProps={{
                                            style: {
                                                width: 300,

                                                // width: "100%",
                                            },
                                        }}
                                        plaintext={isView}
                                    />
                                )}
                            </Col>
                            <Col xs={24} sm={24} md={12} className="mb-3">
                                <RemoteSelectFormGroup
                                    remote={{ url: "station" }}
                                    name={"station_id"}
                                    searchLocal={true}
                                    compProps={{ cleanable: true }}
                                    plaintext={isView}
                                />
                                <CFormGroup name="taxes">
                                    <CheckPicker
                                        data={_.map(taxes?.data, (t: any) => ({
                                            value: t?.id,
                                            label: `${t?.name} (${t?.rate}%)`,
                                        }))}
                                        value={_.map(form.form.getValues("taxes"), (t: any) => t?.id)}
                                        onChange={tax =>
                                            form.form.setValue(
                                                "taxes",
                                                _.intersectionWith(taxes?.data as any, tax, (a: any, b) => a.id === b),
                                            )
                                        }
                                        onClean={() => form.form.setValue("taxes", [])}
                                        placeholder={t("select", { name: t("tax") })}
                                        style={{
                                            width: 300,
                                        }}
                                    />
                                </CFormGroup>
                            </Col>

                            <Col xs={24} sm={24} md={12} className="mb-3">
                                {!has_modifications && (
                                    <TextFormGroup
                                        name="price"
                                        label={t("sale_price")}
                                        type="money"
                                        compProps={{
                                            defaultValue: form.form.getValues("price") ?? 0,
                                            style: {
                                                width: "100%",
                                            },
                                        }}
                                        controlWrapperProps={{
                                            style: {
                                                width: "100%",
                                            },
                                        }}
                                        plaintext={isView}
                                    />
                                )}
                            </Col>

                            <Col xs={24} sm={24} md={12} className="mb-3">
                                <CheckboxFormGroup
                                    name={"hidden"}
                                    label={t("hide_on_terminal")}
                                    plaintext={isView}
                                    controlWrapperProps={{}}
                                />
                                <CheckboxFormGroup name={"giftable"} controlWrapperProps={{}} plaintext={isView} />
                                <CheckboxFormGroup
                                    name={"discountable"}
                                    label={t("not_allow_apply_discount")}
                                    plaintext={isView}
                                    controlWrapperProps={{}}
                                />
                                <CheckboxFormGroup
                                    name={"sold_by_weight"}
                                    plaintext={isView}
                                    compProps={{ disabled: true }}
                                    controlWrapperProps={{}}
                                />
                            </Col>
                        </Row>
                    </Grid>
                    {/*{!isView ? (*/}
                    {/*    <Button*/}
                    {/*        onClick={form.onSave}*/}
                    {/*        color="green"*/}
                    {/*        type="submit"*/}
                    {/*        loading={form.isLoading}*/}
                    {/*        disabled={!form.form.getValues("id")}*/}
                    {/*    >*/}
                    {/*        <CIcon path={iCheckBoxChecked} className="mr-2" />*/}
                    {/*        {t("save")}*/}
                    {/*    </Button>*/}
                    {/*) : null}*/}
                </div>
                <JsonView collapsed />
            </CForm>
            <CreateCategoryModal {...modal} />
        </div>
    ) : (
        <CIcon path={iSpinner} spin className="m-3" />
    );
};

export default ProductInfoTab;
