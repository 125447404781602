import React, { FC, useState } from "react";
import { Button, Notification, toaster } from "rsuite";
import { useFormItemsContext } from "./FormItems";
import CIcon from "components/CIcon";
import { iExcel } from "assets/icons/Icons";
import CUploaderModal from "../Drawers/CUploaderModal/CUploaderModal";
import { useDrawer } from "../Drawers/useDrawer";
import { TText } from "../i18n/TText";
import ImportResultModal from "../../pages/inventory/supplies/modals/ImportResultModal";
import _ from "lodash";
import { useDepot } from "hooks/useDepot";
import { useCFormContext } from "components/Form/CForm/CFormProvider";
import { t } from "lib/i18n";

export interface IImportedItemSuccesProps {
    products: any;
    append: (value: any) => void;
    remove: (index: number | number[]) => void;
}

export interface IFormItemsImportButtonProps {
    title?: string;
    className?: string;
    importUrl: string;
    onImportSuccess: (props: IImportedItemSuccesProps) => void;
}

export interface IImportedItem {
    product_id: number;
    quantity: number;
    uuid: string;
}

export const FormItemsImportButton: FC<IFormItemsImportButtonProps> = ({ title, className, importUrl, ...props }) => {
    const { append, remove } = useFormItemsContext();
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const { disabled } = useCFormContext();
    const [user] = useDepot<IUser | undefined>("user");
    const [quantities, setQuantities] = useState({
        import_count: 0,
        not_found_count: 0,
    });
    const importedResultModal = useDrawer();
    const importModal = useDrawer();

    const importOptions: IImportOption = {
        action: importUrl,
        onSuccess: (response: any) => {
            if (_.isArray(response)) {
                const products: IImportedItem[] = _.filter(response, r => !_.isNil(r.product_id)) ?? [];
                setQuantities({
                    import_count: products.length,
                    not_found_count: response.length - products.length,
                });

                importedResultModal.setIsOpen(true);

                props.onImportSuccess({ products, append, remove });
            } else {
                return toaster.push(
                    <Notification type="error" header={t("error") + " (ISC)"} closable>
                        <TText tkey="went_wrong" />
                    </Notification>,
                );
            }
        },
    };

    return (
        <>
            <Button
                className={className}
                color="cyan"
                appearance="primary"
                test-id="import-product-button"
                onClick={() => importModal.setIsOpen(true)}
                disabled={disabled}
            >
                <CIcon path={iExcel} className="mr-2" />
                <TText tkey="excel_import" />
            </Button>
            <CUploaderModal {...importModal} importOptions={importOptions} />
            <ImportResultModal
                {...importedResultModal}
                import_count={quantities.import_count}
                not_found_count={quantities.not_found_count}
            />
        </>
    );
};
