import { t } from "lib/i18n";
import { useDepot } from "hooks/useDepot";
import _ from "lodash";
import { depot } from "@cloposcom/libs";
import { arrayMove } from "@dnd-kit/sortable";

export interface ColumnsManagerType<T extends object = any> {
    allColumns: ITableColumnProps<T>[];
    columns: ITableColumnProps<T>[];
    toggleColumn: (col: string) => void;
    moveColumn: (from: string, to: string) => void;
}

const defaultColumns = (name: string) => {
    const cols: Record<string, ITableColumnProps> = {
        name: {
            title: t("name"),
            key: "name",
            flexGrow: 4,
            align: "left",
            render: e => _.capitalize(t(e.name?.replace(" ", "_").toLowerCase()) || e.name),
        },
    };
    return cols[name];
};

const refineColumn = (col: ITableColumnProps | string): ITableColumnProps => {
    if (_.isString(col)) {
        return defaultColumns(col);
    }

    if (col.type === "money" && !col.align) {
        col.align = "right";
    }

    col.hide ??= false;
    col.isFixed ??= false;

    return col;
};

type ColumnData = {
    id: string;
    hide: boolean;
};

const getColumnsData = <T>(
    cols: Array<string | ITableColumnProps<T>>,
    key: string,
    columnsDataKey: string,
    forceNew: boolean = false,
) => {
    const oldData = depot.getItem<ColumnData[]>(columnsDataKey);
    const isOldDataExist = !_.isNil(oldData);

    if (isOldDataExist && !forceNew) return oldData;

    const colsData = cols.reduce((result, col) => {
        if (typeof col === "string") {
            result.push({
                id: col,
                hide: false,
            });

            return result;
        }

        result.push({
            id: col.key as string,
            hide: col.hide ?? false,
        });

        return result;
    }, [] as ColumnData[]);

    if (isOldDataExist) {
        colsData.sort(
            (a, b) => oldData.findIndex(oldCol => oldCol.id === a.id) - oldData.findIndex(oldCol => oldCol.id === b.id),
        );
        colsData.forEach(colData => (colData.hide = oldData.find(oldCol => oldCol.id === colData.id)?.hide ?? false));
    } else {
        const prevSort = depot.getItem(`column-sorter-${key}`);

        if (!_.isNil(prevSort)) {
            colsData.sort((a, b) => prevSort.indexOf(a.id) - prevSort.indexOf(b.id));
        }

        const prevVisibility = depot.getItem(`columns-${key}`);

        if (!_.isNil(prevVisibility)) {
            colsData.forEach(colData => (colData.hide = !prevVisibility.includes(colData.id)));
        }
    }

    depot.setItem(columnsDataKey, colsData);

    return colsData;
};

const isAllColumnsExists = <T>(cols: Array<string | ITableColumnProps<T>>, columnsDataKey: string) => {
    const oldCols = depot.getItem<ColumnData[]>(columnsDataKey) ?? [];

    for (const col of cols) {
        const isColExist = oldCols.some(oldCol => oldCol.id === ((col as ITableColumnProps).key ?? col));

        if (!isColExist) return false;
    }

    return true;
};

export const useColumns = <T extends object = any>(
    key: string,
    _cols: Array<string | ITableColumnProps<T>>,
): ColumnsManagerType<T> => {
    const columnsDataKey = `columns-data-${key}`;
    const [data, setData] = useDepot<ColumnData[]>(columnsDataKey, getColumnsData<T>(_cols, key, columnsDataKey));
    let allColumns: ITableColumnProps<T>[] = _cols.map(refineColumn);

    if (!isAllColumnsExists(_cols, columnsDataKey)) {
        setData(getColumnsData<T>(_cols, key, columnsDataKey, true));
    }

    const toggleColumn = (key: string) => {
        const col = data.find(col => col.id === key)!;

        col.hide = !col.hide;

        setData([...data]);
    };

    const moveColumn = (fromKey: string, toKey: string) => {
        setData(prev =>
            arrayMove(
                prev,
                prev.findIndex(col => col.id === fromKey),
                prev.findIndex(col => col.id === toKey),
            ),
        );
    };

    allColumns = data
        .map(col => {
            const column = allColumns.find(c => col.id === c.key);

            if (column !== undefined) {
                column.hide = col.hide;
            }

            return column;
        })
        .filter(x => x !== undefined)
        .map(col => {
            if (col.isFixed) {
                col.hide = false;
            }

            return col;
        });

    return {
        allColumns: allColumns.filter(col => !col.isFixed),
        columns: allColumns.filter(c => !c.hide).sort((a, b) => Number(b.isFixed) - Number(a.isFixed)),
        toggleColumn,
        moveColumn,
    };
};
